import React from "react";

export const ReportIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10.835"
      height="11.82"
      viewBox="0 0 10.835 11.82"
    >
      <path
        id="Path_1898"
        data-name="Path 1898"
        d="M12.646,16.32h1.625a.26.26,0,0,0,.271-.246V4.746a.26.26,0,0,0-.271-.246H12.646a.26.26,0,0,0-.271.246V16.074A.26.26,0,0,0,12.646,16.32Z"
        transform="translate(-9.486 -4.5)"
        fill="#3f3f3f"
      />
      <path
        id="Path_1899"
        data-name="Path 1899"
        d="M5.9,25.668H7.521a.272.272,0,0,0,.271-.271V20.521a.272.272,0,0,0-.271-.271H5.9a.272.272,0,0,0-.271.271V25.4A.272.272,0,0,0,5.9,25.668Z"
        transform="translate(-5.625 -13.848)"
        fill="#3f3f3f"
      />
      <path
        id="Path_1900"
        data-name="Path 1900"
        d="M19.4,23.335h1.619a.273.273,0,0,0,.274-.274V16.024a.273.273,0,0,0-.274-.274H19.4a.273.273,0,0,0-.274.274V23.06A.273.273,0,0,0,19.4,23.335Z"
        transform="translate(-13.346 -11.514)"
        fill="#3f3f3f"
      />
      <path
        id="Path_1901"
        data-name="Path 1901"
        d="M25.875,9.274V19.561a.273.273,0,0,0,.274.274h1.619a.273.273,0,0,0,.274-.274V9.274A.273.273,0,0,0,27.768,9H26.149A.273.273,0,0,0,25.875,9.274Z"
        transform="translate(-17.207 -8.015)"
        fill="#3f3f3f"
      />
    </svg>
  );
};
