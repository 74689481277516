import { useCallback, useEffect, useMemo } from "react";
import { bool, func, array, string, object } from "prop-types";
import { useForm, useWatch, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
  Box,
  Drawer,
} from "@mui/material";
import useStyles from "../styles";
import { validationSchema } from "./OrderDirectAccess.validation";
import { StyledTextField, StyledLabel, StyledButton } from "components";
import { CrossIcon } from "components/Icons";
import { useDebounce } from "helpers/hooks";
import { error } from "utils/notifications";
import { orderDirectCheckEmail } from "services/account";
import { CUSTOMER_STATUS_FILTERS } from "utils/constants";
import { useDispatch } from "react-redux";
import {
  openConfirmDialogAction,
  setConfirmIsOpenAction,
} from "redux/actions/confirmDialogs";

const OrderDirectAccess = ({
  isOpen,
  contacts,
  handleClose,
  handleSendInvite,
  customerId,
  customerInfo,
  handleCustomerStatus,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    control,
    formState: { errors },
    reset,
    setValue,
    setError,
    clearErrors,
  } = useForm({
    mode: "onChange",
    defaultValues: { id: "", email: "" },
    resolver: yupResolver(validationSchema()),
  });

  const formField = useWatch({ control });

  const contactInputDebounced = useDebounce(formField?.email, 500);

  const handleCheckUniqueEmail = async (email, isError) => {
    if (email && !isError) {
      try {
        const { available } = await orderDirectCheckEmail({
          customer_id: customerId,
          email,
        });
        if (!available) {
          setError("email", {
            type: "custom",
            message: "This email already has been used",
          });
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        error(err?.response?.data?.message || "Something went wrong.");
      }
    }
  };

  useEffect(() => {
    handleCheckUniqueEmail(contactInputDebounced, errors?.email);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactInputDebounced]);

  useEffect(() => {
    contacts?.map((contact) => {
      if (contact?.defaultContact) {
        setValue("id", contact?.id || "");
        setValue("email", contact?.email || "");
      }
    });

    return () => {
      reset({
        ...formField,
        id: "",
        email: "",
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const setContact = useCallback(
    (contact) => {
      setValue("id", contact?.id || "");
      setValue("email", contact?.email || "");
      clearErrors("email");
    },
    [setValue, clearErrors]
  );

  const checkContactEmail = useCallback(() => {
    const matchedContact = [...contacts].find(
      (contact) => contact?.email === formField?.email
    );

    const matchedCustomerInfo = [customerInfo].find(
      (contact) => contact?.email === formField?.email
    );

    if (matchedContact || matchedCustomerInfo) {
      setContact(
        matchedCustomerInfo
          ? {
              id: "",
              email: matchedCustomerInfo?.email,
            }
          : matchedContact
      );
    } else {
      setValue("id", "");
    }
  }, [contacts, customerInfo, formField?.email, setContact, setValue]);

  useEffect(checkContactEmail, [checkContactEmail]);

  const isDisableSentBtn = useMemo(() => {
    return !!errors?.email || !formField?.email;
  }, [errors?.email, formField?.email]);

  const sortedContacts = contacts
    .filter((i) => i?.email)
    .sort((a, b) => {
      return Number(b?.defaultContact) - Number(a?.defaultContact);
    });

  const handleProceed = async () => {
    try {
      await handleCustomerStatus({
        target: { value: CUSTOMER_STATUS_FILTERS.active },
      });
      dispatch(setConfirmIsOpenAction(false));
      handleSendInvite(formField);
      handleClose();
    } catch (err) {
      error(err?.response?.data?.message);
    }
  };

  const handleTurnToActiveWarning = () => {
    if (customerInfo.status === CUSTOMER_STATUS_FILTERS.prospect) {
      dispatch(
        openConfirmDialogAction({
          title: "Turn prospect into active customer?",
          text: "Do you want to send order direct invitation to selected prospect? This will turn the prospect into active customer.",
          propBtns: {
            left: {
              label: "Cancel",
              color: "cancel",
              variant: "outlined",
              sx: { height: "28px", minWidth: "78px" },
            },
            right: {
              label: "Proceed",
              color: "primary",
              variant: "contained",
              onClick: handleProceed,
              sx: { height: "28px", minWidth: "78px" },
            },
          },
        })
      );
    } else {
      handleSendInvite(formField);
      handleClose();
    }
  };

  return (
    <Drawer anchor="right" open={isOpen} onClose={handleClose}>
      <Box sx={{ borderBottom: "0.5px solid #D5D9D9" }}>
        <IconButton
          sx={{ position: "absolute", top: 14, right: 14, p: 0 }}
          onClick={handleClose}
        >
          <CrossIcon />
        </IconButton>
        <Typography
          p="14px 0 12px 26px"
          fontWeight="400"
          fontSize="17px"
          color="#3F3F3F"
        >
          Order direct access
        </Typography>
      </Box>
      <Box p="32px" width="545px">
        <Grid display="flex" sx={{ flexDirection: "column" }} container>
          {contacts.length || customerInfo?.email ? (
            <>
              <Paper className={classes.contactsPaper}>
                {customerInfo?.email && (
                  <Box
                    sx={{
                      pt: "4px",
                      pl: 2,
                      mb: 2,
                      height: "62px",
                      backgroundColor:
                        formField?.email === customerInfo?.email &&
                        (formField?.id
                          ? formField?.id === customerInfo?.id
                          : true)
                          ? "rgb(71, 160, 109, .1)"
                          : "FFF",
                      border:
                        formField?.email === customerInfo?.email &&
                        (formField?.id
                          ? formField?.id === customerInfo?.id
                          : true)
                          ? "0.5px solid #47A06D"
                          : "0.5px solid #D5D9D9",
                      borderRadius: "6px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setContact({
                        id: "",
                        email: customerInfo?.email,
                      });
                    }}
                  >
                    <Typography className={classes.cardRoleText}>
                      Business Email
                    </Typography>
                    <Typography className={classes.cardText}>
                      {customerInfo?.name}
                    </Typography>
                    <Typography className={classes.cardEmailText}>
                      {customerInfo?.email}
                    </Typography>
                  </Box>
                )}
                {sortedContacts.map((contact) => (
                  <Box key={contact.id}>
                    {contacts.length === 1 ? (
                      <Box
                        sx={{
                          pt: "6px",
                          pl: 2,
                          mb: 2,
                          height: "62px",
                          backgroundColor:
                            formField?.id === contact?.id
                              ? "rgb(71, 160, 109, .1)"
                              : "FFF",
                          border:
                            formField?.id === contact?.id
                              ? "0.5px solid #47A06D"
                              : "0.5px solid #D5D9D9",
                          borderRadius: "6px",
                          cursor: "pointer",
                        }}
                        onClick={() => setContact(contact)}
                      >
                        <Typography className={classes.cardRoleText}>
                          {contact.role}
                          <StyledLabel
                            style={{
                              position: "relative",
                              left: "6px",
                              top: "2px",
                            }}
                          />
                        </Typography>
                        <Typography className={classes.cardText}>
                          {contact.name}
                        </Typography>
                        <Typography className={classes.cardEmailText}>
                          {contact.email}
                        </Typography>
                      </Box>
                    ) : (
                      <Box>
                        {contact.defaultContact ? (
                          <Box
                            sx={{
                              pt: "6px",
                              pl: 2,
                              mb: 2,
                              height: "46px",
                              backgroundColor:
                                formField?.id === contact?.id
                                  ? "rgb(71, 160, 109, .1)"
                                  : "FFF",
                              border:
                                formField?.id === contact?.id
                                  ? "0.5px solid #47A06D"
                                  : "0.5px solid #D5D9D9",
                              borderRadius: "6px",
                              cursor: "pointer",
                            }}
                            onClick={() => setContact(contact)}
                          >
                            <Typography className={classes.cardRoleText}>
                              Primary Email
                            </Typography>
                            <Typography className={classes.cardEmailText}>
                              {contact.email}
                            </Typography>
                          </Box>
                        ) : (
                          <Box
                            sx={{
                              pt: "4px",
                              pl: 2,
                              mb: 2,
                              height: "62px",
                              backgroundColor:
                                formField?.id === contact?.id
                                  ? "rgb(71, 160, 109, .1)"
                                  : "FFF",
                              border:
                                formField?.id === contact?.id
                                  ? "0.5px solid #47A06D"
                                  : "0.5px solid #D5D9D9",
                              borderRadius: "6px",
                              cursor: "pointer",
                            }}
                            onClick={() => setContact(contact)}
                          >
                            <Typography className={classes.cardRoleText}>
                              {contact.role}
                            </Typography>
                            <Typography className={classes.cardText}>
                              {contact.name}
                            </Typography>
                            <Typography className={classes.cardEmailText}>
                              {contact.email}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    )}
                  </Box>
                ))}
              </Paper>
              {customerInfo?.email || sortedContacts?.length ? (
                <Divider sx={{ mt: "4px", mb: "20px" }}>
                  <Typography fontSize="15px" fontWeight="500" color="#717171">
                    or add new email
                  </Typography>
                </Divider>
              ) : null}
            </>
          ) : (
            ""
          )}
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                fullWidth
                placeholder="Email address"
                size="small"
                InputLabelProps={{ className: classes.inputLabel }}
                error={error ? error.message : ""}
                noErrorMessage={false}
                errorMsgSx={{ bottom: -30 }}
                {...field}
              />
            )}
            name="email"
            control={control}
          />
        </Grid>
      </Box>
      <Box
        sx={{
          display: "flex",
          background: "#F8F8F8",
          p: "24px 42px 24px 42px",
          justifyContent: "flex-end",
          mt: "auto",
          alignItems: "baseline",
        }}
      >
        <StyledButton
          sx={{ height: 31, borderColor: "#D5D9D9" }}
          onClick={handleClose}
          label={
            <Typography
              fontSize="15px"
              sx={{
                color: "#5f6267",
              }}
              fontWeight="normal"
            >
              Cancel
            </Typography>
          }
          variant="text"
        />
        <StyledButton
          variant="contained"
          disabled={isDisableSentBtn}
          onClick={handleTurnToActiveWarning}
          label="Invite"
          fontSize="15px"
          sx={{
            height: "31px",
            width: "78px",
            boxShadow: "none",
            ml: "10px",
          }}
        />
      </Box>
    </Drawer>
  );
};

OrderDirectAccess.propTypes = {
  isOpen: bool,
  contacts: array,
  handleClose: func,
  handleSendInvite: func,
  customerId: string,
  customerInfo: object,
  handleCustomerStatus: func,
};

OrderDirectAccess.defaultProps = {
  isOpen: false,
  contacts: [],
  customerId: "",
};

export default OrderDirectAccess;
