import { Box } from "@mui/material";
import { TasksTotalSummaries, TasksFilter, TasksList } from "./components";

export const TasksTab = () => {
  return (
    <Box>
      <TasksTotalSummaries />
      <TasksFilter />
      <TasksList />
    </Box>
  );
};
