import { getEnvironment } from "helpers/helpers";
import {
  disconnectQuickBooks,
  disconnectShipStation,
  getQuickBooksAuth,
  syncQuickBooks,
} from "../../../../../services/integrations";
import { QuickbooksIcon, ShipstationIcon } from "./components/Icons";

const isProd = getEnvironment("production");

const SHIPSTATION_LOGIN = isProd
  ? "https://ship.shipstation.com/settings/stores"
  : "https://ship-devss111.sslocal.com/";

export const INTEGRATIONS = [
  {
    name: "ShipStation",
    icon: <ShipstationIcon />,
    connectService: () => {
      return SHIPSTATION_LOGIN;
    },
    disconnectService: disconnectShipStation,
    loadingKey: "ShipStation",
    confirmDisconnect: true,
    hasSync: false,
    disabled: false,
    tooltipProps: { disableHoverListener: true },
  },
  {
    name: "QuickBooks",
    icon: <QuickbooksIcon />,
    connectService: getQuickBooksAuth,
    disconnectService: disconnectQuickBooks,
    loadingKey: "Quickbooks",
    connectionKey: "quickBooksTokens",
    confirmDisconnect: false,
    hasSync: true,
    disabled: false,
    tooltipProps: { disableHoverListener: true },
    params: [
      {
        key: "useQuickBooksOrdersId",
        value: false,
        name: "Continue with QuickBooks invoice indexation",
      },
    ],
    progressBar: {
      hide: true,
      tooltip:
        "We'll send you an email notification once the synchronization is complete",
    },
  },
];

export const INTEGRATIONS_SYNC = { Quickbooks: syncQuickBooks };
