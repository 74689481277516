import React, { forwardRef } from "react";
import { string } from "prop-types";
import { Box } from "@mui/system";
import {
  AtentionIcon,
  CheckmarkIcon,
  InformationIcon,
  RefundedIcon,
  SmallHourglassIcon,
} from "../Icons";
import { Typography } from "@mui/material";
import { DRAFT_ORDER_TYPES } from "utils/constants";

export const PaymentStatus = forwardRef(
  ({ str, overdue = "", ...props }, ref) => {
    const styles = {
      block: {
        backgroundColor: "rgba(71, 160, 110, 0.2)",
        width: "max-content",
        minWidth: "62px",
        height: "22px",
        padding: 0.5,
        borderRadius: "10px",
        lineHeight: 1,
        fontSize: 10,
        fontWeight: 500,
        color: "#FFFFFF",
        textTransform: "uppercase",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    };

    switch (str) {
      case "PAID":
      case "FULFILLED":
      case "PARTIALLY FULFILLED":
      case DRAFT_ORDER_TYPES.closed:
        return (
          <Box sx={{ ...styles.block, color: "#47A06D" }} ref={ref} {...props}>
            <CheckmarkIcon width="11.18" height="8" stroke="#52B985" />
            <CheckmarkIcon
              width="11.18"
              height="8"
              stroke="#52B985"
              style={{ marginLeft: "-7px", marginRight: "3px" }}
            />
            {str === DRAFT_ORDER_TYPES.closed ? "COMPLETED" : str}
          </Box>
        );
      case "OVERDUE":
        // return (
        //   <Box
        //     sx={{
        //       ...styles.block,
        //       color: "#EB4233",
        //       backgroundColor: "#F9F9F9",
        //       gap: "4px",
        //       flexWrap: "nowrap",
        //       p: "0 8px 0 8.8px",
        //       whiteSpace: "nowrap",

        //       svg: {
        //         width: "9px",
        //         height: "9px",
        //       },
        //     }}
        //     ref={ref}
        //     {...props}
        //   >
        //     <InformationIcon stroke="#FF6969" />
        //     <Typography fontSize={10} fontWeight={500}>
        //       {overdue}
        //     </Typography>
        //   </Box>
        // );
        return (
          <Box
            component="span"
            display="flex"
            alignItems="center"
            ref={ref}
            {...props}
          >
            <Box display="flex" alignItems="center" width="14px">
              <InformationIcon width="9px" height="9px" stroke="#FF6969" />
            </Box>
            <Typography
              sx={{
                fontSize: 12,
                fontWeight: 300,
                color: "#EB4233",
              }}
            >
              {overdue}
            </Typography>
          </Box>
        );
      case "PARTIALLY_PAID":
        return (
          <Box
            sx={{
              ...styles.block,
              color: "#47A06D",
              display: "inline-flex",
              gap: "4px",
              alignItems: "center",
              px: "10px",
            }}
            ref={ref}
            {...props}
          >
            <CheckmarkIcon width="11.18" height="8" stroke="#52B985" />
            PARTIALLY PAID
          </Box>
        );

      case "PENDING":
        return (
          <Box
            sx={{
              ...styles.block,
              color: "#959595",
              backgroundColor: "rgba(149, 149, 149, 0.1)",
              display: "inline-flex",
              gap: "7px",
              px: "12px",
              width: "82px",
            }}
            ref={ref}
            {...props}
          >
            <SmallHourglassIcon />
            UNPAID
          </Box>
        );
      case "UNFULFILLED":
      case "UNPAID":
      case DRAFT_ORDER_TYPES.open:
        return (
          <Box
            sx={{
              ...styles.block,
              color: "#959595",
              backgroundColor: "rgba(149, 149, 149, 0.1)",
              display: "inline-flex",
              gap: "7px",
              px: "12px",
              width: "82px",
            }}
            ref={ref}
            {...props}
          >
            <SmallHourglassIcon />
            {str}
          </Box>
        );
      case "REFUNDED":
        return (
          <Box
            sx={{
              ...styles.block,
              color: "#FF6969",
              backgroundColor: "rgba(255, 105, 105, 0.15)",
              display: "inline-flex",
              gap: "7px",
              width: "92px",
              px: "11px",
            }}
            ref={ref}
            {...props}
          >
            <RefundedIcon />
            {str}
          </Box>
        );
      case "FAILED":
        return (
          <Box
            sx={{
              ...styles.block,
              color: "#FF6969",
              backgroundColor: "rgba(255, 105, 105, 0.15)",
              display: "inline-flex",
              gap: "3px",
              px: "10px",
            }}
            ref={ref}
            {...props}
          >
            <AtentionIcon size="12" />
            {str}
          </Box>
        );
      default:
        return (
          <Box sx={styles.block} ref={ref} {...props}>
            {str}
          </Box>
        );
    }
  }
);

PaymentStatus.displayName = "PaymentStatus";

PaymentStatus.propTypes = {
  str: string,
  overdue: string,
};

export default PaymentStatus;
