/* eslint-disable no-useless-escape */
import * as Yup from "yup";

export const validationSchema = ({ isCustomerEmail }) =>
  Yup.object().shape({
    contacts: Yup.array().when("businessEmail", {
      is: "",
      then: Yup.array().test("contactsValidation", " ", (value) => {
        return value.length > 0;
      }),
    }),
    subject: Yup.string().trim().required("This field is required"),
    message: Yup.string()
      .trim()
      .test("messageValidation", "This field is required", (value) => {
        if (isCustomerEmail) return !!value;
        return true;
      }),
  });
