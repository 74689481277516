import { string } from "prop-types";
import { Box, Typography } from "@mui/material";

export const NameBlock = ({
  productName,
  productSku,
  productSize,
  productColor,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        height: "100%",
        width: "20%",
        flexDirection: "column",
        flexGrow: 1,
      }}
    >
      <Typography fontSize={13} color="#1C1C19" width="100%" noWrap>
        {productName}
      </Typography>
      <Typography fontSize={13} color="#1C1C19" width="100%" noWrap>
        {productSku}
        {(productSize || productColor) && ` / ${productSize || productColor}`}
      </Typography>
    </Box>
  );
};

NameBlock.propTypes = {
  productName: string,
  mfrName: string,
  productSku: string,
  productSize: string,
  productColor: string,
};
