import { useEffect } from "react";
import { object, func, bool } from "prop-types";
import { Box, Paper, Tab, Tabs, Typography } from "@mui/material";
import useStyles, { cl } from "./styles";
import { ORDERS_FILTER_TABS, ORDER_TYPE_TABS } from "../constants";
import StyledTooltip from "../../../../../components/StyledTooltip";

export const Switcher = ({
  currentTabOrders,
  setCurrentTabOrders,
  isThirdParty,
}) => {
  const classes = useStyles();
  const {
    Switcher: { paperOptions, text },
  } = cl;

  const handleSetTabOrder = (tab) => {
    setCurrentTabOrders(tab);
  };

  useEffect(
    function resetTab() {
      setCurrentTabOrders({
        type: isThirdParty ? "third_party" : "",
        status: "",
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isThirdParty]
  );

  return (
    <Paper {...paperOptions}>
      <Box display="flex" alignItems="center" gap="18px" pl="21px">
        <Typography sx={text}>Orders</Typography>
        <Tabs
          value={currentTabOrders.type}
          onChange={(e, newVal) =>
            handleSetTabOrder((prev) => ({
              ...prev,
              status: newVal === "third_party" ? "" : prev.status,
              type: newVal,
            }))
          }
          className={classes.tabs}
          TabIndicatorProps={{ style: { background: "none" } }}
        >
          {ORDER_TYPE_TABS.map((tab, i) => (
            <Tab
              key={tab.value}
              label={
                <StyledTooltip
                  arrow
                  title={
                    isThirdParty && i !== 2
                      ? "Not available for 3rd party reps"
                      : ""
                  }
                  key={tab.value}
                  placement="top"
                >
                  <Box sx={{ pointerEvents: "all" }}> {tab.name}</Box>
                </StyledTooltip>
              }
              name={tab.field}
              value={tab.value}
              className={classes.tab}
              disabled={isThirdParty && i !== 2}
            />
          ))}
        </Tabs>
      </Box>
      {currentTabOrders.type !== "third_party" && (
        <Tabs
          value={currentTabOrders.status}
          onChange={(e, newVal) =>
            handleSetTabOrder((prev) => ({ ...prev, status: newVal }))
          }
          className={classes.tabs}
          TabIndicatorProps={{ style: { background: "none" } }}
          sx={{ pr: "12px" }}
        >
          {ORDERS_FILTER_TABS.map((tab) => (
            <Tab
              key={tab.value}
              label={tab.name}
              name={tab.field}
              value={tab.value}
              className={classes.tab}
            />
          ))}
        </Tabs>
      )}
    </Paper>
  );
};

Switcher.propTypes = {
  currentTabOrders: object,
  setCurrentTabOrders: func,
  isThirdParty: bool,
};
