import { number, array } from "prop-types";

import { normalizeSnakeCaseString } from "helpers/helpers";

import { Rating, Stack, Typography } from "@mui/material";

export const RatingBlock = ({
  rating,
  reviews,
  price,
  categories,
  widthCheck = 20,
}) => {
  const normalizedCategories = categories?.length
    ? categories.map((item) => normalizeSnakeCaseString(item))
    : [];

  const isTooLong = normalizedCategories.length
    ? normalizedCategories.join(" ,").length > widthCheck
    : false;

  return (
    <Stack
      flexWrap="wrap"
      direction={isTooLong ? "column" : "row"}
      alignItems={isTooLong ? "flex-start" : "center"}
      gap={isTooLong ? 0 : "6px"}
      width="100%"
      sx={{
        "& .MuiTypography-root": {
          fontSize: 12,
          fontWeight: 400,
          color: "#5F6267",
        },
      }}
    >
      <Stack direction="row" alignItems="center" gap="6px">
        {rating && (
          <Stack direction="row" gap="6px" alignItems="center">
            <Typography variant="caption">{rating}</Typography>
            <Rating
              value={rating}
              readOnly
              precision={0.1}
              size="small"
              sx={{ mb: "2px", fontSize: "16px" }}
            />
            {!!reviews && (
              <Typography variant="caption">({reviews})</Typography>
            )}
          </Stack>
        )}
        {price && (
          <Typography
            variant="caption"
            sx={{
              "& span": {
                mr: "6px",
              },
            }}
          >
            <span>•</span>
            {Array(price).fill("$").join("")}
          </Typography>
        )}
      </Stack>
      {!!normalizedCategories.length && (
        <Stack direction="row" alignItems="center" mb={isTooLong ? "6px" : 0}>
          <Typography
            variant="caption"
            sx={{
              "& span": {
                mr: "6px",
              },
            }}
          >
            {!isTooLong && <span>•</span>}
            {normalizedCategories.join(", ")}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

RatingBlock.propTypes = {
  rating: number,
  reviews: number,
  price: number,
  categories: array,
  widthCheck: number,
};
