import { func, object } from "prop-types";

import { photoUrl } from "helpers/helpers";
import { StyledProductIco, TrashTaskIcon } from "components/Icons";

import { cl } from "./styles";

import { Box, Stack, Typography } from "@mui/material";

export const PurchasedProductsListItem = ({ product, handleRemoveProduct }) => {
  return (
    <Stack direction="row" sx={cl.productItem} width="100%">
      <Stack
        direction="row"
        sx={cl.productItemContent}
        width="calc(100% - 30px)"
      >
        <Box className={cl.productPhoto} style={{ height: "35px" }}>
          <StyledProductIco
            src={
              product.photos?.length ? photoUrl(product.photos[0].fileName) : ""
            }
            styles={{
              objectFit: "contain",
              width: "35px",
              height: "35px",
              borderRadius: "4px",
            }}
            placeholderStyles={{
              style: {
                width: "35px",
                height: "35px",
                borderRadius: "4px",
                border: "1px solid #D5D9D9",
                boxSizing: "border-box",
                marginTop: "0.5px",
              },
            }}
          />
        </Box>
        <Stack width="calc(100% - 35px)">
          <Typography fontSize="12px" fontWeight="500" noWrap>
            {product?.name || product?.parentProduct?.name}
          </Typography>
          <Typography fontSize="12px" fontWeight="500" noWrap>
            {product?.sku}
            {(product?.size || product?.color) &&
              ` / ${product?.size || product?.color}`}
          </Typography>
        </Stack>
      </Stack>
      <Box
        sx={{ cursor: "pointer" }}
        onClick={() => handleRemoveProduct(product.id)}
      >
        <TrashTaskIcon />
      </Box>
    </Stack>
  );
};

PurchasedProductsListItem.propTypes = {
  product: object,
  handleRemoveProduct: func,
};

PurchasedProductsListItem.defaultProps = {
  product: {},
  handleRemoveProduct: () => {},
};
