import { string } from "prop-types";
import { Box, Typography } from "@mui/material";
import { normalizeEveryFirstLetterToUpper } from "helpers/helpers";

export const RoleBlock = ({ userRole }) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "left",
        alignItems: "center",
      }}
    >
      <Typography
        variant="caption"
        sx={{ display: "inline-block", color: "#1C1C19" }}
        fontSize="12px"
        noWrap
      >
        {userRole !== "THIRD_PARTY"
          ? normalizeEveryFirstLetterToUpper(userRole)
          : "3rd Party"}
      </Typography>
    </Box>
  );
};

RoleBlock.propTypes = { userRole: string };
RoleBlock.defaultProps = { userRole: "" };
