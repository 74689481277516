import { useCallback, useMemo, useState } from "react";
import { array, object, bool, func, number } from "prop-types";

import {
  CircularProgress,
  Grid,
  Stack as MyStack,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import { LargeCheckbox } from "../../../../../../../components/Checkboxes";
import { SCROLL_LIMIT_CUSTOMERS } from "../../../../../../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { getCustomersAction } from "../../../../../../../redux/actions/customers";
import { customersLoadingSelector } from "../../../../../../../redux/selectors/customers";
import { InfiniteScrollWrapper } from "components";

const Stack = styled(MyStack)({
  " > div:first-of-type": {
    borderRadius: "4px 4px 0 0",
  },
  " > div:last-child": {
    borderRadius: "0 0 4px 4px",
  },
});

const ListHeader = ({
  allchecked,
  isVisibleCheckbox,
  handlerAllCheckedCustomer,
}) => {
  const labels = [
    {
      id: 1,
      name: (
        <LargeCheckbox
          checked={allchecked}
          onChange={handlerAllCheckedCustomer}
          sx={{ p: 0 }}
        />
      ),
      width: 1,
      position: "center",
      visible: isVisibleCheckbox,
    },
    { id: 2, name: "ID", width: 1, position: "center", visible: true },
    {
      id: 3,
      name: "CUSTOMER",
      width: 6,
      position: "flex-start",
      paddingLeft: "24px",
      visible: true,
    },
    {
      id: 4,
      name: "TERRITORY",
      width: 4,
      position: "flex-start",
      visible: true,
    },
  ];
  return (
    <Grid
      container
      sx={{
        border: "1px solid #D5D9D9",
        height: "40px",
        backgroundColor: "#EEEEEE",
        marginTop: "22px",
      }}
    >
      {labels.map((el) => (
        <Grid
          key={el.id}
          xs={el.width}
          sx={{
            display: "flex",
            justifyContent: el.position,
            alignItems: "center",
            paddingLeft: el.paddingLeft ? el.paddingLeft : "",
          }}
          item
        >
          <Typography variant="header">{el.visible ? el.name : ""}</Typography>
        </Grid>
      ))}
    </Grid>
  );
};
ListHeader.propTypes = {
  allchecked: bool,
  isVisibleCheckbox: bool,
  handlerAllCheckedCustomer: func,
};

const ListItem = ({ customer, handlerCheckedCustomer, checkedCustomers }) => {
  const [hoverColor, setHoverColor] = useState("");

  const styleText = {
    fontSize: "12px",
  };

  const handleChangeCustomer = (event) => {
    handlerCheckedCustomer(customer, event.target.checked);
  };

  const isChecked = useMemo(
    () =>
      !!checkedCustomers.find((checkedUser) => checkedUser.id === customer.id),
    [checkedCustomers, customer.id]
  );

  return (
    <Grid
      sx={{
        border: "1px solid #D5D9D9",
        height: "48px",
        marginTop: "-1px",
        backgroundColor: isChecked ? "#ECF5F0" : hoverColor,
        borderLeft: "none",
        borderBottom: "none",
      }}
      container
      onMouseMove={() => setHoverColor("#F7F7F7")}
      onMouseLeave={() => setHoverColor("#FFF")}
    >
      <Grid
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        xs={1}
        item
      >
        <LargeCheckbox
          sx={{
            padding: 0,
          }}
          checked={checkedCustomers.includes(customer)}
          onChange={handleChangeCustomer}
        />
      </Grid>
      <Grid
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        xs={1}
        item
      >
        <Typography color="groundLight.contrastText" sx={styleText}>
          {customer?.customId?.customId}
        </Typography>
      </Grid>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
          paddingLeft: "24px",
        }}
        xs={6}
        item
      >
        <Typography
          color="groundLight.contrastText"
          sx={{
            ...styleText,
            fontWeight: "400",
          }}
        >
          {customer?.name}
        </Typography>
        <Typography
          color="groundLight.contrastText"
          sx={{
            ...styleText,
            fontWeight: 400,
            marginTop: "2px",
          }}
        >
          {customer?.shippingAddress?.formatted_address}
        </Typography>
      </Grid>
      <Grid
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
        xs={4}
        item
      >
        <Typography color="groundLight.contrastText" sx={styleText}>
          {customer?.territory?.name || "Uncategorized"}
        </Typography>
      </Grid>
    </Grid>
  );
};
ListItem.propTypes = {
  customer: object,
  handlerCheckedCustomer: func,
  checkedCustomers: array,
};

const selector = createSelector(
  customersLoadingSelector,
  (customersLoading) => ({
    customersLoading,
  })
);

const TerritoryCustomersTab = ({
  customers,
  customersCount,
  handlerCheckedCustomer,
  allchecked,
  handlerAllCheckedCustomer,
  checkedCustomers,
}) => {
  const { customersLoading } = useSelector(selector);
  const dispatch = useDispatch();

  const handleFetch = useCallback(() => {
    dispatch(
      getCustomersAction({
        limit:
          customers.length < SCROLL_LIMIT_CUSTOMERS
            ? customers.length
            : SCROLL_LIMIT_CUSTOMERS,
      })
    );
  }, [dispatch, customers]);

  return (
    <Stack
      sx={{
        borderRadius: "4px",
      }}
    >
      <ListHeader
        allchecked={allchecked}
        isVisibleCheckbox={customers?.length > 0}
        handlerAllCheckedCustomer={handlerAllCheckedCustomer}
      />
      <Stack
        sx={{
          maxHeight: "48vh",
          overflow: "hidden",
          position: "relative",
          opacity: customersLoading ? 0.5 : 1,
          pointerEvents: customersLoading && "none",
          borderRadius: "0 0 4px 4px",
          borderWidth: customers?.length ? "0 0 1px 1px" : 0,
          borderStyle: "solid",
          borderColor: "#D5D9D9",
        }}
      >
        <InfiniteScrollWrapper
          maxHeight="48vh"
          id="customer-scroll-table"
          dataLength={customers.length}
          next={handleFetch}
          loading={customersLoading}
          loader={
            <CircularProgress sx={{ ml: "50%", mt: "2px" }} size="30px" />
          }
          hasMore={customers.length < customersCount}
        >
          {customers.map((customer) => (
            <ListItem
              key={customer.id}
              customer={customer}
              handlerCheckedCustomer={handlerCheckedCustomer}
              checkedCustomers={checkedCustomers}
            />
          ))}
        </InfiniteScrollWrapper>
      </Stack>
    </Stack>
  );
};
TerritoryCustomersTab.propTypes = {
  customers: array,
  customersCount: number,
  allchecked: bool,
  handlerCheckedCustomer: func,
  handlerAllCheckedCustomer: func,
  checkedCustomers: array,
};

export default TerritoryCustomersTab;
