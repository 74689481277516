import { object, func, bool } from "prop-types";
import { ProspectContent } from "./components";
import { Box } from "@mui/material";
import { StyledTooltip } from "components";

const MapProspectTooltip = ({
  prospect,
  setTooltipHovered,
  children,
  showTooltip,
  tooltipIsOpen,
  setTooltipIsOpen,
  setShowTooltip,
  hideTooltip,
}) => {
  return (
    <StyledTooltip
      arrow
      isDark
      placement="top"
      open={tooltipIsOpen && showTooltip}
      onOpen={() => setTooltipIsOpen(true)}
      onClose={() => {
        setShowTooltip(false);
        setTooltipIsOpen(false);
      }}
      disableFocusListener
      disableHoverListener={hideTooltip}
      sx={{
        "& .MuiTooltip-tooltip": {
          mb: "0 !important",
          p: 0,
          maxWidth: "none",
        },
      }}
      PopperProps={{
        modifiers: [
          {
            name: "offset",
            options: { offset: [0, -2] },
          },
        ],
      }}
      title={
        <Box
          onMouseEnter={() => setTooltipHovered(true)}
          onMouseLeave={() => setTooltipHovered(false)}
        >
          <ProspectContent prospect={prospect} />
        </Box>
      }
    >
      {children}
    </StyledTooltip>
  );
};

MapProspectTooltip.defaultProps = {
  prospect: {},
  setTooltipHovered: () => {},
};

MapProspectTooltip.propTypes = {
  prospect: object,
  children: object,
  setTooltipHovered: func,
  hideTooltip: bool,
  setShowTooltip: func,
  tooltipIsOpen: bool,
  showTooltip: bool,
  setTooltipIsOpen: func,
};

export default MapProspectTooltip;
