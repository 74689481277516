import { func, object } from "prop-types";
import { Controller } from "react-hook-form";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { cl } from "../NotificationsMenu.styles";
import { TABS } from "../NotificationsMenu.constants";

export const Header = ({ setValue, control, onTabsClick }) => {
  return (
    <Box sx={cl.header}>
      <Box sx={cl.header.textWrapper}>
        <Typography sx={cl.header.text}>Order Direct</Typography>
      </Box>
      <Box sx={cl.header.tabsWrapper}>
        <Controller
          render={({ field }) => (
            <Tabs
              sx={cl.header.tabs}
              {...field}
              value={field.value}
              onChange={(e, newValue) => setValue("currentTab", newValue)}
            >
              {TABS.map((tab) => (
                <Tab
                  key={tab}
                  sx={cl.header.tab}
                  label={
                    <Box display="flex" alignItems="center">
                      <Box sx={cl.header.tabLabel}>{tab}</Box>
                    </Box>
                  }
                  value={tab}
                  onClick={() => onTabsClick(tab)}
                />
              ))}
            </Tabs>
          )}
          name="currentTab"
          control={control}
        />
      </Box>
    </Box>
  );
};

Header.propTypes = {
  setValue: func,
  control: object,
  onTabsClick: func,
};
Header.defaultProps = {
  onTabsClick: () => {},
};
