import { object, shape, array, number, func, string, oneOf } from "prop-types";
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { DELIVERY_METHODS } from "../../../../../../helpers";
import { StyledSelect } from "../../../../../../../../components";

export const LocalDeliveryBlock = ({
  control,
  setValue,
  shippingData,
  state,
  deliveryMethodRef,
  setPopperType,
}) => {
  const { deliveryMethod, delivererId, handleFetchReps } = shippingData || {};
  const { repsSalesUsers, salesCount } = state;

  return (
    <Box width="100%" display="flex" justifyContent="space-between">
      <Controller
        render={({ field, fieldState: { error } }) => (
          <FormControl
            sx={{
              ml: 0,
              width: "100%",
            }}
            component="fieldset"
          >
            <RadioGroup
              sx={{ display: "flex", justifyContent: "space-around" }}
              {...field}
            >
              <FormControlLabel
                ref={deliveryMethodRef}
                onMouseEnter={() => {
                  if (!repsSalesUsers?.length) {
                    setPopperType("deliveryMethod");
                  }
                }}
                onMouseLeave={() => setPopperType("")}
                value={DELIVERY_METHODS[1]}
                sx={{
                  ml: 1,
                  mr: 0,
                  mab: 0.5,
                  "& .MuiFormControlLabel-label": {
                    width: "100%",
                  },
                  height: "42px",
                }}
                label={
                  <>
                    <Box display="flex" justifyContent="space-between">
                      {deliveryMethod === DELIVERY_METHODS[1] && (
                        <StyledSelect
                          disabled={!repsSalesUsers?.length}
                          notched={false}
                          fullWidth
                          height="30px"
                          formSx={{
                            width: "40%",
                            maxWidth: "300px",
                            left: 0,
                            mt: "-6px",
                            height: "24px",
                            "& .MuiFormLabel-root": {
                              color: "#B5B5AC",
                              fontSize: "12px",
                              top: "2px",
                            },
                            "& .MuiOutlinedInput-root": {
                              height: "100%",
                              padding: "0 11px !important",
                            },
                            justifyContent: "space-between",
                            "& .MuiOutlinedInput-input": {
                              padding: "0 8px !important",
                            },
                            "& .MuiSvgIcon-root": {
                              right: "4px",
                            },
                          }}
                          size="small"
                          color={field.value === "" ? "#B5B5AC" : "#000000"}
                          displayEmpty
                          noErrorMessage
                          error={error?.message}
                          border="0.5px solid #D5D9D9"
                          {...field}
                          dataLength={repsSalesUsers?.length}
                          dataCount={salesCount}
                          handleFetch={handleFetchReps}
                          value={delivererId}
                          onChange={(e) =>
                            setValue("delivererId", e.target.value)
                          }
                        >
                          <MenuItem sx={{ display: "none" }} value="">
                            Assign Sales Rep
                          </MenuItem>
                          {repsSalesUsers?.map((sale) => (
                            <MenuItem
                              key={sale?.id}
                              value={sale?.id}
                              sx={{ width: "300px" }}
                            >
                              <Box
                                sx={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                                component="span"
                              >
                                {sale?.name}
                              </Box>
                            </MenuItem>
                          ))}
                        </StyledSelect>
                      )}

                      <Typography
                        fontSize={12}
                        fontWeight={400}
                        color={repsSalesUsers?.length ? "#000000" : "#ACACAC"}
                        variant="caption"
                      >
                        Local delivery, no shipping required
                      </Typography>
                    </Box>
                  </>
                }
                control={
                  <Radio
                    disabled={!repsSalesUsers?.length}
                    sx={{
                      "& svg": {
                        width: "16px",
                        height: "16px",
                      },
                    }}
                  />
                }
              />

              <Divider />

              <FormControlLabel
                value={DELIVERY_METHODS[0]}
                sx={{ ml: 1, my: 0.5 }}
                label={
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: 400,
                      color: "#000000",
                    }}
                    variant="caption"
                  >
                    Shipping carrier
                  </Typography>
                }
                control={
                  <Radio
                    sx={{
                      "& svg": {
                        width: "16px",
                        height: "16px",
                      },
                    }}
                  />
                }
              />
            </RadioGroup>
          </FormControl>
        )}
        name="deliveryMethod"
        control={control}
      />
    </Box>
  );
};

LocalDeliveryBlock.propTypes = {
  control: object,
  setValue: func,
  shippingData: shape({
    deliveryMethod: oneOf([...DELIVERY_METHODS]),
    delivererId: string,
    handleFetchReps: func,
  }),
  state: shape({
    repsSalesUsers: array,
    salesCount: number,
  }),
  deliveryMethodRef: object,
  setPopperType: func,
};
LocalDeliveryBlock.defaultProps = {
  control: {},
  setValue: () => {},
  shippingData: shape({
    deliveryMethod: [],
    delivererId: "",
    handleFetchReps: () => {},
  }),
  state: {
    repsSalesUsers: [],
    salesCount: 0,
  },
  deliveryMethodRef: {},
  setPopperType: () => {},
};
