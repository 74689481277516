import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  headerWrapper: {
    display: "flex",
    width: "458px",
    alignItems: "center",
    background: "#EEEEEE",
    border: "0.5px solid #CCCCCC",
    borderBottom: "none",
    height: "39px",
    paddingLeft: "16px",
    borderRadius: "4px 4px 0 0",
    "& .MuiTypography-root": {
      fontSize: "12px",
    },
  },

  stopsWrapper: {
    background: "#fff",
    height: "calc(100vh - 270px)",
    border: "0.5px solid #D5D9D9",
    borderRadius: "4px",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "6px",
      background: "#CCCCCC",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#A8A8A8",
      borderRadius: "8px",
      backgroundClip: "padding-box",
      border: "0.5px solid rgba(0, 0, 0, 0)",
    },
  },

  stopItem: {
    maxWidth: "100%",
    height: "57px",
    padding: "0 23px 0 15px",
    borderBottom: "0.5px solid #CCCCCC",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .title": {
      fontSize: "12px",
    },
    "& .index": {
      fontSize: "12px",
      color: "#B5B5AC",
      marginTop: "1px",
      fontWeight: "700",
    },
    "& .address": {
      fontSize: "12px",
      fontWeight: 300,
      marginTop: "2px",
    },
  },

  indexWrapper: {
    border: "2px solid #F0F0F0",
    borderRadius: "50%",
    height: "25px",
    width: "25px",
    textAlign: "center",
    paddingBottom: "5px",
  },

  deleteIcon: {
    border: "0.5px solid #B2B2B2",
    padding: "5px",
  },
  sliderWrapper: {
    width: "58px",
    marginRight: ({ moreThanOne }) => moreThanOne && "5px",
    "& .slick-arrow::before": {
      content: '""',
    },

    "& .slick-arrow.slick-prev": {
      left: "-8px",
    },

    "& .slick-track": {
      marginRight: 5,
      "& .slick-slide": {
        height: "25px",
        width: "25px!important",
        marginRight: "-8px",
        transition: "all 0.3s ease",
        "&:not(.slick-active)": {
          opacity: 0,
        },
      },
    },
  },
  assignedAvatar: {
    marginRight: "-6px",
    width: "25px",
    height: "25px",
    border: "0.5px solid #fff",
    transition: "all 0.3s ease",
    display: "flex!important",
    "&:hover": {
      zIndex: 100,
    },
  },
}));

export default useStyles;
