import { bool, func, array, string } from "prop-types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  IconButton,
  Divider,
} from "@mui/material";
import { StyledButton, TypographyAddress } from "components";
import { CrossIcon, CustomerIcon, DangerIcon } from "components/Icons";
import useStyles from "./styles";
import { CheckboxContainer } from "./components/CheckboxContainer";

const DuplicatePopup = ({
  isOpen,
  handleClose,
  showCheckboxes,
  handleDelete,
  handleProceed,
  duplicates,
  setCheckedState,
  checkedCustomers,
  showDeleteBtn,
  type,
  loading,
  disabledActionBtn,
}) => {
  const classes = useStyles({ showCheckboxes });
  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle className={classes.dialogTitle}>
        <Box className={classes.titleWrapper}>
          <DangerIcon size={22} />
          <Typography fontSize="17px" color="#3F3F3F">
            Duplicates found
          </Typography>
        </Box>
        <IconButton onClick={handleClose}>
          <CrossIcon />
        </IconButton>
      </DialogTitle>
      <Divider />

      <DialogContent className={classes.dialogContent}>
        {showCheckboxes ? (
          <>
            {duplicates.map((duplicate) => (
              <CheckboxContainer
                key={duplicate.id}
                duplicate={duplicate}
                type={type}
                setCheckedState={setCheckedState}
                checkedCustomers={checkedCustomers}
              />
            ))}
          </>
        ) : (
          <>
            {duplicates.map((duplicate) => {
              return (
                <Box className={classes.customerItemWrapper} key={duplicate.id}>
                  <Box className={classes.customerName}>
                    <CustomerIcon />
                    <Typography className={classes.customerTitle} noWrap>
                      {duplicate.name} (ID: {duplicate.customId.customId})
                    </Typography>
                  </Box>
                  <TypographyAddress
                    styles={{ fontSize: "12px" }}
                    address={duplicate[type]?.formatted_address}
                  />
                </Box>
              );
            })}
          </>
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <StyledButton
          disabled={loading || disabledActionBtn}
          variant="contained"
          label="Proceed with duplicate"
          onClick={() => handleProceed(type)}
          fontSize="15px"
          className={classes.actionButton}
        />

        {handleDelete && showDeleteBtn && (
          <StyledButton
            disabled={loading || disabledActionBtn}
            variant="contained"
            label="Delete duplicate"
            onClick={handleDelete}
            fontSize="15px"
            color="confirmDelete"
            className={classes.actionButton}
          />
        )}

        <StyledButton
          disabled={loading}
          variant="outlined"
          label="Cancel"
          color="cancel"
          fontSize="15px"
          onClick={handleClose}
          className={classes.actionButton}
        />
      </DialogActions>
    </Dialog>
  );
};

DuplicatePopup.propTypes = {
  isOpen: bool,
  handleClose: func,
  handleProceed: func,
  duplicates: array,
  handleDelete: func,
  showCheckboxes: bool,
  type: string,
  checkedCustomers: array,
  setCheckedState: func,
  loading: bool,
  disableDeleteBtn: bool,
  showDeleteBtn: bool,
  disabledActionBtn: bool,
};

DuplicatePopup.defaultProps = {
  isOpen: false,
  showDeleteBtn: false,
  loading: false,
  duplicates: [],
  showCheckboxes: false,
  type: "",
  disabledActionBtn: false,
};

export default DuplicatePopup;
