/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useMemo, useRef, useState } from "react";
import { bool, func, object, any, string } from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import {
  Autocomplete,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  Box,
  Skeleton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  LargeCheckbox,
  StyledAvatar,
  StyledButton,
  StyledTextField,
  TypographyAddress,
} from "components";
import {
  CrossIcon,
  StyledProductIco,
  SimplyDepoFooterIcon,
} from "components/Icons";
import useStyles from "./styles";
import {
  formatDate,
  getFormattedDate,
  matchEmail,
  normalizeUppercaseString,
  photoUrl,
} from "helpers/helpers";
import { useDebounce } from "helpers/hooks";
import { getUserRecipients } from "services/account";
import { getPaymentIntentLinkService } from "services/orders";
import { validationSchema } from "./ViewOrderEmailDialog.validations";
import { defaultValues, letterText } from "./ViewOrderEmailDialog.constants";
import { success } from "utils/notifications";
import { DoubleClipIcon } from "components/Icons/DoubleClipIcon";
import { EmailToBlock } from "./EmailToBlock";
import { updateUserFieldAction } from "redux/actions/auth";

const ViewOrderEmailDialog = ({
  isOpen,
  handleClose,
  order,
  handleSendEmail,
  isEdit,
  isDuplicate,
  customer,
  loading,
  selectedContactEmail,
}) => {
  const {
    deliveryFee,
    totalAmount: grandTotal,
    totalRawAmount: calculatedTotal,
  } = order || {};

  const dispatch = useDispatch();

  const totalDiscount = useMemo(
    () => order?.totalRawAmount - order?.totalAmountWithoutDelivery || 0,
    [order?.totalAmountWithoutDelivery, order?.totalRawAmount]
  );

  const distributor = useSelector(({ auth }) => auth.currentUser);
  const { timeZone, showIncludedPrices } = distributor || {};
  const repsList = useSelector(({ reps }) => reps.reps);
  const rep = repsList?.find((r) => r?.id === order?.salesId);
  const distributorName = order.distributor?.name;

  const customerName = useMemo(
    () => order?.customer?.displayName || order?.customer?.name,
    [order?.customer?.displayName, order?.customer?.name]
  );

  const { type, createOrderType } = order || {};

  const hasCreatedOrderThirdParty = useMemo(() => {
    if (createOrderType === "THIRD_PARTY" && order?.salesId) {
      return { ...rep, role: "THIRD_PARTY" };
    }

    if (type === "THIRD_PARTY") {
      return { ...order?.salesDuplicate, role: "THIRD_PARTY" };
    }

    return false;
  }, [order, rep, type, createOrderType]);

  const orderState = useMemo(() => {
    if (!isOpen) return {};

    return {
      createOrder: !order?.id && !isEdit,
      editOrder: !order?.id && !!isEdit,
      justOpenOrder: !!order?.id && !isEdit,
    };
  }, [isEdit, isOpen, order?.id]);

  const isPreviewValid = useMemo(() => {
    return (
      order?.paymentStatus !== "PAID" &&
      type !== "THIRD_PARTY" &&
      distributor?.stripeAccountVerificationStatus === "VERIFIED"
    );
  }, [
    order?.paymentStatus,
    type,
    distributor?.stripeAccountVerificationStatus,
  ]);

  const { control, handleSubmit, setValue, reset, setError, clearErrors } =
    useForm({
      mode: "onChange",
      defaultValues: {
        ...defaultValues,
        businessEmail: customer?.email || defaultValues?.businessEmail,
        contacts: customer
          ? [
              {
                customerId: customer?.id,
                defaultContact: true,
                email: customer?.email,
                id: customer?.customId?.customId,
                name: customer?.name,
                phone: customer?.phone,
                role: "Customer",
              },
            ]
          : defaultValues?.contacts,
        message: letterText(customerName, distributor?.name, isEdit),
      },
      resolver: yupResolver(validationSchema({ isCustomerEmail: !!customer })),
    });

  const classes = useStyles(isPreviewValid);
  const today = new Date();
  const formattedDate = moment(
    getFormattedDate(order?.createdAt, timeZone)
  ).format("MM/DD/YYYY");
  const formattedDateToday = moment(getFormattedDate(today, timeZone)).format(
    "MMM D, YYYY"
  );
  const formField = useWatch({ control });

  const messageBoxRef = useRef(null);
  const [showScrollbar, setShowScrollbar] = useState(false);

  useEffect(() => {
    if (messageBoxRef.current) {
      const height = messageBoxRef.current.clientHeight;
      if (height) setShowScrollbar(height >= 260);
    }
  }, [formField.message]);

  useEffect(() => {
    if (
      !formField?.others?.some((o) => o?.email === distributor?.baseUser?.email)
    )
      setValue("sendMe", false);
  }, [formField?.others, setValue, distributor?.baseUser?.email]);

  const [recipientsSearch, setRecipientsSearch] = useState("");
  const [contactsSearch, setContactsSearch] = useState("");
  const [recipientsLoading, setRecipientsLoading] = useState(false);

  const [CCList, setCCList] = useState([]);
  const [contactsList, setContactsList] = useState([]);
  const [invoiceLink, setInvoiceLink] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const recipientsSearchDebounced = useDebounce(recipientsSearch, 500);
  const contactsSearchDebounced = useDebounce(contactsSearch, 500);
  const cleanText = inputValue?.replace(/<br\s*\/?>/g, "\n");

  useEffect(() => setContactsSearch(""), [formField?.contacts]);
  useEffect(() => setRecipientsSearch(""), [formField?.others]);
  useEffect(() => {
    setInputValue(formField?.message);
  }, [formField?.message]);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(invoiceLink);
    success("Payment Link Copied");
  };

  const handleInputChange = (event) => {
    setInputValue(event.value);
  };

  const isDisabled = useMemo(() => {
    if ((customer && !formField?.message.trim()) || loading) return true;

    return (
      formField?.contacts?.length === 0 ||
      !formField.message.trim() ||
      !formField.subject.trim() ||
      recipientsSearch.trim() !== "" ||
      contactsSearch.trim() !== ""
    );
  }, [
    contactsSearch,
    customer,
    formField?.contacts?.length,
    formField.message,
    formField.subject,
    loading,
    recipientsSearch,
  ]);

  const getPaymentLink = async () => {
    if (order?.paymentStatus === "PAID" || order.orderStatus === "CANCELED")
      return;

    const res = await getPaymentIntentLinkService(order?.id);
    const { paymentIntentLink } = res || {};
    if (!paymentIntentLink) return;
    setInvoiceLink(paymentIntentLink);
  };

  useEffect(() => {
    if (isOpen && !isDuplicate && !isEdit && !orderState?.createOrder) {
      getPaymentLink();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order?.id, isOpen]);

  useEffect(() => {
    if (!isOpen) {
      setRecipientsSearch("");
      setContactsSearch("");
      setInvoiceLink(null);
      reset({
        ...defaultValues,
        // message: letterText(order?.customer?.name, distributor?.name, isEdit),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order?.id, isOpen]);

  const handleDeleteContact = (id) => {
    setValue(
      "contacts",
      formField.contacts.filter((c) => c.id !== id)
    );
  };
  const defaultContact = contactsList.find((cc) => cc.active === true);
  const isThirdPartyOrder =
    type === "THIRD_PARTY" || createOrderType === "THIRD_PARTY";
  const handleInvoiceLink = () => {
    if (invoiceLink) {
      window.open(invoiceLink, "_blank");
    }
  };

  const setSubject = useMemo(() => {
    const customId = order?.customId?.customId || order?.customId;
    if (order?.orderStatus === "CANCELED") return `Order ${customId} canceled`;

    if (orderState?.justOpenOrder)
      return type !== "THIRD_PARTY"
        ? `${distributor?.name} - Order ${customId}`
        : `Order ${customId}`;
    if (orderState?.editOrder || orderState?.createOrder)
      return isEdit && !isDuplicate
        ? !isThirdPartyOrder
          ? `Order ${customId} updated`
          : `Order ${customId} updated for ${distributor?.name} (3rd Party)`
        : !isThirdPartyOrder
        ? `Order ${customId} confirmed`
        : `Order ${customId} confirmed for ${distributor?.name} (3rd Party)`;
  }, [
    order,
    type,
    isThirdPartyOrder,
    orderState?.justOpenOrder,
    orderState?.editOrder,
    orderState?.createOrder,
    distributor?.name,
    isEdit,
    isDuplicate,
  ]);

  const setMessage = useMemo(() => {
    if (orderState?.justOpenOrder)
      return `Hello,\n\nWe appreciate your business. Please find your invoice details here. Feel free to contact us with any questions.\n\nKind regards,\n${distributor?.name} Team.`;
    if (orderState?.createOrder || orderState?.editOrder) {
      if ((type, createOrderType === "THIRD_PARTY")) {
        return `Hello,\n\nThere is a new order for ${customerName}. Please review and process the order.\n\nKind regards,\n${distributor?.name} Team.`;
      } else {
        return letterText(customerName, distributor?.name, isEdit);
      }
    }
    return "";
  }, [
    orderState?.justOpenOrder,
    orderState?.createOrder,
    orderState?.editOrder,
    customerName,
    distributor?.name,
    type,
    createOrderType,
    isEdit,
  ]);

  const defaultCustomerBusinessEmail = order?.customer?.email
    ? {
        customerId: order?.customer?.id,
        name: order?.customer?.name,
        email: order?.customer?.email,
      }
    : null;

  useEffect(() => {
    if (!isOpen) return;
    const selectContact = order?.customer?.contacts?.find(
      (contact) => contact.id === selectedContactEmail
    );
    const { customer: customerOfOrder } = order || {};
    const defaultContact = !selectedContactEmail
      ? defaultCustomerBusinessEmail
      : customerOfOrder?.contacts?.find(
          (c) => c?.email === selectContact?.email
        );

    reset({
      ...defaultValues,
      subject:
        setSubject !== formField?.subject ? setSubject : formField?.subject,
      contacts: hasCreatedOrderThirdParty?.email
        ? [hasCreatedOrderThirdParty]
        : defaultContact
        ? [defaultContact]
        : [],
      businessEmail: customerOfOrder?.email || "",
      message: setMessage,
      includePrices: isThirdPartyOrder ? showIncludedPrices : true,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order?.customer?.id, isOpen, customer]);

  useEffect(() => {
    const trimmedString = recipientsSearchDebounced.trim().trimStart();
    if (trimmedString) {
      setRecipientsLoading(true);
      return getUserRecipients({ search: trimmedString })
        .then((res) => {
          setRecipientsLoading(false);
          setCCList(res);
        })
        .catch(() => setRecipientsLoading(false));
    }
    setCCList(distributor?.contacts || []);
  }, [distributor, recipientsSearchDebounced]);

  useEffect(() => {
    const customerOfOrder = order?.customer;

    const currentCustomer = customer || customerOfOrder;

    if (hasCreatedOrderThirdParty && Array.isArray(currentCustomer?.contacts)) {
      if (hasCreatedOrderThirdParty.email)
        return setContactsList([
          hasCreatedOrderThirdParty,
          ...currentCustomer?.contacts?.filter((c) => c?.email),
        ]);

      return setContactsList(
        currentCustomer?.contacts?.filter((c) => c?.email)
      );
    }
    if (!currentCustomer) return;

    const list = [
      ...currentCustomer?.contacts?.filter((c) => c.email),
      ...repsList?.filter((c) => c.email || c?.baseUser?.email),
    ];
    if (currentCustomer?.email)
      list.push({ ...currentCustomer, role: "Business", isCustomer: true });
    if (contactsSearchDebounced) {
      return setContactsList(
        list?.filter(
          (contact) =>
            contact?.email &&
            contact?.email
              .toLowerCase()
              .includes(contactsSearchDebounced.toLowerCase())
        )
      );
    }
    return setContactsList(list);
  }, [
    contactsSearchDebounced,
    customer,
    hasCreatedOrderThirdParty,
    order,
    repsList,
  ]);

  const onSubmit = (data) => {
    if (recipientsSearch.trim()) {
      return setError("cc", {
        message: "Please add a valid email address.",
      });
    }

    if (contactsSearch.trim()) {
      return setError("contacts", {
        message: "Please add a valid email address.",
      });
    }

    const {
      message,
      subject,
      sendMe,
      files,
      includePaymentLink,
      includePrices,
    } = data || {};
    const updatedMessage = message.replace(/\n/g, "<br>");
    const otherEmails = data.others
      .map((o) => o.email.trim())
      .filter((email) => email !== distributor?.baseUser?.email);

    const recipientEmails = data.contacts
      .filter(
        (c) =>
          typeof c === "object" &&
          (c.email ||
            c.id ||
            c.customerId ||
            (c.id && !(c.isCustomer || c.role === "Customer")))
      )
      .map((c) => c.email || (c.baseUser && c.baseUser.email))
      .filter((email) => typeof email === "string");

    const formattedData = {
      recipientEmails,
      otherEmails,
      // businessEmail,
      includePaymentLink,
      message: updatedMessage,
      subject,
      sendMe,
      ...(isThirdPartyOrder && { includePrices }),
    };

    if (isThirdPartyOrder)
      dispatch(updateUserFieldAction("showIncludedPrices", includePrices));

    if (customer?.id) {
      const params = {
        customer_id: customer?.id,
      };

      const uploadFormData = new FormData();

      Object.keys(formattedData).forEach((key) =>
        uploadFormData.append(key, JSON.stringify(formattedData[key]))
      );

      const filesArray = Object.keys(files).map((f) => files[f]);

      filesArray.forEach((file) => uploadFormData.append("file[]", file));

      handleSendEmail({ params, data: uploadFormData });
    } else {
      handleSendEmail(formattedData);
    }
  };

  const processingIntoChip = () => {
    const newEmail = recipientsSearch.trim().trimStart();
    const newEmailC = contactsSearch.trim().trimStart();

    const alreadyAddedRecipients =
      !!formField.others.find((e) => e.email === newEmail) ||
      !!formField.contacts.find((e) => e.email === newEmail);

    const alreadyAddedContacts =
      !!formField.others.find((e) => e.email === newEmailC) ||
      !!formField.contacts.find((e) => e.email === newEmailC);

    if (!alreadyAddedRecipients && newEmail && matchEmail(newEmail)) {
      setRecipientsSearch("");
      setValue("others", [
        ...formField.others,
        {
          id: newEmail,
          email: newEmail,
        },
      ]);
      clearErrors("cc");
    }

    if (!alreadyAddedContacts && newEmailC && matchEmail(newEmailC)) {
      setContactsSearch("");
      setValue("contacts", [
        ...formField.contacts,
        {
          id: newEmailC,
          email: newEmailC,
        },
      ]);
      clearErrors("contacts");
    }
  };
  const handlePressSpace = (e) => {
    const val = e.target.value;
    if (e.keyCode === 32 && val !== "") {
      processingIntoChip();
    }
  };

  const setBillingAddress = (order) => {
    if (order?.customer?.billToParentCustomer) {
      return order?.customer?.parentCustomer?.billingAddress?.formatted_address;
    }

    return (
      order?.customer?.billingAddress?.formatted_address ||
      customer?.billingAddress?.formatted_address
    );
  };

  const isVerifiedDist = useMemo(
    () => distributor?.stripeAccountVerificationStatus === "VERIFIED",
    [distributor?.stripeAccountVerificationStatus]
  );

  const showPaymentLinkCheckbox = useMemo(
    () =>
      isPreviewValid &&
      !isThirdPartyOrder &&
      formField.includePaymentLink &&
      isVerifiedDist,
    [
      formField.includePaymentLink,
      isPreviewValid,
      isThirdPartyOrder,
      isVerifiedDist,
    ]
  );

  const formDate = formatDate({
    utc_date: order?.createdAt,
    formatThisYear: "MMM DD[,] H:mm a",
  });

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      PaperProps={{ className: classes.dialog }}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Box sx={{ display: "inline-flex", gap: "15px" }}>
          <Typography className={classes.titleText}>Email Order</Typography>
        </Box>
        <IconButton onClick={handleClose}>
          <CrossIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          width: "100%",
          minWidth: "631px",
          padding: "20px 41px 0px 42px!important",
          display: "flex",
          overflow: "auto",
          height: "calc(100vh - 214px)",
        }}
      >
        <form
          id="view-order-email-form"
          onSubmit={handleSubmit(onSubmit)}
          className={classes.formBox}
        >
          <Box>
            <EmailToBlock
              customerName={
                order?.customer?.displayName ||
                order?.customer?.name ||
                customer?.displayName ||
                customer?.name
              }
              handlePressSpace={handlePressSpace}
              processingIntoChip={processingIntoChip}
              formattedBillingAddress={setBillingAddress(order)}
              {...{
                control,
                setValue,
                handleDeleteContact,
                contactsList,
                recipientsLoading,
                contactsSearch,
                setContactsSearch,
              }}
            />

            <Box className={classes.rowWrap} mt="14px" alignItems="center">
              <Box flex="0 0 15%">
                <Typography className={classes.rowTitle}>CC:</Typography>
              </Box>
              <Box flex="0 0 80%" maxWidth="80%" position="relative">
                <Controller
                  render={({ field, fieldState: { error } }) => (
                    <Autocomplete
                      multiple
                      freeSolo
                      disableCloseOnSelect
                      loading={recipientsLoading}
                      PaperComponent={(props) => (
                        <Box {...props} className={classes.recipientsTable} />
                      )}
                      size="small"
                      isOptionEqualToValue={(option, value) =>
                        option.email === value.email
                      }
                      sx={{
                        "& .MuiAutocomplete-paper": {
                          boxShadow: "none",
                        },
                      }}
                      className={classes.textfield}
                      getOptionLabel={(option) =>
                        option.email || option?.baseUser?.email
                      }
                      renderInput={(params) => (
                        <StyledTextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            value: recipientsSearch,
                          }}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: <></>,
                          }}
                          error={error?.message || ""}
                          noErrorMessage
                          placeholder="Emails"
                          className={classes.textfield}
                          value={recipientsSearch}
                          inputSx={{ color: "#707070" }}
                          onChange={(e) => setRecipientsSearch(e.target.value)}
                          endIcon={
                            recipientsLoading && <CircularProgress size={20} />
                          }
                          onKeyDown={handlePressSpace}
                          onBlur={processingIntoChip}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              height: "fit-content",
                              p: "5px!important",
                              fontSize: "12px",
                            },
                          }}
                        />
                      )}
                      ListboxProps={{
                        sx: {
                          p: 0,
                          maxHeight: "178px",
                          boxShadow: "none",
                          overflow: "overlay",
                        },
                      }}
                      renderOption={(props, recipient, { selected }) => (
                        <Grid
                          key={recipient?.id}
                          container
                          columns={10}
                          sx={{
                            backgroundColor:
                              selected && "rgba(66, 165, 127, 0.1)",
                          }}
                          {...props}
                          className={classes.recipientItem}
                        >
                          <Grid item xs={7}>
                            <Box display="flex" gap="11px" alignItems="center">
                              <StyledAvatar
                                src={photoUrl(
                                  recipient?.profilePhoto?.fileName
                                )}
                                name={recipient?.name}
                                sx={{
                                  height: "29px",
                                  width: "29px",
                                  backgroundColor: "#F5F5F5",
                                  color: "#7A7A7A",
                                  fontWeight: "700",
                                }}
                                stringStyle={{ fontSize: "13px" }}
                              />
                              <Box sx={{ width: "calc(100% - 40px)" }}>
                                <Typography noWrap>
                                  {recipient?.name}
                                </Typography>
                                <Typography fontWeight="400" noWrap>
                                  {recipient?.email}
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography noWrap>
                              {normalizeUppercaseString(recipient?.role) || "-"}
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                      options={CCList}
                      renderTags={(value, getTagProps) => (
                        <Box className={classes.recipientChipWrapper}>
                          {value
                            .filter((val) => !!val?.email)
                            .map((cc, index) => (
                              <Chip
                                key={index}
                                sx={{
                                  borderRadius: "10px",
                                  border: "1px solid #D4D4D4",
                                  backgroundColor: "#F8F8FA",
                                  height: "19px",
                                  maxWidth: "90%",
                                }}
                                size="small"
                                label={
                                  <Typography
                                    sx={{
                                      fontSize: "12px",
                                      lineHeight: 1.5,
                                    }}
                                    color="groundLighter.main"
                                    noWrap
                                  >
                                    {cc?.email}
                                  </Typography>
                                }
                                deleteIcon={<CloseIcon />}
                                onMouseDown={(e) => e.stopPropagation()}
                                {...getTagProps({ index })}
                              />
                            ))}
                        </Box>
                      )}
                      {...field}
                      onChange={(event, newValue) => {
                        if (event.keyCode !== 8) {
                          setValue(field.name, newValue);
                        }
                      }}
                    />
                  )}
                  name="others"
                  control={control}
                />
              </Box>
            </Box>
            <Box className={classes.rowWrap} mt="23px" alignItems="center">
              <Box flex="0 0 15%">
                <Typography className={classes.rowTitle}>Subject:</Typography>
              </Box>
              <Box flex="0 0 80%">
                <Controller
                  render={({ field, fieldState: { error } }) => (
                    <StyledTextField
                      placeholder="Subject"
                      className={classes.textfield}
                      inputSx={{ color: "#707070" }}
                      disabled={!order?.createdAt && !isEdit ? false : true}
                      error={error?.message || ""}
                      {...field}
                    />
                  )}
                  name="subject"
                  control={control}
                />
              </Box>
            </Box>
            <Box className={classes.rowWrap} mt="25px">
              <Box flex="0 0 15%" mt="4px">
                <Typography className={classes.rowTitle}>Message:</Typography>
              </Box>
              <Box flex="0 0 80%">
                <Controller
                  render={({ field, fieldState: { error } }) => (
                    <Box ref={messageBoxRef}>
                      <StyledTextField
                        placeholder={
                          customer
                            ? "Example of the message"
                            : "Example of the message (optional)"
                        }
                        multiline
                        size="small"
                        inputSx={{ color: "#707070" }}
                        // onChange={handleInputChange(field)}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            height: "auto",
                            maxHeight: "260px",
                            minHeight: "86px",
                            overflowY: "auto",
                            display: "flex",
                            alignItems: "baseline",
                            "& textarea": {
                              overflow: showScrollbar
                                ? "scroll !important"
                                : "hidden !important",
                              maxHeight: "243px !important",
                            },
                          },
                        }}
                        className={classes.textfield}
                        error={error?.message || ""}
                        {...field}
                        onChange={(e) => {
                          handleInputChange(field);
                          field.onChange(e);
                        }}
                      />
                    </Box>
                  )}
                  name="message"
                  control={control}
                />
              </Box>
            </Box>
            <Box
              sx={{
                mt: "10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "baseline",
              }}
            >
              <Controller
                render={({ field }) => (
                  <LargeCheckbox
                    label="Send me a copy of this email"
                    formSx={{ pl: "15%", ml: "-9px" }}
                    size={16}
                    labelSx={{ fontSize: "11px", color: "#363531" }}
                    checked={!!field.value}
                    {...field}
                    onChange={() => {
                      setValue(field.name, !field.value);
                      if (!field.value)
                        return setValue("others", [
                          ...formField.others,
                          { email: distributor?.baseUser?.email },
                        ]);
                      setValue(
                        "others",
                        formField.others.filter(
                          (o) => o.email !== distributor?.baseUser?.email
                        )
                      );
                    }}
                  />
                )}
                name="sendMe"
                control={control}
              />

              {isThirdPartyOrder && (
                <Controller
                  render={({ field }) => (
                    <LargeCheckbox
                      label="Include pricing"
                      formSx={{ pl: "15%", ml: "-9px" }}
                      size={16}
                      labelSx={{ fontSize: "11px", color: "#363531" }}
                      checked={!!field.value}
                      {...field}
                    />
                  )}
                  name="includePrices"
                  control={control}
                />
              )}
            </Box>

            {isVerifiedDist && !isThirdPartyOrder && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "baseline",
                }}
              >
                <Controller
                  render={({ field }) => (
                    <LargeCheckbox
                      label="Include payment link"
                      formSx={{ pl: "15%", ml: "-9px" }}
                      size={16}
                      labelSx={{ fontSize: "11px", color: "#363531" }}
                      checked={!!field.value}
                      {...field}
                    />
                  )}
                  name="includePaymentLink"
                  control={control}
                />
              </Box>
            )}
          </Box>
          {isPreviewValid &&
            (order?.paymentStatus === "PENDING" ||
              order?.paymentStatus === "PARTIALLY_PAID") &&
            order.orderStatus !== "CANCELED" && (
              <Box>
                <Typography>Share Invoice</Typography>
                <Box
                  sx={{
                    height: "39px",
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid #D5D9D9",
                    borderRadius: "4px",
                    minWidth: isPreviewValid ? "470px" : "",
                    color: "#5F6267",
                    mt: "20px",
                    mr: "24px",
                  }}
                >
                  {invoiceLink ? (
                    <>
                      <Box sx={{ m: "3px 7px 0 7px" }}>
                        <DoubleClipIcon />
                      </Box>
                      <Typography
                        sx={{
                          maxWidth: "350px",
                          overflow: "hidden",
                          fontSize: 12,
                        }}
                        noWrap
                      >
                        {invoiceLink}
                      </Typography>
                      <Box color="#C2C0C0" component="span">
                        <StyledButton
                          sx={{ color: "#47A06D" }}
                          label="Copy Link"
                          variant="outland"
                          onClick={handleCopyLink}
                        />
                      </Box>
                    </>
                  ) : (
                    <Skeleton sx={{ mr: 2 }} width={"100%"} height={30} />
                  )}
                </Box>
              </Box>
            )}
        </form>

        <Box
          sx={{
            border: "0.5px solid #D5D9D9",
            borderRadius: "4px",
            width: "526px",
            padding: "24px 13px",
            height: "calc(100vh - 214px)",
            overflow: "scroll",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
              margin: "0 63px",
            }}
          >
            {!order?.type ? (
              <>
                <Typography sx={{ fontSize: "14px", fontWeight: "700" }}>
                  {order?.customId?.customId || order.customId}
                </Typography>
                {order?.createdAt ? (
                  <Typography>
                    {moment(
                      getFormattedDate(order?.createdAt, timeZone)
                    ).format(formDate)}
                  </Typography>
                ) : null}
              </>
            ) : null}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              //mt: "40px",
            }}
          >
            {distributor?.profilePhoto && (
              <Box>
                <Box
                  width="112px"
                  height="112px"
                  marginTop="5px"
                  borderRadius="50%"
                  component="img"
                  src={photoUrl(distributor?.profilePhoto?.fileName)}
                />
              </Box>
            )}
            {isEdit && isThirdPartyOrder && !isDuplicate ? (
              <Typography
                sx={{
                  color: "#343434",
                  fontWeight: 700,
                  fontSize: "32px",
                  textAlign: "center",
                  m: "0 11px",
                }}
              >
                Your 3rd party order has been updated
              </Typography>
            ) : null}
            {showPaymentLinkCheckbox && order.orderStatus !== "CANCELED" && (
              <Box
                sx={{
                  background: "rgba(71,160,109,0.1)",
                  width: "430px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  borderRadius: "24px",
                  padding: "25px 32px 17px 32px",
                  mt: "20px",
                }}
              >
                <Box
                  sx={{
                    borderBottom: "1px solid #47a06d",
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={{ fontSize: "13px", color: "#7a7a7a" }}>
                    Invoice#{order?.customId?.customId || order?.customId}
                  </Typography>
                  {order?.deliveryStatus !== "UNFULFILLED" ? (
                    <Typography sx={{ fontSize: "13px", fontWeight: "500" }}>
                      {!isEdit && !order?.createdAt
                        ? formattedDateToday
                        : `Due ${order?.createdAt ? formattedDate : ""}`}
                    </Typography>
                  ) : null}
                </Box>
                <Typography
                  sx={{ fontSize: "30px", fontWeight: "700", mt: "15px" }}
                >
                  ${grandTotal?.toFixed(2) || order?.balance?.toFixed(2)}
                </Typography>
                <StyledButton
                  sx={{
                    "&:hover": {
                      background: "#47a06d",
                    },
                    margin: "15px 0",
                    width: "129px",
                    height: "34px",
                    background: "#47a06d",
                    color: "#ffffff",
                    border: 0,
                    borderRadius: "2px",
                    fontSize: "13px",
                    fontWeight: 600,
                  }}
                  label="Review and pay"
                  fontSize="13px"
                  onClick={handleInvoiceLink}
                />
              </Box>
            )}
          </Box>
          <Box
            sx={{
              borderRadius: "24px;",
              width: "430px",
              ml: "29px",
              mt: "40px",
              border: "1px solid  #efefef",
            }}
          >
            {cleanText && (
              <Box sx={{ marginLeft: "30px", margin: "25px 30px 0 30px" }}>
                <Typography mt="15px">
                  {cleanText?.split("\n")?.map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
                </Typography>
                <Typography
                  sx={{ border: "1px solid #efefef", mt: "15px" }}
                ></Typography>
              </Box>
            )}
            {(!order?.createdAt || order?.orderStatus === "CANCELED") && (
              <Box
                sx={{
                  mt: "15px",
                  border: "1px solid #efefef",
                  padding: "20px 28px",
                  borderRadius: "24px",
                }}
              >
                <Typography sx={{ fontSize: "20px", fontWeight: 700 }}>
                  Items
                </Typography>

                {order?.products?.map((product, index) => {
                  return (
                    <Box
                      key={product.id}
                      sx={{
                        display: "flex",
                        borderBottom:
                          index === order.products.length - 1
                            ? "none"
                            : "1px solid #efefef",
                        mt: "5px",
                        minHeight: "62px",
                      }}
                    >
                      <Box
                        sx={{
                          position: "relative",
                          width: "56px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Box
                          sx={{
                            position: "absolute",
                            right: "4px",
                            width: "auto",
                            height: "16px",
                            background: "#409a65",
                            borderRadius: "32px",
                            padding: " 0 5px 0 5px",
                            fontWeight: 700,
                            fontSize: "10px",
                            color: " #ffffff",
                            lineHeight: "16px",
                            zIndex: "1",
                          }}
                        >
                          {product?.quantity}
                        </Box>
                        <StyledProductIco
                          src={
                            product?.photos?.length
                              ? photoUrl(product.photos[0].fileName)
                              : product?.photo
                              ? photoUrl(product?.photo?.fileName)
                              : ""
                          }
                          styles={{
                            objectFit: "contain",
                            width: "56px",
                            height: "56px",
                            borderRadius: "4px",
                          }}
                        />
                        {formField.includePrices && (
                          <Box
                            sx={{
                              height: "20px",
                              position: "absolute",
                              display: "flex",
                              justifyContent: "center",
                              width: "auto",
                              background: "#ebebeb",
                              borderRadius: "32px",
                              top: "40px",
                              left: "7px",
                              minWidth: "36px",
                              padding: "0 5px",
                              fontWeight: 600,
                              fontSize: "10px",
                              lineHeight: "20px",
                              color: "#333333",
                            }}
                          >
                            $
                            {!isEdit &&
                              !isDuplicate &&
                              (product?.amount
                                ? product?.amount
                                : product?.wholesalePrice
                              ).toFixed(2)}
                          </Box>
                        )}
                      </Box>
                      <Box sx={{ mt: "10px", ml: "10px" }}>
                        <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
                          {product.name}
                        </Typography>
                        <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                          {product.sku}
                        </Typography>{" "}
                      </Box>
                      {formField.includePrices && (
                        <Box
                          sx={{
                            ml: "auto",
                            mt: "10px",
                            fontWeight: "700",
                            fontSize: "16px",
                          }}
                        >
                          $
                          {(!isEdit && !isDuplicate
                            ? product?.amount
                            : product?.wholesalePrice * product?.quantity
                          )?.toFixed(2)}
                        </Box>
                      )}
                    </Box>
                  );
                })}
                {formField.includePrices && (
                  <Box
                    sx={{
                      mb: "15px",
                      pb: "15px",
                      mt: "15px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: "16px", fontWeight: 700, mb: "5px" }}
                      >
                        Subtotal:
                      </Typography>
                      <Typography sx={{ fontSize: "16px", fontWeight: 700 }}>
                        ${(calculatedTotal || 0).toFixed(2)}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: "16px", fontWeight: 700, mb: "5px" }}
                      >
                        Discount:
                      </Typography>
                      <Typography sx={{ fontSize: "16px", fontWeight: 700 }}>
                        (${(totalDiscount || 0).toFixed(2)})
                      </Typography>
                    </Box>
                    {!isThirdPartyOrder ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{ fontSize: "16px", fontWeight: 700, mb: "5px" }}
                        >
                          Shipping:
                        </Typography>
                        <Typography sx={{ fontSize: "16px", fontWeight: 700 }}>
                          $
                          {deliveryFee === ""
                            ? "Free"
                            : (deliveryFee || 0).toFixed(2)}
                        </Typography>
                      </Box>
                    ) : null}
                  </Box>
                )}

                {formField.includePrices && (
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography
                      sx={{
                        fontSize: "20px",
                        fontWeight: 700,
                        color: "#409a65",
                      }}
                    >
                      Total:
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "20px",
                        fontWeight: 700,
                        color: "#409a65",
                      }}
                    >
                      ${(grandTotal || 0).toFixed(2)}
                    </Typography>
                  </Box>
                )}
              </Box>
            )}
            <Box
              sx={{
                width: "430px",
                padding: "15px 0 17px 0",
              }}
            >
              {!hasCreatedOrderThirdParty && (
                <Box>
                  <Typography sx={{ ml: "29px", fontWeight: "700" }}>
                    Terms:
                  </Typography>
                  <Typography sx={{ ml: "29px", fontWeight: "500" }}>
                    {order?.paymentTermsDuplicate?.name ||
                      order?.paymentTerms?.name}
                  </Typography>
                </Box>
              )}
              <Box>
                <Typography
                  sx={{
                    ml: "29px",
                    fontWeight: "700",
                    mt: !hasCreatedOrderThirdParty && "15px",
                  }}
                >
                  Shipping address
                </Typography>
                <Box sx={{ ml: "29px", fontWeight: "600" }}>
                  <TypographyAddress
                    styles={{
                      color: "#000000",
                      fontSize: "14px",
                      fontWeight: 400,
                    }}
                    address={
                      order?.customer?.shippingAddress?.formatted_address
                    }
                  />
                </Box>
              </Box>

              {type || createOrderType !== "THIRD_PARTY" ? (
                <Box>
                  <Typography
                    sx={{ ml: "29px", fontWeight: "700", mt: "15px" }}
                  >
                    Billing address
                  </Typography>
                  <Box sx={{ ml: "29px", fontWeight: "500" }}>
                    <TypographyAddress
                      styles={{
                        color: "#000000",
                        fontSize: "14px",
                        fontWeight: 400,
                      }}
                      address={setBillingAddress(order)}
                    />
                  </Box>
                </Box>
              ) : (
                <Box>
                  {order.fulfillBy ? (
                    <>
                      <Typography
                        sx={{ ml: "29px", fontWeight: "700", mt: "15px" }}
                      >
                        Requested ship date
                      </Typography>
                      <Box sx={{ ml: "29px", fontWeight: "500" }}>
                        {moment(
                          getFormattedDate(order.fulfillBy, timeZone)
                        ).format("MMM D, YYYY")}
                      </Box>
                    </>
                  ) : null}
                  <Typography
                    sx={{ ml: "29px", fontWeight: "700", mt: "20px" }}
                  >
                    Contact
                  </Typography>
                  <Box
                    sx={{
                      ml: "29px",
                      fontWeight: "500",
                      color: "#000000",
                      fontSize: "14px",
                    }}
                  >
                    <Typography>{defaultContact?.name}</Typography>
                    <Typography>{defaultContact?.email}</Typography>
                    <Typography>{defaultContact?.phone}</Typography>
                  </Box>
                  <Typography
                    sx={{ ml: "29px", fontWeight: "700", mt: "20px" }}
                  >
                    Created by
                  </Typography>
                  <Box sx={{ ml: "29px", fontWeight: "500" }}>
                    {rep?.name || distributor?.name}
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              width: "430px",
              ml: "29px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              mt: "15px",
            }}
          >
            <Box
              sx={{
                fontSize: "10px",
              }}
            >
              <Typography sx={{ fontSize: "10px" }}>
                {/* {order?.distributor?.name} */}
                {distributorName}
              </Typography>
              {distributor?.phone && (
                <>
                  {distributor?.phone}
                  {distributor?.baseUser?.email && " | "}
                </>
              )}
              {distributor?.baseUser?.email}
            </Box>
            <Typography
              sx={{ border: "1px solid #efefef", m: "20px 0", width: "100%" }}
            ></Typography>

            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ fontSize: "10px", mr: "5px" }}>
                {" "}
                Powered by
              </Typography>
              <SimplyDepoFooterIcon />
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: "15px 40px 18px" }}>
        <StyledButton
          sx={{ height: "34px" }}
          label="Cancel"
          fontSize="13px"
          color="cancel"
          onClick={handleClose}
        />
        <StyledButton
          disabled={isDisabled}
          sx={{ height: "34px" }}
          fontSize="15px"
          type="submit"
          form="view-order-email-form"
          label={loading ? "Loading..." : "Send Email"}
          variant="contained"
        />
      </DialogActions>
    </Dialog>
  );
};

ViewOrderEmailDialog.propTypes = {
  isOpen: bool,
  handleClose: func,
  order: object,
  handleSendEmail: func,
  isEdit: bool,
  isDuplicate: bool,
  totalDiscount: any,
  deliveryFee: string,
  grandTotal: string,
  calculatedTotal: string,
  customer: object,
  loading: bool,
  selectedContactEmail: any,
};
ViewOrderEmailDialog.defaultProps = {};

export default ViewOrderEmailDialog;
