import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "30px",
    gap: "3px",
  },

  title: {
    fontSize: "13px",
    color: "#AAA",
    fontWeight: "500",
    whiteSpace: "nowrap",
  },

  label: {
    fontSize: "13px",
    fontWeight: "500",
    color: "#5F6267",
    whiteSpace: "nowrap",
  },
}));

export default useStyles;
