import {} from "react";
import { func, bool } from "prop-types";
import { Box, Button } from "@mui/material";

export const DialogFooterComponent = ({
  isConfirmed,
  onSetConfirm,
  onClose,
  loading,
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "114px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        px: 3,
      }}
    >
      <Button
        sx={{
          height: "40px",
          fontSize: 16,
          borderRadius: "4px",
          bgcolor: "#409A65",
        }}
        fullWidth
        disabled={loading}
        variant="contained"
        onClick={isConfirmed ? onClose : onSetConfirm}
      >
        {loading ? "Loading..." : isConfirmed ? "Ok" : "Activate"}
      </Button>
    </Box>
  );
};

DialogFooterComponent.propTypes = {
  isConfirmed: bool,
  onSetConfirm: func,
  onClose: func,
  loading: bool,
};
DialogFooterComponent.defaultProps = {};
