import { object, func, array, bool } from "prop-types";

import RoleItem from "../RoleItem";
import { TableHeader } from "../../../ProfileTab/components";
import { PlusIcon } from "../../../../../../../components/Icons";

import { IconButton, Stack, Typography } from "@mui/material";

export const RolesTab = ({
  handleAddRole,
  fields,
  errors,
  control,
  formField,
  isLoading,
  onEditRole,
  setIsTyping,
  handleDeleteRole,
  checkForUniqNames,
  handleInputChange,
  handleChangeDefault,
}) => {
  return (
    <Stack>
      <TableHeader
        title="Contact Types"
        addElement={
          <IconButton
            onClick={handleAddRole}
            disabled={isLoading || !!Object.keys(errors).length}
          >
            <PlusIcon stroke="#eeeeee" fill="#47A06D" width="20" height="20" />
          </IconButton>
        }
        isEdit
      />

      <Stack gap="18px" py="17px">
        {fields.length > 0 ? (
          fields.map((role, i) => (
            <RoleItem
              key={role.key}
              title={role.name}
              isActive={formField?.contactRoles[i]?.defaultRole || false}
              role={formField.contactRoles[i]}
              control={control}
              setIsTyping={setIsTyping}
              index={i}
              defaultRole={role}
              handleInputChange={handleInputChange}
              checkForUniqNames={checkForUniqNames}
              onEditRole={onEditRole}
              handleChangeDefault={handleChangeDefault}
              handleRemoveRole={() => handleDeleteRole(role, i)}
            />
          ))
        ) : (
          <Stack width="100%">
            <Typography
              fontWeight="300"
              fontSize="16px"
              textAlign="center"
              width="100%"
              color="#6A6A6A"
              paddingTop="20px"
              paddingBottom="20px"
            >
              No roles created yet
            </Typography>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

RolesTab.propTypes = {
  fields: array,
  errors: object,
  control: object,
  isLoading: bool,
  onEditRole: func,
  setIsTyping: func,
  formField: object,
  handleAddRole: func,
  handleDeleteRole: func,
  checkForUniqNames: func,
  handleInputChange: func,
  handleChangeDefault: func,
};
