import React from "react";

export const ClipIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.202"
      height="19.778"
      viewBox="0 0 21.202 19.778"
    >
      <path
        id="icons8-attach"
        d="M21.666,6a3.509,3.509,0,0,0-2.492,1.025L9.158,17.057a.532.532,0,0,0-.095.095.233.233,0,0,0-.032.032.472.472,0,0,0-.063.063v.016a2.517,2.517,0,0,0,3.659,3.454l7.618-7.634a.5.5,0,1,0-.71-.71l-7.618,7.618a1.515,1.515,0,0,1-2.145,0,1.491,1.491,0,0,1-.047-2.066.479.479,0,0,0,.047-.063.239.239,0,0,0,.032-.032l.047-.032v-.016L19.9,7.751A2.509,2.509,0,1,1,23.449,11.3L11.02,23.729a3.524,3.524,0,0,1-4.984-4.984l9.937-9.937a.5.5,0,1,0-.71-.71L5.326,18.035a4.534,4.534,0,0,0,6.4,6.42L24.174,12.025A3.525,3.525,0,0,0,21.666,6Z"
        transform="translate(-4.001 -6)"
        fill="#9c9c94"
      />
    </svg>
  );
};
