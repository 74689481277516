import { useMemo } from "react";
import { string, object, oneOfType, instanceOf } from "prop-types";
import { Box, Typography } from "@mui/material";
import { CheckmarkIcon, CrossBigIcon, SandClockIcon } from "../Icons";
import { normalizeUppercaseString } from "../../helpers/helpers";

export const Statuses = ({
  text,
  textStyle,
  titleStyle = {},
  sandIconColor,
  rejectedIconProps = {},
}) => {
  const preparedText = useMemo(
    () => (typeof text === "string" ? text.toLowerCase() : ""),
    [text]
  );

  const renderContent = useMemo(() => {
    switch (preparedText) {
      case "accepted":
      case "approved":
        return (
          <>
            <CheckmarkIcon
              width="11.18"
              height="8"
              stroke={textStyle?.color ? textStyle?.color : "#53B986"}
            />
            <CheckmarkIcon
              width="11.18"
              height="8"
              stroke={textStyle?.color ? textStyle?.color : "#53B986"}
              style={{ marginLeft: "-7px" }}
            />
            <Typography
              sx={{
                ml: "3px",
                fontSize: 10,
                color: "#53B986",
                ...textStyle,
                ...titleStyle,
              }}
            >
              {normalizeUppercaseString(text)}
            </Typography>
          </>
        );
      case "pending":
        return (
          <>
            <SandClockIcon fill={sandIconColor} />
            <Typography
              sx={{
                ml: "3px",
                fontSize: 10,
                color: "#959595",
                ...textStyle,
                ...titleStyle,
              }}
            >
              {normalizeUppercaseString(text)}
            </Typography>
          </>
        );
      case "rejected":
      case "cancelled":
        return (
          <>
            <CrossBigIcon color="#FF877C" size="8" {...rejectedIconProps} />
            <Typography
              sx={{
                ml: "3px",
                fontSize: 10,
                color: "#FF877C",
                ...textStyle,
                ...titleStyle,
              }}
            >
              {normalizeUppercaseString(text)}
            </Typography>
          </>
        );

      default:
        return <></>;
    }
  }, [
    preparedText,
    sandIconColor,
    text,
    textStyle,
    titleStyle,
    rejectedIconProps,
  ]);
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>{renderContent}</Box>
  );
};

Statuses.propTypes = {
  text: oneOfType([string, instanceOf(null)]),
  textStyle: object,
  titleStyle: object,
  sandIconColor: string,
  rejectedIconProps: object,
};
