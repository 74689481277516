import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ShevronIcon } from "../../../../../../components/Icons";
import { object, array, number, func } from "prop-types";
import StyledButton from "../../../../../../components/StyledButton";

const ApplyMSDSummary = ({
  item,
  checkedDiscounts,
  handleClear,
  discountCounts,
}) => {
  const selectedCount = checkedDiscounts.filter(
    (el) => (el?.manufacturer?.name || "Uncategorized") === item.name
  ).length;

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <ShevronIcon className="my-ico" />
        <Typography
          sx={{
            fontSize: "22px",
            fontWeight: 600,
            color: "#000000",
            ml: "32px",
          }}
        >
          {item.name}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: "15px" }}>
        {!!selectedCount && (
          <StyledButton
            color="error"
            fontSize="17px"
            label="Clear"
            onClick={(e) => {
              e.stopPropagation();
              handleClear(item);
            }}
          />
        )}
        <Typography
          sx={{
            fontSize: "17px",
            fontWeight: 400,
            color: "#000000",
          }}
        >
          {selectedCount
            ? `${selectedCount} of ${discountCounts} selected`
            : `${discountCounts} available`}
        </Typography>
      </Box>
    </Box>
  );
};

ApplyMSDSummary.propTypes = {
  item: object,
  checkedDiscounts: array,
  discountCounts: number,
  handleClear: func,
};
ApplyMSDSummary.defaultProps = {
  item: {},
  checkedDiscounts: [],
  discountCounts: 0,
};

export default ApplyMSDSummary;
