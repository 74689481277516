import { useEffect, useState } from "react";
import PropTypes, { bool, func, number, string } from "prop-types";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  ToggleButton,
  Typography,
  Box,
} from "@mui/material";
import { CrossIcon } from "../../../../components/Icons";
import StyledButton from "../../../../components/StyledButton";
import { PriceFormat } from "../../../../components/TextFields/TextFields";
import useStyles from "./styles";

const TotalDiscountPopup = ({
  isOpen,
  handleClose,
  handleApply,
  amount,
  type,
  orderAmountWithoutCustomerDiscount,
}) => {
  const [discountType, setDiscountType] = useState(type || "PERCENTAGE");
  const [discountAmount, setDiscountAmount] = useState(amount || "");
  const classes = useStyles();

  useEffect(() => {
    setDiscountAmount(amount);
    setDiscountType(type);
  }, [type, amount]);

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle className={classes.dialogTitle}>
        <Typography className={classes.title}>Total order discount</Typography>
        <IconButton onClick={handleClose}>
          <CrossIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContentWrapper}>
        <Box className={classes.discountBy}>
          <Typography>
            Discount by {discountAmount}
            {discountType === "PERCENTAGE" ? "%" : "$"}
          </Typography>
        </Box>
        <Box className={classes.dialogContent}>
          <Box>
            <Typography fontSize="12px" color="#363531" mb="10px">
              Discount Amount
            </Typography>

            <PriceFormat
              type={discountType}
              formSx={{ maxWidth: "132px" }}
              endIcon={
                <span style={{ color: "#D4D4D4" }}>
                  {discountType === "PERCENTAGE" ? "%" : "$"}
                </span>
              }
              value={discountAmount || ""}
              error={
                discountType === "PERCENTAGE"
                  ? !(discountAmount >= 0 && discountAmount <= 100)
                  : false
              }
              onValueChange={(values) => {
                const { floatValue, value } = values ?? {};
                const isAbsolute = discountType === "ABSOLUTE";
                if (floatValue < 0) return;
                if (
                  floatValue >= orderAmountWithoutCustomerDiscount &&
                  isAbsolute
                )
                  return setDiscountAmount(orderAmountWithoutCustomerDiscount);

                setDiscountAmount(isAbsolute ? floatValue?.toFixed(2) : value);
              }}
            />
          </Box>
          <Box>
            <Typography fontSize="12px" color="#363531" mt="22px" mb="10px">
              Discount Type
            </Typography>
            <Box display="flex" justifyContent="space-between">
              <Box display="flex" gap="21px">
                <ToggleButton
                  value="PERCENTAGE"
                  selected={discountType === "PERCENTAGE"}
                  className={classes.toggleButton}
                  onChange={(e) => {
                    setDiscountAmount(0);
                    setDiscountType(e.target.value);
                  }}
                >
                  Percent
                </ToggleButton>
                <ToggleButton
                  value="ABSOLUTE"
                  selected={discountType === "ABSOLUTE"}
                  color="primary"
                  className={classes.toggleButton}
                  onChange={(e) => {
                    setDiscountAmount(0);
                    setDiscountType(e.target.value);
                  }}
                >
                  Total
                </ToggleButton>
              </Box>
              <StyledButton
                disabled={
                  discountType === "PERCENTAGE"
                    ? !(discountAmount >= 0 && discountAmount <= 100)
                    : false
                }
                label="Apply"
                fontSize="15px"
                variant="contained"
                sx={{ width: "85px" }}
                onClick={() =>
                  handleApply(parseFloat(discountAmount), discountType)
                }
              />
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

TotalDiscountPopup.propTypes = {
  isOpen: bool,
  handleClose: func,
  handleApply: func,
  totalAmount: number,
  amount: PropTypes.oneOfType([number, string]),
  type: string,
  orderAmountWithoutCustomerDiscount: number,
};

export default TotalDiscountPopup;
