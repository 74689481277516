import React from "react";
/* eslint-disable react/prop-types */
export const ArchiveIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10.271"
      height="8.586"
      viewBox="0 0 10.271 8.586"
      {...props}
    >
      <g
        id="Icon_feather-archive"
        data-name="Icon feather-archive"
        transform="translate(-1 -4)"
      >
        <path
          id="Path_2377"
          data-name="Path 2377"
          d="M12.086,12v5.479H4.5V12"
          transform="translate(-2.157 -5.393)"
          fill="none"
          stroke="#707070"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_2378"
          data-name="Path 2378"
          d="M1.5,4.5h9.271V6.607H1.5Z"
          transform="translate(0)"
          fill="none"
          stroke="#707070"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_2379"
          data-name="Path 2379"
          d="M15,18h1.686"
          transform="translate(-9.707 -9.707)"
          fill="none"
          stroke="#707070"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};
