import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  filterWrapper: {
    backgroundColor: "#ffffff",
    display: "flex",
    border: "1px solid #d5d9d9",
    borderRadius: "4px 4px 0 0",
    borderBottom: "none",
    alignItems: "center",
    margin: "0 26px",
    height: "58px",
  },

  filterChip: {
    borderRadius: "4px",
    border: "0.7px solid #d5d9d9",
    backgroundColor: "#FAFAFB",
    height: "28px",
  },
}));

export default useStyles;
