import PropTypes, { bool, func, string, object } from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";

const ConfirmDialog = ({ isOpen, text, onCancel, onAccept }) => {
  return (
    <Dialog open={isOpen} scroll="body">
      <DialogContent
        sx={{
          maxWidth: "460px",
          width: "380px",
          letterSpacing: 0,
          fontSize: "15px",
          fontWeight: 400,
          color: "#707070",
          textAlign: "center",
          whiteSpace: "pre-line",
        }}
      >
        {text}
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "space-between",
          px: "30px",
          paddingTop: 0,
          paddingBottom: "16px",
        }}
      >
        <Button
          onClick={onCancel}
          color="groundLighter"
          sx={{
            width: "98px",
            height: "32px",
            boxShadow: "none",
          }}
          variant="outlined"
        >
          <Typography
            sx={{
              fontSize: "13px",
              color: "#6A6A6A",
            }}
          >
            Cancel
          </Typography>
        </Button>
        <Button
          sx={{
            width: "98px",
            height: "32px",
            boxShadow: "none",
          }}
          onClick={onAccept}
          variant="contained"
        >
          <Typography
            sx={{
              fontSize: "13px",
            }}
          >
            Confirm
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  isOpen: bool,
  text: PropTypes.oneOfType([string, object]),
  onCancel: func,
  onAccept: func,
};

ConfirmDialog.defaultProps = {
  isOpen: false,
  text: "Are you sure?",
};

export default ConfirmDialog;
