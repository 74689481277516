import {
  CircularProgress,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";

import { TagsIcon } from "../../../../components/Icons";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import {
  deleteTagAction,
  getTagsAction,
  mergeTagsAction,
} from "../../../../redux/actions/tags.js";
import {
  customerTagsCountSelector,
  customerTagsListSelector,
} from "../../../../redux/selectors/tags.js";
import { useNavigate } from "react-router-dom";

import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { SCROLL_LIMIT, TAGS_TYPE } from "../../../../utils/constants.js";
import TagItem from "../../../../components/TagComponents/TagItem/TagItem";
import TableHeader from "../../../../components/TableHeader";
import { handleConfirmAction } from "../../../DiscountsPage/DiscountsPage.helpers";
import { setConfirmIsOpenAction } from "../../../../redux/actions/confirmDialogs";
import MergeTagDialog from "../../../../components/TagComponents/MergeTagDialog/MergeTagDialog";
import { InfiniteScrollWrapper } from "components";

const selector = createSelector(
  customerTagsListSelector,
  customerTagsCountSelector,
  (tagsList, tagsCount) => ({
    tagsList,
    tagsCount,
  })
);

const CustomerTagsTab = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { tagsList, tagsCount } = useSelector(selector);

  const [limitTags, setLimitTags] = useState(2 * SCROLL_LIMIT);
  const [mergeDialogState, setMergeDialogState] = useState({
    open: false,
    tag: null,
    loading: false,
  });

  const handleDelete = useCallback(
    (tag) => {
      dispatch(
        handleConfirmAction({
          title: "Delete customer tag?",
          text: (
            <Typography
              fontWeight="400"
              fontSize="13px"
              color="#363531"
              whiteSpace="pre-line"
              marginTop="4px"
              marginBottom="8px"
            >
              {"Are you sure you want to delete "}
              <span style={{ color: "#47A06D" }}>{tag?.tag}</span>?
            </Typography>
          ),
          confirmColor: "confirmDelete",
          onConfirm: () => {
            dispatch(deleteTagAction(tag?.id, TAGS_TYPE.CUSTOMER));
            dispatch(setConfirmIsOpenAction(false));
          },
        })
      );
    },
    [dispatch]
  );

  const handleRename = useCallback(
    (tag) => {
      navigate(`tags/${tag.id}`, { state: tag });
    },
    [navigate]
  );

  const handleMerge = (tag) => {
    setMergeDialogState((prev) => ({ ...prev, open: true, tag }));
  };

  const handleFetchGroups = useCallback(() => {
    dispatch(
      getTagsAction({
        limit: limitTags,
      })
    );
    setLimitTags(limitTags + SCROLL_LIMIT);
  }, [dispatch, limitTags]);

  const onMerge = (mergeIds) => {
    dispatch(
      mergeTagsAction(
        TAGS_TYPE.CUSTOMER,
        mergeDialogState.tag?.id,
        mergeIds,
        setMergeDialogState,
        "customerCount"
      )
    );
  };

  return (
    <Grid sx={{ marginTop: "64px" }} container justifyContent="center">
      <MergeTagDialog
        isOpen={mergeDialogState.open}
        onClose={() =>
          setMergeDialogState((prev) => ({ ...prev, tag: null, open: false }))
        }
        tag={mergeDialogState.tag}
        tagsList={tagsList.filter((tag) => tag.id !== mergeDialogState.tag?.id)}
        loading={mergeDialogState.loading}
        {...{ onMerge }}
      />
      <Grid
        sx={{
          width: "980px",
        }}
        item
      >
        <Paper elevation={0}>
          <Box
            sx={{
              p: "24px",
              borderBottom: "1px solid #D4D4D4",
            }}
          >
            <Typography
              sx={{
                variant: "h6",
                fontSize: "30px",
                fontWeight: "400",
                color: "#707070",
              }}
            >
              Customer Tags
            </Typography>
          </Box>
          <Box
            sx={{
              p: "24px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Box>
                <TagsIcon />
              </Box>
              <Box
                sx={{
                  ml: "18px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: 400,
                    color: "#707070",
                  }}
                >
                  Manage tags
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 400,
                    color: "#6A6A6A",
                  }}
                >
                  Rename or delete tags. Organize customer tags by your own
                  needs.
                </Typography>
              </Box>
            </Box>
            <Box sx={{ marginTop: "24px" }}>
              <TableHeader sx={{ borderRadius: "4px 4px 0 0" }}>
                <Grid container>
                  <Grid item xs={13.6}>
                    <Typography
                      sx={{
                        my: "6px",
                        ml: "48px",
                        fontSize: "12px",
                        fontWeight: 400,
                        color: "#6A6A6A",
                        textTransform: "uppercase",
                        overflow: "auto",
                      }}
                    >
                      Customer Tags
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{
                        my: "6px",
                        fontSize: "12px",
                        fontWeight: 400,
                        color: "#6A6A6A",
                        textTransform: "uppercase",
                        overflow: "auto",
                      }}
                    >
                      Used
                    </Typography>
                  </Grid>
                </Grid>
              </TableHeader>
              <Stack
                sx={{
                  maxHeight: "288px",
                  overflow: "hidden",
                  borderWidth: "0px 1px 1px 1px",
                  borderStyle: "solid",
                  borderColor: "#D5D9D9",
                  borderRadius: "0 0 4px 4px",
                  background: !tagsList.length && "white",
                }}
              >
                {tagsCount > 0 ? (
                  <InfiniteScrollWrapper
                    id="customer-tags-scroll"
                    dataLength={tagsList.length}
                    next={handleFetchGroups}
                    loader={
                      <CircularProgress
                        sx={{ ml: "50%", mt: "2px" }}
                        size="30px"
                      />
                    }
                    style={{ overflowX: "hidden" }}
                    hasMore={tagsList.length < tagsCount}
                    maxHeight="287px"
                  >
                    {tagsList.map((tag) => (
                      <TagItem
                        key={tag.id}
                        {...{
                          tag,
                          tagsList,
                          handleDelete,
                          handleRename,
                          handleMerge,
                        }}
                        type="customer"
                      />
                    ))}
                  </InfiniteScrollWrapper>
                ) : (
                  <Typography
                    fontWeight="300"
                    fontSize="16px"
                    textAlign="center"
                    width="100%"
                    color="#6A6A6A"
                    paddingTop="20px"
                    paddingBottom="20px"
                  >
                    No tags found
                  </Typography>
                )}
              </Stack>
            </Box>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default CustomerTagsTab;
