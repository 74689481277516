import { makeStyles } from "@mui/styles";

export const cl = {
  grid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  gridContainer: {
    display: "flex",
    textDecoration: "none",
    alignItems: "center",
    overflow: "hidden",
  },
  gridStart: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    height: "100%",
    position: "relative",
  },
  typography: {
    fontSize: 13,
    fontWeight: 400,
    color: "#1C1C19",
  },
  orderText: {
    fontSize: 13,
    color: "#1C1C19",
    fontWeight: 600,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  orderName: {
    width: "100%",
    svg: {
      transition: "all 0.3s",
      opacity: 0,
    },

    "&:hover": {
      svg: {
        transition: "all 0.3s",
        opacity: 1,
      },
    },
  },
  paymentInformation: {
    position: "absolute",
    transform: "translateX(-100%)",
    left: -5,
    width: "20px",
  },
};

export const useStyles = makeStyles(() => ({
  customTooltip: {
    backgroundColor: "#ffffff",
    border: "0.5px solid #D5D9D9",
    padding: "14.8px 15.8px",
    boxSizing: "border-box",
    borderRadius: "8px",
    textAlign: "center",
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
    color: "#1C1C19",
    marginBottom: "10px !important",
  },
  customArrow: {
    width: "22px",
    height: "9px",
    zIndex: 1,
    color: "#fff",
    backgroundColor: "#ffffff",
    clipPath: "polygon(100% 7%, 0% 7%, 50% 100%)",
    position: "absolute",
  },
}));
