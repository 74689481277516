import { useEffect, useMemo, useRef, useState } from "react";

import { createSelector } from "reselect";
import { useSelector, useDispatch } from "react-redux";
import { object, func, string } from "prop-types";

import { useDebounce } from "helpers/hooks";
import { getCategoriesListAction } from "redux/actions/categories";
import {
  categoriesListSelector,
  isLoadingCategoriesSelector,
} from "redux/selectors/categories";

import { useProductCategories } from "./useProductCategories";

import { cl } from "./components/styles";

import { FieldList } from "./components/FieldList";
import { ParentCategory } from "./components/ParentCategory";
import { getItemWithId } from "Pages/CatalogPage/CatalogNewProductPage/ContentTabs/RequiredInfoTab/components/CategoriesComponent/helpers";

import ClearIcon from "@mui/icons-material/Clear";
import { Box, Chip, FormControl, Grid, TextField } from "@mui/material";

const selector = createSelector(
  isLoadingCategoriesSelector,
  categoriesListSelector,
  (isLoadingCategories, categoriesList) => ({
    isLoadingCategories,
    categoriesList,
  })
);

export const CategoriesInput = ({ control, setValue, fieldName }) => {
  const { categoriesList, isLoadingCategories } = useSelector(selector);

  const { formField, handleDeleteTag, handleAddCategory, isCheckedCategory } =
    useProductCategories({
      control,
      setValue,
      categoriesList,
    });

  const inputRef = useRef(null);
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [showCategories, setShowCategories] = useState([]);

  const searchInputDebounced = useDebounce(searchInput, 500);

  useEffect(() => {
    dispatch(getCategoriesListAction({ search: searchInputDebounced }));
  }, [dispatch, searchInputDebounced]);

  useEffect(() => {
    setShowCategories(categoriesList);
  }, [categoriesList]);

  const closeOptions = (event) => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  const deleteSelectedCategory = (e, id) => {
    e.stopPropagation();
    e.preventDefault();
    handleDeleteTag(fieldName, id);
  };

  const handleOpenList = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setOpen(true);
    setShowCategories(categoriesList);
  };

  const parentCategory = useMemo(() => {
    const hasParent = getItemWithId(
      categoriesList,
      showCategories.find((category) => category?.parentCategoryId)
        ?.parentCategoryId
    );

    return (
      !!hasParent && (
        <ParentCategory
          hasParent={hasParent}
          setShowCategories={setShowCategories}
          categoriesList={categoriesList}
        />
      )
    );
  }, [categoriesList, showCategories]);

  return (
    <Grid xs={12} item container flexDirection="column">
      <FormControl fullWidth>
        <TextField
          ref={inputRef}
          size="small"
          placeholder="Search Product Categories"
          inputProps={{ maxLength: 50, readOnly: true }}
          onClick={(e) => handleOpenList(e)}
          InputProps={{
            sx: {
              ...cl.categoryInput,
              "& input": {
                display: formField.productCategories.length ? "none" : "block",
              },
            },
            startAdornment: (
              <>
                {!!formField.productCategories.length && (
                  <Box sx={cl.categoryChipWrapper}>
                    {formField.productCategories.map(({ name, id }) => (
                      <Chip
                        sx={cl.categoryChip}
                        key={id}
                        label={name}
                        deleteIcon={<ClearIcon />}
                        size="small"
                        onDelete={(e) => deleteSelectedCategory(e, id)}
                      />
                    ))}
                  </Box>
                )}
              </>
            ),
          }}
        />
      </FormControl>
      {open && (
        <FieldList
          parentCategory={parentCategory}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          showCategories={showCategories}
          setShowCategories={setShowCategories}
          categoriesList={categoriesList}
          onClose={closeOptions}
          handleAddCategory={handleAddCategory}
          isCheckedCategory={isCheckedCategory}
          fieldName={fieldName}
          loading={isLoadingCategories}
        />
      )}
    </Grid>
  );
};

CategoriesInput.propTypes = {
  control: object,
  setValue: func,
  fieldName: string,
};
