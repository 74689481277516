import { oneOf, func } from "prop-types";
import { Box, Typography } from "@mui/material";
import useStyles from "./styles";
import { useStoreWidget } from "./components/StoreLink/StoreLink.hooks";
import OrderDirectSetup from "./components/OrderDirectSetup/OrderDirectSetup";
import OrderDirectCompletedSetup from "./components/OrderDirectCompletedSetup/OrderDirectCompletedSetup";
import { HeaderBlock, StorefrontAccessBlock } from "./components";
import { useStorefront } from "./useStorefront";
import { useSelector } from "react-redux";
import { ORDER_DIRECT_TABS } from "Pages/SettingsPage/SettingsPage.constants";
import { BREAKPOINTS } from "helpers/useBreakpoint";
import { useEffect } from "react";

const OrderDirectTab = ({ currentTab, setCurrentTab, breakpoint }) => {
  const classes = useStyles();

  const { widgetState, updateWidgetState, showSetup } = useStoreWidget();

  const {
    storeName,
    allowStorePreview,
    showRetailPrice,
    hideRequestPaymentTerms,
    approvalAccess,
    allowStoreCreditCardPayments,
    defaultPaymentTerms,
  } = useSelector(({ auth }) => auth?.currentUser);

  const [loadingStorefrontPreview, handleSwitchStorefrontPreview] =
    useStorefront();

  const [
    loadingSuggestedRetailPrice,
    handleSwitchStorefrontSuggestedRetailPrice,
  ] = useStorefront();

  const [
    loadingHideRequestPaymentTermsForCustomer,
    handleSwitchHideRequestPaymentTermsForCustomer,
  ] = useStorefront();

  const [loadingApprovalAccess, handleSwitchApprovalAccess] = useStorefront();

  const [
    loadingAllowStoreCreditCardPayments,
    handleSwitchAllowStoreCreditCardPayments,
  ] = useStorefront();

  const isAdvancePaymentType = defaultPaymentTerms?.type === "ADVANCE_PAYMENT";

  useEffect(() => {
    if (breakpoint) {
      setCurrentTab(ORDER_DIRECT_TABS[0].value);
    }
  }, [breakpoint, setCurrentTab]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: {
          lg: "column",
          xl: "row",
        },
        justifyContent: "center",
        alignItems: "flex-start",
        gap: 3.25,
      }}
    >
      <Box
        sx={{
          backgroundColor: "#ffffff",
          border: "1px solid #d5d9d9",
          borderRadius: "8px",
          width: "1126px",
          minHeight: "calc(100vh - 210px)",
        }}
      >
        <Box>
          {"xl" === breakpoint ? (
            <Box className={classes.header}>
              <Typography>Order Direct</Typography>
            </Box>
          ) : (
            <HeaderBlock
              {...{ currentTab, setCurrentTab }}
              tabs={ORDER_DIRECT_TABS}
            />
          )}
          <Box className={classes.body} mb="18px">
            {showSetup ? (
              <OrderDirectSetup
                widgetState={widgetState}
                updateWidgetState={updateWidgetState}
              />
            ) : (
              <OrderDirectCompletedSetup
                {...{
                  currentTab,
                  storeName,
                  widgetState,
                  updateWidgetState,
                  allowStorePreview,
                  loadingStorefrontPreview,
                  handleSwitchStorefrontPreview,
                  showRetailPrice,
                  loadingSuggestedRetailPrice,
                  handleSwitchStorefrontSuggestedRetailPrice,
                  hideRequestPaymentTerms,
                  loadingHideRequestPaymentTermsForCustomer,
                  handleSwitchHideRequestPaymentTermsForCustomer,
                  approvalAccess,
                  loadingApprovalAccess,
                  handleSwitchApprovalAccess,
                  allowStoreCreditCardPayments,
                  loadingAllowStoreCreditCardPayments,
                  handleSwitchAllowStoreCreditCardPayments,
                  isAdvancePaymentType,
                }}
              />
            )}
          </Box>
        </Box>
      </Box>
      {showSetup
        ? null
        : "xl" === breakpoint && (
            <StorefrontAccessBlock
              {...{
                allowStorePreview,
                loadingStorefrontPreview,
                handleSwitchStorefrontPreview,
                showRetailPrice,
                loadingSuggestedRetailPrice,
                handleSwitchStorefrontSuggestedRetailPrice,
                hideRequestPaymentTerms,
                loadingHideRequestPaymentTermsForCustomer,
                handleSwitchHideRequestPaymentTermsForCustomer,
                approvalAccess,
                loadingApprovalAccess,
                handleSwitchApprovalAccess,
                allowStoreCreditCardPayments,
                loadingAllowStoreCreditCardPayments,
                handleSwitchAllowStoreCreditCardPayments,
                isAdvancePaymentType,
              }}
            />
          )}
    </Box>
  );
};

OrderDirectTab.propTypes = {
  currentTab: oneOf(ORDER_DIRECT_TABS.map((tab) => tab.value)),
  setCurrentTab: func,
  breakpoint: oneOf(BREAKPOINTS),
};
OrderDirectTab.defaultProps = {
  currentTab: ORDER_DIRECT_TABS[0].value,
  setCurrentTab: () => {},
  breakpoint: BREAKPOINTS[0],
};

export default OrderDirectTab;
