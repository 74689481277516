/* eslint-disable react/prop-types */
import { Box } from "@mui/material";
import { object, string } from "prop-types";
import {
  ArrowNextIcon,
  ArrowPrevIcon,
} from "../../../../../../components/Icons";

const NextArrow = ({
  style,
  height,
  width,
  color,
  top,
  currentSlide,
  ...props
}) => {
  return (
    <Box
      style={{
        ...style,
        top: top || "30%",
      }}
      {...props}
    >
      <ArrowNextIcon color={color} width={width} height={height} />
    </Box>
  );
};
NextArrow.propTypes = {
  style: object,
  height: string,
  width: string,
  color: string,
  top: string,
};

const PrevArrow = ({
  style,
  height,
  width,
  color,
  top,
  currentSlide,
  ...props
}) => {
  return (
    <Box style={{ ...style, top: top || "30%" }} {...props}>
      <ArrowPrevIcon color={color} width={width} height={height} />
    </Box>
  );
};
PrevArrow.propTypes = {
  style: object,
  height: string,
  width: string,
  color: string,
  top: string,
};

export { NextArrow, PrevArrow };
