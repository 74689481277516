import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { object, array } from "prop-types";
import ChatMessage from "../ChatMessage/ChatMessage";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import moment from "moment";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getFirestore,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import {
  ref,
  getDownloadURL,
  uploadBytesResumable,
  getStorage,
  deleteObject,
} from "firebase/storage";

import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import {
  StyledTextField,
  StyledButton,
  EmojiPicker,
  UploadFile,
} from "components";
import { ClipIcon, ClosePinIcon, CrossIcon, SmileIcon } from "components/Icons";

import useStyles from "./styles";
import { getRepsArrayService } from "services/reps";
import { error } from "utils/notifications";
import {
  openConfirmDialogAction,
  setConfirmIsOpenAction,
} from "redux/actions/confirmDialogs";
import MessageMenu from "../components/MessageMenu/MessageMenu";
import {
  confirmDeleteChatAction,
  handleDeleteChatFiles,
  handleDeleteChatMessages,
} from "firebase/Chat/helpers";

import ImageFullscreen from "../components/ImageFullscreen/ImageFullscreen";
import { MAX_UPLOAD_FILE_SIZE_CHAT_MB } from "utils/constants";
import { checkFileSize } from "helpers/helpers";

const ChatRoom = () => {
  const [messageInput, setMessageInput] = useState("");
  const [messageMenuOpen, setMessageMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [clickedMessage, setClickedMessage] = useState(null);
  const [isEditing, setIsEditing] = useState(null);
  const [emojiPickerAnchor, setEmojiPickerAnchor] = useState(null);
  const [chatPartner, setChatPartner] = useState({});
  const [repLoading, setRepLoading] = useState(false);
  const [imageFullscreenOpen, setImageFullscreenOpen] = useState(false);
  const [fullscreenImage, setFullscreenImage] = useState("");

  const [uploadFile, setUploadFile] = useState(null);
  const [fileBlob, setFileBlob] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [attachment, setAttachment] = useState("");

  const messagesEndRef = useRef(null);
  const lastOnlineRef = useRef(null);
  const messageRefs = useRef([]);

  const { roomId } = useParams();

  const stateLocal = useLocation();

  const msgData = localStorage.getItem("msgData");
  const preparedData = JSON.parse(msgData);

  const state = useMemo(() => {
    return stateLocal?.state || preparedData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomId]);

  const currentUser = useSelector(({ auth }) => auth.currentUser);

  const isGroupChat = useMemo(
    () => !!(!state?.representativeId && state?.representativesIds),
    [state?.representativeId, state?.representativesIds]
  );

  const setMessagesRefLink = useMemo(() => {
    if (!isGroupChat && state?.collectionId && roomId)
      return `representatives/${state.collectionId}/rooms/${roomId}/messages`;
    if (isGroupChat && roomId) return `group-chats/${roomId}/messages`;
  }, [isGroupChat, roomId, state?.collectionId]);

  const messagesRef = collection(getFirestore(), setMessagesRefLink);

  const setCurrentRoomRefLink = useMemo(() => {
    if (!isGroupChat && state?.collectionId && roomId)
      return `representatives/${state.collectionId}/rooms/${roomId}`;
    if (isGroupChat && roomId) return `group-chats/${roomId}`;
  }, [isGroupChat, roomId, state?.collectionId]);

  const currentRoomRef = doc(getFirestore(), setCurrentRoomRefLink);

  const currentRoomStatusRef = doc(
    getFirestore(),
    `online-status/${state.statusDocId || state.representativeId}`
  );

  const [currentRoomStatus] = useDocument(
    doc(
      getFirestore(),
      `online-status/${state.statusDocId || state.representativeId}`
    )
  );

  const lastOnline = useMemo(() => {
    const status = currentRoomStatus?.data();
    if (!status || (!status.online && !status?.lastOnline)) return "";
    return currentRoomStatus?.data()?.online
      ? "active"
      : `active ${moment(
          currentRoomStatus?.data()?.lastOnline?.seconds * 1000
        ).fromNow()}`;
  }, [currentRoomStatus]);

  const setCurrentRoomLink = useMemo(() => {
    if (!isGroupChat && state?.collectionId && roomId)
      return `representatives/${state.collectionId}/rooms/${roomId}`;
    if (isGroupChat && roomId) return `group-chats/${roomId}`;
  }, [isGroupChat, roomId, state?.collectionId]);

  const [currentRoom] = useDocument(doc(getFirestore(), setCurrentRoomLink));

  const [messages, messagesLoading] = useCollection(
    query(messagesRef, orderBy("createdAt", "asc"))
  );

  const [partnerMessages] = useCollection(
    query(
      messagesRef,
      where("userId", "!=", currentUser.id),
      where("read", "==", false)
    )
  );

  const classes = useStyles();

  const setMessageRefLink = useCallback(
    (v) => {
      if (!isGroupChat && state?.collectionId && roomId && v)
        return `representatives/${state.collectionId}/rooms/${roomId}/messages/${v}`;
      if (isGroupChat && roomId && v)
        return `group-chats/${roomId}/messages/${v}`;
    },
    [isGroupChat, roomId, state?.collectionId]
  );

  const handleSendMessage = async () => {
    if ((!messageInput && !uploadFile) || uploading) return;

    setMessageInput("");
    if (isEditing) {
      const messageRef = doc(getFirestore(), setMessageRefLink(isEditing));
      if (isEditing === messages.docs[messages.docs.length - 1].id)
        updateDoc(currentRoomRef, {
          lastMessage: messageInput,
        });
      setIsEditing(null);
      return updateDoc(messageRef, { text: messageInput });
    }
    const { id } = currentUser;
    const uploadMessage = {
      text: messageInput,
      createdAt: serverTimestamp(),
      userId: id,
      read: false,
      attachments: uploadFile
        ? [
            {
              ...uploadFile,
              url: attachment,
            },
          ]
        : [],
    };
    const arrChatPartner = Object.values(chatPartner);

    if (arrChatPartner?.length === 1)
      uploadMessage.sendTo = Object.values(chatPartner).map(({ id }) => id);
    if (arrChatPartner?.length > 1)
      uploadMessage.sendToUsers = Object.values(chatPartner).map(
        ({ id }) => id
      );

    setFileBlob(null);
    setUploadFile(null);
    setAttachment(null);
    addDoc(messagesRef, uploadMessage);
    updateDoc(currentRoomRef, {
      lastMessage: messageInput,
      lastAttachment: uploadFile?.type || null,
      updatedAt: serverTimestamp(),
      readBy: [currentUser.id],
    }).then(() => {
      messagesEndRef?.current?.scrollIntoView();
    });
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const setRepIds = useMemo(() => {
    if (!isGroupChat && state?.representativeId)
      return [state?.representativeId];
    if (isGroupChat && state?.representativesIds)
      return state?.representativesIds;
  }, [isGroupChat, state?.representativeId, state?.representativesIds]);

  useEffect(() => {
    setRepLoading(true);
    const controller = new AbortController();
    getRepsArrayService(
      { representativeDuplicateIds: setRepIds },
      controller.signal
    )
      .then((res) => {
        setChatPartner(res);
        setRepLoading(false);
      })
      .catch((err) => {
        setRepLoading(false);
        error(err?.response?.data?.message);
      });

    return () => controller.abort();
  }, [
    isGroupChat,
    setRepIds,
    state?.representativeId,
    state?.representativesIds,
  ]);

  useEffect(() => {
    setMessageInput("");
    if (uploadFile) {
      const attachmentRef = ref(getStorage(), `${roomId}/${uploadFile.name}`);
      deleteObject(attachmentRef).catch((error) => error(error));
    }
    setUploadFile(null);
    setFileBlob(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomId]);

  useEffect(() => {
    window.onbeforeunload = () => {
      if (uploadFile) {
        const attachmentRef = ref(getStorage(), `${roomId}/${uploadFile.name}`);
        deleteObject(attachmentRef).catch((error) => error(error));
      }
    };
  }, [roomId, uploadFile]);

  const readBy = useMemo(() => currentRoom?.data()?.readBy, [currentRoom]);

  useEffect(() => {
    if (roomId && !readBy?.find((id) => id === currentUser.id))
      updateDoc(currentRoomRef, {
        readBy: readBy ? [...readBy, currentUser.id] : [currentUser.id],
      });
    if (!partnerMessages?.docs) return;
    const batch = writeBatch(getFirestore());
    partnerMessages.docs.forEach((message) => {
      const messageRef = doc(getFirestore(), setMessageRefLink(message?.id));
      batch.update(messageRef, { read: true });
    });
    batch.commit();
  }, [
    partnerMessages,
    currentRoomRef,
    roomId,
    state?.collectionId,
    currentUser?.id,
    readBy,
    setMessageRefLink,
  ]);

  const handlePressEnter = (e) => {
    const val = e.target.value;
    if (e.keyCode === 13 && val !== "") handleSendMessage();
  };

  useEffect(() => {
    if (state.messageId) {
      const scrollIndex = messages?.docs?.findIndex(
        (d) => d.id === state.messageId
      );

      if (scrollIndex > -1)
        messageRefs.current[scrollIndex].scrollIntoView({ behavior: "smooth" });
      return;
    }
  }, [
    messagesLoading,
    state.messageId,
    messagesRef,
    messages?.docs,
    messageMenuOpen,
  ]);

  useEffect(() => {
    if (!messagesLoading) messagesEndRef?.current?.scrollIntoView();
  }, [messagesLoading, messages?.docs?.length]);

  const setHandleDeleteChatLink = useMemo(() => {
    if (!isGroupChat && state?.collectionId && roomId)
      return `representatives/${state.collectionId}/rooms/${roomId}/messages`;
    if (isGroupChat && roomId) return `group-chats/${roomId}/messages`;
  }, [isGroupChat, roomId, state?.collectionId]);

  const handleDeleteChat = () => {
    handleDeleteChatMessages(setHandleDeleteChatLink, messages);
    handleDeleteChatFiles(roomId);
    deleteDoc(currentRoomRef).then(() => {
      dispatch(setConfirmIsOpenAction(false));
      navigate("/conversations");
    });
    deleteDoc(currentRoomStatusRef);
  };

  const handleOpenMessageMenu = (e, message) => {
    e.preventDefault();
    setMessageMenuOpen(true);
    setClickedMessage(message);
    setAnchorEl(e.target);
  };

  const handleDeleteMessage = () => {
    setAnchorEl(null);
    setMessageMenuOpen(false);
    setClickedMessage(null);

    if (clickedMessage.id === messages.docs[messages.docs.length - 1].id)
      updateDoc(currentRoomRef, {
        lastMessage:
          messages.docs[messages.docs.length - 2]?.data()?.text || "",
        lastAttachment:
          messages.docs[messages.docs.length - 2]?.data()?.attachments?.[0]
            ?.type || null,
      });
    const messageRef = doc(
      getFirestore(),
      setMessageRefLink(clickedMessage?.id)
    );
    deleteDoc(messageRef);
    clickedMessage.attachments?.forEach((attachment) => {
      const attachmentRef = ref(getStorage(), `${roomId}/${attachment.name}`);
      deleteObject(attachmentRef).catch((error) => error(error));
    });
  };

  const handleEditMessage = () => {
    setIsEditing(clickedMessage.id);
    setMessageInput(clickedMessage.text);
    setMessageMenuOpen(false);
  };

  const handleAttachmentClick = async (attachment) => {
    if (attachment.type === "image") {
      setImageFullscreenOpen(true);
      return setFullscreenImage(attachment.url);
    }

    const file = await fetch(attachment.url);
    const fileBlob = await file.blob();
    const imageURL = URL.createObjectURL(fileBlob);
    const link = document.createElement("a");
    link.href = imageURL;
    link.download = attachment.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const openConfirmDialog = () => {
    dispatch(
      openConfirmDialogAction({
        title: "Warning",
        text: `${MAX_UPLOAD_FILE_SIZE_CHAT_MB} MB maximum can be attached`,
        buttons: (
          <>
            <StyledButton
              variant="outlined"
              color="cancel"
              label="Cancel"
              sx={{ height: "28px" }}
              onClick={() => {
                dispatch(setConfirmIsOpenAction(false));
              }}
            />
          </>
        ),
      })
    );
  };

  const handleUploadFiles = (e) => {
    if (e.target.files.length === 0) return;
    const file = e.target.files[0];

    if (
      checkFileSize({
        fileSize: file.size,
        maxFileSizeMb: MAX_UPLOAD_FILE_SIZE_CHAT_MB,
      })
    ) {
      openConfirmDialog();
      e.target.value = null;
      return;
    }

    setUploadFile({
      size: file.size,
      name: file.name,
      type: file.type.includes("image") ? "image" : "file",
    });
    setFileBlob(URL.createObjectURL(file));
    e.target.value = null;
    const storageRef = ref(getStorage(), `${roomId}/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    setUploading(true);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );

        setUploadProgress(progress);
      },
      (error) => {
        error(error);
        setUploading(true);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setAttachment(downloadURL);
          setUploading(false);
        });
      }
    );
  };

  const handleDeleteAttachment = () => {
    if (uploading) {
      setFileBlob(null);
      setUploadFile(null);
      setRepLoading(false);
      setUploadProgress(0);
      return;
    }

    const fileRef = ref(getStorage(), `${roomId}/${uploadFile.name}`);
    deleteObject(fileRef)
      .then(() => {
        setFileBlob(null);
        setUploadFile(null);
        setRepLoading(false);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err?.response?.data?.message);
        error(err?.response?.data?.message || "Something went wrong");
      });
  };

  const showLastOnline = useMemo(
    () =>
      lastOnline &&
      !isGroupChat &&
      Object.values(chatPartner)[0]?.representativeId,
    [chatPartner, isGroupChat, lastOnline]
  );

  const optionsBlockWidth = useMemo(() => {
    const title = lastOnlineRef?.current?.offsetWidth || 0;
    return showLastOnline ? title + 120 : 85;
  }, [showLastOnline]);

  return (
    <>
      <Paper
        className={classes.roomSection}
        sx={{
          width: "100%",
          // flex: "0 0 60%",
          overflow: "hidden",
        }}
      >
        <Box height="calc(100% - 65px)">
          <MessageMenu
            isOpen={messageMenuOpen}
            anchorEl={anchorEl}
            handleClose={() => {
              setMessageMenuOpen(false);
            }}
            handleDelete={handleDeleteMessage}
            handleEdit={handleEditMessage}
          />
          <ImageFullscreen
            isOpen={!!imageFullscreenOpen}
            handleClose={() => setImageFullscreenOpen(false)}
            fullscreenImage={fullscreenImage}
          />
          <Box
            display="flex"
            justifyContent="space-between"
            borderBottom="0.5px solid #D5D9D9"
            p="15px 25px 14px 20px"
            alignItems="center"
          >
            {repLoading ? (
              <CircularProgress size="25px" />
            ) : (
              <>
                <Typography
                  color="#1C1C19"
                  fontWeight="500"
                  noWrap
                  sx={{ maxWidth: `calc(100% - ${optionsBlockWidth}px)` }}
                >
                  {Object.values(chatPartner)
                    .map(({ name }) => name)
                    .join(", ")}
                </Typography>

                <Box
                  display="flex"
                  alignItems="center"
                  sx={{ minWidth: "fit-content" }}
                >
                  <StyledButton
                    label="Delete Chat"
                    color="confirmDelete"
                    fontSize="13px"
                    onClick={() =>
                      dispatch(confirmDeleteChatAction(handleDeleteChat))
                    }
                    sx={{ px: "2px" }}
                  />
                  {showLastOnline && (
                    <Stack direction="row" ref={lastOnlineRef}>
                      <Typography
                        fontSize="13px"
                        fontWeight="400"
                        color="#858585"
                        mr="2px"
                      >
                        |
                      </Typography>
                      <Typography
                        fontSize="13px"
                        fontWeight="400"
                        color="#B5B5AC"
                      >
                        {lastOnline}
                      </Typography>
                    </Stack>
                  )}
                </Box>
              </>
            )}
          </Box>
          <Box
            sx={{
              pt: "33px",
              height: "calc(100% - 50px)",
              overflow: "auto",
            }}
          >
            {messagesLoading && (
              <Box mx="auto" width="50px">
                <CircularProgress />
              </Box>
            )}
            {messages?.docs.map((message, i) => {
              return (
                <ChatMessage
                  currentUser={message.data().userId === currentUser.id}
                  key={message.id}
                  message={{ ...message.data(), id: message.id }}
                  partner={
                    isGroupChat
                      ? chatPartner[message.data().userId]
                      : Object.values(chatPartner)[0]
                  }
                  handleOpenMenu={handleOpenMessageMenu}
                  handleAttachmentClick={handleAttachmentClick}
                  ref={(el) => (messageRefs.current[i] = el)}
                  dimAnimation={state.messageId === message.id}
                />
              );
            })}
            <Box ref={messagesEndRef} />
            {!messages?.docs?.length && !messagesLoading && (
              <Typography mx="auto" width="fit-content" color="#B5B5AC">
                No messages here! Feel free to start messaging...
              </Typography>
            )}
          </Box>
        </Box>
        <Box display="flex" borderTop="0.5px solid #D5D9D9" alignItems="center">
          <StyledTextField
            onChange={(e) => setMessageInput(e.target.value)}
            variant="standard"
            value={messageInput}
            placeholder="Type a message..."
            InputProps={{
              sx: { height: "48px", pl: "24px" },
              disableUnderline: true,
              endAdornment: (
                <>
                  {fileBlob && (
                    <InputAdornment
                      position="start"
                      sx={{ justifyContent: "center", display: "flex" }}
                    >
                      {uploadFile.type === "image" ? (
                        <Box
                          component="img"
                          src={fileBlob}
                          className={classes.uploadingImage}
                          sx={{ opacity: uploading && 0.3 }}
                        />
                      ) : (
                        <Box sx={{ opacity: uploading && 0.3 }}>
                          <Typography>{uploadFile.name}</Typography>
                        </Box>
                      )}
                      {uploading && (
                        <Typography
                          sx={{
                            position: "absolute",
                            backgroundColor: "#ffffff",
                            p: "5px",
                          }}
                          fontSize="13px"
                        >
                          {uploadProgress}%
                        </Typography>
                      )}
                      <IconButton
                        sx={{
                          position: "absolute",
                          backgroundColor: "#ffffff",
                          p: "3px",
                          top: 0,
                          right: 0,
                          "&:hover": {
                            backgroundColor: "#ffffff",
                          },
                        }}
                        onClick={handleDeleteAttachment}
                      >
                        <ClosePinIcon size="5" />
                      </IconButton>
                    </InputAdornment>
                  )}
                  {isEditing && (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setMessageInput("");
                          setIsEditing(null);
                        }}
                      >
                        <CrossIcon />
                      </IconButton>
                    </InputAdornment>
                  )}
                </>
              ),
            }}
            onKeyDown={handlePressEnter}
          />
          <StyledButton
            label={isEditing ? "Save" : "Send"}
            onClick={handleSendMessage}
            sx={{
              borderRight: "0.5px solid #D5D9D9",
              borderRadius: 0,
              height: "22px",
            }}
            fontSize="15px"
          />
          <EmojiPicker
            anchorEl={emojiPickerAnchor}
            handleClose={() => setEmojiPickerAnchor(null)}
            onEmojiClick={(e, em) => {
              setMessageInput(`${messageInput}${em.emoji}`);
            }}
          />
          <IconButton
            sx={{ ml: "10px" }}
            onClick={(e) => setEmojiPickerAnchor(e.target)}
          >
            <SmileIcon />
          </IconButton>
          <UploadFile
            label=""
            accept="*"
            Wrapper={IconButton}
            icon={<ClipIcon />}
            wrapperProps={{
              sx: { ml: "4px", mr: "10px" },
            }}
            handleUploadFiles={handleUploadFiles}
          />
        </Box>
      </Paper>
      {/* <Paper
        className={classes.roomSection}
        sx={{
          flex: "0 0 17%",
        }}
      ></Paper> */}
    </>
  );
};

ChatRoom.propTypes = {
  room: object,
  messages: array,
};

ChatRoom.defaultProps = {
  room: null,
  messages: [],
};

export default ChatRoom;
