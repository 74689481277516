import { arrayOf, string } from "prop-types";
import { Box, Typography } from "@mui/material";

export const EmailedTo = ({ emails }) => {
  if (!Array.isArray(emails) || !emails.length) return null;

  return (
    <Typography fontSize={12} fontWeight={400} noWrap width="78%">
      Order emailed to{" "}
      <Box fontWeight={500} component="span">
        {emails.join(", ")}
      </Box>
    </Typography>
  );
};

EmailedTo.propTypes = { emails: arrayOf(string) };
EmailedTo.defaultProps = { emails: [] };
