import { func, bool } from "prop-types";
import { Box, IconButton, Typography } from "@mui/material";
import { CrossIcon } from "components/Icons";

export const RepsAssignedDrawerHeader = ({ onClose, singleAddRep }) => {
  return (
    <Box
      height="67px"
      pl="32px"
      pr="12px"
      borderBottom="0.5px solid #D5D9D9"
      bgcolor="#F8F8F8"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Typography fontSize={17} fontWeight={400} color="#3F3F3F">
        {singleAddRep ? "Assign rep" : "Assign reps"}
      </Typography>

      <IconButton onClick={onClose}>
        <CrossIcon />
      </IconButton>
    </Box>
  );
};

RepsAssignedDrawerHeader.propTypes = { onClose: func, singleAddRep: bool };
RepsAssignedDrawerHeader.defaultProps = {
  onClose: () => {},
  singleAddRep: false,
};
