import { string, func, object } from "prop-types";
import { Box, IconButton, Typography } from "@mui/material";
import { CrossIcon } from "components/Icons";

export const HeaderAddContactDrawer = ({ onClose }) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "67px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid #D5D9D9",
        backgroundColor: "#F8F8F8",
        pl: 4,
        pr: 2,
      }}
    >
      <Typography fontSize={18} color="#3F3F3F" noWrap>
        Add customer contact
      </Typography>
      <IconButton onClick={onClose}>
        <CrossIcon />
      </IconButton>
    </Box>
  );
};

HeaderAddContactDrawer.propTypes = {
  editNote: object,
  customerName: string,
  onClose: func,
};
HeaderAddContactDrawer.defaultProps = { customerName: "", onClose: () => {} };
