export const savedFiltersListSelector = (state) =>
  state.savedFilters.savedFilters;

export const selectedFilterIdSelector = (state) =>
  state.savedFilters.selectedFilterId;

export const savedFiltersLoadingSelector = (state) =>
  state.savedFilters.loading;

export const openedViewNameDialogSelector = (state) => state.savedFilters.ui;
export const filterForEditSelector = (state) =>
  state.savedFilters.filterForEdit;
