import { useContext } from "react";
import { Grid } from "@mui/material";
import {
  BusinessNameBlock,
  TerritoryBlock,
  BusinessPhoneBlock,
  BusinessEmailBlock,
  AddDisplayNameBlock,
  IsASubBusinessBlock,
  ParentCustomerBlock,
  DisplayNameBlock,
} from "./components";
import { CustomerContext } from "Pages/CustomersPage/pages/CustomerPage/CustomerPage";

export const CustomerDetailsBlock = () => {
  const { formField } = useContext(CustomerContext);

  const { addDisplayName, isASubBusiness } = formField || {};

  return (
    <Grid gap={2.75} xs={12} item container>
      <BusinessNameBlock />
      <AddDisplayNameBlock />
      {addDisplayName ? <DisplayNameBlock /> : null}
      <BusinessPhoneBlock />
      <BusinessEmailBlock />
      <TerritoryBlock />
      <IsASubBusinessBlock />
      {isASubBusiness ? <ParentCustomerBlock /> : null}
    </Grid>
  );
};
