import { bool, string } from "prop-types";
import { Box } from "@mui/material";
import { StyledTooltip } from "components";

// eslint-disable-next-line react/prop-types
export const OnboardingTooltip = ({ disableHoverListener, ml, children }) => {
  return (
    <StyledTooltip
      placement="bottom"
      disableHoverListener={disableHoverListener}
      arrow
      title="Complete Onboarding to use this feature"
      PopperProps={{
        modifiers: [{ name: "offset", options: { offset: [0, -6] } }],
      }}
    >
      <Box display="flex" ml={ml}>
        {children}
      </Box>
    </StyledTooltip>
  );
};

OnboardingTooltip.propTypes = {
  disableHoverListener: bool,
  ml: string,
};
