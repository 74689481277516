import { func, object } from "prop-types";
import {
  PRODUCTS_HEADER,
  SORTABLE_COLUMNS,
} from "./PurchasedProductsHeader.constants";

import { classes } from "./styles";

import { Grid, Paper, Typography } from "@mui/material";
import { ArrowDownIcon, ArrowUpIcon } from "components/Icons";

export const PurchasedProductsHeader = ({ handleSetSort, quickSort }) => {
  return (
    <Paper
      elevation={0}
      variant="outlined"
      square
      component={Grid}
      container
      columns={9}
      height="34px"
      sx={classes.paperWrapper}
    >
      {PRODUCTS_HEADER.map(({ label, xs, style }, index) => (
        <Grid
          item
          xs={xs}
          key={index}
          sx={{
            ...classes.grid,
            ...style,
            cursor: !!SORTABLE_COLUMNS[label] && "pointer",
          }}
          onClick={() =>
            SORTABLE_COLUMNS[label] &&
            handleSetSort(SORTABLE_COLUMNS[label].sortableField)
          }
        >
          <Typography sx={classes.typography}>{label}</Typography>
          {SORTABLE_COLUMNS[label] ? (
            quickSort[SORTABLE_COLUMNS[label].sortableField] === "desc" ? (
              <ArrowDownIcon fill="#AEAEAE" />
            ) : (
              <ArrowUpIcon fill="#AEAEAE" />
            )
          ) : null}
        </Grid>
      ))}
    </Paper>
  );
};

PurchasedProductsHeader.propTypes = {
  quickSort: object,
  handleSetSort: func,
};

PurchasedProductsHeader.defaultProps = {
  quickSort: {},
  handleSetSort: () => {},
};
