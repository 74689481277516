/* eslint-disable react/prop-types */
import React from "react";

export const ClosePinIcon = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || 11}
      height={size || 11}
      viewBox="0 0 11 11"
    >
      <path
        id="Icon_material-cancel"
        data-name="Icon material-cancel"
        d="M8.4,3A5.317,5.317,0,0,0,3,8.242a5.317,5.317,0,0,0,5.4,5.242,5.317,5.317,0,0,0,5.4-5.242A5.317,5.317,0,0,0,8.4,3Zm2.7,7.124-.761.739L8.4,8.981,6.461,10.863,5.7,10.124,7.638,8.242,5.7,6.36l.761-.739L8.4,7.5l1.938-1.882.761.739L9.16,8.242Z"
        transform="translate(-3 -3)"
        fill="#b2b2b2"
      />
    </svg>
  );
};
