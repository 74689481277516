import React from "react";
import PropTypes from "prop-types";
import { MapPinIndexedSmall } from "components/Icons";
import { Typography } from "@mui/material";
import theme from "theme";

const MapPointIndexed = ({ index, color }) => {
  const indexLength = index?.toString()?.length ?? 1;
  const fontSize = 12 - (indexLength - 1) * 2;
  return (
    <div style={{ position: "relative" }}>
      <Typography
        color="#fff"
        position="absolute"
        sx={{
          background: color ?? theme.palette.primary.main,
          top: 0,
          transform: "translate(-50%, 90%)",
          left: "50%",
          lineHeight: "12px",
          borderRadius: "50%",
        }}
        fontSize={`${fontSize}px`}
      >
        {index}
      </Typography>
      <MapPinIndexedSmall color={color} />
    </div>
  );
};

MapPointIndexed.propTypes = {
  index: PropTypes.number,
  color: PropTypes.string,
};

export default MapPointIndexed;
