import { useCallback, useEffect, useRef, useState, useMemo } from "react";
import { shape, string, func } from "prop-types";
import lodash, { snakeCase } from "lodash";
import { Chip, Tab, Tabs, Typography, Box, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  FilterMenu,
  StyledButton,
  FilterSearchTextField,
  CustomFilterMenu,
  FilterViewSaveDialog,
} from "components";
import { FilterIcon } from "components/Icons";
import { normalizeSnakeCaseString } from "helpers/helpers";
import useStyles from "../../styles";
import { useDebounce } from "helpers/hooks";
import {
  CUSTOM_FILTERS_TYPES,
  NAME_VIEW_DIALOGS,
  USED_ON_TYPE_ORDER_TYPES,
} from "utils/constants";
import { FILTER_STATUSES } from "../../DraftsTab.constants";
import { ColumnsSettingComponent } from "Pages/CustomersPage/components/CustomersTab/components/TabsContent/components/ColumnsSettingComponent/ColumnsSettingComponent";
import { useTagsActions } from "helpers/useTagsActions";
import { ROLES, TYPES, useRepsActions } from "helpers/useRepsActions";
import { setSelectedFilterIdAction } from "redux/actions/savedFilters";
import {
  checkForDefaultFilter,
  handleSetSwitchesForEdit,
} from "helpers/filters";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { currentUserSelector } from "redux/selectors/auth";
import { useCustomFilterCreate } from "helpers/useCustomFilterCreate";

const selector = createSelector(currentUserSelector, (currentUser) => ({
  currentUser,
}));

export const DraftsFilter = ({ filtersData, currentTab }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { currentUser } = useSelector(selector);

  const { tabField, setTabField, setParams } = filtersData || {};

  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [isFilterChanged, setIsFilterChanged] = useState(false);

  const {
    list: tagsList,
    count: tagsCount,
    handleFetchTags,
    page: tagsPage,
    setTagsParams,
    searcheble: tagsSearcheble,
  } = useTagsActions({
    type: "order",
    params: {
      used: true,
      used_on_type: JSON.stringify([USED_ON_TYPE_ORDER_TYPES.draft_order]),
    },
    open: filterMenuOpen,
  });

  const {
    list: salesList,
    countSales: salesCount,
    handleFetchReps,
    page: salesPage,
    setRepsParams,
    searcheble: repsSearcheble,
  } = useRepsActions({
    type: TYPES.duplicates,
    params: {
      role: ROLES.sales,
      // with_orders: true,
      with_created_orders: true,
      order_type: JSON.stringify(["DRAFT_ORDER"]),
    },
    open: filterMenuOpen,
  });

  const filterAnchor = useRef();
  const [searchInput, setSearchInput] = useState("");
  const searchInputDebounced = useDebounce(searchInput, 500);

  const filterFieldsInit = {
    sales_id: "",
    tag_id: [],
    date: "",
  };

  const switchFieldsInit = [
    {
      checked: false,
      label: "Created by",
      selectLabel: "Select Rep",
      type: "select",
      value: "sales_id",
    },
    {
      value: "tag_id",
      label: "Tag",
      type: "multiselect",
      checked: false,
      selectLabel: "Select Tag",
    },
    {
      value: "date",
      label: "Created at",
      type: "date",
      checked: false,
      selectLabel: "Select Date",
    },
  ];

  const [filterFields, setFilterFields] = useState(filterFieldsInit);
  const [switches, setSwitches] = useState(switchFieldsInit);

  useEffect(() => {
    if (!isFilterChanged) return;
    const salesIdValue = filterFields?.sales_id?.value;
    const validValues = [
      "distributor",
      "back_office_worker",
      "order_direct",
      "quickbooks",
    ];
    const isIncluded = validValues.includes(salesIdValue);

    const created_by = isIncluded
      ? salesIdValue
      : salesIdValue
      ? "sales"
      : undefined;

    const sales_id = isIncluded ? undefined : salesIdValue;

    const { start_date, end_date } = filterFields?.date?.value || {};

    setParams((prev) => ({
      ...prev,
      search: searchInputDebounced,
      created_by,
      sales_duplicate_id: sales_id,
      tag_id: filterFields?.tag_id?.length
        ? filterFields?.tag_id?.map(({ value }) => value)
        : [],
      date: start_date ? undefined : snakeCase(filterFields?.date?.value),
      start_date,
      end_date,
    }));
  }, [searchInputDebounced, filterFields, setParams, isFilterChanged]);

  const filterChipKeys = useMemo(
    () =>
      Object.keys(filterFields).filter((key) => {
        if (
          lodash.isArray(filterFields[key]) &&
          lodash.isEmpty(filterFields[key])
        )
          return;

        return filterFields[key];
      }),
    [filterFields]
  );

  const handleApplyFilter = (newSwitches, newFields) => {
    dispatch(setSelectedFilterIdAction(null));
    setFilterMenuOpen(false);
    setSwitches([...newSwitches]);
    setFilterFields({ ...newFields });
  };

  const handleDeleteFilter = useCallback(
    (key) => {
      dispatch(setSelectedFilterIdAction(null));
      const newState = [...switches];
      const index = newState.findIndex((s) => s.value === key);
      const insert = {
        ...newState[index],
        checked: false,
      };
      if (index > -1) {
        newState.splice(index, 1, insert);
        setSwitches([...newState]);
      }
      setFilterFields((prev) => {
        return {
          ...prev,
          [key]: lodash.isArray(filterFields[key]) ? [] : "",
        };
      });
    },
    [dispatch, filterFields, switches]
  );

  useEffect(() => {
    checkForDefaultFilter({
      type: CUSTOM_FILTERS_TYPES.ORDERS[currentTab],
      list: currentUser.customFilters,
      setFilters: setFilterFields,
      setSwitches,
      switchesList: switches,
      dispatchFunc: (id) => dispatch(setSelectedFilterIdAction(id)),
    });
    setIsFilterChanged(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser.customFilters]);

  const getChipLabel = (key) => {
    const fieldKeys = Object.keys(filterFields[key]);
    const isCombined = key === "price";
    const isArray = lodash.isArray(filterFields[key]);

    return (
      <Box display="flex" alignItems="center" gap="6px">
        <Typography
          sx={{ fontSize: "13px", fontWeight: 500 }}
          color="groundLighter.main"
        >
          {isArray ? (
            <>
              {normalizeSnakeCaseString(filterFields[key][0]?.label || key)}:{" "}
              {typeof filterFields[key][0] === "string"
                ? normalizeSnakeCaseString(filterFields[key][0])
                : filterFields[key]
                    .map(({ name }) => normalizeSnakeCaseString(name))
                    .join(", ")}
            </>
          ) : (
            <>
              {normalizeSnakeCaseString(filterFields[key]?.label || key)}:{" "}
              <span style={{ color: "#5F6368" }}>
                {isCombined
                  ? fieldKeys
                      .filter((fieldKey) => filterFields[key][fieldKey]?.value)
                      .map((fieldKey) => {
                        const { prefix, value } = filterFields[key][fieldKey];
                        return `${prefix} ${value}`;
                      })
                      .join(" and ")
                  : normalizeSnakeCaseString(
                      filterFields[key]?.name || filterFields[key]
                    )}
              </span>
            </>
          )}
        </Typography>
      </Box>
    );
  };

  const preparedSalesMatchData = useMemo(() => {
    const preparedData = {
      id: "sales_id",
      childrenList: [],
      handleFetch: () => handleFetchReps(salesPage + 1),
      setParams: setRepsParams,
      dataCount: salesCount,
      dataLength: salesList.length,
      label: "Select Rep",
      searcheble: repsSearcheble,
    };

    preparedData.childrenList.push({
      name: "Admin",
      id: "distributor",
    });

    preparedData.childrenList.push({
      name: "Back Office",
      id: "back_office_worker",
    });

    preparedData.childrenList = [...preparedData.childrenList, ...salesList];

    return preparedData;
  }, [
    handleFetchReps,
    salesCount,
    salesList,
    salesPage,
    repsSearcheble,
    setRepsParams,
  ]);

  const selectMatchData = useMemo(() => {
    return [
      preparedSalesMatchData,
      {
        id: "tag_id",
        childrenList: tagsList.map(({ tag, ...item }) => ({
          ...item,
          name: tag,
        })),
        handleFetch: () => handleFetchTags(tagsPage + 1),
        setParams: setTagsParams,
        dataCount: tagsCount,
        dataLength: tagsList?.length,
        label: "",
        searcheble: tagsSearcheble,
      },
      {
        id: "date",
        label: "Created at",
        isVisit: false,
      },
    ];
  }, [
    handleFetchTags,
    preparedSalesMatchData,
    tagsCount,
    tagsList,
    tagsPage,
    tagsSearcheble,
    setTagsParams,
  ]);

  const onCustomFilterApply = useCallback(
    (filters) => {
      setFilterFields(filters);
      setSwitches(handleSetSwitchesForEdit(filters, switches));
    },
    [switches]
  );

  const {
    onCloseFilterDialog,
    onFilterViewSave,
    onOpenFilterSaveDialog,
    isOpenedMenu,
  } = useCustomFilterCreate({
    createFilterCallback: onCustomFilterApply,
    menuCallback: () => setFilterMenuOpen(false),
    viewTypeKey: NAME_VIEW_DIALOGS.ORDERS,
    filterType: CUSTOM_FILTERS_TYPES.ORDERS[currentTab],
  });

  return (
    <Box className={classes.filterWrapper}>
      {isOpenedMenu && (
        <FilterViewSaveDialog
          open={isOpenedMenu}
          onClose={onCloseFilterDialog}
          handleSave={onFilterViewSave}
        />
      )}
      <Tabs
        value={tabField.value}
        onChange={(e, newVal) => {
          setTabField({ name: e.target.name, value: newVal });
        }}
        className={classes.tabs}
        TabIndicatorProps={{ style: { backgroundColor: "transparent" } }}
      >
        <Tab label="All" value="" className={classes.tab} />
        {FILTER_STATUSES.map((status) => (
          <Tab
            key={status.value}
            label={status.label}
            name={status.label}
            value={status.value}
            className={classes.tab}
          />
        ))}
      </Tabs>

      <FilterSearchTextField
        adminIsAllowed
        formSx={{ minWidth: "380px" }}
        placeholderWidth="310px"
        placeholder="Search by ID, customer name or address"
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        fullWidth
        adornmentProps={{ sx: { justifyContent: "flex-end" } }}
        handleClearValue={() => setSearchInput("")}
        customAdornment={
          <Box
            display="flex"
            gap="5px"
            overflow="auto"
            sx={{
              py: "4px",
              "&::-webkit-scrollbar": { height: "2px" },
            }}
          >
            {filterChipKeys.map((key) => {
              return (
                <Chip
                  key={key}
                  className={classes.filterChip}
                  size="small"
                  label={getChipLabel(key)}
                  deleteIcon={
                    <CloseIcon size="15" style={{ fill: "#707070" }} />
                  }
                  onMouseDown={(e) => e.stopPropagation()}
                  onDelete={() => handleDeleteFilter(key)}
                />
              );
            })}
          </Box>
        }
      />

      <CustomFilterMenu
        {...{
          selectMatchData,
          type: CUSTOM_FILTERS_TYPES.ORDERS[currentTab],
          switches,
          onFilterApply: onCustomFilterApply,
          resetFilterFunc: () => {
            setFilterFields(filterFieldsInit);
            setSwitches(switchFieldsInit);
          },
        }}
      />

      <Stack direction="row" gap="14px">
        <StyledButton
          label="Filter"
          startIcon={
            filterChipKeys.length ? (
              <Typography
                fontSize="9px!important"
                color="#ffffff"
                backgroundColor="#47A06D"
                borderRadius="50%"
                width="15px"
                height="15px"
              >
                {filterChipKeys.length}
              </Typography>
            ) : (
              <FilterIcon />
            )
          }
          variant="outlined"
          ref={filterAnchor}
          color="edit"
          sx={{
            height: "39px",
            width: "100%",
            maxWidth: "74px",
            border: "0.5px solid #D5D9D9",
            "& .MuiButton-startIcon": {
              ml: 0,
            },
          }}
          fontSize="15px"
          onClick={() => setFilterMenuOpen(true)}
        />

        <FilterMenu
          anchorEl={filterAnchor.current}
          open={filterMenuOpen}
          onClose={() => setFilterMenuOpen(false)}
          filterFields={filterFields}
          isCustomFulfillmentStatus={true}
          switches={switches}
          handleApplyFilter={handleApplyFilter}
          selectMatchData={selectMatchData}
          onViewSave={onOpenFilterSaveDialog}
        />
        <Box sx={{ mt: "9px" }}>
          <ColumnsSettingComponent currentTab={currentTab} />
        </Box>
      </Stack>
    </Box>
  );
};

DraftsFilter.propTypes = {
  filtersData: shape({
    tabField: shape({
      name: string,
      value: string,
    }),
    setTabField: func,
    setParams: func,
  }),
  currentTab: string,
};

export default DraftsFilter;
