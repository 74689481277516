import { Box, Grid, Typography } from "@mui/material";
import { StyledTooltip } from "components";
import { object } from "prop-types";
import { useMemo } from "react";
import { StyledProductIco } from "../../../../../../components/Icons";
import { photoUrl } from "../../../../../../helpers/helpers";
import useStyles from "../styles";

const PerformanceItem = ({ product }) => {
  const classes = useStyles({ type: "performance" });

  const setTooltipTitle = useMemo(() => {
    if (product?.color) return `${product.sku} / ${product?.color}`;
    if (product?.size) return `${product.sku} / ${product?.size}`;
    return "";
  }, [product?.color, product.sku, product?.size]);

  return (
    <Grid container className={classes.gridItem}>
      <Grid item xs={10} className={`${classes.gridColumn} main`}>
        <StyledTooltip
          title={setTooltipTitle}
          disableHoverListener={!(product?.color || product?.size)}
          followCursor
        >
          <Box display="flex" gap="9px" alignItems="center">
            <Box display="flex">
              <StyledProductIco
                placeholderStyles={{
                  wrapperProps: { width: "36px", height: "36px" },
                }}
                src={product.photo ? photoUrl(product.photo?.fileName) : ""}
                styles={{
                  objectFit: "contain",
                  width: "36px",
                  height: "36px",
                  borderRadius: "4px",
                  border: "1px solid #D5D9D9",
                }}
              />
            </Box>

            <Box sx={{ width: "calc(100% - 45px)" }}>
              <Typography
                noWrap
                sx={{
                  width: "fit-content",
                  maxWidth: "100%",
                }}
              >
                {product.name} / {product.sku}
              </Typography>
              <Typography noWrap>by {product.manufacturerName}</Typography>
            </Box>
          </Box>
        </StyledTooltip>
      </Grid>
      <Grid item xs={2} className={`${classes.gridColumn} secondary`}>
        <Typography>Purchases</Typography>
        <Typography>{product.used?.toLocaleString()}</Typography>
      </Grid>
    </Grid>
  );
};

PerformanceItem.propTypes = {
  product: object,
};

export default PerformanceItem;
