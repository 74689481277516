/* eslint-disable react/prop-types */
import React from "react";

export const ArrowLeftIcon = ({
  color = "currentColor",
  width = 5.698,
  height = 9.967,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 5.698 9.967"
    >
      <path
        id="Icon_ionic-ios-arrow-forward"
        data-name="Icon ionic-ios-arrow-forward"
        d="M12.964,11.178l3.771-3.769a.709.709,0,0,0,0-1.006.718.718,0,0,0-1.009,0l-4.272,4.27a.711.711,0,0,0-.021.982l4.29,4.3a.712.712,0,1,0,1.009-1.006Z"
        transform="translate(-11.246 -6.196)"
        fill={color}
      />
    </svg>
  );
};
