import React from "react";

export const RouteIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11.018"
      height="11.018"
      viewBox="0 0 11.018 11.018"
    >
      <path
        id="Icon_awesome-route"
        data-name="Icon awesome-route"
        d="M8.952,6.886H6.886a.689.689,0,0,1,0-1.377H8.952s2.066-2.3,2.066-3.443a2.066,2.066,0,1,0-4.132,0,4.539,4.539,0,0,0,.975,2.066H6.886a2.066,2.066,0,0,0,0,4.132H8.952a.689.689,0,0,1,0,1.377H3.992a14.857,14.857,0,0,1-1.018,1.377H8.952a2.066,2.066,0,0,0,0-4.132Zm0-5.509a.689.689,0,1,1-.689.689A.688.688,0,0,1,8.952,1.377ZM2.066,5.509A2.066,2.066,0,0,0,0,7.575c0,1.14,2.066,3.443,2.066,3.443s2.066-2.3,2.066-3.443A2.066,2.066,0,0,0,2.066,5.509Zm0,2.754a.689.689,0,1,1,.689-.689A.688.688,0,0,1,2.066,8.263Z"
        fill="#3f3f3f"
      />
    </svg>
  );
};
