import { object, string } from "prop-types";
import { Box, Typography } from "@mui/material";
import { AtentionIcon } from "../../Icons";

export const WarningBox = ({ sx, error }) => {
  const cl = {
    mainBlock: {
      border: "1px solid #EB4233",
      borderRadius: "4px",
      backgroundColor: "rgba(235, 66, 51, 0.1)",
    },
    textTitle: { fontSize: 14, fontWeight: 600 },
    textWarn: { fontSize: 14, fontWeight: 400, color: "#000000" },
  };

  return (
    <Box sx={{ ...cl.mainBlock, ...sx }}>
      <Box p="11px">
        <Box mb={1} display="flex" alignItems="center" gap={0.6}>
          <AtentionIcon size="16" />
          <Typography sx={cl.textTitle}>There was a problem.</Typography>
        </Box>

        <Typography sx={cl.textWarn}>- {error}</Typography>
      </Box>
    </Box>
  );
};
WarningBox.propTypes = {
  sx: object,
  error: string,
};
WarningBox.defaultProps = {
  error: "",
};
