import { string } from "prop-types";
import { Box } from "@mui/material";
import { InfoItemBlock } from "../InfoItemBlock/InfoItemBlock";

export const BusinessInfoBlock = ({
  federalTaxId,
  businessPhone,
  businessEmail,
}) => {
  return (
    <Box width="62%" display="flex" pl={5}>
      <Box width="50%">
        <InfoItemBlock
          title="Business contact info"
          info={[
            {
              name: "Phone Number",
              value: [businessPhone],
            },
            {
              name: "Email Address",
              value: [businessEmail],
            },
          ]}
        />
      </Box>
      <Box width="50%">
        <InfoItemBlock
          title="Additional info"
          info={[
            {
              name: "Federal Tax ID (EIN)",
              value: [federalTaxId],
            },
          ]}
          showDivider={false}
        />
      </Box>
    </Box>
  );
};

BusinessInfoBlock.propTypes = {
  federalTaxId: string,
  businessPhone: string,
  businessEmail: string,
};
BusinessInfoBlock.defaultProps = {
  federalTaxId: "",
  businessPhone: "",
  businessEmail: "",
};
