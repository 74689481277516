import { useMemo } from "react";
import { object, bool, func } from "prop-types";
import { Box, Grid, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { LargeCheckbox, StyledTooltip } from "components";
import { PRODUCT_TYPE_INVENTORY } from "utils/constants";

export const TrackQtyBlock = ({
  control,
  isQuickBooksConnected,
  isEdit,
  formField,
  setTabNames,
}) => {
  const handleChange = (e, field) => {
    const v = e.target.checked;
    field.onChange(
      v
        ? PRODUCT_TYPE_INVENTORY.inventory
        : PRODUCT_TYPE_INVENTORY.non_inventory
    );

    // switch Inventory tab to disabled/enabled
    setTabNames((prev) => {
      const temp = [...prev];
      const inventoryTabIndex = temp.findIndex(
        (tab) => tab.title === "Inventory"
      );

      if (inventoryTabIndex >= 0) {
        temp.splice(inventoryTabIndex, 1, {
          ...temp[inventoryTabIndex],
          visible: v,
        });
      }

      return temp;
    });
  };

  const disabled = useMemo(() => {
    if (
      isEdit &&
      formField?.rawProduct?.type === PRODUCT_TYPE_INVENTORY.inventory &&
      isQuickBooksConnected
    ) {
      return true;
    }

    return false;
  }, [formField?.rawProduct?.type, isEdit, isQuickBooksConnected]);

  return (
    <Grid xs={3.5} item>
      <StyledTooltip
        placement="top"
        arrow
        title="Can't turn off track inventory with active Quickbooks connection"
        disableHoverListener={!disabled}
      >
        <Box>
          <Controller
            render={({ field }) => (
              <LargeCheckbox
                adminIsAllowed
                checked={field.value === PRODUCT_TYPE_INVENTORY.inventory}
                disabled={disabled}
                formSx={{ ml: "-10px", mr: 0 }}
                label={
                  <Typography ml="-4px" color="#707070" fontSize={14}>
                    Track quantity
                  </Typography>
                }
                size={19}
                {...field}
                onChange={(e) => handleChange(e, field)}
              />
            )}
            name="type"
            control={control}
          />
        </Box>
      </StyledTooltip>
    </Grid>
  );
};

TrackQtyBlock.propTypes = {
  control: object,
  isQuickBooksConnected: bool,
  isEdit: bool,
  formField: object,
  setTabNames: func,
};
TrackQtyBlock.defaultProps = {};
