import { object, any } from "prop-types";
import { Box } from "@mui/material";
import { RightArrowUpIcon } from "components/Icons";

export const SwitcherLinkBlock = ({ wrapStyles, children }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 2, ...wrapStyles }}>
      <RightArrowUpIcon />
      {children}
    </Box>
  );
};

SwitcherLinkBlock.propTypes = {
  wrapStyles: object,
  children: any,
};
SwitcherLinkBlock.defaultProps = { wrapStyles: {} };
