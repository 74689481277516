import { useWatch } from "react-hook-form";
import lodash from "lodash";

export const useProductCategories = ({ control, setValue, categoriesList }) => {
  const formField = useWatch({ control });

  const handleCheckCategory = (e, fieldName, arrayField) => {
    const { checked } = e.target;
    setValue(fieldName, checked);
    setValue(arrayField, []);
  };

  const getParentCategory = (parentCategoryId) => {
    return categoriesList.find(({ id }) => id === parentCategoryId);
  };

  const filterNulledCategoryId = (list) => {
    return list.filter(({ parentCategoryId }) => parentCategoryId);
  };

  const handleCheckForAllChildSelect = (category) => {
    const parentId = category.parentCategoryId;

    if (parentId) {
      const parentCategory = getParentCategory(parentId);
      const filterByCategoryId = filterNulledCategoryId(
        formField.productCategories
      );

      const checkedParentChildren = filterByCategoryId.filter(
        ({ parentCategoryId }) => parentCategoryId === parentCategory.id
      );

      if (
        [...checkedParentChildren, category].length ===
        parentCategory?.childCategories?.length
      )
        return parentCategory;
      return {};
    }
    return {};
  };

  const handleAddCategory = (category, fieldName) => {
    const existItem = formField.productCategories.find(
      (item) => item.id === category.id
    );

    const parentCategoryWithChildren = handleCheckForAllChildSelect(category);
    if (!lodash.isEmpty(parentCategoryWithChildren) && !existItem) {
      const filterByCategoryId = formField.productCategories.filter(
        ({ parentCategoryId }) => !parentCategoryId
      );

      const filterWithoutChildren = formField.productCategories.filter(
        ({ parentCategoryId }) =>
          parentCategoryId &&
          parentCategoryId !== parentCategoryWithChildren?.id
      );

      return setValue(
        fieldName,
        [
          ...filterByCategoryId,
          ...filterWithoutChildren,
          parentCategoryWithChildren,
        ],
        {
          shouldDirty: true,
        }
      );
    }

    const hasCheckedParent = formField.productCategories.find(
      ({ id }) => id === category.parentCategoryId
    );

    if (existItem) {
      const filteredCategories = formField.productCategories.filter(
        ({ id }) => id !== category.id
      );
      return setValue(fieldName, [...filteredCategories], {
        shouldDirty: true,
      });
    }

    if (!category.parentCategoryId) {
      const filterWithoutChildren = formField.productCategories.filter(
        ({ parentCategoryId }) =>
          parentCategoryId && parentCategoryId !== category?.id
      );

      const filterByCategoryId = formField.productCategories.filter(
        ({ parentCategoryId }) => !parentCategoryId
      );

      return setValue(
        fieldName,
        [...filterByCategoryId, ...filterWithoutChildren, category],
        {
          shouldDirty: true,
        }
      );
    }

    if (hasCheckedParent) {
      const parentCategory = getParentCategory(category.parentCategoryId);

      const filtredListByParent = formField.productCategories.filter(
        ({ id }) => id !== parentCategory.id
      );

      const filtredChildren = parentCategory.childCategories.filter(
        ({ id }) => id !== category.id
      );

      return setValue(fieldName, [...filtredListByParent, ...filtredChildren], {
        shouldDirty: true,
      });
    }

    return setValue(fieldName, [...formField.productCategories, category], {
      shouldDirty: true,
    });
  };

  const isCheckedCategory = (id, parentCategoryId) => {
    return !!formField.productCategories.find(
      (item) => item.id === id || item.id === parentCategoryId
    );
  };

  const handleDeleteTag = (fieldName, id) => {
    const filtredCategories = formField.productCategories.filter(
      (item) => item.id !== id
    );

    return setValue(fieldName, filtredCategories);
  };

  return {
    handleCheckCategory,
    formField,
    control,
    setValue,
    handleAddCategory,
    isCheckedCategory,
    handleDeleteTag,
  };
};
