import Slider from "react-slick";
import pluralize from "pluralize";
import { object, string, func } from "prop-types";

import { LargeCheckbox, StyledAvatar, StyledTooltip } from "components";

import { normalizeUppercaseString, photoUrl } from "helpers/helpers";

import {
  NextArrow,
  PrevArrow,
} from "Pages/CatalogPage/CatalogNewProductPage/ContentTabs/Arrows";

import useStyles from "../../styles";

import { Box, Stack, Typography } from "@mui/material";

export const RouteItem = ({ route, stateKey, onRouteChange }) => {
  const { name, assignedRepresentatives, checked, _count } = route;
  const classes = useStyles({
    moreThanOne: assignedRepresentatives.length > 2,
  });

  const showNextArrow = assignedRepresentatives.length > 3;

  const handleAvatarBlockWidth = (count) => {
    switch (count) {
      case 1:
        return "39px";
      case 2:
        return "52px";
      case 3:
        return "59px";
      default:
        return "59px";
    }
  };
  const avatarWidth = handleAvatarBlockWidth(assignedRepresentatives.length);

  const sliderSettings = {
    infinite: false,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    variableWidth: true,
    nextArrow: <NextArrow width="4.5" height="7.9" color="#707070" top="30%" />,
    prevArrow: <PrevArrow width="4.5" height="7.9" color="#707070" top="30%" />,
  };

  const handleAvatarContent = (rep) => (
    <Box sx={{ textAlign: "center" }}>
      <Typography fontSize="10px" color="primary">
        {!!rep?.role &&
          (rep?.role === "THIRD_PARTY"
            ? "3rd Party Rep"
            : `${normalizeUppercaseString(rep?.role)} Rep`)}
      </Typography>
      <Typography fontSize="10px" color="#363531">
        {rep?.name}
      </Typography>
    </Box>
  );

  return (
    <Stack
      direction="row"
      alignItems="center"
      width="100%"
      className={classes.routeItemWrapper}
    >
      <LargeCheckbox
        formSx={{
          mr: 0,
          ml: "-9px",
          color: "#000",
        }}
        checked={checked}
        onChange={() => onRouteChange(stateKey, route)}
        size={16}
      />
      <Stack
        direction="row"
        alignItems="center"
        gap="9px"
        overflow="hidden"
        sx={{
          "& span": { color: "#B2B2B2" },
          width: `calc(100% - ${avatarWidth})`,
          mr: assignedRepresentatives.length > 2 && "12px",
        }}
      >
        <Typography
          noWrap
          fontWeight={400}
          color="#5F6267"
          mr="1px"
          lineHeight="20px"
        >
          {name}
        </Typography>
        {_count && (
          <>
            <span>•</span>
            <Typography
              sx={{ whiteSpace: "nowrap" }}
              fontWeight={400}
              color="#B2B2B2"
              lineHeight="20px"
            >
              {_count.customers} {pluralize("stop", _count.customers)}
            </Typography>
          </>
        )}
      </Stack>

      {!!assignedRepresentatives.length && (
        <Box
          className={classes.sliderWrapper}
          sx={{
            mr: showNextArrow ? "10px" : "0px",
            width: avatarWidth,
          }}
        >
          <Slider {...sliderSettings}>
            {assignedRepresentatives.map(({ representative }) => {
              return (
                <StyledTooltip
                  placement="top"
                  arrow
                  title={handleAvatarContent(representative)}
                  key={representative?.id}
                  PopperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: { offset: [0, -5] },
                      },
                    ],
                  }}
                >
                  <StyledAvatar
                    className={classes.assignedAvatar}
                    name={representative?.name}
                    src={photoUrl(representative?.profilePhoto?.fileName)}
                    stringStyle={{ fontSize: 10 }}
                  />
                </StyledTooltip>
              );
            })}
          </Slider>
        </Box>
      )}
    </Stack>
  );
};

RouteItem.propTypes = {
  route: object,
  stateKey: string,
  onRouteChange: func,
};
