import { object, func } from "prop-types";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { TitleBlock } from "./components";
import {
  AccordionComponent,
  DetailsComponent,
  LargeCheckbox,
  SummaryComponent,
} from "components";
import { ShevronIcon } from "components/Icons";
import { sx } from "./TabPermissionsComponent.styles";
import { normalizeSnakeCaseString } from "helpers/helpers";
import { Controller } from "react-hook-form";

const TabPermissionsComponent = ({ formField, control, setValue }) => {
  const { role, permissions, portalAccess } = formField || {};

  const permissionData = () => {
    const name = (role) => {
      if (role === "SALES") {
        // return "Sales Rep Access";
        return "Web Portal";
      }
      if (role === "MERCHANDISER") {
        // return "Merchandiser Rep Access";
        return "Web Portal";
      }
      return "";
    };

    const temp = { ...permissions };

    delete temp.id;
    delete temp.role;
    delete temp.representativeId;

    const preparedData = Object.keys(temp).map((key) => {
      const values = Object.values(temp[key]);
      const keys = Object.keys(temp[key]);

      const setName = (childKey) => {
        if (childKey === "create_edit") return "Create and edit";

        return (childKey.charAt(0).toUpperCase() + childKey.slice(1)).replace(
          /_/g,
          " "
        );
      };

      return {
        id: key,
        name: normalizeSnakeCaseString(key),
        checked: values.every(Boolean),
        checkedCount: values.filter(Boolean).length,
        totalCount: values.length,
        children: keys.map((childKey, index) => ({
          id: childKey,
          name: setName(childKey),
          value: values[index],
        })),
      };
    });

    return {
      name: name(role),
      data: preparedData,
    };
  };

  const valueCheckbox = (arr) => {
    const allTrue = arr?.every((item) => !!item?.value);
    return {
      checked: allTrue,
    };
  };

  const { name, data } = permissionData();

  return (
    <Box
      sx={{
        width: "100%",
        pl: "36px",
        pr: "26px",
        pt: 4,
        pb: 0.5,
        maxHeight: "464px",
        overflow: "auto",
      }}
    >
      <TitleBlock {...{ name, control }} />

      <Stack mt={2} gap={2}>
        {data.map((item) => {
          const { totalCount, checkedCount, name } = item;
          return (
            <AccordionComponent
              key={name}
              item={item}
              expandedDflt={false}
              sx={sx.accordionComponent}
            >
              <SummaryComponent item={item} sx={sx.summaryComponent}>
                <Grid container alignItems="center" height="100%">
                  <Grid item xs sx={sx.summaryComponentGrid}>
                    <Controller
                      render={({ field }) => {
                        const { checked } = valueCheckbox(item.children);
                        return (
                          <LargeCheckbox
                            disabled={!portalAccess}
                            checked={checked}
                            indeterminate={
                              checkedCount > 0 && checkedCount !== totalCount
                            }
                            indeterminateIcon={21}
                            checkedColor={!portalAccess ? "#C5C5C5" : "#47a06d"}
                            size={18}
                            formSx={{ m: 0 }}
                            sx={{
                              p:
                                checkedCount > 0 && checkedCount !== totalCount
                                  ? "8px"
                                  : "9px",
                              "& svg": {
                                ml:
                                  checkedCount > 0 &&
                                  checkedCount !== totalCount &&
                                  "-1px",
                              },
                            }}
                            onClick={(e) => e.stopPropagation()}
                            {...field}
                            onChange={(e) => {
                              const val = e.target.checked;
                              setValue(`permissions.${item?.id}`, {
                                ...item?.children?.reduce((acc, child) => {
                                  acc[child?.id] = val;
                                  return acc;
                                }, {}),
                              });
                            }}
                          />
                        );
                      }}
                      control={control}
                      name={`permissions.${item?.id}`}
                    />
                    <Typography ml="6px">{item?.name}</Typography>
                  </Grid>

                  <Grid
                    item
                    xs={2}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    {`${checkedCount}/${totalCount}`}
                  </Grid>

                  <Grid item xs={0.8} display="flex" justifyContent="center">
                    <ShevronIcon
                      className="my-ico"
                      width={12}
                      height={6}
                      color="#707070"
                    />
                  </Grid>
                </Grid>
              </SummaryComponent>

              <DetailsComponent>
                <Grid container>
                  <Stack py={1}>
                    {item?.children?.map((c) => (
                      <Box key={c?.name} pl="45px">
                        <Controller
                          render={({ field }) => {
                            return (
                              <LargeCheckbox
                                disabled={!portalAccess}
                                checkedColor={
                                  !portalAccess ? "#C5C5C5" : "#47a06d"
                                }
                                size={18}
                                formSx={{ m: 0 }}
                                label={
                                  <Typography
                                    fontSize={15}
                                    fontWeight={400}
                                    color="#707070"
                                  >
                                    {c?.name}
                                  </Typography>
                                }
                                checked={c?.value}
                                {...field}
                                onChange={() => {
                                  const hasKeyViewInObject =
                                    "view" in
                                    formField?.permissions?.[`${item?.id}`];

                                  if (
                                    c?.name !== "View" &&
                                    hasKeyViewInObject &&
                                    !formField?.permissions?.[`${item?.id}`]
                                      ?.view &&
                                    !c?.value
                                  ) {
                                    setValue(
                                      `permissions.${item?.id}.view`,
                                      true
                                    );
                                  }

                                  if (
                                    c?.name === "View" &&
                                    formField?.permissions?.[`${item?.id}`]
                                      ?.view
                                  ) {
                                    setValue(`permissions.${item?.id}`, {
                                      ...item?.children?.reduce(
                                        (acc, child) => {
                                          acc[child?.id] = false;
                                          return acc;
                                        },
                                        {}
                                      ),
                                    });
                                  }

                                  setValue(
                                    `permissions.${item?.id}.${c.id}`,
                                    !c?.value
                                  );
                                }}
                              />
                            );
                          }}
                          control={control}
                          name={`permissions.${item?.id}.${c.id}`}
                        />
                      </Box>
                    ))}
                  </Stack>
                </Grid>
              </DetailsComponent>
            </AccordionComponent>
          );
        })}
      </Stack>
    </Box>
  );
};

TabPermissionsComponent.propTypes = {
  control: object,
  formField: object,
  setValue: func,
};
TabPermissionsComponent.defaultProps = {
  control: {},
  formField: {},
  setValue: () => {},
};

export default TabPermissionsComponent;
