/* eslint-disable react/prop-types */
import React from "react";

export const InformationIcon = ({
  stroke = "#47A06D",
  size = "9.853",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 10 10"
      {...props}
    >
      <g
        id="Icon_feather-info"
        data-name="Icon feather-info"
        transform="translate(0.4 0.4)"
      >
        <path
          id="Path_2549"
          data-name="Path 2549"
          d="M12.053,7.526A4.526,4.526,0,1,1,7.526,3,4.526,4.526,0,0,1,12.053,7.526Z"
          transform="translate(-3 -3)"
          fill="none"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.8"
        />
        <path
          id="Path_2550"
          data-name="Path 2550"
          d="M18,19.811V18"
          transform="translate(-13.474 -13.474)"
          fill="none"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.8"
        />
        <path
          id="Path_2551"
          data-name="Path 2551"
          d="M18,12h0"
          transform="translate(-13.474 -9.284)"
          fill="none"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.8"
        />
      </g>
    </svg>
  );
};
