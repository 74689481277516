/* eslint-disable react/prop-types */
import React from "react";

export const SendIcon = ({ fill = "#5f6267" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.599"
      height="14.597"
      viewBox="0 0 14.599 14.597"
    >
      <path
        id="Icon_ionic-ios-send"
        data-name="Icon ionic-ios-send"
        d="M18.642,4.536,4.684,10.619A.32.32,0,0,0,4.7,11.2l3.776,2.133a.609.609,0,0,0,.7-.068l7.445-6.418c.049-.042.167-.122.213-.076s-.027.163-.068.213l-6.441,7.255a.607.607,0,0,0-.061.726l2.468,3.958a.321.321,0,0,0,.578-.008L19.071,4.958A.32.32,0,0,0,18.642,4.536Z"
        transform="translate(-4.503 -4.503)"
        fill={fill}
      />
    </svg>
  );
};
