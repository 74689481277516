import { bool, object, array } from "prop-types";
import { StyledTooltip } from "components";
import { Box, Typography } from "@mui/material";
import { InformationIcon } from "components/Icons";

export const AvailableBlock = ({
  isMissingInfo,
  hideAvailable,
  outOfStock,
  product,
  isNonInventory,
}) => {
  return (
    <>
      {!!isMissingInfo?.length && (
        <StyledTooltip
          arrow
          title={
            <Box textAlign="left">
              <Typography fontSize={12}>Missing info:</Typography>
              {isMissingInfo?.map((info) => (
                <Typography key={info} fontSize={12} fontWeight={300}>
                  {info}
                </Typography>
              ))}
            </Box>
          }
          placement="top"
          PopperProps={{
            modifiers: [
              {
                name: "offset",
                options: { offset: [0, -6] },
              },
            ],
          }}
        >
          <Box display="flex" alignItems="center">
            <InformationIcon size="16" stroke="#FF6969" />
          </Box>
        </StyledTooltip>
      )}

      {!isMissingInfo?.length && !hideAvailable && (
        <Typography fontSize={12} fontWeight={400} color="#1C1C19" noWrap>
          {isNonInventory
            ? "-"
            : outOfStock
            ? "Out of stock"
            : `${
                product.inventory?.onHand - product.inventory?.allocated
              } available`}
        </Typography>
      )}
    </>
  );
};

AvailableBlock.propTypes = {
  isMissingInfo: array,
  hideAvailable: bool,
  outOfStock: bool,
  product: object,
  isNonInventory: bool,
};
AvailableBlock.defaultProps = {
  isMissingInfo: [],
  hideAvailable: false,
  outOfStock: false,
  product: {},
  isNonInventory: false,
};
