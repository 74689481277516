import { useCallback, useEffect, useMemo, useState } from "react";
import { ROLES, TYPES, useRepsActions } from "helpers/useRepsActions";
import { getTasksTotalSummariesService } from "services/tasks";
import { handleError } from "helpers/helpers";

export const useTasksTotalSummaries = () => {
  const {
    list: repsList,
    count: repsCount,
    handleFetchReps,
    page: repsPage,
    loading: repsLoading,
  } = useRepsActions({
    type: TYPES.reps,
    params: {
      exclude_roles: JSON.stringify([ROLES.third_party]),
      active: true,
      limit: 500,
    },
  });

  const [currentRepresentative, setCurrentRepresentative] = useState("");

  const handleSetCurrentRepresentative = (data) => {
    setCurrentRepresentative(data);
  };

  const salesList = useMemo(() => {
    return Object.values(
      repsList
        .filter((el) => el.role === "SALES")
        .reduce((acc, cur) => Object.assign(acc, { [cur.id]: cur }), {})
    );
  }, [repsList]);

  const merchandisersList = useMemo(() => {
    return Object.values(
      repsList
        .filter((el) => el.role === "MERCHANDISER")
        .reduce((acc, cur) => Object.assign(acc, { [cur.id]: cur }), {})
    );
  }, [repsList]);

  const [totalSummariesState, setTotalSummariesState] = useState({
    loadingTotalSummaries: true,
    countCompletedTasks: null,
    countPendingTasks: null,
    countTasks: null,
  });

  const fetchTotalSummaries = useCallback(async () => {
    try {
      const params = {
        representative_id: currentRepresentative || undefined,
      };

      setTotalSummariesState((prev) => ({
        ...prev,
        loadingTotalSummaries: true,
      }));

      const { countCompletedTasks, countPendingTasks, countTasks } =
        await getTasksTotalSummariesService(params);

      setTotalSummariesState((prev) => ({
        ...prev,
        countCompletedTasks: countCompletedTasks || 0,
        countPendingTasks: countPendingTasks || 0,
        countTasks: countTasks || 0,
      }));
    } catch (error) {
      handleError(error);
    } finally {
      setTotalSummariesState((prev) => ({
        ...prev,
        loadingTotalSummaries: false,
      }));
    }
  }, [currentRepresentative]);

  useEffect(() => {
    fetchTotalSummaries();
  }, [currentRepresentative, fetchTotalSummaries]);

  return {
    repsList,
    repsCount,
    currentRepresentative,
    setCurrentRepresentative,
    handleSetCurrentRepresentative,
    handleFetchReps,
    salesList,
    merchandisersList,
    repsPage,
    repsLoading,

    totalSummariesState,
  };
};
