/* eslint-disable react/prop-types */
import React from "react";

export const ThirdPartyItemIcon = ({
  width = "28",
  height = "30.755",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 28 30.755"
      style={{ position: "absolute", top: "5px" }}
      {...props}
    >
      <g id="order" transform="translate(-2 -0.725)">
        <path
          id="Path_5545"
          data-name="Path 5545"
          d="M11.046,30.662,9.9,28.636a.5.5,0,0,1,.189-.681l2.027-1.146"
          fill="none"
          stroke="#5f6267"
          strokeLinecap="round"
          strokeWidth="1.2"
        />
        <path
          id="Path_5547"
          data-name="Path 5547"
          d="M16.115,15.918"
          fill="none"
          stroke="#5f6267"
          strokeLinecap="round"
          strokeWidth="1.2"
        />
        <path
          id="Path_5550"
          data-name="Path 5550"
          d="M21.218,1.524l.993,2.106a.5.5,0,0,1-.239.665l-2.106.993"
          fill="none"
          stroke="#5f6267"
          strokeLinecap="round"
          strokeWidth="1.2"
        />
        <path
          id="Path_5551"
          data-name="Path 5551"
          d="M10.262,27.9a.583.583,0,0,0,.166.952A14.007,14.007,0,0,0,28.847,10.428a.583.583,0,0,0-.952-.166h0a.629.629,0,0,0-.127.694A12.807,12.807,0,0,1,10.955,27.768a.629.629,0,0,0-.694.127ZM22.42,4.423a.628.628,0,0,1-.724.112A12.805,12.805,0,0,0,4.534,21.7a.628.628,0,0,1-.112.724h0a.583.583,0,0,1-.937-.137,14.005,14.005,0,0,1,18.8-18.8.583.583,0,0,1,.137.937Z"
          fill="#5f6267"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
};
