import axios from "axios";

export const getCustomOrdersService = async (type, data, params, isAi) => {
  return axios({
    method: "post",
    responseType: isAi ? "json" : "blob",
    url: `/reports/excel/${type}`,
    params,
    data,
  }).then((res) => res);
};

export const editFavoriteReportsService = async (data) => {
  return await axios
    .post("/users/distributors/favourite-report-list", data)
    .then((res) => res.data);
};

export const removeFavoriteReportService = async (id) => {
  return await axios
    .delete(`/users/distributors/favourite-report-list/${id}`)
    .then((res) => res.data);
};
