import { number } from "prop-types";
import { Box, Typography } from "@mui/material";
import { useMemo } from "react";
import { MainLogoIcon } from "../../../components/Icons/MainLogos";

export const HeaderRegistration = ({ step }) => {
  const setHeight = useMemo(() => {
    if (step === 1) return "238px";
    if (step === 2 || step === 2.1) return "250px";
    if (step === 3) return "290px";
  }, [step]);

  return (
    <Box
      sx={{
        height: setHeight,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        borderBottom: "0.5px solid #D5D9D9",
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <MainLogoIcon width={307} height={45} />
      </Box>
      <Box
        sx={{
          height: step !== 1 ? "70px" : "44px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: 25,
            fontWeight: 500,
            color: "#5F6267",
          }}
        >
          Create Wholesale Account
        </Typography>
      </Box>
    </Box>
  );
};

HeaderRegistration.propTypes = { step: number };
HeaderRegistration.defaultProps = { step: 2 };
