import { useCallback, useEffect, useState } from "react";
import { bool, string } from "prop-types";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  FormControl,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Button,
  Typography,
  Box,
  Grid,
} from "@mui/material";

import {
  PageTabComponent,
  SquareButton,
  ContactsSection,
  StyledTextField,
  UploadFile,
  Loader,
  StyledTooltip,
} from "components";
import { defaultValues } from "./ManufacturerPage.constants";
import {
  createManufacturerAction,
  deleteManufacturerAction,
  updateManufacturerAction,
} from "redux/actions/manufacturers";
import { photoUrl, useAdmin } from "helpers/helpers";
import { useDebounce } from "helpers/hooks";
import {
  checkManufacturerAvailabilityService,
  getManufacturerByIdService,
} from "services/manufacturers";
import { error } from "utils/notifications";
import useStyles from "./styles";
import { validationSchema } from "./ManufacturerPage.validations";

import {
  setEditTypeAction,
  setFormChangedAction,
  openConfirmDialogAction,
  setConfirmIsOpenAction,
  openDiscardChanges,
} from "redux/actions/confirmDialogs";
import { IosArrowForward } from "components/Icons";

const ManufacturerPage = ({ isEdit, navigatePath }) => {
  const isAdmin = useAdmin();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { manufacturerId } = useParams();
  const [newContactOpen, setNewContactOpen] = useState(false);
  const [photoHovered, setPhotoHovered] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editManufacturer, setEditManufacturer] = useState(null);

  const { pathname, state } = useLocation();

  const replaceNullWithEmptyString = (obj) => {
    if (!obj) return {};
    const newObj = {};
    Object.keys(obj).forEach((key) => {
      if (obj[key] === null) {
        newObj[key] = "";
      } else {
        newObj[key] = obj[key];
      }
    });
    return newObj;
  };

  const preparedEditManufacturer = replaceNullWithEmptyString(editManufacturer);

  const {
    control,
    setValue,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
    setError,
    clearErrors,
    trigger,
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues: { ...defaultValues, ...preparedEditManufacturer },
    resolver: yupResolver(validationSchema()),
  });

  const validationNames = Object.keys(validationSchema()?.fields);

  const [duplicateNamesError, setDuplicateNamesError] = useState([]);
  const formChanged = useSelector(
    ({ confirmDialogs }) => confirmDialogs.formChanged
  );

  const [progressPhotoUpload, setProgressPhotoUpload] = useState(false);
  const [preparingPhoto, setPreparingPhoto] = useState(false);

  useEffect(() => {
    const arr = [];
    if (errors?.customId?.type === "customIdAlreadyCreated")
      arr.push("customId");
    if (errors?.name?.type === "duplicateName") arr.push("name");

    setDuplicateNamesError(arr);
  }, [errors?.customId?.type, errors?.name?.type]);

  const formField = useWatch({ control });

  const nameDebounced = useDebounce(formField.name, 500);
  const customIdDebounced = useDebounce(formField.customId, 500);

  useEffect(() => {
    dispatch(setFormChangedAction(isDirty));
  }, [isDirty, dispatch]);

  useEffect(() => {
    dispatch(setEditTypeAction("manufacturer", !isEdit));
  }, [dispatch, isEdit]);

  const handleCheckNameDuplicate = useCallback(() => {
    if (!nameDebounced) return clearErrors("name");

    checkManufacturerAvailabilityService({ name: nameDebounced }).then(
      (res) => {
        if (!res.available && res.manufacturer?.id !== formField.id) {
          return setError("name", {
            type: "duplicateName",
            message: "This manufacturer name is already used",
          });
        }
        clearErrors("name");
      }
    );
  }, [nameDebounced, setError, formField.id, clearErrors]);

  const containsSpecialChars = (str) => {
    const regex = /[:\t\n]/;

    return regex.test(str);
  };

  useEffect(() => {
    // if (!nameDebounced) {
    //   clearErrors("name");
    //   return;
    // }

    const containsSpecial = containsSpecialChars(nameDebounced);

    if (containsSpecial) {
      setError("name", {
        type: "character",
        message: "Invalid character used",
      });
    } else {
      clearErrors("name");
    }

    if (!containsSpecial) handleCheckNameDuplicate();
  }, [clearErrors, handleCheckNameDuplicate, nameDebounced, setError]);

  useEffect(() => {
    if (!customIdDebounced) {
      clearErrors("customId");
      return;
    }
    checkManufacturerAvailabilityService({ custom_id: customIdDebounced })
      .then((res) => {
        if (!res.available && res.manufacturer?.id !== formField.id)
          return setError("customId", {
            type: "customIdAlreadyCreated",
            message: "Manufacturer with this ID already created.",
          });
        clearErrors("customId");
      })
      .catch((err) => error(err?.response?.data?.message));
  }, [customIdDebounced, formField.id, setError, clearErrors]);

  useEffect(() => {
    if (manufacturerId) {
      setLoading(true);
      getManufacturerByIdService(manufacturerId)
        .then((manufacturer) => {
          setLoading(false);

          setEditManufacturer(manufacturer);
        })
        .catch((err) => {
          setLoading(true);
          error(err?.message);
        });
    }
  }, [manufacturerId]);

  useEffect(() => {
    reset({ ...defaultValues, ...preparedEditManufacturer });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editManufacturer, reset]);

  const onSubmit = useCallback(
    (data) => {
      setLoading(true);
      if (errors.customId) return setError("customId", { ...errors.customId });

      const contacts =
        data.contacts?.map(
          ({ fid, role, contactRole, ...customer }) => customer
        ) || [];

      const name = data?.name?.trim();

      const manufacturerData = {
        ...data,
        contacts,
        name,
      };
      if (data?.photo?.groupContentId) {
        manufacturerData.photoId = data.photo?.uuid;
        manufacturerData.groupContentId = data.photo?.groupContentId;
      }
      if (data?.photo?.id) {
        manufacturerData.photoId = data.photo?.id;
      }
      if (data?.photo === null) {
        manufacturerData.photoId = null;
        delete manufacturerData.groupContentId;
      }
      if (data?.groupContentId) {
        manufacturerData.groupContentId = data?.groupContentId;
      }
      const {
        id,
        photo,
        organizationId,
        distributorId,
        quickbooksManufacturerId,
        products,
        _count,
        ...uploadData
      } = manufacturerData;

      if (data.id)
        dispatch(
          updateManufacturerAction({
            id: data.id,
            data: uploadData,
            navigate,
            onSuccess: () => setLoading(false),
            onError: () => setLoading(false),
          })
        );
      else
        dispatch(
          createManufacturerAction({
            data: uploadData,
            onSuccess: () => setLoading(false),
            onError: () => setLoading(false),
            message: true,
            navigate,
          })
        );
      setLoading(false);
    },
    [dispatch, navigate, errors.customId, setError]
  );

  const onError = () => {
    if (errors.customId) setError("customId", { ...errors.customId });
  };

  const handleRemovePhoto = useCallback(async () => {
    setValue("groupContentId", "");
    setValue("photo", null);
  }, [setValue]);

  const handleUploadChange = useCallback(
    (data) => {
      if (data === null) {
        handleRemovePhoto();
        return;
      }
      setValue(
        "photo",
        { fileName: data?.fileName, uuid: data?.uuid },
        {
          shouldDirty: true,
        }
      );
    },
    [setValue, handleRemovePhoto]
  );

  const handleDeleteManufacturer = useCallback(() => {
    dispatch(deleteManufacturerAction(editManufacturer?.id));
    navigate("/catalog", { state: "Manufacturers" });
    dispatch(setConfirmIsOpenAction(false));
  }, [dispatch, editManufacturer?.id, navigate]);

  const handleConfirmChangesDialog = useCallback(() => {
    dispatch(openDiscardChanges(() => navigate(-1, { state: { state } })));
  }, [dispatch, navigate, state]);

  const handleConfirmDelete = useCallback(() => {
    const manufacturerName = editManufacturer?.name;
    dispatch(
      openConfirmDialogAction({
        title: `Delete ${manufacturerName}?`,
        text: (
          <Typography>
            Are you sure you want to delete this manufacturer? <br />
            <span style={{ fontWeight: 400, color: "black" }}>
              All products & discounts{" "}
            </span>
            associated with this manufacturer will be deleted. This can’t be
            undone.
          </Typography>
        ),

        buttons: (
          <>
            <Button
              sx={{
                width: "98px",
                color: "#6A6A6A",
                borderColor: "#D4D4D4",
                fontSize: "13px",
                height: "28px",
              }}
              onClick={() => {
                dispatch(setConfirmIsOpenAction(false));
              }}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              sx={{
                width: "124px",
                color: "#FFFFFF",
                fontSize: "13px",
                height: "28px",
                boxShadow: "none",
              }}
              color="confirmDelete"
              onClick={() => {
                dispatch(setFormChangedAction(false));
                handleDeleteManufacturer();
              }}
              variant="contained"
            >
              Delete
            </Button>
          </>
        ),
      })
    );
  }, [dispatch, editManufacturer?.name, handleDeleteManufacturer]);

  return (
    <>
      <Loader isLoading={loading || progressPhotoUpload || preparingPhoto} />
      <PageTabComponent
        errors={errors}
        title={
          <Box display="inline-flex" gap="15px" alignItems="center">
            <Typography
              variant="contained"
              label="Add Manufacturer"
              component={Link}
              to={{
                pathname: formChanged
                  ? location.pathname
                  : navigatePath || "/catalog",
                state: { state },
              }}
              onClick={() => {
                if (formChanged) {
                  handleConfirmChangesDialog();
                }
              }}
              state={state}
              from={pathname}
              sx={{
                fontSize: "20px",
                cursor: "pointer",
                textDecoration: "none",
              }}
              color="primary"
            >
              Manufacturers
            </Typography>
            <IosArrowForward />

            <p style={{ color: "#5F6267", margin: "0 0 0 4px" }}>
              {!isEdit ? "Add new manufacturer" : "Edit manufacturer"}
            </p>
          </Box>
        }
        form="new-manufacturer-form"
        saveButtons
        isEdit={isEdit}
        handleDelete={handleConfirmDelete}
        navigateState="Manufacturers"
        navigatePath="/catalog"
        validationNames={validationNames}
        duplicateNamesError={duplicateNamesError}
        trigger={trigger}
        formField={formField}
        titleName="manufacturer"
        loading={loading}
      />
      <form
        onSubmit={handleSubmit(onSubmit, onError)}
        id="new-manufacturer-form"
      >
        <Grid container mt="20px" px="32px" spacing="20px">
          <Grid xs={12} xl={6} item>
            <Paper
              sx={{
                // mr: "20px",
                p: "16px 24px",
                height: "427px",
                // width: "50%",
                boxShadow: "none",
                border: "1px solid #F0F0F0",
              }}
            >
              <Typography fontSize="25px" color="#707070">
                {isEdit ? "Edit" : "New"} manufacturer
              </Typography>
              <Box display="flex" pt="7.5px">
                <Box pr="47px" width="50%" mt="18px">
                  <StyledTooltip
                    placement="right"
                    arrow
                    title={errors?.name?.message || ""}
                    open={["duplicateName", "character"].includes(
                      errors?.name?.type
                    )}
                  >
                    <Box>
                      <Controller
                        render={({ field, fieldState: { error } }) => (
                          <StyledTextField
                            label="Name *"
                            size="small"
                            InputProps={{
                              className: classes.textInput,
                            }}
                            InputLabelProps={{
                              className: classes.inputLabel,
                            }}
                            noErrorMessage
                            error={error?.message || ""}
                            {...field}
                          />
                        )}
                        name="name"
                        control={control}
                      />
                    </Box>
                  </StyledTooltip>
                  <Typography className={classes.helperText} mb="29px">
                    This is the name that will be shown on all orders
                  </Typography>

                  <StyledTooltip
                    placement="right"
                    arrow
                    title="This ID is already used"
                    open={errors.customId?.type === "customIdAlreadyCreated"}
                  >
                    <Box>
                      <Controller
                        render={({ field, fieldState: { error } }) => (
                          <StyledTextField
                            label="ID"
                            size="small"
                            InputProps={{
                              className: classes.textInput,
                            }}
                            InputLabelProps={{
                              className: classes.inputLabel,
                            }}
                            error={error?.message || ""}
                            // noErrorMessage
                            errorMsgSx={{
                              bottom: 37,
                              left: 0,
                            }}
                            {...field}
                          />
                        )}
                        name="customId"
                        control={control}
                      />
                    </Box>
                  </StyledTooltip>
                  <Typography className={classes.helperText} mb="29px">
                    You will never see this on your device. This is how the
                    manufacturer will be represented in bulk imports and
                    exports.
                  </Typography>
                  <Controller
                    render={({ field, fieldState: { error } }) => (
                      <StyledTextField
                        label="Email"
                        size="small"
                        InputProps={{
                          className: classes.textInput,
                        }}
                        InputLabelProps={{
                          className: classes.inputLabel,
                        }}
                        error={error?.message || ""}
                        noErrorMessage
                        {...field}
                      />
                    )}
                    name="email"
                    control={control}
                  />
                  <Typography className={classes.helperText}>
                    This is the main email address for this manufacturer.
                  </Typography>
                </Box>
                <Box pl="43px" borderLeft="0.5px solid #D5D9D9">
                  <Typography fontSize="12px" mb="4px" color="#707070">
                    Status
                  </Typography>
                  <Typography
                    fontSize="10px"
                    fontWeight="400"
                    className={classes.helperText}
                  >
                    Control the visibility of the categories and products in the
                    catalog for this manufacturer.
                  </Typography>
                  <Controller
                    render={({ field, fieldState: { error } }) => (
                      <FormControl sx={{ mt: "4px" }} component="fieldset">
                        <RadioGroup
                          defaultValue="ACTIVE"
                          error={error?.message || ""}
                          {...field}
                        >
                          <FormControlLabel
                            value="ACTIVE"
                            label={
                              <Typography
                                sx={{ fontSize: "12px" }}
                                variant="caption"
                                color="groundLighter.main"
                              >
                                Active
                              </Typography>
                            }
                            control={
                              <Radio
                                sx={{
                                  "& svg": {
                                    width: "16px",
                                    height: "16px",
                                  },
                                }}
                              />
                            }
                          />
                          <FormControlLabel
                            value="INACTIVE"
                            label={
                              <Typography
                                sx={{ fontSize: "12px" }}
                                variant="caption"
                                color="groundLighter.main"
                              >
                                Inactive
                              </Typography>
                            }
                            control={
                              <Radio
                                sx={{
                                  "& svg": {
                                    width: "16px",
                                    height: "16px",
                                  },
                                }}
                              />
                            }
                          />

                          <FormControlLabel
                            value="INACTIVE_FOR_CUSTOMERS"
                            label={
                              <Typography
                                sx={{ fontSize: "12px" }}
                                variant="caption"
                                color="groundLighter.main"
                              >
                                Inactive for customers only
                              </Typography>
                            }
                            control={
                              <Radio
                                sx={{
                                  "& svg": {
                                    width: "16px",
                                    height: "16px",
                                  },
                                }}
                              />
                            }
                          />
                        </RadioGroup>
                      </FormControl>
                    )}
                    name="status"
                    control={control}
                  />
                  <Typography
                    fontSize="12px"
                    mt="27px"
                    mb="4px"
                    color="#707070"
                  >
                    Logo
                  </Typography>
                  <Typography
                    fontSize="10px"
                    fontWeight="400"
                    mb="13px"
                    className={classes.helperText}
                  >
                    You can upload a manufacturer-specific logo here.
                  </Typography>
                  <Box
                    sx={{
                      width: "100px",
                      height: "100px",
                      position: "relative",
                    }}
                  >
                    <StyledTooltip
                      title={
                        <Typography textAlign="start" fontSize={12}>
                          • Use at least 500 px by 500 px <br /> • Use white or
                          neutral background
                        </Typography>
                      }
                      PopperProps={{
                        modifiers: [
                          {
                            name: "offset",
                            options: { offset: [0, -6] },
                          },
                        ],
                      }}
                      placement="top"
                      arrow
                    >
                      {isAdmin ? null : (
                        <Box component="span">
                          <UploadFile
                            label=""
                            path="manufacturers/logo"
                            onChange={handleUploadChange}
                            accept="image/png, image/gif, image/jpeg"
                            uploadData={{
                              type: "MANUFACTURER",
                              setGroupContentId: (groupContentId) =>
                                setValue("groupContentId", groupContentId),
                            }}
                            Wrapper={SquareButton}
                            isEmpty={!formField.photo}
                            wrapperProps={{
                              label: "Upload",
                              width: 100,
                              height: 100,
                              fontSize: "12px",
                              iconW: 28.44,
                              iconH: 24.89,
                              onMouseEnter: () => setPhotoHovered(true),
                              onMouseLeave: () => setPhotoHovered(false),
                              styles: {
                                opacity: formField.photo
                                  ? photoHovered
                                    ? 0.8
                                    : 0
                                  : 1,
                                transition: "all .2s ease",
                                "&:hover": {
                                  backgroundColor: formField.photo && "#ffffff",
                                },
                              },
                            }}
                            withCropper
                            dialogSize="small"
                            progressUpload={setProgressPhotoUpload}
                            {...{ preparingPhoto, setPreparingPhoto }}
                          />
                        </Box>
                      )}
                    </StyledTooltip>
                    {formField.photo && (
                      <Box
                        component="img"
                        src={photoUrl(formField?.photo?.fileName)}
                        sx={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          borderRadius: "8px",
                        }}
                      />
                    )}
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Grid>

          <Grid xs={12} xl={6} item>
            <Paper
              sx={{
                height: "427px",
                // width: "50%",
                p: "27px 30px 27px 32px",
                position: "relative",
                boxShadow: "none",
                border: "1px solid #F0F0F0",
              }}
            >
              <ContactsSection
                contacts={formField.contacts}
                setValue={setValue}
                newContactOpen={newContactOpen}
                setNewContactOpen={setNewContactOpen}
                name="contacts"
                type="manufacturer"
              />
            </Paper>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

ManufacturerPage.propTypes = {
  isEdit: bool,
  navigatePath: string,
  navigateState: string,
};

ManufacturerPage.defaultProps = {
  isEdit: false,
};

export default ManufacturerPage;
