import React from "react";

export const TerritoryIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9.544"
      height="10.075"
      viewBox="0 0 9.544 10.075"
      {...props}
    >
      <path
        id="Icon_material-location-city"
        data-name="Icon material-location-city"
        d="M10.863,7.772V4.591L9.272,3,7.681,4.591v1.06H4.5v7.423h9.544v-5.3ZM6.621,12.014H5.56v-1.06h1.06Zm0-2.121H5.56V8.833h1.06Zm0-2.121H5.56V6.712h1.06ZM9.8,12.014H8.742v-1.06H9.8Zm0-2.121H8.742V8.833H9.8Zm0-2.121H8.742V6.712H9.8Zm0-2.121H8.742V4.591H9.8Zm3.181,6.363h-1.06v-1.06h1.06Zm0-2.121h-1.06V8.833h1.06Z"
        transform="translate(-4.5 -3)"
        fill="#3f3f3f"
      />
    </svg>
  );
};
