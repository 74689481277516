import moment from "moment";

export const APPLIES_TO = {
  ALL_PRODUCTS: "All products",
  SPECIFIC_PRODUCTS: "Specific products",
};

export const DISCOUNT_STATUSES = {
  ACTIVE: "Active",
  INACTIVE: "Inactive",
  INACTIVE_FOR_CUSTOMERS: "Inactive for customers only",
};

export const MIN_REQS = {
  NONE: "None",
  MIN_PURCHASE_AMOUNT: "Minimum purchase amount before discount (Currency)",
  MIN_QUANTITY: "Minimum quantity of items (QTY)",
};

export const CUSTOMER_ELIGS = {
  EVERYONE: "Everyone",
  CUSTOMERS: "Specific customers",
  GROUPS: "Specific customer groups",
};

export const defaultValues = {
  id: "",
  name: "",
  type: "PERCENTAGE",
  absoluteDiscount: 0,
  percentDiscount: 0,
  discount: "",
  manufacturer: null,
  productType: "ALL_PRODUCTS",
  startDate: moment().hours(0).minutes(0).seconds(0).format(),
  endDate: "",
  status: "ACTIVE",
  requirements: "NONE",
  minPurchaseAmount: 0,
  minQuantity: 0,
  eligibility: "EVERYONE",
  customers: [],
  parentCustomers: [],
  products: [],
  isLimited: false,
  limit: 0,
  minimum: "",
  hasEndDate: false,
};
