import * as Yup from "yup";

export const validationSchema = () =>
  Yup.object().shape({
    name: Yup.string().trim().required("This field is required"),
    customId: Yup.string()
      .trim()
      .test("customIdValidation", "From 1 to 10 characters", (value) => {
        if (!value) return true;
        return value.length <= 10 && value.length >= 1;
      }),
    email: Yup.string()
      .trim()
      .test("emailValidation", "Enter valid email!", (value) => {
        if (value === "") return true;
        const EMAIL_REGEXP =
          /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d)|(([a-z]|\d)([a-z]|\d|-|\.|_|~)*([a-z]|\d)))\.)+(([a-z])|(([a-z])([a-z]|\d|-|\.|_|~)*([a-z])))$/i; // eslint-disable-line
        return EMAIL_REGEXP.test(value);
      }),
    // contacts: Yup.array().test(
    //   "contactsValidation",
    //   "Add at least one contact.",
    //   (value) => {
    //     return value.length > 0;
    //   }
    // ),
  });
