import React from "react";

export const DollarIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 7.667 7.667"
    >
      <circle
        data-name="Ellipse 1124"
        cx="3.833"
        cy="3.833"
        r="3.833"
        fill="#409a65"
      />
      <path
        data-name="Icon metro-dollar2"
        d="M4.819 4.401a.9.9 0 0 1-.236.624 1.017 1.017 0 0 1-.612.323v.414a.073.073 0 0 1-.076.076h-.32a.077.077 0 0 1-.076-.076v-.414a1.492 1.492 0 0 1-.3-.073 1.454 1.454 0 0 1-.416-.219 1.35 1.35 0 0 1-.11-.089q-.03-.028-.041-.043a.072.072 0 0 1 0-.1l.244-.32a.074.074 0 0 1 .054-.028.059.059 0 0 1 .057.021 1.236 1.236 0 0 0 .575.3.827.827 0 0 0 .175.019.574.574 0 0 0 .337-.1.332.332 0 0 0 .146-.289.24.24 0 0 0-.036-.125.444.444 0 0 0-.079-.1.65.65 0 0 0-.139-.089q-.095-.049-.156-.076t-.189-.077l-.146-.059q-.053-.021-.146-.063t-.148-.073q-.056-.032-.134-.084a.834.834 0 0 1-.127-.1 1.476 1.476 0 0 1-.1-.116.579.579 0 0 1-.084-.137.95.95 0 0 1-.05-.157.822.822 0 0 1-.02-.185.81.81 0 0 1 .232-.573 1.083 1.083 0 0 1 .6-.317v-.425a.077.077 0 0 1 .076-.076h.32a.073.073 0 0 1 .076.076v.421a1.317 1.317 0 0 1 .262.054 1.517 1.517 0 0 1 .206.079 1.141 1.141 0 0 1 .15.089q.071.05.092.069l.036.033a.069.069 0 0 1 .012.09l-.192.346a.064.064 0 0 1-.054.038.075.075 0 0 1-.064-.017l-.034-.028q-.027-.021-.092-.063a1.222 1.222 0 0 0-.139-.08 1.057 1.057 0 0 0-.176-.062.79.79 0 0 0-.2-.027.615.615 0 0 0-.367.1.318.318 0 0 0-.122.376.266.266 0 0 0 .07.1 1.043 1.043 0 0 0 .094.078.834.834 0 0 0 .133.073q.089.041.143.064l.166.065.192.075q.066.027.18.083a1.43 1.43 0 0 1 .179.1 1.511 1.511 0 0 1 .147.118.613.613 0 0 1 .125.15.858.858 0 0 1 .075.181.781.781 0 0 1 .031.223Z"
        fill="#fff"
      />
    </svg>
  );
};
