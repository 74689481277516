/* eslint-disable react/prop-types */
import React from "react";

export const ArrowLeftIcon = ({ color = "#b5b5ac", ...prop }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="5.777"
      height="10.104"
      viewBox="0 0 5.777 10.104"
      {...prop}
    >
      <g
        id="Icon_ionic-ios-arrow-down"
        data-name="Icon ionic-ios-arrow-down"
        transform="translate(5.777) rotate(90)"
      >
        <path
          id="Icon_ionic-ios-arrow-down-2"
          data-name="Icon ionic-ios-arrow-down"
          d="M11.241,15.282l3.82-3.823a.719.719,0,0,1,1.02,0,.728.728,0,0,1,0,1.023l-4.329,4.332a.721.721,0,0,1-1,.021L6.4,12.484a.722.722,0,1,1,1.02-1.023Z"
          transform="translate(-6.188 -11.246)"
          fill={color}
        />
      </g>
    </svg>
  );
};
