/* eslint-disable react/prop-types */
import * as React from "react";
export const PersonIcon = ({ color = "#26874b", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={9.389}
    height={9.389}
    viewBox="0 0 9.389 9.389"
    {...props}
  >
    <path
      data-name="Icon material-person"
      d="M4.694 4.694a2.347 2.347 0 1 0-2.347-2.347 2.347 2.347 0 0 0 2.347 2.347Zm0 1.174C3.128 5.868 0 6.654 0 8.215v1.174h9.389V8.215c0-1.561-3.128-2.347-4.695-2.347Z"
      fill={color}
    />
  </svg>
);
