import { string, func, bool, object } from "prop-types";
import { Grid } from "@mui/material";
import { FooterItem, HeaderItem } from "./components";

export const NoteItemDrawer = ({
  note,
  fileName,
  name,
  role,
  date,
  onEdit,
  onDelete,
  hideEdit,
  hideActions,
  repPermissions,
  repData,
  disabledDelBtn,
  isAdmin,
}) => {
  return (
    <Grid
      sx={{
        border: "1px solid #D5D9D9",
        borderRadius: "4px",
        minHeight: "50px",
      }}
      container
    >
      <HeaderItem
        {...{
          note,
          onEdit,
          onDelete,
          hideEdit,
          hideActions,
          disabledDelBtn,
          isAdmin,
        }}
      />
      <FooterItem
        {...{ fileName, name, role, date, repPermissions, repData }}
      />
    </Grid>
  );
};

NoteItemDrawer.propTypes = {
  note: string,
  fileName: string,
  name: string,
  role: string,
  date: string,
  onEdit: func,
  onDelete: func,
  hideEdit: bool,
  hideActions: bool,
  repPermissions: object,
  repData: object,
  disabledDelBtn: bool,
  isAdmin: bool,
};
