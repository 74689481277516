import { useCallback, useMemo } from "react";
import { object, string, func, bool, oneOf } from "prop-types";
import { Link as NavLink } from "react-router-dom";
import { Box, Chip, Grid, Paper, Typography } from "@mui/material";
import { getCreatedAt, photoUrl } from "helpers/helpers";
import { cl } from "./styles";
import { BREAKPOINTS } from "helpers/useBreakpoint";
import { StyledAvatar } from "components";
import { useSelector } from "react-redux";
import { StoreIcon } from "components/Icons";
import qbLogo from "assets/logos/quickbookLogo.png";
import { useRepsPermissions } from "helpers/hooks";

export const ItemList = ({
  item,
  timeZone,
  onOpenOrder,
  breakpoint,
  handleSetAssignedPopover,
}) => {
  const currentUser = useSelector(({ auth }) => auth?.currentUser);

  const repPermissions = useRepsPermissions();

  const {
    ItemList: { paperOptions, textId, text, textLight },
  } = cl;

  const profilePhoto = useCallback(
    (user) => user?.avatar || photoUrl(user?.profilePhoto?.fileName),
    []
  );

  const CREATED_BY = item?.activities?.[0]?.representativeDuplicate?.name;
  const CREATED_BY_PHOTO =
    item?.activities?.[0]?.representativeDuplicate?.profilePhoto?.fileName;

  const DATA_TYPE = useMemo(
    () => ({
      DISTRIBUTOR: {
        name: "Admin",
        src: currentUser?.profilePhoto?.fileName,
      },
      SALES: {
        name: CREATED_BY || "-",
        src: profilePhoto(item.activities[0]?.representativeDuplicate),
      },
      ORDER_DIRECT: {
        name: CREATED_BY || "Order Direct",
        src:
          CREATED_BY_PHOTO || item?.appCustomer?.profilePhoto?.fileName || "",
      },
      QUICKBOOKS: {
        name: CREATED_BY || "Quickbooks",
        src: qbLogo,
      },
      BACK_OFFICE_WORKER: {
        name: CREATED_BY || "Back Office Worker",
        src: "",
      },
      REPRESENTATIVE: {
        name: CREATED_BY || "Representative",
        src: profilePhoto(item.activities[0]?.representativeDuplicate),
      },
      CUSTOMER: {
        name: CREATED_BY || "Customer",
        src: "",
      },
      SHIP_ENGINE: {
        name: CREATED_BY || "Ship Engine",
        src: "",
      },
    }),
    [
      CREATED_BY,
      CREATED_BY_PHOTO,
      currentUser?.profilePhoto?.fileName,
      item?.activities,
      item?.appCustomer?.profilePhoto?.fileName,
      profilePhoto,
    ]
  );

  const getCreatedBy = useMemo(() => {
    return DATA_TYPE[item?.createdType]?.name || "-";
  }, [DATA_TYPE, item?.createdType]);

  const preparedName = useMemo(() => {
    if (getCreatedBy === "Admin" && !DATA_TYPE[item?.createdType].src) {
      return currentUser?.name;
    }

    return getCreatedBy;
  }, [DATA_TYPE, currentUser?.name, getCreatedBy, item?.createdType]);

  const preparedAvatarData = useMemo(() => {
    return {
      name: preparedName,
      src: DATA_TYPE[item?.createdType].src || "",
      onMouseEnter: (e) =>
        handleSetAssignedPopover(e.target, DATA_TYPE[item?.createdType], true),
      onMouseLeave: (e) =>
        handleSetAssignedPopover(e.target, DATA_TYPE[item?.createdType], false),
    };
  }, [DATA_TYPE, handleSetAssignedPopover, item?.createdType, preparedName]);

  const orderDirectWithoutPhoto =
    getCreatedBy === "Order Direct" && !DATA_TYPE[item?.createdType].src;

  // const disabledCreateEdit = useMemo(() => {
  //   if (repPermissions) {
  //     return !repPermissions?.orders?.create_edit;
  //   }
  // }, [repPermissions]);

  const disabledOrdersView = useMemo(() => {
    if (repPermissions) {
      return !repPermissions?.orders?.view;
    }
  }, [repPermissions]);

  return (
    <Paper component={Grid} {...paperOptions}>
      {/* Id */}
      <Grid
        xs={1.68}
        item
        sx={{
          textDecorationLine: item.orderStatus === "CANCELED" && "line-through",
          textDecorationColor: "#1C1C19",
        }}
      >
        <Typography
          sx={{ ...textId, textDecoration: "none" }}
          component={disabledOrdersView ? "span" : NavLink}
          to={`/orders/${item?.id}`}
          onClick={(e) => {
            if (e.metaKey || e.ctrlKey || disabledOrdersView) return;
            e.stopPropagation();
            onOpenOrder(item?.id);
          }}
        >
          {item?.customId?.customId}
        </Typography>
      </Grid>
      {/* Date */}
      <Grid
        xs={1.8}
        item
        sx={{
          textDecorationLine: item.orderStatus === "CANCELED" && "line-through",
          textDecorationColor: "#1C1C19",
        }}
      >
        <Typography sx={textLight} noWrap>
          {getCreatedAt(item?.createdAt, timeZone)}
        </Typography>
      </Grid>
      {/* Customer */}
      <Grid
        xs={breakpoint !== "xl" ? 4.5 : 5}
        item
        sx={{
          textDecorationLine: item.orderStatus === "CANCELED" && "line-through",
          textDecorationColor: "#1C1C19",
        }}
      >
        <Typography sx={text} noWrap>
          {item?.customer?.displayName || item?.customer?.name}
        </Typography>
        <Typography sx={textLight} noWrap>
          {item?.customer?.shippingAddress?.formatted_address}
        </Typography>
      </Grid>
      {/* Created By */}
      <Grid
        xs={breakpoint !== "xl" ? 2.7 : 2.2}
        item
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          textDecorationLine: item.orderStatus === "CANCELED" && "line-through",
          textDecorationColor: "#1C1C19",
          pr: "10px",
        }}
      >
        {breakpoint !== "xl" ? (
          // Avatar
          <Box>
            {orderDirectWithoutPhoto ? (
              <Box
                sx={{
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                  border: "0.2px solid #26874B",
                  width: "36px",
                  maxWidth: "36px",
                  height: "36px",
                  maxHeight: "36px",
                  backgroundColor: "#E7F5EB",
                }}
                onMouseEnter={preparedAvatarData.onMouseEnter}
                onMouseLeave={preparedAvatarData.onMouseLeave}
              >
                <StoreIcon width={17.07} height={14.2} />
              </Box>
            ) : (
              <StyledAvatar
                sx={{ width: 36, height: 36 }}
                name={preparedAvatarData.name}
                src={preparedAvatarData.src}
                stringStyle={{ fontSize: 12 }}
                onMouseEnter={preparedAvatarData.onMouseEnter}
                onMouseLeave={preparedAvatarData.onMouseLeave}
              />
            )}
          </Box>
        ) : (
          // Name
          <Typography sx={textLight} noWrap>
            {getCreatedBy}
          </Typography>
        )}

        {item?.type === "THIRD_PARTY" && (
          <Chip
            sx={{
              height: "18px",
              width: "65px",
              fontSize: "9px",
              border: "0.5px solid #D5D9D9",
              mx: "9px",
              "& .MuiChip-label": { px: "10px" },
            }}
            label={"3rd Party"}
          />
        )}
      </Grid>
      {/* Total */}
      <Grid
        xs={0.96}
        item
        sx={{
          textDecorationLine: item.orderStatus === "CANCELED" && "line-through",
          textDecorationColor: "#1C1C19",
        }}
      >
        <Typography sx={text} noWrap>
          ${item?.totalAmount?.toFixed(2)}
        </Typography>
      </Grid>
    </Paper>
  );
};

ItemList.propTypes = {
  item: object,
  timeZone: string,
  onOpenOrder: func,
  isThirdParty: bool,
  breakpoint: oneOf(BREAKPOINTS),
  handleSetAssignedPopover: func,
};
