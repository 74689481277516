import { string, any, object } from "prop-types";
import { Box, Typography } from "@mui/material";
import useStyles from "./styles";

const RowInfo = ({ title, label, labelProps }) => {
  const classes = useStyles();
  return (
    <Box className={classes.row}>
      <Typography className={classes.title}>{title}</Typography>
      <Typography
        className={classes.label}
        {...labelProps}
        component={Box}
        noWrap
      >
        {label || "-"}
      </Typography>
    </Box>
  );
};

RowInfo.propTypes = { title: string, label: any, labelProps: object };

export default RowInfo;
