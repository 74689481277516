import { getSettingsTransactionsService } from "../../services/stripe";

export const GET_TRANSACTIONS = "GET_TRANSACTIONS";
export const START_LOADING_TRANSACTIONS = "START_LOADING_TRANSACTIONS";
export const END_LOADING_TRANSACTIONS = "END_LOADING_TRANSACTIONS";
export const GET_TRANSACTIONS_COUNT = "GET_TRANSACTIONS_COUNT";

const getTransactions = (payload) => ({
  type: GET_TRANSACTIONS,
  payload,
});

const getTransactionsCount = (payload) => ({
  type: GET_TRANSACTIONS_COUNT,
  payload,
});

const startLoadingTransactions = () => ({ type: START_LOADING_TRANSACTIONS });
const endLoadingTransactions = () => ({ type: END_LOADING_TRANSACTIONS });

export const getTransactionsAction = (query) => {
  return (dispatch) => {
    dispatch(startLoadingTransactions());
    getSettingsTransactionsService(query)
      .then((res) => {
        dispatch(endLoadingTransactions());
        dispatch(getTransactions(res.rows || []));
        dispatch(getTransactionsCount(res.count || 0));
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err?.message);
        dispatch(endLoadingTransactions());
      });
  };
};
