export const cl = {
  paper: {
    position: "relative",
    height: "52px",
    ":hover": {
      backgroundColor: "#F7F7F7",
    },
    borderBottom: "1px solid #D5D9D9",
    borderRadius: 0,
  },
  line: {
    width: "3px",
    height: "110%",
    backgroundColor: "#42A57F",
    borderRadius: "4px",
    position: "absolute",
    left: "-2px",
  },
  productName: {
    cursor: "pointer",
    textDecoration: "none",
    fontSize: "12px",
    fontWeight: 600,
    color: "#1C1C19",
    "&:hover": {
      textDecoration: "underline",
    },
    width: "100%",
  },
  productNameVariation: {
    fontSize: 12,
    fontWeight: 400,
    color: "#1C1C19",
  },
  manufacturerName: {
    fontSize: "12px",
    fontWeight: 400,
    color: "#1C1C19",
    width: "95%",
  },
  inventoryText: {
    fontSize: "12px",
    fontWeight: 400,
    color: "#47A06D",
  },
  availableText: {
    fontSize: "12px",
    fontWeight: "bold",
    color: "#3F3F3F",
  },
  emptyProduct: {
    objectFit: "contain",
    width: "100%",
    height: "100%",
    borderRadius: "4px",
  },
  productPhotoWrapper: {
    display: "flex",
    width: "16%",
  },
  productPhoto: {
    maxWidth: "38px",
    minWidth: "38px",
    height: "38px",
    backgroundColor: "white",
    border: "1px solid #D5D9D9",
    borderRadius: "4px",
  },
  nameWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "84%",
  },
  gridLeft: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  gridCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  boxWrapper: {
    display: "flex",
    alignItems: "center",
  },
  gridDate: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  link: {
    fontSize: "10px",
    fontWeight: 400,
    color: "#1C1C19",
    display: "flex",
  },
  text: {
    fontSize: "12px",
    fontWeight: 400,
    color: "#1C1C19",
  },
  cancelBtn: {
    width: "98px",
    color: "#6A6A6A",
    borderColor: "#D4D4D4",
    fontSize: "13px",
    height: "28px",
  },
  confirmBtn: {
    width: "98px",
    color: "#FFFFFF",
    fontSize: "13px",
    height: "28px",
    boxShadow: "none",
  },
  selectLabel: {
    position: "absolute",
    bottom: 36,
    left: 0,
    right: 0,
    marginLeft: "auto",
    marginRight: "auto",
    zIndex: 1,
    bgcolor: "#3F3F3F",
    borderRadius: "13px",
    width: "fit-content",
    height: "49px",
    px: "20px",
    alignItems: "center",
  },

  headerItem: {
    display: "flex",
    alignItems: "center",
  },
  sortableHeaderItem: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    flexWrap: "nowrap",
  },
  centredHeaderItem: {
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
  },
  arrowBox: {
    display: "flex",
    cursor: "pointer",
  },
  menu: {
    "@media only screen and (max-width: 1550px)": {
      ".menu": {
        marginLeft: "-20px",
      },
    },
  },
  menuList: { "& > .MuiTypography-root": { color: "#FF6254" } },
};
