import { useMemo } from "react";
import { shape, string, func, array } from "prop-types";
import { Button, Grid, Typography } from "@mui/material";
import { LargeCheckbox } from "../../../../../../components/Checkboxes";

export const ItemListManage = ({
  item,
  checkedCustomers,
  onCheckedCustomer,
}) => {
  const isChecked = useMemo(() => {
    return checkedCustomers.some((el) => el?.id === item?.id);
  }, [checkedCustomers, item?.id]);

  return (
    <Grid
      sx={{
        height: "47px",
        minHeight: "47px",
        "&:not(:last-of-type)": {
          borderBottom: "0.5px solid #D5D9D9",
        },
        alignItems: "center",
      }}
      container
    >
      <Grid display="flex" justifyContent="center" xs={0.6} item>
        <LargeCheckbox
          formSx={{
            marginLeft: 0,
            marginRight: 0,
          }}
          sx={{ paddingLeft: 0, paddingRight: 0, height: "47px" }}
          checked={isChecked}
          onChange={() => onCheckedCustomer(item)}
        />
      </Grid>

      <Grid xs={4.74} item>
        <Typography sx={{ fontSize: 13, color: "#1C1C19" }} noWrap>
          {item?.name}
        </Typography>
        <Typography
          sx={{ fontSize: 13, fontWeight: 300, color: "#1C1C19" }}
          noWrap
        >
          {item?.shippingAddress?.formatted_address}
        </Typography>
      </Grid>
      <Grid xs={5.05} item>
        <Typography sx={{ fontSize: 13, color: "#1C1C19" }} noWrap>
          {item?.territory?.name || "-"}
        </Typography>
      </Grid>
      <Grid
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          pr: 2,
        }}
        xs
        item
      >
        <Typography sx={{ fontSize: 13, color: "#1C1C19" }} noWrap>
          {"-"}
        </Typography>
        <Button
          variant="contained"
          sx={{
            width: 74,
            height: 28,
            fontSize: 15,
            fontWeight: 200,
          }}
        >
          Link
        </Button>
      </Grid>
    </Grid>
  );
};

ItemListManage.propTypes = {
  item: shape({
    name: string,
  }),
  checkedCustomers: array,
  onCheckedCustomer: func,
};
