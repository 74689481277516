export const cl = {
  paperWrapper: {
    maxWidth: "784px",
    overflow: "hidden",
  },
  dialogContentWrapper: {
    width: "784px",
    maxHeight: "500px",
    p: "30px 32.5px",
  },
  actionsWrapper: {
    p: "0 32px 24px",
  },
  actionBtn: {
    height: "27px",
    width: "49px",
    minWidth: "49px",
  },
  tableWrapper: {
    maxHeight: "349px",
    overflow: "hidden",
    border: "0.5px solid #D5D9D9",
    borderRadius: "4px 4px",
  },
  iconBtn: {
    mr: "-8px",
  },
};
