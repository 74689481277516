import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  itemWrapper: {
    width: "100%",
    display: "flex",
    padding: "18px 0px 14px 0px",
  },

  descriptionText: {
    fontSize: "10px",
    marginBottom: "10px",
    marginTop: "5px",
    color: "#707070",
  },

  blockTitle: {
    fontSize: "15px",
    color: "#707070",
  },

  personalDetails: {
    paddingLeft: "27px",
  },

  statusIcon: {
    backgroundColor: ({ statusColor }) => statusColor,
    borderRadius: "50%",
    width: "12px",
    height: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  personalStatus: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
    backgroundColor: ({ statusColor }) => `${statusColor}33`,
    width: "fit-content",
    height: "22.5px",
    padding: "0 9px 0 5px",
    borderRadius: "100px",
    "& .MuiTypography-root": {
      fontSize: "10px",
      color: ({ statusColor }) => statusColor,
    },
  },
}));

export default useStyles;
