import { shape, number, func, array } from "prop-types";
import { Box, Typography } from "@mui/material";

export const ChildItem = ({ category, setValue, ...props }) => {
  return (
    <Box
      key={category?.id}
      sx={{
        "&:hover": { backgroundColor: "#F7F7F7" },
        borderRadius: "4px",
        width: "100%",
        height: "26px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer",
      }}
      onClick={() => {
        setValue("category", category, { shouldDirty: true });
      }}
      {...props}
    >
      <Typography
        sx={{
          pl: "10px",
          fontSize: 12,
          fontWeight: 300,
          color: "#1C1C19",
        }}
      >
        {category?.name}
      </Typography>
    </Box>
  );
};

ChildItem.propTypes = {
  category: shape({
    id: number,
  }),
  categoriesList: array,
  setShowCategories: func,
  setValue: func,
  onClick: func,
};
