import { object } from "prop-types";
import { Box, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { LargeCheckbox } from "components";

export const NotificationBlock = ({ control }) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mt={1.5}
    >
      <Controller
        render={({ field }) => (
          <Box>
            <LargeCheckbox
              formSx={{
                mr: 0,
                flex: "1 1 auto",
                ml: "-9px",
                color: "#000",
              }}
              checked={!!field.value}
              label={
                <Typography ml="-4px" fontSize={12} fontWeight={300}>
                  Send notification to the customer
                </Typography>
              }
              size={16}
              {...field}
            />
          </Box>
        )}
        name="sendNotification"
        control={control}
      />
    </Box>
  );
};

NotificationBlock.propTypes = { control: object };
