import { Dialog, DialogContent } from "@mui/material";
import { Box } from "@mui/system";
import { bool, func, string } from "prop-types";

const ImageFullscreen = ({ isOpen, fullscreenImage, handleClose }) => {
  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="lg">
      <DialogContent
        sx={{
          maxHeight: "90vh",
          p: "10px",
        }}
      >
        <Box
          component="img"
          alt="fullscreen-image"
          src={fullscreenImage}
          sx={{
            width: "100%",
            height: "auto",
            maxHeight: "80vh",
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

ImageFullscreen.propTypes = {
  isOpen: bool,
  fullscreenImage: string,
  handleClose: func,
};

export default ImageFullscreen;
