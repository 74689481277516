import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  pageWrapper: {
    backgroundColor: "#ffffff",
    border: "1px solid #d5d9d9",
    borderRadius: "4px",
    padding: "4.5vh 200px 5.5vh 134px",
    display: "flex",
  },

  pageHeader: {
    display: "flex",
    alignItems: "center",
    gap: "16.5px",
  },

  pageBody: {
    paddingTop: "3.33vh",
    paddingBottom: "1vh",
    display: "flex",
    justifyContent: "center",
  },

  pageHalf: { paddingTop: "3vh" },

  discountInfo: {
    paddingRight: "111px",
    borderRight: "0.5px solid #D5D9D9",
  },

  usageInfo: {
    paddingLeft: "91px",
  },

  title: {
    fontSize: "28px",
    fontWeight: "500",
    color: "#6A6A6A",
    whiteSpace: "nowrap",
  },

  radio: {
    "& svg": {
      height: "15px",
      width: "15px",
    },
  },

  subtitle: {
    fontSize: "16px",
    color: "#6A6A6A",
  },

  subtitle2: {
    fontSize: "14px",
    color: "#6A6A6A",
  },

  searchInput: {
    paddingLeft: "23px",
    backgroundColor: "rgba(213, 217, 217, 0.15)",
    paddingRight: "5px",
  },

  searchLabel: {
    left: 23,
    color: "#707070",
    fontSize: "15px",
    fontWeight: 400,
    transitionProperty: "all",
    top: 1,
    "&.Mui-focused": {
      left: 0,
    },
    "&.MuiFormLabel-filled": {
      left: 0,
    },
  },

  productsTable: {
    maxWidth: "426px",
    border: "0.5px solid #D5D9D9",
    borderRadius: "4px",
    marginTop: "10px",
    overflow: "auto",
    maxHeight: "92px",
    "&::-webkit-scrollbar": {
      width: "3px",
    },
  },

  customersTable: {
    border: "0.5px solid #D5D9D9",
    borderRadius: "4px",
    marginTop: "9px",
    maxHeight: "82px",
    width: "350px",
    maxWidth: "350px",
    overflow: "overlay",
    "& .MuiBox-root": {
      "& .MuiTypography-root": {
        fontSize: "10px",
        color: "#1C1C19",
      },
    },
  },

  groupsTable: {
    border: "0.5px solid #D5D9D9",
    borderRadius: "4px",
    marginTop: "9px",
    maxHeight: "82px",
    overflow: "overlay",
    "& .MuiBox-root": {
      "& .MuiTypography-root": {
        fontSize: "10px",
        color: "#1C1C19",
      },
    },
  },
  textInputSearch: {
    fontSize: 14,
    height: "32px",
    paddingLeft: "18px",
  },
  inputLabel: {
    fontSize: 14,
    lineHeight: 1.5,
    top: -4,
    color: "#B5B5AC",
    "&.Mui-focused": {
      color: "#000000",
    },
  },
}));

export default useStyles;
