import React from "react";

// eslint-disable-next-line react/prop-types
export const MapPinRedIcon = ({ fill = "#a04747" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="36"
      height="43"
      viewBox="0 0 36 43"
    >
      <defs>
        <filter
          id="Union_165"
          x="0"
          y="0"
          width="36"
          height="43"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g
        id="Group_4046"
        data-name="Group 4046"
        transform="translate(-107 -324)"
      >
        <g
          id="Group_3508"
          data-name="Group 3508"
          transform="translate(117 331)"
        >
          <g transform="matrix(1, 0, 0, 1, -10, -7)" filter="url(#Union_165)">
            <g
              id="Union_165-2"
              data-name="Union 165"
              transform="translate(10 7)"
              fill={fill}
            >
              <path
                d="M 8 23.5 C 7.500929832458496 23.5 7.035069942474365 23.27013969421387 6.721869945526123 22.86935043334961 C 5.758490085601807 21.63754081726074 3.908420085906982 19.16284942626953 2.343169927597046 16.43079948425293 C 0.4565800130367279 13.1378698348999 -0.5 10.39610958099365 -0.5 8.281669616699219 C -0.5 3.439440011978149 3.313080072402954 -0.5 8 -0.5 C 12.68692016601562 -0.5 16.5 3.439440011978149 16.5 8.281669616699219 C 16.5 10.39612007141113 15.54347038269043 13.13788032531738 13.65697956085205 16.43078994750977 C 12.09164047241211 19.16311073303223 10.24170017242432 21.63764953613281 9.278280258178711 22.86951065063477 C 8.965200424194336 23.27013969421387 8.499239921569824 23.5 8 23.5 Z"
                stroke="none"
              />
              {/*<path*/}
              {/*  d="M 8 23 C 8.328129768371582 23 8.65625 22.85346984863281 8.884429931640625 22.56147956848145 C 10.96687030792236 19.89879035949707 16 12.97280025482178 16 8.281669616699219 C 16 3.708189964294434 12.41757011413574 -1.77635683940025e-15 8 -1.77635683940025e-15 C 3.581360101699829 -1.77635683940025e-15 0 3.708189964294434 0 8.281669616699219 C 0 12.97280025482178 5.03341007232666 19.89879035949707 7.115839958190918 22.56147956848145 C 7.344019889831543 22.85346984863281 7.671879768371582 23 8 23 M 8 24 C 7.345970153808594 24 6.736509799957275 23.70010948181152 6.327899932861328 23.17723083496094 C 5.356369972229004 21.93498992919922 3.490109920501709 19.43852996826172 1.909319996833801 16.67934989929199 C -0.02115999907255173 13.30980968475342 -1 10.48441982269287 -1 8.281669616699219 C -1 3.163739919662476 3.03737998008728 -1 8 -1 C 12.96261978149414 -1 17 3.163739919662476 17 8.281669616699219 C 17 10.48441982269287 16.02120971679688 13.30980968475342 14.0908203125 16.67934036254883 C 12.50994968414307 19.43877983093262 10.64381980895996 21.93510055541992 9.672140121459961 23.17753028869629 C 9.263759613037109 23.70010948181152 8.654199600219727 24 8 24 Z"*/}
              {/*  stroke="none"*/}
              {/*  fill="#f4f7f4"*/}
              {/*/>*/}
            </g>
          </g>
        </g>

        <circle
          id="Ellipse_691"
          data-name="Ellipse 691"
          cx="3"
          cy="3"
          r="3"
          transform="translate(122 336)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};
