import axios from "axios";

export const createManufacturerService = async (data) => {
  return axios.post("/manufacturers", data).then((res) => res.data);
};

export const getManufacturersService = async (query) => {
  return axios.get("/manufacturers", { params: query }).then((res) => res.data);
};

export const getManufacturerByIdService = async (id) => {
  return axios.get(`/manufacturers/${id}`).then((res) => res.data);
};

export const updateManufacturerService = async (id, data) => {
  return axios.put(`/manufacturers/${id}`, data).then((res) => res.data);
};

export const deleteManufacturerService = async (id) => {
  return axios.delete(`/manufacturers/${id}`).then((res) => res.data);
};

export const checkManufacturerAvailabilityService = (params) => {
  return axios
    .get("manufacturers/check-availability", { params })
    .then((res) => res.data);
};
