import { styled } from "@mui/material";
import { StyledButton, StyledSelect } from "components";

const FilterWrap = styled("div")(({ theme: { spacing, palette } }) => ({
  border: `0.5px solid ${palette.border.main}`,
  padding: spacing(1.5, 0, 1.5, 1.65),
  backgroundColor: palette.common.white,
  minHeight: spacing(8),
  display: "flex",
  alignItems: "center",
  gap: spacing(2),
}));

const FilterSelect = styled(StyledSelect)(({ theme: { palette } }) => ({
  background: palette.primary.transparent,
  color: palette.primary.main,
  height: "39px",
  width: "100%",
  "& fieldset": {
    border: "none",
  },

  "& .MuiSelect-select": {
    padding: "0!important",
    paddingLeft: "15px !important",

    height: "39px",
    lineHeight: "39px",
    fontSize: "20px",
    width: "100%",
  },

  "& .MuiSvgIcon-root": {
    right: "4px",
  },
}));

const FilterButton = styled(StyledButton)(({ theme: { palette } }) => ({
  border: `0.5px solid ${palette.border.main}`,
  width: "100%",
  maxWidth: "74px",
}));

export { FilterWrap, FilterSelect, FilterButton };
