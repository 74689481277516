import { useMemo, useRef, useState } from "react";
import { string, object, number, func } from "prop-types";
import { Box, Typography } from "@mui/material";
import useStyles from "../../styles";
import { useNavigate } from "react-router-dom";
import { ArrowLeftIcon, IosArrowForward } from "components/Icons";
import { StyledButton, ValidationPopper } from "components";

export const HeaderBlock = ({
  navigatePath,
  navigateState,
  routeId,
  formField,
  customersByMissingInfoCount,
  customersState,
  trigger,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const refSaveBtn = useRef();

  const [isOpen, setIsOpen] = useState(false);

  const tooltipTitle = useMemo(() => {
    let title = "";
    if (!formField?.name) title += "• Add a route name\n";
    if (!formField?.priority) title += "• Set the priority for this route\n";
    if (!formField?.stops?.length)
      title += "• Add at least one stop to proceed\n";
    if (customersByMissingInfoCount) {
      title += `• ${customersByMissingInfoCount} stop${
        customersByMissingInfoCount > 1 ? "s" : ""
      } ${
        customersByMissingInfoCount > 1 ? "are" : "is"
      } missing a shipping address.\n`;
    }
    if (!customersState?.available) {
      title += "• Some stops are not accessible\n";
    }

    return (
      <Typography
        sx={{ whiteSpace: "pre-wrap" }}
        fontSize="12px"
        fontWeight="300"
      >
        {title}
      </Typography>
    );
  }, [
    customersByMissingInfoCount,
    customersState?.available,
    formField?.name,
    formField?.priority,
    formField?.stops?.length,
  ]);

  const getValidation = () => {
    const validationPassed =
      !!formField.name && !!formField.priority && !!formField.stops.length;
    return (
      !validationPassed ||
      !!customersByMissingInfoCount ||
      !customersState?.available
    );
  };

  return (
    <Box className={classes.headerWrapper}>
      <Typography className={classes.pageTitle}>
        <Typography
          onClick={() => {
            navigate(navigatePath || -1, { state: navigateState });
          }}
          sx={{
            fontSize: "20px",
            cursor: "pointer",
            mr: "5px",
          }}
          color="primary"
          component="span"
        >
          Routes
        </Typography>
        <IosArrowForward />
        <span style={{ color: "#5F6267" }}>
          {routeId ? "edit" : "create"} route
        </span>
      </Typography>
      <Box display="flex" alignItems="center" gap={1}>
        <StyledButton
          color="greySecondary"
          label="Back"
          sx={{ p: 0 }}
          className={classes.biggerButton}
          onClick={() => {
            navigate(navigatePath || -1, { state: navigateState });
          }}
          startIcon={<ArrowLeftIcon />}
        />
        <ValidationPopper
          isOpen={isOpen}
          anchorEl={refSaveBtn.current}
          errors={tooltipTitle}
          setIsOpen={setIsOpen}
          tailProps={{
            top: -6,
            right: 44,
            rotate: -180,
          }}
          offset={[0, 16]}
          tooltipTitle={tooltipTitle}
        />
        <Box
          ref={refSaveBtn}
          onMouseEnter={() => {
            trigger();
            if (
              !formField.name ||
              !formField.priority ||
              !formField.stops.length ||
              customersByMissingInfoCount ||
              !customersState?.available
            ) {
              setIsOpen(true);
            }
          }}
          onMouseLeave={() => setIsOpen(false)}
        >
          <StyledButton
            label={`${routeId ? "Update" : "Create"} Route`}
            variant="contained"
            className={classes.headerButton}
            type="submit"
            form="new-route-form"
            disabled={getValidation()}
          />
        </Box>
      </Box>
    </Box>
  );
};

HeaderBlock.propTypes = {
  navigatePath: string,
  navigateState: string,
  routeId: string,
  formField: object,
  customersByMissingInfoCount: number,
  customersState: object,
  trigger: func,
};
HeaderBlock.defaultProps = {};

export default HeaderBlock;
