/* eslint-disable no-useless-escape */
import * as Yup from "yup";
import moment from "moment";

export const validationSchema = ({ editingAddress, isEdit, cardValueType }) =>
  Yup.object().shape({
    name: Yup.string().trim().required("Name on Card is not correct."),
    number:
      !isEdit &&
      Yup.string()
        .trim()
        .required("Card number is not correct.")
        .test("numberVal", "Card number is not correct.", (val) => {
          if (cardValueType?.toLowerCase() === "amex") {
            return val.length === 18;
          } else {
            return val.length === 19;
          }
        }),
    exp:
      !isEdit &&
      Yup.string()
        .trim()
        .required("Expiration date is not correct.")
        .test("expValidation", "Expiration date is not correct.", (val) => {
          const year = "20" + val.split(" / ")[1];
          const month = val.split(" / ")[0];
          const currYear = new Date().getFullYear();
          const currentDate = new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            1
          ).getTime();
          return (
            month > 0 &&
            month <= 12 &&
            year >= currYear &&
            year <= currYear + 8 &&
            !(moment(`${year}.${month}`).valueOf() < currentDate)
          );
        }),
    cvc:
      !isEdit &&
      Yup.string()
        .trim()
        .required("Security code is not correct.")
        .test("cvcValidation", "Security code is not correct.", (val) => {
          if (cardValueType?.toLowerCase() === "amex") {
            return val.length === 4;
          } else {
            return val.length === 3;
          }
        }),
    billingAddress:
      editingAddress &&
      Yup.object().shape({
        formatted_address: Yup.string()
          .nullable()
          .required("Formatted address is not correct."),
        street: Yup.string()
          .nullable()
          .required("Street Address is not correct."),
        city: Yup.string().nullable().required("City is not correct."),
        state: Yup.string().nullable().required("State is not correct."),
        zip: Yup.string().nullable().required("ZIP Code is not correct."),
      }),
    expMonth:
      isEdit &&
      Yup.number()
        .required("Expiration month is required.")
        .when("expYear", {
          is: new Date().getFullYear(),
          then: Yup.number().test(
            "expMonthValidation",
            "Expiration month is not correct.",
            (month) => {
              const currMonth = new Date().getMonth();
              return month > currMonth;
            }
          ),
        }),
  });
