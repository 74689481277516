import { useMemo } from "react";
import { string, oneOf, object } from "prop-types";
import { Box, Typography } from "@mui/material";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { StyledTooltip } from "components";

export const RemovedPhotoItem = ({
  removedBy,
  removedAt,
  type,
  stylesProps = {},
}) => {
  const setTitle = useMemo(() => {
    return (
      <Box textAlign="left">
        <Typography fontWeight={300} fontSize={12}>
          {type} removed by
        </Typography>
        <Typography fontWeight={300} fontSize={12}>
          <Box fontWeight={600} component="span">
            {removedBy}
          </Box>{" "}
          on {removedAt}
        </Typography>
      </Box>
    );
  }, [removedAt, removedBy, type]);

  return (
    <Box>
      <StyledTooltip
        placement="top"
        isDark
        arrow
        title={setTitle}
        disableHoverListener={!removedBy || !removedAt}
      >
        <Box
          sx={{
            width: "57px",
            minWidth: "57px",
            maxWidth: "57px",
            height: "57px",
            minHeight: "57px",
            maxHeight: "57px",
            bgcolor: "rgba(246, 246, 246, 0.83)",
            border: "0.5px solid #D5D9D9",
            borderRadius: "4px",
            fontSize: 8,
            fontWeight: 600,
            color: "#5F6267",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            ...stylesProps,
          }}
        >
          <Typography fontSize="inherit" fontWeight="inherit" color="inherit">
            {type}
          </Typography>
          <RemoveCircleIcon sx={{ color: "#5F6267" }} />
          <Typography fontSize="inherit" fontWeight="inherit" color="inherit">
            Removed
          </Typography>
        </Box>
      </StyledTooltip>
    </Box>
  );
};

RemovedPhotoItem.propTypes = {
  removedBy: string,
  removedAt: string,
  type: oneOf(["Photo", "Note", "Task"]),
  stylesProps: object,
};
RemovedPhotoItem.defaultProps = {
  removedBy: "",
  removedAt: "",
};
