import { useEffect, useState } from "react";
import PropTypes, { bool, func, string, object } from "prop-types";
import {
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Box,
} from "@mui/material";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import {
  confirmDialogIsOpenSelector,
  confirmDialogText1Selector,
  confirmDialogText2Selector,
  confirmDialogTitleSelector,
  confirmDialogUserNameSelector,
  confirmDialogIconSelector,
  confirmDialogButtonsSelector,
  confirmDialogPropBtnsSelector,
  confirmDialogFormChangedSelector,
  editTypeSelector,
  confirmDialogCenteredSelector,
  confirmDialogStylesSelector,
  confirmDialogButtonsStylesSelector,
  confirmDialogIsCancelSelector,
} from "redux/selectors/confirmDialogs";
import { CrossIcon, DangerIcon } from "components/Icons";
import { useLocation, useNavigate } from "react-router-dom";
import {
  openDiscardChanges,
  setConfirmIsOpenAction,
  setConfirmPropBtnsAction,
  setEditItemAction,
  setEditTypeAction,
} from "redux/actions/confirmDialogs";
import { usePrevious } from "helpers/hooks";
import { StyledButton } from "components";

const selector = createSelector(
  confirmDialogTitleSelector,
  confirmDialogText1Selector,
  confirmDialogUserNameSelector,
  confirmDialogText2Selector,
  confirmDialogIsOpenSelector,
  confirmDialogIconSelector,
  confirmDialogButtonsSelector,
  confirmDialogPropBtnsSelector,
  confirmDialogFormChangedSelector,
  editTypeSelector,
  confirmDialogCenteredSelector,
  confirmDialogStylesSelector,
  confirmDialogButtonsStylesSelector,
  confirmDialogIsCancelSelector,
  (
    title,
    text1,
    userName,
    text2,
    isOpen,
    isIcon,
    buttons,
    propBtns,
    formChanged,
    editType,
    centered,
    dialogStyles,
    dialogButtonsStyles,
    isCancel
  ) => ({
    title,
    text1,
    userName,
    text2,
    isOpen,
    isIcon,
    buttons,
    propBtns,
    formChanged,
    editType,
    centered,
    dialogStyles,
    dialogButtonsStyles,
    isCancel,
  })
);

const styles = {
  text: {
    fontSize: "15px",
    fontWeight: 400,
    letterSpacing: 0,
    color: "#707070",
    whiteSpace: "pre-wrap",
  },
};

const UniversalConfirm = () => {
  const {
    title,
    text1,
    userName,
    text2,
    isOpen,
    isIcon,
    buttons,
    propBtns,
    formChanged,
    editType,
    centered,
    dialogStyles,
    dialogButtonsStyles,
    isCancel,
  } = useSelector(selector);
  const { pathname } = useLocation();
  const { item: prevEditType } = usePrevious(editType);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isOpen) setLoading(false);
  }, [isOpen]);

  useEffect(() => {
    dispatch(setEditTypeAction("", false));
    if (!formChanged) dispatch(setEditItemAction(null));
    if (formChanged && editType && prevEditType) {
      dispatch(openDiscardChanges(() => navigate(-1)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, dispatch]);

  return (
    <Dialog
      open={isOpen}
      scroll="body"
      TransitionProps={{
        onExited: () => {
          dispatch(setConfirmPropBtnsAction({ propBtns: null }));
        },
      }}
    >
      <DialogTitle
        sx={{
          fontSize: "20px",
          color: "#080808",
          fontWeight: 400,
          p: "12px 30px 10px 30px",
          m: isCancel ? "8px 0 0 0" : "0 0 1 0",
          position: "relative",
        }}
      >
        {isIcon ? (
          <Box display="flex" alignItems="center">
            <DangerIcon />
            <Box sx={{ ml: "10px" }}>{title}</Box>
          </Box>
        ) : (
          <Box
            display="flex"
            alignItems="center"
            sx={{ justifyContent: centered && "center" }}
          >
            {title}
          </Box>
        )}
        <IconButton
          sx={{ position: "absolute", right: 4, top: 5 }}
          onClick={() => {
            dispatch(setConfirmIsOpenAction(false));
          }}
        >
          <CrossIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{
          ...dialogStyles,
          p: "16px 25px 20px 30px",
          maxWidth: "484px",
          minWidth: "420px",
          textAlign: centered && "center",
          borderBottom: isCancel ? "none" : "0.5px solid #D5D9D9",
        }}
      >
        {userName ? (
          <span>
            <Typography sx={styles.text}>
              {text1}
              {userName ? (
                <span style={{ marginLeft: "5px", color: "#47A06D" }}>
                  {userName}?
                </span>
              ) : (
                ""
              )}
            </Typography>
            <Typography sx={styles.text}>
              {text2 ? <span>{text2}</span> : ""}
            </Typography>
          </span>
        ) : (
          <>
            <Box sx={styles.text}>{text1}</Box>
            <Typography sx={styles.text}>
              {text2 ? <span>{text2}</span> : ""}
            </Typography>
          </>
        )}
      </DialogContent>

      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "14px 25px 15px 30px",
          ...dialogButtonsStyles,
        }}
      >
        {!!buttons && buttons}

        {
          <>
            {!!propBtns?.left && (
              <StyledButton
                onClick={() => dispatch(setConfirmIsOpenAction(false))}
                {...propBtns.left}
              />
            )}

            {!!propBtns?.right && propBtns?.right?.onClick && (
              <StyledButton
                {...propBtns.right}
                onClick={() => {
                  const onConfirm = propBtns.right.onClick;
                  if (loading) return;
                  setLoading(true);
                  onConfirm();
                }}
              />
            )}
          </>
        }
      </DialogActions>
    </Dialog>
  );
};

UniversalConfirm.propTypes = {
  isOpen: bool,
  text: PropTypes.oneOfType([string, object]),
  onCancel: func,
  onAccept: func,
};

UniversalConfirm.defaultProps = {
  isOpen: false,
  text: "Are you sure?",
};

export default UniversalConfirm;
