import { useEffect, useMemo } from "react";
import { bool, func, array } from "prop-types";
import { useDispatch } from "react-redux";
import { useForm, useWatch } from "react-hook-form";
import { uniqBy } from "lodash";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";

import { CrossIcon } from "../Icons";
import { bulkUpdateProductsCategoryActions } from "../../redux/actions/products";
import { CategoriesComponent } from "Pages/CatalogPage/CatalogNewProductPage/ContentTabs/components";

export const ChangeCategoriesDialog = ({ open, onClose, products }) => {
  const dispatch = useDispatch();

  const singleProduct = useMemo(() => {
    if (products?.length === 1) return products[0];
    if (uniqBy(products, (v) => v?.productCategory?.id)?.length === 1)
      return products[0];
  }, [products]);

  const { control, setValue, reset } = useForm({
    mode: "onChange",
    defaultValues: {
      category: singleProduct?.productCategory || "",
    },
  });

  useEffect(() => {
    if (open)
      reset({
        category: singleProduct?.productCategory || "",
      });
  }, [open, singleProduct, reset]);

  const formField = useWatch({ control });

  const handleChangeCategory = () => {
    onClose();
    if (
      !formField?.category?.id ||
      formField?.category?.id === singleProduct?.productCategory?.id
    )
      return;

    dispatch(
      bulkUpdateProductsCategoryActions(
        formField.category?.id,
        products.map((prod) => prod?.id)
      )
    );
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        sx={{
          fontSize: "20px",
          color: "#080808",
          fontWeight: 400,
          p: "10px 30px 10px 30px",
          mb: 1,
          borderBottom: "0.5px solid #CCCCCC",
          position: "relative",
        }}
      >
        <Box display="flex" alignItems="center">
          Change product category?
        </Box>

        <IconButton
          sx={{ position: "absolute", right: 4, top: 5 }}
          onClick={onClose}
        >
          <CrossIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{
          p: "16px 25px 0 30px",
          maxWidth: "484px",
          minWidth: "420px",
        }}
      >
        <Box
          sx={{
            fontSize: "15px",
            fontWeight: 400,
            letterSpacing: 0,
            color: "#707070",
            whiteSpace: "pre-wrap",
          }}
        >
          <Typography fontSize="12px" color="#363531">
            Current category:
            {
              <span style={{ marginLeft: "10px", fontWeight: 400 }}>
                {singleProduct?.productCategory?.name ||
                  singleProduct?.category?.name ||
                  "Multiple choice"}
              </span>
            }
          </Typography>
          <Typography fontSize="12px" color="#363531" mt="12px" mb="6px">
            New category:
          </Typography>
          <CategoriesComponent
            control={control}
            setValue={setValue}
            label=""
            inputProps={{ placeholder: "Search product categories" }}
          />
        </Box>
      </DialogContent>

      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "14px 25px 15px 30px",
        }}
      >
        <Button
          sx={{
            width: "98px",
            color: "#6A6A6A",
            borderColor: "#D4D4D4",
            fontSize: "13px",
            height: "28px",
          }}
          onClick={onClose}
          variant="outlined"
        >
          Cancel
        </Button>

        <Button
          sx={{
            width: "98px",
            color: "#FFFFFF",
            fontSize: "13px",
            height: "28px",
            boxShadow: "none",
          }}
          color="primary"
          onClick={() => {
            handleChangeCategory();
          }}
          variant="contained"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ChangeCategoriesDialog.propTypes = {
  open: bool,
  onClose: func,
  products: array,
};
ChangeCategoriesDialog.defaultProps = {
  open: false,
  onClose: () => {},
  products: [],
};
