import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { func, string } from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import { Box, Chip, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useStyles, { cl } from "./styles";
import { FilterIcon } from "components/Icons";

import { FilterSearchTextField } from "components/TextFields/TextFields";
import StyledButton from "components/StyledButton";
import FilterMenu from "components/FilterMenu";

import {
  repsGetParamsSelector,
  repsShowInactiveSelector,
  repsGetFilterSelector,
  repsGetSwitchSelector,
} from "redux/selectors/reps";
import {
  repsGetParamsAction,
  setShowInactiveReps,
  repsGetFiltersAction,
  repsGetSwitch,
} from "redux/actions/reps";
import { territoryListSelector } from "redux/selectors/territory";

import { useDebounce } from "helpers/hooks";
import { normalizeSnakeCaseString } from "helpers/helpers";
import { UNCATEGORIZED_TERRITORY } from "utils/constants";
import { ActiveTabs } from "components";
import { ColumnsSettingComponent } from "Pages/CustomersPage/components/CustomersTab/components/TabsContent/components/ColumnsSettingComponent/ColumnsSettingComponent";

const selector = createSelector(
  repsShowInactiveSelector,
  repsGetParamsSelector,
  territoryListSelector,
  repsGetFilterSelector,
  repsGetSwitchSelector,
  (
    showInactive,
    repsGetParams,
    territoryList,
    repsFilter,
    repsFilterSwitches
  ) => ({
    showInactive,
    repsGetParams,
    territoryList,
    repsFilter,
    repsFilterSwitches,
  })
);

export const NewRepresentativesFilter = ({ setCheckedUsers, currentTab }) => {
  const classes = useStyles();
  const {
    NewRepresentativesFilter: { wrapper, searchAdor, btnLabel, btnFilter },
  } = cl;

  const {
    showInactive,
    repsGetParams,
    territoryList,
    repsFilter,
    repsFilterSwitches,
  } = useSelector(selector);
  const dispatch = useDispatch();

  const filterAnchor = useRef();

  const [searchInput, setSearchInput] = useState("");
  const searchInputDebounced = useDebounce(searchInput, 500);
  const [filterFields, setFilterFields] = useState(repsFilter);
  const [switches, setSwitches] = useState(repsFilterSwitches);
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);

  const filterChipKeys = useMemo(
    () => Object.keys(filterFields).filter((key) => filterFields[key]),
    [filterFields]
  );

  const handleShowInactive = (e, newVal) => {
    setCheckedUsers([]);
    dispatch(setShowInactiveReps(newVal));

    if (!newVal) return;

    //dispatch(
    //  repsGetParamsAction({
    //    ...repsGetParams,
    //    activity: filterFields?.activity?.value,
    //    territoryId: filterFields?.territoryId?.value,
    //    roles:
    //      currentTab === "All"
    //        ? JSON.stringify(["merchandiser", "sales"])
    //        : undefined,
    //    role:
    //      currentTab.toLowerCase() !== "3rd party"
    //        ? currentTab === "All"
    //          ? ""
    //          : currentTab === "Merchandisers"
    //          ? "merchandiser"
    //          : currentTab.toLowerCase()
    //        : "THIRD_PARTY".toLowerCase(),
    //  })
    //);
  };

  useEffect(() => {
    dispatch(repsGetSwitch(switches));
  }, [dispatch, switches]);

  useEffect(() => {
    dispatch(repsGetFiltersAction(filterFields));
  }, [dispatch, filterFields]);

  useEffect(() => {
    if (currentTab !== "3rd Party") {
      setFilterFields(repsFilter);
      setSwitches(repsFilterSwitches);
    } else {
      setFilterFields({ territoryId: "" });
      setSwitches(repsFilterSwitches);
    }
    dispatch(setShowInactiveReps(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  const handleApplyFilter = (newSwitches, newFields) => {
    setCheckedUsers([]);
    setFilterMenuOpen(false);
    setSwitches([...newSwitches]);
    setFilterFields({ ...newFields });
  };

  const handleDeleteFilter = (key) => {
    const newState = switches;
    const index = switches.findIndex((s) => s.value === key);
    const insert = {
      ...switches[index],
      checked: false,
    };
    newState.splice(index, 1, insert);
    setSwitches([...newState]);
    setFilterFields((prev) => {
      return { ...prev, [key]: "" };
    });
  };

  const getChipLabel = (key) => {
    const icon = filterFields[key]?.icon;
    return (
      <Box display="flex" alignItems="center" gap="6px">
        <Typography
          sx={{ fontSize: "13px", fontWeight: 500 }}
          color="groundLighter.main"
        >
          {normalizeSnakeCaseString(filterFields[key]?.label || key)}:{" "}
          {!icon && (
            <span style={{ color: "#5F6368", marginTop: !!icon && "5px" }}>
              {normalizeSnakeCaseString(
                filterFields[key]?.name || filterFields[key]
              )}
            </span>
          )}
        </Typography>
        {icon}
      </Box>
    );
  };

  const ref = useRef(null);

  useEffect(() => {
    ref.current = showInactive;
  }, [showInactive]);

  const handleSetRepsParamsAndFetchRepsList = useCallback(() => {
    dispatch(
      repsGetParamsAction(
        {
          ...repsGetParams,
          search: searchInputDebounced,
          activity: filterFields?.activity?.value,
          territoryId:
            filterFields?.territoryId?.value === UNCATEGORIZED_TERRITORY.id
              ? "null"
              : filterFields?.territoryId?.value,
          roles:
            currentTab === "All"
              ? JSON.stringify(["merchandiser", "sales"])
              : undefined,
          role:
            currentTab.toLowerCase() !== "3rd party"
              ? currentTab === "All"
                ? ""
                : currentTab === "Merchandisers"
                ? "merchandiser"
                : currentTab.toLowerCase()
              : "THIRD_PARTY".toLowerCase(),
          work_status: filterFields?.work_status,
        },
        ref
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentTab,
    dispatch,
    filterFields?.activity?.value,
    filterFields?.territoryId?.value,
    filterFields?.work_status,
    repsGetParams,
    searchInputDebounced,
  ]);

  useEffect(() => {
    handleSetRepsParamsAndFetchRepsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchInputDebounced,
    showInactive,
    currentTab,
    filterFields?.activity?.value,
    filterFields?.territoryId?.value,
    filterFields?.work_status,
  ]);

  const [territoriesParams, setTerritoriesParams] = useState({ search: "" });
  let filteredTerritories = territoryList.filter((territory) =>
    territory.name
      .toLowerCase()
      .includes(territoriesParams.search.toLowerCase())
  );

  const selectMatchData = useMemo(() => {
    if (currentTab !== "3rd Party")
      return [
        {
          id: "activity",
          childrenList: [
            { id: "NOTE_ADDED", name: "Note added" },
            { id: "CHECKED_IN", name: "Checked in" },
            { id: "PHOTO_GROUP_ADDED", name: "Photo added" },
            { id: "COMMENT_ADDED", name: "Photo comment added" },
            { id: "ORDER_ADDED", name: "Order added" },
            { id: "TASK_ADDED", name: " Created task" },
            { id: "TASK_COMPLETED", name: "Completed task" },
          ],
          label: "Select Latest Activity",
        },
        {
          id: "territoryId",
          childrenList: [
            {
              id: UNCATEGORIZED_TERRITORY.id,
              name: UNCATEGORIZED_TERRITORY.name,
            },
            ...filteredTerritories,
          ],
          setParams: setTerritoriesParams,
          label: "Select Territory",
          searcheble: territoryList.length >= 10,
        },
      ];
    else
      return [
        {
          id: "territoryId",
          childrenList: [
            {
              id: UNCATEGORIZED_TERRITORY.id,
              name: UNCATEGORIZED_TERRITORY.name,
            },
            ...filteredTerritories,
          ],
          label: "Select Territory",
          searcheble: territoryList.length >= 10,
        },
      ];
  }, [currentTab, territoryList, filteredTerritories]);

  return (
    <>
      <Box px={4} mt="12px">
        <Box sx={wrapper}>
          <ActiveTabs value={showInactive} onChange={handleShowInactive} />

          <FilterSearchTextField
            adminIsAllowed
            formSx={{ minWidth: "380px" }}
            placeholder="Search reps by name, email or ID"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            fullWidth
            customAdornment={
              <Box sx={searchAdor}>
                {filterChipKeys.map((key) => (
                  <Chip
                    key={key}
                    className={classes.filterChip}
                    size="small"
                    label={getChipLabel(key)}
                    deleteIcon={
                      <CloseIcon size="15" style={{ fill: "#707070" }} />
                    }
                    onMouseDown={(e) => e.stopPropagation()}
                    onDelete={() => handleDeleteFilter(key)}
                  />
                ))}
              </Box>
            }
            handleClearValue={
              // show the clear button only if the input field has a value and does not have a chip
              filterChipKeys?.length ? null : () => setSearchInput("")
            }
          />

          <StyledButton
            sx={btnFilter}
            fontSize="15px"
            label="Filter"
            startIcon={
              filterChipKeys.length ? (
                <Typography sx={btnLabel}>{filterChipKeys.length}</Typography>
              ) : (
                <FilterIcon />
              )
            }
            variant="outlined"
            ref={filterAnchor}
            color="edit"
            onClick={() => setFilterMenuOpen(true)}
          />
          <FilterMenu
            anchorEl={filterAnchor.current}
            open={filterMenuOpen}
            onClose={() => setFilterMenuOpen(false)}
            filterFields={filterFields}
            switches={switches}
            handleApplyFilter={handleApplyFilter}
            selectMatchData={selectMatchData}
          />
          {currentTab !== "3rd Party" && (
            <Box>
              <ColumnsSettingComponent currentTab={`rep_${currentTab}`} />
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

NewRepresentativesFilter.propTypes = {
  setCheckedUsers: func,
  currentTab: string,
};
NewRepresentativesFilter.defaultProps = {
  checkedUsers: [],
};
