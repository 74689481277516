import React, { createContext } from "react";
import { PageWrap } from "./styles";
import {
  ProspectsFilter,
  ProspectsFilterDialog,
  ProspectsPage,
} from "./components";
import { useProspects } from "./ProspectsTab.hooks";
import ReceivingHoursDrawer from "components/ReceivingHoursDrawer/ReceivingHoursDrawer";
import { useRoutesAssigne } from "../CustomersTab/components/TabsContent/TabMapComponent/useRoutesAssigne";
import { MapRoutesDrawer, NoteDrawer, TaskDrawer } from "components";
import PhotoGroupPopup from "../GalleryTab/components/PhotoGroupPopup/PhotoGroupPopup";

export const ProspectsPageContext = createContext();

const ProspectsTab = () => {
  const {
    filtersState,
    handleOpenFilters,
    handleCloseFilters,
    handleSearchProspects,
    handleDeleteFilter,
    handleChangeFilter,
    setFilter,
    currentMarkersList,
    setListToListState,
    listState,
    onShowActiveCustomersChange,
    activeCustomersState,
    handleSearchChange,
    currentMarkersListBySearch,
    currentMarkerType,
    checkedProspects,
    setCheckedProspects,
    handleCheckProspect,
    handleReplaceOldData,
    handleAddProspectsToState,
    setLoadingToListState,
    handleAddCustomersToState,
    openReceivingHours,
    handleSetHours,
    onCloseReceivingHours,
    weekDays,
    onOpenReceivingHours,
    getActiveCustomers,
    currentUser,
    tooltipState,
    setTooltipState,
    handleOpenProductDialog,
    noteDialogState,
    handleCloseCustomerNotes,
    handleOpenCustomerNotes,
    handleCustomerTaskDrawer,
    taskDialogState,
    onCreateCommentCallback,
  } = useProspects();

  const {
    handleCloseDrawer,
    isDrawerOpen,
    onRouteChange,
    loading: drawerLoading,
    routesList,
    handleOpenDrawer,
    handleUnassignAllRoutes,
    onSubmit,
  } = useRoutesAssigne({
    refreshCallback: () => getActiveCustomers(),
  });

  return (
    <ProspectsPageContext.Provider
      value={{
        filtersState,
        currentMarkersList,
        setListToListState,
        listState,
        onShowActiveCustomersChange,
        activeCustomersState,
        handleSearchChange,
        currentMarkersListBySearch,
        currentMarkerType,
        checkedProspects,
        setCheckedProspects,
        handleCheckProspect,
        handleReplaceOldData,
        handleAddProspectsToState,
        setLoadingToListState,
        handleAddCustomersToState,
        onOpenReceivingHours,
        handleOpenDrawer,
        handleOpenProductDialog,
        handleOpenCustomerNotes,
        handleCustomerTaskDrawer,
      }}
    >
      <PageWrap>
        <PhotoGroupPopup
          hidePagination
          user={{
            avatar: currentUser?.profilePhoto,
            name: currentUser?.name,
            id: currentUser?.id,
          }}
          isOpenProductDialog={tooltipState.photoGroupOpen}
          handleCloseProductDialog={() =>
            setTooltipState((prev) => ({ ...prev, photoGroupOpen: false }))
          }
          createCallback={onCreateCommentCallback}
        />

        <NoteDrawer
          open={noteDialogState.open}
          onClose={handleCloseCustomerNotes}
          data={noteDialogState.data}
          refetchCallback={getActiveCustomers}
          titleLabel="Notes"
        />

        <ProspectsFilterDialog
          open={filtersState.open}
          onClose={handleCloseFilters}
          onSearchProspects={handleSearchProspects}
        />

        <ReceivingHoursDrawer
          isOpen={openReceivingHours && !!Object.keys(weekDays)?.length}
          handleClose={onCloseReceivingHours}
          handleSetData={handleSetHours}
          weekDays={weekDays}
        />

        <MapRoutesDrawer
          onClose={handleCloseDrawer}
          open={!!isDrawerOpen}
          loading={drawerLoading}
          {...{ routesList, handleUnassignAllRoutes, onRouteChange, onSubmit }}
        />

        <TaskDrawer
          open={taskDialogState.open}
          onClose={() => handleCustomerTaskDrawer(false, null)}
          data={taskDialogState.data}
          refetchCallback={() => getActiveCustomers()}
        />

        <ProspectsFilter
          categories={filtersState.categories}
          onOpenFilters={handleOpenFilters}
          handleDeleteFilter={handleDeleteFilter}
          filter={filtersState.filter}
          handleChangeFilter={handleChangeFilter}
        />

        <ProspectsPage
          handleOpenFilters={handleOpenFilters}
          handleDeleteFilter
          categories={filtersState.categories}
          filter={filtersState.filter}
          setFilter={setFilter}
        />
      </PageWrap>
    </ProspectsPageContext.Provider>
  );
};

export default ProspectsTab;
