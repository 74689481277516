import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  menu: {
    width: "285px",
  },

  popperWrapper: {
    backgroundColor: "#ffffff",
    borderRadius: "4px",
    border: "1px solid #D5D9D9",
    minWidth: "285px",
    maxWidth: "320px",
  },

  popperTitleWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "17px 8px 10px 19px",
  },

  popperTitle: { fontSize: "18px", color: "#707070" },

  discountTitleWrapper: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    padding: "6px 21px",
    backgroundColor: "#EEEEEE",
    border: "1px solid #D5D9D9",
    borderLeft: "none",
    borderRight: "none",
  },

  discountTitle: { color: "#000000", fontSize: "12px" },

  ISDWrapper: {
    padding: "7px 0px 9px",
    margin: "0px 21px",
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #D5D9D9",
    "&:last-child": {
      borderBottom: "none",
    },
  },

  discountBlock: {},

  MSDWrapper: {
    padding: "7px 0px 9px",
    margin: "0px 21px",
    borderBottom: "1px solid #D5D9D9",
    "&:last-child": {
      borderBottom: "none",
    },
  },
}));

export default useStyles;
