export const cl = {
  wrapper: {
    height: 40,
    minHeight: 40,
    borderRadius: "0 0 4px 4px",
    "&:not(:last-of-type)": {
      borderBottom: "1px solid #D5D9D9",
      borderRadius: 0,
    },
  },
  grid: {
    display: "flex",
    alignItems: "center",
  },
  gridCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  typographyPlan: {
    fontSize: 12,
    fontWeight: 500,
    color: "#1C1C19",
  },
  typography: {
    fontSize: 12,
    fontWeight: 300,
    color: "#1C1C19",
  },
  invoice: {
    wrapper: {
      display: "flex",
      alignItems: "center",
    },
  },
};
