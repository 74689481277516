import { useMemo } from "react";
import { object } from "prop-types";
import { Box, Typography } from "@mui/material";
import { AtentionIcon } from "components/Icons";

export const WarningBlock = ({ errors }) => {
  const errText = useMemo(() => {
    if (errors?.newPassword?.type === "same")
      return errors?.newPassword?.message;
    if (errors?.currentPassword?.type === "manual")
      return "Password is incorrect";

    return "";
  }, [
    errors?.currentPassword?.type,
    errors?.newPassword?.message,
    errors?.newPassword?.type,
  ]);

  return errText ? (
    <Box px="100px">
      <Box
        sx={{
          border: "1px solid #EB4233",
          borderRadius: "8px",
          backgroundColor: "rgba(235, 66, 51, 0.1)",
          pl: 2,
          minHeight: "54px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box display="flex" alignItems="center" gap={0.6}>
          <Box display="flex" alignItems="center" width="16px" height="16px">
            <AtentionIcon size="16" />
          </Box>
          <Typography sx={{ fontSize: 14, fontWeight: 600, ml: 1 }}>
            {errText}
          </Typography>
        </Box>
      </Box>
    </Box>
  ) : null;
};

WarningBlock.propTypes = {
  errors: object,
};

WarningBlock.defaultProps = {
  errors: {},
};
