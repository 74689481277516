import { Stack } from "@mui/material";
import pluralize from "pluralize";
import { func, array } from "prop-types";
import { useEffect } from "react";
import { PurchasedProductsFilterHeader } from "./PurchasedProductsFilterHeader";
import { PurchasedProductsListItem } from "./PurchasedProductsListItem";

export const PurchasedProductsList = ({
  setTemporaryFields,
  handleOpen,
  productsList,
  handleRemoveProduct,
}) => {
  useEffect(() => {
    if (!productsList.length) return;
    return setTemporaryFields((prev) => ({
      ...prev,
      purchasedProducts: {
        label: "Products Purchased",
        value: productsList,
        name: `${productsList.length} ${pluralize(
          "product",
          productsList.length
        )}`,
      },
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsList.length]);

  return (
    <Stack ml="-2px" mb={productsList?.length ? "20px" : "10px"}>
      <PurchasedProductsFilterHeader {...{ handleOpen }} />
      {!!productsList?.length &&
        productsList.map((product) => (
          <PurchasedProductsListItem
            key={product.id}
            {...{ product, handleRemoveProduct }}
          />
        ))}
    </Stack>
  );
};

PurchasedProductsList.propTypes = {
  setTemporaryFields: func,
  handleOpen: func,
  productsList: array,
  handleRemoveProduct: func,
};

PurchasedProductsList.defaultProps = {
  handleRemoveProduct: () => {},
  setTemporaryFields: () => {},
};
