export const PRODUCTS_HEADER = [
  {
    label: "PRODUCT",
    xs: 3,
    style: {},
  },
  {
    label: "LAST ORDER date",
    xs: 2.2,
    style: {},
  },
  {
    label: "PRICE",
    xs: 1.1,
    style: {},
  },
  {
    label: "ORDERED QTY",
    xs: 1.1,
    style: { justifyContent: "center" },
  },
  {
    label: "REORDER",
    xs: 1.2,
    style: {
      ml: "auto",
    },
  },
];
