export const OPEN_SNACKBAR = "OPEN_SNACKBAR";
export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";
export const SET_SNACKBAR_PROGRESS = "SET_SNACKBAR_PROGRESS";
export const SET_SNACKBAR_COUNT_ORDERS = "SET_SNACKBAR_COUNT_ORDERS";
export const SET_SNACKBAR_STATUS = "SET_SNACKBAR_STATUS";
export const RESET_SNACKBAR = "RESET_SNACKBAR";
export const SET_CONNECT_TO_FIREBASE_SNACKBAR =
  "SET_CONNECT_TO_FIREBASE_SNACKBAR";

const openSnackbar = () => ({
  type: OPEN_SNACKBAR,
});

export const closeSnackbar = () => ({
  type: CLOSE_SNACKBAR,
});

const setSnackbarProgress = (payload) => ({
  type: SET_SNACKBAR_PROGRESS,
  payload,
});

const setSnackbarCountOrders = (payload) => ({
  type: SET_SNACKBAR_COUNT_ORDERS,
  payload,
});

const setSnackbarStatus = (payload) => ({
  type: SET_SNACKBAR_STATUS,
  payload,
});

const setConnectToFirebaseSnackbar = (payload) => ({
  type: SET_CONNECT_TO_FIREBASE_SNACKBAR,
  payload,
});

const resetSnackbar = () => ({
  type: RESET_SNACKBAR,
});

export const setSnackbarAction = ({ progress, countOrders, status }) => {
  return (dispatch) => {
    dispatch(setSnackbarProgress(progress));
    dispatch(setSnackbarCountOrders(countOrders));
    dispatch(setSnackbarStatus(status));

    if (
      typeof progress === "number" &&
      !Number.isNaN(progress) &&
      typeof countOrders === "number" &&
      !Number.isNaN(countOrders) &&
      ["PREPARING_DATA", "PENDING"].includes(status)
    ) {
      dispatch(openSnackbar());
    } else {
      dispatch(closeSnackbar());
    }
  };
};

export const setConnectToFirebaseSnackbarAction = (payload) => {
  return (dispatch) => {
    dispatch(setConnectToFirebaseSnackbar(payload));
  };
};

export const resetSnackbarAction = () => {
  return (dispatch) => {
    dispatch(resetSnackbar());
  };
};
