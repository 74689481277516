/* eslint-disable react/prop-types */
export const GalleryNextIcon = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "38"}
      height={height || "36"}
      viewBox="0 0 38 36"
    >
      <rect
        id="Rectangle_10825"
        data-name="Rectangle 10825"
        width="38"
        height="36"
        rx="18"
        fill="#d4d4d4"
      />
      <g
        id="Icon_ionic-ios-arrow-down"
        data-name="Icon ionic-ios-arrow-down"
        transform="translate(13.611 28.552) rotate(-90)"
      >
        <path
          id="Icon_ionic-ios-arrow-down-2"
          data-name="Icon ionic-ios-arrow-down"
          d="M16.743,20.172l7.98-8.456a1.444,1.444,0,0,1,2.13,0,1.679,1.679,0,0,1,0,2.262l-9.041,9.581a1.446,1.446,0,0,1-2.08.047l-9.1-9.62a1.674,1.674,0,0,1,0-2.262,1.444,1.444,0,0,1,2.13,0Z"
          transform="translate(-6.187 -11.246)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};
