import { getProductTagsService } from "../../services/product-tags";

export const START_LOADING_PRODUCT_TAGS = "START_LOADING_PRODUCT_TAGS";
export const END_LOADING_PRODUCT_TAGS = "END_LOADING_PRODUCT_TAGS";
export const GET_PRODUCT_TAGS_LIST = "GET_PRODUCT_TAGS_LIST";
export const GET_PRODUCT_TAGS_COUNT = "GET_PRODUCT_TAGS_COUNT";

const startLoadingProductTags = () => ({
  type: START_LOADING_PRODUCT_TAGS,
});

const endLoadingProductTags = () => ({
  type: END_LOADING_PRODUCT_TAGS,
});

const getProductTagsList = (payload) => ({
  type: GET_PRODUCT_TAGS_LIST,
  payload,
});

const getProductTagsCount = (payload) => ({
  type: GET_PRODUCT_TAGS_COUNT,
  payload,
});

export const getProductTagsListAction = (query) => {
  return (dispatch, getState) => {
    const state = getState();

    const repPermissions =
      state?.auth?.currentUser?.permissions ||
      state?.auth?.representative?.permissions?.[0];

    // const repPermissions = permissions && permissions?.[0];

    if (repPermissions && !repPermissions?.catalog?.product_tags) return;

    dispatch(startLoadingProductTags());

    getProductTagsService(query)
      .then((res) => {
        dispatch(getProductTagsList(res?.rows));
        dispatch(getProductTagsCount(res?.count));
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err?.message);
      })
      .finally(() => {
        dispatch(endLoadingProductTags());
      });
  };
};
