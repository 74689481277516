export const EmptyMessagesIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="140.962"
      height="121.604"
      viewBox="0 0 140.962 121.604"
    >
      <g
        id="Group_7487"
        data-name="Group 7487"
        transform="translate(-6452.978 -20317.894)"
      >
        <path
          id="Path_5118"
          data-name="Path 5118"
          d="M213.586,73.472l-64.726,45.7,1.2,20.678c0,2.467,3.62,37.437,7.064,37.437l60.437-33.24,54.456,29.827c3.443,0,10.091-32.5,10.091-34.965l4.026-19.786L221.355,73.472A8.241,8.241,0,0,0,213.586,73.472Z"
          transform="translate(6307.301 20245.395)"
          fill="#e2f0e8"
        />
        <path
          id="Path_5119"
          data-name="Path 5119"
          d="M331.368,280.958H226.937c-2.782,0-5.039-1.854-5.039-4.141V189.395c0-2.286,2.256-4.141,5.039-4.141H331.368c2.782,0,5.039,1.854,5.039,4.141v87.423C336.406,279.1,334.15,280.958,331.368,280.958Z"
          transform="translate(6245.495 20149.98)"
          fill="#fff"
          stroke="#efefef"
          strokeWidth="1"
        />
        <g
          id="Group_7484"
          data-name="Group 7484"
          transform="translate(6478.146 20345.463)"
        >
          <path
            id="Path_5126"
            data-name="Path 5126"
            d="M730.062,277.98H715.309a5.9,5.9,0,0,1-5.9-5.9V257.329a5.9,5.9,0,0,1,5.9-5.9h14.753a5.9,5.9,0,0,1,5.9,5.9v14.753A5.9,5.9,0,0,1,730.062,277.98Z"
            transform="translate(-709.41 -251.43)"
            fill="#e2f0e8"
          />
          <path
            id="Path_5127"
            data-name="Path 5127"
            d="M947.056,263.928h-18.7a3.039,3.039,0,0,1,0-6.078h18.7a3.039,3.039,0,0,1,0,6.078Z"
            transform="translate(-890.731 -256.821)"
            fill="#409a65"
          />
          <path
            id="Path_5128"
            data-name="Path 5128"
            d="M982.155,333.267H930.24c-2.716,0-4.92-1.36-4.92-3.039s2.2-3.039,4.92-3.039h51.917c2.716,0,4.92,1.36,4.92,3.039S984.873,333.267,982.155,333.267Z"
            transform="translate(-890.731 -316.437)"
            fill="#e2f0e8"
          />
          <path
            id="Path_5129"
            data-name="Path 5129"
            d="M982.155,402.618H930.24c-2.716,0-4.92-1.36-4.92-3.039s2.2-3.039,4.92-3.039h51.917c2.716,0,4.92,1.36,4.92,3.039S984.873,402.618,982.155,402.618Z"
            transform="translate(-890.731 -376.067)"
            fill="#e2f0e8"
            opacity="0.25"
          />
        </g>
        <g
          id="Group_7485"
          data-name="Group 7485"
          transform="translate(6478.146 20381.502)"
        >
          <path
            id="Path_5126-2"
            data-name="Path 5126"
            d="M730.062,277.98H715.309a5.9,5.9,0,0,1-5.9-5.9V257.329a5.9,5.9,0,0,1,5.9-5.9h14.753a5.9,5.9,0,0,1,5.9,5.9v14.753A5.9,5.9,0,0,1,730.062,277.98Z"
            transform="translate(-709.41 -251.43)"
            fill="#f5f5f5"
          />
          <path
            id="Path_5127-2"
            data-name="Path 5127"
            d="M947.056,263.928h-18.7a3.039,3.039,0,0,1,0-6.078h18.7a3.039,3.039,0,0,1,0,6.078Z"
            transform="translate(-890.731 -256.821)"
            fill="#f5f5f5"
          />
          <path
            id="Path_5128-2"
            data-name="Path 5128"
            d="M982.155,333.267H930.24c-2.716,0-4.92-1.36-4.92-3.039s2.2-3.039,4.92-3.039h51.917c2.716,0,4.92,1.36,4.92,3.039S984.873,333.267,982.155,333.267Z"
            transform="translate(-890.731 -316.437)"
            fill="#f5f5f5"
          />
          <path
            id="Path_5129-2"
            data-name="Path 5129"
            d="M982.155,402.618H930.24c-2.716,0-4.92-1.36-4.92-3.039s2.2-3.039,4.92-3.039h51.917c2.716,0,4.92,1.36,4.92,3.039S984.873,402.618,982.155,402.618Z"
            transform="translate(-890.731 -376.067)"
            fill="#f5f5f5"
          />
        </g>
        <path
          id="Path_5130"
          data-name="Path 5130"
          d="M6493.48,20398.557l61.854-27.492v69.922Z"
          transform="translate(38.106 -6.255)"
          fill="#fff"
          stroke="#efefef"
          strokeWidth="1"
        />
        <path
          id="Path_5131"
          data-name="Path 5131"
          d="M6555.334,20398.557l-61.854-27.492v69.922Z"
          transform="translate(-40.002 -6.255)"
          fill="#fff"
          stroke="#efefef"
          strokeWidth="1"
        />
        <path
          id="Path_5122"
          data-name="Path 5122"
          d="M280.533,551.951v14.557c0,2.176-2.791,3.94-6.234,3.94H146.805c-3.443,0-6.234-1.764-6.234-3.94V551.951l66.1-33.266a8.68,8.68,0,0,1,3.665-.856,8.854,8.854,0,0,1,4.105.856Z"
          transform="translate(6312.907 19868.551)"
          fill="#fff"
          stroke="#efefef"
          strokeWidth="1"
        />
        <g
          id="Group_7486"
          data-name="Group 7486"
          transform="translate(6484.191 20351.191)"
        >
          <path
            id="Path_5132"
            data-name="Path 5132"
            d="M5.206,3H2.682A1.682,1.682,0,0,0,1,4.682v7.57a1.682,1.682,0,0,0,1.682,1.682h7.57a1.682,1.682,0,0,0,1.682-1.682V9.729"
            transform="translate(0 -0.318)"
            fill="none"
            stroke="#409a65"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Path_5133"
            data-name="Path 5133"
            d="M12.523,6.047A2.523,2.523,0,1,0,10,3.523,2.523,2.523,0,0,0,12.523,6.047Z"
            transform="translate(-1.43)"
            fill="none"
            stroke="#409a65"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
      </g>
    </svg>
  );
};
