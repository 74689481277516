import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import { StyledButton } from "components";
import {
  openConfirmDialogAction,
  setConfirmIsOpenAction,
} from "redux/actions/confirmDialogs";
import {
  createNoteService,
  deleteNoteService,
  getCustomerNotesService,
  updateNoteService,
} from "services/notes";
import { error, success } from "utils/notifications";

export const useNoteDrawer = ({ data, viewOnly, onClose, refetchCallback }) => {
  const currentUser = useSelector(({ auth }) => auth.currentUser);
  const { profilePhoto } = currentUser || {};
  const dispatch = useDispatch();

  const [loadingNotes, setLoadingNotes] = useState(false);
  const [notesList, setNotesList] = useState([]);
  const [notesCount, setNotesCount] = useState(0);
  const [page, setPage] = useState(1);
  const SCROLL_LIMIT = 10;

  const [note, setNote] = useState("");
  const [editNote, setEditNote] = useState(null);

  const customerName = data?.customer?.name ?? "";

  const isAdminEdit = useMemo(
    () => !!data && !data?.representativeDuplicate,
    [data]
  );

  const isNoteView = useMemo(
    () => !!data && !!data.representativeDuplicate,
    [data]
  );

  const fetchNotesList = useCallback(
    async (p) => {
      if (!data?.customer?.id) return;
      setLoadingNotes(true);
      try {
        const res = await getCustomerNotesService(data?.customer?.id, {
          page: p ? p + 1 : 1,
          limit: SCROLL_LIMIT,
        });
        setNotesList((prev) => [...prev, ...res?.rows]);
        setNotesCount(res?.count);
        setPage(() => (p ? p + 1 : 1));
        return res;
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        error(err?.response?.data?.message || "Something went wrong.");
      } finally {
        setLoadingNotes(false);
      }
    },
    [data?.customer?.id]
  );

  useEffect(() => {
    if (viewOnly && data) {
      setNote(data.text);
      setEditNote(data);
      return setNotesList([data]);
    }
    if (data?.customer?.id && open) return fetchNotesList();
    setPage(1);
    setNotesList([]);
    setEditNote(null);
    setNote("");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, open, viewOnly]);

  const onCancel = () => {
    setNote("");
    setEditNote(null);
  };
  const onConfirm = async () => {
    setLoadingNotes(true);
    if (editNote?.id) {
      try {
        await updateNoteService({
          noteId: editNote?.id,
          text: note,
        });
        setNote("");
        setEditNote(null);
        setPage(1);
        setNotesList([]);
        setNotesCount(1);
        fetchNotesList();

        if (viewOnly) {
          refetchCallback && refetchCallback();
          onClose();
          success("Note updated successfully!");
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        error(err?.response?.data?.message || "Something went wrong.");
      } finally {
        setLoadingNotes(false);
      }
      return;
    }
    try {
      const createdNote = await createNoteService({
        customerId: data?.customer?.id,
        text: note,
      });
      setNote("");
      const preparedList =
        notesList?.length >= 5
          ? notesList.slice(0, notesList.length - 1)
          : notesList;
      refetchCallback([createdNote, ...preparedList]);
      setNotesList(() => [createdNote, ...preparedList]);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      error(err?.response?.data?.message || "Something went wrong.");
    } finally {
      setLoadingNotes(false);
    }
  };

  const handleEdit = (item) => {
    setEditNote(item);
    setNote(item?.text);
  };

  const handleConfirmDeleteNoteDialog = useCallback(
    (id) => {
      dispatch(
        openConfirmDialogAction({
          title: "Delete note?",
          text: "Are you sure you want to delete this note?",
          buttons: (
            <>
              <StyledButton
                variant="outlined"
                color="cancel"
                label="Cancel"
                sx={{ height: "28px" }}
                onClick={() => {
                  dispatch(setConfirmIsOpenAction(false));
                }}
              />
              <Button
                sx={{
                  width: "auto",
                  height: "28px",
                  color: "#FFFFFF",
                  fontSize: "13px",
                  boxShadow: "none",
                }}
                color="confirmDelete"
                onClick={async () => {
                  try {
                    await deleteNoteService({
                      noteId: id,
                    });
                    setPage(1);
                    setNotesCount(1);
                    refetchCallback && refetchCallback(notesList);
                    setNotesList([]);
                    success("Note deleted successfully!");
                    if (viewOnly) {
                      onClose();
                    } else {
                      const res = await fetchNotesList();
                      if (!res?.rows?.length) onClose();
                    }
                  } catch (err) {
                    // eslint-disable-next-line no-console
                    console.error(err);
                    error(
                      err?.response?.data?.message || "Something went wrong."
                    );
                  }
                  dispatch(setConfirmIsOpenAction(false));
                }}
                variant="contained"
              >
                Confirm
              </Button>
            </>
          ),
        })
      );
    },
    [dispatch, fetchNotesList, notesList, onClose, refetchCallback, viewOnly]
  );

  const handleDelete = async (item) => {
    handleConfirmDeleteNoteDialog(item?.id);
  };

  return {
    editNote,
    customerName,
    notesList,
    notesCount,
    handleEdit,
    handleDelete,
    profilePhoto,
    fetchNotesList,
    note,
    setNote,
    isNoteView,
    isAdminEdit,
    loadingNotes,
    page,
    onCancel,
    onConfirm,
    currentUser,
  };
};
