import React from "react";
import { Box, IconButton } from "@mui/material";
import { func, bool, object } from "prop-types";
import { ArrowUpIcon } from "components/Icons/ArrowUpIcon";
import { ArrowDownIcon } from "components/Icons/ArrowDownIcon";

const ArrowBtn = ({
  onClickUp,
  onClickDown,
  stylesUpBtn,
  stylesDownBtn,
  stylesBox,
  checkDate,
}) => {
  return (
    <>
      <Box sx={stylesBox}>
        <IconButton sx={stylesUpBtn} onClick={onClickUp}>
          <ArrowUpIcon />
        </IconButton>
        <IconButton sx={stylesDownBtn} onClick={onClickDown}>
          <ArrowDownIcon />
        </IconButton>
      </Box>
      {checkDate && (
        <Box
          sx={{
            background: "rgb(247 247 247)",
            height: "32px",
            display: "flex",
            alignItems: "center",
            paddingLeft: "9px",
            paddingRight: "9px",
            borderLeft: " 0.5px solid rgb(213, 217, 217);",
            fontSize: "12px",
          }}
        >
          days
        </Box>
      )}
    </>
  );
};

ArrowBtn.propTypes = {
  onClickUp: func,
  onClickDown: func,
  stylesUpBtn: object,
  stylesBox: object,
  stylesDownBtn: object,
  checkDate: bool,
};

ArrowBtn.defaultProps = {
  onClickUp: () => {},
  onClickDown: () => {},
};

export default ArrowBtn;
