import { useEffect, useMemo } from "react";
import Slider from "react-slick";
import { bool, func, array, number, string } from "prop-types";

import ImageBox from "components/ImageComponent/ImageBox";
import { CrossIcon, GalleryNextIcon } from "components/Icons";

import { photoUrl } from "helpers/helpers";
import { TrashIcon } from "../Icons/ImageEditBtns";

import useStyles from "./styles";

import {
  Box,
  Stack,
  Dialog,
  Typography,
  IconButton,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";

export const PhotoGallery = ({
  open,
  title,
  imageList,
  handleClose,
  customeUserIndex,
  activeSlideIndex,
  handleDeleteImage,
  handleDownloadImage,
  setActiveSlideIndex,
}) => {
  const classes = useStyles();

  const sliderSettings = useMemo(() => {
    return {
      infinite: false,
      speed: 400,
      initialSlide: customeUserIndex || 0,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      beforeChange: (_, next) => setActiveSlideIndex(next),
      customPaging: (index) => {
        const isActive = index === activeSlideIndex;
        return (
          <Box
            className={classes.customPaging}
            sx={{
              border: `1px solid ${isActive ? "#47A06D" : "#5F6267"}`,
              background: isActive ? "#47A06D" : "inherit",
            }}
          />
        );
      },
      nextArrow: (
        <Box top="46%" right="50px" zIndex="10">
          <GalleryNextIcon />
        </Box>
      ),
      prevArrow: (
        <Box
          top="46%"
          left="50px"
          zIndex="10"
          sx={{ transform: "scale(-1, -1) translateY(0%)" }}
        >
          <GalleryNextIcon />
        </Box>
      ),
    };
  }, [
    activeSlideIndex,
    classes.customPaging,
    customeUserIndex,
    setActiveSlideIndex,
  ]);

  useEffect(() => {
    if (customeUserIndex) {
      setActiveSlideIndex(customeUserIndex);
    }
  }, [customeUserIndex, setActiveSlideIndex]);

  return (
    <Dialog width="100wv" fullScreen open={open} onClose={handleClose}>
      <DialogTitle className={classes.titleBlock}>
        <Typography>{title}</Typography>

        <Stack direction="row" gap="5px">
          <IconButton
            onClick={() => handleDeleteImage(imageList[activeSlideIndex])}
            sx={{ width: "40px" }}
          >
            <TrashIcon width="16" height="16" />
          </IconButton>
          <IconButton
            onClick={() => handleDownloadImage(imageList[activeSlideIndex])}
            sx={{ width: "40px", mb: "-2px" }}
          >
            <GetAppIcon width="15px" height="15px" />
          </IconButton>
          <IconButton onClick={handleClose}>
            <CrossIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent className={classes.contentBlock}>
        <Slider {...sliderSettings}>
          {imageList?.map((photo) => (
            <Box key={photo?.id} className={classes.imageWrapper}>
              <ImageBox
                sx={{
                  maxWidth: "650px",
                  maxHeight: "calc(100vh - 60.5px)",
                  zIndex: 10,
                }}
                placeholderSrc={photoUrl(photo?.compressedFileName)}
                src={photoUrl(photo?.fileName)}
              />
              <Box
                className={classes.blurBackground}
                component="img"
                src={photoUrl(photo?.compressedFileName)}
              />
            </Box>
          ))}
        </Slider>
      </DialogContent>
    </Dialog>
  );
};

PhotoGallery.propTypes = {
  open: bool,
  imageList: array,
  title: string,
  activeSlideIndex: number,
  handleClose: func,
  handleDeleteImage: func,
  customeUserIndex: number,
  setActiveSlideIndex: func,
  handleDownloadImage: func,
};

PhotoGallery.defaultProps = {
  open: false,
  title: "",
  imageList: [],
  activeSlide: 0,
  customeUserIndex: 0,
  handleClose: () => {},
  handleDeleteImage: () => {},
  setActiveSlideIndex: () => {},
  handleDownloadImage: () => {},
};
