export const sx = {
  accordionComponent: {
    m: "0!important",
    "& .MuiAccordionSummary-root": {
      backgroundColor: "#F8F8F8",
      borderRadius: "4px",
      p: 0,
      height: "54px !important",
    },
    "& .MuiAccordionSummary-root.Mui-expanded": {
      borderRadius: "4px 4px 0 0",
      borderBottom: "1px solid #D5D9D9",
    },
  },
  summaryComponent: {
    height: "100%",
    "& .MuiTypography-root": {
      fontSize: 16,
      color: "#000",
    },
  },
  summaryComponentGrid: {
    height: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    pl: "12px",
  },
};
