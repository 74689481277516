import { useMemo, useState } from "react";
import { object, bool, func } from "prop-types";
import { Box, Grid, Radio, Typography } from "@mui/material";
import { LargeCheckbox, StyledLabel, StyledTooltip } from "components";
import {
  CheckmarkIcon,
  CrossIcon,
  InformationIcon,
  ProspectStatusIcon,
} from "components/Icons";
import { CUSTOMER_STATUS_FILTERS } from "utils/constants";
import { useAdmin } from "helpers/helpers";

const CustomersItem = ({
  customer,
  isChecked,
  handleCheckCustomer,
  handleCheckOneCustomer,
  viewCustomerPopup,
  allowMissingInfo,
}) => {
  const [hoverColor, setHoverColor] = useState("");
  const isAdmin = useAdmin();

  const isMissingInfo = useMemo(
    () => customer?.missingFields,
    [customer?.missingFields]
  );

  const classes = {
    grid: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      opacity: allowMissingInfo ? 1 : isMissingInfo?.length ? 0.5 : 1,
    },
    typography: {
      fontSize: "13px",
      fontWeight: 400,
      color: "#1C1C19",
    },
  };

  // const handleOpenCustomerProfile = useCallback(
  //   (id) => {
  //     navigate(`/customers/${id}`);
  //   },
  //   [navigate]
  // );

  const setGroupName = useMemo(() => {
    if (customer?.parentCustomer) {
      return (
        (!customer?.parentCustomer?.isUncategorized &&
          customer?.parentCustomer?.name) ||
        "Uncategorized"
      );
    } else {
      return "Uncategorized";
    }
  }, [customer?.parentCustomer]);

  const statusIcons = {
    [CUSTOMER_STATUS_FILTERS.active]: <CheckmarkIcon />,
    [CUSTOMER_STATUS_FILTERS.inactive]: <CrossIcon fill="#FF0000" />,
    [CUSTOMER_STATUS_FILTERS.prospect]: <ProspectStatusIcon />,
  };

  return (
    <Grid
      sx={{
        "&:not(:last-of-type)": {
          borderBottom: "1px solid #D5D9D9",
        },
        backgroundColor: isChecked ? "#ECF5F0" : hoverColor,
      }}
      container
      minHeight="56px"
      onMouseMove={() => setHoverColor("#F7F7F7")}
      onMouseLeave={() => setHoverColor("#FFF")}
      data-testid="customer-item"
      onClick={() => {
        if (!allowMissingInfo && isMissingInfo.length) return;
        viewCustomerPopup && handleCheckOneCustomer(customer, isChecked);
      }}
    >
      <Grid item xs={1} sx={classes.grid}>
        {viewCustomerPopup ? (
          isMissingInfo?.length ? (
            <StyledTooltip
              arrow
              title={
                <Box textAlign="left">
                  <Typography fontSize={12}>Missing info:</Typography>
                  {isMissingInfo?.map((info) => (
                    <Typography key={info} fontSize={12} fontWeight={300}>
                      {info}
                    </Typography>
                  ))}
                </Box>
              }
              placement="top"
              PopperProps={{
                modifiers: [
                  {
                    name: "offset",
                    options: { offset: [0, -8] },
                  },
                ],
              }}
            >
              <Box display="flex" alignItems="center">
                <InformationIcon size="16" stroke="#FF6969" />
              </Box>
            </StyledTooltip>
          ) : (
            <Radio
              sx={{
                p: "6px",
              }}
              checked={isChecked}
              onChange={() => {
                if (!allowMissingInfo && isMissingInfo.length) return;
                handleCheckOneCustomer(customer, isChecked);
              }}
            />
          )
        ) : (
          <LargeCheckbox
            sx={{ p: 0 }}
            checked={isChecked}
            disabled={!allowMissingInfo && !!isMissingInfo?.length || isAdmin}
            onChange={() => {
              if (!allowMissingInfo && isMissingInfo.length) return;
              handleCheckCustomer(customer, isChecked);
            }}
          />
        )}
      </Grid>
      {!viewCustomerPopup && (
        <Grid
          item
          xs={1}
          sx={{
            ...classes.grid,
            justifyContent: "flex-start",
            pl: 1.5,
          }}
        >
          {
            isMissingInfo?.length ? (
              <StyledTooltip
                arrow
                title={
                  <Box textAlign="left">
                    <Typography fontSize={12}>Missing info:</Typography>
                    {isMissingInfo?.map((info) => (
                      <Typography key={info} fontSize={12} fontWeight={300}>
                        {info}
                      </Typography>
                    ))}
                  </Box>
                }
                placement="top"
                PopperProps={{
                  modifiers: [
                    {
                      name: "offset",
                      options: { offset: [0, -6] },
                    },
                  ],
                }}
              >
                <Box display="flex" alignItems="center">
                  <InformationIcon size="16" stroke="#FF6969" />
                </Box>
              </StyledTooltip>
            ) : (
              statusIcons[customer.status]
            )
            //  customer.active ? (
            //   <CheckmarkIcon />
            // ) : (
            //   <CrossIcon fill="#FF0000" />
            // )
          }
        </Grid>
      )}
      <Grid item xs={1} sx={{ ...classes.grid, justifyContent: "flex-start" }}>
        <Typography sx={classes.typography}>
          {customer.customId?.customId || customer.id.slice(-5)}
        </Typography>
      </Grid>
      <Grid
        item
        xs={!viewCustomerPopup ? 3.8 : 4.5}
        sx={{
          ...classes.grid,
          flexDirection: "column",
          alignItems: "flex-start",
          cursor: viewCustomerPopup ? "pointer" : "auto",
        }}
        onClick={(e) => {
          e.stopPropagation();
          if (!allowMissingInfo && isMissingInfo.length) return;
          // !viewCustomerPopup
          //   ? handleOpenCustomerProfile(customer.id)
          //   : handleCheckOneCustomer(customer, isChecked);
          viewCustomerPopup && handleCheckOneCustomer(customer, isChecked);
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography sx={classes.typography} noWrap>
            {customer.displayName || customer.name}
          </Typography>
          {customer.status === CUSTOMER_STATUS_FILTERS.prospect && (
            <StyledLabel style={{ marginLeft: "8px" }} text="prospect" />
          )}
        </Box>
        <Typography
          sx={{
            ...classes.typography,
            fontWeight: 300,
            marginTop: "2px",
          }}
          color="#1C1C19"
        >
          {customer.shippingAddress?.formatted_address}
        </Typography>
      </Grid>
      <Grid
        item
        xs={viewCustomerPopup ? 2.7 : 2}
        sx={{ ...classes.grid, justifyContent: "flex-start" }}
      >
        <Typography sx={classes.typography}>{setGroupName}</Typography>
      </Grid>
      <Grid
        item
        xs={viewCustomerPopup ? 1.5 : 2}
        sx={{ ...classes.grid, justifyContent: "flex-start" }}
      >
        <Typography sx={classes.typography}>
          {customer.territory?.name || "Uncategorized"}
        </Typography>
      </Grid>
      <Grid item xs={1} sx={{ ...classes.grid, marginLeft: "-20px" }}>
        <Typography sx={classes.typography}>
          {customer._count?.orders || 0}
        </Typography>
      </Grid>
    </Grid>
  );
};

CustomersItem.propTypes = {
  customer: object,
  isChecked: bool,
  handleCheckCustomer: func,
  viewCustomerPopup: bool,
  handleCheckOneCustomer: func,
  allowMissingInfo: bool,
};

CustomersItem.defaultProps = {
  isChecked: false,
  customer: null,
  allowMissingInfo: false,
};

export default CustomersItem;
