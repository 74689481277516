/* eslint-disable react/prop-types */

import * as React from "react";
export const PlaceholderIcon = ({ fillOpacity = 1, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={236}
      height={236}
      viewBox="0 0 236 236"
      {...props}
    >
      <g data-name="Group 7264" transform="translate(-1659 -1406)">
        <rect
          data-name="Rectangle 11508"
          width={236}
          height={236}
          rx={6}
          transform="translate(1659 1406)"
          fill="#f3f3f3"
          fillOpacity={fillOpacity}
        />
      </g>
      <g data-name="Icon ionic-md-photos" fill="#dbdbdb">
        <path
          data-name="Path 5567"
          d="M175.406 161.449V74.52a13.96 13.96 0 0 0-13.927-13.925H74.521A13.96 13.96 0 0 0 60.596 74.52v86.963a13.96 13.96 0 0 0 13.925 13.922h86.99a14 14 0 0 0 13.895-13.956Zm-79.16-38.248 14.775 20.868 21.755-31.3 28.7 41.737H74.521Z"
        />
        <path
          data-name="Path 5568"
          d="M171.487 142.887h-19.145a3.074 3.074 0 0 0-3.066 3.066v.381h18.764a3.074 3.074 0 0 1 3.066 3.066v18.764h.381a3.074 3.074 0 0 0 3.066-3.066v-19.145a3.074 3.074 0 0 0-3.066-3.066Z"
        />
      </g>
    </svg>
  );
};
