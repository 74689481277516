export const defaultValues = {
  contacts: [],
  message: "",
  others: [],
  sendMe: false,
  subject: "",
  businessEmail: "",
  files: [],
  includePaymentLink: true,
  includePrices: true,
};

export const letterText = (customerName, distrBusinessName, isEdit) => {
  if (!isEdit && customerName && distrBusinessName)
    return `Hello,\n\nWe appreciate your business. Please find your invoice details here. Feel free to contact us with any questions.\n\nKind regards,\n${distrBusinessName} Team.`;

  if (isEdit && customerName && distrBusinessName)
    return `Hello,\n\nWe're getting your order ready and we'll notify you when it's shipped.\n\nKind regards,\n${distrBusinessName} Team.`;

  return "";
};
