import { useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { PayoutDetails, PersonalDetailsDialog } from "./components";
import PayoutsList from "./components/PayoutsList";
// import { FilterSearchTextField } from "../../../../../../../components/TextFields/TextFields";
import ConnectBankDialog from "./components/ConnectBankDialog";
import {
  getBankAccountService,
  getBankAccountSettingsService,
  getPayoutsService,
  getPersonalDetailsService,
} from "../../../../../../../services/stripe";
import Loader from "../../../../../../../components/Loader";
import { error } from "../../../../../../../utils/notifications";
import CompleteSetup from "./components/CompleteSetup";

export const PayoutsTab = () => {
  const [bankAccount, setBankAccount] = useState(null);
  const [personalDetailsState, setPersonalDetailsState] = useState({
    open: false,
    details: null,
  });
  const [loading, setLoading] = useState(true);
  const [payoutsList, setPayoutsList] = useState([]);

  const [addAccountPopupOpen, setAddAccountPopupOpen] = useState(false);
  const [editingAccount, setEditingAccount] = useState(false);

  const [settings, setSettings] = useState({
    interval: "",
    weeklyAnchor: "",
    statementDescriptor: "",
  });

  const handleAddAccount = () => {
    setAddAccountPopupOpen(true);
    setEditingAccount(false);
  };

  const handleCloseAccount = () => {
    setAddAccountPopupOpen(false);
  };

  const handleAddBankAccount = (account) => {
    handleCloseAccount();
    setBankAccount(account);
  };

  useEffect(() => {
    let didCancel = false;
    setLoading(true);
    Promise.all([
      getPersonalDetailsService(),
      getBankAccountService(),
      getBankAccountSettingsService(),
      getPayoutsService(),
    ])
      .then((res) => {
        if (didCancel) return;
        const [personalDetails, bank, accountSettings, payouts] = res;
        if (personalDetails) {
          const { lat, lng } = personalDetails?.address || {};
          const details = {
            ...personalDetails,
            address: {
              ...personalDetails?.address,
              lat: parseFloat(lat),
              lng: parseFloat(lng),
            },
          };
          setPersonalDetailsState((prev) => ({ ...prev, details }));
        }
        setBankAccount(bank);
        setSettings({
          interval: accountSettings?.schedule?.interval || "",
          statementDescriptor: accountSettings?.statementDescriptor || "",
          weeklyAnchor:
            accountSettings?.schedule?.interval === "daily"
              ? "day"
              : accountSettings?.schedule?.weeklyAnchor || "day",
        });
        setPayoutsList(payouts.rows);
        setLoading(false);
      })
      .catch((err) => {
        error(err?.response?.data?.message);
        setLoading(false);
      });

    return () => {
      didCancel = true;
    };
  }, []);

  const handleEditBankAccount = () => {
    setAddAccountPopupOpen(true);
    setEditingAccount(true);
  };

  const handleSetupAction = (type) => {
    if (type === "banking") {
      setAddAccountPopupOpen(true);
      setEditingAccount(!!bankAccount);
    }
    if (type === "personalDetails")
      setPersonalDetailsState((prev) => ({ ...prev, open: true }));
  };

  const handleAddDetails = (res) => {
    const { lat, lng } = res?.address || {};

    const details = {
      ...res,
      address: { ...res.address, lat: parseFloat(lat), lng: parseFloat(lng) },
    };
    setPersonalDetailsState((prev) => ({ ...prev, details, open: false }));
  };

  if (!bankAccount && !personalDetailsState.details && loading)
    return (
      <Box
        width="100%"
        height="300px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress />
      </Box>
    );

  return (
    <Box width="100%">
      <Loader isLoading={loading} />
      <ConnectBankDialog
        open={addAccountPopupOpen}
        handleClose={handleCloseAccount}
        handleAddAccount={handleAddBankAccount}
        isEdit={editingAccount}
        bankAccount={bankAccount}
        setLoading={setLoading}
      />
      <PersonalDetailsDialog
        personalDetails={personalDetailsState.details}
        isOpen={personalDetailsState.open}
        onClose={() =>
          setPersonalDetailsState((prev) => ({ ...prev, open: false }))
        }
        handleAddDetails={handleAddDetails}
        setLoading={setLoading}
      />
      {personalDetailsState.details && bankAccount ? (
        <>
          <PayoutDetails
            handleUpdatePersonalDetails={() =>
              setPersonalDetailsState((prev) => ({ ...prev, open: true }))
            }
            {...{
              bankAccount,
              handleAddAccount,
              handleEditBankAccount,
              settings,
              setSettings,
            }}
          />
          {bankAccount && (
            <>
              <PayoutsList {...{ payoutsList }} />
            </>
          )}
        </>
      ) : (
        <CompleteSetup
          hasBank={!!bankAccount}
          hasPersonalDetails={!!personalDetailsState.details}
          handleAction={handleSetupAction}
        />
      )}
    </Box>
  );
};
