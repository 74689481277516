import { func, bool } from "prop-types";

import { Box, Checkbox, Grid, Typography } from "@mui/material";
import { TableHeader } from "components";
import { CheckboxIcon } from "components/Icons";

const CustomersGroupsHeader = ({
  allGroupsChecked,
  checkAllGroups,
  isAdmin,
}) => {
  const classes = {
    grid: {
      textAlign: "center",
    },
    typography: {
      fontSize: "12px",
      fontWeight: "500",
      color: "#6A6A6A",
      textTransform: "uppercase",
    },
  };

  return (
    <TableHeader
      sx={{
        height: "38px",
        backgroundColor: "#EEEEEE",
        alignItems: "center",
        border: "1px solid #D5D9D9",
        borderTop: "none",
        pb: "3px",
      }}
      columns={24}
    >
      <Box
        width="52px"
        minWidth="52px"
        maxWidth="52px"
        sx={{ ...classes.grid, m: "1px 4px 0 0" }}
      >
        <Checkbox
          disabled={isAdmin}
          icon={<CheckboxIcon size={22} />}
          sx={{ padding: 0, "& .MuiSvgIcon-root": { fontSize: 30 } }}
          style={{ width: 22, height: 22 }}
          checked={allGroupsChecked}
          onChange={checkAllGroups}
        />
      </Box>

      <Grid item xs={1} sx={classes.grid}>
        <Typography
          sx={classes.typography}
          variant="caption"
          color="groundLight"
        >
          STATUS
        </Typography>
      </Grid>
      <Grid
        item
        xs={1}
        sx={{
          pl: 4,
          justifyContent: "flex-start",
          textAlign: "left",
        }}
      >
        <Typography
          sx={classes.typography}
          variant="caption"
          color="groundLight"
        >
          ID
        </Typography>
      </Grid>
      <Grid item xs={8} sx={{ textAlign: "left", pl: "40px" }}>
        <Typography
          sx={classes.typography}
          variant="caption"
          color="groundLight"
        >
          GROUP NAME
        </Typography>
      </Grid>
      <Grid item xs={6} sx={classes.grid}>
        <Typography
          sx={classes.typography}
          variant="caption"
          color="groundLight"
        >
          TOTAL CUSTOMERS
        </Typography>
      </Grid>
    </TableHeader>
  );
};

CustomersGroupsHeader.propTypes = {
  checkAllGroups: func,
  allGroupsChecked: bool,
  isAdmin: bool,
};

CustomersGroupsHeader.defaultProps = {
  allGroupsChecked: false,
};

export default CustomersGroupsHeader;
