import { useCallback, useMemo, useState } from "react";
import { bool, object, string, func } from "prop-types";
import { useNavigate, Link as NavLink } from "react-router-dom";
import moment from "moment-timezone";
import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import {
  getCreatedAt,
  getCreatedOrderBy,
  getFormattedDate,
  normalizeSnakeCaseString,
  separateNumWithComma,
  truncateText,
} from "helpers/helpers";
import { PaymentStatus, StyledTooltip } from "components";
import { InformationIcon, PaperIcon, ShippingCarIcon } from "components/Icons";
import {
  DueTooltip,
  FulfillmentTooltip,
  PrintedBlock,
} from "Pages/OrdersPage/components/DirectOrdersTab/components/OrdersTable/components";
import { getPaymentDue } from "Pages/OrdersPage/Orders.helper";
import { useTheme } from "@emotion/react";
import { useBreakpoint } from "helpers/useBreakpoint";
import { MenuColumnOrder } from "../../../TabsContent/TabSortedComponent/components/columnsData/MenuColumn/MenuColumnOrder";
import { cl } from "./OrdersPopupItem.styles";

export const OrdersPopupItem = ({
  showBorder,
  order,
  timeZone,
  handleOpenEmail,
  handleChoseMenuItem,
  repPermissions,
  isAdmin,
  isQuickBooksConnected,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const breakpoint = useBreakpoint();

  const [hoverColor, setHoverColor] = useState("");
  const [openTooltip, setOpenTooltip] = useState(false);

  const handleCloseTooltip = () => setOpenTooltip(false);

  const handleOpenTooltip = () => setOpenTooltip(true);

  const handleOpenOrder = useCallback(
    (id) => navigate(`/orders/${id}`),
    [navigate]
  );

  const createdBy = useMemo(() => getCreatedOrderBy(order), [order]);

  const isThirdParty = useMemo(
    () => order?.type === "THIRD_PARTY",
    [order?.type]
  );

  const hideDueDate = useMemo(
    () =>
      order?.orderStatus === "CANCELED" ||
      order?.paymentStatus === "PAID" ||
      !order?.paymentTermsDuplicate ||
      order?.deliveryStatus !== "FULFILLED",
    [
      order?.deliveryStatus,
      order?.orderStatus,
      order?.paymentStatus,
      order?.paymentTermsDuplicate,
    ]
  );

  const validateIsoDate = (date) => {
    return moment(date, moment.ISO_8601, true).isValid();
  };

  const overduePayment = useMemo(() => {
    const fulfilledAt = order?.fulfilledAt;

    if (!validateIsoDate(fulfilledAt)) return null;

    const todayAtMidnight = moment.tz(timeZone).startOf("day");
    const fulfilledAtMoment = moment.tz(fulfilledAt, timeZone).startOf("day");

    return todayAtMidnight.diff(fulfilledAtMoment, "days");
  }, [order?.fulfilledAt, timeZone]);

  const setHideDue = useMemo(() => {
    if (order?.deliveryStatus === "FULFILLED") return false;

    return !overduePayment;
  }, [order?.deliveryStatus, overduePayment]);

  const dueData = useMemo(() => {
    return hideDueDate
      ? false
      : getPaymentDue({
          fulfilledAt: order?.fulfilledAt || order?.shippedAt,
          daysInvoices: order?.paymentTermsDuplicate?.daysInvoices,
          hideDue: setHideDue,
        });
  }, [
    hideDueDate,
    order?.fulfilledAt,
    order?.paymentTermsDuplicate?.daysInvoices,
    order?.shippedAt,
    setHideDue,
  ]);

  const overdueFulfillment = useMemo(() => {
    if (!order?.fulfillBy) return;
    if (order?.deliveryStatus === "FULFILLED") return;

    const todayAtMidnight = moment.tz(timeZone).startOf("day");

    const fulfilledAtMoment = moment
      .tz(order?.fulfillBy, timeZone)
      .startOf("day");

    return todayAtMidnight.diff(fulfilledAtMoment, "days");
  }, [order?.deliveryStatus, order?.fulfillBy, timeZone]);

  const getFulfillmentStatus = useMemo(() => {
    const deliveryStatus = normalizeSnakeCaseString(order?.deliveryStatus);
    const items = order?.totalDelivered
      ? `(${order?.totalDelivered} of ${order?.totalQuantity})`
      : "";
    return breakpoint === "xl" ? `${deliveryStatus} ${items}` : deliveryStatus;
  }, [
    breakpoint,
    order?.deliveryStatus,
    order?.totalDelivered,
    order?.totalQuantity,
  ]);

  const filteredOrderDeliveriesData = useMemo(() => {
    return order?.orderDeliveries?.length
      ? order.orderDeliveries.filter(
          ({ shippingCarrier, shippingCarrierDuplicate }) =>
            shippingCarrier || shippingCarrierDuplicate !== null
        )
      : [];
  }, [order?.orderDeliveries]);

  const hasCustomerEmail = useMemo(() => {
    return (
      !!order?.customer?.email ||
      order?.customer?.contacts?.some((contact) => contact?.email)
    );
  }, [order?.customer?.contacts, order?.customer?.email]);

  return (
    <Paper
      sx={{
        backgroundColor: hoverColor,
        borderBottom: showBorder && "1px solid #D5D9D9",
        position: "relative",
        opacity: order?.archived ? 0.5 : 1,
      }}
      elevation={0}
      square
      height="48px"
      onMouseMove={() => setHoverColor("#F7F7F7")}
      onMouseLeave={() => setHoverColor("#FFF")}
    >
      <Grid height="48px" container>
        <Grid xs={10} item container>
          <Grid
            sx={cl.gridContainer}
            component={NavLink}
            to={`/orders/${order.id}`}
            onClick={(e) => {
              if (e.metaKey || e.ctrlKey) return;
              e.stopPropagation();
              e.preventDefault();
              handleOpenOrder(order.id);
            }}
            columns={10}
            container
          >
            <Grid
              sx={{
                ...cl.gridStart,
                pl: "15px",
                justifyContent: "space-between",
              }}
              item
              xs={1}
            >
              <Box
                display="flex"
                alignItems="center"
                gap="5px"
                sx={{
                  width: "72px",
                  height: "100%",
                  textDecorationLine:
                    order?.orderStatus === "CANCELED" && "line-through",
                  textDecorationColor: "#1C1C19",
                }}
              >
                <Typography sx={cl.orderText}>
                  {order.customId?.customId}
                </Typography>

                {order?.archived && (
                  <StyledTooltip
                    arrow
                    title="Archived"
                    placement="top"
                    PopperProps={{
                      modifiers: [
                        { name: "offset", options: { offset: [0, -24] } },
                      ],
                    }}
                  >
                    <Box display="flex" alignItems="center" height="100%">
                      <InformationIcon size="16" stroke="#FC7063" />
                    </Box>
                  </StyledTooltip>
                )}
              </Box>

              {order.note && order.note?.text && (
                <StyledTooltip
                  title={order.note?.text}
                  placement="top"
                  arrow
                  open={openTooltip}
                  onClose={handleCloseTooltip}
                  onOpen={handleOpenTooltip}
                  PopperProps={{
                    modifiers: [
                      { name: "offset", options: { offset: [0, -4] } },
                    ],
                  }}
                >
                  <Box width="30px" display="flex" justifyContent="flex-start">
                    <PaperIcon color="#5F6267" size={17} />
                  </Box>
                </StyledTooltip>
              )}
            </Grid>

            {/* Date */}
            <Grid
              item
              xs={1.9}
              sx={{
                ...cl.grid,
                flexDirection: "column",
                alignItems: "flex-start",
                textDecorationLine:
                  order.orderStatus === "CANCELED" && "line-through",
                textDecorationColor: "#1C1C19",
              }}
            >
              <Typography sx={cl.typography}>
                {getCreatedAt(order.createdAt, timeZone)}
              </Typography>
            </Grid>

            {/* Created by */}
            <Grid item xs={1.8} sx={cl.gridStart}>
              <Typography sx={cl.typography} noWrap>
                {createdBy}
              </Typography>
            </Grid>

            {/* Payment */}
            <Grid item xs={2.1} sx={{ ...cl.gridStart, pl: 0 }}>
              {isThirdParty ? (
                <StyledTooltip
                  isDark
                  title={
                    <Typography variant="subtitle2">3rd party order</Typography>
                  }
                  arrow
                  placement="top"
                >
                  <Box color="#1C1C19">-</Box>
                </StyledTooltip>
              ) : (
                <Box display="flex" alignItems="center" position="relative">
                  <DueTooltip
                    emailCount={order?.remindersSent}
                    dueData={dueData}
                    paymentTermsName={order.paymentTermsDuplicate?.name}
                    disableHoverListener={
                      order.orderStatus === "CANCELED" ||
                      order.paymentStatus === "PAID" ||
                      !order.paymentTermsDuplicate
                    }
                  >
                    <PaymentStatus
                      str={dueData?.overdue ? "OVERDUE" : order.paymentStatus}
                      overdue={dueData?.text}
                    />
                  </DueTooltip>
                </Box>
              )}
            </Grid>

            {/* Fulfillment */}
            <Grid item xs={1.6} sx={cl.gridStart}>
              {isThirdParty ? (
                <StyledTooltip
                  isDark
                  title={
                    <Typography variant="subtitle2">3rd party order</Typography>
                  }
                  arrow
                  placement="top"
                >
                  <Box color="#1C1C19">-</Box>
                </StyledTooltip>
              ) : (
                <Box display="flex" alignItems="center" position="relative">
                  <Box display="flex" alignItems="center" position="relative">
                    {overdueFulfillment >= 0 && (
                      <DueTooltip
                        dueData={{
                          titleTop:
                            overdueFulfillment === 0
                              ? "Due today"
                              : `Overdue ${overdueFulfillment} days`,
                          titleBottom: order?.fulfillBy
                            ? `Due: ${moment(
                                getFormattedDate(order.fulfillBy, timeZone)
                              )?.format("MM/DD/YY")}`
                            : "",
                          divider: true,
                        }}
                        disableHoverListener={
                          order?.deliveryStatus === "FULFILLED" ||
                          !order?.fulfillBy
                        }
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          sx={{ width: "17px" }}
                        >
                          <InformationIcon
                            width="10px"
                            height="10px"
                            stroke={
                              overdueFulfillment === 0
                                ? theme.palette.warning.main
                                : "#FF6969"
                            }
                          />
                        </Box>
                      </DueTooltip>
                    )}
                    {overdueFulfillment >= 0 ? (
                      <>
                        <Typography
                          sx={{
                            fontSize: 12,
                            fontWeight: 400,
                            color: "#EB4233",
                          }}
                        >
                          {overdueFulfillment === 0
                            ? "Due today"
                            : `Overdue ${overdueFulfillment} days`}
                        </Typography>
                      </>
                    ) : (
                      <Stack direction="row" alignItems="center">
                        <Box
                          sx={{
                            height: "6px",
                            width: "6px",
                            backgroundColor:
                              order.deliveryStatus === "UNFULFILLED"
                                ? "#F0C401"
                                : "#47A06D",
                            borderRadius: "50%",
                            mr: "8px",
                          }}
                        />
                        {order.deliveryStatus === "PARTIALLY_FULFILLED" && (
                          <>
                            <Box
                              sx={{
                                height: "6px",
                                width: "6px",
                                backgroundColor: "#b98900",
                                borderRadius: "50%",
                                position: "absolute",
                              }}
                            />
                            <Box
                              sx={{
                                position: "absolute",
                                left: "1.5px",
                                height: "3px",
                                width: "3px",
                                backgroundImage:
                                  "linear-gradient(#ffd79c 50%, #b98900 50%)",
                                borderRadius: "50%",
                              }}
                            />
                          </>
                        )}
                        <Typography
                          sx={{
                            fontSize: 12,
                            fontWeight: 300,
                            color: "#1C1C19",
                          }}
                        >
                          {getFulfillmentStatus}
                        </Typography>
                        <Box
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          {order.deliveryStatus === "PARTIALLY_FULFILLED" ||
                            (order.deliveryStatus === "FULFILLED" &&
                              !!filteredOrderDeliveriesData.length && (
                                <FulfillmentTooltip
                                  titleData={filteredOrderDeliveriesData}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  <Grid
                                    container
                                    justifyContent="center"
                                    width="22px"
                                    height="15px"
                                    ml="3px"
                                  >
                                    <ShippingCarIcon />
                                  </Grid>
                                </FulfillmentTooltip>
                              ))}
                        </Box>
                      </Stack>
                    )}
                  </Box>
                </Box>
              )}
            </Grid>

            {/* Total */}
            <Grid item xs={1.6} sx={cl.gridStart}>
              <Typography
                noWrap
                sx={{
                  ...cl.typography,
                  fontWeight: 600,
                  textDecorationLine:
                    order.orderStatus === "CANCELED" && "line-through",
                  textDecorationColor: "#1C1C19",
                }}
              >
                {truncateText(
                  `$ ${separateNumWithComma(order?.totalAmount?.toFixed(2))}`,
                  12
                )}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {/* Actions */}
        <Grid
          sx={{
            ...cl.grid,
            justifyContent: "flex-end",
          }}
          item
          xs={2}
        >
          {!!order?.lastPdfsPrinted?.length && (
            <PrintedBlock lastPrinted={order?.lastPdfsPrinted} sx={{ mr: 1 }} />
          )}

          <MenuColumnOrder
            {...{
              isQuickBooksConnected,
              order,
              hasCustomerEmail,
              isAdmin,
              handleOpenEmail,
              handleChoseMenuItem,
              handleOpenOrder,
              repPermissions,
            }}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

OrdersPopupItem.propTypes = {
  showBorder: bool,
  order: object,
  timeZone: string,
  handleOpenEmail: func,
  handleChoseMenuItem: func,
  repPermissions: object,
  isAdmin: bool,
  isQuickBooksConnected: bool,
};
OrdersPopupItem.defaultProps = {
  showBorder: false,
  order: {},
  timeZone: "",
  handleOpenEmail: () => {},
  handleChoseMenuItem: () => {},
  repPermissions: {},
  isAdmin: false,
  isQuickBooksConnected: false,
};
