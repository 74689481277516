import { number, bool, func, string } from "prop-types";
import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";

export const AllCustomersSelect = ({
  count,
  value,
  indeterminate,
  onGeneralSelectAll,
  loadingCustomers,
  title = "customers",
}) => {
  return (
    <FormControlLabel
      disabled={loadingCustomers}
      sx={{ ml: 1 }}
      onClick={(e) => e.stopPropagation()}
      control={
        <Checkbox
          checked={value}
          indeterminate={indeterminate}
          onChange={(e) => onGeneralSelectAll(e.target.checked)}
        />
      }
      label={
        loadingCustomers ? (
          <Typography fontSize={13} color="#707070">
            Loading
          </Typography>
        ) : (
          <Typography fontSize={13} color="#707070">
            Select All
            <Box color="#47A06D" component="span">
              {" "}
              {count}{" "}
            </Box>
            {title}
          </Typography>
        )
      }
    />
  );
};

AllCustomersSelect.propTypes = {
  count: number,
  value: bool.isRequired,
  indeterminate: bool,
  onGeneralSelectAll: func.isRequired,
  loadingCustomers: bool,
  title: string,
};
AllCustomersSelect.defaultProps = {
  count: 0,
};
