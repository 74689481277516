import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Typography,
} from "@mui/material";
import { bool, func, object, array, number } from "prop-types";
import { useEffect } from "react";
import { useState } from "react";
import { CrossIcon } from "../../Icons";
import { StyledSelect } from "../../Selects";
import StyledButton from "../../StyledButton";
import useStyles from "./styles";

export const MergeTagDialog = ({
  isOpen,
  onClose,
  tag,
  tagsList,
  onMerge,
  loading,
  handleFetchTags,
  tagsCount,
}) => {
  const [mergeWith, setMergeWith] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    if (!isOpen) setMergeWith([]);
  }, [isOpen]);
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle className={classes.dialogTitle}>
        <Box display="flex" gap="10px" alignItems="center">
          <Typography fontSize="20px" color="#5F6267">
            Merge tags
          </Typography>
          {loading && <CircularProgress size={20} />}{" "}
        </Box>
        <IconButton onClick={onClose}>
          <CrossIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <Typography color="primary" mb="14px">
          {tag?.tag}
        </Typography>
        <StyledSelect
          label="Select tag name"
          fullWidth
          fontSize="14px"
          height="37px"
          labelSx={{ fontSize: "14px" }}
          value={mergeWith}
          onChange={(e) => setMergeWith(e.target.value)}
          multiple
          handleFetch={handleFetchTags}
          dataLength={tagsList.length}
          dataCount={tagsCount - 1}
          PaperPropsSx={{ maxWidth: "300px" }}
        >
          {tagsList.map((t) => (
            <MenuItem key={t.id} value={t.id}>
              <Typography fontSize={12} color="#3F3F3F" noWrap>
                {t.tag}
              </Typography>
            </MenuItem>
          ))}
        </StyledSelect>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <StyledButton label="Cancel" color="edit" onClick={onClose} />
        <StyledButton
          label="Merge"
          variant="contained"
          disabled={!mergeWith}
          onClick={() => onMerge(mergeWith)}
        />
      </DialogActions>
    </Dialog>
  );
};

MergeTagDialog.propTypes = {
  isOpen: bool,
  onClose: func,
  tag: object,
  tagsList: array,
  onMerge: func,
  loading: bool,
  handleFetchTags: func,
  tagsCount: number,
};

export default MergeTagDialog;
