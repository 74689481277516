import { useCallback, useEffect, useMemo, useState } from "react";
import { getOrderedProductsByCustomer } from "services/products";
import { SCROLL_LIMIT } from "utils/constants";
import { error } from "utils/notifications";

const DEFAULT_STATE = {
  loading: false,
  list: [],
  count: 0,
};

const SORT_FIELDS = [
  "sort_by_name",
  "sort_by_last_order_date",
  "sort_by_quantity",
];

const DEFAULT_PARAMS = {
  page: 1,
  limit: SCROLL_LIMIT,
  sort_by_name: "",
  sort_by_last_order_date: "",
  sort_by_quantity: "desc",
};

export const useProductsPurchased = ({ checkedList, isOpen, handleClose }) => {
  const [productsState, setProductsState] = useState(DEFAULT_STATE);
  const [checkedProducts, setCheckedProducts] = useState([]);
  const [productParams, setProductParams] = useState(DEFAULT_PARAMS);

  const handleIsAlreadyAdded = (productId) => {
    return checkedProducts.some(({ id }) => id === productId);
  };

  const handleCheckProduct = (product) => {
    const isAlreadyAdded = handleIsAlreadyAdded(product.id);
    if (isAlreadyAdded)
      return setCheckedProducts(
        checkedProducts.filter((products) => products.id !== product.id)
      );
    return setCheckedProducts((prev) => [...prev, product]);
  };

  const setLoading = (loading) =>
    setProductsState((prev) => ({ ...prev, loading }));

  const handleFetchProducts = useCallback(
    async (page) => {
      if (!page) {
        setLoading(true);
      }
      try {
        const res = await getOrderedProductsByCustomer({
          ...productParams,
          page: page ? page : 1,
        });

        const rows = res?.rows || [];
        const count = res?.count || 0;

        setProductsState((prev) => ({
          ...prev,
          list: page > 1 ? [...prev.list, ...rows] : rows,
          count: count,
        }));

        setProductParams((prev) => ({ ...prev, page: page ? page : 1 }));
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        error(err?.response?.data?.message || "Something went wrong.");
      } finally {
        setLoading(false);
      }
    },
    [productParams]
  );

  const handleCancel = () => {
    handleClose();
    setCheckedProducts([]);
    setProductParams(DEFAULT_PARAMS);
  };

  useEffect(() => {
    if (isOpen && !!checkedList.length) {
      setCheckedProducts(checkedList);
    }
  }, [checkedList, isOpen]);

  const handleSetSort = useCallback(
    (sortField) => {
      const sortFieldToDefault = Object.keys(productParams).filter(
        (item) => SORT_FIELDS.includes(item) && item !== sortField
      );

      setProductParams({
        ...productParams,
        [sortField]: productParams?.[sortField] !== "desc" ? "desc" : "asc",
        ...sortFieldToDefault.reduce(
          (acc, field) => ({ ...acc, [field]: "" }),
          {}
        ),
      });
    },
    [productParams]
  );

  const quickSort = useMemo(() => {
    return {
      sort_by_name: productParams?.sort_by_name,
      sort_by_last_order_date: productParams?.sort_by_last_order_date,
      sort_by_quantity: productParams?.sort_by_quantity,
    };
  }, [
    productParams?.sort_by_last_order_date,
    productParams?.sort_by_name,
    productParams?.sort_by_quantity,
  ]);

  useEffect(() => {
    if (isOpen) {
      handleFetchProducts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isOpen,
    productParams?.sort_by_last_order_date,
    productParams?.sort_by_name,
    productParams?.sort_by_quantity,
  ]);

  return {
    handleCheckProduct,
    handleSetSort,
    quickSort,
    productsState,
    page: productParams.page,
    handleFetchProducts,
    handleIsAlreadyAdded,
    handleCancel,
    checkedProducts,
  };
};
