import { useCallback, useEffect, useMemo, useState } from "react";
import { object, string } from "prop-types";
import {
  Box,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";

import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

import OrdersListHeader from "../../../OrdersListHeader.jsx";
import StyledButton from "../../../../../../../components/StyledButton/index.jsx";
import ConfirmDeliveryPopup from "../ConfirmDeliveryPopup/ConfirmDeliveryPopup.jsx";
import DeliveryDetailsPopup from "../../DeliveryDetailsPopup/DeliveryDetailsPopup.jsx";
import { useDispatch } from "react-redux";
import {
  deleteOrderDeliveryAction,
  deleteOrderReturnAction,
} from "../../../../../../../redux/actions/orders.js";
import {
  openConfirmDialogAction,
  setConfirmIsOpenAction,
} from "../../../../../../../redux/actions/confirmDialogs.js";
import { DangerIcon } from "../../../../../../../components/Icons";
import AcceptPaymentPopup from "../../../AcceptPaymentPopup.jsx";
import {
  useAdmin,
  normalizeSnakeCaseString,
  getFormattedDate,
} from "../../../../../../../helpers/helpers.js";
import { DeliveryItem } from "../../../DeliveryItem/DeliveryItem";
import { ReturnDialog } from "../../../../../../../components";
import moment from "moment";

const OrdersDeliveryBar = ({ order, timeZone, repPermissions }) => {
  const isAdmin = useAdmin();
  const cl = {
    headerBox: {
      display: "flex",
      alignItems: "center",
    },
    nameText: {
      fontSize: "clamp(14px, 1.13vw, 22px)",
      color: "#000",
    },
    chipBox: {
      backgroundColor:
        order?.deliveryStatus === "UNFULFILLED"
          ? "rgba(240, 196, 1, 0.1)"
          : "rgba(71, 160, 110, 0.1)",
      paddingX: 1,
      paddingY: 0.5,
      borderRadius: "20px",
    },
    chipText: {
      fontSize: "10px",
      fontWeight: 600,
      color: order?.deliveryStatus === "UNFULFILLED" ? "#F0C401" : "#47A06D",
      textTransform: "uppercase",
      px: "14px",
    },
    infoStatus: {
      fontSize: "12px",
      fontWeight: 400,
      color: "#5F6267",
    },
  };
  const dispatch = useDispatch();

  const [isOpenBodyComponent, setIsOpenBodyComponent] = useState(false);
  const [isConfirmDeliveryPopupOpen, setIsConfirmDeliveryPopupOpen] =
    useState(false);

  const [returnData, setReturnData] = useState({
    open: false,
    item: null,
  });

  const [deliveryDetails, setDeliveryDetails] = useState(null);
  const [editDelivery, setEditDelivery] = useState(null);
  const [viewPayment, setViewPayment] = useState({});
  const [isAcceptPaymentPopupOpen, setIsAcceptPaymentPopupOpen] =
    useState(false);
  const [list, setList] = useState([]);

  const handleCloseAcceptPaymentPopup = useCallback(() => {
    setIsAcceptPaymentPopupOpen(false);
    setViewPayment({});
  }, []);

  const handleCloseAcceptDeliveryPopup = useCallback(() => {
    setIsConfirmDeliveryPopupOpen(false);
    setEditDelivery(null);
  }, []);

  const handleViewDetails = useCallback((delivery) => {
    setDeliveryDetails(delivery);
  }, []);

  const handleEditDetails = useCallback((delivery) => {
    if (delivery?.titlePopUp === "Edit return") {
      setReturnData({
        open: true,
        item: delivery,
      });
      setDeliveryDetails(null);
    } else {
      setEditDelivery(delivery);
      setDeliveryDetails(null);
      setIsConfirmDeliveryPopupOpen(true);
    }
  }, []);

  const handleDeleteDelivery = useCallback(
    (id, type) => {
      dispatch(setConfirmIsOpenAction(false));
      switch (type) {
        case "delivery": {
          return dispatch(
            deleteOrderDeliveryAction(id, setDeliveryDetails(null))
          );
        }
        case "return": {
          return dispatch(
            deleteOrderReturnAction(id, setDeliveryDetails(null))
          );
        }
      }
    },
    [dispatch]
  );

  const handleConfirmDelete = (id, type) => {
    dispatch(
      openConfirmDialogAction({
        title: (
          <Box display="flex" alignItems="center">
            <DangerIcon />
            <span style={{ marginLeft: "11px" }}>
              Delete {type === "delivery" ? "fulfillment" : type}?
            </span>
          </Box>
        ),
        text: `Please confirm that you would like to delete this ${
          type === "delivery" ? "fulfillment" : type
        }. All data will be erased and this can’t be undone.`,
        buttons: (
          <>
            <StyledButton
              sx={{
                width: "98px",
                color: "#6A6A6A",
                borderColor: "#D4D4D4",
                fontSize: "13px",
                height: "28px",
              }}
              onClick={() => {
                dispatch(setConfirmIsOpenAction(false));
              }}
              label="Cancel"
              variant="outlined"
            />

            <StyledButton
              sx={{
                width: "98px",
                color: "#FFFFFF",
                fontSize: "13px",
                height: "28px",
                boxShadow: "none",
              }}
              color="confirmDelete"
              onClick={() => handleDeleteDelivery(id, type)}
              variant="contained"
              label="Delete"
            />
          </>
        ),
      })
    );
  };

  useEffect(() => {
    if (!order?.orderDeliveries?.length) setIsOpenBodyComponent(false);
  }, [order?.orderDeliveries?.length]);

  useEffect(() => {
    let deliveriesListWithType = [];
    let returnsListWithType = [];
    let restocksListWithType = [];

    if (order?.orderDeliveries?.length) {
      deliveriesListWithType = order?.orderDeliveries?.map((order) => ({
        ...order,
        type: "Delivery",
        titlePopUp: "Delivery details",
      }));
    }
    if (order?.orderReturns?.length) {
      returnsListWithType = order?.orderReturns?.map((order) => {
        if (order?.deliveryMethod === "CANCEL" && order?.restock) {
          return {
            ...order,
            type: "Restock",
            titlePopUp: "Restock details",
          };
        }
        return {
          ...order,
          type: "Return",
          titlePopUp: "Return details",
        };
      });
    }
    if (order?.orderRestocks?.length) {
      restocksListWithType = order?.orderRestocks?.map((order) => ({
        ...order,
        type: "Restock",
        titlePopUp: "Restock details",
      }));
    }

    const sortedList = [
      ...deliveriesListWithType,
      ...returnsListWithType,
      ...restocksListWithType,
    ];

    sortedList?.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateA - dateB;
    });

    setList(sortedList);
  }, [order]);

  const getTitle = useMemo(() => {
    const status = order?.deliveryStatus;
    return normalizeSnakeCaseString(status);
  }, [order?.deliveryStatus]);

  const disabledFulfillBtn = useMemo(() => {
    return repPermissions
      ? !repPermissions?.orders?.create_edit
      : isAdmin || order.orderStatus === "CANCELED";
  }, [isAdmin, order.orderStatus, repPermissions]);

  return (
    <>
      <ConfirmDeliveryPopup
        order={order}
        isOpen={isConfirmDeliveryPopupOpen}
        handleClose={handleCloseAcceptDeliveryPopup}
        editDelivery={editDelivery}
        type="Confirm delivery"
        isOrderPage={true}
      />
      <DeliveryDetailsPopup
        isOpen={!!deliveryDetails}
        order={order}
        delivery={deliveryDetails}
        handleClose={() => setDeliveryDetails(null)}
        handleEditDelivery={handleEditDetails}
        handleDeleteDelivery={handleConfirmDelete}
      />
      <AcceptPaymentPopup
        order={order}
        viewPayment={viewPayment}
        isOpen={isAcceptPaymentPopupOpen}
        handleClose={handleCloseAcceptPaymentPopup}
        isOrderPage={true}
      />
      <ReturnDialog
        open={returnData.open}
        onClose={() => {
          setReturnData({
            open: false,
            item: null,
          });
        }}
        order={order}
        returnItem={returnData.item}
      />
      <Paper
        sx={{
          border: "1px solid ",
          minHeight: "54px",
          borderColor: "#D5D9D9",
          cursor: order.deliveryStatus !== "UNFULFILLED" && "pointer",
        }}
        elevation={0}
        variant="outlined"
        onClick={() =>
          order.deliveryStatus !== "UNFULFILLED" &&
          setIsOpenBodyComponent(!isOpenBodyComponent)
        }
      >
        {/* header of the component */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            px: "27px",
            height: "52px",
            justifyContent: "flex-start",
            position: "relative",
          }}
        >
          <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
            <Box
              sx={{
                height: "11.25px",
                width: "11.25px",
                backgroundColor:
                  order.deliveryStatus !== "FULFILLED" ? "#F0C401" : "#47A06D",
                borderRadius: "50%",
                mr: 3,
              }}
            />
            {order.deliveryStatus === "PARTIALLY_FULFILLED" && (
              <>
                <Box
                  sx={{
                    height: "11.25px",
                    width: "11.25px",
                    backgroundColor: "#b98900",
                    borderRadius: "50%",
                    position: "absolute",
                  }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    left: "30px",
                    height: "5.25px",
                    width: "5.25px",
                    backgroundImage:
                      "linear-gradient(#ffd79c 50%, #b98900 50%)",
                    borderRadius: "50%",
                  }}
                />
              </>
            )}
            <Typography sx={cl.nameText}>{getTitle}</Typography>
            &nbsp;
            <Typography sx={cl.nameText} fontWeight="300">
              ({order.totalDelivered} of {order.totalQuantity})
            </Typography>
            {order?.fulfillBy && (
              <Box
                sx={{
                  background: "#F6F6F6",
                  display: "flex",
                  justifyContent: "space-around",
                  padding: "4px 12.5px",
                  borderRadius: "5px",
                  ml: "20px",
                  cursor: "pointer",
                }}
              >
                <Typography fontSize="clamp(10px, 0.8vw, 14px)">
                  Fulfill by:{" "}
                  {moment(getFormattedDate(order?.fulfillBy, timeZone)).format(
                    "MMM D, YY"
                  )}
                </Typography>
              </Box>
            )}
          </Box>
          <Box display="flex" alignItems="center" gap="22px">
            {order.deliveryStatus !== "FULFILLED" && (
              <StyledButton
                sx={{
                  height: 28,
                  borderColor: "#47A06D",
                }}
                color="primary"
                disabled={disabledFulfillBtn}
                variant="outlined"
                label="Fulfill items"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsConfirmDeliveryPopupOpen(true);
                }}
              />
            )}
            {(!!order?.orderDeliveries?.length ||
              !!order?.orderRestocks?.length) && (
              <IconButton
                onClick={() => setIsOpenBodyComponent(!isOpenBodyComponent)}
                sx={{
                  p: "3px",
                }}
              >
                {isOpenBodyComponent ? (
                  <KeyboardArrowUp sx={{ fontSize: 34, color: "#47A06D" }} />
                ) : (
                  <KeyboardArrowDown sx={{ fontSize: 34 }} />
                )}
              </IconButton>
            )}
          </Box>
        </Box>
        <Grid
          item
          sx={{
            borderTop: isOpenBodyComponent && "0.5px solid #D5D9D9",
            pl: "27px",
            pr: "31px",
          }}
        >
          <Collapse in={isOpenBodyComponent}>
            <Grid
              sx={{
                border: "1px solid #D5D9D9",
                marginTop: "12px",
                marginBottom: "18px",
                borderRadius: "4px",
              }}
              container
              direction="column"
            >
              <OrdersListHeader title="Delivery" />
              {list.map((delivery) => (
                <DeliveryItem
                  key={`${delivery?.id}-${delivery?.name}`}
                  item={delivery}
                  {...{
                    order,
                    timeZone,
                    handleViewDetails,
                    list,
                  }}
                  handleEditDetails={(data) => {
                    if (delivery.type === "Delivery") {
                      handleEditDetails({
                        ...data,
                        titlePopUp: "Edit delivery",
                      });
                    }
                    if (delivery.type === "Return") {
                      setReturnData({
                        open: true,
                        item: data,
                      });
                    }
                  }}
                  handleDeleteDelivery={(id) => {
                    handleConfirmDelete(id, delivery?.type?.toLowerCase());
                  }}
                  repPermissions={repPermissions}
                />
              ))}
            </Grid>
          </Collapse>
        </Grid>
      </Paper>
    </>
  );
};

OrdersDeliveryBar.propTypes = {
  order: object,
  title: string,
  infoStatus: string,
  status: string,
  timeZone: string,
  repPermissions: object,
};

OrdersDeliveryBar.defaultProps = {
  order: null,
  title: "Title",
  infoStatus: "",
  status: "",
};

export default OrdersDeliveryBar;
