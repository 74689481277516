import {
  START_LOADING_PRODUCT_TAGS,
  END_LOADING_PRODUCT_TAGS,
  GET_PRODUCT_TAGS_LIST,
  GET_PRODUCT_TAGS_COUNT,
} from "../actions/product-tags.js";

const initialState = {
  productTagsList: [],
  productTagsCount: 0,
  loading: false,
};

const productTagsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_PRODUCT_TAGS_LIST:
      return { ...state, productTagsList: payload };

    case GET_PRODUCT_TAGS_COUNT:
      return { ...state, productTagsCount: payload };

    case START_LOADING_PRODUCT_TAGS:
      return { ...state, loading: true };

    case END_LOADING_PRODUCT_TAGS:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default productTagsReducer;
