import {
  CUSTOMER_STATUS_FILTERS,
  SCROLL_LIMIT_CUSTOMERS,
} from "utils/constants";
import {
  CREATE_GROUP,
  DELETE_GROUP,
  END_LOADING_GROUPS,
  GET_GROUPS_ACTIVE,
  START_LOADING_GROUPS,
  GET_GROUPS_ACTIVE_COUNT,
  GET_FLAT_COUNT,
  SET_CUSTOMERS_BY_ID,
  GROUPS_GET_PARAMS,
  GET_FLAT_GROUPS,
  UPDATE_GROUP,
  GET_GROUPS_INACTIVE,
  GET_GROUPS_INACTIVE_COUNT,
} from "../actions/parentCustomers";

const initialState = {
  loading: false,

  groupsActive: [],
  groupsActiveCount: 0,

  groupsInactive: [],
  groupsInactiveCount: 0,

  flat: [],
  flatCount: 0,

  parentCustomersParams: {
    limit: SCROLL_LIMIT_CUSTOMERS,
    page: 1,
    include_uncategorized: true,
    with_candidates: false,
    status: `["${CUSTOMER_STATUS_FILTERS.active}"]`,
    sort_name: null,
    sort_orders: null,
  },
};

const parentCustomersReducer = (
  state = initialState,
  { type, payload, groupId, isActive }
) => {
  switch (type) {
    case GET_GROUPS_ACTIVE:
      return { ...state, groupsActive: payload };

    case GET_GROUPS_INACTIVE:
      return { ...state, groupsInactive: payload };

    case GET_FLAT_GROUPS:
      return { ...state, flat: payload };

    case GET_GROUPS_ACTIVE_COUNT:
      return { ...state, groupsActiveCount: payload };

    case GET_GROUPS_INACTIVE_COUNT:
      return { ...state, groupsInactiveCount: payload };

    case GET_FLAT_COUNT:
      return { ...state, flatCount: payload };

    case START_LOADING_GROUPS:
      return { ...state, loading: true };

    case CREATE_GROUP:
      return { ...state, flat: [...state.flat, payload] };

    case DELETE_GROUP: {
      const groupsList = isActive
        ? [...state.groupsActive]
        : [...state.groupsInactive];

      payload.forEach((id) => {
        const index = groupsList.findIndex((group) => group.id === id);
        if (index >= 0) {
          groupsList.splice(index, 1);
        }
      });

      return {
        ...state,
        [isActive ? "groupsActive" : "groupsInactive"]: groupsList,
        loading: false,
      };
    }

    case SET_CUSTOMERS_BY_ID: {
      const groupsList = state.groupsActive;
      const { newGroup, isScrolling } = payload;

      const index = groupsList.findIndex((group) => group.id === groupId);
      if (index >= 0) {
        groupsList.splice(
          index,
          1,
          isScrolling
            ? {
                ...newGroup,
                subCustomers: [
                  ...groupsList[index].subCustomers,
                  ...newGroup.subCustomers,
                ],
              }
            : newGroup
        );
      }
      return {
        ...state,
        groupsActive: groupsList,
      };
    }

    case UPDATE_GROUP: {
      const sortedGroupsList = isActive
        ? [...state.groupsActive]
        : [...state.groupsInactive];
      const flatGroupsList = [...state.flat];

      const updateIndexSorted = sortedGroupsList.findIndex(
        (group) => group.id === payload.id
      );
      if (updateIndexSorted >= 0) {
        sortedGroupsList.splice(updateIndexSorted, 1, payload);
      }

      const updateIndexFlat = flatGroupsList.findIndex(
        (group) => group.id === payload.id
      );
      if (updateIndexFlat >= 0) {
        flatGroupsList.splice(updateIndexFlat, 1, payload);
      }

      return {
        ...state,
        [isActive ? "groupsActive" : "groupsInactive"]: sortedGroupsList,
        flat: flatGroupsList,
        loading: false,
      };
    }

    case GROUPS_GET_PARAMS:
      return { ...state, parentCustomersParams: payload };

    case END_LOADING_GROUPS:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default parentCustomersReducer;
