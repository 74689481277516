export const SortAZIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
    >
      <path
        id="icons8-sort-alpha-up"
        d="M6.664,5l-.114.344L5.537,8.13H5.522v.032l-.49,1.356L5,9.6v.621H6.043V9.779l.214-.605H7.916l.214.605v.438H9.174V9.6l-.032-.078-.49-1.356V8.13H8.637L7.624,5.344,7.51,5Zm7.727.261-.376.36L11.783,7.87l.736.736L13.87,7.249V17h1.043V7.249l1.351,1.356L17,7.87,14.767,5.621ZM7.087,6.91,7.525,8.13H6.649ZM5,11.261V12.3H7.9L5.146,15.059,5,15.221v1.257H9.174V15.435h-2.9L9.028,12.68l.146-.162V11.261Z"
        transform="translate(-5 -5)"
        fill="#3f3f3f"
      />
    </svg>
  );
};
