import React from "react";

export const GroupIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
      <rect
        id="Rectangle_3213"
        data-name="Rectangle 3213"
        width="36"
        height="36"
        rx="7"
        fill="#b5b5ac"
      />
      <path
        id="Icon_awesome-store"
        data-name="Icon awesome-store"
        d="M22.472,4.427,20.05.56A1.2,1.2,0,0,0,19.038,0H3.958A1.2,1.2,0,0,0,2.947.56L.524,4.427A3.33,3.33,0,0,0,2.719,9.518a3.879,3.879,0,0,0,.511.034A3.686,3.686,0,0,0,5.985,8.316a3.689,3.689,0,0,0,5.509,0,3.689,3.689,0,0,0,5.509,0,3.7,3.7,0,0,0,2.755,1.235,3.837,3.837,0,0,0,.511-.034A3.327,3.327,0,0,0,22.472,4.427ZM19.766,10.75a4.736,4.736,0,0,1-1.1-.142v3.725H4.332V10.608a4.945,4.945,0,0,1-1.1.142,5.036,5.036,0,0,1-.672-.045,4.694,4.694,0,0,1-.612-.134v7.346a1.193,1.193,0,0,0,1.194,1.194H19.863a1.193,1.193,0,0,0,1.194-1.194V10.571a3.811,3.811,0,0,1-.612.134A5.191,5.191,0,0,1,19.766,10.75Z"
        transform="translate(6.782 8.5)"
        fill="#fff"
      />
    </svg>
  );
};
