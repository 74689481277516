import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  headerWrapper: {
    display: "flex",
    justifyContent: "space-between",
    height: "45px",
    alignItems: "center",
    borderBottom: "0.5px solid #D5D9D9",
    padding: "3px 32px",
    background: "#F0F0F0",
  },

  headerButton: {
    height: "28px",
  },

  pageTitle: {
    fontSize: "20px",
    color: "#9C9C94",
    display: "flex",
    alignItems: "center",
    gap: "15px",
  },

  pageWrapper: {
    padding: "12px 32px 0",
  },

  bodyWrapper: {
    paddingTop: "12px",
    display: "flex",
    gap: "14px",
    height: "calc(100vh - 150px)",
  },

  tooltip: {
    whiteSpace: "pre-wrap",
    textAlign: "center",
    "& .MuiTooltip-tooltip": {
      borderColor: "#EB4233",
      background: "#EFDADC",
      color: "#1C1C19",
      padding: "10px 15px",
      "& .MuiTooltip-arrow:before": {
        background: "#EFDADC",
        borderColor: "#EB4233",
      },
    },
  },
  biggerButton: {
    "& > span": {
      fontSize: "14px",
    },
  },
  mainFilterWrapper: {
    width: "100%",
    height: "66px",
    backgroundColor: "#FFF",
    border: "0.5px solid #D5D9D9",
    borderRadius: "4px 4px 0 0",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    paddingLeft: "16px",
    paddingRight: "16px",
  },
  wrapperChip: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
    gap: "5px",
    overflow: "auto",
    py: "4px",
    "&::-webkit-scrollbar": {
      height: "2px",
    },
  },
  filterChip: {
    borderRadius: "4px",
    border: "0.7px solid #d5d9d9",
    backgroundColor: "#FAFAFB",
    height: "28px",
  },
  viewAllBtn: {
    color: "#6A6A6A",
    width: "81px",
    height: "36px",
    whiteSpace: "nowrap",
    borderColor: "#D5D9D9",
  },
  filterBtn: {
    height: "36px",
    width: "100%",
    maxWidth: "74px",
    border: "0.5px solid #D5D9D9",
    "& .MuiButton-startIcon": {
      marginLeft: 0,
    },
  },
}));

export default useStyles;
