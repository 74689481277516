import { bool, func } from "prop-types";
import { LargeCheckbox } from "components";

export const SelectBlock = ({ checked, handleCheckOrder }) => {
  return (
    <LargeCheckbox
      checked={checked}
      sx={{ padding: 0 }}
      formSx={{ m: 0 }}
      size={22}
      rectSize={22}
      onChange={handleCheckOrder}
      onClick={(e) => e.stopPropagation()}
      checkedViewBox="0 0 24 24"
    />
  );
};

SelectBlock.propTypes = {
  checked: bool,
  handleCheckOrder: func,
};
SelectBlock.defaultProps = {
  checked: false,
  handleCheckOrder: () => {},
};
