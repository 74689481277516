import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  tableHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: "20.5px",
    paddingRight: "20px",
    border: "0.5px solid #D5D9D9",
    borderRadius: "4px 4px 0px 0px",
    backgroundColor: "#EEEEEE",
    height: "39px",
  },

  table: {
    padding: "18px 25px",
  },

  tableBody: {
    border: "0.5px solid #D5D9D9",
    borderTop: "none",
    maxHeight: "515px",
    overflow: "hidden",
    borderRadius: "0 0 4px 4px",
  },

  chip: {
    backgroundColor: "rgba(71, 160, 109, 0.2)",
    color: "#47A06D",
    borderRadius: "4px",
    height: "24px",
  },

  title: {
    fontSize: "25px",
    color: "#707070",
  },

  noReps: {
    color: "#707070",
    display: "flex",
    justifyContent: "center",
    fontSize: "15px",
    padding: "15px 0",
  },
}));

export default useStyles;
