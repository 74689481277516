import { useMemo } from "react";
import moment from "moment";
import {
  getFormattedDate,
  normalizeSnakeCaseString,
} from "../../../../../helpers/helpers";
import { CARRIER_INFO } from "Pages/OrdersPage/Orders.constants";

export const useDeliveryItemActions = ({ item, order, timeZone, list }) => {
  const setType = useMemo(() => {
    if (item?.restock) return "Return with restock";
    return item?.type || "";
  }, [item?.restock, item?.type]);

  const setVia = useMemo(() => {
    return (
      CARRIER_INFO[item?.deliveryMethod]?.carrierName ||
      normalizeSnakeCaseString(item?.deliveryMethod) ||
      ""
    );
  }, [item?.deliveryMethod]);

  const setFulfilledBy = useMemo(() => {
    return {
      text:
        CARRIER_INFO[item?.deliveryMethod]?.name ||
        item?.delivererDuplicate?.name ||
        item?.shippingCarrier?.name ||
        item?.shippingCarrierDuplicate?.name ||
        "",
      icon: CARRIER_INFO[item?.deliveryMethod]?.logo,
    };
  }, [
    item?.delivererDuplicate?.name,
    item?.deliveryMethod,
    item?.shippingCarrier?.name,
    item?.shippingCarrierDuplicate?.name,
  ]);

  const setDate = useMemo(() => {
    return moment(getFormattedDate(item?.date, timeZone)).format("M/D/YYYY");
  }, [item?.date, timeZone]);

  const setQuantity = useMemo(() => {
    if (item?.type === "Refund") return "-";

    if (
      !!item?.totalQuantity &&
      item?.type !== "Refund" &&
      item?.type !== "Restock"
    )
      return `${item?.totalQuantity} of ${order?.totalQuantity}`;

    if (item?.type === "Restock")
      return `${item?.totalQuantity} of ${order?.totalQuantity}`;
  }, [item?.totalQuantity, item?.type, order?.totalQuantity]);

  const currentIndexItemOfList = useMemo(
    () => list.findIndex((el) => el.id === item?.id),
    [item?.id, list]
  );
  const hasReturnAfterDelivery = useMemo(() => {
    if (currentIndexItemOfList > -1) {
      const cutList = list.slice(currentIndexItemOfList + 1, list?.length);
      return cutList.some((el) => el?.type === "Return");
    }
  }, [currentIndexItemOfList, list]);

  const hasDeliveryAfterReturn = useMemo(() => {
    if (currentIndexItemOfList > -1) {
      const cutList = list.slice(currentIndexItemOfList + 1, list?.length);
      return cutList.some((el) => el?.type === "Delivery");
    }
  }, [currentIndexItemOfList, list]);

  const hasEditBtnDisabled = useMemo(() => {
    if (item?.type === "Delivery") {
      return hasReturnAfterDelivery || item?.deliveryMethod === "SHIP_ENGINE";
    }

    if (item?.type === "Return" && item?.deliveryMethod === "REFUND")
      return true;

    if (item?.type === "Return") {
      return hasDeliveryAfterReturn;
    }
  }, [
    hasDeliveryAfterReturn,
    hasReturnAfterDelivery,
    item?.deliveryMethod,
    item?.type,
  ]);

  const hasDeleteBtnDisabled = useMemo(() => {
    if (item?.type === "Delivery") {
      return hasReturnAfterDelivery || item?.deliveryMethod === "SHIP_ENGINE";
    }
    if (item?.type === "Return") {
      return hasDeliveryAfterReturn;
    }
  }, [
    hasDeliveryAfterReturn,
    hasReturnAfterDelivery,
    item?.deliveryMethod,
    item?.type,
  ]);

  const hideBtns = useMemo(() => {
    if (order?.orderStatus === "CANCELED") return false;
    return item?.deliveryMethod !== "RESTOCK" && !(item?.type === "Restock");
  }, [item?.deliveryMethod, item?.type, order?.orderStatus]);

  return {
    setType,
    setVia,
    setFulfilledBy,
    setDate,
    setQuantity,
    hasEditBtnDisabled,
    hasDeleteBtnDisabled,
    hideBtns,
  };
};
