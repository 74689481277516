import backgroundImg from "assets/images/login-background.jpg";

export const cl = {
  registrationPage: {
    mainWrapper: {
      minHeight: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      py: 2,
      background: `linear-gradient(0deg, rgba(225, 225, 225, 0.7), rgba(225, 225, 225, 0.7)), url(${backgroundImg})`,
      backgroundSize: "100% 100%",
      flexDirection: "column",
    },
    paper: {
      maxWidth: "636px",
      width: "636px",
      borderRadius: "16px",
      border: "0.5px solid #D5D9D9",
    },
  },
};
