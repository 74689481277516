import { useCallback, useMemo, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import {
  Button,
  Grid,
  Link,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { NavLink } from "react-router-dom";
import { restorePassword } from "../../services/account";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import useStyles from "./styles";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema } from "./ResetPassword.validation";
import { PASSWORD_CRITERIA } from "../../components/PasswordCriteria/PasswordCriteria.constants";
import PasswordCriteria from "../../components/PasswordCriteria";
import { errorConfig, success } from "../../utils/notifications";
import { MainLogoIcon } from "../../components/Icons/MainLogos";

const SetNewPasswordPage = () => {
  const classes = useStyles();
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const token = useMemo(() => params.get("restore_password_token"), [params]);
  const role = useMemo(() => params.get("role"), [params]);

  const { control, handleSubmit, trigger } = useForm({
    mode: "all",
    defaultValues: { newPassword: "", confirmPassword: "" },
    resolver: yupResolver(validationSchema()),
  });

  const formField = useWatch({ control });

  const onSubmit = useCallback(async () => {
    const preparedData = {
      data: {
        new_password: formField.newPassword,
        restore_password_token: token,
      },
      role,
    };

    try {
      await restorePassword(preparedData);
      navigate("/password-updated", { state: { role } });
      success("Password changed successfully!");
    } catch (error) {
      setIsLoading(false);
      toast.error(error, errorConfig);
    } finally {
      setIsLoading(false);
    }
  }, [formField.newPassword, token, navigate, role]);

  return (
    <Box className={classes.background}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        minHeight="100dvh"
      >
        <Grid
          item
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            p: 1,
          }}
        >
          <Paper className={classes.mainBlock} elevation={3} width="100%">
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                px: "17%",
              }}
            >
              <MainLogoIcon />
            </Box>
            <Typography
              fontSize="25px"
              textAlign="center"
              mt="71px"
              mb="13px"
              color="#5F6267"
            >
              Choose new password
            </Typography>

            <Controller
              render={({ field, fieldState: { error } }) => (
                <TextField
                  fullWidth
                  margin="normal"
                  placeholder="Enter new password"
                  size="medium"
                  error={!!error?.message}
                  type={isShowPassword ? "text" : "password"}
                  InputProps={{
                    className: classes.input,
                  }}
                  InputLabelProps={{
                    className: classes.inputLabel,
                  }}
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    if (formField?.confirmPassword)
                      trigger(["confirmPassword"]);
                  }}
                />
              )}
              name="newPassword"
              control={control}
            />

            <Controller
              render={({ field, fieldState: { error } }) => (
                <TextField
                  fullWidth
                  margin="normal"
                  placeholder="Re-enter new password"
                  size="medium"
                  error={!!error?.message}
                  type={isShowPassword ? "text" : "password"}
                  InputProps={{
                    className: classes.input,
                  }}
                  InputLabelProps={{
                    className: classes.inputLabel,
                  }}
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    if (formField?.newPassword) trigger(["newPassword"]);
                  }}
                />
              )}
              name="confirmPassword"
              control={control}
            />

            <Grid className={classes.bottomPanel} container>
              <PasswordCriteria
                password={formField.newPassword}
                confirmPassword={formField.confirmPassword}
              />
              <Grid item>
                <Link
                  component="button"
                  underline="none"
                  color={isShowPassword ? "ground.main" : "primary.main"}
                  onClick={() => setIsShowPassword(!isShowPassword)}
                  fontSize="19px"
                >
                  SHOW
                </Link>
              </Grid>
            </Grid>
            <Button
              disabled={PASSWORD_CRITERIA.some((criteria) => {
                const { newPassword: password, confirmPassword } = formField;
                const completed = criteria.condition({
                  password,
                  confirmPassword,
                });
                return !completed;
              })}
              sx={{ mt: 4, mb: 2 }}
              className={classes.loginButton}
              variant="contained"
              fullWidth
              onClick={handleSubmit(onSubmit)}
            >
              {isLoading ? "Loading..." : "Reset Password"}
            </Button>
            <Button
              variant="outlined"
              color="inactive"
              sx={{ color: "#5F6267", fontSize: "18px" }}
              fullWidth
              component={NavLink}
              to="/login"
              className={classes.loginButton}
            >
              Cancel
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SetNewPasswordPage;
