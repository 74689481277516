import { yupResolver } from "@hookform/resolvers/yup";
import {
  Grid,
  IconButton,
  MenuItem,
  Typography,
  Box,
  Drawer,
  CircularProgress,
} from "@mui/material";
import { bool, func, object, array, string } from "prop-types";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import NumberFormat from "react-number-format";
import {
  createContactRoleService,
  getContactRolesService,
} from "services/contactRoles";
import { error } from "utils/notifications";
import { normalizeUppercaseString, onPastePhone } from "helpers/helpers";
import { LargeCheckbox } from "../../Checkboxes";
import { CrossIcon, OutlinedPlusIcon } from "../../Icons";
import { StyledSelect } from "../../Selects";
import StyledButton from "../../StyledButton";
import { StyledTextField } from "../../TextFields/TextFields";
import { validationSchema } from "./ContactPopup.validations";
import useStyles from "./styles";

const ContactAddDrawer = ({
  type,
  isOpen,
  editContact,
  handleClose,
  handleAddContact,
  contacts,
  handleDeleteContact,
}) => {
  const classes = useStyles();

  const [rolesList, setRolesList] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleDefaultRole = useCallback(() => {
    if (!editContact?.itemTitle && !editContact?.role && !!rolesList?.length) {
      const primaryRole = rolesList.filter(({ defaultRole }) => !!defaultRole);
      return primaryRole?.[0]?.name;
    }
    return (
      editContact?.itemTitle ||
      editContact?.role ||
      editContact?.contactRole?.name ||
      ""
    );
  }, [
    editContact?.contactRole?.name,
    editContact?.itemTitle,
    editContact?.role,
    rolesList,
  ]);

  const isPrimaryChecked = useMemo(
    () => contacts.length === 1 && editContact,
    [contacts.length, editContact]
  );

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      fid: editContact?.fid || Date.now(),
      id: editContact?.id || "",
      email: editContact?.email || editContact?.customerEmail || "",
      name: editContact?.contactRole?.name || editContact?.name || "",
      phone: editContact?.phone || "",
      role: handleDefaultRole(),
      defaultContact: !!editContact?.defaultContact,
      custom: "",
    },
    resolver: yupResolver(validationSchema()),
  });

  const formField = useWatch({
    control,
  });

  const onSubmit = async (data) => {
    const { name, phone, email, defaultContact, custom, role, fid, id } = data;

    const setPhone = (phone) => {
      const cleanedPhone = phone.replace(/[^+\d]/g, "");
      const preparedPhone = cleanedPhone?.length === 12 ? cleanedPhone : "";

      return preparedPhone;
    };

    const newContact = {
      name,
      phone: setPhone(phone),
      email,
      defaultContact: isPrimaryChecked ? true : defaultContact,
      fid,
      id: id || undefined,
      role: role === "custom" ? custom : role,
    };

    if (data.role === "custom") {
      try {
        const res = await createContactRoleService({
          name: data.role === "custom" ? custom : role,
          defaultRole: false,
        });

        handleAddContact(
          { ...newContact, contactRoleId: res.id },
          !!editContact
        );
        handleClose();
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        error(err?.response?.data?.message || "Something went wrong.");
      }
      return;
    }

    const currentRoleId = rolesList.filter(({ name }) => name === role)[0].id;

    handleAddContact(
      { ...newContact, contactRoleId: currentRoleId },
      !!editContact
    );
    handleClose();
  };

  useEffect(() => {
    if (isOpen) {
      reset({
        fid: editContact?.fid || Date.now(),
        id: editContact?.id || "",
        email: editContact?.email || editContact?.customerEmail || "",
        name: editContact?.name || editContact?.customerName || "",
        phone: editContact?.phone || "",
        role: handleDefaultRole(),
        defaultContact: contacts.length ? !!editContact?.defaultContact : true,
        custom: editContact?.role || editContact?.itemTitle || "",
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, isOpen, editContact?.id, handleDefaultRole]);

  const getRolesList = async () => {
    setLoading(true);
    await getContactRolesService()
      .then((res) => setRolesList(res))
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (isOpen) return getRolesList();
  }, [isOpen]);

  const isDisabled = useMemo(() => {
    return (
      !formField?.name ||
      !formField?.role ||
      (formField?.role === "custom" && !formField?.custom) ||
      !!errors?.name ||
      !!errors?.phone ||
      !!errors?.email ||
      !!errors?.role ||
      !!errors?.custom
    );
  }, [
    errors?.custom,
    errors?.email,
    errors?.name,
    errors?.phone,
    errors?.role,
    formField?.custom,
    formField?.name,
    formField?.role,
  ]);

  const saveBtnNameRef = useRef(null);
  const titleRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      saveBtnNameRef.current = editContact ? "Save contact" : "Add";
      titleRef.current = editContact
        ? `Edit ${type} contact`
        : `Add ${type} contact`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={handleClose}
      sx={{ zIndex: 1501 }}
    >
      <Box sx={{ borderBottom: "0.5px solid #D5D9D9", background: "#F8F8F8" }}>
        <IconButton
          sx={{ position: "absolute", top: 14, right: 14, p: 0 }}
          onClick={handleClose}
        >
          <CrossIcon />
        </IconButton>
        <Typography
          p="14px 0 12px 26px"
          fontWeight="400"
          fontSize="17px"
          color="#3F3F3F"
        >
          {titleRef.current}
        </Typography>
      </Box>
      <Box sx={{ p: "10px 26px 24px 26px", minWidth: "545px" }}>
        <Grid sx={{ display: "flex", flexDirection: "column" }} container>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                adminIsAllowed
                formSx={{ mt: "15px" }}
                fullWidth
                size="small"
                InputProps={{
                  className: classes.textInput,
                }}
                InputLabelProps={{
                  className: classes.inputLabel,
                }}
                label="Full name *"
                error={error ? error.message : ""}
                {...field}
              />
            )}
            name="name"
            control={control}
          />

          <Controller
            render={({ field, fieldState: { error } }) => (
              <NumberFormat
                adminIsAllowed
                customInput={StyledTextField}
                fullWidth
                formSx={{ mt: "26px" }}
                size="small"
                InputProps={{
                  className: classes.textInput,
                }}
                InputLabelProps={{
                  className: classes.inputLabel,
                }}
                error={error ? error.message : ""}
                label="Phone number"
                format="+# (###) ### ####"
                mask="_"
                onClick={() => {
                  if (["", "+"].includes(field?.value)) {
                    setValue("phone", "+1");
                  }
                }}
                {...field}
                autoComplete="new-password"
                onPaste={(e) => {
                  onPastePhone({ e, setValue, currentValue: formField.phone });
                }}
              />
            )}
            name="phone"
            control={control}
          />
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                adminIsAllowed
                formSx={{ mt: "26px" }}
                fullWidth
                size="small"
                InputProps={{
                  className: classes.textInput,
                }}
                InputLabelProps={{
                  className: classes.inputLabel,
                }}
                label="Email address"
                error={error ? error.message : ""}
                {...field}
              />
            )}
            name="email"
            control={control}
          />
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledSelect
                adminIsAllowed
                formSx={{
                  mt: "26px",
                  mb: "9px",
                  "& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused": {
                    paddingLeft: 0,
                  },
                }}
                label="Contact type"
                leftSidedIcon
                error={error ? error.message : ""}
                MenuProps={{
                  sx: {
                    zIndex: 1502,
                    maxHeight: "348px",
                  },
                }}
                {...field}
                renderValue={(s) => normalizeUppercaseString(s)}
              >
                {!!rolesList.length &&
                  !loading &&
                  rolesList.map(({ name }) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                {loading ? (
                  <MenuItem sx={{ width: "100%" }}>
                    <CircularProgress size={20} sx={{ mx: "auto" }} />
                  </MenuItem>
                ) : (
                  <MenuItem
                    value="custom"
                    sx={{
                      color: "#409A65",
                      "& svg": {
                        mr: "5px",
                      },
                    }}
                  >
                    <OutlinedPlusIcon /> Add new
                  </MenuItem>
                )}
              </StyledSelect>
            )}
            name="role"
            control={control}
          />

          {formField?.role === "custom" && (
            <Controller
              render={({ field, fieldState: { error } }) => (
                <StyledTextField
                  adminIsAllowed
                  formSx={{ mt: "15px" }}
                  fullWidth
                  size="small"
                  InputProps={{
                    className: classes.textInput,
                  }}
                  InputLabelProps={{
                    className: classes.inputLabel,
                  }}
                  label="Custom"
                  error={error ? error.message : ""}
                  {...field}
                />
              )}
              name="custom"
              control={control}
            />
          )}

          <Controller
            render={({ field }) => (
              <LargeCheckbox
                adminIsAllowed
                disabled={!contacts.length}
                checked={
                  !!field.value ||
                  editContact?.status === "PRIMARY" ||
                  isPrimaryChecked
                }
                formSx={{ mr: 0, mb: "13px", ml: "-9px" }}
                label={
                  <Typography color="#5F6267" fontSize="13px" fontWeight="400">
                    Set as primary
                  </Typography>
                }
                size={16}
                {...field}
              />
            )}
            name="defaultContact"
            control={control}
          />
        </Grid>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          background: "#F8F8F8",
          p: "24px 42px 24px 42px",
          mt: "auto",
        }}
      >
        <Box>
          {!!editContact && !!handleDeleteContact && (
            <StyledButton
              color="confirmDelete"
              sx={{
                height: "31px",
                width: "78px",
                boxShadow: "none",
              }}
              onClick={() => {
                handleClose();
                handleDeleteContact(editContact);
              }}
              label={
                <Typography fontSize={15} fontWeight={400}>
                  Delete
                </Typography>
              }
              variant="contained"
            />
          )}
        </Box>

        <Box>
          <StyledButton
            sx={{ height: 31, borderColor: "#D5D9D9" }}
            onClick={handleClose}
            label={
              <Typography
                fontSize="15px"
                sx={{
                  color: "#5f6267",
                }}
                fontWeight="normal"
              >
                Cancel
              </Typography>
            }
            variant="text"
          />
          <StyledButton
            disabled={isDisabled}
            sx={{ height: 31, boxShadow: "none", ml: "10px" }}
            onClick={handleSubmit(onSubmit)}
            label={
              <Typography fontSize="15px" fontWeight="normal">
                {saveBtnNameRef.current}
              </Typography>
            }
            variant="contained"
          />
        </Box>
      </Box>
    </Drawer>
  );
};

ContactAddDrawer.propTypes = {
  isOpen: bool,
  handleClose: func,
  handleAddContact: func,
  editContact: object,
  contacts: array,
  type: string,
  handleDeleteContact: func,
};

ContactAddDrawer.defaultProps = {
  isOpen: false,
  contacts: [],
  type: "customer",
};

export default ContactAddDrawer;
