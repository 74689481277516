import React, { useEffect } from "react";
import { bool, func, number, object, string } from "prop-types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { CrossBigIcon } from "../../../../components/Icons";
import StyledButton from "../../../../components/StyledButton";
import { StyledTextField } from "../../../../components/TextFields/TextFields";
import { useState } from "react";
import { Controller } from "react-hook-form";
import ArrowBtn from "components/Buttons/ArrowBtn/ArrowBtn";

const DeliveryFeeDialog = ({
  currentDeliveryFee,
  orderTotal,
  open,
  onClose,
  handleUpdateDeliveryFee,
  control,
  setValue,
  errors,
}) => {
  const cl = {
    tabs: {
      maxHeight: "34px",
      minHeight: "20px",
      "& .Mui-selected": {
        backgroundColor: "transparent!important",
      },
      "& .MuiTabs-indicator": {
        borderRadius: "4px 4px 0 0",
      },
    },
    tab: {
      height: "34px",
      minHeight: "20px",
      p: 0,
      fontSize: 14,
      color: "#707070",
      textTransform: "none",
    },
    input: {
      height: "27px",
    },
  };

  const [currentTab, setCurrentTab] = useState("Default");

  useEffect(() => {
    setValue("manualDeliveryFee", currentTab !== "Default", {
      shouldDirty: true,
    });
  }, [currentTab, setValue, open]);

  return (
    <Dialog open={open} onClose={onClose} disablePortal>
      <DialogTitle
        sx={{
          maxWidth: "230px",
          width: "230px",
          height: "48px",
          p: "0 12px 0 24px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid #CCCCCC",
        }}
      >
        <Typography sx={{ fontSize: 17, color: "#3F3F3F" }}>
          Delivery Fee
        </Typography>
        <IconButton onClick={onClose}>
          <CrossBigIcon size={15.5} />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{
          maxWidth: "230px",
          p: 0,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid sx={{ width: "182px" }} container>
          <Grid
            sx={{ borderBottom: "1px solid #CCCCCC", marginTop: 1 }}
            xs={12}
            item
          >
            <Tabs
              sx={cl.tabs}
              value={currentTab}
              onChange={(e, newTabId) => setCurrentTab(newTabId)}
            >
              <Tab sx={cl.tab} label="Default" value="Default" />
              <Tab sx={cl.tab} label="Custom" value="Custom" />
            </Tabs>
          </Grid>
          <Grid sx={{ marginTop: 2, marginBottom: 2 }} xs={12} item container>
            <Grid
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
              }}
              item
              container
            >
              <Typography sx={{ fontSize: 12, color: "#363531" }}>
                Current delivery fee
              </Typography>
              <Typography
                sx={{ fontSize: 14, color: "#363531", fontWeight: 600 }}
              >
                ${(parseFloat(currentDeliveryFee) || 0).toFixed(2)}
              </Typography>
            </Grid>
            {currentTab === "Default" && (
              <>
                <Grid
                  sx={{ marginTop: 2, height: "27px", alignItems: "center" }}
                  item
                  container
                >
                  <Typography
                    sx={{ fontSize: 12, color: "#363531", fontWeight: 400 }}
                  >
                    {orderTotal} of the order total
                  </Typography>
                </Grid>
              </>
            )}
            {currentTab === "Custom" && (
              <>
                <Grid
                  sx={{
                    marginTop: 2,
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  item
                  container
                >
                  <Grid sx={{ alignItems: "center" }} xs={7} item container>
                    <Typography sx={{ fontSize: 12, color: "#363531" }}>
                      New delivery fee
                    </Typography>
                  </Grid>
                  <Grid xs={5} item>
                    <Controller
                      render={({ field, fieldState: { error } }) => (
                        <StyledTextField
                          sx={{
                            "& .MuiInputBase-input": {
                              fontSize: 12,
                            },
                          }}
                          // InputProps={{ sx: { height: "27px" } }}
                          InputProps={{
                            sx: {
                              padding: "0",
                              height: "32px",
                              fontSize: "12px",
                            },
                            endAdornment: (
                              <ArrowBtn
                                stylesBox={{
                                  display: "flex",
                                  flexDirection: "column",
                                  height: "32px",
                                }}
                                stylesUpBtn={{
                                  height: "16px",
                                  borderRadius: "0",
                                  background: "#EEEEEE",
                                  "&:hover": {
                                    backgroundColor: "lightgray",
                                    cursor: "pointer",
                                  },
                                }}
                                stylesDownBtn={{
                                  height: "16px",
                                  borderRadius: "0",
                                  background: "#EEEEEE",
                                  "&:hover": {
                                    backgroundColor: "lightgray",
                                    cursor: "pointer",
                                  },
                                }}
                                onClickUp={() => {
                                  setValue("deliveryFee", +field.value + 1);
                                }}
                                onClickDown={() => {
                                  if (+field.value > 1)
                                    setValue("deliveryFee", +field.value - 1);
                                }}
                              />
                            ),
                          }}
                          placeholder="0 $"
                          error={!!error}
                          {...field}
                        />
                      )}
                      name="deliveryFee"
                      control={control}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions
        sx={{
          maxWidth: "230px",
          p: "18px 24px 18px 24px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <StyledButton
          sx={{ width: "87px", height: "27px", color: "#6A6A6A" }}
          fontSize="10px"
          color="groundLighter"
          label="Cancel"
          variant="outlined"
          onClick={onClose}
        />
        <StyledButton
          disabled={errors?.deliveryFee}
          sx={{ width: "87px", height: "27px" }}
          fontSize="10px"
          label="Save"
          variant="contained"
          onClick={handleUpdateDeliveryFee}
        />
      </DialogActions>
    </Dialog>
  );
};
DeliveryFeeDialog.propTypes = {
  currentDeliveryFee: number,
  orderTotal: string,
  open: bool,
  onClose: func,
  handleUpdateDeliveryFee: func,
  control: object,
  setValue: func,
  errors: object,
};

export default DeliveryFeeDialog;
