export const PedestrianIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9.314"
      height="14.901"
      viewBox="0 0 9.314 14.901"
    >
      <path
        data-name="Icon awesome-walking"
        d="M6.052 2.794a1.4 1.4 0 1 0-1.4-1.4 1.4 1.4 0 0 0 1.4 1.4Zm2.746 4.338-.678-.343-.282-.856a3.173 3.173 0 0 0-2.971-2.208 6.4 6.4 0 0 0-2.715.733A2.776 2.776 0 0 0 .706 5.8l-.195.4a.934.934 0 0 0 .413 1.247.919.919 0 0 0 1.237-.416l.195-.4a.925.925 0 0 1 .48-.448l.78-.314-.442 1.77a1.869 1.869 0 0 0 .433 1.711l1.743 1.9a1.878 1.878 0 0 1 .434.806l.533 2.133a.931.931 0 1 0 1.807-.451l-.646-2.59a1.862 1.862 0 0 0-.434-.806L5.72 8.9l.5-2 .16.48a1.856 1.856 0 0 0 .922 1.077l.678.343a.919.919 0 0 0 1.238-.416.942.942 0 0 0-.42-1.252Zm-6.657 4.095a1.806 1.806 0 0 1-.413.626L.273 13.31a.931.931 0 1 0 1.315 1.319L3.316 12.9a1.892 1.892 0 0 0 .413-.626l.393-.984c-1.609-1.754-1.124-1.216-1.379-1.562l-.6 1.5Z"
      />
    </svg>
  );
};
