import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  title: {
    padding: "6px 16px 0 16px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "41px",
  },

  itemsBlock: {
    background: "#F8F8F8",
    borderRadius: "4px",
    padding: "7px 14px",
    margin: "0px 16px 15px",
    border: "1px solid #D5D9D9",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: "68px",
  },
  buttons: {
    borderColor: "#D4D4D4",
    color: "#000",
    maxWidth: "55px",
    minWidth: "55px",
    height: "20px",
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  label: {
    fontSize: "13px",
    fontWeight: "500",
    color: "#5F6267",
  },
  itemsWrap: {
    maxHeight: "235px",
    // overflow: "auto",
  },
}));

export default useStyles;
