import { useCallback, useState } from "react";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import useStyles from "../../styles";
import {
  orderTagsCountSelector,
  orderTagsListSelector,
} from "redux/selectors/tags";
import { setConfirmIsOpenAction } from "redux/actions/confirmDialogs";
import {
  deleteTagAction,
  getTagsAction,
  mergeTagsAction,
} from "redux/actions/tags";
import { SCROLL_LIMIT, TAGS_TYPE } from "utils/constants";
import { handleConfirmAction } from "Pages/DiscountsPage/DiscountsPage.helpers";
import { InfiniteScrollWrapper, MergeTagDialog, TagItem } from "components";
import { TitleBlock, HeaderBlock } from "./components";

const selector = createSelector(
  orderTagsListSelector,
  orderTagsCountSelector,
  (tagsList, tagsCount) => ({
    tagsList,
    tagsCount,
  })
);

export const TagsTab = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { tagsList, tagsCount } = useSelector(selector);

  const [limitTags, setLimitTags] = useState(2 * SCROLL_LIMIT);

  const [mergeDialogState, setMergeDialogState] = useState({
    open: false,
    tag: null,
    loading: false,
  });

  const handleDelete = useCallback(
    (tag) => {
      dispatch(
        handleConfirmAction({
          title: "Delete order tag?",
          text: (
            <Typography
              fontWeight="400"
              fontSize="13px"
              color="#363531"
              whiteSpace="pre-line"
              marginTop="4px"
              marginBottom="8px"
            >
              {"Are you sure you want to delete "}
              <span style={{ color: "#47A06D" }}>{tag?.tag}</span>?
            </Typography>
          ),
          confirmColor: "confirmDelete",
          onConfirm: () => {
            dispatch(deleteTagAction(tag?.id, TAGS_TYPE.ORDERS));
            dispatch(setConfirmIsOpenAction(false));
          },
        })
      );
    },
    [dispatch]
  );

  const handleRename = useCallback(
    (tag) => {
      navigate(`tags/${tag.id}`, { state: tag });
    },
    [navigate]
  );

  const handleMerge = (tag) => {
    setMergeDialogState((prev) => ({ ...prev, open: true, tag }));
  };

  const handleFetchGroups = useCallback(() => {
    dispatch(
      getTagsAction({
        limit: limitTags,
      })
    );
    setLimitTags(limitTags + SCROLL_LIMIT);
  }, [dispatch, limitTags]);

  const onMerge = (mergeIds) => {
    dispatch(
      mergeTagsAction(
        TAGS_TYPE.ORDERS,
        mergeDialogState.tag?.id,
        mergeIds,
        setMergeDialogState,
        "orderCount"
      )
    );
  };

  return (
    <>
      <MergeTagDialog
        isOpen={mergeDialogState.open}
        onClose={() =>
          setMergeDialogState((prev) => ({ ...prev, tag: null, open: false }))
        }
        tag={mergeDialogState.tag}
        tagsList={tagsList.filter((tag) => tag.id !== mergeDialogState.tag?.id)}
        loading={mergeDialogState.loading}
        {...{ onMerge }}
      />
      <Box className={classes.ordersBody}>
        <TitleBlock />
        <HeaderBlock />
        <Stack
          sx={{
            height: "100%",
            maxHeight: "288px",
            overflow: "hidden",
            borderWidth: "0px 1px 1px 1px",
            borderStyle: "solid",
            borderColor: "#D5D9D9",
            borderRadius: "0 0 4px 4px",
            background: !tagsList.length && "white",
          }}
        >
          {tagsCount > 0 ? (
            <InfiniteScrollWrapper
              dataLength={tagsList.length}
              next={handleFetchGroups}
              loader={
                <CircularProgress sx={{ ml: "50%", mt: "2px" }} size="30px" />
              }
              style={{ overflowX: "hidden" }}
              hasMore={tagsList.length < tagsCount}
              maxHeight="287px"
              id="order-tags-scroll"
            >
              {tagsList.map((tag) => (
                <TagItem
                  key={tag.id}
                  {...{
                    tag,
                    tagsList,
                    handleDelete,
                    handleRename,
                    handleMerge,
                  }}
                  type="order"
                />
              ))}
            </InfiniteScrollWrapper>
          ) : (
            <Typography
              fontWeight="300"
              fontSize="16px"
              textAlign="center"
              width="100%"
              color="#6A6A6A"
              paddingTop="20px"
              paddingBottom="20px"
            >
              No tags found
            </Typography>
          )}
        </Stack>
      </Box>
    </>
  );
};
