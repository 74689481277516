import { object, func, number, array, bool } from "prop-types";
import { Box, Stack } from "@mui/material";
import useStyles from "./styles";
import { useRepsPermissions } from "helpers/hooks";
import { CustomerBlock, HeaderBlock } from "./components";
import { StyledTooltip } from "components";

const MapSameCustomersTooltip = ({
  setTooltipHovered,
  checkedCustomers,
  subAddresses,
  top,
  handleCheckCustomer,
  left,
  children,
  hideTooltip,
  showTooltip,
  tooltipIsOpen,
  setTooltipIsOpen,
  setShowTooltip,
}) => {
  const classes = useStyles({
    top,
    left,
  });

  const repPermissions = useRepsPermissions();

  return (
    <StyledTooltip
      open={tooltipIsOpen && showTooltip}
      onOpen={() => setTooltipIsOpen(true)}
      onClose={() => {
        setShowTooltip(false);
        setTooltipIsOpen(false);
      }}
      arrow
      placement="top"
      disableFocusListener
      disableHoverListener={hideTooltip}
      sx={{
        "& .MuiTooltip-tooltip": {
          mb: "0 !important",
          p: 0,
          maxWidth: "none",
        },
      }}
      PopperProps={{
        modifiers: [
          {
            name: "offset",
            options: { offset: [0, -2] },
          },
        ],
      }}
      title={
        <Box
          className={classes.bodyWrapper}
          onMouseEnter={() => setTooltipHovered(true)}
          onMouseLeave={() => setTooltipHovered(false)}
          onClick={(e) => e.stopPropagation()}
          onDoubleClick={(e) => e.stopPropagation()}
          onMouseMove={(e) => e.stopPropagation()}
        >
          <HeaderBlock />

          {!!subAddresses?.length && (
            <Stack direction="column" px="18px">
              {subAddresses.map((address, index) => (
                <CustomerBlock
                  key={index}
                  customer={address}
                  {...{
                    handleCheckCustomer,
                    repPermissions,
                    isChecked: checkedCustomers.some(
                      (checked) => checked.id === address?.id
                    ),
                  }}
                />
              ))}
            </Stack>
          )}
        </Box>
      }
    >
      {children}
    </StyledTooltip>
  );
};

MapSameCustomersTooltip.propTypes = {
  customer: object,
  setTooltipHovered: func,
  handleCheckCustomer: func,
  top: number,
  left: number,
  currentUser: object,
  children: object,
  subAddresses: array,
  checkedCustomers: array,
  hideTooltip: bool,
  setShowTooltip: func,
  tooltipIsOpen: bool,
  showTooltip: bool,
  setTooltipIsOpen: func,
};

export default MapSameCustomersTooltip;
