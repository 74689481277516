import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUserFieldAction } from "../../redux/actions/auth";
import { Box, Button, Dialog, IconButton, Typography } from "@mui/material";
import { cl } from "./ConfirmedSelectionPlanDialog.styles";
import { ActivateBigIcon, CrossIcon, SentLetterBigIcon } from "../Icons";

export const ConfirmedSelectionPlanDialog = () => {
  const currentUser = useSelector(({ auth }) => auth?.currentUser);
  const dispatch = useDispatch();

  const openDialog = useMemo(
    () => !!currentUser?.confirmedSelectionPlanDialog,
    [currentUser?.confirmedSelectionPlanDialog]
  );

  const handleClose = useCallback(() => {
    dispatch(updateUserFieldAction("confirmedSelectionPlanDialog", null));
  }, [dispatch]);

  const type = useMemo(
    () => currentUser?.confirmedSelectionPlanDialog?.type,
    [currentUser?.confirmedSelectionPlanDialog?.type]
  );

  const current_plan = useMemo(
    () => currentUser?.confirmedSelectionPlanDialog?.current_plan,
    [currentUser?.confirmedSelectionPlanDialog?.current_plan]
  );

  const chosen_plan = useMemo(
    () => currentUser?.confirmedSelectionPlanDialog?.chosen_plan,
    [currentUser?.confirmedSelectionPlanDialog?.chosen_plan]
  );

  const extra_user = useMemo(
    () => currentUser?.confirmedSelectionPlanDialog?.extra_user,
    [currentUser?.confirmedSelectionPlanDialog?.extra_user]
  );

  return (
    <Dialog open={openDialog} sx={cl.dialog}>
      <IconButton
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
        }}
        onClick={handleClose}
      >
        <CrossIcon />
      </IconButton>
      <Box
        sx={{
          height: "164px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {type === "Request sent" && <SentLetterBigIcon />}
        {type === "Activated" && <ActivateBigIcon />}
      </Box>
      <Box
        sx={{
          textAlign: "center",
        }}
      >
        <Typography fontSize={24} fontWeight={400} color="#707070">
          {type}
        </Typography>
      </Box>
      <Box
        sx={{
          textAlign: "center",
          px: 9,
          mb: 4,
        }}
      >
        <Typography fontSize={12} fontWeight={300} color="#1C1C19">
          The request to get{" "}
          <Box fontSize={12} fontWeight={600} color="#009B43" component="span">
            {extra_user > 0
              ? current_plan?.subscription?.name
              : chosen_plan?.name}
          </Box>{" "}
          plan was successfully sent
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          px: 3,
          pb: 3,
        }}
      >
        <Button
          sx={{
            height: "40px",
            fontSize: 16,
            borderRadius: "4px",
            bgcolor: "#409A65",
          }}
          fullWidth
          variant="contained"
          onClick={handleClose}
        >
          Ok
        </Button>
      </Box>
    </Dialog>
  );
};

ConfirmedSelectionPlanDialog.propTypes = {};
ConfirmedSelectionPlanDialog.defaultProps = {};
