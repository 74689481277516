import { useCallback, useEffect, useState } from "react";
import { getRepsDuplicatesService, getRepsService } from "services/reps";
import { SCROLL_LIMIT } from "utils/constants";
import { error } from "utils/notifications";

export const ROLES = {
  merchandiser: "merchandiser",
  sales: "sales",
  delivery: "delivery",
  third_party: "third_party",
};

export const TYPES = {
  reps: "reps",
  duplicates: "duplicates",
};

const init = {
  loading: false,
  count: 0,
  existData: false,
  countMerchandiser: 0,
  countSales: 0,
  countDelivery: 0,
  countThirdParty: 0,
  existOrdersWithNoSalesAssigned: false,
  list: [],
  searcheble: false
};

export const useRepsActions = ({ type, params, open = true }) => {
  const [repsState, setRepsState] = useState(init);

  const [repsParams, setRepsParams] = useState({
    page: 1,
    cursor: null,
    limit: SCROLL_LIMIT,
    ...params,
  });

  const handleFetchReps = useCallback(
    async (page) => {
      setRepsState((prev) => ({ ...prev, loading: true }));
      try {
        const getService = (params) => {
          if (type === TYPES.reps) return getRepsService(params);

          if (type === TYPES.duplicates)
            return getRepsDuplicatesService(params);
        };

        const res = await getService({
          ...repsParams,
          page: page || repsParams.page,
        });

        const count = res?.count || 0;
        const existData = !!res?.existData;
        const countMerchandiser = res?.countMerchandiser || 0;
        const countSales = res?.countSales || 0;
        const countDelivery = res?.countDelivery || 0;
        const countThirdParty = res?.countThirdParty || 0;
        const existOrdersWithNoSalesAssigned =
          !!res?.existOrdersWithNoSalesAssigned;
        const rows = res?.rows || [];

        setRepsState((prev) => ({
          ...prev,
          list: page > 1 ? [...prev.list, ...rows] : rows,
          count: count,
          existData,
          countMerchandiser,
          countSales,
          countDelivery,
          countThirdParty,
          existOrdersWithNoSalesAssigned,
          searcheble: !prev.searcheble ? count >= 10 : prev.searcheble
        }));

        if (page) setRepsParams((prev) => ({ ...prev, page }));
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        error(err?.response?.data?.message || "Something went wrong.");
      } finally {
        setRepsState((prev) => ({ ...prev, loading: false }));
      }
    },
    [repsParams, type]
  );

  useEffect(() => {
    if (!type) {
      // eslint-disable-next-line no-console
      return console.error("TYPE_IS_REQUIRED");
    }

    if (open) {
      handleFetchReps();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, repsParams.search]);

  return {
    ...repsState,
    page: repsParams.page,
    handleFetchReps,
    setRepsParams
  };
};
