import { string } from "prop-types";
import { Typography } from "@mui/material";

export const DistributorNameBlock = ({ distributorName }) => {
  return (
    <Typography fontSize={21} fontWeight={600} color="#363531">
      {distributorName}
    </Typography>
  );
};

DistributorNameBlock.propTypes = { distributorName: string };
DistributorNameBlock.defaultProps = { distributorName: "" };
