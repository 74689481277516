import { array } from "prop-types";
import { Box, Typography } from "@mui/material";

export const MsgToast = ({ messages }) => {
  return (
    <Box width="520px">
      <Box textAlign="center">
        <Typography fontSize={18} fontWeight={600}>
          Variations Already Exist
        </Typography>
      </Box>
      <Box mb={2} textAlign="center">
        <Typography fontSize={16} fontWeight={400}>
          {`It seems that some of the variations you're trying to add already exist for this product. These variations won't be added again:`}
        </Typography>
      </Box>
      {messages.map((m) => (
        <Box key={m}>{m}</Box>
      ))}
    </Box>
  );
};

MsgToast.propTypes = { messages: array };
MsgToast.defaultProps = { messages: [] };
