export const HEADER_ITEMS = [
  { name: "Personal details", size: 2.5, styles: { pl: 3 } },
  { name: "Bank", size: 2.5 },
  { name: "Payout schedule", size: 2.5 },
  { name: "Statement descriptor", size: 2.5 },
  { name: "Credit cards", size: 2 },
];

export const HEADER_ITEMS_NO_BANK = [
  { name: "Personal details", size: 2.5, styles: { pl: 3 } },
  { name: "Bank", size: 7.5 },
  { name: "Credit cards", size: 2 },
];
