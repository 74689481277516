import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  tabs: {
    minHeight: "fit-content",
    "& .MuiTabs-indicator": {
      borderRadius: "6px 6px 0 0",
    },
  },
  tab: {
    textTransform: "none",
    padding: "7px 15px",
    "&.Mui-selected": {
      background: "transparent !important",
    },
    minHeight: "fit-content",
    lineHeight: 1,
    minWidth: "fit-content",
    fontSize: "19px",
    fontWeight: "400",
  },
}));

export default useStyles;
