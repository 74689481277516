import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({

  pageHeader: {
    padding: "24px 32px 19px 34px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  title: {
    fontSize: "30px",
    color: "#5F6267",
  },

  pageBody: {
    padding: "13px 32px 13px 46px",
  },

  bodyText: {
    fontSize: "17px",
  },

  optionsWrapper: {
    display: "flex",
    gap: "43px",
    marginTop: "36px",
  },
}));

export default useStyles;
