import { CARRIER_INFO } from "Pages/OrdersPage/Orders.constants";
import { useMemo, useState } from "react";

export const useActivityItemActions = ({ activity, order }) => {
  const [isInfoShow, setInfoShow] = useState(false);

  const toggleExpandInfo = () => {
    setInfoShow((prev) => !prev);
  };

  const CREATED_BY = useMemo(
    () => ({
      DISTRIBUTOR: "You",
      CUSTOMER: order?.customer?.name,
      ORDER_DIRECT: "Order Direct",
      REPRESENTATIVE: activity?.representativeDuplicate?.name,
      QUICKBOOKS: "Quickbooks",
    }),
    [activity?.representativeDuplicate?.name, order?.customer?.name]
  );

  const setName = useMemo(() => {
    const { createdByType: type } = activity || {};

    return CREATED_BY[type] || CARRIER_INFO[type]?.name;
  }, [CREATED_BY, activity]);

  const isMoreInformation = useMemo(
    () =>
      activity?.data?.email?.emailedTo ||
      activity?.data?.customerName ||
      activity?.data?.values ||
      activity?.data?.delivery?.date ||
      activity?.data?.delivery?.products ||
      activity?.data?.products?.deletedProducts?.length ||
      activity?.data?.products?.newProducts?.length ||
      activity?.data?.products?.updatedProducts?.length ||
      activity?.data?.discounts?.addedManufacturerDiscounts?.length ||
      activity?.data?.discounts?.removedManufacturerDiscounts?.length ||
      activity?.data?.return?.deliverer?.length ||
      activity?.data?.return?.products?.updatedProducts?.length ||
      activity?.data?.return?.products?.newProducts?.length ||
      activity?.data?.return?.products?.deletedProducts?.length ||
      activity?.data?.return?.trackingId?.length ||
      activity?.data?.return?.reason?.length ||
      activity?.data?.return?.shippingCarrier?.length ||
      activity?.data?.payment?.date?.length ||
      activity?.data?.payment?.reference?.length ||
      activity?.data?.payment?.amount?.length ||
      activity?.data?.refund?.length ||
      activity?.data?.refund?.amount?.length ||
      activity?.data?.refund?.amount ||
      activity?.data?.refund?.date?.length ||
      activity?.data?.refund?.reference?.length ||
      activity?.data?.reason ||
      activity?.data?.discounts?.addedItemDiscounts?.length ||
      activity?.data?.discounts?.changedItemDiscounts?.length ||
      activity?.data?.discounts?.removedItemDiscounts?.length ||
      activity?.data?.discounts?.totalOrderDiscount?.length ||
      activity?.data?.deliveryFee?.length ||
      activity?.data?.delivery?.deliverer?.length ||
      activity?.data?.delivery?.shippingCarrier?.length ||
      activity?.data?.delivery?.trackingId?.length,
    [activity]
  );

  return {
    isInfoShow,
    toggleExpandInfo,
    isMoreInformation,
    setName,
  };
};
