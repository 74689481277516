import {
  AccordionComponent,
  DetailsComponent,
  SummaryComponent,
} from "../../../../../../components/AccordionComponent/AccordionComponent";
import MSDHeader from "../MSDHeader/MSDHeader";
import MSDItem from "../MSDItem/MSDItem";
import { array, string, func, bool, object, number, any } from "prop-types";
import { Box } from "@mui/system";
import { useCallback, useEffect, useState } from "react";
import {
  getManufacturersWithDiscountsByIdService,
  getManufacturersWithDiscountsService,
} from "../../../../../../services/manufacturers-discounts";
import { SCROLL_LIMIT_DISCOUNT } from "../../../../../../utils/constants";
import ApplyMSDSummary from "../ApplyMSDSummary/ApplyMSDSummary";
import { error } from "../../../../../../utils/notifications";
import Loader from "../../../../../../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import { updateDraftAction } from "../../../../../../redux/actions/drafts";
import { createSelector } from "reselect";
import {
  draftsListSelector,
  draftsLoadingSelector,
} from "../../../../../../redux/selectors/drafts";
import { useNavigate } from "react-router-dom";
import {
  filterByMOQ,
  filterByMPA,
  getDiscountsDictionary,
  getMSDDiscountSum,
  getProductsDictionary,
} from "./MSDPage.helpers";
import { useMemo } from "react";
import ProductsMenu from "../../../ProductsMenu/ProductsMenu";
import { currentUserSelector } from "../../../../../../redux/selectors/auth";
import { CircularProgress } from "@mui/material";
import { updateUserFieldAction } from "redux/actions/auth";
import { InfiniteScrollWrapper } from "components";

const selector = createSelector(
  draftsListSelector,
  draftsLoadingSelector,
  currentUserSelector,
  (drafts, draftsLoading, currentUser) => ({
    drafts,
    draftsLoading,
    currentUser,
  })
);

const MSDPage = ({
  manufacturers,
  manufacturerDiscounts,
  customerId,
  customer,
  productsList,
  setWithDiscount,
  setHandleClearAll,
  isEdit,
  orderId,
  isDuplicate,
  setMsdSelected,
  isPayed,
  deliveryFee,
  manualDeliveryFee,
  contactId,
  createOrderType,
  isDraftOrder,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [productsListMenu, setProductsList] = useState([]);
  const [manufacturerName, setManufacturerName] = useState("");
  const [discountsList, setDiscountsList] = useState([]);
  const [discountsCount, setDiscountsCount] = useState(0);
  const [discountsLoading, setDiscountsLoading] = useState(false);
  const [checkedDiscounts, setCheckedDiscounts] = useState(
    manufacturerDiscounts || []
  );

  const { drafts, draftsLoading, currentUser } = useSelector(selector);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchMSD = useCallback(
    async (page) => {
      const manufacturerIds = manufacturers.map((man) => man.id);
      const product_ids = productsList
        .map((prod) => {
          if ((isEdit && !isDuplicate) || isDraftOrder) {
            if (prod.isNewAdded) return prod.id;
            return prod.product?.id;
          }
          return prod.id;
        })
        .filter((id) => id);
      setDiscountsLoading(true);
      getManufacturersWithDiscountsService({
        manufacturer_ids: `[${manufacturerIds}]`,
        customer_id: customerId,
        discount_limit: SCROLL_LIMIT_DISCOUNT,
        limit: SCROLL_LIMIT_DISCOUNT,
        product_ids: JSON.stringify(product_ids),
        available_by_date: true,
        status: '["active", "inactive_for_customers"]',
        page: page || 1,
      })
        .then((res) => {
          setDiscountsLoading(false);
          setDiscountsList((prev) =>
            page === 1 || !page ? [...res.rows] : [...prev, ...res.rows]
          );
          setDiscountsCount(res.count);
        })
        .catch(() => {
          setDiscountsLoading(false);
          error("Can't get discounts.");
        });
    },
    [customerId, manufacturers, productsList, isEdit, isDuplicate, isDraftOrder]
  );

  useEffect(() => {
    fetchMSD();
  }, [fetchMSD]);

  const handleCheckDiscount = (discount) => {
    const discountIndex = checkedDiscounts.findIndex(
      (checkedDiscount) =>
        checkedDiscount.manufacturerDiscountId === discount.id ||
        checkedDiscount.id === discount.id
    );
    if (discountIndex > -1) {
      const newDiscounts = [...checkedDiscounts];
      newDiscounts.splice(discountIndex, 1);
      return setCheckedDiscounts([...newDiscounts]);
    }

    setCheckedDiscounts([...checkedDiscounts, discount]);
  };

  const handleClearGroup = (group) => {
    const newChecked = [...checkedDiscounts];

    group.discounts.forEach((groupDiscount) => {
      const index = newChecked.findIndex(
        (checkedDiscount) =>
          checkedDiscount.manufacturerDiscountId === groupDiscount.id ||
          checkedDiscount.id === groupDiscount.id
      );
      if (index > -1) newChecked.splice(index, 1);
    });
    setCheckedDiscounts([...newChecked]);
  };

  useEffect(() => {
    const discountSum = getMSDDiscountSum(
      productsList,
      checkedDiscounts,
      isPayed
    );
    setWithDiscount(discountSum);
  }, [checkedDiscounts, productsList, setWithDiscount, isPayed]);

  useEffect(() => {
    setHandleClearAll(() => () => {
      setCheckedDiscounts([]);
    });
  }, [setHandleClearAll, productsList]);

  const prodDict = useMemo(
    () =>
      getProductsDictionary(
        productsList,
        discountsList.map((dis) => dis.discounts).flat(),
        isPayed && !isDuplicate && !isDraftOrder
      ),
    [productsList, discountsList, isPayed, isDuplicate, isDraftOrder]
  );

  const discDict = useMemo(
    () => getDiscountsDictionary({ productsList, checkedDiscounts, isPayed }),
    [productsList, checkedDiscounts, isPayed]
  );

  const handleApply = (e) => {
    e.preventDefault();
    if (!checkedDiscounts.length) return error("No discounts selected.");

    const newProducts = (isEdit ? productsList : drafts.data.productsData).map(
      (product) => {
        const manufacturerDiscountIds = discDict[product.id].map(
          (discount) => discount.id
        );
        return {
          ...product,
          manufacturerDiscountIds,
          manufacturerDiscountDuplicates:
            product.manufacturerDiscountDuplicates || [],
        };
      }
    );

    if (isEdit) {
      const newDiscounts = checkedDiscounts.filter(
        (d) => !manufacturerDiscounts.some(({ id }) => id === d.id)
      );
      const discountWithQty = newDiscounts?.some((d) => d?.type === "QUANTITY");

      if (discountWithQty)
        dispatch(updateUserFieldAction("openDiscountDialogQty", true));

      const navigateString = isDuplicate
        ? `/cart/duplicate/${orderId}`
        : isDraftOrder
        ? `/cart/draft/${orderId}`
        : `/cart/${orderId}`;
      return navigate(navigateString, {
        state: {
          editedOrderData: {
            customer: customer,
            customerId: customerId,
            contactId:
              contactId ||
              customer?.contacts?.find((contact) => contact.defaultContact)?.id,
            products: newProducts,
            manufacturerDiscounts: checkedDiscounts,
            totalOrderDiscountAmount: 0,
            totalOrderDiscountType: "PERCENTAGE",
            discount: {
              name: "Manufacturer Specific Discount",
              type: "manufacturer",
            },
            deliveryFee,
            manualDeliveryFee,
            createOrderType,
          },
        },
      });
    }
    const discountWithQty = checkedDiscounts?.some(
      (d) => d?.type === "QUANTITY"
    );

    if (discountWithQty)
      dispatch(updateUserFieldAction("openDiscountDialogQty", true));

    dispatch(
      updateDraftAction({
        id: drafts.id,
        data: {
          data: {
            ...drafts.data,
            productsData: newProducts,
            manufacturerDiscounts: checkedDiscounts,
            discount: {
              name: "Manufacturer Specific Discount",
              // total: withDiscount,
              type: "manufacturer",
            },
          },
        },
        navigate,
      })
    );
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const getMOQDisabled = (discount) => {
    // MOQ - minimum order quantity
    return filterByMOQ(discount, prodDict);
  };

  const getMPADisabled = (discount) => {
    // MPA - minimum purchase amount
    return filterByMPA(discount, prodDict);
  };

  useEffect(() => {
    if (checkedDiscounts.length > 0) return setMsdSelected(true);
    setMsdSelected(false);
  }, [checkedDiscounts.length, setMsdSelected]);

  const checkDisabled = (discount) => {
    const parentMSD = discountsList.find(
      (msd) => msd.id === discount.manufacturer.id
    );
    const oneSiblingChecked = parentMSD.discounts.some((child) =>
      checkedDiscounts
        .filter(
          (checked) => checked.manufacturer?.id === child?.manufacturer?.id
        )
        .find((checked) => {
          if (checked.productType === "ALL_PRODUCTS")
            return (
              (checked.id === child.id ||
                checked.manufacturerDiscountId === child.id) &&
              child.id !== discount.id
            );
          if (checked.productType === "SPECIFIC_PRODUCTS") {
            return (
              discount.productType === "ALL_PRODUCTS" ||
              checked.products.some(
                (p) =>
                  checked.id !== discount.id &&
                  checked.manufacturerDiscountId !== discount.id &&
                  discount.products.find(
                    ({ product: compareProduct }) =>
                      compareProduct?.id === p.product?.id ||
                      compareProduct?.id === p.productId
                  )
              )
            );
          }
          return false;
        })
    );
    // if an MSD accordion has 1 checked discount, disable the rest -- OUTDATED --
    // One product specific discount can be added to the order with other product specific discount if these discounts will be applied to different product
    //  If All products discount was added to the shopping cart, no other discount could be added from the same Manufacturer
    // When non-conflicting (product specific) MSD's are selected - disable All products discounts in the list
    // When product specific MSD is selected - all conflicting MSD's will be disabled in the discounts list
    return (
      // check if sum of qty or price of one category products add up to discount's minQ ir minPA
      getMOQDisabled(discount) || getMPADisabled(discount) || oneSiblingChecked
    );
  };

  const fetchMoreDiscounts = async ({ id, index, page, discount }) => {
    const product_ids = productsList.map((man) =>
      isEdit ? man.product?.id || man.id : man.id
    );

    const params = {
      customer_id: customerId,
      limit: SCROLL_LIMIT_DISCOUNT,
      product_ids: JSON.stringify(product_ids),
      available_by_date: true,
      status: '["active", "inactive_for_customers"]',
      page,
    };
    const { discounts } = await getManufacturersWithDiscountsByIdService(
      id,
      params
    );
    const newList = [...discountsList];
    const newDiscounts = {
      ...discount,
      discounts: [...discount.discounts, ...discounts],
    };

    newList.splice(index, 1, newDiscounts);
    setDiscountsList([...newList]);
  };

  return (
    <Box sx={{ p: "14px 32px" }}>
      <Loader isLoading={discountsLoading || draftsLoading} />
      <ProductsMenu
        anchorEl={anchorEl}
        handleClose={handleClose}
        products={productsListMenu}
        manufacturerName={manufacturerName}
      />

      <Box maxHeight="708px" overflow="hidden">
        <InfiniteScrollWrapper
          loading={discountsLoading || draftsLoading}
          maxHeight="708px"
          id="discount-parent-table"
          dataLength={discountsList.length}
          hasMore={discountsList.length < discountsCount}
          next={() =>
            fetchMSD(Math.ceil(discountsList.length / discountsCount) + 1)
          }
          loader={
            <CircularProgress
              size={25}
              sx={{
                ml: "50%",
                my: "5px",
                transform: "translateX(-50%)",
              }}
            />
          }
        >
          <form id="apply-discounts-form" onSubmit={handleApply}>
            {discountsList.map((el, msdIndex) => (
              <AccordionComponent key={el.id} item={el} sx={{ border: "none" }}>
                <SummaryComponent item={el}>
                  <ApplyMSDSummary
                    item={el}
                    discountCounts={el?.discountCount}
                    checkedDiscounts={checkedDiscounts}
                    handleClear={handleClearGroup}
                  />
                </SummaryComponent>
                <DetailsComponent>
                  <MSDHeader />
                  <Box maxHeight="576px" overflow="hidden">
                    <InfiniteScrollWrapper
                      id={`${el.id}discount-children-table`}
                      maxHeight="576px"
                      loading={discountsLoading || draftsLoading}
                      dataLength={el.discounts.length}
                      hasMore={el.discounts.length < el.discountCount}
                      next={() => {
                        const currentPage = Math.ceil(
                          el.discounts.length / SCROLL_LIMIT_DISCOUNT
                        );

                        fetchMoreDiscounts({
                          id: el.id,
                          index: msdIndex,
                          page: currentPage + 1,
                          discount: el,
                        });
                      }}
                      loader={
                        <CircularProgress
                          size={25}
                          sx={{
                            ml: "50%",
                            my: "5px",
                            transform: "translateX(-50%)",
                          }}
                        />
                      }
                    >
                      {el.discounts.map((discount) => (
                        <MSDItem
                          key={discount.id}
                          discount={discount}
                          handleCheckDiscount={handleCheckDiscount}
                          setManufacturerName={setManufacturerName}
                          setAnchorElMenu={setAnchorEl}
                          setProductsList={setProductsList}
                          timeZone={currentUser.timeZone}
                          isSelected={checkedDiscounts.some(
                            (checkedDiscount) =>
                              checkedDiscount.id === discount.id ||
                              checkedDiscount.manufacturerDiscountId ===
                                discount.id
                          )}
                          disabled={checkDisabled(discount)}
                          MOQDisabled={getMOQDisabled(discount)}
                          MPADisabled={getMPADisabled(discount)}
                        />
                      ))}
                    </InfiniteScrollWrapper>
                  </Box>
                </DetailsComponent>
              </AccordionComponent>
            ))}
          </form>
        </InfiniteScrollWrapper>
      </Box>
    </Box>
  );
};

MSDPage.propTypes = {
  manufacturers: array,
  customerId: string,
  customer: object,
  productsList: array,
  setWithDiscount: func,
  setHandleClearAll: func,
  manufacturerDiscounts: array,
  isEdit: bool,
  productsData: array,
  isDuplicate: bool,
  orderId: string,
  setMsdSelected: func,
  isPayed: bool,
  deliveryFee: number,
  manualDeliveryFee: bool,
  contactId: any,
  createOrderType: string,
  isDraftOrder: bool,
};

MSDPage.defaultProps = {
  manufacturerIds: [],
  productsList: [],
  manufacturerDiscounts: [],
  isDraftOrder: false,
};

export default MSDPage;
