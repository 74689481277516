export const cl = {
  GalleryFilter: {
    wrapper: {
      backgroundColor: "#ffffff",
      borderRadius: "4px",
      border: "1px solid #d5d9d9",
      display: "flex",
      alignItems: "center",
      marginTop: 1.5,
      mx: 4,
      p: "11px 17px 11px 20px",
      gap: "11px",
    },
    textFieldWrapper: {
      display: "flex",
      gap: 0.5,
      alignItems: "center",
      overflow: "hidden",
      width: "100%",
      justifyContent: "flex-end",
    },
    chip: {
      borderRadius: "4px",
      border: "0.7px solid #d5d9d9",
      backgroundColor: "#FAFAFB",
      height: "28px",
    },
    chipKey: {
      fontSize: "9px!important",
      color: "#ffffff",
      backgroundColor: "#47A06D",
      borderRadius: "50%",
      width: "15px",
      height: "15px",
    },
    filterBtn: {
      height: "39px",
      width: "100%",
      maxWidth: "74px",
      border: "0.5px solid #D5D9D9",
      "& .MuiButton-startIcon": {
        ml: 0,
      },
    },
  },
};
