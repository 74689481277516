import { any, string } from "prop-types";
import { MenuItem, Typography } from "@mui/material";

export const StyledMenuItem = ({ value, children, ...props }) => {
  return (
    <MenuItem value={value} {...props}>
      <Typography fontSize={12} fontWeight={400} color="#3F3F3F" noWrap>
        {children}
      </Typography>
    </MenuItem>
  );
};

StyledMenuItem.propTypes = {
  value: string,
  children: any,
};
