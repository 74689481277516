export const cl = {
  paper: {
    my: "12px",
    pl: 1.75,
    minHeight: "64px",
    height: "64px",
  },
  grid: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  formSx: {
    width: "100%",
    "& .MuiOutlinedInput-root:not(.Mui-disabled):hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#d5d9d9",
      },
    },
  },
  selectGroupTitle: {
    background: "#F7F7F7",
    borderTop: "0.5px solid #D5D9D9",
    borderBottom: "0.5px solid #D5D9D9",
    height: "36px",
    lineHeight: "36px",
  },
};
