import React from "react";
import { Box, Tab, Tabs } from "@mui/material";
import PropTypes, {
  arrayOf,
  shape,
  any,
  string,
  bool,
  func,
  object,
} from "prop-types";
import useStyles from "./styles";
import { StyledTooltip } from "components";

export const StyledTabs = ({ tabs, tabProps, ...props }) => {
  const classes = useStyles();

  const CloneProps = ({ children, ...props }) => {
    return children(props);
  };

  CloneProps.propTypes = { children: func };

  return (
    <Tabs
      {...props}
      className={`${classes.tabs} ${props.className}`}
      value={tabs.findIndex((t) => t.value === props.value) || 0}
    >
      {tabs.map(({ value, label, disabled, showTooltip, tooltipText }) => (
        <CloneProps key={value}>
          {(cloneProps) => (
            <StyledTooltip
              disableHoverListener={!showTooltip}
              title={tooltipText}
              arrow
              placement="top"
              PopperProps={{
                modifiers: [{ name: "offset", options: { offset: [0, -10] } }],
              }}
            >
              <Box value={value}>
                <Tab
                  {...cloneProps}
                  label={label}
                  value={value}
                  disabled={disabled}
                  {...tabProps}
                  className={`${classes.tab} ${tabProps?.className}`}
                />
              </Box>
            </StyledTooltip>
          )}
        </CloneProps>
      ))}
    </Tabs>
  );
};

StyledTabs.propTypes = {
  tabs: arrayOf(
    shape({
      value: any,
      label: PropTypes.oneOfType([string, object]),
      disabled: bool,
      tooltipText: PropTypes.oneOfType([string, object]),
      showTooltip: bool,
    })
  ),
  tabProps: object,
  value: any,
  className: string,
};

export default StyledTabs;
