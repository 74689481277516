export const addPercentsOfPayments = (arr) => {
  if (!arr?.length) return;

  const totalSum = arr.reduce((acc, cur) => (acc += cur?.totalPayments), 0);

  return arr.map((el) => ({
    ...el,
    percent: totalSum ? (el?.totalPayments / totalSum) * 100 : 0,
  }));
};
