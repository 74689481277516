import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  menu: {
    width: "260px",
  },
  title: {
    fontSize: "14px",
    fontWeight: 500,
    color: "#707070",
  },
  head: {
    display: "flex",
    justifyContent: "space-between",
    padding: "4px 8px 4px 20px",
    borderBottom: "1px solid #B2B2B2",
  },
  menuTitleBlock: {
    display: "flex",
    alignItems: "center",
  },
  productItem: {
    display: "flex",
    justifyContent: "space-between",
    padding: "7px 44px 0 21px",
    "& > .MuiTypography-root": {
      fontSize: "12px",
      fontWeight: 400,
      color: "#000000",
    },
  },
}));

export default useStyles;
