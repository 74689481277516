import { object, func } from "prop-types";
import { Box } from "@mui/material";
import {
  StatusesBlock,
  RestockBlock,
  ShippingBlock,
  ItemsListBlock,
} from "./components";

export const ReturnDialogBody = ({
  restockRef,
  setPopperType,
  control,
  errors,
  setValue,
  headerData,
  restockData,
  shippingData,
  state,
  deliveryMethodRef,
  returnListData,
}) => {
  return (
    <Box px={3.25}>
      <StatusesBlock {...{ headerData, control }} />
      <ItemsListBlock {...{ setValue, returnListData, errors }} />
      <RestockBlock
        {...{
          control,
          restockRef,
          setPopperType,
          restockData,
        }}
      />
      <ShippingBlock
        {...{
          state,
          shippingData,
          control,
          errors,
          setValue,
          deliveryMethodRef,
          setPopperType,
        }}
      />
    </Box>
  );
};

ReturnDialogBody.propTypes = {
  restockRef: object,
  setPopperType: func,
  control: object,
  errors: object,
  setValue: func,
  headerData: object,
  restockData: object,
  shippingData: object,
  state: object,
  deliveryMethodRef: object,
  returnListData: object,
};
