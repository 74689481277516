import React from "react";
import { object, number, bool, func } from "prop-types";
import { TableItem, CircleIndexWrap } from "./styles";
import { LargeCheckbox } from "components";
import { ProspectContent } from "components/MapTooltip/components";
import { CircleIndex } from "./components";

const ProspectTableItem = ({
  prospect,
  index,
  checked,
  handleCheckProspect,
}) => {
  return (
    <TableItem>
      <LargeCheckbox
        onChange={() => handleCheckProspect(prospect)}
        checked={checked}
        formSx={{ ml: "-9px" }}
      />
      <ProspectContent prospect={prospect} coloring="light" />
      <CircleIndexWrap>
        <CircleIndex charLength={(index + 1).toString().length}>
          {index + 1}
        </CircleIndex>
      </CircleIndexWrap>
    </TableItem>
  );
};

ProspectTableItem.propTypes = {
  prospect: object,
  index: number,
  checked: bool,
  handleCheckProspect: func,
};

export default ProspectTableItem;
