import React, { useEffect, useMemo, useRef, useState } from "react";
import { Box, Chip, Typography } from "@mui/material";
import { array, string, func, number, bool, object, any } from "prop-types";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import {
  AccordionComponent,
  DetailsComponent,
  SummaryComponent,
} from "../../../../../../components/AccordionComponent/AccordionComponent";
import { FilterSearchTextField } from "../../../../../../components/TextFields/TextFields";
import ItemDiscount from "../ItemDiscount/ItemDiscount";
import ItemDiscountHeader from "../ItemDiscountHeader/ItemDiscountHeader";
import { validationSchema } from "./ItemDiscountPage.validations";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateDraftAction } from "../../../../../../redux/actions/drafts";
import { createSelector } from "reselect";
import {
  draftsListSelector,
  draftsLoadingSelector,
} from "../../../../../../redux/selectors/drafts";
import Loader from "../../../../../../components/Loader";
import { useDebounce } from "../../../../../../helpers/hooks";
import CloseIcon from "@mui/icons-material/Close";
import useStyles, { cl } from "./styles";
import { defaultFilters, FILTER_SWITCHES } from "./constants";
import { normalizeSnakeCaseString } from "../../../../../../helpers/helpers";
import StyledButton from "../../../../../../components/StyledButton";
import { FilterIcon } from "../../../../../../components/Icons";
import FilterMenu from "../../../../../../components/FilterMenu";
import { SCROLL_LIMIT } from "../../../../../../utils/constants";
import {
  manufacturersCountSelector,
  manufacturersListSelector,
} from "../../../../../../redux/selectors/manufacturers";
import { getManufacturersAction } from "../../../../../../redux/actions/manufacturers";

const selector = createSelector(
  draftsListSelector,
  draftsLoadingSelector,
  manufacturersListSelector,
  manufacturersCountSelector,
  (drafts, draftsLoading, manufacturersList, manufacturersCount) => ({
    drafts,
    draftsLoading,
    manufacturersList,
    manufacturersCount,
  })
);

const ItemDiscountPage = ({
  manufacturers,
  productsList,
  setWithDiscount,
  setHandleClearAll,
  isEdit,
  orderId,
  isDuplicate,
  customer,
  customerId,
  deliveryFee,
  manualDeliveryFee,
  contactId,
  createOrderType,
  isDraftOrder,
}) => {
  const classes = useStyles();
  const {
    NewRepresentativesFilter: { wrapper, searchAdor, btnLabel, btnFilter },
  } = cl;

  const filterAnchor = useRef();
  const [searchInput, setSearchInput] = useState("");
  const [filterFields, setFilterFields] = useState(defaultFilters);
  const [switches, setSwitches] = useState(FILTER_SWITCHES);
  const [limitManufacturers, setLimitManufacturers] = useState(
    2 * SCROLL_LIMIT
  );

  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const filterChipKeys = useMemo(
    () => Object.keys(filterFields).filter((key) => filterFields[key]),
    [filterFields]
  );

  const searchInputDebounced = useDebounce(searchInput, 300);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { drafts, draftsLoading, manufacturersList, manufacturersCount } =
    useSelector(selector);

  const { control, handleSubmit, setValue, trigger } = useForm({
    mode: "onChange",
    defaultValues: { products: productsList || [] },
    resolver: yupResolver(validationSchema()),
  });

  const formField = useWatch({
    control,
  });

  const { fields, replace } = useFieldArray({
    control,
    name: "products",
  });

  useEffect(() => {
    setHandleClearAll(() => () => {
      const resetProducts = productsList.map((product) => {
        return {
          ...product,
          itemDiscountAmount: 0,
          itemDiscountType: "PERCENTAGE",
        };
      });
      setValue("products", resetProducts);
    });
  }, [replace, setHandleClearAll, productsList, setValue]);

  useEffect(() => {
    const sum = formField.products.reduce((prevSum, currProd) => {
      const discountAmount = parseFloat(currProd.itemDiscountAmount) || 0;
      const price =
        (currProd.wholesalePrice || currProd.price || 0) * currProd.quantity;
      const disc =
        currProd.itemDiscountType === "PERCENTAGE"
          ? (discountAmount / 100) * price
          : discountAmount;
      return prevSum + disc;
    }, 0);
    setWithDiscount(parseFloat(sum));
  }, [formField, setWithDiscount]);

  const onSubmit = (data) => {
    if (isEdit) {
      const navigateString = isDuplicate
        ? `/cart/duplicate/${orderId}`
        : isDraftOrder
        ? `/cart/draft/${orderId}`
        : `/cart/${orderId}`;
      return navigate(navigateString, {
        state: {
          editedOrderData: {
            customer: customer,
            customerId: customerId,
            contactId:
              contactId ||
              customer?.contacts?.find((contact) => contact.defaultContact)?.id,
            products: data.products,
            discount: {
              name: "Item Specific Discount",
              type: "item",
            },
            manufacturerDiscounts: [],
            manufacturerDiscountIds: [],
            manufacturerDiscountDuplicates: [],
            totalOrderDiscountAmount: 0,
            totalOrderDiscountType: "PERCENTAGE",
            deliveryFee,
            manualDeliveryFee,
            createOrderType,
          },
        },
      });
    }

    dispatch(
      updateDraftAction({
        id: drafts.id,
        data: {
          data: {
            ...drafts.data,
            productsData: data.products,
            discount: {
              name: "Item Specific Discount",
              type: "item",
            },
          },
        },
        navigate,
      })
    );
    // navigate("/cart", {
    //   state: {
    //     discountedProducts: data.products,
    //     discounts: {
    //       name: "Item specific Discount",
    //       total: withDiscount,
    //     },
    //   },
    // });
  };

  const getChipLabel = (key) => {
    const icon = filterFields[key]?.icon;
    return (
      <Box display="flex" alignItems="center" gap="6px">
        <Typography
          sx={{ fontSize: "13px", fontWeight: 500 }}
          color="groundLighter.main"
        >
          {normalizeSnakeCaseString(filterFields[key]?.label || key)}:{" "}
          {!icon && (
            <span style={{ color: "#5F6368", marginTop: !!icon && "5px" }}>
              {normalizeSnakeCaseString(
                filterFields[key]?.name || filterFields[key]
              )}
            </span>
          )}
        </Typography>
        {icon}
      </Box>
    );
  };

  const handleDeleteFilter = (key) => {
    const newState = switches;
    const index = switches.findIndex((s) => s.value === key);
    const insert = {
      ...switches[index],
      checked: false,
    };
    newState.splice(index, 1, insert);
    setSwitches([...newState]);
    setFilterFields((prev) => {
      return { ...prev, [key]: "" };
    });
  };

  const handleApplyFilter = (newSwitches, newFields) => {
    // setCheckedUsers([]);
    setFilterMenuOpen(false);
    setSwitches([...newSwitches]);
    setFilterFields({ ...newFields });
  };

  const [manufacturersState, setManufacturersState] = useState({
    search: "",
    searcheble: false,
  });

  const handleFetchManufacturers = () => {
    if (!manufacturers?.length) {
      dispatch(
        getManufacturersAction({
          limit: limitManufacturers,
          search: manufacturersState.search,
        })
      );
      setManufacturersState((prev) => ({
        ...prev,
        searcheble: !prev.searcheble
          ? manufacturersCount >= 10
          : prev.searcheble,
      }));
      setLimitManufacturers(limitManufacturers + SCROLL_LIMIT);
    }
  };

  useEffect(() => {
    handleFetchManufacturers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manufacturersState.search]);

  const fieldValues = useMemo(() => {
    return fields
      .filter((product) =>
        (product.name || product.parentProduct?.name)
          ?.toLowerCase()
          ?.includes(searchInputDebounced.toLowerCase())
      )
      .filter((product) => {
        if (filterFields)
          return (
            product.manufacturer?.id === filterFields?.manufacturerId?.value ||
            product.parentProduct?.manufacturer?.id ===
              filterFields?.manufacturerId?.value ||
            product.manufacturerId === filterFields?.manufacturerId?.value
          );
        return product;
      });
  }, [fields, filterFields, searchInputDebounced]);

  return (
    <Box sx={{ p: "17px 32px" }}>
      <Loader isLoading={draftsLoading} />
      <Box display="flex" gap="11.5px" sx={wrapper}>
        <FilterSearchTextField
          formSx={{ minWidth: "355px" }}
          placeholder="Search products"
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          fullWidth
          customAdornment={
            <Box sx={searchAdor}>
              {filterChipKeys.map((key) => (
                <Chip
                  key={key}
                  className={classes.filterChip}
                  size="small"
                  label={getChipLabel(key)}
                  deleteIcon={
                    <CloseIcon size="15" style={{ fill: "#707070" }} />
                  }
                  onMouseDown={(e) => e.stopPropagation()}
                  onDelete={() => handleDeleteFilter(key)}
                />
              ))}
            </Box>
          }
        />
        <StyledButton
          sx={btnFilter}
          fontSize="15px"
          label="Filter"
          startIcon={
            filterChipKeys.length ? (
              <Typography sx={btnLabel}>{filterChipKeys.length}</Typography>
            ) : (
              <FilterIcon />
            )
          }
          variant="outlined"
          ref={filterAnchor}
          color="edit"
          onClick={() => setFilterMenuOpen(true)}
        />
        <FilterMenu
          anchorEl={filterAnchor.current}
          open={filterMenuOpen}
          onClose={() => setFilterMenuOpen(false)}
          filterFields={filterFields}
          setFilterFields={setFilterFields}
          switches={switches}
          handleApplyFilter={handleApplyFilter}
          selectMatchData={[
            {
              id: "manufacturerId",
              childrenList:
                manufacturers?.length > 0 ? manufacturers : manufacturersList,
              handleFetch: handleFetchManufacturers,
              setParams: setManufacturersState,
              dataCount:
                manufacturers?.length > 0
                  ? manufacturers?.length
                  : manufacturersCount,
              dataLength:
                manufacturers?.length > 0
                  ? manufacturers?.length
                  : manufacturersList?.length,
              label: "Select Manufacturer",
            },
          ]}
        />
      </Box>
      <AccordionComponent
        expanded
        sx={{
          "&.MuiPaper-root.MuiAccordion-root.MuiPaper-root.MuiAccordion-root": {
            m: 0,
            borderTop: "none",
            borderRadius: 0,
          },
        }}
      >
        <SummaryComponent>
          <Typography fontSize="23px">Items to discount</Typography>
        </SummaryComponent>
        <DetailsComponent>
          <ItemDiscountHeader
            hideAvailable={createOrderType === "THIRD_PARTY"}
          />
          <form id="apply-discounts-form" onSubmit={handleSubmit(onSubmit)}>
            {fieldValues?.length ? (
              fieldValues.map((product, index) => (
                <ItemDiscount
                  key={product?.id}
                  product={product}
                  control={control}
                  index={index}
                  setValue={setValue}
                  products={formField.products}
                  hideAvailable={createOrderType === "THIRD_PARTY"}
                  trigger={trigger}
                />
              ))
            ) : (
              <Typography
                fontWeight="300"
                fontSize="16px"
                textAlign="center"
                width="100%"
                color="#6A6A6A"
                paddingTop="20px"
                paddingBottom="20px"
              >
                No products found
              </Typography>
            )}
          </form>
        </DetailsComponent>
      </AccordionComponent>
    </Box>
  );
};

ItemDiscountPage.propTypes = {
  manufacturers: array,
  customerId: string,
  customer: object,
  productsList: array,
  setWithDiscount: func,
  setHandleClearAll: func,
  withDiscount: number,
  isEdit: bool,
  orderId: string,
  isDuplicate: bool,
  deliveryFee: number,
  manualDeliveryFee: bool,
  contactId: any,
  createOrderType: string,
  isDraftOrder: bool,
};

ItemDiscountPage.defaultProps = {
  manufacturers: [],
  productsList: [],
  deliveryFee: 0,
  manualDeliveryFee: false,
  isDraftOrder: false,
};

export default ItemDiscountPage;
