import { useMemo, useRef, useState } from "react";
import { array, func } from "prop-types";
import { Box, Chip, Divider, Tab, Tabs, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { FilterSearchTextField } from "../../../../components/TextFields/TextFields";
import StyledButton from "../../../../components/StyledButton";
import { FilterIcon } from "../../../../components/Icons";
import { makeStyles } from "@mui/styles";
import { normalizeSnakeCaseString } from "../../../../helpers/helpers";
import FilterMenu from "../../../../components/FilterMenu";
import { UNCATEGORIZED_TERRITORY } from "../../../../utils/constants";
import { createSelector } from "reselect";
import { territoryListSelector } from "../../../../redux/selectors/territory";
import { useSelector } from "react-redux";
import SelectedLabel from "../../../CustomersPage/components/CustomersTab/components/SelectedLabel";
import { ChainIcon } from "../../../../components/Icons/Files";

const useStyles = makeStyles(() => ({
  filterChip: {
    borderRadius: "4px",
    border: "0.7px solid #d5d9d9",
    backgroundColor: "#FAFAFB",
    height: "28px",
  },
}));

const FILTER_SWITCHES = [
  {
    value: "territory_id",
    label: "Territory",
    type: "select",
    checked: false,
    selectLabel: "Select Territory",
  },
];

const selector = createSelector(territoryListSelector, (territoriesList) => ({
  territoriesList,
}));

export const FiltersManegePage = ({
  checkedCustomers,
  onCheckedAllCustomer,
}) => {
  const { territoriesList } = useSelector(selector);
  const classes = useStyles();
  const filterAnchor = useRef();
  const [currentTab, setCurrentTab] = useState("All");
  const [switches, setSwitches] = useState(FILTER_SWITCHES);
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);

  const [customersSearchInput, setCustomersSearchInput] = useState("");

  const defaultFilters = {
    territory_id: "",
  };

  const [filterFields, setFilterFields] = useState(defaultFilters);

  const handleApplyFilter = (newSwitches, newFields) => {
    setFilterMenuOpen(false);
    setSwitches([...newSwitches]);
    setFilterFields({ ...newFields });
  };

  const filterChipKeys = useMemo(
    () => Object.keys(filterFields).filter((key) => filterFields[key]),
    [filterFields]
  );

  const [territoriesState, setTerritoriesState] = useState({ search: "" });
  let filteredTerritories = territoriesList.filter((territory) =>
    territory.name.toLowerCase().includes(territoriesState.search.toLowerCase())
  );

  const selectMatchData = useMemo(
    () => [
      {
        id: "territory_id",
        childrenList: [
          {
            id: UNCATEGORIZED_TERRITORY.id,
            name: UNCATEGORIZED_TERRITORY.name,
          },
          ...filteredTerritories,
        ],
        setParams: setTerritoriesState,
        label: "",
      },
    ],
    [filteredTerritories]
  );

  const handleDeleteFilter = (key) => {
    const newState = switches;
    const index = switches.findIndex((s) => s.value === key);
    const insert = {
      ...switches[index],
      checked: false,
    };
    newState.splice(index, 1, insert);
    setSwitches([...newState]);
    setFilterFields((prev) => {
      return { ...prev, [key]: "" };
    });
  };

  const getChipLabel = (key) => {
    const icon = filterFields[key]?.icon;
    return (
      <Box display="flex" alignItems="center" gap="6px">
        <Typography
          sx={{ fontSize: "13px", fontWeight: 500 }}
          color="groundLighter.main"
        >
          {normalizeSnakeCaseString(filterFields[key]?.label || key)}:{" "}
          {!icon && (
            <span style={{ color: "#5F6368", marginTop: !!icon && "5px" }}>
              {normalizeSnakeCaseString(
                filterFields[key]?.name || filterFields[key]
              )}
            </span>
          )}
        </Typography>
        {icon}
      </Box>
    );
  };

  return (
    <Box px="32px">
      <Box
        sx={{
          backgroundColor: "#ffffff",
          borderRadius: "4px 4px 0 0",
          display: "flex",
          border: "1px solid #d5d9d9",
          alignItems: "center",
          padding: "0 17px 0px 17px",
          height: "67px",
          gap: "11px",
        }}
      >
        <Tabs
          value={currentTab}
          onChange={(e, newVal) => setCurrentTab(newVal)}
          sx={{
            width: "100%",
            maxWidth: "250px",
            minHeight: "38px",
            "& .MuiTabs-flexContainer": { justifyContent: "space-between" },
            marginRight: "5px",
          }}
          TabIndicatorProps={{ style: { background: "none" } }}
        >
          {["All", "Linked", "Unlinked"].map((tab) => (
            <Tab
              key={tab}
              label={tab}
              name={tab}
              value={tab}
              sx={{
                textTransform: "none",
                fontSize: "20px",
                color: "#707070",
                borderRadius: "4px",
                "&.Mui-selected": {
                  backgroundColor: "rgb(71, 160, 110, 0.2)!important",
                },
                minWidth: "10px",
                // width: "84px",
                height: "38px",
                minHeight: "38px",
                padding: "0 10px 0 10px",
              }}
            />
          ))}
        </Tabs>

        <FilterSearchTextField
          formSx={{ minWidth: "380px", overflow: "hidden" }}
          placeholderWidth="315px"
          placeholder={"Search customers by name, address or ID"}
          value={customersSearchInput}
          onChange={(e) => setCustomersSearchInput(e.target.value)}
          fullWidth
          customAdornment={
            <Box
              display="flex"
              alignItems="center"
              overflow="hidden"
              width="100%"
              justifyContent="flex-end"
            >
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  transition: "all 0.3s ease-in-out",
                  position: !checkedCustomers.length && "absolute",
                  right: 0,
                  transform: checkedCustomers.length
                    ? "none"
                    : "translateX(160%)",
                }}
              >
                {<SelectedLabel selectedNum={checkedCustomers.length} />}
                <StyledButton startIcon={<ChainIcon />} label="Link" />
                <Divider
                  orientation="vertical"
                  sx={{ height: "14px", borderColor: "#5F6267" }}
                />
                <StyledButton
                  label="Unlink"
                  sx={{
                    color: "#5F6267",
                  }}
                />
                <Divider
                  orientation="vertical"
                  sx={{ height: "14px", borderColor: "#5F6267" }}
                />
                <StyledButton
                  color="error"
                  label="Cancel"
                  onClick={() => onCheckedAllCustomer(false)}
                />
              </Box>

              <Box
                display="flex"
                gap="5px"
                overflow="auto"
                sx={{
                  py: "4px",
                  "&::-webkit-scrollbar": {
                    height: "2px",
                  },
                }}
              >
                {filterChipKeys.map((key) => (
                  <Chip
                    key={key}
                    className={classes.filterChip}
                    size="small"
                    label={getChipLabel(key)}
                    deleteIcon={
                      <CloseIcon size="15" style={{ fill: "#707070" }} />
                    }
                    onMouseDown={(e) => e.stopPropagation()}
                    onDelete={() => handleDeleteFilter(key)}
                  />
                ))}
              </Box>
            </Box>
          }
        />

        <StyledButton
          label="Filter"
          startIcon={
            filterChipKeys.length ? (
              <Typography
                fontSize="9px!important"
                color="#ffffff"
                backgroundColor="#47A06D"
                borderRadius="50%"
                width="15px"
                height="15px"
              >
                {filterChipKeys.length}
              </Typography>
            ) : (
              <FilterIcon />
            )
          }
          variant="outlined"
          ref={filterAnchor}
          color="edit"
          sx={{
            height: "39px",
            width: "100%",
            maxWidth: "74px",
            border: "0.5px solid #D5D9D9",
            "& .MuiButton-startIcon": {
              ml: 0,
            },
          }}
          fontSize="15px"
          onClick={() => setFilterMenuOpen(true)}
        />
        <FilterMenu
          anchorEl={filterAnchor.current}
          open={filterMenuOpen}
          onClose={() => setFilterMenuOpen(false)}
          filterFields={filterFields}
          setFilterFields={setFilterFields}
          switches={switches}
          handleApplyFilter={handleApplyFilter}
          selectMatchData={selectMatchData}
        />
      </Box>
    </Box>
  );
};

FiltersManegePage.propTypes = {
  checkedCustomers: array,
  onCheckedAllCustomer: func,
};
FiltersManegePage.defaultProps = {};
