import { useCallback, useMemo, useState } from "react";
import { array, func, number } from "prop-types";
import { Box, CircularProgress, Stack } from "@mui/material";
import { HeaderListManage, ItemListManage } from "./components";
import { useDispatch } from "react-redux";
import { getCustomersAction } from "../../../../redux/actions/customers";
import { SCROLL_LIMIT } from "../../../../utils/constants";
import {
  InfiniteLoadMoreBtn,
  InfiniteScrollWrapper,
} from "../../../../components";

export const ListManegePage = ({
  checkedCustomers,
  handleCheckedCustomer,
  customersList,
  customersActiveCount,
  handleCheckedAllCustomer,
}) => {
  const dispatch = useDispatch();

  const [limit, setLimit] = useState(2 * SCROLL_LIMIT);

  const isChecked = useMemo(() => {
    return checkedCustomers?.length === customersList?.length;
  }, [checkedCustomers?.length, customersList?.length]);

  const handleFetch = useCallback(() => {
    dispatch(getCustomersAction({ limit }));
    setLimit(limit + SCROLL_LIMIT);
  }, [limit, dispatch]);

  return (
    <Box px={4}>
      <HeaderListManage
        isChecked={isChecked}
        onCheckedAllCustomer={handleCheckedAllCustomer}
      />
      <Stack
        sx={{
          border: "0.5px solid #D5D9D9",
          borderRadius: "0 0 4px 4px",
          maxHeight: "calc(100vh - 322px)",
          overflow: "hidden",
        }}
      >
        <InfiniteScrollWrapper
          id="customer-scroll-table"
          maxHeight="calc(100vh - 322px)"
          style={{ overflow: "hidden" }}
          dataLength={customersList.length}
          next={handleFetch}
          loader={
            <CircularProgress
              sx={{
                ml: "50%",
                mt: "2px",
              }}
              size="30px"
            />
          }
          hasMore={customersList.length < customersActiveCount}
        >
          {customersList?.map((cust) => (
            <ItemListManage
              key={cust?.id}
              item={cust}
              checkedCustomers={checkedCustomers}
              onCheckedCustomer={handleCheckedCustomer}
            />
          ))}
          {/* // need to add the !loading */}
          {customersList.length <
            customersActiveCount(<InfiniteLoadMoreBtn onClick={handleFetch} />)}
        </InfiniteScrollWrapper>
      </Stack>
    </Box>
  );
};

ListManegePage.propTypes = {
  checkedCustomers: array,
  setCheckedCustomers: func,
  handleCheckedCustomer: func,
  customersList: array,
  customersActiveCount: number,
  handleCheckedAllCustomer: func,
};
ListManegePage.defaultProps = {};
