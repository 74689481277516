import axios from "axios";

export const getGalleryService = async (params) => {
  return axios.get("/photo-groups/gallery", { params }).then((res) => res.data);
};

export const applyPhotoTagsService = async (data) => {
  return axios.post("/photo-groups/apply-tags", data).then((res) => res.data);
};

export const applyMultiplePhotoTagsService = async (data) => {
  return axios
    .put("/photo-groups/bulk-apply-tags", data)
    .then((res) => res.data);
};

export const postCommentService = async (id, data) => {
  return axios
    .post(`/photo-groups/comments/${id}`, data)
    .then((res) => res.data);
};

export const editCommentService = async (id, data) => {
  return axios
    .put(`/photo-groups/comments/${id}`, data)
    .then((res) => res.data);
};

export const deleteCommentService = async (id) => {
  return axios.delete(`/photo-groups/comments/${id}`).then((res) => res.data);
};

export const deletePhotosService = async (ids) => {
  const { data } = await axios.delete("/photo-groups/", {
    data: { ids },
  });
  return data;
};

export const getPhotoGroupByIdService = async (id) => {
  return axios.get(`photo-groups/${id}`).then((res) => res.data);
};

export const getPhotoGroupsRepsDublicateService = async () => {
  return axios.get("photo-groups/duplicates").then((res) => res.data);
};
