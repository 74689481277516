import { Box, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";

export const TrialLine = () => {
  const theme = useTheme();
  const { zIndex } = theme;

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "3px",
          bgcolor: "#FEDF89",
          zIndex: zIndex.appBar + 1,
        }}
      />
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: "50%",
          transform: "translate(-50%, 0)",
          width: "63px",
          height: "15px",
          bgcolor: "#FEDF89",
          zIndex: zIndex.appBar + 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "0px 0px 6px 6px",
        }}
      >
        <Typography fontSize={8} fontWeight={600} color="#BB5212">
          TRIAL MODE
        </Typography>
      </Box>
    </>
  );
};
