import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  input: {
    height: "48px",
    fontSize: "12px",
    fontWeight: 400,
    color: "#5F6267",
    borderRadius: "4px",
    "&.Mui-error > fieldset": {
      filter: "drop-shadow(0.1px 0.2px 2px #EB4233)",
    },
  },

  inputLabel: {
    fontSize: "12px",
    fontWeight: 400,
    color: "#B5B5AC",
    top: 5,
    "&.Mui-focused": {
      top: 0,
    },
    "&.MuiFormLabel-filled": {
      top: -1,
    },
  },
  radioGroup: {
    fontSize: "15px",
    color: "#979797",
  },
}));

export default useStyles;
