export const HeadphonesIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.156"
      height="19.422"
      viewBox="0 0 20.156 19.422"
    >
      <g
        id="Group_7297"
        data-name="Group 7297"
        transform="translate(-191.25 -113.25)"
      >
        <g
          id="Group_5439"
          data-name="Group 5439"
          transform="translate(192 114)"
        >
          <path
            id="Path_3467"
            data-name="Path 3467"
            d="M1.75,9.317a8.567,8.567,0,0,1,17.133,0"
            transform="translate(-0.989 -0.75)"
            fill="none"
            stroke="#47a06d"
            strokeWidth="1.5"
          />
          <rect
            id="Rectangle_8844"
            data-name="Rectangle 8844"
            width="3.427"
            height="5.711"
            rx="1.25"
            transform="translate(0 8.376)"
            fill="none"
            stroke="#47a06d"
            strokeWidth="1.5"
          />
          <rect
            id="Rectangle_8845"
            data-name="Rectangle 8845"
            width="3.427"
            height="5.711"
            rx="1.25"
            transform="translate(15.23 8.376)"
            fill="none"
            stroke="#47a06d"
            strokeWidth="1.5"
          />
          <path
            id="Path_3468"
            data-name="Path 3468"
            d="M22.092,19.5A5.752,5.752,0,0,1,16,22.546"
            transform="translate(-4.387 -5.222)"
            fill="none"
            stroke="#47a06d"
            strokeWidth="1.5"
          />
          <path
            id="Path_3469"
            data-name="Path 3469"
            d="M16,22v1.262"
            transform="translate(-4.387 -5.34)"
            fill="none"
            stroke="#47a06d"
            strokeLinecap="round"
            strokeWidth="1.5"
          />
        </g>
      </g>
    </svg>
  );
};
