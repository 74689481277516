import moment from "moment";
import { shape, string, func, oneOf } from "prop-types";

import { Statuses } from "../../Statuses/Statuses";

import { cl } from "../NotificationsMenu.styles";
import { TABS } from "../NotificationsMenu.constants";

import { Box, Link as MuiLink, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useMemo } from "react";

export const OrderItem = ({
  item,
  currentTab,
  onClickReview,
  onClickResend,
  loading,
  handleClose,
}) => {
  const CustomerLink = useMemo(() => {
    const name =
      item?.customer?.displayName ||
      item?.customer?.name ||
      item?.appCustomer?.name;

    const id = item?.appCustomer?.customers?.[0]?.id || item?.customer?.id;

    const status = item?.status;

    const disabledLink =
      ["REJECTED"].includes(status) ||
      (currentTab === TABS[1] && "PENDING" === status) ||
      !id;

    return (
      <>
        <MuiLink
          to={`/customers/${id}`}
          underline={disabledLink ? "none" : "hover"}
          component={disabledLink ? "span" : Link}
          sx={{
            fontWeight: 400,
            color: "#000",
            cursor: disabledLink ? "default" : "pointer",
          }}
          onClick={handleClose}
        >
          {name}
        </MuiLink>{" "}
      </>
    );
  }, [
    currentTab,
    handleClose,
    item?.appCustomer?.customers,
    item?.appCustomer?.name,
    item?.customer?.displayName,
    item?.customer?.id,
    item?.customer?.name,
    item?.status,
  ]);

  return (
    <Box sx={cl.order.wrapper}>
      <Box sx={cl.order.middleSide}>
        <Typography sx={cl.order.middleSide.invitedText} noWrap>
          {currentTab === TABS[1] && (
            <Box fontWeight={300} component="span">
              Invited
            </Box>
          )}{" "}
          {CustomerLink}
          {currentTab === TABS[0] && (
            <Box fontWeight={300} component="span">
              access request
            </Box>
          )}
        </Typography>

        <Stack direction="row" justifyContent="space-between" width="100%">
          <Typography sx={cl.order.middleSide.date} noWrap>
            {moment(item?.updatedAt).fromNow()}
          </Typography>
          {currentTab === TABS[1] && ["PENDING"].includes(item.status) ? (
            <>
              {loading === item?.appCustomer?.id ? (
                <Typography sx={cl.order.rightSide.btn}>Loading...</Typography>
              ) : (
                <MuiLink
                  sx={cl.order.rightSide.btn}
                  component="button"
                  onClick={() => onClickReview(item)}
                >
                  Review
                </MuiLink>
              )}
            </>
          ) : (
            <Stack direction="row" gap="7px" alignItems="center">
              {currentTab === TABS[0] &&
                ["PENDING", "REJECTED", "CANCELLED"].includes(item.status) && (
                  <Box sx={cl.order.rightSide}>
                    {loading === item?.customer?.id ? (
                      <Typography sx={cl.order.rightSide.btnResend}>
                        Loading...
                      </Typography>
                    ) : (
                      <MuiLink
                        sx={cl.order.rightSide.btnResend}
                        component="button"
                        onClick={() => onClickResend(item)}
                      >
                        Resend
                      </MuiLink>
                    )}
                  </Box>
                )}
              <Statuses
                text={item?.status}
                titleStyle={{ color: "#000000", ml: "5px" }}
                sandIconColor="#F0C401"
                rejectedIconProps={{ strokeWidth: 4 }}
              />
            </Stack>
          )}
        </Stack>
      </Box>
    </Box>
  );
};

OrderItem.propTypes = {
  item: shape({
    id: string,
    name: string,
    date: string,
    status: string,
    customer: shape({
      id: string,
      name: string,
    }),
    appCustomer: shape({
      id: string,
      name: string,
    }),
    updatedAt: string,
    photo: string,
  }),
  currentTab: oneOf([...TABS]),
  onClickReview: func,
  onClickResend: func,
  loading: string,
  handleClose: func,
};
OrderItem.defaultProps = {
  item: {},
  onClickReview: () => {},
  onClickResend: () => {},
  isFirstItem: false,
  isNewItem: false,
  handleClose: () => {},
};
