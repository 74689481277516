import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { array } from "prop-types";
import { PayoutsAccordionItem, PayoutsHeader } from "./components";

const PayoutsList = ({ payoutsList }) => {
  const timeZone = useSelector(({ auth }) => auth.currentUser.timeZone);

  return (
    <Box
      sx={{
        border: "1px solid #d5d9d9",
        borderRadius: "4px",
        mt: "10px",
        maxHeight: "calc(100vh - 510px)",
        overflowY: "overlay",
      }}
    >
      <PayoutsHeader />
      {payoutsList.length ? (
        payoutsList.map((payout) => (
          <PayoutsAccordionItem key={payout.id} {...{ payout, timeZone }} />
        ))
      ) : (
        <Typography
          textAlign="center"
          color="#6A6A6A"
          fontWeight="300"
          fontSize="16px"
          lineHeight={10}
        >
          No payouts found
        </Typography>
      )}
    </Box>
  );
};

PayoutsList.propTypes = {
  payoutsList: array,
};

export default PayoutsList;
