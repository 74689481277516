import { string, object } from "prop-types";
import { Avatar, Box, Grid, Typography } from "@mui/material";
import { photoUrl, stringAvatar, truncateText } from "helpers/helpers";

export const FooterItem = ({
  fileName,
  name,
  role,
  date,
  repPermissions,
  repData,
}) => {
  return (
    <Grid
      sx={{
        height: "44px",
        alignItems: "center",
      }}
      xs={12}
      item
      container
    >
      <Grid sx={{ pl: 1.75 }} xs={1.2} item>
        {fileName ? (
          <Avatar
            sx={{ width: "22px", height: "22px" }}
            src={photoUrl(fileName)}
          />
        ) : (
          <Avatar
            {...stringAvatar(name, {
              width: "22px",
              height: "22px",
              fontSize: "10px",
            })}
          />
        )}
      </Grid>

      <Grid xs={7} item>
        {repPermissions &&
        repPermissions?.customers?.create_edit &&
        !repData ? (
          <Typography fontSize={13} color="#B2B2B2">
            {role}
          </Typography>
        ) : (
          <Typography fontSize={13} color="#B2B2B2" noWrap>
            <Box color="#47A06D" component="span">
              {truncateText(name, 30)}
            </Box>{" "}
            | {role}
          </Typography>
        )}
      </Grid>

      {/* Date */}
      <Grid
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          pr: 1,
        }}
        xs={3.8}
        item
      >
        <Typography fontSize={13} color="#B2B2B2">
          {date}
        </Typography>
      </Grid>
    </Grid>
  );
};

FooterItem.propTypes = {
  fileName: string,
  name: string,
  role: string,
  date: string,
  repPermissions: object,
  repData: object,
};
FooterItem.defaultProps = { fileName: "", name: "", role: "", date: "" };
