import React, { useMemo } from "react";
import {
  Box,
  CircularProgress,
  Drawer,
  IconButton,
  Typography,
} from "@mui/material";
import useStyles from "./styles";
import { CrossIcon } from "components/Icons";
import { StyledButton, StyledTextField } from "components";
import { useCustomerNotes } from "./NoteDrawer.hooks";
import { NoteItemDrawer } from "components/NoteDrawer/components";
import { useBodyNoteDrawer } from "components/NoteDrawer/components/BodyNoteDrawer/BodyNoteDrawer.hooks";
import { useWindowSize } from "helpers/hooks";
import {
  BODY_WRAPPER_PADDINGS,
  FOOTER_HEIGHT,
  HEADER_HEIGHT,
  INLINE_OF_ONE_ROW,
  PADDINGS_TEXTFIELD,
} from "./NoteDrawer.constants";

const NoteDrawer = () => {
  const calcHeight = useMemo(() => {
    return (
      PADDINGS_TEXTFIELD + BODY_WRAPPER_PADDINGS + HEADER_HEIGHT + FOOTER_HEIGHT
    );
  }, []);

  const classes = useStyles({
    BODY_WRAPPER_PADDINGS,
    FOOTER_HEIGHT,
    HEADER_HEIGHT,
  });

  const {
    note,
    handleChangeNote,
    handleSaveNote,
    handleCloseNote,
    open,
    isAdminEdit,
    isNoteView,
    editNote,
    handleDeleteNote,
    repData,
    noteData,
    repPermissions,
    currentUser,
  } = useCustomerNotes();

  const getTitleLabel = () => {
    if (repPermissions) {
      if (!editNote) return "Create";

      return isNoteView ? "View" : "Edit";
    }

    if (isAdminEdit) return "Edit";
    if (isNoteView) return "View";
    return "Create";
  };

  const getSaveLabel = () => {
    if (isAdminEdit || isNoteView || repPermissions) return "Done";
    return "Add";
  };

  const { profilePhoto, name, role } = useMemo(() => repData || {}, [repData]);
  const { getRole, daysAgo, distributorPhoto } = useBodyNoteDrawer();

  const [, windowHeight] = useWindowSize();

  const dynamicRows = ((windowHeight - calcHeight) / INLINE_OF_ONE_ROW).toFixed(
    0
  );

  return (
    <Drawer open={open} onClose={handleCloseNote} anchor="right">
      <Box className={classes.wrapper}>
        <Box className={classes.header}>
          <Typography>{getTitleLabel()} note</Typography>
          {note.loading && <CircularProgress size={23} />}
          <IconButton className={classes.closeButton} onClick={handleCloseNote}>
            <CrossIcon />
          </IconButton>
        </Box>
        <Box className={classes.bodyWrapper}>
          {isNoteView ? (
            <NoteItemDrawer
              note={note.text}
              fileName={repData ? profilePhoto?.fileName : distributorPhoto}
              name={name || "You"}
              role={getRole(role) || "Admin"}
              date={daysAgo(noteData?.updatedAt || noteData?.createdAt)}
              hideActions
              {...{ repPermissions, repData }}
            />
          ) : (
            <StyledTextField
              placeholder="Note"
              multiline
              minRows={3}
              maxRows={dynamicRows}
              fullWidth
              value={note.text}
              onChange={handleChangeNote}
              error={note.error}
              sx={{
                "& .MuiOutlinedInput-root": { height: "100%" },
              }}
            />
          )}
        </Box>
        <Box className={classes.footer}>
          {(repPermissions
            ? repPermissions?.customers?.create_edit &&
              !!repData?.representativeId &&
              (repData?.representativeId === currentUser?.id ||
                repData?.representativeId ===
                  currentUser?.backOfficeRepresentative?.id)
            : isAdminEdit || isNoteView) && (
            <StyledButton
              label="Delete"
              color="error"
              variant="contained"
              fontSize="15px"
              onClick={handleDeleteNote}
            />
          )}
          <Box display="flex" gap="11px" ml="auto">
            {!isNoteView && (
              <StyledButton
                label="Cancel"
                color="cancel"
                fontSize="15px"
                onClick={handleCloseNote}
              />
            )}
            <StyledButton
              disabled={!note.text}
              label={getSaveLabel()}
              variant="contained"
              fontSize="15px"
              onClick={handleSaveNote}
            />
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

export default NoteDrawer;
