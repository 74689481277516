import { useRef } from "react";
import { array, string, object, func, number } from "prop-types";
import { Link as NavLink, useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ImageIcon from "@mui/icons-material/Image";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { photoUrl } from "helpers/helpers";
import { StyledAvatar, StyledLabel, StyledTooltip } from "components";

const useStyles = makeStyles(() => ({
  roomRow: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    padding: "12px 17px 11px 15px",
    height: "71px",
    "&:not(:last-of-type)": {
      borderBottom: "0.2px solid #D5D9D9",
    },
  },
  onlineCircle: {
    width: "8px",
    height: "8px",
    backgroundColor: "#01CE05",
    borderRadius: "50%",
    border: "1px solid #ffffff",
    position: "absolute",
    bottom: 3.6,
    right: 0,
  },
  roomInfo: {
    fontSize: "13px",
    fontWeight: 400,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
}));

export const ConversationList = ({
  chatList,
  activeRoom,
  currentUser,
  setAnchorEl,
  setClickedChat,
  setChatMenuOpen,
  repsCollection,
  setActiveRoom,
  conversationListWidth,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const attachmentIcon = (type) => {
    switch (type) {
      case "image":
        return <ImageIcon style={{ fill: "#cccccc" }} />;
      case "file":
        return <InsertDriveFileIcon style={{ fill: "#cccccc" }} />;
      default:
        return <></>;
    }
  };

  const roomsNameRef = useRef({});

  return chatList.map((room) => {
    const isEllipsisActive = (e) => {
      if (!e) return;
      return e.offsetWidth < e.scrollWidth;
    };

    const showTooltip = isEllipsisActive(roomsNameRef.current[room?.roomId]);

    return (
      <Box
        key={
          room.roomId
            ? `${room.roomId}-${room.messageId}`
            : `${room.id}-${room.messageId}`
        }
      >
        <StyledTooltip
          enterDelay={2000}
          enterNextDelay={2000}
          placement="top"
          arrow
          sx={{
            "& .MuiTooltip-tooltip": {
              width: `${conversationListWidth}px`,
              minWidth: `${conversationListWidth}px`,
            },
          }}
          title={room?.name || room?.chatName}
          disableHoverListener={!showTooltip}
        >
          <Box
            className={classes.roomRow}
            sx={{
              width: "100%",
              color: "#1C1C19",
              textDecoration: "none",
              "&:hover": {
                backgroundColor:
                  (!!room.messageId || activeRoom !== room.roomId) && "#F7F7F7",
              },
              backgroundColor:
                !room.messageId && activeRoom === room.roomId
                  ? "rgba(66, 165, 127, 0.1)"
                  : !room.readBy?.find((id) => id === currentUser.id)
                  ? "#F0F0F0"
                  : "white",
            }}
            component={NavLink}
            to={`/conversations/${room.roomId}`}
            onContextMenu={(e) => {
              e.preventDefault();
              setAnchorEl(e.target);
              setClickedChat(room);
              setChatMenuOpen(true);
            }}
            onClick={(e) => {
              const collectionId = repsCollection?.docs?.[0]?.id;
              const representativeId = room.id;
              localStorage.setItem(
                "msgData",
                JSON.stringify({
                  collectionId: collectionId || null,
                  representativeId: representativeId || null,
                  representativesIds: room?.participants || null,
                  statusDocId: room.statusDocId || null,
                  messageId: room.messageId || null,
                })
              );
              setActiveRoom(room.roomId);
              if (e.metaKey || e.ctrlKey) return;
              navigate(room.roomId);
            }}
          >
            <Box position="relative">
              <StyledAvatar
                sx={{
                  height: "36px",
                  width: "36px",
                }}
                variant={room?.chatName ? "rounded" : "circular"}
                src={photoUrl(room.profilePhoto?.fileName)}
                stringStyle={{ fontSize: "12px" }}
                name={room?.name || room?.chatName}
              />
              {room.online && room.originalId && (
                <Box className={classes.onlineCircle} />
              )}
            </Box>
            <Box ml="5px" overflow="hidden" width="100%">
              <Box display="flex" gap="6px" alignItems="center" width="100%">
                <Typography
                  ref={(el) => (roomsNameRef.current[room?.roomId] = el)}
                  fontSize="13px"
                  fontWeight="400"
                  color="primary"
                  noWrap
                >
                  {room?.name || room?.chatName}
                </Typography>
                {!(room.originalId || room?.chatName) && (
                  <StyledLabel text="deleted" customWidth="43" />
                )}{" "}
              </Box>
              <Typography className={classes.roomInfo}>
                {room.baseUser?.email}
              </Typography>
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  justifyContent: room.lastMessage && "space-between",
                }}
                width="100%"
              >
                <Typography className={classes.roomInfo}>
                  {room.lastMessage}
                </Typography>
                {attachmentIcon(room.lastAttachment)}
              </Box>
            </Box>
          </Box>
        </StyledTooltip>
      </Box>
    );
  });
};

ConversationList.propTypes = {
  chatList: array,
  activeRoom: string,
  currentUser: object,
  repsCollection: object,
  setAnchorEl: func,
  setClickedChat: func,
  setChatMenuOpen: func,
  setActiveRoom: func,
  conversationListWidth: number,
};
ConversationList.defaultProps = {
  chatList: [],
  activeRoom: "",
  currentUser: {},
  repsCollection: {},
  setAnchorEl: () => {},
  setClickedChat: () => {},
  setChatMenuOpen: () => {},
  setActiveRoom: () => {},
  conversationListWidth: 0,
};
