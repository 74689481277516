/* eslint-disable no-useless-escape */
import * as Yup from "yup";

export const validationSchema = () =>
  Yup.object().shape({
    name: Yup.string()
      .trim()
      .required("This field is required")
      .test("nameValidation", "Incorrect territory name!", (value) => {
        const NAME_REGEXP = /[a-zA-Z]/;
        return NAME_REGEXP.test(value);
      }),
  });
