import {
  SET_SAVED_FILTERS,
  START_LOADING_SAVED_FILTERS,
  END_LOADING_SAVED_FILTERS,
  SET_VIEW_NAME_DIALOG,
  SET_EDIT_DIALOG,
  SET_TO_DEFAULT_DIALOGS,
  SET_SELECTED_FILTER_ID,
} from "../actions/savedFilters";

const initialState = {
  filtersForSave: [],
  filterForEdit: {},
  selectedFilterId: "",
  savedFilters: {
    DIRECT_ORDERS: [],
    THIRD_PARTY_ORDERS: [],
    DRAFT_ORDERS: [],
    PRODUCTS: [],
    GALLERY: [],
    CUSTOMERS: [],
    TASKS: [],
  },
  ui: {
    catalog: false,
    orders: false,
    editFilter: false,
    gallery: false,
    customers: false,
    tasks: false,
  },
  loading: false,
};

const savedFiltersReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_SAVED_FILTERS:
      return {
        ...state,
        savedFilters: {
          ...state.savedFilters,
          [payload.type]: payload.filters,
        },
      };

    case SET_VIEW_NAME_DIALOG: {
      return {
        ...state,
        ui: {
          ...state.ui,
          [payload.type]: payload.show,
        },
        filtersForSave: payload.filtersForSave ?? [],
      };
    }

    case SET_SELECTED_FILTER_ID: {
      return {
        ...state,
        selectedFilterId: payload,
      };
    }

    case SET_EDIT_DIALOG: {
      return {
        ...state,
        ui: {
          ...state.ui,
          editFilter: payload.show,
        },
        filterForEdit: payload.filterForEdit ?? {},
      };
    }

    case SET_TO_DEFAULT_DIALOGS: {
      return { ...state, ui: initialState.ui };
    }

    case END_LOADING_SAVED_FILTERS:
      return { ...state, loading: false };

    case START_LOADING_SAVED_FILTERS:
      return { ...state, loading: true };

    default:
      return state;
  }
};

export default savedFiltersReducer;
