/* eslint-disable no-useless-escape */
import * as Yup from "yup";

export const validationSchema = () =>
  Yup.object().shape({
    deliveryMethod: Yup.string().trim().required("This field is required"),
    delivererId: Yup.string()
      .trim()
      .when("deliveryMethod", {
        is: "LOCAL_DELIVERY",
        then: Yup.string()
          .required("This field is required")
          .test("delivererId", "Select rep to fulfill items", (value) => {
            return !!value && value !== "Select reps";
          }),
      }),
    trackingId: Yup.string()
      .trim()
      .nullable()
      .when("deliveryMethod", {
        is: "SHIPPING_CARRIER",
        then: Yup.string().required("Enter tracking ID"),
      }),
    shippingCarrier: Yup.string()
      .trim()
      .nullable()
      .when("deliveryMethod", {
        is: "SHIPPING_CARRIER",
        then: Yup.string().required("Select shipping carrier"),
      }),
  });
