import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  headerWrapper: {
    width: "100%",
    display: "flex",
    height: "39px",
    minHeight: "39px",
    backgroundColor: "#EEEEEE",
    borderBottom: "1px solid #D5D9D9",
    borderRadius: "4px 4px 0 0",
    alignItems: "center",
    "& .MuiTypography-root": {
      color: "#6A6A6A",
    },
  },
}));

export default useStyles;
