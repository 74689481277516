import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { array, number, string } from "prop-types";

import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { TransactionsItemsHeader, TransactionsItem } from "./components";
import { SCROLL_LIMIT } from "../../../../../../../../../utils/constants";
import { getTransactionsAction } from "../../../../../../../../../redux/actions/transactions";
import {
  InfiniteLoadMoreBtn,
  InfiniteScrollWrapper,
} from "../../../../../../../../../components";

export const TransactionsTable = ({ list, count, timeZone }) => {
  const transactionsLoading = useSelector(
    ({ transactions }) => transactions.loading
  );
  const dispatch = useDispatch();

  const [limit, setLimit] = useState(2 * SCROLL_LIMIT);

  const handleFetchTransaction = useCallback(() => {
    dispatch(getTransactionsAction({ limit }));
    setLimit(limit + SCROLL_LIMIT);
  }, [dispatch, limit]);

  return (
    <Box width="100%">
      <TransactionsItemsHeader />
      <Stack
        sx={{
          maxHeight: "calc(100vh - 370px)",
          overflow: "hidden",
          borderWidth: "0px 1px 1px 1px",
          borderStyle: "solid",
          borderColor: "#D5D9D9",
          borderRadius: "0 0 4px 4px",
          background: !list.length && "white",
        }}
      >
        {count > 0 ? (
          <InfiniteScrollWrapper
            maxHeight="calc(100vh - 371px)"
            dataLength={list.length}
            next={handleFetchTransaction}
            loader={
              <CircularProgress sx={{ ml: "50%", mt: "2px" }} size="30px" />
            }
            hasMore={list.length < count}
            id="product-tags-scroll"
          >
            {list.map(
              ({
                id,
                date,
                orderCustomId,
                customerName,
                type,
                amount,
                fee,
                net,
              }) => (
                <TransactionsItem
                  key={id}
                  date={date}
                  orderCustomId={orderCustomId}
                  customerName={customerName}
                  type={type}
                  amount={amount}
                  fee={fee}
                  net={net}
                  timeZone={timeZone}
                />
              )
            )}
            {!transactionsLoading && list.length < count && (
              <InfiniteLoadMoreBtn onClick={handleFetchTransaction} />
            )}
          </InfiniteScrollWrapper>
        ) : (
          <Typography
            fontWeight="300"
            fontSize="16px"
            textAlign="center"
            width="100%"
            color="#6A6A6A"
            paddingTop="20px"
            paddingBottom="20px"
          >
            No transactions found
          </Typography>
        )}
      </Stack>
    </Box>
  );
};

TransactionsTable.propTypes = {
  list: array,
  count: number,
  timeZone: string,
};

TransactionsTable.defaultProps = {
  list: [],
  count: 0,
  timeZone: "",
};

TransactionsTable.defaultProps = {};
