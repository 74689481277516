import React from "react";
import { any, string, object } from "prop-types";
import { Paper } from "@mui/material";
import useStyles from "./styles";

const PaperWrapper = ({ children, className, wrapperStyle }) => {
  const classes = useStyles();
  return (
    <Paper sx={wrapperStyle} className={`${classes.paperWrapper} ${className}`}>
      {children}
    </Paper>
  );
};

PaperWrapper.propTypes = {
  children: any,
  className: string,
  wrapperStyle: object,
};

export default PaperWrapper;
