import * as React from "react";

export const SignoutIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    // width={18.79}
    width={17.79}
    // height={17.5}
    height={16.5}
    viewBox="0 0 18.79 17.5"
    {...props}
  >
    <g
      id="Group_5010"
      data-name="Group 5010"
      transform="translate(-1082.25 -16018.25)"
    >
      <path
        id="Path_3064"
        data-name="Path 3064"
        d="M34,246v-2a2,2,0,0,0-2-2H26a2,2,0,0,0-2,2v12a2,2,0,0,0,2,2h6a2,2,0,0,0,2-2v-2"
        transform="translate(1059 15777)"
        fill="none"
        stroke="#a0a098"
        strokeLinecap="round"
        strokeWidth={1.5}
      />
      <path
        id="Path_3065"
        data-name="Path 3065"
        d="M32,250h9"
        transform="translate(1059 15777)"
        fill="none"
        stroke="#a0a098"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
      <path
        id="Path_3066"
        data-name="Path 3066"
        d="M37,247l2,3"
        transform="translate(1061 15777)"
        fill="none"
        stroke="#a0a098"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
      <path
        id="Path_3067"
        data-name="Path 3067"
        d="M37,253l2-3"
        transform="translate(1061 15777)"
        fill="none"
        stroke="#a0a098"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
    </g>
  </svg>
);
