import { Box, Typography } from "@mui/material";
import { any, string, bool } from "prop-types";

const NoActivity = ({ icon, activity, isMerchendiser }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      height="100%"
      justifyContent="center"
    >
      <Box
        sx={{
          borderRadius: "50%",
          width: "64px",
          height: "64px",
          backgroundColor: "#F6F6F6",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {icon}
      </Box>
      <Typography color="#1C1C19" mt="12px">
        {isMerchendiser ? "Not available for merchandisers" : `No ${activity}`}
      </Typography>
    </Box>
  );
};

NoActivity.propTypes = { activity: string, icon: any, isMerchendiser: bool };

export default NoActivity;
