import axios from "axios";

// eslint-disable-next-line no-undef
const clientId = process.env.REACT_APP_SHIPSTATION_CLIENT_ID;

export const getQuickBooksAuth = async (params) => {
  return axios
    .get("/auth/quickbooks-auth-uri", { params })
    .then((res) => res.data);
};

export const syncQuickBooks = async () => {
  return axios.post("/sync/quickbooks").then((res) => res.data);
};

export const disconnectQuickBooks = async () => {
  return axios.delete("/auth/quickbooks").then((res) => res.data);
};

export const checkShipStationConnection = async () => {
  return axios
    .get(`/oauth2/check-active-token/${clientId}`)
    .then((res) => res.data);
};

export const disconnectShipStation = async () => {
  return axios
    .delete(`/oauth2/drop-tokens/${clientId}`)
    .then((res) => res.data);
};

export const checkQuickbooksShippingAvailability = async ({
  manualDeliveryFee,
}) => {
  return axios
    .post("/quickbooks/check-shipping-availability", { manualDeliveryFee })
    .then((res) => res.data);
};

export const enableQuickbooksShipping = async () => {
  return axios.post("/quickbooks/enable-shipping").then((res) => res.data);
};
