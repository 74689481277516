const RETURNS = [
  { id: 1, name: "Product and shipping box damaged" },
  { id: 2, name: "Product is damaged" },
  { id: 3, name: "Item arrived too late" },
  { id: 4, name: "Item defective or doesn't work" },
  { id: 5, name: "Wrong item was sent" },
  { id: 6, name: "Bought by mistake" },
  { id: 7, name: "Other" },
];

export { RETURNS };
