import { useContext } from "react";
import { Box, Grid, ListSubheader, MenuItem, Paper } from "@mui/material";
import { StyledSelect, TotalSummaryInfoBlock } from "components";
import { TasksPageContext } from "Pages/TasksPage/TasksPage";
import { StyledMenuItem } from "./components";
import { cl } from "./TasksTotalSummaries.styles";

export const TasksTotalSummaries = () => {
  const {
    currentRepresentative,
    repsCount,
    repsList,
    handleFetchReps,
    handleSetCurrentRepresentative,
    salesList,
    merchandisersList,
    repsPage,
    repsLoading,
    totalSummariesState,
  } = useContext(TasksPageContext);

  const {
    loadingTotalSummaries,
    countCompletedTasks,
    countPendingTasks,
    countTasks,
  } = totalSummariesState || {};

  return (
    <Paper
      sx={cl.paper}
      component={Grid}
      container
      elevation={0}
      variant="outlined"
    >
      <Grid sx={cl.grid} xs={12} item>
        <Box width="296px">
          <StyledSelect
            disabled={repsLoading}
            adminIsAllowed
            displayEmpty
            value={currentRepresentative}
            height="39px"
            fontSize="15px"
            formSx={cl.formSx}
            PaperPropsSx={{ maxWidth: "300px" }}
            dataCount={repsCount}
            dataLength={repsList?.length}
            handleFetch={() => handleFetchReps(repsPage + 1)}
            onChange={(e) => handleSetCurrentRepresentative(e.target.value)}
          >
            <MenuItem value="admin">Admin</MenuItem>
            <MenuItem value="">All representatives</MenuItem>
            {salesList?.length && (
              <ListSubheader sx={cl.selectGroupTitle}>Sales</ListSubheader>
            )}
            {salesList?.map((rep) => (
              <StyledMenuItem key={rep.id} value={rep.id}>
                {rep.name}
              </StyledMenuItem>
            ))}
            {merchandisersList?.length && (
              <ListSubheader sx={cl.selectGroupTitle}>
                Merchandisers
              </ListSubheader>
            )}
            {merchandisersList?.map((rep) => (
              <StyledMenuItem key={rep.id} value={rep.id}>
                {rep.name}
              </StyledMenuItem>
            ))}
          </StyledSelect>
        </Box>

        <TotalSummaryInfoBlock
          isLoading={loadingTotalSummaries}
          items={[
            {
              name: "Total Tasks",
              value: countTasks,
            },
            {
              name: "Open",
              value: countPendingTasks,
            },
            {
              name: "Closed",
              value: countCompletedTasks,
            },
          ]}
        />
      </Grid>
    </Paper>
  );
};
