import * as React from "react";

/* eslint-disable react/prop-types */
export const FilterStarIcon = ({ fill = "none", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16.895}
    height={15.738}
    viewBox="0 0 16.895 15.738"
    {...props}
  >
    <path
      data-name="Icon ionic-ios-star"
      d="M15.93 5.555h-5.317L9 .733a.586.586 0 0 0-1.1 0L6.282 5.555H.928a.58.58 0 0 0-.578.578.4.4 0 0 0 .011.1.56.56 0 0 0 .242.408l4.37 3.08L3.3 14.596a.58.58 0 0 0 .2.651.56.56 0 0 0 .325.141.7.7 0 0 0 .361-.13l4.266-3.04 4.266 3.04a.7.7 0 0 0 .361.13.52.52 0 0 0 .322-.141.57.57 0 0 0 .2-.651L11.924 9.72l4.334-3.109.1-.09a.553.553 0 0 0-.427-.965Z"
      fill={fill}
      stroke="#707070"
      strokeWidth={0.7}
    />
  </svg>
);
