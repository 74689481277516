import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import {
  getRepsAction,
  resetSalesRepsAction,
} from "../../../redux/actions/reps";
import { currentUserSelector } from "../../../redux/selectors/auth";
import {
  repsGetSalesUsers,
  salesCountSelector,
} from "../../../redux/selectors/reps";
import { SCROLL_LIMIT } from "../../../utils/constants";
import { initialState } from "../helpers";

const selector = createSelector(
  currentUserSelector,
  repsGetSalesUsers,
  salesCountSelector,
  (currentUser, repsSalesUsers, salesCount) => ({
    currentUser,
    repsSalesUsers,
    salesCount,
  })
);

export const useReturnDialogAction = ({ open, order, returnItem }) => {
  const { currentUser, repsSalesUsers, salesCount } = useSelector(selector);
  const dispatch = useDispatch();
  const { sendNotifications, shippingCarriers } = currentUser || {};

  const [state, setState] = useState(initialState);

  useEffect(() => {
    if (open) {
      dispatch(resetSalesRepsAction());
      dispatch(
        getRepsAction(
          {
            limit: SCROLL_LIMIT,
            role: "sales",
          },
          { isScrolling: true }
        )
      );
    }
  }, [open, dispatch]);

  useEffect(() => {
    if (open && order) {
      setState(() => ({
        title: returnItem?.id ? "Edit return" : "Return",
        orderCustomId: order?.customId?.customId ?? "",
        sendNotification: sendNotifications,
        shippingCarriers,
        repsSalesUsers,
        salesCount,
      }));
    }
  }, [
    open,
    order,
    repsSalesUsers,
    returnItem?.id,
    salesCount,
    sendNotifications,
    shippingCarriers,
  ]);

  return { state };
};
