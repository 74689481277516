import { useMemo, useState } from "react";
import { object, number } from "prop-types";
import { Controller, useWatch } from "react-hook-form";
import { range } from "lodash";
import { MenuItem } from "@mui/material";
import { StyledSelect } from "../../../../../../../../../components/Selects";

export const QauntitySelect = ({ control, product, calcAvailableQuantity }) => {
  const formField = useWatch({ control });

  const itemsList = useMemo(() => {
    return range(calcAvailableQuantity);
  }, [calcAvailableQuantity]);

  const [state, setState] = useState(
    itemsList?.length > 100 ? itemsList.slice(0, 100) : itemsList
  );

  const handleFetch = () => {
    setState((prev) => {
      return [...prev, ...itemsList.slice(state?.length, state?.length + 100)];
    });
  };

  return (
    <Controller
      render={({ field }) => (
        <StyledSelect
          dataLength={state?.length}
          dataCount={itemsList?.length}
          handleFetch={handleFetch}
          notched={false}
          fullWidth
          fontSize="12px"
          color="#000000"
          defaultValue="0"
          {...field}
          value={field?.value || 0}
          height="26px"
          sx={{
            fontWeight: 400,
            "& .MuiSelect-select": {
              pl: 1,
              pr: "24px !important",
              paddingTop: "3px !important",
              paddingBottom: "3px !important",
            },
            "& svg": {
              right: 0,
            },
            "& > fieldset": {
              border: "none",
            },
          }}
        >
          <MenuItem value={field.value} sx={{ display: "none" }}>
            {formField.products?.[product?.index]?.deliverQuantity}
          </MenuItem>
          {state.map((num) => (
            <MenuItem key={num} value={num}>
              {num}
            </MenuItem>
          ))}
        </StyledSelect>
      )}
      name={`products.${product?.index}.deliverQuantity`}
      control={control}
    />
  );
};

QauntitySelect.propTypes = {
  control: object,
  product: object,
  calcAvailableQuantity: number,
};
