// import { Link, useLocation } from "react-router-dom";
import { array, func, string } from "prop-types";

import { Tab, Tabs, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { StyledPopper } from "components";

// import StyledButton from "../../../components/StyledButton";

const useStyles = makeStyles(() => ({
  label: {
    "& .MuiFormLabel-root": {
      color: "#B5B5AC",
      fontSize: "12px",
      top: "2px",
    },
    "& .Mui-focused": {
      color: "#42A57F",
      fontSize: "1rem",
      top: "0px",
    },
    "& .MuiOutlinedInput-root": {
      height: "100%",
    },
  },
  tabs: {
    minHeight: "auto",
    height: "44px",
    "& .Mui-selected": {
      backgroundColor: "transparent!important",
    },
  },
  smallerButton: {
    height: "24px",
    "& > span": {
      fontSize: "11px",
    },
  },

  biggerButton: {
    "& > span": {
      fontSize: "14px",
    },
  },
}));

const ConversationsSwitcherTab = ({
  tabs,
  currentTab,
  handleSetCurrentTab,
}) => {
  const classes = useStyles();
  // const { pathname } = useLocation();

  const handleChangeCurrentTab = (e, newValue) => {
    if (newValue !== tabs[0]) handleSetCurrentTab(newValue);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        px: "32px",
        backgroundColor: "#F0F0F0",
        borderBottom: "0.5px solid #D4D4D4",
        height: "44px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: 3,
        }}
      >
        <Box>
          {
            <Typography
              variant="h6"
              fontWeight="400"
              fontSize="20px"
              color="#9C9C94"
            >
              Conversations
            </Typography>
          }
        </Box>
        <Box>
          <StyledPopper
            anchorEl={anchorEl}
            open={!!anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            sx={{
              pointerEvents: "none",
            }}
          >
            <Typography
              py="2px"
              px="5px"
              fontSize="12px"
              backgroundColor="#6a6a6a"
              color="#fff"
            >
              Coming soon.
            </Typography>
          </StyledPopper>
          {tabs && (
            <Tabs
              className={classes.tabs}
              value={currentTab}
              onChange={handleChangeCurrentTab}
            >
              <Tab
                aria-haspopup="true"
                sx={{
                  py: 0,
                  height: "44px",
                  fontSize: "12px",
                  fontWeight: 400,
                  color: "#9C9C94",
                }}
                // disabled
                onMouseEnter={(e) => setAnchorEl(e.currentTarget)}
                onMouseLeave={() => setAnchorEl(null)}
                label={<Box sx={{ textTransform: "none" }}>{tabs[0]}</Box>}
                value={tabs[0]}
              />
              <Tab
                sx={{
                  py: 0,
                  height: "44px",
                  fontSize: "12px",
                  fontWeight: 400,
                  color: "#9C9C94",
                }}
                // disabled={tab === "Customers"}
                label={<Box sx={{ textTransform: "none" }}>{tabs[1]}</Box>}
                value={tabs[1]}
              />
            </Tabs>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: 1,
        }}
      ></Box>
    </Box>
  );
};

ConversationsSwitcherTab.propTypes = {
  tabs: array,
  currentTab: string,
  handleSetCurrentTab: func,
};
ConversationsSwitcherTab.defaultProps = {
  tabs: [],
};

export default ConversationsSwitcherTab;
