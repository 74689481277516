import React from "react";

export const CatalogIconFilled = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="17"
      viewBox="0 0 22 17"
      {...props}
    >
      <g
        id="Group_4872"
        data-name="Group 4872"
        transform="translate(-891.25 -16418.25)"
      >
        <path
          id="Path_3007"
          data-name="Path 3007"
          d="M2,6A6.277,6.277,0,0,1,7,4a6.277,6.277,0,0,1,5,2V20s-1.5-1-5-1-5,1-5,1ZM12,6a6.277,6.277,0,0,1,5-2,6.277,6.277,0,0,1,5,2V20s-1.5-1-5-1-5,1-5,1Z"
          transform="translate(890 16415)"
          fill="#409a65"
          stroke="#409a65"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_3008"
          data-name="Path 3008"
          d="M12,16.895V7"
          transform="translate(890 16415.686)"
          fill="none"
          stroke="#fff"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
};
