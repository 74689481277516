import { useEffect, useState } from "react";

export const useContacts = ({ initialContacts, setValue }) => {
  const [openContacts, setOpenContacts] = useState(false);
  const [contacts, setContacts] = useState([]);

  const [editContact, setEditContact] = useState(null);

  useEffect(
    function setInitialContacts() {
      if (initialContacts?.length) {
        setContacts(initialContacts);
      }
    },
    [contacts, initialContacts]
  );

  const handleOpenContacts = () => setOpenContacts(true);

  const handleCloseContacts = () => {
    setOpenContacts(false);
    setEditContact(null);
  };

  const handleAddContact = (contact, isEdit) => {
    const isDefaultContact = !!contact?.defaultContact;

    const tempContacts = isDefaultContact
      ? contacts.map((c) => ({ ...c, defaultContact: false }))
      : [...contacts];

    if (isEdit) {
      const indexContact = tempContacts?.findIndex((c) => {
        if (c?.id) return c.id === contact?.id;
        return c?.fid === contact?.fid;
      });

      tempContacts.splice(indexContact, 1, contact);

      setContacts(tempContacts);
      if (setValue) setValue("contacts", tempContacts, { shouldDirty: true });
    }

    if (!isEdit) {
      setContacts([...tempContacts, contact]);
      if (setValue)
        setValue("contacts", [...tempContacts, contact], { shouldDirty: true });
    }
  };

  const handleRemoveContact = (contact) => {
    const isDefaultContact = !!contact?.defaultContact;
    const tempContacts = [...contacts];
    const indexContact = tempContacts?.findIndex((c) => {
      if (c?.id) return c.id === contact?.id;
      return c?.fid === contact?.fid;
    });

    tempContacts.splice(indexContact, 1);

    const c = isDefaultContact
      ? tempContacts.map((c, i) => {
          return i === 0
            ? { ...c, defaultContact: true }
            : { ...c, defaultContact: false };
        })
      : tempContacts;

    setContacts(c);
    if (setValue) setValue("contacts", c, { shouldDirty: true });
  };

  return {
    openContacts,
    handleOpenContacts,
    handleCloseContacts,
    contacts,
    handleAddContact,
    editContact,
    setEditContact,
    handleRemoveContact,
  };
};
