import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUserFieldAction } from "../../redux/actions/auth";
import { getDistributorSubscriptionsService } from "../../services/stripe";
import { EXTRA_USER_PRICE } from "./ReachedUseraLimitDialog.constants";
import { useRepsPermissions } from "helpers/hooks";

export const useUsersLimitActions = () => {
  const currentUser = useSelector(({ auth }) => auth.currentUser);
  const dispatch = useDispatch();

  const repPermissions = useRepsPermissions();

  const [state, setState] = useState({
    isTrial:
      currentUser?.distributorSubscription?.stripeSubscriptionStatus ===
      "trialing",
    isUpdate: false,
    allowedUsers: currentUser?.distributorSubscription?.allowedUsers,
    extraAllowedUsers:
      currentUser?.distributorSubscription?.extraUser?.extraAllowedUsers ?? 0,
    extra_user_count: 1,
    extra_user_price: EXTRA_USER_PRICE,
    current_plan: currentUser?.distributorSubscription || {},
    calcNextSum: 0,
    payDescription: {
      date: "",
      sum: 0,
      timeZone: currentUser?.timeZone,
    },
  });

  const openDialog = useMemo(
    () => !!currentUser?.showReachedUsersLimitDialog,
    [currentUser?.showReachedUsersLimitDialog]
  );

  const handleClose = useCallback(
    () => dispatch(updateUserFieldAction("showReachedUsersLimitDialog", null)),
    [dispatch]
  );

  const fetchSubscriptions = async () => {
    const { rows } =
      (await getDistributorSubscriptionsService({
        params: { priority: true },
      })) || {};

    const listWithFeatures = rows.slice(0, 2).map((el, i) => {
      const preparedData = {
        ...el,
        features: {},
      };

      preparedData.features[i === 0 ? "5 users" : "10 users"] = true;

      preparedData.features = {
        ...preparedData.features,
        "Priority support": i === 1,
        Customizations: i === 1,
      };

      return preparedData;
    });

    setState((prev) => ({
      ...prev,
      plans_list: listWithFeatures,
    }));
  };

  useEffect(() => {
    if (repPermissions) return;
    fetchSubscriptions();
    setState((prev) => ({
      ...prev,
      isUpdate: !!currentUser?.showReachedUsersLimitDialog?.update,
    }));
  }, [
    currentUser?.showReachedUsersLimitDialog?.update,
    openDialog,
    repPermissions,
  ]);

  const premiumPlan = state?.plans_list?.find(
    (sub) => sub?.name === "Wholesaler Premium"
  );

  const extraAllowedUsers =
    state?.current_plan?.extraUser?.extraAllowedUsers ?? 0;

  useEffect(() => {
    const extraUserSum =
      (extraAllowedUsers + state?.extra_user_count) * state?.extra_user_price;

    setState((prev) => ({
      ...prev,
      calcNextSum: state?.current_plan?.subscription?.price + extraUserSum,
    }));
  }, [
    extraAllowedUsers,
    premiumPlan?.price,
    state?.current_plan?.extraUser?.extraAllowedUsers,
    state?.current_plan?.price,
    state?.current_plan?.subscription?.price,
    state?.extra_user,
    state?.extra_user_count,
    state?.extra_user_price,
    state?.plans_list,
  ]);

  const handleSetExtraUser = (value) => {
    setState((prev) => ({ ...prev, extra_user_count: value ?? 0 }));
  };

  const handleSetPayDescription = (value) => {
    if (!value)
      return setState((prev) => ({
        ...prev,
        payDescription: { ...prev.payDescription, date: "", sum: 0 },
      }));

    if (value === "Request Upgrade") {
      return setState((prev) => ({
        ...prev,
        payDescription: {
          ...prev.payDescription,
          date: state?.current_plan?.currentPeriodEnd,
          sum: premiumPlan?.price,
        },
      }));
    }

    if (value === "Request Extra Users") {
      setState((prev) => ({
        ...prev,
        payDescription: {
          ...prev.payDescription,
          date: state?.current_plan?.currentPeriodEnd,
          sum: state?.calcNextSum,
        },
      }));
    }
  };

  const handleChangeCurrentPlan = (value) => {
    const preparedData = {
      current_plan: state?.current_plan,
      chosen_plan: state?.plans_list?.find((plan) => plan.name === value),
    };
    if (value === "extra") {
      preparedData.extra_user_count = state.extra_user_count;
    }

    dispatch(updateUserFieldAction("confirmSelectionPlanDialog", preparedData));
    dispatch(updateUserFieldAction("showReachedUsersLimitDialog", null));
  };

  return {
    payDescription: state.payDescription,
    openDialog,
    handleClose,
    state,
    handleSetExtraUser,
    handleChangeCurrentPlan,
    handleSetPayDescription,
  };
};
