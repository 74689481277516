import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    padding: "37px 37px 28px 46px",
  },

  title: {
    fontSize: "30px",
    color: "#707070",
  },

  dialogContent: {
    width: "100%",
    maxWidth: "1318px",
    padding: "0px 45px 51px 45px",
  },

  filterWrap: {
    display: "flex",
    gap: "26px",
    alignItems: "center",
    paddingTop: "3px",
  },

  tableWrap: {
    border: "1px solid #d5d9d9",
    marginTop: "11px",
    height: "275px",
    borderRadius: "0 0 4px 4px",
  },

  contentWrap: {
    height: "80%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default useStyles;
