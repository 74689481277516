/* eslint-disable no-useless-escape */
import * as Yup from "yup";

export const validationSchema = () =>
  Yup.object().shape({
    checkInCustomerRadius: Yup.number()
      .typeError("Must be a number.")
      .required("This field is required")
      .when("checkInCustomerRadiusEnabled", {
        is: true,
        then: Yup.number()
          .transform((o, v) => {
            if (v === "") return 0;
            return parseFloat(v);
          })
          .test(
            "checkInCustomerRadiusValidations",
            "Must be a number.",
            (value) => {
              return value >= 1;
            }
          ),
      }),
    contactRoles: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().trim().required("This field is required"),
        defaultRole: Yup.boolean().required("This field is required"),
      })
    ),
  });
