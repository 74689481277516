export const FileIconOutlined = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10.172"
      height="11.625"
      viewBox="0 0 10.172 11.625"
    >
      <path
        id="Icon_metro-file-empty"
        data-name="Icon metro-file-empty"
        d="M14.192,4.529A10.3,10.3,0,0,0,13.2,3.4a10.3,10.3,0,0,0-1.132-.99,1.811,1.811,0,0,0-1.032-.479H5.407a.909.909,0,0,0-.908.908v9.809a.909.909,0,0,0,.908.908h8.355a.909.909,0,0,0,.908-.908V5.561a1.811,1.811,0,0,0-.479-1.032Zm-1.5-.618a9.909,9.909,0,0,1,.824.924H11.764V3.087A9.9,9.9,0,0,1,12.688,3.91Zm1.256,8.734a.184.184,0,0,1-.182.182H5.407a.184.184,0,0,1-.182-.182V2.836a.184.184,0,0,1,.182-.182h5.631V5.2a.363.363,0,0,0,.363.363h2.543Z"
        transform="translate(-4.499 -1.928)"
        fill="#707070"
      />
    </svg>
  );
};
