import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  drawMenuWrapper: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    bottom: "28px",
    transition: "0.3s all ease",
    left: "50%",
    transform: "translateX(-50%)",
    pointerEvents: "none",
  },

  greenSpan: {
    color: "#47A06D",
  },

  showControlsWrapper: {
    position: "absolute",
    zIndex: "10",
    bottom: "28px",
    right: "22px",
    display: "flex",
    height: "33px",
    gap: "20px",
    "& .MuiFormControlLabel-root": {
      zIndex: "10",
      backgroundColor: "#fff",
      borderRadius: "4px",
      padding: "7px 9px 7px 0px",
      filter: "drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.56))",
      margin: 0,
    },
  },

  showProspectsWrapper: {
    position: "absolute",
    zIndex: "10",
    bottom: "28px",
    right: "36px",
    width: "139px",
    display: "flex",
    height: "33px",
    gap: "20px",
    transform: "translateX(-100%)",
    "& .MuiFormControlLabel-root": {
      zIndex: "10",
      backgroundColor: "#fff",
      borderRadius: "4px",
      padding: "7px 9px 7px 0px",
      filter: "drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.56))",
      margin: 0,
      width: "139px",
    },
  },

  tools: {
    borderRadius: "0 13px 13px 0",
    border: "0.5px solid #d5d9d9",
    borderLeft: "none",
    transform: ({ showDrawTools, showSelectedDrawing }) =>
      `translate(${showSelectedDrawing ? "0" : "150px"} , ${
        showDrawTools ? "0%" : "100px"
      })`,
    pointerEvents: "all",
    transition: "0.3s all ease",
  },

  selectedMenu: {
    borderRadius: "13px",
    border: "0.5px solid #d5d9d9",
    transform: ({ showSelectedDrawing, showDrawTools }) =>
      `translate(${showDrawTools ? "0" : "-150px"}, ${
        showSelectedDrawing ? "0%" : "100px"
      })`,
    pointerEvents: "all",
    transition: "0.3s all ease",
  },

  selectedMenuWrapper: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    bottom: "28px",
    transform: ({ showSelectedDrawing }) =>
      `translate(-50%, ${showSelectedDrawing ? "0%" : "100px"})`,
    left: "50%",
    transition: "0.3s all ease",
  },

  drawBlockWrapper: {
    backgroundColor: "#3F3F3F",
    height: "44px",
    display: "flex",
    alignItems: "center",
    padding: "0 15px",
  },

  drawCreateBlockWrapper: {
    display: "flex",
    alignItems: "center",
    borderRadius: "13px",
  },

  drawDivider: {
    maxHeight: "20px",
    background: "#FFFFFF",
    margin: "0 7px",
  },

  drawTabs: {
    height: "29px",
    minHeight: "29px",
  },

  drawTab: {
    textTransform: "none",
    fontSize: "13px",
    color: "#fff",
    borderRadius: "4px",
    "&.Mui-selected": {
      backgroundColor: "rgb(255, 255, 255, 0.2)!important",
      color: "#fff",
    },
    minWidth: "10px",
    width: "84px",
    height: "29px",
    minHeight: "29px",
    padding: 0,
  },

  routePlaceholder: {
    position: "absolute",
    zIndex: 100,
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(204, 204, 204, 0.5)",
  },

  routePlaceholderMessage: {
    backgroundColor: "#fff",
    padding: "18px 32px",
    borderRadius: "35px",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    cursor: "pointer",
  },

  noteButton: {
    position: "absolute",
    zIndex: 100,
    top: 10,
    right: 74,
    width: "53px",
    minWidth: "53px",
    height: "40px",
  },
  showMissing: {
    background: "#FFFFFF",
    padding: "4.5px 16px",
    minWidth: "180px",
    position: "absolute",
    zIndex: "10",
    filter: "drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.56))",
    bottom: "28px",
    right: "333px",
    display: "flex",
    gap: "10px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "4px",
  },

  radiusSliderGroup: {
    display: "flex",
    // flexDirection: "column",
    alignItems: "center",
    gap: "14px",
  },
}));

export default useStyles;
