import { useState } from "react";
import { string, func, bool } from "prop-types";
import { Grid, MenuItem, Typography } from "@mui/material";
import { StyledMenu, ThreeDotButton } from "components";

export const HeaderItem = ({
  note,
  onEdit,
  onDelete,
  hideEdit,
  hideActions,
  disabledDelBtn,
  isAdmin,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const openHeaderList = Boolean(anchorEl);

  const handleClickOnDotsBtn = (e) => {
    e.stopPropagation();
    if (isAdmin) return;
    setAnchorEl(e.currentTarget);
  };

  const handleCloseDotsBtn = () => {
    setAnchorEl(null);
  };

  return (
    <Grid xs={12} item container>
      <Grid
        sx={{
          pt: 1.5,
          pb: 1.5,
          pl: 1.75,
        }}
        xs={11}
        item
      >
        <Typography
          fontSize={13}
          color="#5F6267"
          whiteSpace="break-spaces"
          noWrap
        >
          {note}
        </Typography>
      </Grid>
      <Grid
        sx={{
          display: "flex",
          justifyContent: "center",
          pt: 1.5,
        }}
        xs={1}
        item
      >
        {!hideActions && (
          <>
            <ThreeDotButton
              disabled={isAdmin}
              paddingRight="0px"
              onClick={handleClickOnDotsBtn}
            />
            <StyledMenu
              sx={{ paddingY: 0 }}
              anchorEl={anchorEl}
              isOpen={openHeaderList}
              handleClose={handleCloseDotsBtn}
            >
              {hideEdit ? (
                <MenuItem
                  onClick={() => {
                    handleCloseDotsBtn();
                    onEdit();
                  }}
                >
                  Edit
                </MenuItem>
              ) : null}
              <MenuItem
                disabled={disabledDelBtn}
                onClick={() => {
                  handleCloseDotsBtn();
                  onDelete();
                }}
              >
                Delete
              </MenuItem>
            </StyledMenu>
          </>
        )}
      </Grid>
    </Grid>
  );
};

HeaderItem.propTypes = {
  note: string,
  onEdit: func,
  onDelete: func,
  hideEdit: bool,
  hideActions: bool,
  disabledDelBtn: bool,
  isAdmin: bool,
};
HeaderItem.defaultProps = {
  note: "",
  onEdit: () => {},
  onDelete: () => {},
  hideActions: false,
  disabledDelBtn: false,
};
