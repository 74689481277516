import { useEffect, useState } from "react";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { bool, func, string, array, number, oneOf } from "prop-types";
import useStyles from "./styles";
import { CrossIcon } from "../../Icons";
import { LargeCheckbox } from "../../Checkboxes";
import StyledButton from "../../StyledButton";
import { StyledTextField } from "../../TextFields/TextFields";
import { checkTagAvailabilityService } from "services/tags";
import { useAdmin } from "helpers/helpers";
import { InfiniteScrollWrapper } from "components";

export const TagsPopup = ({
  isOpen,
  handleClose,
  tagsList,
  title,
  handleSetTags,
  checkedTags,
  resetTags,
  tagsCount,
  handleFetchTags,
  type,
}) => {
  const classes = useStyles();
  const isAdmin = useAdmin();
  const [tagsToSave, setTagsToSave] = useState([...checkedTags]);
  const [tagInput, setTagInput] = useState("");
  const [customTags, setCustomTags] = useState([]);

  useEffect(() => setTagsToSave(checkedTags), [checkedTags]);

  useEffect(() => {
    const uniqueTags = customTags.filter((t) => {
      return !tagsList.some((item) => item?.id === t?.id);
    });
    setCustomTags([...uniqueTags]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, tagsList]);

  const handleCheckTag = (tag) => {
    const newTags = [...tagsToSave];
    const index = tagsToSave?.findIndex((item) => {
      if (tag?.id) {
        return item?.id === tag?.id || item?.tag?.id === tag?.id;
      }
      return item === tag;
    });
    if (index > -1) {
      newTags.splice(index, 1);
      return setTagsToSave(newTags);
    }
    setTagsToSave([...newTags, tag]);
  };

  const handlePressEnter = async (e) => {
    const val = e.target.value.trim();
    if (e.keyCode === 13 && val !== "") {
      const tagListIndex = tagsList?.findIndex((tag) => {
        return tag?.tag?.toLowerCase() === val?.toLowerCase();
      });
      const customTagsIndex = customTags?.findIndex(
        (tag) => tag?.toLowerCase() === val?.toLowerCase()
      );
      const tagsToSaveIndex = tagsToSave?.findIndex((item) => {
        const t = item?.tag?.tag || item?.tag || item;

        return (
          t?.toLowerCase() === val?.tag?.toLowerCase() ||
          t?.toLowerCase() === val?.toLowerCase()
        );
      });

      let hasTag;

      if (
        (tagListIndex === -1 &&
          customTagsIndex === -1 &&
          tagsToSaveIndex === -1 &&
          type,
        !(tagsCount === tagsList?.length))
      ) {
        hasTag = await checkTagAvailabilityService({ tag: val }, type);
      }

      if (tagListIndex > -1) {
        if (tagsToSaveIndex === -1) {
          setTagsToSave((prev) => [...prev, tagsList[tagListIndex]]);
        } else {
          const tempList = [...tagsToSave];
          tempList.splice(tagsToSaveIndex, 1);
          setTagsToSave(tempList);
        }
        setTagInput("");
        return;
      }

      if (customTagsIndex > -1) {
        if (tagsToSaveIndex === -1) {
          setTagsToSave((prev) => [...prev, customTags[customTagsIndex]]);
        } else {
          const tempList = [...tagsToSave];
          const index = tagsToSave.findIndex((tag) => {
            const t = tag?.tag || tag;
            return t?.toLowerCase() === val?.toLowerCase();
          });
          tempList.splice(index, 1);
          setTagsToSave(tempList);

          const tempCustomList = [...customTags];
          const indexCustom = customTags.findIndex((tag) => {
            const t = tag?.tag || tag;
            return t?.toLowerCase() === val?.toLowerCase();
          });
          tempCustomList.splice(indexCustom, 1);
          setCustomTags(tempCustomList);
        }
        setTagInput("");
        return;
      }

      if (tagsToSaveIndex > -1) {
        const tempList = [...tagsToSave];
        tempList.splice(tagsToSaveIndex, 1);
        setTagsToSave(tempList);
        setTagInput("");
        return;
      }

      const index = customTags?.findIndex(
        (tag) => tag?.toLowerCase() === val?.toLowerCase()
      );
      if (e.keyCode === 13 && val !== "") {
        if (hasTag?.tagWithName) {
          setTagsToSave((prev) => [hasTag?.tagWithName, ...prev]);
          setTagInput("");
          return;
        }
        if (index === -1) {
          setCustomTags([...customTags, val]);
          setTagsToSave([...tagsToSave, val]);
          setTagInput("");
        }
      }
    }
  };

  const textStyles = {
    ".MuiFormControlLabel-label": {
      fontSize: "17px",
      color: "#5F6267",
    },
  };

  return (
    <Dialog open={isOpen}>
      <DialogContent
        sx={{ p: "20px 25px 22px 23px", minWidth: "357px", width: "357px" }}
        data-testid="tags-popup"
      >
        <Grid display="flex" sx={{ flexDirection: "column" }} container>
          <Grid sx={{ position: "absolute", top: 15, right: 11 }} item>
            <IconButton onClick={handleClose}>
              <CrossIcon />
            </IconButton>
          </Grid>
          <Typography
            fontWeight="400"
            fontSize="20px"
            color="#5F6267"
            mb="11px"
          >
            {title}
          </Typography>
          <Grid
            display="flex"
            flexDirection="column"
            maxHeight="168px"
            sx={{ ...textStyles, overflowY: "hidden" }}
          >
            <InfiniteScrollWrapper
              maxHeight="168px"
              dataLength={tagsList?.length}
              hasMore={tagsList?.length < tagsCount}
              loader={
                <CircularProgress sx={{ ml: "50%", mt: "2px" }} size="20px" />
              }
              next={handleFetchTags}
              id="tags-scroll-popup"
            >
              <Stack>
                {customTags?.map((tag) => (
                  <LargeCheckbox
                    key={tag?.tag || tag}
                    label={tag?.tag || tag}
                    onChange={() => handleCheckTag(tag)}
                    checked={tagsToSave?.some(
                      (item) =>
                        item?.tag?.id === tag?.id ||
                        item?.id === tag?.id ||
                        item === tag
                    )}
                    style={{ paddingRight: "16px", paddingLeft: "16px" }}
                  />
                ))}
                {tagsList?.map((tag) => (
                  <LargeCheckbox
                    key={tag?.id}
                    label={tag?.tag}
                    onChange={() => handleCheckTag(tag)}
                    checked={tagsToSave?.some((item) => {
                      return (
                        item?.tag?.id === tag?.id ||
                        item?.id === tag?.id ||
                        item === tag
                      );
                    })}
                    style={{ paddingRight: "16px", paddingLeft: "16px" }}
                  />
                ))}
              </Stack>
            </InfiniteScrollWrapper>
          </Grid>
          <StyledTextField
            size="small"
            formSx={{ mt: "14px" }}
            value={tagInput}
            onChange={(e) => setTagInput(e.target.value)}
            InputProps={{
              className: classes.textInput,
            }}
            InputLabelProps={{
              className: classes.inputLabel,
            }}
            onKeyDown={handlePressEnter}
            label="Type in the new tag and press enter"
          />
          <StyledButton
            disabled={isAdmin || tagsToSave?.length < 1}
            sx={{ height: 40, mt: "11px", mb: "6px" }}
            label={
              <Typography fontSize="15px" fontWeight="normal">
                Set Tags
              </Typography>
            }
            onClick={() => {
              handleSetTags(tagsToSave);
              if (resetTags) setCustomTags([]);
            }}
            variant="contained"
          />
          <StyledButton
            sx={{ height: 40 }}
            color="greyBtn"
            label={
              <Typography fontSize="15px" fontWeight="normal" color="#9C9C94">
                Cancel
              </Typography>
            }
            onClick={handleClose}
            variant="outlined"
          />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

TagsPopup.propTypes = {
  isOpen: bool,
  handleClose: func,
  title: string,
  tagsList: array,
  handleSetTags: func,
  checkedTags: array,
  resetTags: bool,
  tagsCount: number,
  handleFetchTags: func,
  type: oneOf(["product", "customer", "order"]),
};

TagsPopup.defaultProps = {
  isOpen: false,
  title: "",
  tagsList: [],
  checkedTags: [],
  resetTags: false,
  tagsCount: 0,
};

export default TagsPopup;
