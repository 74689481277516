import { Box } from "@mui/material";
import { TableHeader } from "components";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { columnLayoutsSelector } from "redux/selectors/settings";
import { createSelector } from "reselect";

const selector = createSelector(columnLayoutsSelector, (columnLayouts) => ({
  columnLayouts,
}));

export const DraftsHeader = () => {
  const sx = {
    typography: {
      fontSize: 12,
      fontWeight: 400,
      color: "#6A6A6A",
    },
  };

  const { columnLayouts } = useSelector(selector);
  const drafts_tab_disable = useMemo(
    () => [
      ...columnLayouts.drafts.disable,
      {
        id: "Chip",
        name: "Chip",
        disabled: true,
        selected: true,
      },
      {
        id: "Note",
        name: "Note",
        disabled: true,
        selected: true,
      },
    ],
    [columnLayouts.drafts.disable]
  );
  const drafts_tab = columnLayouts.drafts.main;

  const columns = useMemo(() => {
    return [...drafts_tab_disable, ...drafts_tab.filter((col) => col.selected)];
  }, [drafts_tab, drafts_tab_disable]);

  const columnWidth = {
    Order: 4.5,
    Chip: 6.5,
    Note: 2.5,
    Date: 10.5,
    Customer: 22,
    "Created by": 23.7,
    Status: 11.6,
    Total: 8,
    Tags: 11.2,
  };

  const defaultColumnWidth = useMemo(
    () => Object.values(columnWidth).reduce((acc, cur) => (acc += cur), 0),
    // eslint-disable-next-line
    []
  );

  const setColumnWidth = (col) => {
    const columns = columnWidth?.[col?.name]
      ? `${columnWidth?.[col?.name]}%`
      : `${
          (100 - defaultColumnWidth) /
          drafts_tab.filter((col) => col.selected).length
        }%`;
    return columns;
  };

  const setColumnName = (name) => {
    if (name === "Order") return "Draft#";
    if (name === "Chip") return "";

    return name;
  };

  return (
    <TableHeader
      sx={{
        display: "flex",
        backgroundColor: "#EEEEEE",
        borderTop: "none",
      }}
      variant="outlined"
      columns={12}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: `calc(100% - ${22 + 70}px)`,
        }}
      >
        <Box width="22px" minWidth="22px" />
        {columns.map((col) => {
          const valueOfWidth = setColumnWidth(col);
          return (
            <Box
              key={col.id}
              sx={{
                ...sx.typography,
                display: "flex",
                alignItems: "center",
                opacity: col.name === "Note" ? 0 : 1,
                pl: col.name === "Tags" ? "38px" : 0,
                gap: 1,
                width: valueOfWidth,
                maxWidth: valueOfWidth,
                minWidth: valueOfWidth,
                textTransform: "uppercase",
              }}
            >
              {setColumnName(col?.name)}
            </Box>
          );
        })}
        <Box width="70px" minWidth="70px" />
      </Box>
    </TableHeader>
  );
};
