import { Paper } from "@mui/material";
import { Box } from "@mui/system";
import { array, func, bool } from "prop-types";
import { SearchDropDownButtons } from "../../../../../../components/Buttons";
import DiscountCustomerItem from "../DiscountCustomerItem/DiscountCustomerItem";

const CustomersDropDown = ({
  customersList,
  handleCheckCustomer,
  checkedCustomers,
  formCustomers,
  onCancel,
  onAdd,
  allowMissingInfo,
}) => {
  return (
    <Paper
      sx={{
        position: "absolute",
        zIndex: 10,
        width: "100%",
        left: 0,
        boxShadow: "none",
        border: "0.5px solid #D5D9D9",
      }}
    >
      <Box
        sx={{
          maxHeight: "130px",
          overflow: "overlay",
        }}
      >
        {customersList.map((customer) => (
          <DiscountCustomerItem
            key={customer.id}
            handleCheckItem={handleCheckCustomer}
            item={customer}
            isChecked={checkedCustomers.some((cust) => cust.id === customer.id)}
            disabled={formCustomers?.some((cust) => cust.id === customer.id)}
            allowMissingInfo={allowMissingInfo}
          />
        ))}
      </Box>
      <SearchDropDownButtons onCancel={onCancel} onAdd={onAdd} />
    </Paper>
  );
};

CustomersDropDown.propTypes = {
  checkedCustomers: array,
  handleCheckCustomer: func,
  customersList: array,
  formCustomers: array,
  onCancel: func,
  onAdd: func,
  allowMissingInfo: bool,
};

CustomersDropDown.defaultProps = {
  checkedCustomers: [],
  customersList: [],
  formCustomers: [],
};

export default CustomersDropDown;
