import {} from "react";
import { string } from "prop-types";
import { Box, Typography } from "@mui/material";
import { normalizeSnakeCaseString } from "../../../../../../../../helpers/helpers";

export const DeliveryStatusBlock = ({ deliveryStatus }) => {
  return (
    <Box display="flex">
      <Box display="flex" alignItems="center">
        <Typography fontSize={12} color="#000000" mr={1}>
          Delivery Status
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Box
          sx={{
            height: "6px",
            width: "6px",
            backgroundColor:
              deliveryStatus === "UNFULFILLED" ? "#F0C401" : "#47A06D",
            borderRadius: "50%",
            mr: "8px",
          }}
        />
        <Typography sx={{ fontSize: 12, fontWeight: 400, color: "#1C1C19" }}>
          {normalizeSnakeCaseString(deliveryStatus)}
        </Typography>
      </Box>
    </Box>
  );
};

DeliveryStatusBlock.propTypes = {
  deliveryStatus: string,
};
DeliveryStatusBlock.defaultProps = {};
