import { app } from "firebase/Chat/config";
import { deleteDoc, doc, getFirestore, onSnapshot } from "firebase/firestore";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import {
  endLoadingOrders,
  startLoadingOrders,
  existOrdersThirdParty,
  getOrdersThirdPartyList,
  getOrdersThirdPartyCount,
} from "../../redux/actions/orders";
import {
  ordersExistThirdPartySelector,
  ordersGetParamsThirdPartySelector,
  ordersThirdPartyCountSelector,
  ordersThirdPartyListSelector,
} from "../../redux/selectors/orders";
import { getOrdersService } from "../../services/orders";
import { SCROLL_LIMIT } from "../../utils/constants";
import { error } from "../../utils/notifications";

const selector = createSelector(
  ordersThirdPartyListSelector,
  ordersThirdPartyCountSelector,
  ordersExistThirdPartySelector,
  ordersGetParamsThirdPartySelector,
  (
    ordersThirdPartyList,
    ordersThirdPartyCount,
    ordersExistThirdParty,
    paramsThirdParty
  ) => ({
    ordersThirdPartyList,
    ordersThirdPartyCount,
    ordersExistThirdParty,
    paramsThirdParty,
  })
);

export const useThirdPartyOrdersTabActions = () => {
  const { ordersThirdPartyList, ordersThirdPartyCount, ordersExistThirdParty } =
    useSelector(selector);
  const dispatch = useDispatch();
  const firestore = getFirestore(app);
  const { id: currentUserId } = useSelector(
    ({ auth }) => auth.currentUser || {}
  );
  const [thirdPartyOrdersCount, setThirdPartyOrdersCount] = useState({
    count: 0,
  });

  const fetchThirdPartyOrdersCount = useCallback(async () => {
    await onSnapshot(
      doc(firestore, "count-third-party-orders", currentUserId),
      (doc) => {
        setThirdPartyOrdersCount(doc.data() || { count: 0 });
      }
    );
  }, [currentUserId, firestore]);

  useEffect(() => {
    fetchThirdPartyOrdersCount();
  }, [fetchThirdPartyOrdersCount]);

  const deleteThirdPartyOrdersCount = useCallback(async () => {
    try {
      const documentRef = doc(
        firestore,
        "count-third-party-orders",
        currentUserId
      );
      await deleteDoc(documentRef);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error deleting document: ", error);
    }
  }, [firestore, currentUserId]);

  const [page, setPage] = useState(1);

  const handleFetchOrdersThirdParty = (params, isScrolling = false) => {
    dispatch(startLoadingOrders());
    getOrdersService(
      params
        ? params
        : {
            type: "THIRD_PARTY",
            limit: SCROLL_LIMIT,
            page: page + 1,
            sort_by_order_date: "desc",
          }
    )
      .then((res) => {
        dispatch(existOrdersThirdParty(res.existData));
        dispatch(
          getOrdersThirdPartyList(
            params && !isScrolling
              ? [...res.rows]
              : [...ordersThirdPartyList, ...res.rows]
          )
        );
        dispatch(getOrdersThirdPartyCount(res.count));
        if (!params) setPage((prev) => prev + 1);
      })
      .catch((err) => {
        error(err?.response?.data?.message || err?.message);
        // eslint-disable-next-line no-console
        console.error(err?.message);
      })
      .finally(() => {
        dispatch(endLoadingOrders());
      });
  };

  return {
    ordersThirdPartyList,
    ordersThirdPartyCount,
    ordersExistThirdParty,
    handleFetchOrdersThirdParty,
    thirdPartyOrdersCount: thirdPartyOrdersCount.count,
    deleteThirdPartyOrdersCount,
    setPage,
    page,
  };
};
