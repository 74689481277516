import { useState } from "react";
import { object, func } from "prop-types";
import { useWatch } from "react-hook-form";
import { Box } from "@mui/material";
import { TagField, TagsDrawer } from "components";
import { TAG_TYPES } from "helpers/useTagsActions";

export const TagsBlock = ({
  control,
  setValue,
  errors,
  setError,
  clearErrors,
}) => {
  const formField = useWatch({ control });

  const [viewAllTags, setViewAllTags] = useState(false);

  return (
    <Box mt="17px">
      <TagsDrawer
        type={TAG_TYPES.order}
        open={viewAllTags}
        handleClose={() => setViewAllTags(false)}
        orderTags={formField.orderTags}
        handleSave={({ tags }) => {
          setValue("orderTags", tags);
          setViewAllTags(false);
        }}
        setCheckedCustomers={() => {}}
      />
      <TagField
        fieldName="orderTags"
        placeholder="Order tag"
        setValue={setValue}
        errors={errors}
        setError={setError}
        clearErrors={clearErrors}
        chosenTags={formField.orderTags}
        handleViewAll={() => setViewAllTags(true)}
        type="order"
      />
    </Box>
  );
};

TagsBlock.propTypes = {
  control: object,
  setValue: func,
  errors: object,
  setError: func,
  clearErrors: func,
};
