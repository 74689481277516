import { Box } from "@mui/material";
import { StyledTooltip } from "components";

export const PulseBlob = () => {
  return (
    <StyledTooltip title="Checked In" placement="top" arrow>
      <Box p="6px">
        <Box
          sx={{
            background: "#42A57F",
            borderRadius: "50%",
            height: "6px",
            width: "6px",

            boxShadow: "0 0 0 0 rgba(0, 0, 0, 1)",
            transform: "scale(1)",
            animation: "pulse 2s infinite",

            "@keyframes pulse": {
              "0%": {
                transform: "scale(0.95)",
                boxShadow: "0 0 0 0 rgba(66, 165, 127, 0.7)",
              },

              "70%": {
                transform: "scale(1)",
                boxShadow: "0 0 0 5px rgba(66, 165, 127, 0)",
              },

              "100%": {
                transform: "scale(0.95)",
                boxShadow: "0 0 0 0 rgba(66, 165, 127, 0)",
              },
            },
          }}
        />
      </Box>
    </StyledTooltip>
  );
};
