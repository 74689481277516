import { bool, func } from "prop-types";
import { Box, Grid, IconButton, Paper, Typography } from "@mui/material";

import { LargeCheckbox } from "../../../../../../components/Checkboxes";
import StyledTooltip from "../../../../../../components/StyledTooltip";
import { QuestionIcon } from "../../../../../../components/Icons";

export const VariationsTabHeaderList = ({
  checkAllProducts,
  allChecked,
  colorColumn,
  sizeColumn,
  isEdit,
}) => {
  const labels = [
    {
      id: 1,
      name: (
        <LargeCheckbox
          adminIsAllowed
          formSx={{ marginX: 0 }}
          size={22}
          checked={allChecked}
          onChange={checkAllProducts}
          sx={{ p: 0, ml: isEdit ? 0.9 : 0.8 }}
        />
      ),
      size: 2.05,
      position: "center",
      visible: true,
    },
    { id: 1.1, name: "", position: "center", visible: true },
    {
      id: 2,
      name: "IMAGE",
      size: 2.05,
      position: "center",
      visible: true,
      styles: {
        paddingRight: 1.5,
      },
    },
    {
      id: 3,
      name: "SKU",
      size: colorColumn || sizeColumn ? 4.5 : 7,
      // size: true,
      position: "flex-start",
      visible: true,
      styles: {
        // paddingLeft: 1,
      },
    },
    {
      id: 4,
      name: "COLOR",
      size: colorColumn ? 4.5 : 0,
      position: "flex-start",
      visible: colorColumn,
      styles: {
        // paddingLeft: 1,
      },
    },
    {
      id: 5,
      name: "SIZE",
      size: sizeColumn ? 4.5 : 0,
      position: "flex-start",
      visible: sizeColumn,
    },
    {
      id: 6,
      name: "BARCODE",
      // size: 5,
      size: colorColumn || sizeColumn ? 5 : 7,
      position: "flex-start",
      visible: true,
    },
    {
      id: 8,
      name: "ITEMS PER CASE",
      size: 3.6,
      position: "center",
      visible: true,
    },
    {
      id: 9,
      name: (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          WHOLESALE
          <StyledTooltip
            title={"Wholesale price for customers."}
            placement="top"
            arrow
          >
            <IconButton>
              <QuestionIcon />
            </IconButton>
          </StyledTooltip>
        </Box>
      ),
      size: 3.8,
      position: "flex-start",
      visible: true,
    },
    {
      id: 10,
      name: (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          RETAIL
          <StyledTooltip
            title={
              "Customers will  see this. Suggested retail  price for customers."
            }
            placement="top"
            arrow
          >
            <IconButton>
              <QuestionIcon />
            </IconButton>
          </StyledTooltip>
        </Box>
      ),
      size: 4,
      position: "flex-start",
      visible: true,
    },
    // { id: 11, name: "", size: 0.85, position: "center", visible: false },
    // {
    //   id: 11,
    //   name: (
    //     <Box
    //       sx={{
    //         display: "flex",
    //         alignItems: "center",
    //       }}
    //     >
    //       <StyledTooltip
    //         title={
    //           "Purchase orders & receiving only. Customers will not see this."
    //         }
    //         placement="top"
    //         arrow
    //       >
    //         <IconButton>
    //           <QuestionIcon />
    //         </IconButton>
    //       </StyledTooltip>
    //       DISTRIBUTOR PRICE
    //     </Box>
    //   ),
    //   size: 3.6,
    //   position: "flex-start",
    //   visible: true,
    // },

    // { id: 12, name: "trash", size: 0.2, position: "center", visible: false },
  ];

  return (
    <Paper
      sx={{
        backgroundColor: "#EEEEEE",
        alignItems: "center",
        border: "1px solid #D5D9D9",
        borderRadius: "4px 4px 0 0",
      }}
      elevation={0}
      square
      component={Grid}
      columns={30.8}
      container
      height="39px"
    >
      {labels.map((el) => (
        <Grid
          key={el.id}
          sx={{
            display: el.hideColumn ? "none" : "flex",
            justifyContent: el.position,
            alignItems: "center",
            ...el.styles,
          }}
          xs={!el.width && el.size && el.size}
          width={el.width}
          item
        >
          <Typography variant="header">{el.visible ? el.name : ""}</Typography>
        </Grid>
      ))}
    </Paper>
  );
};
VariationsTabHeaderList.propTypes = {
  checkAllProducts: func,
  allChecked: bool,
  colorColumn: bool,
  sizeColumn: bool,
  isEdit: bool,
};
VariationsTabHeaderList.defaultProps = {
  allChecked: false,
  colorColumn: false,
  sizeColumn: false,
};

export default VariationsTabHeaderList;
