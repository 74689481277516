/* eslint-disable react/prop-types */
import React from "react";

export const IndeterminateCheckboxIcon = ({ size = 21, color = "#fff" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 21 21"
    >
      <rect
        data-name="Rectangle 17022"
        width={size}
        height={size}
        rx="4"
        fill={color}
      />
      <rect
        data-name="Rectangle 17023"
        width="14"
        height="2"
        rx="1"
        transform="translate(3.608 9.8)"
        fill="#47a06d"
      />
    </svg>
  );
};
