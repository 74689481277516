import { shape, bool, object, func } from "prop-types";
import { Box, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { LargeCheckbox } from "../../../../../../components";

export const RestockBlock = ({
  control,
  restockRef,
  setPopperType,
  restockData,
}) => {
  const { isTotalDelivery, isRestockDisable } = restockData || {};

  return (
    <Box
      my="6px"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      {isTotalDelivery ? null : (
        <>
          <Box
            ref={restockRef}
            onMouseEnter={() => {
              if (isRestockDisable) setPopperType("restock");
            }}
            onMouseLeave={() => setPopperType("")}
          >
            <Controller
              render={({ field }) => (
                <LargeCheckbox
                  formSx={{ mr: 0, flex: "1 1 auto", ml: "-9px" }}
                  checked={!!field.value}
                  disabled={isRestockDisable}
                  label={
                    <Typography
                      ml="-4px"
                      fontSize={12}
                      fontWeight={400}
                      color={isRestockDisable ? "#B9B9B9" : "#000"}
                    >
                      Restock
                    </Typography>
                  }
                  size={16}
                  {...field}
                />
              )}
              name="restock"
              control={control}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

RestockBlock.propTypes = {
  control: object,
  restockRef: object,
  setPopperType: func,
  restockData: shape({
    isTotalDelivery: bool,
    isRestockDisable: bool,
  }),
};
RestockBlock.defaultProps = {
  control: {},
  restockRef: {},
  setPopperType: () => {},
  restockData: {
    isTotalDelivery: false,
    isRestockDisable: false,
  },
};
