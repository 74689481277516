import {} from "react";
import { object, shape, oneOf, func } from "prop-types";
import { Box, Typography } from "@mui/material";
import { DELIVERY_METHODS } from "../../../../helpers";

import { LocalDeliveryBlock, ShippingCarrierBlock } from "./components";

export const ShippingBlock = ({
  state,
  shippingData,
  control,
  setValue,
  deliveryMethodRef,
  setPopperType,
}) => {
  const { deliveryMethod } = shippingData || {};

  return (
    <Box display="flex" flexDirection="column">
      <Box
        sx={{
          display: "flex",
          border: "0.5px solid #D5D9D9",
          borderRadius: "4px 4px 0 0",
          width: "100%",
          height: "39px",
          alignItems: "center",
          paddingLeft: "20px",
          backgroundColor: "#EEEEEE",
        }}
      >
        <Typography fontSize={12} color="#000000">
          RETURN SHIPPING OPTIONS
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          borderColor: "#D5D9D9",
          borderStyle: "solid",
          borderWidth: "0px 0.5px 0.5px 0.5px ",
          borderRadius: "0 0 4px 4px",
          padding: "14px 20px 20px 20px",
        }}
      >
        <LocalDeliveryBlock
          {...{
            control,
            shippingData,
            state,
            setValue,
            deliveryMethodRef,
            setPopperType,
          }}
        />

        {deliveryMethod === DELIVERY_METHODS[0] && (
          <ShippingCarrierBlock {...{ control, state }} />
        )}
        {/* <Box mt={1} width="100%" /> */}
      </Box>
    </Box>
  );
};

ShippingBlock.propTypes = {
  control: object,
  setValue: func,
  state: object,
  shippingData: shape({
    deliveryMethod: oneOf([...DELIVERY_METHODS]),
  }),
  deliveryMethodRef: object,
  setPopperType: func,
};
ShippingBlock.defaultProps = {
  shippingData: {
    deliveryMethod: "",
  },
};
