import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { updateCustomerGroupTerritoryAction } from "redux/actions/customers";
import {
  getParentCustomersService,
  updateCustomerService,
} from "services/customers";
import { SCROLL_LIMIT_CUSTOMERS } from "utils/constants";
import { error, success } from "utils/notifications";

const initData = {
  customer: null,
  customers: null,
  loading: false,
  list: [],
  count: 0,
};

export const useGroupDialogActions = ({ handleFetchCustomers }) => {
  const dispatch = useDispatch();

  const [groupData, setGroupData] = useState(initData);

  const [params, setParams] = useState({
    limit: SCROLL_LIMIT_CUSTOMERS,
    page: 1,
    include_uncategorized: true,
    with_candidates: false,
  });

  const isAllowedToFetch = useMemo(
    () => !!groupData?.customer?.id || !!groupData?.customers?.length,
    [groupData?.customer?.id, groupData?.customers?.length]
  );

  const handleFetch = async (page) => {
    try {
      const res = await getParentCustomersService({
        ...params,
        page: page ? page : 1,
      });
      const { rows = [], count = 0 } = res || {};

      setGroupData((prev) => ({
        ...prev,
        list: page ? [...prev.list, ...rows] : [...rows],
        count: count,
      }));

      setParams((prev) => ({ ...prev, page: page ? page : 1 }));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      error(err?.response?.data?.message || "Something went wrong.");
    } finally {
      setGroupData((prev) => ({ ...prev, loading: false }));
    }
  };

  const handleOpenGroupDialog = ({ customer, customers }) => {
    if (customer && !customers) {
      setGroupData((prev) => ({ ...prev, customer }));
    }

    if (!customer && customers) {
      setGroupData((prev) => ({ ...prev, customers }));
    }
  };
  const handleCloseGroupDialog = () => {
    setGroupData(initData);
  };

  useEffect(() => {
    if (!isAllowedToFetch) return;
    handleFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAllowedToFetch]);

  const handleSaveParentGroup = async (
    parentCustomerData,
    callback,
    actionFetchParams,
    actionGroupFetchParams
  ) => {
    const { parentCustomerId, billToParentCustomer } = parentCustomerData;
    try {
      setGroupData((prev) => ({ ...prev, loading: true }));
      if (groupData?.customer?.id && !groupData?.customers?.length) {
        await updateCustomerService(
          { parentCustomerId, billToParentCustomer },
          groupData?.customer?.id
        );

        success("Customer(s) updated");
        handleFetchCustomers();
      }

      if (!groupData?.customer?.id && groupData?.customers?.length) {
        const preparedData = {
          customerIds: groupData?.customers.map((el) => el?.id),
          parentCustomerId,
          billToParentCustomer,
          narrowedFilter: {
            includeParentCustomers: false,
          },
        };

        dispatch(
          updateCustomerGroupTerritoryAction(
            preparedData,
            {},
            callback,
            null,
            actionFetchParams,
            actionGroupFetchParams
          )
        );
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      error(err?.response?.data?.message || "Something went wrong.");
    } finally {
      handleCloseGroupDialog();
    }
  };

  return {
    groupData: { ...groupData, params },
    handleOpenGroupDialog,
    handleCloseGroupDialog,
    handleFetch,
    handleSaveParentGroup,
  };
};
