import { Typography, styled } from "@mui/material";
import { makeStyles } from "@mui/styles";
import theme from "theme";

const useStyles = makeStyles(() => ({
  tooltipWrapper: {
    position: "absolute",
    // transform: "translate(calc(-95% + 31px), calc(-100% + 10px))",
    transform: "translate(calc(-50% + 18px), calc(-100% + 10px))",
    textAlign: "left",
    alignItems: "left",
    zIndex: "1000",
    left: ({ left }) => left || "50%",
    top: ({ top }) => top || "50%",
  },

  bodyWrapper: {
    display: "flex",
    gap: "9px",
    overflow: "hidden",
    padding: "5px 9px 6px 8px",
  },

  checkInItem: {
    display: "flex",
    height: "46px",
    justifyContent: "space-between",
    alignItems: "center",
    "&:not(:last-of-type)": {
      borderBottom: "0.5px solid #D5D9D9",
    },
  },

  assignedAvatar: {
    marginRight: "-6px",
    width: "25px",
    height: "25px",
    border: "0.5px solid #fff",
    transition: "all 0.3s ease",
    display: "flex!important",
    "&:hover": {
      zIndex: 100,
    },
  },

  tooltipTail: {
    position: "absolute",
    right: "50%",
    transform: "translate(50%, -15px)",

    "&.background": {
      width: "25.3px",
      height: "17px",
      bottom: "-16px",
      backgroundColor: "#D5D9D9",
      clipPath: "polygon(100% 7%, 0% 7%, 50% 100%)",
    },
    "&.foreground": {
      width: "25px",
      height: "16px",
      bottom: "-15px",
      backgroundColor: "#ffffff",
      clipPath: "polygon(100% 0, 0 0, 50% 100%)",
    },
  },

  routesTitle: {
    fontSize: 13,
    color: "#5F6267",
    cursor: "pointer",
    textDecoration: ({ handleOpenRoutesDrawer }) =>
      handleOpenRoutesDrawer && "underline",
  },

  sliderWrapper: {
    width: "58px",
    marginRight: ({ moreThanOne }) => moreThanOne && "5px",
    "& .slick-arrow::before": {
      content: '""',
    },

    "& .slick-arrow.slick-prev": {
      left: "-8px",
    },

    "& .slick-track": {
      marginRight: 5,
      "& .slick-slide": {
        height: "25px",
        width: "25px!important",
        marginRight: "-8px",
        transition: "all 0.3s ease",
        "&:not(.slick-active)": {
          opacity: 0,
        },
      },
    },
  },
}));

const ProspectTooltip = styled("div")({
  position: "absolute",
  // transform: "translate(calc(-95% + 31px), calc(-100% + 10px))",
  transform: "translate(-50%, -100%)",
  left: "50%",
  textAlign: "left",
  alignItems: "left",
  zIndex: "1000",
});

const ProspectTooltipBody = styled("div")(({ coloring }) => ({
  background:
    coloring === "dark" ? theme.palette.edit.main : theme.palette.common.white,
  borderRadius: "8px",
  padding: "7px 16px 8px 12px",
  overflow: "hidden",
}));

const ProspectTitle = styled(Typography)(({ coloring }) => ({
  color:
    coloring === "dark"
      ? theme.palette.common.white
      : theme.palette.common.black,
  fontWeight: 700,
}));

const ProspectDetails = styled("div")(({ coloring }) => ({
  display: "flex",
  alignItems: "center",
  color: coloring === "dark" ? "#D4D4D4" : "#A8A8A8",
  gap: "7px",
  fontSize: "13px",
}));

const ProspectRating = styled("div")(({ coloring }) => ({
  color:
    coloring === "dark" ? theme.palette.common.white : theme.palette.edit.main,
  fontSize: "13px",
  display: "flex",
  alignItems: "center",
  gap: "8px",
}));

const ProspectPrice = styled(Typography)(({ coloring }) => ({
  color: coloring === "dark" ? "#D4D4D4" : "#A8A8A8",
  fontSize: "13px",
}));

const ProspectAddress = styled(Typography)(({ coloring }) => ({
  color:
    coloring === "dark" ? theme.palette.common.white : theme.palette.edit.main,
  fontSize: "13px",
  maxWidth: "100%",
  overflow: "hidden",
}));

const TooltipArrow = styled("div")({
  position: "absolute",
  transform: "translate(-50%, -50%) rotate(45deg)",
  zIndex: 1,
  left: "50%",
  width: "15px",
  height: "15px",
  background: theme.palette.edit.main,
  border: `0.5px solid`,
  borderColor: `transparent ${theme.palette.border.main} ${theme.palette.border.main} transparent`,
  borderRadius: "20px 0px 2px 0px",
  transition: "all 0.5s ease",
});

export {
  ProspectTooltip,
  ProspectTooltipBody,
  ProspectTitle,
  ProspectDetails,
  ProspectRating,
  ProspectPrice,
  ProspectAddress,
  TooltipArrow,
};

export default useStyles;
