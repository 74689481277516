import { Grid, IconButton, Paper, Typography } from "@mui/material";
import { CartTrashIcon } from "../../../../components/Icons";
import { func, string, bool } from "prop-types";
import { useAdmin } from "helpers/helpers";

const ProductsHeader = ({
  handleDeleteAllProducts,
  formDiscountType,
  orderCompleted,
  deliveryStatus,
  hideAvailable,
}) => {
  const PL_PRICE = 8;
  const PL_TOTAL = 8;
  const isAdmin = useAdmin();
  const classes = {
    grid: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    typography: {
      fontSize: "11px",
      fontWeight: 400,
      color: "#1A1A1A",
      textTransform: "uppercase",
    },
  };
  return (
    <Paper
      sx={{
        backgroundColor: "#F8F8F8",
        borderRadius: "0",
        borderLeft: "none",
        borderRight: "none",
      }}
      elevation={0}
      variant="outlined"
      square
      component={Grid}
      container
      columns={21}
      height="48px"
    >
      <Grid item xs={0.6} sx={classes.grid} />
      <Grid
        item
        xs={formDiscountType === "item" ? 7.8 : 10}
        sx={{ ...classes.grid, justifyContent: "flex-start" }}
      >
        <Typography sx={classes.typography}>Product</Typography>
      </Grid>
      <Grid
        item
        xs={1.5}
        sx={{ ...classes.grid, justifyContent: "flex-start" }}
      >
        {!hideAvailable && (
          <Typography sx={classes.typography}>Available</Typography>
        )}
      </Grid>
      <Grid item xs={formDiscountType === "item" ? 2.3 : 3} sx={classes.grid}>
        <Typography sx={classes.typography}>Quantity</Typography>
      </Grid>
      <Grid
        item
        xs={2.2}
        sx={{ ...classes.grid, justifyContent: "flex-start" }}
      >
        <Typography sx={{ ...classes.typography, pl: `${PL_PRICE}px` }}>
          Price
        </Typography>
      </Grid>
      {formDiscountType === "item" && (
        <Grid
          item
          xs={3}
          sx={{ ...classes.grid, justifyContent: "flex-start" }}
        >
          <Typography sx={classes.typography}>Discount</Typography>
        </Grid>
      )}
      <Grid
        item
        xs={2.6}
        sx={{ ...classes.grid, justifyContent: "flex-start" }}
      >
        <Typography sx={{ ...classes.typography, pl: `${PL_TOTAL}px` }}>
          Total
        </Typography>
      </Grid>
      <Grid
        item
        xs={1}
        sx={{ ...classes.grid, justifyContent: "flex-end", pr: "8px" }}
      >
        {!orderCompleted &&
          (!deliveryStatus || deliveryStatus === "UNFULFILLED") && (
            <IconButton disabled={isAdmin} onClick={handleDeleteAllProducts}>
              <CartTrashIcon />
            </IconButton>
          )}
      </Grid>
    </Paper>
  );
};

ProductsHeader.propTypes = {
  handleDeleteAllProducts: func,
  formDiscountType: string,
  orderCompleted: bool,
  deliveryStatus: string,
  hideAvailable: bool,
};

export default ProductsHeader;
