import { string, object } from "prop-types";
import { Box } from "@mui/material";
import { DeletedIcon, ExpiredIcon, PrimaryIcon } from "../Icons";
import { ProspectIcon } from "components/Icons/LabelIcons";

const defaultStyle = {
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
};

export const StyledLabel = ({ text, customWidth, style, fill, ...props }) => {
  const setSrc = (text) => {
    switch (text) {
      case "expired":
        return <ExpiredIcon />;
      case "archived":
      case "deleted":
        return <DeletedIcon text={text} customWidth={customWidth} />;
      case "primary":
        return <PrimaryIcon fill={fill} />;

      case "prospect":
        return <ProspectIcon />;

      default:
        return <PrimaryIcon />;
    }
  };

  return (
    <Box
      sx={{
        ...defaultStyle,
        ...style,
      }}
      component="span"
      {...props}
    >
      {setSrc(text)}
    </Box>
  );
};

StyledLabel.propTypes = {
  text: string,
  style: object,
  fill: string,
  customWidth: string,
};
StyledLabel.defaultProps = {
  text: "primary",
};

export default StyledLabel;
