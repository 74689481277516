import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  dialogTitle: {
    borderBottom: "1px solid #d5d9d9",
    fontSize: "20px",
    fontWeight: 400,
    padding: "17px 8px 12px 28px",
    display: "flex",
    justifyContent: "space-between",
  },

  dialogContent: { padding: "20px 34px 0 !important", overflow: "hidden" },

  dialogActions: { padding: "18px 34px 24px" },
}));

export default useStyles;
