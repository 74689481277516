export const cl = {
  savedFilterMenuPaper: {
    mt: "6px",
    borderRadius: "8px",
    border: "1px solid #D5D9D9",
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
  },

  savedFilterMenuList: {
    width: "344px",
    maxHeight: "500px",
    overflow: "auto",
    pt: 0,
    pb: 0,
    scrollbarWidth: "6px",
    scrollbarColor: "rgba(0, 0, 0, 0.35) rgba(0, 0, 0, 0.1)",

    "&::-webkit-scrollbar": {
      width: "6px",
      borderRadius: "7px",
      backgroundColor: "rgba(0, 0, 0, 0.1)",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "7px",
      backgroundColor: "rgba(0, 0, 0, 0.35)",
    },
  },

  headerWrapper: {
    p: "18px 24px 14px",
    borderBottom: "0.5px solid #CCCCCC",
  },

  filterList: {
    p: "6px",
    display: "flex",
    gap: "6px",
    flexDirection: "column",
  },
};
