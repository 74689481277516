import { string, bool } from "prop-types";
import { Box, Divider, Link, Typography } from "@mui/material";
import { EmailIcon, PhoneIcon } from "components/Icons";
import { setPhoneNumberMask } from "helpers/helpers";

export const ContactUsBar = ({ text, email, phone, left, rawPhone }) => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: left || "50vw",
        transform: "translateX(-50%)",
        backgroundColor: "#F8F8F8",
        borderRadius: "0 0 23px 23px",
        padding: "0 25px",
        height: "32px",
        display: "flex",
        alignItems: "center",
        zIndex: "10000",
        "& .MuiTypography-root": {
          fontSize: "14px",
        },
        gap: "7px",
      }}
    >
      <Box>
        <Typography whiteSpace="nowrap">{text}</Typography>
      </Box>
      <Divider orientation="vertical" sx={{ maxHeight: "15px" }} />
      <Box
        sx={{
          display: "flex",
          gap: "7px",
          alignItems: "center",
          "& .MuiTypography-root": {
            color: "#707070",
          },
        }}
        component={Link}
        underline="none"
        href={`mailto:${email || "#"}`}
      >
        <EmailIcon width={15.83} height={10.69} />
        <Typography>{email}</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "7px",
          alignItems: "center",
          "& .MuiTypography-root": {
            color: "#707070",
          },
        }}
        component={Link}
        underline="none"
        href={`tel:${phone || "#"}`}
      >
        <PhoneIcon
          style={{ transform: "rotate(90deg)" }}
          width={10.69}
          height={10.69}
        />
        <Typography whiteSpace="nowrap">
          {rawPhone ? phone : setPhoneNumberMask(phone)}
        </Typography>
      </Box>
    </Box>
  );
};

ContactUsBar.propTypes = {
  text: string,
  email: string,
  phone: string,
  left: string,
  rawPhone: bool,
};
ContactUsBar.defaultProps = {
  text: "Need Help?",
  email: "",
  phone: "",
  rawPhone: true,
};
