import { array, bool } from "prop-types";
import { TotalSummaryInfoItem } from "./TotalSummaryInfoItem";

export const TotalSummaryInfoBlock = ({ items, isLoading }) => {
  return items?.map((item) => (
    <TotalSummaryInfoItem
      key={item.name}
      name={item?.name}
      value={item?.value}
      isLoading={isLoading}
      valueStyles={item?.valueStyles}
    />
  ));
};

TotalSummaryInfoBlock.propTypes = { items: array, isLoading: bool };
TotalSummaryInfoBlock.defaultProps = { items: [] };
