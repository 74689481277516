import { Grid, Typography } from "@mui/material";
import TableHeader from "components/TableHeader";

export const HeaderBlock = () => {
  return (
    <TableHeader sx={{ borderRadius: "4px 4px 0 0", mt: "24px" }}>
      <Grid container>
        <Grid item xs={13.6}>
          <Typography
            sx={{
              my: "6px",
              ml: "48px",
              fontSize: "12px",
              fontWeight: 400,
              color: "#6A6A6A",
              textTransform: "uppercase",
              overflow: "auto",
            }}
          >
            Tags
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            sx={{
              my: "6px",
              fontSize: "12px",
              fontWeight: 400,
              color: "#6A6A6A",
              textTransform: "uppercase",
              overflow: "auto",
            }}
          >
            Used
          </Typography>
        </Grid>
      </Grid>
    </TableHeader>
  );
};
