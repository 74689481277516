export const FILTER_DATES_REPORT = [
  { name: "Today" },
  { name: "Yesterday" },
  { name: "This week" },
  { name: "Last week" },
  { name: "This month" },
  { name: "Last month" },
  { name: "This year" },
  { name: "Last year" },
];

export const defaultValues = {
  date: "Today",
  withoutCents: false,
  redNegative: false,
  active: false,
  showProspects: false,
  groupByIds: [""],
  itemsCount: 0,
  itemsList: [],
  category: [{ id: "all", name: "All" }],
  archivedOrders: false,
};
