import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((isPreviewValid) => ({
  dialog: {
    maxWidth: "none",
    maxHeight: "calc(100% - 30px)",
  },
  formBox: {
    display: isPreviewValid ? "flex" : "unset",
    flexDirection: "column",
    justifyContent: "space-between",
    minWidth: "470px",
    maxWidth: "498px",
  },
  titleText: {
    fontSize: "17px",
    color: "#3F3F3F",
  },

  rowTitle: {
    fontSize: "11px",
    color: "#363531",
    marginTop: "0px",
  },

  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #d5d9d9",
    alignItems: "center",
    padding: "10px 22px 12px 32px",
  },

  orderTitleWrapper: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
  },

  recipientsTable: {
    height: "100%",
    zIndex: "10",
    border: "1px solid #D5D9D9",
    width: "100%",
    borderTop: "none",
    backgroundColor: "#ffffff",
    overflow: "overlay",
    borderRadius: "0 0 4px 4px",
  },

  recipientChipWrapper: {
    maxHeight: "75px",
    overflow: "auto",
    maxWidth: "364px",
  },

  recipientItem: {
    width: "100%",
    cursor: "pointer",
    height: "45px",
    "&:not(:last-of-type)": {
      borderBottom: "1px solid #D5D9D9",
    },
    alignItems: "center",
    // paddingLeft: "13px",
    "& .MuiTypography-root": {
      color: "#1C1C19",
      fontSize: "12px",
    },
  },

  rowWrap: {
    display: "flex",
  },

  textfield: {
    color: "#ff0000",
    "& input::placeholder, & textarea::placeholder": {
      color: "#BEBEBE",
      opacity: 1,
      fontWeight: 400,
    },
  },
}));

export default useStyles;
