import { useCallback, useMemo, useRef, useState } from "react";
import { shape, string, bool, func, object } from "prop-types";
import moment from "moment";
import { useSelector } from "react-redux";
import { useNavigate, Link as NavLink, useLocation } from "react-router-dom";
import { createSelector } from "reselect";
import pluralize from "pluralize";
import {
  Box,
  Chip,
  Divider,
  IconButton,
  ListItemText,
  MenuItem,
  Typography,
} from "@mui/material";
import {
  LargeCheckbox,
  StyledTooltip,
  ThreeDotButton,
  StyledMenu,
} from "components";
import {
  getDateExpiration,
  getFormattedDate,
  useAdmin,
  separateNumWithComma,
  formatDate,
  getCreatedOrderBy,
} from "helpers/helpers";
import {
  InformationIcon,
  PaperIcon,
  SendIcon,
  SimpleArrowDownIcon,
} from "components/Icons";
import { cl } from "../../../DirectOrdersTab/components/OrdersTable/styles";
import { columnLayoutsSelector } from "redux/selectors/settings";
import {
  CustomerMenu,
  PrintedBlock,
  TagsMenu,
} from "Pages/OrdersPage/components/DirectOrdersTab/components/OrdersTable/components";

const selector = createSelector(columnLayoutsSelector, (columnLayouts) => ({
  columnLayouts,
}));
const columnWidth = {
  Note: 3,
  Date: 12,
  Customer: 24,
  "Assigned to": 12,
  "Created by": 12,
  Total: 10,
  Emailed: 10,
  Tags: 22,
};

const CHECKBOX_WIDTH = 52;
const ORDER_WIDTH = 70;
const ACTION_WIDTH = 120;

export const ItemThirdParty = ({
  order,
  isChecked,
  currentTab,
  handleCheckOrder,
  handleChoseMenuItem,
  handleOpenEmail,
  handleConfirmCheckedItemsDialog,
  isQuickBooksConnected,
  repPermissions,
}) => {
  const isAdmin = useAdmin();

  const disabledRepPermissions = useMemo(() => {
    return !!repPermissions && !repPermissions?.orders?.create_edit;
  }, [repPermissions]);

  const currentUser = useSelector(({ auth }) => auth?.currentUser);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { columnLayouts } = useSelector(selector);

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [customerAnchorEl, setCustomerAnchorEl] = useState(null);

  const [anchorTagsEl, setAnchorTagsEl] = useState(null);
  const [selectedTags, setSelectedTags] = useState(null);

  const openHeaderList = Boolean(anchorEl);
  const openCustomerPopUp = Boolean(customerAnchorEl);

  const customerNameRef = useRef(null);

  const additionalCountTagsRef = useRef(null);

  const showArchive = useMemo(() => {
    if (isQuickBooksConnected) return order.orderStatus === "CANCELED";

    return ["PENDING", "CANCELED", "COMPLETED"].includes(order.orderStatus);
  }, [isQuickBooksConnected, order.orderStatus]);

  const handleClickOnArrow = (e) => {
    e.stopPropagation();
    if (customerNameRef.current) {
      if (repPermissions && !repPermissions?.customers?.create_edit) return;
      setCustomerAnchorEl(customerNameRef.current);
    }
  };

  const handleClickOnRestTags = (e, tags) => {
    e.stopPropagation();
    setSelectedTags(tags);
    if (additionalCountTagsRef.current) {
      setAnchorTagsEl(additionalCountTagsRef.current);
    }
  };

  const handleCloseArrowBtn = (e) => {
    e.stopPropagation();
    setCustomerAnchorEl(null);
    setSelectedTags(null);
    setAnchorTagsEl(null);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const createdBy = useMemo(() => getCreatedOrderBy(order), [order]);

  const defaultColumnWidth = useMemo(
    () => Object.values(columnWidth).reduce((acc, cur) => (acc += cur), 0),
    []
  );

  const tags = useMemo(() => {
    return order?.tags?.length ? order.tags.map((t) => t?.tag) : [];
  }, [order.tags]);

  const direct_tab_disable = columnLayouts.direct.disable;
  const direct_tab = columnLayouts.direct.main;
  const third_party_tab_disable = columnLayouts.thirdParty.disable;
  const third_party_tab = columnLayouts.thirdParty.main;

  const columns = useMemo(() => {
    if (currentTab === "Direct" && direct_tab_disable)
      return [
        ...direct_tab_disable,
        ...direct_tab.filter((col) => col.selected),
      ];
    if (currentTab === "3rd Party")
      return [
        ...third_party_tab_disable,
        ...third_party_tab.filter((col) => col.selected),
      ];
    return [];
  }, [
    currentTab,
    direct_tab,
    direct_tab_disable,
    third_party_tab,
    third_party_tab_disable,
  ]);

  const formChanged = useSelector(
    ({ confirmDialogs }) => confirmDialogs.formChanged
  );
  const editType = useSelector(({ confirmDialogs }) => confirmDialogs.editType);

  const handleOpenOrder = useCallback(
    (id) => {
      if (formChanged && editType === "checked_items") {
        handleConfirmCheckedItemsDialog(id);
        return;
      }
      navigate(id, { state: { currentTab } });
    },
    [
      currentTab,
      editType,
      formChanged,
      handleConfirmCheckedItemsDialog,
      navigate,
    ]
  );

  const formattedDate = formatDate({
    utc_date: order?.createdAt,
    formatThisYear: "MMM D, h:mm a",
  });

  const columnData = {
    Note: (
      <Box display="flex" alignItems="center" position="relative">
        {order && order.note && order.note?.text && (
          <StyledTooltip
            title={order.note.text}
            placement="top"
            arrow
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
          >
            <Box
              justifyContent="center"
              sx={{ height: "17px", svg: { height: "17px", width: "14px" } }}
            >
              <PaperIcon color="#5F6267" />
            </Box>
          </StyledTooltip>
        )}
      </Box>
    ),
    Date: (
      <Box>
        <Typography
          sx={{
            fontSize: 13,
            fontWeight: 400,
            color: "#1C1C19",
            textDecorationLine:
              order.orderStatus === "CANCELED" && "line-through",
            textDecorationColor: "#1C1C19",
          }}
          noWrap
        >
          {getDateExpiration(order?.createdAt) !== "Other"
            ? getDateExpiration(order?.createdAt)
            : moment(
                getFormattedDate(order?.createdAt, currentUser.timeZone)
              ).format(formattedDate)}
        </Typography>
      </Box>
    ),
    Customer: (
      <Box
        sx={{
          textDecorationLine:
            order.orderStatus === "CANCELED" && "line-through",
          textDecorationColor: "#1C1C19",
        }}
      >
        <Box display="flex">
          <Box
            sx={cl.orderName}
            display="flex"
            gap="3px"
            alignItems="center"
            flexWrap="nowrap"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleClickOnArrow(e, order);
            }}
          >
            <Typography
              ref={customerNameRef}
              sx={{
                ...cl.typography,
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
              noWrap
              color="#1C1C19"
            >
              {order.customer.name}
            </Typography>
            <Box
              display="flex"
              sx={{
                mb: "-2px",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <SimpleArrowDownIcon width="7px" heigh="3.6px" />
            </Box>
          </Box>
        </Box>
        <Typography
          sx={{ fontSize: 13, fontWeight: 300, color: "#1C1C19" }}
          noWrap
        >
          {order?.customer?.shippingAddress?.formatted_address}
        </Typography>

        {order && (
          <CustomerMenu
            customer={order.customer}
            anchorEl={customerAnchorEl}
            isOpen={openCustomerPopUp}
            handleClose={handleCloseArrowBtn}
          />
        )}
        {!!anchorTagsEl && (
          <TagsMenu
            open={!!anchorTagsEl}
            tags={selectedTags}
            anchorEl={anchorTagsEl}
            handleClose={handleCloseArrowBtn}
          />
        )}
      </Box>
    ),
    "Assigned to": (
      <Box>
        <Typography sx={cl.typography} noWrap color="#1C1C19">
          {order?.salesDuplicate?.name ?? "-"}
        </Typography>
      </Box>
    ),
    "Created by": (
      <Box>
        <Typography
          sx={{ fontSize: 13, fontWeight: 400, color: "#1C1C19" }}
          noWrap
        >
          {createdBy}
        </Typography>
      </Box>
    ),
    Total: (
      <Box>
        <Typography
          sx={{ fontSize: 13, fontWeight: 600, color: "#1C1C19" }}
          noWrap
        >
          ${separateNumWithComma(order?.totalAmount)}
        </Typography>
      </Box>
    ),
    Emailed: (
      <Box>
        <Typography
          sx={{
            fontSize: 13,
            fontWeight: 600,
            color: "#1C1C19",
            textDecoration:
              order?.emailed && order?.emailed?.length > 0
                ? "underline"
                : "none",
          }}
          noWrap
        >
          {order?.emailed && order?.emailed > 0
            ? pluralize("time", order?.emailed, true)
            : "-"}
        </Typography>
      </Box>
    ),
    Tags: (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          width: "100%",
          maxWidth: "100%",
        }}
      >
        <Box
          sx={{
            width: "30px",
            minWidth: "30px",
            "&:hover > div": {
              display: "flex",
            },
          }}
          display="flex"
          gap={0.5}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            const tagsWithoutFirstItem = [...tags].slice(1);
            handleClickOnRestTags(e, tagsWithoutFirstItem);
          }}
        >
          {tags?.length > 1 ? (
            <>
              <Typography
                ref={additionalCountTagsRef}
                fontSize={12}
                color="#5F6368"
              >
                +{tags?.length - 1}
              </Typography>
              <Box
                sx={{ display: "none" }}
                alignItems="center"
                cursor="pointer"
              >
                <SimpleArrowDownIcon width="7px" heigh="3.6px" />
              </Box>
            </>
          ) : null}
        </Box>
        {tags?.length ? (
          <Chip
            sx={{
              fontSize: "14px",
              height: "24px",
              color: "#5F6368",
              maxWidth: "calc(100% - 38px)",
              backgroundColor: "#F8F8F8",
            }}
            label={tags[0]?.tag}
            variant="outlined"
          />
        ) : (
          <Typography color="#5F6368">-</Typography>
        )}
      </Box>
    ),
  };
  const handleClickOnDotsBtn = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };

  const handleCloseDotsBtn = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const hasHiddenEditOrder = useMemo(() => {
    return order?.orderStatus === "CANCELED" || order?.archived;
  }, [order?.orderStatus, order?.archived]);

  const setColumnWidth = (col) => {
    // if (col?.name === "Order") return `${ORDER_WIDTH}px`;
    if (col?.name === "Order") return "";

    if (col?.name === "Tags")
      return `calc(${columnWidth?.[col?.name]}% - ${ORDER_WIDTH + 10}px)`;

    const columns = columnWidth?.[col?.name]
      ? `${columnWidth?.[col?.name]}%`
      : `${
          (100 - defaultColumnWidth) /
          direct_tab.filter((col) => col.selected).length
        }%`;
    return columns;
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "47px",
        minHeight: "47px",
        backgroundColor: isChecked ? "#ECF5F0" : "#FFF",
        "&:not(:last-of-type)": {
          borderBottom: "0.5px solid #D5D9D9",
        },
        display: "flex",
        alignItems: "center",
        "&:hover": {
          backgroundColor: isChecked ? "#ECF5F0" : "#F7F7F7",
          cursor: "pointer",
        },
      }}
    >
      <Box
        sx={{
          width: `${CHECKBOX_WIDTH}px`,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          pl: 0,
          "& .MuiCheckbox-root.Mui-checked": {
            width: 22,
            height: 22,
          },
        }}
      >
        <LargeCheckbox
          sx={{ padding: 0 }}
          formSx={{ m: 0 }}
          checked={isChecked}
          onChange={() => handleCheckOrder(order.id)}
          onClick={(e) => {
            e.stopPropagation();
          }}
          checkedViewBox="0 0 24 24"
        />
      </Box>

      <Box
        width={`${ORDER_WIDTH}px`}
        minWidth={`${ORDER_WIDTH}px`}
        display="flex"
        alignItems="center"
        gap="5px"
      >
        <Typography
          sx={{
            fontSize: 13,
            fontWeight: 600,
            color: "#1C1C19",
            textDecorationLine:
              order.orderStatus === "CANCELED" && "line-through",
            textDecorationColor: "#1C1C19",
            "&:hover": {
              textDecoration: "underline",
            },
          }}
          onClick={() =>
            navigate(`/orders/${order.id}`, { state: { currentTab } })
          }
        >
          {order?.customId?.customId}
        </Typography>

        {order?.archived && (
          <StyledTooltip
            arrow
            title="Archived"
            placement="top"
            PopperProps={{
              modifiers: [{ name: "offset", options: { offset: [0, -8] } }],
            }}
          >
            <Box>
              <InformationIcon size="16" stroke="#E59D51" />
            </Box>
          </StyledTooltip>
        )}
      </Box>

      <Box
        sx={{
          width: `calc(100% - ${
            CHECKBOX_WIDTH + ORDER_WIDTH + ACTION_WIDTH
          }px)`,
          maxWidth: `calc(100% - ${
            CHECKBOX_WIDTH + ORDER_WIDTH + ACTION_WIDTH
          }px)`,
          display: "flex",
          alignItems: "center",
          textDecoration: "none",
        }}
        component={NavLink}
        to={`/orders/${order.id}`}
        state={{ currentTab }}
        from={pathname}
        onClick={(e) => {
          if (e.metaKey || e.ctrlKey) return;
          e.stopPropagation();
          e.preventDefault();
          handleOpenOrder(order.id);
        }}
      >
        {columns.map((col) => {
          const valueOfWidth = setColumnWidth(col);
          return (
            <Box
              key={col?.id}
              sx={{
                width: valueOfWidth,
                maxWidth: valueOfWidth,
                minWidth: valueOfWidth,
              }}
            >
              {columnData?.[col?.name]}
            </Box>
          );
        })}
      </Box>

      <Box
        width={`${ACTION_WIDTH}px`}
        minWidth={`${ACTION_WIDTH}px`}
        maxWidth={`${ACTION_WIDTH}px`}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: "14px",
        }}
      >
        {!!order?.lastPdfsPrinted?.length && (
          <PrintedBlock lastPrinted={order?.lastPdfsPrinted} />
        )}

        <IconButton
          sx={{
            backgroundColor: "#F8F8F8",
            borderRadius: "4px",
            border: "0.5px solid #D4D4D4",
            height: "28px",
            width: "29px",
          }}
          disabled={disabledRepPermissions || isAdmin}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            handleOpenEmail(order);
          }}
        >
          <SendIcon fill={"#5f6267"} />
        </IconButton>

        <ThreeDotButton
          paddingRight="14px"
          disabled={isAdmin}
          onClick={handleClickOnDotsBtn}
        />
        <StyledMenu
          sx={{
            paddingY: 0,
          }}
          anchorEl={anchorEl}
          isOpen={openHeaderList}
          handleClose={handleCloseDotsBtn}
        >
          {hasHiddenEditOrder ? null : (
            <MenuItem
              disabled={disabledRepPermissions}
              onClick={handleCloseDotsBtn}
            >
              <ListItemText
                onClick={(e) => handleChoseMenuItem(order, e.target.innerText)}
              >
                Edit order
              </ListItemText>
            </MenuItem>
          )}

          <MenuItem
            disabled={disabledRepPermissions}
            onClick={handleCloseDotsBtn}
          >
            <ListItemText
              onClick={(e) => handleChoseMenuItem(order, e.target.innerText)}
            >
              Duplicate order
            </ListItemText>
          </MenuItem>

          <MenuItem
            disabled={disabledRepPermissions}
            onClick={handleCloseDotsBtn}
          >
            <ListItemText
              onClick={(e) => handleChoseMenuItem(order, e.target.innerText)}
            >
              Assign tags
            </ListItemText>
          </MenuItem>

          <Divider />

          <MenuItem onClick={handleCloseDotsBtn}>
            <ListItemText
              onClick={(e) => handleChoseMenuItem(order, e.target.innerText)}
            >
              Print order
            </ListItemText>
          </MenuItem>

          <MenuItem onClick={handleCloseDotsBtn}>
            <ListItemText
              onClick={(e) => handleChoseMenuItem(order, e.target.innerText)}
            >
              Print packing slip
            </ListItemText>
          </MenuItem>

          <MenuItem onClick={handleCloseDotsBtn}>
            <ListItemText
              onClick={(e) => {
                handleChoseMenuItem(order, e.target.innerText);
              }}
            >
              Print picklist
            </ListItemText>
          </MenuItem>

          <MenuItem onClick={handleCloseDotsBtn}>
            <ListItemText
              onClick={(e) => {
                handleChoseMenuItem(order, e.target.innerText);
              }}
            >
              Print all
            </ListItemText>
          </MenuItem>

          <Divider />

          {showArchive && (
            <MenuItem
              disabled={disabledRepPermissions}
              onClick={handleCloseDotsBtn}
            >
              <ListItemText
                onClick={(e) => handleChoseMenuItem(order, e.target.innerText)}
              >
                {order?.archived ? "Unarchive order" : "Archive order"}
              </ListItemText>
            </MenuItem>
          )}

          {order.orderStatus !== "CANCELED" && (
            <MenuItem
              disabled={disabledRepPermissions}
              onClick={handleCloseDotsBtn}
            >
              <ListItemText
                classes={{ primary: "deleteItem" }}
                sx={{
                  ...cl.threeDotsText,
                  "& > .MuiTypography-root": {
                    color: "#FF6254",
                  },
                }}
                onClick={(e) => handleChoseMenuItem(order, e.target.innerText)}
              >
                Cancel order
              </ListItemText>
            </MenuItem>
          )}
        </StyledMenu>
      </Box>
    </Box>
  );
};

ItemThirdParty.propTypes = {
  order: shape({
    id: string,
    createdAt: string,
  }),
  isChecked: bool,
  currentTab: string,
  handleCheckOrder: func,
  handleChoseMenuItem: func,
  handleOpenEmail: func,
  handleConfirmCheckedItemsDialog: func,
  isQuickBooksConnected: bool,
  repPermissions: object,
};
ItemThirdParty.defaultProps = {
  isQuickBooksConnected: false,
};
