export const EmptyRepsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="201"
      height="201"
      viewBox="0 0 201 201"
    >
      <defs>
        <filter
          id="Ellipse_998"
          x="0"
          y="0"
          width="201"
          height="201"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dx="10" dy="10" />
          <feGaussianBlur stdDeviation="10" result="blur" />
          <feFlood floodOpacity="0.012" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
        <clipPath id="clipPath">
          <ellipse
            id="Ellipse_1001"
            data-name="Ellipse 1001"
            cx="23"
            cy="23.5"
            rx="23"
            ry="23.5"
            transform="translate(0.5 0)"
            fill="#fff"
          />
        </clipPath>
        <clipPath id="clipPath-2">
          <circle
            id="Ellipse_1000"
            data-name="Ellipse 1000"
            cx="20.5"
            cy="20.5"
            r="20.5"
            transform="translate(0.125 0.25)"
            fill="#fff"
          />
        </clipPath>
        <clipPath id="clipPath-3">
          <ellipse
            id="Ellipse_999"
            data-name="Ellipse 999"
            cx="21"
            cy="21.5"
            rx="21"
            ry="21.5"
            transform="translate(0.5 -0.435)"
            fill="#fff"
          />
        </clipPath>
        <clipPath id="clipPath-4">
          <circle
            id="Ellipse_1002"
            data-name="Ellipse 1002"
            cx="22"
            cy="22"
            r="22"
            transform="translate(0 -0.188)"
            fill="#fff"
          />
        </clipPath>
      </defs>
      <g
        id="Group_7500"
        data-name="Group 7500"
        transform="translate(-6395 -18348)"
      >
        <g
          transform="matrix(1, 0, 0, 1, 6405, 18348)"
          filter="url(#Ellipse_998)"
        >
          <g
            id="Ellipse_998-2"
            data-name="Ellipse 998"
            transform="translate(20 20)"
            fill="#fff"
            stroke="#f5f5f5"
            strokeWidth="1"
          >
            <circle cx="70.5" cy="70.5" r="70.5" stroke="none" />
            <circle cx="70.5" cy="70.5" r="70" fill="none" />
          </g>
        </g>
        <g
          id="Group_7498"
          data-name="Group 7498"
          transform="translate(6424.985 18371.379)"
          opacity="0.1"
        >
          <path
            id="Path_5147"
            data-name="Path 5147"
            d="M12.84,80.49c-1.258,1.9-2.447,3.848-3.538,5.853q-1.025,1.882-1.924,3.82l-.014.031A69.651,69.651,0,0,0,3.4,137.4h.014a3.334,3.334,0,0,0,.1.369l.08.049a8.731,8.731,0,0,0,1.314.613c3.05,1.136,6.142,2.092,9.168,3.378,3.963,1.7,3.963,1.875,4.11,6.1a108.86,108.86,0,0,1,.23,11.35,25.127,25.127,0,0,1-.938,4.915c-.007.028-.017.059-.024.087a70.136,70.136,0,0,0,9.45,9.192.2.2,0,0,0,.014-.024c.986-1.635,1.865-3.249,2.464-4.148a86.522,86.522,0,0,1,6.041-8.586c2.454-2.778,7.233-6.4,6.832-10.625-.376-4.347-10.412-6.313-13.661-7.707A108.208,108.208,0,0,1,16.1,136.249c-1.122-.687-4.532-2.322-4.661-3.723-.157-2.092,4.9-2.987,6.438-3.447,2.161-.669,3.775-1.011,5.424-2.513,2.039-1.865,3.339-4.392,5.441-6.156,3.615-3.022,10.231-4.988,5.33-9.886a40.473,40.473,0,0,0-5.027-4.8c-1.241-.871-2.98-2.59-4.249-3.148a3.053,3.053,0,0,0-1.224-.3c-2.273,0-2.252,3.96-3.74,5.441a5.755,5.755,0,0,1-4.005,1.663c-2.339,0-4.277-1.583-3.228-4.622,1.189-3.475,3.012-7.54,6.783-7.746.537-.017,1.506-.146,2.363-.146a3.039,3.039,0,0,1,1.412.244c.554.321.945,1.59,1.328,1.8a2.434,2.434,0,0,0,1.1.324c.8,0,1.408-.655,1.778-2.182.7-2.824-4.312-5.964-6.661-7.808C17.818,86.984,12.049,84.778,12.84,80.49Z"
            transform="translate(0 -52.433)"
            fill="#409a65"
            opacity="0.46"
          />
          <path
            id="Path_5148"
            data-name="Path 5148"
            d="M80.926,0A67.86,67.86,0,0,0,70.169,4.044,69.368,69.368,0,0,0,46.26,22.776a5.01,5.01,0,0,1,2.011-.418,7.179,7.179,0,0,1,3.716,1.175c3.05,1.827,8.537,3.371,8.784,7.732.2,3.629-3.235,6.055-2.614,10.095.446,2.768,2.618,9.614,4.925,11.315,1.126.819,3.705,1.314,6,1.314a7.439,7.439,0,0,0,3.719-.725c1.844-1.213,2.172-4.859,2.743-6.8a27.506,27.506,0,0,1,2.531-6.341c1.237-2.126,3.357-3.514,4.546-5.6,2.715-4.6-.917-10.607,1.67-15.006,2.085-3.549,4.361-5.692,4.552-10.109C89.052,4.612,85.4,1.792,80.926,0Z"
            transform="translate(-29.484)"
            fill="#409a65"
            opacity="0.46"
          />
          <path
            id="Path_5149"
            data-name="Path 5149"
            d="M144.986,202.06a5.233,5.233,0,0,0-4.082,1.966c-1.387,2.482-1.963,6.159-3.085,8.861-2.008,4.73-1.816,7.421,3.277,10.231a22.4,22.4,0,0,0,8.349,2.451c2.423.254,4.873.858,6.01,3.531,1.663,3.862-.945,8.941-.973,12.866-.052,4.9,1.656,6.909,5.839,6.909a18.118,18.118,0,0,0,2.37-.174c7.651-1.007,4.751-9.049,7.233-14.055,1.917-3.9,3.42-8.579,5.9-12.131,1.541-2.21,4.79-3.51,4.378-6.773-.519-4.18-8.279-4.514-11.667-5.142-2.144-.408-4.4-1.182-6.567-1.422l-.945-.23a5.354,5.354,0,0,0-1.739-.247,23.824,23.824,0,0,0-3.493.383,24.278,24.278,0,0,1-3.639.383c-2.2,0-4.082-.69-4.87-3.371-.328-1.108.45-2.6-.53-3.472A2.657,2.657,0,0,0,144.986,202.06Z"
            transform="translate(-88.353 -131.625)"
            fill="#409a65"
            opacity="0.46"
          />
          <path
            id="Path_5150"
            data-name="Path 5150"
            d="M343.574,279.39a21.03,21.03,0,0,0-5.156,1.178c-4.591,1.384-5.846,3.855-8,8.07-1.077,2.137-6.553,7.948-4.786,10.688a3.459,3.459,0,0,0,2.607,1.255,69.4,69.4,0,0,0,16-21.135A3.584,3.584,0,0,0,343.574,279.39Z"
            transform="translate(-211.243 -181.999)"
            fill="#409a65"
            opacity="0.46"
          />
          <path
            id="Path_5151"
            data-name="Path 5151"
            d="M194.927,68.94c-3.587,0-6.1,2.165-6.083,6.452,0,3.556-.718,6.867-.718,10.405.021,2.154.568,3.709-1.551,4.392a3.8,3.8,0,0,1-1.192.213c-1.642,0-2.451-1.408-3.392-2.862-1.394-2.1-2.311-4.856-4.877-4.856a4.257,4.257,0,0,0-.941.112c-3.967.878-5.567,6.247-7.69,9.084-1.391,1.858-3.238,3.489-4.2,5.563-.436.934-.363,2.74-1.579,3.061a1.609,1.609,0,0,1-.369.049c-1.244,0-1.736-1.719-2.963-1.942a3.053,3.053,0,0,0-.547-.052c-2.656,0-3.061,3.963-2.747,6.128,1.251,8.948,8.655,11.172,16.383,13.874,5.183,1.806,10.447,1.7,15.585,4.127a21.24,21.24,0,0,1,6.348,3.991c.68.725,2.513,2.182,2.876,3.036,1.109,2.635-.882,6.986,4.026,6.986.059,0,.122,0,.185,0,5.169-.094,2.858-6.989,5.41-9.422a2.262,2.262,0,0,1,1.562-.729c1.129,0,2.172,1.077,3.085,2.311,1.279,1.687,1.147,2.224.868,4.19-.464,3.1.08,6.473,2.621,8.851,1.255,1.171,2.175,1.67,2.848,1.67,3.388,0,.3-12.716.69-14.912.624-3.427,4.3-7.2,6.306-10,1.513-2.14,3.35-3.064,5.344-4.668,2.813-2.217,3.887-5.992,6.351-8.617,1.44-1.534,2.677-2.743,3.688-3.956a68.11,68.11,0,0,0-1.569-7.742v-.01A70.409,70.409,0,0,0,230.6,75.051a10.965,10.965,0,0,0-3.322-.565c-.2,0-.4.007-.6.021-1.14.084-2.541.6-3.775.6a3.424,3.424,0,0,1-.809-.091c-1.22-.345-2.018-1.527-3.1-2.154l-.45-.934c-3.068,1.06-7-.035-10.384,1.237a25.616,25.616,0,0,0-4.539,2.172,23.223,23.223,0,0,0-3.664,2.681c-.857.812-1.551,2.715-2.527,3.27a4.3,4.3,0,0,1-2.186.659c-2.395,0-3.751-2.6-1.82-5.037,1.712-2.165,5.978-4.054,5.4-7.251A11.972,11.972,0,0,0,194.927,68.94Z"
            transform="translate(-100.964 -44.909)"
            fill="#409a65"
            opacity="0.46"
          />
        </g>
        <g id="Group_7499" data-name="Group 7499" transform="translate(-205 5)">
          <g
            id="Ellipse_1006"
            data-name="Ellipse 1006"
            transform="translate(6663 18441)"
            fill="#fff"
            stroke="#efefef"
            strokeWidth="1"
          >
            <circle cx="22" cy="22" r="22" stroke="none" />
            <circle cx="22" cy="22" r="21.5" fill="none" />
          </g>
          <g
            id="Group_7514"
            data-name="Group 7514"
            transform="translate(-49 7)"
          >
            <g
              id="Ellipse_1004"
              data-name="Ellipse 1004"
              transform="translate(6698 18384)"
              fill="#fff"
              stroke="#efefef"
              strokeWidth="1"
            >
              <circle cx="22" cy="22" r="22" stroke="none" />
              <circle cx="22" cy="22" r="21.5" fill="none" />
            </g>
            <g
              id="Mask_Group_170"
              data-name="Mask Group 170"
              transform="translate(6696.5 18381)"
              clipPath="url(#clipPath)"
            >
              <g
                id="Group_7471"
                data-name="Group 7471"
                transform="translate(8.808 10.402)"
              >
                <path
                  id="Path_5082"
                  data-name="Path 5082"
                  d="M546.012,97.321c-2.821-4.363.7-10.633,2.81-13.223,3.012-.569,9.487-1.565,11.294-.992,1.389.44,3.062,5.769,4.179,10.187a3.9,3.9,0,0,1-2.661,4.743C556.021,99.663,548.3,100.857,546.012,97.321Z"
                  transform="translate(-541.822 -81.399)"
                  fill="#abd2bb"
                />
                <path
                  id="Path_5083"
                  data-name="Path 5083"
                  d="M565.976,80.314c-1.277,0-2.6.163-3.1-1.376a8.485,8.485,0,0,1,3.234-8.81,8.756,8.756,0,0,1,2.875-.959,4.5,4.5,0,0,1,3.238.274c.974.523,1.919-.318,2.93.355a6.2,6.2,0,0,1,1.975,2.381,14.82,14.82,0,0,1,1.171,3.8,1.644,1.644,0,0,1-.28,1.573,5.2,5.2,0,0,1-4.457,2.759S566.01,80.314,565.976,80.314Z"
                  transform="translate(-557.516 -69)"
                  fill="#abd2bb"
                />
                <path
                  id="Path_5084"
                  data-name="Path 5084"
                  d="M605.917,254.2s3.368-.868,4.084.2c.358,1.784.542,3.557.542,3.557l-3.882.728-2.567-.591-.61-.355-.254-2.528A9.8,9.8,0,0,1,605.917,254.2Z"
                  transform="translate(-593.67 -233.496)"
                  fill="#e2f0e8"
                />
                <path
                  id="Path_5085"
                  data-name="Path 5085"
                  d="M578.466,165.534s-.542-1.625-1.354-1.083,1.22,4,1.22,4Z"
                  transform="translate(-570.26 -153.835)"
                  fill="#fcfcfc"
                />
                <path
                  id="Path_5086"
                  data-name="Path 5086"
                  d="M664.68,163.12s0-2.437,1.354-1.083-1.354,3.249-1.354,3.249Z"
                  transform="translate(-648.35 -151.421)"
                  fill="#fcfcfc"
                />
                <path
                  id="Path_5087"
                  data-name="Path 5087"
                  d="M724.517,295.323c-1.134-5.473-2.761-9.751-3.276-10l-3.074,7.606a20.057,20.057,0,0,0-.571,5.081h4.728A2.238,2.238,0,0,0,724.517,295.323Z"
                  transform="translate(-695.433 -261.489)"
                  fill="#e2f0e8"
                />
                <path
                  id="Path_5088"
                  data-name="Path 5088"
                  d="M521.9,311.485c.142-2.445.079-3.984.079-3.984l-4.334-7.034a61.191,61.191,0,0,0-1.083,7.844q-.038.4-.072.805a2.188,2.188,0,0,0,2.181,2.369Z"
                  transform="translate(-516.478 -274.961)"
                  fill="#e2f0e8"
                />
                <path
                  id="Path_5089"
                  data-name="Path 5089"
                  d="M605.086,228.481H609.8l1.279-13.448s-1.083-.279-1.625-2.175a38.355,38.355,0,0,1-.812-5.691l-4.875,1.081s.542,5.161-.542,7.6Z"
                  transform="translate(-593.67 -191.942)"
                  fill="#fcfcfc"
                />
                <path
                  id="Path_5090"
                  data-name="Path 5090"
                  d="M568.91,332.046l.679-8.49c-4.942,2.956-10.024,2.813-15.18,1.245l1.463,7.244H568.91Z"
                  transform="translate(-550.229 -295.507)"
                  fill="#409a65"
                />
                <path
                  id="Path_5091"
                  data-name="Path 5091"
                  d="M538.458,280.982c-1.464-7.064-3.014-14.8-3.014-14.8l-.812,1.621-7.583,2.173c.371,1.732,1.505,6.5,2.645,11Z"
                  transform="translate(-525.884 -244.458)"
                  fill="#e2f0e8"
                />
                <path
                  id="Path_5092"
                  data-name="Path 5092"
                  d="M655.573,274.423c.4-2.164.76-3.853.76-3.853l1.64-8.835c-.516-.516-8.612-1.626-8.612-1.626l-.866-1.294s-1.081,7.859-2.005,15.607h9.084Z"
                  transform="translate(-632.163 -237.9)"
                  fill="#e2f0e8"
                />
                <path
                  id="Path_5093"
                  data-name="Path 5093"
                  d="M585.679,116.122s-.271,6.5.542,7.583,1.9,3.521,3.792,3.792,4.875-5.146,4.875-5.417-.271-7.312-.271-7.312S588.928,110.163,585.679,116.122Z"
                  transform="translate(-578.015 -108.214)"
                  fill="#fcfcfc"
                />
                <path
                  id="Path_5094"
                  data-name="Path 5094"
                  d="M574.8,105.5s-1.083,3.249,2.166,2.166l3.25-1.083s3.249-1.9,3.792-1.083a13.4,13.4,0,0,1,1.083,2.813s1.083-2.813,0-4.438S577.237,100.891,574.8,105.5Z"
                  transform="translate(-568.219 -98.672)"
                  fill="#abd2bb"
                />
              </g>
            </g>
          </g>
          <g
            id="Mask_Group_169"
            data-name="Mask Group 169"
            transform="translate(6663.875 18444.75)"
            clipPath="url(#clipPath-2)"
          >
            <g
              id="Group_7474"
              data-name="Group 7474"
              transform="translate(4.644 4.898)"
            >
              <path
                id="Path_5095"
                data-name="Path 5095"
                d="M232.515,662.118a6.653,6.653,0,1,0-6.653-6.653A6.653,6.653,0,0,0,232.515,662.118Z"
                transform="translate(-217.835 -648.812)"
                fill="#abd2bb"
              />
              <path
                id="Path_5096"
                data-name="Path 5096"
                d="M190.266,697a98.683,98.683,0,0,0-4.15,12.42c-.469,1.981,1.106,3.428,3.136,3.271l4.953-.381.99-13.644Z"
                transform="translate(-181.932 -692.253)"
                fill="#abd2bb"
              />
              <path
                id="Path_5097"
                data-name="Path 5097"
                d="M313.175,683.291,312,698.668l4.854,1.736a2.953,2.953,0,0,0,4.062-3.323c-1.278-6.049-3.858-13.806-5.093-14.079Z"
                transform="translate(-295.486 -679.633)"
                fill="#abd2bb"
              />
              <path
                id="Path_5098"
                data-name="Path 5098"
                d="M268.185,812.572l.281,6.231-6.043.91-.381-6.541Z"
                transform="translate(-250.449 -796.432)"
                fill="#fcfcfc"
              />
              <path
                id="Path_5099"
                data-name="Path 5099"
                d="M242.089,698.8s-1.448,6.469-5.367,7.246-5.08-5.318-5.287-5.756c0,0-.876.413-1.073-.458s-1.23-3.062.72-3.018c0,0-.383-2.176.291-3.245s2.033-3.638,4.835-2.923S243.486,693.714,242.089,698.8Z"
                transform="translate(-221.487 -686.408)"
                fill="#fcfcfc"
              />
              <path
                id="Path_5100"
                data-name="Path 5100"
                d="M230.558,854.663l3.832,2.032-5.019,5.508-9.279-5.837a24.933,24.933,0,0,1,4.073-1.448Z"
                transform="translate(-212.635 -834.374)"
                fill="#fff"
              />
              <path
                id="Path_5101"
                data-name="Path 5101"
                d="M237.769,681.562c-1.459,1.822-.312,3.439-.7,4.748a33.966,33.966,0,0,1,7.623-1.019c.3-.872-.286-2.322-.585-3.465.467,1.086,1.532,2.748,1.419,3.606,1.929.079,1.534.1,3.7.879l-1.688-5.256C243.777,679.485,240.531,679.684,237.769,681.562Z"
                transform="translate(-227.878 -676.928)"
                fill="#abd2bb"
              />
              <path
                id="Path_5103"
                data-name="Path 5103"
                d="M196.178,870.52l2.11-11.737A38.284,38.284,0,0,0,189.6,854.7c-.98,2.037-5.456,1.835-6.393.253a28.212,28.212,0,0,0-8.551,3.964l2.887,11.6h18.639Z"
                transform="translate(-171.673 -834.41)"
                fill="#e2f0e8"
              />
              <path
                id="Path_5104"
                data-name="Path 5104"
                d="M389.267,905.764c-1.709-7.307-3.677-9.661-3.677-9.661l-3.157,9.607s.38.767.88,2.13h4.306A1.692,1.692,0,0,0,389.267,905.764Z"
                transform="translate(-358.974 -871.729)"
                fill="#e2f0e8"
              />
              <path
                id="Path_5105"
                data-name="Path 5105"
                d="M150.5,909.119a27.787,27.787,0,0,1,1.42-3.921l-4.517-7.676a28.3,28.3,0,0,0-2.966,9.7,1.689,1.689,0,0,0,1.678,1.9Z"
                transform="translate(-144.425 -873.009)"
                fill="#e2f0e8"
              />
            </g>
          </g>
          <g id="Group_7515" data-name="Group 7515">
            <g
              id="Ellipse_1005"
              data-name="Ellipse 1005"
              transform="translate(6708 18432)"
              fill="#fff"
              stroke="#efefef"
              strokeWidth="1"
            >
              <circle cx="22" cy="22" r="22" stroke="none" />
              <circle cx="22" cy="22" r="21.5" fill="none" />
            </g>
            <g
              id="Mask_Group_168"
              data-name="Mask Group 168"
              transform="translate(6708.5 18434.436)"
              clipPath="url(#clipPath-3)"
            >
              <g
                id="Group_7470"
                data-name="Group 7470"
                transform="translate(1.876 6.477)"
              >
                <path
                  id="Path_5074"
                  data-name="Path 5074"
                  d="M16.809,405.871c.317-1.47.567-2.431.567-2.431l-5.585-9.459s-1.808,1.83-2.891,9.8a1.841,1.841,0,0,0,1.823,2.088h6.087Z"
                  transform="translate(-8.883 -369.711)"
                  fill="#abd2bb"
                />
                <path
                  id="Path_5075"
                  data-name="Path 5075"
                  d="M318.369,400.531c-1.551-6.912-4.312-10.13-4.312-10.13l-3.5,11.581s.106.228.268.67h5.844A1.74,1.74,0,0,0,318.369,400.531Z"
                  transform="translate(-280.234 -366.491)"
                  fill="#abd2bb"
                />
                <path
                  id="Path_5076"
                  data-name="Path 5076"
                  d="M42.8,359.693c5.741,2.112,13.543,1.817,22.647,0a30.031,30.031,0,0,1,.89-2.922l2.4-9.33c-3.367-3.019-8.1-4.015-9.92-4.3a25.073,25.073,0,0,0-9.176-.12,22.9,22.9,0,0,0-11.817,4.77l4.155,9.455A19.617,19.617,0,0,1,42.8,359.693Z"
                  transform="translate(-34.913 -323.533)"
                  fill="#abd2bb"
                />
                <path
                  id="Path_5077"
                  data-name="Path 5077"
                  d="M162.62,301.092l-.143,4.713a1.067,1.067,0,0,0,.715,1.04,9.419,9.419,0,0,0,6.063.225,1.067,1.067,0,0,0,.691-1.16l-.709-4.818Z"
                  transform="translate(-147.036 -286.16)"
                  fill="#fcfcfc"
                />
                <path
                  id="Path_5078"
                  data-name="Path 5078"
                  d="M146.543,187.534s.276,6.367,1.384,8.858a4.519,4.519,0,0,0,4.152,2.491,6.337,6.337,0,0,0,4.152-2.768c1.384-2.215,1.937-9.412,1.937-9.412a10.023,10.023,0,0,0-5.813-3.045C149.034,183.382,146.543,186.15,146.543,187.534Z"
                  transform="translate(-132.704 -180.515)"
                  fill="#fcfcfc"
                />
                <path
                  id="Path_5079"
                  data-name="Path 5079"
                  d="M245.119,232.715s1.271-1.905,1.905-1.271-.635,4.763-1.905,4.763S245.119,232.715,245.119,232.715Z"
                  transform="translate(-220.862 -223.398)"
                  fill="#fff"
                />
                <path
                  id="Path_5080"
                  data-name="Path 5080"
                  d="M134.22,233.178s-1.588-2.223-2.223-1.587.953,4.763,2.223,4.763v-3.176Z"
                  transform="translate(-119.491 -223.542)"
                  fill="#fff"
                />
                <path
                  id="Path_5081"
                  data-name="Path 5081"
                  d="M144.919,156.634c.021.508.046.953.038,1.383a5.928,5.928,0,0,1-.318,1.877,17.648,17.648,0,0,0-.831,2.673c-.6,2.014-1.063-.767-1.063-.944,0-1.008-.181-3.141-.9-3.606s-2.819-.142-3.776.077a8.38,8.38,0,0,1-3.374.038c-.358.444-.836.669-1.1,1.176a4.017,4.017,0,0,0-.431,1.707,20.974,20.974,0,0,0,.261,2.458c-.462.334-.757-.511-.846-.975-.331-1.214-2.123-2.546-1.441-3.9a3.885,3.885,0,0,0,.19-.363,1.66,1.66,0,0,0-.246-1.274c-.161-1.47.732-4.39,5.591-4.31,6.074.1,7.362-1.02,8.327,2.463A10.106,10.106,0,0,0,144.919,156.634Z"
                  transform="translate(-118.708 -152.557)"
                  fill="#abd2bb"
                />
              </g>
            </g>
          </g>
          <g
            id="Group_7516"
            data-name="Group 7516"
            transform="translate(50 -11)"
          >
            <g
              id="Ellipse_1003"
              data-name="Ellipse 1003"
              transform="translate(6649 18395)"
              fill="#fff"
              stroke="#efefef"
              strokeWidth="1"
            >
              <circle cx="22" cy="22" r="22" stroke="none" />
              <circle cx="22" cy="22" r="21.5" fill="none" />
            </g>
            <g
              id="Mask_Group_171"
              data-name="Mask Group 171"
              transform="translate(6649 18395.188)"
              clipPath="url(#clipPath-4)"
            >
              <g
                id="Group_7473"
                data-name="Group 7473"
                transform="translate(4.469 7.326)"
              >
                <path
                  id="Path_5106"
                  data-name="Path 5106"
                  d="M924.575,861.359a1.941,1.941,0,0,0,1.941-1.955c-.021-3.086-.083-5.8-.227-7.294-.367-3.82-2.394-5.032-2.394-5.032l-1.033,14.28Z"
                  transform="translate(-890.85 -824.513)"
                  fill="#409a65"
                />
                <path
                  id="Path_5107"
                  data-name="Path 5107"
                  d="M724.846,841.537c-.151-8-.3-14.615-.3-14.615-3.622-2.229-17-1.95-17-1.95s-.929,7.717-1.988,16.565Z"
                  transform="translate(-696.108 -804.691)"
                  fill="#e2f0e8"
                />
                <path
                  id="Path_5108"
                  data-name="Path 5108"
                  d="M668.6,827.725H681.05c-.4-3.868-3.615-18.176-3.615-18.176l-9.984,3.055s.788,8.92.788,11.708C668.241,824.95,668.42,826.189,668.6,827.725Z"
                  transform="translate(-661.954 -790.879)"
                  fill="#409a65"
                />
                <path
                  id="Path_5109"
                  data-name="Path 5109"
                  d="M621.514,854.2c.045-4.715-.04-13.412-1.525-15.11,0,0-1.924.99-3.874,7.4-.563,1.85-1.074,3.605-1.551,5.278A1.91,1.91,0,0,0,616.4,854.2h5.113Z"
                  transform="translate(-614.49 -817.352)"
                  fill="#409a65"
                />
                <path
                  id="Path_5110"
                  data-name="Path 5110"
                  d="M823.406,837.458c.359-6.782.673-14.007.375-14.3-.557-.558-7.253-2.744-7.253-2.744s-4.362,13.34-5.375,17.049Z"
                  transform="translate(-790.736 -800.611)"
                  fill="#409a65"
                />
                <path
                  id="Path_5111"
                  data-name="Path 5111"
                  d="M767.652,781.831l1.672-5.852h6.13l.836,6.688S771,784.06,767.652,781.831Z"
                  transform="translate(-751.752 -760.794)"
                  fill="#fcfcfc"
                />
                <path
                  id="Path_5112"
                  data-name="Path 5112"
                  d="M767.652,673.353s.576,5.6,1.548,7.784a3.965,3.965,0,0,0,3.643,2.186,5.555,5.555,0,0,0,3.643-2.429c1.215-1.943,1.7-8.258,1.7-8.258a8.792,8.792,0,0,0-5.1-2.672C770.172,669.721,767.652,672.138,767.652,673.353Z"
                  transform="translate(-751.752 -665.768)"
                  fill="#fcfcfc"
                />
                <path
                  id="Path_5113"
                  data-name="Path 5113"
                  d="M854.638,711.7s1.115-1.671,1.672-1.115-.557,4.179-1.672,4.179S854.638,711.7,854.638,711.7Z"
                  transform="translate(-829.265 -702.083)"
                  fill="#fff"
                />
                <path
                  id="Path_5114"
                  data-name="Path 5114"
                  d="M760.458,712.087s-1.393-1.951-1.951-1.393.836,4.179,1.951,4.179Z"
                  transform="translate(-743.443 -702.196)"
                  fill="#fff"
                />
                <path
                  id="Path_5115"
                  data-name="Path 5115"
                  d="M753.048,628.554A5.624,5.624,0,0,0,753.3,631a4.1,4.1,0,0,0,.386.808,7.528,7.528,0,0,0,.938,1.152c.7.748.6,1.731.7,2.759h.354a9.171,9.171,0,0,1,.145-2.673c.022-.15.348-3.2.862-2.819,1.263.927,2.856.913,4.317,1.056,1.1.107,3.342.222,3.379,1.838.018.789-.269,1.659-.291,2.47a2.9,2.9,0,0,0,.065-.328l0-.012c.252-1.586,1.8-2.6,1.291-3.161-.724-.79.453-1.7.6-2.554a4.017,4.017,0,0,0-1.085-3.251,8.062,8.062,0,0,0-5.168-1.593c-1.875.1-3.114.65-5.293.238C752.768,625.99,753.182,627.314,753.048,628.554Z"
                  transform="translate(-738.633 -624.683)"
                  fill="#abd2bb"
                />
                <path
                  id="Path_5116"
                  data-name="Path 5116"
                  d="M737.863,785.916a17.191,17.191,0,0,1-.685,3.133,12.6,12.6,0,0,0-.075,3.834c.031,1.634,1.3,3.421,1.023,5.026-.711-2-2.881-3.552-3.992-5.417-1.085-1.822-1.86-3.293-.62-5.013C734.283,786.413,737.88,785.816,737.863,785.916Z"
                  transform="translate(-720.589 -769.689)"
                  fill="#409a65"
                />
                <path
                  id="Path_5117"
                  data-name="Path 5117"
                  d="M835.007,795.343c.154,3.4-.514,4.092-1.055,7.529.727-2.322,2.363-3.543,3.651-5.613,1.27-2.041,1.681-4.585-.4-5.945-.142-.093-2.153-1.025-2.144-.844C835.088,790.966,834.94,793.869,835.007,795.343Z"
                  transform="translate(-811.17 -773.759)"
                  fill="#409a65"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
