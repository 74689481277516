import { useState, useCallback, useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import { createSelector } from "reselect";
import { GalleryFilter } from "./components/GalleryFilter/GalleryFilter.jsx";
import {
  galleryCountSelector,
  galleryGetParamsSelector,
  galleryListSelector,
  galleryLoadingSelector,
} from "../../../../redux/selectors/gallery";
import { currentUserSelector } from "../../../../redux/selectors/auth.js";
import {
  deletePhotosAction,
  getGalleryAction,
  groupIdsAction,
  setPictureItemAction,
} from "../../../../redux/actions/gallery.js";

import { CircularProgress, Typography } from "@mui/material";
import {
  IMAGE_EXTENSION,
  SCROLL_LIMIT_GALLERY,
} from "../../../../utils/constants.js";
import PhotoGroupCard from "./components/PhotoGroupCard/PhotoGroupCard.jsx";
import PhotoGroupPopup from "./components/PhotoGroupPopup/PhotoGroupPopup.jsx";
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import { photoUrl } from "../../../../helpers/helpers.js";
import FileSaver from "file-saver";
import { getGalleryService } from "../../../../services/gallery.js";
import { Grid, InfiniteLoader } from "react-virtualized";
import { useWindowSize } from "helpers/hooks.js";
import { ListRowWrapper } from "components/InfiniteLoaderWrapper/components/index.js";
import { InfiniteLoadMoreBtn, TagsDrawer } from "components/index.js";
import { func, object } from "prop-types";
import moment from "moment";
import { success } from "utils/notifications.js";
import { TAG_TYPES } from "helpers/useTagsActions.js";
import { useGalleryTab } from "./useGalleryTab.js";

const selector = createSelector(
  galleryListSelector,
  currentUserSelector,
  galleryCountSelector,
  galleryGetParamsSelector,
  galleryLoadingSelector,
  (
    galleryList,
    currentUser,
    galleryCount,
    galleryGetParams,
    galleryLoading
  ) => ({
    galleryList,
    currentUser,
    galleryCount,
    galleryGetParams,
    galleryLoading,
  })
);

const GalleryTab = ({ setDownloadProgress }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    galleryList,
    currentUser,
    galleryCount,
    galleryGetParams,
    galleryLoading,
  } = useSelector(selector);

  const {
    openTagsDrawer,
    handleCloseTagsDrawer,
    handleSaveTags,
    handleOpenTags,
    loadingTagsDrawer,
    checkedPhotos,
    setCheckedPhotos,
    isOpenProductDialog,
    setIsOpenProductDialog,
    page,
    setPage,
    productTags,
  } = useGalleryTab({ galleryList });

  const handleCheckPhoto = (photo) => {
    const customerIndex = checkedPhotos.findIndex(
      (checkedPhoto) => checkedPhoto.id === photo.id
    );
    if (customerIndex > -1) {
      return setCheckedPhotos(
        checkedPhotos.filter((item) => item.id !== photo.id)
      );
    }

    setCheckedPhotos([...checkedPhotos, photo]);
  };

  const [currentPage, setCurrentPage] = useState(null);

  const handleOpenProductDialog = useCallback(
    (product) => {
      getGalleryService({ ...galleryGetParams })
        .then((res) => {
          const groupIds = res?.rows?.map((el) => el?.id);
          dispatch(groupIdsAction(groupIds));
          const index = groupIds.findIndex((group) => group === product?.id);
          setCurrentPage(index + 1);
          dispatch(setPictureItemAction(product.id));
          setIsOpenProductDialog(true);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err?.message);
        });
    },
    [dispatch, galleryGetParams, setIsOpenProductDialog]
  );

  const handleCloseProductDialog = () => {
    setIsOpenProductDialog(false);
    setCurrentPage(null);
  };
  const handleSetProduct = (product) => {
    handleOpenProductDialog(product);
  };

  const handleDeletePhotos = useCallback(() => {
    const ids = checkedPhotos.map((photo) => photo.id);
    dispatch(deletePhotosAction(ids));
    setCheckedPhotos([]);
  }, [checkedPhotos, dispatch, setCheckedPhotos]);

  const urlToPromise = (url) => {
    return new Promise(function (resolve, reject) {
      JSZipUtils.getBinaryContent(url, function (err, data) {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  };

  const handleDownloadPhotos = () => {
    setDownloadProgress({ download: true, progress: 0 });
    success("Downloading in progress");
    const zip = new JSZip();
    checkedPhotos.forEach(async (group) => {
      group.photos.forEach(async (photo) => {
        const downloadedFileName = `${photo.id}${IMAGE_EXTENSION}`;
        zip
          .folder(group.customer.name)
          .file(downloadedFileName, urlToPromise(photoUrl(photo.fileName)), {
            binary: true,
          });
      });
    });
    let beforeUpdate = 0;
    zip
      .generateAsync({ type: "blob" }, (metadata) => {
        if (metadata.percent - beforeUpdate > 5) {
          setDownloadProgress({
            download: true,
            progress: parseFloat(metadata.percent.toFixed(0)),
          });
          beforeUpdate = metadata.percent;
        }
      })
      .then((blob) => {
        FileSaver.saveAs(
          blob,
          `Gallery photos ${moment().format("DD-MM-YYYY")}`
        );
        setDownloadProgress({ download: false, progress: 0 });
      });
    setCheckedPhotos([]);
  };

  const [windowWidth, windowHeight] = useWindowSize();
  const COLUMN_WIDTH = 300;
  const PAGE_PADDING = 64;
  const PHOTO_GAP = 16;
  const MAX_WIDTH = useMemo(() => windowWidth - PAGE_PADDING, [windowWidth]);
  const columnCount = useMemo(
    () => Math.floor(MAX_WIDTH / COLUMN_WIDTH),
    [MAX_WIDTH]
  );

  const PHOTO_GAP_SUM = useMemo(
    () => (columnCount - 1) * PHOTO_GAP,
    [columnCount]
  );

  const ADDITIONAL_OFFSET = useMemo(
    () =>
      Math.floor(
        (MAX_WIDTH - PHOTO_GAP_SUM - columnCount * COLUMN_WIDTH) / columnCount
      ),
    [MAX_WIDTH, PHOTO_GAP_SUM, columnCount]
  );

  const rowCount = useMemo(
    () => Math.ceil(galleryCount / columnCount),
    [galleryCount, columnCount]
  );

  const handleFetch = useCallback(() => {
    dispatch(
      getGalleryAction({
        ...galleryGetParams,
        page: page + 1,
        limit: SCROLL_LIMIT_GALLERY,
      })
    );
    setPage((prev) => prev + 1);
  }, [dispatch, galleryGetParams, page, setPage]);

  const calculateWidth = () => {
    const additionalWidth = ADDITIONAL_OFFSET;
    const shrinkWidth = COLUMN_WIDTH - additionalWidth;
    const grownWidth = COLUMN_WIDTH + additionalWidth;
    if (grownWidth * columnCount > MAX_WIDTH) return shrinkWidth;
    return grownWidth;
  };

  const handleCreateRoute = () => {
    if (!checkedPhotos?.length) return;
    const selectedCustomersCollection = new Map();

    checkedPhotos?.forEach((photo) => {
      selectedCustomersCollection.set(photo?.customer?.id, photo?.customer);
    });

    const checkedCustomers = Array.from(selectedCustomersCollection.values());

    navigate("/routes/new", { state: { checkedCustomers } });
  };

  useEffect(() => {
    const element = document.getElementById("gallery-table");
    element && element.scroll({ top: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [galleryGetParams]);

  return (
    <>
      <GalleryFilter
        imagesList={galleryList}
        handleEditPhoto={handleOpenProductDialog}
        {...{
          checkedPhotos,
          handleDeletePhotos,
          handleDownloadPhotos,
          handleCreateRoute,
          setPage,
          currentUser,
          handleOpenTags,
        }}
      />

      <TagsDrawer
        type={TAG_TYPES.product}
        open={openTagsDrawer}
        handleClose={handleCloseTagsDrawer}
        productTags={productTags || []}
        isBulk={checkedPhotos.length > 1}
        loading={loadingTagsDrawer}
        handleSave={({ tags }) => {
          handleSaveTags(tags);
        }}
        hideAdvanced
        setCheckedCustomers={() => {}}
      />

      {galleryList.length > 0 ? (
        <InfiniteLoader
          isRowLoaded={({ index }) => !!galleryList[index * columnCount]}
          loadMoreRows={handleFetch}
          rowCount={rowCount}
          threshold={1}
        >
          {({ onRowsRendered, registerChild }) => {
            return (
              <Box>
                <Grid
                  width={windowWidth}
                  id="gallery-table"
                  height={windowHeight - 240}
                  ref={registerChild}
                  columnWidth={COLUMN_WIDTH}
                  columnCount={columnCount}
                  rowCount={Math.ceil(galleryList?.length / columnCount)}
                  rowHeight={416}
                  overscanRowCount={10}
                  style={{
                    padding: "20px 32px",
                    overflowY: "overlay",
                    overflowX: "visible",
                  }}
                  cellRenderer={({ key, rowIndex, columnIndex, style }) => {
                    const productIndex = rowIndex * columnCount + columnIndex;
                    const product = galleryList[productIndex];
                    const galleryLastIndex = galleryList?.length - 1;
                    const width = calculateWidth();
                    const left = columnIndex * width + columnIndex * PHOTO_GAP;
                    if (
                      !product &&
                      !galleryLoading &&
                      galleryCount > galleryList.length &&
                      productIndex === galleryLastIndex + 1
                    ) {
                      return (
                        <Box
                          style={{
                            ...style,
                            top: style.top + 415,
                            left: "50%",
                            transform: "translateX(-50%)",
                          }}
                          sx={{
                            "& svg": {
                              width: "50px",
                              height: "50px",
                            },
                          }}
                        >
                          <InfiniteLoadMoreBtn onClick={handleFetch} />
                        </Box>
                      );
                    }
                    if (!product) {
                      if (galleryLoading)
                        return (
                          <ListRowWrapper
                            style={{
                              ...style,
                              border: "1px solid #d5d9d9",
                              borderRadius: "8px",
                              width,
                              left,
                            }}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            key={key}
                          >
                            <CircularProgress size={30} />
                          </ListRowWrapper>
                        );

                      return <></>;
                    }
                    return (
                      <ListRowWrapper
                        style={{
                          ...style,
                          width,
                          left,
                        }}
                        key={key}
                      >
                        <PhotoGroupCard
                          product={product}
                          handleSetProduct={(item) => {
                            setCurrentPage(productIndex + 1);
                            handleSetProduct(item);
                          }}
                          handleCheckPhoto={handleCheckPhoto}
                          timeZone={currentUser.timeZone}
                          isChecked={checkedPhotos.some(
                            (p) => p.id === product.id
                          )}
                        />
                      </ListRowWrapper>
                    );
                  }}
                  onSectionRendered={({ rowStartIndex, rowStopIndex }) => {
                    onRowsRendered({
                      startIndex: rowStartIndex,
                      stopIndex: rowStopIndex,
                    });
                  }}
                />
                {galleryLoading && (
                  <Box display="flex" justifyContent="center">
                    <CircularProgress size={30} />
                  </Box>
                )}
              </Box>
            );
          }}
        </InfiniteLoader>
      ) : (
        <Typography
          textAlign="center"
          fontWeight="300"
          fontSize="16px"
          mt="200px"
          color="#6A6A6A"
          pb="20px"
        >
          No photos found{" "}
        </Typography>
      )}
      <PhotoGroupPopup
        user={{
          avatar: currentUser.profilePhoto,
          name: currentUser.name,
          id: currentUser.id,
        }}
        isOpenProductDialog={isOpenProductDialog}
        handleCloseProductDialog={handleCloseProductDialog}
        currentPage={currentPage}
      />
    </>
  );
};

GalleryTab.propTypes = {
  downloadProgress: object,
  setDownloadProgress: func,
};

export default GalleryTab;
