import { Stack } from "@mui/material";
import { TasksHeader, TasksItem } from "./components";
import { useContext, useEffect } from "react";
import { TasksPageContext } from "Pages/TasksPage/TasksPage";
import {
  EmptyScreen,
  InfiniteLoaderWrapper,
  TableHeaderControlPanel,
} from "components";
import { tasksFilterAction } from "redux/actions/tasks";
import { useDispatch } from "react-redux";
import { STATUSES_TASKS } from "Pages/TasksPage/TasksPage.constants";

export const TasksList = () => {
  const dispatch = useDispatch();

  const {
    currentUser,
    repPermissions,
    checkedTasks,
    setCheckedTasks,
    handleCheckTask,
    checkAllTasks,
    handleSelectAvailableTasks,
    handleOpenAssignRapDrawer,
    handleOpenDueDateDrawer,

    handleCreateTask,

    tasksFilter,
    countTasks,
    existData,
    loadingTasksList,
    tasksList,
    handleBulkMarkAsCompleted,
    handleBulkMarkAsIncomplete,
    handleCreateRoute,
  } = useContext(TasksPageContext);

  const { timeZone } = currentUser || {};

  const handleSingleRoutesActions = () => {
    return [
      {
        label: "Edit task",
        element: null,
        disabled: false,
        onClick: () => handleCreateTask(checkedTasks?.[0]),
        show: true,
      },
      {
        label: "Mark as Completed",
        element: null,
        disabled: !checkedTasks.some(
          (task) => task.status === STATUSES_TASKS.PENDING
        ),
        onClick: () => handleBulkMarkAsCompleted(),
        show: tasksFilter.status !== STATUSES_TASKS.COMPLETED,
      },
      {
        label: "Mark as Incomplete",
        element: null,
        disabled: false,
        onClick: () => handleBulkMarkAsIncomplete(),
        show: tasksFilter.status === STATUSES_TASKS.COMPLETED,
      },
      {
        label: "Create route",
        element: null,
        disabled: false,
        onClick: () => handleCreateRoute(),
        show: true,
      },
      {
        label: "Assign rep",
        element: null,
        // disabled: !!repPermissions && !repPermissions?.routes?.assign,
        disabled: false,
        onClick: () => handleOpenAssignRapDrawer(),
        show: true,
      },
      {
        label: "Set due Date",
        element: null,
        onClick: () => handleOpenDueDateDrawer(),
        show: true,
      },
    ];
  };

  const handleMultipleRoutesActions = () => {
    return [
      {
        label: "Mark as Completed",
        element: null,
        disabled: !checkedTasks.some(
          (task) => task.status === STATUSES_TASKS.PENDING
        ),
        onClick: () => handleBulkMarkAsCompleted(),
        show: tasksFilter.status !== STATUSES_TASKS.COMPLETED,
      },
      {
        label: "Mark as Incomplete",
        element: null,
        disabled: false,
        onClick: () => handleBulkMarkAsIncomplete(),
        show: tasksFilter.status === STATUSES_TASKS.COMPLETED,
      },
      {
        label: "Create route",
        element: null,
        disabled: false,
        onClick: () => handleCreateRoute(),
        show: true,
      },
      {
        label: "Assign rep",
        element: null,
        // disabled: !!repPermissions && !repPermissions?.routes?.assign,
        disabled: false,
        onClick: () => handleOpenAssignRapDrawer(),
        show: true,
      },
      {
        label: "Set due Date",
        element: null,
        onClick: () => handleOpenDueDateDrawer(),
        show: true,
      },
    ];
  };

  const handleGetQuickActions = (params) => {
    if (!checkedTasks.length) return [];

    if (checkedTasks.length === 1) {
      return handleSingleRoutesActions(params).slice(0, 5);
    }
    return handleMultipleRoutesActions(params).slice(0, 5);
  };

  const handleGetDropDownActions = (params) => {
    if (!checkedTasks.length) return [];

    if (checkedTasks.length === 1) {
      return handleSingleRoutesActions(params).slice(5, 6);
    }
    return handleMultipleRoutesActions(params).slice(5, 6);
  };

  const QUICK_ACTIONS = handleGetQuickActions();

  const DROPDOWN_ACTIONS = handleGetDropDownActions();

  const handleNextFunc = () => {
    dispatch(tasksFilterAction({ ...tasksFilter, page: tasksFilter.page + 1 }));
  };

  useEffect(() => {
    return () => {
      dispatch(tasksFilterAction({ ...tasksFilter, page: 1 }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return tasksList?.length ? (
    <Stack
      sx={{
        height: "100%",
        maxHeight: "calc(100vh - 287px)",
        overflow: "hidden",
        borderWidth: "0 1px 1px 1px",
        borderStyle: "solid",
        borderColor: "#D5D9D9",
        borderRadius: "0 0 4px 4px",
      }}
    >
      <TableHeaderControlPanel
        actionWrapperProps={{ sx: { padding: "0 19px 0 14px !important" } }}
        checkedCount={checkedTasks.length}
        actionsList={QUICK_ACTIONS}
        loading={false}
        dropDownActions={DROPDOWN_ACTIONS}
        onSelectAll={(value) => checkAllTasks(value)}
        hasCheckedItems={!!checkedTasks.length}
        availableSelectCount={countTasks}
        selectName="task"
        onSelectVisible={handleSelectAvailableTasks}
        cancelSelection={() => setCheckedTasks([])}
        headerComponent={<TasksHeader />}
      />

      <InfiniteLoaderWrapper
        itemsList={tasksList}
        itemsCount={countTasks}
        offsetHeight={288}
        offsetWidth={74}
        loading={loadingTasksList}
        hideScroll
        handleFetch={handleNextFunc}
        itemHeight={49}
        tableStyle={{
          borderWidth: "0px",
          "& .ReactVirtualized__Grid__innerScrollContainer": {
            marginBottom: "34px",
          },
        }}
        renderChildren={(index) => (
          <TasksItem
            task={tasksList[index]}
            showBorder={index === tasksList.length - 1}
            isChecked={checkedTasks.some(
              (checkedTask) => checkedTask?.id === tasksList[index]?.id
            )}
            timeZone={timeZone}
            handleCheckTask={handleCheckTask}
            handleEditTask={handleCreateTask}
          />
        )}
      />
    </Stack>
  ) : (
    <EmptyScreen
      type="task"
      height="calc(100vh - 470px)"
      onConfirm={() => handleCreateTask()}
      showAction={!existData}
      loading={loadingTasksList}
      disabled={!!repPermissions && !repPermissions?.tasks?.create_edit}
    />
  );
};
