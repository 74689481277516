/* eslint-disable react/prop-types */
import React from "react";

export const PaperIcon = ({ size = 22, color = "#42a57f" }) => {
  return (
    <svg
      id="Icon_feather-file-text"
      data-name="Icon feather-file-text"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 17.181 22"
    >
      <g
        id="Path_1719"
        data-name="Path 1719"
        transform="translate(-6 -3)"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d="M16.738,3H8.148A2.174,2.174,0,0,0,6,5.2V22.8A2.174,2.174,0,0,0,8.148,25H21.033a2.174,2.174,0,0,0,2.148-2.2V9.6Z"
          stroke="none"
        />
        <path
          d="M 8.147615432739258 3.799999237060547 C 7.404535293579102 3.799999237060547 6.799995422363281 4.42803955078125 6.799995422363281 5.19999885559082 L 6.799995422363281 22.79999923706055 C 6.799995422363281 23.57195854187012 7.404535293579102 24.19999885559082 8.147615432739258 24.19999885559082 L 21.0333251953125 24.19999885559082 C 21.77641677856445 24.19999885559082 22.38095664978027 23.57195854187012 22.38095664978027 22.79999923706055 L 22.38095664978027 9.925739288330078 L 16.40106391906738 3.799999237060547 L 8.147615432739258 3.799999237060547 M 8.147615432739258 2.999998092651367 L 16.73809623718262 2.999998092651367 L 23.18095588684082 9.599998474121094 L 23.18095588684082 22.79999923706055 C 23.18095588684082 24.01501846313477 22.21942520141602 25 21.0333251953125 25 L 8.147615432739258 25 C 6.961515426635742 25 5.999996185302734 24.01501846313477 5.999996185302734 22.79999923706055 L 5.999996185302734 5.19999885559082 C 5.999996185302734 3.984968185424805 6.961515426635742 2.999998092651367 8.147615432739258 2.999998092651367 Z"
          stroke="none"
          fill={color}
        />
      </g>
      <path
        id="Path_1720"
        data-name="Path 1720"
        d="M21,3V9.9h6.9"
        transform="translate(-11.869 -2)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.8"
      />
      <path
        id="Path_1721"
        data-name="Path 1721"
        d="M19.981,19.5H12"
        transform="translate(-7.4 -7.4)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.8"
      />
      <path
        id="Path_1722"
        data-name="Path 1722"
        d="M19.981,25.5H12"
        transform="translate(-7.4 -9)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.8"
      />
      <path
        id="Path_1723"
        data-name="Path 1723"
        d="M14.3,13.5H12"
        transform="translate(-7.749 -5.8)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.8"
      />
    </svg>
  );
};
