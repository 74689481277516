import * as React from "react";
export const ActivateBigIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={90}
    height={90}
    viewBox="0 0 90 90"
    {...props}
  >
    <path
      d="M45 90A45 45 0 1 0 0 45a45 45 0 0 0 45 45Zm20.689-56.148a4.311 4.311 0 1 0-6.889-5.189L38.941 55.018l-6.9-6.434a4.311 4.311 0 1 0-5.881 6.305l7.722 7.2a7.608 7.608 0 0 0 11.265-.985Z"
      fill="#00a252"
      fillRule="evenodd"
    />
  </svg>
);
