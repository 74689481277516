import { object, string, func } from "prop-types";
import { Box, Typography } from "@mui/material";
import { CheckmarkIcon, CrossIcon, InformationIcon } from "components/Icons";
import { StyledTooltip } from "components";

export const StatusBlock = ({ user, handleResetEmail, repPermissions }) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {user.baseUser?.isConfirmed || user?.role === "THIRD_PARTY" ? (
        user.active ? (
          <CheckmarkIcon />
        ) : (
          <CrossIcon fill="#FF0000" />
        )
      ) : (
        <StyledTooltip
          isDark
          arrow
          title={
            <Box textAlign="left">
              <Typography fontSize={13} color="#FFF">
                Rep&apos;s email has not been verified yet.
                <br /> They must verify their email to gain <br />
                access.{" "}
                <span
                  onClick={(e) => {
                    if (repPermissions) return;
                    handleResetEmail(e);
                  }}
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    fontWeight: 600,
                    fontSize: "13px",
                    whiteSpace: "nowrap",
                  }}
                >
                  Resend Verification
                </span>
              </Typography>
            </Box>
          }
          placement="top"
          PopperProps={{
            modifiers: [{ name: "offset", options: { offset: [0, -4] } }],
          }}
        >
          <Box display="flex" alignItems="center">
            <InformationIcon size="17" stroke="#FF6969" />
          </Box>
        </StyledTooltip>
      )}
    </Box>
  );
};

StatusBlock.propTypes = {
  user: object,
  currentTab: string,
  handleResetEmail: func,
  repPermissions: object,
};
StatusBlock.defaultProps = {
  user: {},
  currentTab: "",
  handleResetEmail: () => {},
};
