import { object, number, string, array, func } from "prop-types";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { StyledAvatar, StyledTooltip } from "components";
import { photoUrl } from "helpers/helpers";
import Slider from "react-slick";

export const HeaderItem = ({
  headerBlockRef,
  customerNameRef,
  repPermissions,
  customerId,
  classes,
  displayName,
  name,
  customId,
  address,
  categories,
  assignedRepresentatives,
  customerNameWidth,
  sliderSettings,
  handleAvatarContent,
}) => {
  return (
    <Box
      ref={headerBlockRef}
      display="flex"
      gap="14px"
      justifyContent="space-between"
      alignItems="center"
      sx={{ minWidth: "160px" }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "fit-content",
        }}
      >
        <Box
          width="max-content"
          display="flex"
          gap="4px"
          alignItems="center"
          ref={customerNameRef}
        >
          <Typography
            maxWidth="500px"
            fontSize={14}
            fontWeight={500}
            color="primary"
            component={
              repPermissions
                ? repPermissions?.customers?.view
                  ? Link
                  : "span"
                : Link
            }
            sx={{ textDecoration: "none" }}
            to={`/customers/${customerId}`}
            noWrap
          >
            {displayName || name}
          </Typography>
          <Typography
            maxWidth="100px"
            fontSize={14}
            fontWeight={400}
            color="#707070"
            noWrap
          >
            ID: {customId}
          </Typography>
        </Box>

        <Box>
          <Typography
            fontSize={12}
            color="#A7A6A6"
            maxWidth={
              customerNameWidth > 240 ? `${customerNameWidth}px` : "240px"
            }
            noWrap
          >
            {address}
          </Typography>
        </Box>
      </Box>

      {!!categories?.length && (
        <Box position="absolute" sx={{ top: "3px", right: "3px" }}>
          {categories.map(({ name, id }) => (
            <Typography
              textAlign="right"
              sx={{ background: "#d5d9d9" }}
              fontSize="10px"
              key={id}
            >
              {name}
            </Typography>
          ))}
        </Box>
      )}

      {!!assignedRepresentatives?.length && (
        <Box className={classes.sliderWrapper}>
          <Slider {...sliderSettings}>
            {assignedRepresentatives.map(({ representative: rep }) => (
              <StyledTooltip
                placement="top"
                arrow
                title={handleAvatarContent(rep)}
                key={rep.id}
                PopperProps={{
                  modifiers: [
                    {
                      name: "offset",
                      options: { offset: [0, -5] },
                    },
                  ],
                }}
              >
                <StyledAvatar
                  className={classes.assignedAvatar}
                  name={rep.name}
                  src={photoUrl(rep.profilePhoto?.fileName)}
                  stringStyle={{ fontSize: 10 }}
                />
              </StyledTooltip>
            ))}
          </Slider>
        </Box>
      )}
    </Box>
  );
};

HeaderItem.propTypes = {
  headerBlockRef: object,
  customerNameRef: object,
  repPermissions: object,
  customerId: string,
  classes: object,
  displayName: string,
  name: string,
  customId: string,
  address: string,
  categories: array,
  assignedRepresentatives: array,
  customerNameWidth: number,
  sliderSettings: object,
  handleAvatarContent: func,
};
HeaderItem.defaultProps = {
  headerBlockRef: {},
  customerNameRef: {},
  repPermissions: {},
  customerId: 0,
  classes: {},
  displayName: "",
  name: "",
  customId: "",
  address: "",
  categories: [],
  assignedRepresentatives: [],
  customerNameWidth: 0,
  sliderSettings: {},
  handleAvatarContent: () => {},
};
