import { getCategoriesService } from "../../services/categories";
import { error } from "../../utils/notifications";

export const START_LOADING_CATEGORIES = "START_LOADING_CATEGORIES";
export const END_LOADING_CATEGORIES = "  END_LOADING_CATEGORIES";
export const GET_CATEGORIES_LIST = "  GET_CATEGORIES_LIST";
export const GET_CATEGORIES_COUNT = "  GET_CATEGORIES_COUNT";

const startLoadingCategories = () => ({
  type: START_LOADING_CATEGORIES,
});

const endLoadingCategories = () => ({
  type: END_LOADING_CATEGORIES,
});

const getCategoriesList = (payload) => ({
  type: GET_CATEGORIES_LIST,
  payload,
});

const getCategoriesCount = (payload) => ({
  type: GET_CATEGORIES_COUNT,
  payload,
});

export const getCategoriesListAction = (data) => {
  return (dispatch) => {
    dispatch(startLoadingCategories());
    getCategoriesService(data)
      .then((res) => {
        dispatch(getCategoriesList(res?.rows));
        dispatch(getCategoriesCount(res?.count));
      })
      .catch((err) => {
        error(err?.response?.data?.message || "Something went wrong.");
        // eslint-disable-next-line no-console
        console.error(err?.message);
      })
      .finally(() => {
        dispatch(endLoadingCategories());
      });
  };
};
