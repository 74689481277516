export const customersListSelector = (state) => state.customers.customers;
export const customersLoadingSelector = (state) => state.customers.loading;
export const customersDefaultFiltersSelector = (state) =>
  state.customers.customersFilters;
export const customersDefaultSwitchSelector = (state) =>
  state.customers.customersSwitch;
export const customersByRepListSelector = (state) =>
  state.customers.repCustomers;

export const customersByRepInactiveListSelector = (state) =>
  state.customers.repCustomersInactive;

export const inactiveCustomersCountSelector = (state) =>
  state.customers.inactiveCustomersCount;

export const customersByRepLoadingSelector = (state) =>
  state.customers.repCustomersLoading;

export const customersInactiveListSelector = (state) =>
  state.customers.inactive;

export const customersShowInactiveSelector = (state) =>
  state.customers.showInactive;

export const customersStatusFilterSelector = (state) => state.customers.status;

export const customersShowInactiveByRepSelector = (state) =>
  state.customers.showInactiveByRep;

export const customersStatusFilterByRepSelector = (state) =>
  state.customers.statusByRep;

export const customersGroupSwitchSelector = (state) =>
  state.customers.customersSwitchGroup;

export const customersGroupFilterSelector = (state) =>
  state.customers.customersFiltersGroup;

export const customersGetParamsSelector = (state) =>
  state.customers.customersGetParams;
export const customersCountSelector = (state) => state.customers.count;
export const customersInactiveCountSelector = (state) =>
  state.customers.countInactive;

export const customersExistSelector = (state) => state.customers.existData;

export const customersByRepCountSelect = (state) =>
  state.customers.repCustomersCount;

export const customersByRepActiveCountSelect = (state) =>
  state.customers.repCustomersActiveCount;

export const customersByRepInactiveCountSelect = (state) =>
  state.customers.repCustomersInactiveCount;

export const customersCountDataSelector = (state) => state.customers.countData;

export const inactiveCustomersCountDataSelector = (state) =>
  state.customers.inactiveCountData;

export const searchCustomersSelector = (state) =>
  state.customers.searchCustomers;

export const searchGroupsSelector = (state) => state.customers.searchGroups;
