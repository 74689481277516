import { Menu } from "@mui/material";
import Picker from "emoji-picker-react";
import { func, object } from "prop-types";

export const EmojiPicker = ({ anchorEl, handleClose, onEmojiClick }) => {
  return (
    <Menu
      PaperProps={{
        style: {
          transform: "translateX(-100%) ",
        },
      }}
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={handleClose}
    >
      <Picker onEmojiClick={onEmojiClick} />
    </Menu>
  );
};

EmojiPicker.propTypes = {
  handleClose: func,
  anchorEl: object,
  onEmojiClick: func,
};

EmojiPicker.deafultProps = {
  isOpen: false,
  anchorEl: null,
};

export default EmojiPicker;
