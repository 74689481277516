import React, { useRef, useState } from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { StyledButton, StyledPopper, UploadFile } from "components";
import useStyles from "./styles";
import { useSelector } from "react-redux";
import { useAdmin, photoUrl } from "helpers/helpers";
import {
  CUSTOMER_SHOP_LINK_LOGIN,
  STORE_LOGO_ASPECT,
  removeProtocol,
} from "../../OrderDirectTab.constants";
import { success } from "utils/notifications";
import { useSettingsOrderDirect } from "../../OrderDirectTab.hooks";
import { object, func } from "prop-types";

const StoreLink = ({ widgetState, updateWidgetState }) => {
  const isAdmin = useAdmin();
  const classes = useStyles();
  const {
    id: distributorId,
    brandLogo,
    storeName,
    allowStorePreview,
  } = useSelector(({ auth }) => auth?.currentUser);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(
      (allowStorePreview
        ? CUSTOMER_SHOP_LINK_LOGIN.replace("login", "preview")
        : CUSTOMER_SHOP_LINK_LOGIN) + storeName
    );
    success("Storefront Link Copied!");
  };

  const { handleUploadLogo } = useSettingsOrderDirect({
    updateWidgetState,
    widgetState,
  });

  const [isCroppedDialogOpen, setIsCroppedDialogOpen] = useState(false);

  const tooltipRef = useRef(null);

  const [tooltipData, setTooltipData] = useState({
    open: false,
    text: (
      <>
        <Typography textAlign="start" fontSize={12}>
          • Use at least 714 px by 200 px <br /> • Use white or neutral
          background
        </Typography>
      </>
    ),
    modifiers: [{ name: "offset", options: { offset: [5, 10] } }],
  });

  return (
    <Box className={classes.linkWrapper}>
      <Box>
        <Typography className={classes.storeLinkTitle}>
          Storefront Logo
        </Typography>
        <Box className={classes.logoBlockWrap}>
          <Box
            borderRadius="4px"
            width="96px"
            height="27px"
            border="1px solid #d5d9d9"
            component="img"
            src={photoUrl(brandLogo?.fileName)}
          />
          {!isAdmin && (
            <>
              <StyledPopper
                open={tooltipData.open && !isCroppedDialogOpen}
                anchorEl={tooltipRef.current}
                text={tooltipData.text}
                placement="top"
                transition
                aria-hidden="true"
              />
              <Box
                component="span"
                ref={tooltipRef}
                onMouseEnter={() =>
                  setTooltipData((prev) => ({ ...prev, open: true }))
                }
                onMouseLeave={() =>
                  setTooltipData((prev) => ({ ...prev, open: false }))
                }
                onMouseDown={() =>
                  setTooltipData((prev) => ({ ...prev, open: false }))
                }
              >
                <UploadFile
                  label="Upload new logo"
                  accept="image/png, image/gif, image/jpeg"
                  Wrapper={Button}
                  path="users/distributors/brand-logo"
                  defaultAspect={STORE_LOGO_ASPECT}
                  withCropper
                  onChange={handleUploadLogo}
                  setIsOpen={setIsCroppedDialogOpen}
                  uploadData={{
                    distributorId,
                    type: "BRAND_LOGO_DISTRIBUTOR",
                  }}
                />
              </Box>
            </>
          )}
          {widgetState.logoLoading && <CircularProgress size={20} />}
        </Box>
      </Box>
      <Box className={classes.storeLinkBlock}>
        <Typography className={classes.storeLinkTitle}>
          Storefront Invitation Link
        </Typography>
        <Box
          sx={{
            height: "30px",
            display: "flex",
            alignItems: "center",
            border: "1px solid #5F6267",
            borderRadius: "4px",
            fontSize: 12,
            color: "#5F6267",
            px: "14px",
          }}
          component={Typography}
          noWrap
        >
          {removeProtocol(
            allowStorePreview
              ? CUSTOMER_SHOP_LINK_LOGIN.replace("login", "preview")
              : CUSTOMER_SHOP_LINK_LOGIN
          )}
          <Box color="#C2C0C0" component="span">
            {storeName}
          </Box>
        </Box>
      </Box>
      <StyledButton
        className={classes.storeLinkButton}
        label="Copy Link"
        variant="contained"
        onClick={handleCopyLink}
      />
    </Box>
  );
};

StoreLink.propTypes = {
  widgetState: object,
  updateWidgetState: func,
};

export default StoreLink;
