import { useWatch } from "react-hook-form";
import { object, func, array } from "prop-types";

import { Grid } from "@mui/material";
import InventoryTabHeaderList from "./InventoryTabHeaderList";
import InventoryTabItemList from "./InventoryTabItemList";
import { photoUrl } from "../../../../helpers/helpers";

const InventoryTab = ({
  control,
  setValue,
  handleSaveInventoryItem,
  saveDataAfterChangePhotos,
  disabledUploadFile,
}) => {
  const formField = useWatch({ control });

  const onChangeOnHand = (id, v) => {
    if (formField.variationsFields.length) {
      const tempArr = [...formField.variationsFields];
      const currentEl = tempArr.find(
        (el) => el.id === id || el.customId === id
      );
      currentEl.inventory.onHand = +v;
      const currentPosition = tempArr.findIndex(
        (el) => el.id === id || el.customId === id
      );
      tempArr.splice(currentPosition, 1, currentEl);
      setValue("variationsFields", tempArr);
    } else {
      setValue("onHand", +v);
    }
  };

  const onChangePhoto = (id, v) => {
    const tempArr = [...formField.variationsFields];
    const currentEl = tempArr.find((el) => el.id === id);
    if (!currentEl) {
      setValue("mainPhotoUrl", photoUrl(v[0]?.fileName));
      setValue("photoIds", [v[0]?.id]);
      return;
    }
    currentEl.photoIds = [v[0]?.id];
    currentEl.photoUrl = [v[0]?.fileName];
    const currentPosition = tempArr.findIndex((el) => el.id === id);
    tempArr.splice(currentPosition, 1, currentEl);
    setValue("variationsFields", tempArr);
  };

  return (
    <>
      <Grid
        sx={{
          paddingX: "34px",
          paddingTop: "17px",
          paddingBottom: "34px",
        }}
        container
      >
        <Grid mt="14px" xs={12} item>
          <InventoryTabHeaderList />
          {formField.isMultiple ? (
            formField.variationsFields.map((product, i) => {
              product.name = formField?.name;
              return (
                <InventoryTabItemList
                  key={product?.id || product?.customId}
                  product={product}
                  positionOfList={i}
                  lastItem={formField.variationsFields.length === i + 1}
                  manufacturerName={formField.manufacturerName}
                  {...{
                    setValue,
                    control,
                    onChangePhoto,
                    onChangeOnHand,
                    handleSaveInventoryItem,
                    saveDataAfterChangePhotos,
                    disabledUploadFile,
                  }}
                />
              );
            })
          ) : (
            <InventoryTabItemList
              singleProduct
              product={formField}
              manufacturerName={formField.manufacturerName}
              {...{
                setValue,
                control,
                onChangePhoto,
                onChangeOnHand,
                handleSaveInventoryItem,
                saveDataAfterChangePhotos,
                disabledUploadFile,
              }}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};
InventoryTab.propTypes = {
  control: object,
  setValue: func,
  handleSaveInventoryItem: func,
  saveDataAfterChangePhotos: func,
  disabledUploadFile: array,
};

export default InventoryTab;
