import { array, func, bool, object } from "prop-types";
import { IOSSwitch, StyledButton } from "components";

import useStyles from "../../styles";

import { Box, Divider, MenuList, Stack } from "@mui/material";

export const MenuContent = ({
  switchesState,
  setTemporaryFields,
  setSwitchesState,
  rowItem,
  hasViewSave,
  onViewSave,
  onClose,
  handleApplyFilter,
  temporaryFields,
  contentProps,
  disabledApplyBtn,
}) => {
  const {
    menuContentProps,
    menuSwitchWrapperProps,
    menuButtonBlockProps,
    saveBtnProps,
    deleteBtnProps,
  } = contentProps || {};

  const classes = useStyles();
  return (
    <MenuList sx={{ width: "340px" }} {...menuContentProps}>
      <Stack className={classes.switchesContent}>
        {switchesState
          // .filter(hideArchivedIfQuickBooksConnected)
          .map((filter, i) => (
            <Box
              key={filter.value}
              {...menuSwitchWrapperProps}
              className={classes.menuRow}
            >
              <IOSSwitch
                small
                label={filter.label}
                checked={!!filter.checked}
                onChange={() => {
                  if (filter.checked)
                    setTemporaryFields((prev) => {
                      return {
                        ...prev,
                        [filter.value]: filter.type === "multiselect" ? [] : "",
                      };
                    });
                  const newState = switchesState;
                  const insert = {
                    ...filter,
                    checked: !filter.checked,
                  };
                  newState.splice(i, 1, insert);

                  if (filter.checked && filter?.value === "archived") {
                    setTemporaryFields((prev) => {
                      return { ...prev, [filter.value]: "" };
                    });
                  }
                  if (!filter.checked && filter?.value === "archived") {
                    setTemporaryFields((prev) => {
                      return { ...prev, [filter.value]: filter?.values?.[0] };
                    });
                  }

                  if (filter.checked && filter?.value === "repeated") {
                    setTemporaryFields((prev) => {
                      return { ...prev, [filter.value]: "" };
                    });
                  }
                  if (!filter.checked && filter?.value === "repeated") {
                    setTemporaryFields((prev) => {
                      return {
                        ...prev,
                        [filter.value]: {
                          ...filter,
                          checked: true,
                        },
                      };
                    });
                  }

                  if (filter.checked && filter?.value === "reorder_required") {
                    setTemporaryFields((prev) => {
                      return { ...prev, [filter.value]: "" };
                    });
                  }
                  if (!filter.checked && filter?.value === "reorder_required") {
                    setTemporaryFields((prev) => {
                      return { ...prev, [filter.value]: filter?.values?.[0] };
                    });
                  }

                  setSwitchesState([...newState]);
                }}
                formSx={{
                  "& .MuiFormControlLabel-label": { fontSize: "12px" },
                }}
              />
              {filter.checked && rowItem(filter)}
            </Box>
          ))}
      </Stack>
      <Divider />
      <Box className={classes.buttonsBlock} {...menuButtonBlockProps}>
        {!!deleteBtnProps && deleteBtnProps.showFunc(switchesState) && (
          <>
            <StyledButton
              label={deleteBtnProps.label}
              onClick={deleteBtnProps.onClick}
              variant="outlined"
              color="delete"
              sx={{ mr: "auto" }}
            />
          </>
        )}

        {hasViewSave && !!onViewSave && (
          <StyledButton
            label="Save View"
            onClick={() => onViewSave(temporaryFields)}
            variant="outlined"
            color="cancel"
            sx={{ mr: "auto" }}
          />
        )}
        <StyledButton
          label="Cancel"
          onClick={onClose}
          variant="outlined"
          color="cancel"
        />
        <StyledButton
          label="Apply"
          variant="contained"
          onClick={() => handleApplyFilter(switchesState, temporaryFields)}
          {...saveBtnProps}
          disabled={saveBtnProps?.disabled && disabledApplyBtn}
        />
      </Box>
    </MenuList>
  );
};

MenuContent.propTypes = {
  switchesState: array,
  setTemporaryFields: func,
  setSwitchesState: func,
  rowItem: func,
  onViewSave: func,
  temporaryFields: object,
  onClose: func,
  handleApplyFilter: func,
  hasViewSave: bool,
  disabledApplyBtn: bool,
  contentProps: object,
};

MenuContent.defaultProps = {
  switchesState: [],
  setTemporaryFields: () => {},
  setSwitchesState: () => {},
  rowItem: () => {},
  onViewSave: () => {},
  temporaryFields: {},
  hasViewSave: false,
  disabledApplyBtn: false,
  handleApplyFilter: () => {},
};
