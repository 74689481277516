export const HighestOrderIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12.267"
      height="12"
      viewBox="0 0 12.267 12"
    >
      <path
        id="icons8-descending-sorting"
        d="M4,5V6.067h6.4V5Zm9.067,0v9.967l-1.383-1.383-.75.75,2.283,2.3L13.6,17l.383-.367,2.283-2.3-.75-.75-1.383,1.383V5ZM4,7.133V8.2H9.333V7.133ZM4,9.267v1.067H8.267V9.267ZM4,11.4v1.067H7.2V11.4Zm0,2.133V14.6H6.133V13.533Zm0,2.133v1.067H5.067V15.667Z"
        transform="translate(-4 -5)"
        fill="#3f3f3f"
      />
    </svg>
  );
};
