export const RouteIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19.5 19.395"
    >
      <g fill="none">
        <path d="m6.118 2.435-2.736-.913A2 2 0 0 0 .75 3.42v11.783a2 2 0 0 0 1.368 1.9l4 1.333a2 2 0 0 0 1.264 0l4.736-1.583a2 2 0 0 1 1.264 0l2.736.912a2 2 0 0 0 2.632-1.896V4.087a2 2 0 0 0-1.367-1.9l-4-1.334a2 2 0 0 0-1.265 0L7.381 2.434a2 2 0 0 1-1.265 0Z" />
        <path data-name="Vector" d="M6.75 2.645Zm6-2v16" />
        <path data-name="Vector" d="M0.75 4.645Zm6-2v16" />
      </g>
    </svg>
  );
};
