import { Grid, Typography } from "@mui/material";

const labels = [
  {
    id: 1,
    name: "REPRESENTATIVE NAME",
    width: 5,
    position: "flex-start",
    paddingLeft: "24px",
  },
  { id: 2, name: "ROLE", width: 3, position: "flex-start" },
  { id: 3, name: "TERRITORY", width: 2, position: "flex-start" },
  { id: 4, name: "CUSTOMER COUNT", width: 2, position: "center" },
];

const ListHeader = () => {
  return (
    <Grid
      container
      sx={{
        border: "1px solid #D5D9D9",
        height: "40px",
        backgroundColor: "#EEEEEE",
        marginTop: "22px",
      }}
    >
      {labels.map((el) => (
        <Grid
          key={el.id}
          xs={el.width}
          sx={{
            display: "flex",
            justifyContent: el.position,
            alignItems: "center",
            paddingLeft: el.paddingLeft ? el.paddingLeft : "",
          }}
          item
        >
          <Typography variant="header" sx={{}}>
            {el.name}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};

export default ListHeader;
