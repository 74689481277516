import { useMemo } from "react";
import { func, object, array, number } from "prop-types";
import { Controller, useWatch } from "react-hook-form";
import moment from "moment";
import { Grid, Paper, TextField, Typography, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import StyledButton from "components/StyledButton";
import {
  getFormattedDate,
  useAdmin,
  photoUrl,
  formatDate,
} from "helpers/helpers";
import { TagsBlock } from "./TagsBlock/TagsBlock";
import { FulfillBy } from "Pages/CartPage/components";

const useStyles = makeStyles(() => ({
  multiTextInput: {
    fontSize: 14,
    fontWeight: 400,
    paddingTop: 10,
    paddingBottom: 11,
  },
}));

const OrderNote = ({
  order,
  currentUser,
  control,
  handleSaveNote,
  tagsList,
  orderTagsCount,
  handleFetchTags,
  tagsPage,
  setValue,
  errors,
  setError,
  clearErrors,
  repPermissions,
}) => {
  const isAdmin = useAdmin();
  const classes = useStyles();
  const cl = {
    textBody: {
      fontSize: 14,
      lineHeight: 2,
      color: "#000000",
      maxHeight: "26px",
      overflow: "hidden",
    },
    input: {
      "&::placeholder": {
        fontSize: "12px",
        fontWeight: 300,
        color: "#000000",
      },
    },
    textField: {
      mt: "2px",
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderRadius: "4px",
          border: "0.5px solid #D5D9D9",
        },
      },
    },
    fulfillBlockWrapper: {
      gap: "2px !important",
      "& .MuiTypography-root": {
        fontSize: "12px",
        color: "#000000",
        fontWeight: 500,
        textTransform: "uppercase",
      },
    },
  };

  const disabledRepPermissions = useMemo(() => {
    if (repPermissions) {
      return !repPermissions?.orders?.create_edit;
    }
    return false;
  }, [repPermissions]);

  const formField = useWatch({ control });

  const signature = useMemo(() => order?.signature, [order?.signature]);

  const setCreatedBy = useMemo(() => {
    if (order?.createdType === "QUICKBOOKS") {
      return <Typography sx={cl.textBody}>Quickbooks</Typography>;
    }
    if (order?.createdType === "DISTRIBUTOR") {
      return <Typography sx={cl.textBody}>Admin</Typography>;
    }

    if (order?.createdType === "ORDER_DIRECT") {
      return (
        <>
          <Typography sx={cl.textBody}>Customer</Typography>
          <Typography sx={{ ...cl.textBody, color: "#47A06D" }} noWrap>
            {order?.customer?.name}
          </Typography>
        </>
      );
    }

    return (
      <>
        <Typography sx={cl.textBody}>Sales Rep</Typography>
        <Typography sx={{ ...cl.textBody, color: "#47A06D" }}>
          {order?.salesDuplicate?.name}
        </Typography>
      </>
    );
  }, [
    cl.textBody,
    order?.createdType,
    order?.customer?.name,
    order?.salesDuplicate?.name,
  ]);

  const formattedDate = formatDate({
    utc_date: order?.createdAt,
    formatThisYear: "MMM D[,] h:mm a",
  });
  // const formattedFulfillByDate = formatDate({
  //   utc_date: order?.fulfillBy,
  //   formatThisYear: "MMM D[,] h:mm a",
  // });

  const showFulfillBy = useMemo(() => {
    const type = order?.type || order?.orderType;
    return type !== "THIRD_PARTY";
  }, [order?.orderType, order?.type]);

  return (
    <Grid xs={3.52} item>
      <Paper
        sx={{
          maxWidth: "924px",
          minHeight: "275px",
          pb: 1,
          pt: "14px",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
        elevation={0}
        variant="outlined"
      >
        <Grid>
          <Grid sx={{ alignItems: "center" }} xs={12} item container>
            <Typography
              sx={{ paddingLeft: "24px" }}
              fontSize={12}
              fontWeight={500}
              color="#000000"
              component={Grid}
              xs={4}
              item
            >
              CREATED
            </Typography>
            <Typography
              fontSize={12}
              fontWeight={500}
              color="#000000"
              component={Grid}
              xs={8}
              item
            >
              NOTE
            </Typography>
          </Grid>
          <Grid container>
            <Grid sx={{ paddingLeft: "24px", mt: "4px" }} xs={4} item>
              <Grid item>
                {setCreatedBy}
                <Typography sx={{ ...cl.textBody, opacity: "50%" }}>
                  {moment(
                    getFormattedDate(order?.createdAt, currentUser?.timeZone)
                  ).format(formattedDate)}
                </Typography>
              </Grid>
            </Grid>

            <Grid xs={8} item sx={{ height: "83px" }}>
              <Box
                sx={{
                  display: "flex",
                  paddingRight: 3,
                  position: "relative",
                }}
              >
                <Controller
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      placeholder="Add order note or delivery instructions"
                      multiline
                      rows={3}
                      sx={cl.textField}
                      fullWidth
                      InputProps={{
                        className: classes.multiTextInput,
                        inputProps: {
                          sx: cl.input,
                        },
                      }}
                      error={!!error}
                      {...field}
                    />
                  )}
                  name="orderNote"
                  control={control}
                />
                {formField.orderNote !== order?.note?.text && (
                  <StyledButton
                    disabled={disabledRepPermissions || isAdmin}
                    sx={{
                      width: order?.note?.text ? "72px" : "58px",
                      minWidth: order?.note?.text ? "72px" : "58px",
                      px: "4px",
                      height: "22px",
                      position: "absolute",
                      right: "33px",
                      bottom: "8px",
                      "& span": {
                        whiteSpace: "nowrap",
                        fontSize: "10px !important",
                      },
                    }}
                    label={order?.note?.text ? "Update Note" : "Add Note"}
                    variant="contained"
                    onClick={handleSaveNote}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid container>
          <Grid pl="23px" xs={4} item>
            {/* <Box
              sx={{
                height: "1px",
                borderTop: "1px solid #D5D9D9",
                width: "106px",
                margin: "13px 0 10px 0px",
              }}
            />
            <Typography sx={cl.textBody}>Fulfill by</Typography>
            <Typography sx={{ ...cl.textBody, opacity: "50%" }}>
              {order?.fulfillBy
                ? moment(
                    getFormattedDate(order?.fulfillBy, currentUser?.timeZone)
                  ).format(formattedFulfillByDate)
                : "-"}
            </Typography> */}
          </Grid>

          <Grid xs={8} item container gap="8px">
            {!!signature && (
              <Grid width="100%">
                <Grid
                  sx={{
                    //height: "45px",
                    display: "flex",
                    alignItems: "center",
                    mb: "2px",
                  }}
                  xs={12}
                  item
                >
                  <Typography fontSize={12} fontWeight={500} color="#000">
                    SIGNATURE
                  </Typography>
                </Grid>
                <Grid paddingRight={3} xs={12} item>
                  <Box
                    sx={{
                      //maxWidth: "332.27px",
                      height: "81px",
                      borderRadius: "4px",
                      border: "0.5px solid #D5D9D9",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                      component="img"
                      src={photoUrl(signature?.fileName)}
                      alt="Signature"
                    />
                  </Box>
                </Grid>
              </Grid>
            )}

            <Box components="span" width="100%">
              <TagsBlock
                {...{
                  control,
                  tagsList,
                  orderTagsCount,
                  handleFetchTags,
                  tagsPage,
                  setValue,
                  errors,
                  setError,
                  clearErrors,
                  repPermissions,
                  placeholderFontSize: "12px",
                  fieldSx: { sx: cl.textField },
                  textFieldProps: {
                    inputProps: {
                      sx: cl.input,
                    },
                  },
                }}
              />
            </Box>
          </Grid>
        </Grid>

        <Grid pb={1} container>
          <Grid xs={4} item />
          {showFulfillBy && (
            <Grid xs={8} item container>
              <Box width="100%" pr={3}>
                <Controller
                  render={({ field }) => (
                    <FulfillBy
                      disabled={
                        disabledRepPermissions ||
                        isAdmin ||
                        order?.orderStatus === "CANCELED"
                      }
                      value={field.value}
                      titleProps={{ sx: cl.fulfillBlockWrapper }}
                      inputProps={{
                        sx: cl.textField,
                        inputProps: {
                          placeholder: "Fulfill by date",
                          sx: cl.input,
                        },
                      }}
                      placeholder="Fulfill by date"
                      onChange={(time, keyboardVal) => {
                        let isoTime = "";
                        if (keyboardVal) {
                          const parsed = Date.parse(keyboardVal);
                          const d = parsed ? new Date(parsed) : "";
                          if (d) {
                            d.setHours(0);
                            d.setMinutes(0);
                            d.setSeconds(0);
                          }

                          isoTime = parsed ? d.toISOString() : "";
                        } else if (time) {
                          const d = new Date(time);
                          d.setHours(0);
                          d.setMinutes(0);
                          d.setSeconds(0);

                          isoTime = d.toISOString();
                        }
                        field.onChange(isoTime);
                      }}
                    />
                  )}
                  name="fulfillBy"
                  control={control}
                />
              </Box>
            </Grid>
          )}
        </Grid>
      </Paper>
    </Grid>
  );
};
OrderNote.propTypes = {
  order: object,
  currentUser: object,
  control: object,
  handleSaveNote: func,
  tagsList: array,
  orderTagsCount: number,
  handleFetchTags: func,
  tagsPage: number,
  setValue: func,
  errors: object,
  setError: func,
  clearErrors: func,
  setFirstRender: func,
  repPermissions: object,
};

export default OrderNote;
