import { useState } from "react";
import { bool, func, object, any } from "prop-types";
import { Box, Grid, Typography } from "@mui/material";
import { PreviewPic, StyledTooltip, UploadFiles } from "components";
import Slider from "react-slick";
import { NextArrow, PrevArrow } from "../../../Arrows";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { photoUrl } from "helpers/helpers";

export const ImagesBlock = ({
  isImagesLoading,
  handleUpload,
  formField,
  isAdmin,
  handleOpenGalleryWithIndex,
  handleChangePic,
  handleMovePic,
  handleDeletePic,
  inputRef,
  adminIsAllowed,
}) => {
  const sliderSettings = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const [isHoverImg, setIsHoverImg] = useState({});

  return (
    <Grid mt="-5px" xs={12} item>
      <Box display="flex" alignItems="flex-end">
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 500,
            color: "#707070",
          }}
        >
          Product Images
        </Typography>
        <StyledTooltip
          title={
            <Typography textAlign="start" fontSize={12}>
              • Use at least 500 px by 500 px <br /> • Use white or neutral
              background
            </Typography>
          }
          arrow
          PopperProps={{
            modifiers: [
              {
                name: "offset",
                options: { offset: [0, -6] },
              },
            ],
          }}
          placement="top"
        >
          <Box width="125px">
            <UploadFiles
              disabled={!!isImagesLoading}
              accept="image/png, image/gif, image/jpeg"
              label={isImagesLoading ? "Loading..." : "Upload"}
              onChange={handleUpload}
              adminIsAllowed={adminIsAllowed}
            />
          </Box>
        </StyledTooltip>
      </Box>
      <Typography
        sx={{
          marginTop: 1,
          fontSize: 12,
          color: "#9E9E9E",
        }}
      >
        Maximum 6 images are allowed. You can arrange the order after uploading.
      </Typography>
      <Box
        sx={{
          marginTop: 1,
          width: "100%",
          "& .slick-track": {
            display: "flex",
            alignItems: "center",
            ml: "unset",
          },
        }}
      >
        <Slider {...sliderSettings}>
          {formField?.photos?.length > 0 &&
            formField.photos.map((image, index) => (
              <Box
                key={image?.uuid || image?.id}
                onMouseEnter={() => {
                  if (isAdmin) return;
                  setIsHoverImg(image);
                }}
                onMouseLeave={() => {
                  if (isAdmin) return;
                  setIsHoverImg({});
                }}
                position="relative"
              >
                <PreviewPic
                  onClick={() => handleOpenGalleryWithIndex(index)}
                  src={photoUrl(image?.fileName)}
                  mainImg={formField?.images?.[0]}
                  isHoverImg={isHoverImg}
                  id={image?.uuid || image?.id}
                  onChangeEl={handleChangePic}
                  onMove={handleMovePic}
                  onDelete={handleDeletePic}
                  inputRef={inputRef}
                  image={image}
                  loading={isImagesLoading}
                />
              </Box>
            ))}
        </Slider>
      </Box>
    </Grid>
  );
};

ImagesBlock.propTypes = {
  isImagesLoading: bool,
  handleUpload: func,
  formField: object,
  isAdmin: bool,
  handleOpenGalleryWithIndex: func,
  handleChangePic: func,
  handleMovePic: func,
  handleDeletePic: func,
  inputRef: any,
  adminIsAllowed: bool,
};
