import React, { useCallback, useEffect, useMemo, useState } from "react";
import { object, array, func, bool, string, number } from "prop-types";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import {
  DiagramIcon,
  HouseIcon,
  OrderIcon,
  PeopleIcon,
  PlacesIcon,
  WalletIcon,
} from "../../../../../components/Icons/WidgetTiles";
import { OrdersList, ActivitiesList } from "../../../components";
import SalesChart from "../../../SalesChart";
import SmallWidget from "../../../WidgetTiles/SmallWidget";
import InsightsWidget from "../../../WidgetTiles/InsightsWidget";
import PromotionsWidget from "../../../WidgetTiles/PromotionsWidget/PromotionsWidget";
import moment from "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import { repsGetFilterSelector, repsListSelector } from "redux/selectors/reps";
import { createSelector } from "reselect";
import { repsGetFiltersAction } from "redux/actions/reps";
import {
  customerGetFiltersAction,
  customerGetSwitchFieldActions,
} from "redux/actions/customers";
import {
  customersDefaultFiltersSelector,
  customersDefaultSwitchSelector,
} from "redux/selectors/customers";
import { useRepsPermissions } from "helpers/hooks";

const selector = createSelector(
  repsListSelector,
  repsGetFilterSelector,
  customersDefaultFiltersSelector,
  customersDefaultSwitchSelector,
  (repsList, repsFilter, customersDefaultFilters, customersDefaultSwitch) => ({
    repsList,
    repsFilter,
    customersDefaultFilters,
    customersDefaultSwitch,
  })
);

const ListView = ({
  isLoadingTopSummaries,
  isLoadingOrdersListAndTotalOrders,
  isLoadingActivitiesList,
  isLoadingBottomLeftPart,
  topSummaries,
  ordersList,
  handleChosenOrderTab,
  insights,
  activitiesList,
  chartList,
  currentFilterDate,
  currentRepresentative,
  isMerchendiser,
  handleFetchOrders,
  handleFetchActivities,
  hasMoreActivities,
  dataLength,
  hasMore,
  currentTabOrders,
  setCurrentTabOrders,
  currentTabActivities,
  setCurrentTabActivities,
  params,
  isThirdParty,
  getActivities,
}) => {
  const [topSummariesList, setTopSummaries] = useState([]);
  const repPermissions = useRepsPermissions();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    repsList,
    repsFilter,
    customersDefaultFilters,
    customersDefaultSwitch,
  } = useSelector(selector);

  const setNotAvailable = (title) => {
    if (isThirdParty) return !["Sales (USD)", "Orders"].includes(title);
    if (isMerchendiser) return ["Sales (USD)", "Orders"].includes(title);
  };

  const handleRedirect = useCallback(
    (type) => {
      const { date, start_date, end_date, representativeId } = params;

      let dateName = date || null;
      if (dateName) {
        dateName = dateName.replace("_", " ");
        dateName = dateName.charAt(0).toUpperCase() + dateName.slice(1);
      }
      if (start_date) {
        dateName = moment(start_date).format("MM/DD/YYYY");
      }

      if (end_date) {
        dateName = `${dateName} - ${moment(end_date).format("MM/DD/YYYY")}`;
      }

      let preparedRep = null;

      if (
        representativeId === "admin" &&
        !["new_customers", "visited_customers"].includes(type)
      ) {
        preparedRep = {
          label: "Created by",
          name: "Admin",
          value: "distributor",
        };
      }

      if (representativeId !== "admin" && representativeId) {
        const rep = repsList.find((el) => el?.id === representativeId);
        preparedRep = {
          label: "Created by",
          name: rep?.name,
          value: representativeId,
        };
      }

      if (type === "orders" || type === "sales") {
        const preparedData = {
          date: {
            label: "Created at",
            name: dateName,
            value: date ? dateName : { date, start_date, end_date },
          },
          sales_id: preparedRep,
        };

        if (!preparedRep) delete preparedData.sales_id;

        localStorage.setItem(
          "orderData",
          JSON.stringify({ filters: { ...preparedData } })
        );

        if (repPermissions && !repPermissions?.orders?.view) {
          return;
        }

        return navigate("/orders");
      }

      if (type === "new_customers") {
        const preparedData = {
          created_at: {
            label: "Created At",
            name: dateName,
            value: date ? dateName : { date, start_date, end_date },
          },
        };

        if (preparedRep) preparedData.representative_id = preparedRep;

        let newSwitches = customersDefaultSwitch;

        Object.keys(preparedData).forEach((key) => {
          const replaceIndex = newSwitches.findIndex((s) => s.value === key);

          if (replaceIndex !== -1) {
            newSwitches[replaceIndex] = {
              ...newSwitches[replaceIndex],
              checked: true,
            };
          }
        });

        if (repPermissions && !repPermissions?.customers?.view) {
          return;
        }

        dispatch(customerGetSwitchFieldActions(newSwitches));

        dispatch(
          customerGetFiltersAction({
            ...customersDefaultFilters,
            ...preparedData,
          })
        );

        // skipCustomerReset - avoid reset filters for customers located in the Nav Bar
        return navigate("customers", { state: { skipCustomerReset: true } });
      }

      if (type === "working_reps") {
        if (repPermissions && !repPermissions?.representatives?.view) {
          return;
        }
        dispatch(
          repsGetFiltersAction({
            ...repsFilter,
            work_status: "working",
          })
        );
        return navigate("/representatives");
      }

      if (type === "visited_customers") {
        const preparedData = {
          last_visit: {
            label: "Last visit",
            name: dateName,
            value: date ? dateName : { date, start_date, end_date },
          },
        };

        if (preparedRep) preparedData.representative_id = preparedRep;

        let newSwitches = customersDefaultSwitch;

        Object.keys(preparedData).forEach((key) => {
          const replaceIndex = newSwitches.findIndex((s) => s.value === key);

          if (replaceIndex !== -1) {
            newSwitches[replaceIndex] = {
              ...newSwitches[replaceIndex],
              checked: true,
            };
          }
        });

        if (repPermissions && !repPermissions?.customers?.view) {
          return;
        }

        dispatch(customerGetSwitchFieldActions(newSwitches));

        dispatch(
          customerGetFiltersAction({
            ...customersDefaultFilters,
            ...preparedData,
          })
        );
      }

      // skipCustomerReset - avoid reset filters for customers located in the Nav Bar
      return navigate("customers", { state: { skipCustomerReset: true } });
    },
    [
      customersDefaultFilters,
      customersDefaultSwitch,
      dispatch,
      navigate,
      params,
      repPermissions,
      repsFilter,
      repsList,
    ]
  );

  const rawData = useMemo(
    () => [
      {
        id: 1,
        title: "Sales (USD)",
        name: "sales",
        value: 0,
        icon: <WalletIcon />,
        onClick:
          repPermissions && !repPermissions?.orders?.view
            ? null
            : () => handleRedirect("sales"),
      },
      {
        id: 2,
        title: "Orders",
        name: "orders",
        value: 0,
        icon: <OrderIcon />,
        onClick:
          repPermissions && !repPermissions?.orders?.view
            ? null
            : () => handleRedirect("orders"),
      },
      {
        id: 3,
        title: "New Customers",
        name: "newCustomers",
        value: 0,
        icon: <HouseIcon />,
        onClick:
          repPermissions && !repPermissions?.customers?.view
            ? null
            : () => handleRedirect("new_customers"),
      },
      {
        id: 4,
        title: "Working Reps",
        name: "workingReps",
        value: 0,
        icon: <PeopleIcon />,
        onClick:
          repPermissions && !repPermissions?.representatives?.view
            ? null
            : () => handleRedirect("working_reps"),
      },
      {
        id: 5,
        title: "Visited Customers",
        name: "placeVisits",
        value: 0,
        icon: <PlacesIcon />,
        onClick:
          repPermissions && !repPermissions?.customers?.view
            ? null
            : () => handleRedirect("visited_customers"),
      },
      {
        id: 6,
        title: "Total Activities",
        name: "totalActivities",
        value: 0,
        icon: <DiagramIcon />,
      },
    ],
    [handleRedirect, repPermissions]
  );

  useEffect(() => {
    let store = [];
    rawData.forEach((el) => {
      el.value = topSummaries[el.name];
      store.push(el);
    });
    setTopSummaries(store);
  }, [rawData, topSummaries]);

  return (
    <>
      <Grid container columnSpacing={2.5}>
        {topSummariesList.map((el) => (
          <React.Fragment key={el?.id}>
            <Grid xs={2} item>
              <SmallWidget
                currentFilterDate={currentFilterDate}
                isLoading={isLoadingTopSummaries}
                title={el?.title}
                value={el?.value}
                icon={el?.icon}
                previousSum={el?.previousSum}
                previousPeriod={el?.previousPeriod}
                previousPercent={el?.previousPercent}
                notAvailable={setNotAvailable(el?.title)}
                onClick={el?.onClick}
              />
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
      <Grid container columnSpacing={2.5}>
        <Grid item xs={6} mt={1.5}>
          <OrdersList
            isLoading={isLoadingOrdersListAndTotalOrders}
            ordersList={ordersList}
            handleChosenOrderTab={handleChosenOrderTab}
            handleFetchOrders={handleFetchOrders}
            dataLength={dataLength}
            hasMore={hasMore}
            currentTabOrders={currentTabOrders}
            setCurrentTabOrders={setCurrentTabOrders}
            {...{ isThirdParty, isMerchendiser }}
          />
        </Grid>
        <Grid item xs={6} mt={1.5}>
          <ActivitiesList
            isLoading={isLoadingActivitiesList}
            activitiesList={activitiesList}
            dataLength={activitiesList?.length}
            hasMore={hasMoreActivities}
            currentTabActivities={currentTabActivities}
            setCurrentTabActivities={setCurrentTabActivities}
            handleFetchActivities={handleFetchActivities}
            params={params}
            notAvailable={isThirdParty}
            getActivities={getActivities}
          />
        </Grid>
      </Grid>
      <Grid
        sx={{
          justifyContent: currentRepresentative && "center",
        }}
        container
        columnSpacing={2.5}
      >
        <Grid item xs={3} mt={1.5}>
          <InsightsWidget
            {...{ insights }}
            loading={isLoadingBottomLeftPart}
            currentFilterDate={{
              date: params.date || {
                start_date: params.start_date,
                end_date: params.end_date,
              },
              start_date: params.start_date,
              end_date: params.end_date,
              name: currentFilterDate,
            }}
            notAvailable={isThirdParty}
            isMerchendiser={isMerchendiser}
          />
        </Grid>
        <Grid item xs={3} mt={1.5}>
          <PromotionsWidget notAvailable={isThirdParty} />
        </Grid>
        <Grid item xs={6} mt={1.5}>
          <SalesChart
            chartList={chartList}
            currentFilterDate={currentFilterDate}
          />
        </Grid>
      </Grid>
    </>
  );
};
ListView.propTypes = {
  isLoadingTopSummaries: bool,
  isLoadingOrdersListAndTotalOrders: bool,
  isLoadingActivitiesList: bool,
  isLoadingBottomLeftPart: bool,
  topSummaries: object,
  ordersList: array,
  handleChosenOrderTab: func,
  insights: object,
  activitiesList: array,
  chartList: array,
  currentFilterDate: string,
  currentRepresentative: string,
  isMerchendiser: bool,
  handleFetchOrders: func,
  handleFetchActivities: func,
  hasMoreActivities: bool,
  dataLength: number,
  hasMore: bool,
  currentTabOrders: object,
  setCurrentTabOrders: func,
  currentTabActivities: string,
  setCurrentTabActivities: func,
  params: object,
  isThirdParty: bool,
  getActivities: func,
};

export default ListView;
