import { memo, useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { bool, func, string } from "prop-types";
import { CrossIcon } from "../../../../components/Icons";
import StyledButton from "../../../../components/StyledButton";
import useStyles from "./styles";
import { StyledTooltip } from "components";

const ApplyDiscountPopup = memo(
  ({
    isOpen,
    handleClose,
    handleApplyDiscount,
    isDisablemsdBtn,
    deliveryStatus,
  }) => {
    const classes = useStyles();

    // const [isLoading, setIsLoading] = useState(false);
    // const [countAllMsd, data] = isDisablemsdBtn;
    // const [countChosenMsd, setCountChosenMsd] = useState(0);
    const [openTooltip, setOpenTooltip] = useState(0);

    // useEffect(() => {
    //   if (countAllMsd && isOpen) {
    //     setIsLoading(true);
    //     getManufacturersWithDiscountsService({ ...data })
    //       .then((res) => {
    //         setCountChosenMsd(res.count);
    //       })
    //       .catch(() => {
    //         error("Can't get discounts.");
    //       })
    //       .finally(() => setIsLoading(false));
    //   }
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [isOpen]);

    const getMsdTooltip = () => {
      if (deliveryStatus && deliveryStatus !== "UNFULFILLED")
        return (
          "This order has been partially fulfilled or fulfilled. " +
          "The discount can't be added to this order"
        );

      if (isDisablemsdBtn) return "No MSD available for these products";

      return "";
    };

    return (
      <Dialog open={isOpen} onClose={handleClose} maxWidth="xs">
        <DialogTitle className={classes.dialogTitle} sx={{}}>
          <Typography className={classes.title}>Apply discount</Typography>
          <IconButton onClick={handleClose}>
            <CrossIcon fill="#000000" />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <StyledTooltip
            key={1}
            title={getMsdTooltip()}
            placement="top"
            arrow
            open={
              openTooltip === 1 &&
              (deliveryStatus !== "UNFULFILLED" || isDisablemsdBtn)
            }
            onOpen={() => setOpenTooltip(1)}
            onClose={() => setOpenTooltip(0)}
          >
            <Box>
              <StyledButton
                disabled={
                  // isLoading ||
                  isDisablemsdBtn ||
                  (!!deliveryStatus && deliveryStatus !== "UNFULFILLED")
                }
                label={
                  // isLoading ? (
                  //   <CircularProgress sx={{ mt: "8px" }} size="20px" />
                  // ) : (
                  "MANUFACTURER SPECIFIC DISCOUNT"
                  // )
                }
                variant="outlined"
                className={classes.discountButton}
                fontSize="15px"
                onClick={() => handleApplyDiscount("manufacturer")}
              />
            </Box>
          </StyledTooltip>
          <StyledButton
            label="SELECT ITEMS TO DISCOUNT"
            variant="outlined"
            className={classes.discountButton}
            fontSize="15px"
            onClick={() => handleApplyDiscount("item")}
          />
          <StyledTooltip
            key={2}
            title={
              "This order has been partially fulfilled or fulfilled. " +
              "The discount can't be added to this order"
            }
            placement="top"
            arrow
            open={
              openTooltip === 2 &&
              !!deliveryStatus &&
              deliveryStatus !== "UNFULFILLED"
            }
            onOpen={() => setOpenTooltip(2)}
            onClose={() => setOpenTooltip(0)}
          >
            <Box>
              <StyledButton
                disabled={!!deliveryStatus && deliveryStatus !== "UNFULFILLED"}
                label="INVOICE TOTAL DISCOUNT"
                variant="outlined"
                className={classes.discountButton}
                fontSize="15px"
                onClick={() => handleApplyDiscount("total")}
              />
            </Box>
          </StyledTooltip>
        </DialogContent>
      </Dialog>
    );
  }
);
ApplyDiscountPopup.displayName = ApplyDiscountPopup;

ApplyDiscountPopup.propTypes = {
  isOpen: bool,
  handleClose: func,
  handleApplyDiscount: func,
  isDisablemsdBtn: bool,
  deliveryStatus: string,
};

ApplyDiscountPopup.defaultProps = {
  isOpen: false,
};

export default ApplyDiscountPopup;
