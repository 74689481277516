import { PRODUCTS_HEADER } from "./OrderedProductsPopup.constants";

import { classes } from "./styles";

import { Grid, Paper, Typography } from "@mui/material";

export const OrderedProductsHeader = () => {
  return (
    <Paper
      elevation={0}
      variant="outlined"
      square
      component={Grid}
      container
      columns={9}
      height="34px"
      sx={classes.paperWrapper}
    >
      {PRODUCTS_HEADER.map(({ label, xs, style }, index) => (
        <Grid item xs={xs} key={index} sx={{ ...classes.grid, ...style }}>
          <Typography sx={classes.typography}>{label}</Typography>
        </Grid>
      ))}
    </Paper>
  );
};
