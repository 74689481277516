import { bool, func } from "prop-types";

import { useFilterViewSave } from "./useFilterViewSave";

import { FilterViewHeader } from "./components/FilterViewHeader";
import { FilterViewContent } from "./components/FilterViewContent";
import { FilterViewActions } from "./components/FilterViewActions";

import { cl } from "./styles";

import { Dialog } from "@mui/material";
import { createSelector } from "reselect";
import { savedFiltersLoadingSelector } from "redux/selectors/savedFilters";
import { useSelector } from "react-redux";

const selector = createSelector(
  savedFiltersLoadingSelector,
  (savedFiltersLoading) => ({ savedFiltersLoading })
);

export const FilterViewSaveDialog = ({ open, onClose, handleSave }) => {
  const { savedFiltersLoading } = useSelector(selector);

  const { viewName, onChange, onSave } = useFilterViewSave({ handleSave });

  return (
    <Dialog open={open} sx={cl.dialogWrapper}>
      <FilterViewHeader {...{ onClose }} />
      <FilterViewContent {...{ viewName, onChange }} />
      <FilterViewActions
        {...{ onClose, onSave, viewName, loading: savedFiltersLoading }}
      />
    </Dialog>
  );
};

FilterViewSaveDialog.propTypes = {
  open: bool,
  onClose: func,
  handleSave: func,
};
FilterViewSaveDialog.defaultProps = {
  open: false,
  onClose: () => {},
  handleSave: () => {},
};
