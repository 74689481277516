import { useEffect, useState } from "react";
import { array, func, bool, string, object } from "prop-types";
import useStyles from "./styles";

import { Stack, Typography, Box } from "@mui/material";
import CustomerContactItem from "Pages/CustomersPage/pages/NewCustomerPage/CustomerContactItem";
import { AddIcon } from "../Icons";
import StyledButton from "../StyledButton";
import ContactDeletePopup from "./ContactPopup/ContactDeletePopup";
import CustomerContactsHeader from "./CustomerContactsHeader";
import { useContact } from "./useContact.hooks";
import { useAdmin } from "helpers/helpers";
import ContactAddDrawer from "./ContactPopup/ContactAddDrawer";

export const ContactsSection = ({
  customerId,
  type,
  contacts,
  setValue,
  newContactOpen,
  setNewContactOpen,
  handleUpdateEvent,
  errors,
}) => {
  const classes = useStyles();

  const isAdmin = useAdmin();

  const { defaultContact, handleAddContact, handleDeleteContact } = useContact({
    contacts,
    customerId,
    handleUpdateEvent,
    setValue,
  });

  const [editContact, setEditContact] = useState(null);
  const [deleteContact, setDeleteContact] = useState(null);
  const [deleteContactOpen, setDeleteContactOpen] = useState(false);

  useEffect(() => {
    if (!newContactOpen) setEditContact(null);
    if (!deleteContactOpen) setDeleteContact(null);
  }, [newContactOpen, deleteContactOpen]);

  const handleEditContact = (contact) => {
    setEditContact(contact);
    setNewContactOpen(true);
  };

  return (
    <>
      <ContactAddDrawer
        editContact={editContact}
        isOpen={newContactOpen}
        handleClose={() => setNewContactOpen(false)}
        handleAddContact={handleAddContact}
        defaultContact={defaultContact}
        contacts={contacts}
        type={type}
      />
      <ContactDeletePopup
        contact={deleteContact}
        isOpen={deleteContactOpen}
        handleClose={() => setDeleteContactOpen(false)}
        handleDeleteContact={handleDeleteContact}
        type={type}
      />
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <Typography className={classes.blockTitle}>Contacts</Typography>
        <StyledButton
          disabled={isAdmin}
          label={
            <Typography sx={{ fontSize: "11px", color: "#444444" }}>
              Add Contact
            </Typography>
          }
          color="inactive"
          className={classes.smallerButtonReport}
          variant="outlined"
          onClick={() => setNewContactOpen(true)}
          startIcon={<AddIcon fill={"#B5B5AC"} />}
        />
      </Box>
      <Stack
        sx={{
          maxHeight: "420px",
          height: "100%",
          overflow: "overlay",
        }}
      >
        {contacts.length === 0 && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography sx={{ color: "#707070" }} fontSize={20}>
              No contacts found
            </Typography>
          </Box>
        )}
        {contacts.length !== 0 && <CustomerContactsHeader />}
        <Box
          sx={{
            border: errors?.isPrimaryContact ? "1px solid #F2545B" : "",
            borderRadius: "0 0 4px 4px",
          }}
        >
          {contacts.map((contact, index) => (
            <CustomerContactItem
              handleEditContact={handleEditContact}
              handleDeleteContact={(contact) => {
                setDeleteContact(contact);
                setDeleteContactOpen(true);
              }}
              key={contact.id || contact.fid}
              contact={contact}
              IsLastItem={contacts?.length === index + 1}
            />
          ))}
        </Box>
      </Stack>
    </>
  );
};

ContactsSection.propTypes = {
  customerId: string,
  contacts: array,
  newContactOpen: bool,
  setNewContactOpen: func,
  setValue: func,
  type: string,
  handleUpdateEvent: func,
  errors: object,
};

ContactsSection.defaultProps = {
  contacts: [],
  newContactOpen: false,
  type: "customer",
};

export default ContactsSection;
