import { object, func } from "prop-types";
import { Box } from "@mui/material";
import { CheckInItem } from "./components";

export const CheckInBlock = ({
  classes,
  repPermissions,
  handleOpenProductDialog,
  handleOpenNotes,
  handleCustomerTaskDrawer,
  customer,
  activities,
  currentUser,
  handleCloseTooltip,
}) => {
  return (
    <Box>
      <CheckInItem
        {...{
          classes,
          repPermissions,
          handleOpenProductDialog,
          handleOpenNotes,
          customer,
          activities,
          handleCustomerTaskDrawer,
          currentUser,
          handleCloseTooltip,
        }}
      />
    </Box>
  );
};

CheckInBlock.propTypes = {
  classes: object,
  repPermissions: object,
  handleOpenProductDialog: func,
  handleOpenNotes: func,
  handleCloseTooltip: func,
  handleCustomerTaskDrawer: func,
  customer: object,
  activities: object,
  currentUser: object,
};
CheckInBlock.defaultProps = {
  checkIns: [],
  classes: {},
  repPermissions: {},
  handleOpenProductDialog: () => {},
  handleOpenNotes: () => {},
  handleCloseTooltip: () => {},
  handleCustomerTaskDrawer: () => {},
  customer: {},
  activities: {},
  currentUser: {},
};
