import { bool, number } from "prop-types";
import { Box, Typography } from "@mui/material";

export const QuantityBlock = ({ hasThirdParty, productQuantity }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        height: "100%",
        minWidth: !hasThirdParty ? "9.1%" : "14.1%",
        width: !hasThirdParty ? "9.1%" : "14.1%",
      }}
    >
      <Typography sx={{ fontSize: 13, color: "#1C1C19" }}>
        {productQuantity}
      </Typography>
    </Box>
  );
};

QuantityBlock.propTypes = { hasThirdParty: bool, productQuantity: number };
