export const StripeLogoIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35.209"
      height="14.521"
      viewBox="0 0 35.209 14.521"
    >
      <path
        id="Icon_awesome-stripe"
        data-name="Icon awesome-stripe"
        d="M9.08,9.858,6.7,10.364,6.687,18.2a2.357,2.357,0,0,0,2.537,2.382,3.8,3.8,0,0,0,1.717-.325V18.4c-.314.127-1.854.578-1.854-.864V14.056H10.94v-2.08H9.086Zm4.9,2.839-.149-.721H11.721v8.429h2.438V14.733a1.739,1.739,0,0,1,1.865-.512V11.976a1.67,1.67,0,0,0-2.041.721Zm5.079-3.978-2.454.523v1.992l2.454-.523ZM2.472,14.458c0-.38.319-.528.831-.534a5.474,5.474,0,0,1,2.432.627v-2.3a6.521,6.521,0,0,0-2.427-.446c-1.981,0-3.3,1.034-3.3,2.762,0,2.707,3.714,2.267,3.714,3.433,0,.451-.391.6-.935.6A6.163,6.163,0,0,1,.112,17.82v2.2a6.771,6.771,0,0,0,2.669.556c2.03,0,3.428-.869,3.428-2.63,0-2.911-3.736-2.388-3.736-3.488ZM35.216,16.29c0-2.5-1.21-4.479-3.532-4.479s-3.736,1.975-3.736,4.462c0,2.944,1.667,4.3,4.044,4.3a5.474,5.474,0,0,0,2.707-.633V18.106a5.168,5.168,0,0,1-2.4.539c-.952,0-1.788-.336-1.9-1.48h4.781c.011-.127.033-.638.033-.875Zm-4.837-.924c0-1.1.677-1.563,1.288-1.563s1.238.462,1.238,1.563Zm-6.212-3.554a2.754,2.754,0,0,0-1.915.765l-.127-.605H19.975V23.239l2.443-.517.006-2.762a2.737,2.737,0,0,0,1.728.616c1.75,0,3.345-1.277,3.345-4.38.006-2.839-1.612-4.385-3.329-4.385Zm-.583,6.74a1.467,1.467,0,0,1-1.15-.462l-.017-3.632a1.466,1.466,0,0,1,1.166-.484c.891,0,1.508,1,1.508,2.278C25.1,17.566,24.492,18.551,23.584,18.551Zm-6.971,1.854h2.454V11.976H16.613Z"
        transform="translate(-0.007 -8.719)"
      />
    </svg>
  );
};
