import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  chipWrapper: {
    backgroundColor: "#F6F6F6",
    borderRadius: "16px",
    display: "inline-flex",
    alignItems: "center",
    padding: "0 13px",
    height: "28px",
  },

  date: {
    fontSize: "13px",
    fontWeight: 500,
    color: "#5F6267",
  },
}));

export default useStyles;
