export const cl = {
  wrapper: {
    borderRadius: "0 0 4px 4px",
    borderWidth: "0 1px 1px 1px",
    borderStyle: "solid",
    borderColor: "#D5D9D9",
  },
  grid: {
    // "&:not(:last-child)": { borderRight: "1px solid #D5D9D9" },
    "&:not(:nth-of-type(n+3))": { borderRight: "1px solid #D5D9D9" },
  },
  plan: {
    wrapper: {
      display: "flex",
      alignItems: "center",
      gap: 1.2,
      width: "100%",
      height: "30px",
    },
    textWrapper: {
      display: "flex",
      width: "100%",
    },
    textLeft: { fontSize: 15, color: "#707070", width: "74px" },
    textRight: { fontSize: 15, color: "#000", marginLeft: "4px" },
    pauseBtn: { fontSize: 10, width: 86, height: 24, p: 0 },
    changeBtn: { fontSize: 10, width: 86, height: 24, p: 0, ml: 1.75 },
    contactBtn: {
      px: 0,
      width: "100%",
      maxWidth: "90px",
      height: "24px",
      fontSize: "10px",
      color: "#FFF",
      background: "#47A06D",
    },
    cancelBtn: {
      px: 0,
      width: "100%",
      maxWidth: "114px",
      height: "24px",
      fontSize: "10px",
      background: "#FFF",
      color: "#EB4233",
      border: "0.5px solid #EB4233",
    },
  },
  details: {
    wrapper: {
      display: "flex",
      alignItems: "center",
      gap: 2,
      width: "100%",
    },
    text: {
      fontSize: 12,
      fontWeight: 300,
      color: "#000",
    },
  },
  paymentMethod: {
    wrapper: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      py: 1.3,
      pl: 3,
    },
    cardWrapper: {
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      gap: 0.8,
      overflow: "overlay",
    },
    btn: {
      fontSize: 10,
      width: "100%",
      maxWidth: "234px",
      height: 24,
      p: 0,
      border: "1px solid #D5D9D9",
    },
  },
  credits: {
    fontSize: 15,
    color: "#707070",
    mt: 2.3,
  },
};
