import { Grid, Paper, Typography } from "@mui/material";

const CustomerContactsHeader = () => {
  const classes = {
    grid: {
      textAlign: "center",
    },
    typography: {
      fontSize: "12px",
      color: "#6A6A6A",
      textTransform: "uppercase",
    },
  };

  return (
    <Paper
      sx={{
        height: "37px",
        minHeight: "37px",
        display: "flex",
        alignItems: "center",
        backgroundColor: "#EEE",
        border: "1px solid #D5D9D9",
        borderRadius: "4px 4px 0px 0px",
      }}
      elevation={0}
      square
      component={Grid}
      container
      columns={13}
    >
      <Grid item xs={0.25} />
      <Grid item xs={3.75} sx={{ textAlign: "left" }}>
        <Typography
          sx={classes.typography}
          variant="caption"
          color="groundLight"
        >
          NAME
        </Typography>
      </Grid>
      <Grid item xs={2.35} sx={{ ...classes.grid, textAlign: "left" }}>
        <Typography
          sx={classes.typography}
          variant="caption"
          color="groundLight"
        >
          TITLE
        </Typography>
      </Grid>
      <Grid item xs={2.45} sx={{ textAlign: "left" }}>
        <Typography
          sx={classes.typography}
          variant="caption"
          color="groundLight"
        >
          PHONE NUMBER
        </Typography>
      </Grid>
      <Grid item xs={3.2} sx={{ textAlign: "left" }}>
        <Typography
          sx={classes.typography}
          variant="caption"
          color="groundLight"
        >
          EMAIL ADDRESS
        </Typography>
      </Grid>
    </Paper>
  );
};

export default CustomerContactsHeader;
