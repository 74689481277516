import { useEffect, useMemo, useRef, useState } from "react";
import { bool, any } from "prop-types";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";

import { Box, Chip, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { FilterIcon } from "../../../../../components/Icons";

import { FilterSearchTextField } from "../../../../../components/TextFields/TextFields";
import FilterMenu from "../../../../../components/FilterMenu";
import StyledButton from "../../../../../components/StyledButton";

import useStyles, { cl } from "./styles";
import { defaultFilters, getFilterSwitches } from "./constants";
import { normalizeSnakeCaseString } from "../../../../../helpers/helpers";
import { useDebounce } from "../../../../../helpers/hooks";
import {
  SCROLL_LIMIT,
  UNCATEGORIZED_CATEGORY,
} from "../../../../../utils/constants";

import {
  manufacturersCountSelector,
  manufacturersListSelector,
} from "../../../../../redux/selectors/manufacturers";
import { getManufacturersAction } from "../../../../../redux/actions/manufacturers";
import { getProductsParamsAction } from "../../../../../redux/actions/products";
import { useTagsActions } from "helpers/useTagsActions";

const selector = createSelector(
  manufacturersListSelector,
  manufacturersCountSelector,
  (manufacturersList, manufacturersCount) => ({
    manufacturersList,
    manufacturersCount,
  })
);

export const NewProductsFilter = ({ isOpen, specificManufacturer }) => {
  const classes = useStyles();
  const {
    NewProductsFilter: { wrapper, searchAdor, btnLabel, btnFilter },
  } = cl;

  const { manufacturersList, manufacturersCount } = useSelector(selector);

  const filterAnchor = useRef();
  const dispatch = useDispatch();

  const [searchInput, setSearchInput] = useState("");
  const searchInputDebounced = useDebounce(searchInput, 500);
  const [filterFields, setFilterFields] = useState(defaultFilters);
  const [switches, setSwitches] = useState(
    getFilterSwitches(!!specificManufacturer)
  );
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [limitManufacturers, setLimitManufacturers] = useState(
    2 * SCROLL_LIMIT
  );

  const filterChipKeys = useMemo(
    () => Object.keys(filterFields).filter((key) => filterFields[key]),
    [filterFields]
  );

  const getChipLabel = (key) => {
    const icon = filterFields[key]?.icon;
    return (
      <Box display="flex" alignItems="center" gap="6px">
        <Typography
          sx={{ fontSize: "13px", fontWeight: 500 }}
          color="groundLighter.main"
        >
          {normalizeSnakeCaseString(filterFields[key]?.label || key)}:{" "}
          {!icon && (
            <span style={{ color: "#5F6368", marginTop: !!icon && "5px" }}>
              {normalizeSnakeCaseString(
                filterFields[key]?.name || filterFields[key]
              )}
            </span>
          )}
        </Typography>
        {icon}
      </Box>
    );
  };

  const handleDeleteFilter = (key) => {
    const newState = switches;
    const index = switches.findIndex((s) => s.value === key);
    const insert = {
      ...switches[index],
      checked: false,
    };
    newState.splice(index, 1, insert);
    setSwitches([...newState]);
    setFilterFields((prev) => {
      return { ...prev, [key]: "" };
    });
  };

  const handleApplyFilter = (newSwitches, newFields) => {
    setFilterMenuOpen(false);
    setSwitches([...newSwitches]);
    setFilterFields({ ...newFields });
  };

  const [manufacturersState, setManufacturersState] = useState({
    search: "",
    searcheble: false,
  });

  const handleFetchManufacturers = () => {
    dispatch(
      getManufacturersAction({
        limit: limitManufacturers,
        search: manufacturersState.search,
      })
    );
    setManufacturersState((prev) => ({
      ...prev,
      searcheble: !prev.searcheble ? manufacturersCount >= 10 : prev.searcheble,
    }));
    setLimitManufacturers(limitManufacturers + SCROLL_LIMIT);
  };

  useEffect(() => {
    handleFetchManufacturers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manufacturersState.search]);

  const {
    list: tagsList,
    count: tagsCount,
    handleFetchTags,
    page: tagsPage,
    setTagsParams,
    searcheble,
  } = useTagsActions({
    open: isOpen,
    type: "product",
    params: {
      used: true,
      sort_by_tag: "asc",
    },
  });

  useEffect(() => {
    if (isOpen) {
      if (!!specificManufacturer && !filterFields.manufacturer) return;
      dispatch(
        getProductsParamsAction({
          with_missing_info: undefined,
          status: ["active", "inactive_for_customers"],
          search: searchInputDebounced,
          manufacturer_id:
            specificManufacturer?.id || filterFields?.manufacturer_id?.value,
          category_id:
            filterFields?.category_id?.value === UNCATEGORIZED_CATEGORY.id
              ? "null"
              : filterFields?.category_id?.value,
          tag_ids: filterFields?.tag_ids?.value
            ? JSON.stringify([filterFields?.tag_ids?.value])
            : null,
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, searchInputDebounced, filterFields]);

  useEffect(() => {
    if (!specificManufacturer) return;
    setFilterFields((prev) => {
      return { ...prev, manufacturer: specificManufacturer };
    });

    setSwitches(getFilterSwitches(!!specificManufacturer));
  }, [specificManufacturer]);

  // show the clear button only if the input field has a value and does not have a chip
  const handleClearValue = (
    specificManufacturer
      ? filterChipKeys.filter((c) => c !== "manufacturer").length
      : filterChipKeys?.length
  )
    ? null
    : () => setSearchInput("");

  return (
    <Box mt={2.5}>
      <Box sx={wrapper}>
        <FilterSearchTextField
          formSx={{ minWidth: "380px" }}
          placeholder="Search products by name or SKU"
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          fullWidth
          adornmentProps={{
            sx: {
              justifyContent: "flex-end",
            },
          }}
          placeholderWidth="260px"
          handleClearValue={handleClearValue}
          customAdornment={
            <Box sx={searchAdor}>
              {filterChipKeys.map((key) => (
                <Chip
                  sx={{
                    display:
                      key === "manufacturer" && !!specificManufacturer
                        ? "none"
                        : "inline-flex",
                  }}
                  key={key}
                  className={classes.filterChip}
                  size="small"
                  label={getChipLabel(key)}
                  deleteIcon={
                    <CloseIcon size="15" style={{ fill: "#707070" }} />
                  }
                  onMouseDown={(e) => e.stopPropagation()}
                  disabled={key === "manufacturer" && !!specificManufacturer}
                  onDelete={() => handleDeleteFilter(key)}
                />
              ))}
            </Box>
          }
        />

        <StyledButton
          sx={btnFilter}
          fontSize="15px"
          label="Filter"
          startIcon={
            (
              specificManufacturer
                ? filterChipKeys.length - 1
                : filterChipKeys.length
            ) ? (
              <Typography sx={btnLabel}>
                {specificManufacturer
                  ? filterChipKeys.length - 1
                  : filterChipKeys.length}
              </Typography>
            ) : (
              <FilterIcon />
            )
          }
          variant="outlined"
          ref={filterAnchor}
          color="edit"
          onClick={() => setFilterMenuOpen(true)}
        />
        <FilterMenu
          anchorEl={filterAnchor.current}
          open={filterMenuOpen}
          onClose={() => setFilterMenuOpen(false)}
          filterFields={filterFields}
          switches={switches}
          handleApplyFilter={handleApplyFilter}
          selectMatchData={[
            {
              id: "manufacturer_id",
              childrenList: manufacturersList,
              handleFetch: handleFetchManufacturers,
              setParams: setManufacturersState,
              dataCount: manufacturersCount,
              dataLength: manufacturersList?.length,
              label: "Select Manufacturer",
              searcheble: manufacturersState.searcheble,
            },
            {
              id: "tag_ids",
              childrenList: tagsList,
              handleFetch: () => handleFetchTags(tagsPage + 1),
              setParams: setTagsParams,
              dataCount: tagsCount,
              dataLength: tagsList?.length,
              label: "Select Tag",
              searcheble,
            },
          ]}
        />
      </Box>
    </Box>
  );
};

NewProductsFilter.propTypes = { isOpen: bool, specificManufacturer: any };
NewProductsFilter.defaultProps = { isOpen: false };
