import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  productHeaderContainer: {
    height: "32px",
    backgroundColor: "#F8F8F8",
    "& .MuiGrid-root": {
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      "& .MuiTypography-root": {
        fontSize: "10px",
        color: "#5F6267",
      },
    },
  },

  productNameWrapper: {
    paddingLeft: "11px",
    justifyContent: "flex-start!important",
  },

  productPriceWrapper: {
    justifyContent: "flex-start!important",
  },
}));

export default useStyles;
