import { Box } from "@mui/material";
import { StyledButton, StyledTextField, StyledTooltip } from "components";
import React from "react";
import { bool, number, func, any, object } from "prop-types";

const QuantityPicker = ({
  value,
  minusDisabled,
  plusDisabled,
  onChange,
  error,
  onSubtract,
  onAdd,
  disabled,
  plusTooltipProps,
}) => {
  return (
    <StyledTextField
      value={value ?? 1}
      size="small"
      disabled={disabled}
      formSx={{ width: "84px" }}
      InputProps={{
        startAdornment: (
          <Box height="100%">
            <StyledButton
              label="–"
              disabled={minusDisabled}
              sx={{
                borderRadius: "4px 0 0 4px",
                minWidth: "26px",
                width: "26px",
                p: 0,
                backgroundColor: "#F7F7F7",
                height: "100%",
                borderColor: error ? "#EB4233!important" : "#D5D9D9!important",
                borderTop: "none",
                borderBottom: "none",
                "&.Mui-disabled": {
                  borderLeft: "1px solid #D5D9D9",
                  opacity: 0.75,
                },
                "&:hover": {
                  borderTop: "none",
                  borderBottom: "none",
                  borderColor: error
                    ? "#EB4233!important"
                    : "#D5D9D9!important",
                },
              }}
              color="edit"
              onClick={onSubtract}
              variant="outlined"
            />
          </Box>
        ),
        endAdornment: (
          <StyledTooltip
            placement="top"
            arrow
            title=""
            disableHoverListener
            {...plusTooltipProps}
          >
            <Box height="100%">
              <StyledButton
                disabled={plusDisabled}
                label="+"
                sx={{
                  borderRadius: "0 4px 4px 0",
                  borderColor: error
                    ? "#EB4233!important"
                    : "#D5D9D9!important",
                  borderTop: "none",
                  borderBottom: "none",
                  minWidth: "26px",
                  height: "100%",
                  p: 0,
                  backgroundColor: "#F7F7F7",
                  "&.Mui-disabled": {
                    borderRight: "1px solid #D5D9D9",
                    opacity: 0.75,
                  },
                  "&:hover": {
                    borderTop: "none",
                    borderBottom: "none",
                    borderColor: error
                      ? "#EB4233!important"
                      : "#D5D9D9!important",
                  },
                }}
                color="edit"
                onClick={onAdd}
                variant="outlined"
              />
            </Box>
          </StyledTooltip>
        ),
        sx: {
          height: "31px",
          borderRadius: "4px",
          fontSize: "9px",
          p: 0,
          "& fieldset": {
            borderColor: error ? "#EB4233!important" : "#D5D9D9!important",
            borderRight: "none",
            borderWidth: "1px!important",
            p: 0,
          },
          "& > input": {
            p: 0,
            textAlign: "center",
          },
        },
      }}
      sx={{ borderColor: "#707070" }}
      error={error ? " " : ""}
      onChange={onChange}
    />
  );
};

QuantityPicker.propTypes = {
  minusDisabled: bool,
  plusDisabled: bool,
  value: number,
  onChange: func,
  error: any,
  onSubtract: func,
  onAdd: func,
  disabled: bool,
  plusTooltipProps: object,
};

export default QuantityPicker;
