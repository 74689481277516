import { useCallback, useEffect, useMemo, useState } from "react";
import { reportsList } from "./ReportsPage.constants";
import ReportsTab from "./components/ReportsTab";
import { error, success } from "utils/notifications";
import { useDispatch, useSelector } from "react-redux";
import {
  editFavoriteReportsService,
  removeFavoriteReportService,
} from "services/reports";
import { updateUserFieldAction } from "redux/actions/auth";
import {
  openConfirmDialogAction,
  setConfirmIsOpenAction,
} from "redux/actions/confirmDialogs";
import { StyledButton } from "components";

export const useReportPage = () => {
  const { favouriteReportList = [] } = useSelector(
    ({ auth }) => auth.currentUser
  );

  const [reportState, setReportState] = useState({
    isOpenReportDialog: false,
    currentReport: null,
    isCustom: false,
    loading: false,
    search: "",
  });

  const handleTypeSearch = (e) => {
    setReportState((prev) => ({ ...prev, search: e.target.value }));
  };

  const REPORT_TABS = useMemo(
    () => [
      {
        value: 0,
        label: "Standard",
        disabled: false,
        showTooltip: false,
        tooltipText: "",
      },
      {
        value: 1,
        label: "Favorites",
        disabled: !favouriteReportList.length,
        showTooltip: !favouriteReportList.length,
        tooltipText: "No Favorite reports",
      },
    ],
    [favouriteReportList.length]
  );

  const [currentTab, setCurrentTab] = useState(REPORT_TABS[0].value);

  const handleChangeTab = (newValue) => {
    setCurrentTab(newValue);
  };

  const handleExport = useCallback(
    (report, isCustom) => {
      setReportState((prev) => ({
        ...prev,
        isCustom: !!isCustom,
        currentReport: report,
        isOpenReportDialog: true,
      }));
    },
    [setReportState]
  );

  const handleCloseReportDialog = useCallback(() => {
    setReportState((prev) => ({
      ...prev,
      isCustom: false,
      currentReport: null,
      isOpenReportDialog: false,
    }));
  }, [setReportState]);

  const searchFilter = useCallback(
    (str) => {
      return str.toLowerCase().includes(reportState.search.toLowerCase());
    },
    [reportState.search]
  );

  const filterByChildren = useCallback(
    ({ list, condition, isFavorite }) => {
      return list
        .map((report) => {
          // const matchedReports = report.reports.filter((reportItem) =>
          //   condition(reportItem)
          // );
          const matchedReports = (
            isFavorite ? favouriteReportList : report.reports
          ).filter((reportItem) => condition(reportItem, report.reports));

          if (matchedReports.length)
            return {
              ...report,
              reports: matchedReports,
              reportsCount: matchedReports.length,
            };
        })
        .filter((rep) => rep);
    },
    [favouriteReportList]
  );

  const favoriteReports = useMemo(
    () =>
      filterByChildren({
        list: reportsList,
        condition: (reportItem, reports) =>
          reports.some(
            ({ reportCategory }) =>
              reportCategory === reportItem?.body?.reportCategory
          ) &&
          // favouriteReportList.some(({ type }) => type === reportItem.id) &&
          searchFilter(reportItem.title),
        isFavorite: true,
      }),
    [filterByChildren, searchFilter]
  );
  const dispatch = useDispatch();

  const handleSetFavorite = useCallback(
    async (report, isFavorite, isCustom) => {
      if (reportState.loading) return;
      const { title } = report;
      const data = {
        title,
        id: report?.body?.isCustom && report.id,
        body: {
          ...report.body,
          isCustom,
          type: report?.type,
          reportCategory: report.body?.reportCategory || report.reportCategory,
        },
      };
      // const data = { action: isFavorite ? "remove" : "add", value: reportId };
      try {
        setReportState((prev) => ({
          ...prev,
          loading: true,
        }));
        if (isFavorite) {
          const { favouriteReportList: newFavorites } =
            await removeFavoriteReportService(report.id);
          dispatch(updateUserFieldAction("favouriteReportList", newFavorites));
          dispatch(setConfirmIsOpenAction(false));
        } else {
          const { favouriteReportList: newFavorites } =
            await editFavoriteReportsService(data);
          dispatch(updateUserFieldAction("favouriteReportList", newFavorites));
        }
        const successText = isFavorite
          ? "Removed from Favorites"
          : "Added to Favorites";

        success(successText);
        setReportState((prev) => ({
          ...prev,
          loading: false,
        }));
      } catch (err) {
        setReportState((prev) => ({
          ...prev,
          loading: false,
        }));
        error(err?.response?.data?.message);
      }
    },
    [dispatch, reportState.loading]
  );

  const handleFavorite = useCallback(
    async (report, isFavorite) => {
      const skipIfNotFavoriteTab = currentTab !== REPORT_TABS[1].value;

      if (!isFavorite || skipIfNotFavoriteTab) {
        handleSetFavorite(report, isFavorite);
        return;
      }

      dispatch(
        openConfirmDialogAction({
          title: `Delete ${report.title}?`,
          text: `Are you sure you want to delete custom report ${report.title}?`,
          buttons: (
            <>
              <StyledButton
                label="Cancel"
                color="cancel"
                variant="outlined"
                onClick={() => dispatch(setConfirmIsOpenAction(false))}
              />
              <StyledButton
                label="Delete"
                variant="contained"
                color="error"
                onClick={() => handleSetFavorite(report, isFavorite)}
                // onClick={() => {
                //   dispatch(setConfirmIsOpenAction(false));
                //   onProceedSubmit(data, hasArchivedStops);
                // }}
              />
            </>
          ),
        })
      );
    },
    [REPORT_TABS, currentTab, dispatch, handleSetFavorite]
  );

  const ReportPageContent = useMemo(
    () => [
      <ReportsTab
        key={0}
        reportsList={filterByChildren({
          list: reportsList,
          condition: ({ title }) => searchFilter(title),
          isFavorite: false,
        })}
        onExport={handleExport}
        handleFavorite={handleFavorite}
        isFavoriteTab={false}
        favorites={favouriteReportList}
      />,
      <ReportsTab
        key={1}
        reportsList={favoriteReports}
        onExport={handleExport}
        isFavoriteTab
        handleFavorite={handleFavorite}
        favorites={favouriteReportList}
      />,
    ],
    [
      filterByChildren,
      handleExport,
      handleFavorite,
      favouriteReportList,
      favoriteReports,
      searchFilter,
    ]
  );

  const autoSwitchTab = useCallback(() => {
    if (!favouriteReportList.length) setCurrentTab(REPORT_TABS[0].value);
  }, [REPORT_TABS, favouriteReportList.length]);

  useEffect(autoSwitchTab, [autoSwitchTab]);

  return {
    currentTab,
    handleChangeTab,
    handleCloseReportDialog,
    reportState,
    ReportPageContent,
    REPORT_TABS,
    handleTypeSearch,
    handleSetFavorite,
    setReportState,
  };
};
