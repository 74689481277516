import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  textInput: {
    fontSize: "12px",
    height: "32px",
  },
  inputLabel: {
    fontSize: "12px",
    color: "#B5B5AC",
  },
  helperText: {
    fontSize: "12px",
    fontWeight: 300,
    color: "#6a6a6a",
    marginTop: "2px",
    maxWidth: "350px",
  },
  section: {
    marginRight: "20px",
    padding: "16px 10px 16px 24px",
    height: "427px",
    boxShadow: "none",
    border: "1px solid #F0F0F0",
    width: "50%",
  },
  smallerButton: {
    height: "40px",
    width: "349px",
    "& > span": {
      fontSize: "11px",
    },
  },
}));

export default useStyles;
