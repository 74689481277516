import { object, func, bool, string } from "prop-types";
import {
  Box,
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  SvgIcon,
  Typography,
} from "@mui/material";
import { ReactComponent as ArrowDownIcon } from "../../TabIcons/chevron-down.svg";
import { ReactComponent as ArrowUpIcon } from "../../TabIcons/chevron-up.svg";
import { NavLink } from "react-router-dom";
import {
  ChangePasswordFilledIcon,
  ChangePasswordIcon,
  SettingsFilledIcon,
  SettingsIcon,
  SignoutFilledIcon,
  SignoutIcon,
} from "components/Icons/NavbarIcons/MenuListIcons";
import { StyledAvatar, StyledTooltip } from "components";

const cl = {
  menuItem: {
    "&:hover, &:focus": {
      background: "rgba(66, 165, 127, 0.1)",
    },
  },
  btn: {
    maxWidth: {
      lg: "150px",
      xl: "250px",
    },
  },
  listItemText: {
    fontSize: "12px",
    color: "#363531",
    ml: 1,
  },
};

export const MainMenuBlock = ({
  menuBtnRef,
  openHeaderList,
  handleClickLogoClient,
  trialEnded,
  subscriptionIsCanceled,
  businessName,
  businessEmail,
  profilePhoto,
  anchorEl,
  handleCloseLogoClient,
  disabledSettingsMenuItem,
  onClickSettingsMenuItem,
  setNameOfCurrentHoveredMenuItem,
  nameOfCurrentHoveredMenuItem,
  isAdmin,
  setOpenChangePassword,
  formChanged,
  editType,
  handleConfirmCheckedItemsDialog,
  handleConfirmChangesDialog,
  setActiveTab,
  handleLogOut,
}) => {
  return (
    <>
      <Button
        ref={menuBtnRef}
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={openHeaderList ? "true" : undefined}
        onClick={handleClickLogoClient}
        disabled={trialEnded || subscriptionIsCanceled}
        sx={cl.btn}
        endIcon={
          <SvgIcon
            color={openHeaderList ? "inherit" : "disabled"}
            fontSize="inherit"
          >
            {openHeaderList ? (
              <ArrowUpIcon width="12px" />
            ) : (
              <ArrowDownIcon width="12px" />
            )}
          </SvgIcon>
        }
      >
        <StyledTooltip
          arrow
          placement="bottom"
          title={
            <Box>
              <Typography fontSize={12} fontWeight={500} color="#1C1C19" noWrap>
                {businessName}
              </Typography>
              <Typography fontSize={12} fontWeight={300} color="#1C1C19" noWrap>
                {businessEmail}
              </Typography>
            </Box>
          }
        >
          <StyledAvatar
            sx={{ width: 30, height: 30 }}
            name={businessName}
            src={profilePhoto?.fileName}
            stringStyle={{ fontSize: 14 }}
          />
        </StyledTooltip>
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        disableScrollLock={true}
        open={openHeaderList}
        onClose={handleCloseLogoClient}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuList sx={{ width: "160px" }}>
          <MenuItem
            sx={cl.menuItem}
            disabled={disabledSettingsMenuItem}
            component={NavLink}
            to="/settings"
            value="/settings"
            onClick={onClickSettingsMenuItem}
            onMouseEnter={() => setNameOfCurrentHoveredMenuItem("Settings")}
            onMouseLeave={() => setNameOfCurrentHoveredMenuItem(null)}
          >
            <ListItemIcon>
              {nameOfCurrentHoveredMenuItem === "Settings" ? (
                <SettingsFilledIcon />
              ) : (
                <SettingsIcon />
              )}
            </ListItemIcon>
            <ListItemText
              primary="Settings"
              primaryTypographyProps={cl.listItemText}
            />
          </MenuItem>
          <MenuItem
            sx={cl.menuItem}
            disabled={isAdmin}
            onClick={(e) => {
              if (e.metaKey || e.ctrlKey) return;
              e.preventDefault();
              setOpenChangePassword(true);
              handleCloseLogoClient();
            }}
            onMouseEnter={() =>
              setNameOfCurrentHoveredMenuItem("Change Password")
            }
            onMouseLeave={() => setNameOfCurrentHoveredMenuItem(null)}
          >
            <ListItemIcon>
              {nameOfCurrentHoveredMenuItem === "Change Password" ? (
                <ChangePasswordFilledIcon />
              ) : (
                <ChangePasswordIcon />
              )}
            </ListItemIcon>
            <ListItemText
              primary="Change Password"
              primaryTypographyProps={cl.listItemText}
            />
          </MenuItem>
          <MenuItem
            sx={cl.menuItem}
            disabled={trialEnded || subscriptionIsCanceled}
            onClick={(e) => {
              e.preventDefault();
              if (formChanged) {
                handleCloseLogoClient();
                if (editType === "checked_items")
                  return handleConfirmCheckedItemsDialog("Sign Out");
                if (editType !== "checked_items")
                  return handleConfirmChangesDialog("Sign Out");
              } else {
                setActiveTab("settings");
                handleCloseLogoClient();
                handleLogOut();
              }
            }}
            onMouseEnter={() => setNameOfCurrentHoveredMenuItem("Sign Out")}
            onMouseLeave={() => setNameOfCurrentHoveredMenuItem(null)}
          >
            <ListItemIcon>
              {nameOfCurrentHoveredMenuItem === "Sign Out" ? (
                <SignoutFilledIcon style={{ marginLeft: "2px" }} />
              ) : (
                <SignoutIcon style={{ marginLeft: "2px" }} />
              )}
            </ListItemIcon>
            <ListItemText
              primary="Sign Out"
              primaryTypographyProps={cl.listItemText}
            />
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
};

MainMenuBlock.propTypes = {
  menuBtnRef: object,
  openHeaderList: bool,
  handleClickLogoClient: func,
  trialEnded: bool,
  subscriptionIsCanceled: bool,
  businessName: string,
  businessEmail: string,
  profilePhoto: object,
  anchorEl: object,
  handleCloseLogoClient: func,
  disabledSettingsMenuItem: bool,
  onClickSettingsMenuItem: func,
  setNameOfCurrentHoveredMenuItem: func,
  nameOfCurrentHoveredMenuItem: string,
  isAdmin: bool,
  setOpenChangePassword: func,
  formChanged: bool,
  editType: string,
  handleConfirmCheckedItemsDialog: func,
  handleConfirmChangesDialog: func,
  setActiveTab: func,
  handleLogOut: func,
};
MainMenuBlock.defaultProps = {
  menuBtnRef: null,
  openHeaderList: false,
  handleClickLogoClient: () => {},
  trialEnded: false,
  subscriptionIsCanceled: false,
  businessName: "",
  profilePhoto: {},
  anchorEl: null,
  handleCloseLogoClient: () => {},
  disabledSettingsMenuItem: false,
  onClickSettingsMenuItem: () => {},
  setNameOfCurrentHoveredMenuItem: () => {},
  nameOfCurrentHoveredMenuItem: null,
  isAdmin: false,
  setOpenChangePassword: () => {},
  formChanged: false,
  editType: "",
  handleConfirmCheckedItemsDialog: () => {},
  handleConfirmChangesDialog: () => {},
  setActiveTab: () => {},
  handleLogOut: () => {},
};
