import PropTypes, { bool, func, string, object } from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

const ConfirmDialog = ({
  isOpen,
  text,
  onCancel,
  onAccept,
  title,
  confirmColor,
}) => {
  return (
    <Dialog open={isOpen} scroll="body">
      <DialogTitle
        sx={{
          fontSize: "20px",
          color: "#080808",
          fontWeight: 400,
          p: "10px 30px 10px 30px",
          mb: 1,
          borderBottom: "0.5px solid #CCCCCC",
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent
        sx={{
          p: "16px 25px 10px 30px",
          maxWidth: "460px",
          minWidth: "420px",
          letterSpacing: 0,
          fontSize: "15px",
          fontWeight: 400,
          color: "#707070",
        }}
      >
        {text}
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          p: "14px 25px 14px 30px",
        }}
      >
        <Button
          onClick={onCancel}
          sx={{
            width: "98px",
            color: "#6A6A6A",
            borderColor: "#D4D4D4",
            fontSize: "13px",
            height: "28px",
          }}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          sx={{
            width: "98px",
            color: "#FFFFFF",
            fontSize: "13px",
            height: "28px",
            boxShadow: "none",
          }}
          color={confirmColor}
          onClick={onAccept}
          variant="contained"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  isOpen: bool,
  text: PropTypes.oneOfType([string, object]),
  onCancel: func,
  onAccept: func,
  title: string,
  confirmColor: string,
};

ConfirmDialog.defaultProps = {
  isOpen: false,
  text: "Are you sure?",
  title: "",
  confirmColor: "primary",
};

export default ConfirmDialog;
