import React, { useCallback, useEffect, useState } from "react";
import { bool, string } from "prop-types";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm, useWatch } from "react-hook-form";
import { Grid, Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";

import CatalogNewProductSwitcherTab from "./CatalogNewProductSwitcherTab/CatalogNewProductSwitcherTab";
import ProductDetails from "./ProductDetails";
import { AtentionIcon, IosArrowForward } from "components/Icons";
import { defaultProduct } from "./ProductDetails.constants";
import { validationSchema } from "./ContentTabs/ContentTabs.validation";
import {
  getProductByIdService,
  updateSingleInventoryService,
} from "services/products";
import { error, success } from "utils/notifications";
import {
  openDiscardChanges,
  setEditTypeAction,
  setFormChangedAction,
} from "redux/actions/confirmDialogs";
import { getCategoriesListAction } from "redux/actions/categories";
import {
  getInventoryItemFields,
  setPreparedData,
} from "./ProductDetails.helpers";
import { useActions } from "./useActions";
import { PRODUCT_TYPE_INVENTORY } from "utils/constants";

const CatalogNewProductPage = ({ isEdit, navigatePath, navigateState }) => {
  const navigate = useNavigate();
  const { productId } = useParams();

  const dispatch = useDispatch();

  const { state, pathname } = useLocation();

  const [isColor, setIsColor] = useState(false);
  const [isSize, setIsSize] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    setError,
    formState: { errors, isDirty, dirtyFields },
    clearErrors,
    register,
    trigger,
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues: {
      id: "",
      skuVariationFields: defaultProduct.skuVariationFields,
      colorVariationFields: defaultProduct.colorVariationFields,
      sizeVariationFields: defaultProduct.sizeVariationFields,
      isMultiple: defaultProduct.isMultiple,
      name: defaultProduct.name,
      sku: defaultProduct.sku,
      barcode: defaultProduct.barcode,
      manufacturerId: defaultProduct.manufacturerId,
      manufacturerIdInactive: defaultProduct.manufacturerIdInactive,
      manufacturerName: defaultProduct.manufacturerName,
      category: defaultProduct.category,
      description: defaultProduct.description,
      status: defaultProduct.status,
      wholesalePrice: defaultProduct.wholesalePrice,
      distributorPrice: defaultProduct.distributorPrice,
      minOrderQTY: defaultProduct.minOrderQTY,
      whenOutOfStock: defaultProduct.whenOutOfStock,
      itemsPerCase: defaultProduct.itemsPerCase,
      retailPrice: defaultProduct.retailPrice,
      tags: defaultProduct.tags,
      photos: defaultProduct.photos,
      mainPhotoUrl: "",
      onHand: defaultProduct.onHand,
      expected: defaultProduct.expected,
      allocated: defaultProduct.allocated,
      chooseVariationsType: {
        sku: defaultProduct.chooseVariationsType?.sku,
        color: defaultProduct.chooseVariationsType?.color,
        size: defaultProduct.chooseVariationsType?.size,
      },
      variationsFields: defaultProduct.variationsFields,
      type: defaultProduct.type,
      countryOfOrigin: defaultProduct.countryOfOrigin,
    },
    resolver: yupResolver(validationSchema({ isColor, isSize })),
  });

  const formField = useWatch({ control });
  const formChanged = useSelector(
    ({ confirmDialogs }) => confirmDialogs.formChanged
  );

  useEffect(() => {
    if (
      dirtyFields.skuVariationFields &&
      dirtyFields.skuVariationFields.length > 1
    ) {
      dispatch(setEditTypeAction("product_sku", !isEdit));
    } else {
      dispatch(setEditTypeAction("product", !isEdit));
    }
  }, [dirtyFields.skuVariationFields, dispatch, isEdit]);

  const hasDuplicateNameError = errors?.name?.type === "duplicate";
  const hasCharacterNameError = errors?.name?.type === "character";

  useEffect(() => {
    if (formField?.name && !hasDuplicateNameError && !hasCharacterNameError)
      clearErrors("name");
  }, [
    formField?.name,
    hasDuplicateNameError,
    hasCharacterNameError,
    clearErrors,
  ]);

  useEffect(() => {
    if (formField?.minOrderQTY) clearErrors("minOrderQTY");
    if (formField?.manufacturerId) clearErrors("manufacturerId");

    if (!formField?.isMultiple) {
      if (formField?.wholesalePrice) clearErrors("wholesalePrice");
    }

    if (formField?.isMultiple) {
      if (formField?.variationsFields?.length) clearErrors("variationsFields");
    }

    if (!formField?.isMultiple) {
      if (formField?.sku) clearErrors("sku");
      if (formField?.retailPrice) clearErrors("retailPrice");
      if (formField?.itemsPerCase) clearErrors("itemsPerCase");
    }
  }, [
    clearErrors,
    formField?.manufacturerId,
    formField?.retailPrice,
    formField?.sku,
    formField?.itemsPerCase,
    formField?.minOrderQTY,
    formField?.isMultiple,
    formField?.variationsFields,
    formField?.wholesalePrice,
    // hasCharacterNameError,
  ]);

  useEffect(() => {
    dispatch(getCategoriesListAction());
  }, [dispatch]);

  const [tabNames, setTabNames] = useState([
    { title: "Required Info", name: "Required Info", visible: true },
    {
      title: "Variations",
      name: <Box>Variations</Box>,
      visible: false,
    },
    {
      title: "Inventory",
      name: <Box>Inventory</Box>,
      visible: true,
    },
  ]);

  const [currentTab, setCurrentTab] = useState(tabNames[0].title);

  useEffect(() => {
    dispatch(setFormChangedAction(isDirty));
  }, [isDirty, dispatch, currentTab]);

  useEffect(() => {
    setIsColor(formField.chooseVariationsType.color);
  }, [formField.chooseVariationsType.color]);

  useEffect(() => {
    setIsSize(formField.chooseVariationsType.size);
  }, [formField.chooseVariationsType.size]);

  const getProductsById = () => {
    getProductByIdService(productId)
      .then((res) => {
        const preparedData = setPreparedData({ res });

        reset({
          ...formField,
          rawProduct: {
            ...preparedData,
            requiredFieldsMissing: res?.requiredFieldsMissing,
          },
          ...preparedData,
        });
        if (state?.dirNav === "edit_inventory") {
          return setCurrentTab(tabNames[2].title);
        }

        if (state?.checkMissingInfo) {
          trigger();
        }

        if (state?.isChildren) {
          setValue("blinkVariationsTab", state?.isChildren);
          setCurrentTab(tabNames[1].title);
        }

        if (res?.type === PRODUCT_TYPE_INVENTORY.non_inventory) {
          setTabNames((prev) => {
            const temp = [...prev];
            const inventoryTabIndex = temp.findIndex(
              (tab) => tab.title === "Inventory"
            );

            if (inventoryTabIndex >= 0) {
              temp.splice(inventoryTabIndex, 1, {
                ...temp[inventoryTabIndex],
                visible: false,
              });
            }

            return temp;
          });
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
        error("Something went wrong!");
      });
  };

  useEffect(() => {
    if (productId) {
      getProductsById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  useEffect(() => {
    if (
      errors.name ||
      errors.sku ||
      errors.barcode ||
      errors.manufacturerId ||
      errors.wholesalePrice ||
      errors.itemsPerCase ||
      errors.minOrderQTY
    )
      return setTabNames([
        ...tabNames,
        (tabNames[0].name = (
          <Box>
            <AtentionIcon /> Required Info
          </Box>
        )),
      ]);
    setTabNames([...tabNames, (tabNames[0].name = <Box>Required Info</Box>)]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    errors.name,
    errors.sku,
    errors.barcode,
    errors.manufacturerId,
    errors.wholesalePrice,
    errors.itemsPerCase,
    errors.minOrderQTY,
  ]);

  useEffect(() => {
    if (
      errors.skuVariationFields ||
      errors.colorVariationFields ||
      errors.sizeVariationFields ||
      errors.variationsFields ||
      errors.variationsFields?.type === "variationsFieldsValidation"
    )
      return setTabNames([
        ...tabNames,
        (tabNames[1].name = (
          <Box>
            <AtentionIcon /> Variations
          </Box>
        )),
      ]);
    setTabNames([...tabNames, (tabNames[1].name = <Box>Variations</Box>)]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    errors.colorVariationFields,
    errors.sizeVariationFields,
    errors.skuVariationFields,
    errors.variationsFields,
  ]);

  useEffect(() => {
    if (
      formField.manufacturerIdInactive?.status === "INACTIVE" &&
      formField.manufacturerId === formField.manufacturerIdInactive?.id
    ) {
      setError("manufacturerId", {
        message: "This manufacturer is inactive.",
      });
    }
  }, [
    formField.manufacturerId,
    formField.manufacturerIdInactive?.id,
    formField.manufacturerIdInactive?.status,
    setError,
  ]);

  const setVariationsTab = (isVisible) => {
    setTabNames([...tabNames, (tabNames[1].visible = isVisible)]);
  };

  const handleCurrentTab = (tab) => {
    setCurrentTab(tab);
  };

  const handleOpenProductProfile = useCallback(
    (id) => {
      navigate(id);
    },
    [navigate]
  );

  const handleSaveInventoryItem = (id, data, type) => {
    updateSingleInventoryService(id, data)
      .then((res) => {
        if (type === "multiple") {
          const currentInventory = formField.variationsFields.filter(
            (item) => item.id === id
          )?.[0];

          const preparedInventoryItemData = getInventoryItemFields(
            res,
            currentInventory
          );

          const updatedVariationsField = formField.variationsFields.map(
            (item) => {
              if (item.id === id) {
                return {
                  ...preparedInventoryItemData,
                  color: item.color,
                  initialId: item.initialId,
                };
              }
              return item;
            }
          );

          reset({
            ...formField,
            variationsFields: updatedVariationsField,
            rawProduct: {
              ...formField.rawProduct,
              variationsFields: updatedVariationsField,
            },
            inventoryWithoutSave: null,
          });
        }

        if (type === "single") {
          reset({
            ...formField,
            inventoryWithoutSave: null,
            inventory: {
              ...formField.inventory,
              onHand: data?.onHand,
              expected: data?.expected,
            },
            rawProduct: {
              ...formField.rawProduct,
              onHand: data?.onHand,
              expected: data?.expected,
            },
          });
        }
        success("Inventory updated");
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
        error("Something went wrong!");
      });
  };

  const handleConfirmChangesDialog = useCallback(() => {
    dispatch(
      openDiscardChanges(() => navigate(-1, { state: { navigateState } }))
    );
  }, [dispatch, navigate, navigateState]);

  const { handleSaveProduct, onSubmit } = useActions({
    isEdit,
    productId,
    productProfile: formField,
    state,
    formField,
    errors,
    setError,
    setValue,
  });

  return (
    <>
      <CatalogNewProductSwitcherTab
        title={
          <Box display="flex" alignItems="baseline" gap="15px">
            <Typography
              label="Add Manufacturer"
              component={Link}
              to={{
                pathname: formChanged
                  ? location.pathname
                  : navigatePath || "/catalog",
                state: { navigateState },
              }}
              onClick={() => {
                if (formChanged) {
                  handleConfirmChangesDialog();
                }
              }}
              state={navigateState}
              from={pathname}
              sx={{
                fontSize: "20px",
                cursor: "pointer",
                textDecoration: "none",
              }}
              color="primary"
            >
              Catalog
            </Typography>
            <IosArrowForward />
            <p style={{ color: "#5F6267" }}>
              {!isEdit ? "add new product" : "edit product"}
            </p>
          </Box>
        }
        handleSubmit={handleSubmit(onSubmit)}
        currentTab={currentTab}
        saveButtons
        navigateState="Products"
        navigatePath="/catalog"
        errors={errors}
        trigger={trigger}
        control={control}
      />
      <Grid container gap={2} justifyContent={"center"}>
        <Grid mt="29px" item maxWidth="1178px" width="100%">
          <ProductDetails
            tabNames={tabNames.filter((tab) => tab.visible)}
            setTabNames={setTabNames}
            currentTab={currentTab}
            handleOpenProductProfile={handleOpenProductProfile}
            handleCurrentTab={handleCurrentTab}
            setVariationsTab={setVariationsTab}
            control={control}
            isEdit={isEdit}
            handleSubmit={handleSubmit}
            setValue={setValue}
            reset={reset}
            setError={setError}
            errors={errors}
            clearErrors={clearErrors}
            register={register}
            handleSaveInventoryItem={handleSaveInventoryItem}
            trigger={trigger}
            handleSaveProduct={handleSaveProduct}
            onSubmit={handleSubmit(onSubmit)}
          />
        </Grid>
      </Grid>
    </>
  );
};
CatalogNewProductPage.propTypes = {
  isEdit: bool,
  navigatePath: string,
  navigateState: string,
};
CatalogNewProductPage.defaultProps = {
  isEdit: false,
};

export default CatalogNewProductPage;
