import React from "react";

export const CustomersIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="20"
      viewBox="0 0 24.099 19.75"
      {...props}
    >
      <g
        id="Group_4870"
        data-name="Group 4870"
        transform="translate(-898.177 -16492.25)"
      >
        <path
          id="Path_3001"
          data-name="Path 3001"
          d="M58.9,5.031l.008.012a2.731,2.731,0,0,1-1.792,4.18,3.264,3.264,0,0,1-.418.026A3.12,3.12,0,0,1,54.37,8.2a.75.75,0,0,0-1.121,0,3.11,3.11,0,0,1-4.646,0,.75.75,0,0,0-1.12,0,3.11,3.11,0,0,1-4.646,0,.75.75,0,0,0-1.122,0A3.107,3.107,0,0,1,39.4,9.25a3.31,3.31,0,0,1-.415-.03,2.735,2.735,0,0,1-1.79-4.179h0l.007-.011L39.733.984A.5.5,0,0,1,40.156.75H55.938a.5.5,0,0,1,.423.234Z"
          transform="translate(862.177 16492.25)"
          // fill="none"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_3002"
          data-name="Path 3002"
          d="M39,11.5V15a4,4,0,0,0,4,4H53a4,4,0,0,0,4-4V11.5"
          transform="translate(862.177 16492.25)"
          fill="none"
          strokeLinecap="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_3003"
          data-name="Path 3003"
          d="M44,12v1a2,2,0,0,0,2,2h4a2,2,0,0,0,2-2V12"
          transform="translate(862.177 16492.25)"
          // fill="none"
          strokeLinecap="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
};
