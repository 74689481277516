export const LowestOrderIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
    >
      <path
        id="icons8-sort-amount-up-reversed"
        d="M4,5V6.043H5.043V5Zm9.391.261-.376.36L10.783,7.87l.736.736L12.87,7.249V17h1.043V7.249l1.351,1.356L16,7.87,13.767,5.621ZM4,7.087V8.13H6.087V7.087ZM4,9.174v1.043H7.13V9.174Zm0,2.087V12.3H8.174V11.261Zm0,2.087v1.043H9.217V13.348Zm0,2.087v1.043h6.261V15.435Z"
        transform="translate(-4 -5)"
        fill="#3f3f3f"
      />
    </svg>
  );
};
