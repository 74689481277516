/* eslint-disable react/prop-types */

import * as React from "react";
export const ArrowDropDownIcon = ({ fill = "#47a06d", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={9.136}
    height={4.568}
    viewBox="0 0 9.136 4.568"
    {...props}
  >
    <path
      data-name="Icon ionic-md-arrow-dropdown"
      d="m0 0 4.568 4.568L9.136 0Z"
      fill={fill}
    />
  </svg>
);
export default ArrowDropDownIcon;
