import { number } from "prop-types";
import { Box, Grid, Typography } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";
import { separateNumWithComma } from "helpers/helpers";

export const MainTitleBlock = ({ sum }) => {
  return (
    <Grid
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1.5,
      }}
      xs={12}
      item
    >
      <CheckCircle
        sx={{ fontSize: { xs: 26, md: 42 } }}
        style={{ fill: "#409A65" }}
      />
      <Typography
        fontSize={{ xs: 14, md: 17 }}
        fontWeight={400}
        color="#707070"
      >
        {!!sum && (
          <Box
            fontSize={{ xs: 15, md: 17 }}
            fontWeight={{ xs: 400, md: 600 }}
            color="#363531"
            component="span"
          >
            ${separateNumWithComma(sum)}
          </Box>
        )}{" "}
        Payment has been received
      </Typography>
    </Grid>
  );
};

MainTitleBlock.propTypes = { sum: number };
