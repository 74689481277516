export const defaultCustomer = {
  id: "",
  active: true,
  name: "",
  group: "",
  paymentTerms: null,
  federalTaxId: "",
  federalTaxIdPhoto: null,
  federalTaxIdPhotoId: null,
  billingAddress: {
    formatted_address: "",
    zip: "",
    city: "",
    state: "",
    street: "",
    appartement: "",
  },
  shippingAddress: {
    formatted_address: "",
    zip: "",
    city: "",
    state: "",
    street: "",
    appartement: "",
  },
  phone: "",
  email: "",
  territory: "Uncategorized",
  website: "",
  tags: "",
  discount: "",
  creditCards: [],
};

export const TAB_NAMES = ["General Info", "Credit Cards"];

export const CANADAS_STATE_NAMES = {
  AB: "Alberta",
  BC: "British Columbia ",
  MB: "Manitoba",
  NB: "New Brunswick",
  NL: "Newfoundland and Labrador",
  NS: "Nova Scotia",
  ON: "Ontario",
  PEI: "Prince Edward Island",
  QC: "Quebec",
  SK: "Saskatchewan",
};

export const STATE_NAMES = {
  AL: "Alabama",
  AK: "Alaska",
  AS: "American Samoa",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  DC: "District Of Columbia",
  FM: "Federated States Of Micronesia",
  FL: "Florida",
  GA: "Georgia",
  GU: "Guam",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MH: "Marshall Islands",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  MP: "Northern Mariana Islands",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PW: "Palau",
  PA: "Pennsylvania",
  PR: "Puerto Rico",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VI: "Virgin Islands",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
};

export const INITIAL_POPPER = {
  open: false,
  title: { name: "" },
  anchorEl: null,
  modifiers: [
    {
      name: "offset",
      options: {
        offset: [5, 7],
      },
    },
  ],
};
