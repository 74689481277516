import { useMemo, useRef } from "react";
import { object, func, string, bool } from "prop-types";
import pluralize from "pluralize";
import moment from "moment";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import {
  Card,
  Typography,
  Stack,
  Divider,
  IconButton,
  Box,
} from "@mui/material";
import { stringAvatar, photoUrl, getFormattedDate } from "helpers/helpers";
import {
  GalleryCheckedIcon,
  GalleryLocationIcon,
  GalleryUnCheckedIcon,
} from "components/Icons";
import { CardMediaImage, StyledTooltip } from "components";
import { PhotoCount } from "./components";
import { Avatar, CardContent, Checkbox, Chip } from "./PhotoGroupCard.styles";

const PhotoGroupCard = ({
  product,
  handleSetProduct,
  handleCheckPhoto,
  timeZone,
  isChecked,
}) => {
  const photoCountRef = useRef(null);
  const photoCountWidth =
    photoCountRef.current && photoCountRef.current.clientWidth;

  const reorderLength = product.photoProductInfo?.reduce((sum , p) => sum + (p?._count?.products || 0), 0);

  const getCommentsCount = useMemo(() => {
    return product?.note
      ? product.comments?.length + 1
      : product.comments?.length;
  }, [product.comments?.length, product?.note]);

  return (
    <Card
      sx={{
        height: "400px",
        backgroundColor: "#ffffff",
        border: "1px solid #D5D9D9",
        p: "16px 18px 11px 14px",
        borderRadius: "4px",
        width: "100%",
      }}
      elevation={0}
    >
      <Box sx={{ position: "relative" }}>
        <CardMediaImage
          placeholderSrc={photoUrl(product?.photos[0]?.compressedFileName)}
          src={photoUrl(
            product?.photos[0]?.compressedFileName3 ||
              product?.photos[0]?.fileName
          )}
          alt={`${product?.customer?.name} - photo`}
          onClick={() => handleSetProduct(product)}
        />
        <Checkbox
          style={{ width: 28, height: 28 }}
          disableRipple
          sx={{
            position: "absolute",
            right: "4px!important",
            top: "4px!important",
            p: "5px!important",
            borderRadius: "6px",
            backgroundColor: "rgba(255, 255, 255, 0.78)!important",
          }}
          icon={<GalleryCheckedIcon />}
          checkedIcon={<GalleryUnCheckedIcon />}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onChange={() => {
            handleCheckPhoto(product);
          }}
          checked={isChecked}
        />
        {!!product.photoProductInfo?.products?.length && (
          <Typography
            sx={{
              position: "absolute",
              top: "4px",
              left: "4px",
              backgroundColor: "rgba(255, 255, 255, 0.78)",
              borderRadius: "4px",
              p: "2px 10px 3px 7px",
              maxWidth: "85%",
            }}
            noWrap
            fontSize="11px"
            color="#000000"
          >
            {product.photoProductInfo.manufacturer.name}
          </Typography>
        )}
        <Box sx={{ position: "absolute", bottom: "14px", right: "14px" }}>
          <StyledTooltip
            title={product.representativeDuplicate?.name || ""}
            placement="top-end"
            arrow
          >
            {product.representativeDuplicate?.profilePhoto ? (
              <Avatar
                src={photoUrl(
                  product.representativeDuplicate?.profilePhoto?.fileName
                )}
              />
            ) : (
              <Avatar
                {...stringAvatar(product.representativeDuplicate?.name)}
              />
            )}
          </StyledTooltip>
        </Box>
      </Box>
      <CardContent>
        <Box
          width="100%"
          display="inline-flex"
          alignItems="center"
          gap="8px"
          position="relative"
        >
          <StyledTooltip
            title={
              <Typography
                fontWeight="400"
                color="#707070"
                fontSize="12px"
                whiteSpace="pre-wrap"
              >
                {product.customer?.displayName ||
                  product.customer?.name ||
                  "Unknown"}
                , {product.customer?.group?.name || "Unknown"}
                {"\n"}
                <span
                  style={{
                    fontSize: "10px",
                    fontWeight: 400,
                    color: "#A7A6A6",
                  }}
                >
                  {product.customer?.shippingAddress?.formatted_address ||
                    "Unknown"}
                </span>
              </Typography>
            }
            placement="top"
            arrow
            className="map-tooltip"
          >
            <IconButton sx={{ p: "1px" }}>
              <GalleryLocationIcon fill="#5F6267" />
            </IconButton>
          </StyledTooltip>
          <Box
            sx={{
              width: "calc(100% - 22px)",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 0.5,
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 500,
                maxWidth: `calc(100% - ${photoCountWidth}px)`,
              }}
              noWrap
              color="#5F6267"
            >
              {product.customer?.displayName ||
                product.customer?.name ||
                "Deleted customer"}
            </Typography>
            <PhotoCount
              ref={photoCountRef}
              name="photos"
              count={product?.photos?.length ?? 0}
              show={product?.photos?.length > 1}
              onClick={() => handleSetProduct(product)}
            />
          </Box>
        </Box>
        <Box display="flex" gap="9px">
          <Typography sx={{ fontSize: "12px", color: "#5F6267" }}>
            {pluralize("comment", getCommentsCount, true)}
          </Typography>
          {!!reorderLength && (
            <>
              <Divider flexItem orientation="vertical" />
              <Typography sx={{ fontSize: "12px", color: "#FF8000" }}>
                {reorderLength} products require reorder
              </Typography>
            </>
          )}
        </Box>
        <Box
          display="flex"
          alignItems="center"
          mt="6px"
          justifyContent="space-between"
        >
          <Stack
            sx={{
              pb: "2px",
              maxWidth: "81%",
              overflow: "hidden",
              height: "20px",
            }}
            direction="row"
            overflow="auto"
            spacing={1}
          >
            {product.tags?.slice(0, 2).map((item) => (
              <Chip
                key={item.tag.id}
                sx={{
                  fontSize: "12px",
                  height: "20px",
                  color: "#5F6368",
                  maxWidth: "100px",
                  backgroundColor: "#F8F8F8",
                }}
                label={item.tag.tag}
                variant="outlined"
              />
            ))}
          </Stack>
          {product.tags.length > 2 && (
            <Typography fontSize="12px" color="#5F6267">
              +{product.tags.length - 2} tags
            </Typography>
          )}
        </Box>
        <Box display="flex" justifyContent="space-between" mt="8px">
          <Typography fontSize="12px" color="#959595">
            {product.createdAt &&
              moment(getFormattedDate(product.createdAt, timeZone)).format(
                "MMMM D, YY [at] h:mm a"
              )}
          </Typography>
          <Box
            display="inline-flex"
            alignItems="center"
            gap="8px"
            onClick={() => handleSetProduct(product)}
            sx={{
              cursor: "pointer",
              "&:hover": {
                "& .MuiTypography-root": {
                  textDecoration: "underline",
                },
              },
            }}
          >
            <Typography fontSize="14px" color="#5F6267" fontWeight="500">
              Details
            </Typography>
            <IconButton sx={{ p: "0px" }}>
              <ArrowForwardIosRoundedIcon style={{ fontSize: "13px" }} />
            </IconButton>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

PhotoGroupCard.propTypes = {
  product: object,
  handleSetProduct: func,
  handleCheckPhoto: func,
  timeZone: string,
  isChecked: bool,
};

PhotoGroupCard.defaultProps = {
  product: null,
};

export default PhotoGroupCard;
