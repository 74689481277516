import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  headerWrap: {
    padding: "13px 7px 2px 16px",
    borderBottom: "0.5px solid #D5D9D9",
  },

  descriptionUtils: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },

  headerInfo: {
    height: "100%",
    minHeight: "62px",
    "& .name": {
      fontWeight: "700",
      color: "#000000",
    },
    "& .website": {
      fontSize: "13px",
      lineHeight: "18.5px",
    },

    "& .phone": {
      lineHeight: "18.5px",
      fontSize: "13px",
    },
  },

  picture: {
    height: "50px",
    width: "50px",
  },

  copy: {
    width: "fit-content",
  },

  edit: {
    padding: "0 9px",
    minWidth: "fit-content",
  },

  infoSection: {
    padding: "12px 11px 9px 16px",
    "&:not(:last-of-type)": {
      borderBottom: "0.5px solid #D5D9D9",
    },
  },

  buttons: {
    borderColor: "#D4D4D4",
    color: "#000",
    maxWidth: "55px",
    minWidth: "55px",
    height: "19px",
    paddingLeft: "0px",
    paddingRight: "0px",
  },

  actionsWrap: {
    marginLeft: "-8px",
    display: "flex",
    justifyContent: "space-between",
  },

  actionButton: {
    "&:hover": { backgroundColor: "transparent" },
    flexDirection: "column",
    gap: "3px",
    "& .MuiTypography-root": { fontSize: "12px" },
  },

  actionIcon: {
    backgroundColor: "#EDF6F0",
    borderRadius: "50%",
    height: "29px",
    width: "29px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    //"& svg": {
    //  height: "28px",
    //  width: "28.5px",
    //},
  },

  statusSelect: {
    backgroundColor: "rgba(71, 160, 109, 0.1)",
    border: "none",
    height: "30px",
    width: "95px",
    minWidth: "95px",
    fontSize: "13px",
    color: "#5F6267",
    "& .MuiInputBase-input": {
      paddingRight: "26px !important",
    },
    "& fieldset": {
      border: "none",
      borderRadius: "8px",
    },
  },

  status: {
    backgroundColor: "rgba(71, 160, 109, 0.1)",
    fontSize: "13px",
    // padding: "6px 14px",
    borderRadius: "8px",
    color: "#5F6267",
    width: "95px",
    height: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  tagFieldWrap: { marginTop: "16px" },
}));

export default useStyles;
