import { object, string, number } from "prop-types";
import { Box, Typography } from "@mui/material";
import { HeaderItem } from "./components";
import {
  NextArrow,
  PrevArrow,
} from "Pages/CatalogPage/CatalogNewProductPage/ContentTabs/Arrows";
import { normalizeUppercaseString } from "helpers/helpers";

export const HeaderBlock = ({
  customer,
  headerBlockRef,
  customerNameRef,
  repPermissions,
  classes,
  address,
  customerNameWidth,
}) => {
  const {
    id: customerId,
    name,
    displayName,
    assignedRepresentatives,
    customId,
    categories,
  } = customer || {};

  const sliderSettings = {
    infinite: false,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    variableWidth: true,
    nextArrow: <NextArrow width="4.5" height="7.9" color="#707070" top="30%" />,
    prevArrow: <PrevArrow width="4.5" height="7.9" color="#707070" top="30%" />,
  };

  const handleAvatarContent = (rep) => (
    <Box sx={{ textAlign: "center" }}>
      <Typography fontSize="10px" color="primary">
        {!!rep?.role &&
          (rep?.role === "THIRD_PARTY"
            ? "3rd Party Rep"
            : `${normalizeUppercaseString(rep?.role)} Rep`)}
      </Typography>
      <Typography fontSize="10px" color="#363531">
        {rep?.name}
      </Typography>
    </Box>
  );

  return (
    <Box>
      <HeaderItem
        {...{
          headerBlockRef,
          customerNameRef,
          repPermissions,
          customerId,
          classes,
          displayName,
          name,
          address,
          categories,
          sliderSettings,
          assignedRepresentatives,
          customerNameWidth,
          handleAvatarContent,
        }}
        customId={customId?.customId || ""}
      />
    </Box>
  );
};

HeaderBlock.propTypes = {
  customer: object,
  headerBlockRef: object,
  customerNameRef: object,
  repPermissions: object,
  classes: object,
  address: string,
  customerNameWidth: number,
};
HeaderBlock.defaultProps = {
  customer: {},
  headerBlockRef: {},
  customerNameRef: {},
  repPermissions: {},
  classes: {},
  address: "",
  customerNameWidth: 0,
};
