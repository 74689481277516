import { useState } from "react";
import { uploadGenerateService, uploadPhotoService } from "services/files";
import { error } from "utils/notifications";
import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import { app } from "firebase/Chat/config";
import { createSelector } from "reselect";
import {
  confirmDialogFormChangedSelector,
  editTypeSelector,
} from "redux/selectors/confirmDialogs";
import { useDispatch, useSelector } from "react-redux";
import {
  setEditTypeAction,
  setFormChangedAction,
} from "redux/actions/confirmDialogs";
import { generateUUID } from "helpers/helpers";

const selector = createSelector(
  editTypeSelector,
  confirmDialogFormChangedSelector,
  (editType, formChanged) => ({
    editType,
    formChanged,
  })
);

export const useUploadFiles = ({ isEdit }) => {
  const { editType } = useSelector(selector);
  const dispatch = useDispatch();

  const firestore = getFirestore(app);
  const [loading, setLoading] = useState(null);

  const handleUploadFiles = async (file, onSuccess) => {
    if (!file) return new Promise(null);
    const temporaryId = generateUUID();
    setLoading(temporaryId);

    if (editType === "product" && !isEdit) {
      dispatch(setEditTypeAction("product-photo", true));
      dispatch(setFormChangedAction(true));
    }

    let preparedData = {
      gallery: [
        {
          name: file?.[0].name,
          type: "PRODUCT",
        },
      ],
    };

    try {
      const uploadGenerate = await uploadGenerateService(preparedData);

      if (!uploadGenerate?.groupContentId)
        throw new Error("Something went wrong!");

      const groupContentId = uploadGenerate?.groupContentId;
      const uuid = uploadGenerate?.signedUrlItems?.length
        ? uploadGenerate?.signedUrlItems?.[0]?.uuid
        : null;
      const link = uploadGenerate?.signedUrlItems?.length
        ? uploadGenerate?.signedUrlItems?.[0]?.signedUrl
        : null;
      const headers = uploadGenerate?.signedUrlItems?.length
        ? uploadGenerate?.signedUrlItems?.[0]?.headers
        : null;

      await uploadPhotoService({
        file: file[0],
        url: link,
        headers: {
          uuid,
          "Content-Type": file?.[0].type,
          ...headers,
        },
      });

      const snapshotListener = onSnapshot(
        doc(firestore, "image-uploads", groupContentId),
        (doc) => {
          const res = doc?.data();
          if (res?.finalized) {
            setLoading(null);
            onSuccess();
            if (editType === "product")
              dispatch(setEditTypeAction("product", !isEdit));
            snapshotListener();
          }
        }
      );

      const objectUrl = URL.createObjectURL(file?.[0]);

      return [
        {
          fileName: objectUrl,
          uuid,
          groupContentId,
          temporaryId,
        },
      ];
    } catch (err) {
      setLoading(null);
      // eslint-disable-next-line no-console
      console.error(err);
      error(err?.response?.data?.message || "Something went wrong.");
    }
  };

  return { handleUploadFiles, loading };
};
