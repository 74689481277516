import { useDispatch } from "react-redux";
import { getRepsAction } from "../../../redux/actions/reps";
import { SCROLL_LIMIT } from "../../../utils/constants";

export const useShippingActions = ({
  deliveryMethod,
  delivererId,
  repsSalesUsers,
}) => {
  const dispatch = useDispatch();

  const handleFetchReps = () => {
    const fetchQuery = {
      limit: SCROLL_LIMIT,
      role: "sales",
      cursor: repsSalesUsers?.[repsSalesUsers.length - 1]?.id,
    };
    dispatch(getRepsAction(fetchQuery, { isScrolling: true }));
  };

  return {
    deliveryMethod,
    delivererId,
    handleFetchReps,
  };
};
