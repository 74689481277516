import * as React from "react";

export const SettingsFilledIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    // width={19.326}
    width={17.326}
    // height={19.793}
    height={17.793}
    viewBox="0 0 19.326 19.793"
    {...props}
  >
    <g
      id="Group_5014"
      data-name="Group 5014"
      transform="translate(-3.295 -2.77)"
    >
      <path
        id="Path_3136"
        data-name="Path 3136"
        d="M19.822,12.666a7.375,7.375,0,0,1-.063.893l1.916,1.514a.447.447,0,0,1,.112.585l-1.831,3.175a.447.447,0,0,1-.558.2l-2.282-.92A6.861,6.861,0,0,1,15.571,19l-.344,2.425a.447.447,0,0,1-.447.384H11.118a.447.447,0,0,1-.447-.384L10.328,19a7,7,0,0,1-1.545-.893L6.5,19.03a.447.447,0,0,1-.558-.2L4.111,15.658a.447.447,0,0,1,.112-.585l1.929-1.514a7.379,7.379,0,0,1-.063-.893,7.441,7.441,0,0,1,.063-.893L4.223,10.259a.447.447,0,0,1-.112-.585L5.942,6.5A.447.447,0,0,1,6.5,6.3l2.291.92a6.86,6.86,0,0,1,1.545-.893L10.68,3.9a.447.447,0,0,1,.447-.384H14.8a.447.447,0,0,1,.447.384l.344,2.425a7,7,0,0,1,1.545.893l2.282-.92a.447.447,0,0,1,.558.2L21.8,9.674a.447.447,0,0,1-.112.585L19.76,11.773A7.375,7.375,0,0,1,19.822,12.666Z"
        transform="translate(0 0)"
        fill="#409a65"
        stroke="#409a65"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
      <circle
        id="Ellipse_850"
        data-name="Ellipse 850"
        cx={3.461}
        cy={3.461}
        r={3.461}
        transform="translate(9.497 9.205)"
        fill="#fff"
      />
    </g>
  </svg>
);
