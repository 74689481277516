import * as React from "react";
export const ImageIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15.95}
    height={15.95}
    viewBox="0 0 15.95 15.95"
    {...props}
  >
    <g
      data-name="Icon feather-image"
      fill="none"
      stroke="#1c1c19"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        data-name="Path 5309"
        d="M2.138.5h11.467a1.638 1.638 0 0 1 1.638 1.638v11.467a1.638 1.638 0 0 1-1.638 1.638H2.138A1.638 1.638 0 0 1 .5 13.605V2.138A1.638 1.638 0 0 1 2.138.5Z"
      />
      <path
        data-name="Path 5310"
        d="M6.233 5.005a1.229 1.229 0 1 1-1.228-1.229 1.229 1.229 0 0 1 1.228 1.229Z"
      />
      <path data-name="Path 5311" d="m15.243 10.333-4.095-4.1-9.01 9.01" />
    </g>
  </svg>
);
