import axios from "axios";

export const getInvitesService = async (params) => {
  return await axios({
    method: "GET",
    url: "/users/invites",
    params,
  }).then((res) => res.data);
};

export const getReceivedService = async (params) => {
  return await axios({
    method: "GET",
    url: "/users/orders-directs",
    params,
  }).then((res) => res.data);
};

export const sendInviteService = async (data) => {
  return await axios({
    method: "POST",
    url: "/users/distributors/invite",
    data,
  });
};

export const cancelInviteService = async (id) => {
  return await axios({
    method: "DELETE",
    url: `/users/distributors/invite/${id}`,
  });
};

export const revokeInviteService = async (id) => {
  return await axios({
    method: "PUT",
    url: `/users/distributors/invite/${id}`,
  });
};

export const approveOrderDirectAccessService = async (id, data) => {
  return await axios({
    method: "POST",
    url: `/users/distributors/approve-order-direct/${id}`,
    data,
  });
};

export const checkStoreNameAvailabilityService = async (store_name) => {
  return await axios
    .get("/users/distributors/check-store-name-availability", {
      params: { store_name },
    })
    .then((res) => res.data);
};

export const setStoreDataService = async (params) => {
  return await axios
    .post("/users/distributors/set-store-data", params)
    .then((res) => res.data);
};
