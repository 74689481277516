import React from "react";

export const OrdersIconFilled = ({ ...props }) => {
  return (
    <svg
      width={16}
      height={20}
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2z"
        fill="#409A65"
      />
      <path
        d="M8.667 2H7.333a2.8 2.8 0 0 0-.942.146C6.14 2.24 6 2.367 6 2.5s.14.26.39.354.59.146.943.146h1.334c.353 0 .692-.053.942-.146.25-.094.391-.221.391-.354s-.14-.26-.39-.354A2.8 2.8 0 0 0 8.666 2"
        fill="#409A65"
        stroke="#409A65"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 13h3M5 8h2"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.065 2.8H3a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-12a2 2 0 0 0-2-2h-2.065"
        stroke="#409A65"
        strokeWidth={1.5}
      />
      <path
        d="M9 1H7a2 2 0 1 0 0 4h2a2 2 0 0 0 0-4"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
