import { object, string } from "prop-types";
import { StyledTextField } from "../../../../components/TextFields/TextFields";

export const StyledField = ({ control, field, error, label, ...props }) => {
  return (
    <StyledTextField
      size="medium"
      noErrorMessage
      fullWidth
      sx={{
        borderRadius: "8px",
      }}
      InputProps={{
        style: {
          fontSize: "18px",
          height: "48px",
          borderRadius: "8px",
        },
      }}
      InputLabelProps={{
        sx: {
          top: "-4px",
          fontSize: "18px",
          height: "48px",
          "&.MuiInputLabel-shrink": {
            top: "0px",
          },
        },
      }}
      formSx={{
        fontSize: 18,
        height: "48px",
      }}
      labelSx={{
        fontSize: 18,
      }}
      label={label}
      error={error?.message ? error?.message : ""}
      {...field}
      control={control}
      {...props}
    />
  );
};

StyledField.propTypes = {
  control: object,
  field: object,
  error: object,
  label: string,
};
