/* eslint-disable react/prop-types */
import * as React from "react";
export const ArrowUpIcon = ({ fill = "#5f6267", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={5.263}
    height={10.707}
    viewBox="0 0 5.263 10.707"
    {...props}
  >
    <path
      data-name="Icon awesome-long-arrow-alt-down"
      d="M1.674 3.205v7.216a.287.287 0 0 0 .287.287H3.3a.287.287 0 0 0 .287-.287V3.205h1.1a.574.574 0 0 0 .406-.979L3.036.169a.574.574 0 0 0-.811 0L.168 2.226a.574.574 0 0 0 .406.979h1.1Z"
      fill={fill}
    />
  </svg>
);
