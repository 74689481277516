import { Box, Grid, Typography } from "@mui/material";
import { object } from "prop-types";
import {
  getValueLabel,
  setDiscountValue,
} from "../../../../../DiscountsPage/components/ApplyDiscountPage/components/MSDPage/MSDPage.helpers";
import useStyles from "../styles";

const PromotionItem = ({ promotion }) => {
  const classes = useStyles({ type: "promotions" });
  return (
    <Grid container className={classes.gridItem}>
      <Grid item xs={6} className={`${classes.gridColumn} main`}>
        <Box>
          <Typography noWrap>
            {promotion.manufacturerName} - {promotion.name}
          </Typography>
          <Typography noWrap>
            {setDiscountValue(promotion)} {getValueLabel(promotion)}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={3} className={`${classes.gridColumn} secondary`}>
        <Typography>Used</Typography>
        <Typography noWrap>{promotion.used?.toLocaleString()} times</Typography>
      </Grid>
      <Grid item xs={3} className={`${classes.gridColumn} secondary`}>
        <Typography>Revenue</Typography>
        <Typography>
          $
          {promotion.revenue?.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Typography>
      </Grid>
    </Grid>
  );
};

PromotionItem.propTypes = { promotion: object };

export default PromotionItem;
