import { makeStyles } from "@mui/styles";

export const cl = {
  cardItem: {
    mainWrapper: {
      border: "0.5px solid #CCCCCC",
      borderRadius: "4px",
      height: "51px",
      px: 1,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  selectProps: {
    height: "48px",
    fontSize: "18px",
    border: "1px solid #D5D9D9",
    borderRadius: "8px",
    labelSx: {
      fontSize: "18px",
      color: "#666666",
      "&.Mui-focused": {
        top: "-1px",
      },
      "&.MuiFormLabel-filled": {
        top: "-1px",
      },
    },
  },
};

const useStyles = makeStyles(() => ({
  inputLabel: {
    fontSize: "18px",
    top: 0,
    color: "#666666",
  },

  input: {
    height: "48px",
    fontSize: "18px",
    borderRadius: "8px",
    "& fieldset": { border: "1px solid #D5D9D9" },
    "& input": { padding: "7px 14px" },
    "&:hover:not(.Mui-focused)": {
      "&& fieldset": {
        // borderColor: "rgba(194, 194, 194, 0.5)",
        borderColor: "#212121",
      },
    },
  },
}));

export default useStyles;
