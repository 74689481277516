import { Box, Paper, Typography } from "@mui/material";

export const StatusPage = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        bgcolor: "#f1f1f1",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Paper
        sx={{
          width: "667px",
          minWidth: "500px",
          height: "266px",
          minHeight: "190px",
          bgcolor: "#ffffff",
          mt: "10%",
          borderRadius: "18px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        elevation={0}
        square
      >
        <Typography fontSize={32} fontWeight={700} color="#5F6267">
          Sorry, the link has expired.
        </Typography>
      </Paper>
    </Box>
  );
};
