import { useState } from "react";
import { array, func } from "prop-types";
import { Grid, Typography } from "@mui/material";
import { ScrollControlWrapper, StyledButton } from "components";
import { setPhoneNumberMask } from "helpers/helpers";
import { REP_ROLES } from "utils/constants";

export const AssignedRepsBlock = ({ assignedReps, onEdit, onRemove }) => {
  const [hoveredElement, setHoveredElement] = useState(null);

  const setOpacity = (contact) => {
    if (hoveredElement) {
      if (!!hoveredElement?.id && hoveredElement?.id === contact?.id) return 1;
      if (!!hoveredElement?.fid && hoveredElement?.fid === contact?.fid)
        return 1;
      return 0;
    }
    return 0;
  };

  return (
    <ScrollControlWrapper
      dataLength={assignedReps?.length}
      maxHeight="226px"
      id="assigned-reps-scroll-wrapper"
      component={Grid}
      gap={1}
      container
    >
      {assignedReps?.map((reps) => {
        return (
          <Grid
            key={`${reps?.name}-${reps?.number}-${reps?.id}`}
            sx={{
              border: "0.5px solid #D5D9D9",
              borderRadius: "4px",
              bgcolor: "#F8F8F8",
              position: "relative",
            }}
            xs={12}
            item
            container
            onMouseEnter={() => {
              setHoveredElement(reps);
            }}
            onMouseLeave={() => setHoveredElement(null)}
          >
            <Grid
              sx={{
                fontSize: 13,
                color: "#5F6267",
                pt: "10px",
                pb: "8px",
                pl: "12px",
              }}
              xs={10}
              item
              container
            >
              <Grid
                sx={{
                  fontSize: 13,
                  fontWeight: 700,
                  color: "#5F6267",
                }}
                xs={12}
                item
                component={Typography}
                noWrap
              >
                {REP_ROLES[reps?.role]}
              </Grid>

              <Grid
                sx={{
                  fontSize: 13,
                  color: "#5F6267",
                }}
                xs={12}
                item
                component={Typography}
                noWrap
              >
                {reps?.name}
              </Grid>

              <Grid
                sx={{
                  fontSize: 13,
                  color: "#5F6267",
                }}
                xs={12}
                item
                component={Typography}
                noWrap
              >
                {reps?.email}
              </Grid>

              <Grid
                sx={{
                  fontSize: 13,
                  color: "#5F6267",
                }}
                xs={12}
                item
                component={Typography}
                noWrap
              >
                {setPhoneNumberMask(reps?.phone)}
              </Grid>
            </Grid>

            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                opacity: setOpacity(reps),
              }}
              xs={2}
              item
            >
              <StyledButton
                sx={{
                  mt: 1,
                  borderColor: "#D4D4D4",
                  color: "#000",
                  maxWidth: "55px",
                  minWidth: "55px",
                  height: "20px",
                  paddingLeft: "0px",
                  paddingRight: "0px",
                }}
                label="Edit"
                variant="outlined"
                color="ground"
                fontSize="10px"
                fontWeight="500"
                onClick={() => onEdit(reps)}
              />
              <StyledButton
                sx={{
                  mt: 1,
                  borderColor: "#D4D4D4",
                  color: "#000",
                  maxWidth: "55px",
                  minWidth: "55px",
                  height: "20px",
                  paddingLeft: "0px",
                  paddingRight: "0px",
                }}
                label="Remove"
                variant="outlined"
                color="ground"
                fontSize="10px"
                fontWeight="500"
                onClick={() => onRemove(reps)}
              />
            </Grid>
          </Grid>
        );
      })}
    </ScrollControlWrapper>
  );
};

AssignedRepsBlock.propTypes = {
  assignedReps: array,
  onEdit: func,
  onRemove: func,
};
AssignedRepsBlock.defaultProps = {
  contacts: [],
  onEdit: () => {},
  onRemove: () => {},
};
