import { COUNTRY_PHONE_CODES } from "../../../../../utils/constants";

export const defaultValues = {
  name: "",
  companyIndustry: "",
  description: "",
  federalTaxId: "",
  billingAddress: {
    formatted_address: "",
    zip: "",
    city: "",
    state: "",
    street: "",
    appartement: "",
  },
  shippingAddress: {
    formatted_address: "",
    zip: "",
    city: "",
    state: "",
    street: "",
    appartement: "",
  },
  email: "",
  countryPrefics: COUNTRY_PHONE_CODES[0],
  phone: "",
  website: "",
  timeZone: "",
  contacts: [],
  shippingCarriers: [
    { name: "FedEx", default: true },
    { name: "UPS", default: false },
    { name: "USPS", default: false },
    { name: "Other", default: false },
  ],
  profilePhoto: null,
};

export const TAB_NAMES = ["Summary", "Locations", "Carriers"];
