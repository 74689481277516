/* eslint-disable react/prop-types */
import React from "react";

// eslint-disable-next-line react/prop-types
export const PrimaryIcon = ({ fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39"
      height="13"
      viewBox="0 0 39 13"
    >
      <g
        id="Group_7719"
        data-name="Group 7719"
        transform="translate(-1133 -310)"
      >
        <g id="Group_7720" data-name="Group 7720">
          <rect
            id="Rectangle_11511"
            data-name="Rectangle 11511"
            width="39"
            height="13"
            rx="2"
            transform="translate(1133 310)"
            fill={fill || "#47a06d"}
          />
          <text
            id="PRIMARY"
            transform="translate(1138 319)"
            fill="#fff"
            fontSize="7"
            fontFamily="OpenSans-Regular, Open Sans"
          >
            <tspan x="0" y="0">
              PRIMARY
            </tspan>
          </text>
        </g>
      </g>
    </svg>
  );
};

export const ExpiredIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39"
      height="13"
      viewBox="0 0 39 13"
    >
      <g
        id="Group_7719"
        data-name="Group 7719"
        transform="translate(-1133 -310)"
      >
        <rect
          id="Rectangle_11511"
          data-name="Rectangle 11511"
          width="39"
          height="13"
          rx="2"
          transform="translate(1133 310)"
          fill="#ff3737"
        />
        <text
          id="DELETED"
          transform="translate(1138 319)"
          fill="#fff"
          fontSize="7"
          fontFamily="OpenSans-Regular, Open Sans"
        >
          <tspan x="1" y="0">
            EXPIRED
          </tspan>
        </text>
      </g>
    </svg>
  );
};

export const DeletedIcon = ({ customWidth, text }) => {
  const width = customWidth ?? text.length * 5.8;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height="14"
      viewBox={`0 0 ${width} 14`}
    >
      <g
        id="Group_7719"
        data-name="Group 7719"
        transform="translate(-1133 -310)"
      >
        <rect
          id="Rectangle_11511"
          data-name="Rectangle 11511"
          width={width}
          height="14"
          rx="3"
          transform="translate(1133 310)"
          fill="#ff3737"
        />
        <text
          id="DELETED"
          transform="translate(1138 319)"
          fill="#fff"
          fontSize="8"
          fontFamily="OpenSans-Regular, Open Sans"
        >
          <tspan x="-1" y="1">
            {text.toUpperCase()}
          </tspan>
        </text>
      </g>
    </svg>
  );
};

export const ProspectIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="53.098"
      height="14.31"
      viewBox="0 0 53.098 14.31"
    >
      <path
        id="Path_10267"
        data-name="Path 10267"
        d="M2.24,0H50.857A2.13,2.13,0,0,1,53.1,2V12.31a2.13,2.13,0,0,1-2.24,2H2.24a2.13,2.13,0,0,1-2.24-2V2A2.13,2.13,0,0,1,2.24,0Z"
        fill="#fbbc04"
      />
      <text
        id="PROSPECT"
        transform="translate(4.549 10.655)"
        fill="#fff"
        fontSize="9"
        fontFamily="Roboto-Light, Roboto"
        fontWeight="300"
      >
        <tspan x="0" y="0">
          PROSPECT
        </tspan>
      </text>
    </svg>
  );
};
