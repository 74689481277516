import { object, number } from "prop-types";
import { Box } from "@mui/material";
import { GoogleMap } from "components";

export const MapBlock = ({ address, name, radius }) => {
  return (
    <Box width="374px" height="200px" bgcolor="#FFF" p={1}>
      <GoogleMap customer={name} address={address} radius={radius} />
    </Box>
  );
};

MapBlock.propTypes = {
  address: object,
  name: object,
  radius: number,
};
MapBlock.defaultProps = { address: {}, name: {}, radius: 0 };
