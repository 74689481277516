import { useMediaQuery, useTheme } from "@mui/material";

export const BREAKPOINTS = ["xs", "sm", "md", "lg", "xl"];

export const useBreakpoint = () => {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only(BREAKPOINTS[0]));
  const sm = useMediaQuery(theme.breakpoints.only(BREAKPOINTS[1]));
  const md = useMediaQuery(theme.breakpoints.only(BREAKPOINTS[2]));
  const lg = useMediaQuery(theme.breakpoints.only(BREAKPOINTS[3]));
  const xl = useMediaQuery(theme.breakpoints.only(BREAKPOINTS[4]));

  if (xs) return BREAKPOINTS[0];
  if (sm) return BREAKPOINTS[1];
  if (md) return BREAKPOINTS[2];
  if (lg) return BREAKPOINTS[3];
  if (xl) return BREAKPOINTS[4];
};
