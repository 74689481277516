import { pickBy } from "lodash";
import { defaultProduct } from "./ProductDetails.constants";

export const setPreparedData = ({ res }) => {
  const {
    id,
    isMultiple,
    name,
    sku,
    barcode,
    manufacturer,
    productCategory,
    description,
    status,
    wholesalePrice,
    distributorPrice,
    minOrderQTY,
    sellingOutOfStock,
    itemsPerCase,
    retailPrice,
    tags,
    photos,
    inventory,
    hasColorVariation,
    hasSizeVariation,
    childProducts,
    type,
    countryOfOrigin,
  } = res;

  const preparedData = {
    id,
    isMultiple,
    name,
    sku: sku || "",
    barcode: barcode || "",
    manufacturerId: manufacturer?.id || "",
    manufacturerIdInactive:
      manufacturer?.status === "INACTIVE" ? manufacturer : {},
    manufacturerName: manufacturer?.name || "",
    category: productCategory || "",
    description,
    status,
    wholesalePrice: wholesalePrice || defaultProduct.wholesalePrice,
    distributorPrice: distributorPrice
      ? distributorPrice.toFixed(2)
      : defaultProduct.distributorPrice,
    minOrderQTY: minOrderQTY || 0,
    whenOutOfStock: sellingOutOfStock,
    itemsPerCase: itemsPerCase || 0,
    retailPrice: retailPrice || defaultProduct.retailPrice,
    inventory: inventory || {},
    tags: tags ? tags?.map((item) => item?.tag || item) : [],
    photos: photos.length ? photos : [],
    mainPhotoUrl: "",
    onHand: inventory?.onHand || 0,
    expected: inventory?.expected || 1, // temp
    allocated: inventory?.allocated || 0,
    chooseVariationsType: {
      sku: true,
      color: hasColorVariation,
      size: hasSizeVariation,
    },
    variationsFields: childProducts.length
      ? childProducts.map((prod) => {
          prod.initialId = prod.id;
          return pickBy(prod, (value) => {
            return value !== null;
          });
        })
      : [],
    type,
    countryOfOrigin: countryOfOrigin || "",
  };

  return preparedData;
};

export const getInventoryItemFields = (data, currentInventory) => {
  const {
    barcode,
    createdAt,
    id,
    initialId,
    inventory,
    isMultiple,
    itemsPerCase,
    photos,
    requiredFieldsMissing,
    retailPrice,
    sku,
    status,
    wholesalePrice,
    type,
    size,
    name,
  } = data;

  return {
    barcode: barcode ?? currentInventory?.barcode,
    createdAt: createdAt ?? currentInventory?.createdAt,
    id: id || currentInventory?.id,
    initialId: initialId ?? currentInventory?.initialId,
    inventory: inventory ?? currentInventory?.inventory,
    isMultiple: isMultiple ?? currentInventory?.isMultiple,
    itemsPerCase: itemsPerCase ?? currentInventory?.itemsPerCase,
    photos: photos ?? currentInventory?.photos,
    requiredFieldsMissing:
      requiredFieldsMissing ?? currentInventory?.requiredFieldsMissing,
    retailPrice: retailPrice ?? currentInventory?.retailPrice,
    sku: sku ?? currentInventory?.sku,
    status: status ?? currentInventory?.status,
    wholesalePrice: wholesalePrice ?? currentInventory?.wholesalePrice,
    type: type ?? currentInventory?.type,
    size: size ?? currentInventory?.size,
    name: name ?? currentInventory?.name,
  };
};
