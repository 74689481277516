import React from "react";
import { bool, func, string } from "prop-types";
import { AddPanel, StyledDivider } from "./styles";
import { StyledButton } from "components";
import { MAP_PROSPECT_TYPES } from "Pages/CustomersPage/components/ProspectsTab/components/ProspectsFilter/ProspectsFilter.constants";
import { useRepsPermissions } from "helpers/hooks";

const CreateProspectsPanel = ({
  isOpen,
  onCancel,
  onAddProspect,
  onCreateRoute,
  type,
}) => {
  const repPermissions = useRepsPermissions();

  const buttonsMap = {
    create: {
      [MAP_PROSPECT_TYPES.prospects]: {
        label: "Convert to Customers",
      },
      [MAP_PROSPECT_TYPES.history]: {
        label: "Add as Prospect",
      },
      [MAP_PROSPECT_TYPES.new]: {
        label: "Add as Prospect",
      },
    },
    createRoute: {
      [MAP_PROSPECT_TYPES.prospects]: {
        label: "Create Route",
      },
      [MAP_PROSPECT_TYPES.history]: {
        label: "Add & Create Route",
      },
      [MAP_PROSPECT_TYPES.new]: {
        label: "Add & Create Route",
      },
    },
  };

  return (
    <AddPanel {...{ isOpen }}>
      <StyledButton
        label={buttonsMap.create[type]?.label}
        color="white"
        onClick={onAddProspect}
      />
      <StyledDivider orientation="vertical" />
      <StyledButton
        disabled={!!repPermissions && !repPermissions?.routes?.create_edit}
        label={buttonsMap.createRoute[type]?.label}
        color="white"
        onClick={onCreateRoute}
      />
      <StyledDivider orientation="vertical" />
      <StyledButton label="Cancel" color="confirmDelete" onClick={onCancel} />
    </AddPanel>
  );
};

CreateProspectsPanel.propTypes = {
  isOpen: bool,
  onCancel: func,
  onAddProspect: func,
  onCreateRoute: func,
  type: string,
};

export default CreateProspectsPanel;
