import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  headerWrapper: {
    display: "flex",
    justifyContent: "space-between",
    height: "45px",
    alignItems: "center",
    borderBottom: "0.5px solid #D5D9D9",
    padding: "3px 32px",
  },

  pageTitle: {
    fontSize: "20px",
    color: "#9C9C94",
    display: "flex",
    alignItems: "center",
    gap: "15px"
  },
  smallerButton: {
    width: "22px",
    minWidth: "49px",
    height: "24px",
    "& > span": {
      fontSize: "12px",
      position: "relative",
      right: "5px",
    },
  },
  pageWrapper: {
    display: "flex",
    gap: "14px",
    padding: "16px 32px",
    height: "calc(100vh - 140px)",
  },
}));

export default useStyles;
