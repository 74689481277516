import { useState, useMemo, useEffect } from "react";
import { object } from "prop-types";
import { Box, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { createSelector } from "reselect";
import {
  paytermsListSelector,
  paytermsLoadingSelector,
} from "../../../../../redux/selectors/payterms";
import PaytermsHeader from "./PaytermsTable/PaytermsHeader";
import PaytermsItem from "./PaytermsTable/PaytermsItem";
import { StyledButton } from "components";
import { updatePaytermAction } from "redux/actions/payterms";
import { PAYMENT_TYPES } from "./PaytermsTab.constants";
import { Link } from "react-router-dom";
import { getCurrentUser } from "helpers/auth";
import { setCurrentUser } from "redux/actions/auth";

const selector = createSelector(
  paytermsListSelector,
  paytermsLoadingSelector,
  (paytermsList, paytermsLoading) => ({
    paytermsList,
    paytermsLoading,
  })
);

const PaytermsTab = ({ locationState }) => {
  const { paytermsList, paytermsLoading } = useSelector(selector);
  const dispatch = useDispatch();
  const { state } = useLocation();
  const navigate = useNavigate();

  const isOnboarding = useMemo(() => {
    return state?.type === "onboarding" && state?.tab === "Payment Terms";
  }, [state?.tab, state?.type]);

  const [showInactive, setShowInactive] = useState(false);

  const payterms = useMemo(
    () =>
      (showInactive
        ? paytermsList.filter((payterm) => payterm.status === "INACTIVE")
        : paytermsList.filter((payterm) =>
            ["ACTIVE", "INACTIVE_FOR_CUSTOMERS"].includes(payterm.status)
          )
      ).sort((a, b) =>
        a.type === PAYMENT_TYPES.ADVANCE_PAYMENT
          ? -1
          : b.type === PAYMENT_TYPES.ADVANCE_PAYMENT
          ? 1
          : 0
      ),
    [showInactive, paytermsList]
  );

  const hasDefaultPayTerm = useMemo(
    () => paytermsList?.some((term) => term?.defaultTerm),
    [paytermsList]
  );

  const [payTermOnboarding, setPayTermOnboarding] = useState({});

  const handleSetPayTermOnboarding = (term) => {
    if (term) setPayTermOnboarding(term);
  };

  useEffect(() => {
    setPayTermOnboarding(
      showInactive ? {} : paytermsList?.find((term) => term.status === "ACTIVE")
    );
  }, [paytermsList, showInactive]);

  const handleSavePrimaryPayTerm = () => {
    const { id, name, daysInvoices, type } = payTermOnboarding;
    dispatch(
      updatePaytermAction(
        id,
        {
          type: type || "STANDARD",
          name,
          daysInvoices: type === "ADVANCE_PAYMENT" ? undefined : daysInvoices,
          // active,
          defaultTerm: true,
        },
        () => {
          getCurrentUser({
            setCurrentUser: (user) => {
              dispatch(setCurrentUser(user));
            },
          });
          navigate("/");
        }
      )
    );
  };

  return (
    <Box height="100%">
      <Box
        sx={{
          borderBottom: "1px solid #D5D9D9",
          p: "24px 58px 19px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography fontSize="30px" color="#5F6267">
          Payment Terms
        </Typography>

        <Box>
          <StyledButton
            sx={{ height: 29 }}
            variant="contained"
            label="Add term"
            component={Link}
            to={`payterm/new`}
            state={locationState}
          />

          {isOnboarding && !hasDefaultPayTerm && payterms?.length ? (
            <StyledButton
              sx={{ width: 76, height: 29, marginLeft: 1 }}
              disabled={!payTermOnboarding?.id || paytermsLoading}
              fontSize="12px"
              variant="contained"
              label="Save"
              onClick={handleSavePrimaryPayTerm}
            />
          ) : null}
        </Box>
      </Box>
      <Box p="28px 58px 28px 72px">
        <PaytermsHeader
          handleSetShowInactive={() => setShowInactive(!showInactive)}
          isOnboarding={isOnboarding && !hasDefaultPayTerm}
        />
        {payterms.length > 0 ? (
          <Box
            sx={{
              height: "100%",
              maxHeight: "calc(100vh - 380px)",
              overflowY: "overlay",
              borderWidth: "0px 1px 1px 1px",
              borderStyle: "solid",
              borderColor: "#D5D9D9",
              borderRadius: "0 0 4px 4px",
            }}
          >
            {payterms.map((payterm, index) => (
              <PaytermsItem
                key={payterm.id}
                lastItem={payterms.length === index + 1}
                payterm={payterm}
                isOnboarding={isOnboarding && !hasDefaultPayTerm}
                payTermOnboarding={payTermOnboarding}
                handleSetPayTermOnboarding={handleSetPayTermOnboarding}
              />
            ))}
          </Box>
        ) : (
          <Grid
            sx={{
              borderRadius: "0 0 4px 4px",
              borderWidth: "0 1px 1px 1px",
              borderStyle: "solid",
              borderColor: "#D5D9D9",
            }}
          >
            <Typography
              fontWeight="300"
              fontSize="16px"
              textAlign="center"
              width="100%"
              color="#6A6A6A"
              paddingTop="20px"
              paddingBottom="20px"
            >
              No terms found
            </Typography>
          </Grid>
        )}
      </Box>
    </Box>
  );
};
PaytermsTab.propTypes = {
  locationState: object,
};
PaytermsTab.defaultProps = {
  locationState: {},
};

export default PaytermsTab;
