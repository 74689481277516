import axios from "axios";

export const createOrderService = async (data) => {
  return axios.post("/orders", data).then((res) => res.data);
};

export const updateOrderService = async (id, data) => {
  return axios.put(`/orders/${id}`, data).then((res) => res.data);
};

export const bulkAssignOrderTagsService = async (data) => {
  return axios.put("/orders/bulk-assign-tags", data).then((res) => res.data);
};

export const getOrdersService = async (params) => {
  return axios.get("/orders", { params }).then((res) => res.data);
};

export const getOrderByIdService = async (id) => {
  return axios.get(`/orders/${id}`).then((res) => res.data);
};

export const getOrderInvoceLink = async (id) => {
  return axios.get(`/orders/${id}/invoice-link`).then((res) => res.data);
};

export const getOrderDuplicateByIdService = async (id) => {
  return axios.get(`/orders/${id}/duplicate-info`).then((res) => res.data);
};

export const confirmDeliveryService = async (data) => {
  return axios.post("/orders/deliveries", data).then((res) => res.data);
};

export const bulkConfirmDeliveriesService = async (data) => {
  return axios.post("/orders/deliveries/bulk", data).then((res) => res.data);
};

export const updateDeliveryService = async (data, id) => {
  return axios.put(`/orders/deliveries/${id}`, data).then((res) => res.data);
};

export const deleteDeliveryService = async (id) => {
  return axios.delete(`/orders/deliveries/${id}`).then((res) => res.data);
};

export const deleteReturnService = async (id) => {
  return axios
    .delete(`/orders/returns/${id}`, {
      data: { sendNotification: true },
    })
    .then((res) => res.data);
};

export const confirmReturnService = (data) => {
  return axios.post("/orders/returns", data).then((res) => res.data);
};

export const updateReturnService = (id, data) => {
  return axios.put(`/orders/returns/${id}`, data).then((res) => res.data);
};

export const cancelOrderService = (data) => {
  return axios.post("/orders/cancel", data).then((res) => res.data);
};

export const acceptPaymentsService = (data) => {
  return axios.post("/orders/payments", data).then((res) => res.data);
};

export const updatePaymentsService = (idPayment, data) => {
  return axios
    .put(`/orders/payments/${idPayment}`, data)
    .then((res) => res.data);
};

export const deletePaymentsService = (idPayment) => {
  return axios.delete(`/orders/payments/${idPayment}`).then((res) => res.data);
};

export const updateOrderCustomerService = async (id, data) => {
  return axios.put(`/orders/${id}`, data).then((res) => res.data);
};

export const confirmRefundService = (data) => {
  return axios.post("/orders/refunds", data).then((res) => res.data);
};

export const updateRefundService = (idOrder, data) => {
  return axios.put(`/orders/refunds/${idOrder}`, data).then((res) => res.data);
};

export const deleteRefundService = (idOrder) => {
  return axios
    .delete(`/orders/refunds/${idOrder}`, {
      data: { sendNotification: true },
    })
    .then((res) => res.data);
};

export const getTotalSummariesOrdersService = (params) => {
  return axios
    .get("orders/total-summaries", { params })
    .then((res) => res.data);
};

export const getOrderReportPDF = async (orderId, params) => {
  return axios
    .get(`reports/pdf/orders/${orderId}`, { params, responseType: "blob" })
    .then((res) => res.data);
};

export const getOrdersBulkReportPDF = async ({
  ordersIds,
  includePrices,
  onDownloadProgress,
}) => {
  return await axios({
    method: "POST",
    url: "reports/pdf/bulk-orders",
    data: { ordersIds, includePrices },
    responseType: "blob",
    onDownloadProgress,
  });
};

export const getOrdersBulkPackingSlipPDF = async ({
  ordersIds,
  onDownloadProgress,
}) => {
  return await axios({
    method: "POST",
    url: "reports/pdf/bulk-packing-slip",
    data: { ordersIds },
    responseType: "blob",
    onDownloadProgress,
  });
};

export const bulkPackingSlipOrderReportService = async ({
  data,
  onDownloadProgress,
}) => {
  return await axios({
    method: "POST",
    url: "/reports/pdf/bulk-picklist",
    data,
    responseType: "blob",
    onDownloadProgress,
  });
};

export const printPackingSlipService = async (orderId, type) => {
  return axios
    .get(`/reports/pdf/orders/packing-slip-${type}/${orderId}`, {
      responseType: "blob",
    })
    .then((res) => res.data);
};

export const sendOrderEmailService = (orderId, data) => {
  return axios
    .post(`/orders/send-email/${orderId}`, data)
    .then((res) => res.data);
};

export const getOrderActivitiesService = (orderId, params) => {
  return axios
    .get(`/orders/${orderId}/activities`, { params })
    .then((res) => res.data);
};

export const bulkArchiveOrdersService = async (data) => {
  const res = await axios({
    method: "POST",
    url: "/orders/bulk-archive",
    data,
  });

  return res.data;
};

export const getPaymentIntentLinkService = async (orderId) => {
  const res = await axios({
    method: "GET",
    url: `/orders/${orderId}/payment-intent-link`,
  });
  return res.data;
};

export const getPaymentIntentClientDataService = async (token) => {
  const res = await axios({
    method: "GET",
    url: "/orders/payment-intent-client-data",
    params: { token },
  });
  return res.data;
};

export const getPaidPaymentIntentClientDataService = async ({
  token,
  payment_intent_id,
}) => {
  const res = await axios({
    method: "GET",
    url: "/orders/paid-payment-intent-client-data",
    params: { token, payment_intent_id },
  });
  return res.data;
};

export const getPaymentIntentOrderReportService = async ({ token }) => {
  const res = await axios({
    method: "GET",
    url: "/orders/payment-intent-order-report",
    params: { token },
    responseType: "blob",
  });
  return res.data;
};

export const getPaymentIntentOrderLinkService = async (id) => {
  const res = await axios({
    method: "GET",
    url: `/orders/payment-intent-link/${id}/redirect-link`,
  });
  return res.data;
};
