import { Avatar } from "@mui/material";
import { string, object } from "prop-types";
import { forwardRef } from "react";
import { stringAvatar } from "../../helpers/helpers";

export const StyledAvatar = forwardRef(
  ({ name, src, stringStyle, ...props }, ref) => {
    if (src) return <Avatar src={src} ref={ref} {...props} />;

    return (
      <Avatar
        ref={ref}
        {...props}
        {...stringAvatar(name ? name.trim() : "", {
          ...props.sx,
          ...stringStyle,
        })}
      />
    );
  }
);

StyledAvatar.displayName = "StyledAvatar";

StyledAvatar.propTypes = {
  src: string,
  name: string,
  stringStyle: object,
  sx: object,
};

StyledAvatar.defaultProps = {
  stringStyle: {},
};

export default StyledAvatar;
