import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  wrapper: {
    maxHeight: "100%",
    display: "flex",
    gap: "75px",

    "& > .MuiBox-root": {
      flex: "1 1 auto",
    },
  },

  buttonBlock: {
    padding: "15px 28px",
    backgroundColor: "#F7F7F7",
    boxShadow: "none",
    marginBottom: "9px",
  },
  textInputInventory: {
    fontSize: "12px",
    height: "24px",
    paddingRight: 0,
    "& .MuiInputBase-input": {
      padding: " 0 8px 0 8px",
    },
  },
  inputLabel: {
    fontSize: "12px",
    fontWeight: 400,
    color: "#000000",
    "&.Mui-focused": {
      color: "#000000",
    },
  },

  linkWrap: {
    display: "flex",
    flexDirection: "column",
    gap: "58px",
  },

  copyText: {
    whiteSpace: "pre-wrap",
    fontSize: "14px",
    "&.title": {
      fontWeight: "600",
    },
  },

  copyButton: { marginTop: "16px", height: "28px", width: "89px" },
}));

export default useStyles;
