import React from "react";

// eslint-disable-next-line react/prop-types
export const OutlinedPlusIcon = ({ color, size, plusColor, circleColor }) => {
  return (
    <svg
      id="add_new"
      data-name="add / new"
      xmlns="http://www.w3.org/2000/svg"
      width={size || "12"}
      height={size || "12"}
      viewBox="0 0 12 12"
    >
      <g id="_" data-name="+" transform="translate(2.817 2.817)">
        <path
          id="Union_1"
          data-name="Union 1"
          d="M2.785,6.365V3.58H0v-.8H2.785V0h.8V2.785H6.365v.8H3.581V6.365Z"
          fill={plusColor || color || "#42a57f"}
        />
      </g>
      <g
        id="Ellipse_342"
        data-name="Ellipse 342"
        fill="none"
        stroke={circleColor || color || "#42a57f"}
        strokeWidth="0.5"
      >
        <circle cx="6" cy="6" r="6" stroke="none" />
        <circle cx="6" cy="6" r="5.5" fill="none" />
      </g>
    </svg>
  );
};
