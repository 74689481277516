import { Divider, styled } from "@mui/material";

const AddPanel = styled("div")(({ isOpen, theme: { palette } }) => ({
  background: palette.ground.main,
  position: "absolute",
  bottom: "28px",
  left: "50%",
  transform: isOpen ? "translateX(-50%)" : "translate(-50%, 200%)",
  borderRadius: "13px",
  height: "44px",
  display: "flex",
  alignItems: "center",
  padding: "0 17px",
  gap: "5px",
  transition: "0.3s ease all",
}));

const StyledDivider = styled(Divider)(() => ({
  height: "14px",
  borderColor: "#C7C7C7",
}));

export { AddPanel, StyledDivider };
