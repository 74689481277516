export const initialState = {
  title: "",
  orderCustomId: "",
  sendNotification: false,
  repsSalesUsers: [],
  salesCount: 0,
  shippingCarriers: [],
};

export const RETURNS = [
  { id: 1, name: "Product and shipping box damaged" },
  { id: 2, name: "Product is damaged" },
  { id: 3, name: "Item arrived too late" },
  { id: 4, name: "Item defective or doesn't work" },
  { id: 5, name: "Wrong item was sent" },
  { id: 6, name: "Bought by mistake" },
  { id: 7, name: "Other" },
];

export const PAYMENT_STATUSES = [
  "PAID",
  "FULFILLED",
  "PARTIALLY FULFILLED",
  "PARTIALLY_PAID",
  "PENDING",
  "UNFULFILLED",
  "UNPAID",
  "REFUNDED",
  "FAILED",
];

export const DELIVERY_METHODS = ["SHIPPING_CARRIER", "LOCAL_DELIVERY"];

export const defaultValues = {
  sendNotification: false,
  reason: RETURNS[0].name,
  otherReason: "",
  restock: false,
  deliveryMethod: DELIVERY_METHODS[0],
  delivererId: "",
  trackingId: "",
  shippingCarrier: "",
  productReturns: [],
};
