import { string, func, bool } from "prop-types";

import { cl } from "../styles";

import { Button, DialogActions } from "@mui/material";

export const FilterViewActions = ({ onClose, onSave, viewName, loading }) => {
  return (
    <DialogActions
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        padding: "0px 28px 24px",
      }}
    >
      <Button sx={cl.cancelActionBtn} onClick={onClose} variant="outlined">
        Cancel
      </Button>
      <Button
        disabled={!viewName || loading}
        sx={cl.saveActionBtn}
        color="primary"
        onClick={onSave}
        variant="contained"
      >
        Save
      </Button>
    </DialogActions>
  );
};

FilterViewActions.propTypes = {
  onClose: func,
  viewName: string,
  onSave: func,
  loading: bool,
};
FilterViewActions.defaultProps = {
  onClose: () => {},
  onSave: () => {},
  loading: false,
};
