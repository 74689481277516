import {
  GET_DRAFTS,
  DELETE_DRAFT,
  START_LOADING_DRAFTS,
  END_LOADING_DRAFTS,
  SET_FETCHED,
} from "../actions/drafts";

const initialState = {
  cart: {},
  loading: false,
  fetched: false,
};

const draftsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_DRAFTS:
      return { ...state, cart: payload, fetched: true };

    case DELETE_DRAFT: {
      return { ...state, cart: null, loading: false };
    }

    case SET_FETCHED: {
      return { ...state, fetched: payload };
    }

    case END_LOADING_DRAFTS:
      return { ...state, loading: false };

    case START_LOADING_DRAFTS:
      return { ...state, loading: true };

    default:
      return state;
  }
};

export default draftsReducer;
