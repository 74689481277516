import { Info } from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { bool, func, object } from "prop-types";
import { Controller } from "react-hook-form";
import NumberFormat from "react-number-format";
import {
  BankMemoIcon,
  CrossIcon,
} from "../../../../../../../../../components/Icons";
import { StyledSelect } from "../../../../../../../../../components/Selects";
import StyledButton from "../../../../../../../../../components/StyledButton";
import { StyledTextField } from "../../../../../../../../../components/TextFields/TextFields";
import useStyles from "./styles";
import { useBank } from "./ConnectBank.hooks";
import { useSelector } from "react-redux";

const ConnectBankDialog = ({
  open,
  handleClose,
  handleAddAccount,
  isEdit,
  setLoading,
  bankAccount,
}) => {
  const classes = useStyles();

  const currentUser = useSelector(({ auth }) => auth?.currentUser);

  const isUSCountry = currentUser.country === "US";

  const {
    handleSubmit,
    onSubmit,
    control,
    disableFormButton,
    errorAccountNumber,
    setErrorAccountNumber,
    errorRoutingNumber,
    setErrorRoutingNumber,
  } = useBank({
    setLoading,
    open,
    handleAddAccount,
    handleClose,
    bankAccount,
    isEdit,
    isUSCountry,
  });

  const countlyList = [
    { name: "United States", value: "US", show: isUSCountry },
    { name: "Canada", value: "CA", show: !isUSCountry },
  ];

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle className={classes.dialogTitle}>
        <Typography>{isEdit ? "Update" : "Connect"} bank</Typography>
        <IconButton onClick={handleClose}>
          <CrossIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {isEdit && (
          <Box className={classes.warningBlock}>
            <Info style={{ fill: "#EB4233" }} />
            <Typography>
              Double check your information before updating your bank account.
            </Typography>
          </Box>
        )}
        <Typography fontWeight="600" color="#363531">
          {isEdit ? "New" : "Add new"} bank account
        </Typography>
        <Box
          display="flex"
          flexDirection="column"
          gap="5px"
          mt="12px"
          component="form"
          id="bank-account-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box className={classes.fieldBlock}>
            <Typography>Bank Location</Typography>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <StyledSelect
                  notched={false}
                  fullWidth
                  error={error?.message || ""}
                  {...field}
                >
                  {countlyList.map(
                    ({ show, value, name }) =>
                      show && (
                        <MenuItem value={value} key={value}>
                          {name}
                        </MenuItem>
                      )
                  )}
                </StyledSelect>
              )}
              name="bankLocation"
              control={control}
            />
          </Box>
          <Box className={classes.fieldBlock}>
            <Typography>Account Holder`s Name</Typography>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <StyledTextField
                  error={error?.message || ""}
                  label=""
                  LabelProps={{ shrink: false }}
                  noErrorMessage
                  {...field}
                />
              )}
              name="accountHolderName"
              control={control}
            />
          </Box>

          <Box className={classes.fieldBlock}>
            <Typography>{`${
              isUSCountry ? 9 : 8
            }-Digit Routing Number`}</Typography>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <NumberFormat
                  customInput={StyledTextField}
                  placeholder={`${isUSCountry ? 9 : 8} digits`}
                  format={isUSCountry ? "#########" : "########"}
                  noErrorMessage
                  LabelProps={{ shrink: false }}
                  error={error?.message || !!errorRoutingNumber || ""}
                  {...field}
                  onChange={(e) => {
                    setErrorRoutingNumber(false);
                    field.onChange(e);
                  }}
                />
              )}
              name="routingNumber"
              control={control}
            />
          </Box>
          <Box className={classes.fieldBlock}>
            <Typography>Bank Account Number</Typography>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <StyledTextField
                  noErrorMessage
                  error={error?.message || !!errorAccountNumber || ""}
                  {...field}
                  onChange={(e) => {
                    setErrorAccountNumber(false);
                    field.onChange(e);
                  }}
                  LabelProps={{ shrink: false }}
                />
              )}
              name="accountNumber"
              control={control}
            />
          </Box>
          <Box>
            <BankMemoIcon />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <StyledButton label="Cancel" color="cancel" onClick={handleClose} />
        <StyledButton
          label={`${isEdit ? "Update" : "Add"} bank account`}
          variant="contained"
          form="bank-account-form"
          type="submit"
          disabled={disableFormButton}
        />
      </DialogActions>
    </Dialog>
  );
};

ConnectBankDialog.propTypes = {
  open: bool,
  handleClose: func,
  handleAddAccount: func,
  isEdit: bool,
  setLoading: func,
  bankAccount: object,
};

export default ConnectBankDialog;
