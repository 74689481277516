import { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "@mui/material";
import { StyledButton } from "components";
import { WEEK_DAYS } from "components/ReceivingHoursDrawer/ReceivingHoursDrawer.constants";
import {
  openConfirmDialogAction,
  setConfirmIsOpenAction,
} from "redux/actions/confirmDialogs";

export const useReceivingHours = ({ setValue, weekDays, onDelete }) => {
  const dispatch = useDispatch();

  const [openReceivingHours, setOpenReceivingHours] = useState(false);

  const handleOpenReceivingHours = useCallback(() => {
    setOpenReceivingHours(true);
  }, []);

  const handleCloseReceivingHours = () => setOpenReceivingHours(false);

  const handleSetHours = (hoursData) => {
    setValue("weekDays", hoursData, { shouldDirty: true });
  };

  const list = Object.values(weekDays);
  const hasAnySetHours = list.some(
    (day) => day?.value?.clockInAt && day?.value?.clockOutAt
  );

  const setOpenReceivingHoursFunc = useMemo(() => {
    if (hasAnySetHours) return null;
    return handleOpenReceivingHours;
  }, [handleOpenReceivingHours, hasAnySetHours]);

  const removeAllHours = () => {
    dispatch(
      openConfirmDialogAction({
        title: "Delete working hours?",
        text: "Please confirm that you would like to delete working hours?",
        buttons: (
          <>
            <StyledButton
              variant="outlined"
              color="cancel"
              label="Cancel"
              sx={{ height: "28px" }}
              onClick={() => dispatch(setConfirmIsOpenAction(false))}
            />
            <Button
              sx={{
                width: "auto",
                height: "28px",
                color: "#FFFFFF",
                fontSize: "13px",
                boxShadow: "none",
              }}
              color="confirmDelete"
              onClick={() => {
                dispatch(setConfirmIsOpenAction(false));
                onDelete
                  ? onDelete("weekDays", WEEK_DAYS, "Working hours deleted.")
                  : setValue("weekDays", WEEK_DAYS, { shouldDirty: true });
              }}
              variant="contained"
            >
              Confirm
            </Button>
          </>
        ),
      })
    );
  };

  return {
    openReceivingHours,
    handleOpenReceivingHours,
    handleCloseReceivingHours,
    handleSetHours,
    removeAllHours,
    hasAnySetHours,
    setOpenReceivingHoursFunc,
  };
};
