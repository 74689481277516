import { normalizeUppercaseString } from "helpers/helpers";
import { REP_ROLES } from "utils/constants";

export const formatContactsToCards = (contacts) => {
  if (!contacts) return [];
  const items = contacts.map(
    ({ role, name, phone, email, defaultContact, id, contactRole }) => ({
      itemTitle: contactRole?.name || role,
      customerName: name,
      phone,
      status: defaultContact ? "PRIMARY" : "",
      customerEmail: email,
      id,
      buttons: true,
    })
  );
  return items;
};

export const formatRepsToCards = (reps) => {
  if (!reps) return [];
  const items = reps.map(({ representative }) => ({
    itemTitle: `${REP_ROLES[representative.role] || "Unknown"} Rep`,
    customerName: representative.name,
    customerEmail: representative.baseUser?.email,
    id: representative.id,
  }));
  return items;
};

export const formatCreditCardsToInfoCards = (cards) => {
  if (!cards) return [];
  const items = cards.map(
    ({
      brand,
      last4,
      defaultMethod,
      billingAddress,
      expMonth,
      expYear,
      id,
    }) => ({
      itemTitle: `${normalizeUppercaseString(brand)} **** ${last4}`,
      status: defaultMethod ? "PRIMARY" : "",
      name: billingAddress?.name,
      customerEmail: `Expires ${expMonth}/${expYear}`,
      last4: last4,
      id: id,
    })
  );
  return items;
};

// export const hasWorkingDays = (obj) => {
//   for (const key in obj) {
//     // eslint-disable-next-line no-prototype-builtins
//     if (obj.hasOwnProperty(key)) {
//       const dayObject = obj[key];
//       if (dayObject.value && dayObject.value !== "''") {
//         return true;
//       }
//     }
//   }
//   return false;
// };

export const hasWorkingDays = (weekDays) => {
  const list = Object.values(weekDays);
  return list.some((day) => day?.value?.clockInAt && day?.value?.clockOutAt);
};
