import { string } from "prop-types";
import { Grid, Typography } from "@mui/material";

const OrdersListHeader = ({ title }) => {
  const cl = {
    block: {
      display: "flex",
      alignItems: "center",
    },
    text: {
      fontSize: 12,
      color: "#6A6A6A",
    },
  };

  const labels = [
    {
      id: 0,
      name: ["Type", "Type"],
      size: 2,
      style: { text: { ...cl.text }, block: { ...cl.block, paddingLeft: 2 } },
    },
    {
      id: 1,
      name: ["Method", "VIA"],
      size: 2,
      style: { text: { ...cl.text }, block: { ...cl.block, paddingLeft: 2 } },
    },
    {
      id: 2,
      name: ["Reference #", "Fulfilled by"],
      size: 2.4,
      style: { text: { ...cl.text }, block: { ...cl.block } },
    },
    {
      id: 3,
      name: ["Date", "Date"],
      size: 2.1,
      style: { text: { ...cl.text }, block: { ...cl.block } },
    },
    {
      id: 4,
      name: ["Amount", "Quantity"],
      size: 3.5,
      style: { text: { ...cl.text }, block: { ...cl.block } },
    },
  ];

  return (
    <Grid
      sx={{
        borderBottom: "1px solid #D5D9D9",
        backgroundColor: "#EEEEEE",
        height: 32,
        borderRadius: "4px 4px 0 0",
      }}
      container
    >
      {labels.map((el) => (
        <Grid key={el.id} sx={el.style.block} item xs={el.size}>
          <Typography sx={el.style.text}>
            {title === "Payments" && el.name[0]}
            {title === "Delivery" && el.name[1]}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};
OrdersListHeader.propTypes = {
  title: string,
};
OrdersListHeader.defaultProps = {
  title: "",
};

export default OrdersListHeader;
