import { object } from "prop-types";
import { Box, FormControl, MenuItem, Typography } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { Controller } from "react-hook-form";
import { StyledSelect } from "components";
import { RETURNS } from "../../../../../../helpers";

export const ReturnReasonBlock = ({ control }) => {
  return (
    <Box mt={1}>
      <Typography fontSize={12} color="#000000">
        Why are you returning this?
      </Typography>
      <FormControl
        sx={{
          mt: 1,
          "& .MuiInputBase-input": {
            fontSize: "12px",
            fontWeight: 400,
            color: "#5F6267",
          },
          "& svg": {
            fill: "#B5B5AC",
          },
        }}
        fullWidth
        size="small"
      >
        <Controller
          render={({ field }) => (
            <StyledSelect
              notched={false}
              leftSidedIcon
              sx={{
                height: "28px",
                "& .MuiInputBase-input": {
                  color: "#000000 !important",
                  paddingRight: "4px !important",
                },
              }}
              IconComponent={ExpandMore}
              {...field}
            >
              {RETURNS.map((el) => (
                <MenuItem key={el.id} value={el.name}>
                  {el.name}
                </MenuItem>
              ))}
            </StyledSelect>
          )}
          name="reason"
          control={control}
        />
      </FormControl>
    </Box>
  );
};

ReturnReasonBlock.propTypes = {
  control: object,
};
ReturnReasonBlock.defaultProps = { control: {} };
