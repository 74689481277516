import React, { useState } from "react";
import { number, array, func, bool } from "prop-types";

import { CircularProgress, Stack as MyStack } from "@mui/material";
import ListHeader from "./components/ListHeader";
import ListItem from "./components/ListItem";
import ProfileComponent from "../../../../../../RepresentativesPage/components/ProfileComponent";
import TerritoriesMenu from "../../../../../../RepresentativesPage/components/TerritoriesMenu/TerritoriesMenu";
import styled from "@emotion/styled";
import { InfiniteScrollWrapper } from "components";

const Stack = styled(MyStack)({
  " > div:first-of-type": {
    borderRadius: "4px 4px 0 0",
  },
  " > div:last-child": {
    borderRadius: "0 0 4px 4px",
  },
});

const TerritoryRepresentativesTab = ({
  repsList,
  repsLoading,
  repsCount,
  handleFetchReps,
}) => {
  const [isOpenProfileDialog, setIsOpenProfileDialog] = useState(false);
  const [profile, setProfile] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const [territoriesList, setTerritoriesList] = useState([]);
  const handleOpenProfileDialog = (user) => {
    setProfile(user);
    setIsOpenProfileDialog(true);
  };
  const handleCloseProfileDialog = () => {
    setIsOpenProfileDialog(false);
  };

  return (
    <>
      <Stack
        sx={{
          borderRadius: "4px",
        }}
      >
        <TerritoriesMenu
          anchorEl={anchorEl}
          handleClose={() => setAnchorEl(null)}
          territories={territoriesList}
        />
        <ListHeader />
        <Stack
          sx={{
            maxHeight: "48vh",
            overflow: "hidden",
            position: "relative",
            opacity: repsLoading ? 0.5 : 1,
            pointerEvents: repsLoading && "none",
            borderRadius: "0 0 4px 4px",
            borderWidth: repsList?.length ? "0 0 1px 1px" : 0,
            borderStyle: "solid",
            borderColor: "#D5D9D9",
          }}
        >
          <InfiniteScrollWrapper
            id="rep-scroll-table-small"
            maxHeight="48vh"
            loading={repsLoading}
            dataLength={repsList.length}
            next={handleFetchReps}
            loader={
              <CircularProgress sx={{ ml: "50%", mt: "2px" }} size="30px" />
            }
            hasMore={repsList.length < repsCount}
          >
            {repsList.map((rep) => (
              <ListItem
                key={rep.id}
                representative={rep}
                handleOpenProfileDialog={handleOpenProfileDialog}
                setTerritoriesList={(ters) => setTerritoriesList(ters)}
                setAnchorEl={setAnchorEl}
              />
            ))}
          </InfiniteScrollWrapper>
        </Stack>
      </Stack>
      <ProfileComponent
        profile={profile}
        isOpenProfileDialog={isOpenProfileDialog}
        handleCloseProfileDialog={handleCloseProfileDialog}
      />
    </>
  );
};
TerritoryRepresentativesTab.propTypes = {
  repsList: array,
  repsLoading: bool,
  repsCount: number,
  handleFetchReps: func,
};

TerritoryRepresentativesTab.defaultProps = {
  repsList: [],
  repsLoading: false,
  repsCount: 0,
};

export default TerritoryRepresentativesTab;
