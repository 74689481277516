import { bool, func, array, number } from "prop-types";
import { Grid, Paper, Typography } from "@mui/material";
import {
  SelectLabelComponent,
  LargeCheckbox,
} from "../../../../../../../components";
import pluralize from "pluralize";
import { useAdmin } from "helpers/helpers";

const CustomersHeader = ({
  allCustomersChecked,
  checkAllCustomers,
  checkedCustomers,
  customersCount,
  customers,
  fetchListAndCheckAll,
  repCustomersLoading,
  setCheckedCustomers,
  viewCustomerPopup,
}) => {
  const classes = {
    grid: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    typography: {
      fontSize: "12px",
      fontWeight: 400,
      color: "#6A6A6A",
      textTransform: "uppercase",
    },
  };

  const isAdmin = useAdmin();

  const handleSelectAll = (v) => {
    if (customersCount === customers?.length || !v) {
      checkAllCustomers(true);
      return;
    }
    fetchListAndCheckAll();
  };

  return (
    <Paper
      sx={{ backgroundColor: "#EEEEEE" }}
      elevation={0}
      variant="outlined"
      square
      component={Grid}
      container
      height="48px"
    >
      {checkedCustomers?.length && !viewCustomerPopup ? (
        <SelectLabelComponent
          itemCheckedCount={checkedCustomers?.length}
          itemAllCount={customersCount}
          name={`${pluralize("ENTRY", checkedCustomers?.length)} SELECTED`}
          onSelectAll={(value) => handleSelectAll(value)}
          onCancel={() => {
            if (setCheckedCustomers) setCheckedCustomers([]);
            handleSelectAll(false);
          }}
          dividerAfterSelect
          loading={repCustomersLoading}
        />
      ) : (
        <>
          {!viewCustomerPopup && (
            <Grid item xs={1} sx={classes.grid}>
              <LargeCheckbox
                sx={{ p: 0 }}
                checked={allCustomersChecked}
                onChange={() => checkAllCustomers(allCustomersChecked)}
                disabled={isAdmin}
              />
            </Grid>
          )}
          {!viewCustomerPopup && (
            <Grid
              item
              xs={1}
              sx={{ ...classes.grid, justifyContent: "flex-start" }}
            >
              <Typography sx={classes.typography}>Status</Typography>
            </Grid>
          )}

          <Grid
            item
            xs={1}
            sx={{
              ...classes.grid,
              justifyContent: "flex-start",
              ...(viewCustomerPopup ? { marginLeft: "78px" } : {}),
            }}
          >
            <Typography sx={classes.typography}>Id</Typography>
          </Grid>
          <Grid
            item
            xs={viewCustomerPopup ? 4.5 : 3.8}
            sx={{ ...classes.grid, justifyContent: "flex-start" }}
          >
            <Typography sx={classes.typography}>Customer</Typography>
          </Grid>
          <Grid
            item
            xs={viewCustomerPopup ? 2.7 : 2}
            sx={{ ...classes.grid, justifyContent: "flex-start" }}
          >
            <Typography sx={classes.typography}>Group</Typography>
          </Grid>
          <Grid
            item
            xs={viewCustomerPopup ? 1.5 : 2}
            sx={{ ...classes.grid, justifyContent: "flex-start" }}
          >
            <Typography sx={classes.typography}>Territory</Typography>
          </Grid>
          <Grid
            item
            xs={1}
            sx={{
              ...classes.grid,
              justifyContent: "flex-start",
              marginLeft: "-5px",
            }}
          >
            <Typography sx={classes.typography}>Orders</Typography>
          </Grid>
        </>
      )}
    </Paper>
  );
};

CustomersHeader.propTypes = {
  allCustomersChecked: bool,
  checkAllCustomers: func,
  checkedCustomers: array,
  customersCount: number,
  customers: array,
  fetchListAndCheckAll: func,
  repCustomersLoading: bool,
  setCheckedCustomers: func,
  viewCustomerPopup: bool,
};

export default CustomersHeader;
