export const ACTIVITIES_FILTER_TABS = [
  {
    name: "Tasks",
    value: "task_completed",
  },
  {
    name: "Orders",
    value: "order_added",
  },
  {
    name: "Comments",
    value: "comment_added",
  },
  {
    name: "Notes",
    value: "note_added",
  },
  {
    name: "Photos",
    value: "photo_group_added",
  },
  {
    name: "Check In",
    value: "checked_in",
  },
  {
    name: "All",
    value: "",
  },
];
