export const ExportIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="14.998"
      viewBox="0 0 15 14.998"
    >
      <path
        id="Icon_awesome-upload"
        data-name="Icon awesome-upload"
        d="M8.672,11.253H6.328a.7.7,0,0,1-.7-.7V5.628H3.056a.585.585,0,0,1-.413-1L7.1.17a.568.568,0,0,1,.8,0l4.459,4.459a.585.585,0,0,1-.413,1H9.375V10.55A.7.7,0,0,1,8.672,11.253ZM15,11.019V14.3a.7.7,0,0,1-.7.7H.7a.7.7,0,0,1-.7-.7V11.019a.7.7,0,0,1,.7-.7H4.688v.234a1.642,1.642,0,0,0,1.641,1.641H8.672a1.642,1.642,0,0,0,1.641-1.641v-.234H14.3A.7.7,0,0,1,15,11.019ZM11.367,13.6a.586.586,0,1,0-.586.586A.588.588,0,0,0,11.367,13.6Zm1.875,0a.586.586,0,1,0-.586.586A.588.588,0,0,0,13.242,13.6Z"
        transform="translate(0 -0.005)"
      />
    </svg>
  );
};
