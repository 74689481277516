import { useMemo } from "react";
import { object, number, func, string } from "prop-types";
import { Box, List, MenuItem, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { StyledSelect } from "../../../../Selects";

export const CardBody = ({ name, features, extraUser, handleSetExtraUser }) => {
  const list = useMemo(() => {
    return Object.keys(features || {}).map((el, i) => ({
      id: i,
      name: el,
      value: features[el],
    }));
  }, [features]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box px={3}>
        <List>
          {list.map((feat) => (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1.25,
                py: "4px",
              }}
              key={feat.id}
            >
              <Box
                sx={{
                  width: "16px",
                  minWidth: "16px",
                  height: "16px",
                  borderRadius: "50%",
                  bgcolor: feat.value ? "#47A06D" : "#D4D4D4",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {feat.value ? (
                  <CheckIcon sx={{ fontSize: 10, color: "#FFF" }} />
                ) : (
                  <CloseIcon sx={{ fontSize: 10, color: "#FFF" }} />
                )}
              </Box>
              <Typography fontSize={14} fontWeight={400} color="#5F6267">
                {feat.name}
              </Typography>
            </Box>
          ))}
          {name === "Wholesaler Premium" && (
            <Typography fontSize={14} fontWeight={400} color="#5F6267">
              … and more
            </Typography>
          )}
        </List>
      </Box>
      {extraUser && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box mt={1} mb={0.5} px={3}>
            <Typography fontSize={14} fontWeight={400} color="#5F6267">
              Amount of extra users
            </Typography>
          </Box>
          <Box px={3}>
            <StyledSelect
              displayEmpty
              value={extraUser}
              height="39px"
              fontSize="15px"
              formSx={{ width: "100%" }}
              onChange={(e) => handleSetExtraUser(e.target.value)}
            >
              {[1, 2, 3, 5, 10, 50, 100, 500]?.map((num) => (
                <MenuItem key={num} value={num}>
                  {num}
                </MenuItem>
              ))}
            </StyledSelect>
          </Box>
        </Box>
      )}
    </Box>
  );
};

CardBody.propTypes = {
  name: string,
  features: object,
  extraUser: number,
  handleSetExtraUser: func,
};
