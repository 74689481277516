import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  useLayoutEffect,
} from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import momentTimezone from "moment-timezone";
import { DATA_KEYS } from "utils/constants";
import { formatDate, getFormattedDate } from "./helpers";

export const useDebounce = (value, delay) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
};

export const useDate = (incDate) => {
  const [date, setDate] = useState(
    incDate === "Invalid date" ? "a few seconds ago" : incDate
  ); // Save the current date to be able to trigger an update

  useEffect(() => {
    const timer = setInterval(() => {
      // Creates an interval which will update the current data every minute
      // This will trigger a rerender every component that uses the useDate hook.
      setDate(incDate);
    }, 60 * 1000);
    return () => {
      clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
    };
  }, [incDate]);

  return date;
};

export const usePrevious = (value) => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();

  const clearHook = useCallback(() => {
    ref.current = null;
  }, []);
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return { item: ref.current, clearHook };
};

export const useOffline = () => {
  const [online, setOnline] = useState(true);

  const onOnline = () => {
    setOnline(true);
    // console.clear();
  };

  const onOffline = () => {
    setOnline(false);
  };

  useEffect(() => {
    window.addEventListener("online", onOnline);
    window.addEventListener("offline", onOffline);

    return () => {
      window.removeEventListener("online", onOnline);
      window.removeEventListener("offline", onOffline);
    };
  }, []);

  return online;
};

export const useOnBoardingTasks = () => {
  const currentUser = useSelector(({ auth }) => auth?.currentUser);
  const onboarding = currentUser?.onboarding;

  const onBoardingTasks = useMemo(() => {
    const representatives =
      onboarding === null ? true : onboarding?.representatives;
    const products = onboarding === null ? true : onboarding?.products;
    const customers = onboarding === null ? true : onboarding?.customers;
    const paymentTerms = onboarding === null ? true : onboarding?.paymentTerms;
    const tasks = onboarding === null ? true : onboarding?.tasks;

    return {
      orders: customers && representatives && products && paymentTerms,
      discounts: customers && products,
      routes: customers,
      reports: customers && representatives && products && paymentTerms,
      shopping_cart: customers && representatives && products && paymentTerms,
      chat: customers && representatives && products && paymentTerms,
      representatives,
      products,
      customers,
      tasks,
    };
  }, [onboarding]);

  return { onBoardingTasks, isOnBoarding: !!onboarding };
};

export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    const updateSize = () => {
      setSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
};

export const useLogInRedirect = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const currentUser = useSelector(({ auth }) => auth?.currentUser);

  const handleSetLocalStoragePaths = useCallback(() => {
    const tab = searchParams.get("tab");
    const sync = searchParams.get("sync");
    const appCustomerId = searchParams.get("app_customer_id");

    if (tab && sync && !currentUser) {
      const setData = JSON.stringify({ tab, sync });
      localStorage.setItem(DATA_KEYS.SYNC_DATA, setData);
    }
    if (appCustomerId && !currentUser) {
      const setData = JSON.stringify({ appCustomerId });
      localStorage.setItem(DATA_KEYS.APP_CUSTOMER_ID, setData);
    }
  }, [searchParams, currentUser]);

  const redirectToLocalStoragePath = useCallback(() => {
    const getData = localStorage.getItem(DATA_KEYS.SYNC_DATA);
    const getAppCustomerId = localStorage.getItem(DATA_KEYS.APP_CUSTOMER_ID);
    if (getData) {
      const syncData = JSON.parse(getData);
      localStorage.removeItem(DATA_KEYS.SYNC_DATA);
      navigate(`/settings?tab=${syncData.tab}&sync=${syncData.sync}`);
    }
    if (getAppCustomerId) {
      const appCustomerId = JSON.parse(getAppCustomerId);
      navigate(
        `/?view=order_direct&app_customer_id=${appCustomerId?.appCustomerId}`
      );
    }
  }, [navigate]);

  return { handleSetLocalStoragePaths, redirectToLocalStoragePath };
};

export const useOrderDirectConfigured = () => {
  const { brandLogo, storeName } = useSelector(
    ({ auth }) => auth?.currentUser || {}
  );

  const orderDirectConfigured = useMemo(
    () => !!brandLogo && !!storeName,
    [brandLogo, storeName]
  );

  return orderDirectConfigured;
};

export const useIsVisible = (ref) => {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIntersecting(entry.isIntersecting);
    });

    ref.current && observer.observe(ref.current);

    return () => observer.disconnect();
  }, [ref]);

  return isIntersecting;
};

export const useDaysAgo = ({ date, timeZone, format }) => {
  if (!date) return "-";

  const todayCurrentTimeZone = momentTimezone.tz(timeZone).startOf("day");

  const dateTimeZone = momentTimezone.tz(date, timeZone).startOf("day");

  const diffDays = todayCurrentTimeZone.diff(dateTimeZone, "days");

  if (diffDays === 0) {
    return "Today";
  } else if (diffDays === 1) {
    return "Yesterday";
  } else if (diffDays === -1) {
    return "Tomorrow";
  } else {
    const formattedDate = formatDate({
      utc_date: date,
      formatThisYear: "MMM D",
    });
    return momentTimezone(getFormattedDate(date)).format(
      format || formattedDate
    );
  }
};

export const useRepsPermissions = () => {
  const { currentUser = {}, representative = {} } = useSelector(
    ({ auth }) => auth
  );

  const hasPortalAccess = () => {
    if (
      typeof currentUser?.backOfficeRepresentative?.portalAccess === "boolean"
    ) {
      return currentUser?.backOfficeRepresentative?.portalAccess;
    }

    if (typeof representative?.portalAccess === "boolean") {
      return representative?.portalAccess;
    }

    return false;
  };
  const portalAccess = hasPortalAccess();

  const setBooleansToFalse = useCallback((obj) => {
    return Object.keys(obj).reduce((acc, key) => {
      if (typeof obj[key] === "object") {
        const newObj = {};
        Object.keys(obj[key]).forEach((k) => {
          if (typeof obj[key][k] === "boolean") {
            newObj[k] = false;
          } else {
            newObj[k] = obj[key][k];
          }
        });
        return { ...acc, [key]: newObj };
      }
      return { ...acc, [key]: obj[key] };
    }, {});
  }, []);

  const permissions = useMemo(() => {
    const data = currentUser?.permissions || representative?.permissions?.[0];
    if (!portalAccess && data) {
      return setBooleansToFalse(data);
    }

    return data;
  }, [
    currentUser?.permissions,
    portalAccess,
    representative?.permissions,
    setBooleansToFalse,
  ]);

  const checkHasAnyCheckedTrue = useCallback(
    (permissions) => {
      const temp = { ...permissions };
      delete temp.role;

      return Object.keys(temp).some((key) => {
        if (!portalAccess) return false;
        const values = Object.values(temp[key]);
        return values.some(Boolean);
      });
    },
    [portalAccess]
  );

  const hasAnyCheckedTrue = useMemo(() => {
    const { id, representativeId, role, ...clearData } = permissions || {};
    return checkHasAnyCheckedTrue(clearData);
  }, [checkHasAnyCheckedTrue, permissions]);

  if (!currentUser || !["SALES", "MERCHANDISER"].includes(permissions?.role))
    return null;

  permissions.hasAnyCheckedTrue = hasAnyCheckedTrue;

  return permissions;
};
