import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "0.5px solid #CCCCCC",
    padding: "11px 11px 7px 24px",
  },

  dialogContent: {
    minWidth: "340px",
    maxWidth: "340px",
    padding: "8px 21px 9px 24px!important",
  },

  dialogActions: {
    padding: "20px 21px 15px",
  },
}));

export default useStyles;
