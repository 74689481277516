import { IconButton, MenuItem, Stack, Typography } from "@mui/material";
import { StyledMenu, StyledTabs } from "components";
import { OutlinedPlusIcon, SimpleArrowDownIcon } from "components/Icons";
import useStyles from "Pages/CartPage/styles";
import React, { useRef } from "react";
import { cl } from "Pages/OrdersPage/OrderDetailsPage/OrdersTopInfoBar/styles";
import { bool, func, array, number, object } from "prop-types";

export const SecondStepTabs = ({
  setAddressTab,
  addressTab,
  TABLE_TABS,
  isThirdParty,
  anchorEl,
  isListOpen,
  handleCloseLogoClient,
  formField,
  isAdmin,
  paytermsListActive,
  handleTerms,
  setOpenPaymentTermModal,
  handleClickLogoClient,
}) => {
  const classes = useStyles();
  const drowDownEl = useRef(null);

  return (
    <Stack
      sx={{ borderBottom: "0.5px solid #D5D9D9" }}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      mb="17px"
    >
      <StyledTabs
        onChange={(e, newVal) => {
          setAddressTab(newVal);
        }}
        value={addressTab}
        tabs={TABLE_TABS}
        tabProps={{ className: classes.stepTab }}
        className={classes.stepTabs}
      />
      {!isThirdParty && (
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          height="34px"
          mt="4px"
          sx={{ width: "calc(100% - 165px)", textAlign: "center" }}
        >
          <Typography sx={{ ...cl.textHeader, color: "#707070" }}>
            Terms:
          </Typography>
          &nbsp;
          <Typography
            noWrap
            onClick={(e) => handleClickLogoClient(e, drowDownEl)}
            sx={{
              ...cl.payment.term,
              fontSize: "12px",
              height: "34px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {formField?.paymentTerms?.name ||
              formField?.paymentTermsDuplicate?.name}
          </Typography>
          <Stack
            height="34px"
            justifyContent="center"
            onClick={(e) => handleClickLogoClient(e, drowDownEl)}
          >
            <IconButton
              ref={drowDownEl}
              disabled={isAdmin}
              sx={{
                p: 0,
                height: "7px",
                transform: isListOpen ? "rotate(-180deg)" : 0,
              }}
            >
              <SimpleArrowDownIcon width={8} height={7} fill="#707070" />
            </IconButton>
          </Stack>
          <StyledMenu
            sx={{ p: 0 }}
            anchorEl={anchorEl}
            isOpen={isListOpen}
            handleClose={handleCloseLogoClient}
          >
            <MenuItem
              onClick={(e) => {
                e.stopPropagation();
                setOpenPaymentTermModal(true);
              }}
            >
              <OutlinedPlusIcon />
              <Typography fontSize={12} fontWeight={400} color="#42A57F">
                Add new
              </Typography>
            </MenuItem>
            {paytermsListActive.map((payterm) => (
              <MenuItem
                key={payterm.id}
                onClick={(e) => {
                  e.stopPropagation();
                  handleTerms(payterm);
                }}
              >
                {payterm.name}
              </MenuItem>
            ))}
          </StyledMenu>
        </Stack>
      )}
    </Stack>
  );
};

SecondStepTabs.propTypes = {
  formField: object,
  isThirdParty: bool,
  handleClickLogoClient: func,
  handleCloseLogoClient: func,
  anchorEl: object,
  paytermsListActive: array,
  isAdmin: bool,
  setAddressTab: func,
  addressTab: number,
  TABLE_TABS: array,
  isListOpen: bool,
  setOpenPaymentTermModal: func,
  handleTerms: func,
};
