import { Fragment } from "react";
import { object } from "prop-types";
import moment from "moment";
import { Box, Typography } from "@mui/material";

import {
  normalizeSnakeCaseString,
  separateNumWithComma,
} from "helpers/helpers";
import { preparedType } from "./Activity.helps";
import { CARRIER_INFO } from "Pages/OrdersPage/Orders.constants";

import { EmailedTo } from "./components";
import { Link } from "react-router-dom";

const ActivityMoreInfo = ({ activity }) => {
  const renderInfo = (activity) => {
    const { type, operationType, data } = activity;

    switch (type) {
      case "ORDER_CREATED":
      case "DRAFT_ORDER_CREATED": {
        if (activity?.data?.customerName) {
          return (
            <Box>
              <Typography fontSize={12} noWrap width="78%">
                Created for{" "}
                <Box fontWeight={800} component="span">
                  {activity?.data?.customerName}
                </Box>
              </Typography>

              <EmailedTo emails={activity?.data?.email?.emailedTo} />
            </Box>
          );
        }
        return;
      }

      case "DRAFT_ORDER_COMPLETED": {
        if (activity?.data?.customerName) {
          return (
            <Box>
              <Typography fontSize={12} noWrap width="78%">
                Draft completed (Order #{" "}
                <Box
                  sx={{
                    color: "#000",
                    textDecoration: "none",
                    "&:hover": { textDecoration: "underline" },
                  }}
                  fontWeight={800}
                  to={`/orders/${activity?.data?.orderId}`}
                  component={activity?.data?.orderId ? Link : "span"}
                >
                  {activity?.data?.orderCustomId}
                </Box>{" "}
                was created from this draft order for{" "}
                <Box fontWeight={800} component="span">
                  {activity?.data?.customerName}
                </Box>
                )
              </Typography>
            </Box>
          );
        }
        return;
      }

      case "DELIVERY_OPERATION": {
        if (operationType === "CREATE") {
          return (
            <Box>
              <Box display="flex" alignItems="center" gap="7px">
                <Typography fontSize={12}>
                  via{" "}
                  {CARRIER_INFO[data?.delivery?.deliveryMethod]?.carrierName ||
                    preparedType(data?.delivery?.deliveryMethod)}
                </Typography>
                {CARRIER_INFO[data?.delivery?.deliveryMethod]?.logo}
              </Box>
              {data?.delivery?.products && (
                <>
                  <Typography sx={{ opacity: "30%" }} fontSize={12}>
                    Cases:
                  </Typography>
                  {data.delivery.products.newProducts?.map((product, i) => {
                    product.id = i;
                    return (
                      <Typography key={product.id} fontSize={12}>
                        {product.name} - {product.quantity} cases
                      </Typography>
                    );
                  })}
                </>
              )}
            </Box>
          );
        }
        if (operationType === "UPDATE") {
          let setUpdatedProducts;
          if (data?.delivery?.products?.deletedProducts) {
            setUpdatedProducts = data?.delivery?.products?.deletedProducts
              ?.map((delProd) => {
                let foundEl = data?.delivery?.products?.newProducts?.find(
                  (newProd) => newProd.name === delProd.name
                );
                foundEl = foundEl ?? "deleted";
                if (foundEl === "deleted") {
                  return {
                    oldProd: delProd,
                    type: "removed",
                  };
                }
                if (delProd?.quantity > foundEl?.quantity)
                  return {
                    oldProd: delProd,
                    newProd: foundEl,
                    type: "removed",
                  };
                if (delProd?.quantity < foundEl?.quantity)
                  return { oldProd: delProd, newProd: foundEl, type: "added" };
                if (delProd?.quantity === foundEl?.quantity)
                  return {
                    oldProd: delProd,
                    newProd: foundEl,
                    type: "no changes",
                  };
              })
              .filter((el) => el?.type !== "no changes");
          }

          return (
            <>
              {!!data?.delivery?.trackingId?.length && (
                <Box>
                  <Typography fontSize={12}>
                    Updated the tracking id:
                  </Typography>
                  <Typography fontSize={12}>
                    previous tracking id &quot;
                    {data?.delivery?.trackingId?.[0]}
                    &quot; / current tracking id &quot;
                    {data?.delivery?.trackingId?.[1]}&quot;
                  </Typography>
                </Box>
              )}
              {!!data?.delivery?.shippingCarrier?.length && (
                <Box>
                  <Typography fontSize={12}>
                    Updated the shipping carrier:
                  </Typography>
                  <Typography fontSize={12}>
                    previous shipping carrier &quot;
                    {data?.delivery?.shippingCarrier?.[0]}
                    &quot; / current shipping carrier &quot;
                    {data?.delivery?.shippingCarrier?.[1]}&quot;
                  </Typography>
                </Box>
              )}
              {!!data?.delivery?.deliverer?.length && (
                <Box>
                  <Typography fontSize={12}>
                    Updated the fulfillment:
                  </Typography>
                  <Typography fontSize={12}>
                    previous fulfillment &quot;{data?.delivery?.deliverer?.[0]}
                    &quot; / current delivery &quot;
                    {data?.delivery?.deliverer?.[1]}&quot;
                  </Typography>
                </Box>
              )}

              {!!data?.delivery?.products?.deletedProducts?.length && (
                <Box>
                  {!!setUpdatedProducts?.length && (
                    <>
                      <Typography fontSize={12}>
                        <Box sx={{ opacity: "30%" }} component="span">
                          Cases:{" "}
                        </Box>
                        {setUpdatedProducts.map((prod) => {
                          return (
                            <Box
                              key={prod.id || prod?.oldProd?.name}
                              component="span"
                            >
                              <Box
                                sx={{
                                  color:
                                    prod?.type === "added" ? "green" : "red",
                                }}
                                component="span"
                              >
                                {prod?.type}{" "}
                              </Box>
                              <Box fontWeight={800} component="span">
                                {prod?.newProd?.name}{" "}
                              </Box>
                              <Box component="span">
                                {`old value - ${prod?.oldProd?.quantity} cases`}
                                {prod?.newProd?.quantity &&
                                  `/ new value - ${prod?.newProd?.quantity} cases `}
                              </Box>
                            </Box>
                          );
                        })}
                      </Typography>
                    </>
                  )}
                </Box>
              )}
              {!!data?.delivery?.date?.length && (
                <Box>
                  <Typography fontSize={12}>Updated the date:</Typography>
                  <Typography fontSize={12}>
                    Old date:{" "}
                    {moment(data?.delivery?.date?.[0]).format("MM/DD/YY")}
                  </Typography>
                  <Typography fontSize={12}>
                    New date:{" "}
                    {moment(data?.delivery?.date?.[1]).format("MM/DD/YY")}
                  </Typography>
                </Box>
              )}
              {!!data?.delivery?.products?.updatedProducts?.length && (
                <Box>
                  <Typography fontSize={12}>Updated the quantities:</Typography>
                  {data?.delivery?.products?.updatedProducts.map((prod) => (
                    <Typography key={prod.id || prod.name} fontSize={12}>
                      {prod.name} - old value &quot;{prod.quantity[0]}&quot;
                      cases / new value &quot;{prod.quantity[1]}&quot; cases
                    </Typography>
                  ))}
                </Box>
              )}
            </>
          );
        }
        if (operationType === "DELETE") {
          return (
            <Box>
              <Typography fontSize={12}>
                <Box sx={{ color: "red" }} component="span">
                  Deleted the fulfillment
                </Box>
              </Typography>
            </Box>
          );
        }
        return;
      }

      case "ORDER_EDITED":
      case "DRAFT_ORDER_EDITED": {
        return (
          <>
            {data?.products?.deletedProducts?.length &&
              data?.products?.deletedProducts.map((prod) => (
                <Box key={prod.id || prod.name}>
                  <Typography fontSize={12}>
                    <Box sx={{ color: "red" }} component="span">
                      Removed:{" "}
                    </Box>
                    <Box component="span">
                      &quot;{prod?.name}&quot; from this order
                    </Box>
                  </Typography>
                </Box>
              ))}
            {data?.products?.newProducts?.length &&
              data?.products?.newProducts.map((prod) => (
                <Box key={prod.id || prod.name}>
                  <Typography fontSize={12}>
                    <Box sx={{ color: "green" }} component="span">
                      Added:{" "}
                    </Box>
                    <Box component="span">
                      &quot;{prod?.name}&quot; to this order
                    </Box>
                  </Typography>
                </Box>
              ))}
            {data?.products?.updatedProducts?.length &&
              data?.products?.updatedProducts.map((prod) => (
                <Box key={prod.name}>
                  <Typography fontSize={12}>
                    {prod?.quantity[0] < prod?.quantity[1] ? (
                      <>
                        <Box sx={{ color: "green" }} component="span">
                          Added:{" "}
                        </Box>
                        <Box component="span">
                          {prod?.quantity[1] - prod?.quantity[0]} cases of{" "}
                        </Box>
                        <Box component="span">
                          &quot;{prod?.name}&quot; to this order
                        </Box>
                      </>
                    ) : (
                      <>
                        <Box sx={{ color: "red" }} component="span">
                          Removed:{" "}
                        </Box>
                        <Box component="span">
                          {prod?.quantity[0] - prod?.quantity[1]} cases of{" "}
                        </Box>
                        <Box component="span">
                          &quot;{prod?.name}&quot; from this order
                        </Box>
                      </>
                    )}
                  </Typography>
                </Box>
              ))}
            {data?.discounts?.addedManufacturerDiscounts?.length &&
              data?.discounts?.addedManufacturerDiscounts?.map((prod) => (
                <Box key={prod}>
                  <Typography fontSize={12}>
                    <Box component="span">Added MSD: </Box>
                    <Box component="span">&quot;{prod}&quot; </Box>
                    <Box component="span">to this order</Box>
                  </Typography>
                </Box>
              ))}
            {data?.discounts?.removedManufacturerDiscounts?.length &&
              data?.discounts?.removedManufacturerDiscounts.map((prod) => (
                <Box key={prod}>
                  <Typography fontSize={12}>
                    <Box sx={{ color: "red" }} component="span">
                      Removed :{" "}
                    </Box>
                    <Box component="span">&quot;{prod}&quot; </Box>
                    <Box component="span">from this order</Box>
                  </Typography>
                </Box>
              ))}
            {/* {data?.discounts?.changedManufacturerDiscounts?.length && (
              <>
                <Typography fontSize={12}>Changed the discount ISD: </Typography>
                {data?.discounts?.changedManufacturerDiscounts?.map((prod) => (
                  <Box key={prod.name}>
                    <Typography fontSize={12}>
                      <Box component="span">&quot;{prod?.name}&quot; </Box>
                      <Box component="span">
                        Old value -{" "}
                        {prod?.changedManufacturerDiscount[0] === "PERCENTAGE" &&
                          "%"}
                        {prod?.changedManufacturerDiscount[0] === "ABSOLUTE" &&
                          "$"}
                        {prod?.changedManufacturerAmount[0]}{" "}
                      </Box>
                      <Box component="span">
                        New value -{" "}
                        {prod?.changedManufacturerDiscount[1] === "PERCENTAGE" &&
                          "%"}
                        {prod?.changedManufacturerDiscount[1] === "ABSOLUTE" &&
                          "$"}
                        {prod?.changedManufacturerAmount[1]}
                      </Box>
                    </Typography>
                  </Box>
                ))}
              </>
            )} */}
            {data?.discounts?.addedItemDiscounts?.length && (
              <>
                <Typography fontSize={12}>Added the discount ISD: </Typography>
                {data?.discounts?.addedItemDiscounts?.map((prod) => (
                  <Box key={prod.id || prod.name}>
                    <Typography fontSize={12}>
                      <Box component="span">&quot;{prod?.name}&quot; </Box>
                      <Box component="span">{prod?.itemDiscountAmount}</Box>
                      <Box component="span">
                        {prod?.itemDiscountType === "PERCENTAGE" && "%"}
                        {prod?.itemDiscountType === "ABSOLUTE" && "$"}
                      </Box>
                    </Typography>
                  </Box>
                ))}
              </>
            )}
            {data?.discounts?.removedItemDiscounts?.length && (
              <>
                <Typography fontSize={12}>
                  Removed the discount ISD:{" "}
                </Typography>
                {data?.discounts?.removedItemDiscounts?.map((prod) => (
                  <Box
                    key={`${prod.name}-${prod.sku}-${prod.size}-${prod.color}`}
                  >
                    <Typography fontSize={12}>
                      <Box component="span">&quot;{prod?.name}&quot; </Box>
                      <Box component="span">
                        {prod?.itemDiscountAmount}
                        {prod?.itemDiscountType === "ABSOLUTE" && "$"}
                        {prod?.itemDiscountType === "PERCENTAGE" && "%"}
                      </Box>
                    </Typography>
                  </Box>
                ))}
              </>
            )}
            {data?.discounts?.changedItemDiscounts?.length && (
              <>
                <Typography fontSize={12}>
                  Updated the discount ISD:{" "}
                </Typography>
                {data?.discounts?.changedItemDiscounts?.map((prod) => (
                  <Box key={prod.id || prod.name}>
                    <Typography fontSize={12}>
                      <Box component="span">&quot;{prod?.name}&quot; </Box>
                      <Box component="span">
                        Old value - {prod?.itemDiscountAmount[0]}{" "}
                        {prod?.itemDiscountType[0] === "PERCENTAGE" && "%"}
                        {prod?.itemDiscountType[0] === "ABSOLUTE" && "$"}
                      </Box>
                      <Box component="span">
                        New value - {prod?.itemDiscountAmount[1]}
                        {prod?.itemDiscountType[1] === "PERCENTAGE" && "%"}
                        {prod?.itemDiscountType[1] === "ABSOLUTE" && "$"}
                      </Box>
                    </Typography>
                  </Box>
                ))}
              </>
            )}
            {data?.discounts?.totalOrderDiscount?.length && (
              <>
                <Typography fontSize={12}>
                  Updated the discount TOD:{" "}
                </Typography>
                <Box>
                  <Typography fontSize={12}>
                    <Box component="span">
                      Old value -{" "}
                      {
                        data?.discounts?.totalOrderDiscount[0]
                          ?.totalOrderDiscountAmount
                      }
                      {data?.discounts?.totalOrderDiscount[0]
                        ?.totalOrderDiscountType === "PERCENTAGE" && "%"}
                      {data?.discounts?.totalOrderDiscount[0]
                        ?.totalOrderDiscountType === "ABSOLUTE" && "$"}
                    </Box>{" "}
                    <Box component="span">
                      New value -{" "}
                      {
                        data?.discounts?.totalOrderDiscount[1]
                          ?.totalOrderDiscountAmount
                      }
                      {data?.discounts?.totalOrderDiscount[1]
                        ?.totalOrderDiscountType === "PERCENTAGE" && "%"}
                      {data?.discounts?.totalOrderDiscount[1]
                        ?.totalOrderDiscountType === "ABSOLUTE" && "$"}
                    </Box>
                  </Typography>
                </Box>
              </>
            )}
            {data?.deliveryFee?.length && (
              <>
                <Typography fontSize={12}>
                  Updated the delivery fee:{" "}
                </Typography>
                <Typography fontSize={12}>From: </Typography>
                <Typography fontSize={12}>
                  ${separateNumWithComma(data?.deliveryFee[0])}
                </Typography>
                <Typography fontSize={12}>To: </Typography>
                <Typography fontSize={12}>
                  ${separateNumWithComma(data?.deliveryFee[1])}
                </Typography>
              </>
            )}

            <EmailedTo emails={activity?.data?.email?.emailedTo} />
          </>
        );
      }

      case "CONTACT_CHANGED": {
        return (
          data?.values?.length && (
            <Box>
              <Typography fontSize={12} noWrap width="78%">
                <Box component="span">Updated : </Box>
                <Box component="span">from &quot;{data?.values[0]}&quot; </Box>
                <Box component="span">to &quot;{data?.values[1]}&quot; </Box>
              </Typography>
            </Box>
          )
        );
      }

      case "SALES_CHANGED": {
        return (
          data?.values?.length && (
            <Box>
              <Typography fontSize={12} noWrap width="78%">
                <Box component="span">Updated : </Box>
                <Box component="span">from &quot;{data?.values[0]}&quot; </Box>
                <Box component="span">to &quot;{data?.values[1]}&quot; </Box>
              </Typography>
            </Box>
          )
        );
      }

      case "ORDER_NOTE_CHANGED":
      case "DRAFT_ORDER_NOTE_CHANGED": {
        return !data?.values[0] ? (
          <Typography fontSize={12}>
            <Box sx={{ color: "green" }} component="span">
              Added the Order note:{" "}
            </Box>
            <Box component="span">{data?.values[1]}</Box>
          </Typography>
        ) : (
          <>
            <Typography fontSize={12}>
              <Box sx={{ color: "yellowgreen" }} component="span">
                Updated the Order note:{" "}
              </Box>
            </Typography>
            <Typography fontSize={12} noWrap width="78%">
              from: {data?.values[0]}
            </Typography>
            <Typography fontSize={12} noWrap width="78%">
              to: {data?.values[1]}
            </Typography>
          </>
        );
      }

      case "RETURN_OPERATION": {
        if (operationType === "CREATE") {
          return (
            <Box>
              <Typography fontSize={12}>
                {`Created a return ${
                  data?.return?.restock ? "with restock" : ""
                } for this order`}
              </Typography>
              <Typography fontSize={12} noWrap width="78%">
                <Box sx={{ opacity: "30%" }} component="span">
                  Cases:{" "}
                </Box>
                {data?.return?.products?.newProducts?.map(
                  ({ name, quantity }) => `${name} - ${quantity} cases`
                )}
              </Typography>
              <Typography fontSize={12} noWrap width="78%">
                Reason: {data?.return?.reason}
              </Typography>
            </Box>
          );
        }
        if (operationType === "UPDATE") {
          const trackingIdUpd = !!data?.return?.trackingId?.length;
          const reasonUpd = !!data?.return?.reason?.length;
          const carrierUpd = !!data?.return?.shippingCarrier?.length;

          const resultUpdDel = data?.return?.products?.deletedProducts
            ?.map((delProd, i) => {
              const foundEl = data?.return?.products?.newProducts?.find(
                (newProd) => newProd.name === delProd.name
              );
              if (!foundEl) {
                return {
                  id: i,
                  oldProd: delProd,
                  newProd: {},
                  type: "deleted",
                };
              }
              if (
                delProd?.quantity > foundEl?.quantity ||
                delProd?.quantity < foundEl?.quantity
              )
                return {
                  id: i,
                  oldProd: delProd,
                  newProd: foundEl,
                  type: "updated",
                };
              if (delProd?.quantity === foundEl?.quantity)
                return {
                  id: i,
                  oldProd: delProd,
                  newProd: foundEl,
                  type: "no changes",
                };
            })
            .filter((el) => el && el?.type !== "no changes");

          const resultUpd = data?.return?.products?.updatedProducts;
          const resultDeliverer = data?.return?.deliverer;

          return (
            <Box>
              <Typography fontSize={12}>
                <Box component="span">
                  {`Updated the return ${
                    data?.return?.restock ? "with restock" : ""
                  }`}
                </Box>
              </Typography>
              {!!resultDeliverer?.length && (
                <Typography fontSize={12} noWrap width="78%">
                  <Box sx={{ opacity: "30%" }} component="span">
                    Deliverer:{" "}
                  </Box>
                  {`from ${resultDeliverer[0]} to ${resultDeliverer[1]}`}
                </Typography>
              )}
              {!!resultUpd?.length && (
                <Typography fontSize={12} noWrap width="78%">
                  <Box sx={{ opacity: "30%" }} component="span">
                    Cases:{" "}
                  </Box>
                  {resultUpd?.map(
                    ({ name, quantity }) =>
                      `${name} - old value ${quantity[0]} cases / new value ${quantity[1]} cases`
                  )}
                </Typography>
              )}
              {!!resultUpdDel?.length && (
                <>
                  <Typography fontSize={12} fontWeight={800}>
                    Old value:
                  </Typography>
                  {resultUpdDel.map(({ id, oldProd }) => {
                    return (
                      <Fragment key={id}>
                        <Typography key={id} fontSize={12}>
                          {id === 0 && (
                            <Box sx={{ opacity: "30%" }} component="span">
                              Cases:{" "}
                            </Box>
                          )}
                          <Box component="span">
                            {oldProd.name} - {oldProd.quantity} cases
                          </Box>
                          {/* <Box
                      sx={{
                        color: () => {
                          if (type === "added") return "green";
                          if (type === "updated") return "yellowgreen";
                          if (type === "deleted") return "red";
                        },
                      }}
                      component="span"
                    >
                      {type}{" "}
                    </Box> */}
                          {/* <Box fontWeight={800} component="span">
                        {newProd?.name || oldProd?.name}{" "}
                      </Box> */}
                          {/* <Box component="span">
                        {`old value - ${oldProd?.quantity}`}
                      </Box> */}
                          {/* {newProd?.quantity && (
                        <Box component="span">
                          {`/ new value - ${newProd?.quantity}`}
                        </Box>
                      )} */}
                        </Typography>
                      </Fragment>
                    );
                  })}
                  <Typography fontSize={12} fontWeight={800}>
                    New value:
                  </Typography>
                  {resultUpdDel.map(({ id, newProd }) => {
                    return (
                      <Fragment key={id}>
                        <Typography fontSize={12}>
                          {id === 0 && (
                            <Box sx={{ opacity: "30%" }} component="span">
                              Cases:{" "}
                            </Box>
                          )}
                          <Box component="span">
                            {newProd.name} - {newProd.quantity} cases
                          </Box>
                        </Typography>
                      </Fragment>
                    );
                  })}
                </>
              )}
              {trackingIdUpd && (
                <>
                  <Typography fontSize={12} fontWeight={800}>
                    Old tracking ID:
                  </Typography>
                  <Typography fontSize={12}>
                    {data.return.trackingId[0]}
                  </Typography>
                  <Typography fontSize={12} fontWeight={800}>
                    New tracking ID:
                  </Typography>
                  <Typography fontSize={12}>
                    {data.return.trackingId[1]}
                  </Typography>
                </>
              )}
              {reasonUpd && (
                <>
                  <Typography fontSize={12} fontWeight={800}>
                    Old reason:
                  </Typography>
                  <Typography fontSize={12}>{data.return.reason[0]}</Typography>

                  <Typography fontSize={12} fontWeight={800}>
                    New reason:
                  </Typography>
                  <Typography fontSize={12}>{data.return.reason[1]}</Typography>
                </>
              )}
              {carrierUpd && (
                <>
                  <Typography fontSize={12} fontWeight={800}>
                    Old Shipping Carrier:
                  </Typography>
                  <Typography fontSize={12}>
                    {data.return.shippingCarrier[0]}
                  </Typography>

                  <Typography fontSize={12} fontWeight={800}>
                    New Shipping Carrier:
                  </Typography>
                  <Typography fontSize={12}>
                    {data.return.shippingCarrier[1]}
                  </Typography>
                </>
              )}
            </Box>
          );
        }
        if (operationType === "DELETE") {
          return (
            <Box>
              <Typography fontSize={12}>
                {`Deleted the return ${
                  data?.return?.restock ? "with restock" : ""
                } for this order`}
              </Typography>
              <Typography fontSize={12}>
                <Box sx={{ opacity: "30%" }} component="span">
                  Cases:{" "}
                </Box>
                {data?.return?.products?.deletedProducts?.map(
                  ({ name, quantity }) => `${name} - ${quantity} cases `
                )}
              </Typography>
            </Box>
          );
        }
        return;
      }

      case "PAYMENT_OPERATION": {
        if (operationType === "CREATE") {
          return (
            <Box>
              <Typography fontSize={12}>
                Created the payment via{" "}
                {normalizeSnakeCaseString(data?.payment?.paymentType)} - $
                {separateNumWithComma(data?.payment?.amount)}
              </Typography>
            </Box>
          );
        }
        if (operationType === "UPDATE") {
          return (
            <Box>
              {data?.payment?.reference?.length && (
                <>
                  <Typography fontSize={12}>Updated the reference:</Typography>
                  <Typography fontSize={12}>
                    Old reference: {data?.payment?.reference?.[0]}
                  </Typography>
                  <Typography fontSize={12}>
                    New reference: {data?.payment?.reference?.[1]}
                  </Typography>
                </>
              )}
              {data?.payment?.date?.length && (
                <>
                  <Typography fontSize={12}>Updated the date:</Typography>
                  <Typography fontSize={12}>
                    Old date:{" "}
                    {moment(data?.payment?.date?.[0]).format("MM/DD/YY")}
                  </Typography>
                  <Typography fontSize={12}>
                    New date:{" "}
                    {moment(data?.payment?.date?.[1]).format("MM/DD/YY")}
                  </Typography>
                </>
              )}
              {data?.payment?.amount?.length && (
                <>
                  <Typography fontSize={12}>Updated the payment:</Typography>
                  <Typography fontSize={12}>
                    Old value - $
                    {separateNumWithComma(data?.payment?.amount[0])} amount
                  </Typography>
                  <Typography fontSize={12}>
                    New value - $
                    {separateNumWithComma(data?.payment?.amount[1])} amount
                  </Typography>
                </>
              )}
            </Box>
          );
        }
        if (operationType === "DELETE") {
          return (
            <Box>
              <Typography fontSize={12}>
                Deleted the payment via{" "}
                {normalizeSnakeCaseString(data?.payment?.paymentType)} - $
                {separateNumWithComma(data?.payment?.amount)}
              </Typography>
            </Box>
          );
        }
        return;
      }

      case "REFUND_OPERATION": {
        const setTitle = () => {
          if (data?.return?.restock && data?.return?.products)
            return "Created the refund with restock and return";
          if (data?.return?.restock) return "Created the refund with restock";
          if (data?.return?.products) return "Created the refund with return";

          return "Created the refund";
        };
        if (operationType === "CREATE") {
          return (
            <Box>
              <Typography fontSize={12}>{setTitle()}</Typography>

              <Typography fontSize={12} noWrap width="78%">
                {data?.refund?.map(
                  ({ paymentType, amount }) =>
                    `Via ${normalizeSnakeCaseString(
                      paymentType
                    )} - $${separateNumWithComma(amount)} `
                )}
              </Typography>
            </Box>
          );
        }
        if (operationType === "UPDATE") {
          return (
            <>
              {!!data?.refund?.reference?.length && (
                <Box>
                  <Typography fontSize={12}>Updated the reference:</Typography>
                  <Typography fontSize={12}>
                    Old reference: {data?.refund?.reference?.[0]}
                  </Typography>
                  <Typography fontSize={12}>
                    New reference: {data?.refund?.reference?.[1]}
                  </Typography>
                </Box>
              )}
              {!!data?.refund?.date?.length && (
                <Box>
                  <Typography fontSize={12}>Updated the date:</Typography>
                  <Typography fontSize={12}>
                    Old date:{" "}
                    {moment(data?.refund?.date?.[0]).format("MM/DD/YY")}
                  </Typography>
                  <Typography fontSize={12}>
                    New date:{" "}
                    {moment(data?.refund?.date?.[1]).format("MM/DD/YY")}
                  </Typography>
                </Box>
              )}
              {!!data?.refund?.amount?.length && (
                <Box>
                  <Typography fontSize={12}>
                    Updated the refund{" "}
                    {!!data?.return?.restock && "with restock"}
                  </Typography>

                  <Typography fontSize={12}>
                    Old value - ${separateNumWithComma(data?.refund?.amount[0])}
                  </Typography>
                  <Typography fontSize={12}>
                    New value - ${separateNumWithComma(data?.refund?.amount[1])}
                  </Typography>
                </Box>
              )}
            </>
          );
        }
        if (operationType === "DELETE") {
          return (
            <Box>
              <Typography fontSize={12}>
                Deleted the refund {!!data?.return?.restock && "with restock"}
              </Typography>
              <Typography fontSize={12}>
                Via {normalizeSnakeCaseString(data?.refund?.paymentType)} - $
                {separateNumWithComma(data?.refund?.amount)}
              </Typography>
            </Box>
          );
        }
        return;
      }

      case "ORDER_CANCELED": {
        return (
          <Box>
            <Typography fontSize={12}>
              Canceled this order {!!data?.return?.restock && "with restock"}
            </Typography>

            <Typography fontSize={12} noWrap width="78%">
              Reason: {data?.reason}
            </Typography>
          </Box>
        );
      }

      case "BILLING_ADDRESS_CHANGED": {
        return (
          <Box>
            <Typography fontSize={12}>Updated the address Bill to:</Typography>
            <Typography fontSize={12} noWrap width="78%">
              <Box component="span">From: </Box>
              <Box component="span">{data?.values?.[0]}</Box>
            </Typography>
            <Typography fontSize={12} noWrap width="78%">
              <Box component="span">To: </Box>
              <Box component="span">{data?.values?.[1]}</Box>
            </Typography>
          </Box>
        );
      }

      case "SHIPPING_ADDRESS_CHANGED": {
        return (
          <Box>
            <Typography fontSize={12}>Updated the address Ship to:</Typography>
            <Typography fontSize={12} noWrap width="78%">
              <Box component="span">From: </Box>
              <Box component="span">{data?.values?.[0]}</Box>
            </Typography>
            <Typography fontSize={12} noWrap width="78%">
              <Box component="span">To: </Box>
              <Box component="span">{data?.values?.[1]}</Box>
            </Typography>
          </Box>
        );
      }

      case "PAYMENT_TERMS_CHANGED": {
        return (
          <Box>
            <Typography fontSize={12}>Updated Payment terms:</Typography>
            <Typography fontSize={12} noWrap width="78%">
              <Box component="span">From: </Box>
              <Box component="span">{data?.values?.[0]}</Box>
            </Typography>
            <Typography fontSize={12} noWrap width="78%">
              <Box component="span">To: </Box>
              <Box component="span">{data?.values?.[1]}</Box>
            </Typography>
          </Box>
        );
      }

      case "CUSTOMER_CHANGED": {
        return (
          data?.values?.length && (
            <Box>
              <Typography fontSize={12} noWrap width="78%">
                <Box component="span">Updated : </Box>
                <Box component="span">from &quot;{data?.values[0]}&quot; </Box>
                <Box component="span">to &quot;{data?.values[1]}&quot; </Box>
              </Typography>
            </Box>
          )
        );
      }

      case "FULFILL_BY_CHANGED":
      case "FULFILL_VIA_CHANGED": {
        const [from, to] = data?.values;
        const formatDate = (date) => moment(date).format("MMM D[,] YYYY");
        return (
          data?.values?.length && (
            <Box>
              <Typography fontSize={12} noWrap width="78%">
                <Box component="span">{from ? "Updated: " : "Added: "}</Box>
                {from ? (
                  <>
                    {" "}
                    <Box component="span">{`from "${formatDate(from)}" `}</Box>
                    <Box component="span">{`to "${formatDate(to)}"`}</Box>{" "}
                  </>
                ) : (
                  <Box component="span">{formatDate(to)}</Box>
                )}
              </Typography>
            </Box>
          )
        );
      }

      case "ORDER_EMAILED": {
        return <EmailedTo emails={activity?.data?.email?.emailedTo} />;
      }

      default:
        break;
    }
  };
  return <>{renderInfo(activity)}</>;
};
ActivityMoreInfo.propTypes = {
  activity: object,
};

export default ActivityMoreInfo;
