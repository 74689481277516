import { func, object } from "prop-types";
import { Box } from "@mui/material";
import { PaymentDetailsBody, PaymentDetailsHeader } from "./components";
import { cl } from "./PaymentDetails.styles";

export const PaymentDetails = ({
  control,
  onAddCard,
  onEditCard,
  onDeleteCard,
}) => {
  return (
    <Box sx={cl.wrapper}>
      <PaymentDetailsHeader />
      <PaymentDetailsBody
        control={control}
        onAddCard={onAddCard}
        onEditCard={onEditCard}
        onDeleteCard={onDeleteCard}
      />
    </Box>
  );
};

PaymentDetails.propTypes = {
  control: object,
  onAddCard: func,
  onEditCard: func,
  onDeleteCard: func,
};
