import { string } from "prop-types";

import { cl } from "./styles";

import { Stack, Typography } from "@mui/material";
import { useState } from "react";
import { GOOGLE_PLACE_LINK } from "utils/constants";

export const CustomerImage = ({ url, placeId }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseOver = () => {
    if (placeId) setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleOpenPlace = () => {
    if (!placeId) return;
    const googleMapsUrl = `${GOOGLE_PLACE_LINK}?q=place_id:${placeId}`;

    const url = new URL(googleMapsUrl);
    const anchor = document.createElement("a");
    anchor.href = url.href;
    anchor.target = "_blank";
    anchor.rel = "noopener noreferrer";
    anchor.click();
  };

  return (
    <>
      {url && (
        <Stack
          sx={cl.imageWrapper}
          onMouseOver={handleMouseOver}
          onMouseLeave={handleMouseLeave}
        >
          <img src={url} alt="customer-image" />
          <Stack
            sx={{
              ...cl.hoveredImage,
              display: placeId ? "flex" : "none",
              opacity: isHovered ? 1 : 0,
            }}
            onClick={handleOpenPlace}
          >
            <Typography variant="caption" sx={cl.imageLabel}>
              SEE PHOTOS
            </Typography>
          </Stack>
        </Stack>
      )}
    </>
  );
};

CustomerImage.propTypes = {
  url: string,
  placeId: string,
};

CustomerImage.defaultProps = {
  url: null || "",
  placeId: null || "",
};
