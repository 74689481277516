import { useCallback, useEffect, useState } from "react";
import { STATUSES_TASKS, TABS_PAGE } from "../TasksPage.constants";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { currentUserSelector } from "redux/selectors/auth";
import { useRepsPermissions } from "helpers/hooks";
import { tasksFilterAction } from "redux/actions/tasks";
import {
  bulkMarkAsCompletedService,
  bulkSetDueDateService,
  bulkTasksAssignRepresentativesService,
} from "services/tasks";
import { handleError } from "helpers/helpers";
import { error, success } from "utils/notifications";
import { uniqBy } from "lodash";
import { useNavigate } from "react-router-dom";

const selector = createSelector(currentUserSelector, (currentUser) => ({
  currentUser,
}));

export const useTasksPage = ({ tasksState }) => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector(selector);
  const navigate = useNavigate();
  const repPermissions = useRepsPermissions();

  const {
    // countTasks,
    // existData,
    // existDataByCustomer,
    fetchTasksList,
    // loadingTasksList,
    tasksList,
    tasksFilter,
    checkedTasks,
    setCheckedTasks,
    allTasksChecked,
    setAllTasksChecked,
    setTasksState,
  } = tasksState || {};

  const [currentTab, setCurrentTab] = useState(TABS_PAGE[0].value);

  const handleChangeCurrentTab = (_, tab) => {
    setCurrentTab(tab);
  };

  const handleCheckTask = (taskId) => {
    const taskIndex = checkedTasks.findIndex(
      (checkedTask) => checkedTask === taskId
    );
    if (taskIndex > -1) {
      const newTasks = [...checkedTasks];
      newTasks.splice(taskIndex, 1);
      return setCheckedTasks([...newTasks]);
    }

    setCheckedTasks([...checkedTasks, taskId]);
  };

  const handleUncheckAllTasks = useCallback(() => {
    setAllTasksChecked(false);
    setCheckedTasks([]);
  }, [setAllTasksChecked, setCheckedTasks]);

  const checkAllTasks = useCallback(() => {
    dispatch(
      tasksFilterAction({ ...tasksFilter, page: undefined, limit: undefined })
    );
  }, [dispatch, tasksFilter]);

  const handleSelectAvailableTasks = useCallback(() => {
    if (tasksList?.length === checkedTasks?.length) {
      handleUncheckAllTasks();
      return;
    }

    setCheckedTasks(tasksList);
  }, [checkedTasks?.length, handleUncheckAllTasks, setCheckedTasks, tasksList]);

  const handleBulkMarkAsCompleted = useCallback(async () => {
    const pendingTasksIds = checkedTasks
      .filter((checkedTask) => checkedTask?.status === STATUSES_TASKS.PENDING)
      .map((task) => task?.id);

    if (!pendingTasksIds.length) return error("Nothing to mark as completed");

    try {
      setTasksState((prev) => ({ ...prev, loadingTasksList: true }));

      await bulkMarkAsCompletedService({
        tasksIds: pendingTasksIds,
        status: STATUSES_TASKS.COMPLETED,
      });

      dispatch(tasksFilterAction({ ...tasksFilter, page: 1 }));
      setCheckedTasks([]);
      success("Tasks marked as completed");
    } catch (error) {
      handleError(error);
    } finally {
      setTasksState((prev) => ({ ...prev, loadingTasksList: false }));
    }
  }, [checkedTasks, dispatch, setCheckedTasks, setTasksState, tasksFilter]);

  const handleBulkMarkAsIncomplete = useCallback(async () => {
    const completedTasksIds = checkedTasks
      .filter((checkedTask) => checkedTask?.status === STATUSES_TASKS.COMPLETED)
      .map((task) => task?.id);

    if (!completedTasksIds?.length) error("Nothing to mark as incomplete");

    try {
      setTasksState((prev) => ({ ...prev, loadingTasksList: true }));

      await bulkMarkAsCompletedService({
        tasksIds: completedTasksIds,
        status: STATUSES_TASKS.PENDING,
      });

      dispatch(tasksFilterAction({ ...tasksFilter, page: 1 }));
      setCheckedTasks([]);
      success("Tasks marked as incomplete");
    } catch (error) {
      handleError(error);
    } finally {
      setTasksState((prev) => ({ ...prev, loadingTasksList: false }));
    }
  }, [checkedTasks, dispatch, setCheckedTasks, setTasksState, tasksFilter]);

  const handleCreateRoute = useCallback(() => {
    const checkedTasksIds = checkedTasks.map((task) => task?.id);

    if (!checkedTasksIds.length) return error("Nothing to create route");

    if (repPermissions && !repPermissions?.routes?.create_edit) return;

    if (checkedTasksIds?.length > tasksList?.length) {
      const checkedCustomers = uniqBy(
        checkedTasks.map(({ customer }) => customer),
        ({ id }) => id
      );
      navigate("/routes/new", { state: { checkedCustomers } });
      return;
    }

    const orderObjects = checkedTasksIds.map((orderId) =>
      tasksList.find(({ id }) => id === orderId)
    );

    const checkedCustomers = uniqBy(
      orderObjects.map(({ customer }) => customer),
      ({ id }) => id
    );

    navigate("/routes/new", { state: { checkedCustomers } });
  }, [checkedTasks, navigate, repPermissions, tasksList]);

  const handleBulkAssignRepresentatives = useCallback(
    async ({ repId, isDistributor }) => {
      const tasksIds = checkedTasks.map((task) => task?.id);

      if (!tasksIds.length || !repId) return error("Nothing to assign rep");

      try {
        setTasksState((prev) => ({ ...prev, loadingTasksList: true }));
        const preparedData = {
          tasksIds,
          assignToDistributor: !!isDistributor,
        };

        if (isDistributor) preparedData.representativesIds = [];
        if (!isDistributor) preparedData.representativesIds = [repId];

        await bulkTasksAssignRepresentativesService(preparedData);

        dispatch(tasksFilterAction({ ...tasksFilter, page: 1 }));
        setCheckedTasks([]);
        success("Assigned rep updated");
      } catch (error) {
        handleError(error);
      } finally {
        setTasksState((prev) => ({ ...prev, loadingTasksList: false }));
      }
    },
    [checkedTasks, dispatch, setCheckedTasks, setTasksState, tasksFilter]
  );

  const handleSaveDueDate = useCallback(
    async (data) => {
      try {
        setTasksState((prev) => ({ ...prev, loadingTasksList: true }));

        await bulkSetDueDateService(data);

        dispatch(tasksFilterAction({ ...tasksFilter, page: 1 }));
        setCheckedTasks([]);
        success("Due date updated");
      } catch (error) {
        handleError(error);
      } finally {
        setTasksState((prev) => ({ ...prev, loadingTasksList: false }));
      }
    },
    [dispatch, setCheckedTasks, setTasksState, tasksFilter]
  );

  const [checkedCustomers, setCheckedCustomers] = useState([]);

  const [taskDrawerState, setTaskDrawerState] = useState({
    open: false,
    data: null,
  });

  const handleCreateTask = (data) => {
    setCheckedCustomers([]);
    setTaskDrawerState((prev) => ({
      ...prev,
      open: true,
      data: data || null,
    }));
  };

  const handleCloseTaskDrawer = () => {
    setAllTasksChecked(false);
    setCheckedTasks([]);

    setCheckedCustomers([]);
    setTaskDrawerState({
      open: false,
      data: null,
    });
  };

  const handleFetchList = () =>
    dispatch(tasksFilterAction({ ...tasksFilter, page: 1 }));

  useEffect(() => {
    if (tasksList?.length && tasksList?.length === checkedTasks?.length) {
      setAllTasksChecked(true);
    } else {
      setAllTasksChecked(false);
    }
  }, [checkedTasks?.length, setAllTasksChecked, tasksList?.length]);

  useEffect(() => {
    fetchTasksList();
  }, [fetchTasksList]);

  return {
    TABS_PAGE,
    currentTab,
    handleChangeCurrentTab,
    currentUser,
    checkedTasks,
    handleCheckTask,
    allTasksChecked,
    setAllTasksChecked,
    checkAllTasks,
    handleSelectAvailableTasks,
    repPermissions,
    handleBulkMarkAsCompleted,
    handleBulkMarkAsIncomplete,
    handleBulkAssignRepresentatives,
    handleCreateRoute,
    handleSaveDueDate,
    taskDrawerState,
    setTaskDrawerState,
    handleCreateTask,
    handleCloseTaskDrawer,
    checkedCustomers,
    setCheckedCustomers,
    handleFetchList,
    handleUncheckAllTasks,
  };
};
