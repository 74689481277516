import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  InputAdornment,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { bool, func } from "prop-types";
import { CrossIcon, SearchIcon } from "components/Icons";
import { SearchSelect, StyledButton, StyledTextField } from "components";
import { FSQ_CATEGORIES } from "./ProspectsFilterDialog.constants";
import useStyles from "Pages/CartPage/styles";

const ProspectsFilterDialog = ({ open, onClose, onSearchProspects }) => {
  const [types, setTypes] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const classes = useStyles({ searchInput });
  const handleSelectType = (e) => {
    setTypes(e.target.value);
  };

  const handleDeleteType = (id) => {
    setTypes((prev) => prev.filter((t) => t.id !== id));
  };

  const filteredCategories = FSQ_CATEGORIES.filter((c) =>
    c.name.toLowerCase().includes(searchInput.toLowerCase())
  );
  return (
    <Dialog fullWidth {...{ open, onClose }}>
      <DialogContent>
        <IconButton
          sx={{ position: "absolute", top: "12px", right: "14px" }}
          onClick={onClose}
        >
          <CrossIcon />
        </IconButton>
        <Typography mb="16px">Prospect Type</Typography>
        <SearchSelect
          label="Choose types"
          // formSx={{ width: "70%" }}
          value={types}
          fullWidth
          onChange={handleSelectType}
          handleDelete={handleDeleteType}
          multiselect
          chipSx={{
            height: "24px",
            border: "0.5px solid #D4D4D4",
            borderRadius: "10px",
            backgroundColor: "#FAFAFB",
          }}
          PaperPropsSx={{
            height: "fit-content",
            maxHeight: "354px ",
          }}
          onKeyDown={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <Box>
            <StyledTextField
              fullWidth
              size="small"
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
              name="searchInput"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              placeholder="Search prospect type"
              sx={{ padding: "5px 10px" }}
              onClick={(e) => {
                e.stopPropagation();
              }}
              onFocus={(e) => {
                e.stopPropagation();
              }}
              label=""
              InputProps={{
                className: classes.textInput,
                endAdornment: (
                  <>
                    <InputAdornment
                      sx={{ position: "absolute", left: 9 }}
                      position="start"
                    >
                      <SearchIcon />
                    </InputAdornment>
                    {searchInput && (
                      <InputAdornment position="end">
                        <IconButton
                          sx={{ p: "1px" }}
                          onClick={() => setSearchInput("")}
                        >
                          <CrossIcon size="15" />
                        </IconButton>
                      </InputAdornment>
                    )}
                  </>
                ),
              }}
              InputLabelProps={{
                className: classes.inputLabel,
              }}
            />
          </Box>
          {filteredCategories.map((c) => (
            <MenuItem key={c.id} value={c}>
              {c.name}
            </MenuItem>
          ))}
          {filteredCategories.length === 0 ? (
            <Typography
              fontWeight="300"
              fontSize="16px"
              textAlign="center"
              width="100%"
              color="#6A6A6A"
              paddingTop="20px"
              paddingBottom="20px"
            >
              No prospects types found
            </Typography>
          ) : null}
        </SearchSelect>
        <DialogActions sx={{ justifyContent: "center", p: "27px 0 8px" }}>
          <StyledButton
            fullWidth
            sx={{ height: "39px" }}
            fontSize="15px"
            label="Search Prospects"
            variant="contained"
            onClick={() => onSearchProspects(types)}
          />
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

ProspectsFilterDialog.propTypes = {
  open: bool,
  onClose: func,
  onSearchProspects: func,
};

ProspectsFilterDialog.defaultProps = {
  open: false,
  onClose: () => {},
  onSearchProspects: () => {},
};

export default ProspectsFilterDialog;
