import React from "react";

export const OrderIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="52"
      height="52"
      viewBox="0 0 52 52"
    >
      <rect
        id="Rectangle_8851"
        data-name="Rectangle 8851"
        width="52"
        height="52"
        rx="26"
        fill="#409a65"
        opacity="0.1"
      />
      <g
        id="Group_4898"
        data-name="Group 4898"
        transform="translate(14 13.625)"
      >
        <path
          id="Path_2994"
          data-name="Path 2994"
          d="M9,5.75a.75.75,0,0,0,0-1.5ZM7,5V5ZM5,7H5ZM5,19H5ZM17,5V5Zm-2-.75a.75.75,0,0,0,0,1.5Zm-6,0H7v1.5H9Zm-2,0a2.75,2.75,0,0,0-1.945.805L6.116,6.116A1.25,1.25,0,0,1,7,5.75Zm-1.945.805A2.75,2.75,0,0,0,4.25,7h1.5a1.25,1.25,0,0,1,.366-.884ZM4.25,7V19h1.5V7Zm0,12a2.75,2.75,0,0,0,.805,1.944l1.061-1.061A1.25,1.25,0,0,1,5.75,19Zm.805,1.944A2.75,2.75,0,0,0,7,21.75v-1.5a1.25,1.25,0,0,1-.884-.366ZM7,21.75h5.7v-1.5H7ZM19.75,12V7h-1.5v5Zm0-5a2.75,2.75,0,0,0-.806-1.945L17.884,6.116A1.25,1.25,0,0,1,18.25,7Zm-.806-1.945A2.75,2.75,0,0,0,17,4.25v1.5a1.25,1.25,0,0,1,.884.366ZM17,4.25H15v1.5h2Zm-4.3,17.5H17v-1.5H12.7ZM19.75,19V12h-1.5v7ZM17,21.75A2.75,2.75,0,0,0,19.75,19h-1.5A1.25,1.25,0,0,1,17,20.25Z"
          fill="#409a65"
        />
        <path
          id="Path_2995"
          data-name="Path 2995"
          d="M13,3H11a2,2,0,0,0,0,4h2a2,2,0,0,0,0-4Z"
          fill="none"
          stroke="#409a65"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_2996"
          data-name="Path 2996"
          d="M9,15h3M9,11H9Z"
          fill="none"
          stroke="#409a65"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
};
