export const SETUP_STEPS = [
  {
    title: "Add your personal information",
    description:
      "You will need to submit your personal information in order to start receiving payouts.",
    type: "personalDetails",
    successTitle: "Personal information",
  },
  {
    title: "Add your banking information",
    description:
      "To start receiving payouts, you will need to provide your banking information.",
    type: "banking",
    successTitle: "Banking information",
  },
];
