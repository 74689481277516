import { string } from "prop-types";
import { Link } from "@mui/material";
import { GooglePlayBtnIcon } from "./GooglePlayBtnIcon";

export const GooglePlayBtn = ({ url }) => {
  return (
    <Link href={url} target="_blank">
      <GooglePlayBtnIcon />
    </Link>
  );
};

GooglePlayBtn.propTypes = { url: string };
GooglePlayBtn.defaultProps = { url: "" };
