export const handleCheckSwitchByKey = (switchesList, key) => {
  return switchesList.map((item) => {
    if (item.value === key) {
      return {
        ...item,
        checked: true,
      };
    }
    return item;
  });
};

export const checkIfAllUnchecked = (switchesList) => {
  return !switchesList.some((item) => item.checked);
};

export const handleSetSwitchesForEdit = (obj, switchesList) => {
  let uncheckedSwitches = switchesList.map((item) => {
    return {
      ...item,
      checked: false,
    };
  });

  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      if (Array.isArray(obj[key])) {
        if (obj[key].length > 0) {
          uncheckedSwitches = handleCheckSwitchByKey(uncheckedSwitches, key);
        }
      } else if (obj[key]) {
        uncheckedSwitches = handleCheckSwitchByKey(uncheckedSwitches, key);
      }
    }
  }
  return uncheckedSwitches;
};

export const checkForDefaultFilter = async ({
  type,
  list,
  setFilters,
  setSwitches,
  switchesList,
  dispatchFunc,
}) => {
  if (!list.length) return;
  const filterByType = list.filter((filter) => filter.type === type);

  if (!filterByType.length) return;

  const defaultFilter = filterByType[0];
  dispatchFunc(defaultFilter.id);
  setFilters(defaultFilter.filters);
  setSwitches(handleSetSwitchesForEdit(defaultFilter.filters, switchesList));
};
