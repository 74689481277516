import { string, bool, func, shape, object } from "prop-types";
import { Box, Typography } from "@mui/material";
import { IOSSwitch, StyledTooltip } from "components";

export const StorefrontAccessItem = ({
  title,
  subtitle,
  disabled,
  value,
  handle,
  tooltip,
}) => {
  return (
    <Box height="60px" display="flex" alignItems="center">
      <Box flexGrow={1}>
        <Typography fontSize={12} fontWeight={500} color="#000">
          {title}
        </Typography>
        <Typography fontSize={12} fontWeight={300} color="#000">
          {subtitle}
        </Typography>
      </Box>
      <StyledTooltip
        title={tooltip?.title}
        placement="top"
        arrow
        disableHoverListener={!tooltip?.show}
        PopperProps={{
          modifiers: [{ name: "offset", options: { offset: [0, -6] } }],
        }}
      >
        <Box sx={{ "& div": { mr: 0 } }}>
          <IOSSwitch
            small
            checked={value}
            disabled={disabled}
            onChange={handle}
            hideSwitchText
            formSx={{ mr: 0, cursor: disabled ? "default" : "pointer" }}
          />
        </Box>
      </StyledTooltip>
    </Box>
  );
};

StorefrontAccessItem.propTypes = {
  title: string,
  subtitle: string,
  disabled: bool,
  value: bool,
  handle: func,
  tooltip: shape({
    title: object,
    show: bool,
  }),
};
StorefrontAccessItem.defaultProps = {
  title: "",
  subtitle: "",
  disabled: false,
  value: false,
  handle: () => {},
  tooltip: shape({
    title: "",
    show: false,
  }),
};
