import { useEffect, useState } from "react";
import { getRepsService } from "services/reps";
import { error } from "utils/notifications";

const initialState = {
  loading: false,
  list: [],
  count: 0,
  params: {
    active: true,
    cursor: null,
  },
};

export const useRepsListAction = ({ isOpen, withoutThirdParty }) => {
  const [repsState, setRepsState] = useState(initialState);

  const handleFetchReps = async (signal) => {
    try {
      setRepsState((prev) => ({ ...prev, loading: true }));
      const { rows, countMerchandiser, countSales } = await getRepsService(
        repsState.params,
        signal
      );
      setRepsState((prev) => ({
        ...prev,
        list: [
          ...prev.list,
          ...(withoutThirdParty
            ? rows.filter((r) => r.role !== "THIRD_PARTY")
            : rows),
        ],
        count: (countMerchandiser ?? 0) + (countSales ?? 0),
        params: {
          ...prev.params,
          cursor: rows?.[rows?.length - 1]?.id,
        },
      }));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      error(err?.response?.data?.message || "Something went wrong.");
    } finally {
      setRepsState((prev) => ({ ...prev, loading: false }));
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    if (isOpen) {
      handleFetchReps(controller.signal);
    } else {
      setRepsState(initialState);
    }

    return () => controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [repsState.params.search, isOpen]);

  return {
    repsList: repsState.list,
    loading: repsState.loading,
  };
};
