/* eslint-disable no-useless-escape */
import * as Yup from "yup";

const passwordValidation = (value, ctx) => {
  const UPPER_REGEXP = /(?=.*[A-Z])/;
  const LOWER_REGEXP = /(?=.*?[a-z])/;
  const ONE_DIGIT_REGEXP = /(?=.*[0-9])/;
  if (!UPPER_REGEXP.test(value))
    return ctx.createError({
      message: "Password must contain at least 1 upper case letter",
    });
  if (!LOWER_REGEXP.test(value))
    return ctx.createError({
      message: "Password must contain at least 1 lower case letter",
    });
  if (!ONE_DIGIT_REGEXP.test(value))
    return ctx.createError({
      message: "Password must contain at least 1 number",
    });
  return true;
};

export const validationSchema = () =>
  Yup.object().shape({
    email: Yup.string().trim().required("Email field is required"),
    password: Yup.string()
      .trim()
      .min(8, "Password is too short - should be 8 chars minimum.")
      .required("This field is required")
      .test({
        name: "passwordValidation",
        test: (value, ctx) => passwordValidation(value, ctx),
      }),
  });
