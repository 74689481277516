import { shape, string, func, number } from "prop-types";
import moment from "moment";
import { capitalize } from "lodash";
import { Box, Button, Typography } from "@mui/material";
import { ExpiredIcon } from "../../../../components/Icons";
import { CardIconComponent } from "../../../../components";
import { cl } from "./styles";

export const CardItem = ({ card, onEditCard }) => {
  const {
    cardItem: { mainWrapper },
  } = cl;
  const isCardExpired = (month, year) => {
    const cardDate = moment(`${year}.${month}`).format("yyyy.MM");

    return moment().diff(cardDate, "months") > 0;
  };

  return (
    <Box sx={mainWrapper}>
      <Box display="flex" alignItems="center" gap={1}>
        <CardIconComponent size={30} type={card?.brand?.toLowerCase()} />

        <Typography fontSize={22} fontWeight={400} color="#707070">
          {capitalize(card?.brand)} ending in {card?.last4}
        </Typography>
        {isCardExpired(card?.expMonth, card?.expYear) && <ExpiredIcon />}
      </Box>
      <Button sx={{ fontSize: 16 }} onClick={() => onEditCard(card)}>
        Replaced
      </Button>
    </Box>
  );
};

CardItem.propTypes = {
  card: shape({
    brand: string,
    expMonth: number,
    expYear: number,
    last4: string,
  }),
  onEditCard: func,
};
CardItem.defaultProps = {
  card: {
    brand: "",
    expMonth: 0,
    expYear: 0,
    last4: "",
  },
};
