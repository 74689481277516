import {
  CREATE_PAYTERM,
  DELETE_PAYTERM,
  END_LOADING_PAYTERMS,
  GET_PAYTERMS,
  START_LOADING_PAYTERMS,
  UPDATE_PAYTERM,
} from "../actions/payterms";

const initialState = {
  payterms: [],
  loading: false,
};

const paytermsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_PAYTERMS:
      return { ...state, payterms: payload };

    case START_LOADING_PAYTERMS:
      return { ...state, loading: true };

    case CREATE_PAYTERM:
      return { ...state, payterms: [...state.payterms, payload] };

    case DELETE_PAYTERM: {
      const paytermsList = [...state.payterms];
      const index = paytermsList.findIndex(
        (payterms) => payterms.id === payload
      );
      if (index >= 0) {
        paytermsList.splice(index, 1);
      }
      return { ...state, payterms: paytermsList, loading: false };
    }
    case UPDATE_PAYTERM: {
      const paytermsList = [...state.payterms];
      const updateIndex = paytermsList.findIndex(
        (customer) => customer.id === payload.id
      );
      if (updateIndex >= 0) {
        paytermsList.splice(updateIndex, 1, payload);
      }
      return { ...state, payterms: paytermsList, loading: false };
    }
    case END_LOADING_PAYTERMS:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default paytermsReducer;
