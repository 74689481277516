import { useEffect, useMemo } from "react";
import { bool, func, array, oneOf, object } from "prop-types";
import { Box, Drawer } from "@mui/material";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema } from "./TagsDrawer.validations";
import { TAG_TYPES, useTagsActions } from "helpers/useTagsActions";
import { useTags } from "./useTags";
import { ActionsBlock, HeaderBlock, TagsBlock } from "./components";
import { AdvancedDrawerContent } from "components/TaskDrawer/components/AdvancedDrawerContent";

export const TagsDrawer = ({
  type,
  open,
  handleClose,
  customers,
  productTags,
  orderTags,
  handleSave,
  isBulk,
  loading,
  setCheckedCustomers,
  styles = {},
  adminIsAllowed,
  hideAdvanced,
}) => {
  const { control, setValue, reset } = useForm({
    mode: "onSubmit",
    shouldFocusError: false,
    defaultValues: { tagInput: "", selectedTagsList: [] },
    resolver: yupResolver(validationSchema()),
  });

  const getCommonStrings = (arrays) => {
    const map = new Map();

    arrays.forEach((array) => {
      array.forEach((obj) => {
        const id = obj?.tag?.id;
        map.set(id, (map.get(id) || 0) + 1);
      });
    });

    const commonObjects = [];
    arrays[0].forEach((obj) => {
      if (map.get(obj?.tag?.id) === arrays.length) {
        commonObjects.push(obj);
      }
    });

    return commonObjects;
  };

  const preparedTags = useMemo(() => {
    if (!customers?.length) return [];
    const tagsArr = customers.map((t) => t?.tags);
    return getCommonStrings(tagsArr);
  }, [customers]);

  const tags = useMemo(() => {
    if (TAG_TYPES.customer === type) {
      return isBulk ? preparedTags : customers?.[0]?.tags || {};
    }

    if (TAG_TYPES.product === type) {
      if (isBulk) {
        return productTags.map((tag) => {
          return tag;
        });
      }

      return productTags?.map((tag) => {
        return { tag };
      });
    }

    if (TAG_TYPES.order === type) {
      if (isBulk) {
        return orderTags.map((tag) => {
          return tag;
        });
      }

      return orderTags.map((tag) => {
        return { tag };
      });
    }
  }, [customers, isBulk, orderTags, preparedTags, productTags, type]);

  const customerId = isBulk ? "" : customers?.[0]?.id || {};

  const formField = useWatch({ control });

  const { list: tagsList, loading: loadingTagsList } = useTagsActions({
    open,
    type,
    params: {
      sort_by_tag: "asc",
      limit: null,
      page: null,
    },
  });

  const {
    handlePressEnter,
    handleBlur,
    handleAddTag,
    handleDeleteTag,
    handleCreate,
  } = useTags({ formField, setValue, tags, tagsList, open, type });

  const onClose = () => handleClose();

  useEffect(() => {
    if (!open) {
      reset({ tagInput: "", selectedTagsList: [] });
    }
  }, [open, reset]);

  const onSave = () => {
    handleSave({
      tags: formField?.selectedTagsList,
      customerIds: isBulk ? customers?.map((c) => c?.id) : [customerId],
      isBulk,
    });
  };

  return (
    <Drawer
      sx={{ ...styles }}
      open={open}
      anchor="right"
      data-testid="tags-drawer"
    >
      <HeaderBlock {...{ onClose }} />

      {!hideAdvanced && isBulk && TAG_TYPES.customer === type && (
        <Box
          px={4.25}
          pt={3.5}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "18px",
            "& .MuiOutlinedInput-root": {
              fontSize: "12px",
            },
          }}
        >
          <AdvancedDrawerContent
            selectedCustomers={customers}
            // customers={formField.customers}
            setCheckedCustomers={setCheckedCustomers}
            isAdvancedDrawer
            titleStyles={{ fontSize: 18, color: "#3F3F3F" }}
            adminIsAllowed={adminIsAllowed}
          />
        </Box>
      )}

      <TagsBlock
        selectedTagsList={formField?.selectedTagsList}
        {...{
          handleDeleteTag,
          handlePressEnter,
          handleBlur,
          setValue,
          handleCreate,
          control,
          tagsList,
          handleAddTag,
          isBulk,
          type,
        }}
        loading={loadingTagsList}
      />

      <ActionsBlock {...{ onClose, onSave, loading }} />
    </Drawer>
  );
};

TagsDrawer.propTypes = {
  type: oneOf(Object.keys(TAG_TYPES)).isRequired,
  open: bool,
  handleClose: func,
  handleSave: func,
  isBulk: bool,
  customers: array,
  productTags: array,
  orderTags: array,
  loading: bool,
  hideAdvanced: bool,
  setCheckedCustomers: func,
  styles: object,
  adminIsAllowed: bool,
};
TagsDrawer.defaultProps = {
  open: false,
  handleClose: () => {},
  handleSave: () => {},
  isBulk: false,
  customers: [],
  productTags: [],
  orderTags: [],
  loading: false,
  hideAdvanced: false,
  setCheckedCustomers: () => {},
  adminIsAllowed: false,
};
