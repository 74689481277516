export const repsListSelector = (state) => state.reps.reps;
export const repsSummariesSelector = (state) => state.reps.topSummaries;
export const repsTopSummariesLoadingSelector = (state) =>
  state.reps.topSummariesLoading;
export const repsGetSalesUsers = (state) => state.reps.salesReps;
export const repsGetThirdPartyUsers = (state) => state.reps.thirdPartyReps;
export const thirdPartyRepsCountSelector = (state) =>
  state.reps.thirdPartyCount;
export const repsGetParamsSelector = (state) => state.reps.params;
export const repsCountSelector = (state) => state.reps.count;
export const salesCountSelector = (state) => state.reps.salesCount;
export const merchandisersListSelector = (state) =>
  state.reps.merchandisersReps;
export const merchandisersCountSelector = (state) =>
  state.reps.merchandisersCount;
export const repsInactiveCountSelector = (state) => state.reps.inactiveCount;
export const repsInactiveListSelector = (state) => state.reps.inactive;
export const repsLoadingSelector = (state) => state.reps.loading;
export const repsShowInactiveSelector = (state) => state.reps.showInactive;
export const repsExistActiveSelector = (state) => state.reps.existDataActive;
export const repsExistInactiveSelector = (state) =>
  state.reps.existDataInactive;
export const repsGetFilterSelector = (state) => state.reps.repsFilter;
export const repsGetSwitchSelector = (state) => state.reps.repsFilterSwitches;
export const routesGetFilterSelector = (state) => state.reps.routesFilter;
export const routesGetSwitchSelector = (state) => state.reps.routesSwitch;
export const routesGetQuickFilterSelector = (state) =>
  state.reps.routesQuickFilter;
export const repsCurrentTabSelector = (state) => state.reps.currentTab;
