import { bool } from "prop-types";
import { StyledButton } from "components";

import { cl } from "../styles";

import { DialogActions } from "@mui/material";

export const ActionBlock = ({ disabledBtn }) => {
  return (
    <DialogActions sx={cl.actionWrapper}>
      <StyledButton
        disabled={disabledBtn}
        sx={cl.resetBtn}
        fontSize="15px"
        label="Reset Password"
        variant="contained"
        type="submit"
      />
    </DialogActions>
  );
};

ActionBlock.propTypes = {
  disabledBtn: bool,
};
ActionBlock.defaultProps = {
  disabledBtn: false,
};
