import {
  CLOSE_SNACKBAR,
  OPEN_SNACKBAR,
  SET_SNACKBAR_PROGRESS,
  SET_SNACKBAR_COUNT_ORDERS,
  SET_SNACKBAR_STATUS,
  RESET_SNACKBAR,
  SET_CONNECT_TO_FIREBASE_SNACKBAR,
} from "redux/actions/snackbar";

const initialState = {
  open: false,
  progress: 0,
  countOrders: 0,
  status: "",
  connect_to_db: false,
};

const snackbarReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case OPEN_SNACKBAR:
      return { ...state, open: true };

    case CLOSE_SNACKBAR:
      return { ...state, open: false };

    case SET_SNACKBAR_PROGRESS:
      return { ...state, progress: payload };

    case SET_SNACKBAR_COUNT_ORDERS:
      return { ...state, countOrders: payload };

    case SET_SNACKBAR_STATUS:
      return { ...state, status: payload };

    case SET_CONNECT_TO_FIREBASE_SNACKBAR:
      return { ...state, connect_to_db: payload };

    case RESET_SNACKBAR:
      return { ...initialState };

    default:
      return state;
  }
};

export default snackbarReducer;
