export const SET_FLAT_COLUMN_LAYOUTS = "SET_FLAT_COLUMN_LAYOUTS";
export const SET_SORTED_COLUMN_LAYOUTS = "SET_SORTED_COLUMN_LAYOUTS";
export const SET_DIRECT_COLUMN_LAYOUTS = "SET_DIRECT_COLUMN_LAYOUTS";
export const SET_THIRD_PARTY_COLUMN_LAYOUTS = "SET_THIRD_PARTY_COLUMN_LAYOUTS";
export const SET_REP_ALL_COLUMN_LAYOUTS = "SET_REP_ALL_COLUMN_LAYOUTS";
export const SET_DRAFTS_COLUMN_LAYOUTS = "SET_DRAFTS_COLUMN_LAYOUTS";

const setFlatColumnLayouts = (payload) => ({
  type: SET_FLAT_COLUMN_LAYOUTS,
  payload,
});

const setSortedColumnLayouts = (payload) => ({
  type: SET_SORTED_COLUMN_LAYOUTS,
  payload,
});

const setDirectColumnLayouts = (payload) => ({
  type: SET_DIRECT_COLUMN_LAYOUTS,
  payload,
});

const setThirdPartyColumnLayouts = (payload) => ({
  type: SET_THIRD_PARTY_COLUMN_LAYOUTS,
  payload,
});

const setRepAllColumnLayouts = (payload) => ({
  type: SET_REP_ALL_COLUMN_LAYOUTS,
  payload,
});

const setDraftsColumnLayouts = (payload) => ({
  type: SET_DRAFTS_COLUMN_LAYOUTS,
  payload,
});

export const setFlatColumnLayoutsAction = (columnsData) => {
  return (dispatch) => {
    dispatch(setFlatColumnLayouts(columnsData));
  };
};
export const setSortedColumnLayoutsAction = (columnsData) => {
  return (dispatch) => {
    dispatch(setSortedColumnLayouts(columnsData));
  };
};
export const setDirectColumnLayoutsAction = (columnsData) => {
  return (dispatch) => {
    dispatch(setDirectColumnLayouts(columnsData));
  };
};
export const setThirdPartyColumnLayoutsAction = (columnsData) => {
  return (dispatch) => {
    dispatch(setThirdPartyColumnLayouts(columnsData));
  };
};
export const setRepAllColumnLayoutsAction = (columnsData) => {
  return (dispatch) => {
    dispatch(setRepAllColumnLayouts(columnsData));
  };
};
export const setDraftsColumnLayoutsAction = (columnsData) => {
  return (dispatch) => {
    dispatch(setDraftsColumnLayouts(columnsData));
  };
};
