export const defaultValues = {
  deliveryFee: false,
  deliveryFeePriceCap: false,
  deliveryFeeAmount: 0,
  deliveryFeeType: "PERCENTAGE",
  deliveryFeePriceCapAmount: 0.0,
  minimumOrderValue: false,
  minimumOrderValueAmount: 0,
  signatureOnOrders: false,
};
