import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { object, func, bool, array } from "prop-types";
import { Controller, useFieldArray, useWatch } from "react-hook-form";
import { cloneDeep, filter, uniq } from "lodash";

import {
  Button,
  Divider,
  Grid,
  MenuItem,
  Typography,
  Box,
} from "@mui/material";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import {
  openConfirmDialogAction,
  setConfirmIsOpenAction,
  setEditTypeAction,
  setFormChangedAction,
} from "redux/actions/confirmDialogs";

import {
  LargeCheckbox,
  StyledButton,
  StyledMenu,
  StyledTooltip,
} from "components";

import {
  InputsVariations,
  ReorderBurgerHandler,
  VariationsTabHeaderList,
  VariationsTabItemList,
} from "./components";

import useStyles from "./styles";
import Nestable from "react-nestable";
import { success, warning } from "utils/notifications";
import { MsgToast } from "./components/MsgToast";

const VariationsTab = ({
  control,
  isEdit,
  setValue,
  setError,
  register,
  reset,
  errors,
  clearErrors,
  saveDataAfterChangePhotos,
  saveDataAfterChangeVariations,
  trigger,
  disabledUploadFile,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const formField = useWatch({ control });

  const { fields, append } = useFieldArray({
    control,
    name: "variationsFields",
  });

  const indexes = fields.reduce((map, prod, index) => {
    return { ...map, [isEdit ? prod?.initialId : prod?.customId]: index };
  }, {});

  const sortedFieldsList = useMemo(() => {
    const res = cloneDeep(fields)
      .sort((a, b) => {
        const valA = a?.size?.toUpperCase();
        const valB = b?.size?.toUpperCase();
        if (valA < valB) return -1;
        if (valA > valB) return 1;
        return 0;
      })
      .map((item) => ({
        ...item,
        sortedId: indexes[isEdit ? item?.initialId : item?.customId],
      }));

    return res;
  }, [fields, indexes, isEdit]);

  const [checkedProducts, setCheckedProducts] = useState([]);
  const [allProductsChecked, setAllProductsChecked] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const openBulkList = Boolean(anchorEl);
  const handleClickOnBulkBtn = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };
  const handleCloseBulkBtn = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleSetActiveForCustomers = (e, type) => {
    const tempArr = [...formField?.variationsFields];

    checkedProducts.forEach((el) => {
      const i = tempArr.findIndex((item) => {
        if (item?.id) {
          return item.id === el.id;
        }
        if (item?.customId) {
          return item.customId === el.customId;
        }
      });

      tempArr[i].status =
        type === "ACTIVE" ? "ACTIVE" : "INACTIVE_FOR_CUSTOMERS";
    });
    setValue("variationsFields", tempArr, { shouldDirty: true });
    handleCloseBulkBtn(e);
    setCheckedProducts([]);
    setAllProductsChecked(false);
  };

  // const handleDeletePhotos = async (photos) => {
  //   const photosWithCid = [];
  //   const photosWithoutCid = [];

  //   photos.forEach((photo) => {
  //     if (photo?.cid) return photosWithCid.push(photo);
  //     photosWithoutCid.push(photo);
  //   });

  //   try {
  //     if (photosWithCid.length) {
  //       await removePhotoService({
  //         data: {
  //           ids: photosWithCid.map((p) => p?.cid),
  //         },
  //         isCID: true,
  //       });
  //     }
  //     if (photosWithoutCid.length) {
  //       await removePhotoService({
  //         data: {
  //           ids: photosWithoutCid.map((p) => p?.id),
  //         },
  //         isCID: false,
  //       });
  //     }
  //     return true;
  //   } catch (err) {
  //     console.error(err);
  //     error(err?.response?.data?.message || "Something went wrong.");
  //   }
  // };

  const handleBulkDeletePhotos = async (e) => {
    const tempArr = [...formField?.variationsFields];

    checkedProducts.forEach((el) => {
      const i = tempArr.findIndex((item) => {
        if (item?.id) {
          return item.id === el.id;
        }
        if (item?.customId) {
          return item.customId === el.customId;
        }
      });
      if (isEdit) {
        tempArr[i].photos = [];
      } else {
        tempArr[i].photoIds = [];
        tempArr[i].photoUrl = "";
      }
    });
    setValue("variationsFields", tempArr);
    handleCloseBulkBtn(e);
    setCheckedProducts([]);
    setAllProductsChecked(false);

    if (isEdit)
      saveDataAfterChangePhotos({
        successMsg: "Photo(s) deleted",
        data: formField?.photos,
        variationsData: formField?.variationsFields,
      });
  };

  const handleBulkDeleteVariants = () => {
    dispatch(
      openConfirmDialogAction({
        title:
          checkedProducts.length > 1 ? "Delete products?" : "Delete product?",
        text: "Are you sure you want to delete?\nAll data will be erased and this can't be undone.",
        isIcon: false,
        buttons: (
          <>
            <Button
              sx={{
                width: "98px",
                color: "#6A6A6A",
                borderColor: "#D4D4D4",
                fontSize: "13px",
                height: "28px",
              }}
              variant="outlined"
              onClick={() => {
                dispatch(setConfirmIsOpenAction(false));
                // handleCloseBulkBtn(e);z
              }}
            >
              Cancel
            </Button>
            <Button
              sx={{
                width: "98px",
                color: "#FFFFFF",
                fontSize: "13px",
                height: "28px",
                boxShadow: "none",
              }}
              color="confirmDelete"
              onClick={() => {
                dispatch(setConfirmIsOpenAction(false));
                const tempArr = [...fields];

                checkedProducts.forEach((el) => {
                  const i = tempArr.findIndex((item) => {
                    if (item?.id) {
                      return item.id === el.id;
                    }
                    if (item?.customId) {
                      return item.customId === el.customId;
                    }
                  });
                  tempArr.splice(i, 1);
                });
                setValue("variationsFields", tempArr);
                setCheckedProducts([]);
                setAllProductsChecked(false);
              }}
              variant="contained"
            >
              Delete
            </Button>
          </>
        ),
      })
    );
    dispatch(setFormChangedAction(true));
    dispatch(setEditTypeAction("product", !isEdit));
  };

  const handleCheckProduct = (product) => {
    const repIndex = checkedProducts.findIndex((el) => {
      if (product?.id) {
        return product.id === el.id;
      }
      if (product?.customId) {
        return product.customId === el.customId;
      }
    });
    if (repIndex > -1) {
      const newProducts = [...checkedProducts];
      newProducts.splice(repIndex, 1);
      return setCheckedProducts([...newProducts]);
    }
    setCheckedProducts([...checkedProducts, product]);
  };

  const checkAllProducts = () => {
    setAllProductsChecked(!allProductsChecked);
    if (checkedProducts.length === fields.length) {
      setAllProductsChecked(false);
      return setCheckedProducts([]);
    }
    setAllProductsChecked(true);
    setCheckedProducts([...fields]);
  };

  useEffect(() => {
    if (checkedProducts.length === fields.length) {
      return setAllProductsChecked(true);
    }
    setAllProductsChecked(false);
  }, [checkedProducts, fields.length]);

  const handleCheckClick = useCallback(
    (variant, field) => {
      dispatch(
        openConfirmDialogAction({
          title: "Edit Variation Type",
          text:
            "Editing a variation type will delete the variation table " +
            "and values entered. Do you wish to continue?",
          isIcon: false,
          buttons: (
            <>
              <Button
                sx={{
                  width: "98px",
                  color: "#6A6A6A",
                  borderColor: "#D4D4D4",
                  fontSize: "13px",
                  height: "28px",
                }}
                variant="outlined"
                onClick={() => {
                  dispatch(setConfirmIsOpenAction(false));
                }}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  width: "98px",
                  color: "#FFFFFF",
                  fontSize: "13px",
                  height: "28px",
                  boxShadow: "none",
                }}
                color="confirmDelete"
                onClick={() => {
                  dispatch(setConfirmIsOpenAction(false));
                  reset({
                    ...formField,
                    ...variant,
                  });
                  setValue(field.name, !field.value);
                }}
                variant="contained"
              >
                Delete
              </Button>
            </>
          ),
        })
      );
    },
    [dispatch, formField, reset, setValue]
  );

  // useEffect(() => {
  //   if (
  //     !isEdit &&
  //     formField.isMultiple &&
  //     formField.chooseVariationsType.color &&
  //     !formField.colorVariationFields[0].color
  //   ) {
  //     setError("colorVariationFields[0].color", {
  //       type: "required",
  //       message: "This field is required",
  //     });
  //   } else {
  //     clearErrors("colorVariationFields[0].color");
  //   }
  // }, [
  //   isEdit,
  //   formField.chooseVariationsType.color,
  //   formField.colorVariationFields,
  //   formField.isMultiple,
  //   formField.skuVariationFields,
  //   setError,
  //   clearErrors,
  // ]);

  // useEffect(() => {
  //   if (
  //     !isEdit &&
  //     formField.isMultiple &&
  //     formField.chooseVariationsType.size &&
  //     !formField.sizeVariationFields[0].size
  //   ) {
  //     setError("sizeVariationFields[0].size", {
  //       type: "required",
  //       message: "This field is required",
  //     });
  //   } else {
  //     clearErrors("sizeVariationFields[0].size");
  //   }
  // }, [
  //   isEdit,
  //   formField.chooseVariationsType.size,
  //   formField.colorVariationFields,
  //   formField.isMultiple,
  //   formField.sizeVariationFields,
  //   setError,
  //   clearErrors,
  // ]);

  const handleAddVariations = () => {
    if (
      formField?.chooseVariationsType.color &&
      !formField?.colorVariationFields?.[0]?.color
    ) {
      setValue("chooseVariationsType.color", false);
    }

    if (
      formField?.chooseVariationsType.size &&
      !formField?.sizeVariationFields?.[0]?.size
    ) {
      setValue("chooseVariationsType.size", false);
    }

    if (errors?.colorVariationFields?.length) {
      return;
    }
    if (errors?.sizeVariationFields?.length) {
      return;
    }

    const sku = formField?.skuVariationFields
      ? formField?.skuVariationFields.filter((el) => el.sku)
      : [];

    const colors = formField?.colorVariationFields
      ? formField?.colorVariationFields.filter((el) => el.color)
      : [];

    const sizes = formField?.sizeVariationFields
      ? formField?.sizeVariationFields.filter((el) => el.size)
      : [];

    const variations = [sku, colors, sizes].filter((el) => el.length);

    if (
      formField.isMultiple &&
      !formField.skuVariationFields[0].sku &&
      !formField.variationsFields?.length
    ) {
      setError("skuVariationFields[0].sku", {
        type: "required",
        message: "This field is required",
      });
      return;
    } else {
      clearErrors("skuVariationFields[0].sku");
    }
    const arrayForCheck = [];

    const foundCombinationMsg = [];

    const newAddedItems = [];

    variations[0].forEach((elOne) => {
      if (variations[1])
        variations[1].forEach((elTwo) => {
          const mixed = { ...elOne, ...elTwo };
          const foundCombination = [
            ...formField.variationsFields,
            ...arrayForCheck,
          ].some(
            (v) =>
              `${v.sku}_${v.color || v.size}`.toLowerCase() ===
              `${mixed.sku}_${mixed.color || mixed.size}`.toLowerCase()
          );

          const newVar = {
            ...mixed,
            customId: Math.floor(Math.random() * 10000),
            initialId: Math.floor(Math.random() * 10000),
            barcode: "",
            // itemsPerCase: "0",
            // wholesalePrice: Number(0).toPrecision(3),
            // retailPrice: Number(0).toPrecision(3),
            distributorPrice: Number(0).toPrecision(3),
            itemsPerCase: "",
            wholesalePrice: "",
            retailPrice: "",
            photos: [],
            inventory: {
              onHand: 0,
              expected: 1,
            },
            available: 0,
            status: "ACTIVE",
          };
          if (!foundCombination) {
            arrayForCheck.push({ ...newVar });
            newAddedItems.push({ ...newVar });
            append({ ...newVar });
          }
          if (foundCombination) {
            const mixedVar =
              (mixed.color && "Color " + mixed.color) ||
              (mixed.size && "Size " + mixed.size);

            const preparedText = mixedVar ? ` - ${mixedVar}` : "";

            foundCombinationMsg.push(`SKU ${mixed.sku}${preparedText}`);
          }
        });
      else {
        const newVar = {
          ...elOne,
          customId: Math.floor(Math.random() * 10000),
          initialId: Math.floor(Math.random() * 10000),
          barcode: "",
          // itemsPerCase: "0",
          // wholesalePrice: Number(0).toPrecision(3),
          // retailPrice: Number(0).toPrecision(3),
          distributorPrice: Number(0).toPrecision(3),
          itemsPerCase: "",
          wholesalePrice: "",
          retailPrice: "",
          photos: [],
          inventory: {
            onHand: 0,
            expected: 1,
          },
          available: 0,
          status: "ACTIVE",
        };

        const foundCombination = [
          ...formField.variationsFields,
          ...arrayForCheck,
        ].some(
          (v) =>
            `${v.sku}_${v.color || v.size}`.toLowerCase() ===
            `${elOne.sku}_${elOne.color || elOne.size}`.toLowerCase()
        );
        if (!foundCombination) {
          arrayForCheck.push({ ...newVar });
          newAddedItems.push({ ...newVar });
          append({ ...newVar });
        }

        if (foundCombination) {
          const mixedVar =
            (elOne.color && "Color " + elOne.color) ||
            (elOne.size && "Size " + elOne.size);

          const preparedText = mixedVar ? ` - ${mixedVar}` : "";

          foundCombinationMsg.push(`SKU ${elOne.sku}${preparedText}`);
        }
      }
    });

    setValue("skuVariationFields", [{ sku: "" }]);
    setValue("colorVariationFields", [{ color: "" }]);
    setValue("sizeVariationFields", [{ size: "" }]);

    if (foundCombinationMsg?.length)
      return warning(<MsgToast messages={foundCombinationMsg} />, {
        options: { autoClose: 5000, icon: false },
      });

    if (isEdit)
      saveDataAfterChangeVariations({
        successMsg: "Variations saved",
        variationsData: [
          ...fields,
          ...newAddedItems.map((v) => ({ ...v, itemsPerCase: 1 })),
        ],
      });
  };

  const handleDeleteProduct = (item) => {
    if (!item) return;

    const filteredVariationsFields = formField?.variationsFields.filter(
      (product) => {
        if (item.customId) return product?.customId !== item?.customId;
        if (!item.customId && item.id) return product?.id !== item?.id;
      }
    );

    setValue("variationsFields", filteredVariationsFields);

    saveDataAfterChangeVariations({
      successMsg: "Variation Deleted",
      variationsData: filteredVariationsFields,
    });
  };

  const onDelete = (position, product) => {
    const productName = `${product?.sku}${
      product?.color ? " / " + product?.color : ""
    }${product?.size ? " / " + product?.size : ""}`;
    dispatch(
      openConfirmDialogAction({
        title: `Delete ${productName}?`,
        text: (
          <Typography
            sx={{
              fontSize: "inherit",
              fontWeight: "inherit",
              color: "inherit",
            }}
          >
            Are you sure you want to delete the product{" "}
            <Box sx={{ color: "#47A06D" }} component="span">
              {productName}
            </Box>
            ?<Box component="br" />
            All data will be erased and this can&apos;t be undone.
          </Typography>
        ),
        isIcon: true,
        buttons: (
          <>
            <Button
              sx={{
                width: "98px",
                color: "#6A6A6A",
                borderColor: "#D4D4D4",
                fontSize: "13px",
                height: "28px",
              }}
              variant="outlined"
              onClick={() => {
                dispatch(setConfirmIsOpenAction(false));
              }}
            >
              Cancel
            </Button>
            <Button
              sx={{
                color: "#FFFFFF",
                fontSize: "13px",
                height: "28px",
                boxShadow: "none",
              }}
              color="confirmDelete"
              onClick={() => {
                dispatch(setConfirmIsOpenAction(false));
                handleDeleteProduct(product);
              }}
              variant="contained"
            >
              Delete product
            </Button>
          </>
        ),
      })
    );
  };

  const checkUniqueFailed = useCallback(() => {
    const {
      skuVariationFields: sku,
      colorVariationFields: color,
      sizeVariationFields: size,
      chooseVariationsType: chosenTypes,
    } = formField;
    if (chosenTypes.color || chosenTypes.size) return;
    const skuFields = [
      ...sku.filter((s) => s.sku).map((s) => s.sku.toLowerCase()),
    ];
    const colorFields = [
      ...color.filter((c) => c.color).map((c) => c.color.toLowerCase()),
    ];
    const sizeFields = [
      ...size.filter((s) => s.size).map((s) => s.size.toLowerCase()),
    ];

    const withoutLastSku = [...skuFields];
    const withoutLastColor = [...colorFields];
    const withoutLastSize = [...sizeFields];

    withoutLastSku.splice(-1);
    withoutLastColor.splice(-1);
    withoutLastSize.splice(-1);

    const notUniqueSku = uniq(
      filter(skuFields, (v, i, a) => a.indexOf(v) !== i) // get sku duplicates array
    );

    const notUniqueColor = uniq(
      filter(colorFields, (v, i, a) => a.indexOf(v) !== i) // get color duplicates array
    );

    const notUniqueSize = uniq(
      filter(sizeFields, (v, i, a) => a.indexOf(v) !== i) // get size duplicates array
    );

    const arr = [];
    if (notUniqueSku?.length) arr.push(notUniqueSku.flat());
    if (notUniqueColor?.length) arr.push(notUniqueColor.flat());
    if (notUniqueSize?.length) arr.push(notUniqueSize.flat());

    const notUnique = !!(
      notUniqueSku?.length ||
      notUniqueColor?.length ||
      notUniqueSize?.length
    );

    return notUnique ? arr : false;
  }, [formField]);

  const uniqueFailed = useMemo(() => checkUniqueFailed(), [checkUniqueFailed]);

  const renderItem = (el) => {
    return (
      <>
        <VariationsTabItemList
          key={el.item.id || el.item.customId}
          product={
            formField.variationsFields[
              indexes[isEdit ? el?.item?.initialId : el?.item?.customId]
            ]
          }
          styles={{
            backgroundColor:
              el.item.id === formField?.blinkVariationsTab ? "#ECF5F0" : null,
            transition: "all 1s ease",
          }}
          handler={el.handler}
          onDelete={onDelete}
          position={indexes[isEdit ? el?.item?.initialId : el?.item?.customId]}
          handleCheckProduct={handleCheckProduct}
          selectedOnlySku={
            !formField?.variationsFields?.some(
              (v) =>
                (v?.color === "" || v?.color ? true : false) ||
                (v?.size === "" || v?.size ? true : false)
            )
          }
          isChecked={
            checkedProducts.findIndex((item) => {
              if (item?.id) {
                return item.id === el.item.id;
              }
              if (item?.customId) {
                return item.customId === el.item.customId;
              }
              return false;
            }) > -1
          }
          {...fields}
          control={control}
          setValue={setValue}
          clearErrors={clearErrors}
          sortedFieldsList={formField?.variationsFields}
          isEdit={isEdit}
          errors={errors}
          trigger={trigger}
          disabledUploadFile={disabledUploadFile}
          {...{
            saveDataAfterChangePhotos,
          }}
        />
      </>
    );
  };

  const disableIfVariationsDoNotMatch = useMemo(() => {
    if (formField?.chooseVariationsType?.color) {
      if (
        !formField?.skuVariationFields?.[0]?.sku &&
        formField?.colorVariationFields?.[0]?.color
      )
        return `Add SKU`;
      if (
        formField?.skuVariationFields?.[0]?.sku &&
        !formField?.colorVariationFields?.[0]?.color
      )
        return `Add Color`;
      if (
        !(
          formField?.skuVariationFields?.[0]?.sku &&
          formField?.colorVariationFields?.[0]?.color
        )
      )
        return `Add SKU and Color`;
    }
    if (formField?.chooseVariationsType?.size) {
      if (
        !formField?.skuVariationFields?.[0]?.sku &&
        formField?.sizeVariationFields?.[0]?.size
      )
        return "Add SKU";
      if (
        formField?.skuVariationFields?.[0]?.sku &&
        !formField?.sizeVariationFields?.[0]?.size
      )
        return "Add Size";
      if (
        !(
          formField?.skuVariationFields?.[0]?.sku &&
          formField?.sizeVariationFields?.[0]?.size
        )
      )
        return "Add SKU and Size";
    }
    if (!formField?.skuVariationFields?.[0]?.sku) return "Add SKU";
    return false;
  }, [
    formField?.chooseVariationsType?.color,
    formField?.chooseVariationsType?.size,
    formField?.colorVariationFields,
    formField?.sizeVariationFields,
    formField?.skuVariationFields,
  ]);

  const setTitleTooltipAddBtn = useMemo(() => {
    if (disableIfVariationsDoNotMatch) return disableIfVariationsDoNotMatch;

    if (uniqueFailed)
      return `You've already used the option value ${
        uniqueFailed && uniqueFailed.join(", ")
      }`;
    return "";
  }, [disableIfVariationsDoNotMatch, uniqueFailed]);

  const hasAnyCheckedActivatedProduct = useMemo(
    () => checkedProducts?.some((p) => p?.status === "ACTIVE"),
    [checkedProducts]
  );

  const hasAnyCheckedDeactivatedProduct = useMemo(
    () => checkedProducts?.some((p) => p?.status === "INACTIVE_FOR_CUSTOMERS"),
    [checkedProducts]
  );

  const disabledActiveMenuItem = useMemo(() => {
    if (checkedProducts.length < 1) return true;

    if (hasAnyCheckedActivatedProduct && !hasAnyCheckedDeactivatedProduct)
      return true;
  }, [
    checkedProducts.length,
    hasAnyCheckedActivatedProduct,
    hasAnyCheckedDeactivatedProduct,
  ]);

  const disabledDeactivateForCustomerMenuItem = useMemo(() => {
    if (checkedProducts.length < 1) return true;

    if (!hasAnyCheckedActivatedProduct && hasAnyCheckedDeactivatedProduct)
      return true;
  }, [
    checkedProducts.length,
    hasAnyCheckedActivatedProduct,
    hasAnyCheckedDeactivatedProduct,
  ]);

  return (
    <>
      <Grid
        sx={{
          paddingX: "34px",
          paddingBottom: "34px",
        }}
        container
      >
        <Grid xs={12} item>
          {/* variations type */}
          <Box
            sx={{
              marginTop: "20px",
              display: "flex",
              gap: 3,
              alignItems: "center",
              position: "relative",
            }}
          >
            <Typography
              sx={{
                fontSize: 15,
                position: "absolute",
                top: -18,
              }}
              color="error"
            >
              {errors.variationsFields?.message}
            </Typography>
            <Typography
              sx={{
                fontSize: 15,
                color: "#707070",
              }}
            >
              Choose variation type:
            </Typography>
            <Controller
              render={({ field }) => (
                <LargeCheckbox
                  checked={formField?.chooseVariationsType.sku}
                  formSx={{ mr: 0 }}
                  disabled
                  label={
                    <Typography
                      sx={{
                        ml: "-4px",
                        color: "#B5B5AC",
                      }}
                      fontSize="12px"
                      fontWeight="400"
                    >
                      SKU
                    </Typography>
                  }
                  checkedColor="#D5D9D9"
                  size={24}
                  {...field}
                />
              )}
              name="chooseVariationsType[sku]"
              control={control}
            />
            <Controller
              render={({ field }) => (
                <LargeCheckbox
                  adminIsAllowed
                  checked={formField?.chooseVariationsType.color}
                  formSx={{ mr: 0 }}
                  checkedColor={
                    isEdit
                      ? "#D5D9D9"
                      : formField?.chooseVariationsType?.size ||
                        !!formField?.variationsFields?.length
                      ? "#D5D9D9"
                      : "#47A06D"
                  }
                  disabled={
                    isEdit
                      ? true
                      : formField?.chooseVariationsType?.size ||
                        !!formField?.variationsFields?.length
                  }
                  label={
                    <Typography
                      sx={{
                        ml: "-4px",
                        color:
                          formField?.chooseVariationsType?.size ||
                          formField?.variationsFields?.length
                            ? "#B5B5AC"
                            : "groundLighter.main",
                      }}
                      color="groundLighter.main"
                      fontSize="12px"
                      fontWeight="400"
                    >
                      Color
                    </Typography>
                  }
                  size={24}
                  {...field}
                  onChange={() => {
                    if (field.value) {
                      return handleCheckClick(
                        { colorVariationFields: [{ color: "" }] },
                        field
                      );
                    }
                    setValue(field.name, !field.value);
                  }}
                />
              )}
              name="chooseVariationsType[color]"
              control={control}
            />

            <Controller
              render={({ field }) => (
                <LargeCheckbox
                  adminIsAllowed
                  checked={formField?.chooseVariationsType.size}
                  formSx={{ mr: 0 }}
                  checkedColor={
                    isEdit
                      ? "#D5D9D9"
                      : formField?.chooseVariationsType?.color ||
                        !!formField?.variationsFields?.length
                      ? "#D5D9D9"
                      : "#47A06D"
                  }
                  disabled={
                    isEdit
                      ? true
                      : formField?.chooseVariationsType?.color ||
                        !!formField?.variationsFields?.length
                  }
                  label={
                    <Typography
                      sx={{
                        ml: "-4px",
                        color:
                          formField?.chooseVariationsType?.color ||
                          formField?.variationsFields?.length
                            ? "#B5B5AC"
                            : "groundLighter.main",
                      }}
                      color="groundLighter.main"
                      fontSize="12px"
                      fontWeight="400"
                    >
                      Size
                    </Typography>
                  }
                  size={24}
                  {...field}
                  onChange={() => {
                    if (field.value) {
                      return handleCheckClick(
                        { sizeVariationFields: [{ size: "" }] },
                        field
                      );
                    }
                    setValue(field.name, !field.value);
                  }}
                />
              )}
              name="chooseVariationsType[size]"
              control={control}
            />
          </Box>
        </Grid>
        {/* texts */}
        <Grid xs={12} item>
          <Box>
            <Typography fontSize="13px" color="#707070">
              List all your variants for the variation types below.
            </Typography>
          </Box>
          <Box>
            <Typography
              fontSize="13px"
              fontWeight="400"
              color="greySecondary.main"
            >
              For the fields below, list the variations that exist for your
              products.
            </Typography>
          </Box>
        </Grid>
        {/* SKU inputs */}
        <Grid xs={12} item>
          <Box
            sx={{
              marginTop: 2,
              display: formField.chooseVariationsType.sku ? "block" : "none",
            }}
          >
            <Typography fontSize="13px" color="#707070">
              SKU
            </Typography>
            <Box display="flex">
              <InputsVariations
                nameVariation="skuVariationFields"
                valueName="sku"
                register={register}
                control={control}
              />
            </Box>
          </Box>
        </Grid>
        {/* color inputs */}
        <Grid xs={12} item>
          <Box
            sx={{
              marginTop: 2,
              display: formField.chooseVariationsType.color ? "block" : "none",
            }}
          >
            <Typography fontSize="13px" color="#707070">
              Color
            </Typography>
            <Box display="flex">
              <InputsVariations
                nameVariation="colorVariationFields"
                valueName="color"
                register={register}
                control={control}
              />
            </Box>
          </Box>
        </Grid>
        {/* size inputs */}
        <Grid xs={12} item>
          <Box
            sx={{
              marginTop: 2,
              display: formField.chooseVariationsType.size ? "block" : "none",
            }}
          >
            <Typography fontSize="13px" color="#707070">
              Size
            </Typography>
            <Box display="flex">
              <InputsVariations
                nameVariation="sizeVariationFields"
                valueName="size"
                register={register}
                control={control}
              />
            </Box>
          </Box>
        </Grid>
        <Grid xs={12} item>
          <Divider sx={{ marginY: 2, marginLeft: "1px" }} width="257px" />
          <Box ml="1px" display="flex" gap="17px">
            <StyledButton
              variant="outlined"
              label="Actions"
              className={classes.actionsButton}
              onClick={handleClickOnBulkBtn}
              endIcon={anchorEl ? <ExpandLess /> : <ExpandMore />}
            />
            <StyledMenu
              sx={{ paddingY: 0 }}
              anchorEl={anchorEl}
              isOpen={openBulkList}
              handleClose={handleCloseBulkBtn}
            >
              <MenuItem
                disabled={checkedProducts.length < 1}
                onClick={(e) => {
                  handleCloseBulkBtn(e);
                  handleBulkDeletePhotos(e);
                }}
              >
                <Typography sx={{ color: "#3F3F3F", fontSize: "12px" }}>
                  Remove images
                </Typography>
              </MenuItem>
              <MenuItem
                disabled={checkedProducts.length < 1}
                onClick={(e) => {
                  dispatch(setFormChangedAction(false));
                  dispatch(setEditTypeAction("", !isEdit));
                  handleCloseBulkBtn(e);
                  handleBulkDeleteVariants();
                }}
              >
                <Typography sx={{ color: "#3F3F3F", fontSize: "12px" }}>
                  Delete variants
                </Typography>
              </MenuItem>

              <MenuItem
                disabled={disabledActiveMenuItem}
                onClick={(e) => handleSetActiveForCustomers(e, "ACTIVE")}
              >
                <Typography sx={{ color: "#3F3F3F", fontSize: "12px" }}>
                  Activate for customers
                </Typography>
              </MenuItem>

              <MenuItem
                disabled={disabledDeactivateForCustomerMenuItem}
                onClick={(e) =>
                  handleSetActiveForCustomers(e, "INACTIVE_FOR_CUSTOMERS")
                }
              >
                <Typography sx={{ color: "#3F3F3F", fontSize: "12px" }}>
                  Deactivate for customers
                </Typography>
              </MenuItem>
            </StyledMenu>
            <StyledTooltip
              placement="top"
              arrow
              title={setTitleTooltipAddBtn}
              disableHoverListener={
                !(!!disableIfVariationsDoNotMatch || uniqueFailed)
              }
            >
              <Box>
                <Button
                  sx={{ width: "120px", padding: 0, height: "28px" }}
                  variant="contained"
                  onClick={handleAddVariations}
                  disabled={!!disableIfVariationsDoNotMatch || uniqueFailed}
                >
                  {isEdit ? "Update Variations" : "Add Variations"}
                </Button>
              </Box>
            </StyledTooltip>
          </Box>
        </Grid>
        <Grid sx={{ mt: 2 }} xs={12} item>
          {!!fields?.length && (
            <VariationsTabHeaderList
              checkAllProducts={checkAllProducts}
              allChecked={allProductsChecked}
              control={control}
              isEdit={isEdit}
              colorColumn={
                formField?.variationsFields?.some((v) =>
                  v?.color === "" || v?.color ? true : false
                )
                // || formField.chooseVariationsType?.color
              }
              sizeColumn={
                formField?.variationsFields?.some((v) =>
                  v?.size === "" || v?.size ? true : false
                )
                // || formField.chooseVariationsType?.size
              }
            />
          )}
          {!isEdit ? (
            <>
              {sortedFieldsList?.map((el) => {
                return (
                  <VariationsTabItemList
                    key={el.id || el.customId}
                    product={
                      formField.variationsFields[
                        indexes[isEdit ? el?.initialId : el?.customId]
                      ]
                    }
                    onDelete={onDelete}
                    position={indexes[isEdit ? el?.initialId : el?.customId]}
                    handleCheckProduct={handleCheckProduct}
                    selectedOnlySku={
                      !formField?.variationsFields?.some(
                        (v) =>
                          (v?.color === "" || v?.color ? true : false) ||
                          (v?.size === "" || v?.size ? true : false)
                      )
                    }
                    isChecked={
                      checkedProducts.findIndex((item) => {
                        if (item?.id) {
                          return item.id === el.id;
                        }
                        if (item?.customId) {
                          return item.customId === el.customId;
                        }
                      }) > -1
                    }
                    {...fields}
                    control={control}
                    setValue={setValue}
                    clearErrors={clearErrors}
                    sortedFieldsList={sortedFieldsList}
                    isEdit={isEdit}
                    errors={errors}
                    trigger={trigger}
                    disabledUploadFile={disabledUploadFile}
                    saveDataAfterChangePhotos={() => success("Photo added")}
                  />
                );
              })}
            </>
          ) : (
            <Nestable
              items={formField?.variationsFields}
              handler={<ReorderBurgerHandler />}
              renderItem={renderItem}
              maxDepth={0}
              onChange={({ items }) => {
                const preparedItems = items.map((v) => ({
                  // It prevents a bug with a barcode after rearranging the list. SD-6581
                  ...v,
                  barcode: v?.barcode || "",
                }));

                setValue("variationsFields", preparedItems);
              }}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};
VariationsTab.propTypes = {
  control: object,
  isEdit: bool,
  setValue: func,
  setError: func,
  errors: object,
  clearErrors: func,
  register: func,
  reset: func,
  saveDataAfterChangePhotos: func,
  saveDataAfterChangeVariations: func,
  trigger: func,
  disabledUploadFile: array,
};

export default VariationsTab;
