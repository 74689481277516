import { number } from "prop-types";
import { Box, Typography } from "@mui/material";
import { separateNumWithComma } from "helpers/helpers";

export const RevenueBlock = ({ revenueCount }) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Typography
        variant="caption"
        color="groundLight"
        sx={{ display: "inline-block", color: "#1C1C19" }}
        noWrap
      >
        ${separateNumWithComma(revenueCount) || 0}
      </Typography>
    </Box>
  );
};

RevenueBlock.propTypes = {
  revenueCount: number,
};
RevenueBlock.defaultProps = { revenueCount: 0 };
