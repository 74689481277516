import { func } from "prop-types";
import { Box, IconButton } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";

export const InfiniteLoadMoreBtn = ({ onClick }) => {
  return (
    <Box display="flex" justifyContent="center">
      <IconButton aria-label="load more" onClick={onClick}>
        <DownloadIcon />
      </IconButton>
    </Box>
  );
};

InfiniteLoadMoreBtn.propTypes = { onClick: func.isRequired };
InfiniteLoadMoreBtn.defaultProps = { onClick: () => {} };
