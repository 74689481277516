import { Grid, Typography } from "@mui/material";
import { string } from "prop-types";
import useStyles from "./styles";

const ProductAccordionHeader = ({ total }) => {
  const classes = useStyles();

  return (
    <Grid container columns={14} className={classes.productHeaderContainer}>
      <Grid item xs={4.1} className={classes.productNameWrapper}>
        <Typography noWrap>Product</Typography>
      </Grid>
      <Grid item xs={1.7} className={classes.productPriceWrapper}>
        <Typography noWrap>Unit price</Typography>
      </Grid>
      <Grid className={classes.productPriceWrapper} item xs={1.7}>
        <Typography noWrap>Total {total?.toLowerCase()}</Typography>
      </Grid>
      <Grid item xs={1.6}>
        <Typography noWrap>Promotion</Typography>
      </Grid>
      <Grid item xs={2.6}>
        <Typography noWrap>Marketing Materials</Typography>
      </Grid>
      <Grid item xs={2.3}>
        <Typography noWrap>Reorder Required</Typography>
      </Grid>
    </Grid>
  );
};

ProductAccordionHeader.propTypes = { total: string };

export default ProductAccordionHeader;
