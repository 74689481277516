export const defaultFilters = {
  manufacturer_id: "",
  category_id: "",
  tag_ids: "",
};

export const getFilterSwitches = (hasManufacturer) => {
  const manufacturerSwitch = {
    value: "manufacturer_id",
    label: "Manufacturer",
    type: "select",
    checked: false,
    selectLabel: "Select Manufacturer",
  };

  const otherSwitches = [
    {
      value: "category_id",
      label: "Category",
      type: "categories",
      checked: false,
      selectLabel: "Search product categories",
    },
    {
      value: "tag_ids",
      label: "Tags",
      type: "select",
      checked: false,
      selectLabel: "Select Tag",
    },
  ];
  if (hasManufacturer) return otherSwitches;
  return [manufacturerSwitch, ...otherSwitches];
};
