import { bool, string, number } from "prop-types";
import { Box, Typography } from "@mui/material";

export const StepItem = ({ step, name, isActive }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 0.8,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "36px",
          minWidth: "36px",
          height: "36px",
          bgcolor: isActive ? "#409A65" : "#CCC",
          borderRadius: "50%",
        }}
      >
        <Typography sx={{ fontSize: 20, color: "#FFF" }}>{step}</Typography>
      </Box>
      <Typography sx={{ fontSize: 16, color: "#000" }}>{name}</Typography>
    </Box>
  );
};

StepItem.propTypes = {
  step: number,
  name: string,
  isActive: bool,
};
