export const ACTIVE_TABS = [
  {
    name: "Active",
    value: false,
  },
  {
    name: "Inactive",
    value: true,
  },
];
