import { useMemo, useState } from "react";
import { func, number } from "prop-types";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { SearchIcon } from "../../../../../components/Icons";
import { StyledTooltip } from "../../../../../components";
import { useAdmin } from "helpers/helpers";

export const TerritoryItemUncategorized = ({
  customersWithoutTerritory,
  handleSearchTerritory,
}) => {
  const isAdmin = useAdmin();
  const [openTooltip, setOpenTooltip] = useState(false);
  const hasAnyCustomers = useMemo(
    () => customersWithoutTerritory === 0,
    [customersWithoutTerritory]
  );

  return (
    <Grid sx={{ marginTop: "-1px" }} container>
      <Grid
        sx={{
          px: "32px",
          border: "1px solid #CCCCCC",
          backgroundColor: "white",
          height: "42px",
          borderRadius: "4px 4px 0 0",
        }}
        container
        justifyContent="space-between"
        item
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexGrow: 1,
            gap: 2,
          }}
        >
          <Box
            sx={{
              width: "6px",
              height: "102%",
              marginLeft: "-36px",
              borderRadius: "0 0 0 4px",
              marginTop: "-1px",
            }}
          ></Box>
          <Box>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 700,
              }}
              color="primary"
            >
              Uncategorized
            </Typography>
          </Box>
        </Box>
        <StyledTooltip
          sx={{ textAlign: "center" }}
          title={
            "This territory has no customers or representatives assigned yet"
          }
          placement="top"
          arrow
          open={openTooltip && hasAnyCustomers}
          onClose={() => setOpenTooltip(false)}
          onOpen={() => setOpenTooltip(true)}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton
              disabled={isAdmin || hasAnyCustomers}
              onClick={() => handleSearchTerritory("Uncategorized")}
            >
              <SearchIcon />
            </IconButton>
          </Box>
        </StyledTooltip>
      </Grid>
    </Grid>
  );
};
TerritoryItemUncategorized.propTypes = {
  handleSearchTerritory: func,
  customersWithoutTerritory: number,
};
TerritoryItemUncategorized.defaultProps = {
  handleSearchTerritory: () => {},
  customersWithoutTerritory: 0,
};

export default TerritoryItemUncategorized;
