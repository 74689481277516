import { useCallback, useEffect, useState } from "react";
import { getTagsService } from "services/tags";
import { SCROLL_LIMIT } from "utils/constants";
import { error } from "utils/notifications";

const init = {
  loading: false,
  list: [],
  count: 0,
  firstFetch: false,
  searcheble: false
};

export const TAG_TYPES = {
  customer: "customer",
  product: "product",
  order: "order",
};

export const useTagsActions = ({ type, params, open = true }) => {
  const [tagsState, setTagsState] = useState(init);

  const [tagsParams, setTagsParams] = useState({
    page: 1,
    limit: SCROLL_LIMIT,
    ...params,
  });
  const handleFetchTags = useCallback(
    async (page) => {
      setTagsState((prev) => ({ ...prev, loading: true }));
      try {
        const res = await getTagsService(type, {
          ...tagsParams,
          page: page || tagsParams.page,
        });

        const rows = res?.rows || [];
        const count = res?.count || 0;

        setTagsState((prev) => ({
          ...prev,
          list: page > 1 ? [...prev.list, ...rows] : rows,
          count: count,
          searcheble: !prev.searcheble ? count >= 10 : prev.searcheble
        }));

        if (page) setTagsParams((prev) => ({ ...prev, page }));
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        error(err?.response?.data?.message || "Something went wrong.");
      } finally {
        setTagsState((prev) => ({ ...prev, loading: false }));
      }
    },
    [tagsParams, type]
  );

  useEffect(() => {
    if (open) {
      handleFetchTags();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, tagsParams.search]);

  return { ...tagsState, setTagsParams, page: tagsParams.page, handleFetchTags };
};
