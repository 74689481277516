import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  endLoadingSavedFilters,
  startLoadingSavedFilters,
  updateSavedFiltersAction,
} from "redux/actions/savedFilters";
import { checkFilterNameService } from "services/savedFilters";
import { error } from "utils/notifications";

export const useEditSavedFilter = ({
  filterForEdit,
  open,
  type,
  onClose,
  onFilterApply,
  selectedFilterId,
}) => {
  const dispatch = useDispatch();
  const [viewName, setViewName] = useState("");

  const onChange = (e) => setViewName(e.target.value);

  const onEditFilter = (updatedFilter) => {
    dispatch(
      updateSavedFiltersAction({
        type,
        id: filterForEdit.id,
        filter: updatedFilter,
        onSuccess: () => {
          if (selectedFilterId === filterForEdit.id)
            onFilterApply(updatedFilter.filters);
          onClose();
        },
      })
    );
  };

  const isNameChanged = useMemo(
    () => filterForEdit.name === viewName,
    [filterForEdit.name, viewName]
  );

  const onNameCheckSave = async (filters) => {
    dispatch(startLoadingSavedFilters());
    try {
      const res = await checkFilterNameService(viewName);
      if (res.available) {
        const { id, distributorId, ...rest } = filterForEdit || {};

        onEditFilter({
          ...rest,
          name: viewName,
          filters,
        });

        setViewName("");
        return;
      }
      return error("Name is not available");
    } catch (err) {
      error(err.message);
      dispatch(endLoadingSavedFilters());
    }
  };

  const onSave = async (_, filters) => {
    if (filterForEdit.name !== viewName) return onNameCheckSave(filters);

    const { id, distributorId, ...rest } = filterForEdit || {};

    return onEditFilter({
      ...rest,
      name: viewName,
      filters,
    });
  };

  useEffect(() => {
    if (open) {
      setViewName(filterForEdit.name);
    }
  }, [filterForEdit.name, open]);

  return { onChange, viewName, onSave, isNameChanged };
};
