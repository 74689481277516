import {
  START_LOADING_ORDER_DIRECTS,
  END_LOADING_ORDER_DIRECTS,
  GET_INVITES_LIST,
  GET_INVITES_COUNT,
  GET_RECEIVED_LIST,
  GET_RECEIVED_COUNT,
  GET_NOTIFICATIONS_COUNT,
  GET_NOTIFICATION_RECEIVED_CUSTOMERS,
} from "../actions/order-directs";

const initialState = {
  loading: false,
  hasAnyInvites: null,
  invitesList: [],
  invitesCount: 0,
  hasAnyReceived: null,
  receivedList: [],
  receivedCount: 0,
  notificationsCount: 0,
  notificationReceivedCustomers: [],
};

const chatReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_INVITES_LIST:
      return { ...state, invitesList: payload };
    case GET_INVITES_COUNT: {
      if (!state.hasAnyInvites) {
        return { ...state, invitesCount: payload, hasAnyInvites: !!payload };
      }
      return { ...state, invitesCount: payload };
    }

    case GET_RECEIVED_LIST:
      return { ...state, receivedList: payload };
    case GET_RECEIVED_COUNT: {
      if (!state.hasAnyReceived) {
        return { ...state, receivedCount: payload, hasAnyReceived: !!payload };
      }
      return { ...state, receivedCount: payload };
    }

    case GET_NOTIFICATION_RECEIVED_CUSTOMERS:
      return { ...state, notificationReceivedCustomers: payload };

    case GET_NOTIFICATIONS_COUNT:
      return { ...state, notificationsCount: payload };

    case START_LOADING_ORDER_DIRECTS:
      return { ...state, loading: true };
    case END_LOADING_ORDER_DIRECTS:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default chatReducer;
