import { useEffect, useMemo, useState } from "react";
import { bool, string, any, func, array, object, oneOf } from "prop-types";
import { Controller, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import pluralize from "pluralize";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Typography,
  Box,
} from "@mui/material";
import AddressField from "../../../CustomersPage/components/AddressField/AddressField";
import { CrossIcon, OutlinedPlusIcon } from "components/Icons";

import { TypographyAddress, StyledButton, StyledSelect } from "components";
import useStyles from "./styles";
import GoogleMap from "components/GoogleMap/GoogleMap";
import { checkCustomerAddressService } from "services/customers";
import { useDebounce } from "helpers/hooks";
import DuplicatePopup from "../../../CustomersPage/components/DuplicatePopup";
import { useDuplicates } from "./useDuplicates";
import { createSelector } from "reselect";
import { currentUserSelector } from "redux/selectors/auth";
import { useSelector } from "react-redux";

const selector = createSelector(currentUserSelector, (currentUser) => ({
  currentUser,
}));

const EditCustomerPopup = ({
  isEdit,
  isOpen,
  type,
  data,
  handleClose,
  handleSave,
  contacts,
  handleAddNewContact,
  customer,
  loading,
  withoutAbsoluteLabel,
  env,
}) => {
  const { currentUser } = useSelector(selector);
  const classes = useStyles();

  const validationSchema = () =>
    Yup.object().shape({
      data:
        type === "customer contact"
          ? Yup.number()
              .transform((v, o) => (o === "" ? null : v))
              .nullable()
              .required("This field is required")
          : Yup.object().shape({
              formatted_address: Yup.string().required(
                "This field is required"
              ),
              street: Yup.string().required("This field is required"),
              city: Yup.string().required("This field is required"),
              state: Yup.string().required("This field is required"),
              zip: Yup.string().required("This field is required"),
            }),
    });

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      data: {
        formatted_address: "",
        street: "",
        city: "",
        state: "",
        zip: "",
        appartement: "",
      },
      proceededWithDuplicatedFields: false,
    },
    resolver: yupResolver(validationSchema()),
  });

  const formField = useWatch({ control });

  const addressDebounced = useDebounce(formField.data?.formatted_address);
  const {
    deleteLoading,
    duplicateOpen,
    formatEditType,
    checkedCustomers,
    deleteDublicates,
    setDuplicateOpen,
    addressDuplicates,
    handleCheckCustomers,
    setAddressDuplicates,
    handleProceedWithDuplicate,
    handleSendCheckedCustomers,
  } = useDuplicates(
    clearErrors,
    errors,
    setValue,
    setError,
    type,
    formField,
    addressDebounced
  );

  useEffect(() => {
    if (type === "customer contact")
      return reset({
        data: "",
      });
    reset({
      data: {
        ...data,
        formatted_address: "",
        street: "",
        city: "",
        state: "",
        zip: "",
        appartement: "",
      },
      proceededWithDuplicatedFields:
        customer?.proceededWithDuplicatedFields || false,
    });
    setToggleField("");
    setAddressDuplicates([]);
  }, [
    reset,
    type,
    isOpen,
    data,
    setAddressDuplicates,
    customer?.proceededWithDuplicatedFields,
  ]);

  const { heightOfGoogleAddresses = 0 } = formField || {};

  const [toggleField, setToggleField] = useState("");

  useEffect(() => {
    if (addressDebounced && type !== "customer contact" && !loading)
      checkCustomerAddressService({
        formatted_address: addressDebounced,
        type: type.split(" ")[0],
        proceeded_with_duplicated_fields:
          formField.proceededWithDuplicatedFields,
      }).then((res) => {
        const customers = res?.customers?.filter(
          (resCustomer) => resCustomer.id !== customer?.id
        );
        setAddressDuplicates(customers);
        if (!customers.length) return clearErrors("data.formatted_address");

        setError("data.formatted_address", {
          type: "duplicate",
          message: `${pluralize("duplicate", customers.length, true)} found`,
        });
      });
  }, [
    addressDebounced,
    setError,
    customer?.id,
    type,
    clearErrors,
    loading,
    setAddressDuplicates,
    formField.proceededWithDuplicatedFields,
  ]);

  const handleOpenDuplicates = () => {
    setDuplicateOpen(true);
  };

  const onError = () => {
    if (errors.data?.formatted_address?.type === "duplicate")
      setError("data.formatted_address", {
        ...errors.data?.formatted_address,
      });
  };

  const setMaxHeight = useMemo(() => {
    if (toggleField === "map") {
      return "478px";
    }

    if (toggleField === "details") {
      return heightOfGoogleAddresses < 178
        ? "0px"
        : `${heightOfGoogleAddresses - 160}px`;
    }

    if (heightOfGoogleAddresses > 0) {
      return `${heightOfGoogleAddresses}px`;
    }

    return "0px";
  }, [heightOfGoogleAddresses, toggleField]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth="631px"
      PaperProps={{
        sx: { maxHeight: "calc(100vh - 11px)" },
      }}
    >
      {type !== "customer contact" && (
        <DuplicatePopup
          isOpen={duplicateOpen}
          handleClose={() => setDuplicateOpen(false)}
          handleProceed={handleProceedWithDuplicate}
          showCheckboxes={
            addressDuplicates.length > 1 && !currentUser.quickBooksTokens
          }
          duplicates={addressDuplicates}
          showDeleteBtn={!currentUser.quickBooksTokens}
          checkedCustomers={checkedCustomers}
          setCheckedState={handleCheckCustomers}
          handleDelete={deleteDublicates}
          loading={deleteLoading}
          type={formatEditType(type)}
          disabledActionBtn={
            addressDuplicates.length > 1 && !checkedCustomers.length
          }
        />
      )}
      <form
        id="edit-customer-order"
        onSubmit={handleSubmit((data) => {
          const preparedData = { ...data };

          if (env === "route") {
            const formattedShippingAddress = data?.data?.formatted_address;
            const formattedBillingAddress =
              customer?.billingAddress?.formatted_address;

            if (formattedShippingAddress === formattedBillingAddress) {
              preparedData.billingAddress = { ...data?.data };
            }
          }

          return handleSave(
            preparedData,
            type,
            errors?.data,
            setError,
            handleSendCheckedCustomers
          );
        }, onError)}
      >
        <DialogTitle className={classes.dialogTitleBlock}>
          <Typography className={classes.dialogTitle}>Edit {type}</Typography>
          <IconButton onClick={handleClose}>
            <CrossIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Typography className={classes.currentText}>
            Current {type}
          </Typography>
          {["shipping address", "billing address"].includes(type) ? (
            <TypographyAddress
              address={data?.formatted_address}
              className={classes.data}
            />
          ) : (
            <Box className={classes.contactItem}>
              <Box className={classes.contactTitle}>
                <Typography>
                  {data?.name} | {data?.role}
                </Typography>
                {data?.defaultContact && (
                  <Typography className="default">primary</Typography>
                )}
              </Box>
              <Typography>{data?.phone}</Typography>
              <Typography>{data?.email}</Typography>
            </Box>
          )}
          {type !== "customer contact" && (
            <Typography className={classes.currentText} mt="32px">
              New {type}
            </Typography>
          )}
          {["shipping address", "billing address"].includes(type) ? (
            <>
              <AddressField
                isEdit={isEdit}
                duplicates={addressDuplicates}
                handleOpenDuplicates={handleOpenDuplicates}
                control={control}
                setValue={setValue}
                fieldName="data"
                curtainProps={{ curtainHeight: "198px" }}
                withoutAbsoluteLabel={withoutAbsoluteLabel}
                address={formField.data}
                addressToggled={toggleField === "details"}
                wrapperProps={{ mt: "16px" }}
                onMapOpen={() => {
                  if (toggleField === "map") setToggleField("");
                  else setToggleField("map");
                }}
                typingTrigger={() => {
                  if (formField.proceededWithDuplicatedFields) {
                    setValue("proceededWithDuplicatedFields", false);
                  }
                }}
                mapToggled={toggleField === "map"}
                autocompleteProps={{
                  placeholder: "Address",
                  InputProps: {
                    className: classes.textInput,
                    sx: {
                      height: "32px",
                      fontSize: "12px",
                      "&:first-child": {
                        "& fieldset": {
                          borderRadius: "4px 0 0 4px",
                        },
                      },
                    },
                  },
                  InputLabelProps: {
                    className: classes.inputLabel,
                  },
                  noErrorMessage: false,
                }}
                onToggle={() => {
                  if (toggleField === "details") setToggleField("");
                  else setToggleField("details");
                }}
                error={!!errors.data}
                typeSetValue="heightOfGoogleAddresses"
              />
              <Box
                sx={{
                  maxHeight: setMaxHeight,
                  overflow: "hidden",
                  transition: "all 0.5s ease",
                  opacity: toggleField === "map" ? 1 : 0,
                  pointerEvents: !toggleField === "map" && "none",
                  pb: 1,
                }}
                height={"478px"}
              >
                <GoogleMap
                  address={formField.data}
                  handleSetAddress={(billingAddress) =>
                    setValue("data", billingAddress)
                  }
                  customer={customer}
                />
              </Box>
            </>
          ) : (
            <Controller
              render={({ field, fieldState: { error } }) => (
                <StyledSelect
                  fullWidth
                  noErrorMessage
                  label="New customer contact"
                  className={classes.contactsSelect}
                  error={error?.message || ""}
                  formSx={{ mt: "20px" }}
                  labelSx={{ fontSize: "13px" }}
                  {...field}
                >
                  {contacts.map((contact) => (
                    <MenuItem key={contact.id} value={contact.id}>
                      {contact.name} | {contact.role}
                    </MenuItem>
                  ))}
                  <MenuItem
                    onClick={handleAddNewContact}
                    value=""
                    sx={{
                      color: "#409A65",
                      fontSize: "9px",
                      "& svg": {
                        mr: "8.5px",
                      },
                    }}
                  >
                    <OutlinedPlusIcon /> Add New
                  </MenuItem>
                </StyledSelect>
              )}
              name="data"
              control={control}
            />
          )}
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <StyledButton
            label="Cancel"
            color="cancel"
            variant="outlined"
            fontSize="10px"
            onClick={handleClose}
            className={classes.actionButton}
          />
          <StyledButton
            disabled={loading}
            label="Save"
            variant="contained"
            fontSize="10px"
            color="primary2"
            type="submit"
            form="edit-customer-order"
            className={classes.actionButton}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

EditCustomerPopup.propTypes = {
  isEdit: bool,
  isOpen: bool,
  type: string,
  data: any,
  handleClose: func,
  handleSave: func,
  contacts: array,
  handleAddNewContact: func,
  customer: object,
  loading: bool,
  withoutAbsoluteLabel: bool,
  env: oneOf(["route"]),
};

EditCustomerPopup.defaultProps = {
  isOpen: false,
  type: "billing address",
  data: "",
  contacts: [],
  loading: false,
  withoutAbsoluteLabel: false,
};

export default EditCustomerPopup;
