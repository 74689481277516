import { useMemo } from "react";
import { array, func, object, number, bool, string } from "prop-types";
import { useWatch } from "react-hook-form";

import { OrderItem } from "./OrderItem";
import { InfiniteScrollWrapper } from "components";

import { cl } from "../NotificationsMenu.styles";
import { TABS } from "../NotificationsMenu.constants";

import { Box, CircularProgress, Typography } from "@mui/material";

export const ItemsList = ({
  invitesList,
  receivedList,
  control,
  handleClickReview,
  handleClickResend,
  invitesCount,
  receivedCount,
  onFetchList,
  invitesloading,
  hasAnyInvites,
  hasAnyReceived,
  loading,
  handleClose,
}) => {
  const { currentTab } = useWatch({ control });

  const showTabs = useMemo(() => {
    if (currentTab === TABS[0]) return hasAnyInvites;
    if (currentTab === TABS[1]) return hasAnyReceived;
  }, [currentTab, hasAnyInvites, hasAnyReceived]);

  return (
    <Box sx={cl.list.wrapper}>
      {showTabs ? (
        <>
          {invitesloading && (
            <CircularProgress
              sx={{ ml: "48%", mt: "40%", position: "absolute" }}
              size="20px"
            />
          )}
          <InfiniteScrollWrapper
            dataLength={
              currentTab === TABS[0]
                ? invitesList?.length
                : receivedList?.length
            }
            loading={invitesloading}
            hasMore={
              currentTab === TABS[0]
                ? invitesList.length < invitesCount
                : receivedList?.length < receivedCount
            }
            next={() => onFetchList(currentTab)}
            id="order-direct-scroll-popup"
          >
            {currentTab === TABS[0] &&
              invitesList?.map((order) => (
                <OrderItem
                  key={`${order?.email}-${order?.updatedAt}`}
                  currentTab={currentTab}
                  item={order}
                  onClickReview={handleClickReview}
                  onClickResend={handleClickResend}
                  loading={loading}
                  handleClose={handleClose}
                />
              ))}
            {currentTab === TABS[1] &&
              receivedList?.map((order) => (
                <OrderItem
                  key={order?.appCustomer?.id}
                  currentTab={currentTab}
                  item={order}
                  onClickReview={handleClickReview}
                  onClickResend={handleClickResend}
                  loading={loading}
                  handleClose={handleClose}
                />
              ))}
          </InfiniteScrollWrapper>
        </>
      ) : (
        <Box sx={cl.list.emptyWrapper}>
          <Typography sx={cl.list.empty}>
            {currentTab === TABS[0] && "No customers invited"}
            {currentTab === TABS[1] && "Nothing received yet"}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

ItemsList.propTypes = {
  invitesList: array,
  receivedList: array,
  control: object,
  handleClickReview: func,
  handleClickResend: func,
  invitesCount: number,
  receivedCount: number,
  onFetchList: func,
  invitesloading: bool,
  hasAnyInvites: bool,
  hasAnyReceived: bool,
  loading: string,
  handleClose: func,
};
