/* eslint-disable no-useless-escape */
import * as Yup from "yup";

export const validationSchema = () =>
  Yup.object().shape({
    restock: Yup.boolean(),
    sendNotification: Yup.boolean(),
    paymentRefund: Yup.array().of(
      Yup.object().shape({
        amount: Yup.number()
          .typeError("Must be a number.")
          .when("totalSumToRefund", (totalSumToRefund, schema) => {
            return schema.test({
              test: (sum) =>
                !!totalSumToRefund && sum <= totalSumToRefund && sum >= 0,
              message: "Incorrect sum to refund",
            });
          }),
      })
    ),
    reason: Yup.string().trim().required(),
    otherReason: Yup.string()
      .trim()
      .when("reason", {
        is: "Other",
        then: Yup.string().required("Reason description is missing"),
      }),
  });
