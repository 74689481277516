import React from "react";
import PropTypes from "prop-types";
import { LargeCheckbox } from "components";
import { CheckLabelStyled } from "./styles";

const MapCheckLabel = ({ checked, onChange, label, disabled }) => {
  return (
    <CheckLabelStyled>
      <LargeCheckbox
        {...{ checked, onChange, label, disabled }}
        labelSx={{
          fontSize: "12px",
        }}
        formSx={{ mr: 0, ml: "-9px" }}
        size={17}
      />
    </CheckLabelStyled>
  );
};

MapCheckLabel.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

export default MapCheckLabel;
