import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  paperBlock: {
    padding: "16px 22px 16px 32px",
    height: "100%",
    flex: "1 0 45%",
    border: "1px solid #F0F0F0",
    boxShadow: "none",
  },

  mainTitle: {
    fontSize: "30px",
    fontWeight: 500,
    color: "#707070",
  },
  blockTitle: {
    fontSize: "25px",
    fontWeight: 500,
    color: "#707070",
  },

  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },

  informationHalf: {
    width: "50%",
    paddingRight: "10px",
    paddingTop: "30px",
  },

  applicationHalf: {
    width: "45%",
    paddingLeft: "10px",
    paddingTop: "30px",
    position: "relative",
  },

  blockBody: {
    display: "flex",
    justifyContent: "space-between",
  },

  textInput: {
    fontSize: "12px",
    height: "32px",
  },

  inputLabel: {
    fontSize: "12px",
    color: "#B5B5AC",
    // left: "-5px",
  },

  checkText: {
    letterSpacing: "normal",
    fontWeight: 400,
    color: "#8F8F8F",
    fontSize: "11px",
  },

  smallerButton: {
    marginLeft: "16px",
    height: "24px",
    "& > span": {
      fontSize: "11px",
    },
  },
  smallerButtonReport: {
    marginLeft: "16px",
    height: "28px",
    "& > span": {
      fontSize: "13px",
    },
  },

  tabsWrapper: {
    borderBottom: "0.5px solid #D4D4D4",
    marginLeft: "-32px",
    paddingLeft: "32px",
    paddingRight: "32px",
    marginRight: "-22px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  fieldWrapper: {
    boxShadow: "none",
    border: "0.5px solid #D5D9D9",
    marginTop: "-1px",
    height: "29px",
    borderRadius: "0 0 4px 4px",

    paddingTop: "2.5px",
    paddingLeft: "15.5px",
    overflow: "auto",
  },

  contactsPaper: {
    boxShadow: "none",
    border: "none",
    maxHeight: "calc(100vh - 300px)",
    overflowY: "overlay",
  },

  cardRoleText: {
    marginBottom: "2px",
    fontSize: "12px",
    fontWeight: 400,
    color: "#363531",
  },

  cardText: {
    fontSize: "12px",
    fontWeight: 300,
    color: "#5F6267",
  },

  cardEmailText: {
    marginTop: "-4px",
    fontSize: "12px",
    fontWeight: 300,
    color: "#5F6267",
  },
}));

export default useStyles;
