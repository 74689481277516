import { Button, CircularProgress } from "@mui/material";
import { styled } from "@mui/styles";
import { Box } from "@mui/system";
import PropTypes, { string, func, object, bool, number, any } from "prop-types";
import { forwardRef } from "react";
import { PhotoCameraIcon } from "../Icons";
import StyledButton from "../StyledButton";

const RoundedButtonContainedStyle = styled(Button)({
  padding: 0,
  borderRadius: "12px",
  fontSize: 11,
  fontWeight: 400,
  minWidth: "52px",
  textTransform: "uppercase",
});
const RoundedButtonContained = ({ label, ...props }) => {
  return (
    <RoundedButtonContainedStyle variant="contained" {...props}>
      {label}
    </RoundedButtonContainedStyle>
  );
};
RoundedButtonContained.propTypes = { label: string };
RoundedButtonContained.defaultProps = { label: "" };

const RoundedButtonStyle = styled(Button)({
  padding: 0,
  borderRadius: "12px",
  fontSize: 11,
  fontWeight: 400,
  minWidth: "52px",
  textTransform: "uppercase",
});
const RoundedButton = ({ label, ...props }) => {
  return (
    <RoundedButtonStyle color="groundLighter" {...props}>
      {label}
    </RoundedButtonStyle>
  );
};
RoundedButton.propTypes = { label: string };
RoundedButton.defaultProps = { label: "" };

const ReportButtonStyle = styled(Button)({
  padding: "0 12px",
  fontSize: 13,
  fontWeight: 400,
  color: "#47A06D",
});
const ReportButton = ({ label, ...props }) => {
  return <ReportButtonStyle {...props}>{label}</ReportButtonStyle>;
};
ReportButton.propTypes = { label: string };
ReportButton.defaultProps = { label: "" };

const ProfileButtonStyle = styled(Button)({
  // padding: "4px 30px",
  fontSize: 15,
  fontWeight: 400,
});

const ProfileButton = forwardRef(({ label, ...props }, ref) => {
  return (
    <ProfileButtonStyle ref={ref} {...props}>
      {label}
    </ProfileButtonStyle>
  );
});

ProfileButton.displayName = "ProfileButton";

ProfileButton.propTypes = { label: PropTypes.oneOfType([object, string]) };
ProfileButton.defaultProps = { label: "" };

const ExpandButtonStyle = styled(Button)({
  padding: "0 12px",
  fontSize: 16,
  fontWeight: 400,
});
const ExpandButton = ({ label, ...props }) => {
  return <ExpandButtonStyle {...props}>{label}</ExpandButtonStyle>;
};
ExpandButton.propTypes = { label: string };
ExpandButton.defaultProps = { label: "" };

const DayButton = ({ label, isChecked, handleCheckWeekday, ...props }) => {
  return (
    <Button
      sx={{ height: "40px", ml: "4px", fontSize: "14px" }}
      variant={isChecked ? "contained" : "outlined"}
      onClick={() => handleCheckWeekday(label)}
      {...props}
    >
      {label}
    </Button>
  );
};

DayButton.propTypes = {
  label: string,
  handleCheckWeekday: func,
  isChecked: bool,
};

DayButton.defaultProps = { label: "", isChecked: false };

const SquareButtonStyle = styled(Button)(({ width, height }) => ({
  padding: 0,
  borderRadius: "8px",
  border: "1px solid #D5D9D9",
  color: "#707070",
  backgroundColor: "#F0F0F0",
  fontSize: "5px",
  lineHeight: 2,
  minWidth: `${width}px`,
  minHeight: `${height}px`,
  flexDirection: "column",
  zIndex: 1,
}));
const SquareButton = ({
  label,
  width,
  height,
  iconW,
  iconH,
  children,
  styles,
  loading,
  ...props
}) => {
  return (
    <SquareButtonStyle
      sx={{ display: "flex", flexDirection: "column", ...styles }}
      variant="outlined"
      color="groundLighter"
      width={width}
      height={height}
      styles={styles}
      {...props}
    >
      {loading ? (
        <CircularProgress color="inherit" size={20} />
      ) : (
        <PhotoCameraIcon fill={"#707070"} width={iconW} height={iconH} />
      )}
      {label}
      {children}
    </SquareButtonStyle>
  );
};
SquareButton.propTypes = {
  label: string,
  width: number,
  height: number,
  iconW: number,
  iconH: number,
  children: any,
  styles: object,
  loading: bool,
};
SquareButton.defaultProps = {
  label: "Upload",
  width: 38,
  height: 38,
  iconW: 13.59,
  iconH: 11.89,
  loading: false,
};

const SearchDropDownButtons = ({ onCancel, onAdd, ...props }) => {
  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      p="7px 16px 8.4px"
      alignItems="center"
      {...props}
    >
      <StyledButton
        label="Cancel"
        sx={{ height: "27px", mr: "8px" }}
        color="cancel"
        onClick={onCancel}
        fontWeight="600"
        fontSize="10px"
      />
      <StyledButton
        label="Add"
        variant="contained"
        sx={{ height: "27px" }}
        fontWeight="600"
        fontSize="10px"
        onClick={onAdd}
      />
    </Box>
  );
};

SearchDropDownButtons.propTypes = {
  onCancel: func,
  onAdd: func,
};

export {
  RoundedButtonContained,
  RoundedButton,
  ReportButton,
  ProfileButton,
  ExpandButton,
  DayButton,
  SquareButton,
  SearchDropDownButtons,
};
