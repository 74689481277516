import axios from "axios";

export const getManufacturerDiscountsService = async (params) => {
  return await axios
    .get("/manufacturer-discounts", { params })
    .then((res) => res.data);
};

export const getManufacturerDiscountByIdService = async (id) => {
  return await axios
    .get(`/manufacturer-discounts/${id}`)
    .then((res) => res.data);
};

export const createManufacturerDiscountsService = async (data) => {
  return await axios
    .post("/manufacturer-discounts", data)
    .then((res) => res.data);
};

export const updateManufacturerDiscountsService = async (id, data) => {
  return await axios
    .put(`/manufacturer-discounts/${id}`, data)
    .then((res) => res.data);
};

export const deleteManufacturerDiscountsService = async (id) => {
  return await axios
    .delete(`/manufacturer-discounts/${id}`)
    .then((res) => res.data);
};
