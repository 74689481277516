import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  filterWrap: {
    display: "flex",
    gap: "11px",
  },
}));

export default useStyles;
