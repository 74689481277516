import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  pageWrapper: {
    padding: "15px 31px",
    display: "flex",
    justifyContent: "center",
  },

  importPage: {
    borderRadius: "8px",
    border: "1px solid #d5d9d9",
    backgroundColor: "#ffffff",
    width: "100%",
    height: "643px",
  },

  importHeader: {
    padding: "23px 30px 20px 42px",
    display: "flex",
    justifyContent: "space-between",
  },

  title: {
    fontSize: "30px",
  },

  bodyText: {
    whiteSpace: "pre-wrap",
    fontSize: "17px",
  },

  importBody: {
    padding: "13px 42px",
  },

  uploadBlock: {
    border: "1px solid #d5d9d9",
    borderRadius: "12px",
    padding: "25px 29px 19px 29px",
    width: "629px",
    marginTop: "20px",
  },

  stepIcon: {
    height: "28px",
    width: "28px",
    borderRadius: "50%",
    textAlign: "center",
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "1.8",
  },

  step: {
    // fontSize: "13px",
  },

  columnHeaders: {
    backgroundColor: "#EDEFF2",
    minHeight: "33px!important",
    borderRadius: 0,
  },
  columnHeader: {
    height: "33px!important",
    paddingLeft: "15px!important",
    "&:not(:last-of-type)": {
      borderRight: "1px solid #d5d9d9",
    },
  },

  columnSeparator: { display: "none!important" },

  columnHeaderTitleContainerContent: {
    // height: "33px",
    lineHeight: "33px",
    textTransform: "uppercase",
  },

  columnHeaderTitle: {
    height: "100%",
  },

  virtualScroller: {
    marginTop: "33px!important",
    overflow: "hidden",
  },

  gridColumnSelect: {
    fontSize: "12px",
    "& fieldset": {
      border: "none",
      // "&:not(:last-of-type)": {
      // borderRight: "1px solid #d5d9d9",
      // },
      borderRadius: 0,
    },
  },

  selectItem: {
    flex: 1,
    maxWidth: "200px !important",
    minWidth: "200px !important",
    width: "200px !important",
    "&:not(:last-of-type)": {
      borderRight: "1px solid #d5d9d9",
    },
  },

  selectsRow: {
    borderRadius: "4px 4px 0 0",
    display: "flex",
    pointerEvents: ({ sessionLoading }) => sessionLoading && "none",
  },

  gridCell: {
    minHeight: "33px!important",
    borderBottom: "none!important",
    // maxWidth: "162px!important",
    // minWidth: "161.25px!important",
    "&:not(:last-of-type)": {
      borderRight: "1px solid #d5d9d9",
      paddingLeft: "15px!important",
    },
  },

  gridRow: {
    minHeight: "33px!important",
    maxHeight: "33px!important",
    "&:nth-child(2n)": {
      backgroundColor: "#F7F7F7",
    },
  },

  lastGridRow: {
    "& .MuiDataGrid-cell": {
      borderBottomColor: "#d5d9d9!important",
      borderBottom: "1px solid #d5d9d9!important",
    },
  },

  gridRoot: {
    fontSize: "10px",
    borderRadius: "0 0 4px 4px",
    borderTop: "none",
  },

  uploadWrapper: { display: "flex", alignItems: "center", gap: "20px" },

  uploadProgress: { color: "#47A06D" },

  loaderWrapper: {
    position: "absolute",
    zIndex: 1000,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "486px",
    top: "40px",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    // left: "calc(50% - 20px)",
    // top: "calc(50% - 20px)",
  },
}));

export default useStyles;
