import React from "react";

// eslint-disable-next-line react/prop-types
export const NoteIcon = ({ color = "#ccc", size = 9.979 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 9.979 9.979"
    >
      <path
        id="Path_2986"
        data-name="Path 2986"
        d="M14.246,16.06v1.419H8.407a.907.907,0,0,1-.907-.907V8.407A.907.907,0,0,1,8.407,7.5h8.165a.907.907,0,0,1,.907.907v5.838H15.06C14.058,14.246,14.246,15.058,14.246,16.06Zm1.268-6.141a.454.454,0,0,0-.454-.454H9.919a.454.454,0,1,0,0,.907H15.06A.454.454,0,0,0,15.514,9.919ZM14.3,12.036a.454.454,0,0,0-.454-.454H9.919a.454.454,0,1,0,0,.907H13.85A.454.454,0,0,0,14.3,12.036Z"
        transform="translate(-7.5 -7.5)"
        fill={color}
      />
    </svg>
  );
};
