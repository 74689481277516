import {
  createManufacturerDiscountsService,
  deleteManufacturerDiscountsService,
  getManufacturerDiscountsService,
  updateManufacturerDiscountsService,
} from "../../services/discounts";
import { SCROLL_LIMIT, SCROLL_LIMIT_DISCOUNT } from "../../utils/constants";
import { success, error } from "../../utils/notifications";
import { setConfirmIsOpenAction, setFormChangedAction } from "./confirmDialogs";

import {
  startLoadingManufacturersDiscounts,
  endLoadingManufacturersDiscounts,
  getManufacturersDiscountsAction,
} from "./manufacturers-discounts.js";

export const GET_DISCOUNTS = "GET_DISCOUNTS";
export const START_LOADING_DISCOUNTS = "START_LOADING_DISCOUNTS";
export const END_LOADING_DISCOUNTS = "END_LOADING_DISCOUNTS";
export const CREATE_DISCOUNT = "CREATE_DISCOUNT";
export const DELETE_DISCOUNT = "DELETE_DISCOUNT";
export const UPDATE_DISCOUNT = "UPDATE_DISCOUNT";
export const GET_DISCOUNTS_COUNT = "GET_DISCOUNTS_COUNT";

const getDiscounts = (payload, tagType) => ({
  type: GET_DISCOUNTS,
  payload,
  tagType,
});

const createDiscount = (payload, tagType) => ({
  type: CREATE_DISCOUNT,
  payload,
  tagType,
});

// const updateDiscount = (payload, tagType) => ({
//   type: UPDATE_DISCOUNT,
//   payload,
//   tagType,
// });

// const deleteDiscount = (payload) => ({
//   type: DELETE_DISCOUNT,
//   payload,
// });

const getDiscountsCount = (payload) => ({
  type: GET_DISCOUNTS_COUNT,
  payload,
});

const startLoadingDiscounts = () => ({ type: START_LOADING_DISCOUNTS });
const endLoadingDiscounts = () => ({ type: END_LOADING_DISCOUNTS });

export const getDiscountsAction = () => {
  return (dispatch) => {
    dispatch(startLoadingDiscounts());
    getManufacturerDiscountsService()
      .then((res) => {
        dispatch(getDiscounts(res.rows));
        dispatch(getDiscountsCount(res.count));

        dispatch(endLoadingDiscounts());
      })
      .catch(() => {
        dispatch(endLoadingDiscounts());
        error("Something went wrong.");
      });
  };
};

export const createDiscountAction = (data, navigate) => {
  return (dispatch) => {
    dispatch(startLoadingDiscounts());
    dispatch(startLoadingManufacturersDiscounts());

    createManufacturerDiscountsService(data)
      .then((res) => {
        dispatch(setFormChangedAction(false));
        dispatch(endLoadingDiscounts());
        dispatch(endLoadingManufacturersDiscounts());
        dispatch(createDiscount(res));
        dispatch(
          getManufacturersDiscountsAction({ discount_limit: SCROLL_LIMIT })
        );
        success("Manufacturer specific discount created successfully.");
        navigate("/discounts");
      })
      .catch((err) => {
        error(err.response?.data?.message);
        dispatch(endLoadingDiscounts());
        dispatch(endLoadingManufacturersDiscounts());
      });
  };
};

export const updateDiscountAction = (id, data, navigate) => {
  return (dispatch, getState) => {
    const {
      discounts: { loading },
    } = getState();
    if (loading) return;

    dispatch(startLoadingDiscounts());
    updateManufacturerDiscountsService(id, data)
      .then(() => {
        dispatch(setFormChangedAction(false));
        dispatch(endLoadingDiscounts());
        dispatch(
          getManufacturersDiscountsAction({
            limit: SCROLL_LIMIT,
            discount_limit: SCROLL_LIMIT_DISCOUNT,
          })
        );

        if (navigate) navigate("/discounts");

        dispatch(setConfirmIsOpenAction(false));
        success("Manufacturer specific discount updated successfully.");
      })
      .catch((err) => {
        error(err.response?.data?.message);
        dispatch(endLoadingDiscounts());
      });
  };
};

export const deleteDiscountAction = (id, navigate) => {
  return (dispatch, getState) => {
    const {
      discounts: { loading },
    } = getState();
    if (loading) return;

    dispatch(startLoadingDiscounts());
    deleteManufacturerDiscountsService(id)
      .then(() => {
        dispatch(endLoadingDiscounts());
        success("Manufacturer specific discount deleted successfully.");
        dispatch(
          getManufacturersDiscountsAction({
            limit: SCROLL_LIMIT,
            discount_limit: SCROLL_LIMIT_DISCOUNT,
          })
        );
        dispatch(setConfirmIsOpenAction(false));
        if (navigate) navigate("/discounts");
      })
      .catch((err) => {
        error(err.message);
        dispatch(endLoadingDiscounts());
      });
  };
};
