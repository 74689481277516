export const getFourthSquarePlaces = async (params) => {
  return fetch("https://api.foursquare.com/v3/places/search?" + params, {
    method: "GET",
    headers: {
      accept: "application/json",
      // eslint-disable-next-line no-undef
      Authorization: process.env.REACT_APP_FOURSQUARE_TOKEN,
      "Accept-Language": "en",
    },
  }).then((res) => {
    return res.json();
  });
};
