import { Box, CircularProgress, Grid, Paper, Typography } from "@mui/material";
import { IOSSwitch, StyledTextField } from "components";
import { useAdmin } from "helpers/helpers";
import { Controller } from "react-hook-form";
import NumberFormat from "react-number-format";
import { MapBlock } from "..";
import { object, number, func, bool, string } from "prop-types";
import useStyles from "../../styles";

export const GeneralTab = ({
  control,
  name,
  loading,
  setValue,
  debouncedRadius,
  shippingAddress,
  checkInCustomerRadiusEnabled,
}) => {
  const classes = useStyles();
  const isAdmin = useAdmin();

  return (
    <Paper className={classes.buttonBlock}>
      <Grid className={classes.gridWrapper}>
        <Grid>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <IOSSwitch
                disabled={isAdmin}
                label={
                  <Box className={classes.switchLabelBlock}>
                    <Typography className={classes.switchLabelFirst}>
                      Check in customer radius
                    </Typography>
                    <Typography className={classes.switchLabelSecond}>
                      When enabled, reps are only able to check into Customers
                      when they have a GPS signal and are within defined radius
                    </Typography>
                  </Box>
                }
                checked={!!field.value}
                error={error ? error.message : ""}
                formSx={{ flex: "0 0 34%" }}
                {...field}
                onChange={(e) => {
                  if (isAdmin) return;
                  if (e.target.value === "true") {
                    setValue("checkInCustomerRadius", "");
                  }
                  field.onChange(e);
                }}
              />
            )}
            name="checkInCustomerRadiusEnabled"
            control={control}
          />
        </Grid>
        <Grid className={classes.gridWrapperRight}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <NumberFormat
                customInput={StyledTextField}
                fullWidth
                size="small"
                placeholder="0"
                thousandSeparator={true}
                disabled={isAdmin || !checkInCustomerRadiusEnabled}
                InputProps={{
                  sx: {
                    height: "32px",
                    fontSize: "12px",
                    pr: 0,
                    background: "#fff",
                    color: "#707070",
                  },
                  endAdornment: (
                    <Typography className={classes.customerRadiusFeetText}>
                      feet
                    </Typography>
                  ),
                }}
                error={error ? error.message : ""}
                onValueChange={(v) => {
                  setValue("checkInCustomerRadius", v?.floatValue || "");
                }}
                {...field}
                onChange={() => {}}
              />
            )}
            name="checkInCustomerRadius"
            control={control}
          />
        </Grid>
      </Grid>
      {checkInCustomerRadiusEnabled ? (
        loading ? (
          <Box
            sx={{
              width: "374px",
              height: "200px",
              bgcolor: "#FFF",
              p: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress color="inherit" size={20} />
          </Box>
        ) : (
          <MapBlock
            address={shippingAddress}
            name={{ name }}
            radius={debouncedRadius || 0}
          />
        )
      ) : null}
    </Paper>
  );
};

GeneralTab.propTypes = {
  setValue: func,
  control: object,
  name: string,
  checkInCustomerRadiusEnabled: bool,
  loading: bool,
  shippingAddress: object,
  debouncedRadius: number,
};
