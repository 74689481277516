import { useCallback, useMemo } from "react";
import { shape, object, array, func, string } from "prop-types";
import { Box, Stack } from "@mui/material";
import { HeaderList, ReturnProductItem } from "./components";

export const ItemsListBlock = ({ returnListData, setValue, errors }) => {
  const { productsList, deliveries, returns, returnItem, productReturns } =
    returnListData || {};

  const returnedProducts = useMemo(
    () =>
      returns
        .map((prod) => prod.productReturns)
        .flat()
        .reduce((acc, cur) => {
          const foundProd = acc.find(
            (item) => item.orderProductId === cur.orderProductId
          );
          if (foundProd) {
            const i = acc.findIndex(
              (item) => item.orderProductId === cur.orderProductId
            );
            acc.splice(i, 1, {
              ...foundProd,
              quantity: foundProd.quantity + cur.quantity,
            });
          } else {
            acc.push(cur);
          }
          return acc;
        }, []),
    [returns]
  );

  const deliveredProducts = useMemo(
    () =>
      deliveries
        .map((prod) => prod.productDeliveries)
        .flat()
        .reduce((acc, cur) => {
          const foundProd = acc.find(
            (item) => item.orderProductId === cur.orderProductId
          );
          if (foundProd) {
            const i = acc.findIndex(
              (item) => item.orderProductId === cur.orderProductId
            );
            acc.splice(i, 1, {
              ...foundProd,
              quantity: foundProd.quantity + cur.quantity,
            });
          } else {
            acc.push(cur);
          }
          return acc;
        }, []),
    [deliveries]
  );

  const processedDeliviries = useMemo(
    () =>
      deliveredProducts.map((prod) => {
        const temp = returnedProducts.find(
          (el) => el.orderProductId == prod.orderProductId
        );
        return temp
          ? { ...prod, quantity: prod.quantity - temp.quantity }
          : { ...prod };
      }),
    [deliveredProducts, returnedProducts]
  );

  const setShowTitleForProduct = useCallback(
    (product) => {
      return returnItem?.id
        ? !!product?.parentId
        : productReturns
            ?.filter((el) => el?.totalDelivered > 0)
            ?.some(
              (prod) => prod?.product?.parentProduct?.id === product?.parentId
            );
    },
    [productReturns, returnItem]
  );

  return (
    <Box display="flex" mt={2}>
      <Box width="100%">
        <HeaderList />

        <Stack
          sx={{
            maxHeight: "306px",
            overflow: productsList?.length === 2 ? "hidden" : "auto",
            borderColor: "#D5D9D9",
            borderStyle: "solid",
            borderWidth: "0px 0.5px 0.5px 0.5px ",
            borderRadius: "0 0 4px 4px",
          }}
        >
          {productsList?.map((product) => (
            <ReturnProductItem
              key={product?.id}
              product={{
                ...product,
                processedDeliviries: processedDeliviries.find(
                  (item) => item.orderProductId === product.id
                )?.quantity,
              }}
              showTitleForProduct={setShowTitleForProduct(product)}
              isReturnEdit={!!returnItem?.id}
              error={!!errors?.productReturns}
              {...{ setValue, productReturns }}
            />
          ))}
        </Stack>
      </Box>
    </Box>
  );
};

ItemsListBlock.propTypes = {
  returnListData: shape({
    productsList: array,
    deliveries: array,
    returns: array,
    returnItem: shape({
      id: string,
    }),
    productReturns: array,
  }),
  setValue: func,
  errors: object,
};
ItemsListBlock.defaultProps = {
  returnListData: {
    productsList: [],
    deliveries: [],
    returns: [],
    returnItem: {
      id: "",
    },
    productReturns: [],
  },
};
