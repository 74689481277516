export const cl = {
  inputLabel: {
    fontSize: "13px",
    transform: "translate(14px, 13px) scale(1)",

    "&.Mui-focused": {
      transform: "translate(14px, -7px) scale(0.75)",
    },
    "&.MuiInputLabel-shrink": {
      transform: "translate(14px, -7px) scale(0.75)",
    },
  },

  dialogWrapper: {
    "& .MuiPaper-root": {
      boxShadow: "none",
      width: "100%",
      maxWidth: "340px",
      minWidth: "340px",
    },
  },

  cancelActionBtn: {
    width: "68px",
    color: "#6A6A6A",
    borderColor: "#D4D4D4",
    fontWeight: 400,
    fontSize: "13px",
    height: "28px",
  },

  saveActionBtn: {
    width: "108px",
    color: "#FFFFFF",
    fontWeight: 400,
    fontSize: "13px",
    height: "28px",
    boxShadow: "none",
    padding: "6px 14px",
  },

  actionsWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "15px 21px 17px",
    borderTop: "0.5px solid #D5D9D9",
  },

  contentWrapper: {
    p: "26px 0px 9px",
    maxWidth: "340px",
    minWidth: "340px",
  },

  inputSx: {
    height: "43px",
    fontSize: "13px",
  },
};
