import { makeStyles } from "@mui/styles";

export const cl = {
  textTitle: { fontSize: "30px", color: "#707070" },
  textSubTitle: { fontSize: "20px", fontWeight: 400, color: "#707070" },
  textBody: {
    fontSize: "12px",
    fontWeight: 400,
    color: "#000000",
  },
  block: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    height: "100%",
    flexDirection: "column",
    marginTop: 1,
  },
  blockTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    p: 0,
    borderBottom: "0.5px solid #D5D9D9",
  },
  blockTitleWrapper: { p: "14px 20px 9px 26px" },
  blockDialog: {
    width: "510px",
    paddingX: 0,
  },
  contentWrapper: {
    mt: "10px",
    height: "58px",
    paddingX: "32px",
  },
  label: {
    left: 0,
    height: "24px",
    "& .MuiFormLabel-root": {
      color: "#B5B5AC",
      fontSize: "12px",
      top: "2px",
    },
    "& .MuiOutlinedInput-root": {
      height: "100%",
      padding: "0 11px !important",
    },
  },
  localDelivery: {
    borderTop: "1px solid #D5D9D9",
    mt: "17px",
    pt: "15px",
    paddingX: "32px",
    text: {
      fontSize: "12px",
      fontWeight: 400,
      color: "#000000",
      marginRight: 1,
    },
  },
  shippingCarrierWrapper: { marginX: "32px", marginTop: "12px" },
  shippingCarrier: {
    border: "1px solid #D5D9D9",
    borderRadius: "4px",
    paddingTop: "12px",
    paddingBottom: "18px",
    text: {
      fontSize: 12,
      marginBottom: 0.5,
    },
  },
  stackWrapper: {
    mt: "18px",
    border: "0.5px solid #D5D9D9",
    borderRadius: "4px",
    marginX: "32px",
  },
  stack: {
    maxHeight: "324px",
    overflow: "auto",
    borderRadius: "4px",
  },
  action: {
    paddingX: 3.25,
    paddingTop: "15px",
    paddingBottom: "20px",
    display: "flex",
    justifyContent: "space-between",
    checkbox: { pl: 1, width: "100%" },
    btnWrapper: {
      display: "flex",
      justifyContent: "flex-end",
      width: "90%",
    },
  },
};

const useStyles = makeStyles(() => ({
  textInput: {
    fontSize: "12px",
    height: "28px",
    fontWeight: 400,
    "& .MuiInputBase-input": {
      paddingLeft: "8px",
      paddingRight: "8px",
      paddingTop: "4px",
      paddingBottom: "4px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: "0.5px !important",
    },
  },
  textInputColor: {
    fontSize: "12px",
    fontWeight: 400,
    height: "28px",
    color: "#47A06D",
    "& .MuiInputBase-input": {
      paddingLeft: "8px",
      paddingRight: "8px",
    },
  },
}));

export default useStyles;
