import { useCallback, useMemo, useRef, useState } from "react";
import { string, object, func, number, array } from "prop-types";

import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import styled from "@emotion/styled";
import StyledPopper from "../../../../components/StyledPopper/StyledPopper";
import { CartTrashIcon } from "../../../../components/Icons";

import {
  getFormattedDate,
  useAdmin,
  normalizeSnakeCaseString,
  separateNumWithComma,
  setCardName,
} from "../../../../helpers/helpers";
import {
  textForTooltipDeleteFulfill,
  textForTooltipDeletePayment,
  textForTooltipPaymentReturn,
  textForTooltipDeleteReturn,
  textForTooltipEditFulfill,
  textForTooltipEditReturn,
  textForTooltipEditRefundWithReturn,
  textForTooltipEditRefundWithReturnHasDelivery,
} from "./components/OrdersDelivery/ConfirmDeliveryPopup/ConfirmDelivery.constants";

const StyledButton = styled(Button)({
  fontSize: 10,
  fontWeight: 400,
  minWidth: 32,
  height: 18,
  color: "#707070",
  paddingLeft: 0,
  paddingRight: 0,
});

const OrdersListItem = ({
  order,
  list,
  item,
  type,
  handleEditDetails,
  handleViewDetails,
  handleDeleteDelivery,
  totalQuantity,
  repPermissions,
}) => {
  const isAdmin = useAdmin();
  const cl = {
    block: {
      display: "flex",
      alignItems: "center",
    },
    text: {
      fontSize: 12,
      color: "#1C1C19",
    },
  };

  const refEditBtn = useRef();
  const refDeleteBtn = useRef();

  const [openTooltipEdit, setOpenTooltipEdit] = useState(false);
  const [openTooltipDelete, setOpenTooltipDelete] = useState(false);
  const [tooltipText, setTooltipText] = useState("");

  const setType = useMemo(() => {
    if (item?.deliveryMethod === "RESTOCK") return "Restock";
    if (item?.restock && item?.deliveryMethod === "REFUND")
      return "Return with restock";

    return type;
  }, [item?.deliveryMethod, item?.restock, type]);

  const setVia = useMemo(() => {
    if (item?.restock && item?.deliveryMethod === "CANCEL") return "Cancel";
    if (item?.restock && item?.deliveryMethod === "REFUND") return "Refund";

    if (type === "Restock" || item?.deliveryMethod === "RESTOCK")
      return "Refund";

    if (item?.paymentType === "CREDIT_CARD_FROM_QUICKBOOKS")
      return "Credit Card";

    return normalizeSnakeCaseString(item?.deliveryMethod || item?.paymentType);
  }, [item?.deliveryMethod, item?.paymentType, item?.restock, type]);

  const getLastOrderItemByDate = useCallback((orderslist) => {
    if (!orderslist?.length) return [];

    const filteredListByDate = orderslist?.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateB - dateA;
    });

    return filteredListByDate[0].date;
  }, []);

  const lastOrderReturnsByDate = useMemo(
    () => getLastOrderItemByDate(order?.orderReturns),
    [getLastOrderItemByDate, order?.orderReturns]
  );

  const isDeliveryOlderThanReturn = useMemo(() => {
    if (!lastOrderReturnsByDate) return false;
    return lastOrderReturnsByDate < item?.date;
  }, [item?.date, lastOrderReturnsByDate]);

  const isDeliveryDisabled = useMemo(() => {
    if (!lastOrderReturnsByDate) return false;
    return !isDeliveryOlderThanReturn && item?.type === "Delivery";
  }, [isDeliveryOlderThanReturn, item?.type, lastOrderReturnsByDate]);

  const lastOrderDeliveryByDate = useMemo(
    () => getLastOrderItemByDate(order?.orderDeliveries),
    [getLastOrderItemByDate, order?.orderDeliveries]
  );

  const isReturnOlderThanDelivery = useMemo(() => {
    if (!lastOrderDeliveryByDate) return false;
    return lastOrderDeliveryByDate < item?.date;
  }, [item?.date, lastOrderDeliveryByDate]);

  const isReturnDisabled = useMemo(() => {
    if (!lastOrderDeliveryByDate) return false;
    return !isReturnOlderThanDelivery && item?.type === "Return";
  }, [isReturnOlderThanDelivery, item?.type, lastOrderDeliveryByDate]);

  const lastOrderRefundByDate = useMemo(
    () => getLastOrderItemByDate(order?.orderRefunds),
    [getLastOrderItemByDate, order?.orderRefunds]
  );

  const isRefundOlderThanRefund = useMemo(() => {
    if (!lastOrderRefundByDate) return false;
    return lastOrderRefundByDate < item?.date;
  }, [item?.date, lastOrderRefundByDate]);

  const isRefundDisabled = useMemo(() => {
    if (!lastOrderRefundByDate) return false;
    return !isRefundOlderThanRefund && item?.type === "Payment";
  }, [isRefundOlderThanRefund, item?.type, lastOrderRefundByDate]);

  const hasDeliveryAfterReturn = useCallback(
    (returnItem, key) => {
      if (!returnItem && !key) return false;

      const foundIndex = list.findIndex((el) => el?.id === returnItem?.id);
      if (foundIndex === -1) return false;

      if (foundIndex === list?.length - 1) return false;

      const cutList = list?.slice(foundIndex + 1, list?.length);

      return cutList.some((el) => el?.type === key);
    },
    [list]
  );

  const disabledEditBtn = useMemo(() => {
    if (repPermissions) return true;

    return (
      isAdmin ||
      (order?.orderStatus === "CANCELED" && true) ||
      !!(
        order?.orderPayments?.length &&
        item?.totalRefunds > 0 &&
        type === "Payment"
      ) ||
      isDeliveryDisabled ||
      isReturnDisabled ||
      item?.deliveryMethod === "REFUND"
    );
  }, [
    isAdmin,
    isDeliveryDisabled,
    isReturnDisabled,
    item?.deliveryMethod,
    item?.totalRefunds,
    order?.orderPayments?.length,
    order?.orderStatus,
    repPermissions,
    type,
  ]);

  const disabledDeleteBtn = useMemo(() => {
    if (repPermissions) return true;

    return (
      isAdmin ||
      (order?.orderStatus === "CANCELED" && true) ||
      !!(
        order?.orderPayments?.length &&
        item?.totalRefunds > 0 &&
        type === "Payment"
      ) ||
      isDeliveryDisabled ||
      isReturnDisabled ||
      isRefundDisabled
    );
  }, [
    isAdmin,
    isDeliveryDisabled,
    isRefundDisabled,
    isReturnDisabled,
    item?.totalRefunds,
    order?.orderPayments?.length,
    order?.orderStatus,
    repPermissions,
    type,
  ]);

  return (
    <Grid
      sx={{
        "&:not(:last-of-type)": {
          borderBottom: "1px solid #D5D9D9",
        },
        height: 25,
      }}
      container
    >
      <Grid sx={{ ...cl.block, paddingLeft: 2 }} item xs={2}>
        <Typography sx={cl.text}>{setType}</Typography>
      </Grid>

      <Grid sx={{ ...cl.block, paddingLeft: 2 }} item xs={2}>
        <Typography sx={cl.text} noWrap>
          {setVia}
        </Typography>
      </Grid>

      <Grid sx={cl.block} item xs={2.4}>
        <Typography sx={cl.text} noWrap>
          {item?.delivererDuplicate?.name ||
            item?.reference ||
            item?.check ||
            (item?.creditCard?.last4 &&
              `${setCardName(item?.creditCard?.brand)} ending in ${
                item?.creditCard?.last4
              }`)}
          {(type === "Delivery" || "Return") &&
            item?.deliveryMethod === "LOCAL_DELIVERY" &&
            item?.shippingCarrier}
          {(type === "Delivery" || "Return") &&
            item?.deliveryMethod === "SHIPPING_CARRIER" &&
            (item?.shippingCarrier?.name ?? "-")}
          {type === "Restock" && "-"}
          {item?.deliveryMethod === "RESTOCK" && "-"}
          {item?.paymentType === "CREDIT_CARD_FROM_QUICKBOOKS" &&
            "Via Quickbooks"}
        </Typography>
      </Grid>

      <Grid sx={cl.block} item xs={type === "Refund" ? 2.05 : 2.1}>
        <Typography sx={cl.text}>
          {getFormattedDate(item?.date).split(",")[0]}
        </Typography>
      </Grid>

      <Grid sx={cl.block} item xs={type === "Refund" ? 1.55 : 1.5}>
        <Typography sx={cl.text}>
          {type === "Refund" && "-"}
          {item?.amount &&
            type !== "Restock" &&
            `$${separateNumWithComma(item?.amount)}`}
          {!!item?.totalQuantity &&
            type !== "Refund" &&
            type !== "Restock" &&
            `${item?.totalQuantity} of ${totalQuantity}`}

          {type === "Restock" && `${item?.totalQuantity} of ${totalQuantity}`}
        </Typography>
      </Grid>

      <Grid
        sx={{
          ...cl.block,
          justifyContent: "flex-end",
          marginLeft:
            (item.paymentType === "CREDIT_CARD" ||
              item?.deliveryMethod === "RESTOCK" ||
              type === "Restock") &&
            "-63px",
        }}
        item
        xs={1.9}
      >
        <StyledButton
          color="groundLighter"
          onClick={(e) => {
            e.stopPropagation();
            handleViewDetails({
              ...item,
              isDeliveryDisabled:
                isDeliveryDisabled && item?.type === "Delivery",
              isReturnDisabled: isReturnDisabled && item?.type === "Return",
            });
          }}
        >
          View
        </StyledButton>
        {item.paymentType !== "CREDIT_CARD" &&
          item?.deliveryMethod !== "RESTOCK" &&
          !(type === "Restock") && (
            <>
              <Box
                sx={{
                  borderLeft: "1px solid #D5D9D9",
                  height: 11,
                  marginX: 0.2,
                }}
              />
              <StyledPopper
                open={openTooltipEdit}
                anchorEl={refEditBtn.current}
                text={tooltipText}
                modifiers={[
                  {
                    name: "offset",
                    options: { offset: [0, 10] },
                  },
                ]}
                placement="top"
                transition
                aria-hidden="true"
              />
              <Box
                ref={refEditBtn}
                display="flex"
                onMouseEnter={() => {
                  if (isDeliveryDisabled && item?.type === "Delivery") {
                    setOpenTooltipEdit(true);
                    setTooltipText(textForTooltipEditFulfill);
                  }
                  if (isReturnDisabled && item?.type === "Return") {
                    if (item?.deliveryMethod === "REFUND") {
                      setTooltipText(
                        hasDeliveryAfterReturn(item, "Delivery")
                          ? textForTooltipEditRefundWithReturnHasDelivery
                          : textForTooltipEditRefundWithReturn
                      );
                    }
                    if (item?.deliveryMethod !== "REFUND") {
                      setTooltipText(textForTooltipEditReturn);
                    }
                    setOpenTooltipEdit(true);
                  }
                  if (isRefundDisabled && item?.type === "Payment") {
                    setOpenTooltipEdit(true);
                    setTooltipText(textForTooltipPaymentReturn);
                  }
                }}
                onMouseLeave={() => {
                  setOpenTooltipEdit(false);
                }}
              >
                <StyledButton
                  color="groundLighter"
                  disabled={disabledEditBtn}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEditDetails({ ...item, titlePopUp: "Edit delivery" });
                  }}
                >
                  Edit
                </StyledButton>
              </Box>
              <StyledPopper
                open={openTooltipDelete}
                anchorEl={refDeleteBtn.current}
                text={tooltipText}
                modifiers={[
                  {
                    name: "offset",
                    options: { offset: [0, 10] },
                  },
                ]}
                placement="top"
                transition
                aria-hidden="true"
              />
              <Box
                ref={refDeleteBtn}
                display="flex"
                onMouseEnter={() => {
                  if (isDeliveryDisabled && item?.type === "Delivery") {
                    setOpenTooltipDelete(true);
                    setTooltipText(textForTooltipDeleteFulfill);
                  }
                  if (isReturnDisabled && item?.type === "Return") {
                    setOpenTooltipDelete(true);
                    setTooltipText(textForTooltipDeleteReturn);
                  }
                  if (isRefundDisabled && item?.type === "Payment") {
                    setOpenTooltipDelete(true);
                    setTooltipText(textForTooltipDeletePayment);
                  }
                }}
                onMouseLeave={() => {
                  setOpenTooltipDelete(false);
                }}
              >
                <IconButton
                  sx={{ marginRight: 1, padding: "4px" }}
                  disabled={disabledDeleteBtn}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteDelivery(item?.id);
                  }}
                >
                  <CartTrashIcon
                    width="10.79"
                    height="11.99"
                    stroke="rgba(0, 0, 0, 0.26)"
                  />
                </IconButton>
              </Box>
            </>
          )}
      </Grid>
    </Grid>
  );
};

OrdersListItem.propTypes = {
  order: object,
  list: array,
  type: string,
  item: object,
  handleEditDetails: func,
  handleViewDetails: func,
  handleDeleteDelivery: func,
  totalQuantity: number,
  repPermissions: object,
};
OrdersListItem.defaultProps = {
  onSetAnchorElPopper: () => {},
};

export default OrdersListItem;
