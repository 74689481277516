import { useMemo, useRef, useState } from "react";
import { bool, shape, number } from "prop-types";
import { Box, Grid, Paper } from "@mui/material";
import {
  ThreeDotButton,
  TotalSummariesToggles,
  TotalSummaryBlock,
  TotalSummaryInfoBlock,
} from "../../../../components";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { currentUserSelector } from "../../../../redux/selectors/auth";
import { SETTINGS_NAME } from "../../../../components/TotalSummariesComponents/TotalSummariesToggles/TotalSummariesToggles.constants";
import { addPercentsOfPayments } from "../../../../components/TotalSummariesComponents/helpers";
import { useAdmin } from "helpers/helpers";
import { useRepsPermissions } from "helpers/hooks";

const selector = createSelector(currentUserSelector, (currentUser) => ({
  currentUser,
}));

export const SalesRepTotalSummaries = ({ isLoading, totalSummaries }) => {
  const isAdmin = useAdmin();
  const repPermissions = useRepsPermissions();
  const { currentUser } = useSelector(selector);

  const representatives = currentUser?.totalSummarySettings?.representatives;

  const chosenTopThreeName = useMemo(() => {
    if (!representatives) return "";
    return Object.keys(representatives)
      .map((key) => ({ name: key, value: representatives[key] }))
      .find((el) => el.value)?.name;
  }, [representatives]);

  const toggleBtnAnchor = useRef();
  const [openToggles, setOpenToggles] = useState(false);

  return (
    <Paper
      sx={{
        pl: 1.75,
        minHeight: "64px",
        height: "64px",
      }}
      component={Grid}
      container
      elevation={0}
      variant="outlined"
    >
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
        xs={6}
        item
      >
        <TotalSummaryInfoBlock
          isLoading={isLoading}
          items={[
            {
              name: "Working Reps",
              value: totalSummaries?.working ?? 0,
            },
            { name: "Total Reps", value: totalSummaries?.all ?? 0 },
            {
              name: "Active Reps",
              value: totalSummaries?.active ?? 0,
              valueStyles: { color: "#47A06D" },
            },
            {
              name: "Inactive Reps",
              value: totalSummaries?.inactive ?? 0,
              valueStyles: { color: "#FF3737" },
            },
          ]}
        />
      </Grid>
      <Grid
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: 1,
          pl: 1.6,
        }}
        xs={6}
        item
      >
        <TotalSummaryBlock
          name={SETTINGS_NAME?.[chosenTopThreeName]?.name}
          isLoading={isLoading}
          topName={chosenTopThreeName}
          topThree={addPercentsOfPayments(totalSummaries?.topThree)}
        />
        <Box component="span">
          <ThreeDotButton
            disabled={!!repPermissions || isAdmin}
            ref={toggleBtnAnchor}
            onClick={() => setOpenToggles(true)}
          />
        </Box>
      </Grid>
      <TotalSummariesToggles
        anchorEl={toggleBtnAnchor.current}
        open={openToggles}
        onClose={() => setOpenToggles(false)}
        settingsType="representatives"
      />
    </Paper>
  );
};

SalesRepTotalSummaries.propTypes = {
  isLoading: bool,
  totalSummaries: shape({
    working: number,
    all: number,
    active: number,
    inactive: number,
  }),
};
