/* eslint-disable no-useless-escape */
import * as Yup from "yup";
// import { FED_REGEXP } from "../../../../../utils/validations";
const PHONE_REGEXP =
  /^[\+][\d]{1}[\s]?[\(]?[\d]{3}[\)]?[\s]?[\d]{3}[\s]?[\d]{4}$/im;

export const validationSchema = ({ onboardingSummary }) =>
  Yup.object().shape({
    federalTaxId: Yup.string()
      .trim()
      .nullable()
      .required("Federal Tax ID (EIN) field is required")
      .test("tax", "Must be exactly 9 digits", (v) => {
        return /^\d{2}[-{1}]?\d{7}$/.test(v);
      }),
    website: Yup.string()
      .nullable()
      .test("websiteValidation", "Enter valid link", (value) => {
        const URL_REGEXP =
          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/;

        if (!value) return true;
        return URL_REGEXP.test(value);
      })
      .notRequired(),
    name: Yup.string().trim().required("This field is required"),
    timeZone: Yup.string().trim().required("This field is required"),
    phone: Yup.string()
      .trim()
      .required("This field is required")
      .test(
        "phoneValidation",
        "Not valid phone number!",
        (value, { parent }) => {
          if (!value) return true;
          const { countryPrefics } = parent;
          return PHONE_REGEXP.test(`${countryPrefics?.phone_code}${value}`);
        }
      ),
    customId: Yup.string()
      .trim()
      .test("customIdValidation", "From 5 to 10 characters", (value) => {
        if (!value) return true;
        return value.length <= 10 && value.length >= 5;
      }),
    billingAddress: Yup.object().shape({
      formatted_address: Yup.string().required("This field is required"),
      street: Yup.string(),
      city: Yup.string().required("This field is required"),
      state: Yup.string().required("This field is required").nullable(),
      zip: Yup.string().required("This field is required"),
    }),

    shippingAddress: Yup.object().shape({
      formatted_address: Yup.string()
        .trim()
        .test("formatted_address", (v, ctx) => {
          if (!v && !onboardingSummary) {
            return ctx.createError({ message: "This field is required" });
          }
          return true;
        }),
      street: Yup.string().trim(),
      //.test("street", (v, ctx) => {
      //  if (!v && !onboardingSummary) {
      //    return ctx.createError({ message: "This field is required" });
      //  }
      //  return true;
      //}),
      city: Yup.string()
        .trim()
        .test("city", (v, ctx) => {
          if (!v && !onboardingSummary) {
            return ctx.createError({ message: "This field is required" });
          }
          return true;
        }),
      state: Yup.string()
        .trim()
        .test("state", (v, ctx) => {
          if (!v && !onboardingSummary) {
            return ctx.createError({ message: "This field is required" });
          }
          return true;
        }),
      zip: Yup.string()
        .trim()
        .test("zip", (v, ctx) => {
          if (!v && !onboardingSummary) {
            return ctx.createError({ message: "This field is required" });
          }
          return true;
        }),
      // city: Yup.string().when("formatted_address", {
      //   is: (v) => !!v,
      //   then: Yup.string().required("This field is required"),
      // }),
      // state: Yup.string().when("formatted_address", {
      //   is: (v) => !!v,
      //   then: Yup.string().required("This field is required"),
      // }),
      // zip: Yup.string().when("formatted_address", {
      //   is: (v) => !!v,
      //   then: Yup.string().required("This field is required"),
      // }),
    }),
    email: Yup.string()
      .trim()
      .required("This field is required.")
      .test("emailValidation", "Email not valid!", (value) => {
        if (!value) return true;
        const EMAIL_REGEXP =
          /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d)|(([a-z]|\d)([a-z]|\d|-|\.|_|~)*([a-z]|\d)))\.)+(([a-z])|(([a-z])([a-z]|\d|-|\.|_|~)*([a-z])))$/i; // eslint-disable-line
        return EMAIL_REGEXP.test(value);
      }),
    companyIndustry: Yup.string().trim().required("This field is required."),
    contacts: Yup.array().of(
      Yup.object().shape({
        phone: Yup.string()
          .trim()
          .required("This field is required")
          .test(
            "phoneValidation",
            "Not valid phone number!",
            (value, { parent }) => {
              if (!value) return true;
              const { countryPrefix } = parent;
              return PHONE_REGEXP.test(`${countryPrefix?.phone_code}${value}`);
            }
          ),
        name: Yup.string().required("This field is required"),
        role: Yup.string().required("This field is required"),
        email: Yup.string()
          .trim()
          .required("This field is required.")
          .test("emailValidation", "Email not valid!", (value) => {
            if (!value) return true;
            const EMAIL_REGEXP =
              /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d)|(([a-z]|\d)([a-z]|\d|-|\.|_|~)*([a-z]|\d)))\.)+(([a-z])|(([a-z])([a-z]|\d|-|\.|_|~)*([a-z])))$/i; // eslint-disable-line
            return EMAIL_REGEXP.test(value);
          }),
      })
    ),
    locations: Yup.array().of(
      Yup.object().shape({
        formatted_address: Yup.string().required("This field is required"),
        street: Yup.string(),
        city: Yup.string().required("This field is required"),
        state: Yup.string().required("This field is required").nullable(),
        zip: Yup.string().required("This field is required"),
        name: Yup.string().required("This field is required"),
      })
    ),
    shippingCarriers: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required("This field is required"),
        default: Yup.boolean().required("This field is required"),
      })
    ),
  });
