import { makeStyles } from "@mui/styles";
const useStyles = makeStyles(() => ({
  modalBox: {
    height: "766px",
    background: "white",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },

  trialTitle: {
    fontWeight: "bold",
    fontSize: "32px",
    color: "#5F6267",
    marginTop: "20px",
  },

  trialSubTitle: {
    fontSize: "16px",
    margin: "16px",
    textAlign: "center",
    width: "500px",
    color: "#1C1C19",
  },

  trialIcon: {
    marginTop: "20px",
  },

  textFieldBox: {
    marginTop: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  textField: {
    marginTop: "20px",
    width: "466px",
    height: "48px",
    borderRadius: "8px",
  },

  textMultiline: {
    marginTop: "20px",
    width: "466px",
    borderRadius: "8px",
  },

  label: {
    marginTop: "22px",
    fontSize: "18px",
    width: "464px",
  },

  submitButton: {
    width: "466px",
    height: "48px",
    color: "white",
    marginTop: "18px",
  },

  hubspotWrap: {
    minWidth: "400px",
    minHeight: "426px",
  },
}));
export default useStyles;
