import { shape, bool, func, array, string, object, number } from "prop-types";
import { range } from "lodash";
import { Box, MenuItem, Typography } from "@mui/material";

import { StyledSelect } from "components";
import { StyledProductIco } from "components/Icons";
import { photoUrl } from "helpers/helpers";

export const ReturnProductItem = ({
  product,
  showTitleForProduct,
  isReturnEdit,
  error,
  setValue,
  productReturns,
}) => {
  const isSingleProduct = !product?.product?.parentProduct;

  return product?.id === product?.name ? (
    showTitleForProduct ? (
      <Box display="flex" height="50px" minHeight="50px">
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            pl: "6px",
            bgcolor: "#F7F7F7",
            borderRadius: "4px",
            mx: "8px",
            my: "4px",
            minHeight: "46px",
          }}
        >
          <Box display="flex" wrap="nowrap" alignItems="center">
            <Box display="flex" alignItems="center">
              <StyledProductIco
                src={photoUrl(product?.photo?.fileName)}
                styles={{
                  border: "1px solid #d5d9d9",
                  objectFit: "contain",
                  width: "28px",
                  height: "28px",
                  borderRadius: "4px",
                }}
                placeholderStyles={{
                  width: "28px",
                  height: "28px",
                  wrapperProps: { height: "28px" },
                }}
              />
            </Box>
            <Box ml={1.5}>
              <Typography fontSize={12} fontWeight={500} color="#1C1C19">
                {product?.name}{" "}
                <Box
                  fontWeight={300}
                  component="span"
                >{`by ${product?.manufacturerName}`}</Box>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    ) : (
      <></>
    )
  ) : product?.totalDelivered !== 0 || isReturnEdit ? (
    <Box
      sx={{
        display: "flex",
        py: 1,
        mt: "1px",
        height: "50px",
        "&:not(:last-of-type)": {
          borderBottom:
            (product?.isLastEl || isSingleProduct) && "0.5px solid #D5D9D9",
        },
      }}
    >
      <Box width="65%" display="flex" alignItems="center" pl="9px">
        <Box width="100%" display="flex" gap="12px">
          <StyledProductIco
            src={photoUrl(product?.photo?.fileName)}
            styles={{
              border: "1px solid #d5d9d9",
              objectFit: "contain",
              width: "33px",
              height: "33px",
              borderRadius: "4px",
            }}
            placeholderStyles={{
              width: "33px",
              height: "33px",
              wrapperProps: { height: "33px" },
            }}
          />

          {isSingleProduct && (
            <Box sx={{ width: "170px" }}>
              <Typography fontSize={12} fontWeight={500} color="#1C1C19" noWrap>
                {product?.name}
                <Box fontWeight={400} component="span">
                  {" "}
                  by {product?.manufacturerName}
                </Box>
              </Typography>
            </Box>
          )}

          {!isSingleProduct && (
            <Box display="flex" alignItems="center">
              <Typography fontSize={12} fontWeight={500} color="#1C1C19">
                {product?.name}
                {product?.color ? ` / ${product.color}` : null}
                {product?.size ? ` / ${product.size}` : null}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>

      <Box width="35%" display="flex" alignItems="center" pl={2.5}>
        <Box
          sx={{
            border: `0.5px solid ${error ? "#EB4233" : "#CCCCCC"}`,
            borderRadius: "4px",
            width: "auto",
            height: "26px",
            display: "flex",
          }}
        >
          <Box>
            <StyledSelect
              error={error}
              sx={{
                fontWeight: 400,
                "& .MuiSelect-select": {
                  pl: 1,
                  pr: "24px !important",
                  pt: "3px !important",
                  pb: "3px !important",
                },
                "& svg": {
                  right: 0,
                },
                "& > fieldset": {
                  border: "none",
                },
              }}
              notched={false}
              fullWidth
              fontSize="12px"
              color="#000000"
              defaultValue="0"
              height="26px"
              value={productReturns[product?.index]?.returnQuantity || 0}
              onChange={(e) => {
                const v = e.target.value;
                const prod = productReturns[product?.index];
                setValue(`productReturns[${product?.index}]`, {
                  ...prod,
                  returnQuantity: v,
                });
              }}
            >
              {range(
                isReturnEdit
                  ? product?.processedDeliviries +
                      productReturns[product?.index]?.quantity +
                      1
                  : product?.totalDelivered + 1 || product?.quantity + 1
              ).map((num) => (
                <MenuItem key={num} value={num}>
                  {num}
                </MenuItem>
              ))}
            </StyledSelect>
          </Box>

          <Box
            sx={{
              borderLeft: "0.5px solid #CCCCCC",
              height: "25px",
            }}
          />

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingX: 1,
              cursor: "pointer",
            }}
          >
            <Typography
              fontWeight={400}
              fontSize={12}
              color="#000000"
              onClick={() => {
                const v = isReturnEdit
                  ? product?.processedDeliviries +
                    productReturns[product?.index]?.quantity
                  : product?.totalDelivered || product?.quantity;
                const prod = productReturns[product?.index];
                setValue(`productReturns[${product?.index}]`, {
                  ...prod,
                  returnQuantity: v,
                });
              }}
            >
              of{" "}
              {isReturnEdit
                ? product?.processedDeliviries +
                  productReturns[product?.index]?.quantity
                : product?.totalDelivered || product?.quantity}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  ) : (
    <></>
  );
};

ReturnProductItem.propTypes = {
  product: shape({
    id: string,
    name: string,
    manufacturerName: string,
    totalDelivered: number,
    isLastEl: bool,
    color: string,
    size: string,
    index: number,
    processedDeliviries: number,
    quantity: number,
    photo: shape({ fileName: string }),
    product: shape({
      parentProduct: object,
    }),
  }),
  showTitleForProduct: bool,
  isReturnEdit: bool,
  error: bool,
  setValue: func,
  productReturns: array,
};
ReturnProductItem.defaultProps = {
  product: {
    id: "",
    name: "",
    manufacturerName: "",
    totalDelivered: 0,
    isLastEl: false,
    color: "",
    size: "",
    index: 0,
    processedDeliviries: 0,
    quantity: 0,
    photo: { fileName: null },
    product: {
      parentProduct: {},
    },
  },
  showTitleForProduct: false,
  isReturnEdit: false,
  error: false,
  setValue: () => {},
  productReturns: [],
};
