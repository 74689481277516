import { useContext } from "react";

import { CustomerContext } from "Pages/CustomersPage/pages/CustomerPage/CustomerPage";

import { InformationIcon } from "components/Icons";
import { IOSSwitch, StyledTooltip } from "components";
import { CategoriesInput } from "components/CategoriesInput";
import { useProductCategories } from "components/CategoriesInput/useProductCategories";

import { Box, Grid, Stack, Typography } from "@mui/material";

export const ProductCategoriesBlock = () => {
  const { control, setValue } = useContext(CustomerContext);
  const { handleCheckCategory, formField } = useProductCategories({
    control,
    setValue,
  });

  return (
    <Grid xs={12} item gap="16px" container flexDirection="column">
      <Stack direction="row" gap="7.5px" alignItems="center">
        <IOSSwitch
          small
          checked={formField.showCategories}
          onChange={(e) =>
            handleCheckCategory(e, "showCategories", "productCategories")
          }
          formSx={{
            m: 0,
            "& .MuiBox-root": {
              mr: 0,
            },

            "& .MuiSwitch-root": {
              ml: 0,
            },
          }}
        />

        <Typography fontSize={13} color="ground.main" ml="2px">
          All
        </Typography>

        <StyledTooltip
          placement="top"
          arrow
          title={
            <Typography fontSize={12}>
              Select which product categories you
              <br /> want to enable for customers order
              <br /> direct store front.
            </Typography>
          }
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <InformationIcon size="15" stroke="#000000" />
          </Box>
        </StyledTooltip>
      </Stack>
      {!formField.showCategories && (
        <CategoriesInput
          control={control}
          setValue={setValue}
          fieldName="productCategories"
        />
      )}
    </Grid>
  );
};
