export const DEFAULT_FORM_DATA = {
  newPassword: "",
  confirmPassword: "",
  currentPassword: "",
};

export const FORM_FIELDS = [
  {
    name: "currentPassword",
    type: "password",
    label: "Current password",
  },
  {
    name: "newPassword",
    type: "password",
    label: "New password",
  },
  {
    name: "confirmPassword",
    type: "password",
    label: "Confirm new password",
  },
];
