export const ORDERS_FILTER_TABS = [
  {
    name: "Closed",
    value: "closed",
  },
  {
    name: "Open",
    value: "open",
  },
  {
    name: "Unpaid",
    value: "unpaid",
  },
  {
    name: "Unfulfilled",
    value: "unfulfilled",
  },
  {
    name: "All",
    value: "",
  },
];

export const ORDER_TYPE_TABS = [
  {
    name: "All",
    value: "",
  },
  {
    name: "Direct",
    value: "direct",
  },
  {
    name: "3rd Party",
    value: "third_party",
  },
];
