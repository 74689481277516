import { Fragment, useState } from "react";
import { object, func, bool } from "prop-types";

import { IconButton, ListItem, Stack, Typography } from "@mui/material";

export const MenuItem = ({
  filter,
  getActionList,
  disabled,
  onClick,
  isActive,
}) => {
  const ACTIONS = getActionList(filter);
  const [isItemHovered, setIsItemHovered] = useState(false);

  const { name } = filter;

  return (
    <ListItem
      onClick={() => onClick(filter, isActive)}
      onMouseEnter={() => setIsItemHovered(true)}
      onMouseLeave={() => setIsItemHovered(false)}
      sx={{
        position: "relative",
        cursor: "pointer",
        borderRadius: "4px",
        p: "8.5px 17.5px",
        justifyContent: "space-between",
        backgroundColor: isActive ? "#F7F7F7" : "#ffff",
        svg: {
          transition: "all 0.3s",
          opacity: 0,
        },
        "&:hover": {
          svg: {
            transition: "all 0.3s",
            opacity: 1,
          },
          backgroundColor: "#F7F7F7",
        },
      }}
    >
      <Typography
        fontSize="14px"
        lineHeight="19px"
        color="#5F6267"
        noWrap
        width={`calc(100% - ${isItemHovered ? "82px" : "0px"})`}
      >
        {name}
      </Typography>

      <Stack
        direction="row"
        gap="14px"
        alignItems="center"
        sx={{ position: "absolute", right: "18px" }}
      >
        {ACTIONS.map(({ Icon, onClick, show, iconProps }, index) => (
          <Fragment key={index}>
            {show && (
              <IconButton
                disabled={disabled}
                onClick={(e) => {
                  e.stopPropagation();
                  onClick();
                }}
                sx={{
                  p: 0,
                  opacity: disabled ? ".5" : 1,
                  svg: { ...iconProps },
                }}
                disableRipple
              >
                <Icon />
              </IconButton>
            )}
          </Fragment>
        ))}
      </Stack>
    </ListItem>
  );
};

MenuItem.propTypes = {
  getActionList: func,
  onClick: func,
  filter: object,
  disabled: bool,
  isActive: bool,
};
