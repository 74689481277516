import { bool, string, oneOf, func } from "prop-types";
import { Box, Button } from "@mui/material";

export const CardFooter = ({
  nameBtn,
  disabled,
  type,
  onChangeCurrentPlan,
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "114px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        px: 3,
      }}
    >
      <Button
        sx={{
          height: "67px",
          fontSize: 16,
          borderRadius: "8px",
          color: type === "extra" && "#009C60",
          borderColor: type === "extra" && "#D5D9D9",
          bgcolor: type !== "extra" && "#409A65",
        }}
        disabled={disabled}
        fullWidth
        variant={type === "extra" ? "outlined" : "contained"}
        color={type === "extra" ? "cancel" : "primary"}
        onClick={onChangeCurrentPlan}
      >
        {nameBtn}
      </Button>
    </Box>
  );
};

CardFooter.propTypes = {
  nameBtn: string,
  disabled: bool,
  type: oneOf(["standart", "premium", "extra"]),
  onChangeCurrentPlan: func,
};
CardFooter.defaultProps = {
  nameBtn: "Select",
  disabled: false,
  type: "premium",
};
