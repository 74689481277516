import { func, bool } from "prop-types";
import { Grid, Typography } from "@mui/material";
import { LargeCheckbox } from "../../../../../../components/Checkboxes";

const columns = [
  { name: "CUSTOMER", width: 4.74 },
  { name: "TERRITORY", width: 5.05 },
  { name: "TOTAL", width: "" },
];

export const HeaderListManage = ({ isChecked, onCheckedAllCustomer }) => {
  return (
    <Grid
      sx={{
        borderWidth: "0 0.5px 0px 0.5px",
        borderStyle: "solid",
        borderColor: "#D5D9D9",
        backgroundColor: "#EEEEEE",
        height: "39px",
        minHeight: "39px",
        alignItems: "center",
      }}
      container
    >
      <Grid display="flex" justifyContent="center" xs={0.6} item>
        <LargeCheckbox
          formSx={{
            marginLeft: 0,
            marginRight: 0,
          }}
          sx={{
            paddingLeft: 0,
            paddingRight: 0,
            height: "39px",
          }}
          checked={isChecked}
          onChange={(e) => onCheckedAllCustomer(e.target.checked)}
        />
      </Grid>
      {columns.map(({ name, width }) => (
        <Grid key={name} xs={width} item>
          <Typography fontSize={12} color="#6A6A6A">
            {name}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};

HeaderListManage.propTypes = { isChecked: bool, onCheckedAllCustomer: func };
