/* eslint-disable react/prop-types */
import React from "react";

export const TagIcon = ({ size = 16, fill = "#5f6267", ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        id="Icon_awesome-tag"
        data-name="Icon awesome-tag"
        d="M0,8.08V1.538A1.538,1.538,0,0,1,1.538,0H8.08A1.538,1.538,0,0,1,9.168.451l6.791,6.791a1.538,1.538,0,0,1,0,2.176L9.417,15.958a1.538,1.538,0,0,1-2.176,0L.451,9.168A1.538,1.538,0,0,1,0,8.08ZM3.589,2.051A1.538,1.538,0,1,0,5.128,3.589,1.538,1.538,0,0,0,3.589,2.051Z"
        fill={fill}
      />
    </svg>
  );
};
