import { useMemo } from "react";
import { number, func, string } from "prop-types";
import pluralize from "pluralize";
import { StyledTooltip } from "components";
import { Box, Typography } from "@mui/material";

export const DiscountCount = ({
  availableMSDCount,
  handleApplyDiscount,
  deliveryStatus,
}) => {
  const setSize = useMemo(() => {
    const countDigits = availableMSDCount?.toString()?.length;
    if (countDigits === 1) return "12px";
    if (countDigits === 2) return "14px";
    if (countDigits === 3) return "17px";
    if (countDigits >= 4) return "22px";
  }, [availableMSDCount]);

  return (
    <StyledTooltip
      isDark
      title={`${availableMSDCount}  manufacturer specific  
                ${pluralize(
                  "discounts",
                  availableMSDCount
                )} available for selected customer`}
      arrow
      PopperProps={{
        modifiers: [{ name: "offset", options: { offset: [0, -5] } }],
      }}
      placement="top"
    >
      <Box
        sx={{ position: "relative", left: 25 }}
        onClick={() => handleApplyDiscount("manufacturer")}
        disabled={
          !availableMSDCount ||
          (!!deliveryStatus && deliveryStatus !== "UNFULFILLED")
        }
      >
        <Box
          sx={{
            position: "absolute",
            left: 0,
            top: "50%",
            transform: "translate(-50%, -50%)",
            boxShadow: "0 0 0 rgba(64, 154, 101, 0.9)",
            animation: "shadowPulse 2s infinite",
            borderRadius: "50%",
            width: setSize,
            height: setSize,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1,
            "&:hover": {
              cursor: "pointer",
              animation: "none",
            },
          }}
        >
          <Box
            sx={{
              backgroundColor: "#47A06D !important",
              borderRadius: "50%",
              width: setSize,
              height: setSize,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",

              "& .MuiTypography-root": {
                color: "#ffff",
                fontSize: "8px",
              },
            }}
          >
            <Typography>{availableMSDCount}</Typography>
          </Box>
        </Box>
      </Box>
    </StyledTooltip>
  );
};

DiscountCount.propTypes = {
  availableMSDCount: number,
  handleApplyDiscount: func,
  deliveryStatus: string,
};
DiscountCount.defaultProps = {
  availableMSDCount: false,
  handleApplyDiscount: () => {},
  deliveryStatus: "",
};
