import { makeStyles } from "@mui/styles";

export const styles = {
  // title
  dialogTitleBlock: {
    // maxWidth: "474px",
    maxWidth: "520px",
    borderBottom: "1px solid #D5D9D9",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    p: 0,
    // width: "474px",
  },
  dialogTitleBox: {
    paddingTop: "14px",
    paddingLeft: "26px",
    paddingBottom: "10px",
  },
  dialogTitle: { fontSize: "30px", color: "#707070" },
  dialogSubtitle: { fontSize: "20px", fontWeight: 400, color: "#707070" },
  crossIcon: { marginTop: "20px", marginRight: "16px" },
  // content
  dialogContentBlock: {
    // maxWidth: "474px",
    maxWidth: "520px",
    minWidth: "520px",
    p: 0,
  },
  refundHeader: {
    display: "flex",
    border: "1px solid #D5D9D9",
    borderRadius: "4px 4px 0 0",
    height: "39px",
    alignItems: "center",
    paddingLeft: "20px",
    backgroundColor: "#EEEEEE",
  },
  refundBody: {
    borderWidth: "0 1px 1px 1px",
    borderStyle: "solid",
    borderColor: "#D5D9D9",
    borderRadius: "0 0 4px 4px",
  },
  text: {
    payment: {
      fontSize: 12,
      color: "#000000",
      marginTop: 0.1,
      marginRight: 1,
    },
    delivery: {
      fontSize: 12,
      color: "#000000",
      marginRight: 1,
    },
    status: {
      fontSize: 12,
      fontWeight: 400,
      color: "#1C1C19",
    },
    refund: {
      fontSize: 14,
      color: "#6A6A6A",
    },
    amount: {
      position: "absolute",
      top: "-32px",
      fontSize: "12px",
      fontWeight: 400,
      color: "#000000",
    },
    availableRefund: {
      paddingX: "21px",
      marginY: 1,
      fontSize: 12,
      fontWeight: 400,
      color: "#ACACAC",
    },
    later: {
      fontSize: "12px",
      fontWeight: 400,
      color: "#000000",
    },
    labelRefund: {
      fontSize: "12px",
      fontWeight: 400,
      color: "#000000",
    },
  },
  // actions
  dialogActionsBlock: {
    // maxWidth: "474px",
    maxWidth: "520px",
    paddingX: "26px",
    paddingTop: 0,
    paddingBottom: "23px",
  },
  checkboxFormSx: {
    mr: 0,
    flex: "1 1 auto",
    ml: "-9px",
  },
  checkboxLabel: {
    ml: "-4px",
    fontSize: "12px",
    fontWeight: 400,
  },
};

export const useStyles = makeStyles(() => ({
  textInput: {
    fontSize: "12px",
    height: "30px",
    fontWeight: 400,
    "& .MuiInputBase-input": {
      paddingLeft: "8px",
      paddingRight: "8px",
    },
  },
  multiTextInput: {
    fontSize: "12px",
    paddingTop: 10,
    paddingBottom: 11,
  },
  selectInput: {
    maxHeight: "30px",
    "& .MuiInputBase-input": {
      fontSize: "12px",
      fontWeight: 400,
      color: "#5F6267",
    },
    "& svg": {
      fill: "#B5B5AC",
    },
  },
}));
