import { useContext } from "react";
import { FormControl, Grid } from "@mui/material";
import { CustomerContext } from "Pages/CustomersPage/pages/CustomerPage/CustomerPage";
import { Controller } from "react-hook-form";
import NumberFormat from "react-number-format";
import { StyledTextField } from "components";
import { onPastePhone } from "helpers/helpers";

export const BusinessPhoneBlock = () => {
  const { control, setValue, formField } = useContext(CustomerContext);

  return (
    <Grid xs={12} columnSpacing={2.75} item container>
      <Grid xs={6} item>
        <Controller
          render={({ field, fieldState: { error } }) => (
            <FormControl fullWidth>
              <NumberFormat
                adminIsAllowed
                customInput={StyledTextField}
                fullWidth
                noErrorMessage
                // formSx={{ mt: 1.5 }}
                // size="small"
                InputProps={{
                  sx: {
                    fontSize: "13px",
                    height: "43px",
                    paddingRight: "6px",
                  },
                }}
                InputLabelProps={{
                  sx: {
                    fontSize: "13px",
                    color: "#5F6267",
                    top: 3,
                  },
                }}
                error={error ? error.message : ""}
                label="Business phone"
                format="+# (###) ### ####"
                mask="_"
                onClick={() => {
                  if (["", "+"].includes(field?.value)) {
                    setValue("phone", "+1");
                  }
                }}
                {...field}
                autoComplete="new-password"
                onPaste={(e) =>
                  onPastePhone({ e, setValue, currentValue: formField.phone })
                }
              />
            </FormControl>
          )}
          name="phone"
          control={control}
        />
      </Grid>

      <Grid xs={6} item>
        <Controller
          render={({ field, fieldState: { error } }) => (
            <FormControl fullWidth>
              <NumberFormat
                adminIsAllowed
                customInput={StyledTextField}
                fullWidth
                noErrorMessage
                // formSx={{ mt: 1.5 }}
                // size="small"
                InputProps={{
                  sx: {
                    fontSize: "13px",
                    height: "43px",
                    paddingRight: "6px",
                  },
                }}
                InputLabelProps={{
                  sx: {
                    fontSize: "13px",
                    color: "#5F6267",
                    top: 3,
                  },
                }}
                error={error ? error.message : ""}
                label="Business fax"
                format="+# (###) ### ####"
                mask="_"
                onClick={() => {
                  if (["", "+"].includes(field?.value)) {
                    setValue("businessFax", "+1");
                  }
                }}
                {...field}
              />
            </FormControl>
          )}
          name="businessFax"
          control={control}
        />
      </Grid>
    </Grid>
  );
};
