import PropTypes, { func, object, number, bool, string } from "prop-types";
import _ from "lodash";

import { Box, Grid, MenuItem, Paper, Typography } from "@mui/material";
import { photoUrl } from "../../../../helpers/helpers";
import { StyledSelect } from "../../../../components/Selects";
import { StyledProductIco } from "../../../../components/Icons";

const RefoundProductsItem = ({
  product,
  discount,
  isEdit,
  nameRefundDialog,
  setValue,
  formField,
  isReturnEdit,
  showTitleForProduct,
  error,
}) => {
  const isSingleProduct = !product?.product?.parentProduct;
  return product?.id === product?.name ? (
    showTitleForProduct ? (
      <Paper
        sx={{
          height: "50px",
        }}
        elevation={0}
        square
        component={Grid}
        container
        columns={12}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            pl: "6px",
            backgroundColor: "#F7F7F7",
            borderRadius: "4px",
            mx: "8px",
            my: "4px",
            minHeight: "46px",
          }}
        >
          <Grid container wrap="nowrap" alignItems="center">
            <Grid item display="flex" alignItems="center">
              <StyledProductIco
                src={photoUrl(product?.photo?.fileName)}
                styles={{
                  border: "1px solid #d5d9d9",
                  objectFit: "contain",
                  width: "28px",
                  height: "28px",
                  borderRadius: "4px",
                }}
                placeholderStyles={{
                  width: "28px",
                  height: "28px",
                  wrapperProps: { height: "28px" },
                }}
              />
            </Grid>
            <Grid ml={1.5} xs item zeroMinWidth>
              <Typography fontSize="12px" color="#1C1C19" fontWeight="500">
                {product?.name}{" "}
                <Box
                  fontWeight="300"
                  component="span"
                >{`by ${product?.manufacturerName}`}</Box>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    ) : (
      <></>
    )
  ) : product?.totalDelivered !== 0 || isReturnEdit ? (
    <Paper
      sx={{
        py: 1,
        mt: "1px",
        height: "50px",
        "&:not(:last-of-type)": {
          borderBottom:
            (product?.isLastEl || isSingleProduct) && "1px solid #D5D9D9",
        },
      }}
      elevation={0}
      square
      component={Grid}
      container
      columns={12}
    >
      <Grid
        item
        xs={6}
        sx={{
          display: "flex",
          alignItems: "center",
          pl: "9px",
        }}
      >
        <Box width="100%" display="flex" gap="12px">
          <StyledProductIco
            src={photoUrl(product?.photo?.fileName)}
            styles={{
              border: "1px solid #d5d9d9",
              objectFit: "contain",
              width: "33px",
              height: "33px",
              borderRadius: "4px",
            }}
            placeholderStyles={{
              width: "33px",
              height: "33px",
              wrapperProps: { height: "33px" },
            }}
          />

          {["Return Items", "Refund"].includes(nameRefundDialog) &&
            isSingleProduct && (
              <Box sx={{ width: "170px" }}>
                <Typography
                  fontSize="12px"
                  color="#1C1C19"
                  fontWeight="500"
                  noWrap
                >
                  {product?.name}
                </Typography>
                <Typography
                  color="#1C1C19"
                  fontSize="12px"
                  fontWeight="400"
                  noWrap
                >
                  by {product?.manufacturerName}
                </Typography>
              </Box>
            )}
          {["Return Items", "Refund"].includes(nameRefundDialog) &&
            !isSingleProduct && (
              <Box display="flex" alignItems="center">
                <Typography fontSize="12px" color="#1C1C19" fontWeight="500">
                  {product?.name} / {product?.color || product?.size}
                </Typography>
              </Box>
            )}
        </Box>
      </Grid>

      <Grid
        item
        xs={2}
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {nameRefundDialog === "Refund" && (
          <Typography fontSize="12px" fontWeight="400" color="#1C1C19">
            ${(product?.price - (product?.price * discount) / 100)?.toFixed(2)}
          </Typography>
        )}
      </Grid>

      <Grid
        item
        xs={4}
        sx={{
          display: "flex",
          alignItems: "center",
          paddingLeft: 2.5,
        }}
      >
        {isEdit ? (
          <Grid
            sx={{
              border: `0.5px solid ${error ? "#EB4233" : "#CCCCCC"}`,
              borderRadius: "4px",
              width: "auto",
              height: "26px",
            }}
            container
          >
            <Grid item>
              <StyledSelect
                error={error}
                sx={{
                  fontWeight: 400,
                  "& .MuiSelect-select": {
                    pl: 1,
                    pr: "24px !important",
                    paddingTop: "3px !important",
                    paddingBottom: "3px !important",
                  },
                  "& svg": {
                    right: 0,
                  },
                  "& > fieldset": {
                    border: "none",
                  },
                }}
                notched={false}
                fullWidth
                fontSize="12px"
                color="#000000"
                defaultValue="0"
                height="26px"
                value={
                  formField?.productReturns[product?.index]?.returnQuantity || 0
                }
                onChange={(e) => {
                  const v = e.target.value;
                  const prod = formField.productReturns[product?.index];
                  setValue(`productReturns[${product?.index}]`, {
                    ...prod,
                    returnQuantity: v,
                  });
                }}
              >
                {_.range(
                  isReturnEdit
                    ? product?.processedDeliviries +
                        formField?.productReturns[product?.index]?.quantity +
                        1
                    : product?.totalDelivered + 1 || product?.quantity + 1
                ).map((num) => (
                  <MenuItem key={num} value={num}>
                    {num}
                  </MenuItem>
                ))}
              </StyledSelect>
            </Grid>
            <Grid
              sx={{
                borderLeft: "1px solid #CCCCCC",
                height: "25px",
              }}
              item
            />
            <Grid
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingX: 1,
                cursor: "pointer",
              }}
              item
            >
              <Typography
                fontWeight="400"
                fontSize="12px"
                color="#000000"
                onClick={() => {
                  const prod = formField.productReturns[product?.index];
                  const v = isReturnEdit
                    ? product?.processedDeliviries +
                      formField?.productReturns[product?.index]?.quantity
                    : product?.totalDelivered || product?.quantity;
                  setValue(`productReturns[${product?.index}]`, {
                    ...prod,
                    returnQuantity: v,
                  });
                }}
              >
                of{" "}
                {isReturnEdit
                  ? product?.processedDeliviries +
                    formField?.productReturns[product?.index]?.quantity
                  : product?.totalDelivered || product?.quantity}
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Typography ml="6px" fontWeight="400" fontSize="12px" color="#000000">
            0 of {product?.quantity}
          </Typography>
        )}
      </Grid>
    </Paper>
  ) : (
    <></>
  );
};
RefoundProductsItem.propTypes = {
  product: object,
  quantity: number,
  setValue: func,
  formField: object,
  handleRemoveOrderProduct: func,
  discount: number,
  customerSelected: bool,
  isEdit: bool,
  nameRefundDialog: string,
  isReturnEdit: PropTypes.oneOfType([bool, object]),
  showTitleForProduct: bool,
  error: bool,
};
RefoundProductsItem.defaultProps = {
  product: null,
  quantity: 1,
  discount: 0,
  customerSelected: false,
  isEdit: false,
  isReturnEdit: false,
};

export default RefoundProductsItem;
