export const SortZAIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
    >
      <path
        id="icons8-sort-alpha-up-reversed"
        d="M5,5V6.043H7.9L5.146,8.8,5,8.96v1.257H9.174V9.174h-2.9L9.028,6.419l.146-.162V5Zm9.391.261-.376.36L11.783,7.87l.736.736L13.87,7.249V17h1.043V7.249l1.351,1.356L17,7.87,14.767,5.621Zm-7.727,6-.114.344L5.537,14.391H5.522v.032l-.49,1.356L5,15.858v.621H6.043V16.04l.214-.605H7.916l.214.605v.438H9.174v-.621l-.032-.078-.49-1.356v-.032H8.637L7.624,11.605l-.114-.344Zm.423,1.91.438,1.221H6.649Z"
        transform="translate(-5 -5)"
        fill="#3f3f3f"
      />
    </svg>
  );
};
