import * as React from "react";
export const PaymentTermIcon = (props) => (
  <svg
    width={11}
    height={11}
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        d="M9.467 6.575a.468.468 0 1 1 .936 0 .468.468 0 0 1-.936 0ZM6.434 9.6a.468.468 0 1 1 .243.904.468.468 0 0 1-.243-.904Zm1.374-.65a.468.468 0 1 1 .545.761.468.468 0 0 1-.545-.761Zm1.71-1.217a.468.468 0 1 1-.523.776.468.468 0 0 1 .523-.776Zm-7.163 1.39a.468.468 0 0 0-.03-.627 4.27 4.27 0 1 1 6.834-4.943l-.617.15a.187.187 0 0 0-.087.316l1.45 1.431a.187.187 0 0 0 .309-.075l.627-1.938a.187.187 0 0 0-.223-.24l-.53.13A5.207 5.207 0 0 0 5.203.277a5.205 5.205 0 0 0-3.54 8.879.468.468 0 0 0 .692-.033Z"
        fill="#3F3F3F"
      />
      <path
        d="M6.808 4.125a.917.917 0 0 0-.825-.458h-.916a.917.917 0 1 0 0 1.833h.916a.917.917 0 0 1 0 1.833h-.916a.917.917 0 0 1-.825-.458M5.525 2.75v.917m0 4.583v-.917"
        stroke="#3F3F3F"
        strokeWidth={0.8}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h11v11H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default PaymentTermIcon;
