import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  createOrderFromDraftService,
  deleteDraftOrderService,
  getDraftOrdersService,
} from "services/draft_orders";
import { getPaymentIntentLinkService } from "services/orders";
import { DRAFT_ORDER_TYPES, SCROLL_LIMIT } from "utils/constants";
import { error, success } from "utils/notifications";

const initData = {
  loading: false,
  list: [],
  count: 0,
  existData: false,
};

export const useDraftsTabActions = ({
  setFirstLoading = () => {},
  handleClickOnAssignTags = () => {},
} = {}) => {
  const navigate = useNavigate();
  const currentUser = useSelector(({ auth }) => auth?.currentUser);

  const tabFieldValueRef = useRef(null);

  const [tabField, setTabField] = useState({
    name: "",
    value: "",
  });

  const [params, setParams] = useState({
    limit: SCROLL_LIMIT,
    page: 1,
    sort_by_date: "desc",
    search: "",
    created_by: "",
    tag_id: [],
    date: "",
    start_date: "",
    end_date: "",
  });

  const [emailDialog, setEmailOpen] = useState({
    loading: false,
    open: false,
    order: null,
  });

  const openEmailDialog = useCallback(
    (order) => {
      setEmailOpen({
        ...emailDialog,
        open: !!order,
        order,
      });
    },
    [emailDialog]
  );

  const closeEmailDialog = () => {
    setEmailOpen({
      loading: false,
      open: false,
      order: null,
    });
  };

  const [draftsData, setDraftsData] = useState(initData);

  const handleFetchDrafts = useCallback(
    async ({ page, onSuccess, onError }) => {
      if (page < 1) setFirstLoading(true);

      tabFieldValueRef.current = tabField.value;

      const preparedData = {
        ...params,
        status: tabField.value ? JSON.stringify([tabField.value]) : undefined,
        search: params.search || undefined,
        created_by: params.created_by || undefined,
        tag_ids: params?.tag_id?.length
          ? JSON.stringify(params.tag_id)
          : undefined,
        date: params.date || undefined,
        start_date: params.start_date || undefined,
        end_date: params.end_date || undefined,
      };

      delete preparedData.tag_id;

      try {
        const res = await getDraftOrdersService({
          ...preparedData,
          page: page ? page : 1,
        });
        const { rows = [], count = 0, existData = false } = res || {};

        if (tabFieldValueRef.current === tabField.value) {
          setDraftsData((prev) => ({
            ...prev,
            list: page ? [...prev.list, ...rows] : [...rows],
            count: count,
            existData,
          }));

          setParams((prev) => ({ ...prev, page: page ? page : 1 }));

          if (onSuccess) onSuccess(res);
        }
      } catch (err) {
        if (onError) return onError(err);

        // eslint-disable-next-line no-console
        console.error(err);
        error(err?.response?.data?.message || "Something went wrong.");
      } finally {
        setFirstLoading(false);
      }
    },
    [params, setFirstLoading, tabField?.value]
  );

  const [emailLinkDialogData, setEmailLinkDialogData] = useState(null);

  const getPaymentIntentLink = useCallback(
    async ({ orderId, orderType, customId }) => {
      try {
        if (orderId) {
          const res = await getPaymentIntentLinkService(orderId);
          const { paymentIntentLink } = res || {};
          if (!paymentIntentLink)
            return error("A payment link is not available.");
          setEmailLinkDialogData({ paymentIntentLink, orderType, customId });
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        error(err?.response?.data?.message || "Something went wrong.");
      }
    },
    [setEmailLinkDialogData]
  );

  const handleCreateOrder = useCallback(async ({ data, onSuccess }) => {
    try {
      const res = await createOrderFromDraftService(data);

      if (onSuccess) onSuccess(res);

      return res;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      error(err?.response?.data?.message || "Something went wrong.");
    }
  }, []);

  const handleSendEmail = useCallback(
    (data) => {
      handleCreateOrder({
        data: {
          draftOrderId: emailDialog.order?.id,
          email: { ...data },
        },
        onSuccess: (res) => {
          const {
            customId: { customId = "" },
          } = res;
          handleFetchDrafts({ page: 0 });
          closeEmailDialog();
          success(`Order #${customId} created`);
        },
      });
    },
    [emailDialog.order?.id, handleCreateOrder, handleFetchDrafts]
  );

  const handleCreateWithEmailLink = useCallback(
    async (order) => {
      try {
        const res = await handleCreateOrder({
          data: { draftOrderId: order?.id },
          onSuccess: (res) => {
            const {
              customId: { customId = "" },
            } = res;
            handleFetchDrafts({ page: 0 });
            success(`Order #${customId} created`);
          },
        });

        await getPaymentIntentLink({
          orderId: res?.id,
          orderType: res?.type,
          customId: res?.customId?.customId,
        });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        error(err?.response?.data?.message || "Something went wrong.");
      }
    },
    [getPaymentIntentLink, handleCreateOrder, handleFetchDrafts]
  );

  const handleDuplicateDraft = useCallback(
    ({ order }) => {
      if (!order?.id) return;
      navigate(`/cart/duplicate/${order.id}`, {
        state: { duplicateDraft: order },
      });
    },
    [navigate]
  );

  const handleDeleteDraft = useCallback(
    async ({ id, customId, onSuccess, onError }) => {
      if (!id) return;

      try {
        await deleteDraftOrderService(id);
        if (onSuccess) return onSuccess();
        handleFetchDrafts({ page: 0 });
        success(`Draft ${customId} deleted`);
      } catch (err) {
        if (onError) return onError(err);
        // eslint-disable-next-line no-console
        console.error(err);
        error(err?.response?.data?.message || "Something went wrong.");
      }
    },
    [handleFetchDrafts]
  );

  const menuItems = useCallback(
    (order, arrLabels) => {
      const { status } = order;

      const openList = [
        {
          label: "Assign tags",
          onClick: () => {
            handleClickOnAssignTags({ order, isDraft: true });
          },
        },
        {
          label: "Create & Email",
          onClick: () => openEmailDialog(order),
        },
        {
          label: "Create & New",
          onClick: () => {
            handleCreateOrder({
              data: { draftOrderId: order?.id },
              onSuccess: (res) => {
                const {
                  customId: { customId = "" },
                } = res;
                success(`Order #${customId} created`);
                navigate("/cart");
              },
            });
          },
        },
        {
          label: "Update draft",
          onClick: () => navigate(`/cart/draft/${order.id}`),
        },
        {
          label: "Delete draft",
          onClick: () =>
            handleDeleteDraft({
              id: order.id,
              customId: order?.customId?.customId,
            }),
          redText: true,
        },
      ];

      if (
        currentUser?.stripeAccountVerificationStatus === "VERIFIED" &&
        order?.orderType !== "THIRD_PARTY"
      ) {
        openList.splice(2, 0, {
          label: "Create & Copy link",
          onClick: () => handleCreateWithEmailLink(order),
          // showBeforeDivider: true,
          // showAfterDivider: true,
        });
      }

      if (status === DRAFT_ORDER_TYPES.open)
        return arrLabels?.length
          ? openList.filter((menu) => arrLabels.includes(menu.label))
          : openList;

      const closedList = [
        {
          label: "Duplicate draft",
          onClick: () => handleDuplicateDraft({ order }),
        },
        {
          label: "Delete draft",
          onClick: () =>
            handleDeleteDraft({
              id: order.id,
              customId: order?.customId?.customId,
            }),
          redText: true,
        },
      ];

      if (status === DRAFT_ORDER_TYPES.closed)
        return arrLabels?.length
          ? closedList.filter((menu) => arrLabels.includes(menu.label))
          : closedList;

      return [];
    },
    [
      currentUser?.stripeAccountVerificationStatus,
      handleClickOnAssignTags,
      handleCreateOrder,
      handleCreateWithEmailLink,
      handleDeleteDraft,
      handleDuplicateDraft,
      navigate,
      openEmailDialog,
    ]
  );

  // useEffect(() => {
  //   if (!firstFetch) return;

  //   handleFetchDrafts({ page: 0 });

  //   setFirstFetch(false);
  // }, [firstFetch]);

  useEffect(() => {
    // if (firstFetch) return;

    handleFetchDrafts({ page: 0 });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tabField?.value,
    params?.search,
    params?.created_by,
    params?.tag_id,
    params?.date,
    params?.start_date,
    params?.end_date,
  ]);

  return {
    draftsData,
    params,
    handleFetchDrafts,
    menuItems,
    filtersData: {
      tabField,
      setTabField,
      setParams,
    },
    emailDialogData: {
      ...emailDialog,
      openEmailDialog,
      closeEmailDialog,
    },
    emailLinkDialogData,
    setEmailLinkDialogData,
    handleDeleteDraft,
    handleDuplicateDraft,
    handleCreateOrder,
    handleSendEmail,
  };
};
