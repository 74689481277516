import * as React from "react";
export const PhotoCommentRoundedIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12.85}
    height={12.85}
    viewBox="0 0 9.416 9.416"
    {...props}
  >
    <path
      data-name="Icon material-mode-comment"
      d="M9.411.942A.94.94 0 0 0 8.474 0H.942A.944.944 0 0 0 0 .942v5.649a.944.944 0 0 0 .942.942h6.591l1.883 1.883Z"
      fill="#FFF"
    />
  </svg>
);
