/* eslint-disable no-useless-escape */
import * as Yup from "yup";

export const validationSchema = () =>
  Yup.object().shape({
    paymentTermsId: Yup.number()
      .transform((v, o) => (o === "" ? null : v))
      .nullable()
      .required("Approved payment terms field is required"),
    representativeId: Yup.string(),
    territoryId: Yup.number()
      .transform((v, o) => (o === "" ? null : v))
      .nullable(),
    note: Yup.string().trim(),
  });
