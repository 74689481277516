import { func, object } from "prop-types";
import { Box } from "@mui/material";
import ArrowFrwrdIco from "../../../../icons/arrow-forward.png";

export const ArrowBlock = ({
  handleOpenProfileDialog,
  user,
  repPermissions,
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        minWidth: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      <Box
        onClick={() => {
          if (repPermissions) return;
          handleOpenProfileDialog(user);
        }}
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          pr: "21px",
        }}
      >
        <Box component="img" src={ArrowFrwrdIco} width="7px" />
      </Box>
    </Box>
  );
};

ArrowBlock.propTypes = {
  handleOpenProfileDialog: func,
  user: object,
  repPermissions: object,
};
ArrowBlock.defaultProps = {
  handleOpenProfileDialog: () => {},
  user: {},
};
