import { defaultTableData } from "Pages/CustomersPage/pages/CustomerProfilePage/CustomerProfile.constants";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { updateCustomerService } from "services/customers";
import { error, success } from "utils/notifications";

export const useAssignedRepsBlock = ({ representatives, setValue }) => {
  const [assignedRepsOpen, setAssignedRepsOpen] = useState(false);
  const [editAssignedReps, setEditAssignedReps] = useState(null);
  const [assignedRepresentatives, setAssignedRepresentatives] = useState([]);
  const { customerId } = useParams();

  useEffect(
    function setInitialReps() {
      if (representatives?.length) {
        setAssignedRepresentatives(representatives);
      }
    },
    [assignedRepresentatives, representatives]
  );

  const handleOpenAssignedReps = () => {
    setAssignedRepsOpen(true);
  };
  const handleCloseAssignedReps = () => {
    setAssignedRepsOpen(false);
    setEditAssignedReps(null);
  };
  const handleSetAssignedRepsData = (assignedRepsData) => {
    setAssignedRepresentatives(assignedRepsData);
    if (setValue)
      setValue("representatives", assignedRepsData, { shouldDirty: true });
  };

  const [customerState, setCustomerState] = useState({
    customer: null,
    loading: true,
    tableData: defaultTableData,
    tableTab: { value: 0, label: "Orders" },
  });

  const updateCustomerState = useCallback((newValues) => {
    setCustomerState((prev) => ({ ...prev, ...newValues }));
  }, []);

  const handleUpdateCustomer = async (data) => {
    try {
      updateCustomerState({ loading: true });
      const customer = await updateCustomerService(data, customerId);
      updateCustomerState({ loading: false, customer });
    } catch (err) {
      updateCustomerState({ loading: false });
      error(err?.response?.data?.message);
    }
  };
  const handleSaveAssignReps = async (data) => {
    await handleUpdateCustomer({
      representatives: data,
    });
    success("Assigned reps updated");
  };

  const handleRemoveReps = (reps) => {
    const tempReps = [...assignedRepresentatives];
    const indexReps = tempReps?.findIndex((c) => {
      if (c?.id) return c.id === reps?.id;
      return c?.fid === reps?.fid;
    });
    tempReps.splice(indexReps, 1);
    setAssignedRepresentatives(tempReps);
    if (setValue) setValue("representatives", tempReps, { shouldDirty: true });
  };

  return {
    assignedRepsOpen,
    handleOpenAssignedReps,
    handleCloseAssignedReps,
    editAssignedReps,
    setEditAssignedReps,
    handleSetAssignedRepsData,
    assignedRepresentatives,
    handleSaveAssignReps,
    customerState,
    handleRemoveReps,
  };
};
