import { Box, Button, Dialog, Link } from "@mui/material";
// import { useTrialDialogActions } from "./useTrialDialogActions";
import { DialogTitleComponent } from "./components";
import { useSelector } from "react-redux";
import { ContactUsBar } from "components";
import {
  CONTACT_EMAIL,
  CONTACT_PHONE,
  LINK_TO_SALES,
} from "./TrialDialog.constants";

const DIALOG_WIDTH = 624;

export const TrialDialog = () => {
  const trialDialogIsOpen = useSelector(
    ({ subscriptionDialogs }) => subscriptionDialogs.trialDialog.open
  );

  // const { state, handleChooseCurrentPlan } = useTrialDialogActions();

  return (
    <Dialog
      open={trialDialogIsOpen}
      sx={{
        ".MuiPaper-root": {
          borderRadius: "18px",
          boxShadow: "none",
          width: "100%",
          maxWidth: `${DIALOG_WIDTH}px`,
          minWidth: `${DIALOG_WIDTH}px`,
        },
      }}
    >
      <Box position="relative">
        <ContactUsBar
          text="Need Help?"
          email={CONTACT_EMAIL}
          phone={CONTACT_PHONE}
          rawPhone
          left={`${DIALOG_WIDTH / 2}px`}
        />
        <Box height="90px" />
        <DialogTitleComponent />
        <Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              py: "66px",
            }}
          >
            <Button
              sx={{
                width: "180px",
                height: "51px",
                fontSize: 18,
                borderRadius: "8px",
              }}
              fullWidth
              variant="contained"
              color="primary"
              component={Link}
              href={LINK_TO_SALES}
            >
              Talk to Sales
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};
