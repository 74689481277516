import { useEffect, useState, useMemo, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes, { array, string, func, object, bool } from "prop-types";
import { useWatch } from "react-hook-form";

import { Paper, Tab, Tabs, Typography, Box } from "@mui/material";

import RequiredInfoTab from "./ContentTabs/RequiredInfoTab/RequiredInfoTab";
import VariationsTab from "./ContentTabs/VariationsTab";
import InventoryTab from "./ContentTabs/InventoryTab";
import { StyledPopper } from "components";

import {
  openDiscardChanges,
  setEditTypeAction,
} from "redux/actions/confirmDialogs";
import { createSelector } from "reselect";
import {
  confirmDialogFormChangedSelector,
  editTypeSelector,
} from "redux/selectors/confirmDialogs";
import { categoriesListSelector } from "redux/selectors/categories";
import { success } from "utils/notifications";
import { setPreparedData } from "./ProductDetails.helpers";

const selector = createSelector(
  confirmDialogFormChangedSelector,
  editTypeSelector,
  categoriesListSelector,
  (formChanged, editType, categoriesList) => ({
    formChanged,
    editType,
    categoriesList,
  })
);

const ProductDetails = ({
  currentTab,
  tabNames,
  setTabNames,
  handleCurrentTab,
  setVariationsTab,
  control,
  setValue,
  setError,
  errors,
  clearErrors,
  isEdit,
  handleSubmit,
  reset,
  register,
  handleSaveInventoryItem,
  trigger,
  onSubmit,
  handleSaveProduct,
}) => {
  const dispatch = useDispatch();
  const { formChanged } = useSelector(selector);

  const formField = useWatch({ control });

  useEffect(() => {
    if (formField.isMultiple) {
      reset(
        {
          ...formField,
          barcode: "",
        },
        { keepDirty: true }
      );
    }
    if (!formField.isMultiple) {
      reset(
        {
          ...formField,
          variationsFields: [],
        },
        { keepDirty: true }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formField.isMultiple, reset]);

  useEffect(() => {
    dispatch(setEditTypeAction("product", !isEdit));
    if (formChanged) trigger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isEdit, currentTab]);

  const onError = () => {
    if (errors.name?.type === "duplicate")
      return setError("name", {
        ...errors.name,
      });
  };

  const saveDataAfterChangePhotos = async ({
    successMsg,
    data,
    variationsData,
  }) => {
    if (formField?.rawProduct?.requiredFieldsMissing) return;

    if (!isEdit) {
      success(successMsg);
    } else {
      const preparedData = {
        ...formField?.rawProduct,
      };

      delete preparedData.requiredFieldsMissing;

      if (data) preparedData.photos = data;
      if (variationsData) preparedData.variationsFields = variationsData;

      handleSaveProduct({ data: preparedData, justSave: true, successMsg });
    }
  };

  const handleSaveData = useCallback(
    (res) => {
      const preparedData = setPreparedData({ res });

      reset({
        ...formField,
        rawProduct: preparedData,
        ...preparedData,
      });
    },
    [formField, reset]
  );

  const saveDataAfterChangeVariations = async ({
    successMsg,
    variationsData,
  }) => {
    if (formField?.rawProduct?.requiredFieldsMissing) return;

    if (!isEdit) return;

    const preparedData = {
      ...formField?.rawProduct,
    };

    delete preparedData.requiredFieldsMissing;

    if (variationsData) preparedData.variationsFields = variationsData;

    handleSaveProduct({
      data: preparedData,
      justSave: true,
      successMsg,
      onSuccess: (res) => handleSaveData(res),
    });
  };

  const [bgColor, setBgColor] = useState("white");

  const showColor = useCallback(() => {
    setBgColor("#ECF5F0");

    setTimeout(() => {
      setBgColor("white");
      setValue("blinkVariationsTab", false);
    }, 500);
  }, [setValue]);

  useEffect(() => {
    if (formField?.blinkVariationsTab && currentTab === "Variations") {
      showColor();
    }
  }, [currentTab, formField?.blinkVariationsTab, showColor]);

  const disabledUploadFile = useMemo(() => {
    const errArr = Array.isArray(errors?.variationsFields)
      ? errors?.variationsFields
      : [];

    const errorsList = errArr?.filter(
      (e) => e?.sku?.type || e?.size?.type || e?.color?.type
    );

    return errorsList;
  }, [errors?.variationsFields]);

  const renderContent = (tab) => {
    switch (tab) {
      case "Required Info":
        return (
          <RequiredInfoTab
            {...{
              control,
              isEdit,
              setValue,
              setVariationsTab,
              setError,
              errors,
              clearErrors,
              saveDataAfterChangePhotos,
              setTabNames,
            }}
          />
        );
      case "Variations":
        return (
          <VariationsTab
            control={control}
            isEdit={isEdit}
            setValue={setValue}
            setError={setError}
            errors={errors}
            trigger={trigger}
            disabledUploadFile={disabledUploadFile}
            clearErrors={clearErrors}
            register={register}
            reset={reset}
            saveDataAfterChangePhotos={saveDataAfterChangePhotos}
            saveDataAfterChangeVariations={saveDataAfterChangeVariations}
          />
        );
      case "Inventory":
        return (
          <InventoryTab
            control={control}
            setValue={setValue}
            handleSaveInventoryItem={handleSaveInventoryItem}
            saveDataAfterChangePhotos={saveDataAfterChangePhotos}
            disabledUploadFile={disabledUploadFile}
          />
        );
    }
  };

  const [openTooltip, setTooltip] = useState(false);
  const tabRefs = useRef([]);

  const hasProductName = useMemo(() => !!formField.name, [formField.name]);
  const hasSku = useMemo(
    () => !!formField.sku || !!formField.variationsFields.length,
    [formField.sku, formField.variationsFields.length]
  );
  const hasManufacturer = useMemo(
    () => !!formField.manufacturerId,
    [formField.manufacturerId]
  );

  const handleDisableIsMultIsVar = useMemo(() => {
    return formField.isMultiple && !formField.variationsFields.length;
  }, [formField.isMultiple, formField.variationsFields.length]);

  const handleDisableIsNameIsSkuIsManuf = useMemo(() => {
    return !(hasProductName && hasSku && hasManufacturer);
  }, [hasManufacturer, hasProductName, hasSku]);

  const textTooltip = useMemo(() => {
    if (handleDisableIsNameIsSkuIsManuf)
      return "Add product name, SKU & manufacturer to access the inventory";
    if (handleDisableIsMultIsVar)
      return "Create at least one variation type to access the inventory";
    return "";
  }, [handleDisableIsMultIsVar, handleDisableIsNameIsSkuIsManuf]);

  const handleChangeTab = useCallback(
    (e, newValue) => {
      e.preventDefault();

      if (
        currentTab === "Inventory" &&
        formField.inventoryWithoutSave &&
        isEdit
      ) {
        dispatch(
          openDiscardChanges(
            () => {
              handleCurrentTab(newValue);
              setValue("inventoryWithoutSave", null);
            },
            () => {
              dispatch(setEditTypeAction("product", !isEdit));

              if (isEdit) {
                handleSaveProduct({
                  data: {
                    ...formField.rawProduct,
                    inventory: formField?.inventory,
                    variationsFields: formField?.variationsFields,
                    inventoryWithoutSave: formField.inventoryWithoutSave,
                  },
                  justSave: true,
                  onSuccess: (res) => {
                    handleSaveData(res);
                    setValue("inventoryWithoutSave", null);
                  },
                });
                handleCurrentTab(newValue);
              }
            }
          )
        );
        return;
      }

      handleCurrentTab(newValue);
    },
    [
      currentTab,
      dispatch,
      formField,
      handleCurrentTab,
      handleSaveData,
      handleSaveProduct,
      isEdit,
      setValue,
    ]
  );

  return (
    <Paper sx={{ borderRadius: "4px" }} elevation={0} variant="outlined" square>
      <Box
        sx={{
          height: "64px",
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
          display: "flex",
          alignItems: "flex-end",
          pl: "35px",
        }}
        component="form"
        id="new-product-form"
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        <Typography
          sx={{
            fontSize: "28px",
            height: "100%",
            paddingTop: "10px",
            color: "#707070",
          }}
        >
          Product details
        </Typography>
        <StyledPopper
          open={openTooltip}
          anchorEl={tabRefs.current["Inventory"]}
          text={textTooltip}
          placement="top"
          transition
          aria-hidden="true"
          container={null}
        />
        <Tabs
          sx={{ marginLeft: 4 }}
          value={currentTab}
          onChange={handleChangeTab}
        >
          {tabNames.map((tab) => (
            <Tab
              key={tab.title}
              className="profile-tabs"
              sx={{
                textTransform: "capitalize",
                fontSize: "20px",
                fontWeight: "400",
                px: 1,
                color: "#707070",
                width: "165px",
                "&.Mui-selected": {
                  backgroundColor: `${bgColor} !important`,
                  transition: "all 1s ease",
                },
              }}
              label={
                <Box
                  onMouseEnter={(e) => {
                    if (
                      e.target.innerText === "Inventory" &&
                      (handleDisableIsMultIsVar ||
                        handleDisableIsNameIsSkuIsManuf)
                    ) {
                      setTooltip(true);
                    }
                  }}
                  onMouseLeave={() => setTooltip(false)}
                  sx={{ pointerEvents: "auto" }}
                  component="span"
                  ref={(el) => (tabRefs.current[tab.title] = el)}
                >
                  {tab.name}
                </Box>
              }
              value={tab.title}
              disabled={
                tab.title === "Inventory" &&
                (handleDisableIsMultIsVar || handleDisableIsNameIsSkuIsManuf)
              }
            />
          ))}
        </Tabs>
      </Box>
      <Box
        sx={{
          width: "100%",
          borderRadius: "0 0 4px 4px",
          minHeight: currentTab !== "Required Info" && "59vh",
        }}
      >
        {renderContent(currentTab)}
      </Box>
    </Paper>
  );
};
ProductDetails.propTypes = {
  tabNames: array,
  setTabNames: func,
  currentTab: PropTypes.oneOfType([string, object]),
  handleCurrentTab: func,
  setVariationsTab: func,
  control: object,
  setValue: func,
  setError: func,
  errors: object,
  clearErrors: func,
  isEdit: bool,
  productId: string,
  handleSubmit: func,
  reset: func,
  register: func,
  productProfile: object,
  handleSaveInventoryItem: func,
  trigger: func,
  onSubmit: func,
  handleSaveProduct: func,
};

export default ProductDetails;
