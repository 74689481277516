import { Grid, Paper, Typography } from "@mui/material";
import { LargeCheckbox } from "../../../../../../../components/Checkboxes";
import { func, bool } from "prop-types";

const RepsHeader = ({ handleSelectAll, allSelected, disabled }) => {
  const classes = {
    grid: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    typography: {
      fontSize: "12px",
      fontWeight: "400",
      color: "#6A6A6A",
      textTransform: "uppercase",
    },
  };
  return (
    <Paper
      sx={{
        backgroundColor: "#EEEEEE",
        borderRadius: "4px 4px 0 0",
      }}
      elevation={0}
      variant="outlined"
      square
      component={Grid}
      container
      columns={16}
      height="48px"
    >
      {" "}
      <Grid item xs={1} sx={classes.grid}>
        <LargeCheckbox
          sx={{ padding: 0 }}
          formSx={{ m: 0 }}
          onChange={handleSelectAll}
          checked={allSelected}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={5} sx={classes.grid}>
        <Typography pr={16} sx={classes.typography}>
          Representative name
        </Typography>
      </Grid>
      <Grid
        item
        xs={3.5}
        sx={{ ...classes.grid, justifyContent: "flex-start" }}
      >
        <Typography sx={classes.typography}>Role</Typography>
      </Grid>
      <Grid item xs={4} sx={{ ...classes.grid, justifyContent: "flex-start" }}>
        <Typography sx={classes.typography}>Territory</Typography>
      </Grid>
      <Grid item xs={2.5} sx={classes.grid}>
        <Typography sx={classes.typography}>Customer count</Typography>
      </Grid>
    </Paper>
  );
};

RepsHeader.propTypes = {
  handleSelectAll: func,
  allSelected: bool,
  disabled: bool,
};

export default RepsHeader;
