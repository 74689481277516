import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  checkboxContainerWrapper: {
    padding: "10px 0",
    width: "100%",
    maxWidth: "300px",
    overflowX: "hidden",

    "& .MuiTypography-root": {
      fontSize: "13px",
      color: "#5F6267",
    },

    "&:not(:last-of-type)": {
      borderBottom: "0.5px solid #d5d9d9",
    },
  },

  customerTitle: {
    fontWeight: 600,
  },

  customerId: {
    fontWeight: 300,
    whiteSpace: "nowrap",
  },
}));

export default useStyles;
