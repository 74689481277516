import { useState } from "react";
import { bulkAssignRepresentativesService } from "services/routes";
import { error, success } from "utils/notifications";

export const useAssignedReps = ({
  selectedRoutes,
  handleSetLoading,
  handleGetAllRoutes,
  fetchQuery,
}) => {
  const [assignDialogState, setAssignDialogState] = useState({
    open: false,
    reps: [],
    routes: [],
  });

  const handleAssign = (reps, routes) => {
    setAssignDialogState((prev) => ({
      ...prev,
      open: true,
      reps,
      routes,
    }));
  };

  const handleOpenTransfer = () => {
    const getCommonStrings = (arrays) => {
      if (!arrays.length || !arrays) return [];
      const map = new Map();

      arrays?.forEach((array) => {
        array?.forEach((obj) => {
          const id = obj?.representative?.id;
          map.set(id, (map.get(id) || 0) + 1);
        });
      });

      const commonObjects = [];
      arrays?.[0]?.forEach((obj) => {
        if (map.get(obj?.representative?.id) === arrays.length) {
          commonObjects.push(obj);
        }
      });

      return commonObjects.map((obj) => obj?.representative);
    };

    const preparedAssignedReps = () => {
      const assignedRepsArr = selectedRoutes.map(
        (t) => t?.assignedRepresentatives
      );

      return getCommonStrings(assignedRepsArr);
    };

    setAssignDialogState({
      open: true,
      reps: preparedAssignedReps(),
      routes: [...selectedRoutes],
    });
  };

  const bulkAssignRepresentatives = (reps, routes) => {
    const { didCancel, ...rest } = fetchQuery;

    handleSetLoading(true);
    const routesIds = routes.map((r) => r.id);
    const representativesIds = reps.map((r) => r.id);

    const transferData = {
      routesIds,
      representativesIds,
    };

    bulkAssignRepresentativesService(transferData)
      .then(() => {
        handleGetAllRoutes(rest, false, true);
        success("Route(s) updated successfully.");
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err.message);
        error(err.response?.data?.message || "Something went wrong.");
      });
    //.finally(() => handleSetLoading(false));
  };

  return {
    assignDialogState,
    handleAssign,
    setAssignDialogState,
    handleOpenTransfer,
    bulkAssignRepresentatives,
  };
};
