import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  header: {
    padding: "24px 0 19px 34px",
    borderBottom: "1px solid #d5d9d9",
    "& .MuiTypography-root": { fontSize: "30px" },
  },

  body: {
    padding: "18px 51px 0 50px",
    maxHeight: "100%",
    display: "flex",

    "& > .MuiBox-root": {
      flex: "1 1 auto",
    },
  },
}));

export default useStyles;
