import { useContext, useState } from "react";
import { Box, Grid } from "@mui/material";
import {
  AddressesBlock,
  ContactsBlock,
  CustomerDetailsBlock,
  DiscountsBlock,
  LicensesBlock,
  PaymentTermsBlock,
  ReceivingHoursBlock,
  TagsBlock,
  WrapperBlock,
  WrapperWithBtnBlock,
} from "./components";
import { useLicenses } from "./components/LicensesBlock/useLicenses";
import LicensesDrawer from "components/LicensesDrawer/LicensesDrawer";
import { CustomerContext } from "../../CustomerPage";
import { useReceivingHours } from "./components/ReceivingHoursBlock/useReceivingHours";
import ReceivingHoursDrawer from "components/ReceivingHoursDrawer/ReceivingHoursDrawer";
import ContactAddDrawer from "components/ContactsSection/ContactPopup/ContactAddDrawer";
import { useContacts } from "./components/ContactsBlock/useContacts";
import RepsAssignedDrawer from "components/RepsAssignedDrawer/RepsAssignedDrawer";
import { AssignedRepsBlock } from "./components/AssignedRepsBlock/AssignedRepsBlock";
import { useAssignedRepsBlock } from "./components/AssignedRepsBlock/useAssignedRepsBlock";
import { ProfileComponent } from "Pages/RepresentativesPage/components";
import { useEditRep } from "../../CustomerPage.hooks";
import { error, success } from "utils/notifications";
import { ProductCategoriesBlock } from "./components/ProductCategoriesBlock";
import { RatingBlock } from "./components/RatingBlock";
import { handleCustomerCategories } from "../../CustomerPage.helpers";
import ContactDeletePopup from "components/ContactsSection/ContactPopup/ContactDeletePopup";

export const CustomerMainBody = () => {
  const { setValue, formField } = useContext(CustomerContext);

  const {
    licenses,
    weekDays,
    representatives,
    contacts: formFieldContacts,
  } = formField || {};

  const {
    openLicenses,
    handleOpenLicenses,
    handleCloseLicenses,
    handleSetLicenseData,
    editLicense,
    setEditLicense,
    handleDeleteLicense,
    dialogState,
    setDialogState,
    handleDeleteLicenseFile,
  } = useLicenses({ licenses, setValue });

  const {
    assignedRepsOpen,
    handleCloseAssignedReps,
    handleOpenAssignedReps,
    handleSetAssignedRepsData,
    assignedRepresentatives,
    handleSaveAssignReps,
    editAssignedReps,
    handleRemoveReps,
  } = useAssignedRepsBlock({ representatives, setValue });

  const {
    openReceivingHours,
    handleOpenReceivingHours,
    handleCloseReceivingHours,
    handleSetHours,
    removeAllHours,
    hasAnySetHours,
    setOpenReceivingHoursFunc,
  } = useReceivingHours({ setValue, weekDays });

  const {
    openContacts,
    handleOpenContacts,
    handleCloseContacts,
    contacts,
    handleAddContact,
    handleRemoveContact,
    editContact,
    setEditContact,
  } = useContacts({ initialContacts: formFieldContacts, setValue });

  const {
    open: isOpenRepEditDialog,
    item: itemRepEditDialog,
    openRepEditDialog,
    closeRepEditDialog,
  } = useEditRep();

  const { rating, reviews, price, categories } = formField;

  const customerCategories = formField.id
    ? handleCustomerCategories(categories)
    : categories;

  const [deleteContact, setDeleteContact] = useState(null);
  const [deleteContactOpen, setDeleteContactOpen] = useState(false);

  return (
    <Box
      border="0.5px solid #D5D9D9"
      borderRadius="4px"
      width="1098px"
      p="20px 20px 20px"
      backgroundColor="#FFF"
    >
      <ContactAddDrawer
        isOpen={openContacts}
        handleClose={handleCloseContacts}
        // type={}
        editContact={editContact}
        handleAddContact={handleAddContact}
        contacts={contacts}
        handleDeleteContact={(contact) => {
          setDeleteContact(contact);
          setDeleteContactOpen(true);
        }}
      />
      <RepsAssignedDrawer
        isOpen={assignedRepsOpen}
        editAssignedReps={editAssignedReps}
        handleClose={handleCloseAssignedReps}
        handleSave={handleSaveAssignReps}
        handleSetData={handleSetAssignedRepsData}
        assignedRepresentatives={assignedRepresentatives}
        submitBtnLabel="Update"
      />
      <LicensesDrawer
        isOpen={openLicenses}
        editLicense={editLicense}
        handleClose={handleCloseLicenses}
        handleSetData={handleSetLicenseData}
        {...{ dialogState, setDialogState, handleDeleteLicenseFile }}
        handleDeleteLicense={(license) => {
          handleDeleteLicense({ licenses, license });
        }}
      />

      <ContactDeletePopup
        contact={deleteContact}
        isOpen={deleteContactOpen}
        handleClose={() => setDeleteContactOpen(false)}
        handleDeleteContact={handleRemoveContact}
      />

      <ReceivingHoursDrawer
        isOpen={openReceivingHours}
        handleClose={handleCloseReceivingHours}
        handleSetData={handleSetHours}
        weekDays={weekDays}
      />

      <ProfileComponent
        profile={itemRepEditDialog}
        isOpenProfileDialog={isOpenRepEditDialog}
        handleCloseProfileDialog={closeRepEditDialog}
        handleSuccess={(res) => {
          const tempArr = [...representatives];

          const index = tempArr.findIndex((item) => item?.id === res?.id);
          if (index !== -1) {
            tempArr[index] = res;
          }

          setValue("representatives", tempArr, { shouldDirty: true });
          success("Representative updated successfully.");
        }}
        handleError={(err) => {
          error(err?.response?.data?.message || "Something went wrong.");
        }}
      />

      <Grid container>
        {/* left block */}
        <Grid alignContent="flex-start" gap={2} xs={6} item container>
          <WrapperBlock
            title="Customer details"
            element={
              <RatingBlock
                widthCheck={!price ? 26 : 20}
                categories={customerCategories}
                {...{ reviews, price, rating }}
              />
            }
          >
            <CustomerDetailsBlock />
          </WrapperBlock>

          <WrapperBlock title="Shipping address">
            <AddressesBlock />
          </WrapperBlock>

          <WrapperBlock title="Payments">
            <PaymentTermsBlock />
          </WrapperBlock>

          <WrapperBlock title="Discounts">
            <DiscountsBlock />
          </WrapperBlock>
        </Grid>

        {/* right block */}
        <Grid alignContent="flex-start" gap={2} xs={6} item container>
          <WrapperWithBtnBlock
            title="Representatives"
            labelBtn="Assign"
            onAdd={handleOpenAssignedReps}
          >
            {assignedRepresentatives?.length ? (
              <Box my={1.75}>
                <AssignedRepsBlock
                  assignedReps={assignedRepresentatives}
                  onEdit={(item) => {
                    openRepEditDialog(item);
                  }}
                  onRemove={handleRemoveReps}
                />
              </Box>
            ) : null}
          </WrapperWithBtnBlock>

          <WrapperWithBtnBlock title="Contacts" onAdd={handleOpenContacts}>
            {contacts?.length ? (
              <Box my={1.75}>
                <ContactsBlock
                  contacts={contacts}
                  onEdit={(contact) => {
                    setEditContact(contact);
                    handleOpenContacts();
                  }}
                  onRemove={(contact) => {
                    setDeleteContact(contact);
                    setDeleteContactOpen(true);
                  }}
                />
              </Box>
            ) : null}
          </WrapperWithBtnBlock>

          <WrapperWithBtnBlock
            title="Licenses & Certifications"
            onAdd={handleOpenLicenses}
          >
            {licenses?.length > 0 ? (
              <Box my={1.75}>
                <LicensesBlock
                  licenses={licenses}
                  setValue={setValue}
                  onEdit={(license) => {
                    setEditLicense(license);
                    handleOpenLicenses();
                  }}
                  onDelete={handleDeleteLicense}
                  handleDeleteLicenseFile={handleDeleteLicenseFile}
                />
              </Box>
            ) : null}
          </WrapperWithBtnBlock>

          <WrapperWithBtnBlock
            title="Working Hours"
            onAdd={setOpenReceivingHoursFunc}
          >
            {hasAnySetHours && (
              <ReceivingHoursBlock
                onEdit={handleOpenReceivingHours}
                onRemove={removeAllHours}
              />
            )}
          </WrapperWithBtnBlock>

          <WrapperWithBtnBlock title="Product Categories">
            <ProductCategoriesBlock />
          </WrapperWithBtnBlock>

          <WrapperWithBtnBlock title="Tags">
            <TagsBlock />
          </WrapperWithBtnBlock>
        </Grid>
      </Grid>
    </Box>
  );
};
