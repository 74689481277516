import { useState } from "react";

const files = [
  {
    id: "1",
    type: "folder",
    fileName: "Folder1",
    size: "868 KB",
    owner: "Admin",
    updated: "11/10/2022",
  },
  {
    id: "2",
    type: "folder",
    fileName:
      "Folder2 Lorem, ipsum dolor sit amet consectetur adipisicing elit. Corrupti praesentium consequuntur cumque consectetur totam, commodi illo voluptatibus! Provident, alias ratione.",
    size: "1868 KB",
    owner: "Admin",
    updated: "11/10/2022",
  },
  {
    id: "3",
    type: "folder",
    fileName: "Folder3",
    size: "68 KB",
    owner: "Admin",
    updated: "11/10/2022",
  },
  {
    id: "4",
    type: "img",
    fileName: "Filename.PNG",
    size: "1168 KB",
    owner: "Admin",
    updated: "11/10/2022",
  },
];

export const useFilesActions = () => {
  const [filesList, setFilesList] = useState([...files]);

  const handleDeleteFileOrBtn = (id) => {
    setFilesList((prev) => prev.filter((file) => file.id !== id));
  };

  return {
    filesList,
    setFilesList,
    handleDeleteFileOrBtn,
  };
};
