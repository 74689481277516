import axios from "axios";

export const getTopSummariesService = async (params) => {
  return await axios
    .get("/users/distributors/dashboard/top-summaries", { params })
    .then((res) => res.data);
};

export const getOrdersDashboardService = async (params) => {
  return await axios
    .get("/users/distributors/dashboard/orders", { params })
    .then((res) => res.data);
};

export const getBottomLeftPartService = async (params) => {
  return await axios
    .get("/users/distributors/dashboard/bottom-left-part", { params })
    .then((res) => res.data);
};

export const getActivitiesService = async (params, signal) => {
  return await axios
    .get("/users/distributors/dashboard/activities", { params, signal })
    .then((res) => res.data);
};

export const getChartService = async (params) => {
  return await axios
    .get("/users/distributors/dashboard/chart", { params })
    .then((res) => res.data);
};

export const getActivePromotionsService = async (params, signal) => {
  return await axios
    .get("/users/distributors/dashboard/get-active-promotions", {
      params,
      signal,
    })
    .then((res) => res.data);
};

export const getProductPerformanceService = async (params, signal) => {
  return await axios
    .get("/users/distributors/dashboard/get-product-performance", {
      params,
      signal,
    })
    .then((res) => res.data);
};
