/* eslint-disable react/prop-types */
import React from "react";

export const CrossBigIcon = ({ size, color, strokeWidth }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || 28.957}
      height={size || 28.958}
      viewBox="0 0 28.957 28.958"
    >
      <g
        id="Group_4164"
        data-name="Group 4164"
        transform="translate(-1391.521 -169.97)"
      >
        <line
          id="Line_883"
          data-name="Line 883"
          x2="27.896"
          y2="27.897"
          transform="translate(1392.051 170.5)"
          fill="none"
          stroke={color ?? "#707070"}
          strokeWidth={strokeWidth || 1.5}
        />
        <path
          id="Path_2134"
          data-name="Path 2134"
          d="M27.9,0,0,27.9"
          transform="translate(1392.051 170.5)"
          fill="none"
          stroke={color ?? "#707070"}
          strokeWidth={strokeWidth || 1.5}
        />
      </g>
    </svg>
  );
};
