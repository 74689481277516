import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  titleBlock: {
    fontSize: "20px",
    color: "#080808",
    fontWeight: 400,
    padding: "10px 30px 10px 30px",
    borderBottom: "0.5px solid #CCCCCC",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  customPaging: {
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    position: "absolute",
  },

  contentBlock: {
    padding: "0px",
    height: "calc(100vh - 60.5px)!important",
    boxSizing: "border-box",

    "& .slick-next:before": {
      display: "none",
    },

    "& .slick-dots li": {
      width: "10px",
      height: "10px",
    },

    "& .slick-dots": {
      bottom: 10,
    },

    "& .slick-prev:before": {
      display: "none",
    },
  },

  imageWrapper: {
    display: "flex!important",
    position: "relative",
    justifyContent: "center",
    height: "calc(100vh - 60.5px)!important",
    alignItems: "center",
  },

  blurBackground: {
    width: "100%",
    height: "100%",
    maxWidth: "100%",
    maxHeight: "100%",
    position: "absolute",
    filter: "blur(70px) brightness(112%)",
  },
}));

export default useStyles;
