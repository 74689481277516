import * as React from "react";

export const BillingDownloadIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={7.591}
    height={7.591}
    viewBox="0 0 7.591 7.591"
    {...props}
  >
    <path
      data-name="Icon open-data-transfer-download"
      d="M2.847 0v2.847H.949L3.8 5.693l2.842-2.846h-1.9V0ZM0 6.642v.949h7.591v-.949Z"
    />
  </svg>
);
