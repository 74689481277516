import React, { useRef, useState } from "react";
import { object, func, bool } from "prop-types";

import { Switcher } from ".";
import { StyledButton } from "components";
import { FilterIcon } from "components/Icons";

import { Menu } from "@mui/material";

export const Filter = ({
  control,
  handleSwitchClick,
  setValue,
  hasAnyInvites,
  hasAnyReceived,
}) => {
  const filterAnchor = useRef();
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);

  return (
    <>
      <StyledButton
        startIcon={<FilterIcon />}
        variant="outlined"
        ref={filterAnchor}
        color="edit"
        sx={{
          height: "27px",
          width: "100%",
          maxWidth: "29px",
          minWidth: "29px",
          border: "1px solid #D5D9D9",
          p: 0,
          "& .MuiButton-startIcon": {
            ml: 0,
            mr: 0,
          },
        }}
        fontSize="15px"
        onClick={() => setFilterMenuOpen(true)}
      />
      <Menu
        open={filterMenuOpen}
        anchorEl={filterAnchor.current}
        PaperProps={{
          sx: {
            boxShadow: "0px 6px 10px rgba(0,0,0,16%)",
          },
        }}
        onClose={() => setFilterMenuOpen(false)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
          sx: {
            width: "234px",
            pt: 0,
            pb: 0,
          },
        }}
      >
        <Switcher
          onSwitchClick={handleSwitchClick}
          setValue={setValue}
          control={control}
          hasAnyInvites={hasAnyInvites}
          hasAnyReceived={hasAnyReceived}
          closeMenu={() => setFilterMenuOpen(false)}
        />
      </Menu>
    </>
  );
};

Filter.propTypes = {
  control: object,
  handleSwitchClick: func,
  setValue: func,
  hasAnyInvites: bool,
  hasAnyReceived: bool,
};
Filter.defaultProps = {};
