import { useSearchParams } from "react-router-dom";
import { Box, Paper, Typography } from "@mui/material";
import { AppStoreBtn, GooglePlayBtn } from "components";
import { ActiveIcon } from "components/Icons";
import backgroundImg from "assets/images/login-background.jpg";
import { APP_STORE_LINK, GOOGLE_PLAY_LINK } from "utils/constants";

export const InfoPage = () => {
  const [params] = useSearchParams();

  // confirm | restore
  const type = params.get("type");

  return (
    <Box
      sx={{
        // minHeight: "100dvh",
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        py: 2,
        background: `linear-gradient(0deg, rgba(225, 225, 225, 0.7), rgba(225, 225, 225, 0.7)), url(${backgroundImg})`,
        backgroundSize: "100% 100%",
      }}
    >
      <Paper
        sx={{
          maxWidth: "636px",
          width: "100%",
          borderRadius: "16px",
          border: "0.5px solid #D5D9D9",
          mb: 1,
          mx: "1%",
          px: "3%",
          py: "3%",
        }}
        elevation={0}
        variant="outlined"
      >
        <Box
          sx={{
            height: "44px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mb: "6%",
          }}
        >
          <Typography
            sx={{
              fontSize: 32,
              fontWeight: 700,
              color: "#5F6267",
            }}
          >
            {type === "restore" ? "Password reset" : "Account activated"}
          </Typography>
        </Box>
        <Box
          sx={{
            border: "0.5px solid #EEEEEE",
            borderRadius: "4px",
            height: "174px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Typography fontSize={22} fontWeight={700} color="#000000">
            {type === "restore"
              ? "Password reset successfully"
              : "Password set successfully"}
          </Typography>
          <Box
            sx={{
              width: "48px",
              height: "48px",
              border: "2px solid #47A06D",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
            }}
          >
            <ActiveIcon width="21.55" height="16.12" />
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mt: 4,
          }}
        >
          <Box textAlign="center">
            <Typography fontSize={22} fontWeight={700} color="#000000">
              Download the app
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: 2.75,
              mt: 2.5,
            }}
          >
            <AppStoreBtn url={APP_STORE_LINK} />
            <GooglePlayBtn url={GOOGLE_PLAY_LINK} />
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};
