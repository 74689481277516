import React from "react";
/* eslint-disable react/prop-types */
export const ClipBoard = ({ props }) => {
  return (
    <svg
      data-name="Icon awesome-clipboard-list"
      xmlns="http://www.w3.org/2000/svg"
      width={39.75}
      height={53}
      viewBox="0 0 39.75 53"
      {...props}
    >
      <path
        data-name="Icon awesome-clipboard-list"
        d="M34.781 6.625H26.5a6.625 6.625 0 1 0-13.25 0H4.969A4.97 4.97 0 0 0 0 11.594v36.437A4.97 4.97 0 0 0 4.969 53h29.812a4.97 4.97 0 0 0 4.969-4.969V11.594a4.97 4.97 0 0 0-4.969-4.969M9.938 43.891a2.484 2.484 0 1 1 2.484-2.484 2.48 2.48 0 0 1-2.484 2.484m0-9.937a2.484 2.484 0 1 1 2.484-2.484 2.48 2.48 0 0 1-2.484 2.483Zm0-9.938a2.484 2.484 0 1 1 2.484-2.484 2.48 2.48 0 0 1-2.484 2.484m9.937-19.875a2.484 2.484 0 1 1-2.484 2.484 2.48 2.48 0 0 1 2.484-2.484m13.25 38.094a.83.83 0 0 1-.828.828H17.391a.83.83 0 0 1-.828-.828v-1.657a.83.83 0 0 1 .828-.828H32.3a.83.83 0 0 1 .828.828Zm0-9.937a.83.83 0 0 1-.828.828H17.391a.83.83 0 0 1-.828-.828v-1.657a.83.83 0 0 1 .828-.828H32.3a.83.83 0 0 1 .828.828Zm0-9.938a.83.83 0 0 1-.828.828H17.391a.83.83 0 0 1-.828-.828V20.7a.83.83 0 0 1 .828-.828H32.3a.83.83 0 0 1 .828.828Z"
        fill="#707070"
      />
    </svg>
  );
};
