import { oneOf } from "prop-types";
import { Box, Typography } from "@mui/material";
import { PaymentStatus } from "../../../../../../..";
import { PAYMENT_STATUSES } from "../../../../../../helpers";

export const PaymentStatusBlock = ({ paymentStatus }) => {
  return (
    <Box display="flex">
      <Box>
        <Typography fontSize={12} color="#000000" mt={0.1} mr={1}>
          Payment Status
        </Typography>
      </Box>
      <PaymentStatus str={paymentStatus} />
    </Box>
  );
};

PaymentStatusBlock.propTypes = {
  paymentStatus: oneOf([...PAYMENT_STATUSES]),
};
PaymentStatusBlock.defaultProps = {
  paymentStatus: "",
};
