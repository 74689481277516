import { object, shape, array } from "prop-types";
import { Box, Divider, MenuItem, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { StyledSelect, StyledTextField } from "components";

export const ShippingCarrierBlock = ({ control, state }) => {
  const { shippingCarriers } = state || {};

  return (
    <Box>
      <Box display="flex" width="100%">
        <Box ml={2} width="39%">
          <Typography mb={0.5} fontWeight={400} color="#000000" fontSize={12}>
            Tracking ID
          </Typography>
        </Box>

        <Box width="15%" />

        <Box width="39%">
          <Typography fontWeight={400} color="#000000" fontSize={12} mb={0.5}>
            Shipping carrier
          </Typography>
        </Box>
      </Box>

      <Box display="flex" width="100%">
        <Box ml={2} width="39%">
          <Box>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <StyledTextField
                  fullWidth
                  InputProps={{
                    sx: {
                      fontSize: 12,
                      height: "28px",
                      fontWeight: 400,
                      "& .MuiInputBase-input": {
                        paddingLeft: "8px",
                        paddingRight: "8px",
                      },
                    },
                  }}
                  placeholder="Tracking ID"
                  noErrorMessage
                  error={!!error}
                  {...field}
                />
              )}
              name="trackingId"
              control={control}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "15%",
          }}
        >
          <Divider orientation="vertical" />
        </Box>
        <Box width="39%">
          <Box>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <StyledSelect
                  notched={false}
                  fullWidth
                  formSx={{
                    justifyContent: "space-between",
                    "& .MuiOutlinedInput-input": {
                      padding: "0 8px !important",
                    },
                    "& .MuiSvgIcon-root": {
                      right: "4px",
                    },
                  }}
                  height="28px"
                  size="small"
                  color="#000000"
                  displayEmpty
                  label=""
                  defaultValue=""
                  noErrorMessage
                  border="0.5px solid #D5D9D9"
                  error={error?.message || ""}
                  {...field}
                >
                  <MenuItem sx={{ display: "none" }} value="">
                    <Box component="span" color="#ACACAC">
                      Shipping carrier
                    </Box>
                  </MenuItem>
                  {shippingCarriers.map(({ id, name }) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  ))}
                </StyledSelect>
              )}
              name="shippingCarrier"
              control={control}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

ShippingCarrierBlock.propTypes = {
  control: object,
  state: shape({
    shippingCarriers: array,
  }),
};
ShippingCarrierBlock.defaultProps = {
  control: {},
  state: { shippingCarriers: [] },
};
