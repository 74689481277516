import { Box, Typography } from "@mui/material";

export const DialogTitleComponent = () => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Box textAlign="center" mt={-1}>
        <Typography fontSize={32} fontWeight={400} color="#5F6267">
          Your trial has ended
        </Typography>
      </Box>
      <Box sx={{ textAlign: "center" }}>
        <Typography mt="10px" fontSize={16} fontWeight={300} color="#1C1C19">
          Thanks for taking the time to explore SimplyDepo
          <br />
          platform in action. Please reach out to our
          <br />
          Sales team to start your subscription.
        </Typography>
      </Box>
    </Box>
  );
};
