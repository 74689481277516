export const cl = {
  tableWrapper: {
    borderRadius: "0 0 4px 4px",
    borderWidth: "0 1px 1px 1px",
    borderStyle: "solid",
    borderColor: "#D5D9D9",
  },

  headerWrapper: {
    width: "100%",
    height: "39px",
    minHeight: "39px",
    backgroundColor: "#EEEEEE",
    border: "1px solid #D5D9D9",
    borderRadius: "4px 4px 0 0",
    alignItems: "center",
  },

  headerExpandWrapper: {
    width: "100%",
    height: "32px",
    minHeight: "32px",
    backgroundColor: "rgba(238, 238, 238, 0.3)",
    borderBottom: "1px solid #D5D9D9",
    borderRadius: 0,
    alignItems: "center",
  },

  wrapper: {
    height: 40,
    minHeight: 40,
    borderRadius: "0 0 4px 4px",
    "&:not(:last-of-type)": {
      borderBottom: "1px solid #D5D9D9",
      borderRadius: 0,
    },
  },

  grid: {
    display: "flex",
    alignItems: "center",
  },

  gridCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  typography: {
    fontSize: 12,
    fontWeight: 300,
    color: "#1C1C19",
  },

  typographyPlan: {
    pl: 3.5,
    fontSize: 12,
    fontWeight: 300,
    color: "#1C1C19",
  },

  invoice: {
    wrapper: {
      display: "flex",
      alignItems: "center",
    },
  },
};
