import { styled } from "@mui/material";

const TableHeader = styled("div")(({ theme: { spacing, palette } }) => ({
  padding: spacing(1.5, 3),
  background: palette.headerGrey.main,
  borderBottom: `0.5px solid ${palette.border.main}`,
}));

const TableBody = styled("div")(({ theme: { spacing, palette } }) => ({
  background: palette.common.white,
  height: "100%",
  borderRadius: spacing(0, 0, 0.5, 0.5),
  "&::-webkit-scrollbar": {
    backgroundColor: palette.inactive.main,
    width: "9px",
  },
  "&::-webkit-scrollbar-thumb": {
    borderRadius: "8px",
    backgroundColor: "#a8a8a8",
    border: "2px solid transparent",
    backgroundClip: "content-box",
  },
}));

export { TableHeader, TableBody };
