import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { bool, func, array } from "prop-types";
import { useState, useEffect, useMemo } from "react";
import { CrossBigIcon } from "../../../../components/Icons";
import StyledButton from "../../../../components/StyledButton";
import { containsEvery, useAdmin } from "../../../../helpers/helpers";
import { useDebounce } from "../../../../helpers/hooks";
import { getCustomersService } from "../../../../services/customers";
import {
  CUSTOMER_STATUS_FILTERS,
  UNCATEGORIZED_GROUP,
  UNCATEGORIZED_TERRITORY,
} from "../../../../utils/constants";
import { CustomersTabFilter } from "../../../RepresentativesPage/components/ProfileComponent/TabsContent/TabCustomersComponent/CustomersTabFilter";
import CustomersTableComponent from "../../../RepresentativesPage/components/ProfileComponent/TabsContent/TabCustomersComponent/CustomersTableComponent/CustomersTableComponent";
import lodash, { snakeCase } from "lodash";

const AllCustomersPopup = ({
  open,
  onClose,
  handleAddCustomers,
  addedStops,
  viewCustomerPopup,
  hideActive,
  allowMissingInfo,
}) => {
  const isAdmin = useAdmin();
  const defaultQuery = useMemo(
    () => ({
      territory_id: "",
      representative_id: "",
      search: "",
      status: CUSTOMER_STATUS_FILTERS.active,
    }),
    []
  );

  const [query, setQuery] = useState(defaultQuery);

  const searchDebounced = useDebounce(query.search, 500);

  const [page, setPage] = useState(1);

  //   const [customersList, setCustomersList] = useState([]);
  const [customersState, setCustomersState] = useState({
    list: [],
    checked: [],
    count: 0,
  });

  //   const [checkedCustomers, setCheckedCustomers] = useState([]);

  useEffect(() => {
    if (open) setCustomersState((prev) => ({ ...prev, checked: addedStops }));

    return () => {
      setQuery(defaultQuery);
    };
  }, [open, addedStops, defaultQuery]);

  useEffect(() => {
    setPage(1);
    getCustomersService({
      ...query,
      search: searchDebounced,
      limit: 10,
      page: 1,
      status: `["${query.status}"${
        viewCustomerPopup ? `, "${CUSTOMER_STATUS_FILTERS.prospect}"` : ""
      }]`,
    }).then((res) =>
      setCustomersState((prev) => ({
        ...prev,
        list: [...res.rows],
        count: res.count,
        fetched: true,
      }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    query.territory_id,
    query.group_id,
    query.representative_id,
    query.parent_customer_id,
    query.tag_id,
    query.sort_name,
    query.sort_orders,
    query.sort_newest,
    searchDebounced,
    query.status,
    query.last_visit,
    query.last_visit_than,
    query.last_visit_start_date,
    query.last_visit_end_date,
    query.last_order,
    query.last_order_end_date,
    query.last_order_start_date,
    query.last_order_than,
    query.with_missing_info,
    query.with_duplicated_info,
    query.order_direct,
    query.tasks_due,
    query.tasks_due_start_date,
    query.tasks_due_end_date,
    query.has_assigned_routes,
  ]);

  //   useEffect(() => {
  //     getCustomersService({...query, limit: 10, page: 1}).then((res) => setCustomersList([...res.rows]));
  //   }, [query]);

  const setHasAssignedRoutesValue = (value) => {
    if (value === "ASSIGNED") return true;
    if (value === "NOT_ASSIGNED") return false;
    return undefined;
  };

  const handleCustomersFilter = (e) => {
    const val = e?.target?.value ?? e;
    const sort = val.sort_by ? val.sort_by.value.split(" ") : null;

    const { last_visit, last_order, issues, tasks_due, has_assigned_routes } =
      val;

    let removeSort = null;
    if (sort?.[0] === "sort_name") removeSort = "sort_orders";
    else removeSort = "sort_name";

    const q = {
      parent_customer_id:
        val?.group_id?.value === UNCATEGORIZED_GROUP.id
          ? "null"
          : val?.group_id?.value,
      territory_id:
        val?.territory_id?.value === UNCATEGORIZED_TERRITORY.id
          ? "null"
          : val?.territory_id?.value,
      representative_id: val?.representative_id?.value,
      tag_id: val?.tag_id?.value,
      [removeSort]: null,
      [sort?.[0]]: sort?.[1],
      last_visit: last_visit?.value?.start_date
        ? undefined
        : lodash.snakeCase(last_visit?.value),
      last_visit_than: ["more_than", "less_than"].includes(last_visit?.value)
        ? last_visit.days || 1
        : undefined,
      last_visit_start_date: last_visit?.value?.start_date,
      last_visit_end_date: last_visit?.value?.end_date,

      last_order: last_order?.value?.start_date
        ? undefined
        : snakeCase(last_order?.value),
      last_order_than: ["more_than", "less_than"].includes(last_order?.value)
        ? last_order.days || 0
        : undefined,
      last_order_start_date: last_order?.value?.start_date,
      last_order_end_date: last_order?.value?.end_date,
      with_missing_info: issues?.value === "with_missing_info" || undefined,
      with_duplicated_info:
        issues?.value === "with_duplicated_info" || undefined,
      order_direct: !val?.order_direct
        ? null
        : JSON.stringify([val?.order_direct.value]),
      tasks_due: tasks_due?.value?.start_date
        ? undefined
        : snakeCase(tasks_due?.value),
      tasks_due_start_date: tasks_due?.value?.start_date,
      tasks_due_end_date: tasks_due?.value?.end_date,
      has_assigned_routes: setHasAssignedRoutesValue(has_assigned_routes),
    };

    setQuery((prev) => ({ ...prev, ...q }));
  };

  const handleFetchCustomers = () => {
    setPage((prev) => prev + 1);
    getCustomersService({
      ...query,
      search: searchDebounced,
      limit: 10,
      page: page + 1,
      status: `["${query.status}"${
        viewCustomerPopup ? `, "${CUSTOMER_STATUS_FILTERS.prospect}"` : ""
      }]`,
    }).then((res) =>
      setCustomersState((prev) => ({
        ...prev,
        list: [...prev.list, ...res.rows],
        count: res.count,
      }))
    );
  };

  const handleCheckCustomer = (customer, checked) => {
    if (checked)
      return setCustomersState((prev) => ({
        ...prev,
        checked: prev.checked.filter((item) => item.id !== customer.id),
      }));

    setCustomersState((prev) => ({
      ...prev,
      checked: [...prev.checked, customer],
    }));
  };

  const handleCheckOneCustomer = (customer) => {
    setCustomersState((prev) => ({
      ...prev,
      checked: [customer],
    }));
  };

  const checkAllCustomers = (checked) => {
    if (checked) return setCustomersState((prev) => ({ ...prev, checked: [] }));
    setCustomersState((prev) => ({ ...prev, checked: [...prev.list] }));
  };

  const fetchListAndCheckAll = () => {
    getCustomersService({
      ...query,
      search: searchDebounced,
    }).then((res) =>
      setCustomersState((prev) => ({
        ...prev,
        checked: [...res.rows],
      }))
    );
  };

  return (
    <Dialog
      {...{ open }}
      PaperProps={{ sx: { maxWidth: "980px", height: "708px" } }}
    >
      <DialogTitle
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ p: "37px 26px 24px 26px" }}
      >
        <Typography fontSize="30px" color="#707070">
          All customers
        </Typography>
        <IconButton onClick={onClose}>
          <CrossBigIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ minWidth: "980px", p: "0" }}>
        <CustomersTabFilter
          hideActive={hideActive}
          viewCustomerPopup={viewCustomerPopup}
          isRoute
          {...{ handleCustomersFilter }}
          customersSearchInput={query.search}
          queryStatus={query.status}
          setStatus={(status) => setQuery((prev) => ({ ...prev, status }))}
          setCustomersSearchInput={(search) =>
            setQuery((prev) => ({ ...prev, search }))
          }
        />
        <CustomersTableComponent
          maxHeight="372px"
          {...{
            handleFetchCustomers,
            handleCheckCustomer,
            checkAllCustomers,
            fetchListAndCheckAll,
            handleCheckOneCustomer,
            allowMissingInfo,
          }}
          viewCustomerPopup={viewCustomerPopup}
          customersCount={customersState.count}
          customersList={customersState.list}
          checkedCustomers={customersState.checked}
          allCustomersChecked={containsEvery(
            customersState.list,
            customersState.checked
          )}
          fetched={customersState.fetched}
        />
        {!viewCustomerPopup && (
          <Typography fontSize="17px" color="#6E6E6E" m="17px 0 0 45px">
            Showing {customersState.list.length} customers
          </Typography>
        )}
      </DialogContent>
      <DialogActions sx={{ p: "20px 24px 28px" }}>
        {viewCustomerPopup && (
          <StyledButton label="Cancel" color="cancel" onClick={onClose} />
        )}
        <StyledButton
          disabled={isAdmin}
          variant="contained"
          label="Add"
          onClick={() =>
            handleAddCustomers(
              viewCustomerPopup
                ? customersState.checked[0]
                : customersState.checked
            )
          }
        />
      </DialogActions>
    </Dialog>
  );
};

AllCustomersPopup.propTypes = {
  open: bool,
  onClose: func,
  handleAddCustomers: func,
  addedStops: array,
  viewCustomerPopup: bool,
  hideActive: bool,
  allowMissingInfo: bool,
};

AllCustomersPopup.defaultProps = {
  open: false,
  onClose: () => {},
  addedStops: [],
  hideActive: false,
};

export default AllCustomersPopup;
