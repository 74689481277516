export const cl = {
  NewDiscountsFilter: {
    wrapper: {
      backgroundColor: "#ffffff",
      borderRadius: "4px",
      display: "flex",
      border: "1px solid #d5d9d9",
      alignItems: "center",
      padding: "0px 17px 0px 17px",
      height: "67px",
      gap: "11px",
    },
  },
};
