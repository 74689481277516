import React from "react";

export const DownloadIcon = () => {
  return (
    <svg
      id="Icon_awesome-download"
      data-name="Icon awesome-download"
      xmlns="http://www.w3.org/2000/svg"
      width="16.4"
      height="16.4"
      viewBox="0 0 16.4 16.4"
    >
      <path
        id="Icon_awesome-download-2"
        data-name="Icon awesome-download"
        d="M6.919,0H9.481a.767.767,0,0,1,.769.769V6.15h2.809a.639.639,0,0,1,.452,1.092L8.639,12.117a.621.621,0,0,1-.874,0L2.886,7.242A.639.639,0,0,1,3.338,6.15H6.15V.769A.767.767,0,0,1,6.919,0ZM16.4,12.044v3.587a.767.767,0,0,1-.769.769H.769A.767.767,0,0,1,0,15.631V12.044a.767.767,0,0,1,.769-.769h4.7l1.57,1.57a1.641,1.641,0,0,0,2.325,0l1.57-1.57h4.7A.767.767,0,0,1,16.4,12.044Zm-3.972,2.819a.641.641,0,1,0-.641.641A.643.643,0,0,0,12.428,14.862Zm2.05,0a.641.641,0,1,0-.641.641A.643.643,0,0,0,14.478,14.862Z"
        fill="#b5b5ac"
      />
    </svg>
  );
};
