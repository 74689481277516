import { Box, Stack, Typography } from "@mui/material";
import { StyledButton, StyledLabel, TypographyAddress } from "components";
import useStyles from "Pages/CartPage/styles";
import React, { useMemo } from "react";
import { func, number, object, string } from "prop-types";
import { CUSTOMER_STATUS_FILTERS } from "utils/constants";

export const AddressCard = ({
  addressTab,
  formField,
  setHoveredElement,
  widthOnHover,
  CUSTOMER_ADDRESS_INFO,
  showOnHover,
  handleOpenEditCustomer,
}) => {
  const classes = useStyles();
  const isProspect = useMemo(
    () => formField.customer.status === CUSTOMER_STATUS_FILTERS.prospect,
    [formField.customer.status]
  );
  return (
    <Box
      onMouseEnter={() => {
        setHoveredElement(true);
      }}
      onMouseLeave={() => setHoveredElement(false)}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      flexDirection="row"
      className={classes.addressWrapper}
    >
      <Stack direction="column" width={widthOnHover}>
        <Stack direction="row" gap="2px">
          <Typography color="#5F6267" fontSize="13px" fontWeight={700} noWrap>
            {formField.customer.displayName || formField.customer.name}{" "}
          </Typography>
          <Typography
            color="#5F6267"
            fontSize="13px"
            fontWeight={300}
            whiteSpace="nowrap"
          >
            (ID: {formField.customer?.customId?.customId})
          </Typography>
          {isProspect && (
            <StyledLabel style={{ marginLeft: "5px" }} text="prospect" />
          )}
        </Stack>

        <TypographyAddress
          noWrap
          address={CUSTOMER_ADDRESS_INFO[addressTab]?.formatted_address}
          styles={{
            color: "#9C9C94",
            fontSize: "12px",
            fontWeight: 400,
          }}
        />
      </Stack>
      <Box sx={{ display: showOnHover }} className={classes.editBtnWrapper}>
        <StyledButton
          label="Edit"
          variant="outlined"
          color="ground"
          fontSize="10px"
          fontWeight="500"
          onClick={handleOpenEditCustomer}
        />
      </Box>
    </Box>
  );
};

AddressCard.propTypes = {
  formField: object,
  addressTab: number,
  setHoveredElement: func,
  widthOnHover: string,
  CUSTOMER_ADDRESS_INFO: object,
  showOnHover: string,
  handleOpenEditCustomer: func,
};
