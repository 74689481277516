import { string, func } from "prop-types";
import { Box, Typography } from "@mui/material";
import { NewPaperIcon } from "../../../../../../components/Icons";

export const NoteItem = ({ handleClickCustomerNotes, size }) => {
  return (
    <Box
      sx={{
        border: "0.2px solid #D5D9D9",
        borderRadius: "4px",
        width: size === "small" ? "40px" : "57px",
        maxWidth: size === "small" ? "40px" : "57px",
        minWidth: size === "small" ? "40px" : "57px",
        height: size === "small" ? "40px" : "57px",
        maxHeight: size === "small" ? "40px" : "57px",
        minHeight: size === "small" ? "40px" : "57px",
        display: "flex !important",
        flexDirection: "column",
        justifyContent: "space-between",
        cursor: "pointer",
        backgroundColor: "#F6F6F6",
        position: "relative",
      }}
      onClick={handleClickCustomerNotes}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-end",
          flexGrow: 1,
          mt: size === "small" && "4px",
          pb: size !== "small" && "6px",
        }}
      >
        <NewPaperIcon style={{ marginLeft: "6px" }} />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "18px",
          borderRadius: "0 0 4px 4px",
        }}
      >
        <Typography
          sx={{
            fontSize: 8,
            fontWeight: 600,
            color: "#5F6267",
          }}
        >
          Note
        </Typography>
      </Box>
      {/* {noteCount > 0 && (
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            backgroundColor: "#5F6267",
            display: "flex",
            pl: "2.5px",
            pr: "5px",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "14px",
          }}
        >
          <Typography
            sx={{
              fontSize: size === "small" ? 5 : 6,
              fontWeight: 600,
              color: "#FFF",
              ml: "2px",
            }}
          >{`${noteCount} NOTE${noteCount > 1 ? "S" : ""}`}</Typography>
        </Box>
      )} */}
    </Box>
  );
};

NoteItem.defaultProps = {
  handleClickCustomerNotes: () => {},
  size: "big",
};

NoteItem.propTypes = {
  handleClickCustomerNotes: func,
  size: string,
};
