/* eslint-disable react/prop-types */
import React from "react";

export const GoBackIcon = ({ color = "#fff" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.404"
      height="11.729"
      viewBox="0 0 13.404 11.729"
    >
      <path
        data-name="Icon metro-undo"
        d="M6.702 0a6.68 6.68 0 0 0-4.739 1.963L0 0v5.027h5.029L3.147 3.144a5.026 5.026 0 1 1 6.882 7.328l1.108 1.257A6.7 6.7 0 0 0 6.702 0"
        fill={color}
      />
    </svg>
  );
};
