import { number } from "prop-types";
import { Box, Typography } from "@mui/material";

export const CounterBlock = ({ count }) => {
  return (
    <Box mt="11px">
      <Typography fontSize={20} color="#707070">
        Mark {count} orders as fulfilled
      </Typography>
    </Box>
  );
};

CounterBlock.propTypes = { count: number };
CounterBlock.defaultProps = { count: 0 };
