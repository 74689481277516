import { useContext } from "react";
import { Grid } from "@mui/material";
import { Controller } from "react-hook-form";
import { CustomerContext } from "Pages/CustomersPage/pages/CustomerPage/CustomerPage";
import { StyledTextField } from "components";

export const BusinessEmailBlock = () => {
  const { control } = useContext(CustomerContext);

  return (
    <Grid xs={12} columnSpacing={2.75} item container>
      <Grid xs={6} item>
        <Controller
          render={({ field, fieldState: { error } }) => (
            <StyledTextField
              adminIsAllowed
              fullWidth
              noErrorMessage
              InputProps={{
                sx: {
                  fontSize: "13px",
                  height: "43px",
                  paddingRight: "6px",
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "13px",
                  color: "#5F6267",
                  top: 3,
                },
                // shrink: true,
              }}
              error={error ? error.message : ""}
              label="Business email"
              {...field}
            />
          )}
          name="email"
          control={control}
        />
      </Grid>

      <Grid xs={6} item>
        <Controller
          render={({ field, fieldState: { error } }) => (
            <StyledTextField
              adminIsAllowed
              fullWidth
              noErrorMessage
              InputProps={{
                sx: {
                  fontSize: "13px",
                  height: "43px",
                  paddingRight: "6px",
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "13px",
                  color: "#5F6267",
                  top: 3,
                },
                // shrink: true,
              }}
              error={error ? error.message : ""}
              label="Website"
              {...field}
            />
          )}
          name="website"
          control={control}
        />
      </Grid>
    </Grid>
  );
};
