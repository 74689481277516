import { Paper } from "@mui/material";
import { Box } from "@mui/system";
import { array, func } from "prop-types";
import { SearchDropDownButtons } from "../../../../../../components/Buttons";
import DiscountGroupItem from "../DiscountGroupItem/DiscountGroupItem";

const GroupsDropDown = ({
  groupsList,
  handleCheckGroup,
  checkedGroups,
  formGroups,
  onCancel,
  onAdd,
}) => {
  return (
    <Paper
      sx={{
        position: "absolute",
        zIndex: 10,
        width: "100%",
        left: 0,
        boxShadow: "none",
        border: "0.5px solid #D5D9D9",
      }}
    >
      <Box
        sx={{
          maxHeight: "130px",
          overflow: "overlay",
        }}
      >
        {groupsList.map((group) => (
          <DiscountGroupItem
            key={group.id}
            handleCheckItem={handleCheckGroup}
            item={group}
            isChecked={checkedGroups.some(
              (checkedGroup) => checkedGroup.id === group.id
            )}
            disabled={formGroups?.some(
              (formGroup) => formGroup.id === group.id
            )}
          />
        ))}
      </Box>
      <SearchDropDownButtons onCancel={onCancel} onAdd={onAdd} />
    </Paper>
  );
};

GroupsDropDown.propTypes = {
  checkedGroups: array,
  handleCheckGroup: func,
  groupsList: array,
  formGroups: array,
  onCancel: func,
  onAdd: func,
};

GroupsDropDown.defaultProps = {
  checkedGroups: [],
  groupsList: [],
  formGroups: [],
};

export default GroupsDropDown;
