export const ordersListSelector = (state) => state.orders.orders;
export const ordersGetParamsSelector = (state) => state.orders.params;
export const ordersCountSelector = (state) => state.orders.count;
export const ordersLoadingSelector = (state) => state.orders.loading;
export const editOrderSelector = (state) => state.orders.editOrder;
export const activitiesSelector = (state) => state.orders.activities;
export const activitiesCountSelector = (state) => state.orders.activitiesCount;
export const hasOrderDirectSelector = (state) => state.orders.hasOrderDirect;
export const hasQbOrdersSelector = (state) => state.orders.hasQbOrders;
export const ordersExistSelector = (state) => state.orders.existData;
export const ordersGetSwitchSelector = (state) => state.orders.ordersSwitch;
export const ordersGetFilterSelector = (state) => state.orders.ordersFilter;
export const ordersGetQuickFilterSelector = (state) =>
  state.orders.ordersQuickFilter;
export const existWithNoSalesAssignedSelector = (state) =>
  state.orders.existWithNoSalesAssigned;

export const ordersGetParamsThirdPartySelector = (state) =>
  state.orders.paramsThirdParty;
export const ordersThirdPartyListSelector = (state) =>
  state.orders.ordersThirdPartyList;
export const ordersThirdPartyCountSelector = (state) =>
  state.orders.ordersThirdPartyCount;
export const ordersExistThirdPartySelector = (state) =>
  state.orders.existDataThirdParty;
