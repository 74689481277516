import { object } from "prop-types";
import { Box } from "@mui/material";
import { StyledProductIco } from "components/Icons";
import { photoUrl } from "helpers/helpers";

export const PictureBlock = ({ productPhoto }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        height: "100%",
        width: "60px",
        minWidth: "50px",
      }}
    >
      <Box
        sx={{
          width: "38px",
          backgroundColor: "white",
          border: "1px solid #D5D9D9",
          borderRadius: "4px",
        }}
        height="38px"
      >
        <StyledProductIco
          src={productPhoto ? photoUrl(productPhoto?.fileName) : ""}
          styles={{
            objectFit: "contain",
            width: "100%",
            height: "100%",
            borderRadius: "4px",
          }}
        />
      </Box>
    </Box>
  );
};

PictureBlock.propTypes = { productPhoto: object };
