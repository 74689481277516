import { func, bool, object } from "prop-types";
import { Grid, Typography } from "@mui/material";
import { LargeCheckbox } from "../../../components/Checkboxes";
import TableHeader from "../../../components/TableHeader";

const HeaderList = ({ checkAllDiscounts, allDiscountsChecked, group }) => {
  const classes = {
    grid: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    typography: {
      mb: "2px",
      fontSize: "11px",
      color: "#1A1A1A",
      textTransform: "uppercase",
    },
  };

  return (
    <TableHeader
      sx={{
        backgroundColor: "#EEE",
        alignItems: "center",
        borderTop: "1px solid #D4D4D4",
        borderBottom: "1px solid #D4D4D4",
        borderWidth: "1px 0 1px 0",
      }}
      columns={24}
    >
      <Grid item xs={1.2} sx={{ ...classes.grid, mt: "-2px", pl: "5px" }}>
        <LargeCheckbox
          formSx={{ m: 0 }}
          stroke="#707070"
          sx={{ p: 0, ml: "9px" }}
          size={22}
          checked={
            group
              ? !!group?.discounts.length && allDiscountsChecked
              : allDiscountsChecked
          }
          onChange={() => checkAllDiscounts(group)}
        />
      </Grid>
      <Grid item xs={1.3} sx={classes.grid}>
        <Typography sx={classes.typography}>Status</Typography>
      </Grid>
      <Grid item xs={3} sx={classes.grid}>
        <Typography sx={classes.typography}>DISCOUNT NAME</Typography>
      </Grid>
      <Grid
        item
        xs={3.44}
        sx={{ ...classes.grid, justifyContent: "flex-start" }}
      >
        <Typography sx={classes.typography}>VALUE</Typography>
      </Grid>
      <Grid item xs={4} sx={{ ...classes.grid, justifyContent: "flex-start" }}>
        <Typography sx={classes.typography}>MINIMUM REQUIREMENTS</Typography>
      </Grid>
      <Grid item xs={4} sx={{ ...classes.grid, justifyContent: "flex-start" }}>
        <Typography sx={classes.typography}>END DATE</Typography>
      </Grid>
      <Grid item xs={3} sx={classes.grid}>
        <Typography sx={classes.typography}>ALLOWED USAGE</Typography>
      </Grid>
      <Grid item xs={2} sx={classes.grid}>
        <Typography sx={classes.typography}>USED</Typography>
      </Grid>
      <Grid sx={classes.grid} item xs={1.8} />
    </TableHeader>
  );
};

HeaderList.propTypes = {
  checkAllDiscounts: func,
  allDiscountsChecked: bool,
  group: object,
};
HeaderList.defaultProps = {
  allDiscountsChecked: false,
  group: null,
};

export default HeaderList;
