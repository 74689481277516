import { useMemo } from "react";

export const useSendNotifications = ({ order }) => {
  const hasCustomerEmail = useMemo(() => {
    return (
      !!order?.customer?.email ||
      order?.customer?.contacts?.some((contact) => contact?.email)
    );
  }, [order?.customer?.contacts, order?.customer?.email]);

  return {
    hasCustomerEmail,
  };
};
