import React from "react";

export const DiagramIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="52"
      height="52"
      viewBox="0 0 52 52"
    >
      <rect
        id="Rectangle_8859"
        data-name="Rectangle 8859"
        width="52"
        height="52"
        rx="26"
        fill="#409a65"
        opacity="0.1"
      />
      <g
        id="Component_6"
        data-name="Component 6"
        transform="translate(15.333 18)"
      >
        <g id="Group_9" data-name="Group 9" transform="translate(-1.333 -4)">
          <g id="icon_statistics">
            <g
              id="Rectangle"
              transform="translate(1.333 12)"
              fill="none"
              stroke="#409a65"
              strokeWidth="1.5"
            >
              <rect width="6.095" height="8" rx="1.5" stroke="none" />
              <rect
                x="0.75"
                y="0.75"
                width="4.595"
                height="6.5"
                rx="0.75"
                fill="none"
              />
            </g>
            <g
              id="Rectangle_Copy_5"
              data-name="Rectangle Copy 5"
              transform="translate(8.953 8)"
              fill="none"
              stroke="#409a65"
              strokeWidth="1.5"
            >
              <rect width="6.095" height="12" rx="1.5" stroke="none" />
              <rect
                x="0.75"
                y="0.75"
                width="4.595"
                height="10.5"
                rx="0.75"
                fill="none"
              />
            </g>
            <g
              id="Rectangle_Copy_7"
              data-name="Rectangle Copy 7"
              transform="translate(16.572 4)"
              fill="none"
              stroke="#409a65"
              strokeWidth="1.5"
            >
              <rect width="6.095" height="16" rx="1.5" stroke="none" />
              <rect
                x="0.75"
                y="0.75"
                width="4.595"
                height="14.5"
                rx="0.75"
                fill="none"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
