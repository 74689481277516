import * as React from "react";

export const CheckBoxReportCheckedIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19.186}
    height={19.186}
    viewBox="0 0 19.186 19.186"
    {...props}
  >
    <g
      id="Group_7263"
      data-name="Group 7263"
      transform="translate(15908.107 -15047.84)"
    >
      <g
        id="Rectangle_1999"
        data-name="Rectangle 1999"
        transform="translate(-15908.107 15047.84)"
        fill="none"
        stroke="#989898"
        strokeWidth={0.5}
      >
        <rect width={19.185} height={19.185} rx={2} stroke="none" />
        <rect
          x={0.25}
          y={0.25}
          width={18.685}
          height={18.685}
          rx={1.75}
          fill="none"
        />
      </g>
      <g id="path" transform="translate(-15903.524 15054.037)">
        <path
          id="path-2"
          data-name="path"
          d="M1440.355,4366.123l3.229,3.229,6.791-6.791"
          transform="translate(-1440.355 -4362.562)"
          fill="none"
          stroke="#47a06d"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
        />
      </g>
    </g>
  </svg>
);

export const CheckBoxReportUncheckedIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19.186}
    height={19.186}
    viewBox="0 0 19.186 19.186"
    {...props}
  >
    <g
      id="Group_7263"
      data-name="Group 7263"
      transform="translate(15908.107 -15047.84)"
    >
      <g
        id="Rectangle_1999"
        data-name="Rectangle 1999"
        transform="translate(-15908.107 15047.84)"
        fill="none"
        stroke="#989898"
        strokeWidth={0.5}
      >
        <rect width={19.185} height={19.185} rx={2} stroke="none" />
        <rect
          x={0.25}
          y={0.25}
          width={18.685}
          height={18.685}
          rx={1.75}
          fill="none"
        />
      </g>
    </g>
  </svg>
);

export const ReorderBurgerIcon = (props) => (
  <svg
    id="Icon_ionic-ios-reorder"
    data-name="Icon ionic-ios-reorder"
    xmlns="http://www.w3.org/2000/svg"
    width={6.182}
    height={4.503}
    viewBox="0 0 6.182 4.503"
    {...props}
  >
    <path
      id="Path_4933"
      data-name="Path 4933"
      d="M5.625,21.375h6.182v.563H5.625Z"
      transform="translate(-5.625 -17.434)"
    />
    <path
      id="Path_4934"
      data-name="Path 4934"
      d="M5.625,17.438h6.182V18H5.625Z"
      transform="translate(-5.625 -15.467)"
    />
    <path
      id="Path_4935"
      data-name="Path 4935"
      d="M5.625,13.5h6.182v.563H5.625Z"
      transform="translate(-5.625 -13.5)"
    />
  </svg>
);
