import styled from "@emotion/styled";
import { Button } from "@mui/material";

export const StyledButton = styled(Button)({
  fontSize: 10,
  fontWeight: 400,
  minWidth: 32,
  height: 18,
  color: "#707070",
  paddingLeft: 0,
  paddingRight: 0,
});
