import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Box, Chip, Typography } from "@mui/material";
import {
  ActiveTabs,
  CustomFilterMenu,
  FilterMenu,
  FilterSearchTextField,
  FilterViewSaveDialog,
  StyledButton,
} from "components";

import { useDispatch } from "react-redux";
import { FilterIcon } from "components/Icons";
import CloseIcon from "@mui/icons-material/Close";
import { normalizeSnakeCaseString, truncateText } from "helpers/helpers";
import { debounce, isArray, snakeCase } from "lodash";
import { TasksPageContext } from "Pages/TasksPage/TasksPage";
import { STATUSES_TASKS } from "Pages/TasksPage/TasksPage.constants";
import {
  resetTasksFilterAction,
  resetTasksSwitchAction,
  tasksFilterAction,
  tasksSwitchAction,
} from "redux/actions/tasks";
import {
  checkForDefaultFilter,
  handleSetSwitchesForEdit,
} from "helpers/filters";
import { CUSTOM_FILTERS_TYPES, NAME_VIEW_DIALOGS } from "utils/constants";
import { useCustomFilterCreate } from "helpers/useCustomFilterCreate";
import { setSelectedFilterIdAction } from "redux/actions/savedFilters";

export const FILTER_TABS = [
  {
    name: "All",
    value: "",
    field: "All",
  },
  {
    name: "Open",
    value: STATUSES_TASKS.PENDING,
    field: "Open",
  },
  {
    name: "Closed",
    value: STATUSES_TASKS.COMPLETED,
    field: "Closed",
  },
];

export const TasksFilter = () => {
  const dispatch = useDispatch();

  const filterAnchor = useRef();

  const {
    repsList,
    tasksSwitch,
    tasksFilter,
    handleUncheckAllTasks,
    currentUser,
  } = useContext(TasksPageContext);

  const [filterMenuOpen, setFilterMenuOpen] = useState(false);

  const [tabField, setTabField] = useState(FILTER_TABS[0]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((value, setParams) => setParams(value), 500),
    []
  );

  const [search, setSearch] = useState("");

  const [switches, setSwitches] = useState(tasksSwitch);
  const [filterFields, setFilterFields] = useState({
    representative_id: tasksFilter?.representative_id,
    due_date: tasksFilter?.due_date,
    repeated: tasksFilter?.repeated,
  });

  const getChipLabel = (key) => {
    const fieldKeys = filterFields[key]
      ? Object.keys(filterFields[key])
      : undefined;

    const isCombined = key === "price";
    const isArr = isArray(filterFields[key]);

    return (
      <Box display="flex" alignItems="center" gap="6px">
        <Typography
          sx={{ fontSize: "13px", fontWeight: 500 }}
          color="groundLighter.main"
        >
          {isArr ? (
            <>
              {normalizeSnakeCaseString(
                truncateText(filterFields[key][0]?.label || key)
              )}
              :{" "}
              <span style={{ color: "#5F6368" }}>
                {typeof filterFields[key][0] === "string"
                  ? filterFields[key]
                      .map((name) =>
                        normalizeSnakeCaseString(truncateText(name))
                      )
                      .join(", ")
                  : filterFields[key]
                      .map(({ name }) =>
                        normalizeSnakeCaseString(truncateText(name))
                      )
                      .join(", ")}
              </span>
            </>
          ) : (
            <>
              {normalizeSnakeCaseString(
                truncateText(filterFields[key]?.label || key)
              )}
              :{" "}
              <span style={{ color: "#5F6368" }}>
                {isCombined
                  ? fieldKeys
                      .filter((fieldKey) => filterFields[key][fieldKey]?.value)
                      .map((fieldKey) => {
                        const { prefix, value } = filterFields[key][fieldKey];
                        return `${truncateText(prefix)} ${truncateText(value)}`;
                      })
                      .join(" and ")
                  : key === "repeated"
                  ? "ON"
                  : normalizeSnakeCaseString(
                      truncateText(
                        filterFields?.[key]?.name || filterFields?.[key]
                      )
                    )}
              </span>
            </>
          )}
        </Typography>
      </Box>
    );
  };

  const filterChipKeys = useMemo(
    () => Object.keys(filterFields).filter((key) => filterFields[key]),
    [filterFields]
  );

  const handleDeleteFilter = useCallback(
    (key) => {
      const newState = [...switches];
      const index = newState.findIndex((s) => s.value === key);
      const insert = {
        ...newState[index],
        checked: false,
      };
      if (index > -1) {
        newState.splice(index, 1, insert);

        dispatch(tasksSwitchAction([...newState]));

        setSwitches([...newState]);
      }

      dispatch(setSelectedFilterIdAction(null));

      dispatch(
        tasksFilterAction({
          ...tasksFilter,
          [key]: isArray(filterFields[key]) ? [] : "",
          page: 1,
        })
      );
      handleUncheckAllTasks();

      setFilterFields((prev) => {
        return {
          ...prev,
          [key]: isArray(filterFields[key]) ? [] : "",
        };
      });
    },
    [dispatch, filterFields, handleUncheckAllTasks, switches, tasksFilter]
  );

  const handleApplyFilter = (newSwitches, newFields) => {
    const { representative_id, due_date, ...clearData } = newFields || {};

    dispatch(setSelectedFilterIdAction(null));

    const repeated = newSwitches?.find((el) => el?.value === "repeated");

    const setdate =
      typeof due_date?.value === "string"
        ? snakeCase(due_date?.value === "All" ? "all_time" : due_date?.value)
        : due_date?.value
        ? { ...due_date?.value }
        : "";

    clearData.representative_id = representative_id?.value;
    clearData.due_date = setdate;
    clearData.repeated = repeated?.checked ? true : "";

    setFilterMenuOpen(false);
    dispatch(
      tasksFilterAction({
        ...tasksFilter,
        ...clearData,
        page: 1,
      })
    );
    dispatch(
      tasksSwitchAction(handleSetSwitchesForEdit(newSwitches, switches))
    );
    handleUncheckAllTasks();

    setSwitches([...newSwitches]);
    setFilterFields({
      ...newFields,
      repeated: repeated?.checked ? repeated : "",
    });
  };

  const selectMatchData = [
    {
      id: "representative_id",
      childrenList: [{ name: "Admin", id: currentUser?.id }, ...repsList],
      label: "Select Rep",
    },
    {
      id: "due_date",
      label: "Select Date",
      datesList: [
        { name: "Overdue", id: "overdue", isDueDate: true, withDivider: true },
        { name: "Today", id: "today" },
        { name: "Tomorrow", id: "tomorrow" },
        { name: "This week", id: "this_week" },
        { name: "Next week", id: "next_week" },
        { name: "This month", id: "this_month" },
        { name: "Next month", id: "next_month" },
        { name: "All time", id: "all_time" },
      ],
      hideCustomDate: true,
    },
  ];

  const onCustomFilterApply = useCallback(
    (newFields) => {
      const { representative_id, due_date, ...clearData } = newFields || {};

      const repeated = switches?.find((el) => el?.value === "repeated");

      const setdate =
        typeof due_date?.value === "string"
          ? snakeCase(due_date?.value === "All" ? "all_time" : due_date?.value)
          : due_date?.value
          ? { ...due_date?.value }
          : "";

      clearData.representative_id = representative_id?.value;
      clearData.due_date = setdate;
      clearData.repeated =
        repeated?.checked || newFields?.repeated?.checked ? true : "";

      setSwitches(handleSetSwitchesForEdit(newFields, switches));

      dispatch(
        tasksFilterAction({
          ...tasksFilter,
          ...clearData,
          page: 1,
        })
      );
      setFilterFields({
        ...newFields,
        repeated: clearData?.repeated ? { ...repeated, checked: true } : "",
      });
    },
    [dispatch, switches, tasksFilter]
  );

  const [isFilterChanged, setIsFilterChanged] = useState(false);

  useEffect(() => {
    setIsFilterChanged(true);
    if (isFilterChanged) return;

    checkForDefaultFilter({
      type: CUSTOM_FILTERS_TYPES.TASKS,
      list: currentUser.customFilters,
      setFilters: (filters) => {
        const preparedFilters = {};
        if (filters?.representative_id?.value)
          preparedFilters.representative_id = filters?.representative_id?.value;
        if (filters?.due_date?.value) {
          const due_date = filters?.due_date;

          const setdate =
            typeof due_date?.value === "string"
              ? snakeCase(
                  due_date?.value === "All" ? "all_time" : due_date?.value
                )
              : due_date?.value
              ? { ...due_date?.value }
              : "";
          preparedFilters.due_date = setdate;
        }
        if (filters?.repeated?.checked) preparedFilters.repeated = true;

        if (Object.keys(preparedFilters).length)
          dispatch(
            tasksFilterAction({
              ...tasksFilter,
              ...preparedFilters,
              page: 1,
            })
          );
        setFilterFields(filters);
      },
      setSwitches: (switchers) => {
        setSwitches(switchers);
        dispatch(tasksSwitchAction(switchers));
      },
      switchesList: switches,
      dispatchFunc: (id) => dispatch(setSelectedFilterIdAction(id)),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser.customFilters]);

  const {
    onCloseFilterDialog,
    onFilterViewSave,
    onOpenFilterSaveDialog,
    isOpenedMenu,
  } = useCustomFilterCreate({
    createFilterCallback: onCustomFilterApply,
    menuCallback: () => setFilterMenuOpen(false),
    viewTypeKey: NAME_VIEW_DIALOGS.TASKS,
    filterType: CUSTOM_FILTERS_TYPES.TASKS,
  });

  return (
    <>
      {isOpenedMenu && (
        <FilterViewSaveDialog
          open={isOpenedMenu}
          onClose={onCloseFilterDialog}
          handleSave={onFilterViewSave}
        />
      )}
      <Box
        sx={{
          backgroundColor: "#fff",
          height: "64px",
          border: "0.5px solid #D5D9D9",
          borderRadius: "4px 4px 0 0",
          marginTop: "12px",
          padding: "12px 17px 12px 17px",
          display: "flex",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <ActiveTabs
          value={tabField.value}
          onChange={(e, newVal) => {
            setTabField({ name: e.target.name, value: newVal });
            // setTasksState(initTasksState);
            handleUncheckAllTasks();
            dispatch(
              tasksFilterAction({
                ...tasksFilter,
                status: newVal,
                page: 1,
              })
            );
          }}
          customTabs={FILTER_TABS}
          tabStyles={{ fontWeight: "400" }}
        />

        <Box sx={{ flexGrow: 1 }}>
          <FilterSearchTextField
            adminIsAllowed
            value={search}
            onChange={(e) => {
              const value = e.target.value;
              setSearch(value);
              debouncedSearch(value, (v) => {
                handleUncheckAllTasks();
                dispatch(
                  tasksFilterAction({
                    ...tasksFilter,
                    page: 1,
                    search: v,
                  })
                );
              });
            }}
            formSx={{ minWidth: "380px" }}
            placeholderWidth="340px"
            placeholder="Search tasks"
            fullWidth
            adornmentProps={{ sx: { justifyContent: "flex-end" } }}
            customAdornment={
              <Box
                display="flex"
                gap="5px"
                overflow="auto"
                sx={{ py: "4px", "&::-webkit-scrollbar": { height: "2px" } }}
              >
                {filterChipKeys.map((key) => (
                  <Chip
                    key={key}
                    // className={classes.filterChip}
                    sx={{
                      borderRadius: "4px",
                      border: "0.7px solid #d5d9d9",
                      backgroundColor: "#FAFAFB",
                      height: "28px",
                    }}
                    size="small"
                    label={getChipLabel(key)}
                    deleteIcon={
                      <CloseIcon size="15" style={{ fill: "#707070" }} />
                    }
                    onMouseDown={(e) => e.stopPropagation()}
                    onDelete={() => handleDeleteFilter(key)}
                  />
                ))}
              </Box>
            }
            // show the clear button only if the input field has a value and does not have a chip
            handleClearValue={
              filterChipKeys?.length
                ? null
                : () => {
                    setSearch("");
                    handleUncheckAllTasks("");
                    dispatch(
                      tasksFilterAction({
                        ...tasksFilter,
                        page: 1,
                        search: undefined,
                      })
                    );
                  }
            }
          />
        </Box>

        <CustomFilterMenu
          type={CUSTOM_FILTERS_TYPES.TASKS}
          switches={switches}
          onFilterApply={onCustomFilterApply}
          selectMatchData={selectMatchData}
          resetFilterFunc={() => {
            dispatch(resetTasksSwitchAction());
            dispatch(resetTasksFilterAction());

            setFilterFields({
              representative_id: undefined,
              due_date: undefined,
              repeated: undefined,
            });
            setSwitches(tasksSwitch);
          }}
        />

        <Box>
          <StyledButton
            label="Filter"
            startIcon={
              filterChipKeys.length ? (
                <Typography
                  fontSize="9px!important"
                  color="#ffffff"
                  backgroundColor="#47A06D"
                  borderRadius="50%"
                  width="15px"
                  height="15px"
                >
                  {filterChipKeys.length}
                </Typography>
              ) : (
                <FilterIcon />
              )
            }
            variant="outlined"
            ref={filterAnchor}
            color="edit"
            // className={classes.filterButton}
            sx={{
              height: "39px",
              width: "100%",
              maxWidth: "74px",
              border: "0.5px solid #D5D9D9",
              "& .MuiButton-startIcon": {
                ml: 0,
              },
            }}
            fontSize="15px"
            onClick={() => setFilterMenuOpen(true)}
          />
          <FilterMenu
            anchorEl={filterAnchor.current}
            open={filterMenuOpen}
            onClose={() => setFilterMenuOpen(false)}
            filterFields={filterFields}
            // setFilterFields={setFilterFields}
            switches={switches}
            handleApplyFilter={handleApplyFilter}
            selectMatchData={selectMatchData}
            onViewSave={onOpenFilterSaveDialog}
          />
        </Box>
      </Box>
    </>
  );
};
