import React from "react";

// eslint-disable-next-line react/prop-types
export const PlusIcon = ({ stroke, strokeWidth, fill, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 22.771 24.316"
      {...props}
    >
      <path
        id="Path_1897"
        data-name="Path 1897"
        d="M13.121-2.737h7.152V2.512H13.121v8.2H7.673v-8.2H.5V-2.737H7.673V-10.6h5.448Z"
        transform="translate(0.999 12.1)"
        fill={fill || "#707070"}
        stroke={stroke || "#fff"}
        strokeWidth={strokeWidth || "3"}
      />
    </svg>
  );
};
