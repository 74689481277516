/* eslint-disable react/prop-types */
import * as React from "react";
export const TaskIcon = ({
  fill = "#409a65",
  width = 40,
  height = 40,
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M24.25 4v-.25h-8.5v7.258l.373-.21 2.98-1.68.002-.001a1.75 1.75 0 0 1 1.75 0l.003.001 3.02 1.68.372.207zm-12 0v-.25H6A2.25 2.25 0 0 0 3.75 6v28A2.25 2.25 0 0 0 6 36.25h28A2.25 2.25 0 0 0 36.25 34V6A2.25 2.25 0 0 0 34 3.75h-6.25V14A1.75 1.75 0 0 1 26 15.75a1.75 1.75 0 0 1-.857-.227l-.001-.001-5.02-2.8-.122-.068-.122.068-5 2.78-.003.002a1.75 1.75 0 0 1-1.75 0L13 15.72l.125-.216a1.75 1.75 0 0 1-.875-1.505zM14 23.75h-4a1.75 1.75 0 0 1 0-3.5h4a1.75 1.75 0 0 1 0 3.5Zm-5.237 5.013A1.75 1.75 0 0 1 10 28.25h12a1.75 1.75 0 0 1 0 3.5H10a1.75 1.75 0 0 1-1.237-2.987ZM6 .25h28A5.75 5.75 0 0 1 39.75 6v28A5.75 5.75 0 0 1 34 39.75H6A5.75 5.75 0 0 1 .25 34V6A5.75 5.75 0 0 1 6 .25Z"
      fill={fill}
      stroke="#fff"
      strokeWidth={0.5}
    />
    <mask
      id="a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={width}
      height={height}
    >
      <path
        d="M10 24h4a2 2 0 0 0 0-4h-4a2 2 0 1 0 0 4m12 4H10a2 2 0 1 0 0 4h12a2 2 0 0 0 0-4M34 0H6a6 6 0 0 0-6 6v28a6 6 0 0 0 6 6h28a6 6 0 0 0 6-6V6a6 6 0 0 0-6-6M24 4v6.58L20.98 8.9a2 2 0 0 0-2 0L16 10.58V4zm12 30a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h6v10a2 2 0 0 0 3 1.72l5-2.78 5.02 2.8A2 2 0 0 0 26 16a2 2 0 0 0 2-2V4h6a2 2 0 0 1 2 2z"
        fill={fill}
      />
    </mask>
    <g mask="url(#a)" />
  </svg>
);
