import { object, string, number, func } from "prop-types";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { CartTrashIcon, StyledProductIco } from "components/Icons";
import { photoUrl, separateNumWithComma } from "helpers/helpers";
import { StyledTextField } from "components";
import { useEffect, useState } from "react";

export const FreeCasesItem = ({
  product,
  formDiscountType,
  paymentStatus,
  quantity,
  handleRemoveFreeCaseProduct,
  price,
}) => {
  const itemDiscount =
    quantity *
    (product?.itemDiscountType === "PERCENTAGE"
      ? ((product?.wholesalePrice || product?.price || 0) *
          (product?.itemDiscountAmount || 0)) /
        100
      : product?.itemDiscountAmount || 0);

  const calculatedDiscount =
    product?.itemDiscountType === "PERCENTAGE"
      ? itemDiscount
      : product?.itemDiscountAmount || 0;

  const [qty, setQty] = useState(product.discountQty || 0);

  useEffect(() => {
    setQty(product.discountQty || 0);
  }, [product.discountQty]);

  return (
    <Grid
      sx={{
        height: "57px",
        "&:not(:last-of-type)": { borderBottom: "0.5px solid #D4D4D4" },
        ":last-child": { borderRadius: "0 0 4px 4px" },
      }}
      container
    >
      {/* Photo */}
      <Grid
        item
        xs={1}
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        pr={1.9}
      >
        <Box
          sx={{
            width: "38px",
            height: "38px",
            backgroundColor: "white",
            border: "1px solid #D5D9D9",
            borderRadius: "4px",
          }}
        >
          <StyledProductIco
            src={
              product?.photos?.length
                ? photoUrl(product.photos[0].fileName)
                : product?.photo
                ? photoUrl(product?.photo?.fileName)
                : ""
            }
            styles={{
              objectFit: "contain",
              width: "100%",
              height: "100%",
              borderRadius: "4px",
            }}
          />
        </Box>
      </Grid>

      {/* Name */}
      <Grid
        item
        xs={5.9}
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Typography fontSize={12} fontWeight={500} noWrap>
          {product?.name || product?.parentProduct?.name}
        </Typography>
        <Typography
          fontSize={12}
          fontWeight={400}
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          overflow="hidden"
        >
          {/* by{" "}
          {product?.manufacturer?.name ||
            product?.parentProduct?.manufacturer?.name ||
            product?.manufacturerName} */}
          {product?.sku}
          {(product?.size || product?.color) &&
            ` / ${product?.size || product?.color}`}
        </Typography>
      </Grid>

      {/* update btn */}
      <Grid
        item
        xs={1.7}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {/* <StyledButton
          sx={{ borderColor: "#D5D9D9", color: "#6A6A6A", height: "25px" }}
          variant="outlined"
          label="Update"
          onClick={() => handleUpdateFreeCaseProduct(product)}
        /> */}
        <StyledTextField
          value={qty}
          disabled
          onChange={(e) => {
            setQty(e.target.value);
          }}
          formSx={{ maxWidth: "36px" }}
          InputProps={{
            sx: {
              fontSize: "9px",
              "& input": { pr: 0, pl: 0, textAlign: "center" },
            },
          }}
        />
      </Grid>

      {/* Price */}
      <Grid
        item
        xs={1.3}
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        sx={{ textDecorationLine: "line-through" }}
      >
        <Typography fontSize={12} fontWeight={400} color="#1C1C19">
          <Box component="span" color="#B5B5B5">
            $
          </Box>{" "}
          {separateNumWithComma(price)}
        </Typography>
      </Grid>

      {/* Total amount */}
      {formDiscountType === "item" && (
        <Grid
          item
          xs={1}
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
        >
          {product.totalDelivered > 0 ||
            (!product.isNewAdded &&
              paymentStatus &&
              paymentStatus !== "PENDING" && (
                <Typography
                  fontSize="14px"
                  fontWeight="400"
                  whiteSpace="nowrap"
                  color="#5F6267"
                >
                  ${calculatedDiscount.toFixed(2)}
                </Typography>
              ))}
        </Grid>
      )}
      <Grid
        item
        xs={1.5}
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
      >
        <Typography fontSize={12} fontWeight={600} color="#47A06D">
          $ 0.00
        </Typography>
        {/* <Typography fontSize="12px" fontWeight="600">
          <span style={{ color: "#B5B5B5", fontWeight: 400 }}>$</span>{" "}
          {(calculatedDiscount <=
          (product.wholesalePrice || product.price || 0) * product.quantity
            ? quantity * (product.wholesalePrice || product.price || 0) -
              calculatedDiscount
            : 0
          ).toFixed(2)}
        </Typography> */}
      </Grid>

      {/* Trash btn */}
      <Grid
        item
        xs={0.6}
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        pr="8px"
      >
        <Box>
          <IconButton
            onClick={() => handleRemoveFreeCaseProduct(product)}
            sx={{
              "&.Mui-disabled svg": {
                opacity: 0.5,
              },
            }}
          >
            <CartTrashIcon />
          </IconButton>
        </Box>
      </Grid>
    </Grid>
  );
};

FreeCasesItem.propTypes = {
  product: object,
  formDiscountType: string,
  paymentStatus: string,
  quantity: number,
  handleUpdateFreeCaseProduct: func,
  handleRemoveFreeCaseProduct: func,
  price: number,
};
FreeCasesItem.defaultProps = {
  product: {},
  handleUpdateFreeCaseProduct: () => {},
  handleRemoveFreeCaseProduct: () => {},
  price: 0,
};
