import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { Box } from "@mui/material";
import ProductsList from "./ProductsList";
import CatalogFilter from "../components/CatalogFilter";
import { ProductsTotalSummaries } from "./components";
import {
  totalSummariesCatalogDataSelector,
  totalSummariesCatalogLoadingSelector,
} from "../../../redux/selectors/total-summaries";
import { getTotalSummariesCatalogAction } from "../../../redux/actions/total-summaries";
import { string, object } from "prop-types";

const selector = createSelector(
  totalSummariesCatalogDataSelector,
  totalSummariesCatalogLoadingSelector,
  (totalSummariesCatalogData, totalSummariesCatalogLoading) => ({
    totalSummariesCatalogData,
    totalSummariesCatalogLoading,
  })
);

const ProductsTab = ({ currentTab, repPermissions }) => {
  const { totalSummariesCatalogData, totalSummariesCatalogLoading } =
    useSelector(selector);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTotalSummariesCatalogAction());
  }, [dispatch]);

  return (
    <Box px="32px" height="100%">
      <ProductsTotalSummaries
        isLoading={totalSummariesCatalogLoading}
        totalSummaries={totalSummariesCatalogData}
      />
      <CatalogFilter {...{ repPermissions, currentTab }} />
      <ProductsList {...{ currentTab, repPermissions }} />
    </Box>
  );
};
ProductsTab.propTypes = {
  currentTab: string,
  repPermissions: object,
};
export default ProductsTab;
