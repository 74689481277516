export const EmptyDiscountsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="134.561"
      height="133.821"
      viewBox="0 0 134.561 133.821"
    >
      <g
        id="Group_7497"
        data-name="Group 7497"
        transform="translate(-6509.853 -16206.679)"
      >
        <g
          id="Group_7496"
          data-name="Group 7496"
          transform="translate(6540.574 16223.36) rotate(-30)"
        >
          <path
            id="Path_5146"
            data-name="Path 5146"
            d="M24.889,32H7.109A7.109,7.109,0,0,1,0,24.889V7.109A7.109,7.109,0,0,1,7.109,0h17.78A7.109,7.109,0,0,1,32,7.109v17.78A7.109,7.109,0,0,1,24.889,32Z"
            transform="translate(0 0)"
            fill="#fff"
            stroke="#f5f5f5"
            strokeWidth="1"
          />
          <g
            id="Group_7494"
            data-name="Group 7494"
            transform="translate(7.907 8.537)"
          >
            <path
              id="Path_4958"
              data-name="Path 4958"
              d="M0,5.395,5.395,0"
              transform="translate(5.395 5.395)"
              fill="none"
              stroke="#409a65"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            />
            <path
              id="Path_4959"
              data-name="Path 4959"
              d="M.45.9A.45.45,0,1,0,0,.45.45.45,0,0,0,.45.9Z"
              transform="translate(5.395 5.395)"
              fill="#409a65"
              stroke="#409a65"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            />
            <path
              id="Path_4960"
              data-name="Path 4960"
              d="M.45.9A.45.45,0,1,0,0,.45.45.45,0,0,0,.45.9Z"
              transform="translate(9.891 9.891)"
              fill="#409a65"
              stroke="#409a65"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            />
            <path
              id="Path_4961"
              data-name="Path 4961"
              d="M8.092,16.185A8.092,8.092,0,1,0,0,8.092,8.092,8.092,0,0,0,8.092,16.185Z"
              fill="none"
              stroke="#409a65"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            />
          </g>
        </g>
        <g
          id="Group_7493"
          data-name="Group 7493"
          transform="translate(6558.154 16307.936)"
        >
          <path
            id="Union_272"
            data-name="Union 272"
            d="M17.875,23.214l-14.164-2.5c-2.39-.106-4.028-1.635-3.659-3.42s2.6-3.15,4.989-3.044l13.877.334L30.4,12.951c.355-.243,1.06-.722,2.011-1.355l-9.1-2.025a4.244,4.244,0,0,1,1.533-8.348L42.42,2.452,57.47.071A5.657,5.657,0,0,1,59.25,11.243l-.5.08a3.506,3.506,0,0,1-2.088,2.859l-23.535,7.24a4.393,4.393,0,0,1-1.544.26L20.213,23.3a4.359,4.359,0,0,1-.851.1q-.1.005-.209.005A4.358,4.358,0,0,1,17.875,23.214Z"
            transform="translate(1.984 4.91)"
            fill="#fff"
            stroke="#ebebeb"
            strokeWidth="1"
          />
          <path
            id="Union_273"
            data-name="Union 273"
            d="M20.884,30.676l-16.5-2.6C1.6,28-.347,26.241.052,24.154S3.027,20.425,5.815,20.5l16.068.113L35.41,18.446C37.96,16.636,55.857,4.059,60.843,3.94a14.062,14.062,0,0,1,4.4.618L84.716,0V19.19H66.489q-.2.092-.386.165L38.759,28.291a5.111,5.111,0,0,1-1.782.336L23.629,30.766a5.4,5.4,0,0,1-2.745-.09Z"
            transform="translate(0 1.163)"
            fill="#fff"
            stroke="#ebebeb"
            strokeWidth="1"
          />
          <path
            id="Path_5143"
            data-name="Path 5143"
            d="M230.884,2.578V20.291a2.565,2.565,0,0,0,2.565,2.565h7.1a2.565,2.565,0,0,0,2.565-2.565V2.578A2.565,2.565,0,0,0,240.545.012h-7.1A2.565,2.565,0,0,0,230.884,2.578Z"
            transform="translate(-156.85 -0.013)"
            fill="#409a65"
          />
        </g>
        <path
          id="Path_5144"
          data-name="Path 5144"
          d="M341.082,127.97h-92.63a5.1,5.1,0,0,1-5.1-5.1V70.282a5.1,5.1,0,0,1,5.1-5.1l92.63,9a5.2,5.2,0,0,1,4.846,3.5,4.642,4.642,0,0,1,.256,1.6v43.586A5.1,5.1,0,0,1,341.082,127.97Z"
          transform="translate(6271.257 16179.966)"
          fill="#fff"
          stroke="#f5f5f5"
          strokeWidth="1"
        />
        <g
          id="Rectangle_11755"
          data-name="Rectangle 11755"
          transform="translate(6575.725 16239.426)"
          fill="#f5f5f5"
          stroke="#f5f5f5"
          strokeWidth="1"
        >
          <rect width="17.983" height="69.048" stroke="none" />
          <rect x="0.5" y="0.5" width="16.983" height="68.048" fill="none" />
        </g>
        <rect
          id="Rectangle_11756"
          data-name="Rectangle 11756"
          width="103.403"
          height="17.983"
          transform="translate(6514.582 16268.012)"
          fill="#ececec"
        />
        <path
          id="Path_5145"
          data-name="Path 5145"
          d="M348.726,72.588H248.851c-3.038,0-5.5-.637-5.5-1.422V52.6c0-.786,2.463-1.422,5.5-1.422h99.875c3.038,0,5.5.637,5.5,1.422V71.166C354.227,71.951,351.764,72.588,348.726,72.588Z"
          transform="translate(6280.854 16137.563) rotate(9)"
          fill="#e2f0e8"
        />
      </g>
    </svg>
  );
};
