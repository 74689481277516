/* eslint-disable react/prop-types */
export const MailIcon = ({ width = "21.631", height = "14.602" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 21.631 14.602"
    >
      <path
        id="Icon_zocial-email"
        data-name="Icon zocial-email"
        d="M.072,17.376V5.361q0-.021.063-.4l7.071,6.049L.155,17.794a1.768,1.768,0,0,1-.083-.417ZM1.011,4.131a.9.9,0,0,1,.355-.063H20.41a1.181,1.181,0,0,1,.375.063L13.693,10.2l-.939.751L10.9,12.474,9.042,10.952,8.1,10.2Zm.021,14.477,7.113-6.821L10.9,14.018l2.753-2.232,7.113,6.821a1,1,0,0,1-.355.063H1.365a.945.945,0,0,1-.334-.063ZM14.59,11.014l7.051-6.049a1.245,1.245,0,0,1,.063.4V17.376a1.6,1.6,0,0,1-.063.417Z"
        transform="translate(-0.072 -4.068)"
        fill="#47a06d"
      />
    </svg>
  );
};
