export const cl = {
  arrowDropDownIcon: {
    left: "3px",
    color: "#47A06D !important",
    paddingLeft: "3px",
    height: "37px",
    width: "26px",
    position: "absolute",
  },
  mainBox: {
    display: "flex",
    justifyContent: "space-between",
    px: "32px",
    backgroundColor: "#F0F0F0",
    borderBottom: "0.5px solid #D4D4D4",
    height: "44px",
  },
  orderTypeSelect: {
    "& fieldset": {
      borderColor: "#47A06D !important",
    },
    "&:not(.Mui-disabled):hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#47A06D !important",
      },
    },
  },
  styledToggleButton: {
    height: "28px",
    borderRadius: "0 4px 4px 0",
    border: "none",
    width: "18px",
    borderLeft: "1px solid #D5D9D9",
    transition: "all 0.3s ease",
    "&:hover": {
      backgroundColor: "rgb(49, 112, 76)",
      boxShadow:
        "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
    },
    "&.Mui-selected": {
      backgroundColor: "#47A06D!important",
      filter: "brightness(110%)",
      "& svg": {
        transform: "scaleY(-1)",
      },
    },
  },
};
