import { Box, Typography } from "@mui/material";
import { string } from "prop-types";
import useStyles from "./styles";

const DateChip = ({ date }) => {
  const classes = useStyles();
  return (
    <Box className={classes.chipWrapper} component="span">
      <Typography className={classes.date} data-testid="sales-chart-date">
        {date || "All"}
      </Typography>
    </Box>
  );
};

DateChip.propTypes = { date: string };

export default DateChip;
