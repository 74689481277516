/* eslint-disable react/prop-types */
import React from "react";

export const ArrowRightIcon = ({
  color = "currentColor",
  width = 5.699,
  height = 9.967,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 5.699 9.967"
    >
      <path
        id="Icon_ionic-ios-arrow-forward"
        data-name="Icon ionic-ios-arrow-forward"
        d="M15.228,11.178,11.456,7.409a.709.709,0,0,1,0-1.006.718.718,0,0,1,1.009,0l4.273,4.27a.711.711,0,0,1,.021.982l-4.291,4.3a.712.712,0,0,1-1.009-1.006Z"
        transform="translate(-11.246 -6.196)"
        fill={color}
      />
    </svg>
  );
};
