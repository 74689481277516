import { doc, getFirestore, writeBatch } from "firebase/firestore";
import { useCallback } from "react";

export const useReps = () => {
  const deleteRepsWithStatus = useCallback(({ reps, deleteAction }) => {
    const ids = reps.map((r) => r?.id);
    const duplicateIds = reps?.map((r) => r?.duplicate?.id);
    deleteAction(ids);
    const batch = writeBatch(getFirestore());
    duplicateIds.forEach((id) => {
      const statusRef = doc(getFirestore(), `online-status/${id}`);
      batch.delete(statusRef);
    });
    batch.commit();
  }, []);

  return { deleteRepsWithStatus };
};
