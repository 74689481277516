import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  textInput: {
    fontSize: "13px",
    height: "38px",
  },

  inputLabel: {
    fontSize: "13px",
    color: "#5F6267",
  },
}));

export default useStyles;
