import { Box, Typography } from "@mui/material";
import { StyledTextField } from "components";
import { useAdmin, photoUrl } from "helpers/helpers";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentUserAction,
  updateUserFieldAction,
} from "redux/actions/auth";
import { setStoreDataService } from "services/order-directs";
import { error } from "utils/notifications";
import {
  CUSTOMER_SHOP_LINK_LOGIN,
  removeProtocol,
} from "./OrderDirectTab.constants";
import { useOrderDirectConfigured } from "helpers/hooks";

export const useSettingsOrderDirect = ({ widgetState, updateWidgetState }) => {
  const isAdmin = useAdmin();
  const { brandLogo, storeName } = useSelector(({ auth }) => auth?.currentUser);

  const orderDirectConfigured = useOrderDirectConfigured();

  const dispatch = useDispatch();
  const handleUploadLogo = useCallback(() => {
    dispatch(setCurrentUserAction());
  }, [dispatch]);

  const handleUploadStoreLink = useCallback(async () => {
    try {
      updateWidgetState({ linkLoading: true });
      const storeName = widgetState.link;
      await setStoreDataService({ storeName });
      dispatch(updateUserFieldAction("storeName", storeName));
      updateWidgetState({ storeLinkDialogOpen: false, linkLoading: false });
    } catch (err) {
      error(err?.response?.data?.message);
      updateWidgetState({ linkLoading: false });
    }
  }, [dispatch, updateWidgetState, widgetState?.link]);

  const updateLinkAvailability = useCallback(
    ({ error, uniqueChecked }) => updateWidgetState({ error, uniqueChecked }),
    [updateWidgetState]
  );

  const allowedRegex = useMemo(() => /^[a-zA-Z0-9_-]+$/, []);

  const onLinkChange = useCallback(
    (e) => {
      const { value } = e.target;
      const error = !allowedRegex.test(value);
      updateWidgetState({ error, link: value, uniqueChecked: false });
    },
    [allowedRegex, updateWidgetState]
  );

  const SETUP_STEPS = useMemo(
    () => [
      {
        title: "Set store link to activate your order direct access",
        completed: !!storeName,
        handleAction: () => {
          if (widgetState?.link && !widgetState?.error)
            updateWidgetState({ storeLinkDialogOpen: true });
          else {
            updateWidgetState({ error: true });
          }
        },
        buttonTitle: "Set Store Link",
        updatable: false,
        isUpload: false,
        showEndElement: true,
        endElement: (
          <StyledTextField
            disabled={isAdmin || !!storeName}
            formSx={{ maxWidth: "370px" }}
            onChange={onLinkChange}
            value={widgetState?.link || storeName}
            error={widgetState?.error}
            placeholder="storename"
            sx={{
              userSelect: storeName ? "none" : "auto",
              "& .MuiOutlinedInput-input": {
                fontSize: "12px",
                color: "#C2C0C0",
                pointerEvents: storeName ? "none" : "auto",
              },
              "& .MuiOutlinedInput-root": {
                height: "28px",
              },
            }}
            startAdornment={
              <Typography
                color="#5F6267"
                fontSize="12px"
                sx={{ whiteSpace: "nowrap" }}
              >
                {removeProtocol(CUSTOMER_SHOP_LINK_LOGIN)}
              </Typography>
            }
          />
        ),
      },
      {
        title: "Upload your brand logo",
        buttonTitle: "Upload Logo",
        buttonCompletedTitle: "Update Logo",
        isUpload: true,
        handleAction: handleUploadLogo,
        completed: !!brandLogo,
        updatable: true,
        showEndElement: !!brandLogo,
        endElement: (
          <Box
            width="118px"
            height="33px"
            backgroundColor="#d5d9d9"
            textAlign="center"
            lineHeight="33px"
            component="img"
            borderRadius="4px"
            border="1px solid #d5d9d9"
            src={photoUrl(brandLogo?.fileName)}
          />
        ),
      },
    ],
    [
      storeName,
      isAdmin,
      onLinkChange,
      widgetState?.link,
      widgetState?.error,
      handleUploadLogo,
      brandLogo,
      updateWidgetState,
    ]
  );

  return {
    SETUP_STEPS,
    updateLinkAvailability,
    onLinkChange,
    handleUploadStoreLink,
    orderDirectConfigured,
    handleUploadLogo,
  };
};
