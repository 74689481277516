import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  textInput: {
    fontSize: "14px",
    height: "37px",
  },

  inputLabel: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#ACAEAE",
  },
}));

export default useStyles;
