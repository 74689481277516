import { array } from "prop-types";
import { Link } from "react-router-dom";
import moment from "moment";
import { Box, Typography, Link as MuiLink } from "@mui/material";
import { StyledTooltip } from "components";
import { InformationIcon } from "components/Icons";
import { separateNumWithComma } from "helpers/helpers";

export const LastOrderPrice = ({ lastOrders }) => {
  return (
    <>
      <StyledTooltip
        arrow
        placement="top"
        isDark
        title={
          <Box width="250px">
            <Box my={0.2}>Last order price</Box>
            {lastOrders.map((item) => (
              <Box
                key={item?.id}
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: "40%",
                    fontSize: 12,
                  }}
                  component={Typography}
                  noWrap
                >
                  -{" "}
                  <MuiLink
                    sx={{
                      textDecoration: "underline",
                      cursor: "pointer",
                      color: "#FFF",
                    }}
                    component={Link}
                    to={`/orders/${item?.id}`}
                  >{`Order #${item?.customId?.customId}`}</MuiLink>
                </Box>
                <Box
                  sx={{
                    width: "30%",
                    textAlign: "end",
                    fontSize: 12,
                    pl: 0.5,
                  }}
                  component={Typography}
                  noWrap
                >
                  {moment(item?.createdAt).format("MMM DD, YY")}
                </Box>
                <Box
                  sx={{
                    width: "30%",
                    textAlign: "end",
                    fontSize: 12,
                    pl: 0.5,
                  }}
                  component={Typography}
                  noWrap
                >
                  ${separateNumWithComma(item?.price)}
                </Box>
              </Box>
            ))}
          </Box>
        }
        PopperProps={{
          modifiers: [{ name: "offset", options: { offset: [0, -12] } }],
        }}
      >
        <Box component="span" cursor="pointer">
          <InformationIcon width="9px" height="9px" stroke="#929292" />
        </Box>
      </StyledTooltip>
    </>
  );
};

LastOrderPrice.propTypes = {
  lastOrders: array,
};
LastOrderPrice.defaultProps = {
  lastOrders: [],
};
