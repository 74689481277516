import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { array, func, bool, any } from "prop-types";

import { Box, CircularProgress, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { StyledButton, StyledTabs } from "components";
import { ArrowLeftIcon } from "components/Icons";
import { ImportContext } from "Pages/SettingsPage/SettingsPage";
import { useImport } from "../SettingsTabs/ImportTab/Import.hooks";

const useStyles = makeStyles(() => ({
  label: {
    "& .MuiFormLabel-root": {
      color: "#B5B5AC",
      fontSize: "12px",
      top: "2px",
    },
    "& .Mui-focused": {
      color: "#42A57F",
      fontSize: "1rem",
      top: "0px",
    },
    "& .MuiOutlinedInput-root": {
      height: "100%",
    },
  },
  tabs: {
    minHeight: "auto",
    height: "44px",
    "& .Mui-selected": {
      backgroundColor: "transparent!important",
    },

    "& .MuiTab-root": {
      paddingTop: 0,
      paddingBottom: 0,
      height: "44px",
      fontSize: "12px",
      fontWeight: 400,
      "&:not(.Mui-selected)": {
        color: "#9C9C94",
      },
      textTransform: "none",
    },
  },
  smallerButton: {
    height: "24px",
    "& > span": {
      fontSize: "11px",
    },
  },

  biggerButton: {
    "& > span": {
      fontSize: "14px",
    },
  },
}));

const SettingsSwitcherTab = ({
  tabs,
  currentTab,
  handleSetCurrentTab,
  loading,
  goBack,
  // locationState,
  showAddTagBtn,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const handleChangeCurrentTab = (e, newValue) => {
    handleSetCurrentTab(newValue);
  };

  const { importData, setImportData } = useContext(ImportContext);
  const { handleImportNext, importNextDisabled } = useImport();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        px: "32px",
        backgroundColor: "#F0F0F0",
        borderBottom: "0.5px solid #D4D4D4",
        height: "44px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: 3,
        }}
      >
        <Box>
          <Typography
            variant="h6"
            fontWeight="400"
            fontSize="20px"
            color="#9C9C94"
          >
            Settings
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <StyledTabs
            className={classes.tabs}
            onChange={handleChangeCurrentTab}
            value={currentTab}
            tabs={tabs}
            tabProps={{ className: "" }}
          />
          {loading && <CircularProgress size="20px" />}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: 1,
        }}
      >
        {/* {currentTab === tabs[5].value && (
          <StyledButton
            sx={{ height: 28 }}
            variant="contained"
            label="Add term"
            component={Link}
            to={`payterm/new`}
            state={locationState}
          />
        )} */}
        {showAddTagBtn && currentTab === "Orders" && (
          <StyledButton
            sx={{ height: 28 }}
            variant="contained"
            label="Add tag"
            component={Link}
            to={`tags/new`}
          />
        )}
        {importData.isImport && (
          <>
            <StyledButton
              color="greySecondary"
              startIcon={<ArrowLeftIcon />}
              label="Back"
              onClick={() => {
                if (goBack) return navigate(-1);
                setImportData((prev) => {
                  if (!prev.step || prev.step === 1)
                    return { ...prev, isImport: false };
                  return { ...prev, step: prev.step - 1 };
                });
              }}
            />
            <StyledButton
              disabled={importNextDisabled}
              label={importData.step === 2 ? "Import" : "Next"}
              variant="contained"
              sx={{ height: "28px" }}
              onClick={handleImportNext}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

SettingsSwitcherTab.propTypes = {
  tabs: array,
  currentTab: any,
  handleSetCurrentTab: func,
  loading: bool,
  goBack: bool,
  // locationState: object,
  showAddTagBtn: bool,
};
SettingsSwitcherTab.defaultProps = {
  tabs: [],
  loading: false,
  goBack: false,
  // locationState: {},
  showAddTagBtn: false,
};

export default SettingsSwitcherTab;
