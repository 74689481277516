import { useContext, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { CustomerContext } from "Pages/CustomersPage/pages/CustomerPage/CustomerPage";
import { Controller } from "react-hook-form";
import { LargeCheckbox, StyledTooltip } from "components";

export const IsASubBusinessBlock = () => {
  const { control, setValue, formField } = useContext(CustomerContext);
  const [isHovered, setIsHovered] = useState(false);

  const { isASubBusiness } = formField || {};
  return (
    <StyledTooltip
      title={`${formField?.name} is already a parent customer. Customers are limited to 2 levels.`}
      placement="top"
      arrow
      isDark
      sx={{
        color: "#fffff",
        "& .MuiTooltip-tooltip": {
          width: "250px !important",
          background: "rgba(97, 97, 97, 0.92)",
          top: "10px !important",
          left: "-178px",
        },
        "& .MuiTooltip-arrow": {
          left: "-54px !important",
        },
        "& .MuiTooltip-arrow:before": {
          background: "rgba(97, 97, 97, 0.92) ",
        },
      }}
      open={!!formField?.disableSubCustomer && isHovered}
    >
      <Grid
        mt="-16px"
        mb={isASubBusiness ? "-16px" : "14px"}
        xs={12}
        item
        container
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Controller
          render={({ field }) => (
            <LargeCheckbox
              adminIsAllowed
              disabled={formField?.disableSubCustomer}
              size={16}
              formSx={{ pl: 0.4 }}
              label={
                <Typography
                  variant="caption"
                  sx={{
                    fontSize: 13,
                    fontWeight: 400,
                    color: "#5F6267",
                    letterSpacing: "normal",
                  }}
                >
                  Is a sub-business
                </Typography>
              }
              onClick={() => {
                if (field.value) {
                  setValue("parentCustomerId", "");
                  setValue("parentCustomer", null);
                }
              }}
              {...field}
              checked={formField?.disableSubCustomer ? false : !!field.value}
            />
          )}
          name="isASubBusiness"
          control={control}
        />
      </Grid>
    </StyledTooltip>
  );
};
