export const FilledPlusIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      {...props}
    >
      <circle
        id="Ellipse_951"
        data-name="Ellipse 951"
        cx="9"
        cy="9"
        r="9"
        fill="#409a65"
      />
      <path
        id="Path_4215"
        data-name="Path 4215"
        d="M5074.5-17859.033v10.688"
        transform="translate(-5065.5 17862.689)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth="1"
      />
      <path
        id="Path_4216"
        data-name="Path 4216"
        d="M5074.5-17859.033v10.688"
        transform="translate(-17844.689 -5065.5) rotate(90)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth="1"
      />
    </svg>
  );
};
