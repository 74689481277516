import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  paperWrapper: {
    boxShadow: "none",
    border: "0.5px solid #D5D9D9",
  },
}));

export default useStyles;
