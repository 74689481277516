import { func } from "prop-types";
import { DialogTitle, IconButton, Stack } from "@mui/material";
import { CrossIcon } from "components/Icons";

export const FilterViewHeader = ({ onClose }) => {
  return (
    <Stack sx={{ borderBottom: "0.5px solid #CCCCCC" }}>
      <DialogTitle
        sx={{
          fontSize: "20px",
          color: "#080808",
          fontWeight: 400,
          p: "12px 28px 10px 28px",
          position: "relative",
        }}
      >
        View name
      </DialogTitle>
      <IconButton
        sx={{ position: "absolute", right: "6px", top: "10px" }}
        onClick={onClose}
      >
        <CrossIcon />
      </IconButton>
    </Stack>
  );
};

FilterViewHeader.propTypes = {
  onClose: func,
};
FilterViewHeader.defaultProps = {
  onClose: () => {},
};
