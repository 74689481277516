import React from "react";

export const TrashCanIcon = () => {
  return (
    <svg
      id="icons8-trash-can"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 16 20"
    >
      <path
        id="icons8-trash-can-2"
        data-name="icons8-trash-can"
        d="M12.075,4l-.25.234-.575.6H6V6.5h.8v15A2.465,2.465,0,0,0,9.2,24h9.6a2.465,2.465,0,0,0,2.4-2.5V6.5H22V4.833H16.75l-.575-.6L15.925,4Zm.675,1.667h2.5l.575.6.25.234H19.6v15a.815.815,0,0,1-.8.833H9.2a.815.815,0,0,1-.8-.833V6.5h3.525l.25-.234ZM10,9.833V19h1.6V9.833Zm3.2,0V19h1.6V9.833Zm3.2,0V19H18V9.833Z"
        transform="translate(-6 -4)"
        fill="#5e5e5e"
      />
    </svg>
  );
};
