import { Grid, Typography } from "@mui/material";
import { HEADER_ITEMS, HEADER_ITEMS_NO_BANK } from "./PayoutsHeader.constants";
import { bool } from "prop-types";

import useStyles from "./styles";

const PayoutsHeader = ({ hasBankAccount }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.headerWrapper} container>
      {(hasBankAccount ? HEADER_ITEMS : HEADER_ITEMS_NO_BANK).map(
        ({ name, size, styles }) => (
          <Grid
            key={name}
            sx={{ ...styles }}
            xs={size}
            item
            component={Typography}
          >
            {name}
          </Grid>
        )
      )}
    </Grid>
  );
};

PayoutsHeader.propTypes = {
  hasBankAccount: bool,
};

export default PayoutsHeader;
