import { Fragment } from "react";
import { Avatar, Box, Typography } from "@mui/material";
import Slider from "react-slick";
import { array, bool } from "prop-types";
import { photoUrl, stringAvatar } from "../../helpers/helpers";
import {
  NextArrow,
  PrevArrow,
} from "../../Pages/CatalogPage/CatalogNewProductPage/ContentTabs/Arrows";
import useStyles from "./styles";
import { StyledTooltip } from "components";
import { normalizeUppercaseString } from "helpers/helpers";

export const AvatarSlider = ({ sliderItems, isOrderList }) => {
  const classes = useStyles();

  const sliderSettings = {
    infinite: false,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: (
      <NextArrow
        width="4.92"
        height="8.6"
        color="#1C1C19"
        top={isOrderList ? "45%" : "35%"}
      />
    ),
    prevArrow: (
      <PrevArrow
        width="4.92"
        height="8.6"
        color="#1C1C19"
        top={isOrderList ? "45%" : "35%"}
      />
    ),
  };

  const thirdPartyLabel = (rep) => {
    return rep?.representative?.role === "THIRD_PARTY" ? (
      <Box className={classes.thirdPartyLabel}>3rd Party</Box>
    ) : (
      ""
    );
  };

  const handleAvatarContent = (rep) => (
    <Box sx={{ textAlign: "center" }}>
      <Typography fontSize="10px" color="primary">
        {!!rep?.representative?.role &&
          (rep?.representative?.role === "THIRD_PARTY"
            ? "3rd Party Rep"
            : `${normalizeUppercaseString(rep?.representative?.role)} Rep`)}
      </Typography>
      <Typography fontSize="10px" color="#363531">
        {rep?.representative?.name}
      </Typography>
    </Box>
  );

  if (!sliderItems.length)
    return (
      <Typography sx={{ textAlign: "left", mr: "20px", ml: "7px" }}>
        -
      </Typography>
    );

  return (
    <Box
      className={classes.sliderWrapper}
      sx={{
        position: isOrderList && "absolute",
        top: isOrderList && "-20px",
        left: isOrderList && "-5px",
      }}
    >
      <Slider {...sliderSettings}>
        {sliderItems.map((rep) => {
          const photo = rep.representative?.profilePhoto?.fileName;
          if (photo) {
            return (
              <Fragment
                key={rep.representative?.id || rep.representative?.name}
              >
                {thirdPartyLabel(rep)}
                <StyledTooltip
                  arrow
                  placement="top"
                  title={handleAvatarContent(rep)}
                  PopperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: { offset: [0, -5] },
                      },
                    ],
                  }}
                >
                  <Avatar src={photoUrl(photo)} className={classes.avatar} />
                </StyledTooltip>
              </Fragment>
            );
          }

          return (
            <Fragment key={rep.representative?.id || rep.representative?.name}>
              {thirdPartyLabel(rep)}
              <StyledTooltip
                placement="top"
                arrow
                title={handleAvatarContent(rep)}
                PopperProps={{
                  modifiers: [
                    {
                      name: "offset",
                      options: { offset: [0, -5] },
                    },
                  ],
                }}
              >
                <Avatar
                  {...stringAvatar(rep.representative?.name, {
                    height: "30px",
                    fontSize: "12px",
                    display: "flex!important",
                    width: "30px!important",
                  })}
                />
              </StyledTooltip>
            </Fragment>
          );
        })}
      </Slider>
    </Box>
  );
};

AvatarSlider.propTypes = {
  sliderItems: array,
  isOrderList: bool,
};

AvatarSlider.propTypes = {
  setAssignedAnchorEl: () => {},
};

export default AvatarSlider;
