import { array, bool, string, oneOf } from "prop-types";
import { TotalSummaryCustomersItem } from "./TotalSummaryCustomersItem";
import { Box, Typography } from "@mui/material";
import { TotalSummarySellersItem } from "./TotalSummarySellersItem";
import { TotalSummarySalesRepsItem } from "./TotalSummarySalesRepsItem";

export const TotalSummaryBlock = ({ name, isLoading, topName, topThree }) => {
  if (topThree?.length !== 3) return <Box />;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        gap: "clamp(6px, 0.55vw, 12px)",
        width: "100%",
      }}
    >
      <Typography
        width="clamp(80px, 20%, 200px)"
        fontSize="clamp(14px, 1.2vw, 22px)"
        fontWeight={600}
        color="#5F6267"
        sx={{ whiteSpace: "nowrap", mr: 0.5 }}
      >
        {name}
      </Typography>

      {topName === "topCustomers" &&
        topThree?.map((customer) => (
          <TotalSummaryCustomersItem
            key={customer.id}
            customer={customer}
            isLoading={isLoading}
          />
        ))}
      {topName === "topProducts" &&
        topThree?.map((seller) => (
          <TotalSummarySellersItem
            key={seller.id}
            seller={seller}
            isLoading={isLoading}
          />
        ))}
      {topName === "topRepresentatives" &&
        topThree?.map((rep) => (
          <TotalSummarySalesRepsItem
            key={rep.id}
            salesRep={rep}
            isLoading={isLoading}
          />
        ))}
    </Box>
  );
};

TotalSummaryBlock.propTypes = {
  name: string,
  isLoading: bool,
  topName: oneOf(["topCustomers", "topProducts", "topRepresentatives"]),
  topThree: array,
};
TotalSummaryBlock.defaultProp = {
  name: "",
  isLoading: false,
  topThree: [],
};
