import { array, func, string, bool } from "prop-types";
import { Typography, Box } from "@mui/material";
import { DangerIcon } from "../../../Icons";
import SelectTable from "./components/SelectTable/SelectTable";

const AssignReps = ({
  checkedCustomers,
  merchList,
  salesList,
  thirdPartyList,
  selectedMerch,
  selectedSales,
  selectedThirdParty,
  setSelectedMerch,
  setSelectedSales,
  setSelectedThirdParty,
  titleText,
  isRoute,
}) => {
  return (
    <Box px="23px">
      {!isRoute && (
        <Box
          sx={{
            backgroundColor: "#F8F8FA",
            width: "100%",
            height: "78px",
          }}
        />
      )}
      <Box display="flex" justifyContent="space-between" mt="20px">
        <Box display="flex" alignItems="center" gap="16px">
          {!isRoute && <DangerIcon />}
          <Typography fontSize="15px" color="edit.main">
            {titleText}
          </Typography>
        </Box>
        {!isRoute && (
          <Box
            sx={{
              "& .MuiTypography-root": {
                fontSize: "15px",
              },
            }}
          >
            <Typography display="inline" color="#707070">
              Selected{" "}
            </Typography>
            <Typography display="inline" color="primary">
              {checkedCustomers.length}
            </Typography>
            <Typography display="inline" color="#707070">
              {" "}
              reps
            </Typography>
          </Box>
        )}
      </Box>
      <SelectTable
        selectedReps={selectedSales}
        repsList={salesList}
        setSelectedReps={setSelectedSales}
        repLabel="Sales"
      />
      <SelectTable
        selectedReps={selectedMerch}
        repsList={merchList}
        setSelectedReps={setSelectedMerch}
        repLabel="Merchendiser"
      />
      {!isRoute && (
        <SelectTable
          selectedReps={selectedThirdParty}
          repsList={thirdPartyList}
          setSelectedReps={setSelectedThirdParty}
          repLabel="3rd Party"
        />
      )}
    </Box>
  );
};

AssignReps.propTypes = {
  checkedCustomers: array,
  merchList: array,
  salesList: array,
  thirdPartyList: array,
  selectedMerch: array,
  selectedSales: array,
  selectedThirdParty: array,
  setSelectedMerch: func,
  setSelectedSales: func,
  setSelectedThirdParty: func,
  titleText: string,
  isRoute: bool,
};

AssignReps.defaultProps = {
  checkedCustomers: [],
  merchList: [],
  salesList: [],
  thirdPartyList: [],
  selectedMerch: [],
  selectedSales: [],
  selectedThirdParty: [],
  titleText: "Select to which representative you want to assign customers to.",
  isRoute: false,
};

export default AssignReps;
