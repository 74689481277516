import { func, object } from "prop-types";
import { Box, Typography } from "@mui/material";
import { StyledButton } from "components";
import { useMemo } from "react";

export const DueDateDrawerActions = ({ onClose, formField, errors }) => {
  const disabled = useMemo(() => {
    return !formField?.dueDate || !!Object.keys(errors)?.length;
  }, [errors, formField?.dueDate]);

  return (
    <Box
      sx={{
        borderTop: "0.5px solid #D5D9D9",
        background: "#F8F8F8",
        padding: "24px 0",
        px: "40px",
        display: "flex",
        alignItems: "center",
        gap: 1,
      }}
    >
      <Box flexGrow={1} />
      <Box>
        <StyledButton
          sx={{ height: 31, borderColor: "#D5D9D9" }}
          onClick={onClose}
          label={
            <Typography fontSize={15} color="#5f6267">
              Cancel
            </Typography>
          }
          variant="text"
        />
        {/* <StyledTooltip {...tooltipOptions}> */}
        <Box component="span">
          <StyledButton
            disabled={disabled}
            variant="contained"
            type="submit"
            form="due-date-drawer-form"
            label="Set"
            fontSize="15px"
            sx={{
              height: "31px",
              width: "78px",
              boxShadow: "none",
              ml: "10px",
            }}
          />
        </Box>
        {/* </StyledTooltip> */}
      </Box>
    </Box>
  );
};

DueDateDrawerActions.propTypes = {
  onClose: func,
  formField: object,
  errors: object,
};
DueDateDrawerActions.defaultProps = {
  onClose: () => {},
  formField: {},
  errors: {},
};
