import { string } from "prop-types";
import { Box } from "@mui/material";
import { photoUrl } from "helpers/helpers";

export const ProfilePhotoBlock = ({ fileName }) => {
  return (
    <Box
      component="img"
      sx={{
        // width: "100%",
        height: "100%",
        objectFit: "contain",
        objectPosition: "0 0",
      }}
      src={photoUrl(fileName)}
    />
  );
};

ProfilePhotoBlock.propTypes = { fileName: string };
ProfilePhotoBlock.defaultProps = { fileName: "" };
