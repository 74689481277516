import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "0.5px solid #d5d9d9",
    padding: "7px 1px 9px 24px",
    "& .MuiTypography-root": {
      fontSize: "24px",
      color: "#707070",
    },
  },

  dialogContent: {
    width: "398px",
    padding: "12px 24px 0px!important",
  },

  fieldsWrap: {
    display: "flex",
    gap: 16,

    paddingTop: "16px",
    "&:not(:last-of-type)": {
      paddingBottom: "20px",
      borderBottom: "0.5px solid #d5d9d9",
    },
  },

  blockTitle: {
    fontSize: "12px",
    fontWeight: "300",
    marginTop: "12px",
    color: "#1c1c19",
  },

  addressBlock: {
    padding: "10px 0 5px 0",
  },

  dialogActions: {
    padding: "19px 24px 24px",
  },

  warningBlock: {
    padding: "6px 10px 9px 12px",
    border: "1px solid #EB4233",
    backgroundColor: "rgba(235, 66, 51, 0.1)",
    marginBottom: "13px",
    borderRadius: "4px",
    "& .MuiTypography-root": {
      fontSize: "12px",
    },

    "& .title": {
      display: "flex",
      alignItems: "center",
      gap: "9px",
    },
  },
}));

export default useStyles;
