import { useEffect, useState } from "react";
import { bool, func, string } from "prop-types";
import { Box, Button, Dialog, IconButton, Typography } from "@mui/material";
import { CrossIcon, PrinterIcon } from "components/Icons";
import { LargeCheckbox } from "components";

export const PrintPickOrdersDialog = ({
  open,
  handleClose,
  handlePrint,
  title = "Print Picklist",
  checkboxText = "Group Orders",
  defaultChecked = false,
}) => {
  const [isCheckedGroupOrders, setCheckedGroupOrders] =
    useState(defaultChecked);

  useEffect(() => {
    if (!open) return setCheckedGroupOrders(false);

    return setCheckedGroupOrders(defaultChecked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Dialog open={open}>
      <Box
        sx={{
          width: "230px",
          height: "55px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "0.5px solid #D5D9D9",
          pl: "37px",
          pr: "7px",
        }}
      >
        <Typography fontSize={17} fontWeight={500} color="#3F3F3F">
          {title}
        </Typography>
        <IconButton onClick={handleClose}>
          <CrossIcon />
        </IconButton>
      </Box>

      <Box
        sx={{
          height: "54px",
          display: "flex",
          alignItems: "center",
          pl: "37px",
        }}
      >
        <LargeCheckbox
          formSx={{
            mr: 0,
            flex: "1 1 auto",
            ml: "-9px",
            color: "#707070",
          }}
          label={
            <Typography fontSize={15} fontWeight={300} color="#707070">
              {checkboxText}
            </Typography>
          }
          size={16}
          checked={isCheckedGroupOrders}
          onChange={() => setCheckedGroupOrders((prev) => !prev)}
        />
      </Box>

      <Box px="12px" pb="12px">
        <Button
          sx={{
            height: "41px",
            fontSize: 18,
            fontWeight: 500,
          }}
          startIcon={<PrinterIcon width="16.89" height="14.79" />}
          fullWidth
          variant="contained"
          onClick={() => handlePrint(isCheckedGroupOrders)}
        >
          Print
        </Button>
      </Box>
    </Dialog>
  );
};

PrintPickOrdersDialog.propTypes = {
  open: bool,
  handleClose: func,
  handlePrint: func,
  title: string,
  checkboxText: string,
  defaultChecked: bool,
};
PrintPickOrdersDialog.defaultProps = {
  open: false,
  handleClose: () => {},
  handlePrint: () => {},
  title: "Print Picklist",
  checkboxText: "Group Orders",
  defaultChecked: false,
};
