import { useContext } from "react";
import { Controller } from "react-hook-form";
import NumberFormat from "react-number-format";
import { Box, FormControl, Grid } from "@mui/material";
import { StyledTextField } from "components";
import { CustomerContext } from "Pages/CustomersPage/pages/CustomerPage/CustomerPage";

export const DiscountsBlock = () => {
  const { control } = useContext(CustomerContext);

  return (
    <Grid xs={12} columnSpacing={2.75} item container>
      <Grid xs={6} item>
        <Controller
          render={({ field, fieldState: { error } }) => (
            <FormControl fullWidth>
              <NumberFormat
                adminIsAllowed
                customInput={StyledTextField}
                fullWidth
                noErrorMessage
                formSx={{ mb: 2 }}
                size="small"
                placeholder="0"
                InputProps={{
                  sx: {
                    fontSize: "13px",
                    height: "43px",
                    paddingRight: "6px",
                  },
                  endAdornment: (
                    <Box
                      sx={{
                        width: "32px",
                        height: "32px",
                        minWidth: "32px",
                        minHeight: "32px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "4px",
                        backgroundColor: "#F8F8F8",
                      }}
                    >
                      %
                    </Box>
                  ),
                }}
                InputLabelProps={{
                  sx: {
                    fontSize: "13px",
                    color: "#5F6267",
                    top: 3,
                  },
                }}
                error={error ? error.message : ""}
                label="Percent discount"
                {...field}
              />
            </FormControl>
          )}
          name="percentDiscount"
          control={control}
        />
        <Grid xs={6} item />
      </Grid>
    </Grid>
  );
};
