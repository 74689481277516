import { number, bool } from "prop-types";
import { Typography } from "@mui/material";
import { separateNumWithComma } from "helpers/helpers";

export const SumBlock = ({ wholesalePrice, planeView }) => {
  return (
    <Typography fontSize={12} fontWeight={400} color="#1C1C19" noWrap>
      {planeView
        ? `$${separateNumWithComma(wholesalePrice)}`
        : `$${wholesalePrice}`}
    </Typography>
  );
};

SumBlock.propTypes = { wholesalePrice: number, planeView: bool };
SumBlock.defaultProps = { wholesalePrice: 0, planeView: false };
