import axios from "axios";

export const getContactRolesService = async (params) => {
  return axios.get("contact-roles", { params }).then((res) => res.data);
};

export const createContactRoleService = async (body) => {
  return axios.post("contact-roles", body).then((res) => res.data);
};

export const createContactRoleBulkService = async (body) => {
  return axios.post("contact-roles/bulk", body).then((res) => res.data);
};

export const updateContactRoleService = async (id, body) => {
  return axios.put(`contact-roles/${id}`, body).then((res) => res.data);
};

export const deleteContactRoleService = async (id) => {
  return axios.delete(`contact-roles/${id}`).then((res) => res.data);
};
