import React from "react";
import { func, array, string, object } from "prop-types";
import { Box, Stack, Typography } from "@mui/material";
import { LargeCheckbox } from "components";
import useStyles from "./styles";

export const CheckboxContainer = ({
  duplicate,
  type,
  setCheckedState,
  checkedCustomers,
}) => {
  const classes = useStyles();

  const isChecked = !!checkedCustomers.find(
    (customer) => customer.id === duplicate.id
  );

  return (
    <Box className={classes.checkboxContainerWrapper}>
      <Stack direction="row" gap="10px">
        <Box height="16px">
          <LargeCheckbox
            checked={isChecked}
            onChange={() => setCheckedState(duplicate)}
            size={16}
            formSx={{
              m: 0,
              "& .MuiCheckbox-root": {
                p: 0,
              },
            }}
          />
        </Box>
        <Stack
          direction="column"
          sx={{ maxWidth: "300px", overflow: "hidden" }}
        >
          <Stack direction="row" gap="4px" alignItems="center">
            <Typography className={classes.customerTitle} noWrap>
              {duplicate.name}
            </Typography>
            <Typography className={classes.customerId}>
              (ID: {duplicate.customId.customId})
            </Typography>
          </Stack>
          <Typography className={classes.customerId}>
            {duplicate[type]?.formatted_address}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

CheckboxContainer.propTypes = {
  duplicate: object,
  type: string,
  handleDelete: func,
  setCheckedState: func,
  checkedCustomers: array,
};
