import { object } from "prop-types";
import { Box } from "@mui/material";
import { StyledProductIco } from "components/Icons";
import { photoUrl } from "helpers/helpers";

export const PhotoBlock = ({ product }) => {
  return (
    <Box
      sx={{
        width: "32px",
        height: "32px",
        backgroundColor: "white",
      }}
    >
      <StyledProductIco
        src={
          product?.photos?.length ? photoUrl(product.photos[0].fileName) : ""
        }
        styles={{
          objectFit: "contain",
          width: "100%",
          height: "100%",
          borderRadius: "4px",
          border: "1px solid #D5D9D9",
        }}
        placeholderStyles={{
          width: "32px",
          height: "32px",
        }}
      />
    </Box>
  );
};

PhotoBlock.propTypes = { product: object };
PhotoBlock.defaultProps = { product: {} };
