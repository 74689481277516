/* eslint-disable react/prop-types */
import { Box } from "@mui/material";
import {
  DeliveryStatusBlock,
  OtherReasonBlock,
  PaymentStatusBlock,
  ReturnReasonBlock,
} from "./components";

export const StatusesBlock = ({ headerData, control }) => {
  const { paymentStatus, deliveryStatus, reason } = headerData || {};

  return (
    <>
      <Box width="100%" display="flex">
        <Box
          width="47%"
          display="flex"
          flexDirection="column"
          justifyContent="space-around"
        >
          <PaymentStatusBlock {...{ paymentStatus }} />
          <DeliveryStatusBlock {...{ deliveryStatus }} />
        </Box>

        <Box width="53%">
          <ReturnReasonBlock {...{ control }} />
        </Box>
      </Box>

      {reason === "Other" && (
        <Box mt={1}>
          <OtherReasonBlock {...{ control }} />
        </Box>
      )}
    </>
  );
};
