import moment from "moment";
import { object, func } from "prop-types";
import { forwardRef, Fragment } from "react";

import {
  CheckboxIcon,
  InformationIcon,
  StyledProductIco,
} from "components/Icons";

import { photoUrl } from "helpers/helpers";

import useStyles from "./styles";

import { Grid, Typography, Box, Checkbox } from "@mui/material";
import { StyledTooltip } from "components";

const PurchasedProductItem = forwardRef(
  ({ product, handleIsAlreadyAdded, handleCheckProduct }, ref) => {
    const {
      sku,
      name,
      photos,
      status,
      quantity,
      orderProducts,
      parentProduct,
      wholesalePrice,
    } = product;

    const classes = useStyles();
    const isArchived = status === "INACTIVE";
    const isChecked = handleIsAlreadyAdded(product.id);

    const ITEM_DATA = [
      {
        element: (
          <Grid xs={3.9} item display="flex" alignItems="center" gap="8px">
            {isArchived ? (
              <StyledTooltip
                arrow
                title="This product has been archived"
                placement="top"
                PopperProps={{
                  modifiers: [
                    {
                      name: "offset",
                      options: { offset: [0, -5] },
                    },
                  ],
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    pr: "4px",
                  }}
                >
                  <InformationIcon size="16" stroke="#FF6969" />
                </Box>
              </StyledTooltip>
            ) : (
              <Checkbox
                icon={<CheckboxIcon size={16} />}
                sx={{
                  padding: 0,
                  mr: isChecked ? "2px" : "4px",
                  "& .MuiSvgIcon-root": {
                    fontSize: isChecked ? 20.5 : 31,
                    ml: isChecked ? "-2.5px" : 0,
                  },
                }}
                checked={!!isChecked}
                onClick={(e) => e.stopPropagation()}
                onChange={() => handleCheckProduct(product)}
              />
            )}

            <Box className={classes.productPhoto} style={{ height: "35px" }}>
              <StyledProductIco
                src={photos?.length ? photoUrl(photos[0].fileName) : ""}
                styles={{
                  objectFit: "contain",
                  width: "35px",
                  height: "35px",
                  borderRadius: "4px",
                }}
                placeholderStyles={{
                  style: {
                    width: "35px",
                    height: "35px",
                    borderRadius: "4px",
                    border: "1px solid #D5D9D9",
                    boxSizing: "border-box",
                    marginTop: "0.5px",
                  },
                }}
              />
            </Box>

            <Grid
              item
              display="flex"
              flexDirection="column"
              justifyContent="center"
              overflow="hidden"
              width="calc(100% - 43px)"
            >
              <Box display="flex" alignItems="center" gap={0.5}>
                <Typography className={classes.text} fontWeight="500" noWrap>
                  {name || parentProduct?.name}
                </Typography>
              </Box>

              {sku && (
                <Typography className={classes.text} fontWeight="300" noWrap>
                  SKU: {sku}
                </Typography>
              )}
            </Grid>
          </Grid>
        ),
      },
      {
        element: (
          <Grid item xs={2.2} display="flex" alignItems="center">
            {orderProducts[0]?.order?.createdAt && (
              <a
                href={`/orders/${orderProducts[0]?.order?.id}`}
                target="_blank"
                rel="noreferrer"
              >
                <Typography
                  className={classes.text}
                  sx={{ textDecoration: "underline", cursor: "pointer" }}
                >
                  {moment(orderProducts[0]?.order?.createdAt).format(
                    "MMM D, Y h:mm A"
                  )}
                </Typography>
              </a>
            )}
          </Grid>
        ),
      },
      {
        element: (
          <Grid item xs={1} display="flex" alignItems="center">
            <Typography className={classes.text}>
              ${(wholesalePrice || 0).toFixed(2)}
            </Typography>
          </Grid>
        ),
      },
      {
        element: (
          <Grid item xs={1.2} className={classes.grid}>
            {!!quantity && (
              <Grid className={classes.orderedQty}>
                <Typography fontSize="9px" color="#1C1C19">
                  {quantity}
                </Typography>
              </Grid>
            )}
          </Grid>
        ),
      },
    ];

    return (
      <Grid
        ref={ref}
        container
        key={product.id}
        gap="8px"
        columns={9}
        px="9px"
        height="45px"
        sx={{
          py: "5px",
          pl: "12px",
          overflow: "hidden",
          opacity: isArchived ? 0.5 : 1,
          position: "relative",
          borderBottom: "0.5px solid #D5D9D9",
        }}
      >
        {ITEM_DATA.map(({ element }, index) => (
          <Fragment key={index}>{element}</Fragment>
        ))}
      </Grid>
    );
  }
);

PurchasedProductItem.propTypes = {
  product: object,
  handleCheckProduct: func,
  handleIsAlreadyAdded: func,
};

PurchasedProductItem.defaultProps = {
  product: null,
  handleCheckProduct: () => {},
  handleIsAlreadyAdded: () => {},
};
PurchasedProductItem.displayName = "PurchasedProductItem";

export default PurchasedProductItem;
