import { bool, string, number } from "prop-types";
import { Box, Typography } from "@mui/material";

export const CostPerUnitBlock = ({
  hasThirdParty,
  listPriceCase,
  productItemsPerCase,
  isFreeCase,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-end",
        // minWidth: !hasThirdParty ? "12.45%" : "17.45%",
        // width: !hasThirdParty ? "12.45%" : "17.45%",
        minWidth: !hasThirdParty ? "14.45%" : "19.45%",
        width: !hasThirdParty ? "14.45%" : "19.45%",
      }}
    >
      <Typography sx={{ fontSize: 13, color: "#1C1C19" }} noWrap>
        {isFreeCase ? "Free" : listPriceCase}
        {!isFreeCase && productItemsPerCase > 1 && " case"}
      </Typography>
      {!isFreeCase && productItemsPerCase > 1 && (
        <Typography sx={{ fontSize: 13, color: "#1C1C19" }} mt="2px" noWrap>
          {(listPriceCase / productItemsPerCase).toFixed(2)} item
        </Typography>
      )}
    </Box>
  );
};

CostPerUnitBlock.propTypes = {
  hasThirdParty: bool,
  listPriceCase: string,
  productItemsPerCase: number,
  isFreeCase: bool,
};
CostPerUnitBlock.defaultProps = {
  productItemsPerCase: 0,
  isFreeCase: false,
};
