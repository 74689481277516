import * as React from "react";

export const SignoutFilledIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    // width={18.79}
    width={17.79}
    // height={17.5}
    height={16.5}
    viewBox="0 0 18.79 17.5"
    {...props}
  >
    <g
      id="Group_5012"
      data-name="Group 5012"
      transform="translate(-1053.25 -16018.25)"
    >
      <path
        id="Path_3141"
        data-name="Path 3141"
        d="M34,254V244a2,2,0,0,0-2-2H26a2,2,0,0,0-2,2v12a2,2,0,0,0,2,2h6a2,2,0,0,0,2-2v-2"
        transform="translate(1030 15777)"
        fill="#409a65"
        stroke="#409a65"
        strokeLinecap="round"
        strokeWidth={1.5}
      />
      <path
        id="Path_3142"
        data-name="Path 3142"
        d="M32,250h4.15"
        transform="translate(1034.134 15777)"
        fill="none"
        stroke="#409a65"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
      <path
        id="Path_3143"
        data-name="Path 3143"
        d="M37,247l2,3"
        transform="translate(1032 15777)"
        fill="none"
        stroke="#409a65"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
      <path
        id="Path_3144"
        data-name="Path 3144"
        d="M37,253l2-3"
        transform="translate(1032 15777)"
        fill="none"
        stroke="#409a65"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
    </g>
  </svg>
);
