import { DATA_KEYS } from "utils/constants";
import {
  createDraftService,
  updateDraftService,
  getDraftsService,
} from "../../services/drafts";
import { error } from "../../utils/notifications";

export const GET_DRAFTS = "GET_DRAFTS";
export const START_LOADING_DRAFTS = "START_LOADING_DRAFTS";
export const END_LOADING_DRAFTS = "END_LOADING_DRAFTS";
export const DELETE_DRAFT = "DELETE_DRAFT";
export const GET_DRAFTS_COUNT = "GET_DRAFTS_COUNT";
export const SET_FETCHED = "SET_FETCHED";

export const getDrafts = (payload) => ({
  type: GET_DRAFTS,
  payload,
});

export const deleteDraftAction = (payload) => ({
  type: DELETE_DRAFT,
  payload,
});

export const setFetchedAction = (payload) => ({
  type: SET_FETCHED,
  payload,
});

export const startLoadingDrafts = () => ({ type: START_LOADING_DRAFTS });
export const endLoadingDrafts = () => ({ type: END_LOADING_DRAFTS });

export const getDraftsAction = () => {
  return (dispatch) => {
    dispatch(startLoadingDrafts());
    getDraftsService()
      .then((res) => {
        dispatch(endLoadingDrafts());
        dispatch(getDrafts(res));
        dispatch(setFetchedAction(true));
      })
      .catch((err) => {
        dispatch(endLoadingDrafts());
        if (err?.message === "canceled") return;
        // eslint-disable-next-line no-console
        console.log(err?.message);
        error(err?.message);
        dispatch(setFetchedAction(true));
      });
  };
};

export const createDraftAction = (data, onSuccess) => {
  return (dispatch) => {
    dispatch(startLoadingDrafts());
    createDraftService(data)
      .then((res) => {
        localStorage.setItem(
          DATA_KEYS.DRAFT_ACTIONS,
          JSON.stringify({ draftId: res.id })
        );
        dispatch(endLoadingDrafts());
        dispatch(getDrafts(res));
        if (onSuccess) onSuccess();
      })
      .catch((err) => {
        error(err.message);
        dispatch(endLoadingDrafts());
      });
  };
};

export const updateDraftAction = ({ id, data, navigate }) => {
  return (dispatch) => {
    dispatch(startLoadingDrafts());
    updateDraftService(id, data)
      .then((res) => {
        dispatch(endLoadingDrafts());
        dispatch(getDrafts(res));
        if (navigate) navigate("/cart");
      })
      .catch((err) => {
        error(err.message);
        dispatch(endLoadingDrafts());
      });
  };
};
