import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { LargeCheckbox, PriceFormat } from "components";
import { defaultRepeatInterval } from "Pages/CustomersPage/pages/CustomerProfilePage/components/TaskDrawer/TaskDrawer.constants";
import { Controller } from "react-hook-form";
import { object, func } from "prop-types";
import useStyles from "Pages/CustomersPage/pages/CustomerProfilePage/components/TaskDrawer/styles";
import { SimpleArrowDownIcon } from "components/Icons";
import { useMemo } from "react";
import moment from "moment-timezone";

export const IntervalContainer = ({ control, formField, setValue }) => {
  const classes = useStyles();

  const showSetToRepeat = useMemo(() => {
    if (formField?.dueDate) {
      const isValidDate = moment(formField?.dueDate).isValid();

      return isValidDate;
    }

    return false;
  }, [formField?.dueDate]);

  return (
    <Stack direction="row" gap="30px">
      {showSetToRepeat && (
        <Stack direction="row" alignItems="center" gap="9px">
          <Box>
            <Controller
              render={({ field }) => (
                <LargeCheckbox
                  checked={!!field.value}
                  size={16}
                  formSx={{ m: 0 }}
                  sx={{ padding: 0 }}
                  {...field}
                  onChange={(e, newVal) => {
                    setValue("repeated", newVal);

                    if (!newVal) {
                      setValue("repeatInterval", "day");
                      setValue("repeatStep", "1");
                    }
                  }}
                />
              )}
              name="repeated"
              control={control}
            />
          </Box>
          <Typography variant="header" fontSize="13px">
            Set to repeat
          </Typography>
        </Stack>
      )}
      {formField.repeated && (
        <Stack direction="row" alignItems="center" gap="10px">
          <Typography variant="header" fontSize="13px">
            every
          </Typography>

          <Stack direction="row">
            {formField.repeatInterval !== "weekday" && (
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <PriceFormat
                    type="number"
                    isAllowed={(values) => values.floatValue > 0}
                    inputProps={{ min: 1 }}
                    size="small"
                    sx={{
                      "&:hover": {
                        borderColor: "red !important",
                      },
                    }}
                    InputProps={{
                      className: classes.dayInput,
                    }}
                    error={!!error?.message}
                    {...field}
                  />
                )}
                name="repeatStep"
                control={control}
              />
            )}
            <Box>
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <FormControl fullWidth className={classes.actionsButton}>
                    <Select
                      autoWidth
                      IconComponent={(props) => (
                        <SimpleArrowDownIcon
                          //  style={{ width: "8px", height: "10px" }}
                          {...props}
                        />
                      )}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "1px solid #D5D9D9",
                          borderRadius:
                            formField.repeatInterval === "weekday"
                              ? "4px"
                              : "0 4px 4px 0",
                        },
                      }}
                      className={classes.select}
                      error={!!error?.message}
                      {...field}
                    >
                      {defaultRepeatInterval.map((interval) => (
                        <MenuItem value={interval} key={interval}>
                          {interval}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                name="repeatInterval"
                control={control}
              />
            </Box>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

IntervalContainer.propTypes = {
  control: object,
  formField: object,
  setValue: func,
};
