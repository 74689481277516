import { string } from "prop-types";
import { Grid, Typography } from "@mui/material";

export const WorkStatusBlock = ({ workStatus }) => {
  return (
    <Grid
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Typography
        variant="caption"
        color={workStatus === "WORKING" && "primary"}
        sx={{
          display: "inline-block",
          color: workStatus === "WORKING" ? "#47A06D" : "#1C1C19",
        }}
      >
        {workStatus === "WORKING" ? "working" : "not working"}
      </Typography>
    </Grid>
  );
};

WorkStatusBlock.propTypes = { workStatus: string };
WorkStatusBlock.defaultProps = { workStatus: "" };
