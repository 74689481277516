import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  wrapper: {
    width: "545px",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
  },

  header: {
    backgroundColor: "#F8F8F8",
    borderBottom: "0.5px solid #d5d9d9",
    display: "flex",
    alignItems: "center",
    height: ({ HEADER_HEIGHT }) => `${HEADER_HEIGHT}px`,
    position: "relative",
    paddingLeft: "33px",
    gap: "15px",
  },

  closeButton: {
    position: "absolute",
    right: "21px",
    top: "16px",
  },

  bodyWrapper: {
    padding: ({ BODY_WRAPPER_PADDINGS }) => `${BODY_WRAPPER_PADDINGS}px`,
  },

  footer: {
    marginTop: "auto",
    height: ({ FOOTER_HEIGHT }) => `${FOOTER_HEIGHT}px`,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 30px",
    backgroundColor: "#F8F8F8",
    borderTop: "0.5px solid #D5D9D9",
    "& .MuiButton-root": {
      height: "31px",
    },
  },
}));

export default useStyles;
