import { useEffect, useMemo } from "react";

import { uniqBy } from "lodash";
import { useDispatch } from "react-redux";
import { bool, func, array } from "prop-types";
import { useForm, useWatch } from "react-hook-form";

import { bulkUpdateProductsAssignmentAction } from "../../redux/actions/products";

import { CrossIcon, DangerIcon } from "../Icons";
import { NewManufacturerPopup } from "components";
import { useAssignManufacturer } from "./components/useAssignManufacturer";
import { AssignManufacturerBlock } from "./components/AssignManufacturerBlock";

import { cl } from "./styles";

import {
  Stack,
  Button,
  Dialog,
  IconButton,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";

export const ChangeAssignmentDialog = ({ open, onClose, products }) => {
  const dispatch = useDispatch();

  const singleProduct = useMemo(() => {
    if (products?.length === 1) return products[0];
    if (uniqBy(products, (v) => v?.manufacturer?.id)?.length === 1)
      return products[0];
  }, [products]);

  const { control, setValue, reset } = useForm({
    mode: "onChange",
    defaultValues: {
      manufacturerName: singleProduct?.manufacturer?.name || "",
      manufacturerId: singleProduct?.manufacturer?.id || undefined,
    },
  });

  useEffect(() => {
    if (open)
      reset({
        manufacturerName: singleProduct?.manufacturer?.name || "",
        manufacturerId: singleProduct?.manufacturer?.id || undefined,
      });
  }, [open, singleProduct, reset]);

  const formField = useWatch({ control });

  const handleChangeAssignment = () => {
    onClose();
    if (
      !formField?.manufacturerId ||
      formField?.manufacturerId === singleProduct?.manufacturerId
    )
      return;

    const productIds = products.map((prod) => prod?.id);

    dispatch(
      bulkUpdateProductsAssignmentAction(formField.manufacturerId, productIds)
    );
  };

  const {
    isOpenNewMan,
    customersSearchInput,
    setIsOpenNewManDialog,
    setCustomersSearchInput,
    handleFetchManufacturers,
  } = useAssignManufacturer();

  return (
    <>
      <NewManufacturerPopup
        isOpen={isOpenNewMan}
        handleClose={() => setIsOpenNewManDialog(false)}
        setValue={setValue}
      />

      <Dialog open={open} onClose={onClose}>
        <DialogTitle sx={cl.dialogTitle}>
          Assign manufacturer?
          <IconButton
            sx={{ position: "absolute", right: 14, top: 10 }}
            onClick={onClose}
          >
            <CrossIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent sx={cl.contentWrapper}>
          <Stack direction="row" alignItems="center" gap="10px">
            <DangerIcon size={16} />
            <Typography fontSize="12px" color="#363531">
              All current assigned manufacturers will be unassigned.
            </Typography>
          </Stack>
          <Typography fontSize="12px" color="#363531" mt="15px" mb="6px">
            New manufacture:
          </Typography>
          <AssignManufacturerBlock
            {...{
              control,
              setValue,
              formField,
              customersSearchInput,
              setIsOpenNewManDialog,
              setCustomersSearchInput,
              handleFetchManufacturers,
            }}
          />
        </DialogContent>

        <DialogActions sx={cl.actionsWrapper}>
          <Button sx={cl.cancelBtn} onClick={onClose} variant="outlined">
            Cancel
          </Button>

          <Button
            sx={cl.confirmBtn}
            color="primary"
            onClick={handleChangeAssignment}
            variant="contained"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

ChangeAssignmentDialog.propTypes = {
  open: bool,
  onClose: func,
  products: array,
};
ChangeAssignmentDialog.defaultProps = {
  open: false,
  onClose: () => {},
  products: [],
};
