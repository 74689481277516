import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  grid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  text: {
    fontSize: "12px",
    color: "#1C1C19",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  productPhoto: {
    width: "35px",
    backgroundColor: "white",
    borderRadius: "4px",
  },

  orderedQty: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "4px",
    padding: "7px 9px",
    minWidth: "39px",
    backgroundColor: "#F7F7F7",
    height: "26px",
  },
}));

export default useStyles;
