import { string, any } from "prop-types";
import { Grid, Typography } from "@mui/material";

export const WrapperBlock = ({ title, children, element }) => {
  return (
    <Grid border="0.5px solid #D5D9D9" borderRadius="4px" px={3} xs={12} item>
      <Grid
        minHeight="46px"
        display="flex"
        alignItems="center"
        xs={12}
        item
        justifyContent="space-between"
        gap="10px"
        width="100%"
      >
        <Typography
          fontSize={22}
          color="ground.main"
          sx={{ whiteSpace: "nowrap" }}
        >
          {title}
        </Typography>
        <Grid overflow="hidden" mt="7px">
          {element}
        </Grid>
      </Grid>
      <Grid xs={12} item>
        {children}
      </Grid>
    </Grid>
  );
};

WrapperBlock.propTypes = { title: string, children: any, element: any };
WrapperBlock.defaultProps = { title: "" };
