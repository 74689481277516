import { Box } from "@mui/system";
import { any, string, bool, object } from "prop-types";

const CustomCurtainComponent = ({
  children,
  toggled,
  curtainHeight,
  curtainPropsStyles = {},
  ...props
}) => {
  return (
    <Box
      sx={{
        maxHeight: toggled ? curtainHeight : "0px",
        overflow: "hidden",
        transition: "all 0.5s ease",
        opacity: toggled ? 1 : 0,
        pointerEvents: !toggled && "none",
        pb: 1,
        ...curtainPropsStyles,
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

CustomCurtainComponent.propTypes = {
  children: any,
  toggled: bool,
  curtainHeight: string,
  curtainPropsStyles: object,
};

export default CustomCurtainComponent;
