import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  widgetWrapper: {
    border: "1px solid #D5D9D9",
    height: "296px",
  },

  titleWrapper: {
    padding: "13px 0 20px 28px",
    color: "#1c1c19",
    display: "flex",
    gap: "19px",
  },

  insightsMatrix: {
    display: "flex",
    flexWrap: "wrap",
    padding: "0 5% 0 5%",
  },

  insightItem: {
    textDecoration: "none",
    flex: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "96px",
    flexDirection: "column",
    gap: "5px",
    maxWidth: "50%",
    cursor: "pointer",
    transition: "all 0.3s ease-out",
    "&:hover": {
      "& .MuiTypography-root": {
        "&:first-of-type": {
          textDecoration: "underline",
        },
      },
    },
    "&:first-of-type": {
      borderRight: "0.5px solid #D5D9D9",
      borderBottom: "0.5px solid #D5D9D9",
    },

    "&:nth-of-type(2)": {
      borderBottom: "0.5px solid #D5D9D9",
    },

    "&:nth-of-type(3)": {
      borderRight: "0.5px solid #D5D9D9",
    },

    "& .MuiTypography-root": {
      "&:first-of-type": {
        fontSize: "clamp(17px, 1.3vw, 30px)",
        color: "#000",
        lineHeight: "41px",
        "&.not-available": {
          fontSize: "12px",
          color: "#C6C6C6",
        },
      },

      "&:last-of-type": {
        fontSize: "clamp(12px, 1vw, 15px)",
        fontWeight: "300",
        color: "#9C9C94",
      },
    },
  },

  insightItemLink: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "5px",
    textDecoration: "none",
  },
}));

export default useStyles;
