import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { bool, func, object, string } from "prop-types";
import { CrossIcon } from "../../../../../../../components/Icons";
import StyledButton from "../../../../../../../components/StyledButton";
import { Controller } from "react-hook-form";

const ExportDialog = ({
  open,
  control,
  onClose,
  type,
  format,
  form,
  disabledActionBtn = false,
}) => {
  return (
    <Dialog {...{ open, onClose }}>
      <DialogTitle
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography fontSize="20px" color="#707070" fontWeight="400">
          Export {type}
        </Typography>
        <IconButton onClick={onClose}>
          <CrossIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ minWidth: "474px", pb: "20px" }}>
        <Typography fontSize="12px">
          Export all {type} in {format?.toUpperCase()} format
        </Typography>
        <Controller
          render={({ field }) => (
            <FormControl sx={{ m: "4px 0 0 -5px" }} component="fieldset">
              <RadioGroup
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                }}
                row
                defaultValue="Active"
                {...field}
              >
                <FormControlLabel
                  value="csv"
                  sx={{ marginRight: 0 }}
                  label={
                    <Typography
                      sx={{ fontSize: 14, color: "#1C1C19" }}
                      variant="caption"
                    >
                      CSV
                    </Typography>
                  }
                  control={
                    <Radio
                      sx={{
                        marginLeft: "6px",
                        marginRight: "-4px",
                        "& svg": {
                          width: "18px",
                          height: "18px",
                        },
                      }}
                    />
                  }
                />
                <FormControlLabel
                  sx={{
                    m: 0,
                  }}
                  value="xlsx"
                  label={
                    <Typography
                      sx={{ fontSize: 14, color: "#1C1C19" }}
                      variant="caption"
                    >
                      XLSX
                    </Typography>
                  }
                  control={
                    <Radio
                      sx={{
                        marginLeft: "6px",
                        marginRight: "-4px",
                        "& svg": {
                          width: "18px",
                          height: "18px",
                        },
                      }}
                    />
                  }
                />
              </RadioGroup>
            </FormControl>
          )}
          name="format"
          control={control}
        />
      </DialogContent>
      <Divider />
      <DialogActions sx={{ p: "10px 17px 12px", gap: "20px" }}>
        <StyledButton
          label="Cancel"
          onClick={onClose}
          color="cancel"
          fontSize="15px"
        />
        <StyledButton
          disabled={disabledActionBtn}
          variant="contained"
          label="Export"
          type="submit"
          form={form}
          fontSize="15px"
        />
      </DialogActions>
    </Dialog>
  );
};

ExportDialog.propTypes = {
  open: bool,
  disabledActionBtn: bool,
  control: object,
  onClose: func,
  type: string,
  format: string,
  form: string,
};

export default ExportDialog;
