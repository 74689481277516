import { useEffect, useMemo, useState } from "react";
import { DELIVERY_METHODS } from "../helpers";

export const useFooter = ({
  open,
  deliveryMethod,
  delivererId,
  productReturns,
  trackingId,
  shippingCarrier,
  returnItem,
  formField,
  errors,
}) => {
  const [isDisableBtn, setIsDisableBtn] = useState(true);

  useEffect(() => {
    if (open) {
      if (errors?.otherReason) return setIsDisableBtn(true);

      if (deliveryMethod === DELIVERY_METHODS[1]) {
        if (
          !!delivererId &&
          productReturns?.some((prod) => prod.returnQuantity > 0)
        ) {
          setIsDisableBtn(false);
        } else {
          setIsDisableBtn(true);
        }
      }

      if (deliveryMethod === DELIVERY_METHODS[0]) {
        if (
          !!trackingId &&
          !!shippingCarrier &&
          productReturns?.some((prod) => prod.returnQuantity > 0)
        ) {
          setIsDisableBtn(false);
        } else {
          setIsDisableBtn(true);
        }
      }
    }
  }, [
    delivererId,
    open,
    productReturns,
    deliveryMethod,
    shippingCarrier,
    trackingId,
    formField,
    errors?.otherReason,
  ]);

  const nameConfirmBtn = useMemo(() => {
    return returnItem?.id ? "Confirm" : "Create Return";
  }, [returnItem?.id]);

  return {
    isDisableBtn,
    nameConfirmBtn,
  };
};
