import { bool, object, string, array, oneOfType } from "prop-types";
import { StyledPopper } from "../../../../components";

export const ReturnDialogPopper = ({
  open,
  anchorEl,
  text,
  modifiers,
  style,
  ...props
}) => {
  return (
    <StyledPopper
      {...{ open, anchorEl, text, modifiers }}
      style={{ zIndex: 1401, ...style }}
      placement="top"
      transition
      aria-hidden="true"
      {...props}
    />
  );
};

ReturnDialogPopper.propTypes = {
  open: bool,
  anchorEl: object,
  style: object,
  text: oneOfType([string, object]),
  modifiers: array,
};
ReturnDialogPopper.defaultProps = {
  open: false,
  anchorEl: {},
  style: {},
  text: "",
  modifiers: [],
};
