/* eslint-disable react/prop-types */
import * as React from "react";
export const PhotoCommentIcon = ({ size = 8.526, fill = "#ccc", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 8.526 8.526"
    {...props}
  >
    <path
      data-name="Icon open-comment-square"
      d="M.1.011a.089.089 0 0 0-.1.1v6.188a.1.1 0 0 0 .1.1h6.3l2.126 2.127V.096a.089.089 0 0 0-.1-.1H.107Z"
      fill={fill}
    />
  </svg>
);
