import { object, bool, func } from "prop-types";
import { Box, keyframes } from "@mui/system";
import { CircularProgress, IconButton, Typography } from "@mui/material";
import { photoUrl, renderTextLink } from "../../../helpers/helpers";
import StyledAvatar from "../../../components/StyledAvatar/StyledAvatar";
import moment from "moment";
import { useDate } from "../../../helpers/hooks";
import { CheckmarkIcon, DownloadIcon } from "../../../components/Icons";
import { bytesToSize } from "../../../firebase/Chat/helpers";
import { forwardRef } from "react";
import ChatImage from "./components/ChatImage";

const ChatMessage = forwardRef(
  (
    {
      message,
      currentUser,
      partner,
      handleOpenMenu,
      handleAttachmentClick,
      dimAnimation,
    },
    ref
  ) => {
    const getBgColor = () => {
      if (message.attachments?.length) {
        if (currentUser) return "#F0F0F0";
        return "#E3F2EC";
      }
    };

    const dimColor = keyframes`
  from {
    background-color: rgba(66, 165, 127, 0.25);
  }
  to {
    background-color: #ffffff;
  }
`;

    return (
      <Box
        display="flex"
        justifyContent={currentUser ? "flex-end" : "flex-start"}
        p="12px 25px 16px 20px"
        onContextMenu={(e) => {
          if (currentUser) handleOpenMenu(e, message);
        }}
        sx={{ animation: dimAnimation && `${dimColor} 3000ms ease` }}
        ref={ref}
      >
        <Box display="flex" position="relative">
          {!currentUser && (
            <StyledAvatar
              sx={{
                height: "36px",
                width: "36px",
                mr: "9px",
              }}
              src={photoUrl(partner?.profilePhoto?.fileName)}
              stringStyle={{ fontSize: "12px" }}
              name={partner?.name}
            />
          )}
          <Box backgroundColor={getBgColor} borderRadius="4px">
            {message.attachments?.[0]?.type === "image" && (
              <ChatImage
                isCurrentUser={currentUser}
                attachment={message.attachments[0]}
                onClick={() => handleAttachmentClick(message.attachments[0])}
              />
            )}

            {message.attachments?.[0] &&
              message.attachments[0].type !== "image" && (
                <Box borderBottom="0.5px solid #d5d9d9" padding="3px 5px">
                  <Box display="flex" alignItems="center">
                    <Typography
                      color={currentUser ? "#707070" : "#3F3F3F"}
                      backgroundColor={currentUser ? "#F0F0F0" : "#E3F2EC"}
                      fontSize="12px"
                    >
                      {message.attachments[0].name}
                    </Typography>
                    <IconButton
                      onClick={() =>
                        handleAttachmentClick(message.attachments[0])
                      }
                    >
                      <DownloadIcon />
                    </IconButton>
                  </Box>
                  <Typography fontSize="10px">
                    {bytesToSize(message.attachments[0].size)}
                  </Typography>
                </Box>
              )}
            <Typography
              color={currentUser ? "#707070" : "#3F3F3F"}
              backgroundColor={currentUser ? "#F0F0F0" : "#E3F2EC"}
              padding="6px 9px"
              borderRadius="4px"
              fontSize="13px"
              maxWidth="436px"
              sx={{ wordWrap: "break-word" }}
            >
              {renderTextLink(message?.text)}
            </Typography>
            <Box
              display="inline-flex"
              justifyContent="space-between"
              position="absolute"
              right={currentUser && 0}
              alignItems="center"
              gap="4px"
            >
              <Typography color="#B5B5AC" fontSize="13px" whiteSpace="nowrap">
                sent{" "}
                {useDate(
                  moment(message?.createdAt?.seconds * 1000).fromNow()
                ) || "Sending..."}
              </Typography>
              {currentUser && (
                <Box height="20px" display="inline-flex" alignItems="center">
                  <CheckmarkIcon
                    width="10.1"
                    height="7.35"
                    stroke={message.read ? "#47A06D" : "#B5B5AC"}
                  />
                  {message.createdAt && message.read && (
                    <CheckmarkIcon
                      width="10.1"
                      height="7.35"
                      stroke={message.read ? "#47A06D" : "#B5B5AC"}
                      style={{ marginLeft: "-5px" }}
                    />
                  )}
                  {!message.createdAt && (
                    <CircularProgress
                      size={12}
                      sx={{ ml: "4px", position: "absolute", right: -16 }}
                    />
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
);

ChatMessage.displayName = "ChatMessage";

ChatMessage.propTypes = {
  message: object,
  currentUser: bool,
  partner: object,
  handleOpenMenu: func,
  handleAttachmentClick: func,
  handleDownload: func,
  dimAnimation: bool,
};

ChatMessage.defaultProps = {
  currentUser: false,
  partner: null,
};

export default ChatMessage;
