import * as Yup from "yup";

export const validationSchema = () =>
  Yup.object().shape({
    // name: Yup.string().trim().required("This field is required"),
    // customId: Yup.string()
    //   .trim()
    //   .required("This field is required")
    //   .test("customIdValidation", "Max 10 characters", (value) => {
    //     return value.length <= 10;
    //   }),
  });
