import { Checkbox, FormControlLabel } from "@mui/material";
import {
  CheckboxIcon,
  CheckboxIconChecked,
  CheckedIcon,
  UncheckedIcon,
} from "../Icons";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import PropTypes, { string, number, object, bool, any } from "prop-types";
import { forwardRef } from "react";
import { useAdmin } from "helpers/helpers";

const LargeCheckbox = forwardRef(
  (
    {
      stroke,
      size,
      label,
      formSx,
      fill,
      labelPlacement,
      disabled,
      checkedColor,
      labelSx,
      indeterminateIcon,
      checkedViewBox,
      rectSize,
      adminIsAllowed,
      ...props
    },
    ref
  ) => {
    const isAdmin = useAdmin(adminIsAllowed);
    return (
      <FormControlLabel
        sx={formSx}
        ref={ref}
        labelPlacement={labelPlacement}
        disabled={isAdmin || disabled}
        control={
          <Checkbox
            icon={
              <CheckboxIcon
                stroke={`rgba(112,112,112, ${disabled ? 0.25 : 1})`}
                size={size}
                fill={fill}
              />
            }
            checkedIcon={
              <CheckboxIconChecked
                size={size}
                rectSize={rectSize}
                checkedViewBox={checkedViewBox}
                color={checkedColor}
              />
            }
            indeterminateIcon={
              <IndeterminateCheckBoxIcon sx={{ fontSize: indeterminateIcon }} />
            }
            {...props}
          />
        }
        componentsProps={{
          typography: {
            sx: labelSx,
          },
        }}
        label={label}
      />
    );
  }
);

LargeCheckbox.displayName = "LargeCheckbox";

LargeCheckbox.propTypes = {
  stroke: string,
  size: number,
  formSx: object,
  label: PropTypes.oneOfType([string, object]),
  labelPlacement: string,
  disabled: bool,
  fill: string,
  checkedColor: string,
  labelSx: object,
  indeterminateIcon: number,
  checkedViewBox: string,
  rectSize: number,
  adminIsAllowed: bool,
};

LargeCheckbox.defaultProps = {
  stroke: "rgb(112,112,112)",
  label: "",
  labelPlacement: "end",
  disabled: false,
  labelSx: {},
  adminIsAllowed: false,
};

const SmallCheckbox = forwardRef(
  ({ formSx, label, adminIsAllowed, ...props }, ref) => {
    const isAdmin = useAdmin(adminIsAllowed);
    return (
      <FormControlLabel
        disabled={isAdmin}
        sx={formSx}
        label={label}
        control={
          <Checkbox
            ref={ref}
            icon={<UncheckedIcon />}
            checkedIcon={<CheckedIcon />}
            {...props}
          />
        }
      />
    );
  }
);

SmallCheckbox.propTypes = {
  formSx: object,
  label: any,
  adminIsAllowed: bool,
};

SmallCheckbox.defaultProps = {
  label: "",
  adminIsAllowed: false,
};

SmallCheckbox.displayName = "SmallCheckbox";

export { LargeCheckbox, SmallCheckbox };
