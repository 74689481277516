import { useRepsPermissions } from "helpers/hooks";
import { useOrdersActions } from "Pages/OrdersPage/Orders.hook";
import { useCallback, useEffect, useMemo } from "react";
import { success } from "utils/notifications";

export const CustomerProfileActions = ({
  orderList,
  currentUser,
  checkedOrders,
  customerState,
  setCustomerState,
  clearCheckedList,
  handlePrintOrders,
  handleChoseMenuItem,
  handlePrintPickList,
  actionsSuccessCallback,
  handlePrintPackingSlips,
  setConfirmDeliveryState,
  handleCloseConfirmDeliveryDialog,
  handleClickOnAssignTags,
}) => {
  const repPermissions = useRepsPermissions();

  const isQuickBooksConnected = !!currentUser?.quickBooksTokens;

  const successFulfillOrders = useCallback(() => {
    clearCheckedList();
    handleCloseConfirmDeliveryDialog();
  }, [clearCheckedList, handleCloseConfirmDeliveryDialog]);

  const handleFulfillOrders = useCallback(() => {
    if (repPermissions && !repPermissions?.orders?.create_edit) return;
    if (!orderList?.length || !checkedOrders?.length) return;

    const newCheckedOrders = orderList.filter(
      ({ type, id }) => checkedOrders.includes(id) && type === "DIRECT"
    );

    const filteredCheckedOrders = newCheckedOrders.filter((o) => {
      return !(
        o?.deliveryStatus === "FULFILLED" ||
        ["CANCELED", "COMPLETED"].includes(o?.orderStatus)
      );
    });

    if (newCheckedOrders?.length && filteredCheckedOrders?.length === 0) {
      // return error(
      //   "No orders available for fulfillment due to their statuses being either canceled, fulfilled, or completed."
      // );
      return success("Orders marked as fulfilled");
    }

    if (filteredCheckedOrders?.length === 1) {
      const order = filteredCheckedOrders?.[0];

      if (order) {
        handleChoseMenuItem(order, "Confirm delivery", successFulfillOrders);
      }

      return;
    }

    setConfirmDeliveryState({
      isOpenDialog: true,
      orders: filteredCheckedOrders,
    });
  }, [
    checkedOrders,
    handleChoseMenuItem,
    orderList,
    repPermissions,
    setConfirmDeliveryState,
    successFulfillOrders,
  ]);

  const getOrderActions = () => {
    if (!checkedOrders?.length || checkedOrders?.length > 1) return {};
    return orderList.filter(({ id }) => id === checkedOrders[0])[0];
  };

  const currentOrder = getOrderActions();

  const {
    loading: copyLinkLoading,
    handleGetOrderActions,
    handleMultipleOrderActions,
    getAdditionalAction,
  } = useOrdersActions({
    handleClickOnAssignTags,
    isQuickBooksConnected,
    order: currentOrder,
    handleChoseMenuItem: (order, type) => {
      handleChoseMenuItem(
        order,
        type,
        type !== "Confirm delivery" && actionsSuccessCallback
      );
    },
  });

  useEffect(() => {
    if (copyLinkLoading && !customerState.loading)
      return setCustomerState((prev) => ({ ...prev, loading: true }));
    return setCustomerState((prev) => ({ ...prev, loading: false }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [copyLinkLoading]);

  const dropDownActions = useMemo(
    () =>
      checkedOrders?.length > 1
        ? handleMultipleOrderActions(
            handlePrintOrders,
            handlePrintPickList,
            handlePrintPackingSlips,
            checkedOrders,
            orderList
          )
        : handleGetOrderActions().slice(1),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkedOrders, orderList]
  );

  const additionalActions = useMemo(
    () =>
      getAdditionalAction(checkedOrders, orderList, [], handleFulfillOrders),
    [checkedOrders, getAdditionalAction, orderList, handleFulfillOrders]
  );

  return { dropDownActions, additionalActions };
};
