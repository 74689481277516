/* eslint-disable react/prop-types */
import React from "react";

export const ChangeCategoryIcon = ({
  width = 10.293,
  height = 9.778,
  color = "#3f3f3f",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 10.293 9.778"
    >
      <path
        id="icons8-change"
        d="M5.088,3A1.029,1.029,0,0,0,4.059,4.029v3.6A1.029,1.029,0,0,0,5.088,8.661H6.632v1.544a1.037,1.037,0,0,0,1.029,1.029h3.6a1.037,1.037,0,0,0,1.029-1.029V6.6a1.037,1.037,0,0,0-1.029-1.029H9.72V4.029A1.029,1.029,0,0,0,8.69,3ZM9.72,6.6h1.544v3.6h-3.6V8.661H8.69A1.029,1.029,0,0,0,9.72,7.632ZM2,8.661V10.72a1.037,1.037,0,0,0,1.029,1.029H4.573v1.029l1.544-1.544L4.573,9.69V10.72H3.029V8.661Z"
        transform="translate(-2 -3)"
        fill={color}
      />
    </svg>
  );
};
