import axios from "axios";
import { error } from "utils/notifications";

const getCurrentUser = async () => {
  const { data } = await axios.get("/users/distributors/me");

  return data;
};

const getUserRecipients = async (params) => {
  const { data } = await axios.get("/users/distributors/recipients", {
    params: { ...params },
  });
  return data;
};

const checkEmailService = async (email) => {
  const { data } = await axios.post(`/users/check-email/${email}`);

  return data;
};

export const updateDistributorService = async (body) => {
  const { data } = await axios.put("/users/distributors", body);
  return data;
};

export const updateDistributorSettingsService = async (body) => {
  const { data } = await axios.put("/users/distributors/settings", body);
  return data;
};

export const registerUserService = async (userData) => {
  return await axios
    .post("/users/distributors", userData)
    .then((res) => res.data);
};

const loginUser = async (userData) => {
  const { data } = await axios.post("/auth/distributor", userData);

  return data;
};

const loginSuperAdminAsDistributorService = async (userData) => {
  const { data } = await axios.post(
    "/auth/super-admin-as-distributor",
    userData
  );

  return data;
};

const confirmUserAccount = async (token, password) => {
  const { data } = await axios.post(
    `/users/confirm-account?confirmation_token=${token}`,
    password
  );
  return data;
};

const resetPasswordEmail = async (email) => {
  const { data } = await axios.post(
    `/users/distributors/reset-password`,
    email
  );
  return data;
};

const changePassword = async (userData) => {
  const { data } = await axios.post(`/users/change-password`, userData);
  return data;
};

const restorePassword = async ({ data, role }) => {
  let url = "";
  if (role === "distributor") url = "/users/distributors/restore-password";
  if (role === "representative")
    url = "/users/representatives/restore-password-link";

  if (!role) return error("Something went wrong!");

  const res = await axios.post(url, data);
  return res?.data;
};

const orderDirectInviteService = async (userData) => {
  const { data } = await axios.post("/users/distributors/invite", userData);
  return data;
};

export const orderDirectCheckEmail = async (params) => {
  const { data } = await axios.get(
    "/customers/check-invite-email-availability",
    {
      params: { ...params },
    }
  );
  return data;
};

export const revokeOrderDirectService = async (id) => {
  const { data } = await axios.put(`/users/distributors/invite/${id}`);
  return data;
};

export const cancelOrderDirectService = async (id) => {
  return axios
    .delete(`/users/distributors/invite/${id}`)
    .then((res) => res.data);
};

export const updateDistributorOnboardingService = (data) => {
  return axios({
    method: "PUT",
    url: "/users/distributors/onboarding",
    data,
  });
};

export const completeRegistrationService = async (userData, token) => {
  const { data } = await axios({
    method: "POST",
    url: "/users/confirm-account",
    data: {
      ...userData,
    },
    params: {
      confirmation_token: token,
    },
  });
  return data;
};

export const confirmationInfoService = async (token) => {
  const { data } = await axios({
    method: "GET",
    url: "/users/distributors/confirmation-info",
    params: { confirmation_token: token },
  });
  return data;
};

export const applyForConfirmationService = async (token, userData = {}) => {
  let headers = {};
  if (token)
    headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

  const { data } = await axios({
    method: "POST",
    url: "/distributor-subscriptions/usage/activate-draft",
    data: { type: "confirmation", ...userData },
    ...headers,
  });
  return data;
};

const getNotConfirmedAccountLimitedAccessService = async (params) => {
  const { data } = await axios.get(
    "/auth/not-confirmed-account-limited-access",
    params
  );
  return data;
};

export {
  getCurrentUser,
  loginUser,
  changePassword,
  confirmUserAccount,
  resetPasswordEmail,
  restorePassword,
  checkEmailService,
  getUserRecipients,
  orderDirectInviteService,
  loginSuperAdminAsDistributorService,
  getNotConfirmedAccountLimitedAccessService,
};

export default {
  getCurrentUser,
  loginUser,
  changePassword,
  confirmUserAccount,
  updateDistributorOnboardingService,
  loginSuperAdminAsDistributorService,
  getNotConfirmedAccountLimitedAccessService,
};
