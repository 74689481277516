import { useCallback, useEffect, useMemo, useState } from "react";
import { array, object, func, bool } from "prop-types";
import {
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
  Box,
  InputAdornment,
  Typography,
  Menu,
} from "@mui/material";
import moment from "moment";
import {
  handleCheckFiltersForApply,
  normalizeSnakeCaseString,
} from "../../helpers/helpers";
import {
  SelectDatePicker,
  StyledSelect,
  StyledMultipleSelect,
} from "../Selects";
import { StyledTextField } from "../TextFields/TextFields";
import { CategoriesComponent } from "./components";
import useStyles from "./styles";
import ArrowBtn from "components/Buttons/ArrowBtn/ArrowBtn";
import { LargeCheckbox, SearchTextField } from "components";
import { MenuContent } from "./components/MenuContent/MenuContent";
import { useSelector } from "react-redux";
import { debounce, isEqual } from "lodash";

export const FilterMenu = ({
  switches,
  filterFields,
  handleApplyFilter,
  selectMatchData,
  iconDict,
  price,
  onViewSave,
  showOnlyList = false,
  isCustomFulfillmentStatus,
  contentProps = {},
  ...props
}) => {
  const customFilterViews = useSelector(({ savedFilters }) => savedFilters);

  const selectedFilter = useMemo(
    () =>
      customFilterViews.selectedFilterId
        ? Object.values(customFilterViews.savedFilters)
            .flat()
            .filter(
              (filter) => filter?.id === customFilterViews.selectedFilterId
            )
        : [],
    [customFilterViews.savedFilters, customFilterViews.selectedFilterId]
  );

  // const isQuickBooksConnected = !!currentUser?.quickBooksTokens;
  const classes = useStyles();
  const [switchesState, setSwitchesState] = useState([...switches]);
  const [temporaryFields, setTemporaryFields] = useState({ ...filterFields });

  const isSelectedFilterDifferent = selectedFilter.length
    ? !isEqual(selectedFilter?.[0]?.filters, temporaryFields)
    : true;

  useEffect(() => {
    if (props.open) {
      setSwitchesState([...switches]);
      setTemporaryFields({ ...filterFields });
      return;
    }
  }, [props.open, switches, filterFields]);

  const isFilterChanged = useMemo(
    () => !isEqual(temporaryFields, filterFields),
    [filterFields, temporaryFields]
  );

  const isSwitchesChanged = useMemo(
    () => !isEqual(switchesState, switches),
    [switches, switchesState]
  );

  const hasViewSave = useMemo(
    () =>
      !!onViewSave &&
      isSelectedFilterDifferent &&
      handleCheckFiltersForApply(temporaryFields),
    [onViewSave, isSelectedFilterDifferent, temporaryFields]
  );

  const isEmptyFilter = useMemo(
    () => !handleCheckFiltersForApply(temporaryFields),
    [temporaryFields]
  );

  const disabledApplyBtn = useMemo(
    () =>
      (!hasViewSave && !isSwitchesChanged && !isFilterChanged) || isEmptyFilter,
    [hasViewSave, isSwitchesChanged, isFilterChanged, isEmptyFilter]
  );

  const handleChangeTextField = ({ item, option, value }) => {
    setTemporaryFields((prev) => {
      return {
        ...prev,
        [item.value]: {
          ...prev[item.value],
          [option.id]: {
            value: value,
            prefix: option.prefix,
          },
        },
      };
    });
  };

  const handleTagsChange = useCallback(
    (e, el, item) => {
      const { value } = e.target;
      const fieldName = item?.value;
      const val = value[value.length - 1];

      setTemporaryFields((prev) => {
        const tagsData = [];
        const exist = !!temporaryFields[fieldName]?.some(
          ({ value }) => value === val
        );

        if (exist) {
          const filtredTags = temporaryFields[fieldName]?.filter(
            ({ value }) => value !== val
          );
          tagsData.push(...filtredTags);
        } else {
          const oldData = prev[fieldName] ? prev[fieldName] : [];

          tagsData.push(...oldData, {
            label: item.label,
            name: el.props.name || el.props[fieldName],
            value: val,
            iconId: el.props.iconid,
          });
        }
        return {
          ...prev,
          [item.value]: tagsData,
        };
      });
    },
    [temporaryFields]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce(
      (term, setParams) =>
        setParams((prev) => {
          return {
            ...prev,
            search: term,
          };
        }),
      500
    ),
    []
  );

  const rowItem = useCallback(
    (item) => {
      switch (item.type) {
        case "switch": {
          if (item?.value === "archived") return null;
          if (item?.value === "repeated") return null;
          if (item?.value === "reorder_required") return null;
          const {
            checkboxes,
            valuesForCheckboxes,
            valuesForSelect,
            selectProps,
          } = selectMatchData.find((data) => data.id === item.value) || {};

          const currentValue = temporaryFields[item.value];
          const showCheckboxes =
            !!currentValue?.length &&
            valuesForCheckboxes?.some((checkboxValue) =>
              currentValue.some(
                (selectedValue) => selectedValue === checkboxValue
              )
            );

          const tempField = selectProps?.value || "fulfill_by";

          const showSelect =
            !!currentValue?.length &&
            valuesForSelect?.some((selectValue) =>
              currentValue.some(
                (selectedValue) => selectedValue === selectValue
              )
            );

          const handleClearSubitemsAfterUnselectMainItem = ({
            newVal,
            field,
          }) => {
            if (
              Array.isArray(valuesForCheckboxes) &&
              valuesForCheckboxes[0] &&
              Array.isArray(newVal) &&
              !newVal.includes(valuesForCheckboxes[0]) &&
              field &&
              !!temporaryFields?.[field]
            ) {
              setTemporaryFields((prev) => ({ ...prev, [field]: "" }));
            }
          };

          return (
            <Box>
              <ToggleButtonGroup
                exclusive={!item.multiple}
                value={temporaryFields[item.value]}
                onChange={(e, val) => {
                  let newVal = "";

                  if (item.multiple) {
                    newVal = val ? val : [];
                  } else {
                    newVal = val || "";
                  }

                  const field = checkboxes?.[0]?.field || "";

                  handleClearSubitemsAfterUnselectMainItem({ newVal, field });

                  //if (
                  //  newVal.includes &&
                  //  newVal.includes("UNFULFILLED") &&
                  //  !temporaryFields?.[tempField]
                  //) {
                  //  setTemporaryFields((prev) => ({
                  //    ...prev,
                  //    [selectProps?.value]: {
                  //      label: "Fulfillment status",
                  //      name: selectProps?.children?.[0]?.name,
                  //      value: selectProps?.children?.[0],
                  //    },
                  //  }));
                  //}
                  setTemporaryFields((prev) => {
                    return { ...prev, [item.value]: newVal };
                  });
                }}
              >
                {item.values.map((value) => (
                  <ToggleButton
                    selected={
                      value === temporaryFields[item.value] ||
                      (value?.value &&
                        value?.value === temporaryFields[item.value]?.value)
                    }
                    value={value}
                    key={value?.value || value}
                    className={classes.toggleButton}
                  >
                    {value?.name || normalizeSnakeCaseString(value)}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
              {showCheckboxes && (
                <Box display="flex" flexDirection="column" mt="3px">
                  {checkboxes?.map((item) => (
                    <LargeCheckbox
                      key={item.value}
                      checked={item.value === temporaryFields[item.field]}
                      onChange={() =>
                        setTemporaryFields((prev) => ({
                          ...prev,
                          [item.field]:
                            prev[item.field] === item.value ? "" : item.value,
                        }))
                      }
                      formSx={{ mr: 0, ml: "-9px" }}
                      sx={{ py: "7px" }}
                      label={item.label}
                      size={13}
                      labelSx={{ fontSize: "10px", color: "#B1ACAC" }}
                    />
                  ))}
                </Box>
              )}
              {showSelect && (
                <Box mt="11px">
                  {!isCustomFulfillmentStatus &&
                  temporaryFields?.[tempField]?.name !== "Custom" ? (
                    <StyledSelect
                      adminIsAllowed
                      label={selectProps.label}
                      notched={true}
                      displayEmpty
                      labelProps={{ shrink: true }}
                      fullWidth
                      value={temporaryFields[selectProps.value]?.value || ""}
                      onChange={(e, el) => {
                        const { value } = e.target;
                        setTemporaryFields((prev) => {
                          return {
                            ...prev,
                            [selectProps.value]: value
                              ? {
                                  label: selectProps.label,
                                  name: el.props.name,
                                  value: e.target.value,
                                }
                              : "",
                          };
                        });
                      }}
                    >
                      {selectProps.children?.map((child) => (
                        <MenuItem
                          key={`${child?.id}-${child?.name}`}
                          value={child?.id}
                          name={child?.name}
                          {...child?.props}
                        >
                          <ListItemText
                            primaryTypographyProps={{ sx: { fontSize: 12 } }}
                          >
                            {child?.name}
                          </ListItemText>
                        </MenuItem>
                      ))}
                    </StyledSelect>
                  ) : (
                    <SelectDatePicker
                      adminIsAllowed
                      {...selectProps}
                      isCustomFulfillmentStatus
                      isCustomDate
                      value={
                        temporaryFields?.[tempField]?.value?.start_date
                          ? [
                              moment(
                                temporaryFields?.[tempField]?.value?.start_date
                              ),
                              temporaryFields?.[tempField]?.value?.end_date
                                ? moment(
                                    temporaryFields?.[tempField]?.value
                                      ?.end_date
                                  )
                                : null,
                            ]
                          : temporaryFields?.[tempField]?.value?.name ?? ""
                      }
                      noSquare
                      height="32px"
                      fontSizeLabel="12px"
                      handleDateInput={(newDates) => {
                        const start_date = newDates?.[0]
                          ?.format()
                          ?.split("+")?.[0];
                        const end_date = newDates?.[1]
                          ?.format()
                          ?.split("+")?.[0];

                        setTemporaryFields((prev) => {
                          return {
                            ...prev,
                            [tempField]: {
                              label: item.label,
                              name: newDates[1]
                                ? ` ${newDates[0]?.format(
                                    "MM/DD/YYYY"
                                  )} - ${newDates[1]?.format("MM/DD/YYYY")}`
                                : newDates[0]?.format("MM/DD/YYYY"),
                              value: {
                                date: null,
                                start_date,
                                end_date,
                              },
                            },
                          };
                        });
                      }}
                      fullWidth
                      label={
                        temporaryFields[item.value]?.value
                          ? ""
                          : item.selectLabel || selectProps?.label
                      }
                      sx={{
                        "& > fieldset": {
                          borderRadius: "4px",
                        },
                      }}
                      handleClearValue={() => {
                        setTemporaryFields((prev) => {
                          return {
                            ...prev,
                            [tempField]: "",
                          };
                        });
                      }}
                      onChange={(e, el) => {
                        setTemporaryFields((prev) => {
                          let preparedData = {
                            ...prev,
                            [tempField]: {
                              label: item.label,
                              name: el.props.children,
                              value: selectProps.children.find(
                                (child) => child.name === e.target.value
                              ),
                            },
                          };
                          if (
                            ["more_than", "less_than"].includes(e.target.value)
                          )
                            preparedData[item.value].days = 1;
                          return preparedData;
                        });
                      }}
                      textfieldInputSx={{
                        borderRadius: "4px",
                      }}
                    />
                  )}
                </Box>
              )}
            </Box>
          );
        }
        case "select": {
          const {
            childrenList,
            textFieldProps,
            handleFetch,
            searcheble,
            setParams,
            ...selectProps
          } = selectMatchData?.find((data) => data?.id === item?.value) ?? {};
          return (
            <StyledSelect
              adminIsAllowed
              notched={false}
              labelProps={{ shrink: false }}
              {...selectProps}
              label={temporaryFields[item.value]?.value ? "" : item.selectLabel}
              value={temporaryFields[item.value]?.value || ""}
              formSx={{ height: "32px" }}
              height="32px"
              handleFetch={handleFetch}
              onChange={(e, el) => {
                setTemporaryFields((prev) => {
                  return {
                    ...prev,
                    [item.value]: {
                      label: item.label,
                      name: el.props.name || el.props.tag,
                      value: e.target.value,
                      iconId: el.props.iconid,
                    },
                  };
                });
              }}
              onClose={() => {
                setParams ? debouncedSearch("", setParams) : null;
              }}
              sx={{
                "& .MuiSelect-select": {
                  display: "flex",
                  alignItems: "center",
                },
              }}
            >
              <MenuItem sx={{ display: "none" }} value="">
                {item?.selectLabel}
              </MenuItem>
              {searcheble && (
                <SearchTextField
                  placeholder="Search..."
                  variant="outlined"
                  size="small"
                  formSx={{
                    width: "100%",
                    pl: "10px",
                    pr: "10px",
                  }}
                  onKeyDown={(e) => e.stopPropagation()}
                  onChange={(e) => {
                    debouncedSearch(e.target.value, setParams);
                  }}
                />
              )}
              {childrenList
                ?.filter((el) => el !== false)
                .map((child) => {
                  if (child?.type === "group")
                    return (
                      <ListSubheader
                        key={child?.name}
                        className={classes.selectGroupTitle}
                      >
                        {child?.name}
                      </ListSubheader>
                    );

                  return (
                    <MenuItem
                      key={`${child?.id}-${child?.name}`}
                      value={child?.id}
                      name={child?.name || child?.tag}
                      iconid={child?.iconId}
                      sx={{ maxWidth: "340px !important" }}
                      {...child?.props}
                    >
                      {child?.iconId && (
                        <ListItemIcon> {iconDict[child?.iconId]} </ListItemIcon>
                      )}
                      <ListItemText
                        primaryTypographyProps={{
                          sx: { fontSize: 12 },
                          noWrap: true,
                        }}
                      >
                        {child?.name || child?.tag}
                      </ListItemText>
                    </MenuItem>
                  );
                })}
            </StyledSelect>
          );
        }
        case "multiselect": {
          const {
            childrenList,
            textFieldProps,
            searcheble,
            setParams,
            ...selectProps
          } = selectMatchData?.find((data) => data?.id === item?.value) ?? {};
          const fieldName = item?.value;

          return (
            <StyledMultipleSelect
              adminIsAllowed
              notched={false}
              multiple
              labelProps={{ shrink: false }}
              name={fieldName}
              {...selectProps}
              label={
                // eslint-disable-next-line no-extra-boolean-cast
                !!temporaryFields[fieldName]?.length ? "" : item.selectLabel
              }
              value={temporaryFields[fieldName]}
              formSx={{ height: "32px" }}
              height="32px"
              onChange={(e, el) => handleTagsChange(e, el, item)}
              onClose={() => debouncedSearch("", setParams)}
              sx={{
                "& .MuiSelect-select": {
                  display: "flex",
                  alignItems: "center",
                },
              }}
              renderValue={(selected) => (
                <Typography
                  sx={{ fontSize: "12px", fontWeight: 500 }}
                  color="groundLighter.main"
                  noWrap
                >
                  {
                    // eslint-disable-next-line no-extra-boolean-cast
                    !!selected.length
                      ? selected.map(({ name }) => name).join(", ")
                      : ""
                  }
                </Typography>
              )}
            >
              {searcheble && (
                <SearchTextField
                  placeholder="Search..."
                  variant="outlined"
                  size="small"
                  formSx={{
                    width: "100%",
                    pl: "10px",
                    pr: "10px",
                  }}
                  onKeyDown={(e) => e.stopPropagation()}
                  onChange={(e) => debouncedSearch(e.target.value, setParams)}
                />
              )}
              {
                // eslint-disable-next-line no-extra-boolean-cast
                !!childrenList.length ? (
                  childrenList
                    ?.filter((el) => el !== false)
                    .map((child) => {
                      if (child?.type === "group")
                        return (
                          <ListSubheader
                            key={child?.name}
                            className={classes.selectGroupTitle}
                          >
                            <LargeCheckbox
                              checked={
                                !!temporaryFields[fieldName]?.length &&
                                !!temporaryFields[fieldName]?.some(
                                  (listItem) => listItem.id === child?.id
                                )
                              }
                              size={15}
                              sx={{ p: "5px" }}
                              formSx={{ mr: "5px" }}
                            />
                            {child?.name}
                          </ListSubheader>
                        );

                      return (
                        <MenuItem
                          sx={{ maxWidth: "282px !important" }}
                          key={`${child?.id}-${child?.name}`}
                          value={child?.id}
                          name={child?.name || child?.tag}
                          iconid={child?.iconId}
                          {...child?.props}
                        >
                          <LargeCheckbox
                            checked={
                              !!temporaryFields[fieldName]?.length &&
                              !!temporaryFields[fieldName]?.some(
                                (listItem) => listItem.value === child?.id
                              )
                            }
                            size={15}
                            sx={{ p: "5px" }}
                            formSx={{ mr: "5px" }}
                          />
                          {child?.iconId && (
                            <ListItemIcon>
                              {iconDict[child?.iconId]}
                            </ListItemIcon>
                          )}
                          <ListItemText
                            primaryTypographyProps={{
                              sx: { fontSize: 12 },
                              noWrap: true,
                            }}
                          >
                            {child?.name || child?.tag}
                          </ListItemText>
                        </MenuItem>
                      );
                    })
                ) : (
                  <MenuItem disabled value="">
                    No tags available
                  </MenuItem>
                )
              }
              {}
            </StyledMultipleSelect>
          );
        }
        case "date": {
          const {
            isVisit,
            textFieldProps,
            valuesForTextfield,
            isOrder,
            datesList,
            hideCustomDate,
            ...selectProps
          } = selectMatchData?.find((data) => data?.id === item?.value);
          const { value: currentValue } = temporaryFields[item?.value] || {};

          const showTextField = valuesForTextfield?.includes(currentValue);
          return (
            <Box display="flex" gap="14px">
              <SelectDatePicker
                moreLessFirst={selectProps.moreLessFirst || false}
                datesList={datesList ? datesList : ""}
                hideCustomDate={hideCustomDate}
                adminIsAllowed
                notched={false}
                labelProps={{ shrink: false }}
                // localeText={{ start: "Check-in", end: "Check-out" }}
                {...selectProps}
                value={
                  currentValue?.start_date
                    ? [
                        moment(currentValue?.start_date),
                        currentValue?.end_date
                          ? moment(currentValue?.end_date)
                          : null,
                      ]
                    : currentValue ?? ""
                }
                isVisit={isVisit}
                isOrder={isOrder}
                noSquare
                height="32px"
                fontSizeLabel="12px"
                handleDateInput={(newDates) => {
                  const start_date = newDates?.[0]?.format()?.split("+")?.[0];
                  const end_date = newDates?.[1]?.format()?.split("+")?.[0];
                  setTemporaryFields((prev) => {
                    return {
                      ...prev,
                      [item.value]: {
                        label: item.label,
                        name: newDates[1]
                          ? ` ${newDates[0]?.format(
                              "MM/DD/YYYY"
                            )} - ${newDates[1]?.format("MM/DD/YYYY")}`
                          : newDates[0]?.format("MM/DD/YYYY"),
                        value: {
                          date: null,
                          start_date,
                          end_date,
                        },
                      },
                    };
                  });
                }}
                fullWidth
                label={
                  temporaryFields[item.value]?.value ? "" : item.selectLabel
                }
                sx={{
                  "& > fieldset": {
                    borderRadius: "4px",
                  },
                }}
                handleClearValue={() => {
                  setTemporaryFields((prev) => {
                    return {
                      ...prev,
                      [item.value]: "",
                    };
                  });
                }}
                onChange={(e, el) => {
                  setTemporaryFields((prev) => {
                    let preparedData = {
                      ...prev,
                      [item.value]: {
                        label: item.label,
                        name: el.props.value,
                        value: e.target.value,
                      },
                    };
                    if (["more_than", "less_than"].includes(e.target.value))
                      preparedData[item.value].days = 1;
                    return preparedData;
                  });
                }}
                textfieldInputSx={{
                  borderRadius: "4px",
                }}
              />
              {showTextField && (
                <StyledTextField
                  value={temporaryFields[item.value]?.days || ""}
                  placeholder="0"
                  onChange={(e) => {
                    setTemporaryFields((prev) => {
                      return {
                        ...prev,
                        [item.value]: {
                          ...prev[item.value],
                          days: e.target.value,
                        },
                      };
                    });
                  }}
                  InputProps={{
                    sx: { padding: "0", height: "32px", fontSize: "12px" },
                    endAdornment: (
                      <ArrowBtn
                        checkDate={true}
                        stylesBox={{
                          display: "flex",
                          flexDirection: "column",
                          height: "32px",
                        }}
                        stylesUpBtn={{
                          height: "16px",
                          borderRadius: "0",
                          background: "#EEEEEE",
                          "&:hover": {
                            backgroundColor: "lightgray",
                            cursor: "pointer",
                          },
                        }}
                        stylesDownBtn={{
                          height: "16px",
                          borderRadius: "0",
                          background: "#EEEEEE",
                          "&:hover": {
                            backgroundColor: "lightgray",
                            cursor: "pointer",
                          },
                        }}
                        onClickUp={() =>
                          setTemporaryFields((prev) => ({
                            ...prev,
                            [item.value]: {
                              ...prev[item.value],
                              days: prev[item.value]?.days
                                ? +prev[item.value].days + 1
                                : 1,
                            },
                          }))
                        }
                        onClickDown={() =>
                          setTemporaryFields((prev) => ({
                            ...prev,
                            [item.value]: {
                              ...prev[item.value],
                              days:
                                prev[item.value]?.days &&
                                prev[item.value].days > 1
                                  ? +prev[item.value].days - 1
                                  : 1,
                            },
                          }))
                        }
                      />
                    ),
                  }}
                  {...textFieldProps}
                />
              )}
            </Box>
          );
        }

        case "textfields": {
          return (
            <Box className={classes.textFieldsWrapper}>
              {item?.options?.map((option) => {
                const { prefix, ...textfieldProps } = option;

                return (
                  <StyledTextField
                    adminIsAllowed
                    key={option.id}
                    {...textfieldProps}
                    inputSx={{ fontSize: "12px", height: "35px" }}
                    formSx={{ "& legend > span": { px: "2px" } }}
                    value={
                      temporaryFields[item.value]?.[option.id]?.value || ""
                    }
                    type="text"
                    InputProps={{
                      className: classes.textInputInventory,

                      startAdornment: price && (
                        <InputAdornment position="start">
                          <div>$</div>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <ArrowBtn
                            stylesBox={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                            stylesUpBtn={{
                              height: "16px",
                              borderRadius: "0 5px 0 0",
                              background: "#EEEEEE",
                              "&:hover": {
                                backgroundColor: "lightgray",
                                cursor: "pointer",
                              },
                            }}
                            stylesDownBtn={{
                              height: "16px",
                              borderRadius: "0 0 5px 0",
                              background: "#EEEEEE",
                              "&:hover": {
                                backgroundColor: "lightgray",
                                cursor: "pointer",
                              },
                            }}
                            onClickUp={() => {
                              setTemporaryFields((prev) => {
                                const prevValue =
                                  temporaryFields[item.value]?.[option.id]
                                    ?.value || 0;
                                return {
                                  ...prev,
                                  [item.value]: {
                                    ...prev[item.value],
                                    [option.id]: {
                                      value: +prevValue + 1,
                                      prefix: option.prefix,
                                    },
                                  },
                                };
                              });
                            }}
                            onClickDown={() => {
                              setTemporaryFields((prev) => {
                                const prevValue =
                                  temporaryFields[item.value]?.[option.id]
                                    ?.value || 0;
                                return {
                                  ...prev,
                                  [item.value]: {
                                    ...prev[item.value],
                                    [option.id]: {
                                      value: +prevValue - 1,
                                      prefix: option.prefix,
                                    },
                                  },
                                };
                              });
                            }}
                          />
                        </InputAdornment>
                      ),
                    }}
                    labelSx={{ left: -3 }}
                    onChange={(e) =>
                      handleChangeTextField({
                        item,
                        option,
                        value: e.target.value,
                      })
                    }
                  />
                );
              })}
            </Box>
          );
        }

        case "categories": {
          const selectLabel =
            switchesState.find((el) => el?.value === item?.value).selectLabel ??
            "";
          return (
            <CategoriesComponent
              value={temporaryFields[item.value] ?? {}}
              selectLabel={selectLabel}
              fetchQueryParams={{ used: true }}
              handleSetTemporaryFields={(el) => {
                setTemporaryFields((prev) => {
                  return {
                    ...prev,
                    [item.value]: el
                      ? {
                          label: item.label,
                          name: el?.name,
                          value: el?.id || el?.value,
                          icon: null,
                        }
                      : "",
                  };
                });
              }}
            />
          );
        }
        case "element": {
          const { Element, elementProps } = selectMatchData?.find(
            (data) => data?.id === item?.value
          );

          return (
            <Element
              {...{
                ...elementProps,
                setSwitchesState,
                setTemporaryFields,
                switches,
                filterFields,
              }}
            />
          );
        }
      }
    },
    [
      classes.selectGroupTitle,
      classes.textFieldsWrapper,
      classes.textInputInventory,
      classes.toggleButton,
      filterFields,
      handleTagsChange,
      iconDict,
      isCustomFulfillmentStatus,
      price,
      selectMatchData,
      switches,
      switchesState,
      temporaryFields,
      debouncedSearch,
    ]
  );

  // const hideArchivedIfQuickBooksConnected = (el) => {
  //   return isQuickBooksConnected ? el?.value !== "archived" : true;
  // };

  return (
    <>
      {showOnlyList ? (
        <MenuContent
          {...{
            switchesState,
            setTemporaryFields,
            setSwitchesState,
            rowItem,
            hasViewSave,
            onViewSave,
            onClose: props.onClose,
            handleApplyFilter,
            temporaryFields,
            contentProps,
            disabledApplyBtn,
          }}
        />
      ) : (
        <Menu
          id="basic-menu"
          MenuListProps={{
            "aria-labelledby": "basic-button",
            sx: {
              pt: 0,
            },
          }}
          {...props}
        >
          <MenuContent
            {...{
              switchesState,
              setTemporaryFields,
              setSwitchesState,
              rowItem,
              hasViewSave,
              onViewSave,
              onClose: props.onClose,
              handleApplyFilter,
              temporaryFields,
              contentProps,
              disabledApplyBtn,
            }}
          />
        </Menu>
      )}
    </>
  );
};

FilterMenu.propTypes = {
  switches: array,
  filterFields: object,
  iconDict: object,
  onViewSave: func,
  onClose: func,
  handleApplyFilter: func,
  open: bool,
  price: bool,
  showOnlyList: bool,
  selectMatchData: array,
  isCustomFulfillmentStatus: bool,
  contentProps: object,
};

FilterMenu.defaultProps = {
  switches: [],
  filterFields: {},
  iconDict: {},
  showOnlyList: false,
  handleApplyFilter: () => {},
  contentProps: {},
  selectMatchData: [],
};

export default FilterMenu;
