import React from "react";

// eslint-disable-next-line react/prop-types
export const SearchIcon = ({ small }) => {
  const width = small ? 13.859 : 17.866;
  const height = small ? 14.254 : 18.246;
  const ellipse = small ? 4.475 : 5.89;
  const radius = small ? 4.125 : 5.54;
  const x2 = small ? 4.1 : 5.1;
  const y2 = small ? 4.509 : 5.97;
  const transform1 = small ? 6.545 : 8.615;
  const transform2 = small ? "9.5 9.51" : "12.5 12.048";
  return (
    <svg
      id="Component_114_35"
      data-name="Component 114 – 35"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
    >
      <g id="Component_114_1" data-name="Component 114 – 1" transform="translate(0)">
        <g
          id="Ellipse_2"
          data-name="Ellipse 2"
          transform={`translate(${transform1}) rotate(47)`}
          fill="none"
          stroke="#707070"
          strokeWidth="0.7"
        >
          <ellipse cx={ellipse} cy={ellipse} rx={ellipse} ry={ellipse} stroke="none" />
          <ellipse cx={ellipse} cy={ellipse} rx={radius} ry={radius} fill="none" />
        </g>
        <line
          id="Line_3"
          data-name="Line 3"
          x2={x2}
          y2={y2}
          transform={`translate(${transform2})`}
          fill="none"
          stroke="#707070"
          strokeWidth="0.7"
        />
      </g>
    </svg>
  );
};

// OLD ICON
// <svg
//   xmlns="http://www.w3.org/2000/svg"
//   width="20"
//   height="20"
//   viewBox="0 0 24.722 25.82"
// >
//   <g
//     id="Group_4116"
//     data-name="Group 4116"
//     transform="translate(-1800.961 -250)"
//   >
//     <g
//       id="Component_114_3"
//       data-name="Component 114 – 3"
//       transform="translate(1800.961 250)"
//     >
//       <g
//         id="Ellipse_2"
//         data-name="Ellipse 2"
//         transform="translate(11.293) rotate(47)"
//         fill="none"
//         stroke="#5f6267"
//         strokeWidth="2"
//       >
//         <ellipse cx="7.726" cy="7.72" rx="7.726" ry="7.72" stroke="none" />
//         <ellipse cx="7.726" cy="7.72" rx="6.726" ry="6.72" fill="none" />
//       </g>
//       <line
//         id="Line_3"
//         data-name="Line 3"
//         x2="12.099"
//         transform="translate(15.739 16.29) rotate(47)"
//         fill="none"
//         stroke="#5f6267"
//         strokeWidth="2"
//       />
//     </g>
//   </g>
// </svg>
