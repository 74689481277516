import { array, any, string } from "prop-types";
import moment from "moment";
import { Box, Typography } from "@mui/material";
import { getFormattedDate } from "helpers/helpers";
import { useMemo } from "react";
import { StyledTooltip } from "components";

export const ActivityBlock = ({
  activities,
  activityIcon,
  createdAt,
  timeZone,
}) => {
  const hasPhotoDeleted = useMemo(() => {
    const activity = activities?.[0];
    return (
      activity?.type === "PHOTO_GROUP_ADDED" &&
      activity?.photoGroupComments?.length === 0 &&
      activity?.photoGroups?.length === 0
    );
  }, [activities]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        opacity: hasPhotoDeleted ? 0.5 : 1,
      }}
    >
      {activities?.length ? (
        <StyledTooltip
          arrow
          title="Photo deleted"
          placement="top"
          disableHoverListener={!hasPhotoDeleted}
        >
          <Box components="span">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                pointerEvents: hasPhotoDeleted && "none",
                "& > *:first-of-type": {
                  "& > *:first-of-type": {
                    p: {
                      cursor: "pointer",
                      ":hover": {
                        textDecoration: "underline",
                      },
                    },
                  },
                },
              }}
            >
              {activityIcon}
              <Typography variant="caption" color="groundLight" noWrap>
                at {activities?.[0]?.customer?.name}
              </Typography>
            </Box>
            <Typography variant="caption" color="groundLight">
              {moment(getFormattedDate(createdAt, timeZone)).format(
                "MM/D/YYYY, h:mm A"
              )}
            </Typography>
          </Box>
        </StyledTooltip>
      ) : (
        <Typography>-</Typography>
      )}
    </Box>
  );
};

ActivityBlock.propTypes = {
  activities: array,
  activityIcon: any,
  createdAt: string,
  timeZone: string,
};
ActivityBlock.defaultProps = {
  activities: [],
  activityIcon: "",
  createdAt: "",
  timeZone: "",
};
