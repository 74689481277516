import { object, func } from "prop-types";
import { Button, Grid, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { LargeCheckbox } from "components";
import { useDispatch } from "react-redux";
import {
  openConfirmDialogAction,
  setConfirmIsOpenAction,
} from "redux/actions/confirmDialogs";

export const ContinueSellingBlock = ({ formField, setValue, control }) => {
  const dispatch = useDispatch();

  const handleOpenConfirmDialogWhenOutOfStock = (v) => {
    dispatch(
      openConfirmDialogAction({
        title: 'Turn off "Selling when out of stock"?',
        text: 'Switching off "Continue selling when out of stock" will not allow you to make new orders for this product.',
        buttons: (
          <>
            <Button
              sx={{
                width: "98px",
                color: "#6A6A6A",
                borderColor: "#D4D4D4",
                fontSize: "13px",
                height: "28px",
              }}
              onClick={() => {
                dispatch(setConfirmIsOpenAction(false));
              }}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              sx={{
                width: "98px",
                color: "#FFFFFF",
                fontSize: "13px",
                height: "28px",
                boxShadow: "none",
              }}
              color="primary"
              onClick={() => {
                dispatch(setConfirmIsOpenAction(false));
                setValue("whenOutOfStock", v);
              }}
              variant="contained"
            >
              Confirm
            </Button>
          </>
        ),
      })
    );
  };

  const handleSetWhenOutOfStock = (e) => {
    const v = e.target.checked;
    if (formField?.isMultiple) {
      const allocatedLessThanZero = formField?.variationsFields?.filter(
        (variant) => {
          return variant?.inventory?.onHand - variant?.inventory?.allocated < 0;
        }
      );
      if (formField?.whenOutOfStock && allocatedLessThanZero.length) {
        handleOpenConfirmDialogWhenOutOfStock(v);
      } else {
        setValue("whenOutOfStock", v);
      }
    } else {
      const available =
        formField?.inventory?.onHand - formField?.inventory?.allocated;

      if (formField?.whenOutOfStock && available < 0) {
        handleOpenConfirmDialogWhenOutOfStock(v);
      } else {
        setValue("whenOutOfStock", v);
      }
    }
  };

  return (
    <Grid xs={8.5} item>
      <Controller
        render={({ field }) => (
          <LargeCheckbox
            adminIsAllowed
            checked={!!field.value}
            formSx={{ ml: "-10px", mr: 0 }}
            label={
              <Typography
                sx={{
                  ml: "-4px",
                }}
                color="#707070"
                fontSize={14}
              >
                Continue selling when out of stock
              </Typography>
            }
            size={19}
            {...field}
            onChange={handleSetWhenOutOfStock}
          />
        )}
        name="whenOutOfStock"
        control={control}
      />
    </Grid>
  );
};

ContinueSellingBlock.propTypes = {
  formField: object,
  setValue: func,
  control: object,
};
