import axios from "axios";

export const getDraftsService = async () => {
  return await axios.get("/drafts").then((res) => res.data);
};

export const getCartDraftDataService = async (body) => {
  return await axios.post("/drafts/cart-data", body).then((res) => res.data);
};

export const createDraftService = async (data) => {
  return axios.post("/drafts", data).then((res) => res.data);
};

export const updateDraftService = async (id, data) => {
  return axios.put(`/drafts/${id}`, data).then((res) => res.data);
};
