import { makeStyles } from "@mui/styles";

export const cl = {
  Switcher: {
    paperOptions: {
      sx: {
        alignItems: "center",
        border: "1px solid #D5D9D9",
        borderRadius: "4px 4px 0 0",
        height: 39,
        display: "flex",
        justifyContent: "space-between",
      },
      elevation: 0,
    },
    btnGalleryOptions: {
      sx: {
        ml: 1,
        backgroundColor: "#F8F8F8",
        height: "24px",
        border: "0.5px solid #D5D9D9",
        width: "108px",
        minWidth: "108px",
        whiteSpace: "nowrap",
        color: "#5F6368",
      },
      label: "View in Gallery",
      fontSize: "12px",
      variant: "outlined",
      color: "edit",
    },
    text: {
      fontSize: "16px",
      fontWeight: 500,
      color: "#1C1C19",
      paddingLeft: "17px",
    },
    wrapper: {
      display: "flex",
      alignItems: "center",
      flexGrow: 1,
    },
  },
  HeaderList: {
    paperOptions: {
      sx: {
        backgroundColor: "#F9F9F9",
        alignItems: "center",
        borderWidth: "0 1px 1px 1px",
        borderStyle: "solid",
        borderColor: "#D5D9D9",
        minHeight: "39px",
      },
      elevation: 0,
      container: true,
      square: true,
    },
    text: { fontSize: "12px", color: "#6A6A6A" },
  },
  ItemList: {
    paper: {
      display: "flex",
      alignItems: "center",
      // borderWidth: "0 0 0 0",
      borderStyle: "solid",
      borderColor: "#D5D9D9",
      minHeight: "70px",
      // "&:not(:last-of-type)": {
      borderWidth: "0 0 0.5px 0",
      // },
    },
  },
};

const useStyles = makeStyles(() => ({
  tabs: {
    marginRight: "12px",
    minHeight: "39px",
    alignItems: "center",
    "& .MuiTabs-flexContainer": { justifyContent: "space-between" },
  },
  tab: {
    textTransform: "none",
    fontSize: 11,
    color: "#707070",
    borderRadius: "4px",
    "&.Mui-selected": {
      backgroundColor: "rgba(71, 160, 110, 0.2) !important",
    },
    minWidth: "36px",
    // width: "84px",
    height: "24px",
    minHeight: "24px",
    paddingLeft: "11px",
    paddingRight: "11px",
  },
  // filterChip: {
  //   borderRadius: "4px",
  //   border: "0.7px solid #d5d9d9",
  //   backgroundColor: "#FAFAFB",
  //   height: "28px",
  // },
}));

export default useStyles;
