import { Box, Typography } from "@mui/material";
import { TagsIcon } from "components/Icons";

export const TitleBlock = () => {
  return (
    <Box display="flex" justifyContent="flex-start" alignItems="center">
      <Box>
        <TagsIcon />
      </Box>
      <Box ml="18px">
        <Typography fontSize={20} fontWeight={400} color="#707070">
          Order tags
        </Typography>
        <Typography fontSize={16} fontWeight={400} color="#6A6A6A">
          Rename or delete tags. Organize customer tags by your own needs.
        </Typography>
      </Box>
    </Box>
  );
};
