/* eslint-disable react/prop-types */
import * as React from "react";
export const StoreIcon = ({ width = "11.293", height = "9.389" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 11.293 9.389"
  >
    <path
      d="M11.036 2.175 9.843.275A.587.587 0 0 0 9.349 0H1.943a.587.587 0 0 0-.5.275l-1.19 1.9a1.636 1.636 0 0 0 1.078 2.5 1.906 1.906 0 0 0 .251.017 1.81 1.81 0 0 0 1.353-.607 1.812 1.812 0 0 0 2.706 0 1.812 1.812 0 0 0 2.706 0 1.816 1.816 0 0 0 1.353.607 1.885 1.885 0 0 0 .251-.017 1.634 1.634 0 0 0 1.085-2.5ZM9.707 5.281a2.326 2.326 0 0 1-.541-.07v1.83H2.125v-1.83a2.429 2.429 0 0 1-.541.07 2.474 2.474 0 0 1-.33-.022 2.306 2.306 0 0 1-.3-.066V8.8a.586.586 0 0 0 .587.587h8.214a.586.586 0 0 0 .588-.587V5.193a1.872 1.872 0 0 1-.3.066 2.55 2.55 0 0 1-.334.022Z"
      fill="#26874b"
    />
  </svg>
);
