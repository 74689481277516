import { useCallback, useMemo, useState } from "react";
import { array, any, func, string, bool, number, oneOfType } from "prop-types";
import { Box, IconButton, Link, Typography } from "@mui/material";
import { InfiniteScrollWrapper, StyledButton, StyledTooltip } from "components";
import { PlusIcon } from "components/Icons";
import useStyles from "./styles";
import { NavLink, useNavigate } from "react-router-dom";
import { usePersonalDetails } from "Pages/SettingsPage/components/SettingsTabs/PaymentsTab/components/PayoutsTab/PayoutsTab.hooks";
import { useOnBoardingTasks, useRepsPermissions } from "helpers/hooks";
import { setPhoneNumberMask } from "helpers/helpers";

const CustomerCardDetails = ({
  title,
  items,
  loadingItems,
  itemsCount,
  handleFetchItems,
  handleAdd,
  titleStyle,
  child,
  onDelete,
  handleEdit,
  isAddress,
  addressTab,
  bottomBlock,
  resetCustomerState,
  isReps,
  isParent,
  customer,
  disabledCreateEdit,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const repPermissions = useRepsPermissions();
  const [hoveredElement, setHoveredElement] = useState(null);
  const { isOnBoarding } = useOnBoardingTasks();

  const { allowedToAddCard } = usePersonalDetails({ isSetup: false });
  const isHasParent = customer?.parentCustomer?.isUncategorized !== true;
  const setVisibilityBtns = (el) => {
    if (isAddress) {
      if (el?.id) return "block";
      return hoveredElement?.itemTitle === el?.itemTitle ? "block" : "none";
    }
    return hoveredElement?.id === el?.id ? "block" : "none";
  };

  const hideDelBtn = useMemo(() => {
    if (
      title === "Assigned Reps" &&
      hoveredElement?.itemTitle === "Sales Rep"
    ) {
      const selectedSalesCount = items?.filter(
        (r) => r?.itemTitle === "Sales Rep"
      )?.length;
      if (selectedSalesCount === 1) return true;
    }
    return false;
  }, [title, hoveredElement?.itemTitle, items]);

  const handleEditAddress = (el) => {
    handleEdit(addressTab === 0 ? "shipping address" : "billing address", el);
  };

  const setWidthOnHover = (el) => {
    if (isAddress) {
      if (hoveredElement?.itemTitle === el?.itemTitle && !el?.id) {
        return "calc(100% - 74px)";
      }
      return "100%";
    }

    if (hoveredElement?.id === el?.id || !el?.id) {
      return "calc(100% - 74px)";
    }

    return "100%";
  };

  const phoneNumber = useCallback((phone) => {
    return phone?.replace("+", "")?.length
      ? setPhoneNumberMask(`${phone}`)
      : "";
  }, []);

  return (
    <Box pb={items?.length ? "17px" : "4px"} sx={{ minHeight: "46px" }}>
      <Box className={`${classes.title} ${titleStyle}`}>
        {title}
        {title === "Credit Cards" && !allowedToAddCard ? (
          <StyledTooltip
            title={
              <span style={{ whiteSpace: "pre-wrap" }}>
                No bank account linked to your profile. Add a{" "}
                {repPermissions && !repPermissions?.settings?.payments ? (
                  "bank account "
                ) : (
                  <Link
                    color="primary"
                    to="/settings"
                    state={{ tab: "Payments", paymentsTab: "Payouts" }}
                    component={NavLink}
                  >
                    bank account{" "}
                  </Link>
                )}
                under the payment section to proceed. Complete your order direct
                store setup.
              </span>
            }
            placement="top"
            arrow
          >
            <Box>
              {handleAdd && (
                <IconButton
                  onClick={allowedToAddCard ? handleAdd : undefined}
                  sx={{ p: "0px !important" }}
                  style={{
                    background: "#fff",
                  }}
                >
                  <PlusIcon fill="#707070" width="20" height="20" />
                </IconButton>
              )}
            </Box>
          </StyledTooltip>
        ) : (
          <Box>
            {handleAdd && (
              <>
                {title === "Assigned Reps" && isOnBoarding ? (
                  <StyledTooltip
                    title="Complete Onboarding to use this feature"
                    placement="top"
                    arrow
                  >
                    <IconButton
                      sx={{ p: "0px !important" }}
                      style={{
                        background: "#fff",
                      }}
                      disabled={disabledCreateEdit}
                    >
                      <PlusIcon
                        fill="#dddddd"
                        width="20"
                        height="20"
                        style={{
                          cursor: "not-allowed",
                        }}
                      />
                    </IconButton>
                  </StyledTooltip>
                ) : (
                  <IconButton
                    disabled={disabledCreateEdit}
                    onClick={handleAdd}
                    sx={{ p: "0px !important" }}
                    style={{
                      background: "#fff",
                    }}
                  >
                    <PlusIcon fill="#707070" width="20" height="20" />
                  </IconButton>
                )}
              </>
            )}
          </Box>
        )}
      </Box>
      {items?.length ? (
        <Box className={classes.itemsWrap}>
          <InfiniteScrollWrapper
            maxHeight="235px"
            dataLength={items?.length}
            id="items-scroll-table"
            loading={loadingItems}
            nextFunc={handleFetchItems}
            hasMoreItems={items.length < itemsCount}
          >
            {items?.map((el) => (
              <Box
                key={
                  el.id ||
                  `${el.itemTitle}-${el.customerName}-${el.customerEmail}`
                }
                position="relative"
                sx={{ "&:last-of-type > div": { mb: 0 } }}
              >
                <Box
                  onMouseEnter={() => setHoveredElement(el)}
                  onMouseLeave={() => setHoveredElement(null)}
                  className={classes.itemsBlock}
                >
                  <Box sx={{ maxWidth: setWidthOnHover(el) }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        color: "#5F6267",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: "13px", fontWeight: "bold", mb: "2px" }}
                        noWrap
                      >
                        {el.itemTitle}
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        fontSize: "13px",
                        color: "#5F6267",
                        fontWeight: "medium",
                      }}
                      noWrap
                    >
                      {el.customerName}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "13px",
                        color: "#5F6267",
                        fontWeight: "light",
                      }}
                      noWrap
                    >
                      {el.customerEmail}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "13px",
                        color: "#5F6267",
                        fontWeight: "light",
                      }}
                      component={Link}
                      underline="none"
                      href={`tel:${phoneNumber(el.phone) ?? "#"}`}
                    >
                      {phoneNumber(el.phone)}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: el.buttons ? "flex" : "unset",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    {el.status && (
                      <Typography
                        fontSize="9px"
                        sx={{
                          position: "absolute",
                          top: "12px",
                          right: "31px",
                          opacity: hoveredElement?.id === el?.id ? 0 : 1,
                          p: "2px 4px",
                          color: "#FFFFFF",
                          borderRadius: " 4px",
                          background:
                            el.status === "PENDING" ? "#F0C401" : "#47A06D",
                        }}
                      >
                        {el.status}
                      </Typography>
                    )}
                    {isAddress && isParent && !el?.id && (
                      <Box
                        sx={{
                          position: "absolute",
                          top: "50%",
                          transform: "translate(-50%, -50%)",
                          right: "8px",
                          opacity:
                            hoveredElement?.itemTitle === el?.itemTitle ? 0 : 1,
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "9px",
                            p: "2px 4px",
                            color: "#FFFFFF",
                            borderRadius: " 4px",
                            background: "#47A06D",
                          }}
                        >
                          PRIMARY
                        </Typography>
                      </Box>
                    )}
                    <Box
                      sx={{
                        ml: "15px",
                        position:
                          isAddress && !!el?.id ? "absolute" : "inherit",
                        top: isAddress && !!el?.id && "10px",
                        right: isAddress && !!el?.id && "30px",
                        width: "min-content",
                        display: setVisibilityBtns(el),
                      }}
                    >
                      {isAddress && !!el?.id && (
                        <Box pl="4px" bgcolor="#F8F8F8">
                          <Link
                            sx={{
                              fontSize: 13,
                              fontWeight: 500,
                            }}
                            color="primary"
                            to={`/customers/${el?.id}`}
                            component={NavLink}
                            onClick={() => {
                              if (resetCustomerState) resetCustomerState();
                              navigate(`/customers/${el?.id}`);
                            }}
                          >
                            View
                          </Link>
                        </Box>
                      )}

                      {((isAddress && !el?.id && !isHasParent) ||
                        (!isReps && !isAddress) ||
                        (isParent && !el?.id)) && (
                        <StyledButton
                          label="Edit"
                          disabled={disabledCreateEdit}
                          variant="outlined"
                          color="ground"
                          fontSize="10px"
                          fontWeight="500"
                          className={classes.buttons}
                          onClick={() => {
                            if (repPermissions) {
                              if (repPermissions?.customers?.create_edit) {
                                isAddress
                                  ? handleEditAddress(el)
                                  : handleEdit(el);
                                return;
                              }
                              return;
                            }
                            isAddress ? handleEditAddress(el) : handleEdit(el);
                          }}
                        />
                      )}
                      {!(isAddress || hideDelBtn) && (
                        <StyledTooltip
                          title="The primary card cannot be deleted"
                          placement="top"
                          arrow
                          disableHoverListener={
                            !(
                              title === "Credit Cards" &&
                              el?.status === "PRIMARY"
                            )
                          }
                          PopperProps={{
                            modifiers: [
                              { name: "offset", options: { offset: [0, -10] } },
                            ],
                          }}
                        >
                          <Box>
                            <StyledButton
                              disabled={
                                disabledCreateEdit ||
                                (title === "Credit Cards" &&
                                  el?.status === "PRIMARY")
                              }
                              sx={{ mt: 1 }}
                              label="Remove"
                              variant="outlined"
                              color="ground"
                              fontSize="10px"
                              fontWeight="500"
                              className={classes.buttons}
                              onClick={() => onDelete(el)}
                            />
                          </Box>
                        </StyledTooltip>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}
          </InfiniteScrollWrapper>
        </Box>
      ) : null}
      {child}
      {bottomBlock ? bottomBlock : null}
    </Box>
  );
};
CustomerCardDetails.propTypes = {
  title: any,
  items: array,
  handleAdd: func,
  titleStyle: string,
  child: any,
  onSubmit: func,
  onDelete: func,
  handleEdit: func,
  isAddress: bool,
  addressTab: number,
  handleDeleteContact: func,
  handleEditContact: func,
  repsList: oneOfType([array, bool]),
  bottomBlock: any,
  resetCustomerState: func,
  loadingItems: bool,
  itemsCount: number,
  handleFetchItems: func,
  isReps: bool,
  isParent: bool,
  hide: bool,
  customer: any,
  disabledCreateEdit: bool,
};
CustomerCardDetails.defaultProps = {
  loadingItems: false,
  itemsCount: 0,
  handleFetchItems: () => {},
};

export default CustomerCardDetails;
