import { useEffect } from "react";
import { array, func, object, string } from "prop-types";
import { Controller, useFieldArray, useWatch } from "react-hook-form";

import { TextField, Box } from "@mui/material";

import useStyles from "../../styles";

const CustomField = ({ register, control, fieldName, placeholderName }) => {
  const classes = useStyles();

  return (
    <Box sx={{ position: "relative", paddingRight: 2 }}>
      <Controller
        render={({ field, fieldState: { error } }) => (
          <TextField
            sx={{ width: "100%", maxWidth: "120px", marginX: 0.1 }}
            ref={register}
            fullWidth
            size="small"
            placeholder={placeholderName}
            InputProps={{ className: classes.textInputVariant }}
            InputLabelProps={{ className: classes.inputLabel }}
            error={!!error}
            {...field}
          />
        )}
        name={fieldName}
        control={control}
      />
    </Box>
  );
};
CustomField.propTypes = {
  register: func,
  control: object,
  fieldName: string,
  placeholderName: string,
};
CustomField.defaultProps = {
  placeholderName: "Add another",
};

export const InputsVariations = ({
  nameVariation,
  valueName,
  control,
  register,
}) => {
  const { fields, remove, append } = useFieldArray({
    control,
    name: nameVariation,
    keyName: `${nameVariation}Key`,
  });

  const formField = useWatch({
    control,
  });

  useEffect(() => {
    // add sku
    if (
      formField?.skuVariationFields?.length < 10 &&
      formField?.skuVariationFields[formField.skuVariationFields.length - 1]
        ?.sku !== "" &&
      valueName === "sku"
    ) {
      append({ sku: "" });
    }
    // add color
    if (
      formField?.colorVariationFields?.length < 10 &&
      formField?.colorVariationFields[
        formField.colorVariationFields?.length - 1
      ]?.color !== "" &&
      valueName === "color"
    ) {
      append({ color: "" });
    }
    // add size
    if (
      formField?.sizeVariationFields?.length < 10 &&
      formField?.sizeVariationFields[formField.sizeVariationFields.length - 1]
        ?.size !== "" &&
      valueName === "size"
    ) {
      append({ size: "" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formField]);

  useEffect(() => {
    const arrSku = formField.skuVariationFields;
    const arrSkuCount = formField.skuVariationFields.length - 1;
    const arrSkuPrevLast = formField.skuVariationFields.length - 2;

    const arrSize = formField.sizeVariationFields;
    const arrSizeCount = formField.sizeVariationFields.length - 1;
    const arrSizePrevLast = formField.sizeVariationFields.length - 2;

    const arrColor = formField.colorVariationFields;
    const arrColorCount = formField.colorVariationFields.length - 1;
    const arrColorPrevLast = formField.colorVariationFields.length - 2;

    if (arrSku[arrSkuPrevLast]?.sku === "" && valueName === "sku") {
      remove(arrSkuCount);
    }
    if (arrSize[arrSizePrevLast]?.size === "" && valueName === "size") {
      remove(arrSizeCount);
    }
    if (arrColor[arrColorPrevLast]?.color === "" && valueName === "color") {
      remove(arrColorCount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formField, remove]);

  return fields.map((item, i) => (
    <CustomField
      key={item[`${nameVariation}Key`]}
      register={register}
      control={control}
      fieldName={`${nameVariation}[${i}][${valueName}]`}
      placeholderName={i === 0 ? "Add variation" : "Add another"}
    />
  ));
};
InputsVariations.propTypes = {
  nameVariation: string,
  valueName: string,
  fields: array,
  register: func,
  control: object,
};

export default InputsVariations;
