import React from "react";
import { object, func } from "prop-types";
import { Box, Typography } from "@mui/material";
import { useSettingsOrderDirect } from "../../OrderDirectTab.hooks";
import { SetupItem } from "components";
import SetStoreLinkModal from "../StoreLink/SetStoreLinkModal";

const OrderDirectSetup = ({ widgetState, updateWidgetState }) => {
  const {
    SETUP_STEPS,
    updateLinkAvailability,
    onLinkChange,
    handleUploadStoreLink,
  } = useSettingsOrderDirect({
    widgetState,
    updateWidgetState,
  });

  return (
    <Box display="flex" flexDirection="column" gap="23px">
      <SetStoreLinkModal
        widgetState={widgetState}
        open={widgetState.storeLinkDialogOpen}
        onClose={() => updateWidgetState({ storeLinkDialogOpen: false })}
        onConfirm={handleUploadStoreLink}
        onLinkChange={onLinkChange}
        updateAvailability={updateLinkAvailability}
      />
      <Typography fontSize="22px" color="#6A6A6A" fontWeight="700">
        Set up your store to activate your order direct access
      </Typography>
      {SETUP_STEPS.map((step) => (
        <SetupItem key={step.title} step={step} />
      ))}
    </Box>
  );
};

OrderDirectSetup.propTypes = { widgetState: object, updateWidgetState: func };

export default OrderDirectSetup;
