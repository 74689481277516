import {
  SET_FLAT_COLUMN_LAYOUTS,
  SET_DIRECT_COLUMN_LAYOUTS,
  SET_THIRD_PARTY_COLUMN_LAYOUTS,
  SET_SORTED_COLUMN_LAYOUTS,
  SET_REP_ALL_COLUMN_LAYOUTS,
  SET_DRAFTS_COLUMN_LAYOUTS,
} from "../actions/settings";

export const initialState = {
  column_layouts: {
    flat: {
      disable: [
        { id: "1", name: "Status", selected: true, disable: true },
        { id: "2", name: "ID", selected: true, disable: true },
        { id: "3", name: "Customer", selected: true, disable: true },
      ],
      main: [
        { id: "4", name: "Group", selected: true },
        { id: "5", name: "Territory", selected: true },
        { id: "6", name: "Assigned Reps", selected: true },
        { id: "8", name: "Last Visit", selected: true },
        { id: "9", name: "Last Order", selected: true },
        { id: "10", name: "Orders Total", selected: true },
        {
          id: "7",
          name: "Order Direct",
          selected: true,
        },
      ],
    },
    sorted: {
      disable: [
        { id: "1", name: "Status", selected: true, disable: true },
        { id: "2", name: "ID", selected: true, disable: true },
        { id: "3", name: "Customer", selected: true, disable: true },
      ],
      main: [
        { id: "4", name: "Territory", selected: true },
        { id: "5", name: "Assigned Reps", selected: true },
        { id: "7", name: "Last Visit", selected: true },
        { id: "8", name: "Last Order", selected: true },
        { id: "9", name: "Orders Total", selected: true },
        {
          id: "6",
          name: "Order Direct",
          selected: true,
        },
      ],
    },
    direct: {
      disable: [{ id: "1", name: "Order", selected: true, disable: true }],
      main: [
        { id: "2", name: "Note", selected: true },
        { id: "3", name: "Date", selected: true },
        { id: "4", name: "Customer", selected: true },
        { id: "5", name: "Created by", selected: true },
        { id: "6", name: "Payment", selected: true },
        { id: "7", name: "Fulfillment", selected: true },
        { id: "8", name: "Total", selected: true },
        { id: "9", name: "Tags", selected: true },
      ],
    },
    thirdParty: {
      disable: [{ id: "1", name: "Order", selected: true, disable: true }],
      main: [
        { id: "2", name: "Note", selected: true },
        { id: "3", name: "Date", selected: true },
        { id: "4", name: "Customer", selected: true },
        { id: "5", name: "Assigned to", selected: true },
        { id: "6", name: "Created by", selected: true },
        { id: "7", name: "Total", selected: true },
        { id: "8", name: "Emailed", selected: true },
        { id: "9", name: "Tags", selected: true },
      ],
    },
    rep_all: {
      disable: [
        { id: "1", name: "Status", selected: true, disable: true },
        { id: "2", name: "Rep Name", selected: true, disable: true },
      ],
      main: [
        { id: "3", name: "Role", selected: true },
        { id: "4", name: "Territory", selected: true },
        { id: "5", name: "Work Status", selected: true },
        { id: "6", name: "Latest Activity", selected: true },
        { id: "7", name: "Revenue", selected: true },
        { id: "8", name: "Orders", selected: true },
        { id: "9", name: "Customers", selected: true },
        { id: "10", name: "Visits Today", selected: true },
      ],
    },
    drafts: {
      disable: [{ id: "1", name: "Order", selected: true, disable: true }],
      main: [
        { id: "2", name: "Date", selected: true },
        { id: "3", name: "Customer", selected: true },
        { id: "4", name: "Created by", selected: true },
        { id: "5", name: "Status", selected: true },
        { id: "6", name: "Total", selected: true },
        { id: "7", name: "Tags", selected: true },
      ],
    },
  },
};

const settingsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_FLAT_COLUMN_LAYOUTS:
      return {
        ...state,
        column_layouts: { ...state.column_layouts, flat: payload },
      };

    case SET_SORTED_COLUMN_LAYOUTS:
      return {
        ...state,
        column_layouts: { ...state.column_layouts, sorted: payload },
      };

    case SET_DIRECT_COLUMN_LAYOUTS:
      return {
        ...state,
        column_layouts: { ...state.column_layouts, direct: payload },
      };
    case SET_THIRD_PARTY_COLUMN_LAYOUTS:
      return {
        ...state,
        column_layouts: { ...state.column_layouts, thirdParty: payload },
      };

    case SET_REP_ALL_COLUMN_LAYOUTS:
      return {
        ...state,
        column_layouts: { ...state.column_layouts, rep_all: payload },
      };

    case SET_DRAFTS_COLUMN_LAYOUTS:
      return {
        ...state,
        column_layouts: { ...state.column_layouts, drafts: payload },
      };

    default:
      return state;
  }
};

export default settingsReducer;
