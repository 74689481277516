import React from "react";

// eslint-disable-next-line react/prop-types
export const CalendarIcon = ({ fill }) => {
  return (
    <svg
      id="Icon_awesome-calendar-alt"
      data-name="Icon awesome-calendar-alt"
      xmlns="http://www.w3.org/2000/svg"
      width="15.818"
      height="18.078"
      viewBox="0 0 15.818 18.078"
    >
      <path
        id="Icon_awesome-calendar-alt-2"
        data-name="Icon awesome-calendar-alt"
        d="M0,16.383a1.7,1.7,0,0,0,1.695,1.695H14.123a1.7,1.7,0,0,0,1.695-1.695v-9.6H0Zm11.3-6.92a.425.425,0,0,1,.424-.424h1.412a.425.425,0,0,1,.424.424v1.412a.425.425,0,0,1-.424.424H11.722a.425.425,0,0,1-.424-.424Zm0,4.52a.425.425,0,0,1,.424-.424h1.412a.425.425,0,0,1,.424.424v1.412a.425.425,0,0,1-.424.424H11.722a.425.425,0,0,1-.424-.424Zm-4.52-4.52A.425.425,0,0,1,7.2,9.039H8.615a.425.425,0,0,1,.424.424v1.412a.425.425,0,0,1-.424.424H7.2a.425.425,0,0,1-.424-.424Zm0,4.52a.425.425,0,0,1,.424-.424H8.615a.425.425,0,0,1,.424.424v1.412a.425.425,0,0,1-.424.424H7.2a.425.425,0,0,1-.424-.424ZM2.26,9.463a.425.425,0,0,1,.424-.424H4.1a.425.425,0,0,1,.424.424v1.412A.425.425,0,0,1,4.1,11.3H2.683a.425.425,0,0,1-.424-.424Zm0,4.52a.425.425,0,0,1,.424-.424H4.1a.425.425,0,0,1,.424.424v1.412a.425.425,0,0,1-.424.424H2.683a.425.425,0,0,1-.424-.424ZM14.123,2.26H12.429V.565A.567.567,0,0,0,11.864,0h-1.13a.567.567,0,0,0-.565.565V2.26H5.649V.565A.567.567,0,0,0,5.084,0H3.955A.567.567,0,0,0,3.39.565V2.26H1.695A1.7,1.7,0,0,0,0,3.955V5.649H15.818V3.955A1.7,1.7,0,0,0,14.123,2.26Z"
        fill={fill || "#b5b5ac"}
      />
    </svg>
  );
};
