/* eslint-disable react/prop-types */

export const SmallHourglassIcon = ({ fill = "#959595" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="5.615"
      height="9.125"
      viewBox="0 0 5.615 9.125"
    >
      <path
        id="Icon_ionic-md-hourglass"
        data-name="Icon ionic-md-hourglass"
        d="M9,3.375V6.069H9l0,0,1.871,1.864L9,9.806l0,0H9V12.5h5.615V9.811h0l0,0L12.744,7.938l1.871-1.864,0,0h0V3.375Zm4.679,6.666v1.568H9.937V10.041l1.871-1.869ZM11.808,7.7,9.937,5.836V4.244h3.744v1.59Z"
        transform="translate(-9 -3.375)"
        fill={fill}
      />
    </svg>
  );
};
