import { useEffect, useRef, useState } from "react";
import { bool, func, array } from "prop-types";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import Nestable from "react-nestable";

import { Box } from "@mui/material";
import {
  ArrowsDnDIcon,
  ShevronIcon,
  ShevronRightIcon,
} from "../../../../components/Icons";

import {
  customersWithoutTerritorySelector,
  loadingTerritoryListSelector,
  loadingTerritoryTreeSelector,
  territoryListSelector,
} from "../../../../redux/selectors/territory.js";

import {
  TerritoryItem,
  TerritoryComponent,
  TerritoriesTotalSummaries,
  TerritoryItemUncategorized,
} from "./components";
import { useAdmin } from "helpers/helpers";
import { success } from "utils/notifications";

const selector = createSelector(
  territoryListSelector,
  customersWithoutTerritorySelector,
  loadingTerritoryTreeSelector,
  loadingTerritoryListSelector,
  (
    territoryList,
    customersWithoutTerritory,
    territoryLoading,
    loadingTerritoryTree,
    loadingTerritoryList
  ) => ({
    territoryList,
    customersWithoutTerritory,
    territoryLoading,
    loadingTerritoryTree,
    loadingTerritoryList,
  })
);

const TerritoriesTab = ({
  handleChangedTerritory,
  territories,
  addNamedPathToTerritories,
  addNewTerritory,
}) => {
  const isAdmin = useAdmin();
  const {
    territoryList,
    customersWithoutTerritory,
    loadingTerritoryTree,
    loadingTerritoryList,
  } = useSelector(selector);

  const [lastIdOfMainTerritories, setLastIdOfMainTerritories] = useState(
    territories?.length > 0 ? territories[territories?.length - 1]?.id : []
  );
  const [collapseAll, setCollapseAll] = useState(true);

  const [isOpenTerritoryDialog, setIsOpenTerritoryDialog] = useState(false);
  const [profileTerritory, setProfileTerritory] = useState(null);
  const handleOpenTerritoryDialog = (territory) => {
    setProfileTerritory(territory);
    setIsOpenTerritoryDialog(true);
  };
  const handleCloseTerritoryDialog = () => {
    setIsOpenTerritoryDialog(false);
  };

  const lastChildren = (arr) => {
    return arr[arr.length - 1]?.children?.length > 0
      ? lastChildren(arr[arr.length - 1]?.children)
      : arr[arr.length - 1];
  };

  useEffect(() => {
    setLastIdOfMainTerritories(
      territories?.length > 0 ? territories[territories?.length - 1]?.id : 0
    );
  }, [territories]);

  const handleChangeNameTerritory = (id, newName) => {
    const findNested = (arr, id, name) => {
      let found = arr.find((node) => node.id === id);

      if (found) found.name = name;

      return found ? found : arr.find((c) => findNested(c.children, id, name));
    };

    const nestedEl = findNested(territories, id, newName);

    const newTerr = territories.map((x) => {
      if (x.id === nestedEl.id) {
        return nestedEl;
      }
      return x;
    });
    handleChangedTerritory({
      ter: addNamedPathToTerritories(newTerr),
      onSuccess: () => success("Territory updated"),
    });
  };

  const handleSearchTerritory = (territory) => {
    handleOpenTerritoryDialog(territory);
  };

  const territoriesRefs = useRef([]);

  const handleCollapse = (id) => {
    const collapseOnClick = territoriesRefs.current[id]?.props?.onClick;
    const isCollapsed =
      territoriesRefs.current[id]?.props?.children?.props?.isCollapsed;
    if (isCollapsed) collapseOnClick();
  };

  const handleAddTerritory = (id) => {
    const findNested = (arr, id) => {
      let found = arr.find((node) => node.id === id);

      const currentItem = arr.find((el) => el.id === id);
      let currentPath;

      if (currentItem) {
        currentPath = [...currentItem.path, currentItem.children?.length];
      }

      if (found)
        found.children.push({
          id: `temp-${Date.now()}`,
          name: "New territory",
          path: currentPath,
          parentPath: [...currentItem.path],
          children: [],
        });

      return found ? found : arr.find((c) => findNested(c.children, id));
    };

    const nestedEl = findNested(territories, id);
    const newTerr = territories.map((x) => {
      if (x.id === nestedEl.id) {
        return nestedEl;
      }
      return x;
    });

    handleChangedTerritory({
      ter: addNamedPathToTerritories(newTerr),
      handleCollapse: () => handleCollapse(id),
      onSuccess: () => success("Territory updated"),
    });
  };

  const renderItem = ({ item, collapseIcon, handler }) => {
    return (
      <>
        <div>{collapseIcon}</div>
        <TerritoryItem
          hasChildren={Boolean(item.children?.length)}
          lastTerritory={item?.id === lastIdOfMainTerritories}
          lastOfTerritories={lastChildren(territories)?.id === item?.id}
          handler={handler}
          territory={item}
          territories={territories}
          handleChangeNameTerritory={handleChangeNameTerritory}
          handleSearchTerritory={handleSearchTerritory}
          handleAddTerritory={handleAddTerritory}
          handleChangedTerritory={handleChangedTerritory}
          territoryLoading={loadingTerritoryTree || loadingTerritoryList}
          ref={() => (territoriesRefs.current[item.id] = collapseIcon)}
        />
      </>
    );
  };

  const styleShevronIcon = {
    position: "absolute",
    top: "5px",
    left: "59px",
    width: "36px",
    height: "36px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    borderRadius: "50%",
    ":hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  };
  const styleArrowsDnDIcon = {
    width: "16px",
    height: "36px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    ":hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  };

  const Handler = () => {
    return isAdmin ? (
      <Box width="16px" />
    ) : (
      <Box sx={styleArrowsDnDIcon}>
        <ArrowsDnDIcon />
      </Box>
    );
  };

  const Collapser = ({ isCollapsed }) => {
    return (
      <Box sx={styleShevronIcon}>
        {isCollapsed ? <ShevronRightIcon /> : <ShevronIcon color="#707070" />}
      </Box>
    );
  };
  Collapser.propTypes = { isCollapsed: bool };
  Collapser.defaultProps = { isCollapsed: true };

  return (
    <form id="territory-form" onSubmit={addNewTerritory}>
      <Box sx={{ px: 4 }}>
        <TerritoriesTotalSummaries
          totalTerritories={territoryList?.length}
          customersWithNoTerritory={customersWithoutTerritory?.count}
          collapseAll={collapseAll}
          handleCollapse={setCollapseAll}
        />
        <TerritoryItemUncategorized
          customersWithoutTerritory={customersWithoutTerritory?.count}
          handleSearchTerritory={handleSearchTerritory}
        />
        <Nestable
          items={territories}
          threshold={20}
          renderItem={renderItem}
          maxDepth={10}
          handler={<Handler />}
          collapsed={collapseAll}
          renderCollapseIcon={({ isCollapsed }) => (
            <Collapser isCollapsed={isCollapsed} />
          )}
          onChange={({ items }) => {
            if (isAdmin) return;
            handleChangedTerritory({
              ter: addNamedPathToTerritories(items),
              onSuccess: () => success("Territory updated"),
            });
          }}
        />
      </Box>

      <TerritoryComponent
        profile={profileTerritory}
        territories={territories}
        isOpenTerritoryDialog={isOpenTerritoryDialog}
        handleCloseTerritoryDialog={handleCloseTerritoryDialog}
      />
    </form>
  );
};
TerritoriesTab.propTypes = {
  handleChangedTerritory: func,
  territories: array,
  addNamedPathToTerritories: func,
  addNewTerritory: func,
};

export default TerritoriesTab;
