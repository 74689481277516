import * as React from "react";
export const NewPaperIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25.631}
    height={22.375}
    viewBox="0 0 25.631 22.375"
    {...props}
  >
    <path
      data-name="Path 5552"
      d="M3.6 3.6v-2a1 1 0 0 1 2 0v2a1 1 0 0 1-2 0Z"
      fill="none"
      stroke="#5f6267"
      strokeLinecap="round"
      strokeWidth={1.2}
    />
    <path
      data-name="Path 5553"
      d="M14.6 3.6v-2a1 1 0 0 1 2 0v2a1 1 0 0 1-2 0Z"
      fill="none"
      stroke="#5f6267"
      strokeLinecap="round"
      strokeWidth={1.2}
    />
    <path
      data-name="Path 5554"
      d="M8.6 8.178h8.144"
      fill="none"
      stroke="#5f6267"
      strokeLinecap="round"
      strokeWidth={1.2}
    />
    <path
      data-name="Path 5555"
      d="M6.337 11.577h8.357"
      fill="none"
      stroke="#5f6267"
      strokeLinecap="round"
      strokeWidth={1.2}
    />
    <path
      data-name="Path 5556"
      d="M10.444 14.976h5.807"
      fill="none"
      stroke="#5f6267"
      strokeLinecap="round"
      strokeWidth={1.2}
    />
    <path data-name="Path 5557" d="M14.6 2.266h-9v1.2h9Z" fill="#5f6267" />
    <path
      data-name="Path 5558"
      d="M16.6 3.466h2.388a25.36 25.36 0 0 0 5.29 14.593H6.959A25.041 25.041 0 0 1 1.211 3.466H3.6v-1.2H.005V21.35a1.025 1.025 0 0 0 1.025 1.025h18.13a1.025 1.025 0 0 0 1.02-1.025v-2.091h4.411a1.06 1.06 0 0 0 .8-1.734 24.092 24.092 0 0 1-5.207-14.244 1.032 1.032 0 0 0-1.029-1.015H16.6ZM1.2 9.977v11.2h17.78v-1.918H6.88a1.021 1.021 0 0 1-.763-.342A24.4 24.4 0 0 1 1.2 9.977Z"
      fill="#5f6267"
      fillRule="evenodd"
    />
  </svg>
);
