import { Box, Paper, Typography } from "@mui/material";

const InventoryTabHeaderList = () => {
  const labels = [
    {
      id: 1,
      name: "IMAGE",
      position: "center",
      visible: true,
      styles: { width: "8%" },
    },
    {
      id: 2,
      name: "PRODUCT",
      position: "flex-start",
      visible: true,
      styles: { width: "29.5%" },
    },
    {
      id: 3,
      name: "ON HAND",
      position: "flex-start",
      visible: true,
      styles: { width: "9.1%" },
    },
    {
      id: 4,
      name: "ALLOCATED",
      position: "flex-start",
      visible: true,
      styles: { width: "9%" },
    },
    {
      id: 5,
      name: "AVAILABLE",
      position: "flex-start",
      visible: true,
      styles: { width: "15.8%" },
    },
    {
      id: 6,
      name: "EDIT INVENTORY",
      position: "flex-start",
      visible: true,
      styles: { flexGrow: 1 },
    },
  ];

  const cl = {
    paper: {
      backgroundColor: "#EEEEEE",
      border: "1px solid #D5D9D9",
      borderRadius: "4px 4px 0 0",
      maxWidth: "1400px",
      height: "39px",
      display: "flex",
      alignItems: "center",
    },
    item: {
      display: "flex",
      alignItems: "center",
    },
  };

  return (
    <Paper sx={cl.paper} elevation={0} square>
      {labels.map((el) => (
        <Box
          key={el.id}
          sx={{
            ...cl.item,
            ...el.styles,
            justifyContent: el.position,
          }}
        >
          <Typography variant="header">{el.visible ? el.name : ""}</Typography>
        </Box>
      ))}
    </Paper>
  );
};

export default InventoryTabHeaderList;
