export const cl = {
  wrapper: {
    position: "relative",
    border: "0.5px solid #D5D9D9",
    borderRadius: "4px",
    width: "354px",
    height: "74px",
    bgcolor: "#FFF",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: 1,
    px: 2.75,
    boxShadow:
      "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)",
  },
  titleWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
};
