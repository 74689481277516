import { string, number, shape } from "prop-types";
import { Box, Typography } from "@mui/material";

export const CardPlanItem = ({ type, name, amount, state }) => {
  return (
    <Box
      sx={{
        borderBottom: type === "Current plan" && "0.5px solid #D5D9D9",
        height: "74px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Box>
        <Typography fontSize={12} fontWeight={300} color="#1C1C19">
          {type}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          opacity: type === "Current plan" ? 0.5 : 1,
        }}
      >
        <Typography fontSize={16} fontWeight={400} color="#5F6267">
          {name}
        </Typography>
        <Typography fontSize={16} fontWeight={600} color="#5E6268">
          ${amount}
          <Box fontSize={12} fontWeight={400} color="#5F6267" component="span">
            /mon
          </Box>
        </Typography>
      </Box>
      {!!state?.extra_user && type === "New plan" && (
        <Typography fontSize={12} fontWeight={400} color="#5F6267">
          + {state?.extra_user} extra users
        </Typography>
      )}
    </Box>
  );
};

CardPlanItem.propTypes = {
  type: string,
  name: string,
  amount: number,
  state: shape({
    extra_user: number,
    current_plan: shape({
      subscription: shape({
        name: string,
      }),
    }),
  }),
};
