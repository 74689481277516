import { useMemo } from "react";
import { array, func } from "prop-types";
import { useDispatch } from "react-redux";

import { Box, Button, MenuItem } from "@mui/material";

import { SquareSelect } from "components/Selects";
import { DangerIcon } from "components/Icons";

import { setConfirmIsOpenAction } from "redux/actions/confirmDialogs";
import { useAdmin } from "helpers/helpers";
import { useRepsPermissions } from "helpers/hooks";

export const BulkAction = ({
  checkedPhotos,
  handleDeletePhotos,
  handleDownloadPhotos,
  handleOpenConfirmDialog,
  handleCreateRoute,
  handleOpenTags,
}) => {
  const dispatch = useDispatch();
  const isAdmin = useAdmin();
  const repPermissions = useRepsPermissions();

  const disabledCreateRoute = useMemo(() => {
    if (repPermissions && !repPermissions?.routes?.create_edit) return true;

    return !checkedPhotos?.length;
  }, [checkedPhotos?.length, repPermissions]);

  return (
    <SquareSelect
      disabled={isAdmin}
      value=""
      label="Bulk Actions"
      sx={{ backgroundColor: "white" }}
      formSx={{ maxWidth: "125px" }}
      radiused
      unfocused
      leftSidedIcon
      fullWidth
    >
      <MenuItem onClick={handleCreateRoute} disabled={disabledCreateRoute}>
        Create route
      </MenuItem>
      <MenuItem
        value="Download photos"
        onClick={handleDownloadPhotos}
        disabled={!checkedPhotos.length}
      >
        Download photos
      </MenuItem>
      <MenuItem
        value="Edit Photo Tags"
        onClick={handleOpenTags}
        disabled={!checkedPhotos.length}
      >
        Edit Photo Tags
      </MenuItem>
      <MenuItem
        value="Delete"
        onClick={() =>
          handleOpenConfirmDialog({
            title: (
              <Box display="flex" alignItems="center">
                <DangerIcon />
                <span style={{ marginLeft: "11px" }}>Delete Photo(s)?</span>
              </Box>
            ),
            text: "Please confirm that you would like to delete selected photo(s). All data will be erased and this can't be undone.",
            buttons: (
              <>
                <Button
                  sx={{
                    width: "98px",
                    color: "#6A6A6A",
                    borderColor: "#D4D4D4",
                    fontSize: "13px",
                    height: "28px",
                  }}
                  onClick={() => {
                    dispatch(setConfirmIsOpenAction(false));
                  }}
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button
                  sx={{
                    width: "98px",
                    color: "#FFFFFF",
                    fontSize: "13px",
                    height: "28px",
                    boxShadow: "none",
                  }}
                  color="confirmDelete"
                  onClick={() => {
                    dispatch(setConfirmIsOpenAction(false));
                    handleDeletePhotos();
                  }}
                  variant="contained"
                >
                  Confirm
                </Button>
              </>
            ),
          })
        }
        disabled={!checkedPhotos.length}
        sx={{ color: "#FF6254" }}
      >
        Delete
      </MenuItem>
    </SquareSelect>
  );
};

BulkAction.propTypes = {
  checkedPhotos: array,
  handleDeletePhotos: func,
  handleOpenTags: func,
  handleDownloadPhotos: func,
  handleOpenConfirmDialog: func,
  handleCreateRoute: func,
};
BulkAction.defaultProps = {
  checkedPhotos: [],
  handleOpenTags: () => {},
  handleDeletePhotos: () => {},
  handleDownloadPhotos: () => {},
  handleOpenConfirmDialog: () => {},
};
