import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Chip, IconButton, Stack } from "@mui/material";
import { array, object } from "prop-types";
import { truncateText } from "helpers/helpers";
import useEmblaCarousel from "embla-carousel-react";
import {
  NextArrow,
  PrevArrow,
} from "Pages/CatalogPage/CatalogNewProductPage/ContentTabs/Arrows";

export const MapChipCarousel = ({ slides, routeItemsRef }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel();
  const [selectedIndex, setSelectedIndex] = useState(0);

  const canScrollPrev = useMemo(
    () => (emblaApi ? emblaApi?.canScrollPrev() : false),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [emblaApi, selectedIndex]
  );

  const canScrollNext = useMemo(
    () => (emblaApi ? emblaApi?.canScrollNext() : false),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [emblaApi, selectedIndex]
  );

  const scrollPrev = useCallback(() => {
    if (emblaApi) {
      emblaApi.scrollPrev();
      setSelectedIndex(emblaApi.selectedScrollSnap());
    }
  }, [emblaApi]);

  useEffect(() => {
    if (!emblaApi) return;
    emblaApi?.on("reInit", () => {
      setSelectedIndex(0);
    });
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) {
      emblaApi.scrollNext();
      setSelectedIndex(emblaApi.selectedScrollSnap());
    }
  }, [emblaApi]);

  return (
    <Stack sx={{ position: "relative", width: "100%" }}>
      <Box sx={{ overflow: "hidden", width: "100%" }} ref={emblaRef}>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          {slides?.map(({ route }, index) => {
            const isLastItem = index === slides.length - 1;
            return (
              <Box
                key={index}
                sx={{
                  flex: "0 0 auto",
                  marginRight: isLastItem ? 0 : "8px",
                  minWidth: "0px",
                  maxWidth: "100%",
                }}
                component="span"
                ref={(el) => {
                  routeItemsRef.current[index] = el;
                }}
              >
                <Chip
                  sx={{
                    border: "1px solid #D4D4D4",
                    bgcolor: "#FAFAFB",
                    fontSize: 14,
                    color: "#5F6368",
                    paddingBottom: "1px",
                    height: "24px",
                  }}
                  label={truncateText(route?.name, 25)}
                  size="small"
                />
              </Box>
            );
          })}
        </Box>
      </Box>
      <IconButton
        sx={{
          p: 0,
          position: "absolute",
          top: "8px",
          left: "-8px",
          transform: "translateY(-50%)",
          display: !canScrollPrev ? "none" : "block",
        }}
        onClick={scrollPrev}
        disableRipple
      >
        <PrevArrow width="4.5" height="7.9" color="#707070" />
      </IconButton>

      <IconButton
        onClick={scrollNext}
        sx={{
          p: 0,
          position: "absolute",
          top: "8px",
          right: "-8px",
          transform: "translateY(-50%)",
          display: !canScrollNext ? "none" : "block",
        }}
        disableRipple
      >
        <NextArrow width="4.5" height="7.9" color="#707070" />
      </IconButton>
    </Stack>
  );
};

MapChipCarousel.defaultProps = {
  slides: [],
};

MapChipCarousel.propTypes = {
  slides: array,
  routeItemsRef: object,
};
