import { useMemo, useState, useRef } from "react";
import { bool, func, string, object } from "prop-types";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Typography,
} from "@mui/material";
import { StyledSelect, LargeCheckbox } from "components";
import { CrossIcon } from "components/Icons";

export const GroupConfirmDialog = ({
  open,
  onClose,
  title,
  data,
  handleFetch,
  handleSave,
}) => {
  const [parentGroup, setParentGroup] = useState(null);
  const [billToParentCustomer, setBillToParentCustomer] = useState(false);
  const selectRef = useRef();

  const {
    count = 0,
    customer = {},
    customers = [],
    list = [],
    loading = false,
    params = {},
  } = data || {};

  const currentGroupName = useMemo(() => {
    if (customer?.parentCustomer?.name) {
      return customer?.parentCustomer?.name;
    }

    if (customers?.length) {
      const setUncategorized = (name) => (name === "" ? "Uncategorized" : name);

      const items = [
        ...new Set(
          customers.map((el) =>
            setUncategorized(el ? el?.parentCustomer?.name : "")
          )
        ),
      ];

      const groupNames = [...items];

      if (groupNames.length === 1) return groupNames[0];

      if (groupNames.length > 1) return "Multiple choice";
    }

    return "";
  }, [customer?.parentCustomer?.name, customers]);

  return (
    <Dialog
      open={open}
      sx={{
        "& .MuiPaper-root": {
          boxShadow: "none",
          width: "100%",
          maxWidth: "420px",
          minWidth: "420px",
        },
      }}
    >
      <DialogTitle
        sx={{
          fontSize: "20px",
          color: "#080808",
          fontWeight: 400,
          p: "12px 30px 10px 30px",
          m: "0 0 1 0",
          position: "relative",
        }}
      >
        <Box display="flex" alignItems="center">
          {title}
        </Box>
        <IconButton
          sx={{ position: "absolute", right: 4, top: 5 }}
          onClick={onClose}
        >
          <CrossIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          p: "16px 25px 20px 30px",
          maxWidth: "484px",
          minWidth: "420px",
          borderBottom: "0.5px solid #D5D9D9",
        }}
      >
        <Box>
          <Typography fontSize="12px" color="#363531">
            Current group:
            {
              <span
                style={{
                  marginLeft: "10px",
                  fontWeight: 400,
                }}
              >
                {currentGroupName}
              </span>
            }
          </Typography>

          <StyledSelect
            disabled={loading}
            fullWidth
            label="Select customer group"
            defaultValue=""
            height="49px"
            fontSize="13px"
            color="#363531"
            labelSx={{
              fontSize: "13px",
              mt: "5px",

              "&.MuiInputLabel-root.Mui-focused": {
                mt: "2px",
              },

              "&.MuiFormLabel-filled": {
                mt: "2px",
              },
            }}
            formSx={{ mt: "12px" }}
            dataCount={count}
            dataLength={list?.length}
            handleFetch={() => handleFetch(params?.page + 1)}
            onChange={(e) => {
              setParentGroup(e.target.value);
            }}
            ref={selectRef}
          >
            <MenuItem
              value={customer?.parentCustomer?.id}
              sx={{ display: "none" }}
            >
              <Box display="flex" flexDirection="column">
                <Typography fontSize={13} fontWeight={600} color="#5F6267">
                  {customer?.parentCustomer?.displayName ||
                    customer?.parentCustomer?.name}
                </Typography>
                <Typography fontSize={13} fontWeight={400} color="#5F6267">
                  {customer?.parentCustomer?.shippingAddress
                    ?.formatted_address ||
                    customer?.parentCustomer?.billingAddress?.formatted_address}
                </Typography>
              </Box>
            </MenuItem>

            {list?.map((item) => {
              return (
                <MenuItem
                  key={item.id}
                  value={item.id}
                  // onClick={() => setParentGroup(item)}
                  sx={{
                    width: selectRef.current.offsetWidth,
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    sx={{ overflowX: "hidden" }}
                  >
                    <Typography
                      fontSize={13}
                      fontWeight={600}
                      color="#5F6267"
                      noWrap
                      sx={{
                        textOverflow: "ellipsis",
                      }}
                    >
                      {item?.displayName || item?.name}
                    </Typography>
                    <Typography
                      fontSize={13}
                      fontWeight={400}
                      color="#5F6267"
                      noWrap
                      sx={{
                        textOverflow: "ellipsis",
                      }}
                    >
                      {item?.isUncategorized
                        ? ""
                        : item?.shippingAddress?.formatted_address}
                    </Typography>
                  </Box>
                </MenuItem>
              );
            })}
          </StyledSelect>
        </Box>
        <LargeCheckbox
          size={16}
          formSx={{ pl: 0.4 }}
          label={
            <Typography
              variant="caption"
              sx={{
                fontSize: 13,
                fontWeight: 400,
                color: "#5F6267",
                letterSpacing: "normal",
              }}
            >
              Bill parent group
            </Typography>
          }
          onChange={() => setBillToParentCustomer((prev) => !prev)}
          checked={billToParentCustomer}
        />
      </DialogContent>

      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "14px 25px 15px 30px",
        }}
      >
        <Button
          sx={{
            width: "98px",
            color: "#6A6A6A",
            borderColor: "#D4D4D4",
            fontSize: "13px",
            height: "28px",
          }}
          onClick={onClose}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          disabled={loading || !parentGroup}
          sx={{
            width: "98px",
            color: "#FFFFFF",
            fontSize: "13px",
            height: "28px",
            boxShadow: "none",
          }}
          color="primary"
          onClick={() =>
            handleSave({ parentCustomerId: parentGroup, billToParentCustomer })
          }
          variant="contained"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

GroupConfirmDialog.propTypes = {
  open: bool,
  onClose: func,
  title: string,
  data: object,
  handleFetch: func,
  handleSave: func,
};
GroupConfirmDialog.defaultProps = {
  open: false,
  onClose: () => {},
  title: "Change customer group?",
  data: {},
  handleFetch: () => {},
  handleSave: () => {},
};
