import { useContext, useState } from "react";
import { func } from "prop-types";
import { Box, Grid, Typography } from "@mui/material";
import { StyledButton } from "components";
import { CustomerContext } from "Pages/CustomersPage/pages/CustomerPage/CustomerPage";
import { useWatch } from "react-hook-form";
import moment from "moment-timezone";

export const ReceivingHoursBlock = ({ onEdit, onRemove }) => {
  const { control } = useContext(CustomerContext);
  const formField = useWatch({ control });
  const { weekDays } = formField || {};

  const [hoveredElement, setHoveredElement] = useState(false);

  return (
    <Grid
      sx={{
        border: "0.5px solid #D5D9D9",
        borderRadius: "4px",
        bgcolor: "#F8F8F8",
        mb: 1.75,
        position: "relative",
      }}
      p="10px 0px 8px 12px"
      container
      onMouseEnter={() => setHoveredElement(true)}
      onMouseLeave={() => setHoveredElement(false)}
    >
      <Grid xs={10} item>
        {Object.values(weekDays).map((day) => (
          <Grid key={day?.name} xs={12} item container>
            <Box width="100px">
              <Typography fontSize={13} fontWeight={500} color="#5F6267">
                {day?.name}
              </Typography>
            </Box>
            {day?.value?.clockInAt && day?.value?.clockOutAt ? (
              <Typography fontSize={13} fontWeight={500} color="#B8B8B8">
                {moment(day?.value?.clockInAt).utc().format("h:mm a")}
                {" - "}
                {moment(day?.value?.clockOutAt).utc().format("h:mm a")}
              </Typography>
            ) : (
              <Typography fontSize={13} fontWeight={500} color="#FF6969">
                Closed
              </Typography>
            )}
          </Grid>
        ))}
      </Grid>

      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          opacity: hoveredElement ? 1 : 0,
        }}
        xs={2}
        item
      >
        <StyledButton
          sx={{
            mt: 1,
            borderColor: "#D4D4D4",
            color: "#000",
            maxWidth: "55px",
            minWidth: "55px",
            height: "20px",
            paddingLeft: "0px",
            paddingRight: "0px",
          }}
          label="Edit"
          variant="outlined"
          color="ground"
          fontSize="10px"
          fontWeight="500"
          onClick={() => onEdit()}
        />
        <StyledButton
          sx={{
            mt: 1,
            borderColor: "#D4D4D4",
            color: "#000",
            maxWidth: "55px",
            minWidth: "55px",
            height: "20px",
            paddingLeft: "0px",
            paddingRight: "0px",
          }}
          label="Remove"
          variant="outlined"
          color="ground"
          fontSize="10px"
          fontWeight="500"
          onClick={() => onRemove()}
        />
      </Grid>
    </Grid>
  );
};

ReceivingHoursBlock.propTypes = { onEdit: func, onRemove: func };
