export const defaultFilters = {
  manufacturer_id: "",
  representative_duplicate_id: "",
  reorder_required: "",
  date: "",
  tags: [],
};

export const FILTER_SWITCHES = [
  {
    value: "date",
    label: "Date",
    type: "date",
    checked: false,
    selectLabel: "Select Date",
  },
  {
    value: "manufacturer_id",
    label: "Manufacturer",
    type: "select",
    checked: false,
    selectLabel: "Select Manufacturer",
  },
  {
    value: "representative_duplicate_id",
    label: "Representative",
    type: "select",
    checked: false,
    selectLabel: "Select Representative",
  },
  {
    value: "reorder_required",
    label: "Order Required",
    type: "switch",
    checked: false,
    values: ["yes"],
  },
  {
    value: "tags",
    label: "Tags",
    type: "multiselect",
    checked: false,
    selectLabel: "Select tags",
  },
];
