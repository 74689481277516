import { useCallback, useState, useMemo, useRef } from "react";
import { object, func, string, bool } from "prop-types";
import { useSelector } from "react-redux";
import { Controller, useWatch } from "react-hook-form";
import NumberFormat from "react-number-format";

import {
  Avatar,
  Grid,
  Link,
  MenuItem,
  Typography,
  Button,
  Box,
} from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

import {
  StyledButton,
  UploadFile,
  StyledTextField,
  StyledSelect,
  Loader,
  StyledPopper,
  StyledTooltip,
} from "components/";
import { ActiveIcon, CrossIcon } from "components/Icons";

import {
  getFormattedDate,
  useAdmin,
  photoUrl,
  stringAvatar,
  onPastePhone,
} from "helpers/helpers";
import { ROLES } from "./TabProfile.constants";

import {
  distributorResetRepEmailService,
  distributorResetRepPasswordService,
  logoutRepsByIdService,
} from "services/reps.js";
import { success, error } from "utils/notifications.js";
import { CheckboxComponent } from "./components";
import { TYPES_CONFIRM_DIALOG } from "../useProfileComponent";

const useStyles = makeStyles(() => ({
  avatar: {
    width: 70,
    height: 70,
  },

  profilePhoto: {
    objectFit: "cover",
    width: "100%",
    height: "100%",
  },

  textInput: {
    fontSize: "12px",
    height: "32px",
    // "& > .Mui-disabled": {
    //   color: "#707070",
    //   WebkitTextFillColor: "unset",
    // },
  },

  inputLabel: {
    fontSize: "12px",
    "& > .Mui-disabled": {
      color: "red",
      WebkitTextFillColor: "unset",
    },
  },
  tooltipPhoto: {
    "& .MuiTooltip-tooltip": {
      transform: "translate(-30px, 0px)!important",
      boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
      backgroundColor: "#fff",
      whiteSpace: "pre-wrap",
      textAlign: "center",
    },

    "& .MuiTooltip-arrow": {
      "&:before": {
        backgroundColor: "#fff",
      },
    },
  },
}));

const TabProfileComponent = ({
  profile,
  timeZone,
  control,
  setValue,
  currentTab,
  handleRemovePhoto,
  setProgressPhotoUpload,
  preparingPhoto,
  setPreparingPhoto,
  repPermissions,
  handleConfirmDialog,
}) => {
  const isAdmin = useAdmin();
  const classes = useStyles();

  const repsLoading = useSelector(({ reps }) => reps.loading);
  const repsCurrentTab = useSelector(({ reps }) => reps.currentTab);
  // const currentUser = useSelector(({ auth }) => auth.currentUser);

  const [resetPassClicked, setResetPassClicked] = useState(false);
  const [resetEmailClicked, setEmailClicked] = useState(false);

  const formField = useWatch({ control });

  const [isCroppedDialogOpen, setIsCroppedDialogOpen] = useState(false);

  const tooltipRef = useRef(null);

  const [tooltipData, setTooltipData] = useState({
    open: false,
    text: (
      <>
        <Typography textAlign="start" fontSize={12}>
          • Use at least 500 px by 500 px <br /> • Use white or neutral
          background
        </Typography>
      </>
    ),
    modifiers: [{ name: "offset", options: { offset: [5, 10] } }],
  });

  const handleUploadChange = useCallback(
    (data) => {
      if (isAdmin) return;
      setValue("profilePhoto", data?.fileName);
    },
    [isAdmin, setValue]
  );

  const profilePhoto = useMemo(() => {
    if (profile?.avatar) return profile?.avatar;
    if (formField?.profilePhoto?.fileName)
      return photoUrl(formField?.profilePhoto?.fileName);
    if (typeof formField?.profilePhoto === "string")
      return formField?.profilePhoto;
    return "";
  }, [profile?.avatar, formField?.profilePhoto]);

  const handleLogoutRepsById = (e) => {
    if (isAdmin) return;
    e.preventDefault();
    logoutRepsByIdService(formField?.id)
      .then(() => success("Representative successfully logged out!"))
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err?.message);
        error("Something went wrong.");
      });
  };

  const handleResetPassword = (e) => {
    if (isAdmin) return;
    setResetPassClicked(true);
    e.preventDefault();
    distributorResetRepPasswordService(profile?.id)
      .then(() => success("Password reset link sent to representative!"))
      .catch(() => {
        error("Something went wrong.");
      });
  };

  const handleResetEmail = (e) => {
    if (isAdmin) return;
    setEmailClicked(true);
    e.preventDefault();
    distributorResetRepEmailService(profile?.id)
      .then(() => success("Confirmation email successfully send!"))
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        if (
          err?.response?.data?.message ===
          "Representative profile already confirmed"
        ) {
          return success("Representative account is already confirmed.");
        }

        error(err?.response?.data?.message || "Something went wrong.");
      });
  };

  // const hasRepsLimitBeenReached = useMemo(() => {
  //   const allowedUsers =
  //     currentUser?.distributorSubscription?.allowedUsers || 5;
  //   const extraAllowedUsers =
  //     currentUser?.distributorSubscription?.extraUser?.extraAllowedUsers || 0;

  //   const usedSubscriptions = currentUser?.countRepresentatives || 0;
  //   return !(usedSubscriptions < allowedUsers + extraAllowedUsers);
  // }, [
  //   currentUser?.countRepresentatives,
  //   currentUser?.distributorSubscription?.allowedUsers,
  //   currentUser?.distributorSubscription?.extraUser?.extraAllowedUsers,
  // ]);

  return (
    // <form id="repr-profile-form" onSubmit={handleSubmit(onSubmit)}>
    <>
      <Loader isLoading={repsLoading} />
      <Grid item>
        <Box display="flex" sx={{ mt: "36px", pl: "60px" }}>
          {profile || formField.profilePhoto ? (
            profilePhoto ? (
              <Avatar className={classes.avatar}>
                <Box
                  component="img"
                  className={classes.profilePhoto}
                  src={profilePhoto}
                />
              </Avatar>
            ) : (
              <Avatar
                className={classes.avatar}
                {...stringAvatar(profile.name)}
              />
            )
          ) : (
            <Avatar alt="" src="" sx={{ width: 70, height: 70 }} />
          )}
          <Box
            sx={{
              pl: "10px",
              pt: (currentTab || repsCurrentTab) === "3rd Party" && "8px",
            }}
          >
            {(currentTab !== "3rd Party" || repsCurrentTab !== "3rd Party") &&
              profile && (
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#1C1C19",
                    ml: "10px",
                    mb: "10px",
                  }}
                >
                  Joined on{" "}
                  {getFormattedDate(profile.createdAt, timeZone).split(",")[0]}
                </Typography>
              )}
            <StyledPopper
              open={tooltipData.open && !isCroppedDialogOpen}
              anchorEl={tooltipRef.current}
              text={tooltipData.text}
              modifiers={tooltipData.modifiers}
              placement="top"
              transition
              aria-hidden="true"
              style={{ zIndex: 1300 }}
            />
            {isAdmin ? null : (
              <Box
                component="span"
                ref={tooltipRef}
                onMouseEnter={() =>
                  setTooltipData((prev) => ({ ...prev, open: true }))
                }
                onMouseLeave={() =>
                  setTooltipData((prev) => ({ ...prev, open: false }))
                }
                onMouseDown={() =>
                  setTooltipData((prev) => ({ ...prev, open: false }))
                }
              >
                <UploadFile
                  label="Upload new photo"
                  onChange={handleUploadChange}
                  accept="image/png, image/gif, image/jpeg"
                  Wrapper={Button}
                  withCropper
                  dialogSize="small"
                  settingsCropper={{ cropShape: "round" }}
                  uploadData={{
                    type: "PROFILE_REPRESENTATIVE",
                    setGroupContentId: (groupContentId) => {
                      if (groupContentId)
                        setValue("groupContentId", groupContentId);
                    },
                  }}
                  setIsOpen={setIsCroppedDialogOpen}
                  progressUpload={setProgressPhotoUpload}
                  {...{ preparingPhoto, setPreparingPhoto }}
                />
              </Box>
            )}
            {profile?.profilePhoto || formField.profilePhoto ? (
              <>
                {isAdmin ? null : "•"}
                {isAdmin ? null : (
                  <StyledButton
                    sx={{
                      p: 0,
                      fontWeight: "400",
                      ml: "8px",
                    }}
                    label={
                      <Typography fontSize="14px">Remove photo</Typography>
                    }
                    onClick={() => {
                      if (isAdmin) return;
                      handleRemovePhoto();
                    }}
                  />
                )}
              </>
            ) : (
              ""
            )}
            <Typography
              sx={{
                fontSize: "10px",
                fontWeight: "400",
                color: "#707070",
                ml: "8px",
              }}
            >
              Photos help recognize your employees
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid container>
        <Grid item xs>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            maxWidth="430px"
            height="230px"
            sx={{ pl: "60px", mt: "33px" }}
          >
            <Box display="flex" justifyContent="space-between">
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <StyledTextField
                    fullWidth
                    formSx={{
                      maxWidth: "70%",
                    }}
                    size="small"
                    InputProps={{
                      className: classes.textInput,
                    }}
                    InputLabelProps={{
                      className: classes.inputLabel,
                    }}
                    label="Full name *"
                    error={error ? error.message : ""}
                    {...field}
                  />
                )}
                name="name"
                control={control}
              />

              <Controller
                render={({ field, fieldState: { error } }) => (
                  <StyledTextField
                    formSx={{
                      maxWidth: "26%",
                    }}
                    size="small"
                    fullWidth
                    InputProps={{
                      className: classes.textInput,
                    }}
                    InputLabelProps={{
                      className: classes.inputLabel,
                    }}
                    error={error ? error.message : ""}
                    label="ID"
                    {...field}
                  />
                )}
                name="customId"
                control={control}
              />
            </Box>

            <Controller
              render={({ field, fieldState: { error } }) => (
                <StyledSelect
                  // disabled={hasRepsLimitBeenReached}
                  labelId="select-label-role"
                  label="Role *"
                  labelSx={{ "&.Mui-focused": { paddingLeft: 0 } }}
                  fullWidth
                  leftSidedIcon
                  error={error ? error.message : ""}
                  {...field}
                  onChange={(e) => {
                    const v = e.target.value;
                    const currentRole = formField?.role;

                    if (
                      currentRole === "SALES" &&
                      v === "MERCHANDISER" &&
                      formField?.portalAccess
                    ) {
                      handleConfirmDialog({
                        type: TYPES_CONFIRM_DIALOG.role_change,
                        repName: formField?.name || "current representative",
                        onConfirm: () => field.onChange(e),
                      });
                      return;
                    }

                    if (
                      currentRole === "MERCHANDISER" &&
                      v === "SALES" &&
                      formField?.portalAccess
                    ) {
                      handleConfirmDialog({
                        type: TYPES_CONFIRM_DIALOG.role_change,
                        repName: formField?.name || "current representative",
                        onConfirm: () => field.onChange(e),
                      });
                      return;
                    }

                    field.onChange(e);
                  }}
                >
                  {ROLES.map((role) => {
                    return (
                      <MenuItem
                        key={role.key}
                        value={role.key}
                        disabled={
                          profile
                            ? (role.key === "THIRD_PARTY" &&
                                profile?.role !== "THIRD_PARTY") ||
                              (role.key !== "THIRD_PARTY" &&
                                profile?.role === "THIRD_PARTY")
                            : false
                        }
                      >
                        {role.value}
                      </MenuItem>
                    );
                  })}
                </StyledSelect>
              )}
              name="role"
              control={control}
            />
            <Controller
              render={({ field, fieldState: { error } }) => (
                <NumberFormat
                  customInput={StyledTextField}
                  size="small"
                  fullWidth
                  InputProps={{
                    className: classes.textInput,
                  }}
                  InputLabelProps={{
                    className: classes.inputLabel,
                  }}
                  error={error ? error.message : ""}
                  label={
                    ![formField?.role, profile?.role].includes("THIRD_PARTY")
                      ? "Phone number *"
                      : "Phone number"
                  }
                  format="+# (###) ### ####"
                  mask="_"
                  onClick={() => {
                    if (["", "+"].includes(field?.value)) {
                      setValue("phone", "+1");
                    }
                  }}
                  {...field}
                  autoComplete="new-password"
                  onPaste={(e) => {
                    onPastePhone({
                      e,
                      setValue,
                      currentValue: formField.phone,
                    });
                  }}
                />
              )}
              name="phone"
              control={control}
            />
            <Controller
              render={({ field, fieldState: { error } }) => (
                <StyledTextField
                  sx={{
                    backgroundColor:
                      !!profile?.id && "rgba(212, 212, 212, 0.1)",
                  }}
                  size="small"
                  label="Email *"
                  InputProps={{
                    className: classes.textInput,
                    endAdornment:
                      !!profile?.id &&
                      (currentTab !== "3rd Party" ||
                        repsCurrentTab !== "3rd Party") &&
                      (profile?.baseUser?.isConfirmed ? (
                        <ActiveIcon />
                      ) : profile?.role === "THIRD_PARTY" ? null : (
                        <StyledTooltip
                          isDark
                          arrow
                          title={
                            <Box textAlign="left">
                              <Typography fontSize={13} color="#FFF">
                                Rep&apos;s email has not been verified yet.
                                <br /> They must verify their email to gain{" "}
                                <br />
                                access.{" "}
                                <span
                                  onClick={(e) => {
                                    if (repPermissions) return;
                                    handleResetEmail(e);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                    fontWeight: 600,
                                    fontSize: "13px",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  Resend Verification
                                </span>
                              </Typography>
                            </Box>
                          }
                          placement="top"
                          PopperProps={{
                            modifiers: [
                              { name: "offset", options: { offset: [0, -4] } },
                            ],
                          }}
                        >
                          <Box height="24px">
                            <CrossIcon fill="#EB4F4F" opacity="1" />
                          </Box>
                        </StyledTooltip>
                      )),
                  }}
                  InputLabelProps={{
                    className: classes.inputLabel,
                  }}
                  disabled={!!profile?.id && !(profile?.role === "THIRD_PARTY")}
                  error={error ? error.message : ""}
                  {...field}
                />
              )}
              name="email"
              control={control}
            />

            {/* {["SALES", "MERCHANDISER"].includes(formField?.role) ? (
              <Box>
                <CheckboxComponent
                  type="portalAccess"
                  label="Activate portal access"
                  disabled={isAdmin}
                  isAdmin={isAdmin}
                  control={control}
                  checkedColor={
                    !profile?.id ? "rgba(71, 160, 110, 0.5)" : "#47a06d"
                  }
                />
              </Box>
            ) : (
              <Box />
            )} */}
          </Box>
        </Grid>
        <Grid
          item
          xs
          sx={{
            borderLeft: "1px solid #D4D4D4",
            display: "flex",
            flexDirection: "column",
            justifyContent:
              ![formField?.role, profile?.role].includes("THIRD_PARTY") &&
              "space-evenly",
            pt:
              [formField?.role, profile?.role].includes("THIRD_PARTY") &&
              "11px",
          }}
        >
          <Box
            sx={{
              pl: "64px",
              pr: "36px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: "500",
                    color: "#707070",
                  }}
                  component="span"
                >
                  Status
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 400,
                    color: "#707070",
                  }}
                  component="span"
                >
                  Current status of the rep
                </Typography>
              </Box>
              <CheckboxComponent
                type="active"
                label="Active"
                disabled={isAdmin || !profile?.id}
                isAdmin={isAdmin}
                control={control}
                checkedColor={
                  !profile?.id || isAdmin
                    ? "rgba(71, 160, 110, 0.5)"
                    : "#47a06d"
                }
              />
            </Box>
          </Box>
          {/* {profile?.role !== "THIRD_PARTY" ? ( */}
          {![formField?.role, profile?.role].includes("THIRD_PARTY") ? (
            <>
              <Box sx={{ p: "10px 36px 0 64px" }}>
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: "500",
                    color: "#707070",
                  }}
                  component="span"
                >
                  Security
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 400,
                      color: "#707070",
                    }}
                    component="span"
                  >
                    Log out of all sessions
                  </Typography>
                  {isAdmin ? null : (
                    <Link
                      sx={{
                        fontSize: "14px",
                        fontWeight: 400,
                        color: profile ? "#707070" : "#BCBCBC",
                        pointerEvents: !profile && "none",
                      }}
                      underline="hover"
                      component="button"
                      onClick={(e) => {
                        if (isAdmin) return;
                        handleLogoutRepsById(e);
                      }}
                      disabled={isAdmin || !profile}
                    >
                      Log out
                      <ArrowForwardIos
                        sx={{
                          width: "14px",
                          height: "14px",
                          ml: "12px",
                          pt: "4px",
                        }}
                      />
                    </Link>
                  )}
                </Box>
              </Box>

              <Box sx={{ p: "10px 36px 0 64px" }}>
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: "500",
                    color: "#707070",
                  }}
                  component="span"
                >
                  Password
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 400,
                      color: "#707070",
                    }}
                    component="span"
                  >
                    Send email to reset password
                  </Typography>
                  {isAdmin ? null : (
                    <Link
                      component="button"
                      underline="hover"
                      sx={{
                        fontSize: "14px",
                        fontWeight: 400,
                        pointerEvents: !profile && "none",
                        color: profile ? "#707070" : "#BCBCBC",
                      }}
                      onClick={(e) => {
                        if (isAdmin) return;
                        handleResetPassword(e);
                      }}
                      disabled={isAdmin || !profile}
                    >
                      {resetPassClicked ? "Resend" : "Send"}
                      <ArrowForwardIos
                        sx={{
                          width: "14px",
                          height: "14px",
                          ml: "12px",
                          pt: "4px",
                        }}
                      />
                    </Link>
                  )}
                </Box>
              </Box>
              {!profile?.baseUser?.isConfirmed && (
                <Box sx={{ p: "10px 36px 0 64px" }}>
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: "500",
                      color: "#707070",
                    }}
                    component="span"
                  >
                    Email
                  </Typography>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 400,
                        color: "#707070",
                      }}
                      component="span"
                    >
                      Resend account confirmation email
                    </Typography>
                    {isAdmin ? null : (
                      <Link
                        component="button"
                        underline="hover"
                        sx={{
                          fontSize: "14px",
                          fontWeight: 400,
                          pointerEvents: !profile && "none",
                          color: profile ? "#707070" : "#BCBCBC",
                        }}
                        onClick={(e) => {
                          if (isAdmin) return;
                          handleResetEmail(e);
                        }}
                        disabled={isAdmin || !profile}
                      >
                        {resetEmailClicked ? "Resend" : "Send"}
                        <ArrowForwardIos
                          sx={{
                            width: "14px",
                            height: "14px",
                            ml: "12px",
                            pt: "4px",
                          }}
                        />
                      </Link>
                    )}
                  </Box>
                </Box>
              )}
            </>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
      {/* </form> */}
    </>
  );
};

TabProfileComponent.propTypes = {
  profile: object,
  timeZone: string,
  control: object,
  setValue: func,
  handleSubmit: func,
  currentTab: string,
  handleRemovePhoto: func,
  setProgressPhotoUpload: func,
  preparingPhoto: bool,
  setPreparingPhoto: func,
  repPermissions: object,
  handleConfirmDialog: func,
};

TabProfileComponent.defaultProps = {
  profile: null,
  handleRemovePhoto: () => {},
  handleConfirmDialog: () => {},
};

export default TabProfileComponent;
