import { number } from "prop-types";
import { Box, Rating, Typography } from "@mui/material";

export const RatingItem = ({ rating, reviews, price }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {!!rating && (
        <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
          <Typography fontSize={13} color="#707070">
            {rating}
          </Typography>
          <Rating
            value={rating}
            readOnly
            precision={0.1}
            size="small"
            sx={{ fontSize: "12px" }}
          />
        </Box>
      )}

      {!!reviews && (
        <Typography ml={0.5} fontSize={13} color="#707070">
          ({reviews})
        </Typography>
      )}

      {!!price && (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography mx={0.5} fontSize={13} color="#707070">
            •
          </Typography>
          <Typography fontSize={13} color="#707070">
            {Array(price).fill("$").join("")}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

RatingItem.propTypes = {
  rating: number,
  reviews: number,
  price: number,
};
RatingItem.defaultProps = {
  rating: 0,
  reviews: 0,
  price: 0,
};
