import { bool, string } from "prop-types";
import { Box, Typography } from "@mui/material";

export const TotalBlock = ({ hasThirdParty, calcTotal, isFreeCase }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        height: "100%",
        // minWidth: !hasThirdParty ? "7.4%" : "12.4%",
        // width: !hasThirdParty ? "7.4%" : "12.4%",
        minWidth: !hasThirdParty ? "9.4%" : "14.4%",
        width: !hasThirdParty ? "9.4%" : "14.4%",
      }}
    >
      <Typography sx={{ fontSize: 13, color: "#1C1C19" }} noWrap>
        {isFreeCase ? "Free" : calcTotal}
      </Typography>
    </Box>
  );
};

TotalBlock.propTypes = {
  hasThirdParty: bool,
  calcTotal: string,
  isFreeCase: bool,
};

TotalBlock.defaultProps = { isFreeCase: false };
