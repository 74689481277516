import {
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Typography,
  Box,
} from "@mui/material";
import { bool, func, object, string } from "prop-types";
import { useCallback } from "react";
import { CrossIcon } from "../../Icons";
import StyledButton from "../../StyledButton";

const ContactDeletePopup = ({
  type,
  isOpen,
  contact,
  handleClose,
  handleDeleteContact,
}) => {
  const onSubmit = useCallback(() => {
    handleDeleteContact(contact, true);
    handleClose();
  }, [handleDeleteContact, handleClose, contact]);

  return (
    <Dialog open={isOpen} scroll="body">
      <Box>
        <IconButton
          sx={{ position: "absolute", top: 14, right: 14, p: 0 }}
          onClick={handleClose}
        >
          <CrossIcon />
        </IconButton>
        <Typography
          p="10px 30px 10px 22px"
          fontWeight="400"
          fontSize="20px"
          color="#080808"
          letterSpacing="0.5px"
          marginBottom="8px"
          borderBottom="0.5px solid #CCCCCC"
        >
          Delete contact?
        </Typography>
        {contact ? (
          <Typography
            p="5px 0 16px 22px"
            fontWeight="light"
            fontSize="15px"
            color="#707070"
            maxWidth="420px"
          >
            {`Please confirm that you would like to delete ${type} contact `}
            <span style={{ color: "#47A06D" }}>
              {contact.name || contact.customerName}
            </span>
            ?
          </Typography>
        ) : (
          `Unable to delete a ${type}`
        )}
      </Box>

      <DialogContent sx={{ p: "10px 22px 0 47px", minWidth: "413px" }}>
        <Grid display="flex" sx={{ flexDirection: "row-reverse" }} container>
          <StyledButton
            color="confirmDelete"
            sx={{
              height: 28,
              width: 85,
              mb: "13px",
              mr: "5px",
              boxShadow: "none",
            }}
            onClick={onSubmit}
            label={
              <Typography fontSize="13px" fontWeight="normal">
                Confirm
              </Typography>
            }
            variant="contained"
          />
          <StyledButton
            sx={{ height: 28, width: 85, mr: "10px", borderColor: "#D4D4D4" }}
            onClick={handleClose}
            label={
              <Typography
                fontSize="13px"
                sx={{
                  color: "#6A6A6A",
                }}
                fontWeight="normal"
              >
                Cancel
              </Typography>
            }
            variant="outlined"
          />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

ContactDeletePopup.propTypes = {
  isOpen: bool,
  handleClose: func,
  handleDeleteContact: func,
  contact: object,
  type: string,
};

ContactDeletePopup.defaultProps = {
  isOpen: false,
  defaultContact: "",
  type: "customer",
};

export default ContactDeletePopup;
