import { Grid, Typography } from "@mui/material";
import { HEADER_ITEMS } from "./PayoutsHeader.constants";
import useStyles from "./styles";

const PayoutsHeader = () => {
  const classes = useStyles();

  return (
    <Grid className={classes.headerWrapper} container>
      {HEADER_ITEMS.map(({ name, size, styles }) => (
        <Grid
          key={name}
          sx={{ ...styles }}
          xs={size}
          item
          component={Typography}
          fontSize="12px"
        >
          {name}
        </Grid>
      ))}
    </Grid>
  );
};

export default PayoutsHeader;
