import React from "react";

export const OrdersIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="10"
      viewBox="0 0 11 10"
    >
      <path
        id="Union_41"
        data-name="Union 41"
        d="M.612,10V3.125h9.779V10ZM4.279,5.624H6.723V4.376H4.279ZM0,2.5V0H11V2.5Z"
        fill="#3f3f3f"
      />
    </svg>
  );
};
