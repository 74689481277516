export const UPDATE_SYNC = "UPDATE_SYNC";
export const ADD_SYNC = "ADD_SYNC";

export const updateSyncStatusesAction = (payload) => ({
  type: UPDATE_SYNC,
  payload,
});

export const addSyncStatusAction = (payload) => ({
  type: ADD_SYNC,
  payload,
});
