export const BookIcon = () => {
  return (
    <svg
      id="Icon_ionic-md-book"
      data-name="Icon ionic-md-book"
      xmlns="http://www.w3.org/2000/svg"
      width="62.405"
      height="52.801"
      viewBox="0 0 62.405 52.801"
    >
      <path
        id="Icon_ionic-md-book-2"
        data-name="Icon ionic-md-book"
        d="M60.11,5.683l-25.532,4.8L9.045,5.683c-3.27-.51-5.67,2.535-5.67,5.64V48c0,3.105,2.4,4.89,5.67,5.64l25.532,4.785,25.532-4.8c3.27-.75,5.67-2.535,5.67-5.64V11.323C65.78,8.218,63.38,5.173,60.11,5.683Zm0,42.3-22.682,4.8V16.109l22.682-4.8Zm-28.382,4.8-22.682-4.8V11.323l22.682,4.8Z"
        transform="translate(-3.375 -5.627)"
        fill="#707070"
      />
    </svg>
  );
};
