import { Box, Grid, IconButton, Paper, Typography } from "@mui/material";
import { func, object, string } from "prop-types";
import { CartTrashIcon } from "../../../../../../components/Icons";
import { photoUrl } from "../../../../../../helpers/helpers";
import { StyledProductIco } from "../../../../../../components/Icons";

const DiscountProductSmall = ({
  product,
  handleRemoveProduct,
  manufacturer,
}) => {
  return (
    <Paper
      sx={{
        py: "4px",
        height: "46px",
        cursor: "pointer",
        borderBottom: "0.5px solid #F0F0F0",
        backgroundColor: "transparent",
        display: "flex",
        alignItems: "center",
        "& .MuiTypography-root": {
          color: "#1C1C19",
        },
        "&:not(:last-of-type)": {
          borderBottom: "0.5px solid #F0F0F0",
        },
        overflow: "hidden",
      }}
      elevation={0}
      square
      component={Grid}
      container
      columns={8}
    >
      <Grid
        item
        xs={1}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          m: "0 14px 0 7px",
        }}
      >
        <Box
          sx={{
            width: "38px",
            backgroundColor: "white",
            border: "1px solid #D5D9D9",
            borderRadius: "4px",
          }}
          height="38px"
        >
          <StyledProductIco
            src={
              product?.photos?.length
                ? photoUrl(product.photos[0].fileName)
                : ""
            }
            styles={{
              objectFit: "contain",
              width: "100%",
              height: "100%",
              borderRadius: "4px",
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={3.6}>
        <Typography fontSize="14px" fontWeight="500" noWrap>
          {product.name || product.parentProduct?.name}
        </Typography>
        <Typography
          fontSize="10px"
          fontWeight="400"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          overflow="hidden"
        >
          by{" "}
          {product.manufacturer?.name ||
            product.parentProduct?.manufacturer?.name ||
            manufacturer}
        </Typography>
      </Grid>
      <Grid item xs={2.4}>
        <Typography fontSize="10px" fontWeight="500" noWrap>
          {product.sku}
        </Typography>
        <Typography fontSize="10px" fontWeight="400" noWrap>
          {product.flavor || product.color || product.size} (Pack of{" "}
          {product.itemsPerCase})
        </Typography>
      </Grid>
      <Grid item xs={0.4}>
        <IconButton onClick={() => handleRemoveProduct(product)}>
          <CartTrashIcon height="15.55" width="12.65" />
        </IconButton>
      </Grid>
    </Paper>
  );
};

DiscountProductSmall.propTypes = {
  product: object,
  handleRemoveProduct: func,
  manufacturer: string,
};

DiscountProductSmall.defaultProps = {
  product: null,
  manufacturer: "",
};

export default DiscountProductSmall;
