import { object } from "prop-types";
import { Grid, Typography } from "@mui/material";

export const SkuBlock = ({ product }) => {
  return (
    <>
      <Grid xs={12} item>
        <Typography
          fontSize={12}
          color="#1C1C19"
          fontWeight={500}
          textAlign="center"
          noWrap
        >
          {product.sku}
        </Typography>
      </Grid>

      <Grid xs={12} item>
        <Typography
          fontSize={12}
          fontWeight={400}
          color="#1C1C19"
          textAlign="center"
          noWrap
        >
          {product.flavor || product.color || product.size} (Pack of{" "}
          {product.itemsPerCase})
        </Typography>
      </Grid>
    </>
  );
};

SkuBlock.propTypes = { product: object };
SkuBlock.defaultProps = { product: {} };
