import React from "react";

export const ArrowsDnDIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10.104"
      height="17.777"
      viewBox="0 0 10.104 17.777"
    >
      <g id="Group_4117" data-name="Group 4117" transform="translate(-55 -239)">
        <g
          id="Icon_ionic-ios-arrow-down"
          data-name="Icon ionic-ios-arrow-down"
          transform="translate(55 251)"
        >
          <path
            id="Icon_ionic-ios-arrow-down-2"
            data-name="Icon ionic-ios-arrow-down"
            d="M11.241,15.282l3.82-3.823a.719.719,0,0,1,1.02,0,.728.728,0,0,1,0,1.023l-4.329,4.332a.721.721,0,0,1-1,.021L6.4,12.484a.722.722,0,1,1,1.02-1.023Z"
            transform="translate(-6.188 -11.246)"
            fill="#d4d4d4"
          />
        </g>
        <g
          id="Icon_ionic-ios-arrow-down-3"
          data-name="Icon ionic-ios-arrow-down"
          transform="translate(65.104 244.777) rotate(180)"
        >
          <path
            id="Icon_ionic-ios-arrow-down-4"
            data-name="Icon ionic-ios-arrow-down"
            d="M11.241,15.282l3.82-3.823a.719.719,0,0,1,1.02,0,.728.728,0,0,1,0,1.023l-4.329,4.332a.721.721,0,0,1-1,.021L6.4,12.484a.722.722,0,1,1,1.02-1.023Z"
            transform="translate(-6.188 -11.246)"
            fill="#d4d4d4"
          />
        </g>
      </g>
    </svg>
  );
};
