import pluralize from "pluralize";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  openConfirmDialogAction,
  setConfirmIsOpenAction,
} from "redux/actions/confirmDialogs";
import { routesQuickFilterActions } from "redux/actions/reps";
import {
  getAssignedRoutesService,
  bulkAssignRoutesService,
} from "services/routes";
import { error, success } from "utils/notifications";
import useStyles from "./styles";

const DEFAULT_DRAWER_VALUES = {
  assigned: [],
  available: [],
};

export const useRoutesAssigne = ({ refreshCallback }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isRoutesPage = pathname.split("/")[1] === "routes";

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(null);
  const [routesList, setRoutesList] = useState(DEFAULT_DRAWER_VALUES);

  const handleCloseDrawer = () => {
    setIsDrawerOpen(null);
    setRoutesList(DEFAULT_DRAWER_VALUES);
  };

  const handleCheckForInaccessibleRoutes = (resList) => {
    if (!resList.length) return;

    const routeNames = resList.map((item) => item.name);

    dispatch(
      openConfirmDialogAction({
        title: "Not accessible stop",
        text: (
          <>
            Customer{" "}
            <span className={classes.greenSpan}>{isDrawerOpen.name}</span> could
            not be added to {pluralize("route", routeNames.length)}{" "}
            <span className={classes.greenSpan}>{routeNames.join(", ")}</span>
          </>
        ),
        propBtns: {
          right: {
            label: "Ok",
            variant: "contained",
            onClick: () => dispatch(setConfirmIsOpenAction(false)),
          },
        },
      })
    );
  };

  const handleGetRoutes = async (id) => {
    setLoading(true);
    try {
      const res = await getAssignedRoutesService(id);
      setRoutesList({
        assigned: handlePrepareRoutes(res.assignedRoutes, true),
        available: handlePrepareRoutes(res.availableRoutes, false),
      });
    } catch (err) {
      error(err?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const handleFilterStateByKey = (key, id) => {
    return routesList[key].filter((item) => item.id !== id);
  };

  const getFieldIds = () => {
    if (routesList.assigned.length)
      return routesList.assigned.map(({ id }) => id);
    return [];
  };

  const handleDialog = (routeNames, callback) => {
    dispatch(
      openConfirmDialogAction({
        title: "Remove stop from the route?",
        text: (
          <>
            The route requires at least one stop. If you continue{" "}
            <span className={classes.greenSpan}>{routeNames.join(", ")}</span>{" "}
            will be deleted.
          </>
        ),
        propBtns: {
          left: { label: "Cancel", color: "cancel", variant: "outlined" },
          right: {
            label: "Proceed",
            color: "error",
            variant: "contained",
            onClick: () => {
              callback(true);
              dispatch(setConfirmIsOpenAction(false));
            },
          },
        },
      })
    );
  };

  const onSubmit = () => {
    const filteredState = routesList.available.filter(
      (item) => item._count.customers === 0
    );

    if (!filteredState.length) return onProceedSubmit();

    const routeNames = filteredState.map((item) => item.name);
    handleDialog(routeNames, onProceedSubmit);
  };

  const onProceedSubmit = async (redirect = false) => {
    const preparedData = {
      assignedRoutesIds: getFieldIds(),
    };
    setLoading(true);

    try {
      const res = await bulkAssignRoutesService(isDrawerOpen.id, preparedData);
      success(res.message);
      handleCheckForInaccessibleRoutes(res.inaccessibleRoutes);

      if (redirect && isRoutesPage) {
        dispatch(routesQuickFilterActions({ name: "type", value: "" }));
        navigate("/routes");
      }
    } catch (err) {
      error(err?.response?.data?.message);
    } finally {
      setLoading(false);
      setIsDrawerOpen(false);
      setRoutesList(DEFAULT_DRAWER_VALUES);
      refreshCallback(isDrawerOpen?.status);
    }
  };

  const onRouteChange = (key, route) => {
    const changedRep = {
      ...route,
      checked: !route.checked,
      wasModified: true,
      ...{
        _count: {
          customers: route.checked
            ? route._count.customers - 1
            : route._count.customers + 1,
        },
      },
    };

    const filteredState = handleFilterStateByKey(key, route.id);
    if (route.checked) {
      return setRoutesList({
        available: [changedRep, ...routesList.available],
        assigned: filteredState,
      });
    }

    return setRoutesList({
      available: filteredState,
      assigned: [...routesList.assigned, changedRep],
    });
  };

  const handleProceedUnassignAllRoutes = async (redirect = false) => {
    const preparedData = {
      assignedRoutesIds: [],
    };

    setLoading(true);
    try {
      const res = await bulkAssignRoutesService(isDrawerOpen.id, preparedData);
      success(res.message);
      handleCheckForInaccessibleRoutes(res.inaccessibleRoutes);

      if (redirect && isRoutesPage) {
        dispatch(routesQuickFilterActions({ name: "type", value: "" }));
        navigate("/routes");
      }
    } catch (err) {
      error(err?.response?.data?.message);
    } finally {
      setLoading(false);
      setIsDrawerOpen(false);
      setRoutesList(DEFAULT_DRAWER_VALUES);
      refreshCallback(isDrawerOpen?.status);
    }
  };

  const handleUnassignAllRoutes = async () => {
    const filteredAvailableState = routesList.available.filter(
      (item) => item._count.customers === 0
    );

    const filteredAssignedState = routesList.assigned.filter(
      (item) => item._count.customers === 1
    );

    const filteredState = [...filteredAvailableState, ...filteredAssignedState];
    if (!filteredState.length) return handleProceedUnassignAllRoutes();

    const routeNames = filteredState.map((item) => item.name);

    handleDialog(routeNames, handleProceedUnassignAllRoutes);
  };

  const handlePrepareRoutes = (list, assignedList) => {
    return list.length
      ? list.map((item) => {
          return {
            ...item,
            checked: assignedList ? true : false,
            wasModified: false,
          };
        })
      : [];
  };

  const handleOpenDrawer = (customer) => {
    setIsDrawerOpen(customer);
    handleGetRoutes(customer.id);
  };

  return {
    handleCloseDrawer,
    handleOpenDrawer,
    isDrawerOpen,
    routesList,
    handleUnassignAllRoutes,
    loading,
    onRouteChange,
    onSubmit,
  };
};
