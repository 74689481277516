import React from "react";

export const QuestionIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      viewBox="0 0 10.245 10.245"
    >
      <path
        id="Icon_awesome-question-circle"
        data-name="Icon awesome-question-circle"
        d="M10.808,5.685A5.123,5.123,0,1,1,5.685.563,5.122,5.122,0,0,1,10.808,5.685ZM5.823,2.256A2.675,2.675,0,0,0,3.415,3.573a.248.248,0,0,0,.056.336l.717.543a.248.248,0,0,0,.344-.044c.369-.468.622-.739,1.184-.739.422,0,.944.272.944.681,0,.309-.255.468-.672.7-.486.272-1.129.611-1.129,1.459v.083a.248.248,0,0,0,.248.248H6.263a.248.248,0,0,0,.248-.248V6.566c0-.588,1.718-.612,1.718-2.2A2.357,2.357,0,0,0,5.823,2.256ZM5.685,7.379a.95.95,0,1,0,.95.95A.951.951,0,0,0,5.685,7.379Z"
        transform="translate(-0.563 -0.563)"
        fill="currentColor"
      />
    </svg>
  );
};
