import { bool, func } from "prop-types";
import { Button, Grid } from "@mui/material";

export const ActionsBlock = ({ loading, handleDownloadReport }) => {
  return (
    <Grid mt={{ xs: 0, md: 8.25 }} xs={12} item>
      <Button
        disabled={loading}
        sx={{
          mt: 2.25,
          height: { xs: "37px", md: "50px" },
          fontSize: { xs: 15, md: 18 },
          fontWeight: 500,
        }}
        variant="contained"
        fullWidth
        onClick={handleDownloadReport}
      >
        {loading ? "Loading..." : "Download invoice"}
      </Button>
    </Grid>
  );
};

ActionsBlock.propTypes = { loading: bool, handleDownloadReport: func };
ActionsBlock.defaultProps = { loading: false, handleDownloadReport: () => {} };
