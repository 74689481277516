import React from "react";

export const GroupsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11.21"
      height="8.509"
      viewBox="0 0 11.21 8.509"
    >
      <path
        id="Icon_material-group"
        data-name="Icon material-group"
        d="M9.143,11.147a1.69,1.69,0,0,0,1.524-1.823A1.69,1.69,0,0,0,9.143,7.5,1.693,1.693,0,0,0,7.615,9.323,1.693,1.693,0,0,0,9.143,11.147Zm-4.076,0A1.69,1.69,0,0,0,6.59,9.323,1.69,1.69,0,0,0,5.067,7.5,1.693,1.693,0,0,0,3.538,9.323,1.693,1.693,0,0,0,5.067,11.147Zm0,1.216c-1.187,0-3.567.711-3.567,2.127v1.52H8.634V14.49C8.634,13.074,6.254,12.363,5.067,12.363Zm4.076,0c-.148,0-.316.012-.494.03a2.7,2.7,0,0,1,1,2.1v1.52H12.71V14.49C12.71,13.074,10.33,12.363,9.143,12.363Z"
        transform="translate(-1.5 -7.5)"
        fill="#3f3f3f"
      />
    </svg>
  );
};
