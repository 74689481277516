import { useContext, useMemo, useState } from "react";
import { any, func, object, string } from "prop-types";
import { Box, Typography } from "@mui/material";
import CustomerDescription from "../CustomerDescription";
import CustomerCardInfo from "../CustomerCardInfo";
import { StyledTabs } from "components";
import { SwitcherLinkBlock } from "./components";
import { CustomerProfileContext } from "Pages/CustomersPage/pages/CustomerProfilePage/CustomerProfilePage";
import { useAddressesSubCustomers } from "Pages/CustomersPage/pages/CustomerProfilePage/CustomerProfile.hooks";

export const LeftColumnBlock = ({
  customerDescRef,
  handleOpenEmailDialog,
  classes,
  shippingAddress,
  billingAddress,
  handleOpenEditCustomer,
  name,
}) => {
  const { customerState, resetCustomerState, isParent, repPermissions } =
    useContext(CustomerProfileContext);

  const disabledCreateEdit = useMemo(() => {
    if (repPermissions) {
      return !repPermissions?.customers?.create_edit;
    }
  }, [repPermissions]);

  const { customer } = customerState || {};
  const { subCustomerCount } = customer || {};
  const [addressTab, setAddressTab] = useState(0);

  const preparedAddress = (address) => {
    if (!address) return "-";
    const preparedData = address?.split(", ");
    const firstLine = preparedData?.length ? preparedData?.splice(0, 1) : "";
    const secondLine = preparedData?.join(", ") || "";
    return (
      <>
        <Box component="span">{firstLine}</Box>
        <br />
        <Box component="span">{secondLine}</Box>
      </>
    );
  };

  const TABLE_TABS = useMemo(
    () => [
      {
        value: 0,
        label: "Ship to",
        disabled: false,
        showTooltip: false,
        tooltipText: "",
      },
      {
        value: 1,
        label: "Bill to",
        disabled: false,
        showTooltip: false,
        tooltipText: "",
      },
    ],
    []
  );

  const CUSTOMER_ADDRESS_INFO = useMemo(
    () => ({
      0: {
        itemTitle: name,
        customerName: preparedAddress(shippingAddress?.formatted_address),
      },
      1: {
        itemTitle: name,
        customerName: preparedAddress(billingAddress?.formatted_address),
      },
    }),
    [
      billingAddress?.formatted_address,
      name,
      shippingAddress?.formatted_address,
    ]
  );

  const {
    addressesSubCustomersState,
    handleFetchAddressesSubCustomers,
    resetAddressesSubCustomersState,
  } = useAddressesSubCustomers({ parentCustomerId: customer?.id });

  const setSubCustomerTextLink = useMemo(() => {
    if (!subCustomerCount) return null;
    const { loading, hasFetched } = addressesSubCustomersState || {};

    if (loading) return "Loading...";

    if (hasFetched) return `Hide ${subCustomerCount} Locations`;
    if (!hasFetched) return `Show all locations (${subCustomerCount})`;
  }, [addressesSubCustomersState, subCustomerCount]);

  const list = useMemo(() => {
    return [CUSTOMER_ADDRESS_INFO[addressTab]].concat(
      addressesSubCustomersState?.list?.map((item) => ({
        itemTitle: item?.displayName || item?.name,
        customerName: preparedAddress(
          addressTab === 0
            ? item?.shippingAddress?.formatted_address
            : item?.billingAddress?.formatted_address
        ),
        id: item?.id,
      }))
    );
  }, [CUSTOMER_ADDRESS_INFO, addressTab, addressesSubCustomersState?.list]);

  return (
    <Box
      flex="1 1 19%"
      display="flex"
      flexDirection="column"
      gap="11px"
      ref={customerDescRef}
      overflow="hidden"
    >
      <CustomerDescription {...{ handleOpenEmailDialog }} />
      <CustomerCardInfo
        title={
          <StyledTabs
            onChange={(e, newVal) => {
              setAddressTab(newVal);
            }}
            value={addressTab}
            tabs={TABLE_TABS}
            tabProps={{ className: classes.addressTab }}
            className={classes.addressTabs}
          />
        }
        customer={customer}
        items={list}
        titleStyle={classes.titleStyle}
        handleEdit={handleOpenEditCustomer}
        isAddress={true}
        addressTab={addressTab}
        bottomBlock={
          subCustomerCount > 0 ? (
            <SwitcherLinkBlock wrapStyles={{ mt: "17px", px: "14px" }}>
              <Typography
                color="primary"
                sx={{
                  fontWeight: 400,
                  fontSize: 13,
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => {
                  addressesSubCustomersState?.hasFetched
                    ? resetAddressesSubCustomersState()
                    : handleFetchAddressesSubCustomers(1);
                }}
              >
                {setSubCustomerTextLink}
              </Typography>
            </SwitcherLinkBlock>
          ) : null
        }
        isParent={isParent}
        resetCustomerState={resetCustomerState}
        loadingItems={addressesSubCustomersState?.loading}
        itemsCount={addressesSubCustomersState?.count}
        handleFetchItems={() =>
          handleFetchAddressesSubCustomers(addressesSubCustomersState?.page + 1)
        }
        disabledCreateEdit={disabledCreateEdit}
      />
    </Box>
  );
};

LeftColumnBlock.propTypes = {
  customerDescRef: any,
  handleOpenEmailDialog: func,
  classes: object,
  shippingAddress: object,
  billingAddress: object,
  handleOpenEditCustomer: func,
  name: string,
};

export default LeftColumnBlock;
