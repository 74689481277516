import {
  START_LOADING_TOTAL_SUMMARIES_CATALOG,
  END_LOADING_TOTAL_SUMMARIES_CATALOG,
  GET_TOTAL_SUMMARIES_CATALOG_DATA,
  START_LOADING_TOTAL_SUMMARIES_ORDERS,
  END_LOADING_TOTAL_SUMMARIES_ORDERS,
  GET_TOTAL_SUMMARIES_ORDERS_DATA,
  SET_TOTAL_SUMMARIES_ORDERS_PARAMS,
  GET_TOTAL_SUMMARIES_CUSTOMERS_DATA,
  START_LOADING_TOTAL_SUMMARIES_CUSTOMERS,
  END_LOADING_TOTAL_SUMMARIES_CUSTOMERS,
} from "../actions/total-summaries";

const initialState = {
  totalSummariesCatalogData: null,
  totalSummariesCatalogLoading: false,

  totalSummariesOrdersData: null,
  totalSummariesOrdersLoading: false,
  totalSummariesOrdersParams: {
    date: null,
    start_date: null,
    end_date: null,
    type: "direct",
  },

  totalSummariesCustomersData: null,
  totalSummariesCustomersLoading: false,
};

const totalSummariesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_TOTAL_SUMMARIES_CATALOG_DATA:
      return {
        ...state,
        totalSummariesCatalogData: payload,
      };
    case START_LOADING_TOTAL_SUMMARIES_CATALOG:
      return {
        ...state,
        totalSummariesCatalogLoading: true,
      };
    case END_LOADING_TOTAL_SUMMARIES_CATALOG:
      return {
        ...state,
        totalSummariesCatalogLoading: false,
      };

    case GET_TOTAL_SUMMARIES_ORDERS_DATA:
      return {
        ...state,
        totalSummariesOrdersData: payload,
      };
    case SET_TOTAL_SUMMARIES_ORDERS_PARAMS:
      return {
        ...state,
        totalSummariesOrdersParams: payload,
      };
    case START_LOADING_TOTAL_SUMMARIES_ORDERS:
      return {
        ...state,
        totalSummariesOrdersLoading: true,
      };
    case END_LOADING_TOTAL_SUMMARIES_ORDERS:
      return {
        ...state,
        totalSummariesOrdersLoading: false,
      };

    case GET_TOTAL_SUMMARIES_CUSTOMERS_DATA:
      return {
        ...state,
        totalSummariesCustomersData: payload,
      };
    case START_LOADING_TOTAL_SUMMARIES_CUSTOMERS:
      return {
        ...state,
        totalSummariesCustomersLoading: true,
      };
    case END_LOADING_TOTAL_SUMMARIES_CUSTOMERS:
      return {
        ...state,
        totalSummariesCustomersLoading: false,
      };

    default:
      return state;
  }
};

export default totalSummariesReducer;
