/* eslint-disable react/prop-types */
import React from "react";

export const SmallArrowRight = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="8"
      viewBox="0 0 8 8"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_8"
            data-name="Rectangle 8"
            width="8"
            height="8"
            transform="translate(124 402)"
            fill="#fff"
          />
        </clipPath>
      </defs>
      <g
        id="Group_2"
        data-name="Group 2"
        transform="translate(-124 -402)"
        clipPath="url(#clip-path)"
      >
        <path
          id="Path_21"
          data-name="Path 21"
          d="M125.667,406h4.666"
          fill="none"
          stroke="#343434"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_22"
          data-name="Path 22"
          d="M128.333,408l2-2"
          fill="none"
          stroke="#343434"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_23"
          data-name="Path 23"
          d="M128.333,404l2,2"
          fill="none"
          stroke="#343434"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};
