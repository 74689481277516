export const defaultFilters = {
  manufacturerId: "",
};

export const FILTER_SWITCHES = [
  {
    value: "manufacturerId",
    label: "Manufacturer",
    type: "select",
    checked: false,
    selectLabel: "Select Manufacturer",
  },
];
