import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  wrapper: {
    width: "545px",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
  },

  header: {
    backgroundColor: "#F8F8F8",
    borderBottom: "0.5px solid #d5d9d9",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "67px",
    position: "relative",
    paddingLeft: "33px",
    paddingRight: "13px",
    gap: "15px",
  },

  routeItemWrapper: {
    padding: "2.5px 9px 2.5px 16px",
    "&:hover": {
      backgroundColor: "#F8F8F8",
      borderRadius: "8px",
    },
  },

  bodyWrapper: {
    width: "100%",
    padding: "0px !important",
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    height: "calc(100vh - 147px)",

    "& .MuiOutlinedInput-root": {
      fontSize: "12px",
    },

    "& > :first-of-type": {
      borderBottom: "0.5px solid #D5D9D9",
      paddingBottom: "13px",
    },
  },

  footer: {
    marginTop: "auto",
    height: "80px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 30px",
    backgroundColor: "#F8F8F8",
    borderTop: "0.5px solid #D5D9D9",
    "& .MuiButton-root": {
      height: "31px",
    },
  },

  sliderWrapper: {
    marginLeft: "auto",

    "& .slick-arrow::before": {
      content: '""',
    },

    "& .slick-arrow.slick-prev": {
      left: "-8px",
    },

    "& .slick-arrow.slick-next": {
      right: "-23px",
    },

    "& .slick-track": {
      marginRight: ({ moreThanOne }) => moreThanOne && 5,
      "& .slick-slide": {
        height: "25px",
        width: "25px!important",
        marginRight: "-8px",
        transition: "all 0.3s ease",
        "&:not(.slick-active)": {
          opacity: 0,
        },
      },
    },
  },

  assignedAvatar: {
    cursor: "pointer",
    marginRight: "-6px",
    width: "25px",
    height: "25px",
    border: "0.5px solid #fff",
    transition: "all 0.3s ease",
    display: "flex!important",
    "&:hover": {
      zIndex: 100,
    },
  },
}));

export default useStyles;
