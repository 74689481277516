import { DialogActions } from "@mui/material";
import { StyledButton } from "components";
import { bool, func } from "prop-types";

export const ActionBlock = ({
  handleClose,
  getCreateButtonLabel,
  isDisabled,
}) => {
  return (
    <DialogActions sx={{ padding: "15px 40px 18px" }}>
      <StyledButton
        sx={{ height: "34px" }}
        label="Cancel"
        fontSize="13px"
        color="cancel"
        onClick={handleClose}
      />
      <StyledButton
        disabled={isDisabled}
        sx={{ height: "34px" }}
        fontSize="15px"
        type="submit"
        form="order-email-form"
        label={getCreateButtonLabel()}
        variant="contained"
      />
    </DialogActions>
  );
};

ActionBlock.propTypes = {
  getCreateButtonLabel: func,
  handleClose: func,
  isDisabled: bool,
};
