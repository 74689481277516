import { useEffect, useRef } from "react";
import { string, func } from "prop-types";
import {
  Elements,
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const Wrapper = ({ setLoadingStripeElement, onSubmit, ...props }) => {
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (stripe && elements) {
      setLoadingStripeElement(false);
    }
  }, [elements, setLoadingStripeElement, stripe]);

  return (
    <form
      id="stripe-form"
      onSubmit={(e) => onSubmit(e, { stripe, elements })}
      {...props}
    >
      <PaymentElement />
    </form>
  );
};
Wrapper.propTypes = {
  onSubmit: func,
  setLoadingStripeElement: func,
};
Wrapper.defaultProps = {
  onSubmit: () => {},
  setLoadingStripeElement: () => {},
};

export const StripeElement = ({
  setLoadingStripeElement,
  clientSecret,
  stripeAccount,
  onSubmit,
}) => {
  const options = {
    mode: "payment",
    currency: "usd",
  };

  const stripeRef = useRef(
    // eslint-disable-next-line no-undef
    loadStripe(process.env.REACT_APP_STRIPE_API_KEY_PUBLIC, {
      stripeAccount,
      options,
    })
  );

  const optionsStripe = { clientSecret };
  return (
    <Elements stripe={stripeRef.current} options={optionsStripe}>
      <Wrapper
        setLoadingStripeElement={setLoadingStripeElement}
        onSubmit={onSubmit}
      />
    </Elements>
  );
};

StripeElement.propTypes = {
  clientSecret: string,
  stripeAccount: string,
  onSubmit: func,
  setLoadingStripeElement: func,
};
StripeElement.defaultProps = {
  clientSecret: "",
  stripeAccount: "",
  onSubmit: () => {},
  setLoadingStripeElement: () => {},
};
