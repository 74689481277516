import { string, func, object } from "prop-types";
import { Box, Typography } from "@mui/material";
import { TaskBigIcon } from "../../../../../../components/Icons";

export const TaskItem = ({ size, onClick, sxProp }) => {
  return (
    <Box
      sx={{
        border: "0.2px solid #D5D9D9",
        borderRadius: "4px",
        width: size === "small" ? "40px" : "57px",
        maxWidth: size === "small" ? "40px" : "57px",
        minWidth: size === "small" ? "40px" : "57px",
        height: size === "small" ? "40px" : "57px",
        maxHeight: size === "small" ? "40px" : "57px",
        minHeight: size === "small" ? "40px" : "57px",
        display: "flex !important",
        flexDirection: "column",
        justifyContent: "space-between",
        backgroundColor: "#F6F6F6",
        position: "relative",
        cursor: "pointer",
        ...sxProp,
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-end",
          flexGrow: 1,
          mt: size === "small" && "4px",
          // pb: size !== "small" && "6px",
        }}
      >
        <TaskBigIcon />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "18px",
          borderRadius: "0 0 4px 4px",
        }}
      >
        <Typography
          sx={{
            fontSize: 8,
            fontWeight: 600,
            color: "#5F6267",
          }}
        >
          Task
        </Typography>
      </Box>
    </Box>
  );
};

TaskItem.defaultProps = {
  size: "big",
  onClick: () => {},
  sxProp: {},
};

TaskItem.propTypes = {
  size: string,
  onClick: func,
  sxProp: object,
};
