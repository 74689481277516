import { number } from "prop-types";
import { Box } from "@mui/material";
import { StepItem } from "./StepItem";

const STEPS = [
  { step: 1, name: "Account Creation" },
  { step: 2, name: "Business Details" },
  { step: 3, name: "Finish" },
];

export const StepsRegistration = ({ step }) => {
  return (
    <Box
      sx={{
        height: "70px",
        px: "85px",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      {STEPS.map((s) => (
        <StepItem
          key={s.step}
          step={s.step}
          name={s.name}
          isActive={s.step === step || (s.step === 2 && step === 2.1)}
        />
      ))}
    </Box>
  );
};

StepsRegistration.propTypes = { step: number };
StepsRegistration.defaultProps = { step: 1 };
