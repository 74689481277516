import React from "react";

export const OrdersIcon = ({ ...props }) => {
  return (
    <svg
      width={16}
      height={20}
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5 13h3M5 8h2m2-7H7a2 2 0 1 0 0 4h2a2 2 0 0 0 0-4Z"
        stroke="#A5A5A5"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.065 2.8H3a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-12a2 2 0 0 0-2-2h-2.065"
        stroke="#A5A5A5"
        strokeWidth={1.5}
      />
    </svg>
  );
};
