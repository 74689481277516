import {
  LOWERCASE_REG_EXP,
  MIN_PASSWORD_LENGTH,
  NUMBER_REG_EXP,
  SYMBOL_REG_EXP,
  UPPERCASE_REG_EXP,
} from "components/PasswordCriteria/PasswordCriteria.constants";
import * as Yup from "yup";

export const validationSchema = () =>
  Yup.object().shape({
    newPassword: Yup.string()
      .min(
        MIN_PASSWORD_LENGTH,
        `Password must be at least ${MIN_PASSWORD_LENGTH} characters`
      )
      .matches(
        LOWERCASE_REG_EXP,
        "Password must have at least one lowercase char"
      )
      .matches(
        UPPERCASE_REG_EXP,
        "Password must have at least one uppercase char"
      )
      .matches(NUMBER_REG_EXP, "Password must have at least one digit")
      .matches(SYMBOL_REG_EXP, "Password must have at least one special char")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords don't match!")
      .required("Required"),
  });
