import { object, bool, func } from "prop-types";

import { Grid, Typography } from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";

import { StyledAvatar } from "components";
import { photoUrl } from "helpers/helpers";
import { getPriority } from "../TaskDrawer/TaskDrawer.hooks";
import { useSelector } from "react-redux";
import { useDaysAgo } from "helpers/hooks";
import { StatusIcon } from "components/Icons";

const TaskItem = ({ task, showBorder, onClick }) => {
  const currentUser = useSelector(({ auth }) => auth.currentUser);
  const { profilePhoto } = currentUser || {};

  const {
    title: taskName,
    status,
    priority: priorityType,
    dueDate,
    assignedRepresentatives,
  } = task || {};

  const assignedRepresentative = assignedRepresentatives?.length
    ? assignedRepresentatives?.[0]?.representative
    : { name: "You", profilePhoto };

  const assignedRepresentativeName = assignedRepresentative?.name || "-";

  const assignedRepresentativeFileName =
    assignedRepresentative?.profilePhoto?.fileName;

  const preparedDueDate = useDaysAgo({
    date: dueDate,
    timeZone: currentUser.timeZone,
  });

  return (
    <Grid
      onClick={() => onClick(task)}
      columns={25}
      container
      sx={{
        borderBottom: showBorder && "1px solid #D5D9D9",
        "& .MuiTypography-root": {
          fontSize: "13px",
          color: "#1C1C19",
        },
        "&:hover": {
          backgroundColor: "#F7F7F7",
        },
        cursor: "pointer",
      }}
      height="48px"
      alignItems="center"
    >
      {/* Status */}
      <Grid xs={1.6} item>
        <StatusIcon status={status} sx={{ ml: "28%" }} />
      </Grid>

      {/* Task Name */}
      <Grid xs={13.4} item>
        <Typography fontSize={13} color="#1C1C19" noWrap>
          {taskName}
        </Typography>
      </Grid>

      {/* Assignee */}
      <Grid display="flex" alignItems="center" gap={1} xs={3.8} item>
        <StyledAvatar
          name={assignedRepresentativeName}
          sx={{ width: 23.5, height: 23.5 }}
          stringStyle={{ fontSize: 12 }}
          src={photoUrl(assignedRepresentativeFileName)}
        />
        <Typography fontSize={13} color="#1C1C19" noWrap>
          {assignedRepresentativeName}
        </Typography>
      </Grid>

      {/* Due Date */}
      <Grid xs={3.2} item>
        <Typography fontSize={13} color="#1C1C19">
          {preparedDueDate}
        </Typography>
      </Grid>

      {/* Priority */}
      <Grid xs={2} item>
        {getPriority({
          type: priorityType,
          sx: {
            width: "52px",
            height: "18px",
            borderRadius: "4px",
          },
        })}
      </Grid>

      {/* icon */}
      <Grid
        display="flex"
        justifyContent="center"
        alignItems="center"
        xs={1}
        item
      >
        <ArrowForwardIos style={{ fill: "#5F6267", fontSize: "13px" }} />
      </Grid>
    </Grid>
  );
};

TaskItem.propTypes = { task: object, showBorder: bool, onClick: func };

export default TaskItem;
