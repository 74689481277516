import { func, bool, object, array, number, oneOf } from "prop-types";
import { Box, Checkbox, Paper } from "@mui/material";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  CheckboxIcon,
} from "../../../../../../../components/Icons";
import { useMemo } from "react";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { columnLayoutsSelector } from "../../../../../../../redux/selectors/settings";

const selector = createSelector(columnLayoutsSelector, (columnLayouts) => ({
  columnLayouts,
}));

const columnWidth = {
  Status: 5,
  ID: 8,
  Customer: 22,
  empty: 4,
};
const columnStyles = {
  Status: { justifyContent: "center" },
  "Orders Total": { justifyContent: "center", paddingLeft: "8px" },
};

const SORTABLE_COLUMNS = {
  "Last Visit": { sortableField: "sort_visit" },
  "Last Order": { sortableField: "sort_last_order" },
  "Orders Total": { sortableField: "sort_by_orders_count" },
  Customer: { sortableField: "sort_display_name" },
  Group: { sortableField: "sort_parent_customer_name" },
  Territory: { sortableField: "sort_territory_name" },
  "Order Direct": { sortableField: "sort_order_direct" },
};

const TabHeaderComponent = ({
  checkedCustomers,
  handleSetCheckedCustomersHeader,
  customersCount,
  group,
  isFlat,
  customersList,
  quickSort,
  handleSetSortVisit,
  currentTab,
  isAdmin,
}) => {
  const classes = {
    grid: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "37px",
    },
    typography: {
      fontSize: 12,
      fontWeight: 500,
      color: "#6A6A6A",
      textTransform: "uppercase",
    },
  };

  const { columnLayouts } = useSelector(selector);

  const flat_tab_disable = columnLayouts.flat.disable;
  const flat_tab = columnLayouts.flat.main;
  const sorted_tab_disable = columnLayouts.sorted.disable;
  const sorted_tab = columnLayouts.sorted.main;

  const columns = useMemo(() => {
    if (currentTab === "FLAT" && flat_tab_disable)
      return [...flat_tab_disable, ...flat_tab.filter((col) => col.selected)];
    if (currentTab === "SORTED")
      return [
        ...sorted_tab_disable,
        ...sorted_tab.filter((col) => col.selected),
      ];
    return [];
  }, [currentTab, flat_tab, flat_tab_disable, sorted_tab, sorted_tab_disable]);

  const defaultColumnWidth = useMemo(
    () => Object.values(columnWidth).reduce((acc, cur) => (acc += cur), 0),
    []
  );

  const isChecked = useMemo(() => {
    const customerLoadedCount = group?.subCustomers?.length;
    const checkedCustomerCurrentGroupCount = checkedCustomers.filter(
      (customer) => customer?.parentCustomer?.id === group?.id
    )?.length;

    if (group && checkedCustomerCurrentGroupCount === 0) return false;

    return group
      ? customerLoadedCount === checkedCustomerCurrentGroupCount ||
          group?.subCustomerCount === checkedCustomerCurrentGroupCount ||
          customersCount === checkedCustomers?.length
      : customersList?.length === checkedCustomers?.length ||
          customersCount === checkedCustomers?.length;
  }, [checkedCustomers, customersCount, customersList?.length, group]);

  const handleColumnName = (name) => {
    switch (name) {
      case "Orders Total":
        return "Orders";
      default:
        return name;
    }
  };

  return (
    <Paper
      sx={{
        backgroundColor: "#EEEEEE",
        position: "relative",
        alignItems: "center",
        borderBottom: "1px solid #D5D9D9",
        borderLeft: isFlat && "1px solid #D5D9D9",
        borderRight: isFlat && "1px solid #D5D9D9",
        display: "flex",
      }}
      elevation={0}
      square
      height="37px"
    >
      <Box width="52px" minWidth="52px" maxWidth="52px" sx={classes.grid}>
        <Checkbox
          disabled={isAdmin}
          icon={<CheckboxIcon size={21} />}
          sx={{ padding: 0, "& .MuiSvgIcon-root": { fontSize: 31 } }}
          style={{ marginLeft: "1px" }}
          checked={isChecked}
          onChange={(e) =>
            handleSetCheckedCustomersHeader(e.target.checked, group)
          }
        />
      </Box>
      {columns.map((col) => {
        const setWidth = columnWidth?.[col?.name]
          ? `${columnWidth?.[col?.name]}%`
          : `${
              (100 - defaultColumnWidth) /
              (currentTab === "FLAT" ? flat_tab : sorted_tab).filter(
                (col) => col.selected
              ).length
            }%`;

        return (
          <Box
            key={col.id}
            sx={{
              ...classes.typography,
              display: "flex",
              alignItems: "center",
              flexGrow: col?.name === "Customer" ? 1 : 0,
              gap: 1,
              width: setWidth,
              maxWidth: setWidth,
              cursor: !!SORTABLE_COLUMNS[col?.name] && "pointer",
              ...columnStyles?.[col?.name],
            }}
            onClick={() => {
              if (SORTABLE_COLUMNS[col?.name])
                return handleSetSortVisit(
                  SORTABLE_COLUMNS[col?.name].sortableField
                );
            }}
          >
            {handleColumnName(col?.name)}{" "}
            {SORTABLE_COLUMNS[col?.name] ? (
              quickSort[SORTABLE_COLUMNS[col?.name].sortableField] ===
              "desc" ? (
                <ArrowDownIcon />
              ) : (
                <ArrowUpIcon />
              )
            ) : null}
          </Box>
        );
      })}
      <Box
        sx={{
          width: `${columnWidth.empty}%`,
        }}
      />
      {/* <Box width="11px" /> */}
    </Paper>
  );
};

TabHeaderComponent.propTypes = {
  checkedCustomers: array,
  handleSetCheckedCustomersHeader: func,
  group: object,
  isFlat: bool,
  customersCount: number,
  customersList: array,
  quickSort: object,
  handleSetSortVisit: func,
  currentTab: oneOf(["FLAT", "SORTED"]),
  isAdmin: bool,
};
TabHeaderComponent.defaultProps = {
  group: null,
  customersCount: 0,
  handleSetSortVisit: () => {},
};
export default TabHeaderComponent;
