import { Box, Typography } from "@mui/material";

const ReportsPageTab = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        px: "32px",
        backgroundColor: "#F0F0F0",
        borderBottom: "1px solid #D5D9D9",
        height: "44px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: 3,
        }}
      >
        <Box>
          <Typography
            variant="h6"
            fontWeight="400"
            fontSize={20}
            color="#9C9C94"
          >
            Reports
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: 1,
        }}
      ></Box>
    </Box>
  );
};

export default ReportsPageTab;
