import { useState } from "react";

export const useAssignRapDrawer = () => {
  const [state, setState] = useState({
    open: false,
  });

  const handleOpenAssignRapDrawer = () => {
    setState((prev) => ({ ...prev, open: true }));
  };
  const handleCloseAssignRapDrawer = () => {
    setState((prev) => ({ ...prev, open: false }));
  };

  return {
    ...state,
    handleOpenAssignRapDrawer,
    handleCloseAssignRapDrawer,
  };
};
