export const PAYOUTS_SCHEDULES = [
  "day",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];
