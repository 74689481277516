import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  bodyWrapper: {
    width: "324px",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
  },
}));

export const cl = {
  editBtn: {
    width: "41px",
    minWidth: "41px",
    border: "0.5px solid #D5D9D9",
    height: "fit-content",
    fontSize: "9px",
    fontWeight: 500,
    lineHeight: "13px",
    py: "1px",
    color: "black",
  },
};

export default useStyles;
