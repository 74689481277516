import { bool, func, object } from "prop-types";
import { Box, Link } from "@mui/material";
import { StorefrontAccess } from "./components";
import { NavLink } from "react-router-dom";

export const StorefrontAccessBlock = ({
  allowStorePreview,
  loadingStorefrontPreview,
  handleSwitchStorefrontPreview,
  showRetailPrice,
  loadingSuggestedRetailPrice,
  handleSwitchStorefrontSuggestedRetailPrice,
  hideRequestPaymentTerms,
  loadingHideRequestPaymentTermsForCustomer,
  handleSwitchHideRequestPaymentTermsForCustomer,
  approvalAccess,
  loadingApprovalAccess,
  handleSwitchApprovalAccess,
  hideStorefrontAccess,
  hidePayments,
  wrapperStyles = {},
  allowStoreCreditCardPayments,
  loadingAllowStoreCreditCardPayments,
  handleSwitchAllowStoreCreditCardPayments,
  isAdvancePaymentType,
}) => {
  return (
    <Box
      sx={{
        backgroundColor: "#ffffff",
        border: "1px solid #d5d9d9",
        borderRadius: "8px",
        width: "480px",
        p: "33px 22px",
        display: "flex",
        flexDirection: "column",
        gap: "23px",
        ...wrapperStyles,
      }}
    >
      {hideStorefrontAccess ? null : (
        <StorefrontAccess
          title="Storefront Access"
          options={[
            {
              title: "Approval access",
              subtitle: "Customers can register and buy without approval",
              disabled: loadingApprovalAccess,
              value: !!approvalAccess,
              handle: () =>
                handleSwitchApprovalAccess("approvalAccess", !approvalAccess, {
                  hideRequestPaymentTerms: !approvalAccess
                    ? true
                    : hideRequestPaymentTerms,
                }),
            },
            {
              title: "Payment terms",
              subtitle: "Customer ability to select payment terms",
              disabled:
                loadingHideRequestPaymentTermsForCustomer || !!approvalAccess,
              value: !hideRequestPaymentTerms,
              handle: () =>
                handleSwitchHideRequestPaymentTermsForCustomer(
                  "hideRequestPaymentTerms",
                  !hideRequestPaymentTerms
                ),
            },
            {
              title: "Retail price",
              subtitle: "Show suggested retail price",
              disabled: loadingSuggestedRetailPrice,
              value: !!showRetailPrice,
              handle: () =>
                handleSwitchStorefrontSuggestedRetailPrice(
                  "showRetailPrice",
                  !showRetailPrice
                ),
            },
            {
              title: "Storefront preview",
              subtitle: "Storefront preview without customer account",
              disabled: loadingStorefrontPreview,
              value: !!allowStorePreview,
              handle: () =>
                handleSwitchStorefrontPreview(
                  "allowStorePreview",
                  !allowStorePreview
                ),
            },
          ]}
        />
      )}

      {hidePayments ? null : (
        <StorefrontAccess
          title="Payments"
          options={[
            {
              title: "Credit cards",
              subtitle: "Online credit card payments",
              disabled:
                loadingAllowStoreCreditCardPayments || isAdvancePaymentType,
              tooltip: {
                title: (
                  <span style={{ whiteSpace: "pre-wrap" }}>
                    Not available for Advance payment.
                    <br />
                    Change your{" "}
                    <Link
                      color="primary"
                      to="/settings"
                      state={{ tab: "Payment Terms" }}
                      component={NavLink}
                    >
                      primary payment term
                    </Link>
                  </span>
                ),
                show: isAdvancePaymentType,
              },
              value: !!allowStoreCreditCardPayments,
              handle: () =>
                handleSwitchAllowStoreCreditCardPayments(
                  "allowStoreCreditCardPayments",
                  !allowStoreCreditCardPayments
                ),
            },
          ]}
        />
      )}
    </Box>
  );
};

StorefrontAccessBlock.propTypes = {
  allowStorePreview: bool,
  loadingStorefrontPreview: bool,
  handleSwitchStorefrontPreview: func,
  showRetailPrice: bool,
  loadingSuggestedRetailPrice: bool,
  handleSwitchStorefrontSuggestedRetailPrice: func,
  hideRequestPaymentTerms: bool,
  loadingHideRequestPaymentTermsForCustomer: bool,
  handleSwitchHideRequestPaymentTermsForCustomer: func,
  approvalAccess: bool,
  loadingApprovalAccess: bool,
  handleSwitchApprovalAccess: func,
  hideStorefrontAccess: bool,
  hidePayments: bool,
  wrapperStyles: object,
  allowStoreCreditCardPayments: bool,
  loadingAllowStoreCreditCardPayments: bool,
  handleSwitchAllowStoreCreditCardPayments: func,
  isAdvancePaymentType: bool,
};
StorefrontAccessBlock.defaultProps = {
  allowStorePreview: false,
  loadingStorefrontPreview: false,
  handleSwitchStorefrontPreview: () => {},
  showRetailPrice: false,
  loadingSuggestedRetailPrice: false,
  handleSwitchStorefrontSuggestedRetailPrice: () => {},
  hideRequestPaymentTerms: false,
  loadingHideRequestPaymentTermsForCustomer: false,
  handleSwitchHideRequestPaymentTermsForCustomer: () => {},
  approvalAccess: false,
  loadingApprovalAccess: false,
  handleSwitchApprovalAccess: () => {},
  hideStorefrontAccess: false,
  hidePayments: false,
  wrapperStyles: {},
  allowStoreCreditCardPayments: false,
  loadingAllowStoreCreditCardPayments: false,
  handleSwitchAllowStoreCreditCardPayments: () => {},
  isAdvancePaymentType: false,
};

export default StorefrontAccessBlock;
