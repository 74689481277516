import {
  ORDER_STATUSES,
  PAYMENT_STATUSES,
  DELIVERY_STATUSES,
  DELIVERY_METHODS,
  ARCHIVED,
} from "Pages/OrdersPage/Orders.constants";
import { SCROLL_LIMIT } from "utils/constants";

export const FILTER_TABS_DATA = [
  { label: "Tasks", value: 0, type: "task_completed" },
  { label: "Orders", value: 1, type: "order_added" },
  { label: "Comments", value: 2, type: "comment_added" },
  { label: "Notes", value: 3, type: "note_added" },
  { label: "Photos", value: 4, type: "photo_group_added" },
  { label: "Check In", value: 5, type: "checked_in" },
  { label: "All", value: 6, type: "" },
];

export const ORDER_FILTERS = {
  order_status: "",
  payment_status: "",
  delivery_status: "",
  delivery_method: "",
  sort_by: "",
  payment_due: "",
  archived: "",
  fulfill_by: "",
};

export const ORDER_SWITCHES = [
  {
    value: "sales_id",
    label: "Created by",
    type: "select",
    checked: false,
    selectLabel: "Select Rep",
  },
  {
    value: "sort_by",
    label: "Sort by",
    type: "switch",
    checked: false,
    values: ["order_date", "shipping_date"],
  },
  {
    value: "order_status",
    label: "Order status",
    type: "switch",
    values: ORDER_STATUSES,
    checked: false,
    multiple: true,
  },
  {
    value: "payment_status",
    label: "Payment status",
    type: "switch",
    values: PAYMENT_STATUSES,
    checked: false,
    multiple: true,
  },
  {
    value: "delivery_status",
    label: "Fulfillment status",
    type: "switch",
    values: DELIVERY_STATUSES,
    checked: false,
    multiple: true,
  },
  {
    value: "delivery_method",
    label: "Fulfillment method",
    type: "switch",
    values: DELIVERY_METHODS,
    checked: false,
  },
  {
    value: "archived",
    label: "Archived",
    type: "switch",
    values: ARCHIVED,
    checked: false,
  },
];

export const NOTE_FILTERS = {
  date: "",
  sales_id: "",
};

export const TASK_FILTERS = {
  status: "OPEN",
  sales_id: "",
  date: "",
};

export const FILTERS_MAP = {
  0: ORDER_FILTERS,
  1: NOTE_FILTERS,
  2: TASK_FILTERS,
  3: {},
};

export const NOTE_SWITCHES = [
  {
    value: "date",
    label: "Date",
    type: "date",
    checked: false,
    selectLabel: "Select",
  },
  {
    value: "sales_id",
    label: "Created by",
    type: "select",
    checked: false,
    selectLabel: "Select Rep",
  },
];

export const TASK_SWITCHES = [
  {
    value: "status",
    label: "Status",
    type: "switch",
    checked: true,
    values: ["OPEN", "CLOSED"],
  },
  {
    value: "sales_id",
    label: "Assigned to",
    type: "select",
    checked: false,
    selectLabel: "Assigned to",
  },
  {
    value: "date",
    label: "Date",
    type: "date",
    checked: false,
    selectLabel: "Select",
  },
];

export const defaultTableData = {
  loading: { 0: true, 1: true, 2: true, 3: true },
  items: { 0: [], 1: [], 2: [], 3: [] },
  count: 0,
  checkedItems: [],
};

export const TABLE_TABS = { Orders: 0, Notes: 1, Tasks: 2, Timeline: 3 };

export const defaultTableQuery = {
  page: 1,
  limit: SCROLL_LIMIT,
  search: "",
  filters: FILTERS_MAP[0],
};

export const PRIMARY_PAYMENT_METHODS = {
  CREDIT_CARD: "Credit Card",
  CASH: "Cash",
  CHECK: "Check",
};

export const ORDER_ITEM_WIDTH = {
  checkbox: 50,
  order_id: 100,
  date: 5,
  created_by: 5,
  payment: 5.5,
  fulfillment: 5.5,
  total: true,
  actions: 130,
};
