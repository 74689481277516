import { func, object, oneOf, bool } from "prop-types";
import { Controller } from "react-hook-form";
import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import useStyles from "../../styles";
import { IOSSwitch, PriceFormat } from "components";
import { useAdmin } from "helpers/helpers";

export const GeneralTab = ({
  control,
  handleCheckDeliveryFee,
  deliveryFeeType,
  deliveryFee,
  deliveryFeePriceCap,
  minimumOrderValue,
}) => {
  const isAdmin = useAdmin();
  const classes = useStyles();

  return (
    <Box className={classes.ordersBody}>
      <Box>
        <Typography className={classes.blockTitle}>
          Delivery & Handling
        </Typography>
        <Paper className={classes.buttonBlock}>
          <Grid className={classes.gridWrapper}>
            <Grid>
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <IOSSwitch
                    disabled={isAdmin}
                    label={
                      <Box className={classes.switchLabelBlock}>
                        <Typography className={classes.switchLabelFirst}>
                          Delivery
                        </Typography>
                        <Typography className={classes.switchLabelSecond}>
                          Delivery & handling fee
                        </Typography>
                      </Box>
                    }
                    checked={!!field.value}
                    formSx={{ flex: "0 0 34%" }}
                    error={error ? error.message : ""}
                    {...field}
                    onChange={() => {
                      if (isAdmin) return;
                      handleCheckDeliveryFee(field.value);
                    }}
                  />
                )}
                name="deliveryFee"
                control={control}
              />
            </Grid>
            <Grid className={classes.gridWrapperRight}>
              <Controller
                render={({ field, fieldState: { error } }) => {
                  return (
                    <PriceFormat
                      type={deliveryFeeType}
                      noErrorMessage
                      fullWidth
                      disabled={isAdmin || !deliveryFee}
                      InputProps={{
                        sx: {
                          height: "32px",
                          fontSize: "12px",
                          pr: 0,
                          background: "#fff",
                          color: "#707070",
                        },
                        endAdornment: (
                          <Controller
                            render={({ field, fieldState: { error } }) => (
                              <FormControl fullWidth>
                                <Select
                                  disabled={isAdmin || !deliveryFee}
                                  sx={{
                                    height: "32px",
                                    width: "45px",
                                    fontSize: 12,
                                    color: "#707070",
                                    borderRadius: "0",
                                    "& .MuiSelect-select": {
                                      paddingLeft: "8px",
                                      minWidth: "20px",
                                    },
                                    "& .MuiOutlinedInput-notchedOutline": {
                                      borderWidth: "0 0 0 1px !important",
                                      borderColor: "#D5D9D9 !important",
                                    },
                                  }}
                                  size="small"
                                  error={!!error?.message}
                                  {...field}
                                >
                                  <MenuItem value="PERCENTAGE">%</MenuItem>
                                  <MenuItem value="ABSOLUTE">$</MenuItem>
                                </Select>
                              </FormControl>
                            )}
                            name="deliveryFeeType"
                            control={control}
                          />
                        ),
                      }}
                      error={error ? !!error.message : ""}
                      {...field}
                    />
                  );
                }}
                name="deliveryFeeAmount"
                control={control}
              />
            </Grid>
          </Grid>
        </Paper>
        <Paper className={classes.buttonBlock}>
          <Grid className={classes.gridWrapper}>
            <Grid>
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <IOSSwitch
                    disabled={isAdmin || !deliveryFee}
                    hasTooltip={!deliveryFee}
                    tooltipText="Delivery Fee Price Cap cannot be turned on/off because the Delivery Fee is off. Please enable Delivery Fee to access this option."
                    label={
                      <Box className={classes.switchLabelBlock}>
                        <Typography className={classes.switchLabelFirst}>
                          Delivery Fee Price Cap
                        </Typography>
                        <Typography className={classes.switchLabelSecond}>
                          Delivery fee will not add to invoice if order total is
                          greater then specified amount
                        </Typography>
                      </Box>
                    }
                    checked={!!field.value}
                    error={error ? error.message : ""}
                    formSx={{ flex: "0 0 34%" }}
                    {...field}
                    onChange={(e) => {
                      if (isAdmin) return;
                      field.onChange(e);
                    }}
                  />
                )}
                name="deliveryFeePriceCap"
                control={control}
              />
            </Grid>
            <Grid className={classes.gridWrapperRight}>
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <PriceFormat
                    noErrorMessage
                    fullWidth
                    disabled={isAdmin || !deliveryFee || !deliveryFeePriceCap}
                    InputProps={{
                      sx: {
                        height: "32px",
                        fontSize: "12px",
                        pr: 0,
                        background: "#fff",
                        color: "#707070",
                      },
                      startAdornment: (
                        <Typography className={classes.deliveryPriceCapText}>
                          $
                        </Typography>
                      ),
                    }}
                    error={error ? error.message : ""}
                    {...field}
                  />
                )}
                name="deliveryFeePriceCapAmount"
                control={control}
              />
            </Grid>
          </Grid>
        </Paper>
        <Typography className={classes.blockTitle}>Orders</Typography>
        <Paper className={classes.buttonBlock}>
          <Grid className={classes.gridWrapper}>
            <Grid>
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <IOSSwitch
                    disabled={isAdmin}
                    label={
                      <Box className={classes.switchLabelBlock}>
                        <Typography className={classes.switchLabelFirst}>
                          Minimum order value
                        </Typography>
                        <Typography className={classes.switchLabelSecond}>
                          Set the amount that wholesale orders need to meet or
                          exceed.
                        </Typography>
                      </Box>
                    }
                    checked={!!field.value}
                    error={error ? error.message : ""}
                    formSx={{ flex: "0 0 34%" }}
                    {...field}
                    onChange={(e) => {
                      if (isAdmin) return;
                      field.onChange(e);
                    }}
                  />
                )}
                name="minimumOrderValue"
                control={control}
              />
            </Grid>
            <Grid className={classes.gridWrapperRight}>
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <PriceFormat
                    noErrorMessage
                    fullWidth
                    disabled={isAdmin || !minimumOrderValue}
                    InputProps={{
                      sx: {
                        height: "32px",
                        fontSize: "12px",
                        pr: 0,
                        background: "#fff",
                        color: "#707070",
                      },
                      startAdornment: (
                        <Typography className={classes.deliveryPriceCapText}>
                          $
                        </Typography>
                      ),
                    }}
                    error={error ? error.message : ""}
                    {...field}
                  />
                )}
                name="minimumOrderValueAmount"
                control={control}
              />
            </Grid>
          </Grid>
        </Paper>
      </Box>
      <Box>
        <Typography className={classes.blockTitle}>
          Electronic Signature
        </Typography>
        <Paper className={classes.buttonBlock}>
          <Grid className={classes.gridWrapper}>
            <Grid>
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <IOSSwitch
                    disabled={isAdmin}
                    label={
                      <Box className={classes.switchLabelBlock}>
                        <Typography className={classes.switchLabelFirst}>
                          Signature on Orders
                        </Typography>
                        <Typography className={classes.switchLabelSecond}>
                          Use electronic signatures on orders
                        </Typography>
                      </Box>
                    }
                    checked={!!field.value}
                    formSx={{ flex: "0 0 34%" }}
                    error={error ? error.message : ""}
                    {...field}
                    onChange={(e) => {
                      if (isAdmin) return;
                      field.onChange(e);
                    }}
                  />
                )}
                name="signatureOnOrders"
                control={control}
              />
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Box>
  );
};

GeneralTab.propTypes = {
  control: object,
  handleCheckDeliveryFee: func,
  deliveryFeeType: oneOf(["PERCENTAGE", "ABSOLUTE"]),
  deliveryFee: bool,
  deliveryFeePriceCap: bool,
  minimumOrderValue: bool,
};
GeneralTab.defaultProps = {
  handleCheckDeliveryFee: () => {},
  deliveryFeeType: "PERCENTAGE",
  deliveryFee: false,
  deliveryFeePriceCap: false,
  minimumOrderValue: false,
};
