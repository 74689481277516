import { Box, Dialog, DialogContent, IconButton } from "@mui/material";
import { bool, func, array } from "prop-types";
import Slider from "react-slick";
import { CrossIcon, GalleryNextIcon } from "../../../../../../components/Icons";
import ImageBox from "../../../../../../components/ImageComponent/ImageBox";
import { photoUrl } from "../../../../../../helpers/helpers";
import useStyles from "./styles";

const ImageFullscreen = ({ open, onClose, images }) => {
  const classes = useStyles();
  return (
    <Dialog
      {...{ open, onClose }}
      PaperProps={{ className: classes.fullscreenDialog }}
    >
      <IconButton className={classes.closeButton} onClick={onClose}>
        <CrossIcon fill="#5F6267" opacity={1} />
      </IconButton>
      <DialogContent className={classes.fullscreenDialogContent}>
        <Slider
          infinite={false}
          speed={400}
          slidesToShow={1}
          slidesToScroll={1}
          dots
          customPaging={() => <Box className={classes.customPaging} />}
          nextArrow={
            <Box
              className={classes.arrow}
              sx={{
                transform: "translateY(0%)",
              }}
            >
              <GalleryNextIcon width="30" />
            </Box>
          }
          prevArrow={
            <Box
              className={classes.arrow}
              top="10px"
              left="-40px"
              zIndex="10"
              height="100%"
              width="40px"
              sx={{
                transform: "scale(-1, -1)",
                display: "flex!important",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <GalleryNextIcon width="30" />
            </Box>
          }
        >
          {images?.map((photo) => (
            <Box
              key={photo.id}
              className={classes.imageWrapper}
              onClick={onClose}
            >
              <ImageBox
                key={photo.id}
                sx={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  maxWidth: "98%",
                  maxHeight: "98%",
                  zIndex: 10,
                }}
                placeholderSrc={photoUrl(photo?.compressedFileName)}
                src={photoUrl(photo?.fileName)}
              />
              <Box
                className={classes.backgroundWrapper}
                component="img"
                src={photoUrl(photo?.compressedFileName)}
              />
            </Box>
          ))}
        </Slider>
      </DialogContent>
    </Dialog>
  );
};

ImageFullscreen.propTypes = {
  open: bool,
  onClose: func,
  images: array,
};

export default ImageFullscreen;
