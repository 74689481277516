import { object, func } from "prop-types";
import { Box, DialogTitle, IconButton, Typography } from "@mui/material";
import { CrossBigIcon } from "components/Icons";

export const HeaderBlock = ({ cl, handleClose }) => {
  return (
    <DialogTitle sx={cl.blockTitle}>
      <Box sx={cl.blockTitleWrapper}>
        <Typography sx={cl.textTitle}>Fulfill orders</Typography>
      </Box>
      <IconButton sx={{ mr: "12px" }} onClick={handleClose}>
        <CrossBigIcon size={20} />
      </IconButton>
    </DialogTitle>
  );
};

HeaderBlock.propTypes = {
  cl: object,
  handleClose: func,
};
HeaderBlock.defaultProps = {
  cl: {},
  handleClose: () => {},
};
