import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { string, object } from "prop-types";

export const TypographyAddress = ({
  address,
  styles,
  stylesBottom,
  ...props
}) => {
  if (!address) return <Typography> - </Typography>;

  return (
    <Box>
      <Typography sx={{ ...styles }} {...props}>
        {address.split(", ")[0]}
      </Typography>
      <Typography sx={{ ...styles, ...stylesBottom }} {...props}>
        {address.split(", ").slice(1).join(", ")}
      </Typography>
    </Box>
  );
};

TypographyAddress.propTypes = {
  address: string,
  styles: object,
  stylesBottom: object,
};

export default TypographyAddress;
