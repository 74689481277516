import { Link as MyLink } from "react-router-dom";
import { Grid, Paper, Typography, Box, Link } from "@mui/material";
import useStyles from "./styles";
import { MainLogoIcon } from "components/Icons/MainLogos";
import { useLocation } from "react-router-dom";
import { AppStoreBtn, GooglePlayBtn } from "components";
import { APP_STORE_LINK, GOOGLE_PLAY_LINK } from "utils/constants";
import { ActiveIcon } from "components/Icons";

const PasswordUpdatedPage = () => {
  const { state } = useLocation();

  const classes = useStyles();
  return (
    <Box className={classes.background}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Grid
          item
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            p: 1,
          }}
        >
          <Paper className={classes.mainBlock} elevation={3}>
            {state?.role === "distributor" ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    py: "27px",
                  }}
                >
                  <MainLogoIcon width={307} height={45} />
                </Box>
                <Typography
                  fontSize="22px"
                  fontWeight="500"
                  textAlign="center"
                  mt="20px"
                  mb="20px"
                >
                  Password Updated!
                </Typography>
                <Typography fontSize="18px" textAlign="center">
                  Your password has been changed successfully.
                </Typography>
              </>
            ) : (
              <>
                <Box
                  sx={{
                    height: "44px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mb: "6%",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 32,
                      fontWeight: 700,
                      color: "#5F6267",
                    }}
                  >
                    Password updated
                  </Typography>
                </Box>
                <Box
                  sx={{
                    border: "0.5px solid #EEEEEE",
                    borderRadius: "4px",
                    height: "174px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <Typography
                    fontSize={20}
                    fontWeight={700}
                    color="#000000"
                    textAlign="center"
                  >
                    Password changed successfully
                  </Typography>
                  <Box
                    sx={{
                      width: "48px",
                      height: "48px",
                      border: "2px solid #47A06D",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "50%",
                    }}
                  >
                    <ActiveIcon width="21.55" height="16.12" />
                  </Box>
                </Box>
              </>
            )}

            {state?.role === "distributor" && (
              <Typography fontSize="18px" textAlign="center">
                Use your new password to{" "}
                <Link to="/login" underline="hover" component={MyLink}>
                  log in
                </Link>
                .
              </Typography>
            )}
            {state?.role !== "distributor" && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  mt: 4,
                }}
              >
                <Box textAlign="center">
                  <Typography fontSize={22} fontWeight={700} color="#000000">
                    Download the app
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: 2.75,
                    mt: 2.5,
                  }}
                >
                  <AppStoreBtn url={APP_STORE_LINK} />
                  <GooglePlayBtn url={GOOGLE_PLAY_LINK} />
                </Box>
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PasswordUpdatedPage;
