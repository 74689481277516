import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  textInput: {
    fontSize: "15px",
    height: "34px",
    backgroundColor: "#F8F8F8",
    paddingLeft: "25px",
  },

  section: {
    border: "0.5px solid #D5D9D9",
    boxShadow: "none",
  },

  inputLabel: {
    top: "-2px",
    paddingRight: ({ customersSearchInput }) => customersSearchInput && "25px",
    paddingLeft: "25px",
    fontSize: "15px",
    transitionProperty: "all",
    color: "#BDC5C5",
    "&.Mui-focused": {
      paddingLeft: 0,
    },
    "&.MuiFormLabel-filled": {
      paddingLeft: 0,
    },
  },

  stepTab: {
    fontSize: "16px",
    marginTop: "5px",
    paddingBottom: "11px",
    whiteSpace: "nowrap",
  },

  stepTabs: { height: "100%" },

  editBtnWrapper: {
    position: "absolute",
    overflow: "hidden",
    ml: "15px",
    right: "30px",
    width: "min-content",

    "& button": {
      borderColor: "#D4D4D4",
      color: "#000",
      width: "55px",
      height: "20px",
    },
  },

  addressWrapper: {
    border: "0.5px solid #D5D9D9",
    backgroundColor: "#F8F8F8",
    borderRadius: "4px",
    padding: "12.5px 12px",
    overflow: "hidden",
  },

  updateBtn: {
    borderColor: "#D5D9D9",
    color: "#6A6A6A",
    padding: "3px 14px",
  },

  multiTextInput: {
    fontSize: "12px",
  },

  multiInputLabel: {
    fontSize: "12px",
    color: "#CBCBCB",
  },

  stepTitle: {
    color: "#47A06D",
    textTransform: "uppercase",
    fontSize: "14px",
    fontWeight: 400,
  },

  title: {
    color: "#707070",
    fontSize: "25px",
    fontWeight: 600,
  },

  summaryRow: {
    display: "flex",
    color: "#1C1C19",
    fontSize: "16px",
    justifyContent: "space-between",
    marginBottom: "8px",
  },

  customerDiscountRow: {
    display: "flex",
    justifyContent: "space-between",
    borderTop: "0.5px solid #D5D9D9",
    height: "76px",
    background: "rgba(71, 160, 109, 0.1)",
    marginTop: "7px",
    padding: "26px 15px 31px 0",
    alignItems: "center",
  },

  discountRow: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    borderTop: "0.5px solid #D5D9D9",
    paddingRight: "19px",
    paddingTop: "11px",
    minHeight: "72px",
    height: "auto",
    position: "relative",
  },

  discountRowName: {
    display: "flex",
    justifyContent: "space-between",
  },

  discountRowText: {
    margin: "4px 0 10px 20px",
    overflow: "auto",
  },

  discountName: {
    fontSize: "14px",
    color: "#707070",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },

  discountTotal: {
    fontSize: "12px",
    color: "#707070",
    fontWeight: 400,
    whiteSpace: "nowrap",
  },

  tooltip: {
    "& .MuiTooltip-tooltip": {
      transform: "translate(-5px, 0px)!important",
      boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
      backgroundColor: "#fff",
      whiteSpace: "pre-wrap",
      textAlign: "center",
    },

    "& .MuiTooltip-arrow": {
      "&:before": {
        backgroundColor: "#fff",
      },
    },
  },

  secondStepHeaderContainer: {
    gap: "8px",

    "@media (min-width: 1600px)": {
      gap: "11px",
      marginBottom: "1px",
    },
  },

  secondStepContainer: {
    alignItems: "flex-start",

    "@media (min-width: 1600px)": {
      alignItems: "flex-end",
    },
  },
}));

export default useStyles;
