import { useEffect, useCallback, useMemo, useState } from "react";
import { bool, func, shape, string } from "prop-types";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";
import moment from "moment";

import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { CrossIcon } from "components/Icons";
import {
  StyledButton,
  SelectDatePicker,
  StyledPopper,
  Loader,
} from "components";

import { validationSchema } from "./ReportDialog.validations";
import { getCustomOrdersService } from "services/reports";
import { error } from "utils/notifications";
import { FILTER_DATES_REPORT, defaultValues } from "../Report.constants";

import { getCustomersService } from "services/customers";
import { getRepsDuplicatesService, getRepsService } from "services/reps";
import ReportBody from "./components/ReportBody";
import { getProductsService } from "services/products";
import { getManufacturersService } from "services/manufacturers";
import { downloadExcelFile } from "helpers/helpers";
import { getTerritoryListService } from "services/territory";
import { getFlatGroupsService } from "services/parentCustomers";
import { generateFileName } from "./helpers";
import { CustomReportDialog } from "./components";
import { CUSTOMER_STATUS_FILTERS } from "utils/constants";
import { OPTIONAL_CUSTOMER_COLUMN } from "Pages/ReportsPage/ReportsPage.constants";
import { ROLES } from "helpers/useRepsActions";
import { useRepsPermissions } from "helpers/hooks";

const ReportDialog = ({
  open,
  onClose,
  report,
  isCustom,
  handleSetFavorite,
  disabledCustomReportBtn = false,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [anchorElPopper, setAnchorElPopper] = useState(null);
  const [checkInactive, setCheckInactive] = useState(false);
  const [selectedDate, setSelectedDate] = useState("This month");
  const [parentSearchInput, setParentSearchInput] = useState("");
  const [addToFavoritesState, setAddToFavoritesState] = useState({
    show: false,
    open: false,
  });

  const repPermissions = useRepsPermissions();

  const updatedDefaultValues = useMemo(() => {
    const updatedValues = { ...defaultValues };
    updatedValues.date =
      report?.title === "Reps summary" ? "This month" : "Today";
    return updatedValues;
  }, [report?.title]);

  const {
    control,
    setValue,
    reset,
    formState: { isDirty },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      ...updatedDefaultValues,
      groupBy: report?.reportsPerGroup[0] || report?.groupBy || "",
      report,
      selectAndReorderColumns: report?.selectAndReorderColumnsBottom?.filter(
        (el) => el?.chosen
      ),
      selectAndReorderColumnsBottom: report?.selectAndReorderColumnsBottom,
      ...(report?.title === "Customer details" && {
        active: report?.customer?.status === CUSTOMER_STATUS_FILTERS.inactive,
        showProspects:
          report?.customer?.status === CUSTOMER_STATUS_FILTERS.prospect,
      }),
    },
    resolver: yupResolver(validationSchema()),
  });

  const formField = useWatch({ control });

  const isCustomerReport = useMemo(
    () => formField?.report?.title === "Customer details",
    [formField?.report?.title]
  );

  const handleCustomerStatus = useCallback(() => {
    const customerStatus = [];
    if (formField.active) {
      customerStatus.push(CUSTOMER_STATUS_FILTERS.inactive);
    } else {
      customerStatus.push(CUSTOMER_STATUS_FILTERS.active);
    }
    if (formField.showProspects) {
      customerStatus.push(CUSTOMER_STATUS_FILTERS.prospect);
    }

    return customerStatus.length ? JSON.stringify(customerStatus) : null;
  }, [formField.active, formField.showProspects]);

  const currentCustomerStatus = useMemo(
    () => handleCustomerStatus(),
    [handleCustomerStatus]
  );

  useEffect(() => {
    if (formField.category?.length === 0) {
      setValue("category", [{ id: "all", name: "All" }]);
    }
  }, [formField.category, setValue]);

  const hasProductSelected = useMemo(() => {
    return formField?.selectAndReorderColumnsBottom?.find(
      (el) => el.name === "Product"
    )?.selected;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formField?.selectAndReorderColumns?.length]);

  const openTooltip = Boolean(anchorElPopper && !hasProductSelected);

  const hasChosenCol = useMemo(() => {
    return formField?.selectAndReorderColumns?.length > 0;
  }, [formField?.selectAndReorderColumns]);

  const hasUnchosenCol = useMemo(
    () => formField?.selectAndReorderColumnsBottom?.some((col) => !col.chosen),
    [formField]
  );

  const handleDateInput = (newDates) => {
    if (newDates) {
      setValue("date", newDates, { shouldDirty: true });
      setSelectedDate(newDates);
    }
  };

  const prepareQueryDate = useCallback((date, isArray) => {
    if (typeof date === "string") return {};
    if (isArray) {
      return [moment(date?.[0]), moment(date?.[1])];
    }
    const start_date = date?.[0]
      ? moment(date?.[0])?.format()?.split("+")?.[0]
      : "";
    const end_date = date?.[1]
      ? moment(date?.[1])?.format()?.split("+")?.[0]
      : "";
    return { start_date, end_date };
  }, []);

  const getDate = useMemo(() => {
    const isDateString = typeof formField.date === "string";
    const { start_date, end_date } = prepareQueryDate(formField.date);

    return {
      date:
        isDateString && formField?.report?.canGetDate === true
          ? _.snakeCase(formField.date)
          : null,
      start_date: isDateString ? null : start_date,
      end_date: isDateString ? null : end_date,
    };
  }, [formField.date, formField?.report?.canGetDate, prepareQueryDate]);

  const showProspects = useMemo(
    () => report?.config?.isShow?.prospectsShow,
    [report?.config?.isShow?.prospectsShow]
  );

  const showReportPeriodShow = useMemo(
    () => report?.config?.isShow?.reportPeriodShow && report?.canGetDate,
    [report?.config?.isShow?.reportPeriodShow, report?.canGetDate]
  );
  const showNumberOptionsShow = useMemo(
    () => report?.config?.isShow?.numberOptionsShow,
    [report?.config?.isShow?.numberOptionsShow]
  );
  const showArchiveOrders = useMemo(
    () => report?.config?.isShow?.includeArchived,
    [report?.config?.isShow?.includeArchived]
  );
  const showInactiveCustomers = useMemo(
    () =>
      report?.config?.isShow?.check === false &&
      report?.config?.isShow?.includeInactive,
    [report?.config?.isShow?.check, report?.config?.isShow?.includeInactive]
  );
  const showInactive = useMemo(
    () =>
      report?.config?.isShow?.check === true &&
      report?.config?.isShow?.includeInactive,
    [report?.config?.isShow?.check, report?.config?.isShow?.includeInactive]
  );

  const showInactiveReps = useMemo(
    () => report?.config?.isShow?.showInactiveReps,
    [report?.config?.isShow?.showInactiveReps]
  );

  const showWithoutCents = useMemo(
    () => report?.config?.isShow?.withoutCents,
    [report?.config?.isShow?.withoutCents]
  );
  const showNegativeNumbersInRed = useMemo(
    () => report?.config?.isShow?.showNegativeNumbersInRed,
    [report?.config?.isShow?.showNegativeNumbersInRed]
  );

  const handleAddColumn = useCallback(
    (col) => {
      const index = formField?.selectAndReorderColumns?.findIndex(
        (el) => el?.type === col?.type
      );
      const tempArr = [...formField?.selectAndReorderColumns];
      if (index > -1) {
        setValue(
          "selectAndReorderColumns",
          tempArr.filter((el) => el?.type !== col?.type),
          { shouldDirty: true }
        );
      } else {
        setValue(
          "selectAndReorderColumns",
          [...formField?.selectAndReorderColumns, col],
          { shouldDirty: true }
        );
      }
    },
    [formField?.selectAndReorderColumns, setValue]
  );

  const setRepsParams = useMemo(() => {
    const params = {
      limit: 20,
      search: parentSearchInput,
    };

    if (
      formField?.report?.reportCategory === "3rd-party-sales-summary" &&
      formField?.groupBy === "Reps"
    ) {
      params.role = ROLES.third_party;
      params.with_orders = true;
      params.order_type = JSON.stringify([ROLES.third_party]);
    }

    return params;
  }, [
    formField?.groupBy,
    formField?.report?.reportCategory,
    parentSearchInput,
  ]);

  const matchFetchData = useMemo(
    () => [
      {
        id: "Products",
        handleFetch: getProductsService,
        params: {
          limit: 20,
          page: 1,
          stock:
            report?.type === "low_stock"
              ? JSON.stringify(["out", "low"])
              : undefined,
          status:
            !formField.active && showInactive
              ? JSON.stringify(["ACTIVE", "INACTIVE_FOR_CUSTOMERS"])
              : undefined,
          search: parentSearchInput,
          type:
            report?.type === "low_stock" ? JSON.stringify(["INVENTORY"]) : null,
        },
        key: "productIds",
      },
      {
        id: "Manufacturers",
        handleFetch: getManufacturersService,
        params: {
          limit: 20,
          page: 1,
          status:
            !formField.active && showInactive
              ? JSON.stringify(["ACTIVE", "INACTIVE_FOR_CUSTOMERS"])
              : undefined,
          search: parentSearchInput,
        },
        key: "manufacturerIds",
      },
      {
        id: "Categories",
        handleFetch: () => {},
        params: { limit: 20, page: 1, search: parentSearchInput },
        key: "categoryIds",
      },
      {
        id: "Sales reps",
        handleFetch: getRepsService,
        // handleFetch: getRepsDuplicatesService,
        params: {
          limit: 5,
          role: ROLES.sales,
          //active: !formField.active && showInactiveReps ? true : undefined,
          //with_orders: true,
          search: parentSearchInput,
        },
        countField: "countSales",
        key: "representativeIds",
        // key: "representativeDuplicateIds",
      },
      {
        id: "Merchandisers",
        handleFetch: getRepsService,
        // handleFetch: getRepsDuplicatesService,
        params: {
          limit: 20,
          role: ROLES.merchandiser,
          // with_orders: true,
          active: !formField.active && showInactiveReps ? true : undefined,
        },
        countField: "countMerchandiser",
        key: "representativeIds",
        // key: "representativeDuplicateIds",
        search: parentSearchInput,
      },
      {
        id: "Customers",
        handleFetch: getCustomersService,
        params: {
          page: 1,
          limit: 20,
          status: currentCustomerStatus,
          search: parentSearchInput,
        },
        key: "customerIds",
      },
      {
        id: "Reps",
        // handleFetch: getRepsService,
        handleFetch: getRepsDuplicatesService,
        // params: { limit: 20, search: parentSearchInput, with_orders: true },
        params: setRepsParams,
        // key: "representativeIds",
        key: "representativeDuplicateIds",
      },
      {
        id: "Territory",
        handleFetch: getTerritoryListService,
        params: { only_parents: true, search: parentSearchInput },
        key: "territoryIds",
      },
      {
        id: "Customers groups",
        handleFetch: getFlatGroupsService,
        params: {
          limit: 20,
          page: 1,
          with_candidates: false,
          include_uncategorized: true,
          status: currentCustomerStatus,
          search: parentSearchInput,
        },
        key: "parentCustomerIds",
      },
    ],
    [
      report?.type,
      formField.active,
      showInactive,
      parentSearchInput,
      showInactiveReps,
      currentCustomerStatus,
      setRepsParams,
    ]
  );

  const currentGroup = useMemo(
    () => matchFetchData.find((data) => data.id === formField.groupBy),
    [formField.groupBy, matchFetchData]
  );

  useEffect(() => {
    setValue("groupBy", report?.groupBy ?? report?.reportsPerGroup?.[0]);
  }, [report, setValue]);

  useEffect(() => {
    if (!open) return reset({ ...updatedDefaultValues });
    const incomingDate = (date) => {
      if (typeof date === "string" && isCustom) return date;

      return date ? prepareQueryDate(date, true) : selectedDate;
    };

    reset({
      ...updatedDefaultValues,
      ...formField,

      groupByIds:
        report?.groupBy && report?.groupByIds ? report?.groupByIds : [""],
      groupBy:
        formField.groupBy ||
        report?.groupBy ||
        report?.reportsPerGroup?.[0] ||
        "",
      report,
      selectAndReorderColumns: report?.selectAndReorderColumnsBottom?.filter(
        (el) => el?.chosen
      ),
      active: formField.active,
      showProspects: formField.showProspects,
      selectAndReorderColumnsBottom: report?.selectAndReorderColumnsBottom,
      ...report?.body,
      date:
        incomingDate(report?.body?.date) ||
        (report?.start_date
          ? [report?.start_date, report?.end_date]
          : report?.date || updatedDefaultValues.date),
    });

    if (formField?.groupBy === "Categories" || formField.groupBy === "All")
      return;
    if (!formField.groupBy) return;

    setLoading(true);
    const { fetchParam } = report || {};
    currentGroup
      .handleFetch({
        ...currentGroup.params,
        ...fetchParam?.[currentGroup.key],
        ...(report?.url === "customer" && {
          status: currentCustomerStatus,
        }),
      })
      .then((res) => {
        const noAssignmentItem = {
          id: "no_assignment",
          name: "No assignment",
        };

        let itemsList = res?.rows || res;

        const existOrdersWithNoSalesAssigned =
          res?.existOrdersWithNoSalesAssigned;

        if (
          formField?.report?.reportCategory === "3rd-party-sales-summary" &&
          formField?.groupBy === "Reps" &&
          existOrdersWithNoSalesAssigned
        ) {
          itemsList = [noAssignmentItem, ...itemsList];
        }

        setLoading(false);
        setValue("itemsList", itemsList);
        setValue("itemsCount", res[currentGroup.countField] || res.count);
        if (report?.customer)
          setValue(
            "groupByIds",
            _.isArray(report.customer) ? report.customer : [report.customer]
          );

        if (report?.products && formField.groupBy === "Products")
          setValue("groupByIds", report.products);

        if (report?.group) {
          setValue("groupByIds", [report.group]);
          setValue("groupBy", report?.reportsPerGroup?.[1]);
        }
      })
      .catch(() => setLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    open,
    reset,
    setValue,
    formField.groupBy,
    formField.active,
    currentCustomerStatus,
  ]);

  useEffect(() => {
    setValue(
      "showProspects",
      report?.customer?.status === CUSTOMER_STATUS_FILTERS.prospect
    );
    setValue(
      "active",
      report?.customer?.status === CUSTOMER_STATUS_FILTERS.inactive
    );
  }, [report?.customer?.status, setValue]);

  const handleCheckForDuplicate = useCallback(
    (nameCheck, setName) => {
      const currentEl = formField.selectAndReorderColumns.filter(
        ({ name }) => name === nameCheck
      );
      const names = formField[setName].map(({ name }) => name);

      if (
        names.includes(nameCheck) ||
        (setName === "selectAndReorderColumns" &&
          !!currentEl.length &&
          !currentEl[0].chosen)
      )
        return;

      return setValue(setName, [
        OPTIONAL_CUSTOMER_COLUMN,
        ...formField[setName],
      ]);
    },
    [formField, setValue]
  );

  useEffect(() => {
    if ((formField.active || formField.showProspects) && isCustomerReport) {
      handleCheckForDuplicate("Status", "selectAndReorderColumns");
      handleCheckForDuplicate("Status", "selectAndReorderColumnsBottom");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formField.active,
    formField.showProspects,
    formField.selectAndReorderColumns,
    setValue,
    handleCheckForDuplicate,
  ]);

  useEffect(() => {
    setAddToFavoritesState((prev) => ({ ...prev, show: isDirty }));
  }, [isDirty]);

  const handleChangeSelect = useCallback(
    (value) => {
      value === "All"
        ? setValue("groupByIds", [""], { shouldDirty: true })
        : setValue(
            "groupByIds",
            value.filter((el) => el),
            { shouldDirty: true }
          );
    },
    [setValue]
  );
  const onSubmit = async (isAi) => {
    setCheckInactive(true);
    setLoading(true);

    const showInactiveField = ["Customer details", "Current inventory"];

    const {
      withoutCents,
      redNegative,
      selectAndReorderColumns,
      selectAndReorderColumnsBottom,
      active,
      includeArchived,
      includeInactive,
      showProspects,
    } = formField;

    const preparedData = {
      withoutCents,
      includeArchived,
      ...(showInactiveField.includes(formField.report.title) && {
        includeInactive: includeInactive || active,
      }),
    };

    const filteredColumns = selectAndReorderColumns.filter(
      ({ chosen }) => chosen
    );

    if (filteredColumns?.length && isCustom)
      preparedData.fields = filteredColumns
        .flatMap((field) => {
          const { selectWith, type } = field;
          if (selectWith) {
            return [type, ...selectWith.map((item) => item.type)];
          } else {
            return type;
          }
        })
        .filter((item) =>
          formField.includeArchived ? item : item !== "archived"
        );

    if (formField.report.title === "Customer details") {
      preparedData.includeProspects = showProspects;
    }

    if (!isCustom)
      preparedData.fields = selectAndReorderColumnsBottom?.flatMap((field) => {
        const { selectWith, type } = field;
        if (selectWith) {
          return [type, ...selectWith.map((item) => item.type)];
        } else {
          return type;
        }
      });

    const ids =
      formField?.groupBy === "Categories"
        ? [...formField.groupByIds]
        : [...formField.groupByIds.map((g) => g.id)];
    const hasIds = !!ids.filter((id) => !!id).length;
    const uncategorizedIndex = ids.findIndex(
      (id) => id === currentGroup?.uncategorizedItem?.id
    );
    if (uncategorizedIndex > -1) ids.splice(uncategorizedIndex, 1, null);

    preparedData[currentGroup?.key] = hasIds ? ids : undefined;

    try {
      let urlType = "";
      if (report?.title === "Sales summary") {
        urlType = "custom-orders";
        preparedData.redNegative = redNegative;
      }
      if (report?.title === "Reps summary") {
        urlType = report.url;
      }
      if (report?.roleByGroup) {
        const { groupBy } = formField;
        preparedData.role = report.roleByGroup[groupBy];
      }

      if (report.role) {
        preparedData.role = report.role;
      }
      if (report.orderType) {
        preparedData.orderType = report.orderType;
      }
      if (report.type) {
        preparedData.redNegative = report.config.isShow.showNegativeNumbersInRed
          ? redNegative
          : undefined;
        preparedData.type = formField.type || report.type;
        preparedData.active =
          report.config.isShow.showInactiveReps && !active ? true : undefined;
        urlType = report.url;
      }

      if (preparedData?.representativeDuplicateIds?.length) {
        const preparedRepsIds = preparedData?.representativeDuplicateIds.map(
          (repId) => {
            return repId === "no_assignment" ? null : repId;
          }
        );
        preparedData.representativeDuplicateIds = preparedRepsIds;
      }

      if (!urlType) {
        setLoading(false);
        onClose();
        return;
      }
      const params = report.canGetDate ? { ...getDate } : {};
      const fileName = generateFileName(getDate, report.title);

      if (isAi) {
        preparedData.useAITool = true;
        preparedData.fileName = fileName;
      }

      const res = await getCustomOrdersService(
        urlType,
        preparedData,
        params,
        isAi
      );

      if (res.status === 200) {
        if (isAi) {
          window.open(res.data.url, "_blank");
        } else {
          downloadExcelFile(res, "report", fileName);
        }
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      error(err?.response?.data?.message || "Something went wrong.");
    }
    setLoading(false);
    onClose();
    setCheckInactive(false);
  };

  const handleAddFavorite = useCallback(
    async (name) => {
      const { itemsCount, itemsList, report, ...reportData } = formField;

      const sendReport = {
        body: {
          ...reportData,
        },
        reportCategory: report?.reportCategory,
        title: name || report?.title,
        type: report?.type,
        id: report.id,
      };

      await handleSetFavorite(sendReport, false, true);
      reset({}, { keepValues: true });
    },
    [formField, handleSetFavorite, reset]
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: "620px" },
      }}
    >
      <CustomReportDialog
        disabledCustomReportBtn={disabledCustomReportBtn}
        name={report?.title}
        open={addToFavoritesState.open}
        onClose={() =>
          setAddToFavoritesState((prev) => ({ ...prev, open: false }))
        }
        handleSetFavorite={handleAddFavorite}
      />
      <Loader isLoading={isLoading} />
      <StyledPopper
        style={{ zIndex: 1401 }}
        open={openTooltip}
        anchorEl={anchorElPopper}
        text={
          <>
            <Box component="span">
              This item can not be added to the report.
            </Box>
            <br />
            <Box component="span">Please select a product first</Box>
          </>
        }
        modifiers={[
          {
            name: "offset",
            options: { offset: [10, 10] },
          },
        ]}
        placement="top"
        transition
        aria-hidden="true"
      />
      <DialogContent
        sx={{
          width: "620px",
          maxWidth: "620px",
          p: 0,
        }}
      >
        <Grid container>
          <Grid
            sx={{
              borderBottom: "1px solid #D5D9D9",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: "55px",
              pl: "36px",
              pr: "10px",
            }}
            xs={12}
            item
          >
            <Typography fontSize={17} fontWeight={500} color="#3F3F3F" noWrap>
              {/* {isCustom
                ? "Custom Report"
                : !!report?.title && `${report?.title} report`} */}
              {!!report?.title && `${report?.title} report`}
            </Typography>

            <IconButton onClick={onClose}>
              <CrossIcon />
            </IconButton>
          </Grid>

          {(showReportPeriodShow || report?.title === "Reps summary") && (
            <Grid p="10px 36px" xs={12} item>
              <Box mb={1}>
                <Typography
                  fontSize={16}
                  fontWeight={500}
                  color="#6A6A6A"
                  noWrap
                >
                  Report period
                </Typography>
              </Box>
              <Box sx={{ ml: "-1px", zIndex: 100 }}>
                <SelectDatePicker
                  radiused
                  handleDateInput={handleDateInput}
                  value={formField.date || "Today"}
                  fullWidth
                  height="38px"
                  fontSizeLabel="15px"
                  labelPaddingTop={10}
                  datesList={FILTER_DATES_REPORT}
                  handleClearValue={() => setValue("date", "Today")}
                  onChange={(e) => handleDateInput(e.target.value)}
                  doNotApplyDisabledForAdmin
                />
              </Box>
            </Grid>
          )}
          <ReportBody
            {...{
              isCustom,
              control,
              report,
              matchFetchData,
              handleChangeSelect,
              setValue,
              hasChosenCol,
              handleAddColumn,
              setAnchorElPopper,
              hasProductSelected,
              hasUnchosenCol,
              showWithoutCents,
              showInactiveReps,
              showNumberOptionsShow,
              showNegativeNumbersInRed,
              showArchiveOrders,
              showInactiveCustomers,
              showInactive,
              checkInactive,
              showProspects,
              currentGroup,
              setParentSearchInput,
              open,
            }}
          />
          <Grid
            sx={{ borderTop: !isCustom && "1px solid #D5D9D9" }}
            p="27px 36px 34px"
            xs={12}
            item
          >
            <StyledButton
              disabled={isCustom ? !hasChosenCol : false}
              sx={{ height: 48, width: "100%", mb: "15px" }}
              label={
                <Typography fontSize={22} fontWeight={500}>
                  Run Report
                </Typography>
              }
              variant="contained"
              onClick={() => onSubmit()}
            />
            <StyledButton
              disabled={isCustom ? !hasChosenCol : false}
              sx={{ height: 48, width: "100%", mb: "15px" }}
              label={
                <Typography fontSize={22} fontWeight={500}>
                  Run Visual Report
                </Typography>
              }
              variant="contained"
              onClick={() => onSubmit(true)}
            />
            {addToFavoritesState.show && (
              <StyledButton
                sx={{ height: 48, width: "100%" }}
                label={`${
                  report?.body?.isCustom ? "Update in" : "Add to"
                } Favorites`}
                fontSize="22px"
                variant="outlined"
                disabled={!!repPermissions}
                onClick={() => {
                  if (report?.body?.isCustom) {
                    handleAddFavorite();
                  } else
                    setAddToFavoritesState((prev) => ({ ...prev, open: true }));
                }}
              />
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
ReportDialog.propTypes = {
  open: bool,
  disabledCustomReportBtn: bool,
  onClose: func,
  report: shape({
    title: string,
  }),
  isCustom: bool,
  handleSetFavorite: func,
};
ReportDialog.defaultProps = {
  open: false,
  disabledCustomReportBtn: false,
  onClose: () => {},
  report: {
    title: "Default string",
  },
  isCustom: false,
};

export default ReportDialog;
