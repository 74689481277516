import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  dialogTitleBlock: {
    borderBottom: "0.5px solid #d5d9d9",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 14px 10px 32px",
  },

  dialogTitle: {
    fontSize: "17px",
    color: "#3F3F3F",
  },

  currentText: {
    fontSize: "12px",
    color: "#707070",
    marginBottom: "10px",
    textTransform: "uppercase",
    fontWeight: "600",
  },

  data: {
    fontSize: "13px",
    color: "#363531",
    fontWeight: 400,
  },

  dialogContent: {
    padding: "19px 36px 10px 36px!important",
    width: "631px",
  },

  textInput: {
    color: "#363531",
    fontSize: "13px",
    fontWeight: 400,
  },

  dialogActions: {
    padding: "15px 36px 26px 36px",
  },
  inputLabel: {
    top: "-3px",
    color: "rgba(0, 0, 0, 0.38) !important",
    fontSize: "13px",
  },

  actionButton: {
    height: "27px",
    width: "65px",
  },

  contactItem: {
    padding: "7px 9px",
    border: "0.5px solid #D5D9D9",
    borderRadius: "4px",
    width: "fit-content",
    "& .MuiTypography-root": {
      fontSize: "13px",
      fontWeight: 400,
      color: "#363531",
      "&.default": {
        textTransform: "uppercase",
        padding: "0 4.5px",
        backgroundColor: "#26AD69",
        fontSize: "9px",
        fontWeight: 400,
        color: "#ffffff",
        borderRadius: "2px",
      },
    },
  },

  contactTitle: {
    display: "inline-flex",
    alignItems: "center",
    gap: "7px",
  },

  contactsSelect: {
    fontWeight: 400,
    fontSize: "13px",
    color: "#363531",
  },
}));

export default useStyles;
