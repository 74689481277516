import {
  getDraftOrderActivitiesService,
  getDraftOrderByIdService,
  updateDraftOrderService,
} from "services/draft_orders";
import {
  confirmDeliveryService,
  createOrderService,
  deleteDeliveryService,
  deleteReturnService,
  getOrderByIdService,
  getOrdersService,
  sendOrderEmailService,
  updateDeliveryService,
  updateOrderCustomerService,
  updateOrderService,
  getOrderActivitiesService,
} from "../../services/orders";
import { SCROLL_LIMIT } from "../../utils/constants";
import { error, success } from "../../utils/notifications";
import { updateUserFieldAction } from "./auth";
import { setFormChangedAction } from "./confirmDialogs";
import { deleteDraftAction, getDraftsAction } from "./drafts";

export const GET_ORDERS = "GET_ORDERS";
export const SET_EDIT_ORDER = "SET_EDIT_ORDER";
export const GET_ORDERS_PARAMS = "GET_ORDERS_PARAMS";
export const GET_ORDERS_COUNT = "GET_ORDERS_COUNT";
export const CREATE_ORDER = "CREATE_ORDER";
export const START_LOADING_ORDERS = "START_LOADING_ORDERS";
export const END_LOADING_ORDERS = "END_LOADING_ORDERS";
export const UPDATE_ORDER = "UPDATE_ORDER";
export const GET_ACTIVITIES = "GET_ACTIVITIES";
export const GET_ACTIVITIES_COUNT = "GET_ACTIVITIES_COUNT";
export const EXIST_ORDERS = "EXIST_ORDERS";
export const GET_ORDERS_THIRD_PARTY_LIST = "GET_ORDERS_THIRD_PARTY_LIST";
export const GET_ORDERS_THIRD_PARTY_COUNT = "GET_ORDERS_THIRD_PARTY_COUNT";
export const EXIST_ORDERS_THIRD_PARTY = "EXIST_ORDERS_THIRD_PARTY";
export const GET_ORDERS_PARAMS_THIRD_PARTY = "GET_ORDERS_PARAMS_THIRD_PARTY";
export const SET_CURRENT_ORDER_TAB = "SET_CURRENT_ORDER_TAB";
export const ORDERS_GET_FILTER_FIELD = "ORDERS_GET_FILTER_FIELD";
export const ORDERS_GET_SWITCH = "ORDERS_GET_SWITCH";
export const RESET_ORDERS_ACTION_FILTER = "RESET_ORDERS_ACTION_FILTER";
export const RESET_ORDERS_ACTION_SWITCH = "RESET_ORDERS_ACTION_SWITCH";
export const QUICK_FILTER = "QUICK_FILTER";
export const RESET_QUICK_FILTER = "RESET_QUICK_FILTER";
export const GET_ORDERS_HAS_ORDER_DIRECT = "GET_ORDERS_HAS_ORDER_DIRECT";
export const SET_ORDERS_HAS_QB = "SET_ORDERS_HAS_QB";
export const GET_EXIST_WITH_NO_SALES_ASSIGNED =
  "GET_EXIST_WITH_NO_SALES_ASSIGNED";

export const getOrdersList = (payload) => ({
  type: GET_ORDERS,
  payload,
});

export const setCurrentOrderTab = (payload) => ({
  type: SET_CURRENT_ORDER_TAB,
  payload,
});

export const setEditOrder = (payload) => ({
  type: SET_EDIT_ORDER,
  payload,
});

const ordersGetParams = (payload) => ({
  type: GET_ORDERS_PARAMS,
  payload,
});

const getOrdersCount = (payload) => ({
  type: GET_ORDERS_COUNT,
  payload,
});

const getOrdersHasOrderDirect = (payload) => ({
  type: GET_ORDERS_HAS_ORDER_DIRECT,
  payload,
});

const setOrdersHasOrderQB = (payload) => ({
  type: SET_ORDERS_HAS_QB,
  payload,
});

const createOrder = (payload) => ({
  type: CREATE_ORDER,
  payload,
});

const updateOrder = (payload) => ({
  type: UPDATE_ORDER,
  payload,
});

const getActivities = (payload) => ({
  type: GET_ACTIVITIES,
  payload,
});

const getActivitiesCount = (payload) => ({
  type: GET_ACTIVITIES_COUNT,
  payload,
});

const ordersExist = (payload) => ({
  type: EXIST_ORDERS,
  payload,
});

export const getOrdersThirdPartyList = (payload) => ({
  type: GET_ORDERS_THIRD_PARTY_LIST,
  payload,
});

export const getOrdersThirdPartyCount = (payload) => ({
  type: GET_ORDERS_THIRD_PARTY_COUNT,
  payload,
});

const getExistWithNoSalesAssigned = (payload) => ({
  type: GET_EXIST_WITH_NO_SALES_ASSIGNED,
  payload,
});

export const existOrdersThirdParty = (payload) => ({
  type: EXIST_ORDERS_THIRD_PARTY,
  payload,
});

export const getOrdersParamsThirdParty = (payload) => ({
  type: GET_ORDERS_PARAMS_THIRD_PARTY,
  payload,
});

export const ordersGetFilterField = (payload) => ({
  type: ORDERS_GET_FILTER_FIELD,
  payload,
});

export const ordersGetSwitchField = (payload) => ({
  type: ORDERS_GET_SWITCH,
  payload,
});

export const resetOrdersActionsFilter = (payload) => ({
  type: RESET_ORDERS_ACTION_FILTER,
  payload,
});

export const resetOrdersActionsSwitch = (payload) => ({
  type: RESET_ORDERS_ACTION_SWITCH,
  payload,
});

export const getQuickFilter = (payload) => ({
  type: QUICK_FILTER,
  payload,
});

export const resetQuickFilter = (payload) => ({
  type: RESET_QUICK_FILTER,
  payload,
});
export const startLoadingOrders = () => ({ type: START_LOADING_ORDERS });
export const endLoadingOrders = () => ({ type: END_LOADING_ORDERS });

export const getOrdersAction = (query, onSuccess) => {
  return (dispatch, getState) => {
    const state = getState();
    const page = query?.page || 1;
    const limit = query?.limit ?? SCROLL_LIMIT;

    dispatch(startLoadingOrders());

    getOrdersService({
      type: "DIRECT",
      ...state.orders.params,
      ...query,
      limit,
      page,
    })
      .then((res) => {
        dispatch(ordersExist(res.existData));
        dispatch(
          getOrdersList({
            orders: res?.rows,
            isScrolling: page > 1,
          })
        );
        dispatch(getOrdersCount(res.count));
        dispatch(getOrdersHasOrderDirect(res.existCreatedByOrderDirect));
        dispatch(setOrdersHasOrderQB(res.existCreatedByQuickBooks));
        dispatch(endLoadingOrders());
        if (onSuccess) onSuccess();
      })
      .catch((err) => {
        dispatch(endLoadingOrders());
        error(err?.message || "Something went wrong.");
        // eslint-disable-next-line no-console
        console.log(err?.message);
      });
  };
};

export const resetGetActionsFilter = () => {
  return (dispatch) => {
    dispatch(resetOrdersActionsFilter());
  };
};
export const resetGetActionsSwitchOrders = () => {
  return (dispatch) => {
    dispatch(resetOrdersActionsSwitch());
  };
};
export const resetQuickFilterActions = () => {
  return (dispatch) => {
    dispatch(resetQuickFilter());
  };
};

export const resetGlobalOrdersActions = () => {
  return (dispatch) => {
    dispatch(resetGetActionsSwitchOrders());
    dispatch(resetOrdersActionsFilter());
  };
};
export const getActivitiesAction = (orderId, query) => {
  return (dispatch) => {
    dispatch(startLoadingOrders());

    getOrderActivitiesService(orderId, query)
      .then((res) => {
        dispatch(getActivities(res.rows));
        dispatch(getActivitiesCount(res.count));
      })
      .catch((err) => {
        error(err?.message || "Something went wrong.");
        // eslint-disable-next-line no-console
        console.error(err?.message);
      })
      .finally(() => dispatch(endLoadingOrders()));
  };
};

export const getDraftActivitiesAction = (orderId, query) => {
  return (dispatch) => {
    dispatch(startLoadingOrders());

    getDraftOrderActivitiesService(orderId, query)
      .then((res) => {
        dispatch(getActivities(res.rows));
        dispatch(getActivitiesCount(res.count));
      })
      .catch((err) => {
        error(err?.message || "Something went wrong.");
        // eslint-disable-next-line no-console
        console.error(err?.message);
      })
      .finally(() => dispatch(endLoadingOrders()));
  };
};

export const getOrderByIdAction = (id) => {
  return (dispatch) => {
    dispatch(startLoadingOrders());
    getOrderByIdService(id)
      .then((order) => {
        dispatch(setEditOrder(order));
        dispatch(endLoadingOrders());
      })
      .catch((err) => {
        dispatch(endLoadingOrders());

        error(err?.message);
      });
  };
};

export const getDraftOrderByIdAction = (id) => {
  return (dispatch) => {
    dispatch(startLoadingOrders());
    getDraftOrderByIdService(id)
      .then((order) => {
        dispatch(setEditOrder(order));
        dispatch(endLoadingOrders());
      })
      .catch((err) => {
        dispatch(endLoadingOrders());

        error(err?.message);
      });
  };
};

export const createOrderAction = ({
  data,
  navigate,
  deleteDraft,
  onSuccess,
}) => {
  return (dispatch) => {
    dispatch(startLoadingOrders());
    createOrderService(data)
      .then((res) => {
        dispatch(setFormChangedAction(false));
        if (navigate)
          navigate("/orders", {
            state: data?.type === "THIRD_PARTY" ? "3rd Party" : "Direct",
          });
        if (onSuccess) onSuccess(res);
        success("Order created successfully.");
        if (deleteDraft) dispatch(deleteDraftAction());
        dispatch(getDraftsAction());
        dispatch(createOrder(res));
        dispatch(endLoadingOrders());
      })
      .catch((err) => {
        dispatch(endLoadingOrders());
        error(err?.response?.data?.message || "Something went wrong.");
        // eslint-disable-next-line no-console
        console.log(err?.message);
      });
  };
};

export const updateOrderAction = ({ id, data, navigate, onSuccess }) => {
  return (dispatch) => {
    dispatch(startLoadingOrders());
    updateOrderService(id, data)
      .then((res) => {
        dispatch(setFormChangedAction(false));
        if (navigate) {
          navigate(`/orders/${id}`);
        }
        dispatch(setEditOrder(res));
        dispatch(updateOrder(res));
        if (onSuccess) onSuccess(res);
        success("Order updated successfully.");
      })
      .catch((err) => {
        error(err?.response?.data?.message || "Something went wrong.");
        // eslint-disable-next-line no-console
        console.log(err);
        dispatch(endLoadingOrders());
      });
  };
};

export const updateDraftOrderAction = ({ id, data, onSuccess }) => {
  return (dispatch) => {
    dispatch(startLoadingOrders());
    updateDraftOrderService({ id, data })
      .then((res) => {
        dispatch(setEditOrder(res));
        dispatch(updateOrder(res));
        if (onSuccess) onSuccess(res);
      })
      .catch((err) => {
        error(err?.response?.data?.message || "Something went wrong.");
        // eslint-disable-next-line no-console
        console.log(err);
      })
      .finally(() => dispatch(endLoadingOrders()));
  };
};

export const ordersGetFiltersAction = (data) => {
  return (dispatch) => {
    dispatch(ordersGetFilterField(data));
  };
};
export const ordersGetSwitchFieldActions = (data) => {
  return (dispatch) => {
    dispatch(ordersGetSwitchField(data));
  };
};
export const getQuickFilterActions = (data) => {
  return (dispatch) => {
    dispatch(getQuickFilter(data));
  };
};
export const ordersGetParamsAction = (data, onSuccess) => {
  return (dispatch) => {
    dispatch(ordersGetParams(data));
    dispatch(getOrdersAction({ limit: SCROLL_LIMIT, page: 1 }, onSuccess));
  };
};

export const resetOrdersGetParamsAction = () => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(
      ordersGetParamsAction({
        ...state.orders.params,
        order_status: null,
        payment_status: null,
        delivery_status: null,
        delivery_method: null,
        created_by: null,
        sales_id: null,
        sort_by_shipping_date: null,
        sort_by_order_date: "desc",
        tag_id: [],
      })
    );
  };
};

export const confirmOrderDeliveryAction = (
  data,
  handleClose,
  handleGetOrder,
  isOrderPage,
  onSuccess
) => {
  return (dispatch) => {
    dispatch(startLoadingOrders());
    confirmDeliveryService(data)
      .then((order) => {
        onSuccess && onSuccess();
        dispatch(
          updateUserFieldAction("sendNotifications", data.sendNotification)
        );
        dispatch(
          updateUserFieldAction("defaultDeliveryMethod", data.deliveryMethod)
        );
        dispatch(setEditOrder(order));
        dispatch(updateOrder(order));
        dispatch(getOrdersAction({ limit: SCROLL_LIMIT, page: 1 }));
        handleClose();
        dispatch(endLoadingOrders());
        success("Order delivery confirmed successfully.");
        !isOrderPage && handleGetOrder && handleGetOrder(order?.id);
      })
      .catch((err) => {
        dispatch(endLoadingOrders());
        const { deliveredQuantityError, message } = err?.response?.data || {};
        if (deliveredQuantityError)
          error("Something went wrong. Reload your page and try again");
        else error(message || err?.message);
      });
  };
};

export const updateOrderDeliveryAction = ({
  data,
  id,
  handleClose,
  handleGetOrder,
  onSuccess,
}) => {
  return (dispatch) => {
    dispatch(startLoadingOrders());
    updateDeliveryService(data, id)
      .then((order) => {
        dispatch(
          updateUserFieldAction("sendNotifications", data.sendNotification)
        );
        onSuccess && onSuccess();
        dispatch(setEditOrder(order));
        handleClose();
        dispatch(endLoadingOrders());
        success("Order delivery updated successfully.");
        handleGetOrder && handleGetOrder(order?.id);
      })
      .catch((err) => {
        dispatch(endLoadingOrders());
        error(err?.response?.data?.message || err?.message);
      });
  };
};

export const deleteOrderDeliveryAction = (id, handleClose) => {
  return (dispatch) => {
    dispatch(startLoadingOrders());
    deleteDeliveryService(id)
      .then((order) => {
        dispatch(setEditOrder(order));
        if (handleClose) handleClose();
        dispatch(endLoadingOrders());
        success("Order delivery deleted successfully.");
      })
      .catch((err) => {
        dispatch(endLoadingOrders());
        error(err?.response?.data?.message || err?.message);
      });
  };
};

export const deleteOrderReturnAction = (id, handleClose) => {
  return (dispatch) => {
    dispatch(startLoadingOrders());
    deleteReturnService(id)
      .then((order) => {
        dispatch(setEditOrder(order));
        if (handleClose) handleClose();
        dispatch(endLoadingOrders());
        success("Order return deleted successfully.");
      })
      .catch((err) => {
        dispatch(endLoadingOrders());
        error(err?.response?.data?.message || err?.message);
      });
  };
};

export const updateOrderCustomerAction = (id, data, handleClose) => {
  return (dispatch) => {
    dispatch(startLoadingOrders());
    updateOrderCustomerService(id, data)
      .then((order) => {
        dispatch(setEditOrder(order));
        handleClose();
        dispatch(endLoadingOrders());
        success("Order customer updated successfully.");
      })
      .catch((err) => {
        dispatch(endLoadingOrders());
        error(err?.response?.data?.message || err?.message);
      });
  };
};

export const updateDraftOrderCustomerAction = (id, data, handleClose) => {
  return (dispatch) => {
    dispatch(startLoadingOrders());
    updateDraftOrderService({ id, data })
      .then((order) => {
        dispatch(setEditOrder(order));
        handleClose();
        dispatch(endLoadingOrders());
        success("Order draft customer updated successfully.");
      })
      .catch((err) => {
        dispatch(endLoadingOrders());
        error(err?.response?.data?.message || err?.message);
      });
  };
};

export const sendOrderEmailAction = (id, data, handleClose) => {
  return (dispatch) => {
    dispatch(startLoadingOrders());
    sendOrderEmailService(id, data)
      .then(() => {
        dispatch(endLoadingOrders());
        handleClose();
        success("Email sent successfully.");
        dispatch(resetOrdersParamsThirdPartyAction());
        dispatch(getOrdersThirdPartyListAction());
        dispatch(getOrdersAction());
        dispatch(getOrderByIdAction(id));
      })
      .catch((err) => {
        dispatch(endLoadingOrders());
        error(err?.response?.data?.message || err?.message);
      });
  };
};

export const getOrdersThirdPartyListAction = (query) => {
  return (dispatch, getState) => {
    const state = getState();
    getOrdersService({
      type: "THIRD_PARTY",
      ...state.orders.paramsThirdParty,
      ...query,
    })
      .then((res) => {
        dispatch(existOrdersThirdParty(res.existData));
        dispatch(getOrdersThirdPartyList(res.rows));
        dispatch(getOrdersThirdPartyCount(res.count));
        dispatch(getExistWithNoSalesAssigned(res.existWithNoSalesAssigned));
      })
      .catch((err) => {
        error(err?.response?.data?.message || err?.message);
        // eslint-disable-next-line no-console
        console.log(err?.message);
      });
  };
};

export const getOrdersParamsThirdPartyAction = (data) => {
  return (dispatch) => {
    dispatch(getOrdersParamsThirdParty(data));
    dispatch(getOrdersThirdPartyListAction());
  };
};

export const resetOrdersParamsThirdPartyAction = () => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(
      getOrdersParamsThirdPartyAction({
        ...state.orders.paramsThirdParty,
        search: "",
        limit: SCROLL_LIMIT,
        page: 1,
        sort_by_order_date: "desc",
        tag_id: [],
      })
    );
  };
};
