// eslint-disable-next-line react/prop-types
export const MapPinIndexed = ({ index, completed }) => {
  const index_sizes = [17, 13, 13];
  const l = index.toString().length;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="42"
      height="52"
      viewBox="0 0 42 52"
    >
      <defs>
        <filter
          id="Union_165"
          x="0"
          y="0"
          width="42"
          height="52"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g id="Group_3508" data-name="Group 3508" transform="translate(10 7)">
        <g transform="matrix(1, 0, 0, 1, -10, -7)" filter="url(#Union_165)">
          <g
            id="Union_165-2"
            data-name="Union 165"
            transform="translate(10 7)"
            fill={completed ? "#409a65" : "#fff"}
          >
            <path
              d="M 11 32.5 C 10.37075996398926 32.5 9.783419609069824 32.20677185058594 9.38856029510498 31.69549942016602 C 6.415440082550049 27.84886932373047 -0.5 18.18260955810547 -0.5 11.52231979370117 C -0.5 8.315540313720703 0.6932600140571594 5.299489974975586 2.859970092773438 3.029750108718872 C 5.032849788665771 0.7535600066184998 7.923699855804443 -0.5 11 -0.5 C 14.07573986053467 -0.5 16.96639060974121 0.7535499930381775 19.13945960998535 3.029730081558228 C 21.30653953552246 5.29964017868042 22.5 8.315699577331543 22.5 11.52231979370117 C 22.5 18.18304061889648 15.58481979370117 27.84902000427246 12.6117000579834 31.69565010070801 C 12.21695041656494 32.20677185058594 11.629469871521 32.5 11 32.5 Z"
              stroke="none"
            />
            <path
              d="M 11 32 C 11.45116996765137 32 11.90233993530273 31.79612922668457 12.21609020233154 31.38987922668457 C 15.07944011688232 27.68527030944824 22 18.04911041259766 22 11.52231979370117 C 22 5.15923023223877 17.07415008544922 0 11 0 C 4.924369812011719 0 -1.77635683940025e-15 5.15923023223877 -1.77635683940025e-15 11.52231979370117 C -1.77635683940025e-15 18.04911041259766 6.920929908752441 27.68527030944824 9.784279823303223 31.38987922668457 C 10.09803009033203 31.79612922668457 10.54883003234863 32 11 32 M 11 33 C 10.21467971801758 33 9.483099937438965 32.63592147827148 8.992830276489258 32.00112152099609 C 7.663300037384033 30.28096008300781 5.109720230102539 26.82469940185547 2.948189973831177 23.00712966918945 C 0.328359991312027 18.38014984130859 -1 14.51609992980957 -1 11.52231979370117 C -1 8.186349868774414 0.2423900067806244 5.047679901123047 2.498310089111328 2.684499979019165 C 4.766449928283691 0.3085100054740906 7.785739898681641 -1 11 -1 C 14.21368980407715 -1 17.23278045654297 0.3084999918937683 19.5011100769043 2.684469938278198 C 21.75740051269531 5.047830104827881 23 8.18649959564209 23 11.52231979370117 C 23 14.51611042022705 21.67169952392578 18.38015937805176 19.05200004577637 23.00711059570312 C 16.890380859375 26.82501029968262 14.33697032928467 30.28108978271484 13.00730037689209 32.00141906738281 C 12.5172700881958 32.63592147827148 11.78555011749268 33 11 33 Z"
              stroke="none"
              fill={completed ? "#f4f7f4" : "#B1B1B1"}
            />
          </g>
        </g>
      </g>
      <text
        id="_1"
        data-name="1"
        transform={`translate(${index_sizes[l - 1]} ${l > 2 ? 24 : 26})`}
        fill={completed ? "#fff" : "#B1B1B1"}
        fontSize={l > 2 ? "10" : "15"}
        fontFamily="Roboto-Regular, Roboto"
      >
        <tspan x="0" y="0">
          {index}
        </tspan>
      </text>
    </svg>
  );
};
