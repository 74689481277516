import { bool, func, object, array } from "prop-types";
import { LargeCheckbox } from "components";

export const CheckboxBlock = ({
  disabled,
  outOfStock,
  isMissingInfo,
  isChecked,
  handleCheckProduct,
  product,
  manufacturer,
  isNonInventory,
}) => {
  return (
    <LargeCheckbox
      size={16}
      formSx={{ m: 0 }}
      disabled={
        disabled || (!isNonInventory && outOfStock) || !!isMissingInfo?.length
      }
      checked={isChecked}
      onChange={() =>
        handleCheckProduct({
          ...product,
          name: product.name,
          manufacturer,
          quantity: 1,
          itemDiscountAmount: 0,
          itemDiscountType: "PERCENTAGE",
          isNewAdded: true,
        })
      }
    />
  );
};

CheckboxBlock.propTypes = {
  disabled: bool,
  outOfStock: bool,
  isMissingInfo: array,
  isChecked: bool,
  handleCheckProduct: func,
  product: object,
  manufacturer: object,
  isNonInventory: bool,
};
CheckboxBlock.defaultProps = {
  disabled: false,
  outOfStock: false,
  isMissingInfo: [],
  isChecked: false,
  handleCheckProduct: () => {},
  product: {},
  manufacturer: {},
};
