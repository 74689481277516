import * as React from "react";

export const ChangePasswordFilledIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16.5}
    height={17.333}
    viewBox="0 0 16.5 17.333"
    {...props}
  >
    <path
      id="Path_3182"
      data-name="Path 3182"
      d="M36.833,160.333H25.167A1.667,1.667,0,0,0,23.5,162v6.667a1.666,1.666,0,0,0,1.667,1.666H36.833a1.666,1.666,0,0,0,1.667-1.666V162A1.667,1.667,0,0,0,36.833,160.333Z"
      transform="translate(-22.75 -153.75)"
      fill="#409a65"
      stroke="#409a65"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
    <path
      id="Path_3183"
      data-name="Path 3183"
      d="M26,157a2.5,2.5,0,0,1,2.5-2.5h5A2.5,2.5,0,0,1,36,157v3.333H26Z"
      transform="translate(-22.75 -153.75)"
      fill="none"
      stroke="#409a65"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
  </svg>
);
