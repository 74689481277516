import React from "react";
import { Box } from "@mui/material";
import { DollarIcon } from "components/Icons";

const PulsingDiscount = () => {
  return (
    <Box
      sx={{
        boxShadow: "0 0 0 rgba(64, 154, 101, 0.9)",
        animation: "shadowPulse 2s infinite",
        borderRadius: "50%",
        width: "14px",
        height: "14px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 10,
        "&:hover": {
          animation: "none",
        },
      }}
    >
      <DollarIcon />
    </Box>
  );
};

PulsingDiscount.propTypes = {};

export default PulsingDiscount;
