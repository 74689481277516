import { Dialog } from "@mui/material";
import { cl } from "./ReachedUsersLimitDialog.styles";
import {
  DialogBodyComponent,
  DialogFooterComponent,
  DialogHeaderComponent,
  DialogTitleComponent,
} from "./components";
import { useUsersLimitActions } from "./useUsersLimitActions";

export const ReachedUsersLimitDialog = () => {
  const {
    openDialog,
    handleClose,
    state,
    handleSetExtraUser,
    handleChangeCurrentPlan,
    handleSetPayDescription,
    payDescription,
  } = useUsersLimitActions();

  return (
    <Dialog open={openDialog} sx={cl.dialog}>
      <DialogHeaderComponent
        onClose={handleClose}
        title={state.isUpdate ? "Upgrade Now" : "Add representative"}
      />
      <DialogTitleComponent
        {...{ state }}
        planName={state?.current_plan?.subscription?.name}
        usingUserCount={state?.allowedUsers}
      />
      <DialogBodyComponent
        {...{
          state,
          handleSetExtraUser,
          handleChangeCurrentPlan,
          handleSetPayDescription,
        }}
      />
      <DialogFooterComponent
        {...{ state }}
        date={payDescription?.date}
        sum={payDescription?.sum}
        timeZone={payDescription?.timeZone}
      />
    </Dialog>
  );
};
