import { bool, number, string, func } from "prop-types";
import { Box, Button, Paper, Typography } from "@mui/material";

const TextBox = ({ text, sum }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        "&:first-of-type": { borderLeft: "1px solid #D5D9D9", ml: 2 },
        "&:not(:last-of-type)": { borderRight: "1px solid #D5D9D9" },
        maxHeight: "25.5px",
      }}
    >
      <Typography
        sx={{
          pl: 2,
          pr: 2,
        }}
        fontSize={17}
        color="#A5A5A5"
        noWrap
      >
        {text}{" "}
        <Box fontWeight={700} color="#5F6267" component="span">
          {sum}
        </Box>
      </Typography>
    </Box>
  );
};
TextBox.propTypes = {
  text: string,
  sum: number,
};
TextBox.defaultProps = {
  text: "",
};

export const TerritoriesTotalSummaries = ({
  totalTerritories,
  customersWithNoTerritory,
  collapseAll,
  handleCollapse,
}) => {
  return (
    <Paper
      sx={{
        my: "12px",
        px: 1.75,
        height: "64px",
        maxHeight: "64px",
        display: "flex",
        alignItems: "center",
        borderColor: "#CCCCCC",
      }}
      elevation={0}
      variant="outlined"
    >
      <Button
        sx={{
          width: "99px",
          height: "37px",
          bgcolor: collapseAll ? null : "rgba(71, 160, 110, 0.2)",
          fontSize: 20,
          color: collapseAll ? "#707070" : null,
        }}
        onClick={() => handleCollapse(false)}
      >
        Expand
      </Button>
      <Button
        sx={{
          width: "99px",
          height: "37px",
          bgcolor: collapseAll ? "rgba(71, 160, 110, 0.2)" : null,
          fontSize: 20,
          color: collapseAll ? null : "#707070",
        }}
        onClick={() => handleCollapse(true)}
      >
        Collapse
      </Button>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <TextBox text="Total Territories" sum={totalTerritories} />
        <TextBox
          text="Customers with no Territory"
          sum={customersWithNoTerritory}
        />
      </Box>
    </Paper>
  );
};

TerritoriesTotalSummaries.propTypes = {
  totalTerritories: number,
  customersWithNoTerritory: number,
  collapseAll: bool,
  handleCollapse: func,
};
TerritoriesTotalSummaries.defaultProps = {
  totalTerritories: 0,
  customersWithNoTerritory: 0,
  collapseAll: true,
  handleCollapse: () => {},
};
