import { object } from "prop-types";
import { Box, Grid, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import useStyles from "../../../styles";

export const CaseQtyBlock = ({ control }) => {
  const classes = useStyles();

  return (
    <Grid mb="-4px" xs={12} item>
      <Box display="flex" gap={3}>
        <Controller
          render={({ field, fieldState: { error } }) => (
            <TextField
              sx={{ width: "32%" }}
              fullWidth
              size="small"
              placeholder="0"
              label="Items per case *"
              InputProps={{
                className: classes.textInputFilled,
              }}
              InputLabelProps={{
                className: classes.inputLabelFilled,
                shrink: true,
              }}
              error={!!error}
              {...field}
            />
          )}
          name="itemsPerCase"
          control={control}
        />
        <Controller
          render={({ field, fieldState: { error } }) => (
            <TextField
              sx={{ width: "32%" }}
              fullWidth
              size="small"
              label="Minimum order QTY *"
              placeholder="0"
              InputProps={{
                className: classes.textInputFilled,
              }}
              InputLabelProps={{
                className: classes.inputLabelFilled,
                shrink: true,
              }}
              error={!!error}
              {...field}
            />
          )}
          name="minOrderQTY"
          control={control}
        />
      </Box>
    </Grid>
  );
};

CaseQtyBlock.propTypes = {
  control: object,
};
