import { Grid, Typography } from "@mui/material";
import { TAB_HEADER_ITEMS } from "../../../../TransactionsTab.constants";
import { cl } from "../components.style";

export const TransactionsItemsHeader = () => {
  return (
    <Grid sx={cl.headerWrapper} container>
      {TAB_HEADER_ITEMS.map(({ name, size, styles }) => (
        <Grid key={name} sx={styles} xs={size} item container>
          <Typography
            sx={{
              fontSize: 12,
              fontWeight: 400,
              color: "#000000",
            }}
          >
            {name}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};
