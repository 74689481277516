import { string, object, bool } from "prop-types";
import { Box, Typography } from "@mui/material";
import { StyledTooltip } from "components";

export const NameBlock = ({ planeView, name, product }) => {
  const { sku = "", color = "", size = "" } = product || {};

  return planeView ? (
    <>
      <StyledTooltip
        placement="top"
        arrow
        PopperProps={{
          modifiers: [
            {
              name: "offset",
              options: { offset: [0, -6] },
            },
          ],
        }}
        title={
          <Box textAlign="start">
            <Typography fontSize={10}>
              {name} (Pack of {product.itemsPerCase})
            </Typography>
          </Box>
        }
      >
        <Box component="span">
          <Typography fontSize="12px" fontWeight={500} color="#1C1C19" noWrap>
            {name} (Pack of {product.itemsPerCase})
          </Typography>
          {sku && (
            <Typography fontSize="12px" fontWeight={400} color="#1C1C19" noWrap>
              {sku} {color && `/ ${color}`} {size && `/ ${size}`}
            </Typography>
          )}
        </Box>
      </StyledTooltip>
    </>
  ) : (
    <Box pl={1}>
      <Typography fontSize={12} fontWeight={500} color="#1C1C19" noWrap>
        {name}
      </Typography>
      {sku && (
        <Typography fontSize="12px" fontWeight={400} color="#1C1C19" noWrap>
          {sku} {color && `/ ${color}`} {size && `/ ${size}`}
        </Typography>
      )}
    </Box>
  );
};

NameBlock.propTypes = {
  planeView: bool,
  name: string,
  product: object,
  manufacturer: object,
};
NameBlock.defaultProps = {
  planeView: false,
  name: "",
  product: {},
  manufacturer: {},
};
