export const FSQ_CATEGORIES = [
  { id: 17000, name: "Retail" },
  { id: 17002, name: "Antique Store" },
  { id: 17003, name: "Arts and Crafts Store" },
  { id: 17004, name: "Auction House" },
  { id: 17005, name: "Automotive Retail" },
  {
    id: 17006,
    name: "Car Dealership",
  },
  {
    id: 17007,
    name: "Classic and Antique Car Dealership",
  },
  {
    id: 17008,
    name: "New Car Dealership",
  },
  {
    id: 17009,
    name: "RV and Motorhome Dealership",
  },
  {
    id: 17010,
    name: "Used Car Dealership",
  },
  {
    id: 17011,
    name: "Car Parts and Accessories",
  },
  {
    id: 17012,
    name: "Motorcycle Dealership",
  },
  {
    id: 17013,
    name: "Motorsports Store",
  },
  { id: 17014, name: "Baby Store" },
  { id: 17015, name: "Betting Shop" },
  { id: 17016, name: "Big Box Store" },
  { id: 17017, name: "Board Store" },
  { id: 17018, name: "Bookstore" },
  {
    id: 17019,
    name: "Used Bookstore",
  },
  { id: 17020, name: "Boutique" },
  { id: 17021, name: "Cannabis Store" },
  { id: 17022, name: "Comic Book Store" },
  {
    id: 17023,
    name: "Computers and Electronics Retail",
  },
  {
    id: 17024,
    name: "Camera Store",
  },
  {
    id: 17025,
    name: "Electronics Store",
  },
  {
    id: 17026,
    name: "Mobile Phone Store",
  },
  {
    id: 17027,
    name: "Video Games Store",
  },
  {
    id: 17028,
    name: "Construction Supplies Store",
  },
  { id: 17029, name: "Convenience Store" },
  { id: 17030, name: "Cosmetics Store" },
  { id: 17031, name: "Costume Store" },
  { id: 17032, name: "Dance Store" },
  { id: 17033, name: "Department Store" },
  { id: 17034, name: "Discount Store" },
  { id: 17035, name: "Drugstore" },
  { id: 17036, name: "Duty-free Store" },
  { id: 17037, name: "Eyecare Store" },
  { id: 17038, name: "Textiles Store" },
  { id: 17039, name: "Fashion Retail" },
  {
    id: 17040,
    name: "Batik Store",
  },
  {
    id: 17041,
    name: "Bridal Store",
  },
  {
    id: 17042,
    name: "Children's Clothing Store",
  },
  {
    id: 17043,
    name: "Clothing Store",
  },
  {
    id: 17044,
    name: "Fashion Accessories Store",
  },
  {
    id: 17045,
    name: "Jewelry Store",
  },
  {
    id: 17046,
    name: "Lingerie Store",
  },
  {
    id: 17047,
    name: "Men's Store",
  },
  {
    id: 17048,
    name: "Shoe Store",
  },
  {
    id: 17049,
    name: "Sunglasses Store",
  },
  {
    id: 17050,
    name: "Swimwear Store",
  },
  {
    id: 17051,
    name: "Watch Store",
  },
  {
    id: 17052,
    name: "Women's Store",
  },
  { id: 17053, name: "Fireworks Store" },
  { id: 17054, name: "Flea Market" },
  { id: 17055, name: "Floating Market" },
  { id: 17056, name: "Flower Store" },
  {
    id: 17057,
    name: "Food and Beverage Retail",
  },
  {
    id: 17058,
    name: "Beer Store",
  },
  {
    id: 17059,
    name: "Butcher",
  },
  {
    id: 17060,
    name: "Candy Store",
  },
  {
    id: 17061,
    name: "Cheese Store",
  },
  {
    id: 17062,
    name: "Chocolate Store",
  },
  {
    id: 17063,
    name: "Coffee Roaster",
  },
  {
    id: 17064,
    name: "Dairy Store",
  },
  {
    id: 17065,
    name: "Farmers Market",
  },
  {
    id: 17066,
    name: "Fish Market",
  },
  {
    id: 17067,
    name: "Fruit and Vegetable Store",
  },
  {
    id: 17068,
    name: "Gourmet Store",
  },
  {
    id: 17069,
    name: "Grocery Store",
  },
  {
    id: 17070,
    name: "Organic Grocery",
  },
  {
    id: 17071,
    name: "Health Food Store",
  },
  {
    id: 17072,
    name: "Herbs and Spices Store",
  },
  {
    id: 17073,
    name: "Imported Food Store",
  },
  {
    id: 17074,
    name: "Kosher Store",
  },
  {
    id: 17075,
    name: "Kuruyemişçi Shop",
  },
  {
    id: 17076,
    name: "Liquor Store",
  },
  {
    id: 17077,
    name: "Meat and Seafood Store",
  },
  {
    id: 17078,
    name: "Sausage Store",
  },
  {
    id: 17079,
    name: "Turşucu Shop",
  },
  {
    id: 17080,
    name: "Wine Store",
  },
  { id: 17081, name: "Framing Store" },
  {
    id: 17082,
    name: "Furniture and Home Store",
  },
  {
    id: 17083,
    name: "Carpet Store",
  },
  {
    id: 17084,
    name: "Home Appliance Store",
  },
  {
    id: 17085,
    name: "Housewares Store",
  },
  {
    id: 17086,
    name: "Kitchen Supply Store",
  },
  {
    id: 17087,
    name: "Lighting Store",
  },
  {
    id: 17088,
    name: "Mattress Store",
  },
  { id: 17089, name: "Gift Store" },
  { id: 17090, name: "Hardware Store" },
  { id: 17091, name: "Hobby Store" },
  { id: 17092, name: "Knitting Store" },
  { id: 17093, name: "Leather Goods Store" },
  { id: 17094, name: "Luggage Store" },
  { id: 17095, name: "Medical Supply Store" },
  { id: 17096, name: "Miscellaneous Store" },
  { id: 17097, name: "Mobility Store" },
  { id: 17098, name: "Music Store" },
  { id: 17099, name: "Newsagent" },
  { id: 17100, name: "Newsstand" },
  { id: 17101, name: "Garden Center" },
  { id: 17102, name: "Office Supply Store" },
  { id: 17103, name: "Outdoor Supply Store" },
  { id: 17104, name: "Outlet Mall" },
  { id: 17105, name: "Outlet Store" },
  { id: 17106, name: "Packaging Supply Store" },
  { id: 17107, name: "Party Supply Store" },
  { id: 17108, name: "Pawn Shop" },
  { id: 17109, name: "Perfume Store" },
  { id: 17110, name: "Pet Supplies Store" },
  { id: 17111, name: "Pop-Up Store" },
  { id: 17112, name: "Print Store" },
  { id: 17113, name: "Record Store" },
  { id: 17114, name: "Shopping Mall" },
  { id: 17115, name: "Shopping Plaza" },
  { id: 17116, name: "Souvenir Store" },
  { id: 17117, name: "Sporting Goods Retail" },
  {
    id: 17118,
    name: "Baseball Store",
  },
  {
    id: 17119,
    name: "Bicycle Store",
  },
  {
    id: 17120,
    name: "Dive Store",
  },
  {
    id: 17121,
    name: "Fishing Store",
  },
  {
    id: 17122,
    name: "Golf Store",
  },
  {
    id: 17123,
    name: "Gun Store",
  },
  {
    id: 17124,
    name: "Hunting Supply Store",
  },
  {
    id: 17125,
    name: "Running Store",
  },
  {
    id: 17126,
    name: "Skate Store",
  },
  {
    id: 17127,
    name: "Ski Store",
  },
  {
    id: 17128,
    name: "Soccer Store",
  },
  {
    id: 17129,
    name: "Surf Store",
  },
  {
    id: 17130,
    name: "Tennis Store",
  },
  { id: 17131, name: "Stationery Store" },
  { id: 17132, name: "Supplement Store" },
  {
    id: 17133,
    name: "Swimming Pool Supply Store",
  },
  { id: 17134, name: "Tobacco Store" },
  { id: 17135, name: "Toy Store" },
  { id: 17136, name: "Vape Store" },
  { id: 17137, name: "Video Store" },
  {
    id: 17138,
    name: "Vintage and Thrift Store",
  },
  {
    id: 17139,
    name: "Warehouse or Wholesale Store",
  },
  {
    id: 17140,
    name: "Moped Dealership",
  },
  {
    id: 17141,
    name: "Motor Scooter Dealership",
  },
  {
    id: 17142,
    name: "Supermarket",
  },
  { id: 17143, name: "Marijuana Dispensary" },
  { id: 17144, name: "Market" },
  { id: 17145, name: "Pharmacy" },
  { id: 17146, name: "Smoke Shop" },
  { id: 17001, name: "Adult Store" },
];
