import {
  SET_UNREAD_COUNT,
  SET_ROOMS,
  SET_ROOMS_LOADING,
  SET_UNREAD_GROUP_ROOMS_COUNT,
  SET_GROUP_CHAT_ROOMS,
  SET_GROUP_ROOMS_LOADING,
} from "../actions/chat";

const initialState = {
  unreadCount: 0,
  rooms: [],
  loading: false,

  unreadGroupChatCount: 0,
  groupChatRooms: [],
  groupChatloading: false,
};

const chatReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_UNREAD_COUNT:
      return { ...state, unreadCount: payload };

    case SET_UNREAD_GROUP_ROOMS_COUNT:
      return { ...state, unreadGroupChatCount: payload };

    case SET_ROOMS:
      return { ...state, rooms: payload };

    case SET_GROUP_CHAT_ROOMS:
      return { ...state, groupChatRooms: payload };

    case SET_ROOMS_LOADING:
      return { ...state, loading: payload };

    case SET_GROUP_ROOMS_LOADING:
      return { ...state, groupChatloading: payload };

    default:
      return state;
  }
};

export default chatReducer;
