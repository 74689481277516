/* eslint-disable no-useless-escape */
import * as Yup from "yup";
import { DELIVERY_METHODS } from "./ReturnDialog.constants";

export const validationSchema = () =>
  Yup.object().shape({
    trackingId: Yup.string()
      .trim()
      .when("deliveryMethod", {
        is: DELIVERY_METHODS[0],
        then: Yup.string().required("Enter tracking ID"),
      }),
    shippingCarrier: Yup.string()
      .trim()
      .when("deliveryMethod", {
        is: DELIVERY_METHODS[0],
        then: Yup.string().required("Select shipping carrier"),
      }),
    reason: Yup.string().trim().required(),
    otherReason: Yup.string()
      .trim()
      .when("reason", {
        is: "Other",
        then: Yup.string().required("Reason description is missing"),
      }),
    productReturns: Yup.array().test(
      "productReturnsValidation",
      "Select quantity to create return",
      (value) => {
        const productIsChosen = value.some(
          (product) => product.returnQuantity > 0
        );
        return productIsChosen;
      }
    ),
  });
