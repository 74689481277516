import { useMemo, useState } from "react";
import { oneOfType, string, number, object, bool } from "prop-types";
import { Box } from "@mui/material";
import { LocationIcon } from "../Icons";
import { cl } from "./styles";
import { StyledTooltip } from "components";

export const CheckinStatus = ({
  isCheckin,
  borderSize,
  borderProps,
  iconProps,
  showCheckInStatus,
  ...props
}) => {
  const { wrapper, overLine } = cl;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const greenColor = { main: "#26874B", background: "#E7F5EB" };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const redColor = { main: "#FF6254", background: "#FFEFEF" };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const greyColor = { main: "#5F6267", background: "#D4D4D450" };

  const setColor = useMemo(() => {
    if (isCheckin?.locationType === "NO_LOCATION_PROVIDED") return greyColor;
    if (isCheckin?.locationType === "OUTSIDE_STORE") return redColor;
    if (isCheckin?.locationType === "INSIDE_STORE" || isCheckin === null)
      return greenColor;

    return redColor;
  }, [isCheckin, greyColor, redColor, greenColor]);

  const initialTooltip = {
    open: false,
    title: "",
  };
  const [tooltipState, setTooltipState] = useState(initialTooltip);

  const handleClose = () => {
    setTooltipState(initialTooltip);
  };

  const handleOpen = () => {
    if (isCheckin?.locationType === "NO_LOCATION_PROVIDED") {
      setTooltipState({ open: true, title: "Location not provided" });
    }
    if (isCheckin === null) {
      setTooltipState({ open: true, title: "Skipped check in" });
    }

    if (
      ["OUTSIDE_STORE", "INSIDE_STORE"].includes(isCheckin?.locationType) &&
      !showCheckInStatus
    ) {
      setTooltipState({ open: true, title: "Checked In" });
      return;
    }

    if (isCheckin?.locationType === "OUTSIDE_STORE") {
      setTooltipState({ open: true, title: "Out of radius" });
    }

    if (isCheckin?.locationType === "INSIDE_STORE") {
      setTooltipState({ open: true, title: "Verified location" });
    }
  };

  return (
    <StyledTooltip
      open={tooltipState.open}
      title={tooltipState.title}
      placement="top"
      arrow
      onClose={handleClose}
      onOpen={handleOpen}
    >
      <Box
        sx={{
          ...wrapper,
          border: `0.2px solid ${setColor.main}`,
          width: borderSize,
          maxWidth: borderSize,
          height: borderSize,
          maxHeight: borderSize,
          backgroundColor: setColor.background,
          cursor: isCheckin ? "pointer" : "auto",
        }}
        {...borderProps}
        {...props}
      >
        <LocationIcon
          width="8.06"
          height="12.9"
          fill={setColor.main}
          {...iconProps}
        />
        {isCheckin === null ||
        isCheckin?.locationType === "NO_LOCATION_PROVIDED" ? (
          <Box sx={overLine} component="span" />
        ) : null}
      </Box>
    </StyledTooltip>
  );
};

CheckinStatus.propTypes = {
  isCheckin: object,
  borderSize: oneOfType([string, number]),
  borderProps: object,
  iconProps: object,
  showCheckInStatus: bool,
};
CheckinStatus.defaultProps = {
  borderSize: "22.6px",
};
