import React from "react";

export const ShevronRightIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9.889"
      height="17.295"
      viewBox="0 0 9.889 17.295"
    >
      <g
        id="Icon_ionic-ios-arrow-down"
        data-name="Icon ionic-ios-arrow-down"
        transform="translate(0 17.295) rotate(-90)"
      >
        <path
          id="Icon_ionic-ios-arrow-down-2"
          data-name="Icon ionic-ios-arrow-down"
          d="M14.838,18.154l6.54-6.545a1.231,1.231,0,0,1,1.746,0,1.246,1.246,0,0,1,0,1.751l-7.41,7.415a1.234,1.234,0,0,1-1.7.036L6.548,13.365a1.236,1.236,0,0,1,1.746-1.751Z"
          transform="translate(-6.187 -11.246)"
          fill="#707070"
        />
      </g>
    </svg>
  );
};
