import {
  getManufacturersWithDiscountsService,
  getManufacturerByIdWithDiscountsService,
  bulkActiveInactiveDicountsService,
  bulkDeleteDicountsService,
} from "../../services/manufacturers-discounts.js";
import { SCROLL_LIMIT, SCROLL_LIMIT_DISCOUNT } from "../../utils/constants.js";
import { error, success } from "../../utils/notifications.js";

export const START_LOADING_MANUFACTURERS_DISCOUNTS =
  "START_LOADING_MANUFACTURERS_DISCOUNTS";

export const EXIST_MANUFACTURER_DISCOUNTS = "EXIST_MANUFACTURER_DISCOUNTS";

export const END_LOADING_MANUFACTURERS_DISCOUNTS =
  "END_LOADING_MANUFACTURERS_DISCOUNTS";
export const GET_MANUFACTURERS_DISCOUNTS = "GET_MANUFACTURERS_DISCOUNTS";
export const GET_MANUFACTURERS_DISCOUNTS_COUNT =
  "GET_MANUFACTURERS_DISCOUNTS_COUNT";
export const GET_MANUFACTURERS_DISCOUNTS_COUNT_ALL =
  "GET_MANUFACTURERS_DISCOUNTS_COUNT_ALL";
export const SET_MANUFACTURERS_DICOUNTS_BY_ID =
  "SET_MANUFACTURERS_DICOUNTS_BY_ID";
export const SHOW_INACTIVE_DISCOUNTS = "SHOW_INACTIVE_DISCOUNTS";
export const GET_MANUFACTURERS_DICOUNTS_PARAMS =
  "GET_MANUFACTURERS_DICOUNTS_PARAMS";

export const startLoadingManufacturersDiscounts = () => ({
  type: START_LOADING_MANUFACTURERS_DISCOUNTS,
});
export const endLoadingManufacturersDiscounts = () => ({
  type: END_LOADING_MANUFACTURERS_DISCOUNTS,
});

export const getManufacturersDiscounts = (payload) => ({
  type: GET_MANUFACTURERS_DISCOUNTS,
  payload,
});

export const getManufacturersDiscountsCount = (payload) => ({
  type: GET_MANUFACTURERS_DISCOUNTS_COUNT,
  payload,
});

export const getManufacturersDiscountsCountAll = (payload) => ({
  type: GET_MANUFACTURERS_DISCOUNTS_COUNT_ALL,
  payload,
});

const setManufacturersDiscountsById = (payload, id) => ({
  type: SET_MANUFACTURERS_DICOUNTS_BY_ID,
  id,
  payload,
});

export const setShowInactiveDiscounts = (payload) => ({
  type: SHOW_INACTIVE_DISCOUNTS,
  payload,
});

const getManufacturersDiscountsParams = (payload) => ({
  type: GET_MANUFACTURERS_DICOUNTS_PARAMS,
  payload,
});

const manufacturerDiscountsExist = (payload) => ({
  type: EXIST_MANUFACTURER_DISCOUNTS,
  payload,
});

export const getManufacturersDiscountsAction = (params) => {
  return (dispatch, getState) => {
    const state = getState();
    const page = params?.page || 1;
    const limit = params?.limit ?? SCROLL_LIMIT;

    dispatch(startLoadingManufacturersDiscounts());
    getManufacturersWithDiscountsService({
      ...state.manufacturersDiscounts.params,
      ...params,
      status: state.manufacturersDiscounts.showInactive
        ? '["inactive"]'
        : '["active", "inactive_for_customers"]',
      limit,
      page,
    })
      .then((res) => {
        dispatch(manufacturerDiscountsExist(res?.existData));
        dispatch(
          getManufacturersDiscounts({
            manufacturersDiscounts: res?.rows ?? [],
            isScrolling: page > 1,
          })
        );
        dispatch(getManufacturersDiscountsCount(res?.count ?? 0));
        dispatch(getManufacturersDiscountsCountAll(res?.countAll ?? 0));

        dispatch(endLoadingManufacturersDiscounts());
      })
      .catch((err) => {
        dispatch(endLoadingManufacturersDiscounts());
        // eslint-disable-next-line no-console
        console.log(err?.message);
      });
  };
};

export const getManufacturerByIdWithDiscountsAction = (id, params) => {
  return (dispatch, getState) => {
    const state = getState();
    const page = params?.page || 1;
    const limit = params?.limit ?? SCROLL_LIMIT;

    dispatch(startLoadingManufacturersDiscounts());
    getManufacturerByIdWithDiscountsService(id, {
      ...params,
      status: state.manufacturersDiscounts.showInactive
        ? '["inactive"]'
        : '["active", "inactive_for_customers"]',
      limit,
      page,
    })
      .then((res) => {
        dispatch(
          setManufacturersDiscountsById(
            {
              discountsList: res ?? [],
              isScrolling: page > 1,
            },
            id
          )
        );
        dispatch(endLoadingManufacturersDiscounts());
      })
      .catch((err) => {
        dispatch(endLoadingManufacturersDiscounts());
        // eslint-disable-next-line no-console
        console.log(err?.message);
      });
  };
};

export const getManufacturersDiscountsParamsAction = (data) => {
  return (dispatch) => {
    dispatch(getManufacturersDiscountsParams(data));
    dispatch(
      getManufacturersDiscountsAction({
        limit: SCROLL_LIMIT,
        discount_limit: SCROLL_LIMIT_DISCOUNT,
      })
    );
  };
};

export const bulkActiveInactiveDicountsAction = (discountIds) => {
  return (dispatch, getState) => {
    const state = getState();

    bulkActiveInactiveDicountsService(discountIds, {
      active: state.manufacturersDiscounts.showInactive ? "active" : "inactive",
    })
      .then(() =>
        dispatch(
          getManufacturersDiscountsAction({
            limit: SCROLL_LIMIT,
            discount_limit: SCROLL_LIMIT_DISCOUNT,
          })
        )
      )
      // eslint-disable-next-line no-console
      .catch((err) => console.error(err?.message));
  };
};

export const bulkDeleteDiscountsAction = (discountIds) => {
  return (dispatch) => {
    bulkDeleteDicountsService(discountIds)
      .then(() => {
        dispatch(
          getManufacturersDiscountsAction({
            limit: SCROLL_LIMIT,
            discount_limit: SCROLL_LIMIT_DISCOUNT,
          })
        );
        success("Discount deleted successfully.");
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err?.message);
        error(err?.message || "Something went wrong.");
      });
  };
};

export const resetDiscountsGetParamsAction = () => {
  return (dispatch) => {
    dispatch(
      getManufacturersDiscountsParamsAction({
        search: "",
      })
    );
  };
};
