import React from "react";

export const EmailIcon = (props) => {
  return (
    <svg
      width="15"
      height="10"
      viewBox="0 0 15 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.89996 0C1.07154 0 0.399963 0.671573 0.399963 1.5V2.29294C0.425646 2.30204 0.451092 2.31264 0.476197 2.32476L7.07407 5.50994C7.28007 5.60939 7.5202 5.60939 7.7262 5.50994L14.3241 2.32476C14.3491 2.31269 14.3744 2.30214 14.4 2.29306V1.5C14.4 0.671573 13.7284 0 12.9 0H1.89996Z"
        fill="#409A65"
      />
      <path
        d="M14.4 3.95377L8.37832 6.86077C7.76032 7.15911 7.03995 7.15911 6.42195 6.86077L0.399963 3.9536V8.5C0.399963 9.32843 1.07154 10 1.89996 10H12.9C13.7284 10 14.4 9.32843 14.4 8.5V3.95377Z"
        fill="#409A65"
      />
    </svg>
  );
};
