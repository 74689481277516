import { useEffect, useRef, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import useStyles from "./styles";
import { defaultValues } from "./InventoryTab.constants";
import { validationSchema } from "./InventoryTab.validations";
import { useDebounce, useRepsPermissions } from "../../../../../helpers/hooks";
import { updateDistributorSettingsService } from "../../../../../services/account";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentUser } from "../../../../../redux/actions/auth";
import { error } from "../../../../../utils/notifications";
import ArrowBtn from "components/Buttons/ArrowBtn/ArrowBtn";
import { useAdmin } from "helpers/helpers";

const InventoryTab = () => {
  const isAdmin = useAdmin();
  const classes = useStyles();
  const currentUser = useSelector(({ auth }) => auth.currentUser);

  const [loading, setLoading] = useState(false);

  const {
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      lowInventoryThreshold:
        currentUser.lowInventoryThreshold ||
        defaultValues.lowInventoryThreshold,
    },
    resolver: yupResolver(validationSchema()),
  });

  const dispatch = useDispatch();

  const formField = useWatch({ control });

  const formFieldDebounced = useDebounce(formField, 1000);

  const lowInventoryThresholdRef = useRef(formField.lowInventoryThreshold);

  const repPermissions = useRepsPermissions();

  useEffect(() => {
    const errorKeys = Object.keys(errors);
    if (
      errorKeys.length ||
      lowInventoryThresholdRef.current === formField.lowInventoryThreshold
    )
      return;

    if (repPermissions && !repPermissions?.settings?.inventory) return;

    setLoading(true);

    updateDistributorSettingsService({
      lowInventoryThreshold: +formFieldDebounced.lowInventoryThreshold,
    })
      .then((res) => {
        dispatch(setCurrentUser(res));

        lowInventoryThresholdRef.current = formField.lowInventoryThreshold;
      })
      .catch((err) => {
        error(err?.response?.data?.message || "Something went wrong.");
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formFieldDebounced, dispatch]);

  return (
    <>
      <Box
        sx={{ borderBottom: "1px solid #D5D9D9" }}
        className={classes.ordersHeader}
      >
        <Typography fontSize="30px" color="#5F6267">
          Inventory
        </Typography>
      </Box>
      <Box className={classes.ordersBody}>
        <Box>
          <Paper className={classes.buttonBlock}>
            <Grid container>
              <Grid xs={6} item>
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: 500,
                    color: "#000000",
                  }}
                >
                  Create alerts for SKUs that are below a default threshold:
                </Typography>
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: 400,
                    color: "#5F6267",
                  }}
                >
                  Default threshold for low stock notifications
                </Typography>
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: 1,
                }}
                xs={6}
                item
              >
                {loading && (
                  <CircularProgress
                    color="inherit"
                    sx={{ opacity: 0.5 }}
                    size={20}
                  />
                )}

                <Controller
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      disabled={isAdmin || loading}
                      placeholder="0"
                      sx={{ width: "58px", paddingTop: "1px" }}
                      fullWidth
                      InputProps={{
                        className: classes.textInputInventory,
                        endAdornment: (
                          <ArrowBtn
                            stylesBox={{
                              display: "flex",
                              flexDirection: "column",
                              height: "22px",
                            }}
                            stylesUpBtn={{
                              height: "11px",
                              padding: "0 5px",
                              borderRadius: "0 5px 0 0",
                              background: "#EEEEEE",
                              "&:hover": {
                                backgroundColor: "lightgray",
                                cursor: "pointer",
                              },
                            }}
                            stylesDownBtn={{
                              padding: "0 5px",

                              height: "11px",
                              borderRadius: "0 0 5px 0",

                              background: "#EEEEEE",
                              "&:hover": {
                                backgroundColor: "lightgray",
                                cursor: "pointer",
                              },
                            }}
                            onClickUp={() => {
                              if (isAdmin) return;
                              field.onChange(
                                parseInt(field.value, 10) + 1 || 0
                              );
                            }}
                            onClickDown={() => {
                              if (isAdmin) return;
                              field.onChange(
                                parseInt(field.value, 10) - 1 || 0
                              );
                            }}
                          />
                        ),
                      }}
                      InputLabelProps={{
                        className: classes.inputLabel,
                      }}
                      error={!!error}
                      {...field}
                      onChange={(e) => {
                        if (isAdmin) return;
                        field.onChange(e);
                      }}
                    />
                  )}
                  name="lowInventoryThreshold"
                  control={control}
                />
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Box>
    </>
  );
};

export default InventoryTab;
