import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { createSelector } from "reselect";
// import StyledButton from "../../../../../components/StyledButton";
import { currentUserSelector } from "../../../../../redux/selectors/auth";
import { integrationsSyncSelector } from "../../../../../redux/selectors/integrations";
import { IntegrationsCard } from "./components";
import ConnectIntegrationDialog from "./components/ConnectIntegrationDialog";
import { useIntegrations } from "./Integrations.hooks";
import { INTEGRATIONS, INTEGRATIONS_SYNC } from "./IntegrationsTab.constants";
import useStyles from "./styles";

const selector = createSelector(
  currentUserSelector,
  integrationsSyncSelector,
  (currentUser, integrationStatuses) => ({ currentUser, integrationStatuses })
);

const IntegrationsTab = () => {
  const classes = useStyles();
  const [connectOpen, setConnectOpen] = useState(false);
  const [integration, setIntegration] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const { currentUser, integrationStatuses } = useSelector(selector);

  const { handleSync, loading, handleDisconnect, checkShipStation } =
    useIntegrations();

  const handleConnect = async ({ integration, params }) => {
    if (!integration.connectService) return;
    const redirectUri = await integration.connectService(params);
    if (redirectUri) {
      window.open(redirectUri, "_blank");
      setConnectOpen(false);
    }
  };

  const switchTabByQuery = () => {
    const sync = searchParams.get("sync");
    if (sync) {
      handleSync({ syncService: INTEGRATIONS_SYNC[sync], loadingKey: sync });
      searchParams.delete("sync");
      setSearchParams(searchParams);
    }
  };

  useEffect(switchTabByQuery, [searchParams, handleSync, setSearchParams]);

  useEffect(() => {
    checkShipStation();
  }, [checkShipStation]);

  return (
    <>
      <ConnectIntegrationDialog
        open={connectOpen}
        integration={integration}
        handleClose={() => {
          setConnectOpen(false);
        }}
        handleConnect={handleConnect}
      />
      <Box>
        <Box className={classes.titleBlock}>
          <Typography fontSize="30px" color="#5F6267">
            Integration
          </Typography>
          {/* temporarly hidden */}
          {/* <StyledButton
            variant="contained"
            label="Suggest Integration"
            fontSize="14px"
          /> */}
        </Box>
        <Box className={classes.contentBlock}>
          <Typography whiteSpace="pre-wrap" fontSize="14px">
            {`Integrate with our partners.\n\nWhatever your integration needs are, our in-house integration engineering team can scope\nand deliver a solution for your business.`}
          </Typography>
          <Box className={classes.integrationCardsWrapper}>
            {INTEGRATIONS.map((integration) => {
              const key = integration.name.toLowerCase();
              const syncStatus =
                integrationStatuses[key] === "ERROR"
                  ? "PENDING"
                  : integrationStatuses[key];
              const {
                connectionKey,
                hasSync,
                disabled,
                tooltipProps,
                progressBar = { hide: false, tooltip: "" },
              } = integration;
              return (
                <IntegrationsCard
                  key={key}
                  integration={integration}
                  onEnable={(item) => {
                    setIntegration(item);
                    setConnectOpen(true);
                  }}
                  loading={loading === key}
                  isConnected={
                    connectionKey
                      ? !!currentUser[connectionKey] && !!syncStatus
                      : !!syncStatus
                  }
                  onDisable={() =>
                    handleDisconnect({
                      disconnectService: integration.disconnectService,
                      loadingKey: key,
                      confirm: integration.confirmDisconnect,
                    })
                  }
                  syncStatus={syncStatus}
                  onSync={() =>
                    handleSync({
                      syncService: INTEGRATIONS_SYNC[integration.loadingKey],
                      loadingKey: integration.loadingKey,
                    })
                  }
                  hasSync={hasSync}
                  disabled={disabled}
                  tooltipProps={tooltipProps}
                  progressBar={progressBar}
                />
              );
            })}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default IntegrationsTab;
