import { forwardRef } from "react";
import { makeStyles } from "@mui/styles";
import {
  CardContent as MyCardContent,
  Chip as MyChip,
  Checkbox as MyCheckbox,
  Avatar as MyAvatar,
  styled,
} from "@mui/material";

const useStyles = makeStyles(() => ({
  "&.MuiAvatar-root": {
    border: "5px solid rgba(255, 255, 255, 0.68)",
  },
}));

export const Avatar = forwardRef((props, ref) => {
  const classes = useStyles();
  return (
    <MyAvatar {...props} ref={ref} className={classes["&.MuiAvatar-root"]} />
  );
});
Avatar.displayName = "Avatar";

export const CardContent = styled((props) => <MyCardContent {...props} />)(
  () => ({
    ".MuiCardContent-root": {
      padding: 0,
    },
    "&.MuiCardContent-root:last-child": {
      padding: "8px 0 11px",
    },
  })
);

export const Chip = styled((props) => <MyChip {...props} />)(() => ({
  ".MuiChip-label": {
    paddingLeft: "8px",
    paddingRight: "8px",
  },
}));

export const Checkbox = styled((props) => <MyCheckbox {...props} />)(() => ({
  "&.MuiButtonBase-root": {
    right: 14,
    top: 14,
    padding: 0,
    backgroundColor: "white",
  },
}));

//export const Tooltip = styled(({ className, ...props }) => (
//  <MyTooltip {...props} classes={{ popper: className }} />
//))(() => ({
//  "&.map-tooltip": {
//    [`& .${tooltipClasses.tooltip}`]: {
//      backgroundColor: "#ffffff",
//      borderRadius: "8px",
//      border: "0.3px solid #D5D9D9",
//      fontSize: "12px",
//      fontWeight: 400,
//      color: "#A7A6A6",
//      right: "1px",
//    },

//    [`& .${tooltipClasses.arrow}`]: {
//      color: "white",
//      width: "25px",
//      height: "16px",
//      bottom: "-7px",
//      "&:before": {
//        border: "1px solid #D5D9D9",
//      },
//    },
//  },
//  [`& .${tooltipClasses.tooltip}`]: {
//    backgroundColor: "white",
//    fontSize: "14px",
//    fontWeight: 400,
//    color: "#9C9C94",
//    border: "1px solid #D5D9D9",
//  },
//  [`& .${tooltipClasses.arrow}`]: {
//    color: "white",
//    "&:before": {
//      border: "1px solid #D5D9D9",
//    },
//  },
//}));
