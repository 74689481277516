import { Divider } from "@mui/material";
import { Box } from "@mui/system";
import { bool, number, object } from "prop-types";
import StyledButton from "../../../../../../../../../components/StyledButton";
import SelectedLabel from "../../../../SelectedLabel";

const CreateRouteMenu = ({
  showSelectLabel,
  selectedNum,
  createRouteProps,
  editProps,
  cancelProps,
  dividerProps,
  isHideText,
  ...props
}) => {
  return (
    <Box display="flex" alignItems="center" {...props}>
      {showSelectLabel && (
        <>
          <SelectedLabel isHideText={isHideText} {...{ selectedNum }} />
          <Divider
            sx={{ padding: "0px 8px" }}
            orientation="vertical"
            {...dividerProps}
          />
        </>
      )}
      <StyledButton label="Create Route" {...createRouteProps} />
      <Divider orientation="vertical" {...dividerProps} />
      <StyledButton label="Edit" {...editProps} />
      <Divider orientation="vertical" {...dividerProps} />
      <StyledButton label="Cancel" {...cancelProps} />
    </Box>
  );
};

CreateRouteMenu.propTypes = {
  showSelectLabel: bool,
  selectedNum: number,
  createRouteProps: object,
  editProps: object,
  cancelProps: object,
  dividerProps: object,
  isHideText: bool,
};

export default CreateRouteMenu;
