import { useContext } from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { CustomerContext } from "Pages/CustomersPage/pages/CustomerPage/CustomerPage";
import { Controller } from "react-hook-form";
import { OutlinedPlusIcon } from "components/Icons";
import { ExpandMore } from "@mui/icons-material";

export const TerritoryBlock = () => {
  const {
    control,
    territoryList,
    setOpenNewTerritoryModal,
    isAdmin,
    repPermissions,
  } = useContext(CustomerContext);

  return (
    <Grid xs={12} item container>
      <Controller
        render={({ field, fieldState: { error } }) => (
          <FormControl fullWidth>
            <InputLabel
              id={`${field.name}-select-label`}
              sx={{
                top: "-4px",
                fontSize: "13px",
                "&.MuiInputLabel-shrink": {
                  top: "0px",
                },
                color: "#5F6267",
              }}
            >
              Territory
            </InputLabel>
            <Select
              label="Territory"
              labelId={`${field.name}-select-label`}
              sx={{
                height: 43,
                "& .MuiInputBase-input": {
                  fontSize: 13,
                },
                "& fieldset": {
                  border: "1px solid #D5D9D9",
                  "& legend": {
                    width: "11%",
                  },
                },
                color: field.value === "" ? "#717171" : "",
              }}
              fullWidth
              IconComponent={(props) => (
                <ExpandMore sx={{ fontSize: 20 }} {...props} />
              )}
              error={!!error?.message}
              {...field}
            >
              <MenuItem
                disabled={
                  !!repPermissions && !repPermissions?.customers?.territories
                }
                onClick={() => {
                  if (isAdmin) return;
                  setOpenNewTerritoryModal(true);
                }}
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "#FFF",
                  },
                }}
              >
                <OutlinedPlusIcon />
                <Typography
                  fontSize="13px"
                  fontWeight="400"
                  color="#42A57F"
                  ml={1}
                >
                  Add new territory
                </Typography>
              </MenuItem>
              <MenuItem value="Uncategorized">Uncategorized</MenuItem>
              {territoryList?.map((territory) => (
                <MenuItem key={territory?.id} value={territory?.id}>
                  {territory?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        name="territoryId"
        control={control}
      />
    </Grid>
  );
};
