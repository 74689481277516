import {
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Typography,
  Box,
} from "@mui/material";
import { StyledButton } from "components";
import { CrossIcon } from "components/Icons";
import { bool, func, object } from "prop-types";
import { LICENSES } from "utils/constants";

const LicensesDeletePopup = ({ isOpen, handleClose, license, onDelete }) => {
  const setName = (license) => {
    const { type, name } = license || {};

    if (!type) return "";

    if (type === "OTHER" && name) return name;

    const licenseName = LICENSES.find((l) => l.type === type)?.name;

    return licenseName;
  };

  return (
    <Dialog open={isOpen} scroll="body">
      <Box>
        <IconButton
          sx={{ position: "absolute", top: 14, right: 14, p: 0 }}
          onClick={handleClose}
        >
          <CrossIcon />
        </IconButton>
        <Typography
          p="10px 30px 10px 22px"
          fontWeight="400"
          fontSize="20px"
          color="#080808"
          letterSpacing="0.5px"
          marginBottom="8px"
          borderBottom="0.5px solid #CCCCCC"
        >
          Delete licenses?
        </Typography>
        <Typography
          p="5px 0 16px 22px"
          fontWeight="light"
          fontSize="15px"
          color="#707070"
          maxWidth="420px"
        >
          {`Please confirm that you would like to delete license ? `}
          <span style={{ color: "#47A06D" }}>
            {setName(license)}: {license?.documentNumber}
          </span>
        </Typography>
      </Box>

      <DialogContent sx={{ p: "10px 22px 0 47px", minWidth: "413px" }}>
        <Grid display="flex" sx={{ flexDirection: "row-reverse" }} container>
          <StyledButton
            color="confirmDelete"
            sx={{
              height: 28,
              width: 85,
              mb: "13px",
              mr: "5px",
              boxShadow: "none",
            }}
            onClick={() => {
              onDelete(license);
              handleClose();
            }}
            label={
              <Typography fontSize="13px" fontWeight="normal">
                Confirm
              </Typography>
            }
            variant="contained"
          />
          <StyledButton
            sx={{ height: 28, width: 85, mr: "10px", borderColor: "#D4D4D4" }}
            onClick={handleClose}
            label={
              <Typography
                fontSize="13px"
                sx={{
                  color: "#6A6A6A",
                }}
                fontWeight="normal"
              >
                Cancel
              </Typography>
            }
            variant="outlined"
          />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

LicensesDeletePopup.propTypes = {
  isOpen: bool,
  handleClose: func,
  license: object,
  onDelete: func,
};

LicensesDeletePopup.defaultProps = {
  isOpen: false,
};

export default LicensesDeletePopup;
