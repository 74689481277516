import { useCallback } from "react";
import { array, func, object, string } from "prop-types";
import { Box, IconButton, Menu, Typography } from "@mui/material";
import { CrossIcon } from "../../../../components/Icons";
import useStyles from "./styles";
import { StyledProductIco } from "../../../../components/Icons";
import { photoUrl } from "helpers/helpers";

const ProductsMenu = ({
  anchorEl,
  products,
  manufacturerName,
  handleClose,
}) => {
  const classes = useStyles();

  const productName = useCallback((product) => {
    const variation = product?.product?.color || product?.product?.size;

    if (product?.product?.name) {
      return `${product?.product?.name} - ${product?.product?.sku} ${
        variation ? `/ ${variation}` : ""
      }`;
    }

    if (product?.product?.parentProduct?.name) {
      return `${product?.product?.parentProduct?.name} - ${
        product?.product?.sku
      } ${variation ? `/ ${variation}` : ""}`;
    }

    return <></>;
  }, []);

  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={handleClose}
      PaperProps={{ className: classes.menu }}
      sx={{
        ".MuiMenu-list": {
          paddingTop: 0,
          paddingBottom: 0,
        },
      }}
    >
      <Box className={classes.head}>
        <Box className={classes.menuTitleBlock}>
          <Typography className={classes.title}>
            Specific Product Discounts
          </Typography>
        </Box>
        <IconButton onClick={handleClose}>
          <CrossIcon />
        </IconButton>
      </Box>
      {products.map((product) => (
        <Box
          key={product?.product?.id}
          sx={{
            display: "flex",
            borderBottom: "1px solid #B2B2B2",
          }}
        >
          <Box
            sx={{
              width: "38px",
              minWidth: "38px",
              backgroundColor: "white",
              border: "1px solid #D5D9D9",
              borderRadius: "4px",

              margin: "8px 4px 8px 20px",
            }}
            height="38px"
          >
            <StyledProductIco
              src={
                product?.product?.photos?.length
                  ? photoUrl(product?.product?.photos[0].fileName)
                  : ""
              }
              styles={{
                objectFit: "contain",
                width: "100%",
                height: "100%",
                borderRadius: "4px",
              }}
            />
          </Box>
          <Box mt="10px" width="75%">
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: 500,
                color: "#1C1C19",
              }}
              noWrap
            >
              {productName(product)}
            </Typography>
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: 400,
                color: "#1C1C19",
              }}
              noWrap
            >
              {manufacturerName}
            </Typography>
          </Box>
        </Box>
      ))}
    </Menu>
  );
};

ProductsMenu.propTypes = {
  anchorEl: object,
  handleClose: func,
  products: array,
  manufacturerName: string,
};

ProductsMenu.defaultProps = {
  anchorEl: null,
  products: [],
};

export default ProductsMenu;
