import { Grid, Paper, Typography } from "@mui/material";
import { bool } from "prop-types";

const ItemDiscountHeader = ({ hideAvailable }) => {
  return (
    <Paper
      sx={{
        backgroundColor: "#EEEEEE",
        borderRadius: 0,
        borderLeft: "none",
        borderRight: "none",
        alignItems: "center",
        pl: "12px",
        "& .MuiTypography-root": {
          textTransform: "uppercase",
          fontSize: "12px",
          color: "#6A6A6A",
        },
      }}
      elevation={0}
      variant="outlined"
      square
      component={Grid}
      container
      columns={26}
      height="48px"
    >
      <Grid item xs={3.7} pl="5px">
        <Typography>Product name</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography>SKU</Typography>
      </Grid>
      <Grid item xs={4.55}>
        <Typography>Manufacturer</Typography>
      </Grid>
      <Grid item xs={4}>
        {!hideAvailable && <Typography>Available</Typography>}
      </Grid>
      <Grid item xs={3.3}>
        <Typography>Qty</Typography>
      </Grid>
      <Grid item xs={4.3}>
        <Typography>Price</Typography>
      </Grid>
      <Grid item xs={3.15}>
        <Typography>Discount</Typography>
      </Grid>
    </Paper>
  );
};

ItemDiscountHeader.propTypes = {
  hideAvailable: bool,
};

export default ItemDiscountHeader;
