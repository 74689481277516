export const cl = {
  grid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    textDecorationLine: "none",
  },
  gridStart: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    height: "100%",
    position: "relative",
  },
  gridContainer: {
    display: "flex",
    textDecoration: "none",
    alignItems: "center",
    overflow: "hidden",
  },
  orderText: {
    fontSize: 13,
    color: "#1C1C19",
    fontWeight: 600,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",

    "&:hover": {
      textDecoration: "underline",
    },
  },
  typography: {
    fontSize: 13,
    fontWeight: 400,
    color: "#1C1C19",
    textDecorationLine: "none",
  },
  orderName: {
    width: "100%",
    svg: {
      transition: "all 0.3s",
      opacity: 0,
    },

    "&:hover": {
      svg: {
        transition: "all 0.3s",
        opacity: 1,
      },
    },
  },
};
