import { string, any, bool } from "prop-types";
import { Typography, Box } from "@mui/material";
import { StyledTooltip, StyledLabel } from "components";
import { setPhoneNumberMask } from "helpers/helpers";

const TableItem = ({ title, value, isDefault, isEdit, EditField }) => {
  return (
    <Box
      sx={{
        "&:not(:last-of-type)": {
          borderBottom: !isEdit && "1px solid #d5d9d9",
        },
        "& .MuiTypography-root": {
          fontSize: "15px",
        },
      }}
      display="flex"
      justifyContent="space-between"
      ml="20px"
      mr="10px"
      height="62px"
      alignItems="center"
    >
      <Box display="flex" gap="10px" alignItems="center">
        <Typography color="#979797" fontWeight={600}>
          {title}
        </Typography>
        {isDefault && <StyledLabel />}
      </Box>
      {isEdit && !!EditField ? (
        <Box width="308px">{EditField}</Box>
      ) : title === "Website" ? (
        <StyledTooltip
          placement="top"
          arrow
          title={value?.url || ""}
          PopperProps={{
            modifiers: [{ name: "offset", options: { offset: [0, 0] } }],
          }}
          disableHoverListener={!value?.queryString}
        >
          <Typography>
            {value?.domainName || "-"}
            {value?.queryString && <Box component="span">...</Box>}
          </Typography>
        </StyledTooltip>
      ) : (
        <Typography color="#000000">
          {title === "Business Phone" ? setPhoneNumberMask(value) : value}
        </Typography>
      )}
    </Box>
  );
};

TableItem.propTypes = {
  title: string,
  value: any,
  isDefault: bool,
  isEdit: bool,
  EditField: any,
};

export default TableItem;
