import { Box, Typography } from "@mui/material";
import { PadlockIcon } from "components/Icons";

export const PermissionsDenyPage = () => {
  return (
    <Box
      display="flex"
      width="100%"
      height="calc(100vh - 94px)"
      justifyContent="center"
      alignItems="center"
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box
          sx={{
            width: "45px",
            height: "45px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50%",
            border: "1px solid #5F6267",
            mb: 2,
          }}
        >
          <PadlockIcon stroke="#5F6267" width={22.33} height={24.81} />
        </Box>

        <Typography fontSize={25} fontWeight={600} color="#5F6267" mb={2}>
          You need permission to view this page
        </Typography>

        <Typography fontSize={16} fontWeight={400} color="#5F6267">
          Contact your administration to request access.
        </Typography>
      </Box>
    </Box>
  );
};

export default PermissionsDenyPage;
