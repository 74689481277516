import {
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/system";
import { bool, func, any, array, string, number, object } from "prop-types";
import { CrossIcon } from "../../../../components/Icons";
import { StyledTextField } from "../../../../components/TextFields/TextFields";
import useStyles from "./styles";
import { useEffect, useState } from "react";
import { useDebounce } from "../../../../helpers/hooks";
import StyledButton from "../../../../components/StyledButton";
import { InfiniteScrollWrapper } from "components";

const NewMessagePopup = ({
  isOpen,
  handleClose,
  headerTitle,
  ItemWrapper,
  users,
  checkedUsers,
  handleCheckUser,
  handleFetchUsers,
  handleSearchUsers,
  usersCount,
  loading,
  handleCreateRoom,
}) => {
  const classes = useStyles();
  const [searchInput, setSearchInput] = useState("");
  const searchInputDebounced = useDebounce(searchInput, 500);

  useEffect(() => {
    handleSearchUsers(searchInputDebounced);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInputDebounced]);

  useEffect(() => {
    if (searchInput) setSearchInput("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedUsers]);

  useEffect(() => {
    if (isOpen) handleCheckUser("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Dialog open={isOpen} maxWidth="lg">
      <DialogTitle sx={{ p: "9px 26px 11px 26px" }}>
        <IconButton
          sx={{ position: "absolute", top: 5, right: 14 }}
          onClick={handleClose}
        >
          <CrossIcon size="29" />
        </IconButton>
        <Typography className={classes.title}>New message</Typography>
      </DialogTitle>
      <DialogContent sx={{ width: "474px", height: "684px", p: 0 }}>
        <Box>
          <StyledTextField
            value={searchInput}
            placeholder="Search representative"
            onChange={(e) => setSearchInput(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography color="#707070" fontSize="15px" pr="13px">
                    To:
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      maxWidth: "200px",
                      overflowX: "hidden",
                    }}
                  >
                    {!!checkedUsers?.length &&
                      checkedUsers.map((user) => (
                        <Chip
                          key={user?.id}
                          sx={{
                            mr: 0.5,
                            maxWidth: "86px",
                          }}
                          className={classes.chip}
                          label={user?.name}
                          deleteIcon={
                            <CloseIcon
                              style={{ fontSize: "10px", color: "#47A06D" }}
                            />
                          }
                          onDelete={() => handleCheckUser(user)}
                        />
                      ))}
                  </Box>
                </InputAdornment>
              ),
              endAdornment: !!checkedUsers?.length && (
                <InputAdornment position="end">
                  <StyledButton
                    fontSize="15px"
                    fontWeight="500"
                    label="Next"
                    onClick={() => handleCreateRoom(checkedUsers)}
                  />
                </InputAdornment>
              ),
              sx: {
                pl: "26px",
                "& > fieldset": {
                  borderRadius: 0,
                  borderRight: "none",
                  borderLeft: "none",
                },
              },
            }}
          />
        </Box>
        <Box className={classes.table}>
          <Box className={classes.tableHeader}>
            <Typography fontSize="15px" color="#6A6A6A">
              {headerTitle}
            </Typography>
            {loading && <CircularProgress size="20px" />}
          </Box>
          <Box className={classes.tableBody}>
            {users.length ? (
              <InfiniteScrollWrapper
                loading={loading}
                maxHeight="515px"
                dataLength={users.length}
                next={handleFetchUsers}
                loader={
                  <CircularProgress sx={{ ml: "50%", mt: "2px" }} size="20px" />
                }
                hasMore={users.length < usersCount}
                id="reps-messages-table"
              >
                {users.map((user) => (
                  <ItemWrapper
                    key={user.id}
                    user={user}
                    handleCheckUser={handleCheckUser}
                    isChecked={checkedUsers.some(
                      (item) => item?.id === user.id
                    )}
                  />
                ))}
              </InfiniteScrollWrapper>
            ) : loading ? (
              <Box
                sx={{
                  height: "48px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress size="24px" />
              </Box>
            ) : null}
            {!loading && !users.length && (
              <Typography className={classes.noReps}>No reps found</Typography>
            )}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

NewMessagePopup.propTypes = {
  isOpen: bool,
  handleClose: func,
  headerTitle: string,
  ItemWrapper: any,
  users: array,
  checkedUser: object,
  checkedUsers: array,
  handleCheckUser: func,
  handleFetchUsers: func,
  usersCount: number,
  handleSearchUsers: func,
  loading: bool,
  handleCreateRoom: func,
};

NewMessagePopup.defaultProps = {
  isOpen: false,
  handleClose: func,
  users: [],
  headerTitle: "Customers",
  usersCount: 0,
  loading: false,
};

export default NewMessagePopup;
