/* eslint-disable react/prop-types */
import * as React from "react";
export const ArrowDownIcon = ({ fill = "#5f6267", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={5.263}
    height={10.707}
    viewBox="0 0 5.263 10.707"
    {...props}
  >
    <path
      data-name="Icon awesome-long-arrow-alt-down"
      d="M3.588 7.503V.287A.287.287 0 0 0 3.301 0H1.962a.287.287 0 0 0-.287.287v7.216h-1.1a.574.574 0 0 0-.406.979l2.057 2.057a.574.574 0 0 0 .811 0l2.057-2.057a.574.574 0 0 0-.406-.979h-1.1Z"
      fill={fill}
    />
  </svg>
);
