import axios from "axios";

export const getCustomersService = async (data) => {
  return axios({
    url: "/customers",
    params: { ...data },
  }).then((res) => res.data);
};

export const getParentCustomersService = async (data) => {
  return axios({
    url: "/customers/parent-customers",
    params: { ...data },
  }).then((res) => res.data);
};

export const checkCustomerAddressService = async (params) => {
  const { data } = await axios.get("/customers/check-address", { params });
  return data;
};

export const getCustomerByIdService = async (id) => {
  const { data } = await axios.get(`/customers/${id}`);
  return data;
};

export const getDocumentSignedUrl = async (files) => {
  const { data } = await axios({
    method: "POST",
    url: "/customers/document-signed-url",
    data: files,
  });

  return data;
};

export const uploadFile = async ({ url, file, headers, fileType }) => {
  const { data } = await axios({
    method: "PUT",
    headers: {
      ...headers,
      "Content-Type": fileType,
      // "Access-Control-Allow-Origin": "*",
    },
    url,
    data: file,
  });

  return data;
};

export const customersProceedWithDuplicates = async (customersData) => {
  return await axios
    .put("/customers/bulk-proceed-with-duplicates", customersData)
    .then((res) => res.data);
};

export const createCustomerService = async (userData) => {
  return await axios.post("/customers", userData).then((res) => res.data);
};

export const deleteBulkService = async (data, params) => {
  const res = await axios.delete(`/customers`, { data }, params);
  return res.data;
};

export const deleteCustomersService = async (customerIds) => {
  const { data } = await axios.delete(`/customers`, {
    data: { customerIds },
  });
  return data;
};

// export const archiveCustomersService = async (users, active, params) => {
//   const { data } = await axios.post(
//     `/customers/active`,
//     { users },
//     { params: { active, ...params } }
//   );
//   return data;
// };

export const updateCustomerService = async (userData, userId) => {
  const { data } = await axios.put(`/customers/${userId}`, userData);
  return data;
};

export const updateCustomerGroupTerritoryService = async (body, params) => {
  const { data } = await axios.put(
    "/customers/bulk-parent-customer-territory",
    body,
    {
      params,
    }
  );
  return data;
};

export const getCustomersByRepService = async (id, params) => {
  return axios
    .get(`/customers/assigned/${id}`, { params })
    .then((res) => res.data);
};

export const transferCustomersService = async (data) => {
  return axios.put("/transfers/customers", data).then((res) => res.data);
};

export const bulkAssignCustomersService = async (data, params) => {
  return axios.put("/customers/bulk-assign-representatives", data, { params });
};

export const bulkActivationService = async (data, params) => {
  return axios
    .put("/customers/bulk-activation", data, { params })
    .then((res) => res.data);
};

export const bulkUpdatePaymentTermsService = (data) => {
  return axios({
    method: "PUT",
    url: "/customers/bulk-payment-terms",
    data,
  }).then((res) => res.data);
};

export const transferCustomersToAnotherTerritory = async (newTerritory) => {
  return axios
    .put("transfers/customers-territory", newTerritory)
    .then((res) => res.data);
};

export const getTotalSummariesCustomersService = (params) => {
  return axios
    .get("customers/total-summaries", { params })
    .then((res) => res.data);
};

export const checkCustomerInfoAvailabilityService = (params) => {
  return axios
    .get("customers/check-info-availability", { params })
    .then((res) => res.data);
};

export const getAppCustomerByIdService = async (customerId) => {
  const { data } = await axios.get(`/users/app-customers/${customerId}`);
  return data;
};

export const removeAllRepsService = async (customersIds) => {
  const { data } = await axios.delete(`/customers/assign-representatives`, {
    data: { customersIds },
  });
  return data;
};

export const sendEmailToCustomerService = async ({ params, data }) => {
  const res = await axios({
    method: "POST",
    url: "/customers/send-email-to-customer",
    params,
    data,
  });

  return res.data;
};
