import { useMemo } from "react";
import { bool, func } from "prop-types";
import { Box } from "@mui/material";
import { LargeCheckbox } from "components";
import { createSelector } from "reselect";
import { columnLayoutsSelector } from "redux/selectors/settings";
import { useSelector } from "react-redux";

const selector = createSelector(columnLayoutsSelector, (columnLayouts) => ({
  columnLayouts,
}));

export const HeaderThirdParty = ({ allOrdersChecked, checkAllOrders }) => {
  const { columnLayouts } = useSelector(selector);

  const CHECKBOX_WIDTH = 52;
  const ORDER_WIDTH = 72;
  const EMPTY_BLOCK_WIDTH = 120;

  const third_party_tab_disable = columnLayouts.thirdParty.disable;
  const third_party_tab = columnLayouts.thirdParty.main;
  const columns = useMemo(() => {
    if (third_party_tab_disable)
      return [
        ...third_party_tab_disable,
        ...third_party_tab.filter((col) => col.selected),
      ];

    return [];
  }, [third_party_tab, third_party_tab_disable]);

  const columnWidth = {
    Note: 3,
    Date: 12,
    Customer: 24,
    "Assigned to": 12,
    "Created by": 12,
    Total: 10,
    Emailed: 10,
    Tags: 22,
  };

  const defaultColumnWidth = useMemo(
    () => Object.values(columnWidth).reduce((acc, cur) => (acc += cur), 0),
    // eslint-disable-next-line
    []
  );

  const setColumnWidth = (col) => {
    if (col?.name === "Order") return `${ORDER_WIDTH}px`;

    if (col?.name === "Tags")
      return `calc(${columnWidth?.[col?.name]}% - ${ORDER_WIDTH + 8}px)`;

    const column = columnWidth?.[col?.name]
      ? `${columnWidth?.[col?.name]}%`
      : `${
          (100 - defaultColumnWidth) /
          third_party_tab.filter((col) => col.selected).length
        }%`;

    return column;
  };

  return (
    <Box
      sx={{
        borderWidth: "0 0.5px 0 0.5px",
        borderStyle: "solid",
        borderColor: "#D5D9D9",
        width: "100%",
        height: "39px",
        backgroundColor: "#EEEEEE",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "5.6%",
          maxWidth: `${CHECKBOX_WIDTH}px`,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <LargeCheckbox
          sx={{ padding: 0 }}
          formSx={{ marginLeft: 0, marginRight: 0 }}
          checked={allOrdersChecked}
          onChange={checkAllOrders}
        />
      </Box>
      <Box
        sx={{
          width: `calc(100% - ${
            CHECKBOX_WIDTH + ORDER_WIDTH + EMPTY_BLOCK_WIDTH
          }px)`,
          display: "flex",
          alignItems: "center",
        }}
      >
        {columns.map((col) => {
          const valueOfWidth = setColumnWidth(col);
          return (
            <Box
              key={col.id}
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                color: "#6A6A6A",
                textTransform: "uppercase",
                display: "flex",
                alignItems: "center",
                opacity: col.name === "Note" ? 0 : 1,
                pl: col.name === "Tags" ? "38px" : 0,
                gap: 1,
                width: valueOfWidth,
                maxWidth: valueOfWidth,
                minWidth: valueOfWidth,
              }}
            >
              {col?.name}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

HeaderThirdParty.propTypes = {
  allOrdersChecked: bool,
  checkAllOrders: func,
};
