import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { bool, func } from "prop-types";
import { CrossIcon, DangerIcon } from "../../../../components/Icons";
import StyledButton from "../../../../components/StyledButton";

const RouteWarning = ({ open, onClose }) => {
  return (
    <Dialog {...{ open, onClose }}>
      <DialogTitle
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        borderBottom="0.5px solid #d5d9d9"
        sx={{
          p: "16px 12px 11px 17px",
        }}
      >
        <Typography fontSize="18px" color="edit.main">
          {"Can't create route"}
        </Typography>
        <IconButton onClick={onClose}>
          <CrossIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          p: "11px 40px!important",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <DangerIcon size="56.5" />
        <Typography mt="10px" fontSize="15px" color="edit.main">
          {"Some stops can't be accessed"}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", p: "6px 19px 21px 19px" }}>
        <StyledButton
          sx={{ width: "100%" }}
          label="Edit Stops"
          fontSize="14px"
          variant="contained"
          onClick={onClose}
        />
      </DialogActions>
    </Dialog>
  );
};

RouteWarning.defaultProps = {
  open: false,
  onClose: () => {},
};

RouteWarning.propTypes = {
  open: bool,
  onClose: func,
};

export default RouteWarning;
