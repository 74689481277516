import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  profileWrapper: {
    padding: "12px 24px 0",
    display: "flex",
    gap: "16px",
  },

  addressTab: { fontSize: "16px", marginTop: "5px" },

  addressTabs: { height: "100%" },

  titleStyle: { borderBottomWidth: "0 !important", marginBottom: "12px" },
  titleRightBlockStyle: { cursor: "grab" },
}));

export default useStyles;
