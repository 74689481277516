import { useMemo } from "react";

export const useTitles = ({
  hasTrialDialogState,
  hasStatusCanceled,
  hasStatusCanceledWithEndPeriod,
  hasStatusPayFailed,
  hasStatusPayFailedAndIncompleted,
  hasStatusPayFailedAndIncompletedAfter23h,
  hasConfirmSelectionPlan,
  hasTrialEndedWithNextSubscription,
}) => {
  const title = useMemo(() => {
    if (hasConfirmSelectionPlan) return "Confirm selected plan";
    if (hasTrialDialogState || hasTrialEndedWithNextSubscription)
      return "Your trial has ended";
    if (hasStatusCanceled) return "Your subscription has canceled";
    if (hasStatusCanceledWithEndPeriod) return "Your subscription has ended";
    if (
      hasStatusPayFailed ||
      hasStatusPayFailedAndIncompleted ||
      hasStatusPayFailedAndIncompletedAfter23h
    )
      return "Payment failed";
    return "";
  }, [
    hasConfirmSelectionPlan,
    hasStatusCanceled,
    hasStatusCanceledWithEndPeriod,
    hasStatusPayFailed,
    hasStatusPayFailedAndIncompleted,
    hasStatusPayFailedAndIncompletedAfter23h,
    hasTrialDialogState,
    hasTrialEndedWithNextSubscription,
  ]);

  const titleSuccess = useMemo(() => {
    if (
      hasTrialDialogState ||
      hasConfirmSelectionPlan ||
      hasTrialEndedWithNextSubscription
    )
      return "Subscription activated";
    if (
      hasStatusCanceled ||
      hasStatusCanceledWithEndPeriod ||
      hasStatusPayFailed ||
      hasStatusPayFailedAndIncompleted ||
      hasStatusPayFailedAndIncompletedAfter23h
    )
      return "Subscription reactivated";
    return "";
  }, [
    hasConfirmSelectionPlan,
    hasStatusCanceled,
    hasStatusCanceledWithEndPeriod,
    hasStatusPayFailed,
    hasStatusPayFailedAndIncompleted,
    hasStatusPayFailedAndIncompletedAfter23h,
    hasTrialDialogState,
    hasTrialEndedWithNextSubscription,
  ]);

  const subTitle = useMemo(() => {
    if (hasTrialDialogState || hasTrialEndedWithNextSubscription)
      return "Just click 'Activate Now' to restore full access to your Simply Depo account.";
    if (hasStatusCanceled || hasStatusCanceledWithEndPeriod)
      return "Just click 'Reactivate Now' to restore full access to your Simply Depo account.";
    if (
      hasStatusPayFailed ||
      hasStatusPayFailedAndIncompleted ||
      hasStatusPayFailedAndIncompletedAfter23h
    )
      return "Update payment method to restore full access to your Simply Depo account.";

    return "";
  }, [
    hasStatusCanceled,
    hasStatusCanceledWithEndPeriod,
    hasStatusPayFailed,
    hasStatusPayFailedAndIncompleted,
    hasStatusPayFailedAndIncompletedAfter23h,
    hasTrialDialogState,
    hasTrialEndedWithNextSubscription,
  ]);

  const btnName = useMemo(() => {
    if (
      hasTrialDialogState ||
      hasConfirmSelectionPlan ||
      hasTrialEndedWithNextSubscription
    )
      return "Activate Now";
    if (hasStatusCanceled || hasStatusCanceledWithEndPeriod)
      return "Reactivate Now";
    if (
      hasStatusPayFailed ||
      hasStatusPayFailedAndIncompleted ||
      hasStatusPayFailedAndIncompletedAfter23h
    )
      return "Process Payment";

    return "";
  }, [
    hasConfirmSelectionPlan,
    hasStatusCanceled,
    hasStatusCanceledWithEndPeriod,
    hasStatusPayFailed,
    hasStatusPayFailedAndIncompleted,
    hasStatusPayFailedAndIncompletedAfter23h,
    hasTrialDialogState,
    hasTrialEndedWithNextSubscription,
  ]);

  return {
    title,
    titleSuccess,
    subTitle,
    btnName,
  };
};
