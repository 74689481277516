import React from "react";

export const RepsIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="22"
      viewBox="0 0 19.81 21.5"
      {...props}
    >
      <path
        id="Path_2997"
        data-name="Path 2997"
        d="M58,10a4,4,0,1,0-4-4A4,4,0,0,0,58,10Z"
        transform="translate(-48.095 -1.25)"
        // fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Path_2998"
        data-name="Path 2998"
        d="M63.082,22c2.761,0,5.083-2.42,3.52-4.7a10.749,10.749,0,0,0-17.2,0c-1.563,2.276.758,4.7,3.52,4.7Z"
        transform="translate(-48.095 -1.25)"
        // fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};
