import React from "react";

export const NoteIcon = (props) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.0875 2.51256C13.4041 1.82915 12.296 1.82915 11.6126 2.51256L7.35102 6.77417C7.09567 7.02951 6.89311 7.33265 6.75492 7.66628L5.90716 9.71282C5.79106 9.99307 5.85522 10.3157 6.06972 10.5302C6.28421 10.7447 6.6068 10.8088 6.88706 10.6928L8.93385 9.84501C9.26752 9.70681 9.5707 9.50423 9.82607 9.24886L14.0875 4.98744C14.7709 4.30402 14.7709 3.19598 14.0875 2.51256Z"
        fill="#409A65"
      />
      <path
        d="M5.35004 3.5C4.65968 3.5 4.10004 4.05964 4.10004 4.75V11.25C4.10004 11.9404 4.65968 12.5 5.35004 12.5H11.85C12.5404 12.5 13.1 11.9404 13.1 11.25V9C13.1 8.58579 13.4358 8.25 13.85 8.25C14.2642 8.25 14.6 8.58579 14.6 9V11.25C14.6 12.7688 13.3688 14 11.85 14H5.35004C3.83125 14 2.60004 12.7688 2.60004 11.25V4.75C2.60004 3.23122 3.83125 2 5.35004 2H7.60004C8.01425 2 8.35004 2.33579 8.35004 2.75C8.35004 3.16421 8.01425 3.5 7.60004 3.5H5.35004Z"
        fill="#409A65"
      />
    </svg>
  );
};
