import { useCallback, useEffect, useMemo } from "react";
import { bool, func, array } from "prop-types";
import { Drawer } from "@mui/material";
import {
  DueDateDrawerActions,
  DueDateDrawerBody,
  DueDateDrawerHeader,
} from "./components";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema } from "./DueDateDrawer.validations";

export const DueDateDrawer = ({
  checkedTasksIds,
  open,
  handleClose,
  handleSave,
}) => {
  const currentUser = useSelector(({ auth }) => auth.currentUser);
  const { timeZone } = currentUser || {};

  const initState = useMemo(() => {
    const now = moment().utc();
    return {
      dueDate: now.utc().toISOString(),
      addTime: false,
      dueTime: now
        .hour(9)
        .minute(0)
        .second(0)
        .millisecond(0)
        .utc()
        .toISOString(),
      repeated: false,
      repeatInterval: "day",
      repeatStep: "1",
    };
  }, []);

  const {
    control,
    setValue,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onSubmit",
    defaultValues: { ...initState },
    resolver: yupResolver(validationSchema()),
  });

  const formField = useWatch({ control });

  const onSubmit = useCallback(
    (data) => {
      const {
        addTime,
        dueDate,
        dueTime,
        repeatInterval,
        repeatStep,
        repeated,
      } = data || {};

      const preparedData = {
        tasksIds: checkedTasksIds,
        dueDate,
      };

      if (addTime && dueTime)
        preparedData.dueTime = moment(dueTime).utc().format("HH:mm");

      if (repeated) {
        if (repeatInterval === "weekday") {
          preparedData.repeatInterval = repeatInterval.toUpperCase();
          preparedData.repeated = repeated;
        }

        if (repeatInterval && repeatInterval !== "weekday" && repeatStep) {
          preparedData.repeatInterval = repeatInterval.toUpperCase();
          preparedData.repeatStep = +repeatStep;
          preparedData.repeated = repeated;
        }
      }

      handleSave(preparedData);
      handleClose();
    },
    [handleClose, handleSave, checkedTasksIds]
  );

  useEffect(() => {
    if (!open) reset({ ...initState });
  }, [initState, open, reset]);

  return (
    <Drawer anchor="right" open={open}>
      <DueDateDrawerHeader onClose={handleClose} />

      <DueDateDrawerBody
        onSubmit={handleSubmit(onSubmit)}
        timeZone={timeZone}
        control={control}
        setError={setError}
        clearErrors={clearErrors}
        setValue={setValue}
        formField={formField}
      />

      <DueDateDrawerActions
        onClose={handleClose}
        formField={formField}
        errors={errors}
      />
    </Drawer>
  );
};

DueDateDrawer.propTypes = {
  open: bool,
  handleClose: func,
  handleSave: func,
  checkedTasksIds: array,
};
DueDateDrawer.defaultProps = {
  open: false,
  handleClose: () => {},
  handleSave: () => {},
  checkedTasksIds: [],
};
