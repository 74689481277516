import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  scrollhost: {
    overflow: "auto",
    height: "100%",
    maxHeight: ({ maxHeight }) => maxHeight,
    scrollbarWidth: "none",
    "-ms-overflow-style": "none",
    position: "relative",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    marginTop: ({ scrollBarStyle }) => scrollBarStyle?.marginTop || null,
    marginBottom: ({ scrollBarStyle }) => scrollBarStyle?.marginBottom || null,
  },

  scrollhostContainer: {
    position: "relative",
    height: "100%",
    maxHeight: ({ maxHeight }) => maxHeight,
  },

  scrollBar: {
    width: "6px",
    height: "100%",
    maxHeight: ({ maxHeight }) => maxHeight,
    right: ({ scrollBarStyle }) => scrollBarStyle?.right || 0,
    top: "0px",
    position: "absolute",
    borderRadius: "7px",
    bottom: "0px",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    transition: "all 0.3s",
  },

  scrollThumb: {
    width: "6px",
    height: "20px",
    maxHeight: ({ maxHeight }) => maxHeight,
    position: "absolute",
    borderRadius: "7px !important",
    opacity: 1,
    top: 0,
    backgroundColor: "rgba(0, 0, 0, 0.35)",
  },
}));

export default useStyles;
