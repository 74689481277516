import { string, func } from "prop-types";
import { Box, Typography } from "@mui/material";
import { StyledAvatar } from "components";

export const InfoBlock = ({
  name,
  src,
  date,
  onMouseEnter,
  onMouseLeave,
  createdBy,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        pl: 1.8,
        width: "100%",
      }}
    >
      <StyledAvatar
        sx={{ width: 21.27, height: 21.27 }}
        name={name}
        src={src}
        stringStyle={{ fontSize: 9 }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          width: "100%",
          maxWidth: "calc(100% - 45.27px)",
        }}
      >
        <Typography fontSize={16} fontWeight={400} color="#47A06D" noWrap>
          {createdBy === "APP_CUSTOMER" ? "Order direct" : name}
        </Typography>
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: 400,
            color: "#00000030",
            whiteSpace: "nowrap",
          }}
        >
          {date}
        </Typography>
      </Box>
    </Box>
  );
};

InfoBlock.propTypes = {
  name: string,
  src: string,
  date: string,
  onMouseEnter: func,
  onMouseLeave: func,
  createdBy: string,
};
