import { useEffect, useMemo, useRef, useState } from "react";
import { object, bool, array, func, number } from "prop-types";
import { Controller } from "react-hook-form";

import {
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import { CopyIcon, InformationIcon, TrashIcon } from "components/Icons";
import {
  LargeCheckbox,
  SquareButton,
  UploadFile,
  PriceFormat,
  StyledTooltip,
  StyledPopper,
} from "components";

import useStyles from "../../styles";
import { cl } from "./VariationsTabItemList.styles";
import { photoUrl, separateNumWithComma } from "helpers/helpers.js";
import { useVariationsTabItemList } from "./VariationsTabItemList.hooks";
import NumberFormat from "react-number-format";
import {
  MAX_UPLOAD_FILE_RESOLUTION_PRODUCT_MP,
  MAX_UPLOAD_FILE_SIZE_PRODUCT_MB,
} from "utils/constants";
import { error } from "utils/notifications";
import { createSelector } from "reselect";
import {
  confirmDialogFormChangedSelector,
  editTypeSelector,
} from "redux/selectors/confirmDialogs";
import { useSelector } from "react-redux";

const selector = createSelector(
  editTypeSelector,
  confirmDialogFormChangedSelector,
  (editType, formChanged) => ({
    editType,
    formChanged,
  })
);

export const VariationsTabItemList = ({
  product,
  onDelete,
  position,
  handleCheckProduct,
  selectedOnlySku,
  isChecked,
  control,
  setValue,
  clearErrors,
  sortedFieldsList,
  isEdit,
  errors,
  handler,
  saveDataAfterChangePhotos,
  styles = {},
  trigger,
  disabledUploadFile,
}) => {
  const classes = useStyles();
  const { editType, formChanged } = useSelector(selector);
  const photoUploading = editType === "product-photo" && formChanged;

  const [photoHovered, setPhotoHovered] = useState(false);

  const retailPriceRef = useRef();

  const { showCopyBtn, handleClickCopy, formField } = useVariationsTabItemList({
    isEdit,
    sortedFieldsList,
    product,
    position,
    control,
    setValue,
    clearErrors,
  });

  const [progressPhotoUpload, setProgressPhotoUpload] = useState(false);
  const [preparingPhoto, setPreparingPhoto] = useState(false);

  const areAllFieldsFilled = (variation) => {
    return (
      variation.itemsPerCase !== undefined &&
      variation.retailPrice !== undefined &&
      variation.wholesalePrice !== undefined
    );
  };
  const setItemPerCase = (length) => {
    if (!length) return "18px";
    if (length === 2) return "15px";
    if (length === 3) return "12px";
    if (length > 3) return "8px";
    return "18px";
  };

  const handleSetPhoto = (data) => {
    setProgressPhotoUpload(false);
    setValue(`variationsFields[${position ?? 0}].photos`, [data]);
    saveDataAfterChangePhotos({
      successMsg: "Photo added",
      data: formField?.photos,
      variationsData: formField?.variationsFields,
    });
  };

  const [isCroppedDialogOpen, setIsCroppedDialogOpen] = useState(false);

  // const disabledUploadFile = useMemo(() => {
  //   const errArr = Array.isArray(errors?.variationsFields)
  //     ? errors?.variationsFields
  //     : [];

  //   const errorsList = errArr?.filter(
  //     (e) => e?.sku?.type || e?.size?.type || e?.color?.type
  //   );

  //   return errorsList;
  // }, [errors?.variationsFields]);

  const tooltipRef = useRef(null);

  const [tooltipData, setTooltipData] = useState({
    open: false,
    text: "",
    modifiers: [],
  });

  useEffect(() => {
    setTooltipData((prev) => ({
      ...prev,
      text: disabledUploadFile?.length ? (
        <>
          <Box component="span" textAlign="start" fontSize={12}>
            Ensure the product name is valid and complete to upload photos
          </Box>
        </>
      ) : (
        <>
          <Box component="span" textAlign="start" fontSize={12}>
            • Use at least 500 px by 500 px <br /> • Use white or neutral
            background
          </Box>
        </>
      ),
      modifiers: [
        {
          name: "offset",
          options: { offset: [disabledUploadFile?.length ? 0 : 8, 10] },
        },
      ],
    }));
  }, [disabledUploadFile]);

  const calculatedRetailPrices = formField?.variationsFields.map(
    (variation) => {
      if (
        variation.retailPrice !== undefined &&
        variation.itemsPerCase !== undefined
      ) {
        return variation.retailPrice / variation.itemsPerCase;
      }
      return null;
    }
  );

  const calculatedWholesalePrices = formField?.variationsFields.map(
    (variation) => {
      if (
        variation.wholesalePrice !== undefined &&
        variation.itemsPerCase !== undefined
      ) {
        return variation.wholesalePrice / variation.itemsPerCase;
      }
      return null;
    }
  );

  const setTooltipTitleRetailPrice = useMemo(() => {
    if (errors?.variationsFields?.[position]?.retailPrice?.message) {
      return (
        <Typography fontSize="12px">
          {errors?.variationsFields?.[position]?.retailPrice?.message}
        </Typography>
      );
    }
    if (calculatedRetailPrices && calculatedRetailPrices[position] !== null) {
      return (
        <Typography fontSize="12px">
          ${separateNumWithComma(calculatedRetailPrices[position])} per item
        </Typography>
      );
    }
  }, [calculatedRetailPrices, errors?.variationsFields, position]);

  return (
    <>
      <Paper
        sx={{
          ...cl.paper,
          backgroundColor: isChecked ? "#ECF5F0" : "white",
          "&:last-of-type": {
            borderRadius: "0 0 4px 4px",
          },
          opacity: product?.status === "INACTIVE_FOR_CUSTOMERS" ? 0.5 : 1,
          ...styles,
        }}
        elevation={0}
        square
        component={Grid}
        columns={30.8}
        container
      >
        <Grid sx={cl.gridCenter} xs={0.5} item>
          {isEdit && handler}
        </Grid>

        <Grid sx={cl.gridCenter} xs={1.25} item>
          <LargeCheckbox
            adminIsAllowed
            sx={{ p: 0, mx: 0.7 }}
            formSx={{ marginX: 0 }}
            size={22}
            checked={isChecked}
            onChange={() => handleCheckProduct(product)}
          />
        </Grid>

        <Grid sx={cl.gridCenter} xs={0.4} item>
          {product?.status === "INACTIVE_FOR_CUSTOMERS" && (
            <StyledTooltip
              isDark
              sx={{ zIndex: 1300 }}
              arrow
              title={
                <Box textAlign="left">
                  <Typography fontSize={12} color="#FFF">
                    Inactive for customers
                  </Typography>
                </Box>
              }
              placement="top"
              PopperProps={{
                modifiers: [{ name: "offset", options: { offset: [0, -8] } }],
              }}
            >
              <Box display="flex" alignItems="center">
                <InformationIcon size="16" stroke="#FF6969" />
              </Box>
            </StyledTooltip>
          )}
        </Grid>

        <Grid
          sx={cl.gridCenter}
          // xs={1.6}
          width="60px"
          marginRight={"10px"}
          item
        >
          <StyledPopper
            open={tooltipData.open && !isCroppedDialogOpen}
            anchorEl={tooltipRef.current}
            text={tooltipData.text}
            modifiers={tooltipData.modifiers}
            placement="top"
            transition
            aria-hidden="true"
            style={{ zIndex: 1300 }}
          />
          <Box
            sx={{
              position: "relative",
              pointerEvents:
                (progressPhotoUpload || preparingPhoto || photoUploading) &&
                "none",
              opacity: photoUploading ? 0.5 : 1,
            }}
            ref={tooltipRef}
            onMouseEnter={() => {
              trigger("variationsFields");
              setTooltipData((prev) => ({ ...prev, open: true }));
            }}
            onMouseLeave={() =>
              setTooltipData((prev) => ({ ...prev, open: false }))
            }
            onMouseDown={() =>
              setTooltipData((prev) => ({ ...prev, open: false }))
            }
            onClick={(e) => {
              if (progressPhotoUpload || preparingPhoto || photoUploading) {
                e.preventDefault();
                return;
              }
            }}
            data-testid={`variationsFields[${position}].image`}
          >
            <UploadFile
              label=""
              path="product-photos"
              onChange={handleSetPhoto}
              accept="image/png, image/gif, image/jpeg"
              Wrapper={SquareButton}
              uploadData={{ type: "PRODUCT" }}
              adminIsAllowed
              wrapperProps={{
                label: "Upload",
                width: 42,
                height: 40,
                fontSize: "12px",
                iconW: 18.44,
                iconH: 14.89,
                onMouseEnter: () => setPhotoHovered(true),
                onMouseLeave: () => setPhotoHovered(false),
                styles: {
                  opacity: photoHovered
                    ? 0.8
                    : product?.photos && product?.photos[0]?.fileName
                    ? 0
                    : product?.photoUrl && product?.photoUrl[0]
                    ? 0
                    : 1,
                  transition: "all .2s ease",
                  "&:hover": {
                    backgroundColor:
                      (product?.photos
                        ? product?.photos[0]?.fileName
                        : product?.photoUrl) && "#ffffff",
                  },
                },
              }}
              maxUploadFileSizeMb={{
                count: MAX_UPLOAD_FILE_SIZE_PRODUCT_MB,
                callback: (fileName) => {
                  error(
                    <>
                      <Typography component="span" fontSize={12}>
                        Photo upload failed
                        <br />
                        {fileName}
                        <br />
                        Exceeds maximum file size of{" "}
                        {MAX_UPLOAD_FILE_SIZE_PRODUCT_MB} MB
                      </Typography>
                      <br />
                    </>
                  );
                },
              }}
              maxUploadFileResolutionMp={{
                count: MAX_UPLOAD_FILE_RESOLUTION_PRODUCT_MP,
                callback: (fileName) => {
                  error(
                    <>
                      <Typography component="span" fontSize={12}>
                        Photo upload failed
                        <br />
                        {fileName}
                        <br />
                        Exceeds maximum image resolution of{" "}
                        {MAX_UPLOAD_FILE_RESOLUTION_PRODUCT_MP} MP
                      </Typography>
                      <br />
                    </>
                  );
                },
              }}
              disabled={!!disabledUploadFile?.length}
              withCropper
              dialogSize="small"
              progressUpload={setProgressPhotoUpload}
              setIsOpen={setIsCroppedDialogOpen}
              preparingPhoto={preparingPhoto}
              setPreparingPhoto={setPreparingPhoto}
              confirmData={
                editType === "product" ? { type: "product-photo" } : null
              }
            />
            {progressPhotoUpload || preparingPhoto ? (
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "42px",
                  height: "40px",
                  borderRadius: "8px",
                  objectFit: "contain",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress color="inherit" size={20} />
              </Box>
            ) : (
              <Box
                component="img"
                src={
                  product?.photoUrl
                    ? photoUrl(product?.photoUrl[0])
                    : photoUrl(product?.photos && product?.photos[0]?.fileName)
                }
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "42px",
                  height: "40px",
                  borderRadius: "8px",
                  objectFit: "contain",
                }}
              />
            )}
          </Box>
        </Grid>

        {/* <Grid xs={0.7} item /> */}

        {/* SKU */}
        <Grid sx={cl.gridLeft} item xs={selectedOnlySku ? 7 : 4.5}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <TextField
                sx={{ width: "90%" }}
                fullWidth
                size="small"
                InputProps={{ className: classes.textInputVariant }}
                InputLabelProps={{ className: classes.inputLabel }}
                error={!!error}
                {...field}
                onBlur={(e) => {
                  trigger("variationsFields");
                  field.onBlur(e);
                }}
              />
            )}
            name={`variationsFields[${position}].sku`}
            control={control}
          />
        </Grid>
        {/* {!selectedOnlySku && !product?.color && !product?.size && (
          <Grid item xs={4.5} />
        )} */}
        {/* COLOR */}
        {product?.color !== undefined && (
          <Grid sx={cl.gridLeft} xs={4.5} item>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <TextField
                  sx={{ width: "144px" }}
                  fullWidth
                  size="small"
                  InputProps={{ className: classes.textInputVariant }}
                  InputLabelProps={{ className: classes.inputLabel }}
                  error={!!error}
                  {...field}
                  onBlur={(e) => {
                    trigger("variationsFields");
                    field.onBlur(e);
                  }}
                />
              )}
              name={`variationsFields[${position}].color`}
              control={control}
            />
          </Grid>
        )}
        {/* SIZE */}
        {product?.size !== undefined && (
          <Grid sx={cl.gridLeft} xs={4.5} item>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <TextField
                  sx={{ width: "144px" }}
                  fullWidth
                  size="small"
                  InputProps={{ className: classes.textInputVariant }}
                  InputLabelProps={{ className: classes.inputLabel }}
                  error={!!error}
                  {...field}
                  onBlur={(e) => {
                    trigger("variationsFields");
                    field.onBlur(e);
                  }}
                />
              )}
              name={`variationsFields[${position}].size`}
              control={control}
            />
          </Grid>
        )}

        {/* BARCODE */}
        <Grid
          sx={cl.gridLeft}
          // xs={5}
          xs={selectedOnlySku ? 7 : 5}
          item
        >
          {/* <StyledTooltip
            placement="top"
            arrow
            sx={{ zIndex: 1300 }}
            title={
              errors?.variationsFields?.[position]?.barcode?.message ||
              "Incorrect value"
            }
            open={
              errors?.variationsFields?.[position]?.barcode?.ref?.name ===
              `variationsFields[${position}].barcode`
            }
            PopperProps={{
              modifiers: [{ name: "offset", options: { offset: [0, -12] } }],
            }}
          > */}
          <Box sx={{ width: "100%" }}>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <NumberFormat
                  adminIsAllowed
                  customInput={TextField}
                  fullWidth
                  size="small"
                  InputProps={{
                    className: classes.textInputVariant,
                  }}
                  InputLabelProps={{
                    className: classes.inputLabel,
                  }}
                  error={!!error}
                  {...field}
                  onChange={(e) => {
                    const v = e.target.value || "";
                    setValue(`variationsFields[${position}].barcode`, v, {
                      shouldDirty: true,
                    });
                  }}
                />
              )}
              name={`variationsFields[${position}].barcode`}
              control={control}
            />
          </Box>
          {/* </StyledTooltip> */}
        </Grid>

        {/* ITEM PER CASE */}
        <Grid sx={cl.gridCenter} xs={3.6} item>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <TextField
                sx={{
                  width: "44px",
                  "& input": { p: 0 },
                }}
                placeholder="0"
                fullWidth
                size="small"
                InputProps={{
                  sx: {
                    fontSize: "12px",
                    height: "28px",
                    paddingRight: 0,
                    "& input": {
                      paddingLeft: setItemPerCase(
                        field?.value?.length ||
                          formField?.variationsFields?.[
                            position
                          ]?.itemsPerCase?.toString()?.length
                      ),
                      paddingRight: "8px",
                    },
                  },
                }}
                InputLabelProps={{
                  className: classes.inputLabel,
                }}
                error={!!error}
                {...field}
                onChange={(e) => {
                  if (!!error && e.target.value) {
                    clearErrors(`variationsFields[${position}].itemsPerCase`);
                  }
                  setValue(
                    `variationsFields[${position}].itemsPerCase`,
                    e.target.value,
                    { shouldDirty: true }
                  );
                }}
              />
            )}
            name={`variationsFields[${position}].itemsPerCase`}
            control={control}
          />
        </Grid>

        {/* WHOLESALE PRICE */}

        <StyledTooltip
          placement="top"
          arrow
          PopperProps={{
            modifiers: [
              {
                name: "offset",
                options: { offset: [-24, -12] },
              },
            ],
          }}
          title={
            calculatedWholesalePrices &&
            calculatedWholesalePrices[position] !== null && (
              <Typography fontSize="12px">
                ${separateNumWithComma(calculatedWholesalePrices[position])} per
                item
              </Typography>
            )
          }
          sx={{
            zIndex: 1300,
            display:
              formField?.variationsFields[position]?.wholesalePrice !==
                undefined &&
              formField?.variationsFields[position]?.wholesalePrice !== "" &&
              calculatedWholesalePrices &&
              calculatedWholesalePrices[position] !== null &&
              areAllFieldsFilled(formField?.variationsFields[position]) &&
              formField?.variationsFields[position]?.itemsPerCase !== ""
                ? "block"
                : "none",
            "& .MuiTooltip-arrow": {
              left: "-24px !important",
            },
          }}
        >
          <Grid sx={cl.gridLeft} xs={3.8} item>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <PriceFormat
                  adminIsAllowed
                  sx={{ width: "87px" }}
                  fullWidth
                  size="small"
                  InputProps={{
                    className: classes.textInputVariantWithAdor,
                    startAdornment: (
                      <InputAdornment
                        sx={{ marginRight: "3px" }}
                        position="start"
                      >
                        <Typography
                          sx={{
                            fontSize: "12px",
                            fontWeight: 400,
                            color: "#D4D4D4",
                          }}
                        >
                          $
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    className: classes.inputLabel,
                  }}
                  thousandSeparator={true}
                  decimalScale={2}
                  error={!!error}
                  {...field}
                  onValueChange={({ value }) => {
                    if (!!error && value) {
                      clearErrors(
                        `variationsFields[${position}].wholesalePrice`
                      );
                    }
                    setValue(
                      `variationsFields[${position}].wholesalePrice`,
                      value,
                      { shouldDirty: true }
                    );
                  }}
                  onChange={() => {}}
                />
              )}
              name={`variationsFields[${position}].wholesalePrice`}
              control={control}
            />
          </Grid>
        </StyledTooltip>

        {/* RETAIL PRICE */}
        <StyledTooltip
          placement="top"
          arrow
          PopperProps={{
            modifiers: [
              {
                name: "offset",
                options: { offset: [-10, -12] },
              },
            ],
          }}
          sx={{
            zIndex: 1300,
            display:
              formField?.variationsFields[position]?.retailPrice !==
                undefined &&
              formField?.variationsFields[position]?.retailPrice !== "" &&
              calculatedRetailPrices &&
              calculatedRetailPrices[position] !== null &&
              areAllFieldsFilled(formField?.variationsFields[position]) &&
              formField?.variationsFields[position]?.itemsPerCase !== ""
                ? "block"
                : "none",
            "& .MuiTooltip-arrow": {
              left: "-10px !important",
            },
          }}
          title={setTooltipTitleRetailPrice}
        >
          <Grid sx={cl.gridLeft} xs={3} item>
            <Box ref={retailPriceRef}>
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <PriceFormat
                    adminIsAllowed
                    sx={{ width: "87px" }}
                    fullWidth
                    size="small"
                    InputProps={{
                      className: classes.textInputVariantWithAdor,
                      startAdornment: (
                        <InputAdornment
                          sx={{ marginRight: "3px" }}
                          position="start"
                        >
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: 400,
                              color: "#D4D4D4",
                            }}
                          >
                            $
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      className: classes.inputLabel,
                    }}
                    error={!!error}
                    {...field}
                    onValueChange={({ value }) => {
                      if (!!error && value) {
                        clearErrors(
                          `variationsFields[${position}].retailPrice`
                        );
                      }
                      setValue(
                        `variationsFields[${position}].retailPrice`,
                        value,
                        { shouldDirty: true }
                      );
                    }}
                    onChange={() => {}}
                  />
                )}
                name={`variationsFields[${position}].retailPrice`}
                control={control}
              />
            </Box>
          </Grid>
        </StyledTooltip>
        {/* DISTRIBUTOR PRICE */}
        {/* <Grid sx={cl.gridLeft} xs={3.3} item>
        <Controller
          render={({ field, fieldState: { error } }) => (
            <TextField
              sx={{ width: "116px" }}
              fullWidth
              size="small"
              InputProps={{
                className: classes.textInputVariantWithAdor,
                startAdornment: (
                  <InputAdornment sx={{ marginRight: "3px" }} position="start">
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 400,
                        color: "#D4D4D4",
                      }}
                    >
                      $
                    </Typography>
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                className: classes.inputLabel,
              }}
              error={!!error}
              {...field}
            />
          )}
          name={`variationsFields[${position}].distributorPrice`}
          control={control}
        />
      </Grid> */}

        {/* COPY SIZE */}
        <Grid sx={cl.gridCenter} xs={0.5} item>
          {showCopyBtn && (
            <StyledTooltip
              sx={{ zIndex: 1300 }}
              placement="top"
              arrow
              title="Copy from previous row"
              PopperProps={{
                modifiers: [
                  {
                    name: "offset",
                    options: { offset: [0, -12] },
                  },
                ],
              }}
            >
              <IconButton
                sx={{ width: "35px", height: "35px" }}
                onClick={handleClickCopy}
              >
                <CopyIcon />
              </IconButton>
            </StyledTooltip>
          )}
        </Grid>

        {/* DELETE */}
        <Grid sx={cl.gridCenter} xs={1.5} item>
          <IconButton onClick={() => onDelete(position, product)}>
            <TrashIcon height="18" width="18" />
          </IconButton>
        </Grid>
      </Paper>
    </>
  );
};
VariationsTabItemList.propTypes = {
  product: object,
  onChangePhoto: func,
  onDelete: func,
  position: number,
  handleCheckProduct: func,
  selectedOnlySku: bool,
  isChecked: bool,
  control: object,
  setValue: func,
  clearErrors: func,
  sortedFieldsList: array,
  isEdit: bool,
  errors: object,
  handler: object,
  saveDataAfterChangePhotos: func,
  styles: object,
  trigger: func,
  disabledUploadFile: array,
};
VariationsTabItemList.defaultProps = {
  isChecked: false,
  saveDataAfterChangePhotos: () => {},
};

export default VariationsTabItemList;
