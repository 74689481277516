import { useEffect, useRef, useState } from "react";
import { CartTrashIcon, FilterStarIcon } from "components/Icons";

import EditIcon from "@mui/icons-material/Edit";
import {
  deleteSavedFiltersAction,
  getSavedFiltersAction,
  onEditFilterDialogAction,
  setSelectedFilterIdAction,
  updateSavedFiltersAction,
} from "redux/actions/savedFilters";
import { useDispatch } from "react-redux";
import {
  openConfirmDialogAction,
  setConfirmIsOpenAction,
} from "redux/actions/confirmDialogs";
import { Typography } from "@mui/material";
import { updateUserFieldAction } from "redux/actions/auth";

export const useCustomFilterMenu = ({
  onFilterApply,
  type,
  savedFilters,
  resetFilterFunc,
  selectedFilterId,
  currentUser,
}) => {
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);

  const filterAnchor = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSavedFiltersAction(type));

    if (selectedFilterId) {
      const selectedFilter = savedFilters.filter(
        (f) => f.id === selectedFilterId
      );

      if (selectedFilter.length && !selectedFilter?.[0].defaultCustomFilter)
        return dispatch(setSelectedFilterIdAction(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, type]);

  const onCloseEditDialog = () =>
    dispatch(onEditFilterDialogAction({ show: false }));

  const onDefaultChange = (filter) => {
    const { id, distributorId, ...rest } = filter || {};

    const filteredFilters = savedFilters?.map((item) => ({
      ...item,
      defaultCustomFilter: false,
    }));

    const updatedFilter = {
      ...rest,
      defaultCustomFilter: !filter.defaultCustomFilter,
    };

    dispatch(
      updateSavedFiltersAction({
        type,
        id,
        filter: updatedFilter,
        msg: "Default layout filter updated",
        filtersState: filteredFilters,
        onSuccess: (newFilter) => {
          if (filter.defaultCustomFilter) {
            const filteredUserState = currentUser.customFilters.filter(
              (item) => item.id !== filter.id
            );
            dispatch(updateUserFieldAction("customFilters", filteredUserState));
          } else {
            dispatch(
              updateUserFieldAction("customFilters", [
                ...currentUser.customFilters,
                newFilter,
              ])
            );
          }
        },
      })
    );
  };

  const onSelectFilter = (filter, isActive) => {
    setFilterMenuOpen(false);

    if (isActive) {
      resetFilterFunc();
      dispatch(setSelectedFilterIdAction(null));
      return;
    }

    onFilterApply(filter.filters);
    dispatch(setSelectedFilterIdAction(filter.id));
    return;
  };

  const confirmDelete = (filter) => {
    onCloseEditDialog();

    if (filter.defaultCustomFilter || selectedFilterId === filter.id)
      resetFilterFunc();

    if (filter.defaultCustomFilter) {
      const filteredUserState = currentUser.customFilters.filter(
        (item) => item.id !== filter.id
      );

      dispatch(updateUserFieldAction("customFilters", filteredUserState));
    }

    dispatch(setConfirmIsOpenAction(false));
    dispatch(deleteSavedFiltersAction({ type, id: filter.id }));
  };

  const onEdit = (filter) => {
    dispatch(onEditFilterDialogAction({ show: true, filterForEdit: filter }));
    setFilterMenuOpen(false);
  };

  const onDelete = (filter) => {
    setFilterMenuOpen(false);
    dispatch(
      openConfirmDialogAction({
        title: "Delete selected view?",
        text: (
          <Typography fontWeight="400" fontSize="15px" whiteSpace="pre-line">
            Please confirm that you would like to delete selected view? This
            cannot be restored.
          </Typography>
        ),
        propBtns: {
          left: {
            label: "Cancel",
            color: "cancel",
            variant: "outlined",
            sx: {
              width: "98px",
              color: "#6A6A6A",
              borderColor: "#D4D4D4",
              fontSize: "13px",
              height: "28px",
            },
          },
          right: {
            sx: {
              width: "98px",
              color: "#FFFFFF",
              fontSize: "13px",
              height: "28px",
              boxShadow: "none",
            },
            label: "Confirm",
            variant: "contained",
            onClick: () => confirmDelete(filter),
          },
        },
      })
    );
  };

  const getActionList = (filter) => {
    return [
      {
        Icon: EditIcon,
        onClick: () => onEdit(filter),
        show: true,
        iconProps: { width: "18px", height: "18px" },
      },
      {
        Icon: CartTrashIcon,
        onClick: () => onDelete(filter),
        show: true,
        iconProps: { width: "14px", height: "15px" },
      },
      {
        Icon: FilterStarIcon,
        onClick: () => onDefaultChange(filter),
        show: true,
        iconProps: {
          opacity: filter.defaultCustomFilter ? "1 !important" : "0",
          "& path": {
            fill: filter.defaultCustomFilter ? "#707070" : "none",
          },
        },
      },
    ];
  };

  return {
    filterAnchor,
    filterMenuOpen,
    setFilterMenuOpen,
    getActionList,
    onCloseEditDialog,
    onSelectFilter,
    onDelete,
  };
};
