export const cl = {
  dialogWrapper: {
    width: "720px",
    maxWidth: "720px",
    borderRadius: "16px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: "28px",
  },
  textTitle: {
    fontSize: "25px",
    fontWeight: 500,
    lineHeight: "29px",
    color: "#5F6267",
  },
  blockTitle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "30px 35px 0",
  },
  closeBtn: {
    marginLeft: "auto",
    padding: 0,
    mb: "10px",
  },
  resetBtn: {
    height: "67px",
    fontSize: "19px",
    fontWeight: 500,
    width: "100%",
    borderRadius: "8px",
  },
  actionWrapper: {
    padding: "0 100px 50px 100px",
  },
  forgotText: {
    fontSize: "20px",
    ml: "auto",
    color: "#5F6267",
    lineHeight: "36px",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
  dialogContent: {
    height: "100%",
    padding: "0px 100px 20px",

    "& .MuiOutlinedInput-root": {
      height: "54px !important",
      borderRadius: "8px",
    },
    "& .MuiInputLabel-root": {
      fontSize: "17px",
      lineHeight: "34px",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      fontSize: "16px",
      lineHeight: "23px",
    },
    "& .MuiOutlinedInput-root fieldset legend": {
      fontSize: "11.7px",
    },
  },
  criterial: {
    "& .MuiTypography-root": {
      fontSize: "14px",
    },
    "& svg": {
      width: 10.5,
      height: 8.5,
    },
  },
};
