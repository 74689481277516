export const defaultValues = {
  customer: "",
  contactId: "",
  salesId: "",
  products: [],
  manufacturerDiscounts: [],
  manufacturerDiscountIds: [],
  discount: null,
  totalOrderDiscountAmount: 0,
  totalOrderDiscountType: "PERCENTAGE",
  productsData: [],
  customerId: "",
  note: "",
  manualDeliveryFee: false,
  deliveryFee: 0,
  createOrderType: "DIRECT",
  paymentTerms: {},
  fulfillBy: "",
  orderTags: [],
  freeCasesProductsIds: null,
  customId: "",
};

export const DEFAULT_ORDER_DATA = {
  createOrderType: "DIRECT",
  customer: "",
  contactId: "",
  salesId: "",
  deliveryFee: 0,
  discount: null,
  freeCasesProductsIds: null,
  fulfillBy: "",
  manualDeliveryFee: false,
  manufacturerDiscounts: [],
  note: "",
  orderTags: [],
  paymentTerms: {},
  productsData: [],
  tagIds: [],
  totalOrderDiscountAmount: 0,
  totalOrderDiscountType: "PERCENTAGE",
};

export const DISCOUNT_TYPES = {
  total: "INVOICE_TOTAL_DISCOUNT",
  item: "ITEM_DISCOUNT",
  manufacturer: "MANUFACTURER_DISCOUNT",
};

export const DISCOUNT_NAMES = {
  total: "Invoice Total Discount",
  item: "Item Specific Discount",
  manufacturer: "Manufacturer Specific Discount",
};

export const ORDER_TYPES = {
  "3rd Party": "THIRD_PARTY",
  Direct: "DIRECT",
};

export const CREATED_ORDER_TYPES = [
  "with_new",
  "with_email_link",
  "save_as_draft",
  "update_draft",
  "update_draft_and_email",
];

export const CART_TYPES = {
  order: "ORDER",
  edit: "EDIT",
  duplicate: "DUPLICATE",
  draft: "DRAFT",
  duplicate_draft: "DUPLICATE_DRAFT",
};
