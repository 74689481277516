import { string } from "prop-types";
import { Grid, Typography } from "@mui/material";

export const CustomIdBlock = ({ customId }) => {
  return (
    <Grid
      item
      xs={1.2}
      sx={{
        display: "flex",
        justifyContent: "left",
        alignItems: "center",
      }}
    >
      <Typography
        variant="caption"
        sx={{ display: "inline-block", color: "#1C1C19" }}
        fontSize="12px"
      >
        {customId}
      </Typography>
    </Grid>
  );
};

CustomIdBlock.propTypes = { customId: string };
CustomIdBlock.defaultProps = { customId: "" };
