import { useEffect, useMemo, useState } from "react";
import { object, func } from "prop-types";
import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { Controller, useWatch } from "react-hook-form";
import { StyledTextField } from "../../../../../../../../components/TextFields/TextFields";
import { useDebounce } from "../../../../../../../../helpers/hooks";
import useStyles, { cl } from "./styles";

import { getItemWithId } from "./helpers";
import { ChildItem } from "./ChildItem";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { createSelector } from "reselect";
import {
  categoriesListSelector,
  isLoadingCategoriesSelector,
} from "../../../../../../../../redux/selectors/categories";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getCategoriesListAction } from "../../../../../../../../redux/actions/categories";
import { ExpandMore } from "@mui/icons-material";
import { useCallback } from "react";
import { cloneDeep } from "lodash";

const selector = createSelector(
  isLoadingCategoriesSelector,
  categoriesListSelector,
  (isLoadingCategories, categoriesList) => ({
    isLoadingCategories,
    categoriesList,
  })
);

export const CategoriesComponent = ({ control, setValue }) => {
  const classes = useStyles();
  const formField = useWatch({ control });

  const { isLoadingCategories, categoriesList } = useSelector(selector);
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState("");
  const [showCategories, setShowCategories] = useState([]);
  const searchInputDebounced = useDebounce(searchInput, 500);

  useEffect(() => {
    dispatch(getCategoriesListAction({ search: searchInputDebounced }));
  }, [dispatch, searchInputDebounced]);

  const handleAddCategory = useCallback(
    (cat) => {
      const tempCatArr = cloneDeep(formField.category);

      const index = formField.category.findIndex((el) => el.id === cat.id);
      if (index > -1) {
        tempCatArr.splice(index, 1);
        setValue("category", tempCatArr);
        return;
      }
      setValue("category", [
        ...tempCatArr.filter((cat) => cat?.name !== "All"),
        cat,
      ]);
      return;
    },
    [formField.category, setValue]
  );

  useEffect(() => {
    setValue(
      "groupByIds",
      formField.category
        .filter((cat) => cat?.name !== "All")
        .map(({ id }) => id)
    );
  }, [formField.category, setValue]);

  const parentCategory = useMemo(() => {
    const hasParent = getItemWithId(
      categoriesList,
      showCategories.find((category) => category?.parentCategoryId)
        ?.parentCategoryId
    );

    return (
      !!hasParent && (
        <Box
          sx={{
            "&:hover": { backgroundColor: "#F7F7F7" },
            borderRadius: "4px",
            width: "100%",
            height: "26px",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            ml: "-16px",
          }}
          onClick={() => {
            handleAddCategory(hasParent);
          }}
        >
          <IconButton
            sx={{
              width: "26px",
              height: "26px",
            }}
            onClick={(e) => {
              e.stopPropagation();
              if (hasParent?.parentCategoryId) {
                setShowCategories(
                  getItemWithId(categoriesList, hasParent?.parentCategoryId)
                    ?.childCategories
                );
              }
              if (hasParent?.parentCategoryId === null) {
                setShowCategories(categoriesList);
              }
            }}
          >
            <ChevronLeftIcon />
          </IconButton>

          <Typography
            sx={{
              fontSize: 12,
              fontWeight: 600,
              color: "#1C1C19",
            }}
          >
            {hasParent?.name}
          </Typography>
        </Box>
      )
    );
  }, [categoriesList, handleAddCategory, showCategories]);

  const categoryField = useMemo(() => {
    return (
      <Paper
        sx={{
          width: "100%",
        }}
        elevation={0}
      >
        {parentCategory}
        {showCategories?.map((category) => (
          <Box
            key={category?.id}
            sx={{
              "&:hover": { backgroundColor: "#F7F7F7" },
              borderRadius: "4px",
              width: "100%",
              height: "26px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              cursor: "pointer",
              backgroundColor: formField.category.some(
                (cat) => cat.id === category?.id
              )
                ? "#F2F8F5"
                : "#FFF",
            }}
            onClick={() => {
              handleAddCategory(category);
            }}
          >
            <Typography
              sx={{
                pl: "10px",
                fontSize: 12,
                fontWeight: 300,
                color: "#1C1C19",
              }}
            >
              {category?.name}
            </Typography>
            {!!category?.childCategories?.length && (
              <IconButton
                sx={{
                  width: "26px",
                  height: "26px",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowCategories(
                    getItemWithId(categoriesList, category?.id)?.childCategories
                  );
                }}
              >
                <ChevronRightIcon />
              </IconButton>
            )}
          </Box>
        ))}
      </Paper>
    );
  }, [
    categoriesList,
    formField.category,
    handleAddCategory,
    parentCategory,
    showCategories,
  ]);

  const getCurrentCategoriesList = useMemo(() => {
    return searchInput ? categoriesList : [];
  }, [categoriesList, searchInput]);

  return (
    <Grid xs={12} item position="relative">
      <Controller
        render={({ field, fieldState: { error } }) => {
          return (
            <Autocomplete
              multiple
              disableCloseOnSelect
              fullWidth
              onInputChange={(event, option, reason) => {
                if (reason === "clear") setSearchInput("");
              }}
              loading={isLoadingCategories}
              PaperComponent={(props) => {
                return <Box {...props} className={classes.recipientsTable} />;
              }}
              size="small"
              isOptionEqualToValue={(option, value) => {
                return option.id === value?.id;
              }}
              sx={cl.search}
              className={classes.textfield}
              getOptionLabel={(option) => {
                return option.name || "";
              }}
              renderInput={(params) => (
                <StyledTextField
                  placeholder="Search product categories"
                  className={classes.textfield}
                  value={searchInput}
                  onChange={(e) => {
                    setSearchInput(e.target.value);
                  }}
                  sx={cl.searchInput}
                  InputProps={{
                    ...params.InputProps,
                  }}
                  onClick={() => {
                    setShowCategories([...categoriesList]);
                  }}
                  {...params}
                />
              )}
              ListboxProps={{ sx: cl.searchListBoxProps }}
              classes={{
                noOptions: classes.noOptions,
              }}
              noOptionsText={categoryField}
              popupIcon={
                <ExpandMore
                  style={{
                    color: "#808080",
                  }}
                />
              }
              renderOption={(props, recipient) => {
                return (
                  <ChildItem
                    {...props}
                    category={recipient}
                    setValue={setValue}
                  />
                );
              }}
              options={getCurrentCategoriesList}
              error={error?.message || ""}
              {...field}
              onChange={(e, newValue) => {
                setValue(
                  field.name,
                  newValue.filter((cat) => cat?.name !== "All")
                );
              }}
            />
          );
        }}
        name="category"
        control={control}
      />
    </Grid>
  );
};

CategoriesComponent.propTypes = {
  control: object,
  setValue: func,
};
