import { ToggleButton } from "@mui/material";
import { string, any, object, bool } from "prop-types";
import { forwardRef } from "react";

export const StyledToggleButton = forwardRef(
  ({ children, error, ...props }, ref) => {
    return (
      <ToggleButton
        sx={{
          color: "#707070",
          height: "32px",
          // width: "20px",
          borderColor: error ? "#F2545B" : "#D5D9D9",
          backgroundColor: error && "rgba(236,66,51,0.15)",
          "&:hover": {
            backgroundColor: error && "rgba(236,66,51,0.2)",
          },

          "&.Mui-selected": {
            "&:hover": {
              backgroundColor: error && "rgba(236,66,51,0.2)",
            },

            backgroundColor: error
              ? "rgba(236,66,51,0.2)"
              : "rgba(71, 160, 109, 0.1)!important",
            borderColor: !error && "#47A06D",
            color: "#6A6A6A",
            "& .MuiTypography-root": {
              color: !error && "#47A06D",
            },
            "& svg": {
              transform: "scaleY(-1)",
            },
          },
        }}
        {...props}
        ref={ref}
      >
        {children}
      </ToggleButton>
    );
  }
);

StyledToggleButton.propTypes = {
  label: string,
  children: any,
  labelSx: object,
  fullWidth: bool,
  error: bool,
};

StyledToggleButton.defaultTypes = {
  label: "",
  fullWidth: false,
  children: null,
  error: false,
};

StyledToggleButton.displayName = "StyledToggleButton";

export default StyledToggleButton;
