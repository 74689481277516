import { useContext } from "react";
import { Grid, Typography } from "@mui/material";
import { Controller, useWatch } from "react-hook-form";
import { LargeCheckbox } from "components";
import { CustomerContext } from "Pages/CustomersPage/pages/CustomerPage/CustomerPage";

export const AddDisplayNameBlock = () => {
  const { control } = useContext(CustomerContext);
  const formField = useWatch({ control });
  const { addDisplayName } = formField || {};

  return (
    <Grid mt="-16px" mb={addDisplayName ? "-16px" : 0} xs={12} item container>
      <Controller
        render={({ field }) => (
          <LargeCheckbox
            adminIsAllowed
            size={16}
            formSx={{ pl: 0.4 }}
            label={
              <Typography
                variant="caption"
                sx={{
                  fontSize: 13,
                  fontWeight: 400,
                  color: "#5F6267",
                  letterSpacing: "normal",
                }}
              >
                Add display name
              </Typography>
            }
            {...field}
            checked={!!field.value}
          />
        )}
        name="addDisplayName"
        control={control}
      />
    </Grid>
  );
};
