import { useContext } from "react";
import { TasksPageContext } from "Pages/TasksPage/TasksPage";
import { Box, Typography } from "@mui/material";
import { StyledButton, StyledTabs } from "components";

export const TabsPage = () => {
  const { tabs, currentTab, handleChangeCurrentTab, handleCreateTask } =
    useContext(TasksPageContext);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        px: "32px",
        backgroundColor: "#F0F0F0",
        borderBottom: "0.5px solid #D4D4D4",
        height: "44px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: 3,
        }}
      >
        <Typography
          variant="h6"
          fontWeight="400"
          fontSize="20px"
          color="#9C9C94"
        >
          Tasks
        </Typography>
        <Box>
          {tabs && (
            <StyledTabs
              onChange={handleChangeCurrentTab}
              value={currentTab}
              tabs={tabs}
              tabProps={{
                sx: {
                  py: 0,
                  height: "44px",
                  fontSize: "12px !important",
                  fontWeight: 400,
                  color: "#9C9C94",
                },
              }}
              sx={{
                minHeight: "auto",
                height: "44px",
                "& .Mui-selected": {
                  backgroundColor: "transparent!important",
                },
              }}
            />
          )}
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: 1,
        }}
      >
        {currentTab === tabs[0].value && (
          <>
            <StyledButton
              sx={{ height: 28 }}
              variant="contained"
              label="Add Task"
              onClick={() => handleCreateTask()}
            />
          </>
        )}
        {currentTab === tabs[1].value && (
          <>
            <StyledButton
              sx={{ height: 28 }}
              variant="contained"
              label="Add Form"
            />
          </>
        )}
      </Box>
    </Box>
  );
};
