import { string, oneOfType, number, object } from "prop-types";
import { Box, Typography } from "@mui/material";

export const ItemText = ({ name, value, valueStyle }) => {
  return (
    <Box sx={{ width: "100%", display: "flex" }}>
      <Typography
        sx={{
          width: "45%",
          pl: "27px",
          fontSize: 16,
          fontWeight: 600,
          color: "#000000",
        }}
      >
        {name}
      </Typography>
      <Typography
        sx={{
          width: "55%",
          fontSize: 16,
          fontWeight: 300,
          color: "#292929",
          ...valueStyle,
        }}
        noWrap
      >
        {value}
      </Typography>
    </Box>
  );
};

ItemText.propTypes = {
  name: string,
  value: oneOfType([string, number]),
  valueStyle: object,
};
ItemText.defaultProps = { name: "", value: "" };
