import React, { useMemo } from "react";
import { Box, Link, Typography } from "@mui/material";
import { func, shape, number, array } from "prop-types";
import { organizeIssuesFilter } from "../../helpers";
// import { useBreakpoint } from "utils/useBreakpoint";

const MissingInfoCounts = ({ handleApplyFilter, countData, filterFields }) => {
  const { missingInfoCount, duplicatedInfoCount, expiredDocumentInfoCount } =
    useMemo(() => countData || [], [countData]);

  const missingInfoParams = useMemo(
    () => organizeIssuesFilter(filterFields),
    [filterFields]
  );

  const COUNT_SECTIONS = [
    {
      count: duplicatedInfoCount,
      label: "address duplicates",
      info: { value: "with_duplicated_info", name: "Duplicates" },
    },
    {
      count: missingInfoCount,
      label: "missing info",
      info: { value: "with_missing_info", name: "Missing info" },
    },
    {
      count: expiredDocumentInfoCount,
      label: "licenses expired",
      info: { value: "with_expired_document", name: "Expired license" },
    },
  ];

  // const breakpoint = useBreakpoint();

  return (
    <Box
      display="flex"
      alignItems="center"
      gap="14px"
      // ml={breakpoint === "xl" ? "calc(11% - 152px)" : "0px"}
    >
      {COUNT_SECTIONS.filter(({ count }) => count > 0).map(
        ({ count, label, info }) => {
          const isActive = !!missingInfoParams[info.value];
          return (
            <Box
              pr="14px"
              key={label}
              display="flex"
              alignItems="center"
              gap="6px"
              sx={{
                "&:not(:last-of-type)": {
                  borderRight: "1px solid #D5D9D9",
                },
              }}
            >
              <Typography color="error">
                {count} {label}
              </Typography>
              {!isActive && (
                <Link
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleApplyFilter(info)}
                >
                  View
                </Link>
              )}
            </Box>
          );
        }
      )}
    </Box>
  );
};

MissingInfoCounts.propTypes = {
  handleApplyFilter: func,
  filterFields: array,
  countData: shape({
    missingInfoCount: number,
    duplicatedInfoCount: number,
  }),
};

export default MissingInfoCounts;
