export const FILTER_SWITCHES = [
  {
    value: "created_by",
    label: "Created by",
    type: "select",
    checked: false,
    selectLabel: "Select Rep",
  },
  {
    value: "sales_id",
    label: "Assigned to",
    type: "select",
    checked: false,
    selectLabel: "Select Rep",
  },
  {
    value: "total",
    label: "Total",
    type: "textfields",
    checked: false,
    options: [
      {
        id: "total_amount_greater",
        label: "Greater than",
        startAdornment: <div style={{ marginRight: "5px" }}> $ </div>,
        prefix: ">",
        placeholder: "0.00",
        type: "number",
      },
      {
        id: "total_amount_lesser",
        label: "Less than",
        startAdornment: <div style={{ marginRight: "5px" }}> $ </div>,
        prefix: "<",
        placeholder: "0.00",
        type: "number",
      },
    ],
  },
  {
    value: "date",
    label: "Date",
    type: "date",
    checked: false,
    selectLabel: "Select Date",
  },

  {
    value: "archived",
    label: "Archived",
    type: "switch",
    values: ["on"],
    checked: false,
  },
  {
    value: "tag_id",
    label: "Tag",
    type: "multiselect",
    checked: false,
    selectLabel: "Select Tag",
  },
];

export const defaultFilters = {
  rep_id: "",
  created_by: "",
  total: "",
  date: "",
  sales_id: "",
  archived: "",
  tag_id: [],
};
