import { useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  Paper,
  Tab,
  Tabs,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { app } from "../../firebase/Chat/config";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  getFirestore,
  onSnapshot,
  query,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { StyledTextField } from "../../components/TextFields/TextFields";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import ConversationsSwitcherTab from "./ConversationsSwitcherTab/ConversationsSwitcherTab";
import { normalizeUppercaseString, useAdmin } from "../../helpers/helpers";
import { CrossIcon, PlusIcon, SearchIcon } from "../../components/Icons";
import useStyles from "./styles";
import NewMessagePopup from "./components/NewMessagePopup/NewMessagePopup";
import NewMessageRepsItem from "./components/NewMessageRepsItem/NewMessageRepsItem";
import { createSelector } from "reselect";
import { currentUserSelector } from "../../redux/selectors/auth";
import { getRepsArrayService } from "../../services/reps";
import { error } from "../../utils/notifications";
import MessageMenu from "./components/MessageMenu/MessageMenu";
import {
  confirmDeleteChatAction,
  handleDeleteChatFiles,
  handleDeleteChatMessages,
} from "../../firebase/Chat/helpers";
import { setConfirmIsOpenAction } from "../../redux/actions/confirmDialogs";
import {
  groupChatloadingSelector,
  groupChatRoomsSelector,
  repRoomsLoadingSelector,
  repRoomsSelector,
} from "../../redux/selectors/chat";
import { useDebounce } from "../../helpers/hooks";
import EmptyScreen from "../../components/EmptyScreen/EmptyScreen";
import { isEqual, orderBy, sortBy } from "lodash";
import { useRepsListAction } from "./hooks";
import { ConversationList } from "./components";
import { ScrollControlWrapper } from "components";

const selector = createSelector(
  currentUserSelector,
  repRoomsSelector,
  repRoomsLoadingSelector,
  groupChatRoomsSelector,
  groupChatloadingSelector,
  (
    currentUser,
    representativeRooms,
    representativeRoomsLoading,
    groupChatRooms,
    groupChatloading
  ) => ({
    currentUser,
    representativeRooms,
    representativeRoomsLoading,
    groupChatRooms,
    groupChatloading,
  })
);

const ConversationsPage = () => {
  const isAdmin = useAdmin();
  const {
    currentUser,
    representativeRooms,
    representativeRoomsLoading,
    groupChatRooms,
    groupChatloading,
  } = useSelector(selector);
  const navigate = useNavigate();
  const firestore = getFirestore(app);

  const [repsCollection, repsCollectionLoading] = useDocument(
    collection(getFirestore(app), "representatives"),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const repRoomsRef = collection(
    firestore,
    `representatives/${repsCollection?.docs?.[0]?.id}/rooms`
  );
  const groupChatRoomsRef = collection(firestore, `group-chats`);

  // const repStatusRef = collection(firestore, "online-status");

  const conversationListRef = useRef(null);
  const conversationListWidth = conversationListRef.current?.clientWidth;

  const classes = useStyles();
  const dispatch = useDispatch();
  const conversationTabs = ["Customers", "Representatives"];

  const messageTabs = ["all", "unread", "read"];

  const { roomId } = useParams();

  const {
    repsList,
    repsCount,
    repsLoading,
    handleFetchReps,
    handleSearchReps,
  } = useRepsListAction();

  const [messageTab, setMessageTab] = useState(messageTabs[0]);
  const [activeRoom, setActiveRoom] = useState(roomId);
  const [roomsInfo, setRoomsInfo] = useState([]);
  const [checkedReps, setCheckedReps] = useState([]);
  const [newMessageOpen, setNewMessageOpen] = useState(false);
  const [onlineStatuses, setOnlineStatuses] = useState([]);
  const [chatMenuOpen, setChatMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [clickedChat, setClickedChat] = useState(null);
  const [repsInfoLoading, setRepsInfoloading] = useState(false);

  const [currentConversationTab, setCurrentConversationTab] = useState(
    conversationTabs[1]
  );

  const [searchValue, setSearchValue] = useState("");
  const [searchRooms, setSearchRooms] = useState([]);

  const searchValueDebounced = useDebounce(searchValue, 500);

  const handleCheckReps = (rep) => {
    if (rep === "") return setCheckedReps([]);
    const index = checkedReps.findIndex((r) => r?.id === rep?.id);
    const tempCheckedReps = [...checkedReps];

    if (index === -1) return setCheckedReps((prev) => [...prev, rep]);

    tempCheckedReps.splice(index, 1);
    setCheckedReps(tempCheckedReps);
  };

  const filteredRoomsInfo = useMemo(
    () =>
      roomsInfo.filter((room) => {
        if (messageTab === "read")
          return room?.readBy?.find((id) => id === currentUser.id);
        if (messageTab === "unread")
          return !room?.readBy?.find((id) => id === currentUser.id);
        return room;
      }),
    [messageTab, roomsInfo, currentUser.id]
  );

  const handleCreateRoom = (users) => {
    const rooms = searchValue
      ? searchRooms
      : roomsInfo.filter((room) => room.id || room.chatName);

    const currentUsersIds = users.map((el) => el?.duplicate?.id);

    const isGroupChat = users?.length > 1;

    const hasChat = rooms.find((el) => {
      return isEqual(sortBy(currentUsersIds), sortBy([el?.representativeId]));
    });
    const hasGroupChat = rooms
      .filter((room) => room?.chatName)
      .find((el) => {
        return isEqual(
          sortBy(currentUsersIds),
          sortBy(el?.participants.filter((i) => i !== currentUser?.id))
        );
      });

    if (hasChat) {
      setActiveRoom(hasChat.roomId);
      const collectionId = repsCollection?.docs[0].id;
      const representativeId = hasChat.id;
      navigate(hasChat.roomId, {
        state: {
          collectionId,
          representativeId,
          statusDocId: hasChat.statusDocId,
          messageId: hasChat.messageId,
        },
      });
      setNewMessageOpen(false);
      return;
    }

    if (hasGroupChat) {
      setActiveRoom(hasGroupChat.roomId);
      const representativesIds = currentUsersIds;
      navigate(hasGroupChat.roomId, {
        state: {
          representativesIds,
          statusDocId: hasGroupChat.statusDocId,
          messageId: hasGroupChat.messageId,
        },
      });
      setNewMessageOpen(false);
      return;
    }

    const createdBy = {
      id: currentUser?.id,
      role: "DISTRIBUTOR",
    };

    if (!isGroupChat) {
      const uploadRoom = {
        distributorId: currentUser.id,
        lastMessage: "",
        lastAttachment: null,
        createdAt: serverTimestamp(),
        representativeId: users[0].duplicate.id,
        updatedAt: serverTimestamp(),
        readBy: [currentUser.id],
        createdBy,
      };

      const uploadRep = {
        id: users[0].duplicate.id,
        online: false,
        lastOnline: null,
      };

      addDoc(repRoomsRef, uploadRoom).then((res) => {
        setActiveRoom(res.id);
        const collectionId = repsCollection?.docs[0].id;
        const representativeId = users[0].duplicate.id;
        navigate(res.id, {
          state: { collectionId, representativeId },
        });
      });
      setDoc(doc(firestore, "online-status", users[0].duplicate.id), uploadRep);
      setNewMessageOpen(false);
      return;
    }

    if (isGroupChat) {
      const getUsersName = users?.map(({ name }) => name).join(", ");
      const getUsersId = users?.map(({ duplicate }) => duplicate?.id);

      const uploadGroupRoom = {
        chatName: getUsersName,
        lastMessage: "",
        lastAttachment: null,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        readBy: [currentUser.id],
        participants: [currentUser.id, ...getUsersId],
        createdBy,
      };

      addDoc(groupChatRoomsRef, uploadGroupRoom).then((res) => {
        setActiveRoom(res.id);
        const collectionId = res.id;
        const representativesIds = getUsersId;
        navigate(res.id, {
          state: { collectionId, representativesIds },
        });
      });

      setNewMessageOpen(false);
      return;
    }
  };

  const currentRooms = useMemo(
    () =>
      representativeRooms?.map((room) => {
        return { ...room.data(), roomId: room.id };
      }),
    [representativeRooms]
  );
  const currentGroupRooms = useMemo(
    () =>
      groupChatRooms?.map((room) => {
        return { ...room.data(), roomId: room.id };
      }),
    [groupChatRooms]
  );

  const representativeDuplicateIds = useMemo(
    () => currentRooms.map((room) => room.representativeId),
    [currentRooms]
  );

  const groupChatRepsDuplicateIds = useMemo(
    () =>
      [
        ...new Set(
          currentGroupRooms?.map((room) => room?.participants)?.flat()
        ),
      ].filter((id) => id !== currentUser?.id),
    [currentGroupRooms, currentUser?.id]
  );

  // const [onlineStatuses] = useCollection(
  //   query(
  //     repStatusRef,
  //     where("id", "in", ["online", ...representativeDuplicateIds])
  //   )
  //   // 'online' string needed because of initial empty array
  // );

  const setStatusEl = (status) => {
    if (!status) return;
    const newStatuses = [...onlineStatuses];
    const index = onlineStatuses.findIndex((s) => s.id === status.id);
    if (index > -1) {
      newStatuses.splice(index, 1, status);
      return setOnlineStatuses([...newStatuses]);
    }
    setOnlineStatuses([...onlineStatuses, status]);
  };

  useEffect(() => {
    if (currentRooms?.length || currentGroupRooms?.length) {
      [...representativeDuplicateIds, ...groupChatRepsDuplicateIds].map(
        (id) => {
          return onSnapshot(doc(firestore, `online-status/${id}`), (snap) => {
            setStatusEl(snap.data());
            return snap;
          });
        }
      );
      setRepsInfoloading(true);

      getRepsArrayService({
        representativeDuplicateIds: [
          ...new Set([
            ...representativeDuplicateIds,
            ...groupChatRepsDuplicateIds,
          ]),
        ],
      })
        .then((res) => {
          setRepsInfoloading(false);
          const infos = [...currentRooms, ...currentGroupRooms].map((room) => {
            return {
              ...res[room.representativeId],
              ...room,
              originalId: res[room.representativeId]?.representativeId,
            };
          });
          setRoomsInfo(infos);
        })
        .catch((err) => {
          setRepsInfoloading(false);
          error(err?.response?.data?.message || err?.message);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRooms?.length, currentGroupRooms?.length]);

  useEffect(() => {
    if (roomsInfo.length) {
      const infos = [...currentRooms, ...currentGroupRooms]?.map((room) => {
        const matchRoomById = roomsInfo.find(
          (currRoom) => currRoom.roomId === room.roomId
        );
        return {
          ...matchRoomById,
          ...room,
          // lastOnline,
          // online,
          // statusDocId: id,
        };
      });
      setRoomsInfo(infos);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRooms]);

  useEffect(() => {
    const infos = roomsInfo.map((room) => {
      const matchStatus = onlineStatuses?.find(
        (status) => status.id === room.representativeId
      );
      if (matchStatus)
        return {
          ...room,
          lastOnline: matchStatus.lastOnline,
          online: matchStatus.online,
          statusDocId: matchStatus.id,
        };
      return { ...room };
    });
    setRoomsInfo(infos);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onlineStatuses, roomsInfo.length]);

  const roomsLoading = useMemo(
    () =>
      repsCollectionLoading ||
      representativeRoomsLoading ||
      repsInfoLoading ||
      groupChatloading,
    [
      groupChatloading,
      representativeRoomsLoading,
      repsCollectionLoading,
      repsInfoLoading,
    ]
  );

  const setMessagesRefLink = useMemo(() => {
    return clickedChat?.chatName
      ? `group-chats/${clickedChat?.roomId}/messages`
      : `representatives/${repsCollection?.docs?.[0]?.id}/rooms/${clickedChat?.roomId}/messages`;
  }, [clickedChat?.chatName, clickedChat?.roomId, repsCollection?.docs]);

  const messagesRef = collection(getFirestore(), setMessagesRefLink);

  const [messages] = useCollection(query(messagesRef));

  const setRoomLocLink = useMemo(() => {
    return clickedChat?.chatName
      ? `group-chats/${clickedChat?.roomId}`
      : `representatives/${repsCollection?.docs?.[0]?.id}/rooms/${clickedChat?.roomId}`;
  }, [clickedChat?.chatName, clickedChat?.roomId, repsCollection?.docs]);

  const handleDeleteChat = async () => {
    const roomLoc = setRoomLocLink;

    await handleDeleteChatMessages(`${roomLoc}/messages`, messages);

    handleDeleteChatFiles(roomId);

    const roomRef = doc(getFirestore(), roomLoc);

    const currentRoomStatusRef = doc(
      getFirestore(),
      `online-status/${clickedChat.representativeId}`
    );

    deleteDoc(roomRef).then(() => {
      dispatch(setConfirmIsOpenAction(false));
      if (activeRoom === clickedChat?.roomId) {
        navigate("/conversations");
      }
    });
    deleteDoc(currentRoomStatusRef);
  };

  useEffect(() => {
    if (searchValueDebounced) {
      setRepsInfoloading(true);
      Promise.all(
        roomsInfo.map(async (roomInfo) => {
          const col = collection(
            firestore,
            roomInfo?.chatName
              ? `group-chats/${roomInfo.roomId}/messages`
              : `representatives/${repsCollection?.docs?.[0]?.id}/rooms/${roomInfo.roomId}/messages`
          );
          const messages = await getDocs(
            query(
              col
              // where("text", ">=", searchValueDebounced),
              // where("text", "<=", `${searchValueDebounced}\uf8ff`)
            )
          );

          return messages.docs
            .filter((m) =>
              m
                .data()
                .text.toLowerCase()
                .includes(searchValueDebounced.toLowerCase())
            )
            .map((m) => {
              return {
                ...roomInfo,
                lastMessage: m.data().text,
                messageId: m.id,
              };
            });
        })
      )
        .then((res) => {
          setRepsInfoloading(false);
          setSearchRooms(res.flat());
        })
        .catch((err) => {
          setRepsInfoloading(false);
          error(err?.message);
        });
    } else {
      setSearchRooms([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValueDebounced, firestore]);

  const chatList = useMemo(() => {
    return orderBy(
      (searchValue ? searchRooms : filteredRoomsInfo).filter(
        (room) => room.id || room.chatName
      ),
      (el) => el?.updatedAt?.seconds,
      "desc"
    );
  }, [filteredRoomsInfo, searchRooms, searchValue]);

  if (isAdmin) return <></>;

  return (
    <>
      <ConversationsSwitcherTab
        tabs={conversationTabs}
        currentTab={currentConversationTab}
        handleSetCurrentTab={(tab) => setCurrentConversationTab(tab)}
      />
      <NewMessagePopup
        isOpen={newMessageOpen}
        handleClose={() => setNewMessageOpen(false)}
        headerTitle="Representatives"
        ItemWrapper={NewMessageRepsItem}
        users={repsList}
        handleCheckUser={handleCheckReps}
        checkedUsers={checkedReps}
        usersCount={repsCount}
        handleFetchUsers={handleFetchReps}
        handleSearchUsers={handleSearchReps}
        loading={repsLoading}
        handleCreateRoom={handleCreateRoom}
        reservedIds={currentRooms?.map((room) => room.representativeId)}
      />
      <Box
        sx={{
          display: "flex",
          p: "17px 28px 0 28px",
          height: "calc(100vh - 185px)",
          width: "100%",
        }}
      >
        <MessageMenu
          isOpen={chatMenuOpen}
          handleClose={() => {
            setChatMenuOpen(false);
          }}
          handleDelete={() => {
            setChatMenuOpen(false);
            dispatch(confirmDeleteChatAction(handleDeleteChat));
          }}
          anchorEl={anchorEl}
          anchorOrigin={{ horizontal: "center", vertical: "center" }}
          room={clickedChat}
          collectionId={repsCollection?.docs?.[0]?.id}
        />
        <Paper
          ref={conversationListRef}
          sx={{
            flex: "0 0 19.5%",
            position: "relative",
            boxShadow: "none",
            border: "0.5px solid #D5D9D9",
            width: "19.5%",
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            height="50px"
            pl={2}
            pr={0.5}
          >
            <Box display="flex" alignItems="center" width="100%">
              <Tabs
                value={messageTab}
                onChange={(e, newTab) => setMessageTab(newTab)}
                sx={{
                  minHeight: "24px",
                  alignItems: "center",
                  "& .MuiTabs-flexContainer": {
                    justifyContent: "space-between",
                  },
                }}
                TabIndicatorProps={{ sx: { display: "none" } }}
              >
                {messageTabs.map((tab) => (
                  <Tab
                    key={tab}
                    value={tab}
                    sx={{
                      whiteSpace: "nowrap",
                      textTransform: "none",
                      fontSize: 13,
                      color: "#707070",
                      borderRadius: "4px",
                      "&.Mui-selected": {
                        backgroundColor: "rgba(71, 160, 110, 0.2) !important",
                        // backgroundColor: "rgba(66, 165, 127, 0.1) !important",
                      },
                      minWidth: "36px",
                      // width: "84px",
                      height: "24px",
                      minHeight: "24px",
                      paddingLeft: "11px",
                      paddingRight: "11px",
                    }}
                    label={
                      <Box sx={{ textTransform: "none" }}>
                        {normalizeUppercaseString(tab)}
                      </Box>
                    }
                  />
                ))}
              </Tabs>
            </Box>

            <IconButton onClick={() => setNewMessageOpen(true)}>
              <PlusIcon />
            </IconButton>
          </Box>

          <Box
            sx={{
              position: "absolute",
              width: "100%",
              left: 0,
              height: "0.5px",
              backgroundColor: "#D5D9D9",
            }}
          />

          <Box width="100%" position="relative">
            <StyledTextField
              placeholder="Search messages"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              size="small"
              formSx={{ p: "12px 17px 10px 15px" }}
              InputProps={{
                className: classes.searchInput,
                endAdornment: (
                  <>
                    <InputAdornment
                      position="start"
                      sx={{ position: "absolute", left: 11 }}
                    >
                      <SearchIcon />
                    </InputAdornment>
                    {searchValue && (
                      <InputAdornment position="end">
                        <IconButton
                          sx={{ p: "2px" }}
                          onClick={() => setSearchValue("")}
                        >
                          <CrossIcon size="20" />
                        </IconButton>
                      </InputAdornment>
                    )}
                  </>
                ),
              }}
              InputLabelProps={{
                className: classes.searchLabel,
              }}
            />

            <Box
              position="relative"
              sx={{
                width: "100%",
                height: "calc(100vh - 307px)",
              }}
            >
              {roomsLoading && (
                <Box
                  width="35px"
                  mx="auto"
                  position="absolute"
                  sx={{ right: "14px", top: "4px" }}
                >
                  <CircularProgress size="25px" />
                </Box>
              )}

              <ScrollControlWrapper
                dataLength={chatList?.length}
                id="conversation-list"
              >
                {!!chatList?.length && (
                  <ConversationList
                    {...{
                      chatList,
                      activeRoom,
                      currentUser,
                      setAnchorEl,
                      setClickedChat,
                      setChatMenuOpen,
                      repsCollection,
                      setActiveRoom,
                      conversationListWidth,
                    }}
                  />
                )}
              </ScrollControlWrapper>
              {/* {chatList.map((room) => {
                return (
                  <Box
                    key={
                      room.roomId
                        ? `${room.roomId}-${room.messageId}`
                        : `${room.id}-${room.messageId}`
                    }
                    className={classes.roomRow}
                    sx={{
                      color: "#1C1C19",
                      textDecoration: "none",
                      "&:hover": {
                        backgroundColor:
                          (!!room.messageId || activeRoom !== room.roomId) &&
                          "#F7F7F7",
                      },
                      backgroundColor:
                        !room.messageId && activeRoom === room.roomId
                          ? "rgba(66, 165, 127, 0.1)"
                          : !room.readBy?.find((id) => id === currentUser.id)
                          ? "#F0F0F0"
                          : "white",
                    }}
                    component={NavLink}
                    to={`/conversations/${room.roomId}`}
                    onContextMenu={(e) => {
                      e.preventDefault();
                      setAnchorEl(e.target);
                      setClickedChat(room);
                      setChatMenuOpen(true);
                    }}
                    onClick={(e) => {
                      const collectionId = repsCollection?.docs?.[0]?.id;
                      const representativeId = room.id;
                      localStorage.setItem(
                        "msgData",
                        JSON.stringify({
                          collectionId: collectionId || null,
                          representativeId: representativeId || null,
                          representativesIds: room?.participants || null,
                          statusDocId: room.statusDocId || null,
                          messageId: room.messageId || null,
                        })
                      );
                      setActiveRoom(room.roomId);
                      if (e.metaKey || e.ctrlKey) return;
                      navigate(room.roomId);
                    }}
                  >
                    <Box position="relative">
                      <StyledAvatar
                        sx={{
                          height: "36px",
                          width: "36px",
                        }}
                        variant={room?.chatName ? "rounded" : "circular"}
                        src={photoUrl(room.profilePhoto?.fileName)}
                        stringStyle={{ fontSize: "12px" }}
                        name={room?.name || room?.chatName}
                      />
                      {room.online && room.originalId && (
                        <Box className={classes.onlineCircle} />
                      )}
                    </Box>
                    <Box ml="5px" overflow="hidden" width="100%">
                      <Box display="flex" gap="6px" alignItems="center">
                        <Typography
                          fontSize="12px"
                          fontWeight="400"
                          color="primary"
                        >
                          {room?.name || room?.chatName}
                        </Typography>
                        {!(room.originalId || room?.chatName) && (
                          <StyledLabel text="deleted" />
                        )}{" "}
                      </Box>
                      <Typography className={classes.roomInfo}>
                        {room.baseUser?.email}
                      </Typography>
                      <Box
                        display="flex"
                        alignItems="center"
                        sx={{
                          justifyContent: room.lastMessage && "space-between",
                        }}
                        width="100%"
                      >
                        <Typography className={classes.roomInfo}>
                          {room.lastMessage}
                        </Typography>
                        {attachmentIcon(room.lastAttachment)}
                      </Box>
                    </Box>
                  </Box>
                );
              })} */}
            </Box>
          </Box>
        </Paper>
        {!representativeRooms.length && !groupChatRooms?.length && (
          <Box
            width="100%"
            sx={{
              height: "calc(100vh - 185px)",
            }}
          >
            <EmptyScreen
              type="messages"
              height="calc(100vh - 185px)"
              onConfirm={() => setNewMessageOpen(true)}
              showAction
            />
          </Box>
        )}
        <Outlet />
      </Box>
    </>
  );
};

export default ConversationsPage;
