import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  headerWrapper: {
    display: "flex",
    justifyContent: "space-between",
    height: "45px",
    alignItems: "center",
    borderBottom: "0.5px solid #D5D9D9",
    padding: "3px 32px",
    background: "#F0F0F0",
  },

  pageTitle: {
    fontSize: "20px",
    color: "#9C9C94",
  },

  bodyWrapper: {
    padding: "0 32px",
  },

  headerButton: {
    height: "28px",
  },
}));

export default useStyles;
