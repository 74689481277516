export const cl = {
  wrapper: {
    height: "25px",
    display: "flex",
    alignItems: "center",
    "&:not(:last-of-type)": {
      borderBottom: "1px solid #D5D9D9",
    },
    maxWidth: "100%",
  },
  btn_wrapper: {
    width: "15.9%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  divider: {
    borderLeft: "1px solid #D5D9D9",
    height: 11,
    marginX: 0.2,
  },
};
