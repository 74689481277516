import * as React from "react";
export const NewOrderIcon = (props, width, height) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || 28}
    height={height || 30.755}
    viewBox="0 0 28 30.755"
    {...props}
  >
    <path
      data-name="Path 5545"
      d="M9.046 29.937 7.9 27.911a.5.5 0 0 1 .189-.681l2.027-1.146"
      fill="none"
      stroke="#5f6267"
      strokeLinecap="round"
      strokeWidth={1.2}
    />
    <path
      data-name="Path 5546"
      d="m9.224 15.86 2.445 1.115"
      fill="none"
      stroke="#5f6267"
      strokeLinecap="round"
      strokeWidth={1.2}
    />
    <path
      data-name="Path 5547"
      d="M14.115 22.753v-7.56"
      fill="none"
      stroke="#5f6267"
      strokeLinecap="round"
      strokeWidth={1.2}
    />
    <path
      data-name="Path 5548"
      d="m7.222 12.075 6.713 3.032a.445.445 0 0 0 .361 0l6.935-3.032"
      fill="none"
      stroke="#5f6267"
      strokeLinecap="round"
      strokeWidth={1.2}
    />
    <path
      data-name="Path 5549"
      d="M17.784 10.301 11 13.522m3.2-4.957 7.136 3.46a.2.2 0 0 1 .113.18v7.084a.2.2 0 0 1-.117.182L14.2 22.715a.2.2 0 0 1-.168 0l-6.917-3.24A.2.2 0 0 1 7 19.291v-7.087a.2.2 0 0 1 .111-.179l6.917-3.458a.2.2 0 0 1 .172-.002Z"
      fill="none"
      stroke="#5f6267"
      strokeLinecap="round"
      strokeWidth={1.2}
    />
    <path
      data-name="Path 5550"
      d="m19.218.799.993 2.106a.5.5 0 0 1-.239.665l-2.106.993"
      fill="none"
      stroke="#5f6267"
      strokeLinecap="round"
      strokeWidth={1.2}
    />
    <path
      data-name="Path 5551"
      d="M8.262 27.175a.583.583 0 0 0 .166.952A14.007 14.007 0 0 0 26.847 9.703a.583.583 0 0 0-.952-.166.629.629 0 0 0-.127.694A12.807 12.807 0 0 1 8.955 27.043a.629.629 0 0 0-.694.127ZM20.42 3.698a.628.628 0 0 1-.724.112A12.805 12.805 0 0 0 2.534 20.975a.628.628 0 0 1-.112.724.583.583 0 0 1-.937-.137 14.005 14.005 0 0 1 18.8-18.8.583.583 0 0 1 .137.937Z"
      fill="#5f6267"
      fillRule="evenodd"
    />
  </svg>
);
