export const manufacturersDiscountsLoadingSelector = (state) =>
  state.manufacturersDiscounts.loadingManufacturersDiscounts;
export const manufacturersDiscountsCountSelector = (state) =>
  state.manufacturersDiscounts.countManufacturersDiscounts;
export const manufacturersDiscountsCountAllSelector = (state) =>
  state.manufacturersDiscounts.countAllManufacturersDiscounts;
export const manufacturersDiscountsListSelector = (state) =>
  state.manufacturersDiscounts.manufacturersDiscounts;
export const manufacturersDiscountsShowInactiveSelector = (state) =>
  state.manufacturersDiscounts.showInactive;
export const manufacturersDiscountsParamsSelector = (state) =>
  state.manufacturersDiscounts.params;
export const manufacturersDiscountsExistSelector = (state) =>
  state.manufacturersDiscounts.existData;
