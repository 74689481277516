import { bool, func } from "prop-types";
import { Grid, Typography } from "@mui/material";
import { LargeCheckbox } from "../../../../../../components/Checkboxes";
import React from "react";
import TableHeader from "../../../../../../components/TableHeader";

const PaytermsHeader = ({ handleSetShowInactive, isOnboarding }) => {
  const labels = [
    {
      id: 1,
      name: isOnboarding ? (
        ""
      ) : (
        <Typography variant="header">STATUS</Typography>
      ),
      size: 2,
      position: "flex-start",
      styles: {
        paddingLeft: 2,
      },
    },
    {
      id: 2,
      name: <Typography variant="header">TERM NAME</Typography>,
      size: 2.5,
      position: "flex-start",
    },
    {
      id: 3,
      name: "",
      size: 1.5,
    },
    {
      id: 4,
      name: (
        <Typography variant="header">DAYS FROM SUPPLIER INVOICES</Typography>
      ),
      size: 4,
      position: "flex-start",
    },
    {
      id: 5,
      name: (
        <LargeCheckbox
          formSx={{ pr: "0px", m: 0 }}
          sx={{ p: 0 }}
          label={
            <Typography
              sx={{
                fontSize: "15px",
                fontWeight: 400,
                color: "#6A6A6A",
                whiteSpace: "nowrap",
                mr: "9px",
              }}
            >
              Show Inactive
            </Typography>
          }
          labelPlacement="start"
          stroke="#5F6267"
          size={20}
          onChange={handleSetShowInactive}
        />
      ),
      size: 2,
      position: "flex-end",
      styles: {
        paddingRight: 2,
      },
    },
  ];

  return (
    <TableHeader
      sx={{
        backgroundColor: "#EEEEEE",
        alignItems: "center",
        border: "0.5px solid #D5D9D9",
        borderRadius: "4px 4px 0 0",
      }}
      elevation={0}
      square
      component={Grid}
      columns={12}
      container
      height="37px"
    >
      {labels.map((el) => (
        <Grid
          key={el.id}
          sx={{
            display: "flex",
            justifyContent: el.position,
            alignItems: "center",
            ...el.styles,
          }}
          xs={el.size}
          item
        >
          {el.name}
        </Grid>
      ))}
    </TableHeader>
  );
};
PaytermsHeader.propTypes = {
  handleSetShowInactive: func,
  isOnboarding: bool,
};
PaytermsHeader.defaultProps = {
  isOnboarding: false,
};

export default PaytermsHeader;
