import { Box, Typography } from "@mui/material";
import { AtentionIcon } from "../Icons";
import { func } from "prop-types";

const StatusWidget = ({ onWidgetStatusClick }) => {
  return (
    <Box
      position="fixed"
      right="30px"
      bottom="30px"
      display="flex"
      gap="10px"
      alignItems="center"
      backgroundColor="#FF6254"
      padding="10px 25px"
      zIndex="100"
      border="0.5px solid #d5d9d9"
      borderRadius="5px"
      onClick={onWidgetStatusClick}
      sx={{ cursor: "pointer" }}
    >
      <Typography color="#fff">Synchronization failed</Typography>{" "}
      <AtentionIcon size="30" />
    </Box>
  );
};

StatusWidget.propTypes = {
  onWidgetStatusClick: func,
};

export default StatusWidget;
