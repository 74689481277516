import { object, array } from "prop-types";
import {
  Box,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { StyledSelect } from "components";

export const ShippingCarrierBlock = ({
  cl,
  control,
  optionsShippingCarrierSelect,
  shippingCarriers,
}) => {
  return (
    <Box sx={cl.shippingCarrierWrapper}>
      <Grid sx={cl.shippingCarrier} container>
        <Grid sx={{ maxWidth: "100%" }} item xs={12} container>
          <Grid item xs={4.6}>
            <Typography sx={cl.shippingCarrier.text}>Tracking ID</Typography>
          </Grid>

          <Grid item xs />

          <Grid item xs={4.6}>
            <Typography sx={cl.shippingCarrier.text}>
              Shipping carrier
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} container>
          <Grid sx={{ marginLeft: 0 }} item xs={4.6}>
            <Box>
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    fullWidth
                    InputProps={{ sx: cl.textInput }}
                    placeholder="Tracking ID"
                    error={!!error}
                    {...field}
                  />
                )}
                name="trackingId"
                control={control}
              />
            </Box>
          </Grid>
          <Grid item xs display="flex" justifyContent="center">
            <Divider orientation="vertical" />
          </Grid>
          <Grid item xs={4.6}>
            <Box>
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <StyledSelect
                    {...optionsShippingCarrierSelect}
                    error={error?.message || ""}
                    {...field}
                  >
                    <MenuItem sx={{ display: "none" }} value="">
                      <Box component="span" color="#ACACAC">
                        Shipping carrier
                      </Box>
                    </MenuItem>

                    {shippingCarriers.map(({ id, name }) => (
                      <MenuItem key={id} value={id}>
                        {name}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                )}
                name="shippingCarrier"
                control={control}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

ShippingCarrierBlock.propTypes = {
  cl: object,
  control: object,
  optionsShippingCarrierSelect: object,
  shippingCarriers: array,
};
ShippingCarrierBlock.defaultProps = {
  cl: {},
  optionsShippingCarrierSelect: {},
  shippingCarriers: [],
};
