import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  inputForm: {
    "& .MuiInputLabel-root.Mui-focused": {
      // paddingLeft: "4px",
    },
    "& .MuiFormLabel-filled": {
      paddingLeft: 0,
    },
  },
  select: {
    minHeight: ({ height }) => height || "32px",
    height: "fit-content",
    fontSize: ({ fontSize }) => fontSize || "12px",
    color: ({ color }) => color || "#1C1C19",
    paddingLeft: ({ leftSidedIcon }) => leftSidedIcon && "15px",
    "& > fieldset": {
      border: ({ border }) => border,
      borderRadius: ({ borderRadius }) => borderRadius,
    },
    "& > .MuiSelect-select": {
      height: "fit-content",
      paddingTop: "5px",
      paddingBottom: "5px",
    },
  },
  squareSelectForm: {
    "& .Mui-focused.MuiFormLabel-root": {
      transform: ({ unfocused }) =>
        unfocused && "translate(14px, 9px) scale(1)",
      top: ({ unfocused, squareSelectLabelPosition }) =>
        squareSelectLabelPosition
          ? squareSelectLabelPosition
          : unfocused
          ? "0!important"
          : 11,
      "& ~ .MuiOutlinedInput-root": {
        "& > fieldset": {
          border: "0.5px solid #D5D9D9",
        },
        "& > svg": {
          fill: "#5F6267",
        },
      },
    },
    "& .MuiFormLabel-filled": {
      top: 11,
      color: "#D4D4D4",
      "& ~ .MuiOutlinedInput-root": {
        "& > svg": {
          fill: "#5F6267",
        },
      },
    },
  },
  squareSelect: {
    "&:hover": {
      "&& fieldset": {
        border: "0.5px solid #D5D9D9",
        backgroundColor: "rgba(213, 217, 217, 0.15)",
      },
    },
    height: ({ height }) => height || "39px",
    backgroundColor: "white",
    borderRadius: ({ radiused, radiusedRight }) => {
      if (radiused) {
        return "4px";
      } else if (radiusedRight) {
        return "0 4px 4px 0 !important";
      } else {
        return 0;
      }
    },
    fontSize: ({ fontSizeLabel }) => fontSizeLabel || "15px",
    fontWeight: 400,
    paddingLeft: ({ leftSidedIcon }) => leftSidedIcon && 14,
    "& > fieldset": {
      border: "0.5px solid #D5D9D9",
    },
    "& > .MuiSelect-select": {
      display: "block",
      paddingRight: ({ leftSidedIcon }) =>
        leftSidedIcon ? "0!important" : "22px!important",
      paddingTop: ({ labelPaddingTop }) => labelPaddingTop || 20,
      alignItems: "center",
    },
  },
  squareSelectLabel: {
    fontSize: "15px",
    color: "#707070",
    fontWeight: 400,
    left: ({ leftSidedIcon }) => leftSidedIcon && 14,
    transitionProperty: "all",
    top: ({ squareSelectLabelPosition }) => squareSelectLabelPosition || "0px",
  },
  squareSelectIcon: {
    fill: "#5F6267",
    transform: "scale(0.75)",
    left: ({ leftSidedIcon }) => leftSidedIcon && 6,
  },
  label: {
    fontSize: ({ labelSx }) => labelSx?.fontSize || "12px",
    color: ({ labelSx }) => labelSx?.color || "#B5B5AC",
    paddingLeft: ({ leftSidedIcon }) => leftSidedIcon && "15px",
    transitionProperty: "all",
  },
  icon: {
    // fill: "#B5B5AC",
    fill: ({ fill }) => fill && "white",
    transform: ({ iconStyles }) => iconStyles?.transform || "scale(0.75)",
    position: ({ leftSidedIcon }) => leftSidedIcon && "absolute",
    left: ({ leftSidedIcon }) => leftSidedIcon && 4,
    top: ({ iconStyles }) => iconStyles?.top || "calc(50% - .5em)",
  },
}));

export default useStyles;
