import { string, func, array, number, bool, object } from "prop-types";
import { Box, CircularProgress } from "@mui/material";
import { NoteItemDrawer } from "../NoteItemDrawer/NoteItemDrawer";
import { useBodyNoteDrawer } from "./BodyNoteDrawer.hooks";
import { InfiniteScrollWrapper } from "components";
import { useCallback } from "react";

export const BodyNoteDrawer = ({
  notesList,
  fetchNotesList,
  notesCount,
  distrProfilePhoto,
  handleEdit,
  handleDelete,
  viewOnly,
  currentUser,
  isAdmin,
}) => {
  const onEdit = (item) => handleEdit(item);

  const onDelete = (item) => handleDelete(item);

  const { getRole, daysAgo } = useBodyNoteDrawer();

  const checkIsRepsTaskOwner = useCallback(({ currentUser, reps }) => {
    return (
      currentUser?.backOfficeRepresentative?.id ===
      reps?.representativeDuplicate?.representativeId
    );
  }, []);

  const setHideEdit = (reps) => {
    if (viewOnly) return true;

    if (currentUser?.backOfficeRepresentative?.id) {
      if (checkIsRepsTaskOwner({ currentUser, reps })) return true;

      return false;
    }

    if (!reps?.representativeDuplicate) return true;

    return false;
  };

  const setDisabledDelBtn = useCallback(
    ({ reps, currentUser }) => {
      const backOfficeRepresentative = currentUser?.backOfficeRepresentative;

      if (!backOfficeRepresentative) return false;

      if (backOfficeRepresentative) {
        if (checkIsRepsTaskOwner({ currentUser, reps })) return false;

        return true;
      }

      return false;
    },
    [checkIsRepsTaskOwner]
  );

  const setName = useCallback(({ reps, currentUser, distributor }) => {
    const backOfficeRepresentative = currentUser?.backOfficeRepresentative;

    if (!backOfficeRepresentative)
      return reps?.representativeDuplicate?.name || "You";

    return reps?.representativeDuplicate?.name || distributor?.name || "";
  }, []);

  return (
    <Box
      sx={{
        "& .infinite-scroll-component": {
          display: "flex",
          flexDirection: "column",
          gap: 2,
          overflow: "hidden !important",
        },
        height: "calc(100vh - 300px)",
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <Box flexGrow={1} pt={3} pl={4} pr={3}>
        <InfiniteScrollWrapper
          dataLength={notesList.length}
          maxHeight="calc(100vh - 300px)"
          next={fetchNotesList}
          loader={
            <CircularProgress sx={{ ml: "50%", mt: "2px" }} size="30px" />
          }
          hasMore={notesList.length < notesCount}
          id="notes-scroll"
        >
          {notesList?.map((n) => (
            <NoteItemDrawer
              key={n?.id}
              note={n.text}
              fileName={
                n?.representativeDuplicate
                  ? n?.representativeDuplicate?.profilePhoto?.fileName
                  : distrProfilePhoto
              }
              name={setName({
                reps: n,
                currentUser,
                distributor: n?.distributor,
              })}
              role={getRole(n?.representativeDuplicate?.role) || "Admin"}
              date={
                n?.updatedAt ? daysAgo(n?.updatedAt) : daysAgo(n?.createdAt)
              }
              onEdit={() => onEdit(n)}
              onDelete={() => onDelete(n)}
              hideEdit={setHideEdit(n)}
              hideActions={viewOnly}
              disabledDelBtn={setDisabledDelBtn({ reps: n, currentUser })}
              isAdmin={isAdmin}
            />
          ))}
        </InfiniteScrollWrapper>
      </Box>
    </Box>
  );
};

BodyNoteDrawer.propTypes = {
  viewOnly: bool,
  notesList: array,
  fetchNotesList: func,
  notesCount: number,
  distrProfilePhoto: string,
  handleEdit: func,
  handleDelete: func,
  currentUser: object,
  isAdmin: bool,
};
BodyNoteDrawer.defaultProps = {
  fetchNotesList: () => {},
  notesCount: 0,
  distrProfilePhoto: "",
  viewOnly: false,
  currentUser: {},
  isAdmin: false,
};
