import { useState } from "react";
import { Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import PropTypes, { func, array, object, bool } from "prop-types";
import ReprItem from "./ReprItem";
import RepsHeader from "./RepsHeader";
import TerritoriesMenu from "../../../../TerritoriesMenu/TerritoriesMenu";

const RepsTableComponent = ({
  handleSelectAll,
  handleSelectRep,
  selectedReps,
  representatives,
  loading,
  checkedReps,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [territoriesList, setTerritoriesList] = useState([]);

  return (
    <Box sx={{ px: "26px", mt: "15px" }}>
      <TerritoriesMenu
        anchorEl={anchorEl}
        territories={territoriesList}
        handleClose={() => setAnchorEl(null)}
      />
      <Stack
        sx={{
          opacity: loading ? 0.5 : 1,
          pointerEvents: loading && "none",
        }}
      >
        <RepsHeader
          handleSelectAll={handleSelectAll}
          allSelected={
            selectedReps?.length === representatives?.length &&
            !!selectedReps?.length
          }
          disabled={representatives?.length === 0}
        />
        <Stack
          width="100%"
          sx={{
            maxHeight: "280px",
            overflow: "overlay",
            borderWidth: "0 1px 1px 1px",
            borderStyle: "solid",
            borderColor: "#D5D9D9",
            borderRadius: "0 0 4px 4px",
          }}
        >
          {representatives?.length ? (
            representatives.map((representative) => {
              return (
                <ReprItem
                  key={representative.id}
                  representative={representative}
                  handleSelectRep={handleSelectRep}
                  isSelected={selectedReps.some(
                    (r) => r.id === representative.id
                  )}
                  setTerritoriesList={setTerritoriesList}
                  setAnchorEl={setAnchorEl}
                  disabled={checkedReps.some((r) => r.id === representative.id)}
                />
              );
            })
          ) : (
            <Box
              minHeight="56px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              fontSize={16}
              fontWeight={400}
              color="#707070"
              component={Typography}
            >
              No reps found
            </Box>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

RepsTableComponent.propTypes = {
  handleSelectAll: func,
  handleSelectRep: func,
  selectedReps: PropTypes.oneOfType([object, array]),
  representatives: array,
  profile: object,
  checkedReps: array,
  loading: bool,
};

RepsTableComponent.defaultProps = {
  selectedRep: null,
  representatives: [],
  loading: false,
  checkedReps: [],
};

export default RepsTableComponent;
