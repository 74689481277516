export const cl = {
  dialog: {
    ".MuiPaper-root": {
      borderRadius: "8px",
      boxShadow: "none",
      width: "100%",
      maxWidth: "474px",
    },
  },
  blockTitle: {
    width: "474px",
    height: "69px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    p: 0,
    borderBottom: "0.5px solid #D5D9D9",
  },
  textTitle: { fontSize: "30px", color: "#707070" },
  textBody: {
    fontSize: "12px",
    fontWeight: 400,
    color: "#000000",
  },
  block: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    height: "100%",
    flexDirection: "column",
    marginTop: 1,
  },
  blockTitleWrapper: { pl: "26px" },
  action: {
    paddingRight: "26px",
    paddingBottom: "22px",
    display: "flex",
    justifyContent: "flex-end",
    btnWrapper: {
      display: "flex",
      justifyContent: "flex-end",
    },
  },
  blockDialog: {
    py: 0,
    px: "26px",
    overflowY: "hidden",
  },
  contentWrapper: {
    mt: "10px",
    height: "58px",
  },
  shippingCarrier: {
    maxWidth: "100%",
    paddingTop: "12px",
    text: {
      fontSize: 12,
      marginBottom: 0.5,
    },
  },
  label: {
    left: 0,
    height: "24px",
    "& .MuiFormLabel-root": {
      color: "#B5B5AC",
      fontSize: "12px",
      top: "2px",
    },
    "& .MuiOutlinedInput-root": {
      height: "100%",
      padding: "0 11px !important",
    },
  },
  shippingCarrierWrapper: {
    marginTop: "12px",
  },
  textInput: {
    fontSize: "12px",
    height: "28px",
    fontWeight: 400,
    "& .MuiInputBase-input": {
      paddingLeft: "8px",
      paddingRight: "8px",
      paddingTop: "4px",
      paddingBottom: "4px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: "0.5px !important",
    },
  },
  selectFulfillVia: {
    left: 0,
    height: "24px",
    "& .MuiFormLabel-root": {
      color: "#B5B5AC",
      fontSize: "12px",
      top: "2px",
    },
    "& .MuiOutlinedInput-root": {
      height: "100%",
      padding: "0 11px !important",
    },
    justifyContent: "space-between",
    "& .MuiOutlinedInput-input": {
      padding: "0 8px 0 2px !important",
    },
    "& .MuiSvgIcon-root": {
      right: "4px",
    },
  },
  selectFulfillBy: {
    left: 0,
    height: "24px",
    "& .MuiFormLabel-root": {
      color: "#B5B5AC",
      fontSize: "12px",
      top: "2px",
    },
    "& .MuiOutlinedInput-root": {
      height: "100%",
      padding: "0 11px !important",
    },
    "& .MuiOutlinedInput-input": {
      padding: "0 22px 0 2px !important",
    },
  },
};

export const optionsShippingCarrierSelect = {
  notched: false,
  fullWidth: true,
  formSx: {
    ...cl.label,
    justifyContent: "space-between",
    "& .MuiOutlinedInput-input": {
      padding: "0 8px !important",
    },
    "& .MuiSvgIcon-root": {
      right: "4px",
    },
  },
  height: "28px",
  size: "small",
  color: "#000000",
  displayEmpty: true,
  label: "",
  defaultValue: "",
  noErrorMessage: true,
  border: "0.5px solid #D5D9D9",
};
