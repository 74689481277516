import { string, element } from "prop-types";
import { Box, Typography } from "@mui/material";

export const BoxItem = ({ text, icon, ...props }) => {
  return (
    <Box {...props}>
      <Typography fontSize={12} fontWeight={400} color="#1C1C19" noWrap>
        {text}
      </Typography>
      {icon}
    </Box>
  );
};
BoxItem.propTypes = { text: string, icon: element };
BoxItem.defaultProps = { text: "", width: "10%" };
