import { object } from "prop-types";
import { Grid } from "@mui/material";
import { Controller } from "react-hook-form";
import { StyledTextField } from "components";
import useStyles from "../../../styles";

export const DescriptionBlock = ({ control }) => {
  const classes = useStyles();

  return (
    <Grid xs={12} item>
      <Controller
        render={({ field, fieldState: { error } }) => (
          <StyledTextField
            adminIsAllowed
            fullWidth
            size="small"
            label="Description"
            multiline
            rows={3}
            InputProps={{
              className: classes.multiTextInput,
            }}
            InputLabelProps={{
              className: classes.inputLabel,
            }}
            error={!!error}
            {...field}
          />
        )}
        name="description"
        control={control}
      />
    </Grid>
  );
};

DescriptionBlock.propTypes = { control: object };
