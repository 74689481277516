import React, { useCallback, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { string, node, number, bool, func } from "prop-types";

import { resetScrollHeight } from "helpers/helpers";
import { ScrollControlWrapper } from "components";

import { CircularProgress } from "@mui/material";

export const InfiniteScrollWrapper = ({
  children,
  id = "",
  dataLength,
  loading = false,
  nextFunc,
  hasMoreItems,
  maxHeight,
  resetCallback,
  ...props
}) => {
  const [oldDataLength, setOldDataLength] = useState(dataLength);

  const handleSetDataLength = useCallback(() => {
    if (dataLength < oldDataLength) {
      resetScrollHeight(id);
      setOldDataLength(dataLength);
      resetCallback && resetCallback();
    }
    return setOldDataLength(dataLength);
  }, [dataLength, id, oldDataLength, resetCallback]);

  useEffect(() => {
    handleSetDataLength();
  }, [handleSetDataLength]);

  return (
    <ScrollControlWrapper
      id={id}
      dataLength={oldDataLength}
      loading={loading}
      maxHeight={maxHeight}
    >
      <InfiniteScroll
        dataLength={oldDataLength}
        next={nextFunc}
        loader={<CircularProgress sx={{ ml: "50%", mt: "2px" }} size="30px" />}
        hasMore={hasMoreItems}
        scrollableTarget={id}
        {...props}
      >
        {children}
      </InfiniteScroll>
    </ScrollControlWrapper>
  );
};

export default InfiniteScrollWrapper;

InfiniteScrollWrapper.propTypes = {
  children: node,
  id: string,
  dataLength: number,
  loading: bool,
  nextFunc: func,
  resetCallback: func,
  hasMoreItems: bool,
  maxHeight: string,
};
