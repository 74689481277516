export const productsListSelector = (state) => state.products.products;
export const productsCountWithVariationsSelector = (state) =>
  state.products.countWithVariations;
export const productsLoadingSelector = (state) => state.products.loading;
export const productsExistSelector = (state) => state.products.existData;

export const isCreatingNewProductSelector = (state) =>
  state.products.isCreatingNewProduct;

export const creatingNewProductRequiredInfoSelector = (state) =>
  state.products.creatingNewProductRequiredInfo;

export const creatingNewProductVariationsSelector = (state) =>
  state.products.creatingNewProductVariations;

export const productsCountSelector = (state) => state.products.count;
export const productsParamsSelector = (state) => state.products.params;

export const productsShowInactiveSelector = (state) =>
  state.products.showInactive;

export const getParentProductSelector = (state) => state.products.parentProduct;

export const selectedProductsSelector = (state) =>
  state.products.selectedProducts;
export const selectedChildrensOfProductsSelector = (state) =>
  state.products.selectedChildrensOfProducts;
export const catalogGetFilterSelector = (state) => state.products.catalogFilter;
export const catalogGetSwitchSelector = (state) => state.products.catalogSwitch;

export const searchProductsSelector = (state) => state.products.searchProducts;
