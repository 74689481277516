export const getInviteColor = (status) => {
  switch (status) {
    case "PENDING":
      return "#F0C401";
    case "REJECTED":
      return "#EB4233";
    default:
      return "#47A06D";
  }
};
