import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  filterChip: {
    borderRadius: "4px",
    border: "0.7px solid #d5d9d9",
    backgroundColor: "#FAFAFB",
    height: "28px",
  },
}));

export default useStyles;
