import { string, func } from "prop-types";
import { DialogTitle, IconButton, Typography } from "@mui/material";
import { CrossIcon } from "components/Icons";

export const HeaderBlock = ({ title, onClose }) => {
  return (
    <DialogTitle
      sx={{
        pl: 4,
        pr: 2,
        py: 0,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "504px",
        height: "58px",
      }}
    >
      <Typography fontSize={17} fontWeight={500} color="#3F3F3F">
        {title}
      </Typography>
      <IconButton onClick={onClose}>
        <CrossIcon />
      </IconButton>
    </DialogTitle>
  );
};

HeaderBlock.propTypes = { title: string, onClose: func };
HeaderBlock.defaultProps = { title: "", onClose: () => {} };
