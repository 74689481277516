import { Box } from "@mui/material";
import React from "react";
import PropTypes, { object, func, node } from "prop-types";

const ListRowWrapper = ({ style, children, ...props }) => {
  return (
    <Box style={style} {...props}>
      {children}
    </Box>
  );
};

ListRowWrapper.propTypes = {
  style: object,
  children: PropTypes.oneOfType([node, func]),
};

ListRowWrapper.defaultProps = {
  style: {},
  children: <></>,
};

export default ListRowWrapper;
