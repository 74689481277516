import { useContext } from "react";
import { Controller, useWatch } from "react-hook-form";
import { Grid, MenuItem, Typography } from "@mui/material";
import { CustomerContext } from "Pages/CustomersPage/pages/CustomerPage/CustomerPage";
import { StyledSelect } from "components";
import { OutlinedPlusIcon } from "components/Icons";
import { PAYMENT_TERM_TYPE } from "Pages/CustomersPage/pages/CustomerPage/CustomerPage.constants";
import { ExpandMore } from "@mui/icons-material";

export const PaymentTermsBlock = () => {
  const { control, paytermsList, setOpenPaymentTermModal, repPermissions } =
    useContext(CustomerContext);
  const formField = useWatch({ control });
  const { paymentTermsDuplicate } = formField || {};

  return (
    <Grid xs={12} columnSpacing={2.75} item container>
      <Grid xs={6} item>
        <Controller
          render={({ field, fieldState: { error } }) => (
            <StyledSelect
              adminIsAllowed
              fullWidth
              formSx={{ mb: "16px" }}
              size="small"
              labelId="select-label-payment-method"
              error={error ? error.message : ""}
              label="Primary payment method"
              IconComponent={(props) => (
                <ExpandMore sx={{ fontSize: 27 }} {...props} />
              )}
              labelSx={{
                color: "#5F6267!important",
                fontWeight: 400,
                fontSize: 13,
                top: 3,
                "&.MuiInputLabel-shrink": { top: 0 },
              }}
              sx={{
                "&:hover": {
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: " #3F3F3F !important",
                  },
                },
              }}
              height="43px"
              fontSize="13px"
              {...field}
              // value={(paytermsList.length && field.value) || ""}
            >
              {PAYMENT_TERM_TYPE.map((type) => (
                <MenuItem key={type.value} value={type.value}>
                  {type.name}
                </MenuItem>
              ))}
            </StyledSelect>
          )}
          name="primaryPaymentMethod"
          control={control}
        />
      </Grid>

      <Grid xs={6} item>
        <Controller
          render={({ field, fieldState: { error } }) => (
            <StyledSelect
              adminIsAllowed
              fullWidth
              formSx={{ mb: "16px" }}
              size="small"
              labelId="select-label-payment"
              error={error ? error.message : ""}
              label="Payment terms"
              IconComponent={(props) => (
                <ExpandMore sx={{ fontSize: 27 }} {...props} />
              )}
              labelSx={{
                color: "#5F6267!important",
                fontWeight: 400,
                fontSize: 13,
                top: 3,
                "&.MuiInputLabel-shrink": { top: 0 },
              }}
              sx={{
                "&:hover": {
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: " #3F3F3F !important",
                  },
                },
              }}
              height="43px"
              fontSize="13px"
              {...field}
              value={(paytermsList.length && field.value) || ""}
            >
              <MenuItem
                disabled={
                  !!repPermissions && !repPermissions?.settings?.payment_terms
                }
                onClick={() => {
                  // if (isAdmin) return;
                  setOpenPaymentTermModal(true);
                }}
              >
                <OutlinedPlusIcon />
                <Typography
                  fontSize="13px"
                  fontWeight="400"
                  color="#42A57F"
                  ml={1}
                >
                  Add new
                </Typography>
              </MenuItem>
              <MenuItem
                value={
                  paymentTermsDuplicate?.paymentTermsId ||
                  paymentTermsDuplicate?.id
                }
                sx={{ display: "none" }}
              >
                {paymentTermsDuplicate?.name}
              </MenuItem>
              {paytermsList?.map((pt) => (
                <MenuItem key={pt.id} value={pt.id}>
                  {pt.name}
                </MenuItem>
              ))}
            </StyledSelect>
          )}
          name="paymentTermsId"
          control={control}
        />
      </Grid>
    </Grid>
  );
};
