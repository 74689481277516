import { func, string, shape } from "prop-types";
import { Box, IconButton, Typography } from "@mui/material";
import { CrossBigIcon } from "../../../Icons";

export const ReturnDialogHeader = ({ state, onClose }) => {
  const { title, orderCustomId } = state || {};

  return (
    <Box
      borderBottom="0.5px solid #D5D9D9"
      display="flex"
      justifyContent="space-between"
      p="14px 16px 8px 26px"
    >
      <Box>
        <Box>
          <Typography fontSize={30} fontWeight={400} color="#707070">
            {title}
          </Typography>
        </Box>

        <Box>
          <Typography fontSize={20} fontWeight={400} color="#707070">
            Order ID: {orderCustomId}
          </Typography>
        </Box>
      </Box>
      <IconButton sx={{ width: "36px", height: "36px" }} onClick={onClose}>
        <CrossBigIcon size={20} />
      </IconButton>
    </Box>
  );
};

ReturnDialogHeader.propTypes = {
  state: shape({
    title: string,
    orderCustomId: string,
  }),
  onClose: func,
};
ReturnDialogHeader.defaultProps = {
  state: {
    title: "",
    orderCustomId: "",
  },
  onClose: () => {},
};
