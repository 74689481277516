import { useCallback, useEffect, useMemo, useState } from "react";

export const useReturnListActions = ({
  open,
  returnItem,
  order,
  productReturns,
}) => {
  const [productsList, setProductsList] = useState([]);

  const deliveries = useMemo(
    () => (order?.orderDeliveries?.length ? [...order?.orderDeliveries] : []),
    [order?.orderDeliveries]
  );

  const returns = useMemo(
    () => (order?.orderReturns?.length ? [...order?.orderReturns] : []),
    [order?.orderReturns]
  );

  const productsListWithGroups = useCallback((products) => {
    if (products?.length) {
      const groups = products?.reduce(
        (acc, p, i) => {
          if (p?.product?.parentProduct) {
            if (acc[p?.product?.parentProduct?.id]) {
              acc[p?.product?.parentProduct?.id].push({ ...p, index: i });
            } else {
              acc[p?.product?.parentProduct?.id] = [
                {
                  parentId: p?.product?.parentProduct?.id,
                  id: p?.name,
                  name: p?.name,
                  manufacturerName: p?.manufacturerName,
                  photo: p?.product?.parentProduct?.photos?.[0],
                },
                { ...p, index: i },
              ];
            }
          } else {
            acc.without_group.push({ ...p, index: i });
          }
          return acc;
        },
        { without_group: [] }
      );

      const addedLastEl = Object.entries(groups).map((el) => {
        if (el[0] === "without_group") {
          return el;
        } else {
          const indexLastEl = el[1].length - 1;
          let newEl = { ...el };
          newEl[1][indexLastEl].isLastEl = true;
          return newEl;
        }
      });

      return addedLastEl?.map((group) => group[1])?.flat();
    }
    return [];
  }, []);

  useEffect(() => {
    if (open) {
      if (returnItem?.id) {
        setProductsList(
          productsListWithGroups(
            returnItem?.productReturns?.map((prod) => {
              return order?.products?.find(
                (i) => i?.id === prod?.orderProductId
              );
            })
          )
        );
      } else {
        setProductsList(productsListWithGroups(order?.products));
      }
    }
  }, [returnItem, order?.products, productsListWithGroups, open]);

  return {
    productsList,
    deliveries,
    returns,
    returnItem,
    productReturns,
  };
};
