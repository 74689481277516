import { useMemo } from "react";
import { bool, object, func, any } from "prop-types";
import { Box, Fade, Popper, Typography } from "@mui/material";
import { checkAndPrepareText } from "helpers/helpers";

export const ValidationPopper = ({
  anchorEl,
  errors,
  isOpen,
  setIsOpen,
  tailProps,
  tooltipTitle,
  ...props
}) => {
  const errorCount = Object.keys(errors).reduce((prevSum, key) => {
    if (Array.isArray(errors[key]))
      return prevSum + Object.keys(errors[key]).length;
    return prevSum + 1;
  }, 0);

  const errorMessageSingleBarcode = useMemo(
    () => errors?.barcode?.message,
    [errors?.barcode?.message]
  );
  // const errorMessageMultipleBarcode = useMemo(() => {
  //   return errors?.variationsFields?.length
  //     ? errors?.variationsFields?.some((item) => {
  //         return (
  //           item?.barcode?.message ===
  //           "This barcode has been already used on the existing product"
  //         );
  //       })
  //     : null;
  // }, [errors?.variationsFields]);

  return (
    <Popper
      id="product-validations-popup"
      open={isOpen && !!Object.keys(errors).length}
      //anchor el should be the "Save" button
      anchorEl={anchorEl}
      placement="bottom-start"
      transition
      modifiers={[
        {
          name: "offset",
          options: {
            // eslint-disable-next-line react/prop-types
            offset: props.offset ? props.offset : [15, 15],
          },
        },
      ]}
      {...props}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Box position="relative">
            <Box
              // backgroundColor="#E6D7D7"
              backgroundColor="#EFDADC"
              p="10.5px 13.5px"
              maxWidth="320px"
              borderRadius="8px"
              boxShadow="0px 0px 0px 0.5px red"
              sx={{
                "& .MuiTypography-root": {
                  color: "#1C1C19 ",
                },
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: tailProps?.top ? tailProps?.top : 10,
                  right: tailProps?.right ? tailProps?.right : -6,
                  zIndex: 0,
                  bottom: tailProps?.bottom ? tailProps?.bottom : "auto",
                }}
              >
                <Box
                  sx={{
                    zIndex: 3,
                    position: "relative",
                    backgroundColor: "#EFDADC",
                    textAlign: "left",
                    width: "12px",
                    height: "12px",
                    borderTopRightRadius: "30%",
                    transform: `rotate(${
                      tailProps?.rotate ? tailProps?.rotate : -90
                    }deg) skewX(-40deg) scale(1.02,.542)`,
                    "&:before, &:after": {
                      content: "''",
                      position: "absolute",
                      backgroundColor: "inherit",
                      width: "12px",
                      height: "12px",
                      borderTopRightRadius: "30%",
                    },
                    "&:before": {
                      transform:
                        "rotate(-135deg) skewX(-45deg) scale(1.414,.707) translate(0,-50%)",
                    },
                    "&:after": {
                      transform:
                        "rotate(135deg) skewY(-45deg) scale(.707,1.414) translate(50%)",
                    },
                  }}
                />
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  top: tailProps?.top ? tailProps?.top - 2 : 10,
                  right: tailProps?.right ? tailProps?.right : -8,
                  zIndex: -3,
                  bottom: tailProps?.bottom ? tailProps?.bottom - 2 : "auto",
                }}
              >
                <Box
                  sx={{
                    zIndex: 2,
                    position: "relative",
                    backgroundColor: "#EB4233",
                    textAlign: "left",
                    width: "12px",
                    height: "12px",
                    borderTopRightRadius: "30%",
                    transform: `rotate(${
                      tailProps?.rotate ? tailProps?.rotate : -90
                    }deg) skewX(-42deg) scale(0.92,.470)`,
                    "&:before, &:after": {
                      content: "''",
                      position: "absolute",
                      backgroundColor: "inherit",
                      width: "12px",
                      height: "12px",
                      borderTopRightRadius: "30%",
                    },
                    "&:before": {
                      transform:
                        "rotate(-135deg) skewX(-45deg) scale(1.414,.707) translate(0,-50%)",
                    },
                    "&:after": {
                      transform:
                        "rotate(135deg) skewY(-45deg) scale(.707,1.414) translate(50%)",
                    },
                  }}
                />
              </Box>
              {/* <IconButton
                onClick={() => setIsOpen(false)}
                sx={{ position: "absolute", top: 2, right: 2 }}
              >
                <CrossIcon size="15" />
              </IconButton> */}
              {tooltipTitle ? (
                tooltipTitle
              ) : (
                <>
                  <Typography fontSize="12px" fontWeight="300">
                    {`There ${
                      errorCount === 1 ? "is" : "are"
                    } ${errorCount} item${
                      errorCount === 1 ? "" : "s"
                    } that require your attention.`}
                    <br />
                    Make sure to check each field.
                  </Typography>
                  {
                    errorMessageSingleBarcode ===
                      "This barcode has been already used on the existing product"
                    // || errorMessageMultipleBarcode) && (
                    // <Typography fontSize="12px" fontWeight="400">
                    //   This barcode has been already used on the existing
                    //   product.
                    // </Typography>
                  }
                  {errors?.name?.type === "specialCharacter" && (
                    <Typography fontSize="12px" fontWeight="300">
                      • Invalid character used{" "}
                      {checkAndPrepareText(errors?.name?.valueRef)
                        .map((e) => e)
                        .join(", ")}
                    </Typography>
                  )}
                  {errors?.isPrimaryContact && (
                    <Typography mt={2} fontSize="12px" fontWeight="400">
                      At least 1 primary contact is required
                    </Typography>
                  )}
                </>
              )}
            </Box>
          </Box>
        </Fade>
      )}
    </Popper>
  );
};

export default ValidationPopper;

ValidationPopper.propTypes = {
  anchorEl: object,
  isOpen: bool,
  setIsOpen: func,
  errors: object,
  tailProps: object,
  tooltipTitle: any,
};

ValidationPopper.defaultProps = {
  anchorEl: null,
  isOpen: false,
  errors: {},
};
