/* eslint-disable no-useless-escape */
import * as Yup from "yup";

const PHONE_REGEXP =
  /^[\+][\d]{1}[\s]?[\(]?[\d]{3}[\)]?[\s]?[\d]{3}[\s]?[\d]{4}$/im;

export const validationSchema = ({ activeOrderCount }) =>
  Yup.object().shape({
    federalTaxId: Yup.string()
      .trim()
      .nullable()
      .test("federalTaxId", "Must be exactly 9 digits", (v) => {
        return /^\d{2}[-{1}]?\d{7}$/.test(v) || v === "";
      }),
    website: Yup.string()
      .nullable()
      .test("websiteValidation", "Enter valid link", (value) => {
        const URL_REGEXP =
          // /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/;
          /^(http(s)?:\/\/|www\.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/;
        if (!value) return true;
        return URL_REGEXP.test(value);
      })
      .notRequired(),
    name: Yup.string().trim().required(" "),
    groupId: Yup.number()
      .transform((v, o) => (o === "" ? null : v))
      .nullable()
      .required("This field is required"),
    territoryId: Yup.string().trim().required("This field is required"),
    phone: Yup.string()
      .trim()
      .test("phoneValidation", "Not valid phone number!", (value) => {
        if (!value.replace("+", "")) return true;
        return PHONE_REGEXP.test(value);
      }),
    email: Yup.string()
      .trim()
      .test("emailValidation", "Email not valid!", (value) => {
        if (!value) return true;
        const EMAIL_REGEXP =
          /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d)|(([a-z]|\d)([a-z]|\d|-|\.|_|~)*([a-z]|\d)))\.)+(([a-z])|(([a-z])([a-z]|\d|-|\.|_|~)*([a-z])))$/i; // eslint-disable-line
        return EMAIL_REGEXP.test(value);
      }),
    percentDiscount: Yup.number()
      .transform((v, o) => (o === "" ? 0 : v))
      .nullable(true)
      .required("This field is required")
      .typeError("Must be a number.")
      .test("discountValidation", "Please type a valid discount.", (value) => {
        return value >= 0 && value <= 100;
      }),
    customId: Yup.string()
      .trim()
      .test("customIdValidation", "From 1 to 10 characters", (value) => {
        if (!value) return true;
        return value.length <= 10 && value.length >= 1;
      }),
    billingAddress: Yup.object().shape({
      formatted_address: Yup.string().required("This field is required"),
      street: Yup.string().required("This field is required"),
      city: Yup.string().required("This field is required"),
      state: Yup.string().required("This field is required"),
      zip: Yup.string().required("This field is required"),
    }),
    shippingAddress: Yup.object().shape({
      formatted_address: Yup.string().required("This field is required"),
      street: Yup.string().required("This field is required"),
      city: Yup.string().required("This field is required"),
      state: Yup.string().required("This field is required"),
      zip: Yup.string().required("This field is required"),
      lat: Yup.number().required("Coordinates are missing"),
      lng: Yup.number().required("Coordinates are missing"),
    }),
    representatives:
      activeOrderCount &&
      Yup.object().shape({
        sales: Yup.array().test("salesValidations", " ", (value) => {
          return value.length > 0;
        }),
      }),
  });
