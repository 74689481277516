import { useState } from "react";
import { string } from "prop-types";
import { Menu, MenuList } from "@mui/material";
import { ColumnsSettingsList } from "./ColumnsSettingsList";
import { ThreeDotButton } from "../../../../../../../../components";
import { useAdmin } from "helpers/helpers";
import { useRepsPermissions } from "helpers/hooks";

export const ColumnsSettingComponent = ({ currentTab }) => {
  const isAdmin = useAdmin();
  const repPermissions = useRepsPermissions();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  return (
    <>
      <ThreeDotButton
        disabled={!!repPermissions || isAdmin}
        paddingRight="0px"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      />
      <Menu
        id="column-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        sx={{
          "& .MuiPaper-root": { borderRadius: "8px" },
        }}
        MenuListProps={{
          "aria-labelledby": "setting-button",
          sx: {
            width: "340px",
            py: 0,
          },
        }}
      >
        <MenuList>
          <ColumnsSettingsList
            onClose={() => setAnchorEl(null)}
            currentTab={currentTab}
          />
        </MenuList>
      </Menu>
    </>
  );
};

ColumnsSettingComponent.propTypes = {
  currentTab: string,
};
