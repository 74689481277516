import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  headerWrap: {
    padding: "13px 22px 14px 19px",
    display: "flex",
    flexDirection: "column",
    gap: "11px",
  },
}));

export default useStyles;
