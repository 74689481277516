export const WIDGET_SIZES = [
  {
    value: "lg",
    text: "Large (900 x 600 px)",
    sizes: { width: 900, height: 600, scale: 0.42 },
  },
  {
    value: "st",
    text: "Standard (600 x 500 px)",
    sizes: { width: 600, height: 500, scale: 0.5 },
  },
  {
    value: "sm",
    text: "Small (335 x 165 px)",
    sizes: { width: 335, height: 165, scale: 1 },
  },
];
