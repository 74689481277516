import { array, bool, object, func } from "prop-types";
import { useWatch } from "react-hook-form";
import { IconButton, Typography, Box } from "@mui/material";
import { PlusIcon } from "../../../../../../../../../components/Icons";
import TableHeader from "../../../TableHeader";
import { ContactsItem } from "./components";

const ContactsTable = ({
  contacts,
  isEdit,
  control,
  setValue,
  clearErrors,
  phoneCodeList,
  handleAddContact,
  handleRemoveContact,
  handleChangeDefault,
}) => {
  const formField = useWatch({ control });

  return (
    <Box>
      <TableHeader
        title="Contacts"
        isEdit={isEdit}
        addElement={
          <IconButton onClick={handleAddContact}>
            <PlusIcon stroke="#eeeeee" fill="#47A06D" width="20" height="20" />
          </IconButton>
        }
      />
      {contacts.length ? (
        <Box
          sx={{
            maxHeight: "296px",
            overflowY: "auto",
          }}
        >
          {contacts.map((contact, index) => (
            <ContactsItem
              key={contact.id}
              phoneCodeList={phoneCodeList}
              contact={formField.contacts[index]}
              countryPrefixFormat={
                formField?.contacts?.[index]?.countryPrefix?.format
              }
              {...{
                // contact
                index,
                isEdit,
                control,
                setValue,
                clearErrors,
                handleRemoveContact,
                handleChangeDefault,
              }}
            />
          ))}
        </Box>
      ) : (
        <Box
          sx={{
            border: "1px solid #D5D9D9",
            borderTop: "none",
            borderRadius: "0 0 4px 4px",
            width: "100%",
          }}
        >
          <Typography
            fontSize="16px"
            textAlign="center"
            color="#6A6A6A"
            padding="20px 0"
          >
            No contacts added yet
          </Typography>
        </Box>
      )}
    </Box>
  );
};

ContactsTable.propTypes = {
  contacts: array,
  isEdit: bool,
  control: object,
  setValue: func,
  clearErrors: func,
  handleAddContact: func,
  handleRemoveContact: func,
  handleChangeDefault: func,
  phoneCodeList: array,
};

export default ContactsTable;
