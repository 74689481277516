import { Button } from "@mui/material";
import { StyledButton } from "components";
import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import {
  openConfirmDialogAction,
  setConfirmIsOpenAction,
} from "redux/actions/confirmDialogs";

export const TYPES_CONFIRM_DIALOG = {
  role_change: "role_change",
};

export const useProfileComponent = () => {
  const dispatch = useDispatch();

  const TYPES = useMemo(
    () => ({
      role_change: {
        title: "Role change?",
        text: (repName) =>
          `All permissions for ${repName} will be cleared. Without permissions, ${repName} won't be able to log in. Do you want to proceed with the role change?`,
        buttons: (onConfirm) => (
          <>
            <Button
              sx={{
                width: "auto",
                height: "28px",
                color: "#FFFFFF",
                fontSize: "13px",
                boxShadow: "none",
              }}
              color="confirmDelete"
              onClick={() => {
                if (onConfirm) onConfirm();
                dispatch(setConfirmIsOpenAction(false));
              }}
              variant="contained"
            >
              Yes
            </Button>

            <StyledButton
              variant="outlined"
              color="cancel"
              label="No"
              sx={{ height: "28px" }}
              onClick={() => dispatch(setConfirmIsOpenAction(false))}
            />
          </>
        ),
      },
    }),
    [dispatch]
  );

  const handleConfirmDialog = useCallback(
    ({ repName = "", onConfirm, type }) => {
      if (!Object.values(TYPES_CONFIRM_DIALOG).includes(type))
        // eslint-disable-next-line no-console
        return console.error(
          "handleConfirmDialog type error: needs to be one of",
          Object.values(TYPES_CONFIRM_DIALOG)
        );

      dispatch(
        openConfirmDialogAction({
          title: TYPES[type].title,
          text: TYPES[type].text(repName),
          buttons: TYPES[type].buttons(onConfirm),
        })
      );
    },
    [TYPES, dispatch]
  );

  return { handleConfirmDialog };
};
