import { Controller } from "react-hook-form";
import { string, object, number, func, bool } from "prop-types";

import { CartTrashIcon } from "components/Icons";
import { StyledLabel, StyledPopper, StyledTextField } from "components";

import { Box, IconButton, InputAdornment, Radio, Stack } from "@mui/material";
import { useRef } from "react";

const RoleItem = ({
  role,
  index,
  control,
  isActive,
  onEditRole,
  defaultRole,
  handleRemoveRole,
  handleInputChange,
  handleChangeDefault,
}) => {
  const inputRef = useRef();

  if (!role) return <></>;
  return (
    <Stack
      width="fit-content"
      direction="row"
      alignItems="center"
      ml="12px"
      sx={{ height: "100%" }}
    >
      <Controller
        render={({ field }) => {
          return (
            <>
              <Radio
                size="small"
                checked={isActive}
                {...field}
                onChange={() => {
                  handleChangeDefault(index);
                }}
              />
              <StyledLabel
                fill={isActive ? "#47a06d" : "#D5D9D9"}
                style={{
                  mr: "13px",
                  pt: "0.5px",
                  width: "48px",
                }}
              />
            </>
          );
        }}
        name={`contactRoles.${index}.defaultRole`}
        control={control}
      />
      <Controller
        render={({ field, fieldState: { error } }) => {
          const showTooltip = error && error.type === "dublicate";
          return (
            <>
              <StyledPopper
                anchorEl={inputRef.current}
                open={showTooltip}
                sx={{
                  pointerEvents: "none",
                }}
                text={error?.message || ""}
              />
              <Box ref={inputRef}>
                <StyledTextField
                  fullWidth
                  size="large"
                  //disabled={!!Object.keys(errors).length && !error}
                  InputProps={{
                    placeholder: "Title",
                    sx: {
                      minWidth: "243px",
                      height: "43px",
                      fontSize: "15px",
                      borderRadius: "9px",
                      paddingLeft: 0,
                      paddingRight: "8px",
                      "& .MuiOutlinedInput-input": {
                        padding: "10px 0px 10px 10px",
                      },
                    },
                    endAdornment: role?.defaultRole ? null : (
                      <InputAdornment position="end" sx={{ height: "100%" }}>
                        <IconButton onClick={() => handleRemoveRole(index)}>
                          <CartTrashIcon width="15" height="17" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={error?.message || ""}
                  {...field}
                  onChange={(e) =>
                    handleInputChange(e, defaultRole, role, index, field)
                  }
                  onBlur={() => onEditRole(index, role)}
                />
              </Box>
            </>
          );
        }}
        name={`contactRoles.${index}.name`}
        control={control}
      />
    </Stack>
  );
};

RoleItem.propTypes = {
  control: object,
  role: object,
  defaultRole: object,
  title: string,
  index: number,
  isActive: bool,
  onEditRole: func,
  setIsTyping: func,
  handleRemoveRole: func,
  checkForUniqNames: func,
  handleInputChange: func,
  handleChangeDefault: func,
};

export default RoleItem;
