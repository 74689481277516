import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Typography,
} from "@mui/material";
import { bool, func, object } from "prop-types";
import { Controller, useForm, useWatch } from "react-hook-form";
import { LargeCheckbox } from "../../../../../../../../../components/Checkboxes";
import { CrossIcon } from "../../../../../../../../../components/Icons";
import { StyledSelect } from "../../../../../../../../../components/Selects";
import StyledButton from "../../../../../../../../../components/StyledButton";
import { StyledTextField } from "../../../../../../../../../components/TextFields/TextFields";
import AddressField from "../../../../../../../../CustomersPage/components/AddressField/AddressField";
import { defaultValues } from "./PersonalDetails.constants";
import useStyles from "./styles";
import { range } from "lodash";
import MapPopup from "../../../../../../../../CustomersPage/pages/NewCustomerPage/MapPopup/MapPopup";
import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema } from "./PersonalDetailsDialog.validations";
import {
  createPersonalDetailsService,
  updatePersonalDetailsService,
} from "../../../../../../../../../services/stripe";
import { error } from "../../../../../../../../../utils/notifications";
import { useCallback } from "react";
import NumberFormat from "react-number-format";
import { createSelector } from "reselect";
import { currentUserSelector } from "../../../../../../../../../redux/selectors/auth";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import moment from "moment";
import { Info } from "@mui/icons-material";
import { usePersonalDetails } from "../../PayoutsTab.hooks";

const selector = createSelector(currentUserSelector, (currentUser) => ({
  currentUser,
}));

const PersonalDetailsDialog = ({
  isOpen,
  onClose,
  personalDetails,
  handleAddDetails,
  setLoading,
}) => {
  const { requirements, isSameState, checkSame } = usePersonalDetails({
    isSetup: false,
  });

  const { officeLocation, businessContact } = useMemo(
    () => isSameState,
    [isSameState]
  );

  const { currentUser } = useSelector(selector);
  const classes = useStyles();

  const [mapOpen, setMapOpen] = useState(false);

  const { control, setValue, handleSubmit, reset } = useForm({
    mode: "onChange",
    defaultValues: {
      ...defaultValues,
    },
    resolver: yupResolver(validationSchema()),
  });

  const formField = useWatch({ control });

  const onSubmit = (data) => {
    const { heightOfGoogleAddresses, ...preparedData } = data || {};

    const newAddress = { ...data.address };
    Object.keys(newAddress).forEach((k) => {
      if (typeof newAddress[k] === "string")
        newAddress[k] = newAddress[k].trim().trimStart();
    });
    const { id, ...uploadData } = {
      ...preparedData,
      address: newAddress,
    };

    if (personalDetails) {
      setLoading(true);
      updatePersonalDetailsService(uploadData)
        .then((res) => {
          handleAddDetails(res);
          onClose();
          setLoading(false);
        })
        .catch((err) => {
          const isPhoneError = err?.response?.data?.message
            ? err.response.data.message.includes("body.phone")
            : false;
          const errPhoneMsg = isPhoneError ? "Wrong phone number" : "";

          error(errPhoneMsg || err?.response?.data?.message);
          setLoading(false);
        });
      return;
    }

    setLoading(true);
    createPersonalDetailsService(preparedData)
      .then((res) => {
        setLoading(false);
        handleAddDetails(res);
      })
      .catch((err) => {
        setLoading(false);
        error(err?.response?.data?.message);
      });
  };

  const handleReset = useCallback(() => {
    if (isOpen) reset({ ...defaultValues, ...personalDetails, ssn: "" });
  }, [isOpen, personalDetails, reset]);

  useEffect(() => {
    handleReset();
  }, [handleReset]);

  const { billingAddress, baseUser, phone } = useMemo(
    () => currentUser,
    [currentUser]
  );

  const handleSameAsOfficeChange = () => {
    checkSame("officeLocation");

    if (officeLocation)
      return setValue(
        "address",
        personalDetails?.address || defaultValues.address
      );
    setValue("address", billingAddress);
  };

  const handleSameAsBusinessContactChange = () => {
    checkSame("businessContact");
    if (businessContact) {
      setValue("phone", personalDetails?.phone || defaultValues.phone);
      setValue("email", personalDetails?.email || defaultValues.email);
      return;
    }
    setValue("phone", phone);
    setValue("email", baseUser.email);
  };

  const daysInMonth = useMemo(
    () =>
      moment()
        .set("month", formField.dateOfBirth.month - 1)
        .set("year", formField.dateOfBirth.year)
        .daysInMonth(),
    [formField.dateOfBirth.month, formField.dateOfBirth.year]
  );

  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        sx: { maxHeight: "calc(100% - 16px)" },
      }}
    >
      <MapPopup
        isOpen={mapOpen}
        handleClose={() => setMapOpen(false)}
        address={formField.address}
        handleSetAddress={(val) => setValue("address", val)}
      />
      <DialogTitle className={classes.dialogTitle}>
        <Typography>Personal details</Typography>
        <IconButton onClick={onClose}>
          <CrossIcon size="30" />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {!!requirements?.length && !!personalDetails && (
          <Box className={classes.warningBlock}>
            <Box className="title">
              <Info style={{ fill: "#EB4233" }} />
              <Typography>Verification unsuccessful due to:</Typography>
            </Box>
            <Box pl="29px">
              {requirements?.map((req) => (
                <Typography noWrap key={req}>
                  - {req}
                </Typography>
              ))}
            </Box>
          </Box>
        )}
        <Typography fontSize="12px" fontWeight="300" mb="5px" color="#1c1c19">
          The person associated with your account should be a business owner or
          significant shareholder
        </Typography>
        <Box
          className={classes.fieldsWrap}
          component="form"
          id="personal-details-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                label="First name"
                error={error?.message || ""}
                {...field}
              />
            )}
            name="firstName"
            control={control}
          />
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                label="Last name"
                error={error?.message || ""}
                {...field}
              />
            )}
            name="lastName"
            control={control}
          />
        </Box>
        <Box borderBottom="0.5px solid #d5d9d9" pb="10px">
          <Box className={classes.fieldsWrap}>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <NumberFormat
                  customInput={StyledTextField}
                  label="SSN (Last 4 digits)"
                  format="####"
                  noErrorMessage
                  error={error?.message || ""}
                  {...field}
                />
              )}
              name="ssn"
              control={control}
            />
            <Controller
              render={({ field, fieldState: { error } }) => (
                <StyledTextField
                  label="Title"
                  error={error?.message || ""}
                  {...field}
                />
              )}
              name="title"
              control={control}
            />
          </Box>
          <Typography fontSize="10px" fontWeight="300" mt="9px" color="#1c1c19">
            We use this to verify your identity and keep your account secure.
          </Typography>
        </Box>
        <Typography className={classes.blockTitle}>Date of birth</Typography>
        <Box className={classes.fieldsWrap}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledSelect
                fullWidth
                label="Month"
                error={error?.message || ""}
                {...field}
              >
                {range(1, 13).map((month) => (
                  <MenuItem key={month} value={month}>
                    {month}
                  </MenuItem>
                ))}
              </StyledSelect>
            )}
            name="dateOfBirth.month"
            control={control}
          />
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledSelect
                fullWidth
                label="Day"
                error={error?.message || ""}
                {...field}
              >
                {range(1, daysInMonth + 1).map((day) => (
                  <MenuItem key={day} value={day}>
                    {day}
                  </MenuItem>
                ))}
              </StyledSelect>
            )}
            name="dateOfBirth.day"
            control={control}
          />
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledSelect
                fullWidth
                label="Year"
                error={error?.message || ""}
                noErrorMessage
                {...field}
              >
                {range(1950, moment().year() - 18).map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </StyledSelect>
            )}
            name="dateOfBirth.year"
            control={control}
          />
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt="12px"
        >
          <Typography m="0!important" className={classes.blockTitle}>
            Contacts
          </Typography>
          <LargeCheckbox
            formSx={{ m: 0 }}
            labelSx={{ fontSize: "12px", fontWeight: "300", color: "#1c1c19" }}
            label="Same as business contact"
            size={15}
            onChange={handleSameAsBusinessContactChange}
            checked={businessContact}
          />
        </Box>
        <Box className={classes.fieldsWrap}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                label="Email"
                disabled={businessContact}
                error={error?.message || ""}
                {...field}
              />
            )}
            name="email"
            control={control}
          />
          <Controller
            render={({ field, fieldState: { error } }) => (
              <NumberFormat
                customInput={StyledTextField}
                fullWidth
                format="+# (###) ### ####"
                mask="_"
                disabled={businessContact}
                size="small"
                error={error?.message || ""}
                label="Phone"
                onClick={() => {
                  if (["", "+"].includes(field?.value)) {
                    setValue("phone", "+1");
                  }
                }}
                {...field}
              />
            )}
            name="phone"
            control={control}
          />
        </Box>
        <Box className={classes.addressBlock}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb="10px"
          >
            <Typography m="0!important" className={classes.blockTitle}>
              Address
            </Typography>
            <LargeCheckbox
              formSx={{ mr: "16px" }}
              labelSx={{
                fontSize: "12px",
                fontWeight: "300",
                color: "#1c1c19",
              }}
              label="Same as office location"
              size={15}
              checked={officeLocation}
              onChange={handleSameAsOfficeChange}
            />
          </Box>
          <AddressField
            hideUnit
            disabled={officeLocation}
            addressToggled
            noToggleLabel
            onMapOpen={() => setMapOpen(true)}
            fieldName="address"
            address={formField.address}
            {...{ setValue, control }}
            unitLabel="Unit # (Optional)"
          />
        </Box>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <StyledButton
          fontSize="13px"
          label="Cancel"
          color="edit"
          onClick={onClose}
        />
        <StyledButton
          type="submit"
          fontSize="13px"
          label="Save"
          variant="contained"
          form="personal-details-form"
        />
      </DialogActions>
    </Dialog>
  );
};

PersonalDetailsDialog.propTypes = {
  onClose: func,
  isOpen: bool,
  personalDetails: object,
  handleAddDetails: func,
  setLoading: func,
};
export default PersonalDetailsDialog;
