import React, { useContext, useEffect, useMemo, useRef } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import pluralize from "pluralize";
import { AddRounded } from "@mui/icons-material";
import { DataTableHeader } from "./components";
import { InfiniteLoaderWrapper, StyledButton } from "components";
import { CustomerProfileContext } from "Pages/CustomersPage/pages/CustomerProfilePage/CustomerProfilePage";
import { useWindowSize } from "helpers/hooks";
import { FiltersTabs } from "../FiltersTabs/FiltersTabs";
import { number } from "prop-types";
import useStyles from "./styles";

const DataTable = ({ clientHeight }) => {
  const tableRef = useRef();
  const [windowWidth, windowHeight] = useWindowSize();

  const NAVBAR_HEIGHT = 72;
  const SUBHEADER_HEIGHT = 44;
  const TABLE_HEADER_HEIGHT = 110;
  const tableHeight = tableRef.current?.clientHeight;
  const BOTTOM_OFFSET = 43;
  const BUTTON_HEIGHT = 44;

  const createButtonTop =
    NAVBAR_HEIGHT +
    SUBHEADER_HEIGHT +
    TABLE_HEADER_HEIGHT +
    tableHeight -
    BOTTOM_OFFSET;

  const shouldUseTop = createButtonTop < windowHeight;

  const classes = useStyles();
  const { currentTable, customerState, filterSearch, filters, customerId } =
    useContext(CustomerProfileContext);

  const {
    handleFetch,
    TableItem,
    getProps,
    handleCreate,
    getControlPanelProps,
    ControlPanel,
    hasControlPanel,
  } = useMemo(() => currentTable || {}, [currentTable]);

  const currentTab = useMemo(
    () => customerState.tableTab?.value,
    [customerState.tableTab?.value]
  );

  const customerIdRef = useRef(customerId);

  useEffect(() => {
    if (customerIdRef.current !== customerId) {
      handleFetch(true);
      customerIdRef.current = customerId;
      return;
    }

    handleFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterSearch, filters, currentTab, customerId]);

  const tableItems = useMemo(
    () => customerState.tableData?.items[customerState.tableTab.value],
    [customerState.tableData?.items, customerState.tableTab.value]
  );

  const tableCount = useMemo(
    () => customerState.tableData?.count,
    [customerState.tableData?.count]
  );

  const tableExistData = useMemo(
    () => customerState.tableData?.existDataByCustomer,
    [customerState.tableData?.existDataByCustomer]
  );

  const tableLoading = useMemo(
    () => !!customerState.tableData?.loading[customerState.tableTab.value],
    [customerState.tableData?.loading, customerState.tableTab.value]
  );

  const offsetWidth = windowWidth - tableRef.current?.clientWidth - 3;

  const panelProps = useMemo(
    () => hasControlPanel && getControlPanelProps(tableLoading, tableCount),
    [hasControlPanel, getControlPanelProps, tableLoading, tableCount]
  );

  return (
    <Box
      ref={tableRef}
      position="relative"
      height={`calc(100% - ${currentTable?.headerLabels ? "149px" : "115px"})`}
    >
      <FiltersTabs />

      {hasControlPanel && <ControlPanel {...panelProps} />}
      {currentTable?.headerLabels ? (
        <DataTableHeader
          labelsWithFixedWidth={currentTable?.labelsWithFixedWidth}
          labels={currentTable?.headerLabels}
          actionBlock={currentTable?.actionBlock}
        />
      ) : null}

      {tableLoading ? (
        <Box
          display="flex"
          alignItems="center"
          height="60vh"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      ) : tableItems?.length ? (
        <InfiniteLoaderWrapper
          itemsList={tableItems}
          itemsCount={tableCount}
          offsetHeight={currentTable?.headerLabels ? 143 : 115}
          offsetWidth={offsetWidth}
          loading={tableLoading}
          clientHeight={clientHeight}
          handleFetch={() => {
            handleFetch();
          }}
          tableStyle={{
            border: "none",
            height: "100%",
            width: "100%",
          }}
          itemHeight={customerState.tableTab.label === "Timeline" ? 144 : 48}
          renderChildren={(index) => {
            const props = getProps?.(
              tableItems[index],
              index,
              tableItems?.length || 0
            );
            return <TableItem {...props} />;
          }}
        />
      ) : tableExistData ? (
        <Typography textAlign="center" lineHeight="400px">
          {customerState.tableTab.label === "Timeline"
            ? "No activities found"
            : `No ${customerState.tableTab.label.toLowerCase()} found`}
        </Typography>
      ) : (
        <Typography textAlign="center" lineHeight="400px">
          {customerState.tableTab.label === "Timeline"
            ? "No activities yet"
            : `No ${customerState.tableTab.label.toLowerCase()} created yet`}
        </Typography>
      )}
      {handleCreate && (
        <StyledButton
          className={classes.createButton}
          sx={{
            bottom: shouldUseTop ? "unset" : "43px",
            top: shouldUseTop
              ? `${windowHeight - BOTTOM_OFFSET - BUTTON_HEIGHT}px`
              : "unset",
          }}
          label={`New ${pluralize(
            customerState.tableTab.label,
            1
          ).toLowerCase()}`}
          variant="contained"
          fontSize="14px"
          onClick={() => handleCreate(null)}
          startIcon={<AddRounded style={{ fontSize: "24px" }} />}
        />
      )}
    </Box>
  );
};

export default DataTable;

DataTable.propTypes = {
  clientHeight: number || null,
};
