import {
  START_LOADING_TERRITORY_TREE,
  END_LOADING_TERRITORY_TREE,
  START_LOADING_TERRITORY_LIST,
  END_LOADING_TERRITORY_LIST,
  GET_TERRITORY_TREE,
  GET_TERRITORY_LIST,
  GET_CUSTOMERS_WITHOUT_TERRITORY,
} from "../actions/territory";

const initialState = {
  territoryTree: [],
  territoryList: [],
  customersWithoutTerritory: [],
  loadingTerritoryTree: false,
  loadingTerritoryList: false,
};

const territoryReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case START_LOADING_TERRITORY_TREE:
      return { ...state, loadingTerritoryTree: true };

    case END_LOADING_TERRITORY_TREE:
      return { ...state, loadingTerritoryTree: false };

    case START_LOADING_TERRITORY_LIST:
      return { ...state, loadingTerritoryList: true };

    case END_LOADING_TERRITORY_LIST:
      return { ...state, loadingTerritoryList: false };

    case GET_TERRITORY_TREE:
      return { ...state, territoryTree: payload };

    case GET_TERRITORY_LIST:
      return { ...state, territoryList: payload };

    case GET_CUSTOMERS_WITHOUT_TERRITORY:
      return { ...state, customersWithoutTerritory: payload };

    default:
      return state;
  }
};

export default territoryReducer;
