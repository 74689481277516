import axios from "axios";

export const uploadImportSheetService = async ({
  data,
  onUploadProgress,
  type,
}) => {
  return axios({
    method: "POST",
    // eslint-disable-next-line no-undef
    baseURL: process.env.REACT_APP_UPLOAD,
    url: "/importer",
    data,
    params: { type },
    onUploadProgress,
    headers: {
      "Content-Type":
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    },
  }).then((res) => res.data);
};

export const getImportSessionsService = async () => {
  return axios.get(`/importer/sessions`).then((res) => res.data);
};

export const getImportSessionStateByIdService = async (id) => {
  return axios.get(`/importer/state/${id}`).then((res) => res.data);
};

export const getImportSessionByIdService = async (id, params) => {
  return axios
    .get(`/importer/session/${id}`, { params })
    .then((res) => res.data);
};

export const getImportErrorsService = async (id) => {
  return axios.get(`/importer/session/error/${id}`).then((res) => res.data);
};

export const validateImportService = async (id) => {
  return axios.post(`/importer/session/${id}/validate`).then((res) => res.data);
};

export const getImportSchemasService = async () => {
  return axios.get(`/importer/schemas`).then((res) => res.data);
};

export const remapImportDataService = async (id, data) => {
  return axios
    .post(`importer/session/${id}/remap`, data)
    .then((res) => res.data);
};

export const deleteImportSessionService = async (id) => {
  return axios.delete(`importer/session/${id}`).then((res) => res.data);
};

export const finishImportService = async (id) => {
  return axios.post(`importer/session/${id}/import`).then((res) => res.data);
};

export const fixImportErrorService = async (id, body) => {
  return axios
    .post(`importer/session/error/${id}`, body)
    .then((res) => res.data);
};

export const importDeleteAllErrorsService = async (id) => {
  return axios.delete(`/importer/session/error/${id}`).then((res) => res.data);
};
