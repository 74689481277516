import { HourglassBottom, HourglassTop } from "@mui/icons-material";
import {
  CarIcon,
  FlagIcon,
  HighestOrderIcon,
  HomeIcon,
  LowestOrderIcon,
  PedestrianIcon,
  SortAZIcon,
  SortZAIcon,
} from "../../components/Icons";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";

export const PRIORITIES = ["LOW", "MEDIUM", "HIGH"];

export const ROUTE_TYPES = [
  {
    value: "driving",
    icon: <CarIcon />,
  },
  {
    value: "walking",
    icon: <PedestrianIcon />,
  },
  {
    value: "bicycling",
    icon: <DirectionsBikeIcon style={{ fontSize: "16px" }} />,
  },
];

export const defaultValues = {
  name: "",
  assignedReps: [],
  priority: PRIORITIES[1],
  note: "",
  mode: "driving",
  stops: [],
  startLocation: null,
  endLocation: null,
};

export const STOPS_INDECES = {
  start: <HomeIcon />,
  end: <FlagIcon />,
};

export const PREFIXES = { more_than: ">", less_than: "<" };

export const FILTER_FIELDS_INIT = {
  representative_id: "",
  territory_id: "",
  group_id: "",
  tag_id: [],
  last_visit: "",
  last_order: "",
  missing_info: "",
  order_direct: "",
  tasks_due: "",
  has_assigned_routes: "",
};

export const SWITCHES_INIT = [
  {
    value: "representative_id",
    label: "Representative",
    type: "select",
    checked: false,
    selectLabel: "Select Rep",
  },
  {
    value: "last_visit",
    label: "Last visit",
    type: "date",
    checked: false,
    selectLabel: "Select",
  },
  {
    value: "territory_id",
    label: "Territory",
    type: "select",
    checked: false,
    selectLabel: "Select Territory",
  },
  {
    value: "last_order",
    label: "Last order",
    type: "date",
    checked: false,
    selectLabel: "Select",
  },
  {
    value: "group_id",
    label: "Group",
    type: "select",
    checked: false,
    selectLabel: "Select Group",
  },
  {
    value: "tag_id",
    label: "Tag",
    type: "multiselect",
    checked: false,
    selectLabel: "Select Tag",
  },
  {
    value: "issues",
    label: "Issues",
    type: "switch",
    checked: false,
    values: [
      { value: "with_missing_info", name: "Missing info" },
      { value: "with_duplicated_info", name: "Duplicates" },
    ],
  },
  {
    value: "order_direct",
    label: "Order direct",
    type: "switch",
    checked: false,
    values: [
      { value: "APPROVED", name: "Approved" },
      { value: "PENDING", name: "Pending" },
      { value: "REJECTED", name: "Rejected" },
      { value: null, name: "No order direct" },
    ],
  },
  {
    value: "tasks_due",
    label: "Tasks",
    type: "date",
    checked: false,
    selectLabel: "Select",
  },
  {
    value: "has_assigned_routes",
    label: "Route assignment",
    type: "switch",
    checked: false,
    values: ["ASSIGNED", "NOT_ASSIGNED"],
  },
];

export const iconSort = {
  highestOrderIcon: <HighestOrderIcon />,
  lovestOrderIcon: <LowestOrderIcon />,
  sortAZIcon: <SortAZIcon />,
  sortZAIcon: <SortZAIcon />,
  hourGlassTop: <HourglassTop style={{ fill: "#3F3F3F", fontSize: "16px" }} />,
  hourGlassBottom: (
    <HourglassBottom style={{ fill: "#3F3F3F", fontSize: "16px" }} />
  ),
};
