/* eslint-disable no-useless-escape */
import * as Yup from "yup";

export const validationSchema = (isUSCountry) =>
  Yup.object().shape({
    bankLocation: Yup.string().trim().required("This field is required"),
    accountHolderName: Yup.string().trim().required("This field is required"),
    accountNumber: Yup.string().trim().required("This field is required"),
    routingNumber: Yup.string()
      .trim()
      .test(
        "numberValidations",
        isUSCountry ? "Must be 9 digits" : "Must be 8 digits",
        (val) => {
          return isUSCountry ? val.length === 9 : val.length === 8;
        }
      )
      .required("This field is required"),
  });
