import React from "react";
import { object, bool, number, array } from "prop-types";
import RenderProductBox from "../../RenderProductBox";

const RenderProductBoxWithFreeCases = (props) => {
  const { product, freeCaseList } = props;
  const attachedFreeCase = freeCaseList.find(
    ({ id }) => id === product.id || id === product.product?.id
  );

  return (
    <>
      <RenderProductBox key={product.id} {...props} />
      {attachedFreeCase && (
        <RenderProductBox
          key={attachedFreeCase.id}
          {...props}
          product={attachedFreeCase}
        />
      )}
    </>
  );
};

export default RenderProductBoxWithFreeCases;

RenderProductBoxWithFreeCases.propTypes = {
  product: object,
  oldOrder: object,
  isEdit: bool,
  isDuplicate: bool,
  order: object,
  index: number,
  freeCaseList: array,
  newProducts: array,
};
