export const cl = {
  inputLabel: {
    transform: "translate(14px, 8px) scale(1)",

    "&.Mui-focused": {
      transform: "translate(14px, -7px) scale(0.75)",
    },
    "&.MuiInputLabel-shrink": {
      transform: "translate(14px, -7px) scale(0.75)",
    },
  },

  dialogWrapper: {
    "& .MuiPaper-root": {
      boxShadow: "none",
      width: "100%",
      maxWidth: "420px",
      minWidth: "420px",
    },
  },

  cancelActionBtn: {
    width: "98px",
    color: "#6A6A6A",
    borderColor: "#D4D4D4",
    fontWeight: 400,
    fontSize: "13px",
    height: "28px",
  },

  saveActionBtn: {
    width: "98px",
    color: "#FFFFFF",
    fontWeight: 400,
    fontSize: "13px",
    height: "28px",
    boxShadow: "none",
  },
};
