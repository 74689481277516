import React from "react";

export const DashboardIcon = ({ ...props }) => {
  return (
    <svg
      id="Component_10_Frame_97629"
      data-name="Component 10/Frame 97629"
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="20"
      viewBox="0 0 22 20"
      {...props}
    >
      <g id="Group_10" data-name="Group 10" transform="translate(-1.5 -2.118)">
        <g id="icon_dashboard">
          <g
            id="Rectangle"
            transform="translate(1.5 2.118)"
            fill="none"
            stroke="#a5a5a5"
            strokeWidth="1.5"
          >
            <rect width="9.882" height="7.412" rx="1.5" stroke="none" />
            <rect
              x="0.75"
              y="0.75"
              width="8.382"
              height="5.912"
              rx="0.75"
              fill="none"
            />
          </g>
          <g
            id="Rectangle_Copy_6"
            data-name="Rectangle Copy 6"
            transform="translate(1.5 10.765)"
            fill="none"
            stroke="#a5a5a5"
            strokeWidth="1.5"
          >
            <rect width="9.882" height="11.118" rx="1.5" stroke="none" />
            <rect
              x="0.75"
              y="0.75"
              width="8.382"
              height="9.618"
              rx="0.75"
              fill="none"
            />
          </g>
          <g
            id="Rectangle-2"
            data-name="Rectangle"
            transform="translate(22.5 21.883) rotate(180)"
            fill="none"
            stroke="#a5a5a5"
            strokeWidth="1.5"
          >
            <rect width="9.882" height="7.412" rx="1.5" stroke="none" />
            <rect
              x="0.75"
              y="0.75"
              width="8.382"
              height="5.912"
              rx="0.75"
              fill="none"
            />
          </g>
          <g
            id="Rectangle_Copy_6-2"
            data-name="Rectangle Copy 6"
            transform="translate(22.5 13.235) rotate(180)"
            fill="none"
            stroke="#a5a5a5"
            strokeWidth="1.5"
          >
            <rect width="9.882" height="11.118" rx="1.5" stroke="none" />
            <rect
              x="0.75"
              y="0.75"
              width="8.382"
              height="9.618"
              rx="0.75"
              fill="none"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
