import {
  END_LOADING_TAGS,
  CREATE_TAG,
  DELETE_TAG,
  GET_TAGS,
  START_LOADING_TAGS,
  UPDATE_TAG,
  GET_TAGS_COUNT,
  GET_CUSTOMER_TAGS_COUNT,
  GET_ORDER_TAGS_COUNT,
} from "../actions/tags";

const initialState = {
  product: [],
  customer: [],
  order: [],
  loading: false,
  count: 0,
  customerCount: 0,
  orderCount: 0,
};

const tagsReducer = (state = initialState, { type, payload, tagType }) => {
  switch (type) {
    case GET_TAGS:
      if (payload.isScrolling) {
        return {
          ...state,
          [tagType]: [...state[tagType], ...payload[tagType]],
        };
      }
      return { ...state, [tagType]: payload[tagType] };

    case GET_TAGS_COUNT:
      return { ...state, count: payload };

    case GET_CUSTOMER_TAGS_COUNT:
      return { ...state, customerCount: payload };

    case GET_ORDER_TAGS_COUNT:
      return { ...state, orderCount: payload };

    case CREATE_TAG:
      return {
        ...state,
        [tagType]: [...state[tagType], payload],
        count: tagType === "product" ? state.count + 1 : state.count,
        customerCount:
          tagType === "customer"
            ? state.customerCount + 1
            : state.customerCount,
        orderCount:
          tagType === "order" ? state.orderCount + 1 : state.orderCount,
      };

    case DELETE_TAG: {
      const tagsList = [...state[tagType]];
      const index = tagsList.findIndex((tag) => tag.id === payload.id);
      if (index >= 0) {
        tagsList.splice(index, 1);
      }
      return {
        ...state,
        [tagType]: tagsList,
        loading: false,
        count: tagType === "product" ? state.count - 1 : state.count,
        customerCount:
          tagType === "customer"
            ? state.customerCount - 1
            : state.customerCount,
      };
    }

    case UPDATE_TAG: {
      const tagsList = [...state[tagType]];
      const updateIndex = tagsList.findIndex(
        (customer) => customer.id === payload.id
      );
      if (updateIndex >= 0) {
        tagsList.splice(updateIndex, 1, payload);
      }
      return { ...state, [tagType]: tagsList, loading: false };
    }

    case END_LOADING_TAGS:
      return { ...state, loading: false };

    case START_LOADING_TAGS:
      return { ...state, loading: true };

    default:
      return state;
  }
};

export default tagsReducer;
