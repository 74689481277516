import {
  START_LOADING_CATEGORIES,
  END_LOADING_CATEGORIES,
  GET_CATEGORIES_LIST,
  GET_CATEGORIES_COUNT,
} from "../actions/categories";

const initialState = {
  isLoadingCategories: false,
  categoriesList: [],
  categoriesCount: [],
};

const confirmDialogsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_CATEGORIES_LIST:
      return { ...state, categoriesList: payload };

    case GET_CATEGORIES_COUNT:
      return { ...state, categoriesCount: payload };

    case START_LOADING_CATEGORIES:
      return { ...state, isLoadingCategories: true };

    case END_LOADING_CATEGORIES:
      return { ...state, isLoadingCategories: false };

    default:
      return state;
  }
};

export default confirmDialogsReducer;
