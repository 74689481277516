import { styled } from "@mui/material";

const TableItem = styled("div")(({ theme: { palette } }) => ({
  display: "flex",
  alignItems: "center",
  padding: "10px 25px 12px 20px",
  borderBottom: `1px solid ${palette.border.main}`,
  maxWidth: "458px",
  height: "100%",
}));

const CircleIndexWrap = styled("div")(() => ({
  marginLeft: "auto",
}));

export { TableItem, CircleIndexWrap };
