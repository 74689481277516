import { useMemo, useState } from "react";
import { object, func } from "prop-types";
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import { PriceFormat, StyledTooltip } from "components";
import { separateNumWithComma } from "helpers/helpers";
import { Controller } from "react-hook-form";
import { QuestionIcon } from "components/Icons";
import useStyles from "../../../styles";

export const PricesBlock = ({ formField, setValue, control, errors }) => {
  const classes = useStyles();

  const [isWholesalePriceTooltipOpen, setIsWholesalePriceTooltipOpen] =
    useState(false);

  const [isRetailPriceTooltipOpen, setIsRetailPriceTooltipOpen] =
    useState(false);

  const calculatedRetailPrices = useMemo(() => {
    if (
      formField?.retailPrice !== undefined &&
      formField?.itemsPerCase !== undefined
    ) {
      return formField.retailPrice / formField.itemsPerCase;
    }
    return null;
  }, [formField?.itemsPerCase, formField?.retailPrice]);

  const calculatedWholesalePrices = useMemo(() => {
    if (
      formField?.wholesalePrice !== undefined &&
      formField?.itemsPerCase !== undefined
    ) {
      return formField?.wholesalePrice / formField?.itemsPerCase;
    }
    return null;
  }, [formField?.itemsPerCase, formField?.wholesalePrice]);

  const areAllFieldsFilled = useMemo(() => {
    return (
      formField?.itemsPerCase !== undefined &&
      formField?.retailPrice !== undefined &&
      formField?.wholesalePrice !== undefined
    );
  }, [
    formField?.itemsPerCase,
    formField?.retailPrice,
    formField?.wholesalePrice,
  ]);

  const setTooltipTitleRetailPrice = useMemo(() => {
    const retailPrices = calculatedRetailPrices;
    if (errors?.retailPrice?.message) {
      return (
        <Typography fontSize="12px">{errors?.retailPrice?.message}</Typography>
      );
    }

    if (retailPrices !== null) {
      return (
        <Typography fontSize="12px">
          ${separateNumWithComma(retailPrices)} per item
        </Typography>
      );
    }
  }, [calculatedRetailPrices, errors?.retailPrice?.message]);

  return (
    <Grid xs={12} item>
      <Box display="flex" gap={3}>
        <StyledTooltip
          placement="top"
          arrow
          sx={{
            display:
              formField?.wholesalePrice !== undefined &&
              formField?.wholesalePrice !== "" &&
              calculatedWholesalePrices &&
              calculatedWholesalePrices !== null &&
              areAllFieldsFilled &&
              formField.itemsPerCase !== "" &&
              !isWholesalePriceTooltipOpen
                ? "block"
                : "none",
          }}
          title={
            calculatedWholesalePrices !== null && (
              <Typography fontSize="12px">
                ${separateNumWithComma(calculatedWholesalePrices)} per item
              </Typography>
            )
          }
        >
          <Box width="32%">
            <Controller
              render={({ field, fieldState: { error } }) => (
                <PriceFormat
                  adminIsAllowed
                  fullWidth
                  size="small"
                  label="Wholesale price *"
                  InputProps={{
                    className: classes.textInputFilled,
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography
                          sx={{
                            fontSize: 14,
                            color: "#D4D4D4",
                          }}
                        >
                          $
                        </Typography>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <StyledTooltip
                          title={"Wholesale price for customers. *"}
                          placement="top"
                          onOpen={() => setIsWholesalePriceTooltipOpen(true)}
                          onClose={() => setIsWholesalePriceTooltipOpen(false)}
                          arrow
                        >
                          <IconButton>
                            <QuestionIcon />
                          </IconButton>
                        </StyledTooltip>
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    className: classes.inputLabelFilled,
                  }}
                  error={!!error}
                  {...field}
                  onValueChange={({ value }) => {
                    if (field.value !== parseFloat(value))
                      setValue("wholesalePrice", value, {
                        shouldDirty: true,
                      });
                  }}
                  onChange={() => {}}
                />
              )}
              name="wholesalePrice"
              control={control}
            />
          </Box>
        </StyledTooltip>
        <StyledTooltip
          placement="top"
          arrow
          sx={{
            display:
              formField?.retailPrice !== undefined &&
              formField?.retailPrice !== "" &&
              calculatedRetailPrices &&
              calculatedRetailPrices !== null &&
              areAllFieldsFilled &&
              formField.itemsPerCase !== "" &&
              !isRetailPriceTooltipOpen
                ? "block"
                : "none",
          }}
          title={setTooltipTitleRetailPrice}
        >
          <Box
            // ref={retailPriceRef}
            width="32%"
          >
            <Controller
              render={({ field, fieldState: { error } }) => (
                <PriceFormat
                  adminIsAllowed
                  fullWidth
                  size="small"
                  label="Retail price *"
                  InputProps={{
                    className: classes.textInputFilled,
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography
                          sx={{
                            fontSize: 14,
                            color: "#D4D4D4",
                          }}
                        >
                          $
                        </Typography>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <StyledTooltip
                          title={
                            "Customers will  see this. Suggested retail  price for customers."
                          }
                          placement="top"
                          arrow
                          onOpen={() => setIsRetailPriceTooltipOpen(true)}
                          onClose={() => setIsRetailPriceTooltipOpen(false)}
                        >
                          <IconButton>
                            <QuestionIcon />
                          </IconButton>
                        </StyledTooltip>
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    className: classes.inputLabelFilled,
                  }}
                  error={!!error}
                  {...field}
                  onValueChange={({ value }) => {
                    if (field.value !== parseFloat(value))
                      setValue("retailPrice", value, {
                        shouldDirty: true,
                      });
                  }}
                  onChange={() => {}}
                />
              )}
              name="retailPrice"
              control={control}
            />
          </Box>
        </StyledTooltip>
      </Box>
    </Grid>
  );
};

PricesBlock.propTypes = {
  formField: object,
  setValue: func,
  control: object,
  errors: object,
};
