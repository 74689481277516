import { Chip, Typography } from "@mui/material";
import React from "react";
import useStyles from "./styles";
import CloseIcon from "@mui/icons-material/Close";
import { string, func } from "prop-types";

const FilterChip = ({ title, text, onDelete }) => {
  const classes = useStyles();
  return (
    <Chip
      className={classes.filterChip}
      size="small"
      label={
        <Typography
          sx={{ fontSize: "13px", fontWeight: 500 }}
          color="groundLighter.main"
        >
          {title}: <span style={{ color: "#5F6368" }}>{text}</span>
        </Typography>
      }
      deleteIcon={<CloseIcon size="15" style={{ fill: "#707070" }} />}
      onMouseDown={(e) => e.stopPropagation()}
      onDelete={onDelete}
    />
  );
};

FilterChip.propTypes = {
  title: string,
  text: string,
  onDelete: func,
};

export default FilterChip;
