import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  createNoteService,
  deleteNoteService,
  updateNoteService,
} from "services/notes";
import { error, success } from "utils/notifications";
import { CustomerProfileContext } from "../../CustomerProfilePage";
import { useDispatch, useSelector } from "react-redux";
import {
  openConfirmDialogAction,
  setConfirmIsOpenAction,
} from "redux/actions/confirmDialogs";
import { useRepsPermissions } from "helpers/hooks";

export const useCustomerNotes = () => {
  const {
    currentTable,
    noteState,
    handleCloseNote,
    customerId,
    handleRefetchNotes,
    handleFetchActivities,
  } = useContext(CustomerProfileContext);

  const currentUser = useSelector(({ auth }) => auth?.currentUser);

  const repPermissions = useRepsPermissions();

  const { filterTab } = currentTable || {};

  const dispatch = useDispatch();

  const editNote = useMemo(() => noteState.note, [noteState.note]);

  const isAdminEdit = useMemo(
    () =>
      repPermissions ? false : !!editNote && !editNote.representativeDuplicate,
    [editNote, repPermissions]
  );

  const repData = noteState?.note?.representativeDuplicate;

  const isNoteView = useMemo(() => {
    if (repPermissions) {
      if (!editNote) return false;
      if (!!editNote && repData?.representativeId === currentUser?.id)
        return false;

      if (
        !!editNote &&
        !!repData?.representativeId &&
        (repData?.representativeId ===
          currentUser?.permissions?.representativeId ||
          repData?.representativeId ===
            currentUser?.backOfficeRepresentative?.id)
      )
        return false;

      return true;
    }

    return !!editNote && !!editNote.representativeDuplicate;
  }, [
    currentUser?.id,
    currentUser?.permissions?.representativeId,
    currentUser?.backOfficeRepresentative?.id,
    editNote,
    repData?.representativeId,
    repPermissions,
  ]);

  const [note, setNote] = useState({ text: "", error: false, loading: false });

  const handleChangeNote = ({ target }) => {
    setNote((prev) => ({ ...prev, error: false, text: target.value }));
  };

  const resetNote = useCallback(() => {
    if (noteState.open) {
      setNote((prev) => ({ ...prev, text: noteState.note?.text ?? "" }));
    }
  }, [noteState.note?.text, noteState.open]);

  useEffect(resetNote, [resetNote, noteState?.open]);

  const setError = () => {
    setNote((prev) => ({ ...prev, error: true }));
  };

  const setLoading = (loading) => {
    setNote((prev) => ({ ...prev, loading: !!loading }));
  };

  const handleSaveNote = async () => {
    if (repPermissions) {
      if (
        !repPermissions?.customers?.create_edit
        // || repData?.representativeId !== currentUser?.id
      ) {
        handleCloseNote();
        return;
      }
    }

    if (isNoteView) {
      handleCloseNote();
      return;
    }

    setLoading(true);
    const { text } = note;
    if (!text) {
      setError();
      return;
    }
    try {
      if (
        repPermissions
          ? repPermissions?.customers?.create_edit &&
            ((!!repData?.representativeId &&
              repData?.representativeId === currentUser?.id) ||
              (!!repData?.representativeId &&
                repData?.representativeId ===
                  currentUser?.backOfficeRepresentative?.id))
          : isAdminEdit && editNote
      ) {
        await updateNoteService({ noteId: editNote.id, text });
      } else {
        await createNoteService({ customerId, text });
      }
      success(`Note ${isAdminEdit ? "updated" : "created"} successfully!`);
      filterTab && handleFetchActivities({ page: 1 });
      handleCloseNote();
      handleRefetchNotes();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      error(err?.response?.data?.message);
    }
  };

  const handleConfirmDelete = async () => {
    try {
      setLoading(true);
      await deleteNoteService({ noteId: editNote.id });
      success("Note deleted successfully!");
      filterTab && handleFetchActivities({ page: 1 });
      dispatch(setConfirmIsOpenAction(false));
      handleCloseNote();
      handleRefetchNotes();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      error(err?.response?.data?.message);
    }
  };

  const handleDeleteNote = async () => {
    dispatch(
      openConfirmDialogAction({
        title: "Delete note?",
        text: "Are you sure you want to delete this note?",
        propBtns: {
          left: { label: "Cancel", color: "cancel", variant: "outlined" },
          right: {
            label: "Confirm",
            color: "error",
            variant: "contained",
            onClick: handleConfirmDelete,
          },
        },
      })
    );
  };

  return {
    note,
    handleChangeNote,
    handleSaveNote,
    handleCloseNote,
    isAdminEdit,
    isNoteView,
    editNote,
    open: noteState.open,
    repData,
    noteData: noteState.note,
    handleDeleteNote,
    repPermissions,
    currentUser,
  };
};
