import { useMemo } from "react";
import { string, number, array, func, bool } from "prop-types";
import moment from "moment";

import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { PaymentStatusBilling } from "../../../../../../../../../../../components/PaymentStatusBilling/PaymentStatusBilling";
import { BillingDownloadIcon } from "../../../../../../../../../../../components/Icons";

import { separateNumWithComma } from "../../../../../../../../../../../helpers/helpers";
import { cl } from "./PaymentPlansItem.styles";

export const PaymentPlansItem = ({
  name,
  invoice,
  billingPeriod,
  usersCount,
  amount,
  status,
  dueDate,
  onDownloadInvoice,
  invoiceLoading,
}) => {
  const [startBillDate, endBillDate] = billingPeriod;

  const preparedBillingPeriod = useMemo(() => {
    return `${moment(startBillDate).format("MMM D YYYY")} — ${moment(
      endBillDate
    ).format("MMM D YYYY")}`;
  }, [endBillDate, startBillDate]);

  return (
    <Grid sx={cl.wrapper} container>
      <Grid xs={0.3} item />
      {/* Plan */}
      <Grid sx={cl.grid} xs={2.4} item>
        <Typography sx={cl.typographyPlan}>{name}</Typography>
      </Grid>
      {/* Invoice */}
      <Grid sx={cl.grid} xs={1.9} item>
        <Box sx={cl.invoice.wrapper}>
          <Typography sx={cl.typography}>{invoice}</Typography>
          <IconButton onClick={onDownloadInvoice}>
            <BillingDownloadIcon />
          </IconButton>
          {invoiceLoading && <CircularProgress size={20} />}
        </Box>
      </Grid>
      {/* Billing period */}
      <Grid sx={cl.grid} xs={2.3} item>
        <Typography sx={cl.typography}>{preparedBillingPeriod}</Typography>
      </Grid>
      {/* Users */}
      <Grid sx={cl.gridCenter} xs={1} item>
        <Typography sx={cl.typography}>{usersCount}</Typography>
      </Grid>
      {/* Amount */}
      <Grid sx={cl.gridCenter} xs={1} item>
        <Typography sx={cl.typography}>
          ${separateNumWithComma(amount)}
        </Typography>
      </Grid>
      {/* Status */}
      <Grid sx={cl.gridCenter} xs={1.5} item>
        <PaymentStatusBilling str={status} />
      </Grid>
      {/* Due date */}
      <Grid sx={cl.grid} xs item>
        <Typography sx={cl.typography} pl={3}>
          {moment(dueDate).format("MMM D, YYYY")}
        </Typography>
      </Grid>
    </Grid>
  );
};

PaymentPlansItem.propTypes = {
  name: string,
  invoice: string,
  billingPeriod: array,
  usersCount: number,
  amount: number,
  status: string,
  dueDate: string,
  onDownloadInvoice: func,
  invoiceLoading: bool,
};
PaymentPlansItem.defaultProps = {
  name: "",
  invoice: "",
  billingPeriod: [],
  usersCount: 0,
  amount: 0,
  status: "",
  dueDate: "",
  onDownloadInvoice: () => {},
  invoiceLoading: false,
};
