import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { forwardRef } from "react";
import { bool, string } from "prop-types";
import useStyles from "./styles";
import { StyledTooltip } from "components";

export const ValueToggleButtonGroup = forwardRef(
  ({ qtyPicker, type, ...props }, ref) => {
    const classes = useStyles();

    return (
      <ToggleButtonGroup
        size="small"
        exclusive
        className={classes.toggleGroup}
        ref={ref}
        {...props}
      >
        <StyledTooltip
          title={"Percent off per case"}
          placement="top"
          arrow
          isDark
          PopperProps={{
            modifiers: [{ name: "offset", options: { offset: [0, -2] } }],
          }}
        >
          <ToggleButton
            sx={{
              border: "none",
              backgroundColor: type === "PERCENTAGE" ? "#F2F2F2" : "#FAFAFA",
              "&.Mui-selected": { backgroundColor: "#F2F2F2" },
              width: "30px",
              textTransform: "none",
            }}
            value="PERCENTAGE"
          >
            %
          </ToggleButton>
        </StyledTooltip>
        <StyledTooltip
          title={"Amount off per case"}
          placement="top"
          arrow
          isDark
          PopperProps={{
            modifiers: [{ name: "offset", options: { offset: [0, -2] } }],
          }}
        >
          <ToggleButton
            sx={{
              border: "none",
              backgroundColor: type === "ABSOLUTE" ? "#F2F2F2" : "#FAFAFA",

              "&.Mui-selected": { backgroundColor: "#F2F2F2" },
              width: "30px",
              textTransform: "none",
            }}
            value="ABSOLUTE"
          >
            $
          </ToggleButton>
        </StyledTooltip>
        {qtyPicker && (
          <StyledTooltip
            title={"Number of free cases"}
            placement="top"
            arrow
            isDark
            PopperProps={{
              modifiers: [{ name: "offset", options: { offset: [0, -2] } }],
            }}
          >
            <ToggleButton
              sx={{
                border: "none",
                backgroundColor: type === "QUANTITY" ? "#F2F2F2" : "#FAFAFA",

                "&.Mui-selected": { backgroundColor: "#F2F2F2" },
                width: "30px",
                textTransform: "none",
              }}
              value="QUANTITY"
            >
              Qty
            </ToggleButton>
          </StyledTooltip>
        )}
      </ToggleButtonGroup>
    );
  }
);

ValueToggleButtonGroup.propTypes = {
  qtyPicker: bool,
  type: string,
};

ValueToggleButtonGroup.defaultProps = {
  qtyPicker: true,
};

ValueToggleButtonGroup.displayName = "ValueToggleButtonGroup";

export default ValueToggleButtonGroup;
