import { func, object, shape, bool } from "prop-types";
import { Box, Typography } from "@mui/material";
import { LargeCheckbox, StyledButton } from "../../../../components";
import { Controller } from "react-hook-form";

export const ReturnDialogFooter = ({
  onClose,
  control,
  trigger,
  sendNotificationsData,
  footerData,
  sendNotificationRef,
  btnSubmitRef,
  setPopperType,
  onSubmit,
}) => {
  const { hasCustomerEmail } = sendNotificationsData;
  const { isDisableBtn, nameConfirmBtn } = footerData;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        py: 1,
        px: 3.25,
      }}
    >
      <Controller
        render={({ field }) => (
          <Box
            ref={sendNotificationRef}
            onMouseEnter={() => {
              if (!hasCustomerEmail) setPopperType("sendNotification");
            }}
            onMouseLeave={() => setPopperType("")}
          >
            <LargeCheckbox
              disabled={!hasCustomerEmail}
              formSx={{
                mr: 0,
                flex: "1 1 auto",
                ml: "-9px",
                color: hasCustomerEmail ? "#000" : "#B9B9B9",
              }}
              checked={!!field.value}
              label={
                <Typography ml="-4px" fontSize={12} fontWeight={400}>
                  Send notification to the customer
                </Typography>
              }
              size={16}
              {...field}
            />
          </Box>
        )}
        name="sendNotification"
        control={control}
      />

      <Box
        sx={{
          display: "flex",
          gap: 1,
        }}
      >
        <StyledButton
          sx={{ color: "#6A6A6A", height: "33px" }}
          fontSize="13px"
          label="Cancel"
          color="groundLighter"
          onClick={onClose}
        />
        <Box
          ref={btnSubmitRef}
          onMouseEnter={() => {
            trigger();
            setPopperType("btnSubmit");
          }}
          onMouseLeave={() => setPopperType("")}
        >
          <StyledButton
            disabled={isDisableBtn}
            sx={{ paddingX: 3, height: "33px" }}
            fontSize="15px"
            label={nameConfirmBtn}
            variant="contained"
            onClick={onSubmit}
          />
        </Box>
      </Box>
    </Box>
  );
};

ReturnDialogFooter.propTypes = {
  onClose: func,
  control: object,
  trigger: func,
  sendNotificationsData: shape({
    hasCustomerEmail: bool,
  }),
  footerData: shape({
    isDisableBtn: bool,
  }),
  sendNotificationRef: object,
  btnSubmitRef: object,
  setPopperType: func,
  onSubmit: func,
};
ReturnDialogFooter.defaultProps = {
  onClose: () => {},
  control: {},
  trigger: () => {},
  sendNotificationsData: {
    hasCustomerEmail: false,
  },
  footerData: {
    isDisableBtn: true,
  },
  sendNotificationRef: {},
  btnSubmitRef: {},
  setPopperType: () => {},
  onSubmit: () => {},
};
ReturnDialogFooter.displayName = "ReturnDialogFooter";
