import * as React from "react";

export const BillingPersonIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={13.319}
    height={13.319}
    viewBox="0 0 13.319 13.319"
    {...props}
  >
    <path
      data-name="Icon material-person-outline"
      d="M6.66 1.582A1.748 1.748 0 1 1 4.912 3.33 1.748 1.748 0 0 1 6.66 1.582m0 7.492c2.472 0 5.078 1.215 5.078 1.748v.916H1.582v-.916c0-.533 2.606-1.748 5.078-1.748M6.66 0a3.33 3.33 0 1 0 3.33 3.33A3.329 3.329 0 0 0 6.66 0Zm0 7.492C4.437 7.492 0 8.607 0 10.822v2.5h13.319v-2.5c0-2.214-4.437-3.33-6.659-3.33Z"
    />
  </svg>
);
