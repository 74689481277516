import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  filterWrapper: {
    backgroundColor: "#ffffff",
    borderRadius: "4px 4px 0 0",
    display: "flex",
    border: "1px solid #d5d9d9",
    marginTop: "12px",
    padding: "12px 14px 2px 12px",
    gap: "11px",
  },

  tab: {
    textTransform: "none",
    fontSize: "20px",
    color: "#707070",
    "&.Mui-selected": {
      backgroundColor: "rgb(71, 160, 110, 0.2)!important",
      borderRadius: "4px",
    },
    width: "fit-content",
    minWidth: "10px",
    paddingLeft: "15px",
    paddingRight: "15px",
    height: "38px",
    minHeight: "38px",
    padding: 0,
  },

  tabs: {
    width: "100%",
    maxWidth: "270px",
    minWidth: "270px",
  },

  filterChip: {
    borderRadius: "4px",
    border: "0.7px solid #d5d9d9",
    backgroundColor: "#FAFAFB",
    height: "28px",
  },
}));

export default useStyles;
