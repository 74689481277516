import { useState } from "react";

export const usePhotoGallery = () => {
  const [isOpenGallery, setIsOpenGallery] = useState(false);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [openIndex, setSetOpenIndex] = useState(0);

  const handleCloseGallery = () => setIsOpenGallery(false);
  // const handleOpenGallery = () => setIsOpenGallery(true);

  const handleOpenGalleryWithIndex = (index) => {
    setIsOpenGallery(true);
    setSetOpenIndex(index);
  };

  const handleImageURL = (url) => {
    const newUrl = new URL(url);
    const pathName = newUrl.pathname;

    const decodedPathName = decodeURIComponent(pathName);

    const splittedPath = decodedPathName.split("/");
    const fileName = splittedPath[splittedPath.length - 1];
    const [name, type] = fileName.split(".");

    return { name, type };
  };

  const handleDownloadImage = async (image) => {
    const { fileName } = image;
    const { name, type } = handleImageURL(fileName);

    const file = await fetch(fileName);
    const fileBlob = await file.blob();
    const imageURL = URL.createObjectURL(fileBlob);
    const link = document.createElement("a");
    link.href = imageURL;
    link.download = `${name}.${type}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return {
    handleCloseGallery,
    handleOpenGalleryWithIndex,
    isOpenGallery,
    handleDownloadImage,
    activeSlideIndex,
    setActiveSlideIndex,
    openIndex,
  };
};
