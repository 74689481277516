import { PaperWrapper } from "components";
import StyledTabs from "components/StyledTabs/StyledTabs";
import { useContext, useEffect, useMemo, useState } from "react";
import { DataTable, Filters } from "./components";
import useStyles from "./styles";
import { number, object } from "prop-types";
import { Box } from "@mui/material";
import { CustomerProfileContext } from "Pages/CustomersPage/pages/CustomerProfilePage/CustomerProfilePage";

const CustomerTables = ({ clientHeight, repPermissions }) => {
  const classes = useStyles();
  const TABLE_TABS = useMemo(
    () => [
      {
        value: 0,
        label: "Orders",
        disabled: repPermissions && !repPermissions?.orders?.view,
        showTooltip: false,
        tooltipText: "",
      },
      {
        value: 1,
        label: "Notes",
        disabled: false,
        showTooltip: false,
        tooltipText: "",
      },
      {
        value: 2,
        label: "Tasks",
        disabled: repPermissions && !repPermissions?.tasks?.view,
        showTooltip: false,
        tooltipText: "",
      },
      {
        value: 3,
        label: "Timeline",
        disabled: false,
        showTooltip: false,
        tooltipText: "",
      },
    ],
    [repPermissions]
  );

  const [currentTab, setCurrentTab] = useState(() => {
    const firstActiveTab =
      TABLE_TABS.find(({ disabled }) => !disabled) || TABLE_TABS[0];

    return {
      value: firstActiveTab.value,
      label: firstActiveTab.label,
    };
  });

  const { handleChangeTab } = useContext(CustomerProfileContext);

  useEffect(() => {
    handleChangeTab(currentTab);
  }, [currentTab, handleChangeTab]);

  return (
    <PaperWrapper wrapperStyle={{ height: "100%", maxWidth: "100%" }}>
      <Box className={classes.headerWrap} maxWidth="100%">
        <StyledTabs
          onChange={(e, newVal) => {
            const tab = TABLE_TABS.find(({ value }) => value === newVal);
            setCurrentTab((...prev) => ({
              ...prev,
              value: tab.value,
              label: tab.label,
            }));
          }}
          value={currentTab.value}
          tabs={TABLE_TABS}
        />
        <Filters />
      </Box>
      <DataTable clientHeight={clientHeight} />
    </PaperWrapper>
  );
};

export default CustomerTables;

CustomerTables.propTypes = {
  clientHeight: number || null,
  repPermissions: object,
};
