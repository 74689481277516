import { Box } from "@mui/material";
import { ReorderBurgerIcon } from "components/Icons";

export const ReorderBurgerHandler = () => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        display: "flex",
        alignItems: "center",
        cursor: "grab",
        m: "0px 15px",
      }}
    >
      <ReorderBurgerIcon />
    </Box>
  );
};
