import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { changePassword, resetPasswordEmail } from "services/account";
import { error, errorConfig, success } from "utils/notifications";
import { DEFAULT_FORM_DATA } from "./ChangePasswordDialog.constants";
import { changePasswordValidationSchema } from "./ChangePasswordDialog.validation";
import { PASSWORD_CRITERIA } from "components/PasswordCriteria/PasswordCriteria.constants";

export const useChangePassword = (onClose) => {
  const currentUser = useSelector(({ auth }) => auth?.currentUser);

  const { baseUser } = currentUser || {};

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    trigger,
    setError,
    clearErrors,
  } = useForm({
    mode: "onSubmit",
    defaultValues: DEFAULT_FORM_DATA,
    resolver: yupResolver(changePasswordValidationSchema()),
  });

  const formField = useWatch({ control });
  const { currentPassword, newPassword, confirmPassword } = formField || {};

  const handleForgotPassword = useCallback(async () => {
    try {
      await resetPasswordEmail(baseUser);
      onClose();
      success("Email sent!");
    } catch (err) {
      if (err?.response?.status === 404) {
        toast.error(
          `There isn't a SimplyDepo account associated with ${baseUser?.email}.`,
          errorConfig
        );
        return;
      }
      toast.error("Couldn't find your email", errorConfig);
    }
  }, [baseUser, onClose]);

  const onSubmit = async (data) => {
    const { currentPassword, newPassword } = data;
    try {
      await changePassword({ currentPassword, newPassword });
      onClose();
      reset(DEFAULT_FORM_DATA);
      success("Password changed");
    } catch (err) {
      const isPasswordError = err?.response?.data?.message
        ? err.response.data.message.includes("Wrong current password")
        : false;

      if (isPasswordError) {
        setError("currentPassword", {
          type: "manual",
          message: "Wrong current password",
        });
      } else {
        error(err?.response?.data?.message);
      }
    }
  };

  const errArr = Object.keys(errors);

  const disabledBtn = useMemo(() => {
    if (
      !currentPassword ||
      !newPassword ||
      !confirmPassword ||
      newPassword !== confirmPassword
    )
      return true;
    if (errArr?.length > 0) return true;

    const completedPass = PASSWORD_CRITERIA.some((criteria) => {
      if (!criteria.condition({ password: newPassword, confirmPassword }))
        return true;
    });

    if (completedPass) return true;
  }, [confirmPassword, currentPassword, errArr?.length, newPassword]);

  const showWarning = useMemo(() => {
    return (
      errors?.currentPassword?.type === "manual" ||
      errors?.newPassword?.type === "same"
    );
  }, [errors?.currentPassword?.type, errors?.newPassword?.type]);

  return {
    control,
    handleSubmit,
    handleForgotPassword,
    errors,
    clearErrors,
    onSubmit,
    trigger,
    disabledBtn,
    showWarning,
  };
};
