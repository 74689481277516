import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bool, func, object, string } from "prop-types";
import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { createSelector } from "reselect";

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
// import { ExpandMore } from "@mui/icons-material";
import { CrossBigIcon } from "../../../../components/Icons";
import { LargeCheckbox } from "../../../../components/Checkboxes";
import StyledButton from "../../../../components/StyledButton";
import RefoundProductsItem from "./RefoundProductsItem";
// import { StyledSelect } from "../../../../components/Selects";
import PaymentStatus from "../../../../components/PaymentStatus/PaymentStatus";
import { StyledTextField } from "../../../../components/TextFields/TextFields";
import PaymentItem from "../CancelDialog/components/PaymentItem";
import StyledPopper from "../../../../components/StyledPopper/StyledPopper.jsx";
import useStyles, { cl } from "./RefundDialog.styles";

import {
  normalizeSnakeCaseString,
  separateNumWithComma,
  setCardName,
} from "../../../../helpers/helpers";
import { validationSchema } from "./RefoundProductsItem.validations";
import { RETURNS } from "./RefundDialog.constants.js";
import { SCROLL_LIMIT } from "../../../../utils/constants";
import { error, success } from "../../../../utils/notifications.js";

import {
  confirmRefundService,
  updateRefundService,
} from "../../../../services/orders";
import { currentUserSelector } from "../../../../redux/selectors/auth";
import {
  endLoadingOrders,
  getOrderByIdAction,
  getOrdersAction,
  startLoadingOrders,
} from "../../../../redux/actions/orders";
// import {
//   repsGetSalesUsers,
//   salesCountSelector,
// } from "../../../../redux/selectors/reps";
import {
  getRepsAction,
  resetSalesRepsAction,
} from "../../../../redux/actions/reps";

const selector = createSelector(currentUserSelector, (currentUser) => ({
  currentUser,
}));

const RefundDialog = ({
  open,
  onClose,
  nameRefundDialog,
  order,
  returnItem,
  refundEditItem,
  handleGetOrder,
  isOrderPage,
  successCallback,
}) => {
  const classes = useStyles();
  const { currentUser } = useSelector(selector);
  const dispatch = useDispatch();
  const notificationRef = useRef();
  const [openTooltipNotification, setOpenTooltipNotification] = useState(false);

  const { sendNotifications } = currentUser ?? {};

  // const optionsShippingCarrierSelect = {
  //   notched: false,
  //   fullWidth: true,
  //   formSx: {
  //     ...cl.label,
  //     justifyContent: "space-between",
  //     "& .MuiOutlinedInput-input": {
  //       padding: "0 8px !important",
  //     },
  //     "& .MuiSvgIcon-root": {
  //       right: "4px",
  //     },
  //   },
  //   height: "28px",
  //   size: "small",
  //   color: "#000000",
  //   displayEmpty: true,
  //   label: "",
  //   defaultValue: "",
  //   noErrorMessage: true,
  //   border: "0.5px solid #D5D9D9",
  // };

  const [subTotalItemsSum, setSubTotalItemsSum] = useState(0);
  const [avialibleForRefund, setAvialibleForRefund] = useState(0);
  const [productsList, setProductsList] = useState([]);
  const [isDisableBtn, setIsDisableBtn] = useState(true);

  const anchorElPopper = useRef(null);
  const [openTooltip, setOpenTooltip] = useState(false);
  const btnRef = useRef(null);
  const [openPopper, setOpenPopper] = useState(false);

  const hasCustomerEmail = useMemo(() => {
    return (
      !!order?.customer?.email ||
      order?.customer?.contacts?.some((contact) => contact?.email)
    );
  }, [order?.customer?.contacts, order?.customer?.email]);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
    trigger,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      paymentRefund: [],
      // returnShippingOptions: returnItem?.deliveryMethod || "SHIPPING_CARRIER",
      reason: returnItem?.reason ?? RETURNS[0].name,
      reasonRefound: "",
      restock: returnItem?.restock ?? false,
      sendNotification: sendNotifications,
      productReturns: returnItem
        ? returnItem?.productReturns?.map((prod) => ({
            ...prod,
            returnQuantity: prod.quantity,
          }))
        : order?.products?.map((prod) => ({
            ...prod,
            returnQuantity: 0,
          })),
      amount: "",
      paymentType: "CREDIT_CARD",
      date: new Date(Date.now()),
      check: "",
      reference: "",
      cardId: order?.customer?.creditCards
        ? order?.customer?.creditCards?.find(
            (card) => card?.defaultMethod === "true"
          )?.id || order?.customer?.creditCards[0]?.id
        : "",
      creditCards: order?.orderPayments?.filter((c) => c?.creditCard),
      // trackingId: returnItem?.trackingId || "",
      // shippingCarrier: returnItem?.shippingCarrier?.id || "",
      delivererId: order?.customer?.assignedRepresentatives?.length
        ? order?.customer?.assignedRepresentatives[0]?.representative?.id
        : "",
      delivery: order?.deliveryFee ?? 0,
    },
    resolver: yupResolver(validationSchema()),
  });

  const formField = useWatch({ control });

  const { fields } = useFieldArray({
    control,
    name: "paymentRefund",
  });

  const deliveries = useMemo(
    () => (order?.orderDeliveries?.length ? [...order?.orderDeliveries] : []),
    [order?.orderDeliveries]
  );

  const returns = useMemo(
    () => (order?.orderReturns?.length ? [...order?.orderReturns] : []),
    [order?.orderReturns]
  );

  const deliveredProducts = useMemo(
    () =>
      deliveries
        .map((prod) => prod.productDeliveries)
        .flat()
        .reduce((acc, cur) => {
          const foundProd = acc.find(
            (item) => item.orderProductId === cur.orderProductId
          );
          if (foundProd) {
            const i = acc.findIndex(
              (item) => item.orderProductId === cur.orderProductId
            );
            acc.splice(i, 1, {
              ...foundProd,
              quantity: foundProd.quantity + cur.quantity,
            });
          } else {
            acc.push(cur);
          }
          return acc;
        }, []),
    [deliveries]
  );

  const returnedProducts = useMemo(
    () =>
      returns
        .map((prod) => prod.productReturns)
        .flat()
        .reduce((acc, cur) => {
          const foundProd = acc.find(
            (item) => item.orderProductId === cur.orderProductId
          );
          if (foundProd) {
            const i = acc.findIndex(
              (item) => item.orderProductId === cur.orderProductId
            );
            acc.splice(i, 1, {
              ...foundProd,
              quantity: foundProd.quantity + cur.quantity,
            });
          } else {
            acc.push(cur);
          }
          return acc;
        }, []),
    [returns]
  );

  const processedDeliviries = useMemo(
    () =>
      deliveredProducts.map((prod) => {
        const temp = returnedProducts.find(
          (el) => el.orderProductId == prod.orderProductId
        );
        return temp
          ? { ...prod, quantity: prod.quantity - temp.quantity }
          : { ...prod };
      }),
    [deliveredProducts, returnedProducts]
  );

  const productsListWithGroups = useCallback((products) => {
    if (products?.length) {
      const groups = products?.reduce(
        (acc, p, i) => {
          if (p?.product?.parentProduct) {
            if (acc[p?.product?.parentProduct?.id]) {
              acc[p?.product?.parentProduct?.id].push({ ...p, index: i });
            } else {
              acc[p?.product?.parentProduct?.id] = [
                {
                  parentId: p?.product?.parentProduct?.id,
                  id: p?.name,
                  name: p?.name,
                  manufacturerName: p?.manufacturerName,
                  photo: p?.product?.parentProduct?.photos?.[0],
                },
                { ...p, index: i },
              ];
            }
          } else {
            acc.without_group.push({ ...p, index: i });
          }
          return acc;
        },
        { without_group: [] }
      );

      const addedLastEl = Object.entries(groups).map((el) => {
        if (el[0] === "without_group") {
          return el;
        } else {
          const indexLastEl = el[1].length - 1;
          let newEl = { ...el };
          newEl[1][indexLastEl].isLastEl = true;
          return newEl;
        }
      });

      return addedLastEl?.map((group) => group[1])?.flat();
    }
    return [];
  }, []);

  const setTitle = useMemo(() => {
    if (nameRefundDialog === "Refund") return "Refund";
    // if (nameRefundDialog === "Return Items" && !returnItem) return "Return";
    // if (nameRefundDialog === "Return Items" && returnItem) return "Edit return";
    return "Refund";
  }, [nameRefundDialog]);

  const setPaymentTitle = useCallback((item) => {
    if (item?.paymentType === "Credit Card") {
      if (!item?.brand) return "";

      return `${setCardName(item?.brand)} **** ${item?.last4}`;
    }
    if (item?.paymentType === "Check") return `Ref #: ${item?.check}`;
    if (item?.paymentType === "Cash") {
      if (item?.reference) {
        return `Ref # ${item?.reference}`;
      } else {
        return "";
      }
    }
  }, []);

  const productReturnsWithQuantity = formField?.productReturns
    ?.filter((prod) => prod.returnQuantity > 0)
    ?.map((el) => {
      return {
        orderProductId: el.orderProductId || el.id,
        quantity: el.returnQuantity,
      };
    });

  const setLabelBtn = useMemo(() => {
    if (nameRefundDialog === "Refund" && productReturnsWithQuantity?.length) {
      return "Refund & Return";
    }
    if (nameRefundDialog === "Refund")
      return subTotalItemsSum
        ? "Refund $" + separateNumWithComma(subTotalItemsSum)
        : "Refund";
    // if (nameRefundDialog === "Return Items" && !returnItem)
    //   return "Create Return";
    // if (nameRefundDialog === "Return Items" && returnItem) return "Confirm";
    return "Accept";
  }, [nameRefundDialog, productReturnsWithQuantity?.length, subTotalItemsSum]);

  const calculateSubtotal = useCallback(() => {
    const subtotal = formField?.paymentRefund?.reduce((acc, cur) => {
      if (cur?.isRefund) {
        acc += Number(cur?.amount);
      }
      return acc;
    }, 0);
    return subtotal;
  }, [formField?.paymentRefund]);

  const paymentCashList = useMemo(
    () =>
      order?.orderPayments?.filter(
        (payment) =>
          payment?.paymentType === "CASH" &&
          payment?.amount > payment?.totalRefunds
      ),
    [order?.orderPayments]
  );

  const paymentCash = useMemo(
    () =>
      paymentCashList?.map((cash) => {
        return {
          amount: (cash?.amount - cash?.totalRefunds).toFixed(2),
          totalSumToRefund: cash?.amount,
          paymentType: "Cash",
          isRefund: true,
          reference: cash?.reference,
          id: cash?.id,
        };
      }),
    [paymentCashList]
  );

  const paymentChecksList = useMemo(
    () =>
      order?.orderPayments?.filter(
        (payment) =>
          payment?.paymentType === "CHECK" &&
          payment?.amount > payment?.totalRefunds
      ),
    [order?.orderPayments]
  );

  const paymentChecks = useMemo(
    () =>
      paymentChecksList?.map((check) => {
        return {
          amount: (check?.amount - check?.totalRefunds).toFixed(2),
          totalSumToRefund: check?.amount,
          paymentType: "Check",
          isRefund: true,
          check: check?.check,
          id: check?.id,
        };
      }),
    [paymentChecksList]
  );
  const paymentCreditCardsList = useMemo(
    () =>
      order?.orderPayments?.filter(
        (payment) =>
          payment?.paymentType === "CREDIT_CARD" &&
          payment?.amount > payment?.totalRefunds
      ),
    [order?.orderPayments]
  );

  const paymentCreditCards = useMemo(
    () =>
      paymentCreditCardsList?.map((card) => {
        return {
          last4: card?.creditCard?.last4 ?? "",
          brand: card?.creditCard?.brand ?? "",
          amount: (card?.amount - card?.totalRefunds).toFixed(2) ?? 0,
          totalSumToRefund: card?.amount ?? 0,
          paymentType: "Credit Card",
          isRefund: true,
          id: card?.id,
        };
      }),
    [paymentCreditCardsList]
  );

  const countSubtotal = useMemo(() => {
    return formField?.productReturns?.reduce((acc, cur) => {
      acc += cur?.returnQuantity;
      return acc;
    }, 0);
  }, [formField?.productReturns]);

  const setReturnEdit = useMemo(() => {
    return nameRefundDialog === "Return Items" && returnItem && !refundEditItem;
  }, [nameRefundDialog, refundEditItem, returnItem]);

  const setShowTitleForProduct = useCallback(
    (product) => {
      return setReturnEdit
        ? !!product?.parentId
        : formField?.productReturns
            ?.filter((el) => el?.totalDelivered > 0)
            ?.some(
              (prod) => prod?.product?.parentProduct?.id === product?.parentId
            );
    },
    [formField?.productReturns, setReturnEdit]
  );

  const isTotalDelivery = useMemo(
    () => order?.totalDelivered === 0,
    [order?.totalDelivered]
  );
  const isEditReturn = useMemo(() => "Edit return" === setTitle, [setTitle]);

  const isRestockDisable = useMemo(() => {
    if (!productReturnsWithQuantity?.length) return true;

    if (isEditReturn) {
      return true;
    } else {
      return isTotalDelivery;
    }
  }, [isEditReturn, isTotalDelivery, productReturnsWithQuantity?.length]);

  useEffect(() => {
    if (open) {
      if (nameRefundDialog === "Refund") {
        if (subTotalItemsSum === 0) {
          return setIsDisableBtn(true);
        } else {
          return setIsDisableBtn(false);
        }
      }

      // if (
      //   nameRefundDialog === "Return Items" &&
      //   formField.returnShippingOptions === "LOCAL_DELIVERY"
      // ) {
      //   if (
      //     !!formField?.delivererId &&
      //     formField?.productReturns?.some((prod) => prod.returnQuantity > 0)
      //   ) {
      //     setIsDisableBtn(false);
      //   } else {
      //     setIsDisableBtn(true);
      //   }
      // }
      // if (
      //   nameRefundDialog === "Return Items" &&
      //   formField.returnShippingOptions === "SHIPPING_CARRIER"
      // ) {
      //   if (
      //     !!formField?.trackingId &&
      //     !!formField?.shippingCarrier &&
      //     formField?.productReturns?.some((prod) => prod.returnQuantity > 0)
      //   ) {
      //     setIsDisableBtn(false);
      //   } else {
      //     setIsDisableBtn(true);
      //   }
      // }
    }
  }, [
    formField,
    errors,
    nameRefundDialog,
    subTotalItemsSum,
    open,
    productReturnsWithQuantity?.length,
    productReturnsWithQuantity,
  ]);

  useEffect(() => {
    if (open) {
      if (returnItem?.id) {
        setProductsList(
          productsListWithGroups(
            returnItem?.productReturns?.map((prod) => {
              return order?.products?.find(
                (i) => i?.id === prod?.orderProductId
              );
            })
          )
        );
      } else {
        setProductsList(productsListWithGroups(order?.products));
      }
    }
  }, [returnItem, order?.products, productsListWithGroups, open]);

  useEffect(() => {
    if (open) {
      reset({
        ...formField,
        // returnShippingOptions: returnItem?.deliveryMethod || "SHIPPING_CARRIER",
        reason: returnItem?.reason ?? RETURNS[0].name,
        reasonRefound: "",
        restock: returnItem?.restock ?? false,
        sendNotification: sendNotifications,
        productReturns: returnItem
          ? returnItem?.productReturns?.map((prod) => ({
              ...prod,
              returnQuantity: prod.quantity,
            }))
          : order?.products?.map((prod) => ({
              ...prod,
              returnQuantity: 0,
            })),
        amount: "",
        paymentType: "CREDIT_CARD",
        date: new Date(Date.now()),
        check: "",
        reference: "",
        cardId: order?.customer?.creditCards
          ? order?.customer?.creditCards?.find(
              (card) => card?.defaultMethod === "true"
            )?.id || order?.customer?.creditCards[0]?.id
          : "",
        creditCards:
          nameRefundDialog === "Refund"
            ? order?.orderPayments?.filter((c) => c?.creditCard)
            : order?.customer?.creditCards || [],
        // trackingId: returnItem?.trackingId || "",
        // shippingCarrier: returnItem?.shippingCarrier?.id || "",
        // delivererId: order?.customer?.assignedRepresentatives?.length
        //   ? order?.customer?.assignedRepresentatives[0]?.representative?.id
        //   : "",
        paymentRefund: [
          ...(paymentCreditCards?.length ? paymentCreditCards : []),
          ...(paymentChecks?.length ? paymentChecks : []),
          ...(paymentCash?.length ? paymentCash : []),
        ],
        delivery: order?.deliveryFee ?? 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameRefundDialog, open, order, reset, returnItem]);

  useEffect(() => {
    if (open) {
      setAvialibleForRefund(calculateSubtotal());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formField.paymentRefund, open]);

  useEffect(() => {
    if (open) {
      setSubTotalItemsSum(calculateSubtotal());
    }
  }, [calculateSubtotal, formField, open]);

  useEffect(() => {
    if (open) {
      setValue(
        "amount",
        subTotalItemsSum > order?.totalPayments
          ? order?.totalPayments
          : subTotalItemsSum
      );
    }
  }, [open, order?.totalPayments, setValue, subTotalItemsSum]);

  useEffect(() => {
    if (refundEditItem && open) {
      reset({
        ...formField,
        amount: refundEditItem?.amount,
        check: refundEditItem?.check,
        date: refundEditItem?.date,
        paymentType: refundEditItem?.paymentType,
        reason: refundEditItem?.reason,
        reference: refundEditItem?.reference,
        productReturns: formField?.productReturns?.map((ret) => {
          const found = refundEditItem?.productReturns?.find(
            (prod) => prod.orderProductId === ret.id
          );
          return found
            ? { ...ret, ...found, returnQuantity: found?.quantity }
            : ret;
        }),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refundEditItem, reset]);

  const [loading, setLoading] = useState(false);

  const onSubmit = useCallback(
    (data) => {
      dispatch(startLoadingOrders());
      setLoading(true);
      const {
        sendNotification,
        restock,
        // reason,
        // productReturns,
        // returnShippingOptions,
        // trackingId,
        // shippingCarrier,
        paymentRefund,
        reasonRefound,
        // delivererId,
      } = data;

      if (nameRefundDialog === "Refund") {
        const refundData = {
          sendNotification,
          restock,
          reason: reasonRefound,
          payments: paymentRefund
            .filter((payment) => payment?.isRefund && payment?.amount > 0)
            .map((el) => {
              return { amount: Number(el?.amount), id: el?.id };
            }),
        };

        if (productReturnsWithQuantity.length) {
          refundData.productReturns = productReturnsWithQuantity;
        }

        if (refundEditItem) {
          updateRefundService(refundEditItem?.id, refundData)
            .then(() => {
              success("Refund was successfully");
              if (successCallback) successCallback();
              dispatch(getOrderByIdAction(order?.id));
              dispatch(getOrdersAction({ limit: SCROLL_LIMIT }));
              !isOrderPage && handleGetOrder(order?.id);
            })
            .catch((err) => {
              error(err?.message || "Something went wrong");
            })
            .finally(() => {
              dispatch(endLoadingOrders());
              setLoading(false);
              onClose();
            });
        } else {
          refundData.orderId = order?.id;
          confirmRefundService(refundData)
            .then(() => {
              if (successCallback) successCallback();
              success("Refund was successfully");
              dispatch(getOrderByIdAction(order?.id));
              dispatch(getOrdersAction({ limit: SCROLL_LIMIT }));
              !isOrderPage && handleGetOrder(order?.id);
            })
            .catch((err) => {
              const { deliveredQuantityError, message } =
                err?.response?.data || {};
              if (deliveredQuantityError)
                error("Something went wrong. Reload your page and try again");
              else error(message || err?.message);
            })
            .finally(() => {
              dispatch(endLoadingOrders());
              setLoading(false);
              onClose();
            });
        }
      }

      // const returnData = {
      //   deliveryMethod: returnShippingOptions,
      //   reason: reason,
      //   sendNotification: sendNotification,
      //   productReturns: productReturns
      //     .filter((prod) => prod.returnQuantity > 0)
      //     .map((el) => {
      //       return {
      //         orderProductId: el.orderProductId || el.id,
      //         quantity: el.returnQuantity,
      //       };
      //     }),
      // };

      // if (returnShippingOptions === "SHIPPING_CARRIER") {
      //   returnData.trackingId = trackingId;
      //   returnData.shippingCarrierId = shippingCarrier;
      // }
      // if (returnShippingOptions === "LOCAL_DELIVERY") {
      //   returnData.delivererId = delivererId;
      // }

      // if (nameRefundDialog === "Return Items" && returnItem) {
      //   updateReturnService(returnItem.id, returnData)
      //     .then(() => {
      //       dispatch(getOrderByIdAction(order?.id));
      //       dispatch(getOrdersAction({ limit: SCROLL_LIMIT }));
      //       success("Return updated successfully");
      //     })
      //     .catch((err) => {
      //       error(err?.message || "Something went wrong");
      //     })
      //     .finally(() => {
      //       dispatch(endLoadingOrders());
      //       onClose();
      //     });
      //   return;
      // }

      // if (nameRefundDialog === "Return Items") {
      //   returnData.orderId = order?.id;
      //   returnData.restock = restock;
      //   confirmReturnService(returnData)
      //     .then(() => {
      //       dispatch(getOrderByIdAction(order?.id));
      //       dispatch(getOrdersAction({ limit: SCROLL_LIMIT }));
      //       success("Return was successfully");
      //     })
      //     .catch((err) => {
      //       error(err?.message || "Something went wrong");
      //     })
      //     .finally(() => {
      //       dispatch(endLoadingOrders());
      //       onClose();
      //     });
      // }
    },
    [
      dispatch,
      handleGetOrder,
      nameRefundDialog,
      onClose,
      order?.id,
      productReturnsWithQuantity,
      refundEditItem,
      isOrderPage,
      successCallback,
    ]
  );

  const styledPopperText = useMemo(() => {
    if (openTooltip && !productReturnsWithQuantity?.length && !isEditReturn) {
      return "Pick at least one item to proceed";
    }

    if (openTooltip && isEditReturn) {
      return (
        <>
          <Box component="span">
            Restock couldn&apos;t be performed in edit return.
          </Box>
          <br />
          <Box component="span">
            Please edit an inventory directly from the product inventory tab
          </Box>
        </>
      );
    }
    if (openPopper) {
      return Object.entries(errors)
        .map((er) => er[1].message)
        .join("\n");
    }
    return "";
  }, [
    errors,
    isEditReturn,
    openPopper,
    openTooltip,
    productReturnsWithQuantity?.length,
  ]);

  const getAnchorEl = useMemo(() => {
    if (openTooltip) {
      return anchorElPopper.current;
    }
    if (openPopper) {
      return btnRef.current;
    }
    return null;
  }, [openPopper, openTooltip]);

  // const setLabelColor = useCallback(
  //   (value) => (value === "" ? "#B5B5AC" : "#000000"),
  //   []
  // );

  useEffect(() => {
    if (open) {
      dispatch(resetSalesRepsAction());
      dispatch(
        getRepsAction(
          {
            limit: SCROLL_LIMIT,
            role: "sales",
          },
          { isScrolling: false }
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  // const handleFetchReps = useCallback(() => {
  //   const fetchQuery = {
  //     limit: SCROLL_LIMIT,
  //     role: "sales",
  //     cursor: repsSalesUsers[repsSalesUsers.length - 1].id,
  //   };
  //   dispatch(getRepsAction(fetchQuery, { isScrolling: true }));
  // }, [dispatch, repsSalesUsers]);

  return (
    <>
      <StyledPopper
        style={{ zIndex: 1401 }}
        open={openTooltipNotification}
        anchorEl={notificationRef.current}
        text="No email address found for this customer"
        // modifiers={[{ name: "offset", options: { offset: [-102, 0] } }]}
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [+`-${notificationRef.current?.clientWidth / 2 - 16}`, 0],
            },
          },
        ]}
        placement="top"
        transition
        aria-hidden="true"
      />
      <Dialog onClose={onClose} open={open}>
        <StyledPopper
          style={{ zIndex: 1401, whiteSpace: "pre-wrap" }}
          open={openTooltip || (openPopper && !!Object.keys(errors).length)}
          anchorEl={getAnchorEl}
          text={styledPopperText}
          modifiers={[
            {
              name: "offset",
              options: { offset: openPopper ? [5, 10] : [-18, 0] },
            },
          ]}
          placement="top"
          transition
          aria-hidden="true"
        />
        <form id="refund-dialog-form" onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle sx={cl.blockTitle}>
            <Box sx={cl.boxTitle}>
              <Typography sx={cl.textTitle}>{setTitle}</Typography>
              <Typography sx={cl.textSubTitle}>
                Order ID: {order?.customId?.customId}
              </Typography>
            </Box>
            <IconButton
              sx={{ marginTop: "20px", marginRight: "16px" }}
              onClick={onClose}
            >
              <CrossBigIcon size={27.9} />
            </IconButton>
          </DialogTitle>

          <DialogContent sx={cl.blockContent}>
            <Grid container>
              <Grid sx={{ paddingLeft: "26px" }} xs={5.8} item container>
                <Grid item xs={12} container>
                  <Box>
                    <Typography
                      sx={{ ...cl.textContent, marginTop: 0.1, marginRight: 1 }}
                    >
                      Payment Status
                    </Typography>
                  </Box>
                  <PaymentStatus str={order?.paymentStatus} />
                </Grid>
                <Grid item xs={12} container>
                  <Box display="flex" alignItems="center">
                    <Typography sx={{ ...cl.textContent, marginRight: 1 }}>
                      Delivery Status
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        height: "6px",
                        width: "6px",
                        backgroundColor:
                          order?.deliveryStatus === "UNFULFILLED"
                            ? "#F0C401"
                            : "#47A06D",
                        borderRadius: "50%",
                        mr: "8px",
                      }}
                    />
                    <Typography
                      sx={{ fontSize: 12, fontWeight: 400, color: "#1C1C19" }}
                    >
                      {normalizeSnakeCaseString(order?.deliveryStatus)}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              <Grid sx={{ paddingRight: "26px" }} xs item>
                {nameRefundDialog === "Refund" && (
                  <Grid xs={12} mt="1px" item>
                    <Controller
                      render={({ field }) => (
                        <StyledTextField
                          placeholder="Reason for refund (optional)"
                          multiline
                          rows={2}
                          fullWidth
                          InputProps={{
                            className: classes.multiTextInput,
                          }}
                          {...field}
                        />
                      )}
                      name="reasonRefound"
                      control={control}
                    />
                  </Grid>
                )}
                {/* {nameRefundDialog === "Return Items" && (
                  <Grid xs={12} item>
                    <Typography sx={{ fontSize: 12, color: "#000000" }}>
                      Why are you returning this?
                    </Typography>
                    <FormControl
                      sx={{ marginTop: 1, maxWidth: "230px" }}
                      className={classes.selectInput}
                      fullWidth
                      size="small"
                    >
                      <Controller
                        render={({ field }) => (
                          <StyledSelect
                            notched={false}
                            leftSidedIcon
                            sx={{
                              height: "28px",
                              "& .MuiInputBase-input": {
                                color: "#000000 !important",
                                paddingRight: "4px !important",
                              },
                            }}
                            IconComponent={ExpandMore}
                            {...field}
                          >
                            {RETURNS.map((el) => (
                              <MenuItem key={el.id} value={el.name}>
                                {el.name}
                              </MenuItem>
                            ))}
                          </StyledSelect>
                        )}
                        name="reason"
                        control={control}
                      />
                    </FormControl>
                  </Grid>
                )} */}
              </Grid>
            </Grid>

            <Grid paddingX={3.25} marginTop={2} container>
              <Grid item xs>
                {(!!productsList?.filter((product) => product.totalDelivered)
                  .length ||
                  isEditReturn) && (
                  <Grid
                    sx={{
                      display: "flex",
                      border: "1px solid #D5D9D9",
                      borderRadius: "4px 4px 0 0",
                      height: "39px",
                      alignItems: "center",
                      backgroundColor: "#EEEEEE",
                    }}
                    item
                    xs={12}
                  >
                    <Grid sx={{ paddingLeft: "20px" }} item xs={6}>
                      <Typography sx={{ fontSize: 12, color: "#6A6A6A" }}>
                        ITEMS
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      {nameRefundDialog === "Refund" && (
                        <Typography sx={{ fontSize: 12, color: "#6A6A6A" }}>
                          PRICE PAID
                        </Typography>
                      )}
                    </Grid>
                    <Grid pl={2.5} item xs={4} container>
                      <Typography sx={{ fontSize: 12, color: "#6A6A6A" }}>
                        QUANTITY
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                <Stack
                  sx={{
                    maxHeight:
                      nameRefundDialog === "Refund" ? "204px" : "322px",
                    overflow: productsList?.length === 2 ? "hidden" : "auto",
                    borderColor: "#D5D9D9",
                    borderStyle: "solid",
                    borderWidth: "0px 1px 1px 1px ",
                    borderRadius: "0 0 4px 4px",
                  }}
                >
                  {productsList?.map((product) => (
                    <RefoundProductsItem
                      key={product?.id}
                      product={{
                        ...product,
                        processedDeliviries: processedDeliviries.find(
                          (item) => item.orderProductId === product.id
                        )?.quantity,
                      }}
                      showTitleForProduct={setShowTitleForProduct(product)}
                      discount={order?.customerDiscount}
                      setValue={setValue}
                      formField={formField}
                      isEdit
                      nameRefundDialog={nameRefundDialog}
                      isReturnEdit={setReturnEdit}
                      error={!!errors?.productReturns}
                    />
                  ))}
                </Stack>
              </Grid>
              <Grid
                sx={{ marginY: "6px", justifyContent: "space-between" }}
                item
                xs={12}
                container
                alignItems="center"
              >
                {isTotalDelivery ? null : (
                  <>
                    <Box
                      ref={anchorElPopper}
                      onMouseEnter={() => {
                        if (isRestockDisable) {
                          setOpenTooltip(true);
                        }
                      }}
                      onMouseLeave={() => setOpenTooltip(false)}
                    >
                      <Controller
                        render={({ field }) => (
                          <LargeCheckbox
                            formSx={{ mr: 0, flex: "1 1 auto", ml: "-9px" }}
                            checked={!!field.value}
                            disabled={isRestockDisable}
                            label={
                              <Typography
                                sx={{
                                  ml: "-4px",
                                }}
                                color={isRestockDisable ? "#8D8D8D" : "#000000"}
                                fontSize="12px"
                                fontWeight="400"
                              >
                                Restock
                              </Typography>
                            }
                            size={16}
                            {...field}
                          />
                        )}
                        name="restock"
                        control={control}
                      />
                    </Box>
                  </>
                )}
              </Grid>
            </Grid>

            <Grid paddingX={3.25} container>
              <Grid
                sx={{
                  display: "flex",
                  border: "1px solid #D5D9D9",
                  borderRadius: "4px 4px 0 0",
                  height: "39px",
                  alignItems: "center",
                  paddingLeft: "20px",
                  backgroundColor: "#EEEEEE",
                }}
                item
                xs={12}
              >
                <Typography sx={{ fontSize: 12, color: "#000000" }}>
                  {nameRefundDialog === "Refund" && "SUMMARY"}
                  {/* {nameRefundDialog === "Return Items" &&
                    "RETURN SHIPPING OPTIONS"} */}
                </Typography>
              </Grid>
              <Grid
                sx={{
                  borderColor: "#D5D9D9",
                  borderStyle: "solid",
                  borderWidth: "0px 1px 1px 1px ",
                  borderRadius: "0 0 4px 4px",
                  padding: "14px 20px 20px 20px",
                }}
                item
                xs={12}
                container
              >
                {nameRefundDialog === "Refund" && (
                  <>
                    <Grid
                      sx={{ marginBottom: 1 }}
                      item
                      xs={12}
                      container
                      justifyContent="space-between"
                    >
                      <Typography sx={cl.summaryText}>
                        Subtotal: ({countSubtotal} items)
                      </Typography>
                      <Typography sx={cl.summaryText}>
                        $
                        {separateNumWithComma(
                          subTotalItemsSum - (formField?.delivery ?? 0)
                        )}
                      </Typography>
                    </Grid>
                    <Grid
                      sx={{ marginBottom: 1 }}
                      item
                      xs={12}
                      container
                      justifyContent="space-between"
                    >
                      <Typography sx={cl.summaryText}>Delivery:</Typography>
                      <Typography sx={cl.summaryText}>
                        {typeof formField?.delivery === "number" &&
                        !Number.isNaN(formField?.delivery) &&
                        formField?.delivery > 0
                          ? formField.delivery
                          : "free"}
                      </Typography>
                    </Grid>
                    <Grid
                      sx={{ borderBottom: "1px solid #CCCCCC" }}
                      item
                      xs={12}
                      container
                      justifyContent="space-between"
                    >
                      <Typography
                        sx={{ ...cl.summaryTextBold, marginBottom: 1 }}
                      >
                        Refund total:
                      </Typography>
                      <Typography sx={cl.summaryTextBold}>
                        ${separateNumWithComma(subTotalItemsSum)}
                      </Typography>
                    </Grid>

                    <Grid
                      sx={{
                        marginTop: "10px",
                        justifyContent: "space-between",
                      }}
                      xs={12}
                      item
                      container
                    >
                      <Grid
                        sx={{ alignItems: "center" }}
                        xs="auto"
                        item
                        container
                      >
                        <Typography
                          sx={{
                            fontSize: 12,
                            fontWeight: 400,
                            color: "#ACACAC",
                          }}
                        >
                          ${separateNumWithComma(avialibleForRefund)} available
                          for refund
                        </Typography>
                      </Grid>
                      <Grid
                        sx={{ alignItems: "center" }}
                        xs="auto"
                        item
                        container
                      >
                        <Typography
                          sx={{
                            fontSize: 13,
                            fontWeight: 600,
                            color: "#000000",
                          }}
                        >
                          Refund Amount:
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      sx={{
                        marginTop: 1,
                        maxHeight: "210px",
                        overflow: "overlay",
                      }}
                      xs={12}
                      gap={1.5}
                      item
                      container
                    >
                      {fields?.map((payment, index) => {
                        return (
                          <PaymentItem
                            key={payment?.id}
                            type={payment?.paymentType}
                            title={setPaymentTitle(payment)}
                            sum={payment?.amount}
                            control={control}
                            setValue={setValue}
                            index={index}
                            isChecked={
                              formField?.paymentRefund?.find(
                                (refund) => refund.id === payment?.id
                              )?.isRefund
                            }
                          />
                        );
                      })}
                    </Grid>
                  </>
                )}
                {/* {nameRefundDialog === "Return Items" && (
                  <>
                    <Grid item xs={12} container justifyContent="space-between">
                      <Controller
                        render={({ field }) => (
                          <FormControl
                            sx={{
                              marginLeft: 0,
                              width: "100%",
                            }}
                            component="fieldset"
                          >
                            <Tooltip
                              placement="top"
                              title="No reps found in the system, please add at least 1 rep."
                              disableHoverListener={!!repsSalesUsers?.length}
                              PopperProps={{
                                modifiers: [
                                  {
                                    name: "offset",
                                    options: { offset: [0, -16] },
                                  },
                                ],
                              }}
                              arrow
                            >
                              <RadioGroup
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-around",
                                }}
                                {...field}
                              >
                                <FormControlLabel
                                  value="LOCAL_DELIVERY"
                                  sx={{
                                    marginLeft: 1,
                                    marginRight: 0,
                                    marginBottom: 0.5,
                                    "& .MuiFormControlLabel-label": {
                                      width: "100%",
                                    },
                                  }}
                                  label={
                                    <>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        {formField?.returnShippingOptions ===
                                          "LOCAL_DELIVERY" && (
                                          <StyledSelect
                                            disabled={!repsSalesUsers?.length}
                                            notched={false}
                                            fullWidth
                                            height="30px"
                                            formSx={{
                                              width: "40%",
                                              left: 0,
                                              mt: "-6px",
                                              height: "24px",
                                              "& .MuiFormLabel-root": {
                                                color: "#B5B5AC",
                                                fontSize: "12px",
                                                top: "2px",
                                              },
                                              "& .MuiOutlinedInput-root": {
                                                height: "100%",
                                                padding: "0 11px !important",
                                              },
                                              justifyContent: "space-between",
                                              "& .MuiOutlinedInput-input": {
                                                padding: "0 8px !important",
                                              },
                                              "& .MuiSvgIcon-root": {
                                                right: "4px",
                                              },
                                            }}
                                            size="small"
                                            color={setLabelColor(field.value)}
                                            displayEmpty
                                            noErrorMessage
                                            error={error?.message}
                                            border="0.5px solid #D5D9D9"
                                            {...field}
                                            dataLength={repsSalesUsers?.length}
                                            dataCount={salesCount}
                                            handleFetch={handleFetchReps}
                                            value={formField.delivererId}
                                            onChange={(e) =>
                                              setValue(
                                                "delivererId",
                                                e.target.value
                                              )
                                            }
                                          >
                                            <MenuItem
                                              sx={{ display: "none" }}
                                              value=""
                                            >
                                              Assign Sales Rep
                                            </MenuItem>
                                            {repsSalesUsers?.map((sale) => (
                                              <MenuItem
                                                key={sale?.id}
                                                value={sale?.id}
                                              >
                                                {sale?.name}
                                              </MenuItem>
                                            ))}
                                          </StyledSelect>
                                        )}

                                        <Typography
                                          sx={{
                                            fontSize: "12px",
                                            fontWeight: 400,
                                            color: repsSalesUsers?.length
                                              ? "#000000"
                                              : "#ACACAC",
                                          }}
                                          variant="caption"
                                        >
                                          Local delivery, no shipping required
                                        </Typography>
                                      </Box>
                                    </>
                                  }
                                  control={
                                    <Radio
                                      disabled={!repsSalesUsers?.length}
                                      sx={{
                                        "& svg": {
                                          width: "16px",
                                          height: "16px",
                                        },
                                      }}
                                    />
                                  }
                                />

                                <Divider />
                                <FormControlLabel
                                  value="SHIPPING_CARRIER"
                                  sx={{ marginLeft: 1, marginY: 0.5 }}
                                  label={
                                    <Typography
                                      sx={{
                                        fontSize: "12px",
                                        fontWeight: 400,
                                        color: "#000000",
                                      }}
                                      variant="caption"
                                    >
                                      Shipping carrier
                                    </Typography>
                                  }
                                  control={
                                    <Radio
                                      sx={{
                                        "& svg": {
                                          width: "16px",
                                          height: "16px",
                                        },
                                      }}
                                    />
                                  }
                                />
                              </RadioGroup>
                            </Tooltip>
                          </FormControl>
                        )}
                        name="returnShippingOptions"
                        control={control}
                      />
                    </Grid>

                    {formField?.returnShippingOptions ===
                      "SHIPPING_CARRIER" && (
                      <>
                        <Grid item xs={12} container>
                          <Grid sx={{ marginLeft: 2 }} item xs={4.6}>
                            <Typography
                              sx={{
                                ...cl.summaryText,
                                fontSize: 12,
                                marginBottom: 0.5,
                              }}
                            >
                              Tracking ID
                            </Typography>
                          </Grid>
                          <Grid item xs />
                          <Grid sx={{ marginRight: 2 }} item xs={4.6}>
                            <Typography
                              sx={{
                                ...cl.summaryText,
                                fontSize: 12,
                                marginBottom: 0.5,
                              }}
                            >
                              Shipping carrier
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} container>
                          <Grid sx={{ marginLeft: 2 }} item xs={4.6}>
                            <Box>
                              <Controller
                                render={({ field, fieldState: { error } }) => (
                                  <StyledTextField
                                    fullWidth
                                    InputProps={{
                                      className: classes.textInput,
                                    }}
                                    placeholder="Tracking ID"
                                    noErrorMessage
                                    error={!!error}
                                    {...field}
                                  />
                                )}
                                name="trackingId"
                                control={control}
                              />
                            </Box>
                          </Grid>
                          <Grid
                            item
                            xs
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Divider orientation="vertical" />
                          </Grid>
                          <Grid sx={{ marginRight: 2 }} item xs={4.6}>
                            <Box>
                              <Controller
                                render={({ field, fieldState: { error } }) => (
                                  <StyledSelect
                                    {...optionsShippingCarrierSelect}
                                    error={error?.message || ""}
                                    {...field}
                                  >
                                    <MenuItem sx={{ display: "none" }} value="">
                                      <Box component="span" color="#ACACAC">
                                        Shipping carrier
                                      </Box>
                                    </MenuItem>
                                    {shippingCarriers.map(({ id, name }) => (
                                      <MenuItem key={id} value={id}>
                                        {name}
                                      </MenuItem>
                                    ))}
                                  </StyledSelect>
                                )}
                                name="shippingCarrier"
                                control={control}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </>
                    )}
                    <Grid marginTop={1} item xs={12} container />
                  </>
                )} */}
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions sx={cl.blockActions}>
            {/* {nameRefundDialog === "Refund" && ( */}
            <Controller
              render={({ field }) => (
                <Box
                  sx={{
                    flexGrow: 1,
                  }}
                  ref={notificationRef}
                  onMouseEnter={() => {
                    if (!hasCustomerEmail) {
                      setOpenTooltipNotification(true);
                    }
                  }}
                  onMouseLeave={() => {
                    setOpenTooltipNotification(false);
                  }}
                >
                  <LargeCheckbox
                    disabled={!hasCustomerEmail}
                    formSx={{
                      mr: 0,
                      flex: "1 1 auto",
                      ml: "-9px",
                      color: hasCustomerEmail ? "#000" : "#B9B9B9",
                    }}
                    checked={!!field.value}
                    label={
                      <Typography
                        sx={{
                          ml: "-4px",
                        }}
                        fontSize="12px"
                        fontWeight="400"
                      >
                        Send notification to the customer
                      </Typography>
                    }
                    size={16}
                    {...field}
                  />
                </Box>
              )}
              name="sendNotification"
              control={control}
            />
            {/* )} */}
            <StyledButton
              sx={{ color: "#6A6A6A", height: "33px" }}
              fontSize="13px"
              label="Cancel"
              color="groundLighter"
              onClick={onClose}
            />
            <Box
              ref={btnRef}
              onMouseEnter={() => {
                trigger();
                setOpenPopper(true);
              }}
              onMouseLeave={() => setOpenPopper(false)}
            >
              <StyledButton
                disabled={isDisableBtn || loading}
                sx={{
                  height: "33px",
                  width: "140px",
                  "& > span": {
                    width: "140px",
                    paddingLeft: "8px",
                    paddingRight: "8px",
                  },
                }}
                fontSize="13px"
                label={
                  <Typography fontSize={13} noWrap>
                    {loading ? "loading..." : setLabelBtn}
                  </Typography>
                }
                variant="contained"
                type="submit"
                form="refund-dialog-form"
              />
            </Box>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

RefundDialog.propTypes = {
  open: bool,
  onClose: func,
  nameRefundDialog: string,
  order: object,
  returnItem: object,
  refundEditItem: object,
  handleFetch: func,
  handleGetOrder: func,
  successCallback: func,
  isOrderPage: bool,
};

export default RefundDialog;
