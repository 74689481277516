import React from "react";

export const DownloadUpIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10.095"
      height="12.258"
      viewBox="0 0 10.095 12.258"
    >
      <path
        id="Icon_metro-file-upload"
        data-name="Icon metro-file-upload"
        d="M11.882,15.8h4.326V11.474h2.884L14.045,6.427,9,11.474h2.884ZM9,17.243H19.092v1.442H9Z"
        transform="translate(-8.997 -6.427)"
        fill="#707070"
      />
    </svg>
  );
};
