import { func, string, bool } from "prop-types";
import { useMemo } from "react";
import {
  EmptyCustomersIcon,
  EmptyDiscountsIcon,
  EmptyMapsIcon,
  EmptyMessagesIcon,
  EmptyOrdersIcon,
  EmptyProductsIcon,
  EmptyRepsIcon,
  EmptyTasksIcon,
} from "../Icons";
import { CircularProgress, Typography, Box } from "@mui/material";
import useStyles from "./styles";
import StyledButton from "../StyledButton";
import { useAdmin } from "helpers/helpers";

export const EmptyScreen = ({
  type,
  onConfirm,
  height,
  showAction,
  loading,
  disabled,
}) => {
  const isAdmin = useAdmin();
  const classes = useStyles({ height });

  const icon = useMemo(() => {
    switch (type) {
      case "products": {
        return <EmptyProductsIcon />;
      }
      case "orders": {
        return <EmptyOrdersIcon />;
      }
      case "drafts": {
        return <EmptyOrdersIcon />;
      }
      case "customers": {
        return <EmptyCustomersIcon />;
      }
      case "discounts": {
        return <EmptyDiscountsIcon />;
      }
      case "messages": {
        return <EmptyMessagesIcon />;
      }
      case "reps": {
        return <EmptyRepsIcon />;
      }
      case "routes": {
        return <EmptyMapsIcon />;
      }
      case "task": {
        return <EmptyTasksIcon />;
      }
    }
  }, [type]);

  const getStartedBy = useMemo(() => {
    switch (type) {
      case "products": {
        return " creating your first product";
      }
      case "orders": {
        return " creating your first order";
      }
      case "drafts": {
        return " creating your first draft";
      }
      case "customers": {
        return " adding your first customer";
      }
      case "discounts": {
        return " adding your first discount";
      }
      case "messages": {
        return " messaging your \nteam or customers";
      }
      case "reps": {
        return " adding your first rep";
      }
      case "routes": {
        return "";
      }
      case "task": {
        return " adding your first task";
      }
    }
  }, [type]);

  const buttonLabel = useMemo(() => {
    switch (type) {
      case "products": {
        return "Add Product";
      }
      case "orders": {
        return "Create order";
      }
      case "drafts": {
        return "Create draft";
      }
      case "customers": {
        return "Add Customer";
      }
      case "discounts": {
        return "Add Discount";
      }
      case "messages": {
        return "Send message";
      }
      case "reps": {
        return "Add Representative";
      }
      case "routes": {
        return "Create route";
      }
      case "task": {
        return "Create task";
      }
    }
  }, [type]);

  return (
    <Box className={classes.emptyScreenWrapper}>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {icon}
          <Typography className={classes.notFoundText}>
            No {type} found
          </Typography>
          {showAction && (
            <>
              {type === "routes" ? (
                <Box width="200px">
                  <Typography
                    fontSize={14}
                    fontWeight={400}
                    color="#1C1C19"
                    textAlign="center"
                  >
                    Help sales reps visit all the right locations. Efficiently.
                  </Typography>
                </Box>
              ) : (
                <Typography className={classes.getStartedText}>
                  Get started by{getStartedBy}
                </Typography>
              )}
              <StyledButton
                disabled={disabled || isAdmin}
                label={buttonLabel}
                variant="contained"
                className={classes.confirmButton}
                fontSize="16px"
                onClick={onConfirm}
              />
            </>
          )}
        </>
      )}
    </Box>
  );
};

EmptyScreen.propTypes = {
  type: string,
  onConfirm: func,
  height: string,
  showAction: bool,
  loading: bool,
  disabled: bool,
};

export default EmptyScreen;
