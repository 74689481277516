import PropTypes, { number, string, object, func } from "prop-types";

import { Box, CircularProgress, IconButton, Typography } from "@mui/material";

import { TrashIcon } from "../Icons/ImageEditBtns";
import { PlusIcon } from "../Icons/ImageEditBtns";
import { ArrowRightIcon } from "../Icons/ImageEditBtns";
import { ArrowLeftIcon } from "../Icons/ImageEditBtns";

export const PreviewPic = ({
  width,
  height,
  src,
  mainImg,
  isHoverImg,
  id,
  onChangeEl,
  onMove,
  onDelete,
  inputRef,
  image,
  loading,
  ...props
}) => {
  return (
    <>
      <Box
        component="img"
        sx={{
          width: `${width}px`,
          height: `${height}px`,
          maxWidth: { xs: `${width}px` },
          maxHeight: { xs: `${height}px` },
          minWidth: { xs: `${width}px` },
          minHeight: { xs: `${height}px` },
          border: "1px solid #D5D9D9",
          borderRadius: "8px",
          objectFit: "cover",
          opacity: loading ? 0.5 : 1,
        }}
        alt="Product"
        src={src}
        {...props}
      />
      {loading === image?.temporaryId && (
        <Box
          sx={{
            position: "absolute",
            top: width ? `${width / 2 - 10}px` : "calc(50% - 10px)",
            left: height ? `${height / 2 - 10}px` : "calc(50% - 10px)",
          }}
        >
          <CircularProgress color="inherit" size={20} />
        </Box>
      )}

      {mainImg?.uuid === id && (
        <Box
          sx={{
            backgroundColor: "rgba(71, 160, 109, 0.15)",
            width: `${width}px`,
            height: `${height}px`,
            borderRadius: "8px",
            border: "2px solid #47A06D",
            position: "absolute",
            top: 0,
          }}
        >
          <Box
            sx={{
              width: "24px",
              height: "12px",
              backgroundColor: "#47A06D",
              position: "absolute",
              top: "8px",
              right: "8px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "2px",
            }}
          >
            <Typography
              sx={{
                fontSize: "8px",
                fontWeight: 400,
                color: "#ffffff",
              }}
            >
              MAIN
            </Typography>
          </Box>
        </Box>
      )}
      <Box
        sx={{
          width: `${width}px`,
          height: "21px",
          backgroundColor: "#9F9F9F",
          position: "absolute",
          bottom: "0px",
          justifyContent: "space-between",
          alignItems: "center",
          borderRadius: "0 0 8px 8px",
          display:
            (isHoverImg?.uuid || isHoverImg?.id) === id ? "flex" : "none",
          pointerEvents: !!loading && "none",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <IconButton sx={{ p: "6px" }} onClick={() => onMove(image, "left")}>
            <ArrowLeftIcon color="#fff" width={10} height={10} />
          </IconButton>
        </Box>
        <Box sx={{ display: "flex" }}>
          <IconButton sx={{ p: "6px" }} onClick={() => onMove(image, "right")}>
            <ArrowRightIcon color="#fff" width={10} height={10} />
          </IconButton>
        </Box>
        <Box sx={{ display: "flex" }}>
          <IconButton sx={{ p: "6px" }} component="label">
            <PlusIcon color="#fff" width={10} height={10} />
            <input
              ref={inputRef}
              type="file"
              hidden
              onChange={(e) => onChangeEl(e, image)}
              accept="image/png, image/gif, image/jpeg"
            />
          </IconButton>
        </Box>
        <Box sx={{ display: "flex" }}>
          <IconButton sx={{ p: "6px" }} onClick={() => onDelete(image)}>
            <TrashIcon color="#fff" width={10} height={10} />
          </IconButton>
        </Box>
      </Box>
    </>
  );
};
PreviewPic.propTypes = {
  width: number,
  height: number,
  src: string,
  mainImg: object,
  isHoverImg: object,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChangeEl: func,
  onMove: func,
  onDelete: func,
  inputRef: object,
  image: object,
  loading: string,
};
PreviewPic.defaultProps = {
  width: 100,
  height: 100,
  src: "",
  isHoverImg: {},
  image: {},
};

export default PreviewPic;
