import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { bool, func, object } from "prop-types";
import { LargeCheckbox } from "../../../../../../components/Checkboxes";
import { InformationIcon } from "components/Icons";
import { StyledTooltip } from "components";

const DiscountCustomerItem = ({
  item,
  disabled,
  isChecked,
  handleCheckItem,
  allowMissingInfo,
}) => {
  const nowrap = {
    whiteSpace: "nowrap",
    overflowX: "hidden",
    textOverflow: "ellipsis",
  };

  const isMissingInfo = item?.missingFields;

  return (
    <Box
      key={item.id}
      display="flex"
      alignItems="center"
      p="4.5px 14px 6px 6px"
      height="43px"
      borderBottom="0.5px solid #D5D9D9"
      sx={{
        opacity:
          disabled || allowMissingInfo ? 1 : isMissingInfo?.length ? 0.5 : 1,
      }}
    >
      <LargeCheckbox
        checked={isChecked}
        onChange={() => handleCheckItem(item)}
        size={16}
        formSx={{ m: 0 }}
        disabled={disabled || (!allowMissingInfo && !!isMissingInfo?.length)}
      />
      <Box sx={{ overflowX: "hidden" }}>
        <Box display="flex" alignItems="center">
          <Typography fontSize="12px" color="#1C1C19" sx={nowrap}>
            {item?.displayName || item?.name} (ID: {item?.customId?.customId})
          </Typography>
          {!!isMissingInfo?.length && (
            <StyledTooltip
              arrow
              title={
                <Box textAlign="left">
                  <Typography fontSize={12}>Missing info:</Typography>
                  {isMissingInfo?.map((info) => (
                    <Typography key={info} fontSize={12} fontWeight={300}>
                      {info}
                    </Typography>
                  ))}
                </Box>
              }
              placement="top"
              PopperProps={{
                modifiers: [
                  {
                    name: "offset",
                    options: { offset: [0, -8] },
                  },
                ],
              }}
            >
              <Box display="flex" alignItems="center">
                <InformationIcon size="16" stroke="#FF6969" />
              </Box>
            </StyledTooltip>
          )}
        </Box>
        <Typography
          fontSize="12px"
          fontWeight="400"
          color="#1C1C19"
          sx={nowrap}
        >
          {item.shippingAddress?.formatted_address}
        </Typography>
      </Box>
    </Box>
  );
};

DiscountCustomerItem.propTypes = {
  item: object,
  handleCheckItem: func,
  disabled: bool,
  isChecked: bool,
  allowMissingInfo: bool,
};

DiscountCustomerItem.defaultProps = {
  item: null,
  disabled: false,
  isChecked: false,
  allowMissingInfo: false,
};

export default DiscountCustomerItem;
