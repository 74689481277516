import {
  Button,
  Divider,
  Grid,
  Paper,
  SvgIcon,
  Typography,
  Box,
} from "@mui/material";
import { useCallback, useState } from "react";
import { ReactComponent as PadlockIco } from "../../assets/icons/PadlockIco.svg";
import { NavLink } from "react-router-dom";
import { resetPasswordEmail } from "../../services/account";
import { toast } from "react-toastify";
import { Controller, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { StyledTextField } from "../../components/TextFields/TextFields";
import { validationSchema } from "./ResetPassword.validation";
import useStyles from "../LoginPage/styles";
import { errorConfig, success } from "../../utils/notifications";
import { MainLogoIcon } from "../../components/Icons/MainLogos";

const ResetPasswordPage = () => {
  const classes = useStyles();

  const [isCheckedAndConfirmedEmail, setIsCheckedAndConfirmedEmail] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { control, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues: {
      email: "",
    },
    resolver: yupResolver(validationSchema()),
  });

  const formField = useWatch({
    control,
  });

  const onSubmit = useCallback(
    async (data) => {
      setIsLoading(true);
      try {
        await resetPasswordEmail(data);
        setIsLoading(false);
        setIsCheckedAndConfirmedEmail(true);
        success("Email sent!");
      } catch (err) {
        if (err?.response?.status === 404) {
          toast.error(
            `There isn't a Simply Depo account associated with ${formField?.email}.`,
            errorConfig
          );
          return;
        }
        toast.error("Couldn't find your email", errorConfig);
      } finally {
        setIsLoading(false);
      }
    },
    [formField?.email]
  );

  return (
    <form
      id="reset-password-form"
      onSubmit={handleSubmit(onSubmit)}
      className={classes.background}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Grid item>
          <Paper
            className={classes.mainBlock}
            sx={{
              mt: "-3%",
            }}
            elevation={3}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                py: "27px",
              }}
            >
              <MainLogoIcon width={307} height={45} />
            </Box>
            {isCheckedAndConfirmedEmail ? (
              <>
                <Typography
                  variant="h6"
                  textAlign="center"
                  mt="71px"
                  mb="12px"
                  fontSize="25px"
                  color="#5F6267"
                >
                  Check your email
                </Typography>

                <Typography
                  color="#1C1C19"
                  textAlign="center"
                  mt={3}
                  fontSize="15px"
                >
                  We sent a reset password email to:
                </Typography>
                <Typography
                  textAlign="center"
                  mb={3}
                  fontWeight="400"
                  color="#409A65"
                  fontSize="15px"
                >
                  {formField.email}
                </Typography>

                <Typography
                  color="#1C1C19"
                  textAlign="center"
                  fontSize="15px"
                  sx={{ my: 2, whiteSpace: "pre-wrap" }}
                >
                  {`Please click the reset password link to set your\nnew password.`}
                </Typography>
                <Button
                  sx={{ mt: 3, height: "67px", fontSize: "18px" }}
                  variant="contained"
                  fullWidth
                  color="primary2"
                  component={NavLink}
                  to="/login"
                >
                  Log In
                </Button>
                <Typography
                  color="#7E7E7E"
                  fontWeight="400"
                  textAlign="center"
                  fontSize="15px"
                  sx={{ mt: "48.5px", whiteSpace: "pre-wrap" }}
                >
                  {`If you don't see the email in your inbox,\ncheck your spam folder.`}
                </Typography>
              </>
            ) : (
              <>
                <Box textAlign="center">
                  <SvgIcon sx={{ width: 49, height: 49 }} viewBox="0 0 49 49">
                    <PadlockIco />
                  </SvgIcon>
                </Box>
                <Typography
                  variant="h6"
                  textAlign="center"
                  my={2}
                  color="#5F6267"
                  fontSize="25px"
                >
                  Forgot your password?
                </Typography>
                <Typography
                  mb={2}
                  variant="caption"
                  component="div"
                  textAlign="center"
                  fontSize="15px"
                  color="#1C1C19"
                >
                  Enter your email below to reset your password.
                </Typography>
                <Controller
                  render={({ field, fieldState: { error } }) => (
                    <StyledTextField
                      fullWidth
                      margin="normal"
                      label="Email"
                      size="medium"
                      InputProps={{
                        className: classes.input,
                      }}
                      InputLabelProps={{
                        className: classes.inputLabel,
                      }}
                      error={error ? error.message : ""}
                      {...field}
                    />
                  )}
                  name="email"
                  control={control}
                />
                <Button
                  disabled={isLoading}
                  className={classes.resetButton}
                  sx={{
                    mt: "35px",
                  }}
                  variant="contained"
                  fullWidth
                  type="submit"
                  color="primary2"
                  form="reset-password-form"
                  // onClick={handleResetPassword}
                >
                  {isLoading ? "Sending request..." : "Continue"}
                </Button>

                <Divider sx={{ my: "30px" }}>
                  <Typography fontSize="13px" color="#000000">
                    Or
                  </Typography>
                </Divider>
                <Button
                  variant="outlined"
                  fullWidth
                  component={NavLink}
                  to="/login"
                  className={classes.resetButton}
                  sx={{ color: "#5F6267" }}
                  color="greyBtn"
                >
                  Log in
                </Button>
              </>
            )}
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
};

export default ResetPasswordPage;
