import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  textInput: {
    fontSize: "12px",
    height: "32px",
  },

  inputLabel: {
    fontSize: "12px",
    color: "#B5B5AC",
  },

  helperText: {
    fontSize: "10px",
    fontWeight: 400,
    color: "#6a6a6a",
    marginTop: "2px",
  },
  section: {
    marginRight: "20px",
    padding: "16px 10px 16px 24px",
    height: "427px",
    boxShadow: "none",
    border: "1px solid #F0F0F0",
    width: "50%",
  },
}));

export default useStyles;
