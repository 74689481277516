import React from "react";

// eslint-disable-next-line react/prop-types
export const FlagIcon = ({ color = "#ccc" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8.328"
      height="9.76"
      viewBox="0 0 8.328 9.76"
    >
      <g
        id="icons8-flag-2_2_"
        data-name="icons8-flag-2 (2)"
        transform="translate(0.328)"
      >
        <path
          id="Path_1115"
          data-name="Path 1115"
          d="M7.764.615a3.492,3.492,0,0,1-1.844.568A3.752,3.752,0,0,1,4.059.607,4.011,4.011,0,0,0,2.08,0C.476,0,.088.4.049.449L0,.508V6.239l.232-.146A3.756,3.756,0,0,1,2.08,5.72a4.155,4.155,0,0,1,1.489.33,6.314,6.314,0,0,0,2.351.459,3.808,3.808,0,0,0,2-.618L8,5.835V.458Z"
          fill={color}
        />
        <path
          id="Path_1116"
          data-name="Path 1116"
          d="M1,3v8.744"
          transform="translate(-0.828 -2.484)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};
