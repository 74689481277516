import { Box, CircularProgress } from "@mui/material";

export const LoadingBlock = () => {
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#9C9C94",
      }}
    >
      <CircularProgress />
    </Box>
  );
};
