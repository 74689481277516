import { object, bool } from "prop-types";
import { Box, Grid } from "@mui/material";
import { Controller } from "react-hook-form";
import { StyledTextField, StyledTooltip } from "components";
import useStyles from "../../../styles";
import { useSelector } from "react-redux";

export const ProductNameBlock = ({
  tooltipState,
  errors,
  control,
  closeTooltip,
}) => {
  const classes = useStyles();

  const confirmDialogIsOpen = useSelector(
    ({ confirmDialogs }) => confirmDialogs.isOpen
  );

  return (
    <Grid xs={12} item>
      <Controller
        render={({ field, fieldState: { error } }) => (
          <StyledTooltip
            placement="right"
            arrow
            title={tooltipState.title || `This product name is\nalready used`}
            open={
              (tooltipState.open || errors.name?.type === "duplicate") &&
              !confirmDialogIsOpen &&
              !closeTooltip
            }
          >
            <Box>
              <StyledTextField
                adminIsAllowed
                fullWidth
                size="small"
                label="Product name *"
                InputProps={{
                  className: classes.textInput,
                }}
                InputLabelProps={{
                  className: classes.inputLabel,
                }}
                error={error || tooltipState?.open ? " " : ""}
                {...field}
              />
            </Box>
          </StyledTooltip>
        )}
        name="name"
        control={control}
      />
    </Grid>
  );
};

ProductNameBlock.propTypes = {
  tooltipState: object,
  errors: object,
  control: object,
  closeTooltip: bool,
};
