import { Box } from "@mui/system";
import { object, func } from "prop-types";
import { PayoutsHeader, PayoutsItem } from "./components";

const PayoutDetails = ({
  bankAccount,
  handleAddAccount,
  handleEditBankAccount,
  settings,
  setSettings,
  handleUpdatePersonalDetails,
}) => {
  return (
    <Box width="100%" border="1px solid #d5d9d9" borderRadius="4px" mt="15px">
      <PayoutsHeader hasBankAccount={!!bankAccount} />
      <PayoutsItem
        {...{
          bankAccount,
          handleAddAccount,
          handleEditBankAccount,
          settings,
          setSettings,
          handleUpdatePersonalDetails,
        }}
      />
    </Box>
  );
};

PayoutDetails.propTypes = {
  bankAccount: object,
  handleAddAccount: func,
  handleEditBankAccount: func,
  settings: object,
  handleChangeDesription: func,
  setSettings: func,
  handleUpdatePersonalDetails: func,
};

export default PayoutDetails;
