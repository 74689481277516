import { useContext, useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Typography,
} from "@mui/material";
import { CustomerContext } from "Pages/CustomersPage/pages/CustomerPage/CustomerPage";
import { Controller } from "react-hook-form";
import { LargeCheckbox, StyledSelect, StyledTextField } from "components";
import { useParentCustomers } from "Pages/CustomersPage/pages/CustomerPage/CustomerPage.hooks";
import { CrossIcon, SearchIcon } from "components/Icons";
import { useDebounce } from "helpers/hooks";
import useStyles from "Pages/CartPage/styles";
import { ExpandMore } from "@mui/icons-material";

export const ParentCustomerBlock = () => {
  const [customersSearchInput, setCustomersSearchInput] = useState("");
  const parentsSearchInputDebounced = useDebounce(customersSearchInput, 500);
  const { control, formField, setValue } = useContext(CustomerContext);
  const { parentCustomerId, parentCustomer } = formField || {};

  const { parentsState, handleFetch: handleFetchParents } = useParentCustomers(
    parentsSearchInputDebounced,
    true,
    formField.name
  );
  const {
    loading: loadingParents,
    list: parentsList,
    count: countParents,
    params: { page },
  } = parentsState || {};

  const classes = useStyles({ customersSearchInput });

  return (
    <Grid mb={parentCustomerId ? "14px" : "24px"} xs={12} item container>
      <Grid xs={12} item>
        <Controller
          render={({ field, fieldState: { error } }) => (
            <StyledSelect
              adminIsAllowed
              disabled={loadingParents}
              error={error ? error.message : ""}
              noErrorMessage
              fullWidth
              label={loadingParents ? "Loading..." : "Parent customer"}
              labelSx={{
                color: "#5F6267!important",
                fontWeight: 400,
                fontSize: 13,
                top: 7,
                "&.MuiInputLabel-shrink": { top: 0 },
              }}
              PaperPropsSx={{ "& ul": { pt: 0 } }}
              sx={{
                "&:hover": {
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: " #3F3F3F !important",
                  },
                },
              }}
              PAPER_WIDTH="476px !important"
              IconComponent={(props) => (
                <ExpandMore sx={{ fontSize: 20 }} {...props} />
              )}
              height="52px"
              fontSize="13px"
              onClose={() => {
                setCustomersSearchInput("");
              }}
              dataLength={parentsList.length}
              dataCount={countParents}
              handleFetch={() => handleFetchParents(page + 1)}
              inputProps={{ sx: { icon: { fill: "#5F6267" } } }}
              {...field}
            >
              <MenuItem
                sx={{
                  p: "10px",
                  "&:hover": { backgroundColor: "#FFF !important" },
                  "&.Mui-focused": { bgcolor: "#FFF !important" },
                  "&.Mui-focusVisible": { bgcolor: "#FFF !important" },
                  "&.Mui-selected": { bgcolor: "#FFF !important" },
                  "&.Mui-selected:hover": { bgcolor: "#FFF" },
                }}
                onKeyDown={(e) => e.stopPropagation()}
              >
                <StyledTextField
                  fullWidth
                  size="small"
                  value={customersSearchInput}
                  onChange={(e) => setCustomersSearchInput(e.target.value)}
                  placeholder="Search by name, address or ID"
                  sx={{
                    fieldset: { borderWidth: "0px !important" },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "red",
                    },
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  InputProps={{
                    className: classes.textInput,
                    endAdornment: (
                      <>
                        <InputAdornment
                          sx={{ position: "absolute", left: 9 }}
                          position="start"
                        >
                          <SearchIcon />
                        </InputAdornment>
                        {customersSearchInput && (
                          <InputAdornment position="end">
                            <IconButton
                              sx={{ p: "1px" }}
                              onClick={() => setCustomersSearchInput("")}
                            >
                              <CrossIcon size="15" />
                            </IconButton>
                          </InputAdornment>
                        )}
                      </>
                    ),
                  }}
                  InputLabelProps={{
                    className: classes.inputLabel,
                  }}
                />
              </MenuItem>
              {parentsList.length === 0 ? (
                <Typography
                  fontWeight="300"
                  fontSize="16px"
                  textAlign="center"
                  width="100%"
                  color="#6A6A6A"
                  paddingTop="20px"
                  paddingBottom="20px"
                >
                  No customers found
                </Typography>
              ) : null}

              <MenuItem value={field?.value} sx={{ display: "none" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <Typography
                    noWrap
                    fontSize={13}
                    fontWeight={600}
                    color="#5F6267"
                  >
                    {parentCustomer?.displayName || parentCustomer?.name}
                  </Typography>
                  <Typography
                    fontSize={13}
                    fontWeight={400}
                    noWrap
                    color="#5F6267"
                  >
                    {parentCustomer?.shippingAddress?.formatted_address}
                  </Typography>
                </Box>
              </MenuItem>

              {parentsList.map((customer) => (
                <MenuItem
                  key={customer?.id}
                  value={customer?.id}
                  onClick={() => setValue("parentCustomer", customer)}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <Typography
                      noWrap
                      fontSize={13}
                      fontWeight={600}
                      color="#5F6267"
                    >
                      {customer?.displayName || customer?.name}
                    </Typography>
                    <Typography
                      fontSize={13}
                      fontWeight={400}
                      noWrap
                      color="#5F6267"
                    >
                      {customer?.shippingAddress?.formatted_address}
                    </Typography>
                  </Box>
                </MenuItem>
              ))}
            </StyledSelect>
          )}
          name="parentCustomerId"
          control={control}
        />
        {/* <Controller
          render={({ field, fieldState: { error } }) => (
            <FormControl fullWidth>
              <InputLabel
                id={`${field.name}-select-label`}
                sx={{
                  top: "-4px",
                  fontSize: "13px",
                  "&.MuiInputLabel-shrink": {
                    top: "0px",
                  },
                }}
              >
                Select parent customer
              </InputLabel>
              <Select
                label="Select parent customer"
                labelId={`${field.name}-select-label`}
                sx={{
                  height: 43,
                  "& .MuiInputBase-input": {
                    fontSize: 13,
                  },
                  "& fieldset": {
                    border: "1px solid #D5D9D9",
                  },
                  color: field.value === "" ? "#717171" : "",
                  "& fieldset legend": {
                    // pr: field?.value ? "4px" : 0,
                    width: "25%",
                  },
                }}
                fullWidth
                IconComponent={(props) => (
                  <ExpandMore sx={{ fontSize: 20 }} {...props} />
                )}
                error={!!error?.message}
                {...field}
              >
                <MenuItem value="">parent customer</MenuItem>
              </Select>
            </FormControl>
          )}
          name="parentCustomer"
          control={control}
        /> */}
      </Grid>

      {!!parentCustomerId && (
        <Grid mt={1} xs={12} item>
          <Controller
            render={({ field }) => (
              <LargeCheckbox
                adminIsAllowed
                size={16}
                formSx={{ pl: 0.4 }}
                label={
                  <Typography
                    variant="caption"
                    sx={{
                      fontSize: 13,
                      fontWeight: 400,
                      color: "#5F6267",
                      letterSpacing: "normal",
                    }}
                  >
                    Bill parent group
                  </Typography>
                }
                {...field}
                checked={!!field.value}
              />
            )}
            name="billParentGroup"
            control={control}
          />
        </Grid>
      )}
    </Grid>
  );
};
