export const cl = {
  paper: {
    borderWidth: "0 1px 1px 1px",
    borderStyle: "solid",
    borderColor: "#D5D9D9",
    position: "relative",
    height: "52px",
    ":hover": {
      backgroundColor: "#F7F7F7",
    },
  },
  gridLeft: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  gridCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    fontSize: "12px",
    color: "#1C1C19",
  },
};
