import { number, string } from "prop-types";
import { Chip } from "@mui/material";
import pluralize from "pluralize";

export const ChipComponent = ({ name, count, ...props }) => {
  return (
    <Chip
      sx={{
        height: "15px",
        fontSize: "13px",
        fontWeight: 300,
      }}
      label={pluralize(name, count, true)}
      {...props}
    />
  );
};

ChipComponent.propTypes = {
  name: string,
  count: number,
};
ChipComponent.defaultProps = {
  name: "item",
  count: 0,
};
