import { string, func, bool, any } from "prop-types";
import { Button, Typography } from "@mui/material";
import { PhotoCameraIcon } from "../Icons";
import { useAdmin } from "helpers/helpers";

const UploadFiles = ({
  label,
  accept,
  onChange,
  startIcon,
  disabled,
  adminIsAllowed,
  ...props
}) => {
  const isAdmin = useAdmin(adminIsAllowed);

  return (
    <Button
      sx={{
        marginLeft: 2,
        border: "1px solid #D5D9D9",
        borderRadius: "8px",
        height: startIcon ? "38px" : "24px",
        background: startIcon ? "#fffff" : "#F2F2F2",
      }}
      disabled={isAdmin || disabled}
      variant="outlined"
      color="uploadBtn"
      component="label"
      startIcon={
        startIcon ? (
          startIcon
        ) : (
          <PhotoCameraIcon width={16.79} height={14.53} fill="#707070" />
        )
      }
      data-testid="upload-files"
    >
      <Typography fontSize={12} color="#707070">
        {label}
      </Typography>
      <input
        type="file"
        hidden
        onChange={onChange}
        accept={accept}
        {...props}
      />
    </Button>
  );
};
UploadFiles.propTypes = {
  label: string,
  accept: string,
  onChange: func,
  disabled: bool,
  startIcon: any,
  adminIsAllowed: bool,
};
UploadFiles.defaultProps = {
  label: "Upload",
  accept: "image/png, image/gif, image/jpeg",
  onChange: () => {},
  adminIsAllowed: false,
};

export { UploadFiles };
