import { useMemo } from "react";
import { usePersonalDetails } from "./components/SettingsTabs/PaymentsTab/components/PayoutsTab/PayoutsTab.hooks";
import { useRepsPermissions } from "helpers/hooks";

export const useSettings = () => {
  const { allowedToAddCard, getPayoutWarningText } = usePersonalDetails({
    isSetup: false,
  });

  const TAB_NAMES = useMemo(
    () => [
      "Profile",
      "Payments",
      "Representatives",
      "Orders",
      "Inventory",
      "Payment Terms",
      "Import/Export",
      "Integrations",
      "Order Direct",
      "Customers",
    ],
    []
  );

  const repPermissions = useRepsPermissions();

  const SETTINGS_TABS = useMemo(
    () => [
      {
        value: TAB_NAMES[0],
        label: TAB_NAMES[0],
        disabled: repPermissions ? !repPermissions?.settings?.profile : false,
        showTooltip: false,
        tooltipText: "",
      },
      {
        value: TAB_NAMES[1],
        label: TAB_NAMES[1],
        disabled: repPermissions ? !repPermissions?.settings?.payments : false,
        showTooltip: false,
        tooltipText: "",
      },
      {
        value: TAB_NAMES[2],
        label: TAB_NAMES[2],
        disabled: repPermissions
          ? !repPermissions?.settings?.representatives
          : false,
        showTooltip: false,
        tooltipText: "",
      },
      {
        value: TAB_NAMES[3],
        label: TAB_NAMES[3],
        disabled: repPermissions ? !repPermissions?.settings?.orders : false,
        showTooltip: false,
        tooltipText: "",
      },
      {
        value: TAB_NAMES[4],
        label: TAB_NAMES[4],
        disabled: repPermissions ? !repPermissions?.settings?.inventory : false,
        showTooltip: false,
        tooltipText: "",
      },
      {
        value: TAB_NAMES[5],
        label: TAB_NAMES[5],
        disabled: repPermissions
          ? !repPermissions?.settings?.payment_terms
          : false,
        showTooltip: false,
        tooltipText: "",
      },
      {
        value: TAB_NAMES[6],
        label: TAB_NAMES[6],
        // disabled: repPermissions
        //   ? !repPermissions?.settings?.import_export
        //   : false,
        disabled: false,
        showTooltip: false,
        tooltipText: "",
      },
      {
        value: TAB_NAMES[7],
        label: TAB_NAMES[7],
        disabled: repPermissions
          ? !repPermissions?.settings?.integrations
          : false,
        showTooltip: false,
        tooltipText: "",
      },
      {
        value: TAB_NAMES[8],
        label: TAB_NAMES[8],
        disabled: repPermissions
          ? !repPermissions?.settings?.order_direct
          : !allowedToAddCard,
        showTooltip: repPermissions ? false : !allowedToAddCard,
        tooltipText: getPayoutWarningText(),
      },
      {
        value: TAB_NAMES[9],
        label: TAB_NAMES[9],
        disabled: repPermissions ? !repPermissions?.settings?.customers : false,
        showTooltip: false,
        tooltipText: "",
      },
    ],
    [TAB_NAMES, allowedToAddCard, getPayoutWarningText, repPermissions]
  );

  const getLocationError = (field, formField) => {
    const parentField = field.split(".");

    if (!field || (parentField && !formField[parentField[[0]]].street))
      return "field is required";
    return "";
  };

  return { SETTINGS_TABS, getLocationError };
};
