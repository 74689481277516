import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  customTooltip: {
    backgroundColor: "#ffffff",
    border: "0.5px solid #D5D9D9",
    p: "2px 20px 4px 20px",
    borderRadius: "4px",
    textAlign: "center",
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
    color: "#1C1C19",
    marginBottom: "0!important",
  },
  customArrow: {
    width: "7px",
    height: "10px",
    left: "45%",
    bottom: "-8px",
    zIndex: 1,
    color: "#fff",
    backgroundColor: "#ffffff",
    clipPath: "polygon(100% 7%, 0% 7%, 50% 100%)",
    position: "absolute",
  },
}));

export const cl = {
  wrapper: {
    width: "778px",
    maxWidth: "778px",
  },
  title: {
    borderBottom: "1px solid #D4D4D4",
    display: "flex",
    height: "78px",
    textWrapper: {
      display: "flex",
      alignItems: "center",
      pl: 5,
      gap: 1,
    },
    crossWrapper: {
      flexGrow: 1,
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-start",
      pt: 2,
      pr: 2,
    },
    text: {
      fontSize: 30,
      color: "#707070",
    },
    iconBtn: {
      border: "1px solid #D4D4D4",
      width: "28px",
      height: "28px",
      borderRadius: "4px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "#6A6A6A",
    },
  },
  addresses: {
    borderBottom: "1px solid #D4D4D4",
    display: "flex",
    pt: 2,
    pb: 3.3,

    billing: {
      width: "31%",
      pl: 5,
      borderRight: "1px solid #D4D4D4",
    },
    shipping: {
      width: "33.3%",
      pl: 7,
      borderRight: "1px solid #D4D4D4",
    },
    delivery: {
      width: "33.3%",
      pl: 6,
    },
    title: {
      fontSize: 12,
      fontWeight: 500,
      color: "#000",
      textDecoration: "underline",
      mb: 1,
    },
    name: {
      fontSize: 12,
      color: "#000",
    },
    info: {
      fontSize: 12,
      color: "#000000",
      fontWeight: 300,
      width: "90%",
      mb: 1,
      whiteSpace: "pre-line",
    },
    selectWrapper: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      height: "100%",
      flexDirection: "column",
      marginTop: 1,
      mr: 3,
    },
    select: {
      left: 0,
      height: "24px",
      "& .MuiFormLabel-root": {
        color: "#B5B5AC",
        fontSize: "12px",
        top: "2px",
      },
      "& .MuiOutlinedInput-root": {
        height: "100%",
        padding: "0 11px !important",
      },
      justifyContent: "space-between",
      "& .MuiOutlinedInput-input": {
        padding: "0 8px !important",
      },
      "& .MuiSvgIcon-root": {
        right: "4px",
      },
    },
  },
  details: {
    display: "flex",
    pt: 2,
    pb: 3.3,

    leftWrapper: {
      width: "62%",
      display: "flex",
    },
    block: { width: "43%", pl: 5 },
    rightWrapper: { width: "38%" },
    settings: {
      border: "1px solid #D5D9D9",
      borderRadius: "4px",
      backgroundColor: "#EEEEEE",
      width: "100%",
      height: "30px",
      display: "flex",
      alignItems: "center",
      pl: 1,
    },
    selectsWrapper: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      gap: 2,
      height: "100%",
      flexDirection: "column",
      mr: 3,
    },
    select: {
      left: 0,
      height: "30px",
      "& .MuiFormLabel-root": {
        color: "#B5B5AC",
        fontSize: "14px",
        fontWeight: 300,
        top: "2px",
      },
      "& .MuiOutlinedInput-root": {
        height: "100%",
      },
      justifyContent: "space-between",
      "& .MuiOutlinedInput-input": {
        padding: "0 8px !important",
        paddingLeft: "8px !important",
      },
      "& .MuiSvgIcon-root": {
        right: "4px",
      },
    },
    notes: {
      fontSize: 12,
      paddingTop: 10,
      paddingBottom: 11,
      border: {
        "& fieldset": { border: "1px solid #D5D9D9" },
      },
    },
    btnWrapper: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
    },
    btn: {
      width: "125px",
      maxHeight: "34px",
      fontSize: 13,
      fontWeight: 600,
    },
    name: {
      fontSize: 12,
      color: "#000000",
    },
    info: {
      fontSize: 12,
      color: "#000000",
      fontWeight: 300,
      width: "90%",
    },
    iconBtn: {
      display: "flex",
      alignItems: "center",
    },
  },
};
