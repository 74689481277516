import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({

  repsHeader: {
    padding: "24px 0 0 34px",
  },

  repsBody: {
    padding: "18px 76px",
    maxHeight: "100%",
    // overflow: "auto",
  },

  buttonBlock: {
    padding: "15px 28px",
    backgroundColor: "#F7F7F7",
    boxShadow: "none",
    marginBottom: "9px",
  },

  blockTitle: {
    fontSize: "20px",
    marginBottom: "13px",
    color: "#707070",
  },

  dayButton: {
    height: "30px",
    fontSize: "9px",
    minWidth: "48px",
    borderColor: "#707070",
  },

  switchLabel: {
    color: "#5F6267",
    fontWeight: 400,
    fontSize: "14px",
  },
}));

export default useStyles;
