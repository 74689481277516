import * as Yup from "yup";

export const validationSchema = ({ hasAnyAttachment }) =>
  Yup.object().shape({
    licenseNumber: Yup.string()
      .trim()
      .test(
        "licenseNumberValidation",
        "License number is missing!",
        (value) => {
          if (hasAnyAttachment) return true;
          return !!value;
        }
      ),

    licenseOtherName: Yup.string()
      .trim()
      .when("licenseName", {
        is: "Other",
        then: Yup.string().trim().required("This field is required"),
      }),
  });
