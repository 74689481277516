/* eslint-disable react-hooks/rules-of-hooks */
import {
  bool,
  func,
  object,
  any,
  number,
  array,
  string,
  oneOfType,
} from "prop-types";
import { useSelector } from "react-redux";
import moment from "moment";
import { Typography, Box } from "@mui/material";
import { StyledButton, TypographyAddress } from "components";
import { SimplyDepoFooterIcon } from "components/Icons";
import { getFormattedDate, photoUrl } from "helpers/helpers";
import React, { useMemo } from "react";
import RenderProductBox from "../../RenderProductBox";
import RenderProductBoxWithFreeCases from "../RenderProductBoxWithFreeCases";

export const PreviewEmailBlock = ({
  order,
  isEdit,
  isDuplicate,
  totalDiscount,
  deliveryFee,
  grandTotal,
  calculatedTotal,
  oldOrder,
  freeCaseList,
  formDate,
  orderCustomId,
  isThirdPartyOrder,
  showPaymentLinkCheckbox,
  dueDate,
  formattedDueDate,
  formattedDate,
  invoiceLink,
  cleanText,
  hasCreatedOrderThirdParty,
  type,
  createOrderType,
  customer,
  defaultContact,
  hasShowPrices,
}) => {
  const distributor = useSelector(({ auth }) => auth.currentUser);
  const { timeZone } = distributor || {};
  const repsList = useSelector(({ reps }) => reps.reps);
  const rep = repsList?.find((r) => r?.id === order?.salesId);

  const orderProductIds = order?.products?.map((product) => product.id) || [];

  const missingProducts =
    oldOrder?.products?.filter(
      (oldProduct) => !orderProductIds.includes(oldProduct.id)
    ) || [];

  const formattedProducts = useMemo(() => {
    const num = grandTotal || order?.balance || 0;

    return typeof num === "number" ? num.toFixed(2) : num;
  }, [grandTotal, order?.balance]);

  const handleInvoiceLink = () => {
    if (invoiceLink) {
      window.open(invoiceLink, "_blank");
    }
  };

  const newProducts = order?.products?.filter(
    (product) =>
      !oldOrder?.products?.some((oldProduct) => oldProduct.id === product.id)
  );

  const renderedFreeCaseBlock = () => {
    const freeCases = freeCaseList
      ?.filter(
        ({ id }) =>
          !order?.products?.some((p) => p.id === id || p.product?.id === id)
      )
      ?.sort((a, b) => a?.name?.localeCompare(b?.name))
      ?.map((product, index) => (
        <RenderProductBox
          key={product.id}
          product={product}
          oldOrder={oldOrder}
          isEdit={isEdit}
          isDuplicate={isDuplicate}
          order={order}
          index={index}
        />
      ));

    return freeCases;
  };

  const setBillingAddress = (order) => {
    if (order?.customer?.billToParentCustomer) {
      return order?.customer?.parentCustomer?.billingAddress?.formatted_address;
    }

    return (
      order?.customer?.billingAddress?.formatted_address ||
      customer?.billingAddress?.formatted_address
    );
  };

  return (
    <Box
      sx={{
        border: "0.5px solid #D5D9D9",
        borderRadius: "4px",
        width: "526px",
        padding: "24px 13px",
        height: "calc(100vh - 214px)",
        overflow: "scroll",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "baseline",
          margin: "0 27px",
        }}
      >
        {!order?.type ? (
          <>
            <Typography sx={{ fontSize: "14px", fontWeight: "700" }}>
              {orderCustomId}
            </Typography>
            {oldOrder?.createdAt ? (
              <Typography>
                {moment(getFormattedDate(oldOrder?.createdAt, timeZone)).format(
                  formDate
                )}
              </Typography>
            ) : null}
          </>
        ) : null}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          //mt: "40px",
        }}
      >
        {distributor.profilePhoto && (
          <Box>
            <Box
              width="112px"
              height="112px"
              marginTop="5px"
              borderRadius="50%"
              component="img"
              src={photoUrl(distributor.profilePhoto?.fileName)}
            />
          </Box>
        )}
        {isEdit && isThirdPartyOrder && !isDuplicate ? (
          <Typography
            sx={{
              color: "#343434",
              fontWeight: 700,
              fontSize: "32px",
              textAlign: "center",
              m: "0 11px",
            }}
          >
            Your 3rd party order has been updated
          </Typography>
        ) : null}

        {showPaymentLinkCheckbox && (
          <Box
            sx={{
              background: "rgba(71,160,109,0.1)",
              width: "430px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              borderRadius: "24px",
              padding: "25px 32px 17px 32px",
              mt: "20px",
            }}
          >
            <Box
              sx={{
                borderBottom: "1px solid #47a06d",
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontSize: "13px", color: "#7a7a7a" }}>
                Invoice#
                {orderCustomId}
              </Typography>
              <Typography sx={{ fontSize: "13px", fontWeight: "500" }}>
                {!isEdit || isDuplicate
                  ? ""
                  : `${dueDate > 0 ? `Due ${formattedDueDate}` : formattedDate} 
                        `}
              </Typography>
            </Box>
            <Typography
              sx={{ fontSize: "30px", fontWeight: "700", mt: "15px" }}
            >
              ${formattedProducts}
            </Typography>
            <StyledButton
              sx={{
                "&:hover": {
                  background: "#47a06d",
                },
                margin: "15px 0",
                width: "129px",
                height: "34px",
                background: "#47a06d",
                color: "#ffffff",
                border: 0,
                borderRadius: "2px",
                fontSize: "13px",
                fontWeight: 600,
              }}
              label="Review and pay"
              fontSize="13px"
              onClick={handleInvoiceLink}
            />
          </Box>
        )}
      </Box>
      <Box
        sx={{
          borderRadius: "24px;",
          width: "430px",
          ml: "29px",
          mt: "40px",
        }}
      >
        <Box sx={{ marginLeft: "30px", margin: "25px 30px 0 30px" }}>
          <Typography mt="15px">
            {cleanText?.split("\n")?.map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </Typography>
        </Box>
        <Box
          sx={{
            mt: "15px",
            border: "1px solid #efefef",
            padding: "20px 28px",
            borderRadius: "24px",
            //width: "fit-content",
          }}
        >
          <Typography sx={{ fontSize: "20px", fontWeight: 700 }}>
            Items
          </Typography>

          <Box>
            {order?.products
              ?.filter(
                (product) =>
                  !newProducts?.some(
                    (newProduct) => newProduct?.id === product?.id
                  )
              )
              ?.map((product, index) => (
                <RenderProductBoxWithFreeCases
                  key={product?.id}
                  {...{
                    product,
                    index,
                    oldOrder,
                    isEdit,
                    isDuplicate,
                    order,
                    freeCaseList,
                    showPrice: hasShowPrices,
                  }}
                />
              ))}
            {isEdit && renderedFreeCaseBlock()}
            {missingProducts?.map((product, index) => (
              <RenderProductBox
                key={product?.id}
                product={product}
                oldOrder={oldOrder}
                isEdit={isEdit}
                isDuplicate={isDuplicate}
                order={order}
                index={index}
                missingProducts={missingProducts}
                isDeletedProduct
                showPrice={hasShowPrices}
              />
            ))}
            {newProducts?.map((product, index) => (
              <RenderProductBoxWithFreeCases
                key={product?.id}
                {...{
                  product,
                  index,
                  oldOrder,
                  isEdit,
                  isDuplicate,
                  order,
                  newProducts,
                  freeCaseList,
                  showPrice: hasShowPrices,
                }}
              />
            ))}
            {!isEdit && renderedFreeCaseBlock()}
          </Box>

          {hasShowPrices && (
            <Box
              sx={{
                mb: "15px",
                pb: "15px",
                mt: "15px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{ fontSize: "16px", fontWeight: 700, mb: "5px" }}
                >
                  Subtotal:
                </Typography>

                {!isEdit ||
                isDuplicate ||
                oldOrder?.totalRawAmount === calculatedTotal ? (
                  <Typography sx={{ fontSize: "16px", fontWeight: 700 }}>
                    $
                    {typeof calculatedTotal === "number"
                      ? (calculatedTotal || 0).toFixed(2)
                      : calculatedTotal}
                  </Typography>
                ) : (
                  <Box display="flex">
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 700,
                        color: "#939393",
                        textDecoration: "line-through",
                        mr: "8px",
                        mt: "5px",
                      }}
                    >
                      ${(oldOrder?.totalRawAmount || 0).toFixed(2)}
                    </Typography>
                    <Typography sx={{ fontSize: "16px", fontWeight: 700 }}>
                      ${(calculatedTotal || 0).toFixed(2)}
                    </Typography>
                  </Box>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{ fontSize: "16px", fontWeight: 700, mb: "5px" }}
                >
                  Discount:
                </Typography>

                {!isEdit ||
                isDuplicate ||
                oldOrder?.totalDiscountValue === totalDiscount ? (
                  <Typography sx={{ fontSize: "16px", fontWeight: 700 }}>
                    ($
                    {typeof totalDiscount === "number"
                      ? totalDiscount?.toFixed(2)
                      : totalDiscount}
                    )
                  </Typography>
                ) : (
                  <Box display="flex">
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 700,
                        color: "#939393",
                        textDecoration: "line-through",
                        mr: "7px",
                        mt: "5px",
                      }}
                    >
                      ${oldOrder?.totalDiscountValue}
                    </Typography>
                    <Typography sx={{ fontSize: "16px", fontWeight: 700 }}>
                      (${totalDiscount})
                    </Typography>
                  </Box>
                )}
              </Box>
              {!isThirdPartyOrder ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: 700, mb: "5px" }}
                  >
                    Shipping:
                  </Typography>
                  {!isEdit ||
                  isDuplicate ||
                  oldOrder?.deliveryFee === deliveryFee ? (
                    <Typography sx={{ fontSize: "16px", fontWeight: 700 }}>
                      ${deliveryFee === "" ? "Free" : deliveryFee}
                    </Typography>
                  ) : (
                    <Box display="flex">
                      <Typography
                        sx={{
                          color: "#939393",
                          textDecoration: "line-through",
                          fontWeight: 700,
                          mr: "8px",
                          mt: "5px",
                          fontSize: "12px",
                        }}
                      >
                        ${deliveryFee === "" ? "Free" : oldOrder?.deliveryFee}
                      </Typography>
                      <Typography sx={{ fontSize: "16px", fontWeight: 700 }}>
                        ${deliveryFee === "" ? "Free" : deliveryFee}
                      </Typography>
                    </Box>
                  )}
                </Box>
              ) : null}
            </Box>
          )}

          {hasShowPrices && (
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: 700,
                  color: "#409a65",
                }}
              >
                Total:
              </Typography>
              {isDuplicate ||
              oldOrder?.totalAmount === grandTotal ||
              !isEdit ? (
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: 700,
                    color: "#409a65",
                  }}
                >
                  $
                  {typeof grandTotal === "number"
                    ? (grandTotal || 0).toFixed(2)
                    : grandTotal}
                </Typography>
              ) : (
                <Box sx={{ display: "flex" }}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 700,
                      color: "#939393",
                      textDecoration: "line-through",
                      mr: "8px",
                      mt: "4px",
                    }}
                  >
                    $
                    {typeof oldOrder?.totalAmount === "number"
                      ? (oldOrder?.totalAmount || 0).toFixed(2)
                      : oldOrder?.totalAmount}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "20px",
                      fontWeight: 700,
                      color: "#409a65",
                    }}
                  >
                    $
                    {typeof grandTotal === "number"
                      ? (grandTotal || 0).toFixed(2)
                      : grandTotal}
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        </Box>
        <Box
          sx={{
            width: "430px",
            padding: "25px 0 17px 0",
          }}
        >
          {!hasCreatedOrderThirdParty && (
            <Box>
              <Typography
                sx={{
                  ml: "29px",
                  fontWeight: "700",
                }}
              >
                Terms:
              </Typography>
              <Typography sx={{ ml: "29px", fontWeight: "500" }}>
                {order?.paymentTermsDuplicate?.name ||
                  order?.paymentTerms?.name}
              </Typography>
            </Box>
          )}
          <Box>
            <Typography sx={{ ml: "29px", fontWeight: "700", mt: "30px" }}>
              Shipping address
            </Typography>
            <Box sx={{ ml: "29px", fontWeight: "600" }}>
              <TypographyAddress
                styles={{
                  color: "#000000",
                  fontSize: "14px",
                  fontWeight: 400,
                }}
                address={order?.customer?.shippingAddress?.formatted_address}
              />
            </Box>
          </Box>

          {type || createOrderType !== "THIRD_PARTY" ? (
            <Box>
              <Typography sx={{ ml: "29px", fontWeight: "700", mt: "20px" }}>
                Billing address
              </Typography>
              <Box sx={{ ml: "29px", fontWeight: "500" }}>
                <TypographyAddress
                  styles={{
                    color: "#000000",
                    fontSize: "14px",
                    fontWeight: 400,
                  }}
                  address={setBillingAddress(order)}
                />
              </Box>
            </Box>
          ) : (
            <Box>
              {order?.fulfillBy ? (
                <>
                  <Typography
                    sx={{ ml: "29px", fontWeight: "700", mt: "20px" }}
                  >
                    Requested ship date
                  </Typography>
                  <Box sx={{ ml: "29px", fontWeight: "500" }}>
                    {moment(
                      getFormattedDate(order?.fulfillBy, timeZone)
                    ).format("MMM D, YYYY")}
                  </Box>
                </>
              ) : null}
              <Typography sx={{ ml: "29px", fontWeight: "700", mt: "20px" }}>
                Contact
              </Typography>
              <Box
                sx={{
                  ml: "29px",
                  fontWeight: "500",
                  color: "#000000",
                  fontSize: "14px",
                }}
              >
                <Typography>{defaultContact?.name}</Typography>
                <Typography>{defaultContact?.email}</Typography>
                <Typography>{defaultContact?.phone}</Typography>
              </Box>
              <Typography sx={{ ml: "29px", fontWeight: "700", mt: "20px" }}>
                Created by
              </Typography>
              <Box sx={{ ml: "29px", fontWeight: "500" }}>
                {rep?.name || distributor?.name}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          width: "430px",
          ml: "29px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Typography
          sx={{ border: "1px solid #efefef", m: "20px 0", width: "100%" }}
        ></Typography>
        <Box sx={{ display: "flex", alignItems: "center", mt: "15px" }}>
          <Typography sx={{ fontSize: "10px", mr: "5px" }}>
            {" "}
            Powered by
          </Typography>
          <SimplyDepoFooterIcon />
        </Box>
      </Box>
    </Box>
  );
};

PreviewEmailBlock.propTypes = {
  isOpen: bool,
  handleClose: func,
  order: object,
  handleSendEmail: func,
  isEdit: bool,
  isDraft: bool,
  isDuplicate: bool,
  totalDiscount: oneOfType([number, string]),
  deliveryFee: oneOfType([number, string]),
  grandTotal: oneOfType([number, string]),
  calculatedTotal: oneOfType([number, string]),
  customer: object,
  loading: bool,
  selectedContactEmail: any,
  oldOrder: object,
  freeCaseList: array,
  formDate: string,
  orderCustomId: string,
  isThirdPartyOrder: bool,
  showPaymentLinkCheckbox: bool,
  hasCreatedOrderThirdParty: bool,
  dueDate: string,
  formattedDueDate: string,
  formattedDate: string,
  invoiceLink: string,
  cleanText: string,
  type: string,
  defaultContact: object,
  createOrderType: string,
  hasShowPrices: bool,
};
