import axios from "axios";

export const getPaytermsService = async (params) => {
  return axios.get("/payment-terms", { params }).then((res) => res.data);
};

export const createPaytermService = async (data) => {
  return axios.post("/payment-terms", data).then((res) => res.data);
};

export const deletePaytermService = async (id) => {
  return axios.delete(`/payment-terms/${id}`).then((res) => res.data);
};

export const updatePaytermService = async (id, data) => {
  return axios.put(`/payment-terms/${id}`, data).then((res) => res.data);
};
