import { string, number, shape } from "prop-types";
import { Box, Typography } from "@mui/material";

export const CardPlanItem = ({ type, name, amount, state }) => {
  return (
    <Box
      sx={{
        borderBottom: type === "Current plan" && "0.5px solid #D5D9D9",
        height: "74px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Box>
        <Typography fontSize={14} fontWeight={400} color="#9A9A9A">
          {type}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          fontSize={16}
          fontWeight={600}
          color={type === "Current plan" ? "#9A9A9A" : "#47A06D"}
        >
          {name}
        </Typography>
        <Typography
          fontSize={16}
          fontWeight={600}
          color={type === "Current plan" ? "#9A9A9A" : "#47A06D"}
        >
          ${amount}{" "}
          <Box fontSize={16} fontWeight={300} color="#9A9A9A" component="span">
            / month
          </Box>
        </Typography>
      </Box>
      {!!state?.extra_user_count && type === "New plan" && (
        <Typography fontSize={16} fontWeight={600} color="#5F6267">
          + {state?.extra_user_count} extra users
        </Typography>
      )}
    </Box>
  );
};

CardPlanItem.propTypes = {
  type: string,
  name: string,
  amount: number,
  state: shape({
    extra_user: number,
    current_plan: shape({
      subscription: shape({
        name: string,
      }),
    }),
  }),
};
