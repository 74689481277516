import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({

  titleBlock: {
    padding: "24px 42px 19px 34px",
    borderBottom: "1px solid #d5d9d9",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  contentBlock: {
    padding: "19px 55px",
  },

  integrationCardsWrapper: {
    marginTop: "19px",
    display: "grid",
    gap: "41px",
    gridTemplateColumns: "repeat(auto-fill, 280px)",
  },
}));

export default useStyles;
