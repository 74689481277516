import { bool, func, object } from "prop-types";

import { StyledButton } from "components";
import { CrossIcon } from "components/Icons";
import { RoutesList } from "./components/RoutesList";

import useStyles from "./styles";

import {
  Box,
  CircularProgress,
  Drawer,
  IconButton,
  Typography,
} from "@mui/material";

export const MapRoutesDrawer = ({
  open,
  loading,
  onClose,
  onSubmit,
  routesList,
  onRouteChange,
  handleUnassignAllRoutes,
}) => {
  const classes = useStyles();

  return (
    <Drawer open={open} onClose={onClose} anchor="right">
      <Box className={classes.wrapper}>
        <Box className={classes.header}>
          <Typography variant="h6" fontWeight={500}>
            Routes
          </Typography>
          {loading && <CircularProgress size={23} sx={{ mr: "auto" }} />}
          <IconButton onClick={onClose}>
            <CrossIcon />
          </IconButton>
        </Box>

        <Box className={classes.bodyWrapper}>
          <RoutesList
            title="Assigned Routes"
            list={routesList.assigned}
            stateKey="assigned"
            {...{ onRouteChange }}
          />
          <RoutesList
            title="Available Routes"
            list={routesList.available}
            stateKey="available"
            {...{ onRouteChange }}
          />
        </Box>

        <Box className={classes.footer}>
          <StyledButton
            disabled={loading || routesList.assigned.length < 1}
            label="Unassign All Routes"
            variant="contained"
            color="confirmDelete"
            fontSize="15px"
            onClick={() => handleUnassignAllRoutes()}
          />
          <Box display="flex" gap="11px" ml="auto">
            <StyledButton
              disabled={loading}
              label="Cancel"
              color="cancel"
              fontSize="15px"
              onClick={onClose}
            />

            <StyledButton
              disabled={loading}
              label="Update"
              variant="contained"
              fontSize="15px"
              onClick={onSubmit}
            />
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

MapRoutesDrawer.propTypes = {
  open: bool,
  loading: bool,
  onClose: func,
  onSubmit: func,
  routesList: object,
  onRouteChange: func,
  handleUnassignAllRoutes: func,
};
