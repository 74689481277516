import { func, bool, object } from "prop-types";
import { Box, Stack } from "@mui/material";
import { StyledButton } from "components";

export const FooterNoteDrawer = ({
  editNote,
  disabledBtns,
  onCancel,
  onConfirm,
  viewOnly,
  onClose,
  handleDelete,
  isNoteView,
  isAdminEdit,
  loading,
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: viewOnly ? "87px" : "67px",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        borderTop: "1px solid #D5D9D9",
        backgroundColor: "#F8F8F8",
        pl: 4,
        pr: 2,
      }}
    >
      {viewOnly ? (
        <Stack direction="row" justifyContent="space-between" width="100%">
          {(isAdminEdit || isNoteView) && (
            <StyledButton
              disabled={loading}
              label="Delete"
              color="error"
              variant="contained"
              sx={{ height: "31px", minWidth: "78px" }}
              fontSize="15px"
              onClick={handleDelete}
            />
          )}

          <Box display="flex" gap="11px" ml="auto">
            {!isNoteView && (
              <StyledButton
                disabled={loading}
                label="Cancel"
                color="cancel"
                fontSize="15px"
                sx={{ height: "31px", minWidth: "78px" }}
                onClick={onClose}
              />
            )}
            <StyledButton
              disabled={loading || disabledBtns}
              label="Done"
              variant="contained"
              fontSize="15px"
              sx={{ height: "31px", minWidth: "78px" }}
              onClick={onConfirm}
            />
          </Box>
        </Stack>
      ) : (
        <>
          <StyledButton
            disabled={disabledBtns}
            label={"Cancel"}
            sx={{ height: "31px", mr: 1, minWidth: "78px" }}
            color="cancel"
            onClick={onCancel}
            fontWeight="600"
            fontSize="15px"
          />
          <StyledButton
            disabled={disabledBtns}
            label={editNote ? "Done" : "Add"}
            variant="contained"
            sx={{ height: "31px", minWidth: "78px" }}
            fontWeight="600"
            fontSize="15px"
            onClick={onConfirm}
          />
        </>
      )}
    </Box>
  );
};

FooterNoteDrawer.propTypes = {
  editNote: object,
  onCancel: func,
  onConfirm: func,
  onClose: func,
  disabledBtns: bool,
  viewOnly: bool,
  isNoteView: bool,
  isAdminEdit: bool,
  handleDelete: func,
  loading: bool,
};
FooterNoteDrawer.defaultProps = {
  onCancel: () => {},
  onConfirm: () => {},
  handleDelete: () => {},
  onClose: () => {},
  disabledBtns: true,
  isNoteView: false,
  isAdminEdit: false,
  viewOnly: false,
  loading: false,
};
