export const EmptyProductsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="90.969"
      height="138.025"
      viewBox="0 0 90.969 138.025"
    >
      <defs>
        <filter
          id="Path_5047"
          x="2.198"
          y="0"
          width="88.771"
          height="138.025"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dx="3" dy="-2" />
          <feGaussianBlur stdDeviation="4" result="blur" />
          <feFlood floodOpacity="0.039" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g
        id="Group_7468"
        data-name="Group 7468"
        transform="translate(-6211.266 -19320.475)"
      >
        <g
          transform="matrix(1, 0, 0, 1, 6211.27, 19320.47)"
          filter="url(#Path_5047)"
        >
          <path
            id="Path_5047-2"
            data-name="Path 5047"
            d="M303.957,164.2H246.514c-1.747,0-3.164-3.362-3.164-7.509V58.689c0-4.147,1.417-7.509,3.164-7.509h57.443c1.747,0,3.164,3.362,3.164,7.509V156.7C307.121,160.843,305.7,164.2,303.957,164.2Z"
            transform="translate(-231.65 -36.68)"
            fill="#fff"
            stroke="#f5f5f5"
            strokeWidth="1"
          />
        </g>
        <rect
          id="Rectangle_11745"
          data-name="Rectangle 11745"
          width="66.469"
          height="15"
          rx="3"
          transform="translate(6221.266 19333.732)"
          fill="#e2f0e8"
        />
        <path
          id="Path_5050"
          data-name="Path 5050"
          d="M36.271,139.441a2.371,2.371,0,1,0-2.371-2.371A2.371,2.371,0,0,0,36.271,139.441Z"
          transform="translate(6194.436 19204.162)"
          fill="#bbb"
        />
        <path
          id="Path_5051"
          data-name="Path 5051"
          d="M84.46,139.441a2.371,2.371,0,1,0-2.371-2.371A2.371,2.371,0,0,0,84.46,139.441Z"
          transform="translate(6153.315 19204.162)"
          fill="#bbb"
        />
        <path
          id="Path_5052"
          data-name="Path 5052"
          d="M129.211,139.441a2.371,2.371,0,1,0-2.371-2.371A2.371,2.371,0,0,0,129.211,139.441Z"
          transform="translate(6115.13 19204.162)"
          fill="#7d7d7d"
        />
        <g
          id="Group_7464"
          data-name="Group 7464"
          transform="translate(6226.266 19355)"
        >
          <g
            id="Rectangle_11746"
            data-name="Rectangle 11746"
            fill="#fff"
            stroke="#f5f5f5"
            strokeWidth="1"
          >
            <rect width="58" height="23" rx="3" stroke="none" />
            <rect x="0.5" y="0.5" width="57" height="22" rx="2.5" fill="none" />
          </g>
          <path
            id="Path_5071"
            data-name="Path 5071"
            d="M942,262.515H927.652a2.332,2.332,0,0,1,0-4.665H942a2.332,2.332,0,1,1,0,4.665Z"
            transform="translate(-901.95 -252.179)"
            fill="#f5f5f5"
          />
          <path
            id="Path_5072"
            data-name="Path 5072"
            d="M952.26,331.854H927.652a2.332,2.332,0,0,1,0-4.665h24.609a2.332,2.332,0,1,1,0,4.665Z"
            transform="translate(-901.95 -314.056)"
            fill="#f5f5f5"
          />
          <rect
            id="Rectangle_11747"
            data-name="Rectangle 11747"
            width="16"
            height="16"
            rx="5"
            transform="translate(4 3)"
            fill="#409a65"
          />
        </g>
        <g
          id="Group_7466"
          data-name="Group 7466"
          transform="translate(6226.266 19409)"
        >
          <g
            id="Rectangle_11746-2"
            data-name="Rectangle 11746"
            fill="#fff"
            stroke="#f5f5f5"
            strokeWidth="1"
          >
            <rect width="58" height="23" rx="3" stroke="none" />
            <rect x="0.5" y="0.5" width="57" height="22" rx="2.5" fill="none" />
          </g>
          <path
            id="Path_5071-2"
            data-name="Path 5071"
            d="M942,262.515H927.652a2.332,2.332,0,0,1,0-4.665H942a2.332,2.332,0,1,1,0,4.665Z"
            transform="translate(-901.95 -252.179)"
            fill="#f5f5f5"
          />
          <path
            id="Path_5072-2"
            data-name="Path 5072"
            d="M952.26,331.854H927.652a2.332,2.332,0,0,1,0-4.665h24.609a2.332,2.332,0,1,1,0,4.665Z"
            transform="translate(-901.95 -314.056)"
            fill="#f5f5f5"
          />
          <rect
            id="Rectangle_11747-2"
            data-name="Rectangle 11747"
            width="16"
            height="16"
            rx="5"
            transform="translate(4 3)"
            fill="#e2f0e8"
          />
        </g>
        <g
          id="Group_7465"
          data-name="Group 7465"
          transform="translate(6211.266 19382)"
        >
          <g
            id="Rectangle_11746-3"
            data-name="Rectangle 11746"
            fill="#fff"
            stroke="#f5f5f5"
            strokeWidth="1"
          >
            <rect width="58" height="23" rx="3" stroke="none" />
            <rect x="0.5" y="0.5" width="57" height="22" rx="2.5" fill="none" />
          </g>
          <path
            id="Path_5071-3"
            data-name="Path 5071"
            d="M942,262.515H927.652a2.332,2.332,0,0,1,0-4.665H942a2.332,2.332,0,1,1,0,4.665Z"
            transform="translate(-901.95 -252.179)"
            fill="#f5f5f5"
          />
          <path
            id="Path_5072-3"
            data-name="Path 5072"
            d="M952.26,331.854H927.652a2.332,2.332,0,0,1,0-4.665h24.609a2.332,2.332,0,1,1,0,4.665Z"
            transform="translate(-901.95 -314.056)"
            fill="#f5f5f5"
          />
          <rect
            id="Rectangle_11747-3"
            data-name="Rectangle 11747"
            width="16"
            height="16"
            rx="5"
            transform="translate(4 3)"
            fill="#409a65"
            opacity="0.44"
          />
        </g>
        <path
          id="Path_5073"
          data-name="Path 5073"
          d="M6239.12,19442.688h29.791"
          transform="translate(1.251 2)"
          fill="none"
          stroke="#f5f5f5"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};
