import React from "react";

export const PeopleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="52"
      height="52"
      viewBox="0 0 52 52"
    >
      <rect
        id="Rectangle_8855"
        data-name="Rectangle 8855"
        width="52"
        height="52"
        rx="26"
        fill="#409a65"
        opacity="0.1"
      />
      <g
        id="Group_4900"
        data-name="Group 4900"
        transform="translate(-886.419 -16539)"
      >
        <path
          id="Path_2997"
          data-name="Path 2997"
          d="M58,10a4,4,0,1,0-4-4A4,4,0,0,0,58,10Z"
          transform="translate(854.419 16553)"
          fill="none"
          stroke="#409a65"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_2998"
          data-name="Path 2998"
          d="M63.082,22c2.761,0,5.083-2.42,3.52-4.7a10.749,10.749,0,0,0-17.2,0c-1.563,2.276.758,4.7,3.52,4.7Z"
          transform="translate(854.419 16553)"
          fill="none"
          stroke="#409a65"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
};
