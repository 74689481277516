import axios from "axios";

export const getTerritoryTreeService = async () => {
  return axios.get("/territories").then((res) => res.data);
};

export const getTerritoryListService = async (params) => {
  return axios.get("/territories/list", { params }).then((res) => res.data);
};

export const getCustomersByTerritoryService = async (territoryId) => {
  return axios
    .get(`/customers/by-territory?territoryId=${territoryId}`)
    .then((res) => res.data);
};

export const addTerritoryService = async (terData) => {
  return await axios.post("/territories", terData).then((res) => res.data);
};

export const deleteByIdWithTransferService = async (id, newTerritoryId) => {
  return axios
    .delete(`/territories/with-transfer/${id}`, {
      data: { newTerritoryId },
    })
    .then((res) => res.data);
};

export const createSingleTerritoreService = (name) => {
  return axios.post("/territories/single", { name }).then((res) => res.data);
};

export const getCountriesService = async (params) => {
  return axios.get("/lookup/countries", { params }).then((res) => res.data);
};
