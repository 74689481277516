import { number } from "prop-types";
import { Box, Typography } from "@mui/material";

export const FooterRegistration = ({ step }) => {
  return (
    <Box
      sx={{
        height: "60px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderTop: "0.5px solid #D5D9D9",
        bgcolor: "rgba(64, 154, 102, 0.15)",
        borderRadius: "0 0 15px 15px",
      }}
    >
      <Typography
        sx={{
          fontSize: 20,
          fontWeight: 500,
          color: "#47A06D",
        }}
      >
        {`Wholesale ${step === 1 ? "Selling" : ""} Made Simple`}
      </Typography>
    </Box>
  );
};

FooterRegistration.propTypes = {
  step: number,
};
FooterRegistration.defaultProps = {
  step: 1,
};
