import { useEffect, useMemo } from "react";
import { bool, func, object } from "prop-types";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema } from "./validations";

import { Box, Button, Dialog, IconButton, Typography } from "@mui/material";
import { CrossIcon } from "../../../../../../components/Icons";
import { StyledTextField } from "../../../../../../components/TextFields/TextFields";

export const CreateFolderDialog = ({ open, onClose, editData }) => {
  const { control, handleSubmit, reset } = useForm({
    mode: "onChange",
    defaultValues: { name: editData?.fileName || "" },
    resolver: yupResolver(validationSchema()),
  });

  useEffect(() => {
    if (open) reset({ name: editData?.fileName || "" });
  }, [editData?.fileName, open, reset]);

  const getTitle = useMemo(() => {
    if (!editData) return "Create folder";
    if (editData && editData?.type === "folder") return "Rename folder";
    if (editData && editData?.type !== "folder") return "Rename file";
  }, [editData]);

  const getTitleTextField = useMemo(() => {
    if (!editData) return "Folder name";
    if (editData && editData?.type === "folder") return "New foldername:";
    if (editData && editData?.type !== "folder") return "New filename:";
  }, [editData]);

  const getPlaceholder = useMemo(() => {
    if (!editData) return "Folder name";
    if (editData && editData?.type === "folder") return "New foldername";
    if (editData && editData?.type !== "folder") return "New filename";
  }, [editData]);

  const onSubmit = (data) => {
    // eslint-disable-next-line no-console
    console.log("data", data);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Box
        sx={{
          width: "424px",
          height: "56px",
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "0.5px solid #CCCCCC",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            pl: "28px",
          }}
        >
          <Typography
            sx={{
              fontSize: 20,
              color: "#080808",
            }}
          >
            {getTitle}
          </Typography>
        </Box>
        <Box>
          <IconButton onClick={onClose}>
            <CrossIcon />
          </IconButton>
        </Box>
      </Box>

      <Box pt="8px" px="34px">
        <Box mb="6px">
          <Typography
            sx={{
              fontSize: 12,
              color: "#363531",
            }}
          >
            {getTitleTextField}
          </Typography>
        </Box>
        <Box>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                sx={{
                  "& .MuiInputBase-input": {
                    fontSize: 13,
                    fontWeight: 300,
                  },
                }}
                placeholder={getPlaceholder}
                fullWidth
                size="small"
                InputProps={{ sx: { maxHeight: 34 } }}
                error={!!error?.message}
                {...field}
              />
            )}
            name="name"
            control={control}
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: 1,
          p: "18px 34px 24px 34px",
        }}
      >
        <Button
          sx={{
            fontSize: 13,
            width: "85px",
            height: "28px",
          }}
          variant="outlined"
          color="cancel"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          sx={{
            fontSize: 13,
            width: "85px",
            height: "28px",
          }}
          variant="contained"
          onClick={handleSubmit(onSubmit)}
        >
          {editData ? "Confirm" : "Create"}
        </Button>
      </Box>
    </Dialog>
  );
};

CreateFolderDialog.propTypes = { open: bool, onClose: func, editData: object };
CreateFolderDialog.defaultProps = {};
