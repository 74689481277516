export const DiscountBreakdownIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.069"
      height="16.778"
      viewBox="0 0 18.069 16.778"
    >
      <path
        id="Path_2556"
        data-name="Path 2556"
        d="M24.7,7.523V13.21l-8.582,8.215a1.838,1.838,0,0,0,1.162.5,2.009,2.009,0,0,0,1.266-.5l7.687-7.247V9.016Z"
        transform="translate(-8.162 -5.144)"
        fill="#5f6267"
      />
      <path
        id="Path_2557"
        data-name="Path 2557"
        d="M16.446,3.375H9.993L2.746,10.941a1.651,1.651,0,0,0-.5,1.145,1.764,1.764,0,0,0,.5,1.287l4.993,4.985a1.851,1.851,0,0,0,1.162.5,2.013,2.013,0,0,0,1.262-.5l7.574-7.239V4.666ZM15.208,7.517a1.292,1.292,0,1,1,.863-.863A1.256,1.256,0,0,1,15.208,7.517Z"
        transform="translate(-2.249 -3.375)"
        fill="#5f6267"
      />
    </svg>
  );
};
