import React from "react";
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import useStyles from "./styles";
import { WIDGET_SIZES } from "./StoreWidget.constants";
import { string, func, object } from "prop-types";
import { StyledButton } from "components";

const StoreWidget = ({ size, setSize, widgetSrc, sizes, storeName }) => {
  const classes = useStyles({ scaleIframe: sizes.scale });

  return (
    <Box className={classes.wrapper}>
      <Typography className={classes.text}>
        Add the widget to your website to convert traffic into orders
      </Typography>
      <RadioGroup
        value={size}
        onChange={(e, newVal) => setSize(newVal)}
        className={classes.radioGroup}
      >
        {WIDGET_SIZES.map(({ value, text }) => (
          <FormControlLabel
            key={value}
            label={text}
            control={<Radio />}
            value={value}
          />
        ))}
      </RadioGroup>
      <Box className={classes.widgetWrapper}>
        <StyledButton
          label="View full size"
          color="edit"
          onClick={() => {
            window.open(
              `${widgetSrc}?preview=true&storeName=${storeName}`,
              "_blank"
            );
          }}
        />
        <Box className={classes.iframeWrap}>
          <iframe
            height={sizes.height}
            width={sizes.width}
            style={{ height: `${sizes.height}px`, width: `${sizes.width}px` }}
            src={`${widgetSrc}?storeName=${storeName}`}
          />
        </Box>
      </Box>
    </Box>
  );
};

StoreWidget.propTypes = {
  size: string,
  setSize: func,
  widgetSrc: string,
  sizes: object,
  storeName: string,
};
StoreWidget.defaultProps = { size: "lg", setSize: () => {} };

export default StoreWidget;
