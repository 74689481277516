export const cl = {
  imageWrapper: {
    backgroundColor: "#CBCBCB",
    height: "80px",
    width: "65px",
    minWidth: "65px",
    borderRadius: "4px",
    color: "#5F6267",
    fontSize: "6px",
    lineHeight: "8px",
    fontWeight: 600,
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    objectFit: "cover",
    positions: "relative",
    transition: "all 0.2s",

    "& img": {
      objectFit: "cover",
      height: "80px",
      width: "65px",
    },
  },
  hoveredImage: {
    transition: "all 0.2s",
    position: "absolute",
    marginTop: "65px",
    backgroundColor: "#5F626799",
    borderRadius: "0 0 4px 4px",
    width: "65px",
    padding: "2.5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  imageLabel: {
    color: "#ffff",
    fontSize: "6px",
    fontWeight: "600",
    cursor: "pointer",
  },
};
