import React from "react";

// eslint-disable-next-line react/prop-types
export const PicIcon = ({ size = 16, color = "#ccc", ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16 16"
      {...props}
    >
      <rect
        id="Rectangle_2260"
        data-name="Rectangle 2260"
        width="16"
        height="16"
        rx="2"
        fill="none"
      />
      <path
        id="Path_1218"
        data-name="Path 1218"
        d="M12.444,15H3.556A.65.65,0,0,1,3,14.286V5.714A.65.65,0,0,1,3.556,5h8.889A.65.65,0,0,1,13,5.714v8.571A.65.65,0,0,1,12.444,15Z"
        transform="translate(0 -2)"
        fill={color}
      />
      <path
        id="Path_1219"
        data-name="Path 1219"
        d="M12.59,14.031,10.062,10.44,8.316,12.7,6.528,11.446l-1.75,2.584Z"
        transform="translate(-0.674 -3.143)"
        fill="#fff"
      />
      <circle
        id="Ellipse_499"
        data-name="Ellipse 499"
        cx="1"
        cy="1"
        r="1"
        transform="translate(5.757 5.177)"
        fill="#fff"
      />
    </svg>
  );
};
