import React from "react";

export const CheckedInIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="5.688"
      height="11.375"
      viewBox="0 0 5.688 11.375"
    >
      <path
        id="icons8-map-pin_1_"
        data-name="icons8-map-pin (1)"
        d="M16.844,4a2.842,2.842,0,0,0-.427,5.652v5.3a.427.427,0,1,0,.853,0v-5.3A2.842,2.842,0,0,0,16.844,4Zm-.711,1.706a.427.427,0,1,1-.427.427A.427.427,0,0,1,16.133,5.706Z"
        transform="translate(-14 -4)"
        fill="#42a57f"
      />
    </svg>
  );
};
