import { useCallback, useState, useMemo } from "react";
import { shape, string, func } from "prop-types";

import { Box, ListItemText, MenuItem, Typography } from "@mui/material";
import {
  FolderIcon,
  ImageIcon,
} from "../../../../../../components/Icons/Files";
import StyledMenu from "../../../../../../components/StyledMenu/StyledMenu";
import { useNavigate } from "react-router-dom";
import { ThreeDotButton } from "../../../../../../components";

export const ItemFilesList = ({ file, handleRename, onDeleteItem }) => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const openHeaderList = Boolean(anchorEl);

  const getIcon = useMemo(() => {
    if (file?.type === "folder") return <FolderIcon />;
    if (file?.type === "img") return <ImageIcon />;

    return "";
  }, [file?.type]);

  const handleOpenMenu = useCallback((e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  }, []);

  const handleCloseMenu = () => {
    // e.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "54px",
        minHeight: "54px",
        "&:not(:last-of-type)": {
          borderBottom: "0.5px solid #D5D9D9",
        },
        display: "flex",
      }}
    >
      {/* Name */}
      <Box
        sx={{
          width: "35.8%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box sx={{ width: "5%" }} />
        <Box sx={{ width: "24px" }}>{getIcon}</Box>
        <Box sx={{ width: "88%", pl: "1%" }}>
          {file?.type === "folder" ? (
            <Typography
              sx={{
                fontSize: 12,
                fontWeight: 600,
                color: "#1C1C19",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
              noWrap
              onClick={() => navigate(`folder/${file?.id}`)}
            >
              {file?.fileName}
            </Typography>
          ) : (
            <Typography
              sx={{
                fontSize: 12,
                fontWeight: 600,
                color: "#1C1C19",
              }}
              noWrap
            >
              {file?.fileName}
            </Typography>
          )}
        </Box>
      </Box>
      {/* Owner */}
      <Box
        sx={{
          width: "23.5%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: 12,
            color: "#1C1C19",
          }}
        >
          {file?.owner || "-"}
        </Typography>
      </Box>
      {/* Size */}
      <Box
        sx={{
          width: "22.2%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: 12,
            color: "#1C1C19",
          }}
        >
          {file?.size || "-"}
        </Typography>
      </Box>
      {/* Updated */}
      <Box
        sx={{
          width: "15%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: 12,
            color: "#1C1C19",
          }}
        >
          {file?.updated || "-"}
        </Typography>
      </Box>
      {/* ... */}
      <Box
        sx={{
          width: "3.5%",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <ThreeDotButton onClick={handleOpenMenu} />
        <StyledMenu
          anchorEl={anchorEl}
          isOpen={openHeaderList}
          handleClose={handleCloseMenu}
        >
          {file?.type !== "folder" ? (
            <MenuItem
              onClick={() => {
                handleCloseMenu();
              }}
            >
              <ListItemText>Download</ListItemText>
            </MenuItem>
          ) : null}

          <MenuItem
            onClick={() => {
              handleRename(file);
              handleCloseMenu();
            }}
          >
            <ListItemText>Rename</ListItemText>
          </MenuItem>

          {file?.type !== "folder" ? (
            <MenuItem
              onClick={() => {
                handleCloseMenu();
                navigate(`manage/${file?.id}`);
              }}
            >
              <ListItemText>Manage Customers</ListItemText>
            </MenuItem>
          ) : null}

          <MenuItem
            onClick={() => {
              onDeleteItem(file);
              handleCloseMenu();
            }}
          >
            <ListItemText
              classes={{ primary: "deleteItem" }}
              sx={{ "& > .MuiTypography-root": { color: "#FF6254" } }}
            >
              Delete
            </ListItemText>
          </MenuItem>
        </StyledMenu>
      </Box>
    </Box>
  );
};

ItemFilesList.propTypes = {
  file: shape({ id: string }),
  handleRename: func,
  onDeleteItem: func,
};
ItemFilesList.defaultProps = {};
