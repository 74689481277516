import { useEffect, useState } from "react";
import { bool, func, object } from "prop-types";
import { Grid, IconButton, Typography, Box, Drawer } from "@mui/material";
import { StyledButton } from "components";
import { CrossIcon } from "components/Icons";
import { ReceivingHoursItem } from "./components";
import { WEEK_DAYS } from "./ReceivingHoursDrawer.constants";

const ReceivingHoursDrawer = ({
  isOpen,
  handleClose,
  handleSetData,
  weekDays,
}) => {
  const [dayTimePickers, setDayTimePickers] = useState(WEEK_DAYS);

  const handleShowDatePicker = (dayName, timeIn, timeOut) => {
    setDayTimePickers((prev) => {
      return {
        ...prev,
        [dayName]:
          timeIn && timeOut
            ? {
                ...prev[dayName],
                value: {
                  clockInAt: timeIn,
                  clockOutAt: timeOut,
                },
              }
            : {
                ...prev[dayName],
                value: "",
              },
      };
    });
  };

  const handleSetDay = (dayName, type, time) => {
    setDayTimePickers((prev) => {
      return {
        ...prev,
        [dayName]: {
          ...prev[dayName],
          value: {
            ...prev[dayName]?.value,
            [type]: time,
          },
        },
      };
    });
  };

  useEffect(() => {
    if (isOpen) setDayTimePickers(weekDays);
  }, [isOpen, weekDays]);

  return (
    <Drawer anchor="right" open={isOpen}>
      <Box sx={{ borderBottom: "0.5px solid #D5D9D9", background: "#F8F8F8" }}>
        <IconButton
          sx={{ position: "absolute", top: 14, right: 14, p: 0 }}
          onClick={handleClose}
        >
          <CrossIcon />
        </IconButton>
        <Typography
          p="14px 0 12px 26px"
          fontWeight="400"
          fontSize="17px"
          color="#3F3F3F"
        >
          Working hours
        </Typography>
      </Box>
      <Box sx={{ p: "10px 18px 21px 36px", width: "545px" }}>
        <Grid container>
          {Object.values(dayTimePickers).map((day) => (
            <ReceivingHoursItem
              key={day.name}
              day={day}
              handleSetDay={handleSetDay}
              handleShowDatePicker={handleShowDatePicker}
            />
          ))}
        </Grid>
      </Box>
      <Box
        sx={{
          display: "flex",
          background: "#F8F8F8",
          p: "24px 30px",
          justifyContent: "flex-end",
          mt: "auto",
          alignItems: "baseline",
        }}
      >
        <StyledButton
          sx={{ height: 31, borderColor: "#D5D9D9" }}
          onClick={handleClose}
          label={
            <Typography
              fontSize="15px"
              sx={{
                color: "#5f6267",
              }}
              fontWeight="normal"
            >
              Cancel
            </Typography>
          }
          variant="text"
        />
        <StyledButton
          variant="contained"
          onClick={() => {
            handleSetData(dayTimePickers);
            handleClose();
          }}
          label="Add"
          fontSize="15px"
          sx={{
            height: "31px",
            width: "78px",
            boxShadow: "none",
            ml: "10px",
          }}
        />
      </Box>
    </Drawer>
  );
};

ReceivingHoursDrawer.propTypes = {
  isOpen: bool,
  handleClose: func,
  handleSetData: func,
  weekDays: object,
};

export default ReceivingHoursDrawer;
