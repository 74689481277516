export const CarIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.391"
      height="14.901"
      viewBox="0 0 20.391 14.901"
    >
      <path
        data-name="Path 5374"
        d="M18.038 4.519c-.676-1.51-2.4-4.519-4.9-4.519H7.255c-2.5 0-4.157 2.912-4.9 4.519C1.179 5.646.002 6.759.002 8.676v3.725a.393.393 0 0 0 .3.382c.73.167 3.049.5 9.892.5s9.161-.333 9.892-.5a.393.393 0 0 0 .3-.382V8.676c.005-1.912-1.088-3.089-2.348-4.157ZM6.961 1.176h6.47c1.985 0 3.039 2.941 3.039 3.431H3.925c0-.49 1.32-3.431 3.036-3.431Zm-3.824 9.985a1.569 1.569 0 1 1 1.569-1.569 1.567 1.567 0 0 1-1.569 1.569Zm10.588-1.554a.787.787 0 0 1-.784.784h-5.49a.787.787 0 0 1-.784-.784.787.787 0 0 1 .784-.784h5.49a.787.787 0 0 1 .784.784Zm3.529 1.569a1.569 1.569 0 1 1 1.571-1.569 1.567 1.567 0 0 1-1.571 1.569Z"
      />
      <path
        data-name="Path 5375"
        d="M5.49 13.725c-2.348 0-4.706-.245-4.706-.245 0 .833-.015 1.422.294 1.422h4.167c.308-.001.245-.648.245-1.177Z"
      />
      <path
        data-name="Path 5376"
        d="M14.901 13.725c2.353 0 4.706-.245 4.706-.245 0 .784.1 1.422-.245 1.422h-4.216c-.328-.001-.245-.663-.245-1.177Z"
      />
    </svg>
  );
};
