import { useEffect, useState } from "react";
import { string, object, func } from "prop-types";
import { Box } from "@mui/material";

const ImageBox = ({ src, placeholderSrc, alt, sx, setLoadingPhoto }, props) => {
  const [imageSrc, setImageSrc] = useState(placeholderSrc);

  const cl = `progressive-${
    imageSrc === placeholderSrc ? "loading" : "loaded"
  }`;

  useEffect(() => {
    if (setLoadingPhoto && cl === "progressive-loading") setLoadingPhoto(true);
  }, [cl, setLoadingPhoto]);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      if (setLoadingPhoto) setLoadingPhoto(false);
      setImageSrc(src);
    };
  }, [setLoadingPhoto, src]);

  return (
    <Box
      sx={sx}
      className={cl}
      component="img"
      src={imageSrc}
      alt={alt}
      {...props}
    />
  );
};
ImageBox.propTypes = {
  src: string,
  placeholderSrc: string,
  alt: string,
  sx: object,
  setLoadingPhoto: func,
};

export default ImageBox;
