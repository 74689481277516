import * as React from "react";

// eslint-disable-next-line react/prop-types
export const FilledCopyIcon = ({ color = "#fff", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={6.984}
    height={8.73}
    viewBox="0 0 6.984 8.73"
    {...props}
  >
    <g data-name="Group 10390" fill={color}>
      <path
        data-name="Path 5687"
        d="M5.237 1.8h1.637a.109.109 0 0 0 .109-.109.508.508 0 0 0-.182-.387L5.401.136a.68.68 0 0 0-.375-.135.134.134 0 0 0-.135.135v1.318a.348.348 0 0 0 .346.346Z"
      />
      <path
        data-name="Path 5688"
        d="M4.42 1.455V0H2.037a.584.584 0 0 0-.582.582v6.111a.584.584 0 0 0 .582.582h4.365a.584.584 0 0 0 .582-.582V2.275H5.238a.82.82 0 0 1-.818-.82Z"
      />
      <path
        data-name="Path 5689"
        d="M.946 7.202V1.164H.582A.584.584 0 0 0 0 1.746v6.4a.584.584 0 0 0 .582.582h4.656a.584.584 0 0 0 .582-.582v-.364H1.528a.584.584 0 0 1-.582-.58Z"
      />
    </g>
  </svg>
);
