export const FormsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 16.121 16.125"
    {...props}
  >
    <path
      data-name="Icon awesome-wpforms"
      d="M16.121 1.55v13.02a1.537 1.537 0 0 1-1.555 1.555H1.555A1.54 1.54 0 0 1 0 14.567V1.55A1.537 1.537 0 0 1 1.555 0H14.57a1.536 1.536 0 0 1 1.551 1.55m-1.342 13.017V1.55a.214.214 0 0 0-.209-.2h-.335l-3.969 2.68-2.206-1.8-2.2 1.8-3.971-2.688h-.334a.214.214 0 0 0-.209.209v13.016a.214.214 0 0 0 .209.209H14.57a.21.21 0 0 0 .209-.209M5.4 5.542v1.331H2.76V5.542Zm0 2.677v1.342H2.76V8.219Zm.4-5.3L7.747 1.35H3.483zm7.557 2.623v1.331H6.308V5.542Zm0 2.677v1.342H6.308V8.219Zm-3.044-5.3 2.325-1.569H8.377l1.94 1.573Zm3.044 7.985v1.346H9.784v-1.347Z"
      fill="#707070"
    />
  </svg>
);
