import * as Yup from "yup";

import {
  SYMBOL_REG_EXP,
  LOWERCASE_REG_EXP,
  UPPERCASE_REG_EXP,
  MIN_PASSWORD_LENGTH,
  NUMBER_REG_EXP,
} from "components/PasswordCriteria/PasswordCriteria.constants";

export const changePasswordValidationSchema = () =>
  Yup.object().shape({
    newPassword: Yup.string()
      .min(
        MIN_PASSWORD_LENGTH,
        `Password must be at least ${MIN_PASSWORD_LENGTH} characters`
      )
      .matches(
        LOWERCASE_REG_EXP,
        "Password must have at least one lowercase char"
      )
      .matches(
        UPPERCASE_REG_EXP,
        "Password must have at least one uppercase char"
      )
      .matches(NUMBER_REG_EXP, "Password must have at least one digit")
      .matches(SYMBOL_REG_EXP, "Password must have at least one special char")
      .test(
        "same",
        "The new password cannot be the same as the current password. Please choose a different password.",
        (value, ctx) => {
          return value !== ctx?.parent?.currentPassword;
        }
      )
      .required("New password is required"),
    currentPassword: Yup.string().required("Current password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords don't match!")
      .required("Confirm password is required")
      .test("confirmPassword", "Incorrect confirm password", (value, ctx) => {
        return (
          value === ctx?.parent?.newPassword &&
          value !== ctx?.parent?.currentPassword
        );
      }),
  });
