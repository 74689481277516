import React from "react";

// eslint-disable-next-line react/prop-types
export const PrinterIcon = ({ width = "13.186", height = "11.542" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 13.186 11.542"
    >
      <g
        id="Icon_metro-printer"
        data-name="Icon metro-printer"
        transform="translate(0)"
      >
        <path
          id="Icon_metro-printer-2"
          data-name="Icon metro-printer"
          d="M5.867,3.856H12.46V5.5H5.867Zm9.066,2.473H3.395a.827.827,0,0,0-.824.824v4.121a.827.827,0,0,0,.824.824H5.867v3.3H12.46V12.1h2.473a.827.827,0,0,0,.824-.824V7.153a.827.827,0,0,0-.824-.824ZM4.219,8.8a.824.824,0,1,1,.824-.824.824.824,0,0,1-.824.824Zm7.418,5.769H6.692v-4.12h4.945Z"
          transform="translate(-2.571 -3.856)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
