export const invitesloadingSelector = (state) => state.orderDirects.loading;
export const invitesListSelector = (state) => state.orderDirects.invitesList;
export const invitesCountSelector = (state) => state.orderDirects.invitesCount;
export const receivedListSelector = (state) => state.orderDirects.receivedList;
export const receivedCountSelector = (state) =>
  state.orderDirects.receivedCount;
export const hasAnyInvitesSelector = (state) =>
  state.orderDirects.hasAnyInvites;
export const hasAnyReceivedSelector = (state) =>
  state.orderDirects.hasAnyReceived;
export const notificationsCountSelector = (state) =>
  state.orderDirects.notificationsCount;
export const notificationReceivedCustomersSelector = (state) =>
  state.orderDirects.notificationReceivedCustomers;
