import { object, bool, array } from "prop-types";

import { normalizeUppercaseString } from "../../../../../../helpers/helpers";

import { Fade, Popper, Stack, Typography, Box } from "@mui/material";

const AssignedRepsPopper = ({ anchorEl, open, rep, modifiers, ...props }) => {
  return (
    <Popper
      anchorEl={anchorEl}
      open={open}
      placement="top"
      id={open ? "transition-popper" : undefined}
      transition
      modifiers={modifiers}
      sx={{
        pointerEvents: "none",
      }}
      {...props}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Box
            sx={{
              backgroundColor: "#ffffff",
              textAlign: "center",
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "17px",
              color: "#1C1C19",
              border: "0.4px solid #d5d9d9",
              borderRadius: "8px",
              boxShadow: "0px 3px 6px rgba(0, 3, 6, 0.16)",
              padding: "12.5px 13.5px",
            }}
          >
            <Typography fontSize="10px" color="primary">
              {!!rep?.role &&
                (rep?.role === "THIRD_PARTY"
                  ? "3rd Party Rep"
                  : `${normalizeUppercaseString(rep?.role)} Rep`)}
            </Typography>
            <Typography fontSize="10px" color="#363531">
              {rep?.name}
            </Typography>
            <Stack
              sx={{
                position: "relative",
                width: "100%",
                alignItems: "center",
                mt: "13.5px",
                mb: "-13.5px",
              }}
            >
              <Box
                sx={{
                  width: "16.5px",
                  height: "12.5px",
                  bottom: "-12px",
                  backgroundColor: "#D5D9D9",
                  clipPath: "polygon(0 0, 50% 55%, 100% 0)",
                  position: "absolute",
                }}
              />
              <Box
                sx={{
                  width: "16px",
                  height: "12px",
                  bottom: "-11px",
                  zIndex: 1,
                  backgroundColor: "#ffffff",
                  clipPath: "polygon(0 0, 50% 55%, 100% 0)",
                  position: "absolute",
                }}
              />
            </Stack>
          </Box>
        </Fade>
      )}
    </Popper>
  );
};

AssignedRepsPopper.propTypes = {
  anchorEl: object,
  rep: object,
  open: bool,
  modifiers: array,
};

AssignedRepsPopper.defaultProps = {
  anchorEl: {},
  rep: {},
  open: false,
  modifiers: [
    {
      name: "offset",
      options: {
        offset: [0, 11],
      },
    },
  ],
};

export default AssignedRepsPopper;
