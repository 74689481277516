import backgroundImg from "assets/images/login-background.jpg";

export const cl = {
  main_wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    background: `linear-gradient(0deg, rgba(225, 225, 225, 0.7), rgba(225, 225, 225, 0.7)), url(${backgroundImg})`,
    backgroundSize: "100% 100%",
  },
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "16px",
    width: "720px",
  },
  text_field: { height: "64px", borderRadius: "8px", fontSize: 18 },
  login_btn: {
    height: "67px",
    fontSize: 20,
    fontWeight: 500,
  },
};
