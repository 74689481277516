import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  infoBlockWrapper: {
    height: "67px",
    border: "0.5px solid #D5D9D9",
    borderRadius: "4px",
    background: "#fff",
    display: "flex",
    alignItems: "center",
    padding: "0 22px 0 18px",
    justifyContent: "space-between",
  },

  fieldsWrapper: {
    display: "flex",
    gap: "21px",
  },

  typeButton: {
    borderRadius: "20px!important",
    border: "0.3px solid #D5D9D9!important",
    width: "63px",
    height: "27px",
    background: "#F9F9F9",
    "& path": {
      fill: "#707070",
    },
    "&.Mui-selected": {
      background: "rgba(71, 160, 109, 0.1)",
      borderColor: "#47A06D!important",
      "& path": {
        fill: "#47A06D",
      },
    },
  },

  typeButtonsWrapper: {
    gap: "10px",
  },
}));

export default useStyles;
