import { string } from "prop-types";
import { Box, Typography } from "@mui/material";

export const PaymentFailedQuestions = ({ email }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "57px",
        minHeight: "57px",
        gap: "4px",
        fontSize: 16,
      }}
    >
      <Typography color="#000000" fontWeight={700}>
        Have Questions?
      </Typography>
      <Typography
        sx={{
          color: "#47A06D",
          fontWeight: 300,
          cursor: "pointer",
        }}
        onClick={() => {
          window.location.href = `mailto:${email}`;
        }}
      >
        Email Us
      </Typography>
      {/* <Typography color="#1C1C19" fontWeight={300}>
        or Call
      </Typography>
      <Typography
        sx={{
          color: "#1C1C19",
          fontWeight: 300,
          textDecoration: "none",
        }}
        component="a"
        href={`tel:${tel}`}
      >
        {tel}
      </Typography> */}
    </Box>
  );
};

PaymentFailedQuestions.propTypes = {
  email: string,
  // tel: string,
};
PaymentFailedQuestions.defaultProps = {
  email: "support@simplydepo.com",
  // tel: "(347) 673-9266",
};
