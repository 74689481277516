import * as React from "react";
export const EmptyTasksIcon = (props) => (
  <svg
    width={99}
    height={121}
    viewBox="0 0 99 121"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#a)">
      <path
        d="M88.792 115.895H10.209c-2.094 0-3.792-1.93-3.792-4.312v-91.03c0-2.38 1.698-4.311 3.792-4.311h78.583c2.093 0 3.791 1.93 3.791 4.312v91.03c0 2.376-1.698 4.311-3.791 4.311Z"
        fill="#fff"
        stroke="#EFEFEF"
        strokeWidth={0.749}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m50.249 44.832 3.78 3.779c.426.427.947.725 1.448.828.5.103.94.004 1.22-.278.282-.28.382-.72.279-1.22-.104-.501-.401-1.022-.828-1.45l-3.78-3.779 3.65-3.65c.427-.427.725-.948.828-1.449.104-.5.004-.94-.277-1.22-.281-.282-.72-.381-1.221-.278-.5.103-1.022.401-1.449.828l-3.65 3.65-4.01-4.009c-.426-.427-.947-.725-1.448-.828-.5-.103-.94-.003-1.22.278-.282.28-.382.72-.279 1.22.104.501.402 1.022.829 1.45l4.009 4.008-4.139 4.139c-.427.427-.724.948-.828 1.449-.103.5-.003.94.278 1.22.281.281.72.381 1.22.278.502-.103 1.023-.401 1.45-.828z"
        fill="#409A65"
      />
      <path
        d="M69.232 69.291H30.334c-2.035 0-3.686-1.019-3.686-2.277s1.648-2.277 3.686-2.277h38.9c2.035 0 3.686 1.02 3.686 2.277s-1.651 2.277-3.688 2.277"
        fill="#E2F0E8"
      />
      <path
        opacity={0.4}
        d="M69.232 85.983H30.334c-2.035 0-3.686-1.019-3.686-2.277s1.648-2.277 3.686-2.277h38.9c2.035 0 3.686 1.02 3.686 2.277s-1.651 2.277-3.688 2.277"
        fill="#E2F0E8"
      />
      <path
        opacity={0.8}
        d="M69.232 76.575H30.334c-2.035 0-3.686-1.02-3.686-2.277s1.648-2.278 3.686-2.278h38.9c2.035 0 3.686 1.02 3.686 2.278s-1.651 2.277-3.688 2.277"
        fill="#E2F0E8"
      />
      <path
        opacity={0.1}
        d="M69.232 93.267H30.334c-2.035 0-3.686-1.02-3.686-2.278s1.648-2.277 3.686-2.277h38.9c2.035 0 3.686 1.02 3.686 2.277s-1.651 2.277-3.688 2.277"
        fill="#E2F0E8"
      />
      <mask id="b" fill="#fff">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M44.316 11.746a5.996 5.996 0 0 1 11.117 0h13.923a2.997 2.997 0 0 1 2.997 2.997v7.493a2.997 2.997 0 0 1-2.997 2.997H30.394a2.997 2.997 0 0 1-2.997-2.997v-7.493a2.997 2.997 0 0 1 2.997-2.997z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.316 11.746a5.996 5.996 0 0 1 11.117 0h13.923a2.997 2.997 0 0 1 2.997 2.997v7.493a2.997 2.997 0 0 1-2.997 2.997H30.394a2.997 2.997 0 0 1-2.997-2.997v-7.493a2.997 2.997 0 0 1 2.997-2.997z"
        fill="#fff"
      />
      <path
        d="M44.316 11.746v.75h.505l.19-.469zm11.117 0-.694.281.19.469h.504zm-10.422.281a5.25 5.25 0 0 1 4.864-3.278V7.251a6.745 6.745 0 0 0-6.253 4.214zm4.864-3.278c2.2 0 4.085 1.355 4.864 3.278l1.389-.562a6.745 6.745 0 0 0-6.253-4.214zm5.558 3.747h13.923v-1.499H55.433zm13.923 0a2.25 2.25 0 0 1 2.248 2.247h1.498a3.746 3.746 0 0 0-3.746-3.746zm2.248 2.247v7.493h1.498v-7.493zm0 7.493a2.25 2.25 0 0 1-2.248 2.248v1.499a3.746 3.746 0 0 0 3.746-3.747zm-2.248 2.248H30.394v1.499h38.962zm-38.962 0a2.25 2.25 0 0 1-2.248-2.248h-1.499a3.746 3.746 0 0 0 3.747 3.747zm-2.248-2.248v-7.493h-1.499v7.493zm0-7.493a2.25 2.25 0 0 1 2.248-2.247v-1.499a3.746 3.746 0 0 0-3.747 3.746zm2.248-2.247h13.922v-1.499H30.394z"
        fill="#EFEFEF"
        mask="url(#b)"
      />
    </g>
    <defs>
      <filter
        id="a"
        x={0.048}
        y={0.507}
        width={98.904}
        height={120.258}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={-1.499} />
        <feGaussianBlur stdDeviation={2.997} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_186_554" />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_186_554"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
