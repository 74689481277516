import { func, bool, array } from "prop-types";

import { cl } from "../../styles";

import { DialogActions } from "@mui/material";
import { StyledButton } from "components";

export const ProductActionBlock = ({
  handleCancel,
  disabledSaveBtn,
  checkedProducts,
  handleApplyPurchasedProducts,
}) => {
  return (
    <DialogActions sx={cl.actionsWrapper}>
      <StyledButton
        label="Cancel"
        sx={cl.actionBtn}
        color="cancel"
        onClick={handleCancel}
        fontWeight="600"
        fontSize="10px"
      />
      <StyledButton
        disabled={disabledSaveBtn}
        label="Add"
        variant="contained"
        sx={cl.actionBtn}
        fontWeight="600"
        fontSize="10px"
        onClick={() => {
          handleApplyPurchasedProducts(checkedProducts);
          handleCancel();
        }}
      />
    </DialogActions>
  );
};

ProductActionBlock.propTypes = {
  handleCancel: func,
  handleApplyPurchasedProducts: func,
  disabledSaveBtn: bool,
  checkedProducts: array,
};

ProductActionBlock.defaultProps = {
  handleCancel: () => {},
  checkedProducts: [],
  disabledSaveBtn: false,
  handleApplyPurchasedProducts: () => {},
};
