import { useMemo, useRef, useState } from "react";

export const usePopperActions = ({ errors, formField, isEditReturn }) => {
  const sendNotificationRef = useRef(null);
  const restockRef = useRef(null);
  const btnSubmitRef = useRef(null);
  const deliveryMethodRef = useRef(null);

  const [popperType, setPopperType] = useState(null);

  const { productReturns } = formField || {};

  const productReturnsWithQuantity = productReturns
    ?.filter((prod) => prod.returnQuantity > 0)
    ?.map((el) => {
      return {
        orderProductId: el.orderProductId || el.id,
        quantity: el.returnQuantity,
      };
    });

  const restockText = useMemo(() => {
    if (!productReturnsWithQuantity?.length && !isEditReturn) {
      return "Pick at least one item to proceed";
    }

    if (isEditReturn) {
      return (
        <>
          <span>Restock couldn&apos;t be performed in edit return.</span>
          <br />
          <span>
            Please edit an inventory directly from the product inventory tab
          </span>
        </>
      );
    }
  }, [isEditReturn, productReturnsWithQuantity?.length]);

  const restockOffset = useMemo(() => {
    if (!productReturnsWithQuantity?.length && !isEditReturn) {
      return [-18, 0];
    }
    if (isEditReturn) {
      return [-10, 0];
    }
  }, [isEditReturn, productReturnsWithQuantity?.length]);

  const popperData = useMemo(() => {
    switch (popperType) {
      case "sendNotification":
        return {
          open: true,
          anchorEl: sendNotificationRef.current,
          text: "No email address found for this customer",
          modifiers: [{ name: "offset", options: { offset: [-88, 0] } }],
        };

      case "restock":
        return {
          open: true,
          anchorEl: restockRef.current,
          text: restockText,
          modifiers: [{ name: "offset", options: { offset: restockOffset } }],
          style: { whiteSpace: "pre-wrap" },
        };

      case "btnSubmit":
        return {
          open: !!Object.keys(errors).length,
          anchorEl: btnSubmitRef.current,
          text: Object.entries(errors)
            .map((er) => er[1].message)
            .join("\n"),
          modifiers: [{ name: "offset", options: { offset: [0, 10] } }],
          style: { whiteSpace: "pre-wrap" },
        };

      case "deliveryMethod":
        return {
          open: true,
          anchorEl: deliveryMethodRef.current,
          text: "No reps found in the system, please add at least 1 rep.",
          modifiers: [{ name: "offset", options: { offset: [0, 0] } }],
        };

      default:
        return {
          open: false,
          anchorEl: null,
          text: "",
          modifiers: [],
        };
    }
  }, [errors, popperType, restockOffset, restockText]);

  return {
    popperData,
    setPopperType,
    sendNotificationRef,
    restockRef,
    btnSubmitRef,
    deliveryMethodRef,
  };
};
