import { string, object, bool } from "prop-types";
import { Box } from "@mui/material";
import { ImagePlaceholder } from "../../../components/ImagePlaceholder/ImagePlaceholder";

export const StyledProductIco = ({
  src,
  styles,
  placeholderStyles,
  showAlt,
  ...props
}) => {
  return (
    <>
      {src && src !== null ? (
        <Box
          component="img"
          sx={styles}
          src={src}
          alt={showAlt ? "" : "Product Image"}
          {...props}
        />
      ) : (
        <Box
          xs={1}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            bottom: "1px",
          }}
        >
          <ImagePlaceholder
            height="38px"
            width="38px"
            fillOpacity={0}
            style={{
              borderRadius: "4px",
              border: "1px solid #D5D9D9",
            }}
            {...placeholderStyles}
          />
        </Box>
      )}
    </>
  );
};

StyledProductIco.displayName = "StyledProductIco";

StyledProductIco.propTypes = {
  src: string,
  styles: object,
  placeholderStyles: object,
  showAlt: bool,
};
StyledProductIco.defaultProps = {
  styles: {},
  placeholderStyles: {},
};
