import React from "react";
import { string, array, bool } from "prop-types";
import { Box, Typography } from "@mui/material";

export const InfoItemBlock = ({ title, info, showDivider }) => {
  return (
    <Box width="200px" position="relative">
      {showDivider && (
        <Box
          sx={{
            borderLeft: "0.5px solid #D5D9D9",
            width: "1px",
            height: "68px",
            position: "absolute",
            right: 0,
            transform: "translate(-50%, 30%)",
          }}
        />
      )}
      <Box>
        <Typography
          sx={{
            fontSize: 12,
            fontWeight: 500,
            color: "#000",
            textDecoration: "underline",
          }}
        >
          {title}
        </Typography>
      </Box>
      <Box>
        {info.map(({ name, value }) => (
          <React.Fragment key={name}>
            <Typography
              sx={{
                mt: 1,
                fontSize: 12,
                fontWeight: 500,
                color: "#000",
              }}
            >
              {name}
            </Typography>
            {value.map((i) => (
              <Typography
                key={i}
                sx={{
                  fontSize: 12,
                  fontWeight: 300,
                  color: "#000",
                }}
              >
                {i}
              </Typography>
            ))}
          </React.Fragment>
        ))}
      </Box>
    </Box>
  );
};

InfoItemBlock.propTypes = { title: string, info: array, showDivider: bool };
InfoItemBlock.defaultProps = { title: "", info: [], showDivider: true };
