import { Box, Typography } from "@mui/material";

export const HeaderFilesList = () => {
  const columns = [
    { name: "NAME", width: "35.8%", style: { pl: "5%" } },
    { name: "OWNER", width: "23.5%" },
    { name: "SIZE", width: "22.2%" },
    { name: "UPDATED", width: "18.5%" },
  ];

  return (
    <Box
      sx={{
        mt: 2,
        borderWidth: "0.5px 0.5px 0px 0.5px",
        borderStyle: "solid",
        borderColor: "#D5D9D9",
        borderRadius: "4px 4px 0 0",
        backgroundColor: "#EEEEEE",
        width: "100%",
        height: "39px",
        display: "flex",
        alignItems: "center",
      }}
    >
      {columns.map(({ name, style, width }) => (
        <Box key={name} width={width}>
          <Typography
            sx={{
              ...style,
            }}
            fontSize={12}
            color="#6A6A6A"
          >
            {name}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};
