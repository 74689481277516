import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  title: { fontSize: "22px", color: "#3F3F3F" },

  dialogContentWrapper: {
    minWidth: "524px",
    padding: 0,
  },

  dialogContent: {
    padding: "22px 21px 29px 29px",
  },

  discountBy: {
    height: "40px",
    backgroundColor: "rgba(66, 165, 127, 0.1)",
    borderTop: "0.5px solid #d5d9d9",
    borderBottom: "0.5px solid #d5d9d9",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingRight: "21px",
    "& .MuiTypography-root": {
      fontSize: "17px",
      color: "#3F3F3F",
    },
  },

  toggleButton: {
    color: "#6A6A6A",
    height: "32px",
    textTransform: "none",
    fontSize: "15px",
    width: "132px",
    "&.Mui-selected": {
      backgroundColor: "rgba(71, 160, 109, 0.1)!important",
      borderColor: "#47A06D",
      color: "#6A6A6A",
    },
  },
}));

export default useStyles;
