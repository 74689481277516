import StyledButton from "../../components/StyledButton";
import {
  openConfirmDialogAction,
  setConfirmIsOpenAction,
} from "../../redux/actions/confirmDialogs";

export const handleConfirmAction = ({
  title,
  text,
  text2,
  onConfirm,
  isCancel,
  confirmColor,
  dialogStyles,
  dialogButtonsStyles,
  confirmButtonStyles,
  confirmText,
}) => {
  return (dispatch) => {
    dispatch(
      openConfirmDialogAction({
        title,
        text,
        text2,
        dialogStyles,
        dialogButtonsStyles,
        isCancel,
        buttons: (
          <>
            <StyledButton
              sx={{
                width: "98px",
                color: "#6A6A6A",
                borderColor: "#D4D4D4",
                fontSize: "13px",
                height: "28px",
              }}
              onClick={() => {
                dispatch(setConfirmIsOpenAction(false));
              }}
              label="Cancel"
              variant="outlined"
            />

            <StyledButton
              sx={{
                ...confirmButtonStyles,
                width: confirmButtonStyles?.width
                  ? confirmButtonStyles?.width
                  : "98px",
                color: "#FFFFFF",
                fontSize: "13px",
                height: "28px",
                boxShadow: "none",
              }}
              color={confirmColor}
              onClick={onConfirm}
              variant="contained"
              label={confirmText ? confirmText : "Confirm"}
            />
          </>
        ),
      })
    );
  };
};
