export const TAB_HEADER_ITEMS = [
  { name: "", size: 0.3 },
  { name: "Plan", size: 2.4 },
  { name: "Invoice", size: 1.9 },
  { name: "Billing period", size: 2.3 },
  { name: "Users", size: 1, styles: { justifyContent: "center" } },
  { name: "Amount", size: 1, styles: { justifyContent: "center" } },
  { name: "Status", size: 1.5, styles: { justifyContent: "center" } },
  { name: "Due date", size: 1.6, styles: { pl: 3 } },
];
