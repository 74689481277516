import axios from "axios";
import history from "../utils/history";
import accountService from "../services/account";
import { persistor } from "redux/store";

const getTokenFromLocalStorage = () => localStorage.getItem("token");

const setTokenToLocalStorage = (token) => {
  if (token) {
    localStorage.setItem("token", token);
  }
};

const removeTokenFromLocalStorage = () => localStorage.removeItem("token");
const removeTokenFromSessionStorage = () => sessionStorage.removeItem("token");

const setTokenToAxios = (token) =>
  (axios.defaults.headers.Authorization = `Bearer ${token}`);

const removeTokenFromAxios = () => (axios.defaults.headers.Authorization = "");

const getUserRoleFromLocalStorage = () =>
  JSON.parse(localStorage.getItem("userRole"));

const setUserRoleToLocalStorage = (userRole) =>
  localStorage.setItem("userRole", JSON.stringify(userRole));

const removeUserRoleFromLocalStorage = () =>
  localStorage.removeItem("userRole");
const removeUserRoleFromSessionStorage = () =>
  sessionStorage.removeItem("userRole");

const removeAllTokens = () => {
  removeTokenFromAxios();
  removeTokenFromLocalStorage();
  removeTokenFromSessionStorage();
  removeUserRoleFromLocalStorage();
  removeUserRoleFromSessionStorage();
};

export const removeAllPersistData = () => {
  persistor.pause();
  persistor.flush().then(() => {
    return persistor.purge();
  });
};

const createAxiosResponseInterceptor = () => {
  const currentUrl = window.location.href;
  const splittedUrl = currentUrl.split("/");

  const distrId =
    splittedUrl?.[3] === "login" && splittedUrl?.[4]?.length
      ? splittedUrl?.[4]
      : null;

  const interceptor = axios.interceptors.response.use(
    (response) => response, // on success
    (error) => {
      const logInLink = distrId ? `/login/${distrId}` : "/login";
      const userRoleAdmin = sessionStorage.getItem("userRole");
      const userRole = getUserRoleFromLocalStorage();

      if (
        (error?.response?.status === 401 &&
          error?.response?.config?.url !== "/auth/distributor" &&
          error?.response?.config?.url !== "/oauth2/authorization-code" &&
          error?.response?.config?.url !==
            "/orders/payment-intent-client-data" &&
          userRoleAdmin !== "SUPER_ADMIN") ||
        (userRole === "REPRESENTATIVE" &&
          error?.response?.status === 403 &&
          !error?.response?.data?.logOutNotRequired)
      ) {
        removeAllTokens();
        removeAllPersistData();
        window.location.reload();
        history.push(logInLink);
      }

      axios.interceptors.response.eject(interceptor);

      return Promise.reject(error);
    }
  );
};

const getCurrentUser = async ({ setCurrentUser, onSuccess, onFail }) => {
  try {
    const user = await accountService.getCurrentUser();
    if (setCurrentUser) setCurrentUser({ ...user });

    if (onSuccess) onSuccess();
  } catch (err) {
    if (onFail) onFail();
  }
};

export {
  getTokenFromLocalStorage,
  setTokenToLocalStorage,
  removeTokenFromLocalStorage,
  removeTokenFromAxios,
  getUserRoleFromLocalStorage,
  setUserRoleToLocalStorage,
  removeUserRoleFromLocalStorage,
  setTokenToAxios,
  removeAllTokens,
  getCurrentUser,
  createAxiosResponseInterceptor,
};

export default {
  getTokenFromLocalStorage,
  removeTokenFromLocalStorage,
  removeTokenFromAxios,
  getUserRoleFromLocalStorage,
  setUserRoleToLocalStorage,
  removeUserRoleFromLocalStorage,
  setTokenToAxios,
  removeAllTokens,
  getCurrentUser,
  createAxiosResponseInterceptor,
};
