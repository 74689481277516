import { func, bool } from "prop-types";
import { Stack } from "@mui/material";
import { StyledButton } from "components";

export const ActionsBlock = ({ onClose, onSave, loading }) => {
  return (
    <Stack
      sx={{
        width: "100%",
        height: "80px",
        minHeight: "80px",
        borderTop: "0.5px solid #D5D9D9",
        background: "#F8F8F8",
        justifyContent: "flex-end",
        alignItems: "center",
        pr: 3.75,
      }}
      direction="row"
      gap={1}
    >
      <StyledButton
        label="Cancel"
        color="cancel"
        fontSize="15px"
        sx={{ width: "78px", height: "31px" }}
        onClick={onClose}
      />
      <StyledButton
        disabled={loading}
        label="Add"
        variant="contained"
        fontSize="15px"
        sx={{ width: "78px", height: "31px" }}
        onClick={onSave}
      />
    </Stack>
  );
};

ActionsBlock.propTypes = {
  onClose: func,
  onSave: func,
  loading: bool,
};
ActionsBlock.defaultProps = {
  onClose: () => {},
  onSave: () => {},
  loading: false,
};
