import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { object, bool, func } from "prop-types";

import {
  Box,
  Grid,
  ListItemText,
  MenuItem,
  Paper,
  Radio,
  Typography,
} from "@mui/material";
import { CheckmarkIcon, CrossIcon, PrimaryIcon } from "components/Icons";
import StyledMenu from "components/StyledMenu/StyledMenu";
import {
  deletePaytermAction,
  updatePaytermAction,
} from "redux/actions/payterms";
import {
  openConfirmDialogAction,
  setConfirmIsOpenAction,
} from "redux/actions/confirmDialogs";
import StyledButton from "components/StyledButton";
import { StyledTooltip, ThreeDotButton } from "components";
import { PAYMENT_TYPES } from "../PaytermsTab.constants";
import { useAdmin } from "helpers/helpers";

const PaytermsItem = ({
  payterm,
  lastItem,
  isOnboarding,
  payTermOnboarding,
  handleSetPayTermOnboarding,
}) => {
  const isAdmin = useAdmin();
  const cl = {
    paper: {
      borderRadius: lastItem ? "0 0 4px 4px" : 0,
      borderWidth: lastItem ? "0px" : "0 0px 1px 0px",
      borderStyle: "solid",
      borderColor: "#D5D9D9",
      position: "relative",
      height: "51px",
      ":hover": {
        backgroundColor: "#F7F7F7",
      },
    },
    gridLeft: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    gridRight: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    text: {
      fontSize: "12px",
      fontWeight: 600,
      color: "#1C1C19",
    },
    menuItemWrapper: {
      "& > .MuiMenuItem-root": {
        padding: "4.5px 0px",
        "& > .MuiListItemText-root": {
          "& > .MuiTypography-root": {
            fontSize: "12px",
            fontWeight: 500,
            "&:not(.deleteItem)": {
              color: "#3F3F3F",
            },
          },
        },
      },
    },
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const openHeaderList = Boolean(anchorEl);

  const handleClickOnDotsBtn = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };
  const handleCloseLogoClient = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleEdit = (e) => {
    handleCloseLogoClient(e);
    navigate(`payterm/${payterm.id}`, { state: payterm });
  };

  const handleActivate = useCallback(
    (e) => {
      dispatch(setConfirmIsOpenAction(false));
      handleCloseLogoClient(e);
      dispatch(
        updatePaytermAction(payterm.id, {
          type: payterm?.type,
          daysInvoices: payterm?.daysInvoices,
          defaultTerm: payterm?.defaultTerm,
          status: payterm.status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
        })
      );
    },
    [dispatch, payterm]
  );

  const handleDelete = useCallback(() => {
    dispatch(deletePaytermAction(payterm.id));
    dispatch(setConfirmIsOpenAction(false));
  }, [dispatch, payterm.id]);

  const handleWarning = ({ e, action, actionLabel, text, title }) => {
    handleCloseLogoClient(e);

    dispatch(
      openConfirmDialogAction({
        title,
        text,
        buttons: (
          <>
            <StyledButton
              label="Cancel"
              onClick={() => dispatch(setConfirmIsOpenAction(false))}
              variant="outlined"
              color="cancel"
              fontSize="13px"
              sx={{ width: "85px", height: "28px" }}
            />
            <StyledButton
              label={actionLabel}
              onClick={action}
              variant="contained"
              fontSize="13px"
              color="error"
              sx={{ height: "28px" }}
            />
          </>
        ),
      })
    );
  };

  return (
    <Paper
      sx={cl.paper}
      elevation={0}
      square
      component={Grid}
      columns={12}
      container
    >
      {isOnboarding ? (
        <Grid sx={{ ...cl.gridLeft, paddingLeft: 1 }} xs={2} item>
          <Radio
            checked={payTermOnboarding?.id === payterm?.id}
            onChange={() => handleSetPayTermOnboarding(payterm)}
          />
          {payTermOnboarding?.id === payterm?.id ? (
            <PrimaryIcon />
          ) : (
            <PrimaryIcon fill="#D5D9D9" />
          )}
        </Grid>
      ) : (
        <Grid
          sx={{
            ...cl.gridLeft,
            pl: 3,
            pr: 4,
            justifyContent: "space-between",
          }}
          xs={2}
          item
        >
          {["ACTIVE", "INACTIVE_FOR_CUSTOMERS"].includes(payterm?.status) ? (
            <CheckmarkIcon />
          ) : (
            <CrossIcon fill="#FF0000" />
          )}
        </Grid>
      )}
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          pr: 1,
        }}
        xs={4}
        item
      >
        <Typography sx={cl.text} noWrap>
          {payterm?.name}
        </Typography>

        {isOnboarding ? null : payterm?.defaultTerm ? (
          <Box>
            <PrimaryIcon />
          </Box>
        ) : null}
      </Grid>

      <Grid sx={cl.gridLeft} xs={4} item>
        <Typography sx={{ ...cl.text, fontWeight: 400 }}>
          {payterm?.daysInvoices}
        </Typography>
      </Grid>
      <Grid sx={cl.gridRight} xs={2} item>
        {payterm?.name !== "Uncategorized" && (
          <>
            <ThreeDotButton disabled={isAdmin} onClick={handleClickOnDotsBtn} />

            <StyledMenu
              sx={{ paddingY: 0 }}
              anchorEl={anchorEl}
              isOpen={openHeaderList}
              handleClose={handleCloseLogoClient}
            >
              <MenuItem onClick={handleEdit}>
                <ListItemText>Edit Term</ListItemText>
              </MenuItem>

              {payterm.type !== PAYMENT_TYPES.ADVANCE_PAYMENT && (
                <StyledTooltip
                  title="Primary term couldn't be deactivated"
                  placement="top"
                  arrow
                  disableHoverListener={!payterm?.defaultTerm}
                >
                  <Box sx={cl.menuItemWrapper} component="span">
                    <MenuItem
                      disabled={payterm?.defaultTerm}
                      onClick={(e) => {
                        if (payterm.status === "INACTIVE")
                          return handleActivate(e);
                        handleWarning({
                          e,
                          action: handleActivate,
                          actionLabel: "Deactivate",
                          title: `Deactivate ${payterm.name}?`,
                          text: `Deactivated payment terms will not be possible to add to the new customers. Are you sure you want to proceed?`,
                        });
                      }}
                    >
                      <ListItemText>
                        {payterm?.status === "INACTIVE"
                          ? "Activate"
                          : "Deactivate"}{" "}
                        Term
                      </ListItemText>
                    </MenuItem>
                  </Box>
                </StyledTooltip>
              )}

              {payterm.type !== PAYMENT_TYPES.ADVANCE_PAYMENT && (
                <StyledTooltip
                  title="Primary term couldn't be deleted"
                  placement="top"
                  arrow
                  disableHoverListener={!payterm?.defaultTerm}
                >
                  <Box sx={cl.menuItemWrapper} component="span">
                    <MenuItem
                      disabled={payterm?.defaultTerm}
                      onClick={(e) =>
                        handleWarning({
                          e,
                          action: handleDelete,
                          actionLabel: "Delete Term",
                          title: `Delete ${payterm.name}?`,
                          text: `Are you sure you want to delete the term ${payterm.name}? This can't be undone.`,
                        })
                      }
                    >
                      <ListItemText>Delete Term</ListItemText>
                    </MenuItem>
                  </Box>
                </StyledTooltip>
              )}
            </StyledMenu>
          </>
        )}
      </Grid>
    </Paper>
  );
};
PaytermsItem.propTypes = {
  payterm: object,
  lastItem: bool,
  isOnboarding: bool,
  payTermOnboarding: object,
  handleSetPayTermOnboarding: func,
};
PaytermsItem.defaultProps = {
  lastItem: false,
  isOnboarding: false,
  payTermOnboarding: {},
  handleSetPayTermOnboarding: () => {},
};

export default PaytermsItem;
