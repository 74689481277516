import { string } from "prop-types";
import { Grid, Typography } from "@mui/material";

export const ItemBlock = ({ title, value }) => {
  return (
    <Grid height={{ xs: "30px", md: "auto" }} py={0.1} xs={12} item container>
      <Grid
        fontSize={{ xs: 13, md: 14 }}
        fontWeight={400}
        color="#7A7A7A"
        xs={4}
        item
      >
        {title}
      </Grid>
      <Grid
        textAlign="end"
        fontSize={{ xs: 13, md: 14 }}
        fontWeight={400}
        color="#000"
        xs={8}
        item
      >
        <Typography noWrap>{value}</Typography>
      </Grid>
    </Grid>
  );
};

ItemBlock.propTypes = { title: string, value: string };
ItemBlock.defaultProps = { title: "", value: "" };
