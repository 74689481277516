import { useSelector } from "react-redux";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { createSelector } from "reselect";
import {
  customersByRepActiveCountSelect,
  customersByRepInactiveCountSelect,
} from "../../../../../../redux/selectors/customers";

const selector = createSelector(
  customersByRepActiveCountSelect,
  customersByRepInactiveCountSelect,
  (activeCount, inactiveCount) => ({
    activeCount,
    inactiveCount,
  })
);

export const CustomersInfoBarComponent = () => {
  const { activeCount, inactiveCount } = useSelector(selector);

  return (
    <Grid container px="26px">
      <Grid item xs={2} />
      <Grid
        item
        xs={8}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          my: "12px",
        }}
      >
        <Box
          sx={{
            minWidth: "152px",
            textAlign: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: "400",
              color: "#9C9C94",
            }}
          >
            Assigned customers
          </Typography>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: "500",
              mt: "-6px",
            }}
            color="#707070"
          >
            {activeCount + inactiveCount}
          </Typography>
        </Box>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Box
          sx={{
            minWidth: "152px",
            textAlign: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: "400",
              color: "#9C9C94",
            }}
          >
            Active customers
          </Typography>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: "500",
              color: "#42A57F",
              mt: "-6px",
            }}
          >
            {activeCount}
          </Typography>
        </Box>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Box
          sx={{
            minWidth: "152px",
            textAlign: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: "400",
              color: "#9C9C94",
            }}
          >
            Inactive customers
          </Typography>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: "500",
              color: "#FF6969",
              mt: "-6px",
            }}
          >
            {inactiveCount}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};
