import { PaperWrapper } from "components";
import CustomerCardDetails from "./components/CustomerCardDetails";
import {
  any,
  array,
  func,
  string,
  object,
  bool,
  number,
  oneOfType,
} from "prop-types";

const CustomerCardInfo = ({
  title,
  items,
  handleAdd,
  titleStyle,
  child,
  wrapperStyle,
  onSubmit,
  onDelete,
  handleEdit,
  isAddress,
  addressTab,
  repsList,
  bottomBlock,
  resetCustomerState,
  loadingItems,
  itemsCount,
  handleFetchItems,
  isReps,
  isParent,
  customer,
  disabledCreateEdit,
}) => {
  return (
    <PaperWrapper {...{ wrapperStyle }}>
      <CustomerCardDetails
        title={title}
        items={items}
        child={child}
        handleAdd={handleAdd}
        titleStyle={titleStyle}
        onSubmit={onSubmit}
        onDelete={onDelete}
        handleEdit={handleEdit}
        isAddress={isAddress}
        isReps={isReps}
        addressTab={addressTab}
        repsList={repsList}
        bottomBlock={bottomBlock}
        resetCustomerState={resetCustomerState}
        loadingItems={loadingItems}
        itemsCount={itemsCount}
        handleFetchItems={handleFetchItems}
        isParent={isParent}
        customer={customer}
        disabledCreateEdit={disabledCreateEdit}
      />
    </PaperWrapper>
  );
};
CustomerCardInfo.propTypes = {
  title: any,
  items: array,
  handleAdd: func,
  titleStyle: string,
  child: any,
  wrapperStyle: object,
  onSubmit: func,
  onDelete: func,
  handleEdit: func,
  isAddress: bool,
  addressTab: number,
  repsList: oneOfType([array, bool]),
  bottomBlock: any,
  resetCustomerState: func,
  loadingItems: bool,
  itemsCount: number,
  handleFetchItems: func,
  isReps: bool,
  isParent: bool,
  customer: any,
  disabledCreateEdit: bool,
};

export default CustomerCardInfo;
