import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  menuList: {
    "& .MuiMenuItem-root": {
      padding: "4.5px 13px",
      "& svg": {
        marginRight: "13px",
      },
      "& .MuiListItemText-root": {
        "& .MuiTypography-root": {
          fontSize: "12px",
          fontWeight: 500,
          "&:not(.deleteItem)": {
            color: "#3F3F3F",
          },
        },
      },
    },
  },
}));

export default useStyles;
