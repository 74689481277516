import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  completeWrap: {
    paddingTop: "25px",
  },

  completeTitle: {
    color: "#6A6A6A",
    fontSize: "22px",
    fontWeight: "700",
    marginBottom: "25px",
  },
  innerWrap: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },

  rowWrap: {
    display: "flex",
    gap: "40px",
    alignItems: "center",
    padding: "15px 22px 14px 32px",
    border: "0.5px solid #D5D9D9",
    borderRadius: "4px",
    minHeight: "76px",
  },

  description: {
    fontSize: "16px",
    color: "#D5D9D9",
  },

  rowTitle: {
    fontSize: "16px",
    color: "#1C1C19",
    fontWeight: "600",
  },

  stepsWrap: { display: "flex", flexDirection: "column", gap: "15px" },

  goButton: {
    width: "97px",
    "& .MuiTypography-root": {
      fontWeight: "500",
    },
  },

  iconWrap: {
    width: "24px",
    height: "24px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default useStyles;
