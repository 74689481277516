/* eslint-disable react/prop-types */
import React from "react";

export const TrashIcon = ({
  color = "currentColor",
  width = 8.306,
  height = 9.967,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 8.306 9.967"
    >
      <g id="icons8-trash-can" transform="translate(1.174)">
        <path
          id="icons8-trash-can-2"
          data-name="icons8-trash-can"
          d="M9.154,4l-.13.117-.3.3H6v.831h.415v7.475a1.252,1.252,0,0,0,1.246,1.246h4.983a1.252,1.252,0,0,0,1.246-1.246V5.246h.415V4.415H11.58l-.3-.3L11.152,4Zm.35.831h1.3l.3.3.13.117h1.83v7.475a.414.414,0,0,1-.415.415H7.661a.414.414,0,0,1-.415-.415V5.246h1.83l.13-.117ZM8.076,6.907v4.568h.831V6.907Zm1.661,0v4.568h.831V6.907Zm1.661,0v4.568h.831V6.907Z"
          transform="translate(-7.174 -4)"
          fill={color}
        />
      </g>
    </svg>
  );
};
