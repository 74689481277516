import { number } from "prop-types";
import moment from "moment";
import { Box, Typography, Link } from "@mui/material";
import { PadlockIcon } from "components/Icons";
import { separateNumWithComma } from "helpers/helpers";

export const InfoBlock = ({ balance }) => {
  return (
    <Box
      sx={{
        marginTop: "15px",
        "& .MuiTypography-root": {
          color: "#707070",
          fontWeight: "300",
        },
      }}
    >
      <Box display={{ xs: "none", md: "flex" }} gap="11px" alignItems="center">
        <PadlockIcon stroke="#5F6267" />
        <Typography fontSize="18px">
          Information is protected and confidential.
        </Typography>
      </Box>
      <Typography
        fontSize={{
          xs: 12,
          md: 14,
        }}
        lineHeight={1.3}
        mt="10px"
      >
        By selecting <span style={{ fontWeight: "600" }}>Pay</span>, I accept{" "}
        <Link underline="always" color="#707070">
          Terms of Service{" "}
        </Link>
        and have read acknowledge{" "}
        <Link underline="always" color="#707070">
          Privacy Policy
        </Link>
        . I also allow Simply Depo to charge ${separateNumWithComma(balance)} on
        my card on {moment(Date.now()).format("MMM DD, YYYY")}.
      </Typography>
    </Box>
  );
};

InfoBlock.propTypes = { balance: number };
InfoBlock.defaultProps = { balance: 0 };
