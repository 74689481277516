import { useMemo, useState } from "react";
import { object, func } from "prop-types";
import {
  CircularProgress,
  Grid,
  MenuItem,
  Typography,
  Box,
} from "@mui/material";

import {
  BankIcon,
  BillingCalendarIcon,
  CardsIcon,
  StripeLogoIcon,
} from "components/Icons";
import {
  StyledSelect,
  StyledButton,
  StyledTextField,
  StyledTooltip,
} from "components";
import useStyles from "./styles";
import { setBankAccountSettingsService } from "services/stripe";
import { error } from "utils/notifications";
import { PAYOUTS_SCHEDULES } from "../../../../../../PaymentsTab.constants";
import { normalizeUppercaseString, useAdmin } from "helpers/helpers";
import { usePersonalDetails } from "../../../../PayoutsTab.hooks";

const PayoutsItem = ({
  bankAccount,
  handleAddAccount,
  handleEditBankAccount,
  settings,
  setSettings,
  handleUpdatePersonalDetails,
}) => {
  const isAdmin = useAdmin();
  const { statusProps, verificationDocLoading } = usePersonalDetails({
    isSetup: false,
  });

  const [editSchedule, setEditSchedule] = useState(false);
  const [editDesc, setEditDesc] = useState(false);
  const [settingsLoading, setSettingsLoading] = useState({
    interval: false,
    descriptor: false,
    schedule: false,
  });

  const { icon, color, status } = useMemo(
    () => statusProps || {},
    [statusProps]
  );

  const classes = useStyles({
    statusColor: color,
  });

  const handleDescriptionClick = () => {
    if (settings.statementDescriptor.length > 22) return;
    if (editDesc) {
      setSettingsLoading((prev) => {
        return { ...prev, descriptor: true };
      });
      setBankAccountSettingsService({
        statementDescriptor: settings.statementDescriptor,
      })
        .then(() => {
          setEditDesc(false);
          setSettingsLoading((prev) => {
            return { ...prev, descriptor: false };
          });
        })
        .catch((err) => {
          error(err?.response?.data?.error);
          setSettingsLoading((prev) => {
            return { ...prev, descriptor: false };
          });
        });
    }
    setEditDesc(true);
  };

  const handleScheduleClick = () => {
    if (editSchedule) {
      setSettingsLoading((prev) => {
        return { ...prev, schedule: true };
      });

      const preparedData = {
        schedule: {
          interval: settings.weeklyAnchor === "day" ? "daily" : "weekly",
          // delayDays: 2,
        },
      };

      if (settings.weeklyAnchor !== "day") {
        preparedData.schedule.weeklyAnchor = settings.weeklyAnchor;
      }

      setBankAccountSettingsService(preparedData)
        .then(() => {
          setEditSchedule(false);
          setSettingsLoading((prev) => {
            return { ...prev, schedule: false };
          });
        })
        .catch((err) => {
          error(err?.response?.data?.error);
          setSettingsLoading((prev) => {
            return { ...prev, schedule: false };
          });
        });
    }
    setEditSchedule(true);
  };

  return (
    <Grid container className={classes.itemWrapper}>
      <Grid container xs={2.5} item>
        <Box className={classes.personalDetails}>
          {verificationDocLoading ? (
            <CircularProgress size={15} />
          ) : (
            <Box
              className={classes.personalStatus}
              data-testid="stripe-verification-status"
            >
              <Box className={classes.statusIcon}>{icon}</Box>
              <Typography>{status}</Typography>
            </Box>
          )}
          <Typography className={classes.descriptionText} whiteSpace="pre-wrap">
            Status of your personal{"\n"}details.
          </Typography>
          <StyledButton
            disabled={isAdmin}
            label="Update personal details"
            variant="outlined"
            color="cancel"
            fontSize="10px"
            sx={{
              color: "#000000",
              height: "24px",
              width: "165px",
              whiteSpace: "nowrap",
            }}
            onClick={handleUpdatePersonalDetails}
          />
        </Box>
      </Grid>
      <Grid container item xs={bankAccount ? 2.5 : 7.5}>
        <Box>
          <Box display="flex" gap="6px" alignItems="center">
            <BankIcon />
            {bankAccount ? (
              <Typography className={classes.blockTitle}>
                Account:{" "}
                <span style={{ color: "#000000" }}>
                  **** {bankAccount?.last4}
                </span>
              </Typography>
            ) : (
              <StyledButton
                disabled={isAdmin}
                label="Add bank account"
                variant="outlined"
                color="cancel"
                fontSize="10px"
                sx={{ ml: "9px", color: "#000000", height: "24px" }}
                onClick={handleAddAccount}
              />
            )}
          </Box>
          {!!bankAccount && (
            <>
              <Typography
                className={classes.descriptionText}
                whiteSpace="pre-wrap"
              >
                {"Payments will be deposited into\nthis bank account."}
              </Typography>
              <StyledButton
                disabled={isAdmin}
                variant="outlined"
                label="Edit bank account"
                color="cancel"
                fontSize="10px"
                onClick={handleEditBankAccount}
                sx={{
                  width: "165px",
                  color: "#000000",
                  height: "24px",
                }}
              />
            </>
          )}
        </Box>
      </Grid>
      {!!bankAccount && (
        <>
          <Grid container item xs={2.5}>
            <Box position="relative">
              {settingsLoading.schedule && (
                <CircularProgress
                  size={16}
                  sx={{ position: "absolute", right: -20, top: -3 }}
                />
              )}
              {editSchedule ? (
                <StyledSelect
                  disabled={isAdmin}
                  value={settings.weeklyAnchor}
                  formSx={{
                    width: "165px",
                    mt: "-6px",
                    "& > .MuiOutlinedInput-root": {
                      height: "24px",
                    },
                  }}
                  height="24px"
                  notched={false}
                  onChange={(e) =>
                    setSettings((prev) => {
                      return { ...prev, weeklyAnchor: e.target.value };
                    })
                  }
                >
                  {PAYOUTS_SCHEDULES.map((day) => (
                    <MenuItem key={day} value={day}>
                      Every{" "}
                      {day === "day" ? day : normalizeUppercaseString(day)}
                    </MenuItem>
                  ))}
                </StyledSelect>
              ) : (
                <Box display="flex" gap="6px" alignItems="center" height="24px">
                  <BillingCalendarIcon />
                  <Typography className={classes.blockTitle}>
                    Payout:{" "}
                    <span style={{ color: "#000000" }}>
                      Every{" "}
                      {settings.weeklyAnchor === "day"
                        ? "day"
                        : normalizeUppercaseString(settings.weeklyAnchor)}
                    </span>
                  </Typography>
                </Box>
              )}
              <Typography
                className={classes.descriptionText}
                whiteSpace="pre-wrap"
              >
                {"Frequency of deposits into your\nbank account."}
              </Typography>
              <StyledButton
                disabled={isAdmin}
                color={editSchedule ? "primary" : "cancel"}
                variant={editSchedule ? "contained" : "outlined"}
                label={
                  editSchedule ? "Save Payout Schedule" : "Edit payout schedule"
                }
                fontSize="10px"
                sx={{
                  width: "165px",
                  color: editSchedule ? "#fff" : "#000",
                  height: "24px",
                }}
                onClick={handleScheduleClick}
              />
            </Box>
          </Grid>
          <Grid container item xs={2.5}>
            <Box>
              {editDesc ? (
                <StyledTextField
                  errorMsgSx={{
                    bottom: -15,
                    fontSize: "10px!important",
                  }}
                  value={settings.statementDescriptor}
                  onChange={(e) =>
                    setSettings((prev) => {
                      return { ...prev, statementDescriptor: e.target.value };
                    })
                  }
                  error={
                    settings.statementDescriptor.length > 22 &&
                    "Max 22 characters"
                  }
                  placeholder="Enter description"
                  formSx={{ width: "165px", mt: "-6px" }}
                  InputProps={{
                    sx: { height: "24px", fontSize: "10px" },
                    endAdornment: settingsLoading.descriptor && (
                      <CircularProgress size={16} />
                    ),
                  }}
                  LabelProps={{ shrink: false }}
                />
              ) : (
                <Box display="flex" gap="6px" alignItems="center" height="24px">
                  <Typography
                    className={classes.blockTitle}
                    maxWidth="85%"
                    lineHeight={1.2}
                  >
                    Description:{" "}
                    <span style={{ color: "#000000" }}>
                      {settings.statementDescriptor || "-"}
                    </span>
                  </Typography>
                </Box>
              )}
              <Typography
                className={classes.descriptionText}
                whiteSpace="pre-wrap"
              >
                {"Payout description on your bank\nstatements."}
              </Typography>
              <StyledTooltip
                arrow
                title="Maximum characters allowed: 22"
                placement="top"
                disableHoverListener={settings.statementDescriptor.length <= 22}
              >
                <Box>
                  <StyledButton
                    color={editDesc ? "primary" : "cancel"}
                    variant={editDesc ? "contained" : "outlined"}
                    label={
                      editDesc ? "Save Descriptor" : "Edit statement descriptor"
                    }
                    fontSize="10px"
                    sx={{
                      width: "165px",
                      color: editDesc ? "#fff" : "#000",
                      height: "24px",
                    }}
                    disabled={
                      isAdmin || settings.statementDescriptor.length > 22
                    }
                    onClick={handleDescriptionClick}
                  />
                </Box>
              </StyledTooltip>
            </Box>
          </Grid>
        </>
      )}
      <Grid container item xs={2}>
        <Box>
          <Box>
            <CardsIcon />
          </Box>
          <Typography
            className={classes.descriptionText}
            whiteSpace="pre-wrap"
            mb="5px !important"
          >
            {"Credit card fee: 2.9% + 30 cent"}
          </Typography>
          <Typography fontSize="9px" color="#D4D4D4" fontWeight={500}>
            per successful card charge
          </Typography>
          <Box display="flex" gap="8px" alignItems="center" mt="17px">
            <Typography fontSize="9px" color="#D4D4D4" fontWeight={500}>
              Powered by
            </Typography>
            <StripeLogoIcon />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

PayoutsItem.propTypes = {
  bankAccount: object,
  handleAddAccount: func,
  settings: object,
  setSettings: func,
  handleChangeDesription: func,
  handleEditBankAccount: func,
  handleUpdatePersonalDetails: func,
};

export default PayoutsItem;
