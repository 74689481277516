import {
  SET_CONFIRM_OPEN,
  SET_CONFIRM_TEXT1,
  SET_CONFIRM_TITLE,
  SET_CONFIRM_USERNAME,
  SET_CONFIRM_TEXT2,
  SET_CONFIRM_ICON,
  SET_CONFIRM_BUTTONS,
  SET_FORM_CHANGED,
  SET_EDIT_ITEM,
  SET_EDIT_TYPE,
  SET_CONFIRM_SAVE,
  SET_CONFIRM_CENTERED,
  SET_CONFIRM_PATH,
  SET_CONFIRM_DIALOG_STYLES,
  SET_CONFIRM_DIALOG_BUTTONS_STYLES,
  SET_CONFIRM_IS_CANCEL_STYLES,
  SET_CONFIRM_PROP_BTNS,
} from "../actions/confirmDialogs";

const initialState = {
  title: null,
  text1: null,
  userName: null,
  text2: null,
  isOpen: false,
  isIcon: false,
  buttons: null,
  propBtns: {},
  formChanged: false,
  editItem: null,
  editType: "",
  onSave: () => {},
  centered: false,
  path: "",
  dialogStyles: {},
  dialogButtonsStyles: {},
  isCancel: false,
  createMode: false,
};

const confirmDialogsReducer = (
  state = initialState,
  { type, payload, createMode = false }
) => {
  switch (type) {
    case SET_CONFIRM_OPEN:
      return { ...state, isOpen: payload };

    case SET_CONFIRM_TITLE:
      return { ...state, title: payload };

    case SET_EDIT_ITEM:
      return { ...state, editItem: payload };

    case SET_EDIT_TYPE:
      return { ...state, editType: payload, createMode };

    case SET_CONFIRM_TEXT1:
      return { ...state, text1: payload };

    case SET_CONFIRM_USERNAME:
      return { ...state, userName: payload };

    case SET_CONFIRM_TEXT2:
      return { ...state, text2: payload };

    case SET_CONFIRM_ICON:
      return { ...state, isIcon: payload };

    case SET_CONFIRM_BUTTONS:
      return { ...state, buttons: payload };

    case SET_CONFIRM_PROP_BTNS:
      return { ...state, propBtns: payload };

    case SET_CONFIRM_CENTERED:
      return { ...state, centered: payload };

    case SET_FORM_CHANGED:
      return { ...state, formChanged: payload };

    case SET_CONFIRM_SAVE:
      return { ...state, onSave: payload };

    case SET_CONFIRM_PATH:
      return { ...state, path: payload };

    case SET_CONFIRM_DIALOG_STYLES:
      return { ...state, dialogStyles: payload };

    case SET_CONFIRM_DIALOG_BUTTONS_STYLES:
      return { ...state, dialogButtonsStyles: payload };

    case SET_CONFIRM_IS_CANCEL_STYLES:
      return { ...state, isCancel: payload };

    default:
      return state;
  }
};

export default confirmDialogsReducer;
