import { useState } from "react";
import {} from "prop-types";
import { Box, Button, Stack, Typography } from "@mui/material";
import {
  CreateFolderDialog,
  HeaderFilesList,
  ItemFilesList,
} from "./components";

import { useFilesActions } from "./useFilesActions";
import { useDispatch } from "react-redux";
import {
  openConfirmDialogAction,
  setConfirmIsOpenAction,
} from "../../../../redux/actions/confirmDialogs";
import { DangerIcon } from "../../../../components/Icons";
import { useLocation } from "react-router-dom";

export const FilesTab = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const { filesList, handleDeleteFileOrBtn } = useFilesActions();
  const [editFile, setEditFile] = useState(null);
  const [checkedCustomers] = useState([]);

  const [isOpenRenameItemDialog, setOpenRenameItemDialog] = useState(false);

  const handleCloseRenameItemDialog = () => {
    setOpenRenameItemDialog(false);
  };

  const handleRename = (file) => {
    setEditFile(file);
    setOpenRenameItemDialog(true);
  };

  const handleDeleteFile = (fileId) => {
    handleDeleteFileOrBtn(fileId);
  };

  const handleDeleteDialog = (file) => {
    dispatch(
      openConfirmDialogAction({
        title: (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              maxWidth: "420px",
            }}
          >
            <DangerIcon />
            <Box ml="11px" component={Typography} noWrap width="80%">
              Delete {file?.fileName}?
            </Box>
          </Box>
        ),
        text: (
          <Typography fontSize={15} fontWeight={400} whiteSpace="pre-line">
            {`Are you sure you want to delete this ${
              file?.type === "folder" ? "folder" : "file"
            }?`}
            {"\n"}
            <span style={{ marginTop: "3px" }}>
              {`This ${
                file?.type === "folder" ? "folder" : "file"
              } will be erased and this can't be undone.`}
            </span>
          </Typography>
        ),
        buttons: (
          <>
            <Button
              sx={{
                width: "98px",
                color: "#6A6A6A",
                borderColor: "#D4D4D4",
                fontSize: "13px",
                height: "28px",
              }}
              onClick={() => {
                dispatch(setConfirmIsOpenAction(false));
              }}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              sx={{
                width: "98px",
                color: "#FFFFFF",
                fontSize: "13px",
                height: "28px",
                boxShadow: "none",
              }}
              color="confirmDelete"
              onClick={() => {
                dispatch(setConfirmIsOpenAction(false));
                handleDeleteFile(file?.id);
              }}
              variant="contained"
            >
              Confirm
            </Button>
          </>
        ),
      })
    );
  };

  return filesList?.length ? (
    <>
      <CreateFolderDialog
        open={isOpenRenameItemDialog}
        onClose={handleCloseRenameItemDialog}
        editData={editFile}
      />
      {["folder", "manage"].includes(pathname.split("/")?.[2]) ? null : (
        <Box px={4}>
          <HeaderFilesList checkedCustomers={checkedCustomers} />
          <Stack
            sx={{
              border: "0.5px solid #D5D9D9",
              borderRadius: "0 0 4px 4px",
              maxHeight: "calc(100vh - 190px)",
              overflow: "auto",
            }}
          >
            {filesList.map((file) => (
              <ItemFilesList
                key={file?.id}
                file={file}
                handleRename={handleRename}
                onDeleteItem={handleDeleteDialog}
              />
            ))}
          </Stack>
        </Box>
      )}
    </>
  ) : null;
};

FilesTab.propTypes = {};
FilesTab.defaultProps = {};
