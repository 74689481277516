import React from "react";
/* eslint-disable react/prop-types */
export const BoxIcon = ({ fill, ...props }) => {
  return (
    <svg
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18.002 16"
      {...props}
    >
      <path
        id="Union_144"
        data-name="Union 144"
        d="M1,16V5H17V16ZM0,4V0H18V4Z"
      />
    </svg>
  );
};
