import {
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Typography,
  Box,
} from "@mui/material";
import { StyledButton } from "components";
import { CrossIcon } from "components/Icons";
import { bool, func } from "prop-types";

const EmptyCartPopup = ({ isOpen, handleClose, onSubmit }) => {
  return (
    <Dialog open={isOpen} scroll="body">
      <Box
        sx={{
          width: "450px !important",
        }}
      >
        <Box>
          <IconButton
            sx={{ position: "absolute", top: 14, right: 14, p: 0 }}
            onClick={handleClose}
          >
            <CrossIcon />
          </IconButton>
          <Typography
            p="10px 30px 10px 22px"
            fontWeight="400"
            fontSize="20px"
            color="#080808"
            letterSpacing="0.5px"
            marginBottom="8px"
          >
            Empty your cart?
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              fontSize: "15px",
              borderBottom: "0.5px solid #CCCCCC",
              color: "#707070",
              p: "0px 30px 10px 22px",
            }}
          >
            Another draft order is in process. Empty your cart to start a new
            order.
          </Typography>
        </Box>

        <DialogContent sx={{ p: "10px 22px 0 47px", minWidth: "413px" }}>
          <Grid display="flex" sx={{ flexDirection: "row-reverse" }} container>
            <StyledButton
              sx={{
                height: 28,
                width: 85,
                mb: "13px",
                mr: "5px",
                boxShadow: "none",
              }}
              onClick={onSubmit}
              label={
                <Typography fontSize="13px" fontWeight="normal" color="#fffff">
                  Proceed
                </Typography>
              }
              variant="contained"
            />
            <StyledButton
              sx={{ height: 28, width: 85, mr: "10px", borderColor: "#D4D4D4" }}
              onClick={handleClose}
              label={
                <Typography
                  fontSize="13px"
                  sx={{
                    color: "#6A6A6A",
                  }}
                  fontWeight="normal"
                >
                  Cancel
                </Typography>
              }
              variant="outlined"
            />
          </Grid>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

EmptyCartPopup.propTypes = {
  isOpen: bool,
  handleClose: func,
  onSubmit: func,
};

EmptyCartPopup.defaultProps = {
  isOpen: false,
};

export default EmptyCartPopup;
