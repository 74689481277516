import * as React from "react";
export const RightArrowUpIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={8.973}
    height={8.904}
    viewBox="0 0 8.973 8.904"
    {...props}
  >
    <g
      data-name="RightArrowUpIcon"
      fill="none"
      stroke="#47a06d"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.3}
    >
      <path data-name="Path 3848" d="M4.109 4.826.65 8.255 8.05.92" />
      <path data-name="Path 3849" d="M8.054 6.775V.92H1.983" />
    </g>
  </svg>
);
