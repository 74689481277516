import { string, number, object, bool, func } from "prop-types";
import { Controller } from "react-hook-form";
import { Grid, Typography } from "@mui/material";
import { LargeCheckbox } from "../../../../../components/Checkboxes";
import { StyledTextField } from "../../../../../components/TextFields/TextFields";
import { makeStyles } from "@mui/styles";
import { separateNumWithComma } from "../../../../../helpers/helpers";

const useStyles = makeStyles(() => ({
  textInput: {
    fontSize: "12px",
    fontWeight: 400,
    color: "#000000",
    height: "30px",
  },
}));

const PaymentItem = ({
  type,
  title,
  sum,
  control,
  setValue,
  index,
  isChecked,
}) => {
  const classes = useStyles();
  return (
    <Grid
      sx={{
        width: "100%",
        border: "1px solid #DBE2ED",
        borderRadius: "4px",
        backgroundColor: isChecked ? "rgba(66, 165, 127, 0.12)" : "#FFF",
      }}
      item
      container
    >
      <Grid
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "60px",
        }}
        xs={1.3}
        item
      >
        <Controller
          render={({ field }) => (
            <LargeCheckbox
              formSx={{ margin: 0 }}
              checked={field.value}
              size={16}
              {...field}
            />
          )}
          name={`paymentRefund[${index}].isRefund`}
          control={control}
        />
      </Grid>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
        xs
        item
      >
        <Typography sx={{ fontSize: 12, fontWeight: 700, color: "#363531" }}>
          {type}
        </Typography>
        <Typography sx={{ fontSize: 12, color: "#363531" }}>{title}</Typography>
        <Typography sx={{ fontSize: 12, fontWeight: 400 }}>
          Amount paid: ${separateNumWithComma(sum)}
        </Typography>
      </Grid>
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
        }}
        xs={3.2}
        item
      >
        <Controller
          render={({ field, fieldState: { error } }) => (
            <StyledTextField
              sx={{
                width: "90%",
                backgroundColor: "#FFF",
                borderRadius: "4px",
              }}
              size="small"
              InputProps={{
                className: classes.textInput,
                startAdornment: "$",
              }}
              error={!!error}
              {...field}
              onChange={(e) => {
                const v = e.target.value.trim();
                if (+v >= 0 && +v <= +sum) {
                  setValue(`paymentRefund[${index}].amount`, v);
                }
              }}
            />
          )}
          name={`paymentRefund[${index}].amount`}
          control={control}
        />
      </Grid>
    </Grid>
  );
};
PaymentItem.propTypes = {
  type: string,
  title: string,
  sum: string,
  control: object,
  setValue: func,
  index: number,
  isChecked: bool,
};

export default PaymentItem;
