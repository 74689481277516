import { useCallback, useMemo } from "react";
import { string, object, number, bool, func } from "prop-types";
import {
  // Collapse,
  // Divider,
  Grid,
  Paper,
  Typography,
  Box,
  Skeleton,
} from "@mui/material";
// import { ArrowDownInCircle, ArrowUpInCircle } from "../../../components/Icons";
import { separateNumWithComma, truncateText } from "../../../helpers/helpers";
import { StyledTooltip } from "components";

const SmallWidget = ({
  title,
  value,
  icon,
  // previousSum,
  // previousPeriod,
  // previousPercent,
  currentFilterDate,
  isLoading,
  notAvailable,
  onClick,
}) => {
  const prepareText = useCallback(
    (value) => {
      switch (title) {
        case "Sales (USD)":
          return separateNumWithComma(value);
        case "New Customers":
          return `+${value}`;

        default:
          return value;
      }
    },
    [title]
  );

  const setName = useMemo(() => {
    return `${title === "Sales (USD)" ? "$ " : ""}${prepareText(value)}`;
  }, [prepareText, title, value]);

  // const setFontSize = useCallback(
  //   (value) => {
  //     if (!value || title !== "Sales (USD)") return "24px";

  //     return "clamp(12px, 13cqw, 24px)";
  //   },
  //   [title]
  // );

  const styles = useMemo(
    () => ({
      title: {
        fontSize: "clamp(11px, 8cqw, 14px)",
        color: "#1C1C19",
      },
      subTitle: {
        fontSize: "12px",
        fontWeight: 400,
        color: "#9C9C94",
      },
      value: {
        fontSize: "clamp(12px, 13cqw, 24px)",
        fontWeight: 600,
        color: "#000000",
      },

      notAvailable: {
        color: "#C6C6C6",
        fontSize: "12px",
        marginTop: "6px",
      },
    }),
    []
  );

  return (
    <Paper
      sx={{
        border: "1px solid #D5D9D9",
        pl: "8%",
        pr: "2%",
        py: 2.3,
        minHeight: "96px",
        cursor: onClick ? "pointer" : "default",
      }}
      elevation={0}
      onClick={onClick}
    >
      <Grid flexWrap="nowrap" container>
        <Grid lineHeight={0} mr="8%" item>
          {icon}
        </Grid>
        <Grid
          sx={{
            whiteSpace: "nowrap",
            containerType: "inline-size",

            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
          item
          flexDirection="column"
          xs={12}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            {" "}
            <StyledTooltip
              isDark
              title={title}
              followCursor
              placement="top-start"
              // style={{ cursor: "pointer" }}
            >
              <Typography
                sx={styles.title}
                // noWrap
              >
                {title}
              </Typography>
            </StyledTooltip>
            {[""].includes(currentFilterDate) && (
              <Typography sx={styles.subTitle} noWrap>
                {currentFilterDate}
              </Typography>
            )}
          </Box>

          {isLoading ? (
            <Skeleton
              sx={{
                bgcolor: "#F5F5F5",
                fontSize: 24,
                maxWidth: 137,
              }}
              variant="text"
            />
          ) : (
            <StyledTooltip
              title={`${title === "Sales (USD)" ? "$" : ""} ${prepareText(
                value
              )}`}
              followCursor
              placement="top-start"
              // cursor="pointer"
              // style={{ cursor: "pointer" }}
            >
              {notAvailable ? (
                <Typography sx={styles.notAvailable}>Not available</Typography>
              ) : (
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={styles.value}
                    // noWrap
                  >
                    {" "}
                    {truncateText(setName, 12)}
                  </Typography>
                </Box>
              )}
            </StyledTooltip>
          )}
        </Grid>
      </Grid>
      {/* <Collapse in={[""].includes(currentFilterDate)}>
        <Divider sx={{ marginY: "20px" }} />
        <Grid container>
          <Grid
            sx={{ justifyContent: "space-between", alignItems: "center" }}
            xs={12}
            item
            container
          >
            <Grid item>
              <Typography
                sx={{ fontSize: 16, fontWeight: 600, color: "#000000" }}
              >
                {(title === "Sales (USD)" &&
                  `$${separateNumWithComma(previousSum)}`) ||
                  (title === "New Customers" && `+${previousSum}`) ||
                  previousSum}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                sx={{ fontSize: 12, fontWeight: 400, color: "#9C9C94" }}
              >
                Last week
              </Typography>
            </Grid>
          </Grid>
          <Grid
            sx={{
              alignItems: "center",
              gap: 1,
              color: previousPeriod === "increase" ? "#409A65" : "#FF6254",
            }}
            xs={12}
            item
            container
          >
            {previousPeriod === "increase" ? (
              <ArrowUpInCircle color="currentColor" />
            ) : (
              <ArrowDownInCircle color="currentColor" />
            )}
            <Typography>{previousPercent}%</Typography>
          </Grid>
        </Grid>
      </Collapse> */}
    </Paper>
  );
};

SmallWidget.propTypes = {
  title: string,
  value: number,
  icon: object,
  currentFilterDate: string,
  isLoading: bool,
  notAvailable: bool,
  onClick: func,
};

SmallWidget.defaultProps = {
  title: "",
  value: 0,
  notAvailable: false,
};

export default SmallWidget;
