import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  completeRegistrationService,
  confirmationInfoService,
} from "services/account";
import { error } from "utils/notifications";
import { initialErrPhoneStatus } from "../RegistrationPage.constants";

export const useRegActions = ({ formData, state }) => {
  const navigate = useNavigate();

  const { setValue, formField, setError } = formData;

  const {
    distributor: { confirmationSubscriptionDraft },
    token,
  } = formField ?? {};

  const [regState, setRegState] = useState({
    loading: false,
  });

  const [errPhoneStatus, setErrPhoneStatus] = useState(initialErrPhoneStatus);

  const setLoading = useCallback((v) => {
    setRegState((prev) => ({
      ...prev,
      loading: v,
    }));
  }, []);

  const hasDistributorTrial = useMemo(() => {
    return confirmationSubscriptionDraft?.subscription?.trial;
  }, [confirmationSubscriptionDraft?.subscription?.trial]);

  const finishSetAccount = async () => {
    navigate("/login");
  };

  const fetchConfirmationInfo = () => {
    setLoading(true);
    confirmationInfoService(state?.confirmation_token)
      .then((res) => {
        const { distributor, token } = res ?? {};
        const { confirmationProgress, confirmationSubscriptionDraft } =
          distributor ?? {};
        if (distributor?.billingAddress)
          setValue("billingAddress", distributor?.billingAddress);
        setValue("distributor", distributor);
        setValue("token", token);

        if (confirmationProgress === "NONE") return setValue("step", 1);
        if (confirmationProgress === "CARD_ADDED") return setValue("step", 3);
        if (confirmationProgress === "DETAILS_CONFIRMED") {
          if (confirmationSubscriptionDraft?.subscription?.trial === true) {
            return setValue("step", 3);
          }
          return setValue("step", 2.1);
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        error(err?.response?.data?.message || "Something went wrong.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!state?.confirmation_token) return;
    fetchConfirmationInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (data) => {
    const {
      password,
      name,
      website,
      industry,
      timeZone,
      billingAddress,
      countryPrefics,
      phone,
      tax,
      profilePhoto,
      cid,
      country,
    } = data;
    setLoading(true);
    try {
      const uploadData = {
        password,
        name,
        website,
        companyIndustry: industry,
        timeZone,
        billingAddress,
        phone: `${countryPrefics?.phone_code}${phone}`.replace(/\s|\(|\)/g, ""),
        federalTaxId: parseInt(tax?.replace("-", ""), 10) || null,
        profilePhotoId: profilePhoto?.id,
        cid,
        country,
      };
      await completeRegistrationService(uploadData, state?.confirmation_token);
      hasDistributorTrial ? setValue("step", 3) : setValue("step", 2.1);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err?.response?.data?.message);
      if (/phone/.test(err?.response?.data?.message)) {
        setError("phone", {
          type: "custom",
          message: "Not valid phone number!",
        });

        setErrPhoneStatus((prev) => ({
          ...prev,
          status: true,
          message: "Not valid phone number!",
          value: formField?.phone,
        }));
      } else {
        error(err?.response?.data?.message || "Something went wrong.");
      }
    } finally {
      setLoading(false);
    }
  };

  return {
    regState,
    setLoading,
    onSubmit,
    errPhoneStatus,
    setErrPhoneStatus,
    finishSetAccount,
    fetchConfirmationInfo,
    token,
  };
};
