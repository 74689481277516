import { useState } from "react";
import { array, func } from "prop-types";
import { Grid, Typography } from "@mui/material";
import { ScrollControlWrapper, StyledButton } from "components";
import { setPhoneNumberMask } from "helpers/helpers";

export const ContactsBlock = ({ contacts, onEdit, onRemove }) => {
  const [hoveredElement, setHoveredElement] = useState(null);

  const setOpacity = (contact) => {
    if (hoveredElement) {
      if (!!hoveredElement?.id && hoveredElement?.id === contact?.id) return 1;
      if (!!hoveredElement?.fid && hoveredElement?.fid === contact?.fid)
        return 1;
      return 0;
    }
    return 0;
  };

  return (
    <ScrollControlWrapper
      dataLength={contacts?.length}
      maxHeight="226px"
      id="contacts-scroll-wrapper"
      component={Grid}
      gap={1}
      container
    >
      {contacts?.map((contact) => {
        const { role, name, email, phone, contactRole } = contact || {};

        return (
          <Grid
            key={`${contact?.name}-${contact?.number}-${contact?.id}`}
            sx={{
              border: "0.5px solid #D5D9D9",
              borderRadius: "4px",
              bgcolor: "#F8F8F8",
              position: "relative",
            }}
            xs={12}
            item
            container
            onMouseEnter={() => {
              setHoveredElement(contact);
            }}
            onMouseLeave={() => setHoveredElement(null)}
          >
            <Grid
              sx={{
                fontSize: 13,
                color: "#5F6267",
                pt: "10px",
                pb: "8px",
                pl: "12px",
              }}
              xs={10}
              item
              container
            >
              <Grid
                sx={{
                  fontSize: 13,
                  fontWeight: 700,
                  color: "#5F6267",
                }}
                xs={12}
                item
                component={Typography}
                noWrap
              >
                {contactRole?.name || role}
              </Grid>

              <Grid
                sx={{
                  fontSize: 13,
                  color: "#5F6267",
                }}
                xs={12}
                item
                component={Typography}
                noWrap
              >
                {name}
              </Grid>

              <Grid
                sx={{
                  fontSize: 13,
                  color: "#5F6267",
                }}
                xs={12}
                item
                component={Typography}
                noWrap
              >
                {email}
              </Grid>

              {!!phone && (
                <Grid
                  sx={{
                    fontSize: 13,
                    color: "#5F6267",
                  }}
                  xs={12}
                  item
                  component={Typography}
                  noWrap
                >
                  {setPhoneNumberMask(phone)}
                </Grid>
              )}
            </Grid>

            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                opacity: setOpacity(contact),
              }}
              xs={2}
              item
            >
              <StyledButton
                sx={{
                  mt: 1,
                  borderColor: "#D4D4D4",
                  color: "#000",
                  maxWidth: "55px",
                  minWidth: "55px",
                  height: "20px",
                  paddingLeft: "0px",
                  paddingRight: "0px",
                }}
                label="Edit"
                variant="outlined"
                color="ground"
                fontSize="10px"
                fontWeight="500"
                onClick={() => onEdit(contact)}
              />
              <StyledButton
                sx={{
                  mt: 1,
                  borderColor: "#D4D4D4",
                  color: "#000",
                  maxWidth: "55px",
                  minWidth: "55px",
                  height: "20px",
                  paddingLeft: "0px",
                  paddingRight: "0px",
                }}
                label="Remove"
                variant="outlined"
                color="ground"
                fontSize="10px"
                fontWeight="500"
                onClick={() => onRemove(contact)}
              />
            </Grid>
            {contact.defaultContact && (
              <Typography
                fontSize="9px"
                sx={{
                  position: "absolute",
                  top: "calc(50% - 9px)",
                  right: "8px",
                  opacity: setOpacity(contact) === 1 ? 0 : 1,
                  p: "2px 4px",
                  color: "#FFFFFF",
                  borderRadius: " 4px",
                  background: "#47A06D",
                }}
              >
                PRIMARY
              </Typography>
            )}
          </Grid>
        );
      })}
    </ScrollControlWrapper>
  );
};

ContactsBlock.propTypes = { contacts: array, onEdit: func, onRemove: func };
ContactsBlock.defaultProps = {
  contacts: [],
  onEdit: () => {},
  onRemove: () => {},
};
