import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Button, Box, MenuItem, Typography } from "@mui/material";
import {
  array,
  object,
  string,
  func,
  bool,
  number,
  oneOfType,
  oneOf,
} from "prop-types";
import { DangerIcon } from "components/Icons";
import { StyledSelect } from "components";
import {
  setConfirmButtonsAction,
  setConfirmIsOpenAction,
} from "redux/actions/confirmDialogs";
import {
  UNCATEGORIZED_TERRITORY,
  UNCATEGORIZED_CATEGORY,
} from "utils/constants";
import {
  selectedChildrensOfProductsAction,
  selectedProductsAction,
} from "redux/actions/products";

const ConfirmNewInstanceText = ({
  item,
  instanceListProp,
  instance,
  handleChangeInstance,
  hideCurrent,
  buttonsConfig,
  dataCount,
  handleFetch,
  dataLength,
}) => {
  const dispatch = useDispatch();

  const changeProductCategory = useCallback(
    (categoryId) => {
      if (categoryId === "Uncategorized") item.categoryId = null;
      if (categoryId !== null && categoryId !== "Uncategorized")
        item.categoryId = categoryId;
      return item;
    },
    [item]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const arrayIds = [];

  const subTerritories = [];
  function getSubTerritories(data) {
    if (Array.isArray(data)) {
      data.forEach((value) => {
        subTerritories.push(value);
        if (Array.isArray(value.children)) {
          getSubTerritories(value.children);
        }
      });
    }
  }
  if (instance === "main_territory" || instance === "territory") {
    getSubTerritories(
      instanceListProp?.filter((territory) => territory?.id !== item?.id)
    );
    instanceListProp = [].concat(subTerritories);
  }

  const changeConfirmButtons = useCallback(
    (e) => {
      dispatch(
        setConfirmButtonsAction(
          <>
            <Button
              sx={{
                width: "98px",
                color: "#6A6A6A",
                borderColor: "#D4D4D4",
                fontSize: "13px",
                height: "28px",
                ml: buttonsConfig.ml,
              }}
              onClick={() => {
                dispatch(setConfirmIsOpenAction(false));
              }}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              sx={{
                width: "98px",
                color: "#FFFFFF",
                fontSize: "13px",
                height: "28px",
                boxShadow: "none",
              }}
              color={buttonsConfig.mainColor || "primary"}
              onClick={() => {
                dispatch(setConfirmIsOpenAction(false));
                dispatch(selectedProductsAction([]));
                dispatch(selectedChildrensOfProductsAction([]));
                if (item?.children) {
                  handleChangeInstance(
                    item?.id ? [item?.id] : item,
                    e.target.value,
                    arrayIds
                  );
                } else {
                  handleChangeInstance(
                    item?.id ? [item?.id] : item,
                    instance === "category"
                      ? changeProductCategory(e.target.value)
                      : e.target.value
                  );
                }
              }}
              variant="contained"
            >
              {buttonsConfig.mainLabel || "Confirm"}
            </Button>
          </>
        )
      );
    },
    [
      dispatch,
      handleChangeInstance,
      instance,
      item,
      arrayIds,
      changeProductCategory,
      buttonsConfig,
    ]
  );

  const setGroupName = useMemo(() => {
    if (!Array.isArray(item)) return item?.group?.name;

    const setUncategorized = (name) => (name === "" ? "Uncategorized" : name);

    const items = item?.length
      ? [
          ...new Set(
            item.map((el) => setUncategorized(el ? el?.group?.name : ""))
          ),
        ]
      : [];

    const groupNames = [...items];

    if (groupNames.length === 1) return groupNames[0];

    if (groupNames.length > 1) return "Multiple choice";
  }, [item]);

  const setGroupValue = useMemo(() => {
    if (!Array.isArray(item)) return item?.group?.id;

    const items = item?.length ? item.map((el) => el?.group?.id) : [];

    const groupIds = [...items];

    return groupIds[0];
  }, [item]);

  const setPaymentTermName = useMemo(() => {
    const setUncategorized = (name) => (name ? name : "Uncategorized");

    if (!Array.isArray(item))
      return setUncategorized(item?.paymentTermsDuplicate?.name);

    const payTermsOfCheckedCustomers = [
      ...new Set(item.map((customer) => customer?.paymentTermsDuplicate?.name)),
    ];

    return payTermsOfCheckedCustomers?.length > 1
      ? "Multiple choice"
      : setUncategorized(payTermsOfCheckedCustomers[0]);
  }, [item]);

  const setTerritoryValue = useMemo(() => {
    const isMultipleChoice = item?.length > 1;

    const defaultTerritory = UNCATEGORIZED_TERRITORY.id;

    if (isMultipleChoice) {
      const haveItemsSameTerritory = item?.every(
        (i) => i?.territory?.id === item?.[0]?.territory?.id
      );
      return haveItemsSameTerritory
        ? item?.[0]?.territory?.id
        : defaultTerritory;
    }

    const currentTerritory = item?.[0]?.territory?.id || item?.territory?.id;

    return currentTerritory || defaultTerritory;
  }, [item]);

  const setPaymentTermValue = useMemo(() => {
    const isMultipleChoice = item?.length > 1;

    const defaultTerm =
      instanceListProp?.find((term) => term?.defaultTerm)?.id || "";
    const currentTerm = instanceListProp.find(
      (el) => el?.id === item?.[0]?.paymentTermsDuplicate?.paymentTermsId
    )
      ? item?.[0]?.paymentTermsDuplicate?.paymentTermsId
      : "";

    if (isMultipleChoice) {
      const haveItemsSameTerm = item?.every(
        (i) =>
          i?.paymentTermsDuplicate?.paymentTermsId ===
          item?.[0]?.paymentTermsDuplicate?.paymentTermsId
      );

      const currentTerm = instanceListProp.find(
        (el) => el?.id === item?.[0]?.paymentTermsDuplicate?.paymentTermsId
      );

      return haveItemsSameTerm ? currentTerm?.id || defaultTerm : defaultTerm;
    }

    return currentTerm || defaultTerm;
  }, [instanceListProp, item]);

  const renderContent = (instance) => {
    switch (instance) {
      case "group": {
        return (
          <Typography fontSize="12px" color="#363531">
            Current {instance}:
            {
              <span style={{ marginLeft: "10px", fontWeight: 400 }}>
                {setGroupName}
              </span>
            }
          </Typography>
        );
      }
      case "territory":
        return (
          <Box display="flex">
            <DangerIcon size="15.89" />
            <Typography fontSize="12px" color="#363531">
              <span style={{ marginLeft: "11px" }}>
                All current assigned{" "}
                {instance === "territory" ? "territories" : instance}s will be
                reassigned.
              </span>
            </Typography>
          </Box>
        );
      case "main_territory":
        return (
          <Box display="flex">
            <Typography fontSize="12px" color="#363531">
              <span>
                This territory{" "}
                <span style={{ color: "#47A06D" }}>{item?.name}</span> has{" "}
                {item?._count?.customer || 0} assigned customers.
              </span>
              <br />
              <span>
                Please select a new territory to reassign all customers.
              </span>
            </Typography>
          </Box>
        );
      case "category":
        return (
          <Typography fontSize="12px" color="#363531">
            Current {instance}:
            {
              <span style={{ marginLeft: "10px", fontWeight: 400 }}>
                {(item?.category && item?.category?.name) || "Uncategorized"}
              </span>
            }
          </Typography>
        );
      case "payment term":
        return (
          <Typography fontSize="12px" color="#363531">
            Current {instance}:
            {
              <span style={{ marginLeft: "10px", fontWeight: 400 }}>
                {setPaymentTermName}
              </span>
            }
          </Typography>
        );
    }
  };

  const renderUncategorizedItem = (instance) => {
    switch (instance) {
      case "territory":
        return (
          <MenuItem value={UNCATEGORIZED_TERRITORY.id}>
            {UNCATEGORIZED_TERRITORY.name}
          </MenuItem>
        );
      case "main_territory":
        return (
          <MenuItem value={UNCATEGORIZED_TERRITORY.id}>
            {UNCATEGORIZED_TERRITORY.name}
          </MenuItem>
        );
      case "category":
        return (
          <MenuItem value={UNCATEGORIZED_CATEGORY.id}>
            {UNCATEGORIZED_CATEGORY.name}
          </MenuItem>
        );
    }
  };

  const renderDefaultValues = (instance) => {
    switch (instance) {
      case "group":
        return setGroupValue;
      case "territory":
        return setTerritoryValue;
      case "main_territory":
        return item?.territory?.id || UNCATEGORIZED_TERRITORY.id;
      case "category":
        return item?.category?.id || UNCATEGORIZED_CATEGORY.id;
      case "payment term":
        return setPaymentTermValue;
    }
  };

  const setFormLabelSelect = useMemo(() => {
    let name = "";

    if (instance === "main_territory") name = "territory";

    return `New ${name || instance}:`;
  }, [instance]);

  return (
    <Box>
      {!hideCurrent && renderContent(instance)}
      <Typography fontSize="12px" color="#363531" mt="12px" mb="6px">
        {setFormLabelSelect}
      </Typography>
      <StyledSelect
        fullWidth
        defaultValue={renderDefaultValues(instance)}
        fontSize="13px"
        color="#363531"
        onChange={changeConfirmButtons}
        dataCount={dataCount}
        dataLength={dataLength}
        // dataLength={instanceList?.length}
        handleFetch={handleFetch}
      >
        {renderUncategorizedItem(instance)}
        {instanceListProp?.map((item) => {
          return (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          );
        })}
      </StyledSelect>
    </Box>
  );
};

ConfirmNewInstanceText.propTypes = {
  instanceListProp: oneOfType([array, oneOf(["productCategoriesActiveList"])]),
  item: oneOfType([object, array]),
  instance: string,
  handleChangeInstance: func,
  hideCurrent: bool,
  buttonsConfig: object,
  dataCount: number,
  handleFetch: func,
  dataLength: number,
};

ConfirmNewInstanceText.defaultProps = {
  instanceListProp: [],
  item: null,
  checkedCustomersWithAll: [],
  instance: "group",
  hideCurrent: false,
  buttonsConfig: {},
};

export default ConfirmNewInstanceText;
