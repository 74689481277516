import { useCallback } from "react";
import { string } from "prop-types";
import { useDispatch } from "react-redux";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Typography,
  Box,
} from "@mui/material";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { CrossIcon } from "../Icons";
import StyledButton from "../StyledButton";
import useStyles from "./styles";
import { StyledTextField } from "../TextFields/TextFields";
import { createTagAction, updateTagAction } from "redux/actions/tags";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema } from "./NewTagComponent.validations";
import { useCheckUniqueTag } from "./useCheckUniqueTag";

const NewTagComponent = ({ type, navigatePath }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { state: editTag, state } = useLocation();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      id: editTag?.id || "",
      tag: editTag?.tag || "",
    },
    resolver: yupResolver(validationSchema()),
  });

  const { tag } = useWatch({ control });

  const { isUnique } = useCheckUniqueTag({
    tag,
    editTag,
    setError,
    type,
  });

  const onSubmit = useCallback(
    (data) => {
      const { id, ...uploadData } = data;
      if (data.id) dispatch(updateTagAction(data.id, uploadData, type));
      else dispatch(createTagAction(uploadData, type));
      navigate(-1);
    },
    [dispatch, navigate, type]
  );

  return (
    <Dialog sx={{ ".MuiPaper-root": { boxShadow: "none" } }} open>
      <form id="customer-tag-form" onSubmit={handleSubmit(onSubmit)}>
        <Box borderBottom="0.5px solid #CCCCCC" p="11px 0 7px 24px">
          <Grid sx={{ position: "absolute", top: 8, right: 9 }} item>
            <IconButton
              onClick={() => navigate(navigatePath, { state: state })}
            >
              <CrossIcon />
            </IconButton>
          </Grid>
          <Typography fontWeight="400" fontSize="20px" color="#5F6267">
            {editTag?.id
              ? "Rename tag"
              : `New ${type[0].toUpperCase() + type.substring(1)} Tag`}
          </Typography>
        </Box>

        <DialogContent sx={{ p: "8px 21px 8px 24px", minWidth: "340px" }}>
          <Grid display="flex" sx={{ flexDirection: "column" }} container>
            <Grid xs={12} item>
              <Typography
                fontWeight="400"
                fontSize="17px"
                color="primary"
                noWrap
              >
                {editTag?.tag}
              </Typography>
            </Grid>

            <Controller
              render={({ field, fieldState: { error } }) => (
                <StyledTextField
                  fullWidth
                  formSx={{ mt: 1, mb: "6px" }}
                  size="small"
                  InputProps={{
                    className: classes.textInput,
                  }}
                  InputLabelProps={{
                    className: classes.inputLabel,
                  }}
                  noErrorMessage={false}
                  error={error ? error.message : ""}
                  label="New tag name"
                  {...field}
                />
              )}
              name="tag"
              control={control}
            />

            <DialogActions>
              <StyledButton
                sx={{ height: 27, color: "#6A6A6A" }}
                label={
                  <Typography fontSize="10px" fontWeight="normal">
                    Cancel
                  </Typography>
                }
                variant="text"
                onClick={() => {
                  navigate(-1);
                }}
              />
              <StyledButton
                disabled={!tag || !!errors?.tag || !isUnique}
                sx={{ height: 27 }}
                label={
                  <Typography fontSize="10px" fontWeight="normal">
                    Done
                  </Typography>
                }
                type="submit"
                form="customer-tag-form"
                variant="contained"
              />
            </DialogActions>
          </Grid>
        </DialogContent>
      </form>
    </Dialog>
  );
};

NewTagComponent.propTypes = {
  type: string,
  navigatePath: string,
  navigateState: string,
};

NewTagComponent.propTypes = {
  type: string,
};

export default NewTagComponent;
