import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createSavedFiltersAction,
  viewNameDialogAction,
} from "redux/actions/savedFilters";
import { openedViewNameDialogSelector } from "redux/selectors/savedFilters";
import { createSelector } from "reselect";

const selector = createSelector(
  openedViewNameDialogSelector,
  (openedViewNameDialog) => ({
    openedViewNameDialog,
  })
);

export const useCustomFilterCreate = ({
  menuCallback,
  createFilterCallback,
  viewTypeKey,
  filterType,
}) => {
  const dispatch = useDispatch();
  const { openedViewNameDialog } = useSelector(selector);

  const onCloseFilterDialog = () =>
    dispatch(
      viewNameDialogAction({
        type: viewTypeKey,
        show: false,
      })
    );

  const onFilterViewSave = (name) =>
    dispatch(
      createSavedFiltersAction(
        {
          name,
          type: filterType,
        },
        createFilterCallback
      )
    );

  const onOpenFilterSaveDialog = (filters) => {
    menuCallback && menuCallback();
    dispatch(
      viewNameDialogAction({
        type: viewTypeKey,
        show: true,
        filtersForSave: filters,
      })
    );
  };

  const isOpenedMenu = useMemo(
    () => openedViewNameDialog[viewTypeKey],
    [openedViewNameDialog, viewTypeKey]
  );

  return {
    onCloseFilterDialog,
    onFilterViewSave,
    onOpenFilterSaveDialog,
    isOpenedMenu,
  };
};
