import pluralize from "pluralize";
import { useCallback, useState } from "react";
import {
  checkCustomerAddressService,
  customersProceedWithDuplicates,
  deleteCustomersService,
} from "services/customers";
import { error } from "utils/notifications";

export const useDuplicates = (
  clearErrors,
  errors,
  setValue,
  setError,
  type,
  formField,
  addressDebounced
) => {
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [duplicateOpen, setDuplicateOpen] = useState(false);
  const [checkedCustomers, setCheckedCustomers] = useState([]);
  const [addressDuplicates, setAddressDuplicates] = useState([]);

  const checkedCusmomersIds = useCallback(() => {
    if (!checkedCustomers.length) return;
    return checkedCustomers.map(({ id }) => id);
  }, [checkedCustomers]);

  const checkDuplicateCustomers = useCallback(
    (customers) => {
      const filtredCustomers = customers.filter(
        (customer) => customer.id !== formField.id
      );

      if (checkedCustomers.length) {
        const checkedIds = checkedCusmomersIds();
        return filtredCustomers.filter((item) => !checkedIds.includes(item.id));
      }
      return filtredCustomers;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkedCusmomersIds, checkedCustomers.length, formField.id]
  );

  const addressValidation = useCallback(
    (customersList) => {
      const customers = checkDuplicateCustomers(customersList);
      setAddressDuplicates(customers);
      if (!customers.length) {
        return clearErrors("data.formatted_address");
      }
      setError("data.formatted_address", {
        type: "duplicate",
        message: `${pluralize("duplicate", customers.length, true)} found`,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkDuplicateCustomers, clearErrors, setError]
  );

  const handleProceedWithDuplicate = () => {
    addressValidation(addressDuplicates);
    if (addressDuplicates.length === 1 || !errors?.data?.formatted_address) {
      if (addressDuplicates.length === 1)
        setCheckedCustomers(addressDuplicates);
      setValue("proceededWithDuplicatedFields", true);
    }

    clearErrors("data.formatted_address");
    setAddressDuplicates([]);
    setDuplicateOpen(false);
  };

  const handleCheckCustomers = useCallback(
    (customer) => {
      const existingItem = checkedCustomers.find(
        (item) => item.id === customer.id
      );

      if (existingItem) {
        const filtredCustomers = checkedCustomers.filter(
          ({ id }) => id !== customer.id
        );
        return setCheckedCustomers(filtredCustomers);
      }

      return setCheckedCustomers((prev) => [...prev, customer]);
    },
    [checkedCustomers]
  );

  const handleCheckDuplicateAddress = useCallback(() => {
    checkCustomerAddressService({
      formatted_address: addressDebounced,
      type: type.split(" ")[0],
      proceeded_with_duplicated_fields: formField.proceededWithDuplicatedFields,
    }).then((res) => {
      addressValidation(res.customers);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressDebounced]);

  const deleteDublicates = useCallback(() => {
    if (!checkedCustomers.length && addressDuplicates.length > 1) return;

    setDeleteLoading(true);
    let ids = [];

    if (addressDuplicates.length === 1) {
      ids = [addressDuplicates[0].id];
    } else {
      const checkedIds = checkedCusmomersIds();
      ids = checkedIds;
    }

    deleteCustomersService(ids)
      .then(() => {
        handleCheckDuplicateAddress();
      })
      .catch((err) => {
        error(err?.message || "Something went wrong.");
      })
      .finally(() => {
        setDuplicateOpen(false);
        setCheckedCustomers([]);
        setDeleteLoading(false);
        setAddressDuplicates([]);
      });
  }, [
    addressDuplicates,
    checkedCusmomersIds,
    checkedCustomers.length,
    handleCheckDuplicateAddress,
  ]);

  const formatEditType = (inputString) => {
    const words = inputString.split(" ");
    if (words.length > 1) {
      words[1] = words[1].charAt(0).toUpperCase() + words[1].slice(1);
    }
    return words.join("");
  };

  const handleSendCheckedCustomers = useCallback(async () => {
    const customerIds = checkedCusmomersIds();
    if (!customerIds || !customerIds.length) return;

    await customersProceedWithDuplicates({
      customerIds,
      proceededWithDuplicatedFields: true,
    }).catch((error) =>
      // eslint-disable-next-line no-console
      console.log("customersProceedWithDuplicates", error)
    );
  }, [checkedCusmomersIds]);

  return {
    formatEditType,
    checkedCustomers,
    handleCheckCustomers,
    deleteLoading,
    deleteDublicates,
    duplicateOpen,
    setDuplicateOpen,
    addressDuplicates,
    setAddressDuplicates,
    handleProceedWithDuplicate,
    handleSendCheckedCustomers,
  };
};
