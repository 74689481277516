import { StyledButton, StyledTooltip } from "components";
import React from "react";
import { func, bool, any, object } from "prop-types";
import { Box, Menu, Typography } from "@mui/material";
import { cl } from "../../styles";
import { FilledCopyIcon } from "components/Icons";
import { copyTextToClipboard } from "utils/copyTextToClipboard";
import { NavLink } from "react-router-dom";

const CustomerMenu = ({ anchorEl, isOpen, handleClose, customer }) => {
  return (
    <Menu
      id="basic-menu"
      sx={{
        ".MuiPopover-paper": {
          boxShadow: "0px 3px 6px rgba(0, 3, 6, 0.16)",
          border: "0.5px solid #D5D9D9",
          borderRadius: "8px",
        },
      }}
      MenuListProps={{
        "aria-labelledby": "basic-button",
        sx: {
          width: "202px",
          p: "10px",
        },
      }}
      anchorEl={anchorEl}
      open={isOpen}
      onClose={handleClose}
      onClick={(e) => e.stopPropagation()}
    >
      <Box sx={{ width: "100%" }}>
        <Typography sx={cl.typography} color="#1C1C19">
          {customer?.displayName || customer?.name}
        </Typography>
        <Typography
          sx={{
            ...cl.typography,
            color: "#47A06D",
            fontWeight: 300,
            textDecoration: "none",
            "&:hover": { textDecoration: "underline" },
          }}
          noWrap
          component={NavLink}
          to={`/customers/${customer?.id}`}
        >
          {customer?._count ? customer?._count?.orders : 0} {""}
          {customer?._count && customer?._count.orders === 1
            ? "order"
            : "orders"}
        </Typography>
        {customer?.email && (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              sx={{ ...cl.typography, fontWeight: 300 }}
              noWrap
              color="#1C1C19"
            >
              {customer?.email}
            </Typography>
            <StyledTooltip
              arrow
              title="Copy email"
              placement="top"
              PopperProps={{
                modifiers: [{ name: "offset", options: { offset: [0, -8] } }],
              }}
            >
              <Box
                sx={{
                  cursor: "pointer",
                }}
                onClick={() =>
                  copyTextToClipboard(customer?.email, "Email copied")
                }
              >
                <FilledCopyIcon color="#707070" width="8px" height="10px" />
              </Box>
            </StyledTooltip>
          </Box>
        )}
        <StyledButton
          sx={{
            height: 24,
            width: "100%",
            fontWeight: 300,
            border: "0.5px solid #D5D9D9",
            color: "#1C1C19",
            backgroundColor: "#ffff",
            borderRadius: "8px",
            mt: "7px",
          }}
          variant="outlined"
          label="Customer Details"
          component={NavLink}
          to={`/customers/${customer?.id}`}
        />
      </Box>
    </Menu>
  );
};

export default CustomerMenu;

CustomerMenu.propTypes = {
  customer: object,
  anchorEl: any,
  isOpen: bool,
  handleClose: func,
};
