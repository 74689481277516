import { bool, func, object } from "prop-types";
import { Box } from "@mui/material";
import { LargeCheckbox } from "components";

export const CheckboxBlock = ({ isChecked, handleSetCheckedUser, user }) => {
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <LargeCheckbox
        sx={{ p: 0 }}
        formSx={{ m: 0 }}
        size={22}
        checked={isChecked}
        onChange={() => handleSetCheckedUser(user)}
      />
    </Box>
  );
};

CheckboxBlock.propTypes = {
  isChecked: bool,
  handleSetCheckedUser: func,
  user: object,
};
CheckboxBlock.defaultProps = {
  isChecked: false,
  handleSetCheckedUser: () => {},
  user: {},
};
