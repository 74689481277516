import PropTypes, {
  shape,
  string,
  bool,
  array,
  any,
  date,
  number,
} from "prop-types";
import { StyledTooltip } from "components";
import { Box, Divider, Typography } from "@mui/material";
import moment from "moment";
import { getFormattedDate } from "helpers/helpers";
import { useSelector } from "react-redux";
import { currentUserSelector } from "redux/selectors/auth";

const DueTooltip = ({
  children,
  dueData,
  disableHoverListener,
  paymentTermsName,
  offset,
  emailCount,
}) => {
  const { timeZone } = useSelector(currentUserSelector);
  return (
    <StyledTooltip
      arrow
      isDark
      title={
        <>
          {!!emailCount && (
            <Typography
              fontSize="12px"
              fontWeight="500"
              borderBottom="1px solid #fff"
              pb="4px"
            >
              {emailCount} {emailCount > 1 ? "reminders" : "reminder"} sent
            </Typography>
          )}
          {dueData?.dueDate && !dueData?.hideDue && (
            <Typography
              fontSize="12px"
              fontWeight="300"
              whiteSpace="pre-wrap"
              mt={emailCount ? "4px" : 0}
            >
              <span style={{ fontWeight: 500 }}>Due: </span>{" "}
              {moment(getFormattedDate(dueData?.dueDate, timeZone))?.format(
                "MMM DD, YY"
              ) || ""}
            </Typography>
          )}
          {!!paymentTermsName && (
            <Typography
              fontSize="12px"
              lineHeight="16px"
              fontWeight="300"
              whiteSpace="pre-wrap"
            >
              <span style={{ fontWeight: 500 }}>Terms: </span>{" "}
              {paymentTermsName}
            </Typography>
          )}

          {(dueData?.titleTop || dueData?.titleBottom) && (
            <Box display="flex" sx={{ flexDirection: "column" }}>
              {!!dueData?.titleTop && (
                <Box>
                  <Typography fontSize={12} fontWeight={500}>
                    {dueData?.titleTop}
                  </Typography>
                </Box>
              )}
              {!!dueData?.divider && <Divider color="#FFF" />}
              {!!dueData?.titleBottom && (
                <Box>
                  <Typography fontSize={12} fontWeight={300}>
                    {dueData?.titleBottom}
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        </>
      }
      placement="top"
      disableHoverListener={disableHoverListener}
      PopperProps={{
        modifiers: [
          {
            name: "offset",
            options: { offset: offset || [0, -6] },
          },
        ],
      }}
    >
      {children}
    </StyledTooltip>
  );
};

DueTooltip.propTypes = {
  children: any,
  emailCount: number,
  paymentTermsName: string,
  disableHoverListener: bool,
  dueData: PropTypes.oneOfType([
    shape({
      dueDate: date,
      text: string,
    }),
    bool,
  ]),
  offset: array,
  hideDue: bool,
};

export default DueTooltip;
