import { bool, func } from "prop-types";
import { Box, Drawer } from "@mui/material";

import { BodyAddContactDrawer, HeaderAddContactDrawer } from "./components";

export const AddContactDrawer = ({ open, onClose, handleAddContact }) => {
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{ zIndex: "1700" }}
    >
      <Box width="545px" height="100%">
        <Box width="100%" height="100%" display="flex" flexDirection="column">
          <HeaderAddContactDrawer onClose={onClose} />

          <BodyAddContactDrawer
            {...{
              onClose,
              handleAddContact,
              open,
            }}
          />
        </Box>
      </Box>
    </Drawer>
  );
};

AddContactDrawer.propTypes = {
  open: bool,
  onClose: func,
  handleAddContact: func,
};
AddContactDrawer.defaultProps = {
  open: false,
  onClose: () => {},
};
