import {
  applyPhotoTagsService,
  deleteCommentService,
  deletePhotosService,
  editCommentService,
  getGalleryService,
  postCommentService,
  getPhotoGroupsRepsDublicateService,
  applyMultiplePhotoTagsService,
} from "../../services/gallery";
import { SCROLL_LIMIT, SCROLL_LIMIT_GALLERY } from "../../utils/constants";

import { error, success } from "../../utils/notifications";
import { getProductTagsListAction } from "./product-tags";
import { getTagsAction } from "./tags";

export const GET_GALLERY = "GET_GALLERY";
export const GALLERY_GET_PARAMS = "GALLERY_GET_PARAMS";

export const GET_GALLERY_COUNT = "GET_GALLERY_COUNT";
export const GET_PHOTO_TAGS = "GET_PHOTO_TAGS";
export const UPDATE_GALLERY = "UPDATE_GALLERY";
export const DELETE_GALLERY = "DELETE_GALLERY";
export const SET_PICTURE = "SET_PICTURE";
export const START_LOADING_GALLERY = "START_LOADING_GALLERY";
export const END_LOADING_GALLERY = "END_LOADING_GALLERY";
export const START_LOADING_PICTURE = "START_LOADING_PICTURE";
export const END_LOADING_PICTURE = "END_LOADING_PICTURE";
export const START_LOADING_PHOTO_TAGS = "START_LOADING_PHOTO_TAGS";
export const END_LOADING_PHOTO_TAGS = "END_LOADING_PHOTO_TAGS";
export const GET_SHOWING_PHOTOS_COUNT = "GET_SHOWING_PHOTOS_COUNT";
export const REPS_WITH_PHOTO = "REPS_WITH_PHOTO";
export const REPS_WITH_PHOTO_PARAMS = "REPS_WITH_PHOTO_PARAMS";
export const REPS_WITH_PHOTO_COUNT = "REPS_WITH_PHOTO_COUNT";
export const GROUP_IDS = "GROUP_IDS";
export const UPDATE_MULTIPLE_GALLERY = "UPDATE_MULTIPLE_GALLERY";

export const getGallery = (payload) => ({
  type: GET_GALLERY,
  payload,
});

export const getGalleryCount = (payload) => ({
  type: GET_GALLERY_COUNT,
  payload,
});

export const getShowingPhotosCount = (payload) => ({
  type: GET_SHOWING_PHOTOS_COUNT,
  payload,
});

const updateGallery = (payload) => ({
  type: UPDATE_GALLERY,
  payload,
});

const updateMultipleGallery = (payload) => ({
  type: UPDATE_MULTIPLE_GALLERY,
  payload,
});

const deleteGallery = (payload) => ({
  type: DELETE_GALLERY,
  payload,
});

export const setPictureItemAction = (payload) => ({
  type: SET_PICTURE,
  payload,
});

const galleryGetParams = (payload) => ({
  type: GALLERY_GET_PARAMS,
  payload,
});

const getRepsWithPhoto = (payload) => ({
  type: REPS_WITH_PHOTO,
  payload,
});
const getRepsWithPhotoParams = (payload) => ({
  type: REPS_WITH_PHOTO_PARAMS,
  payload,
});
const getRepsWithPhotoCount = (payload) => ({
  type: REPS_WITH_PHOTO_COUNT,
  payload,
});

export const groupIdsAction = (payload) => ({
  type: GROUP_IDS,
  payload,
});

export const startLoadingGallery = () => ({ type: START_LOADING_GALLERY });
export const endLoadingGallery = () => ({ type: END_LOADING_GALLERY });

const startLoadingPicture = () => ({ type: START_LOADING_PICTURE });
const endLoadingPicture = () => ({ type: END_LOADING_PICTURE });

const startLoadingTags = () => ({ type: START_LOADING_PHOTO_TAGS });
const endLoadingTags = () => ({ type: END_LOADING_PHOTO_TAGS });

export const getGalleryAction = (query) => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(startLoadingGallery());
    getGalleryService({
      ...state.gallery.galleryGetParams,
      ...query,
    })
      .then((res) => {
        dispatch(endLoadingGallery());
        dispatch(getShowingPhotosCount(res.photoCount));
        dispatch(
          getGallery({ newPhotos: res.rows, isScrolling: query.page > 1 })
        );
        dispatch(getGalleryCount(res.count));
      })
      .catch((err) => {
        error(err?.response?.data?.message);
        dispatch(endLoadingGallery());
        // eslint-disable-next-line no-console
        console.log(err?.response?.data?.message);
      });
  };
};

export const applyPhotoTagsAction = (data, setCurrentPicture) => {
  return (dispatch) => {
    dispatch(startLoadingTags());
    applyPhotoTagsService(data)
      .then((res) => {
        dispatch(updateGallery(res));
        dispatch(getProductTagsListAction());
        if (setCurrentPicture) setCurrentPicture(res);
        dispatch(getTagsAction({ limit: SCROLL_LIMIT }));
      })
      .catch((err) => {
        error(err?.response?.data?.message);
        dispatch(endLoadingTags());
        // eslint-disable-next-line no-console
        console.log(err?.response?.data?.message);
      });
  };
};

export const applyMultiplePhotoTagsAction = (data, setCurrentPicture) => {
  return (dispatch) => {
    dispatch(startLoadingTags());
    applyMultiplePhotoTagsService(data)
      .then((res) => {
        dispatch(updateMultipleGallery(res));
        dispatch(getProductTagsListAction());
        if (setCurrentPicture) setCurrentPicture(res);
        dispatch(getTagsAction({ limit: SCROLL_LIMIT }));
      })
      .catch((err) => {
        error(err?.response?.data?.message);
        dispatch(endLoadingTags());
        // eslint-disable-next-line no-console
        console.log(err?.response?.data?.message);
      });
  };
};

export const postGalleryCommentAction = (
  id,
  data,
  setCurrentPicture,
  callback
) => {
  return (dispatch) => {
    dispatch(startLoadingPicture());
    postCommentService(id, data)
      .then((res) => {
        dispatch(endLoadingPicture());
        dispatch(updateGallery(res));
        if (setCurrentPicture) setCurrentPicture(res);
        if (callback) callback(res);
      })
      .catch((err) => {
        error(err?.response?.data?.message);
        dispatch(endLoadingPicture());
        // eslint-disable-next-line no-console
        console.log(err?.response?.data?.message);
      });
  };
};

export const editGalleryCommentAction = (
  id,
  data,
  setCurrentPicture,
  callback
) => {
  return (dispatch) => {
    dispatch(startLoadingPicture());
    editCommentService(id, data)
      .then((res) => {
        dispatch(endLoadingPicture());
        dispatch(updateGallery(res));
        if (setCurrentPicture) setCurrentPicture(res);
        if (callback) callback();
      })
      .catch((err) => {
        error(err?.response?.data?.message);
        dispatch(endLoadingPicture());
        // eslint-disable-next-line no-console
        console.log(err?.response?.data?.message);
      });
  };
};

export const deleteGalleryCommentAction = (id, setCurrentPicture, callback) => {
  return (dispatch) => {
    dispatch(startLoadingPicture());
    deleteCommentService(id)
      .then((res) => {
        dispatch(endLoadingPicture());
        dispatch(updateGallery(res));
        if (setCurrentPicture) setCurrentPicture(res);
        if (callback) callback(res);
      })
      .catch((err) => {
        error(err?.response?.data?.message);
        dispatch(endLoadingPicture());
        // eslint-disable-next-line no-console
        console.log(err?.response?.data?.message);
      });
  };
};

export const deletePhotosAction = (ids) => {
  return (dispatch, getState) => {
    const {
      gallery: { loading },
    } = getState();
    if (loading) return;

    dispatch(startLoadingGallery());
    deletePhotosService(ids)
      .then(() => {
        dispatch(deleteGallery(ids));
        dispatch(endLoadingGallery());
        success("Photo(s) deleted successfully.");
      })
      .catch((err) => {
        error(err?.response?.data?.message);
        dispatch(endLoadingGallery());
        // eslint-disable-next-line no-console
        console.log(err?.response?.data?.message);
      });
  };
};

export const galleryGetParamsAction = (data) => {
  return (dispatch) => {
    dispatch(galleryGetParams(data));
    dispatch(getGalleryAction({ limit: SCROLL_LIMIT_GALLERY }));
  };
};

export const resetGalleryGetParamsAction = () => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(
      galleryGetParamsAction({
        tags: [],
        representative_duplicate_id: "",
        search: state.gallery.galleryGetParams.search,
        start_date: null,
        end_date: null,
        date: null,
        manufacturer_id: null,
        reorder_required: null,
      })
    );
  };
};

export const getRepsWithPhotoAction = (query) => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(startLoadingGallery());
    getPhotoGroupsRepsDublicateService({
      ...state.gallery.repsWithPhotoParams,
      ...query,
    })
      .then((res) => {
        dispatch(getRepsWithPhoto(res.rows));
        dispatch(getRepsWithPhotoCount(res.count));
      })
      .catch((err) => {
        error(err?.response?.data?.message);
        // eslint-disable-next-line no-console
        console.log(err?.response?.data?.message);
      })
      .finally(() => {
        dispatch(endLoadingGallery());
      });
  };
};

export const repsWithPhotoParamsAction = (data) => {
  return (dispatch) => {
    dispatch(getRepsWithPhotoParams(data));
    dispatch(getRepsWithPhotoAction({ limit: SCROLL_LIMIT }));
  };
};
