import { number } from "prop-types";
import { Box, Typography } from "@mui/material";

export const Total = ({ count }) => {
  return (
    <Box
      sx={{
        display: "flex",
        fontSize: 15,
        fontWeight: 300,
        color: "#707070",
        whiteSpace: "nowrap",
      }}
      component={Typography}
    >
      Total
      <Typography sx={{ color: "#47A06D" }} component="span">
        &nbsp;{count}&nbsp;
      </Typography>
      photos
    </Box>
  );
};

Total.propTypes = { count: number };
Total.defaultProps = { count: 0 };
