import { TableHeader } from "components";
import { Grid, Typography } from "@mui/material";

const cl = {
  grid: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    height: "100%",
  },
  typography: {
    fontSize: "12px",
    fontWeight: "400",
    color: "#6A6A6A",
    textTransform: "uppercase",
  },
};

export const OrdersPopupHeader = () => {
  return (
    <TableHeader
      sx={{
        display: "flex",
        backgroundColor: "#EEEEEE",
        borderTop: "none",
        borderLeft: "none",
        borderRight: "none",
      }}
      variant="outlined"
      columns={12}
    >
      <Grid sx={cl.grid} pl="15px" item xs={1}>
        <Typography sx={cl.typography}>Order #</Typography>
      </Grid>

      <Grid item xs={1.9} sx={cl.grid}>
        <Typography sx={cl.typography}>Date</Typography>
      </Grid>

      <Grid item xs={1.8} sx={cl.grid}>
        <Typography sx={cl.typography}>Created By</Typography>
      </Grid>

      <Grid item xs={2.1} sx={cl.grid}>
        <Typography sx={cl.typography}>Payment</Typography>
      </Grid>

      <Grid item xs={1.6} sx={cl.grid}>
        <Typography sx={cl.typography}>Fulfillment</Typography>
      </Grid>

      <Grid item xs={3.6} sx={cl.grid}>
        <Typography sx={cl.typography}>Total</Typography>
      </Grid>
    </TableHeader>
  );
};
