import { useState } from "react";
import { bool, func } from "prop-types";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  OutlinedInput,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

import { CrossIcon } from "../../../../../components/Icons";
import StyledButton from "../../../../../components/StyledButton";

const periods = [
  { name: "Today", textAlign: "left" },
  { name: "This week", textAlign: "center" },
  { name: "This month", textAlign: "right" },
  { name: "Yesterday", textAlign: "left" },
  { name: "Last week", textAlign: "center" },
  { name: "Last month", textAlign: "right" },
];

const ReportComponent = ({ isOpenReport, handleCloseReport }) => {
  const [start_date, setStartDate] = useState();
  const [end_date, setEndDate] = useState();
  const [selectPeriod, setSelectPeriod] = useState(periods[1].name);

  return (
    <Dialog open={isOpenReport} onClose={handleCloseReport}>
      <DialogContent
        sx={{
          padding: 0,
          paddingTop: "12px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "17px",
              fontWeight: 500,
              color: "#3F3F3F",
              marginLeft: "24px",
            }}
          >
            Export Report
          </Typography>
          <IconButton sx={{ marginRight: "12px" }} onClick={handleCloseReport}>
            <CrossIcon />
          </IconButton>
        </Box>
      </DialogContent>
      <DialogContent>
        <Box>
          <Typography
            sx={{ fontSize: "10px", fontWeight: 400, color: "#6A6A6A" }}
          >
            Sales Representative
          </Typography>
          <OutlinedInput
            sx={{
              marginTop: "10px",
              height: 32,
            }}
            fullWidth
            value="John Dole"
            disabled
          />
          <Box
            sx={{
              marginTop: "36px",
              border: "1px solid #B5B5B5",
              borderRadius: "4px",
              position: "relative",
              height: "32px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                position: "absolute",
                top: -10,
                left: 7,
                background: "white",
                display: "inline-block",
                width: "50px",
                textAlign: "center",
                fontSize: "12px",
                fontWeight: 400,
                color: "#B5B5B5",
              }}
              component="span"
            >
              Format
            </Typography>
            <FormControl sx={{ width: "100%" }} component="fieldset">
              <RadioGroup
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  height: "32px",
                }}
                row
                defaultValue="CSV"
              >
                <FormControlLabel
                  value="CSV"
                  sx={{ height: "32px" }}
                  label={
                    <Typography variant="caption" sx={{ color: "#5F6267" }}>
                      CSV
                    </Typography>
                  }
                  control={
                    <Radio
                      sx={{
                        "& svg": {
                          width: "12px",
                          height: "12px",
                        },
                      }}
                    />
                  }
                />
                <FormControlLabel
                  sx={{ m: 0, height: "32px" }}
                  value="PDF"
                  label={
                    <Typography variant="caption" sx={{ color: "#5F6267" }}>
                      PDF
                    </Typography>
                  }
                  control={
                    <Radio
                      sx={{
                        "& svg": {
                          width: "12px",
                          height: "12px",
                        },
                      }}
                    />
                  }
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
      </DialogContent>
      <DialogContent sx={{ py: 0, maxWidth: "354px" }}>
        <Grid container>
          {periods &&
            periods.map((period) => (
              <Grid
                key={period.name}
                item
                xs={4}
                sx={{
                  textAlign: period.textAlign,
                  marginTop: "12px",
                }}
              >
                <Link
                  component="button"
                  color={period.name === selectPeriod ? "primary" : "inherit"}
                  underline={period.name === selectPeriod ? "always" : "none"}
                  onClick={() => setSelectPeriod(period.name)}
                  sx={{ fontSize: "10px" }}
                >
                  {period.name}
                </Link>
              </Grid>
            ))}
        </Grid>
      </DialogContent>
      <DialogContent sx={{ paddingTop: "36px" }}>
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Box>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Date from"
                value={start_date}
                onChange={(newValue) => setStartDate(newValue)}
                renderInput={(params) => (
                  <TextField sx={{ width: "150px" }} size="small" {...params} />
                )}
              />
            </LocalizationProvider>
          </Box>
          <Box sx={{ marginLeft: "6px" }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Date to"
                value={end_date}
                onChange={(newValue) => setEndDate(newValue)}
                renderInput={(params) => (
                  <TextField sx={{ width: "150px" }} size="small" {...params} />
                )}
              />
            </LocalizationProvider>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          padding: "24px",
        }}
      >
        <StyledButton fullWidth variant="contained" label="Download" />
      </DialogActions>
    </Dialog>
  );
};

ReportComponent.propTypes = {
  isOpenReport: bool,
  handleCloseReport: func,
};

ReportComponent.defaultProps = {
  isOpenReport: false,
};

export default ReportComponent;
