import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  filterWrapper: {
    display: "flex",
    justifyContent: "space-between",
    height: "67px",
    alignItems: "center",
    border: "0.5px solid #D5D9D9",
    borderRadius: "4px 4px 0 0",
    padding: "3px 24px 3px 17px",
    background: "#fff",
    gap: "12px",
  },

  filterButton: {
    height: "39px",
    width: "100%",
    maxWidth: "74px",
    border: "0.5px solid #D5D9D9",
    "& .MuiButton-startIcon": {
      ml: 0,
    },
  },

  filterChip: {
    borderRadius: "4px",
    border: "0.7px solid #d5d9d9",
    backgroundColor: "#FAFAFB",
    height: "28px",
  },
}));

export default useStyles;
