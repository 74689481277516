import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  ordersHeader: {
    padding: "24px 24px 0 34px",
    borderBottom: "0.5px solid #D5D9D9",
    gap: "40px",
  },

  ordersBody: {
    padding: "18px 76px",
    maxHeight: "100%",
  },

  buttonBlock: {
    padding: "15px 28px",
    backgroundColor: "#F7F7F7",
    boxShadow: "none",
    marginBottom: "9px",
  },

  gridWrapper: {
    display: "flex",
    alignItems: "center",
  },

  gridWrapperRight: {
    marginLeft: "auto",
    width: "113px",
  },

  blockTitle: {
    fontSize: "30px",
    marginBottom: "13px",
    color: "#5F6267",
  },

  switchLabelBlock: {
    width: "100%",
    height: "100%",
  },

  switchLabelFirst: {
    color: "#00000",
    fontWeight: "500",
    fontSize: "14px",
  },

  switchLabelSecond: {
    color: "#5F6267",
    fontWeight: 400,
    fontSize: "14px",
  },

  customerRadiusFeetText: {
    color: "#D4D4D4",
    fontWeight: 400,
    fontSize: "12px",
    marginRight: "5px",
  },
}));

export default useStyles;

export const cl = {
  tabs: {
    ml: 1.1,
    mt: "5px",
    mb: 0,
    minHeight: "52px",
    height: "52px",
    "& .MuiButtonBase-root.MuiTab-root": {
      minHeight: "52px",
    },
    "& .MuiTabs-indicator": {
      borderRadius: "4px 4px 0 0",
    },
  },
  tab: {
    fontSize: "22px",
    fontWeight: 400,
    color: "#707070",
    mx: 2,
    py: 0,
    px: 0.6,
    minWidth: 50,
    pb: "10px",
  },
  tabLabel: { textTransform: "none" },
};
