import { useEffect, useCallback } from "react";
import { useDebounce } from "../../helpers/hooks";
import { useDispatch } from "react-redux";
import { Controller, useForm, useWatch } from "react-hook-form";
import { bool, func } from "prop-types";
import { yupResolver } from "@hookform/resolvers/yup";
import { error } from "../../utils/notifications";

import {
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { CrossIcon } from "../Icons";
import StyledButton from "../StyledButton";
import { StyledTextField } from "../TextFields/TextFields";
import { validationSchema } from "./ManufacturerComponents.validations";
import { defaultValues } from "./ManufacturerComponents.constants";
import { checkManufacturerAvailabilityService } from "../../services/manufacturers";
import { createManufacturerAction } from "../../redux/actions/manufacturers";
import useStyles from "./styles";
import { StyledTooltip } from "components";

export const NewManufacturerPopup = ({
  isOpen,
  handleClose,
  setValue,
  fetchManufacturers,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    reset,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: { ...defaultValues },
    resolver: yupResolver(validationSchema()),
  });

  const formField = useWatch({ control });
  const nameDebounced = useDebounce(formField.name, 500);

  const customIdDebounced = useDebounce(formField.customId);
  useEffect(() => {
    if (!customIdDebounced) return;
    checkManufacturerAvailabilityService({ custom_id: customIdDebounced })
      .then((res) => {
        if (!res.available && res.manufacturer?.id !== formField.id)
          return setError("customId", {
            type: "custom",
            message: "Manufacturer with this ID already created.",
          });
        clearErrors("customId");
      })
      .catch((err) => error(err?.response?.data?.message));
  }, [customIdDebounced, formField.id, setError, clearErrors]);

  const onSubmit = useCallback(
    (data) => {
      dispatch(
        createManufacturerAction({
          data,
          onSuccess: (profile) => {
            setValue("manufacturerId", profile?.id);
            setValue("manufacturerName", profile?.name);
            if (fetchManufacturers) fetchManufacturers();
          },
          message: false,
        })
      );
      handleClose();
    },
    [dispatch, handleClose, setValue, fetchManufacturers]
  );

  const handleCheckNameDuplicate = useCallback(() => {
    if (!nameDebounced) return clearErrors("name");

    checkManufacturerAvailabilityService({ name: nameDebounced }).then(
      (res) => {
        if (!res.available && res.manufacturer?.id !== formField.id) {
          return setError("name", {
            type: "duplicateName",
            message: "This manufacturer name is already used",
          });
        }
        clearErrors("name");
      }
    );
  }, [nameDebounced, setError, formField.id, clearErrors]);

  useEffect(() => {
    if (!nameDebounced) {
      clearErrors("name");
      return;
    }
    handleCheckNameDuplicate();
  }, [clearErrors, handleCheckNameDuplicate, nameDebounced]);
  useEffect(() => {
    reset({ ...defaultValues });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, isOpen]);

  return (
    <Dialog open={isOpen}>
      <Box>
        <IconButton
          sx={{ position: "absolute", top: 14, right: 14, p: 0 }}
          onClick={handleClose}
        >
          <CrossIcon />
        </IconButton>
        <Typography
          p="14px 0 12px 26px"
          fontWeight="400"
          fontSize="17px"
          color="#3F3F3F"
        >
          New manufacturer
        </Typography>
      </Box>
      <DialogContent sx={{ p: "10px 22px 24px 27px", minWidth: "413px" }}>
        <Grid display="flex" sx={{ flexDirection: "column" }} container>
          <StyledTooltip
            placement="right"
            arrow
            title="This manufacturer name is already used"
            open={errors.name?.type === "duplicateName"}
          >
            <Box>
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <StyledTextField
                    adminIsAllowed
                    formSx={{ mt: "5px" }}
                    fullWidth
                    size="small"
                    InputProps={{
                      className: classes.textInput,
                    }}
                    InputLabelProps={{
                      className: classes.inputLabel,
                    }}
                    // noErrorMessage
                    label="Manufacturer Name *"
                    error={error ? error.message : ""}
                    {...field}
                  />
                )}
                name="name"
                control={control}
              />
            </Box>
          </StyledTooltip>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                adminIsAllowed
                formSx={{ mt: "26px" }}
                fullWidth
                size="small"
                InputProps={{
                  className: classes.textInput,
                }}
                InputLabelProps={{
                  className: classes.inputLabel,
                }}
                // noErrorMessage
                label="ID"
                error={error ? error.message : ""}
                {...field}
              />
            )}
            name="customId"
            control={control}
          />
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                adminIsAllowed
                formSx={{ m: "26px 0 26px 0" }}
                fullWidth
                size="small"
                InputProps={{
                  className: classes.textInput,
                }}
                InputLabelProps={{
                  className: classes.inputLabel,
                }}
                // noErrorMessage
                label="Primary Email"
                error={error ? error.message : ""}
                {...field}
              />
            )}
            name="email"
            control={control}
          />
          <Typography fontSize="12px" mb="4px" color="#707070">
            Status
          </Typography>
          <Typography className={classes.helperText}>
            Control the visibility of the categories and products in the catalog
            for this manufacturer.
          </Typography>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <FormControl sx={{ mt: "4px" }} component="fieldset">
                <RadioGroup
                  defaultValue="ACTIVE"
                  error={error?.message || ""}
                  {...field}
                >
                  <FormControlLabel
                    value="ACTIVE"
                    label={
                      <Typography
                        sx={{ fontSize: "12px" }}
                        variant="caption"
                        color="groundLighter.main"
                      >
                        Active
                      </Typography>
                    }
                    control={
                      <Radio
                        sx={{
                          "& svg": {
                            width: "16px",
                            height: "16px",
                          },
                        }}
                      />
                    }
                  />
                  <FormControlLabel
                    value="INACTIVE"
                    label={
                      <Typography
                        sx={{ fontSize: "12px" }}
                        variant="caption"
                        color="groundLighter.main"
                      >
                        Inactive
                      </Typography>
                    }
                    control={
                      <Radio
                        sx={{
                          "& svg": {
                            width: "16px",
                            height: "16px",
                          },
                        }}
                      />
                    }
                  />
                  <FormControlLabel
                    value="INACTIVE_FOR_CUSTOMERS"
                    label={
                      <Typography
                        sx={{ fontSize: "12px" }}
                        variant="caption"
                        color="groundLighter.main"
                      >
                        Inactive for customers only
                      </Typography>
                    }
                    control={
                      <Radio
                        sx={{
                          "& svg": {
                            width: "16px",
                            height: "16px",
                          },
                        }}
                      />
                    }
                  />
                </RadioGroup>
              </FormControl>
            )}
            name="status"
            control={control}
          />
          <StyledButton
            disabled={!formField?.name || Object.keys(errors)?.length}
            sx={{ height: 40, mt: "16px", boxShadow: "none" }}
            onClick={handleSubmit(onSubmit)}
            label={
              <Typography fontSize="15px" fontWeight="normal">
                Add Manufacturer
              </Typography>
            }
            variant="contained"
          />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

NewManufacturerPopup.propTypes = {
  isOpen: bool,
  handleClose: func,
  setValue: func,
  fetchManufacturers: func,
};

NewManufacturerPopup.defaultProps = {
  isOpen: false,
};

export default NewManufacturerPopup;
