import React from "react";
import { any, number } from "prop-types";
import { CircleWrap } from "./styles";

const CircleIndex = ({ children, charLength }) => {
  return <CircleWrap charLength={charLength}>{children}</CircleWrap>;
};

CircleIndex.propTypes = { children: any, charLength: number };

export default CircleIndex;
