import {
  SET_TRIAL_DIALOG_CLOSE,
  SET_TRIAL_DIALOG_OPEN,
  SET_PAYMENT_FAILED_DIALOG_CLOSE,
  SET_PAYMENT_FAILED_DIALOG_OPEN,
} from "redux/actions/subscriptionDialogs";

const initialState = {
  trialDialog: {
    open: false,
  },
  paymentFailedDialog: {
    open: false,
  },
};

const subscriptionDialogsReducer = (state = initialState, { type }) => {
  switch (type) {
    case SET_TRIAL_DIALOG_OPEN:
      return {
        ...state,
        trialDialog: { ...state.trialDialog, open: true },
      };
    case SET_TRIAL_DIALOG_CLOSE:
      return {
        ...state,
        trialDialog: { ...state.trialDialog, open: false },
      };

    case SET_PAYMENT_FAILED_DIALOG_OPEN:
      return {
        ...state,
        paymentFailedDialog: { ...state.paymentFailedDialog, open: true },
      };
    case SET_PAYMENT_FAILED_DIALOG_CLOSE:
      return {
        ...state,
        paymentFailedDialog: { ...state.paymentFailedDialog, open: false },
      };

    default:
      return state;
  }
};

export default subscriptionDialogsReducer;
