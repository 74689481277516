export const BankIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12.32"
      height="13.617"
      viewBox="0 0 12.32 13.617"
    >
      <path
        id="Icon_material-account-balance"
        data-name="Icon material-account-balance"
        d="M4.3,7.336v4.539H6.242V7.336Zm3.891,0v4.539h1.945V7.336ZM3,15.117H15.32V13.172H3Zm9.078-7.781v4.539h1.945V7.336ZM9.16,1.5,3,4.742v1.3H15.32v-1.3Z"
        transform="translate(-3 -1.5)"
      />
    </svg>
  );
};
