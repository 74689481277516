import { array } from "prop-types";
import { Box } from "@mui/material";
import {
  TransactionsExpandedTableHeader,
  TransactionsExpandedTableItem
} from "./components";

export const TransactionsExpandedTable = ({ list }) => {
  return (
    <Box width="100%">
      <TransactionsExpandedTableHeader />
      {list.map(
        ({
           id,
           date,
           orderId,
           grossTotal,
           fees,
           netTotal,
         }) => (
          <TransactionsExpandedTableItem
            key={id}
            date={date}
            orderId={orderId}
            grossTotal={grossTotal}
            fees={fees}
            netTotal={netTotal}
          />
        )
      )}
    </Box>
  );
};

TransactionsExpandedTable.propTypes = {
  list: array,
};
TransactionsExpandedTable.defaultProps = {};
