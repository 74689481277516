import { useCallback, useEffect, useState } from "react";

export const useProducts = ({ switches, filterFields }) => {
  const [selectedProducts, setSelectedProducts] = useState([]);

  const handleApplyPurchasedProducts = (list) => setSelectedProducts(list);

  const handleRemoveProduct = (id) =>
    setSelectedProducts(
      selectedProducts.filter((products) => products.id !== id)
    );

  const handleProductIds = () => {
    if (!selectedProducts.length) return [];
    return selectedProducts.map(({ id }) => id);
  };

  const handleCurrentSwitch = useCallback(() => {
    return switches.filter((item) => item?.value === "purchasedProducts")?.[0];
  }, [switches]);

  useEffect(() => {
    const currentSwitch = handleCurrentSwitch();
    if (!currentSwitch?.checked) setSelectedProducts([]);
  }, [handleCurrentSwitch]);

  useEffect(() => {
    if (
      filterFields?.purchasedProducts &&
      filterFields?.purchasedProducts?.value?.length
    ) {
      setSelectedProducts(filterFields?.purchasedProducts?.value);
    }
  }, [filterFields?.purchasedProducts]);

  return {
    handleApplyPurchasedProducts,
    selectedProducts,
    handleRemoveProduct,
    handleProductIds,
  };
};
