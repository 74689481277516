/* eslint-disable no-useless-escape */
import * as Yup from "yup";

export const validationSchema = () =>
  Yup.object().shape({
    // amount:
    //   !isReturn &&
    //   Yup.number()
    //     .required()
    //     .transform((v, o) => (o === "" ? null : v))
    //     .nullable(true),
    date: Yup.string().required(),
    check: Yup.string().when("paymentType", {
      is: "CHECK",
      then: Yup.string().required(),
    }),
  });
