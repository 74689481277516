import { useMemo } from "react";
import { func, string } from "prop-types";
import { Box, IconButton, Typography } from "@mui/material";
import { CrossIcon } from "../../Icons";

export const DialogHeaderComponent = ({ onClose, title }) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const titleMemo = useMemo(() => title, []);
  return (
    <Box
      sx={{
        width: "100%",
        height: "52px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        pl: 2.5,
        borderBottom: "0.5px solid #D5D9D9",
      }}
    >
      <Typography fontSize={24} fontWeight={400} color="#707070">
        {titleMemo}
      </Typography>
      <IconButton onClick={onClose} sx={{ marginRight: "8px" }}>
        <CrossIcon />
      </IconButton>
    </Box>
  );
};

DialogHeaderComponent.propTypes = {
  onClose: func,
  title: string,
};
DialogHeaderComponent.defaultProps = {
  onClose: () => {},
};
