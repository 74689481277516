import { useMemo, useState } from "react";
import { object, array, func, string } from "prop-types";
import { Link as NavLink } from "react-router-dom";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { manufacturersDiscountsShowInactiveSelector } from "../../../redux/selectors/manufacturers-discounts";

import {
  Grid,
  IconButton,
  ListItemText,
  MenuItem,
  Paper,
  Typography,
  Box,
} from "@mui/material";
import { ActiveIcon, CrossIcon, EyeIcon } from "../../../components/Icons";
import { LargeCheckbox } from "../../../components/Checkboxes";
import StyledMenu from "../../../components/StyledMenu/StyledMenu";
import { getFormattedDate, useAdmin } from "../../../helpers/helpers";
import {
  getValueLabel,
  setDiscountValue,
} from "./ApplyDiscountPage/components/MSDPage/MSDPage.helpers";
import { ThreeDotButton } from "../../../components";

const selector = createSelector(
  manufacturersDiscountsShowInactiveSelector,
  (showInactive) => ({
    showInactive,
  })
);

const ItemList = ({
  item,
  checkedCustomers,
  handleCheckCustomer,
  timeZone,
  setAnchorElMenu,
  setManufacturerName,
  setProductsList,
  handleEditDiscount,
  handleDeleteDiscount,
  handleEnableDiscount,
  handleDisableDiscount,
  handleConfirmCheckedItemsDialog,
  repPermissions,
}) => {
  const isAdmin = useAdmin();

  const disabledCreateEdit = useMemo(() => {
    if (repPermissions) {
      return !repPermissions?.discounts?.create_edit;
    }
  }, [repPermissions]);

  const { showInactive } = useSelector(selector);
  const [hoverColor, setHoverColor] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const openHeaderList = Boolean(anchorEl);

  const isChecked = useMemo(
    () =>
      !!checkedCustomers.find(
        (checkedCustomer) => checkedCustomer.id === item.id
      ),
    [checkedCustomers, item.id]
  );

  const classes = {
    grid: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    typography: {
      fontSize: "12px",
      color: "#1C1C19",
    },
    paper: {
      minHeight: "44px",
      "&:not(:last-child)": { borderBottom: "1px solid #D4D4D4" },
      backgroundColor: isChecked ? "#ECF5F0" : hoverColor,
    },
  };

  const handleClickLogoClient = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };
  const handleCloseLogoClient = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const formChanged = useSelector(
    ({ confirmDialogs }) => confirmDialogs.formChanged
  );
  const editType = useSelector(({ confirmDialogs }) => confirmDialogs.editType);

  const handleAction = ({ action, isRedirect }) => {
    setAnchorEl(null);
    if (formChanged && editType === "checked_items" && isRedirect) {
      handleConfirmCheckedItemsDialog(() => action());
      return;
    }
    action();
  };

  const setMinimumRequirements = (el) => {
    switch (el?.requirements) {
      case "MIN_PURCHASE_AMOUNT":
        return `Minimum purchase $${el?.minPurchaseAmount.toFixed(2)}`;

      case "MIN_QUANTITY":
        return `Minimum quantity ${el?.minQuantity}`;

      default:
        return "No minimum";
    }
  };

  return (
    <Paper
      sx={classes.paper}
      elevation={0}
      square
      component={Grid}
      columns={24}
      container
      height="51px"
      onMouseMove={() => setHoverColor("#F7F7F7")}
      onMouseLeave={() => setHoverColor("#FFF")}
    >
      <Grid item xs={1.2} sx={{ ...classes.grid, pl: "5px" }}>
        <LargeCheckbox
          formSx={{ m: 0 }}
          stroke="#707070"
          sx={{ p: 0, ml: "9px" }}
          size={22}
          checked={
            checkedCustomers.findIndex(
              (checkedCustomer) => checkedCustomer.id === item.id
            ) > -1
          }
          onClick={(e) => {
            e.stopPropagation();
          }}
          onChange={() => handleCheckCustomer(item)}
        />
      </Grid>

      <Grid item xs={1.3} sx={{ ...classes.grid, pl: 0.5 }}>
        {item.status !== "INACTIVE" ? (
          <Box sx={{ paddingLeft: 1 }}>
            <ActiveIcon />
          </Box>
        ) : (
          <Box sx={{ paddingLeft: 0.5, paddingTop: 0.5 }}>
            <CrossIcon fill="#FF0000" />
          </Box>
        )}
      </Grid>

      <Grid item xs={3} sx={classes.grid}>
        <Typography
          sx={{
            ...classes.typography,
            textDecoration: "none",
            "&:hover": { textDecoration: "underline" },
          }}
          noWrap
          component={disabledCreateEdit ? "span" : NavLink}
          to={`/discounts/${item.id}`}
          onClick={(e) => {
            if (disabledCreateEdit) return;
            if (e.metaKey || e.ctrlKey) return;
            e.preventDefault();
            handleEditDiscount(item.id);
          }}
        >
          {item.name}
        </Typography>
      </Grid>

      <Grid item xs={3.44} sx={classes.grid}>
        <Typography sx={classes.typography} fontWeight="500" noWrap>
          {setDiscountValue(item)}
        </Typography>
        <Typography sx={classes.typography} ml="4px" noWrap>
          {getValueLabel(item)}
        </Typography>
        {item.productType !== "ALL_PRODUCTS" && item?.products.length ? (
          <IconButton
            sx={{ pl: "6px" }}
            onClick={(e) => {
              setProductsList(item?.products);
              setAnchorElMenu(e.currentTarget);
              setManufacturerName(item?.manufacturer?.name);
            }}
          >
            <EyeIcon fill="#1C1C19" />
          </IconButton>
        ) : (
          ""
        )}
      </Grid>

      <Grid item xs={4} sx={classes.grid}>
        <Typography sx={classes.typography} noWrap>
          {setMinimumRequirements(item)}
        </Typography>
      </Grid>

      <Grid item xs={4} sx={classes.grid}>
        <Typography sx={classes.typography}>
          {item.endDate
            ? getFormattedDate(item.endDate, timeZone).split(",")[0]
            : "Unlimited"}
        </Typography>
      </Grid>

      <Grid item xs={3} sx={classes.grid}>
        <Typography sx={classes.typography}>
          {item.isLimited ? item.limit : "Unlimited"}
        </Typography>
      </Grid>
      <Grid item xs={2} sx={classes.grid}>
        <Typography sx={classes.typography}>
          {item._count.duplicates ? item._count.duplicates : 0} times
        </Typography>
      </Grid>

      <Grid
        item
        xs={1.956}
        sx={{ ...classes.grid, justifyContent: "flex-end" }}
      >
        <ThreeDotButton
          disabled={disabledCreateEdit || isAdmin}
          onClick={handleClickLogoClient}
        />

        <StyledMenu
          sx={{ width: "86px", p: 0 }}
          anchorEl={anchorEl}
          isOpen={openHeaderList}
          handleClose={handleCloseLogoClient}
        >
          <MenuItem
            onClick={() =>
              handleAction({
                action: () => handleEditDiscount(item.id),
                isRedirect: true,
              })
            }
          >
            <ListItemText>Edit</ListItemText>
          </MenuItem>
          {showInactive ? (
            <MenuItem
              onClick={() =>
                handleAction({
                  action: () => handleEnableDiscount(item.id),
                  isRedirect: false,
                })
              }
            >
              <ListItemText>Enable</ListItemText>
            </MenuItem>
          ) : (
            <MenuItem
              onClick={() =>
                handleAction({
                  action: () => handleDisableDiscount(item.id),
                  isRedirect: false,
                })
              }
            >
              <ListItemText>Disable</ListItemText>
            </MenuItem>
          )}
          <MenuItem
            onClick={() =>
              handleAction({
                action: () => handleDeleteDiscount(item.id),
                isRedirect: false,
              })
            }
          >
            <ListItemText
              classes={{ primary: "deleteItem" }}
              sx={{ "& > .MuiTypography-root": { color: "#FF6254" } }}
            >
              Delete
            </ListItemText>
          </MenuItem>
        </StyledMenu>
      </Grid>
    </Paper>
  );
};

ItemList.propTypes = {
  item: object,
  checkedCustomers: array,
  handleCheckCustomer: func,
  timeZone: string,
  setAnchorElMenu: func,
  setProductsList: func,
  setManufacturerName: func,
  handleEditDiscount: func,
  handleDeleteDiscount: func,
  handleEnableDiscount: func,
  handleDisableDiscount: func,
  handleConfirmCheckedItemsDialog: func,
  repPermissions: object,
};

ItemList.defaultProps = {
  item: {},
  checkedCustomers: [],
};

export default ItemList;
