import { string, func } from "prop-types";

import { StyledTextField } from "components";

import { cl } from "../styles";

import { DialogContent } from "@mui/material";

export const FilterViewContent = ({ viewName, onChange }) => {
  return (
    <DialogContent
      sx={{
        p: "20px 28px 32px",
        maxWidth: "484px",
        minWidth: "420px",
      }}
    >
      <StyledTextField
        label="Filter Name"
        name="filterName"
        fullWidth
        labelSx={cl.inputLabel}
        value={viewName}
        onChange={onChange}
      />
    </DialogContent>
  );
};

FilterViewContent.propTypes = {
  viewName: string,
  onChange: func,
};
FilterViewContent.defaultProps = {
  viewName: "",
  onChange: () => {},
};
