import { addOnlySharedTags } from "helpers/helpers";
import { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  applyMultiplePhotoTagsAction,
  applyPhotoTagsAction,
} from "redux/actions/gallery";

export const useGalleryTab = () => {
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [checkedPhotos, setCheckedPhotos] = useState([]);
  const [openTagsDrawer, setOpenTagsDrawer] = useState(false);
  const [loadingTagsDrawer, setLoadingTagsDrawer] = useState(false);
  const [isOpenProductDialog, setIsOpenProductDialog] = useState(false);

  const handleOpenTags = () => setOpenTagsDrawer(true);

  const handleCloseTagsDrawer = () => setOpenTagsDrawer(false);

  const handleFindAndReplaceByIdGalleryTags = (galleryId, updatedItem) => {
    const currentGalleryIndex = checkedPhotos.findIndex(
      (galleryItem) => galleryItem.id === galleryId
    );

    const updatedGallery = [...checkedPhotos];
    updatedGallery[currentGalleryIndex] = updatedItem;

    setCheckedPhotos(updatedGallery);
  };

  const handleSaveMultipleTags = async (tagIds, newTags) => {
    const photoGroupIds = checkedPhotos.map((photo) => photo.id);
    dispatch(
      applyMultiplePhotoTagsAction(
        {
          photoGroupIds: photoGroupIds,
          tagIds,
          newTags,
          returnUpdated: true,
        },
        (res) => setCheckedPhotos(res)
      )
    );

    setOpenTagsDrawer(false);
  };

  const handleSaveTags = async (tags) => {
    const tagIds = [];
    const newTags = [];

    tags.forEach((tag) => {
      return tag?.newTag
        ? newTags.push(tag?.tag)
        : tagIds.push(tag?.id || tag?.tag?.id);
    });

    if (checkedPhotos.length > 1)
      return handleSaveMultipleTags(tagIds, newTags);

    dispatch(
      applyPhotoTagsAction(
        {
          photoGroupId: checkedPhotos[0]?.id,
          tagIds,
          newTags,
        },
        (res) => handleFindAndReplaceByIdGalleryTags(checkedPhotos[0]?.id, res)
      )
    );

    return setOpenTagsDrawer(false);
  };

  const handleGetProductTags = useCallback(() => {
    if (!checkedPhotos?.length) return;

    if (checkedPhotos.length === 1)
      return checkedPhotos[0].tags.map((item) => item.tag || item);

    return addOnlySharedTags(checkedPhotos);
  }, [checkedPhotos]);

  const productTags = useMemo(
    () => handleGetProductTags(),
    [handleGetProductTags]
  );

  return {
    openTagsDrawer,
    setOpenTagsDrawer,
    handleOpenTags,
    handleCloseTagsDrawer,
    handleSaveTags,
    loadingTagsDrawer,
    setLoadingTagsDrawer,
    checkedPhotos,
    setCheckedPhotos,
    isOpenProductDialog,
    setIsOpenProductDialog,
    page,
    setPage,
    productTags,
  };
};
