import * as React from "react";
// eslint-disable-next-line react/prop-types
export const CopyIcon = ({ color = "#b5b5ac", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14.135}
    height={17.5}
    viewBox="0 0 14.135 17.5"
    {...props}
  >
    <path
      data-name="Icon ionic-md-copy"
      d="M8.749 0H3.722a1.683 1.683 0 0 0-1.7 1.662v.358h-.319a1.683 1.683 0 0 0-1.7 1.662v12.114a1.718 1.718 0 0 0 1.7 1.7h8.75a1.683 1.683 0 0 0 1.662-1.7v-.316h.358a1.683 1.683 0 0 0 1.662-1.7V5.385Zm0 1.876 3.509 3.509H8.749Zm2.019 13.92a.34.34 0 0 1-.316.358H1.703a.375.375 0 0 1-.358-.358V3.681a.34.34 0 0 1 .358-.316h.316v10.749a1.194 1.194 0 0 0 1.367 1.367h7.383Zm2.019-2.019a.34.34 0 0 1-.316.358H3.722a.375.375 0 0 1-.358-.358V1.662a.34.34 0 0 1 .358-.316h3.681v5.385h5.385Z"
      fill={color}
    />
  </svg>
);
