import { useContext } from "react";
import { CustomerProfileContext } from "Pages/CustomersPage/pages/CustomerProfilePage/CustomerProfilePage";
import { Box, Tab, Tabs, Typography } from "@mui/material";

export const FiltersTabs = () => {
  const { currentTable } = useContext(CustomerProfileContext);

  const { filterTabsData, filterTabsTitle, filterTab, setFilterTab } =
    currentTable || {};

  return filterTabsData ? (
    <Box
      sx={{
        borderTop: "0.5px solid #D5D9D9",
        width: "100%",
        height: "56px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "10%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography fontSize={16} color="#5F6267">
          {filterTabsTitle}
        </Typography>
      </Box>
      <Box
        sx={{
          width: "86%",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Tabs
          value={filterTab?.value}
          sx={{
            marginRight: "12px",
            minHeight: "39px",
            alignItems: "center",
            "& .MuiTabs-flexContainer": { justifyContent: "space-between" },
          }}
          TabIndicatorProps={{ style: { background: "none" } }}
        >
          {filterTabsData.map((tab) => (
            <Tab
              key={tab.value}
              label={<Box sx={{ pointerEvents: "all" }}>{tab.label}</Box>}
              name={tab.label}
              value={tab?.value}
              onClick={() => {
                setFilterTab(tab);
              }}
              sx={{
                textTransform: "none",
                fontSize: 11,
                color: "#707070",
                borderRadius: "4px",
                "&.Mui-selected": {
                  backgroundColor: "rgba(71, 160, 110, 0.2) !important",
                },
                minWidth: "36px",
                height: "24px",
                minHeight: "24px",
                paddingLeft: "11px",
                paddingRight: "11px",
              }}
            />
          ))}
        </Tabs>
      </Box>
      <Box width="4%" />
    </Box>
  ) : null;
};
