import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  ordersHeader: {
    padding: "24px 0 0 34px",
    borderBottom: "1px solid #D5D9D9",
  },

  ordersBody: {
    padding: "18px 76px",
    maxHeight: "100%",
  },

  buttonBlock: {
    padding: "15px 28px",
    backgroundColor: "#F7F7F7",
    boxShadow: "none",
    marginBottom: "9px",
  },

  gridWrapper: {
    display: "flex",
    alignItems: "center",
  },

  gridWrapperRight: {
    marginLeft: "auto",
    width: "113px",
  },

  blockTitle: {
    fontSize: "30px",
    marginBottom: "13px",
    color: "#5F6267",
  },

  switchLabelBlock: {
    width: "100%",
    height: "100%",
  },

  switchLabelFirst: {
    color: "#00000",
    fontWeight: "500",
    fontSize: "14px",
  },

  switchLabelSecond: {
    color: "#5F6267",
    fontWeight: 400,
    fontSize: "14px",
  },

  deliveryPriceCapText: {
    color: "#D4D4D4",
    fontWeight: 400,
    fontSize: "12px",
    marginRight: "5px",
  },
}));

export default useStyles;
