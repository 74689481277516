import { Box, Typography } from "@mui/material";
import WifiTetheringOffIcon from "@mui/icons-material/WifiTetheringOff";

const OfflinePage = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "calc(100vh - 250px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "15px",
      }}
    >
      <WifiTetheringOffIcon style={{ fontSize: "90px", fill: "#47A06D" }} />
      <Typography fontSize="25px" color="error">
        No internet connection detected.
      </Typography>
      <Typography fontSize="18px">Check your internet connection.</Typography>
    </Box>
  );
};

export default OfflinePage;
