import { object } from "prop-types";
import { Grid, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import useStyles from "../../../styles";

export const SkuBlock = ({ formField, control }) => {
  const classes = useStyles();

  return (
    <Grid xs={12} item>
      {!formField.isMultiple && (
        <Controller
          render={({ field, fieldState: { error } }) => (
            <TextField
              sx={{ width: "100%" }}
              fullWidth
              size="small"
              label="SKU *"
              InputProps={{
                className: classes.textInput,
              }}
              InputLabelProps={{
                className: classes.inputLabel,
              }}
              error={!!error}
              {...field}
            />
          )}
          name="sku"
          control={control}
        />
      )}
      {formField.isMultiple && (
        <Controller
          render={({ field, fieldState: { error } }) => (
            <TextField
              sx={{ width: "100%" }}
              fullWidth
              size="small"
              label="Minimum order QTY *"
              InputProps={{
                className: classes.textInput,
              }}
              InputLabelProps={{
                className: classes.inputLabel,
              }}
              error={!!error}
              {...field}
            />
          )}
          name="minOrderQTY"
          control={control}
        />
      )}
    </Grid>
  );
};

SkuBlock.propTypes = {
  formField: object,
  control: object,
};
