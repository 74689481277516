import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  menu: {
    width: "285px",
  },
  title: {
    fontSize: "18px",
    fontWeight: 400,
    color: "#707070",
  },
  head: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0px 15px 8px 19px",
  },
  menuTitleBlock: {
    display: "flex",
    alignItems: "center",
  },
  menuHeaderBlock: {
    backgroundColor: "#EEEEEE",
    display: "flex",
    justifyContent: "space-between",
    padding: "8px 11px 8px 19px",
    "& > .MuiTypography-root": {
      fontSize: "12px",
      color: "#000000",
    },
  },
  headerAssigned: {
    display: "flex",
    alignItems: "center",
    "& > .MuiTypography-root": {
      fontSize: "12px",
      color: "#000000",
    },
  },
  territoryItem: {
    display: "flex",
    justifyContent: "space-between",
    padding: "7px 44px 0 21px",
    "& > .MuiTypography-root": {
      fontSize: "12px",
      fontWeight: 400,
      color: "#000000",
    },
  },
}));

export default useStyles;
