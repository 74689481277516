import { Grid, Paper } from "@mui/material";
import PropTypes, { array, number, object, string } from "prop-types";
import { forwardRef } from "react";

export const TableHeader = forwardRef(
  ({ children, columns, variant, sx, ...props }, ref) => {
    return (
      <Paper
        sx={{
          backgroundColor: "#EEEEEE",
          alignItems: "center",
          border: "1px solid #D5D9D9",
          ...sx,
        }}
        elevation={0}
        square
        component={Grid}
        columns={columns}
        container
        ref={ref}
        variant={variant}
        height="39px"
        {...props}
      >
        {children}
      </Paper>
    );
  }
);

TableHeader.displayName = "TableHeader";

TableHeader.propTypes = {
  children: PropTypes.oneOfType([array, object]),
  columns: number,
  variant: string,
  sx: object,
};

TableHeader.defaultProps = {
  children: null,
  columns: 42,
};

export default TableHeader;
