import { string, func, object, bool } from "prop-types";
import { Box, IconButton, Typography } from "@mui/material";
import { CrossIcon } from "components/Icons";

export const HeaderNoteDrawer = ({
  editNote,
  customerName,
  onClose,
  viewOnly,
  isAdminEdit,
  titleLabel,
}) => {
  const getTitleLabel = () => {
    if (isAdminEdit) return "Edit note";
    return "View note";
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "67px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid #D5D9D9",
        backgroundColor: "#F8F8F8",
        pl: 4,
        pr: 2,
      }}
    >
      {viewOnly ? (
        <Typography fontSize={18} color="#3F3F3F" noWrap>
          {titleLabel || getTitleLabel()}
        </Typography>
      ) : (
        <Typography fontSize={18} color="#3F3F3F" noWrap>
          {editNote ? "Edit note" : `Notes | ${customerName}`}
        </Typography>
      )}
      <IconButton onClick={onClose}>
        <CrossIcon />
      </IconButton>
    </Box>
  );
};

HeaderNoteDrawer.propTypes = {
  viewOnly: bool,
  isAdminEdit: bool,
  editNote: object,
  customerName: string,
  onClose: func,
  titleLabel: string,
};
HeaderNoteDrawer.defaultProps = {
  customerName: "",
  onClose: () => {},
  viewOnly: false,
  isAdminEdit: false,
};
