import { useLocation } from "react-router-dom";
import { Box, Paper } from "@mui/material";
import {
  HeaderRegistration,
  FooterRegistration,
  LinksRegistration,
  StepsRegistration,
  StepOne,
  StepTwo,
  StepTwoHalf,
  StepThree,
} from "./components";
import { useRegActions, useFormActions } from "./hooks";
import { cl } from "./RegistrationPage.styles";

export const RegistrationPage = () => {
  const { state } = useLocation();

  const {
    registrationPage: { mainWrapper, paper },
  } = cl;

  const formData = useFormActions({ state });
  const { formField, handleSubmit } = formData;
  const { step } = formField;

  const {
    regState,
    onSubmit,
    errPhoneStatus,
    setErrPhoneStatus,
    finishSetAccount,
    fetchConfirmationInfo,
    setLoading,
    token,
  } = useRegActions({
    formData,
    state,
  });

  const { loading } = regState;

  return (
    <Box sx={mainWrapper}>
      <Paper sx={paper} elevation={0} variant="outlined">
        <HeaderRegistration step={step} />
        <StepsRegistration step={step} />
        <Box px="85px">
          {step === 1 && <StepOne {...{ formData, loading }} />}

          {step === 2 && (
            <StepTwo
              {...{ loading, formData, errPhoneStatus, setErrPhoneStatus }}
              onSubmit={handleSubmit(onSubmit)}
            />
          )}

          {step === 2.1 && (
            <StepTwoHalf
              {...{ formData, regState }}
              onSubmit={fetchConfirmationInfo}
            />
          )}

          {step === 3 && (
            <StepThree
              {...{ loading, setLoading, token }}
              onSubmit={finishSetAccount}
            />
          )}
        </Box>
        <FooterRegistration step={step} />
      </Paper>
      <Box height="60px" width="100%">
        <LinksRegistration />
      </Box>
    </Box>
  );
};

export default RegistrationPage;
