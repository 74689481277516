export const FILTER_TABS = [
  {
    name: "All",
    value: "",
    field: "type",
  },
  {
    name: "Active",
    value: "active",
    field: "type",
  },
  {
    name: "Inactive",
    value: "inactive",
    field: "type",
  },
  {
    name: "Assigned",
    value: "assigned",
    field: "type",
  },
  {
    name: "Unassigned",
    value: "unassigned",
    field: "type",
  },
];

export const defaultFilters = {
  representative_id: "",
  priority: "",
};

export const FILTER_SWITCHES = [
  {
    value: "total_stops",
    label: "Total Stops",
    type: "textfields",
    checked: false,
    options: [
      {
        id: "total_stops_greater",
        label: "More than",
        prefix: ">",
        placeholder: "0",
        type: "number",
      },
      {
        id: "total_stops_lesser",
        label: "Less than",
        prefix: "<",
        placeholder: "0",
        type: "number",
      },
    ],
  },
  {
    value: "priority",
    label: "Priority",
    type: "switch",
    checked: false,
    values: ["HIGH", "MEDIUM", "LOW"],
  },
  {
    value: "representative_id",
    label: "Representative",
    type: "select",
    checked: false,
    selectLabel: "Select Rep",
  },
];
