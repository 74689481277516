/* eslint-disable react/prop-types */
export const PadlockIcon = ({
  stroke,
  width = "15.568",
  height = "17.132",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 15.568 17.132"
    >
      <path
        id="Path_5457"
        data-name="Path 5457"
        d="M6.063,16.5H17.005a1.563,1.563,0,0,1,1.563,1.563v5.471A1.563,1.563,0,0,1,17.005,25.1H6.063A1.563,1.563,0,0,1,4.5,23.534V18.063A1.563,1.563,0,0,1,6.063,16.5Z"
        transform="translate(-3.75 -8.716)"
        fill="none"
        stroke={stroke || "#fff"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Path_5458"
        data-name="Path 5458"
        d="M10.5,10.034V6.908a3.908,3.908,0,1,1,7.816,0v3.126"
        transform="translate(-6.624 -2.25)"
        fill="none"
        stroke={stroke || "#fff"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};
