// eslint-disable-next-line react/prop-types
export const StarIcon = ({ stroke = "#D5D9D9", fill = "#fff" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.93"
      height="21.807"
      viewBox="0 0 22.93 21.807"
    >
      <path
        id="Icon_metro-star-full"
        data-name="Icon metro-star-full"
        d="M25.5,11.013,17.578,9.861,14.035,2.683,10.493,9.861,2.571,11.013,8.3,16.6,6.95,24.49l7.086-3.725,7.086,3.725L19.768,16.6,25.5,11.013Z"
        transform="translate(-2.57 -2.684)"
        fill={fill}
        stroke={stroke}
      />
    </svg>
  );
};
