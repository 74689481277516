import { object, bool, array, number, func, string } from "prop-types";
import { Box } from "@mui/material";
import { FilterThirdParty, ListThirdParty } from "./components";
import { ThirdPartyOrdersTotalSummaries } from "./components";
import { initialState } from "redux/reducers/settings";
import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateDistributorSettingsService } from "services/account";
import { setThirdPartyColumnLayoutsAction } from "redux/actions/settings";
import { error } from "utils/notifications";
import { useAdmin } from "helpers/helpers";

export const ThirdPartyOrdersTab = ({
  currentTab,
  ordersLoading,
  loadingAllItems,
  ordersThirdPartyList,
  ordersThirdPartyCount,
  ordersExistThirdParty,
  handleFetchOrdersThirdParty,
  allOrdersChecked,
  checkAllOrders,
  checkedOrders,
  handleCheckOrder,
  totalSummaries,
  handleChoseMenuItem,
  handleOpenEmail,
  handleConfirmCheckedItemsDialog,
  isLoadingTotalSummaries,
  isQuickBooksConnected,
  currentUser,
  repPermissions,
  itemAllCount,
  onSelectAll,
  additionalActions,
  setCheckedOrders,
  dropDownActions,
  setPage,
  params,
  page,
}) => {
  const dispatch = useDispatch();

  const isAdmin = useAdmin();

  const handleResetLayout = useCallback(async () => {
    const { thirdParty } = initialState.column_layouts;

    try {
      const res = await updateDistributorSettingsService({
        orderLayoutSettings: { thirdParty },
      });
      dispatch(
        setThirdPartyColumnLayoutsAction(res?.orderLayoutSettings?.thirdParty)
      );
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      error(err?.response?.data?.message || "Something went wrong.");
    }
  }, [dispatch]);

  // It should reset the columns to the initial state if their counts don't match
  const savedColumnsCount = {
    thirdParty:
      (currentUser?.orderLayoutSettings?.thirdParty?.disable?.length || 0) +
      (currentUser?.orderLayoutSettings?.thirdParty?.main?.length || 0),
  };
  const initColumnsCount = {
    thirdParty:
      initialState.column_layouts.thirdParty.disable?.length +
      initialState.column_layouts.thirdParty.main?.length,
  };

  useEffect(() => {
    if (savedColumnsCount?.thirdParty !== initColumnsCount?.thirdParty) {
      if (repPermissions || isAdmin) return;
      handleResetLayout();
    }
  }, [
    repPermissions,
    currentTab,
    handleResetLayout,
    initColumnsCount?.thirdParty,
    isAdmin,
    savedColumnsCount?.thirdParty,
  ]);

  const nextPageFetch = () => {
    handleFetchOrdersThirdParty({ ...params, page: page + 1 }, true);
    setPage(page + 1);
  };

  return (
    <Box pt={1} px={4}>
      <ThirdPartyOrdersTotalSummaries
        isLoading={isLoadingTotalSummaries}
        totalSummaries={totalSummaries}
      />
      <FilterThirdParty {...{ currentTab, setPage }} />
      <ListThirdParty
        {...{
          currentTab,
          ordersLoading,
          loadingAllItems,
          ordersThirdPartyList,
          ordersThirdPartyCount,
          ordersExistThirdParty,
          allOrdersChecked,
          checkAllOrders,
          checkedOrders,
          handleCheckOrder,
          handleChoseMenuItem,
          handleOpenEmail,
          repPermissions,
          isQuickBooksConnected,
          handleConfirmCheckedItemsDialog,
          itemAllCount,
          onSelectAll,
          additionalActions,
          setCheckedOrders,
          dropDownActions,
          setPage,
          nextPageFetch,
        }}
      />
    </Box>
  );
};

ThirdPartyOrdersTab.propTypes = {
  currentTab: string,
  ordersLoading: bool,
  loadingAllItems: bool,
  ordersThirdPartyList: array,
  ordersThirdPartyCount: number,
  ordersExistThirdParty: bool,
  handleFetchOrdersThirdParty: func,
  allOrdersChecked: bool,
  checkAllOrders: func,
  checkedOrders: array,
  handleCheckOrder: func,
  totalSummaries: object,
  handleChoseMenuItem: func,
  handleOpenEmail: func,
  handleConfirmCheckedItemsDialog: func,
  isLoadingTotalSummaries: bool,
  isQuickBooksConnected: bool,
  currentUser: object,
  repPermissions: object,
  onSelectAll: func,
  itemAllCount: number,
  page: number,
  additionalActions: array,
  setCheckedOrders: func,
  dropDownActions: array,
  setPage: func,
  params: object,
};
