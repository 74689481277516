import React from "react";

export const GalleryCheckedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
    >
      <g
        id="Rectangle_4980"
        data-name="Rectangle 4980"
        fill="#fff"
        stroke="#707070"
        strokeWidth="1"
      >
        <rect width="19" height="19" rx="4" stroke="none" />
        <rect x="0.5" y="0.5" width="18" height="18" rx="3.5" fill="none" />
      </g>
    </svg>
  );
};
