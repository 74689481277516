import { useMemo, useState } from "react";
import { bool, object, array, func, string, number } from "prop-types";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import moment from "moment";

import { Box, Checkbox, Paper, Typography } from "@mui/material";

import {
  ActiveIcon,
  CheckboxIcon,
  CrossIcon,
  InformationIcon,
  ProspectStatusIcon,
} from "components/Icons";
import { Statuses, AvatarSlider, StyledTooltip } from "components";

import { territoryListSelector } from "redux/selectors/territory";
import { currentUserSelector } from "redux/selectors/auth";
import { getOrderReportPDF } from "services/orders";
import { openFilePreview } from "../../../../../../OrdersPage/Orders.helper";

import { getFormattedDate } from "helpers/helpers";
import { columnLayoutsSelector } from "redux/selectors/settings";
import { CustomerColumn, LastOrderColumn, MenuColumn } from "./components";
import { paytermsListSelector } from "redux/selectors/payterms";
import { CUSTOMER_STATUS_FILTERS } from "utils/constants";

const selector = createSelector(
  territoryListSelector,
  currentUserSelector,
  columnLayoutsSelector,
  paytermsListSelector,
  (territoryList, currentUser, columnLayouts, paytermsList) => ({
    territoryList,
    currentUser,
    columnLayouts,
    paytermsList,
  })
);

const columnWidth = {
  Status: 5,
  ID: 8,
  Customer: 22,
  menu: 4,
};
const columnStyles = {
  Status: { justifyContent: "center" },
  "Orders Total": { justifyContent: "center", paddingRight: "8px" },
};

const TabListComponent = ({
  customer,
  checkedCustomers,
  handleCheckCustomer,
  handleOpenCustomerProfile,
  handleRedirectMissingInfo,
  handleOpenCustomerNotes,
  currentTab,
  style,
  lastItem,
  repPermissions,
  handleGetOrderActions,
  isAdmin,
}) => {
  const [pageLoading, setPageLoading] = useState(false);

  // const slicedId = customer?.customId?.customId?.slice(0, 6);
  const { currentUser, columnLayouts } = useSelector(selector);

  const flat_tab_disable = columnLayouts.flat.disable;
  const flat_tab = columnLayouts.flat.main;
  const sorted_tab_disable = columnLayouts.sorted.disable;
  const sorted_tab = columnLayouts.sorted.main;

  const columns = useMemo(() => {
    if (currentTab === "FLAT" && flat_tab_disable)
      return [...flat_tab_disable, ...flat_tab.filter((col) => col.selected)];
    if (currentTab === "SORTED")
      return [
        ...sorted_tab_disable,
        ...sorted_tab.filter((col) => col.selected),
      ];
    return [];
  }, [currentTab, flat_tab, flat_tab_disable, sorted_tab, sorted_tab_disable]);

  const defaultColumnWidth = useMemo(
    () => Object.values(columnWidth).reduce((acc, cur) => (acc += cur), 0),
    []
  );

  const isChecked = useMemo(() => {
    return checkedCustomers.some(({ id }) => id === customer.id);
  }, [checkedCustomers, customer?.id]);

  const classes = {
    grid: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    typography: {
      fontSize: "12px",
      fontWeight: "400",
      color: "#1C1C19",
    },
    paper: {
      backgroundColor: isChecked ? "#ECF5F0 !important" : "#fff",
      borderBottom: !lastItem && "1px solid #D5D9D9",
      display: "flex",
      height: "51px",
      maxHeight: "51px",
      minHeight: "51px",
    },
  };

  const handleDownloadInvoice = (id) => {
    setPageLoading(true);
    getOrderReportPDF(id).then((res) => {
      setPageLoading(false);
      openFilePreview(res);
    });
  };

  const isMissingInfo = useMemo(
    () => customer?.missingFields,
    [customer?.missingFields]
  );

  const duplicatedFields = useMemo(
    () => customer?.duplicatedFields,
    [customer?.duplicatedFields]
  );

  const setGroupName = useMemo(() => {
    if (customer?.parentCustomer) {
      return (
        (!customer?.parentCustomer?.isUncategorized &&
          (customer?.parentCustomer?.displayName ||
            customer?.parentCustomer?.name)) ||
        "Uncategorized"
      );
    } else {
      return "Parent";
    }
  }, [customer?.parentCustomer]);

  const statusIcons = {
    [CUSTOMER_STATUS_FILTERS.active]: <ActiveIcon />,
    [CUSTOMER_STATUS_FILTERS.inactive]: <CrossIcon fill="#FF0000" />,
    [CUSTOMER_STATUS_FILTERS.prospect]: <ProspectStatusIcon />,
  };

  const columnData = {
    Status:
      isMissingInfo?.length || duplicatedFields?.length ? (
        <StyledTooltip
          arrow
          title={
            <Box textAlign="left">
              {!!isMissingInfo?.length && (
                <>
                  <Typography fontSize={12}>Missing info:</Typography>
                  {isMissingInfo?.map((info) => (
                    <Typography key={info} fontSize={12} fontWeight={300}>
                      {info}
                    </Typography>
                  ))}
                </>
              )}
              {!!duplicatedFields?.length && (
                <>
                  <Typography fontSize={12}>Duplicates:</Typography>
                  {duplicatedFields?.map((info) => (
                    <Typography key={info} fontSize={12} fontWeight={300}>
                      {info}
                    </Typography>
                  ))}
                </>
              )}
            </Box>
          }
          placement="top"
          PopperProps={{
            modifiers: [
              {
                name: "offset",
                options: { offset: [0, -6] },
              },
            ],
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              cursor:
                isMissingInfo?.length || duplicatedFields?.length
                  ? "pointer"
                  : "default",
              p: 1,
            }}
            onClick={() => {
              if (isMissingInfo?.length || duplicatedFields?.length) {
                if (repPermissions && !repPermissions?.customers?.create_edit)
                  return;

                if (handleRedirectMissingInfo && customer)
                  handleRedirectMissingInfo(customer);
              }
            }}
          >
            <InformationIcon size="16" stroke="#FF6969" />
          </Box>
        </StyledTooltip>
      ) : (
        //  customer?.active ? (
        //   <ActiveIcon />
        // ) : (
        //   <CrossIcon fill="#FF0000" />
        // )
        statusIcons[customer.status]
      ),
    ID: (
      <StyledTooltip
        title={customer?.customId?.customId}
        // disableHoverListener={customer?.customId?.customId?.length <= 6}
        disableHoverListener={!customer?.customId?.customId}
      >
        <Typography
          sx={{
            ...classes.typography,
            overflow: "hidden",
            whiteSpace: "nowrap",
            // cursor: slicedId && "pointer",
          }}
        >
          {customer?.customId?.customId || "-"}
        </Typography>
      </StyledTooltip>
    ),
    Customer: <CustomerColumn {...{ customer, handleOpenCustomerProfile }} />,
    Group: <Typography sx={classes.typography}>{setGroupName}</Typography>,
    Territory: (
      <Typography sx={classes.typography}>
        {customer.territory?.name || "Uncategorized"}
      </Typography>
    ),
    "Assigned Reps": (
      <Box sx={{ mr: "20px", position: "relative" }}>
        <AvatarSlider
          isOrderList={true}
          sliderItems={customer.assignedRepresentatives?.filter(
            (rep) => rep.representative.active
          )}
        />
      </Box>
    ),
    "Order Direct": customer?.orderDirectInvite?.status ? (
      <Statuses text={customer?.orderDirectInvite?.status} />
    ) : (
      "-"
    ),
    "Last Visit": (
      <Typography sx={classes.typography}>
        {customer?.lastCheckInAt ? (
          moment(
            getFormattedDate(customer?.lastCheckInAt, currentUser.timeZone)
          ).format("MMM D, yyyy")
        ) : (
          <Box component="span" fontWeight={300} color="#C4C4C4">
            not visited
          </Box>
        )}
      </Typography>
    ),

    "Last Order": customer?.orders?.[0] ? (
      <LastOrderColumn
        {...{
          handleDownloadInvoice,
          customer,
          currentUser,
          pageLoading,
        }}
      />
    ) : (
      "  -"
    ),

    "Orders Total": (
      <Typography sx={classes.typography}>
        {customer._count?.orders || 0}
      </Typography>
    ),
  };

  return (
    <Paper
      sx={{
        ...classes.paper,
        position: "relative",
        "&:hover": { backgroundColor: "#F7F7F7" },
      }}
      elevation={0}
      square
      style={style}
      className="tab-list-item"
      id={customer.id}
    >
      {/* checkbox */}
      <Box width="52px" minWidth="52px" maxWidth="52px" sx={classes.grid}>
        <Checkbox
          icon={<CheckboxIcon size={isChecked ? 22 : 22.5} />}
          sx={{
            padding: 0,
            "& .MuiSvgIcon-root": { fontSize: isChecked ? 28 : 31 },
          }}
          disabled={isAdmin}
          checked={!!isChecked}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onChange={() => handleCheckCustomer(customer)}
        />
      </Box>
      <Box width="100%" sx={classes.grid}>
        {columns.map((col) => {
          const setWidth = columnWidth?.[col?.name]
            ? `${columnWidth?.[col?.name]}%`
            : `${
                (100 - defaultColumnWidth) /
                (currentTab === "FLAT" ? flat_tab : sorted_tab).filter(
                  (col) => col.selected
                )?.length
              }%`;

          return (
            <Box
              key={col.id}
              sx={{
                ...classes.typography,
                display: "flex",
                alignItems: "center",
                flexGrow: col?.name === "Customer" ? 1 : 0,
                width: setWidth,
                maxWidth: setWidth,
                ...columnStyles?.[col?.name],
              }}
            >
              {columnData?.[col?.name]}
            </Box>
          );
        })}
        <Box
          sx={{
            width: `${columnWidth.menu}%`,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <MenuColumn
            isGroups={false}
            // adminIsAllowed
            {...{
              customer,
              handleOpenCustomerNotes,
              repPermissions,
              handleGetOrderActions,
            }}
          />
        </Box>
      </Box>
    </Paper>
  );
};

TabListComponent.propTypes = {
  customer: object,
  lastItem: bool,
  checkedCustomers: array,
  handleGetOrderActions: func,
  handleCheckCustomer: func,
  handleOpenConfirmDialog: func,
  handleOpenCustomerProfile: func,
  handleRedirectMissingInfo: func,
  // handleChangeGroup: func,
  handleChangeParentGroup: func,
  handleChangePaymentTerm: func,
  handleChangeTerritory: func,
  handleDeleteCustomers: func,
  handleArchiveCustomers: func,
  setAssignedAnchorEl: func,
  handleAssignReps: func,
  dataCount: number,
  handleFetch: func,
  groupList: array,
  currentTab: string,
  handleOpenCustomerNotes: func,
  onReport: func,
  reportData: object,
  handleOrders: func,
  style: object,
  handleCustomerTaskDrawer: func,
  handleAssignTags: func,
  repPermissions: object,
  isAdmin: bool,
};
TabListComponent.defaultProps = {
  checkedCustomers: [],
  currentTab: "",
  onReport: () => {},
  style: {},
};

export default TabListComponent;
