import React from "react";

export const DoubleClipIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.059"
      height="19.041"
      viewBox="0 0 19.059 19.041"
    >
      <g
        id="Icon_feather-link"
        data-name="Icon feather-link"
        transform="translate(-2.242 -2.257)"
      >
        <path
          id="Path_5460"
          data-name="Path 5460"
          d="M15,12.655a4.387,4.387,0,0,0,6.616.474L24.249,10.5a4.387,4.387,0,1,0-6.2-6.2l-1.509,1.5"
          transform="translate(-4.983 0)"
          fill="none"
          stroke="#aaa"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_5461"
          data-name="Path 5461"
          d="M13.527,15.252a4.387,4.387,0,0,0-6.616-.474L4.278,17.41a4.387,4.387,0,1,0,6.2,6.2l1.5-1.5"
          transform="translate(0 -4.351)"
          fill="none"
          stroke="#aaa"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
};
