import { Box, CircularProgress } from "@mui/material";
import { useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getPaymentIntentOrderLinkService } from "services/orders";
import { error } from "utils/notifications";

export const PaymentLinkPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const handleFetch = useCallback(
    async (id) => {
      try {
        const { paymentIntentRedirectLink = "" } =
          await getPaymentIntentOrderLinkService(id);

        if (!paymentIntentRedirectLink) {
          navigate("/status");
          throw new Error("Something went wrong!");
        }

        window.location.href = paymentIntentRedirectLink;
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err?.response?.data?.message || "Something went wrong.");
        error(err?.response?.data?.message || "Something went wrong.");
      }
    },
    [navigate]
  );

  useEffect(() => {
    if (id) handleFetch(id);
  }, [handleFetch, id]);

  return (
    <Box
      width="100%"
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress color="inherit" size={20} />
    </Box>
  );
};

export default PaymentLinkPage;
