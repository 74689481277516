import { generateUUID } from "helpers/helpers";
import { useRepsPermissions } from "helpers/hooks";
import { useEffect } from "react";

const LIMIT_ADDED_TAGS = 30;

export const useTags = ({
  setValue,
  formField,
  tags,
  tagsList,
  open,
  type,
}) => {
  const repPermissions = useRepsPermissions();

  const { selectedTagsList } = formField || {};

  useEffect(() => {
    if (tags?.length && open)
      setValue(
        "selectedTagsList",
        tags?.map(({ tag }) => tag)
      );
  }, [tags, setValue, open]);

  const handleCreate = (tagName) => {
    if (!tagName) return;

    const newTag = {
      id: generateUUID(),
      tag: tagName || formField?.tagInput,
      newTag: true,
    };
    setValue("selectedTagsList", [...selectedTagsList, newTag], {
      shouldDirty: true,
    });
  };

  const handleAddTag = (tag) => {
    if (selectedTagsList?.length >= LIMIT_ADDED_TAGS) return;
    setValue("selectedTagsList", [...selectedTagsList, tag], {
      shouldDirty: true,
    });
  };

  const checkIsUnique = (name, arr, list) => {
    const res1 = !arr.some((t) => {
      return t?.tag?.toLowerCase() === name?.toLowerCase();
    });

    const res2 = list.find((t) => {
      return t?.tag?.toLowerCase() === name?.toLowerCase();
    });

    if (res1 && !res2) return { isUnique: true, tag: {} };

    if (res1 && res2) return { isUnique: false, tag: res2 };

    return { isUnique: false, tag: {} };
  };

  const handlePressEnter = (e) => {
    if (selectedTagsList?.length >= LIMIT_ADDED_TAGS) return;
    if (type === "order" && repPermissions && !repPermissions?.settings?.orders)
      return;

    const val = e.target.value?.trim();

    const { isUnique, tag } = checkIsUnique(val, selectedTagsList, tagsList);

    if (e.keyCode === 13 && val !== "") {
      if (isUnique) {
        handleCreate(val);
      }

      if (!isUnique && tag?.id) {
        handleAddTag(tag);
      }
      setValue("tagInput", "");
    }
  };

  const handleBlur = (e) => {
    if (selectedTagsList?.length >= LIMIT_ADDED_TAGS) return;
    if (type === "order" && repPermissions && !repPermissions?.settings?.orders)
      return;

    const val = e.target.value.trim();

    const { isUnique, tag } = checkIsUnique(val, selectedTagsList, tagsList);

    if (val !== "") {
      if (isUnique) {
        handleCreate(val);
      }

      if (!isUnique && tag?.id) {
        handleAddTag(tag);
      }
      setValue("tagInput", "");
    }
  };

  const handleDeleteTag = (tagId) => {
    const tempList = [...selectedTagsList];
    const index = selectedTagsList.findIndex((t) => t?.id === tagId);
    if (index > -1) {
      tempList.splice(index, 1);
      setValue("selectedTagsList", [...tempList], { shouldDirty: true });
    }
  };

  return {
    tagsList,
    handlePressEnter,
    handleBlur,
    handleAddTag,
    handleDeleteTag,
    handleCreate,
  };
};
