export const FILTER_TABS = [
  {
    name: "All",
    value: "",
  },
  {
    name: "Unfulfilled",
    value: ["unfulfilled"],
    field: "delivery_status",
  },
  {
    name: "Unpaid",
    value: ["pending"],
    field: "payment_status",
  },
  {
    name: "Open",
    value: ["pending"],
    field: "order_status",
  },
  {
    name: "Closed",
    value: ["completed"],
    field: "order_status",
  },
];
