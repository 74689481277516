import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  textInput: {
    fontSize: ({ placeholderFontSize }) =>
      placeholderFontSize ? placeholderFontSize : "12px",
    height: "32px",
    "& > fieldset": {
      borderRadius: ({ tagInput }) => tagInput && "4px 4px 0 0",
    },
  },

  inputLabel: {
    fontSize: "12px",
    fontWeight: 400,
    color: "#B5B5AC",
  },

  fieldButton: {
    height: 25,
    whiteSpace: "nowrap",
    marginRight: "-12px",
  },

  fieldWrapper: {
    boxShadow: "none",
    border: "0.5px solid #D5D9D9",
    marginTop: "-1px",
    maxHeight: "71px",
    borderRadius: "0 0 4px 4px",
    padding: "2.5px 0 0 10.5px",
    overflow: "auto",
  },

  searchText: {
    fontSize: "12px",
    fontWeight: 400,
    color: "#000000",
    cursor: "pointer",
    margin: "1px 0 4px 4px",
  },

  chipField: {
    marginTop: "5px",
    maxHeight: "110px",
    overflowY: "auto",
  },

  tagChip: {
    backgroundColor: "#fff",
    color: "#707070",
    border: "0.5px solid #D5D9D9",
    borderRadius: "10px",
    height: "20px",
    maxWidth: "220px",
    fontSize: "12px",
    marginRight: "7px",
  },
}));

export default useStyles;
