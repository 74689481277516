import * as React from "react";

export const InfoIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={10.743}
    height={10.743}
    viewBox="0 0 10.743 10.743"
    {...props}
  >
    <path
      data-name="Icon awesome-info-circle"
      d="M5.371 0a5.372 5.372 0 1 0 5.372 5.372A5.372 5.372 0 0 0 5.371 0Zm0 2.383a.91.91 0 1 1-.91.91.91.91 0 0 1 .91-.911Zm1.213 5.5a.26.26 0 0 1-.26.26H4.418a.26.26 0 0 1-.26-.26v-.52a.26.26 0 0 1 .26-.26h.26V5.718h-.26a.26.26 0 0 1-.26-.26v-.521a.26.26 0 0 1 .26-.26h1.386a.26.26 0 0 1 .26.26v2.167h.26a.26.26 0 0 1 .26.26Z"
      fill="#47a06d"
    />
  </svg>
);
