import React from "react";

export const WalletIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="52"
      height="52"
      viewBox="0 0 52 52"
    >
      <rect
        id="Rectangle_8849"
        data-name="Rectangle 8849"
        width="52"
        height="52"
        rx="26"
        fill="#409a65"
        opacity="0.1"
      />
      <g
        id="Group_4897"
        data-name="Group 4897"
        transform="translate(-889.25 -16669.496)"
      >
        <path
          id="Path_1"
          data-name="Path 1"
          d="M51.5,7.248a1.75,1.75,0,1,0,0,3.5H65.111a2.639,2.639,0,0,1,2.639,2.639v6.3a2.569,2.569,0,0,1-2.569,2.569H61.5a.75.75,0,0,1,0-1.5h3.681a1.069,1.069,0,0,0,1.069-1.069v-6.3a1.139,1.139,0,0,0-1.139-1.139H51.5a3.25,3.25,0,1,1,0-6.5h1a.75.75,0,0,1,0,1.5ZM61.75,6.5a.75.75,0,0,1,.75-.75H66a.75.75,0,0,1,0,1.5H62.5A.75.75,0,0,1,61.75,6.5Z"
          transform="translate(857.25 16681.748)"
          fill="#409a65"
          fillRule="evenodd"
        />
        <path
          id="Path_2"
          data-name="Path 2"
          d="M57.47,8.028a.75.75,0,0,0,1.061,0l2-2A.75.75,0,0,0,59.47,4.968l-.72.72V2.5a.75.75,0,0,0-1.5,0V5.687l-.72-.72A.75.75,0,0,0,55.47,6.028Z"
          transform="translate(857.25 16681.748)"
          fill="#409a65"
          fillRule="evenodd"
        />
        <path
          id="Path_3"
          data-name="Path 3"
          d="M49,7.748a.75.75,0,0,1,.75.75v10A2.25,2.25,0,0,0,52,20.748H63a.75.75,0,0,1,0,1.5H52a3.75,3.75,0,0,1-3.75-3.75V8.5A.75.75,0,0,1,49,7.748Z"
          transform="translate(857.25 16681.748)"
          fill="#409a65"
          fillRule="evenodd"
        />
        <path
          id="Path_4"
          data-name="Path 4"
          d="M63,14.748a.75.75,0,0,1,.75.75v1a.75.75,0,0,1-1.5,0v-1A.75.75,0,0,1,63,14.748Z"
          transform="translate(857.25 16681.748)"
          fill="#409a65"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
};
