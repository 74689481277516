import { Grid } from "@mui/material";

export const TitleBlock = () => {
  return (
    <Grid
      mb={{ xs: 2.5, md: 1.5 }}
      fontSize={{ xs: 15, md: 16 }}
      fontWeight={{ xs: 400, md: 600 }}
      color="#363531"
      xs={12}
      item
    >
      Payment details
    </Grid>
  );
};
