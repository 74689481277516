import { downloadExcelFile as downloadFile } from "helpers/helpers";
import { useState } from "react";
import { getPaymentIntentOrderReportService } from "services/orders";
import { error } from "utils/notifications";

const openFilePreview = ({ res, print }) => {
  const url = URL.createObjectURL(res);
  print ? window.open(url).print() : window.open(url);
};

export const usePaymentReceived = ({ token }) => {
  const [loading, setLoading] = useState(false);

  const handleDownloadReport = async ({ print, download }) => {
    if (!token) return error("Something went wrong.");

    try {
      setLoading(true);
      const res = await getPaymentIntentOrderReportService({ token });
      if (download) {
        downloadFile(res, "pdf", "Invoice_report");
        return;
      }
      openFilePreview({ res, print });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      error(err?.response?.data?.message || "Something went wrong.");
    } finally {
      setLoading(false);
    }
  };

  return { loading, handleDownloadReport };
};
