import { makeStyles } from "@mui/styles";

export const cl = {
  search: {
    "& .MuiAutocomplete-paper": {
      boxShadow: "none",
    },
  },
  searchInput: {
    "& .MuiOutlinedInput-root": {
      height: "34px",
      py: "0px !important",
      fontSize: "15px",
      fontWeight: 300,
      backgroundColor: "#F8F8F8",
      "& > fieldset": {
        borderColor: "rgb(213,217,217)!important",
        borderWidth: "1px!important",
      },
    },
  },
  searchListBoxProps: {
    p: 0,
    maxHeight: "146px",
    boxShadow: "none",
    overflow: "overlay",
    borderRadius: "4px",
  },
};

const useStyles = makeStyles(() => ({
  textInput: {
    fontSize: 14,
    height: "32px",
  },
  textInputSearch: {
    fontSize: 14,
    height: "32px",
    paddingLeft: "18px",
  },
  inputLabel: {
    fontSize: 14,
    lineHeight: 1.5,
    top: -4,
    color: "#B5B5AC",
    "&.Mui-focused": {
      transform: "translate(14px, -5px) scale(0.75)",
    },
    "&.MuiInputLabel-shrink": {
      transform: "translate(14px, -5px) scale(0.75)",
    },
  },
  textInputFilled: {
    fontSize: "14px",
    height: "32px",
    paddingRight: 0,
  },
  textInputVariant: {
    fontSize: "12px",
    height: "28px",
    paddingRight: 0,
    "& input": { paddingLeft: "8px", paddingRight: "8px" },
  },
  textInputVariantWithAdor: {
    fontSize: "12px",
    height: "28px",
    paddingRight: 0,
    paddingLeft: "8px",
    "& input": {
      paddingRight: "4px",
    },
    lineHeight: 1.5,
  },
  textInputSmaller: {
    fontSize: "12px",
    height: "24px",
    paddingRight: 0,
  },
  textInputInventory: {
    fontSize: "12px",
    height: "24px",
    paddingRight: 0,
    "& .MuiInputBase-input": {
      padding: "0 0 0 8px",
    },
  },
  tooltip: {
    "& .MuiTooltip-tooltip": {
      transform: "translate(-5px, 0px)!important",
      boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
      backgroundColor: "#fff",
      whiteSpace: "pre-wrap",
      textAlign: "center",
    },

    "& .MuiTooltip-arrow": {
      "&:before": {
        backgroundColor: "#fff",
      },
    },
  },
  tooltipPhoto: {
    "& .MuiTooltip-tooltip": {
      transform: "translate(8px, 0px)!important",
      boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
      backgroundColor: "#fff",
      whiteSpace: "pre-wrap",
      textAlign: "center",
    },

    "& .MuiTooltip-arrow": {
      "&:before": {
        backgroundColor: "#fff",
      },
    },
  },
  textInputCorner: {
    fontSize: "12px",
    height: "28px",
    paddingRight: 0,
    borderRadius: "4px 0 0 4px",
  },
  textInputCornerBig: {
    fontSize: "15px",
    height: "39px",
    paddingRight: 0,
  },
  inputLabelFilled: {
    fontSize: "14px",
    lineHeight: 1,
    color: "#B5B5AC",
    "&.Mui-focused": {
      transform: "translate(14px, -5px) scale(0.75)",
    },
    "&.MuiInputLabel-shrink": {
      transform: "translate(14px, -5px) scale(0.75)",
    },
  },
  selectInput: {
    "& .MuiInputBase-input": {
      fontSize: "12px",
      color: "#5F6267",
    },
    "& svg": {
      fill: "#B5B5AC",
    },
  },
  selectIcon: {
    fill: "#B5B5AC",
  },
  selectInputBig: {
    "& .MuiInputBase-input": {
      fontSize: "15px",
      fontWeight: 400,
      color: "#707070",
    },
    "& svg": {
      fill: "#B5B5AC",
    },
  },
  multiTextInput: {
    fontSize: "14px",
  },
  select: {
    fontSize: "12px",
  },
  smallerButton: {
    height: "24px",
    "& > span": {
      fontSize: "12px",
    },
  },
  actionsButton: {
    border: "1px solid #D4D4D4",
    width: "72px",
    minWidth: "120px",
    height: "28px",
    paddingLeft: 0,
    paddingRight: 0,
    "& > span": {
      color: "#5F6267",
      fontSize: "12px",
    },
  },

  recipientsTable: {
    height: "100%",
    zIndex: "10",
    border: "1px solid #D5D9D9",
    width: "100%",
    backgroundColor: "#ffffff",
    overflow: "overlay",
    borderRadius: "4px",
    marginTop: "1px",
  },
  textfield: {
    color: "#ff0000",
    "& input::placeholder, & textarea::placeholder": {
      color: "#BEBEBE",
      opacity: 1,
      fontWeight: 400,
    },
  },
}));

export default useStyles;
