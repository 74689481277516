import { useEffect, useState, useCallback, useRef } from "react";
import { isEqual, snakeCase } from "lodash";
import { getActivitiesService } from "../../services/dashboard";
import { SCROLL_LIMIT_DASHBOARD_ACTIVITIES } from "../../utils/constants";
import { useRepsPermissions } from "helpers/hooks";

const prepereQuaryDate = (date) => {
  if (typeof date === "string") return {};
  const start_date = date?.[0]?.format()?.split("+")?.[0];
  const end_date = date?.[1]?.format()?.split("+")?.[0];
  return { start_date, end_date };
};

export const useActivities = ({
  currentRepresentative,
  currentFilterDate,
  currentTabActivities,
}) => {
  const repPermissions = useRepsPermissions();
  const [isLoadingActivitiesList, setIsLoadingActivitiesList] = useState(false);
  const [activitiesList, setActivitiesList] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMoreActivities, setHasMoreActivities] = useState(true);

  const isDateString = typeof currentFilterDate === "string";
  const { start_date, end_date } = prepereQuaryDate(currentFilterDate);

  const [params, setParams] = useState({
    limit: SCROLL_LIMIT_DASHBOARD_ACTIVITIES,
    page: 1,
    type: currentTabActivities,
    representativeId: currentRepresentative,
    date: isDateString ? snakeCase(currentFilterDate) : null,
    start_date: isDateString ? null : start_date,
    end_date: isDateString ? null : end_date,
  });

  useEffect(() => {
    setParams({
      limit: SCROLL_LIMIT_DASHBOARD_ACTIVITIES,
      page: 1,
      type: currentTabActivities,
      representativeId: currentRepresentative,
      date: isDateString ? snakeCase(currentFilterDate) : null,
      start_date: isDateString ? null : start_date,
      end_date: isDateString ? null : end_date,
    });
  }, [
    currentFilterDate,
    currentRepresentative,
    currentTabActivities,
    end_date,
    isDateString,
    start_date,
  ]);

  const getActivities = useCallback(() => {
    const controller = new AbortController();

    setActivitiesList([]);
    setIsLoadingActivitiesList(true);
    getActivitiesService(params, controller.signal)
      .then((res) => {
        setActivitiesList(res?.rows);
        setHasMoreActivities(res?.count > activitiesList?.length);
        setIsLoadingActivitiesList(false);
        setPage(1);
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err.message))
      .finally(() => setIsLoadingActivitiesList(false));

    return () => controller.abort();
  }, [activitiesList, params]);

  const paramsRef = useRef();

  useEffect(
    () => {
      if (repPermissions && !repPermissions?.hasAnyCheckedTrue) return;

      if (!isEqual(params, paramsRef.current)) {
        paramsRef.current = params;
        getActivities();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params]
  );

  const handleFetchActivities = () => {
    getActivitiesService({ ...params, page: page + 1 })
      .then((res) => {
        setActivitiesList((prev) => [...prev, ...res?.rows]);
        setHasMoreActivities(res?.count > activitiesList?.length);
        setPage((prev) => prev + 1);
        setIsLoadingActivitiesList(false);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err.message);
      })
      .finally(() => {
        setIsLoadingActivitiesList(false);
      });
  };

  return {
    isLoadingActivitiesList,
    activitiesList,
    handleFetchActivities,
    hasMoreActivities,
    getActivities,
  };
};
