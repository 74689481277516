import { Box, IconButton } from "@mui/material";

export const CounterBlock = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "2.9%",
        minWidth: "2.9%",
      }}
    >
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls="long-menu"
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        sx={{
          width: "28px",
          height: "28px",
          p: 0.5,
          cursor: "auto",
        }}
      >
        <Box
          sx={{
            counterIncrement: "section",
            position: "relative",
            "&::before": {
              content: "counter(section, decimal-leading-zero)",
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
              fontSize: "15px",
              fontWeight: 500,
              color: "#D4D4D4",
            },
          }}
          component="span"
        />
      </IconButton>
    </Box>
  );
};
