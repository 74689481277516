export const SETTINGS_TABS = [
  "Profile",
  "Payments",
  "Representatives",
  "Orders",
  "Inventory",
  "Payment Terms",
  "Import/Export",
  "Integrations",
  "Order Direct",
];

export const ORDERS_TABS = [
  { value: "general", name: "General" },
  { value: "tags", name: "Tags" },
];

export const ORDER_DIRECT_TABS = [
  { value: "overview", name: "Overview" },
  { value: "storefront_access", name: "Storefront Access" },
  { value: "payments", name: "Payments" },
];

export const PAYTERMS = [
  {
    id: 0,
    active: true,
    name: "NET 15",
    daysInvoices: 15,
  },
  {
    id: 1,
    active: true,
    name: "NET 30",
    daysInvoices: 30,
  },
  {
    id: 2,
    active: true,
    name: "Due on receipt",
    daysInvoices: 0,
  },
];
