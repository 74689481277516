import { array, string, object, func } from "prop-types";
import { Stack } from "@mui/material";
import { FreeCasesItem } from "./components";

export const ListBlock = ({
  list,
  formDiscountType,
  paymentStatus,
  handleUpdateFreeCaseProduct,
  handleRemoveFreeCaseProduct,
}) => {
  return (
    <Stack
      sx={{
        borderWidth: "0 0.5px 0.5px 0.5px",
        borderStyle: "solid",
        borderColor: "#D4D4D4",
        borderRadius: "0 0 4px 4px",
      }}
    >
      {list?.map((product) => {
        return (
          <FreeCasesItem
            key={product?.id}
            product={product}
            formDiscountType={formDiscountType}
            paymentStatus={paymentStatus}
            quantity={product.discountQty || 0}
            handleUpdateFreeCaseProduct={handleUpdateFreeCaseProduct}
            handleRemoveFreeCaseProduct={handleRemoveFreeCaseProduct}
            price={
              (product?.wholesalePrice || product?.price || 0) *
              (product?.discountQty || 0)
            }
          />
        );
      })}
    </Stack>
  );
};

ListBlock.propTypes = {
  list: array,
  paymentStatus: string,
  formDiscountType: string,
  formField: object,
  handleUpdateFreeCaseProduct: func,
  handleRemoveFreeCaseProduct: func,
  discDict: array,
};
ListBlock.defaultProps = { list: [] };
