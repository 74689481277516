import { useCallback, useMemo, useState, useRef } from "react";
import { string, object, func, oneOfType } from "prop-types";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { Typography, Box } from "@mui/material";

import { ArrowLeftIcon } from "components/Icons";
import { StyledButton, ValidationPopper } from "components";
import { openDiscardChanges } from "redux/actions/confirmDialogs";
import { validationSchema } from "../ContentTabs/ContentTabs.validation";
import useStyles from "./CatalogNewProductSwitcherTab.styles";
import { checkAndPrepareText } from "helpers/helpers";
import { createSelector } from "reselect";
import {
  confirmDialogFormChangedSelector,
  editTypeSelector,
} from "redux/selectors/confirmDialogs";

const selector = createSelector(
  editTypeSelector,
  confirmDialogFormChangedSelector,
  (editType, formChanged) => ({
    editType,
    formChanged,
  })
);

export const CatalogNewProductSwitcherTab = ({
  title,
  currentTab,
  navigatePath,
  handleSubmit,
  errors,
  trigger,
  control,
  navigateState,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const refSaveBtn = useRef();
  const { pathname } = useLocation();

  const [isOpen, setIsOpen] = useState(false);

  const { editType, formChanged } = useSelector(selector);

  const { isMultiple, name, sku, manufacturerId, itemsPerCase, minOrderQTY } =
    useWatch({
      control,
    });

  const validationNames = Object.keys(
    validationSchema({ isColor: null, isSize: null })?.fields
  );

  const hasDuplicateNameError = errors?.name?.type === "duplicate";
  const hasCharacterNameError = errors?.name?.type === "character";

  const dispatch = useDispatch();

  const isDisabled = useMemo(() => {
    if (!isMultiple) {
      return (
        hasDuplicateNameError ||
        !sku ||
        !itemsPerCase ||
        !minOrderQTY ||
        !!errors?.sku ||
        !!errors?.itemsPerCase ||
        !!errors?.wholesalePrice ||
        !!errors?.name
      );
    }

    return (
      hasDuplicateNameError ||
      !name ||
      !minOrderQTY ||
      !manufacturerId ||
      !!errors?.name ||
      !!errors?.minOrderQTY ||
      !!errors?.manufacturerId ||
      !!errors?.variationsFields
    );
  }, [
    errors?.itemsPerCase,
    errors?.wholesalePrice,
    errors?.manufacturerId,
    errors?.minOrderQTY,
    errors?.name,
    errors?.sku,
    errors?.variationsFields,
    hasDuplicateNameError,
    isMultiple,
    itemsPerCase,
    manufacturerId,
    minOrderQTY,
    name,
    sku,
  ]);

  const handleConfirmChangesDialog = useCallback(() => {
    dispatch(openDiscardChanges(() => navigate(-1, { state: { currentTab } })));
  }, [currentTab, dispatch, navigate]);

  const errorCount = Object.keys(errors).reduce((prevSum, key) => {
    if (Array.isArray(errors[key]))
      return prevSum + Object.keys(errors[key]).length;
    return prevSum + 1;
  }, 0);

  // const handleBarcodeDublicate = useCallback(() => {
  //   if (
  //     !errors ||
  //     !errors?.variationsFields ||
  //     !errors?.variationsFields?.length
  //   )
  //     return false;

  //   const hasBarcodeError = errors.variationsFields.filter(
  //     (item) => item.barcode
  //   );

  //   const hasBarcodeDublicates = hasBarcodeError.length
  //     ? hasBarcodeError.filter(
  //         ({ barcode }) => barcode.type === "barcode-dublicate"
  //       )
  //     : [];

  //   return !!hasBarcodeDublicates.length;
  // }, [errors]);

  const handleBarcodeMaxSymbols = useCallback(() => {
    if (
      !errors ||
      !errors?.variationsFields ||
      !errors?.variationsFields?.length
    )
      return false;

    const hasBarcodeError = errors.variationsFields.filter(
      (item) => item.barcode
    );

    const hasBarcodeErrors = hasBarcodeError.length
      ? hasBarcodeError.filter(({ barcode }) => barcode.type === "barcode")
      : [];

    return !!hasBarcodeErrors.length;
  }, [errors]);

  const variationsCharactersErrors = useMemo(() => {
    if (!errors?.variationsFields?.length) return [];

    const errArr = [];

    const filterItemsByType = (item) =>
      [item?.sku?.type, item?.color?.type, item?.size?.type].includes(
        "character"
      );

    const prepareItems = (item) => {
      if (item?.sku?.type === "character" && item?.sku?.message)
        errArr.push(item.sku.message);
      if (item?.color?.type === "character" && item?.color?.message)
        errArr.push(item.color.message);
      if (item?.size?.type === "character" && item?.size?.message)
        errArr.push(item.size.message);
      return errArr;
    };

    errors.variationsFields.filter(filterItemsByType).forEach(prepareItems);

    return checkAndPrepareText(errArr.join());
  }, [errors?.variationsFields]);

  const tooltipTitle = useMemo(() => {
    // const hasBarcodeErrors = handleBarcodeDublicate();
    const hasBarcodeErrors = handleBarcodeMaxSymbols();

    const title = hasBarcodeErrors
      ? "\n• Barcode length: Max 14 symbols\n"
      : "";

    return (
      <Typography
        sx={{ whiteSpace: "pre-wrap" }}
        fontSize="12px"
        fontWeight="300"
      >
        {/* {hasBarcodeErrors && "• "} */}

        {`There ${errorCount === 1 ? "is" : "are"} ${errorCount} item${
          errorCount === 1 ? "" : "s"
        } that require your attention`}

        {title}

        {hasBarcodeErrors ? null : <br />}

        {variationsCharactersErrors?.length
          ? `• Invalid character used ${variationsCharactersErrors.join(", ")}`
          : [errors?.name?.type, errors?.sku?.type].includes("character") &&
            `• Invalid character used ${
              errors?.name?.message || errors?.sku?.message
            }`}
      </Typography>
    );
  }, [
    errorCount,
    errors?.name?.message,
    errors?.name?.type,
    errors?.sku?.message,
    errors?.sku?.type,
    handleBarcodeMaxSymbols,
    variationsCharactersErrors,
  ]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        px: "32px",
        backgroundColor: "#F0F0F0",
        borderBottom: "0.5px solid #D4D4D4",
        height: "44px",
      }}
      data-testid="switcher-tab"
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: 3,
        }}
      >
        <Box>
          {title && (
            <Typography
              variant="h6"
              fontWeight="400"
              fontSize="20px"
              color="#9C9C94"
            >
              {title}
            </Typography>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: 1,
        }}
      >
        <>
          <StyledButton
            color="greySecondary"
            label="Back"
            className={classes.biggerButton}
            component={Link}
            to={{
              pathname: formChanged
                ? location.pathname
                : navigatePath || "/catalog",
              state: { navigateState },
            }}
            state={navigateState}
            from={pathname}
            onClick={() => {
              if (formChanged) {
                handleConfirmChangesDialog("/catalog");
              }
            }}
            startIcon={<ArrowLeftIcon />}
          />
          <Box
            component="span"
            ref={refSaveBtn}
            onMouseEnter={() => {
              hasDuplicateNameError || hasCharacterNameError
                ? trigger(validationNames?.filter((item) => item !== "name"))
                : trigger();
              setIsOpen(true);
            }}
          >
            <StyledButton
              disabled={isDisabled}
              className={classes.smallerButton}
              variant="contained"
              label="Save"
              onClick={(e) => {
                e.preventDefault();
                if (formChanged && editType === "product-photo") {
                  return dispatch(openDiscardChanges(() => handleSubmit()));
                }
                handleSubmit();
              }}
            />
          </Box>
        </>
        <ValidationPopper
          isOpen={isOpen}
          tooltipTitle={tooltipTitle}
          anchorEl={refSaveBtn.current}
          errors={errors}
          setIsOpen={setIsOpen}
          tailProps={{
            top: -6,
            right: 44,
            rotate: -180,
          }}
          offset={[15, 16]}
        />
      </Box>
    </Box>
  );
};

CatalogNewProductSwitcherTab.propTypes = {
  title: oneOfType([string, object]),
  handleSubmit: func,
  currentTab: string,
  navigatePath: string,
  navigateState: string,
  errors: object,
  trigger: func,
  control: object,
};
CatalogNewProductSwitcherTab.defaultProps = {
  title: "no title",
  navigatePath: "/catalog",
  navigateState: "Products",
};

export default CatalogNewProductSwitcherTab;
