import { number, string, func, bool, object, shape, arrayOf } from "prop-types";
import pluralize from "pluralize";
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Link,
  Typography,
} from "@mui/material";

export const SelectLabelComponent = ({
  itemCheckedCount,
  itemAllCount,
  name,
  onSelectAll,
  dividerAfterNumber,
  dividerAfterSelect,
  onCancel,
  loading,
  checkboxNemeStartAdor,
  checkboxNemeEndAdor,
  stylesWrapper,
  stylesSelectedBlock,
  stylesText,
  stylesDivider,
  additionalActions,
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        pl: "16px",
        ...stylesWrapper,
      }}
    >
      <Box
        sx={{
          minWidth: "24px",
          height: "24px",
          bgcolor: "rgba(71, 160, 110, 0.2)",
          borderRadius: "4px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          px: "4px",
          mr: "9px",
          ...stylesSelectedBlock,
        }}
      >
        <Typography
          sx={stylesText}
          fontSize={14}
          fontWeight={500}
          color="#47A06D"
        >
          {itemCheckedCount}
        </Typography>
      </Box>

      <Box>
        <Typography
          sx={stylesText}
          fontSize={14}
          fontWeight={600}
          color="#47A06D"
        >
          {name}
        </Typography>
      </Box>

      {dividerAfterNumber && (
        <Divider
          orientation="vertical"
          sx={{
            height: "14px",
            ml: "16px",
            ...stylesDivider,
          }}
        />
      )}

      {onSelectAll ? (
        <Box ml="18px">
          <FormControlLabel
            disabled={loading}
            sx={{
              height: "34px",
              color: "#FF6969",
            }}
            onClick={(e) => e.stopPropagation()}
            control={
              <Checkbox
                checked={itemCheckedCount === itemAllCount}
                indeterminate={itemCheckedCount !== itemAllCount}
                onChange={(e) => onSelectAll(e.target.checked)}
              />
            }
            label={
              <Box sx={{ displat: "flex", alignItems: "center" }}>
                <Typography
                  sx={stylesText}
                  fontSize={13}
                  fontWeight={400}
                  color="#707070"
                >
                  {checkboxNemeStartAdor}
                  <Box
                    sx={stylesText}
                    fontWeight={700}
                    color="#47A06D"
                    component="span"
                  >
                    {" "}
                    {itemAllCount}{" "}
                  </Box>
                  {pluralize(checkboxNemeEndAdor, itemAllCount)}
                </Typography>
              </Box>
            }
          />
        </Box>
      ) : null}

      {dividerAfterSelect && (
        <Divider
          orientation="vertical"
          sx={{
            height: "14px",
            mr: "4px",
            ...stylesDivider,
          }}
        />
      )}

      <Box display="flex" alignItems="center">
        {additionalActions?.length
          ? additionalActions.map((action) => (
              <Box key={action?.label} display="flex" alignItems="center">
                {action?.dividerBefore ? (
                  <Divider
                    orientation="vertical"
                    sx={{
                      height: "14px",
                      ...action?.stylesDividerBefore,
                    }}
                  />
                ) : null}

                <Link
                  sx={{
                    cursor: action?.disabled ? "auto" : "pointer",
                    fontSize: 13,
                    color: "#47A06D",
                    opacity: action?.disabled ? 0.5 : 1,
                    ...action?.labelStyles,
                  }}
                  underline="none"
                  onClick={action?.onClick}
                >
                  {action?.label}
                </Link>

                {action?.dividerAfter ? (
                  <Divider
                    orientation="vertical"
                    sx={{
                      height: "14px",
                      ...action?.stylesDividerAfter,
                    }}
                  />
                ) : null}
              </Box>
            ))
          : null}

        {onCancel ? (
          <Link
            sx={{
              cursor: "pointer",
              fontSize: 13,
              color: "#FF6969",
              paddingLeft: "12px",
            }}
            underline="none"
            onClick={onCancel}
          >
            Cancel
          </Link>
        ) : null}
      </Box>
    </Box>
  );
};

SelectLabelComponent.propTypes = {
  itemCheckedCount: number,
  itemAllCount: number,
  name: string,
  onSelectAll: func,
  dividerAfterNumber: bool,
  dividerAfterSelect: bool,
  onCancel: func,
  loading: bool,
  checkboxNemeStartAdor: string,
  checkboxNemeEndAdor: string,
  stylesWrapper: object,
  stylesSelectedBlock: object,
  stylesText: object,
  stylesDivider: object,
  additionalActions: arrayOf(
    shape({
      label: string,
      labelStyles: object,
      onClick: func,
      dividerBefore: bool,
      dividerAfter: bool,
    })
  ),
};
SelectLabelComponent.defaultProps = {
  itemCheckedCount: 0,
  itemAllCount: 0,
  name: "",
  loadimg: false,
  dividerAfterNumber: false,
  dividerAfterSelect: false,
  checkboxNemeStartAdor: "Select all",
  checkboxNemeEndAdor: "entry",
  stylesWrapper: {},
  stylesSelectedBlock: {},
  stylesText: {},
  stylesDivider: {},
};
