import { doc, getFirestore, writeBatch } from "firebase/firestore";
import { deleteObject, getStorage, listAll, ref } from "firebase/storage";
import StyledButton from "../../components/StyledButton";
import {
  openConfirmDialogAction,
  setConfirmButtonsAction,
  setConfirmIsOpenAction,
  setConfirmText1Action,
  setConfirmTitleAction,
} from "../../redux/actions/confirmDialogs";

export const confirmDeleteChatAction = (handleConfirm) => {
  return (dispatch) => {
    dispatch(
      openConfirmDialogAction({
        isIcon: false,
        title: "",
        text: "",
        propBtns: null,
      })
    );
    dispatch(setConfirmIsOpenAction(true));
    dispatch(setConfirmTitleAction("Delete Chat?"));
    dispatch(
      setConfirmText1Action(
        "Deleting removes the chat from your and your representative's inboxes."
      )
    );
    dispatch(
      setConfirmButtonsAction(
        <>
          <StyledButton
            variant="contained"
            label="Cancel"
            onClick={() => dispatch(setConfirmIsOpenAction(false))}
          />
          <StyledButton
            variant="contained"
            label="Delete"
            color="confirmDelete"
            onClick={handleConfirm}
          />
        </>
      )
    );
  };
};

export const handleDeleteChatFiles = (roomId) => {
  const roomFilesRef = ref(getStorage(), `${roomId}`);
  listAll(roomFilesRef).then((res) => {
    res.items.forEach((fileRef) =>
      deleteObject(fileRef).catch((error) => error(error))
    );
  });
};

export const bytesToSize = (bytes) => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "n/a";
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  if (i === 0) return `${bytes} ${sizes[i]})`;
  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
};

export const handleDeleteChatMessages = async (loc, messages) => {
  const batch = writeBatch(getFirestore());
  messages.docs.forEach((message) => {
    const messageRef = doc(getFirestore(), `${loc}/${message.id}`);
    batch.delete(messageRef);
  });
  await batch.commit();
};
