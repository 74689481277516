import { string, number, shape, bool } from "prop-types";
import moment from "moment";
import { Box, Typography } from "@mui/material";
import {
  getFormattedDate,
  separateNumWithComma,
} from "../../../helpers/helpers";

export const DialogFooterComponent = ({ state, date, sum, timeZone }) => {
  const { isTrial } = state;
  return (
    <Box textAlign="center" pt={1.5} pb={3.5}>
      {sum && !isTrial ? (
        <Typography fontSize={12} fontWeight={300} color="#1C1C19">
          When your plan renews on{" "}
          {moment(getFormattedDate(date, timeZone)).format("MMM D, YYYY")}, your
          new monthly total will be{" "}
          <Box component="span" fontWeight={600} color="#00A252">
            ${separateNumWithComma(sum)}
          </Box>
          . You will receive a prorated charge for your current billing cycle on
          your next invoice.
        </Typography>
      ) : (
        <Box height="18px" />
      )}
    </Box>
  );
};

DialogFooterComponent.propTypes = {
  date: string,
  sum: number,
  timeZone: string,
  state: shape({
    isTrial: bool,
  }),
};
DialogFooterComponent.defaultProps = {
  date: "",
  sum: 0,
  timeZone: "",
  state: {
    isTrial: false,
  },
};
