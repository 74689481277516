/* eslint-disable react/prop-types */
import React from "react";

export const CheckboxIconChecked = ({
  size = 24,
  color = "#47a06d",
  checkedViewBox = "0 0 23 23",
  rectSize = 23,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox={checkedViewBox}
    >
      <rect
        id="Rectangle_2581"
        width={rectSize}
        height={rectSize}
        rx="4"
        fill={color}
      />
      <path
        id="checkbox-icon-checked-path"
        d="M1440.355,4366.756l3.8,3.8,8-8"
        transform="translate(-1435.355 -4354.669)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};
