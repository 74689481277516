import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  profileHeader: {
    padding: "24px 26px 0 34px",
  },

  editButton: {
    height: "28px",
    borderColor: "#CCCCCC",
  },

  title: {
    color: "#5F6267",
    fontSize: "30px",
  },

  profileInnerPage: {
    padding: "20px 26px 15px 35px",
    maxHeight: "calc(100% - 128px)",
    overflow: "auto",
  },

  buttonsBlock: {
    display: "flex",
    gap: "12px",
  },

  editInput: {
    height: "43px",
    fontSize: "15px",
    borderRadius: "9px",
    paddingLeft: 0,
  },
  editInputPhone: {
    height: "43px",
    fontSize: "15px",
    borderRadius: "9px",
    paddingLeft: 0,
    "& input": {
      paddingLeft: "18px",
    },
  },
}));

export default useStyles;
