import React from "react";

// eslint-disable-next-line react/prop-types
export const CheckmarkIcon = ({ stroke, strokeWidth, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.484"
      height="12.346"
      viewBox="0 0 17.484 12.346"
      {...props}
    >
      <g id="path" transform="translate(1.414 1.414)">
        <path
          id="path-2"
          data-name="path"
          d="M1440.355,4367.771l4.723,4.723,9.932-9.932"
          transform="translate(-1440.355 -4362.562)"
          fill="none"
          stroke={stroke || "#42a57f"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={strokeWidth || 2}
        />
      </g>
    </svg>
  );
};
