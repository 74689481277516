import React from "react";

// eslint-disable-next-line react/prop-types
export const AtentionIcon = ({ size = "14.75", ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 14.75 14.75"
      {...props}
    >
      <g
        id="Icon_feather-info"
        data-name="Icon feather-info"
        transform="translate(-2.5 -2.5)"
      >
        <path
          id="Path_2437"
          data-name="Path 2437"
          d="M16.75,9.875A6.875,6.875,0,1,1,9.875,3,6.875,6.875,0,0,1,16.75,9.875Z"
          fill="#ff6254"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_2438"
          data-name="Path 2438"
          d="M18,21.551V18"
          transform="translate(-8.125 -11.25)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_2439"
          data-name="Path 2439"
          d="M18,12h0"
          transform="translate(-8.125 1)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
};
