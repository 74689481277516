import { useDebounce } from "helpers/hooks";
import { useEffect, useState } from "react";
import { checkTagAvailabilityService } from "services/tags";
import { error } from "utils/notifications";

export const useCheckUniqueTag = ({
  tag,
  editTag,
  setError,
  type,
  chosenTags,
}) => {
  const tagDebounced = useDebounce(tag, 500);
  const [isUnique, setIsUnique] = useState(false);

  const handleCheckUniqueTag = async (tag) => {
    if (chosenTags?.length) {
      const hasTag = chosenTags?.some((t) => {
        const savedTag = t?.tag || t;
        return savedTag?.toLowerCase() === tag?.toLowerCase();
      });

      if (hasTag) {
        setError("tag", {
          type: "custom",
          message: "This tag already exists",
        });
        setIsUnique(false);
        return;
      }
    }

    try {
      if (type && tag) {
        let res;

        res = await checkTagAvailabilityService({ tag }, type);

        if (!res?.tagWithName?.id || res?.tagWithName?.tag === editTag?.tag) {
          return setIsUnique(true);
        }
      }
      setError("tag", {
        type: "custom",
        message: "This tag already exists",
      });
      setIsUnique(false);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      error(err?.response?.data?.message || "Something went wrong.");
    }
  };

  useEffect(() => {
    if (tagDebounced) handleCheckUniqueTag(tagDebounced.trim());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagDebounced]);

  useEffect(() => setIsUnique(false), [tag]);

  return { isUnique };
};
