import React from "react";
/* eslint-disable react/prop-types */
export const CheckboxIcon = ({
  stroke = "rgb(112,112,112)",
  size = 24,
  fill = "none",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <g
        id="Rectangle_4524"
        data-name="Rectangle 4524"
        fill="none"
        stroke={stroke}
        strokeWidth="1"
      >
        <rect width="24" height="24" rx="4" stroke="none" />
        <rect x="0.5" y="0.5" width="22" height="22" rx="3.5" fill={fill} />
      </g>
    </svg>
  );
};
