/* eslint-disable react/prop-types */
import React from "react";

export const PhotoCameraIcon = ({
  width = 28.443,
  height = 24.888,
  fill = "currentColor",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 28.443 24.888"
    >
      <path
        id="Icon_awesome-camera"
        data-name="Icon awesome-camera"
        d="M28.443,8.472v16a2.667,2.667,0,0,1-2.667,2.667H2.667A2.667,2.667,0,0,1,0,24.471v-16A2.667,2.667,0,0,1,2.667,5.805H7.555l.683-1.828A2.663,2.663,0,0,1,10.733,2.25H17.7A2.663,2.663,0,0,1,20.2,3.978l.689,1.828h4.889A2.667,2.667,0,0,1,28.443,8.472Zm-7.555,8a6.666,6.666,0,1,0-6.666,6.666A6.672,6.672,0,0,0,20.888,16.472Zm-1.778,0a4.889,4.889,0,1,1-4.889-4.889A4.9,4.9,0,0,1,19.11,16.472Z"
        transform="translate(0 -2.25)"
        fill={fill}
      />
    </svg>
  );
};
