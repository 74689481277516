export const TASKS_FILTER = "TASKS_FILTER";
export const TASKS_SWITCH = "TASKS_SWITCH";
export const RESET_TASKS_FILTER = "RESET_TASKS_FILTER";
export const RESET_TASKS_SWITCH = "RESET_TASKS_SWITCH";

export const tasksFilterAction = (payload) => ({
  type: TASKS_FILTER,
  payload,
});

export const tasksSwitchAction = (payload) => ({
  type: TASKS_SWITCH,
  payload,
});

export const resetTasksFilterAction = () => ({
  type: RESET_TASKS_FILTER,
});

export const resetTasksSwitchAction = () => ({
  type: RESET_TASKS_SWITCH,
});
