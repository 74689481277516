export const cl = {
  paper: {
    position: "relative",
    mt: "11px",
    paddingTop: "16px",
    paddingBottom: "18px",
    display: "flex",
  },
  block: {
    paddingLeft: 5.25,
    paddingRight: 2,
    maxWidth: "300px",
    minWidth: "200px",
    overflow: "hidden",
  },
  blockLast: {
    display: "flex",
    justifyContent: "flex-end",
    paddingX: 1.5,
    flex: "1 1 auto",
  },
  textHeader: {
    fontSize: "12px",
    fontWeight: 400,
    color: "#000000",
  },
  textBody: {
    fontSize: "12px",
    lineHeight: "1.3",
    color: "#000000",
    maxHeight: "32px",
  },
  label: {
    height: "24px",
    "& .MuiFormLabel-root": {
      color: "#B5B5AC",
      fontSize: "12px",
      top: "2px",
    },
    "& .MuiOutlinedInput-root": {
      height: "100%",
    },
  },
  iconBtn: { height: "10px", paddingX: "3px" },
  payment: {
    wrapper: {
      paddingLeft: 5.25,
      paddingRight: 2,
      maxWidth: "400px",
      overflow: "hidden",
    },
    termsWrapper: { marginBottom: 1, display: "flex", flexDirection: "column" },
    text: { fontSize: 12, fontWeight: 500, color: "#000" },
    term: {
      fontSize: 14,
      fontWeight: 400,
      color: "#000",
      marginRight: "10.5px",
    },
    counter: {
      display: "flex",
      alignItems: "center",
      width: "max-content",
      height: 22,
      backgroundColor: "rgba(238, 238, 238, 0.5)",
      borderRadius: "5px",
    },
    counterText: {
      fontSize: 12,
      color: "#707070",
      px: 1.18,
    },
  },
  divider: { width: "16px", display: "flex", justifyContent: "center" },
  statusText: {
    marginLeft: 1,
    justifyContent: "center",
    display: "flex",
    gap: "4px",
    alignItems: "center",
    height: "24px",
  },
};
