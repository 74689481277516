import { useCallback, useEffect, useReducer } from "react";
import { number, bool, func } from "prop-types";
import { Box, IconButton } from "@mui/material";
import { ArrowPrevIcon, ArrowNextIcon } from "../Icons";
import { createSelector } from "reselect";
import { groupIdsSelector } from "../../redux/selectors/gallery";
import { useSelector } from "react-redux";

const selector = createSelector(groupIdsSelector, (groupIds) => ({ groupIds }));

function reducer(state, action) {
  switch (action.type) {
    case "increment":
      return { count: state.count + 1 };
    case "decrement":
      return { count: state.count - 1 };
    default:
      throw new Error();
  }
}

export const GalleryPagination = ({
  currentPage,
  loading,
  onGalleryPagination,
}) => {
  const { groupIds } = useSelector(selector);
  const initialState = { count: currentPage || 1 };
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleArrowNavigation = useCallback(
    (event) => {
      if (event.key === "ArrowLeft" && state.count > 1 && !loading) {
        onGalleryPagination(state.count - 1);
        dispatch({ type: "decrement" });
      } else if (
        event.key === "ArrowRight" &&
        state.count < groupIds.length &&
        !loading
      ) {
        onGalleryPagination(state.count + 1);
        dispatch({ type: "increment" });
      }
    },
    [state.count, groupIds.length, loading, onGalleryPagination]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleArrowNavigation);
    return () => {
      window.removeEventListener("keydown", handleArrowNavigation);
    };
  }, [handleArrowNavigation]);

  return (
    <Box display="flex" alignItems="center">
      <IconButton
        disabled={state?.count === 1 || loading}
        size="small"
        sx={{
          width: "30px",
          height: "30px",
        }}
        onClick={() => {
          onGalleryPagination(state.count - 1);
          dispatch({ type: "decrement" });
        }}
      >
        <ArrowPrevIcon
          color={state?.count === 1 || loading ? "#D5D9D9" : "#707070"}
          width={10}
          height={18}
        />
      </IconButton>

      <Box fontSize={12} color={loading ? "#D5D9D9" : "#A1A1A1"}>
        {state.count} of {groupIds?.length}
      </Box>

      <IconButton
        disabled={state?.count === groupIds?.length || loading}
        sx={{
          width: "30px",
          height: "30px",
        }}
        onClick={() => {
          onGalleryPagination(state.count + 1);
          dispatch({ type: "increment" });
        }}
      >
        <ArrowNextIcon
          color={
            state?.count === groupIds?.length || loading ? "#D5D9D9" : "#707070"
          }
          width={10}
          height={18}
        />
      </IconButton>
    </Box>
  );
};

GalleryPagination.propTypes = {
  currentPage: number,
  loading: bool,
  onGalleryPagination: func,
};
GalleryPagination.defaultProps = {
  currentPage: 1,
  loading: false,
  onGalleryPagination: () => {},
};
