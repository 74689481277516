import { bool, func, shape, string, object } from "prop-types";
import { Box, Dialog } from "@mui/material";
import {
  ReturnDialogBody,
  ReturnDialogFooter,
  ReturnDialogHeader,
  ReturnDialogPopper,
} from "./components";
import {
  useFooter,
  useReturnDialogAction,
  useSendNotifications,
  usePopperActions,
  useFormActions,
  useRestockActions,
  useHeaderActions,
  useShippingActions,
  useReturnListActions,
  useOnSubmit,
} from "./hooks";

export const ReturnDialog = ({
  open,
  onClose,
  order,
  returnItem,
  handleGetOrder,
  successCallback = () => {},
}) => {
  const { state } = useReturnDialogAction({ open, order, returnItem });

  const sendNotificationsData = useSendNotifications({ order });

  const formData = useFormActions({
    state,
    order,
    open,
    returnItem,
  });

  const { errors, control, trigger, formField, setValue, handleSubmit } =
    formData;

  const headerData = useHeaderActions({
    paymentStatus: order?.paymentStatus,
    deliveryStatus: order?.deliveryStatus,
    reason: formField?.reason,
  });

  const restockData = useRestockActions({
    isEdit: !!returnItem?.id,
    isTotalDelivery: order?.totalDelivered === 0,
    productReturns: formField?.productReturns,
  });

  const shippingData = useShippingActions({
    deliveryMethod: formField?.deliveryMethod,
    delivererId: formField?.delivererId,
    repsSalesUsers: state?.repsSalesUsers,
  });

  const returnListData = useReturnListActions({
    open,
    returnItem,
    order,
    productReturns: formField?.productReturns,
  });

  const {
    popperData,
    setPopperType,
    sendNotificationRef,
    restockRef,
    btnSubmitRef,
    deliveryMethodRef,
  } = usePopperActions({ errors, formField, isEditReturn: !!returnItem?.id });

  const footerData = useFooter({
    open,
    deliveryMethod: formField?.deliveryMethod,
    delivererId: formField?.delivererId,
    productReturns: formField?.productReturns,
    trackingId: formField?.trackingId,
    shippingCarrier: formField?.shippingCarrier,
    returnItem,
    formField,
    errors,
  });

  const { onSubmit } = useOnSubmit({
    returnItem,
    onClose,
    orderId: order?.id,
    handleGetOrder,
    successCallback,
  });

  return (
    <>
      <ReturnDialogPopper
        open={popperData.open}
        anchorEl={popperData.anchorEl}
        text={popperData.text}
        modifiers={popperData.modifiers}
        style={popperData?.style}
      />

      <Dialog open={open} onClose={onClose}>
        <Box width="510px">
          <ReturnDialogHeader {...{ state, onClose }} />

          <ReturnDialogBody
            {...{
              order,
              state,
              restockRef,
              setPopperType,
              formField,
              control,
              setValue,
              errors,
              headerData,
              restockData,
              shippingData,
              deliveryMethodRef,
              returnListData,
            }}
          />

          <ReturnDialogFooter
            {...{
              onClose,
              control,
              trigger,
              sendNotificationsData,
              footerData,
              sendNotificationRef,
              btnSubmitRef,
              setPopperType,
            }}
            onSubmit={handleSubmit(onSubmit)}
          />
        </Box>
      </Dialog>
    </>
  );
};

ReturnDialog.propTypes = {
  open: bool,
  onClose: func,
  order: shape({
    id: string,
  }),
  returnItem: object,
  isEdit: bool,
  handleGetOrder: func,
  successCallback: func,
};
ReturnDialog.defaultProps = {
  open: false,
  onClose: () => {},
  order: {},
  returnItem: {},
  isEdit: false,
};
