import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  summaryWrapper: {
    display: "flex",
    gap: "20px",
    height: "64px",
    border: "0.5px solid #D5D9D9",
    alignItems: "center",
    borderRadius: "4px",
    background: "#fff",
    padding: "0 24px",
    margin: "12px 0 10px 0",
  },

  divider: {
    maxHeight: "23px",
  },

  countTitle: {
    fontSize: "17px",
    color: "#A5A5A5",
  },

  countNumber: {
    color: "#5F6267",
    fontWeight: "700",
  },
  //   countBlock: {
  //     borderRight: "",
  //   },
}));

export default useStyles;
