import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    padding: "16px 10px 12px 31px",
  },
  dialogContent: {
    minWidth: "388px",
    padding: ({ showCheckboxes }) =>
      showCheckboxes ? "0px 42px 0px 42px" : "0px 42px 7px 42px",
    boxSizing: "border-box",
    marginBottom: ({ showCheckboxes }) => (showCheckboxes ? "12px" : "0"),
    maxHeight: ({ showCheckboxes }) => (showCheckboxes ? "178px" : "164px"),
    overflow: "auto",
  },

  titleWrapper: {
    display: "inline-flex",
    gap: "11px",
    alignItems: "center",
  },

  dialogActions: {
    display: "flex",
    flexDirection: "column",
    gap: "13px",
    padding: "0 42px 31px",
    "&>:not(:first-of-type)": {
      marginLeft: 0,
    },
  },

  actionButton: {
    width: "100%",
    height: "34px",
  },

  customerItemWrapper: {
    maxWidth: "300px",
    overflow: "hidden",
    padding: "9px 0",
    "&:not(:last-of-type)": {
      borderBottom: "1px solid #d5d9d9",
    },
  },

  customerName: {
    display: "inline-flex",
    gap: "6px",
    alignItems: "center",

    "& svg": {
      width: "13px",
      height: "10.5px",
    },
  },

  customerTitle: { maxWidth: "285px", fontSize: "14px", color: "#000000" },
}));

export default useStyles;
