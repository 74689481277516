import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { setBankAccountService } from "../../../../../../../../../services/stripe";
import { error } from "../../../../../../../../../utils/notifications";
import { defaultValues } from "./ConnectBankDialog.constants";
import { validationSchema } from "./ConnectBankDialog.validations";

export const useBank = ({
  setLoading,
  open,
  handleAddAccount,
  handleClose,
  bankAccount,
  isEdit,
  isUSCountry = true,
}) => {
  const { control, handleSubmit, reset, setError } = useForm({
    mode: "onChange",
    defaultValues: {
      ...defaultValues,
      bankLocation: isUSCountry ? "US" : "CA",
    },
    resolver: yupResolver(validationSchema(isUSCountry)),
  });

  const [errorAccountNumber, setErrorAccountNumber] = useState("");
  const [errorRoutingNumber, setErrorRoutingNumber] = useState("");

  const [disableFormButton, setDisableFormButton] = useState(false);
  const formField = useWatch({ control });

  const onSubmit = useCallback(
    (data) => {
      const uploadData = {
        ...data,
        routingNumber: data.routingNumber.toString(),
      };
      setLoading(true);
      setBankAccountService(uploadData)
        .then((res) => {
          handleAddAccount(res);
          handleClose();
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);

          if (err?.response?.data?.message === "Wrong account number") {
            setErrorAccountNumber("Wrong account number");
            error("Wrong account number");
            return;
          }

          if (err?.response?.data?.message === "Wrong routing number") {
            setErrorRoutingNumber("Wrong routing number");
            error("Wrong routing number");
            return;
          }
          error(err?.response?.data?.message || err?.message);
        });
    },
    [handleAddAccount, handleClose, setLoading]
  );

  useEffect(() => {
    if (open && isEdit) {
      const {
        country: bankLocation,
        routingNumber,
        accountHolderName,
      } = bankAccount;
      reset({
        bankLocation,
        accountNumber: "",
        routingNumber,
        accountHolderName,
      });
    }
  }, [reset, open, isEdit, bankAccount]);

  const checkFieldsValidation = useCallback(async () => {
    if (!open) return;
    if (errorAccountNumber) {
      setError("currentPassword", {
        type: "errorAccountNumber",
        message: "Wrong account number",
      });
      setDisableFormButton(true);
      return;
    }

    if (errorRoutingNumber) {
      setError("currentPassword", {
        type: "errorRoutingNumber",
        message: "Wrong routing number",
      });
      setDisableFormButton(true);
      return;
    }

    try {
      await validationSchema(isUSCountry).validate(formField, {
        abortEarly: false,
      });
      setDisableFormButton(false);
    } catch ({ errors }) {
      setDisableFormButton(!!errors.length);
      return !!errors.length;
    }
  }, [
    open,
    errorAccountNumber,
    errorRoutingNumber,
    setError,
    isUSCountry,
    formField,
  ]);

  useEffect(checkFieldsValidation, [checkFieldsValidation]);

  return {
    onSubmit,
    handleSubmit,
    control,
    disableFormButton,
    errorAccountNumber,
    setErrorAccountNumber,
    errorRoutingNumber,
    setErrorRoutingNumber,
  };
};
