import axios from "axios";

export const getManufacturersWithDiscountsService = async (params) => {
  return await axios
    .get("/manufacturers/with-discounts", { params })
    .then((res) => res.data);
};

export const getManufacturersWithDiscountsByIdService = async (id, params) => {
  return await axios
    .get(`/manufacturers/${id}/with-discounts`, { params })
    .then((res) => res.data);
};

export const getManufacturerByIdWithDiscountsService = async (id, params) => {
  return await axios
    .get(`/manufacturers/${id}/with-discounts`, { params })
    .then((res) => res.data);
};

export const bulkActiveInactiveDicountsService = async (
  discountIds,
  params
) => {
  return await axios
    .put("/manufacturer-discounts/bulk", { discountIds }, { params })
    .then((res) => res.data);
};

export const bulkDeleteDicountsService = async (discountIds) => {
  return await axios
    .delete("/manufacturer-discounts/bulk", { data: { discountIds } })
    .then((res) => res.data);
};
