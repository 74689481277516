import { useMemo } from "react";
import { object, array, number } from "prop-types";

import { Typography, Box } from "@mui/material";
import { ShevronIcon } from "../../../components/Icons";

const SummaryItem = ({
  item,
  checkedDiscounts,
  groupsList,
  discountCounts,
}) => {
  const isDiscountSelected = useMemo(() => {
    return !!checkedDiscounts.filter((discount) => {
      return discount?.id
        ? discount?.manufacturer?.id === item?.id
        : item?.id === groupsList[0]?.id;
    }).length;
  }, [checkedDiscounts, groupsList, item?.id]);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "85%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "22px",
          }}
        >
          <ShevronIcon className="my-ico" />
        </Box>
        <Typography
          sx={{
            fontSize: "22px",
            fontWeight: 600,
            color: "#5F6267",
            ml: "32px",
          }}
          noWrap
        >
          {item.name}
        </Typography>
        {isDiscountSelected && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minWidth: "fit-content",
            }}
          >
            <Box
              sx={{
                ml: 3,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "4px",
                minWidth: "24px",
                height: "24px",
                backgroundColor: "rgba(71, 160, 110, 0.2)",
              }}
            >
              <Typography fontSize="14px" fontWeight={600} color="primary">
                {
                  checkedDiscounts.filter(
                    (el) =>
                      (el?.manufacturer?.name || "Uncategorized") === item.name
                  ).length
                }
              </Typography>
            </Box>
            <Typography
              fontSize="14px"
              fontWeight={600}
              letterSpacing={0.2}
              color="primary"
              ml={1}
            >
              DISCOUNT
              {checkedDiscounts.filter(
                (el) =>
                  (el?.manufacturer?.name || "Uncategorized") === item.name
              ).length >= 2 && "S"}{" "}
              SELECTED
            </Typography>
          </Box>
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          width: "15%",
        }}
      >
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 400,
            color: "#000000",
          }}
        >
          {discountCounts} discounts
        </Typography>
      </Box>
    </Box>
  );
};

SummaryItem.propTypes = {
  item: object,
  checkedDiscounts: array,
  groupsList: array,
  discountCounts: number,
};
SummaryItem.defaultProps = {
  item: {},
  checkedDiscounts: [],
  groupsList: [],
  discountCounts: 0,
};

export default SummaryItem;
