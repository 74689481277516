import { styled } from "@mui/material";

const CheckLabelStyled = styled("div")(({ theme: { palette } }) => ({
  position: "absolute",
  zIndex: 1000,
  bottom: "28px",
  right: "23px",
  background: palette.common.white,
  borderRadius: "4px",
  height: "27px",
  display: "flex",
  alignItems: "center",
  padding: "0 6px",
  filter: "drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.56))",
  color: palette.edit.main,
}));

export { CheckLabelStyled };
