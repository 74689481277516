export const TAB_HEADER_ITEMS = [
  { name: "Transaction date", size: 2.4, styles: { pl: 3.5 } },
  { name: "Order #", size: 1.8, styles: { pl: 0 } },
  { name: "Customer", size: 2, styles: { pl: 0 } },
  { name: "Gross total", size: 1.6, styles: { pl: 0 } },
  { name: "Fees", size: 1.6, styles: { pl: 0 } },
  { name: "Net total", size: 1.4, styles: { pl: 0 } },
  { name: "Type", size: 1.2, styles: { pl: 0 } },
];
``;

export const TAB_EXPAND_HEADER_ITEMS = [
  { name: "Transaction date", size: 4.2, styles: { pl: 3.5 } },
  { name: "Invoice #", size: 1.4, styles: { pl: 0 } },
  { name: "Gross total", size: 1.4, styles: { pl: 0 } },
  { name: "Fees", size: 1.3, styles: { pl: 0 } },
  { name: "Net total", size: 2, styles: { pl: 0 } },
];
``;

