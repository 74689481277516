import {
  HighestOrderIcon,
  LowestOrderIcon,
  SortAZIcon,
  SortZAIcon,
} from "../../../../../../../../components/Icons";
import { HourglassBottom, HourglassTop } from "@mui/icons-material";

export const defaultFilters = {
  representative_id: "",
  territory_id: "",
  group_id: "",
  tag_id: "",
  sort_by: "",
  last_visit: "",
};

export const ISSUES_FIELDS = {
  "Missing info": "with_missing_info",
  Duplicates: "with_duplicated_info",
  "Expired license": "with_expired_document",
};

export const FILTER_SWITCHES = [
  {
    value: "representative_id",
    label: "Representative",
    type: "select",
    checked: false,
    selectLabel: "Select Rep",
  },
  {
    value: "last_visit",
    label: "Last visit",
    type: "date",
    checked: false,
    selectLabel: "Select",
  },
  {
    value: "territory_id",
    label: "Territory",
    type: "select",
    checked: false,
    selectLabel: "Select Territory",
  },
  {
    value: "group_id",
    label: "Group",
    type: "select",
    checked: false,
    selectLabel: "Select Group",
  },
  {
    value: "tag_id",
    label: "Tag",
    type: "select",
    checked: false,
    selectLabel: "Select Tag",
  },
  {
    value: "sort_by",
    label: "Sort by",
    type: "select",
    checked: false,
    selectLabel: "Sort by",
  },
];

export const getSortList = (isGroups) => {
  return [
    {
      iconId: "highestOrderIcon",
      name: "Highest Order Volumes",
      id: "sort_orders desc",
      sort: "sort_orders",
    },
    {
      iconId: "lovestOrderIcon",
      name: "Lowest Order Volumes",
      id: "sort_orders asc",
      sort: "sort_orders",
    },
    {
      iconId: "sortAZIcon",
      name: `${isGroups ? "Group" : "Customer"} Name A-Z`,
      id: "sort_name asc",
      sort: "sort_name",
    },
    {
      iconId: "sortZAIcon",
      name: `${isGroups ? "Group" : "Customer"} Name Z-A`,
      id: "sort_name desc",
      sort: "sort_name",
    },
    !isGroups && {
      iconId: "hourGlassTop",
      name: "Newest to Oldest",
      id: "sort_newest desc",
      sort: "sort_newest",
    },
    !isGroups && {
      iconId: "hourGlassBottom",
      name: "Oldest to Newest",
      id: "sort_newest asc",
      sort: "sort_newest",
    },
  ];
};
export const iconSort = {
  highestOrderIcon: <HighestOrderIcon />,
  lovestOrderIcon: <LowestOrderIcon />,
  sortAZIcon: <SortAZIcon />,
  sortZAIcon: <SortZAIcon />,
  hourGlassTop: <HourglassTop style={{ fill: "#3F3F3F", fontSize: "16px" }} />,
  hourGlassBottom: (
    <HourglassBottom style={{ fill: "#3F3F3F", fontSize: "16px" }} />
  ),
};

export const FILTER_DATE_VALUES = [
  { name: "Today", id: "today" },
  { name: "Yesterday", id: "yesterday" },
  { name: "This week", id: "this_week" },
  { name: "Last week", id: "last_week" },
  { name: "This month", id: "this_month" },
  { name: "Last month", id: "last_month" },
];

export const FILTER_DATE_VALUES_TASKS = [
  { name: "Today", id: "today" },
  { name: "Tomorrow", id: "tomorrow" },
  { name: "This week", id: "this_week" },
  { name: "Next week", id: "next_week" },
];

export const PREFIXES = { more_than: ">", less_than: "<" };
