import { Box } from "@mui/material";
import { ScrollControlWrapper } from "components";
import React from "react";
import { SelectedCustomerItem } from "./SelectedCustomerItem";
import { array, func } from "prop-types";

export const SelectedCustomerWrapper = ({
  selectedCustomers,
  deleteCustomer,
}) => {
  return (
    <Box maxHeight="186px" mt="5px" mb="20px">
      <ScrollControlWrapper
        maxHeight="186px"
        dataLength={selectedCustomers.length}
        id="selected-customers"
      >
        {selectedCustomers.map((customer) => (
          <SelectedCustomerItem
            customer={customer}
            key={customer.id}
            deleteCustomer={deleteCustomer}
          />
        ))}
      </ScrollControlWrapper>
    </Box>
  );
};

SelectedCustomerWrapper.propTypes = {
  selectedCustomers: array,
  deleteCustomer: func,
};

SelectedCustomerWrapper.defaultProps = {
  selectedCustomers: [],
  deleteCustomer: () => {},
};
