import { string, arrayOf, shape, bool, func } from "prop-types";
import { Box, Typography } from "@mui/material";
import { StorefrontAccessItem } from "./components";

export const StorefrontAccess = ({ title, options }) => {
  return (
    <Box
      sx={{
        border: "0.5px solid #D5D9D9",
        borderRadius: "8px",
      }}
    >
      {/* HEADER */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          px: "21px",
          height: "54px",
          bgcolor: "#F8F8F8",
          borderBottom: "0.5px solid #D5D9D9",
          borderRadius: "8px 8px 0 0",
        }}
      >
        <Typography fontSize={16} fontWeight={500} color="#000">
          {title}
        </Typography>
      </Box>

      {/* LIST */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          borderRadius: "0 0 8px 8px",
          py: "4px",
          px: "23px",
          "& > *:not(:last-child)": {
            borderBottom: "0.5px solid #D5D9D9",
          },
        }}
      >
        {options.map(
          ({
            title = "",
            subtitle = "",
            disabled = false,
            value = false,
            handle = () => {},
            tooltip = {},
          }) => (
            <StorefrontAccessItem
              key={title}
              {...{ title, subtitle, disabled, value, handle, tooltip }}
            />
          )
        )}
      </Box>
    </Box>
  );
};

StorefrontAccess.propTypes = {
  title: string,
  options: arrayOf(
    shape({
      title: string,
      subtitle: string,
      disabled: bool,
      value: bool,
      handle: func,
    })
  ),
};
StorefrontAccess.defaultProps = {
  title: "",
  options: [],
};
