import { string, func } from "prop-types";
import { Box, Typography } from "@mui/material";
import { ThirdPartyItemIcon } from "components/Icons/ThirdPartyItemIcon";

export const ThirdPartyItem = ({ handleClickCustomerNotes, size }) => {
  return (
    <Box
      sx={{
        border: "1px solid #D5D9D9",
        borderRadius: "4px",
        width: size === "small" ? "40px" : "57px",
        maxWidth: size === "small" ? "40px" : "57px",
        minWidth: size === "small" ? "40px" : "57px",
        height: size === "small" ? "40px" : "57px",
        maxHeight: size === "small" ? "40px" : "57px",
        minHeight: size === "small" ? "40px" : "57px",
        display: "flex !important",
        flexDirection: "column",
        justifyContent: "space-between",
        cursor: "pointer",
        backgroundColor: "#F6F6F6",
        position: "relative",
      }}
      onClick={handleClickCustomerNotes}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-end",
          flexGrow: 1,
          mt: size === "small" && "-2px",
          pb: size !== "small" && "6px",
          position: "relative",
        }}
      >
        <ThirdPartyItemIcon
          width={size === "small" ? 18 : 28}
          height={size === "small" ? 22 : 30.755}
        />
        <Typography
          sx={{
            position: "absolute",
            top: size === "small" ? "40%" : "30%",
            fontSize: size === "small" ? "6px" : "7px",
            width: "18px",
            textAlign: "center",
          }}
        >
          3rd
        </Typography>
        <Typography
          sx={{
            position: "absolute",
            top: size === "small" ? "60%" : "50%",
            fontSize: size === "small" ? "6px" : "7px",
            width: "18px",
            textAlign: "center",
          }}
        >
          Party
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "18px",
          borderRadius: "0 0 4px 4px",
        }}
      >
        <Typography
          sx={{
            fontSize: 8,
            fontWeight: 600,
            color: "#5F6267",
          }}
        >
          Order
        </Typography>
      </Box>
    </Box>
  );
};

ThirdPartyItem.defaultProps = {
  handleClickCustomerNotes: () => {},
  size: "big",
};

ThirdPartyItem.propTypes = {
  handleClickCustomerNotes: func,
  size: string,
};
