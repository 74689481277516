import { useMemo, useState, useEffect, useRef } from "react";
import { shape, string } from "prop-types";
import moment from "moment";
import { useSelector } from "react-redux";
import { Avatar, Box, Grid, IconButton, Typography } from "@mui/material";
import {
  formatDate,
  getFormattedDate,
  photoUrl,
  stringAvatar,
} from "../../../helpers/helpers";
import { ROLES } from "../../../utils/constants";
import { PaperIcon } from "../../Icons";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

export const NoteItemList = ({ item, noteId }) => {
  const textRef = useRef();
  const currentUser = useSelector(({ auth }) => auth.currentUser);
  const { timeZone, profilePhoto } = currentUser || {};

  const [isShowText, setShowText] = useState(false);
  const [isEllipsis, setEllipsis] = useState(null);

  const repsName = item?.representativeDuplicate?.name;

  const text = item?.text ?? "";

  const role = useMemo(() => {
    if (!item || !item?.representativeDuplicate?.role) return;
    return ROLES.find(
      (i) => Object.keys(i)[0] === item?.representativeDuplicate?.role
    )[item?.representativeDuplicate?.role];
  }, [item]);

  const date = useMemo(() => {
    const formattedDate = formatDate({
      utc_date: item?.updatedAt || item?.createdAt,
    });

    return moment(
      getFormattedDate(item?.updatedAt || item?.createdAt, timeZone)
    ).format(formattedDate);
  }, [item?.updatedAt, item?.createdAt, timeZone]);

  const handleShowText = () => {
    setShowText((prev) => !prev);
  };

  const isEllipsisActive = (e) => {
    if (!e) return;
    return e.offsetWidth < e.scrollWidth;
  };

  useEffect(() => {
    setEllipsis(isEllipsisActive(textRef.current));
  }, []);

  return (
    <Box
      sx={{
        borderBottom: "0.5px solid #D5D9D9",
        "&:last-of-type": {
          borderWidth: 0,
        },
        width: "100%",
        backgroundColor:
          item?.id === noteId ? "rgba(71, 160, 110, 0.1)" : "#FFF",
      }}
    >
      <Grid
        sx={{
          minHeight: "60px",
          pt: "12px",
          width: "100%",
        }}
        container
      >
        <Grid xs={2.4} item>
          <Box
            sx={{
              display: "flex",
              borderRight: "0.5px solid #D5D9D9",
              height: "calc(100% - 10px) !important",
            }}
          >
            <Box width="42%" pl={2}>
              {(
                item?.representativeDuplicate
                  ? item?.representativeDuplicate?.profilePhoto?.fileName
                  : profilePhoto?.fileName
              ) ? (
                <Avatar
                  sx={{ width: "36px", height: "36px" }}
                  src={photoUrl(
                    item?.representativeDuplicate
                      ? item?.representativeDuplicate?.profilePhoto?.fileName
                      : profilePhoto?.fileName
                  )}
                />
              ) : (
                <Avatar
                  {...stringAvatar(
                    item?.representativeDuplicate?.name || "You",
                    {
                      width: "36px",
                      height: "36px",
                      fontSize: "12px",
                    }
                  )}
                />
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "58%",
              }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  fontWeight: 600,
                  color: "#1C1C19",
                }}
                noWrap
              >
                {repsName || "You"}
              </Typography>
              <Typography
                sx={{
                  fontSize: 12,
                  fontWeight: 300,
                  color: "#1C1C19",
                }}
                noWrap
              >
                {role || "Admin"}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid
          sx={{
            pl: 1.9,
            width: "100%",
          }}
          xs={8.9}
          item
        >
          <Typography
            sx={{
              fontSize: 12,
              fontWeight: 300,
              color: "#D1D1D1",
              mt: "-2px",
            }}
            noWrap
          >
            {date}
          </Typography>
          <Box
            display="flex"
            sx={{
              width: "100%",
            }}
          >
            <Box mt="2px">
              <PaperIcon size={12} color="#5F6267" />
            </Box>
            {isShowText ? (
              <Typography
                sx={{
                  width: "100%",
                  fontSize: 12,
                  fontWeight: 400,
                  color: "#3F3F3F",
                  pl: 1,
                  pt: "5px",
                  pb: 1,
                }}
              >
                {text}
              </Typography>
            ) : (
              <Typography
                ref={textRef}
                sx={{
                  width: "100%",
                  fontSize: 12,
                  fontWeight: 400,
                  color: "#3F3F3F",
                  pl: 1,
                  pt: "5px",
                }}
                noWrap
              >
                {text}
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid
          xs={0.7}
          item
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {isEllipsis && (
            <IconButton sx={{ p: "3px" }} onClick={handleShowText}>
              {isShowText ? (
                <KeyboardArrowDown sx={{ fontSize: 34 }} />
              ) : (
                <KeyboardArrowUp sx={{ fontSize: 34, color: "#C4C4C4" }} />
              )}
            </IconButton>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

NoteItemList.propTypes = {
  item: shape({
    id: string,
  }),
  noteId: string,
};
NoteItemList.defaultProps = {
  noteId: "",
};
