// eslint-disable-next-line no-undef
const CUSTOMER_APP = process.env.REACT_APP_CUSTOMER_APP;

export const CUSTOMER_SHOP_LINK_REGISTER = `${CUSTOMER_APP}orderdirect/`;
export const CUSTOMER_SHOP_LINK_LOGIN = `${CUSTOMER_APP}login/`;

export const STORE_LOGO_ASPECT = 250 / 70;

export const removeProtocol = (link) => {
  return link.replace("https://", "");
};
