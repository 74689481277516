import { array, func, bool } from "prop-types";
import { Box, Grid, Typography } from "@mui/material";

import ReportComponent from "./ReportComponent";

const ReportsTab = ({
  reportsList,
  onExport,
  handleFavorite,
  favorites,
  isFavoriteTab,
}) => {
  return (
    <Grid
      container
      sx={{
        mt: 3,
        maxHeight: "calc(100vh - 330px)",
        gap: 4,
        width: "1068px",
      }}
    >
      {reportsList.length ? (
        reportsList.map((report) => (
          <Grid
            key={report?.title}
            xs={12}
            item
            sx={{
              "&:last-of-type": {
                pb: "30px",
              },
            }}
          >
            <ReportComponent
              title={report?.title}
              reportsCount={report?.reportsCount}
              reports={report?.reports}
              config={report?.config}
              onExport={onExport}
              handleFavorite={handleFavorite}
              favorites={favorites}
              isFavoriteTab={isFavoriteTab}
            />
          </Grid>
        ))
      ) : (
        <Box
          height="101px"
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography>No reports found</Typography>
        </Box>
      )}
    </Grid>
  );
};
ReportsTab.propTypes = {
  reportsList: array,
  onExport: func,
  handleFavorite: func,
  favorites: array,
  isFavoriteTab: bool,
};
ReportsTab.defaultProps = {
  reportsList: [],
  handleFavorite: () => {},
  isFavoriteTab: false,
  favorites: [],
};

export default ReportsTab;
