export const cl = {
  mainWrapper: {
    height: "57px",
    borderRadius: "0 0 4px 4px",
    "&:not(:last-of-type)": {
      borderBottom: "1px solid #D5D9D9",
      borderRadius: "0",
    },
    display: "flex",
    alignItems: "center",
    backgroundColor: "#fff",
  },
  // counter: {
  //   width: "2.9%",
  //   minWidth: "2.9%",
  // icoBtn: {
  //   width: "28px",
  //   height: "28px",
  //   fontSize: "15px",
  //   fontWeight: 500,
  //   color: "#D4D4D4",
  //   p: 0.5,
  // },
  // },
  // product: {
  //   width: "12.9%",
  //   minWidth: "12.9%",
  //   maxWidth: "12.9%",
  //   display: "flex",
  //   height: "100%",
  //   icon: { width: "60px", minWidth: "50px" },
  //   styledProductIcoWrapper: {
  //     width: "38px",
  //     backgroundColor: "white",
  //     border: "1px solid #D5D9D9",
  //     borderRadius: "4px",
  //   },
  //   styledProductIco: {
  //     objectFit: "contain",
  //     width: "100%",
  //     height: "100%",
  //     borderRadius: "4px",
  //   },
  //   name: {
  //     width: "90%",
  //     display: "flex",
  //     justifyContent: "center",
  //     alignItems: "flex-start",
  //     flexDirection: "column",
  //   },
  // },
  // sku: { flexGrow: 1 },
  // colorOrSize: { width: "8.1%", minWidth: "8.1%" },
  // available: { width: "9.05%", minWidth: "9.05%" },
  // quantity: { width: "9.1%", minWidth: "9.1%" },
  // cost: {
  //   flexDirection: "column",
  //   justifyContent: "center",
  //   alignItems: "flex-end",
  //   width: "12.45%",
  //   minWidth: "12.45%",
  // },
  // total: { width: "7.4%", minWidth: "7.4%" },
  empty: { width: "0.9%", minWidth: "0.9%" },

  // center: {
  //   display: "flex",
  //   justifyContent: "center",
  //   alignItems: "center",
  //   height: "100%",
  // },
  // right: {
  //   display: "flex",
  //   justifyContent: "flex-end",
  //   alignItems: "center",
  //   height: "100%",
  // },
  // left: {
  //   display: "flex",
  //   justifyContent: "flex-start",
  //   alignItems: "center",
  //   height: "100%",
  // },
  // typography: {
  //   fontSize: 13,
  //   color: "#1C1C19",
  // },
};
