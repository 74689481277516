import { useEffect, useMemo, useState } from "react";
import { array, bool, func } from "prop-types";
import { useForm, useWatch } from "react-hook-form";
import { Dialog, DialogContent } from "@mui/material";

import { repsListSelector } from "redux/selectors/reps";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import momentTimezone from "moment-timezone";
import { currentUserSelector } from "redux/selectors/auth";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  cl,
  optionsShippingCarrierSelect,
} from "./ConfirmDeliveryBulkDialog.styles";
import { validationSchema } from "./ConfirmDeliveryBulkDialog.validations";
import {
  CounterBlock,
  FooterBlock,
  FulfillBlock,
  HeaderBlock,
  // NotificationBlock,
  ShippingCarrierBlock,
} from "./components";
import { Loader } from "components";
import { ROLES, TYPES, useRepsActions } from "helpers/useRepsActions";

const defaultValues = {
  deliveryMethod: "SHIPPING_CARRIER",
  date: "",
  // sendNotification: false,
  trackingId: "",
  shippingCarrier: "",
  delivererId: "Select reps",
};

const selector = createSelector(
  currentUserSelector,
  repsListSelector,
  (currentUser, repsList) => ({
    currentUser,
    repsList,
  })
);

export const ConfirmDeliveryBulkDialog = ({
  open,
  handleClose,
  orders,
  handleConfirmDelivery,
  isLoading,
}) => {
  const { currentUser, repsList } = useSelector(selector);

  const {
    shippingCarriers,
    // sendNotifications,
    defaultDeliveryMethod,
    timeZone,
  } = currentUser || {};

  const [openTooltip, setOpenTooltip] = useState(false);
  const [isDisableBtn, setIsDisableBtn] = useState(true);

  const setDeliveryMethod = useMemo(() => {
    if (defaultDeliveryMethod) {
      return defaultDeliveryMethod;
    }
  }, [defaultDeliveryMethod]);

  const {
    control,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      ...defaultValues,
      deliveryMethod: setDeliveryMethod,
      // sendNotification: sendNotifications,
    },
    resolver: yupResolver(validationSchema()),
  });

  const formField = useWatch({ control });
  const {
    date,
    deliveryMethod,
    delivererId,
    trackingId,
    shippingCarrier,
    // sendNotification,
  } = formField || {};

  const {
    list: repsSalesUsers,
    count: repsSalesCount,
    handleFetchReps,
    page: repsPage,
  } = useRepsActions({
    type: TYPES.reps,
    params: {
      exclude_roles: JSON.stringify([ROLES.third_party]),
      active: true,
    },
    open,
  });

  useEffect(() => {
    if (isLoading) return setIsDisableBtn(true);
    if (deliveryMethod === "LOCAL_DELIVERY") {
      if (!!delivererId && delivererId !== "Select reps") {
        setIsDisableBtn(false);
      } else {
        setIsDisableBtn(true);
      }
    }
    if (deliveryMethod === "SHIPPING_CARRIER") {
      if (!!trackingId && !!shippingCarrier) {
        setIsDisableBtn(false);
      } else {
        setIsDisableBtn(true);
      }
    }
  }, [
    date,
    delivererId,
    deliveryMethod,
    errors,
    shippingCarrier,
    trackingId,
    isLoading,
  ]);

  useEffect(() => {
    if (!open)
      reset({
        ...defaultValues,
        deliveryMethod: setDeliveryMethod,
        // sendNotification: sendNotifications,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleSubmit = () => {
    const preparedData = {
      // sendNotification,
    };

    if (deliveryMethod === "LOCAL_DELIVERY") {
      preparedData.deliveryMethod = deliveryMethod;
      preparedData.delivererId = delivererId;
    }

    if (deliveryMethod === "SHIPPING_CARRIER") {
      preparedData.trackingId = trackingId;
      preparedData.shippingCarrierId = shippingCarrier;
      preparedData.deliveryMethod = deliveryMethod;
    }
    if (date) {
      preparedData.date = momentTimezone
        .tz(date, timeZone)
        .hours(0)
        .minutes(0)
        .seconds(0)
        .utc()
        .format();
    } else {
      preparedData.date = preparedData.date = momentTimezone
        .tz(new Date(), timeZone)
        .hours(0)
        .minutes(0)
        .seconds(0)
        .utc()
        .format();
    }

    handleConfirmDelivery(preparedData);
  };

  return (
    <>
      <Loader isLoading={isLoading} />
      <Dialog open={open} onClose={handleClose} sx={cl.dialog}>
        <HeaderBlock {...{ cl, handleClose }} />

        <DialogContent sx={cl.blockDialog}>
          <CounterBlock count={orders?.length} />

          <FulfillBlock
            {...{
              cl,
              repsList,
              openTooltip,
              setOpenTooltip,
              control,
              deliveryMethod,
              repsSalesUsers,
              repsSalesCount,
            }}
            handleFetchReps={() => handleFetchReps(repsPage + 1)}
          />

          {deliveryMethod === "SHIPPING_CARRIER" && (
            <ShippingCarrierBlock
              {...{
                cl,
                control,
                optionsShippingCarrierSelect,
                shippingCarriers,
              }}
            />
          )}

          {/* <NotificationBlock {...{ control }} /> */}
        </DialogContent>

        <FooterBlock
          {...{
            cl,
            handleClose,
            isDisableBtn,
          }}
          onSubmit={handleSubmit}
        />
      </Dialog>
    </>
  );
};

ConfirmDeliveryBulkDialog.propTypes = {
  open: bool,
  handleClose: func,
  orders: array,
  handleConfirmDelivery: func,
  isLoading: bool,
};
ConfirmDeliveryBulkDialog.defaultProps = {
  open: false,
  handleClose: () => {},
  orders: [],
  handleConfirmDelivery: () => {},
  isLoading: false,
};
