import { useMemo } from "react";
import { object, bool, func, string } from "prop-types";

import { Box, Grid, IconButton, Paper, Typography } from "@mui/material";

import { LargeCheckbox } from "../../../../../../components/Checkboxes";
import { EyeIcon } from "../../../../../../components/Icons";

import { getFormattedDate } from "../../../../../../helpers/helpers";
import { getValueLabel } from "../MSDPage/MSDPage.helpers";
import { StyledTooltip } from "components";

const MSDItem = ({
  discount,
  handleCheckDiscount,
  isSelected,
  disabled,
  setProductsList,
  setAnchorElMenu,
  setManufacturerName,
  timeZone,
  MOQDisabled,
  MPADisabled,
}) => {
  const classes = {
    grid: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    typography: {
      fontSize: "12px",
      color: "#1C1C19",
    },
  };

  const exceededLimit = useMemo(
    () => discount.limit && discount.limit <= discount._count?.duplicates,
    [discount]
  );

  const setMinimumRequirements = (el) => {
    switch (el?.requirements) {
      case "MIN_PURCHASE_AMOUNT":
        return `Minimum purchase $${el?.minPurchaseAmount.toFixed(2)}`;

      case "MIN_QUANTITY":
        return `Minimum quantity ${el?.minQuantity}`;

      default:
        return "No minimum";
    }
  };

  const setDiscountValue = (type) => {
    switch (type) {
      case "ABSOLUTE":
        return `$${discount?.absoluteDiscount.toFixed(2)}`;
      case "PERCENTAGE":
        return `${discount?.percentDiscount}%`;
      default:
        return discount?.quantityDiscount;
    }
  };

  const getTooltipTitle = () => {
    if (exceededLimit)
      return `This discount is unavailable, it has been used ${discount.limit} of ${discount.limit} times`;

    if (MPADisabled)
      return `This discount is unavailable. Minimum purchase amount - ${discount.minPurchaseAmount}$`;

    if (MOQDisabled)
      return `This discount is unavailable. Pick at least ${discount.minQuantity} cases to proceed`;

    return "";
  };

  return (
    <Paper
      sx={{
        borderRadius: "0 0 4px 4px",
        border: "none",
        alignItems: "center",
        pl: "16px",
        opacity: (disabled || exceededLimit) && 0.5,
        "&:not(:last-child)": {
          borderBottom: "1px solid #D4D4D4",
          borderRadius: 0,
        },
        "& .MuiTypography-root": {
          fontSize: "12px",
          color: "#1C1C19",
        },
        backgroundColor: isSelected && "rgba(66, 165, 127, 0.1)",
      }}
      elevation={0}
      variant="outlined"
      square
      component={Grid}
      container
      columns={12}
      height="48px"
    >
      <Grid item xs={0.5}>
        <StyledTooltip
          placement="top"
          arrow
          title={getTooltipTitle()}
          PopperProps={{
            modifiers: [{ name: "offset", options: { offset: [0, -14] } }],
          }}
        >
          <Box width="22px">
            <LargeCheckbox
              onChange={() => handleCheckDiscount(discount)}
              checked={isSelected}
              disabled={disabled || exceededLimit}
            />
          </Box>
        </StyledTooltip>
      </Grid>

      <Grid item xs={2} sx={classes.grid}>
        <Typography>{discount.name}</Typography>
      </Grid>
      <Grid item xs={2} sx={classes.grid}>
        <Typography sx={classes.typography} fontWeight="500">
          {setDiscountValue(discount.type)}
        </Typography>
        <Typography sx={classes.typography} ml="4px">
          {getValueLabel(discount)}
        </Typography>
        {discount.productType !== "ALL_PRODUCTS" &&
        discount?.products.length ? (
          <IconButton
            sx={{ pl: "6px" }}
            onClick={(e) => {
              setProductsList(discount?.products);
              setAnchorElMenu(e.currentTarget);
              setManufacturerName(discount?.manufacturer?.name);
            }}
          >
            <EyeIcon fill="#1C1C19" />
          </IconButton>
        ) : (
          ""
        )}
      </Grid>
      <Grid item xs={2.6} sx={classes.grid}>
        <Typography sx={classes.typography}>
          {setMinimumRequirements(discount)}
        </Typography>
      </Grid>
      <Grid item xs={2} sx={classes.grid}>
        <Typography sx={classes.typography}>
          {discount.endDate
            ? getFormattedDate(discount.endDate, timeZone).split(",")[0]
            : "Unlimited"}
        </Typography>
      </Grid>
      <Grid item xs={1.5} sx={classes.grid}>
        <Typography sx={classes.typography}>
          {discount.isLimited ? discount.limit : "Unlimited"}
        </Typography>
      </Grid>
      <Grid item xs={1} sx={classes.grid}>
        <Typography sx={classes.typography}>
          {discount._count.duplicates ? discount._count.duplicates : 0} times
        </Typography>
      </Grid>
    </Paper>
  );
};

MSDItem.propTypes = {
  discount: object,
  handleCheckDiscount: func,
  isSelected: bool,
  disabled: bool,
  setProductsList: func,
  setAnchorElMenu: func,
  setManufacturerName: func,
  timeZone: string,
  MOQDisabled: bool,
  MPADisabled: bool,
};

export default MSDItem;
