import * as React from "react";

export const SimpleArrowDownIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={5.69}
    height={3.253}
    viewBox="0 0 5.69 3.253"
    {...props}
  >
    <path
      data-name="Icon ionic-ios-arrow-down"
      d="M2.846 2.273 4.998.12a.4.4 0 0 1 .574 0 .41.41 0 0 1 0 .576l-2.438 2.44a.406.406 0 0 1-.561.012L.118.698A.407.407 0 0 1 .692.122Z"
    />
  </svg>
);
