import { ISSUES_FIELDS } from "./components/TabsContent/components/NewCustomersFilter/NewCustomerFilter.constants";

export const findItemAndSet = (arr, item) => {
  if (!Array.isArray(arr) && !item) return [];

  let store = [...arr];

  const indexItem = store.findIndex(({ id }) => id === item.id);

  if (indexItem === -1) store = [...store, item];

  if (indexItem > -1) store.splice(indexItem, 1);

  return store;
};

export const organizeIssuesFilter = (selectedValues) => {
  if (!selectedValues?.length) return {};

  const filterObject = {};

  selectedValues.forEach((filter) => {
    filterObject[ISSUES_FIELDS[filter]] = true;
  });

  return filterObject;
};

export const missingInfoRedirect = ({
  id,
  navigate,
  stateProps = {},
  missingInfo,
}) => {
  if (!missingInfo)
    return navigate(`/customers/edit/${id}`, { state: stateProps });

  const state = { checkMissingInfo: true, ...stateProps };

  return navigate(`/customers/edit/${id}`, { state });
};
