import React from "react";

export const CatalogIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="17"
      viewBox="0 0 22 17"
      {...props}
    >
      <path
        id="Path_3010"
        data-name="Path 3010"
        d="M38,6a6.277,6.277,0,0,1,5-2,6.277,6.277,0,0,1,5,2V20s-1.5-1-5-1-5,1-5,1ZM48,6a6.277,6.277,0,0,1,5-2,6.277,6.277,0,0,1,5,2V20s-1.5-1-5-1-5,1-5,1Z"
        transform="translate(-37.25 -3.25)"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};
