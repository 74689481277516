import { bool, func, array, object, string } from "prop-types";

import { EditFilterContent } from "./components/FilterViewContent";

import { cl } from "./styles";

import { Dialog } from "@mui/material";
import { useEditSavedFilter } from "./useEditSavedFilter";

export const EditSavedFilterDialog = ({
  open,
  type,
  onClose,
  filterForEdit,
  onFilterApply,
  selectedFilterId,
  switches,
  selectMatchData,
  onDelete,
}) => {
  const { onChange, viewName, onSave, isNameChanged } = useEditSavedFilter({
    filterForEdit,
    open,
    type,
    onClose,
    onFilterApply,
    selectedFilterId,
  });

  return (
    <Dialog open={open} sx={cl.dialogWrapper} onClose={onClose}>
      <EditFilterContent
        {...{
          viewName,
          onChange,
          selectMatchData,
          filterForEdit,
          switches,
          onClose,
          onSave,
          isNameChanged,
          onDelete,
        }}
      />
    </Dialog>
  );
};

EditSavedFilterDialog.propTypes = {
  open: bool,
  onClose: func,
  onDelete: func,
  onFilterApply: func,
  filterForEdit: object,
  selectMatchData: array,
  type: string,
  switches: array,
  selectedFilterId: string,
};
EditSavedFilterDialog.defaultProps = {
  open: false,
  onDelete: () => {},
  onClose: () => {},
  onFilterApply: () => {},
  switches: [],
  type: "",
  selectedFilterId: "",
  selectMatchData: [],
  filterForEdit: {},
};
