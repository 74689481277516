/* eslint-disable no-useless-escape */
import { checkAndPrepareText } from "helpers/helpers";
import * as Yup from "yup";

const containsSpecialChars = (str) => {
  const regex = /[:\t\n]/;

  return regex.test(str);
};

export const validationSchema = ({ isColor, isSize }) =>
  Yup.object().shape({
    name: Yup.string()
      .trim()
      .nullable()
      .required("This field is required")
      .test("character", "Invalid character used", (value, ctx) => {
        const containsSpecial = checkAndPrepareText(value)
          .map((e) => e)
          .join(", ");

        if (containsSpecial?.length)
          return ctx.createError({
            type: "character",
            message: containsSpecial,
          });

        return !containsSpecial.length;
      }),
    isMultiple: Yup.bool(),
    sku: Yup.string()
      .trim()
      .when("isMultiple", {
        is: false,
        then: Yup.string()
          .required("This field is required")
          .test("sku", "Invalid character used", (value, ctx) => {
            if (value === "") return true;

            const containsSpecial = checkAndPrepareText(value)
              .map((e) => e)
              .join(", ");

            if (containsSpecial?.length)
              return ctx.createError({
                type: "character",
                message: containsSpecial,
              });

            return !containsSpecial.length;
          }),
      }),
    wholesalePrice: Yup.string().when("isMultiple", {
      is: false,
      then: Yup.string()
        .required()
        // .transform((v, o) => (o === "" ? null : v))
        // .nullable(true)
        // .required()
        // .typeError("Must be a number.")
        .test(
          ("itemsPerCase",
          "Must be 1 or more.",
          (value) => {
            const REGEXP = /\d/;
            return REGEXP.test(+value) && +value > 0;
          })
        ),
    }),
    minOrderQTY: Yup.number()
      .transform((v, o) => (o === "" ? null : v))
      .nullable(true)
      .required("This field is required")
      .typeError("Must be a number.")
      .test("minOrderQTY", "Must be 1 or more.", (value) => {
        const FED_REGEXP = /\d/;
        return FED_REGEXP.test(value) && value > 0;
      }),
    barcode: Yup.string()
      .nullable()
      .typeError("Must be a number.")
      .test("barcode", "Max 14 symbols allowed!", (value) => {
        if (value === "" || value === null) return true;
        const FED_REGEXP = /^\d+$/;
        return FED_REGEXP.test(value) && value?.length <= 14;
      }),
    itemsPerCase: Yup.number()
      .transform((v, o) => (o === "" ? null : v))
      .nullable(true)
      .when("isMultiple", {
        is: false,
        then: Yup.number()
          .required("This field is required")
          .typeError("Must be a number.")
          .test("itemsPerCase", "This field is required", (value) => {
            const REGEXP = /\d/;
            return REGEXP.test(value) && value > 0;
          }),
      }),

    skuVariationFields: Yup.array().of(
      Yup.object().shape({
        sku: Yup.string().trim(),
      })
    ),
    colorVariationFields:
      isColor &&
      Yup.array().of(
        Yup.object().shape({
          color: Yup.string().trim(),
        })
      ),
    sizeVariationFields:
      isSize &&
      Yup.array().of(
        Yup.object().shape({
          size: Yup.string().trim(),
        })
      ),
    variationsFields: Yup.array()
      .when("isMultiple", {
        is: true,
        then: Yup.array().test(
          "variationsFieldsValidation",
          "Add at least 1 product variation.",
          (value) => {
            return value.length > 0;
          }
        ),
      })
      .of(
        Yup.object().shape({
          sku: Yup.string()
            .required("This field is required")
            .test("sku", "Invalid character used", (value, ctx) => {
              if (value === "") return true;

              const variationsFields =
                ctx?.from?.[1]?.value?.variationsFields || [];
              const currentVariation = ctx?.from?.[0]?.value || {};

              const preparedValue = `${currentVariation?.sku ?? ""}-${
                currentVariation?.color ?? ""
              }-${currentVariation?.size ?? ""}`;

              const preparedValuesArr = variationsFields.map(
                (item) =>
                  `${item?.sku ?? ""}-${item?.color ?? ""}-${item?.size ?? ""}`
              );

              const isUnique =
                preparedValuesArr.filter((item) => {
                  return item?.toLowerCase() === preparedValue?.toLowerCase();
                })?.length === 1;

              if (!isUnique)
                return ctx.createError({
                  type: "sku-duplicate",
                  message: "This sku already exist",
                });

              const containsSpecial = containsSpecialChars(value);

              if (containsSpecial) {
                return ctx.createError({
                  type: "character",
                  message: value,
                });
              }

              return !containsSpecial;
            }),
          color:
            isColor &&
            Yup.string()
              .required("This field is required")
              .test("color", "Invalid character used", (value, ctx) => {
                if (value === "") return true;

                const containsSpecial = containsSpecialChars(value);

                if (containsSpecial) {
                  return ctx.createError({
                    type: "character",
                    message: value,
                  });
                }

                return !containsSpecial;
              }),
          size:
            isSize &&
            Yup.string()
              .required("This field is required")
              .test("size", "Invalid character used", (value, ctx) => {
                if (value === "") return true;
                const containsSpecial = containsSpecialChars(value);

                if (containsSpecial) {
                  return ctx.createError({
                    type: "character",
                    message: value,
                  });
                }

                return !containsSpecial;
              }),
          barcode: Yup.string()
            .nullable()
            .typeError("Must be a number.")
            .test("barcode", "Max 14 symbols allowed!", (value) => {
              if (value === "" || value === undefined || value === null)
                return true;

              // const variationsFields =
              //   ctx?.from?.[1]?.value?.variationsFields || {};

              // const barCodes = variationsFields.map((item) => item?.barcode);

              // const isUnique =
              //   barCodes.filter((item) => item === value)?.length === 1;

              // if (!isUnique)
              //   return ctx.createError({
              //     type: "barcode-dublicate",
              //     message:
              //       "This barcode has been already used on the existing product",
              //   });

              const FED_REGEXP = /^\d+$/;
              return FED_REGEXP.test(value) && value?.length <= 14;
            }),
          itemsPerCase: Yup.number()
            .transform((v, o) => (o === "" ? null : v))
            .nullable(true)
            .typeError("Must be a number.")
            .test(
              ("itemsPerCase",
              "Must be 1 or more.",
              (value) => {
                const REGEXP = /\d/;
                return REGEXP.test(value) && value > 0;
              })
            ),
          wholesalePrice: Yup.number()
            // .transform((v, o) => (o === "" ? null : v))
            // .nullable(true)
            .required()
            // .typeError("Must be a number.")
            .test(
              ("itemsPerCase",
              "Must be 1 or more.",
              (value) => {
                const REGEXP = /\d/;
                return REGEXP.test(+value) && +value > 0;
              })
            ),
          retailPrice: Yup.number()
            .transform((v, o) => (o === "" ? null : v))
            .nullable()
            .typeError("Must be a number.")
            .test(
              "retailPrice",
              "Retail price could not be lower than wholesale price",
              (value, { parent: { wholesalePrice } }) => {
                return value >= wholesalePrice;
              }
            ),

          distributorPrice: Yup.number()
            .nullable()
            .typeError("Must be a number."),
        })
      ),
    manufacturerId: Yup.number()
      .required("This field is required")
      .typeError("This field is required")
      .nullable()
      .test("manufacturerId", "This field is required", (value) => {
        // if (
        //   ctx?.parent?.manufacturerIdInactive?.status === "INACTIVE" &&
        //   ctx?.parent?.manufacturerIdInactive?.id === value
        // ) {
        //   return ctx.createError({ message: "This manufacturer is inactive." });
        // }
        const FED_REGEXP = /\d/;
        return FED_REGEXP.test(value);
      }),
    retailPrice: Yup.number()
      .transform((v, o) => (o === "" ? null : v))
      .nullable()
      .typeError("Must be a number.")
      .when("isMultiple", {
        is: false,
        then: Yup.number()
          .transform((v, o) => (o === "" ? null : v))
          .nullable()
          .typeError("Must be a number.")
          .required("This field is required")
          .test(
            "retailPrice",
            "Retail price could not be lower than wholesale price",
            (value, { parent: { wholesalePrice } }) => {
              return value >= wholesalePrice;
            }
          ),
      }),
    distributorPrice: Yup.number().nullable().typeError("Must be a number."),
    msrpPrice: Yup.number().typeError("Must be a number."),
    countryOfOrigin: Yup.string().nullable(),
  });
