export const defaultValues = {
  firstName: "",
  lastName: "",
  dateOfBirth: {
    day: 1,
    month: 1,
    year: "",
  },
  email: "",
  phone: "",
  address: {
    formatted_address: "",
    zip: "",
    city: "",
    state: "",
    street: "",
    appartement: "",
  },
  ssn: "",
  title: "",
};
