import React from "react";

export const CopyIconFilled = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9.432"
      height="11.79"
      viewBox="0 0 9.432 11.79"
    >
      <g
        id="Icon_ionic-ios-copy"
        data-name="Icon ionic-ios-copy"
        transform="translate(-4.5 -1.125)"
      >
        <path
          id="Path_5687"
          data-name="Path 5687"
          d="M23.876,3.561h2.211a.148.148,0,0,0,.147-.147h0a.686.686,0,0,0-.246-.523L24.095,1.314a.919.919,0,0,0-.506-.182h0a.181.181,0,0,0-.182.182V3.095A.47.47,0,0,0,23.876,3.561Z"
          transform="translate(-12.302 -0.005)"
          fill="#8b8b8b"
        />
        <path
          id="Path_5688"
          data-name="Path 5688"
          d="M14.129,3.09V1.125H10.911a.788.788,0,0,0-.786.786v8.253a.788.788,0,0,0,.786.786h5.9a.788.788,0,0,0,.786-.786V4.2H15.234A1.107,1.107,0,0,1,14.129,3.09Z"
          transform="translate(-3.66)"
          fill="#8b8b8b"
        />
        <path
          id="Path_5689"
          data-name="Path 5689"
          d="M5.777,13.78V5.625H5.286a.788.788,0,0,0-.786.786v8.646a.788.788,0,0,0,.786.786h6.288a.788.788,0,0,0,.786-.786v-.491h-5.8A.788.788,0,0,1,5.777,13.78Z"
          transform="translate(0 -2.928)"
          fill="#8b8b8b"
        />
      </g>
    </svg>
  );
};
