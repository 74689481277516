import { object, func } from "prop-types";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";

export const StatusBlock = ({ control, setValue, formField }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingY: "20px",
        paddingLeft: "80px",
        paddingRight: "115px",
        // paddingLeft: mediaXs ? "30px" : "80px",
        // paddingRight: mediaXs ? "20px" : "115px",
      }}
    >
      <Typography
        sx={{
          fontSize: 14,
          // fontSize: mediaXs ? 11 : 14,
          fontWeight: 500,
          color: "#707070",
        }}
      >
        Status
      </Typography>
      <Controller
        render={({ field }) => (
          <FormControl sx={{ marginLeft: 0 }} component="fieldset">
            <RadioGroup
              sx={{
                display: "flex",
                justifyContent: "space-around",
              }}
              row
              defaultValue="Active"
              {...field}
              onChange={(e) => {
                field.onChange(e.target.value);
                const variationsTemp = [...formField?.variationsFields];
                setValue(
                  "variationsFields",
                  variationsTemp.map((v) => ({
                    ...v,
                    status: e.target.value,
                  }))
                );
              }}
            >
              <FormControlLabel
                value="ACTIVE"
                sx={{ marginRight: 0 }}
                label={
                  <Typography
                    sx={{ fontSize: 14, color: "#1C1C19" }}
                    // sx={{ fontSize: mediaXs ? 11 : 14, color: "#1C1C19" }}
                    variant="caption"
                  >
                    Active
                  </Typography>
                }
                control={
                  <Radio
                    sx={{
                      marginLeft: "6px",
                      marginRight: "-4px",
                      "& svg": {
                        width: "18px",
                        height: "18px",
                        // width: mediaXs ? "14px" : "18px",
                        // height: mediaXs ? "14px" : "18px",
                      },
                    }}
                  />
                }
              />
              <FormControlLabel
                sx={{
                  m: 0,
                }}
                value="INACTIVE"
                label={
                  <Typography
                    sx={{ fontSize: 14, color: "#1C1C19" }}
                    // sx={{ fontSize: mediaXs ? 11 : 14, color: "#1C1C19" }}
                    variant="caption"
                  >
                    Inactive
                  </Typography>
                }
                control={
                  <Radio
                    sx={{
                      marginLeft: "6px",
                      marginRight: "-4px",
                      "& svg": {
                        width: "18px",
                        height: "18px",
                        // width: mediaXs ? "14px" : "18px",
                        // height: mediaXs ? "14px" : "18px",
                      },
                    }}
                  />
                }
              />

              <Box component="span">
                <FormControlLabel
                  sx={{
                    m: 0,
                  }}
                  value="INACTIVE_FOR_CUSTOMERS"
                  label={
                    <Typography fontSize={14} color="#1C1C19" variant="caption">
                      Inactive for customers only
                    </Typography>
                  }
                  control={
                    <Radio
                      sx={{
                        marginLeft: "6px",
                        marginRight: "-4px",
                        "& svg": {
                          width: "18px",
                          height: "18px",
                        },
                      }}
                    />
                  }
                />
              </Box>
            </RadioGroup>
          </FormControl>
        )}
        name="status"
        control={control}
      />
    </Box>
  );
};

StatusBlock.propTypes = {
  control: object,
  setValue: func,
  formField: object,
};
