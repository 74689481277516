import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  select: {
    height: "32px",
    fontSize: "12px",
  },
  label: {
    fontSize: "12px",
  },
  icon: {
    fill: "#B5B5AC",
    transform: "scale(0.75)",
  },
}));

export default useStyles;
