export const cl = {
  mainGrid: {
    alignItems: "center",
    height: "48px",
  },
  typography: {
    fontSize: 13,
    fontWeight: 400,
    color: "#1C1C19",
  },
};
