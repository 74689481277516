import { styled, Tooltip, tooltipClasses } from "@mui/material";

export const StyledTooltip = styled(
  ({
    className,
    isDark,
    PopperProps = {
      modifiers: [{ name: "offset", options: { offset: [0, -5] } }],
    },
    ...props
  }) => (
    <Tooltip
      {...props}
      classes={{ popper: className }}
      PopperProps={PopperProps}
    />
  )
)(({ isDark }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: isDark ? "#707070" : "#ffffff",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "17px",
    color: isDark ? "#ffffff" : "#1C1C19",
    border: isDark ? "none" : "0.4px solid #d5d9d9",
    borderRadius: "8px",
    boxShadow: "0px 3px 6px rgba(0, 3, 6, 0.16)",
    padding: "12.5px 13.5px",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: isDark ? "#707070" : "#ffffff",
    height: "12px",
    width: "17px",

    "&:before": {
      border: isDark ? "none" : "0.4px solid #d5d9d9",
      borderRadius: "2px 0 2px 0",
      backgroundColor: isDark ? "#707070" : "#ffffff",
    },
  },
  [`& .MuiTooltip-tooltipPlacementBottom .${tooltipClasses.arrow}`]: {
    marginTop: "-0.98em !important",
    marginBottom: "-0.98em !important",
  },
  [`& .MuiTooltip-tooltipPlacementTop .${tooltipClasses.arrow}`]: {
    marginBottom: "-0.98em !important",
    marginTop: "-0.98em !important",
  },
  [`& .MuiTooltip-tooltipPlacementRight .${tooltipClasses.arrow}`]: {
    "&:before": {
      borderRadius: "0 0 0 2px",
    },
  },
  [`& .MuiTooltip-tooltipPlacementLeft .${tooltipClasses.arrow}`]: {
    "&:before": {
      borderRadius: "0 2px 0 0",
    },
  },
}));

export default StyledTooltip;
