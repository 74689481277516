import React from "react";
import { TableBody, TableHeader } from "./styles";
import { Box, Typography } from "@mui/material";
import { array, func } from "prop-types";
import { ProspectTableItem } from "./components";
import { InfiniteLoaderWrapper } from "components";
import { CUSTOMER_STATUS_FILTERS } from "utils/constants";

const ProspectsTable = ({
  prospects,
  checkedProspects,
  handleCheckProspect,
}) => {
  return (
    <Box height="calc(100% - 49px)">
      <TableHeader>
        <Typography variant="header" fontWeight="600" fontSize="15px">
          Prospects
        </Typography>
      </TableHeader>
      <TableBody>
        {prospects.length ? (
          <InfiniteLoaderWrapper
            itemsList={prospects}
            itemsCount={0}
            offsetHeight={283}
            loading={false}
            handleFetch={() => {}}
            itemHeight={115}
            tableStyle={{ borderWidth: "0px" }}
            bordersWidthProp={0.5}
            listProps={{ width: 458 }}
            renderChildren={(index) => (
              <ProspectTableItem
                prospect={prospects[index]}
                index={index}
                checked={checkedProspects.some(({ id, foursquareId }) =>
                  prospects[index].status === CUSTOMER_STATUS_FILTERS.prospect
                    ? id === prospects[index].id
                    : id === prospects[index].id ||
                      foursquareId === prospects[index].fsq_id
                )}
                handleCheckProspect={handleCheckProspect}
              />
            )}
          />
        ) : (
          <Box
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography fontSize="12px" color="groundLighter">
              Please select an area on the map or search for location
            </Typography>
          </Box>
        )}
      </TableBody>
    </Box>
  );
};

ProspectsTable.propTypes = {
  prospects: array,
  checkedProspects: array,
  handleCheckProspect: func,
};

ProspectsTable.defaultProps = {
  prospects: [],
  checkedProspects: [],
  handleCheckProspect: () => {},
};

export default ProspectsTable;
