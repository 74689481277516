import React from "react";

export const OrderIcon = (props) => {
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.960429 0.448118C0.5 0.896235 0.5 1.61659 0.5 3.05882V9.94118C0.5 11.3834 0.5 12.1038 0.960429 12.5519C1.42086 13 2.161 13 3.64286 13H8.35714C9.839 13 10.5791 13 11.0396 12.5519C11.5 12.1038 11.5 11.3834 11.5 9.94118V3.05882C11.5 1.61659 11.5 0.896235 11.0396 0.448118C10.5791 -2.98023e-08 9.839 0 8.35714 0H3.64286C2.161 0 1.42086 -2.98023e-08 0.960429 0.448118ZM3.64286 3.05882C3.43447 3.05882 3.23462 3.13939 3.08727 3.2828C2.93992 3.42621 2.85714 3.62072 2.85714 3.82353C2.85714 4.02634 2.93992 4.22085 3.08727 4.36426C3.23462 4.50767 3.43447 4.58824 3.64286 4.58824H8.35714C8.56553 4.58824 8.76538 4.50767 8.91273 4.36426C9.06008 4.22085 9.14286 4.02634 9.14286 3.82353C9.14286 3.62072 9.06008 3.42621 8.91273 3.2828C8.76538 3.13939 8.56553 3.05882 8.35714 3.05882H3.64286ZM3.64286 6.11765C3.43447 6.11765 3.23462 6.19821 3.08727 6.34162C2.93992 6.48503 2.85714 6.67954 2.85714 6.88235C2.85714 7.08517 2.93992 7.27967 3.08727 7.42308C3.23462 7.56649 3.43447 7.64706 3.64286 7.64706H8.35714C8.56553 7.64706 8.76538 7.56649 8.91273 7.42308C9.06008 7.27967 9.14286 7.08517 9.14286 6.88235C9.14286 6.67954 9.06008 6.48503 8.91273 6.34162C8.76538 6.19821 8.56553 6.11765 8.35714 6.11765H3.64286ZM3.64286 9.17647C3.43447 9.17647 3.23462 9.25704 3.08727 9.40045C2.93992 9.54386 2.85714 9.73836 2.85714 9.94118C2.85714 10.144 2.93992 10.3385 3.08727 10.4819C3.23462 10.6253 3.43447 10.7059 3.64286 10.7059H6.78571C6.9941 10.7059 7.19395 10.6253 7.3413 10.4819C7.48865 10.3385 7.57143 10.144 7.57143 9.94118C7.57143 9.73836 7.48865 9.54386 7.3413 9.40045C7.19395 9.25704 6.9941 9.17647 6.78571 9.17647H3.64286Z"
        fill="#409A65"
      />
    </svg>
  );
};
