import * as React from "react";

export const ProfileIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={17}
    viewBox="0 0 20 20"
    {...props}
  >
    <circle
      id="Ellipse_862"
      data-name="Ellipse 862"
      cx={9.25}
      cy={9.25}
      r={9.25}
      transform="translate(0.75 0.75)"
      fill="none"
      stroke="#9c9c94"
      strokeWidth={1.5}
    />
    <path
      id="Path_3180"
      data-name="Path 3180"
      d="M37,87.328a8.945,8.945,0,0,0-12,0V88"
      transform="translate(-21 -71)"
      fill="none"
      stroke="#9c9c94"
      strokeLinecap="round"
      strokeWidth={1.5}
    />
    <circle
      id="Ellipse_863"
      data-name="Ellipse 863"
      cx={3.25}
      cy={3.25}
      r={3.25}
      transform="translate(6.75 4.75)"
      fill="none"
      stroke="#9c9c94"
      strokeWidth={1.5}
    />
  </svg>
);
