export const cl = {
  plusIcon: {
    p: 0,
  },
  productPhoto: {
    width: "35px",
    backgroundColor: "white",
    borderRadius: "4px",
  },
  productItem: {
    justifyContent: "space-between",
    alignItems: "center",
    "&:not(:last-child)": {
      borderBottom: "0.5px solid #D5D9D9",
    },
  },
  productItemContent: {
    alignItems: "center",
    gap: "6px",
    padding: "6px 0",
  },
};
