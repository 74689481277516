import { linearProgressClasses } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  accordionsWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    minWidth: "458px",
    width: "458px",
    overflow: "auto",
  },

  accordionItem: { margin: "0!important", width: "100%", maxWidth: "100%" },

  accordionSummary: {
    width: "100%",
    maxWidth: "100%",
    padding: "0 20px 0 19px",
    height: "41px!important",
    minHeight: "41px",
    "&.Mui-expanded": {
      backgroundColor: "#EEEEEE",
      borderBottom: "0.5px solid #D5D9D9",
      [`& .${linearProgressClasses.colorPrimary}:before`]: {
        content: '""',
        right: 0,
        zIndex: 0,
        position: "absolute",
        width: "105px",
        height: "9px",
        border: "0.5px solid #707070",
        borderRadius: "16px",
        opacity: 0.5,
      },
    },
    "& .MuiAccordionSummary-content": {
      justifyContent: "space-between",
      width: "100%",
    },
    "& .MuiTypography-root": {
      fontSize: "12px",
    },
  },

  routeProgress: {
    width: "105px",
    borderRadius: "16px",
    height: "9px",
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: "#DEDEDE",
      zIndex: 2,
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: "16px",
      zIndex: 2,
    },
  },
}));

export default useStyles;
