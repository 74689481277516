import { Box } from "@mui/system";
import { Checkbox, Typography } from "@mui/material";
import { object, bool, func } from "prop-types";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CircleCheckedFilled from "@mui/icons-material/CheckCircle";
import { StyledTooltip } from "components";

const NewMessageRepsItem = ({ user, isChecked, handleCheckUser, disabled }) => {
  const isConfirmed =
    user?.baseUser?.isConfirmed || user?.role === "THIRD_PARTY";

  return (
    <Box
      sx={{
        height: "48px",
        display: "flex",
        opacity: disabled || !isConfirmed ? "0.5" : "1",
        backgroundColor: isChecked && "#F7F7F7",
        alignItems: "center",
        "&:not(:last-of-type)": {
          borderBottom: "0.5px solid #d5d9d9",
        },
      }}
    >
      <StyledTooltip
        title="You are not able to select unverified reps"
        disableHoverListener={isConfirmed}
        arrow
        placement="top"
        PopperProps={{
          modifiers: [{ name: "offset", options: { offset: [0, -15] } }],
        }}
      >
        <Box>
          <Checkbox
            icon={
              <CircleOutlinedIcon style={{ height: "17px", width: "17px" }} />
            }
            checkedIcon={
              <CircleCheckedFilled style={{ height: "17px", width: "17px" }} />
            }
            disabled={disabled || !isConfirmed}
            checked={isConfirmed && isChecked}
            onChange={() => isConfirmed && handleCheckUser(user)}
          />
        </Box>
      </StyledTooltip>

      <Box sx={{ width: "calc(100% - 35px)" }}>
        <Typography fontSize="12px" color="#1C1C19" noWrap>
          {user.name} (ID: {user.customId?.customId})
        </Typography>
        <Typography fontSize="12px" color="#1C1C19" fontWeight="400" noWrap>
          {user.baseUser?.email || user.shippingAddress?.formatted_address}
        </Typography>
      </Box>
    </Box>
  );
};

NewMessageRepsItem.propTypes = {
  user: object,
  isChecked: bool,
  handleCheckUser: func,
  disabled: bool,
};

export default NewMessageRepsItem;
