import PropTypes, { shape, string, bool, array, any, date } from "prop-types";

import { StyledTooltip } from "components";
import { FilledCopyIcon } from "components/Icons";

import { copyTextToClipboard } from "utils/copyTextToClipboard";

import { Typography, Box } from "@mui/material";
import { shippingLinks } from "utils/constants";

const FulfillmentTooltip = ({
  children,
  disableHoverListener,
  offset,
  titleData,
}) => {
  return (
    <StyledTooltip
      arrow
      isDark
      title={
        <>
          {titleData.length
            ? titleData.map(
                (
                  { shippingCarrier, shippingCarrierDuplicate, trackingId },
                  index
                ) => {
                  const shippingName =
                    shippingCarrier?.name || shippingCarrierDuplicate?.name;
                  const getLink = (id) => {
                    const url = shippingLinks[shippingName];
                    return url ? window.open(`${url}${id}`, "_blank") : "";
                  };

                  return (
                    <Box key={index} pl="8px">
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        gap="10px"
                        alignItems="center"
                      >
                        <Typography
                          color="white"
                          fontSize="12px"
                          fontWeight="500"
                        >
                          {shippingCarrier?.name ||
                            shippingCarrierDuplicate?.name}
                        </Typography>

                        <StyledTooltip
                          arrow
                          title="Copy tracking number"
                          placement="top"
                          PopperProps={{
                            modifiers: [
                              { name: "offset", options: { offset: [0, -8] } },
                            ],
                          }}
                        >
                          <Box
                            sx={{
                              padding: "4px 8px",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              copyTextToClipboard(trackingId, "Tracking copied")
                            }
                          >
                            <FilledCopyIcon />
                          </Box>
                        </StyledTooltip>
                      </Box>
                      <Typography
                        color="white"
                        fontSize="12px"
                        fontWeight="500"
                        sx={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() => getLink(trackingId)}
                      >
                        {trackingId}
                      </Typography>
                    </Box>
                  );
                }
              )
            : ""}
        </>
      }
      placement="top"
      disableHoverListener={disableHoverListener}
      PopperProps={{
        modifiers: [
          {
            name: "offset",
            options: { offset: offset || [0, -8] },
          },
        ],
      }}
    >
      {children}
    </StyledTooltip>
  );
};

FulfillmentTooltip.propTypes = {
  children: any,
  titleData: array,
  paymentTermsName: string,
  disableHoverListener: bool,
  dueData: PropTypes.oneOfType([
    shape({
      dueDate: date,
      text: string,
    }),
    bool,
  ]),
  offset: array,
};

export default FulfillmentTooltip;
