import { useState } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import _ from "lodash";

import {
  customersCountSelector,
  customersGetParamsSelector,
  customersListSelector,
} from "../../redux/selectors/customers";

import { getCustomersService } from "../../services/customers";
import { error } from "../../utils/notifications";
import { findItemAndSet } from "./components/CustomersTab/helpers";
import { getCustomerByGroupIdService } from "../../services/parentCustomers";

const selector = createSelector(
  customersListSelector,
  customersCountSelector,
  customersGetParamsSelector,
  (customersList, customersCount, customersParams) => ({
    customersList,
    customersCount,
    customersParams,
  })
);

export const useCheckedCustomers = ({ isAdmin }) => {
  const { customersList, customersCount, customersParams } =
    useSelector(selector);
  const [checkedCustomers, setCheckedCustomers] = useState([]);
  const [loadingCustomers, setLoadingCustomers] = useState(false);

  const handleCheckCustomer = (customer) => {
    if (isAdmin) return;
    const currentStateCheckedCustomers = findItemAndSet(
      checkedCustomers,
      customer
    );
    setCheckedCustomers([...currentStateCheckedCustomers]);
    return;
  };

  const handleSetCheckedCustomersHeader = (value, group) => {
    if (group && value) {
      setCheckedCustomers((prev) =>
        _.uniqBy([...prev, ...group.subCustomers], (v) => v.id)
      );
      return;
    }
    if (group && !value) {
      setCheckedCustomers((prev) => [
        ...prev?.filter(
          (customer) => customer?.parentCustomer?.id !== group?.id
        ),
      ]);
      return;
    }

    if (value) return setCheckedCustomers([...customersList]);

    if (!value) return setCheckedCustomers([]);
  };

  const handleSetCheckAllCustomers = (value) => {
    if (!value) return setCheckedCustomers([]);

    if (customersList?.length === customersCount)
      return setCheckedCustomers([...customersList]);

    if (customersList?.length < customersCount) {
      setLoadingCustomers(true);
      getCustomersService({
        ...customersParams,
      })
        .then(({ rows }) => setCheckedCustomers([...rows]))
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err.message);
          error(err.response?.data?.message || "Something went wrong.");
        })
        .finally(() => setLoadingCustomers(false));
    }
  };

  const handleSetCheckAllCustomersWithGroup = (value, group) => {
    if (value) {
      if (group?.subCustomerCount === group?.subCustomers?.length) {
        setCheckedCustomers((prev) =>
          _.uniqBy([...prev, ...group?.subCustomers], (v) => v.id)
        );
        return;
      }

      if (group?.subCustomerCount !== group?.subCustomers?.length) {
        setLoadingCustomers(true);
        getCustomerByGroupIdService(group?.id, {
          ...customersParams,
        })
          .then(({ subCustomers }) =>
            setCheckedCustomers((prev) =>
              _.uniqBy([...prev, ...subCustomers], (v) => v.id)
            )
          )
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.error(err.message);
            error(err.response?.data?.message || "Something went wrong.");
          })
          .finally(() => setLoadingCustomers(false));
      }
    }

    if (group && !value) {
      setCheckedCustomers((prev) => [
        ...prev?.filter(
          (customer) => customer?.parentCustomer?.id !== group?.id
        ),
      ]);
      return;
    }
  };

  const resetCheckedCustomers = () => setCheckedCustomers([]);

  return {
    checkedCustomers,
    setCheckedCustomers,
    handleCheckCustomer,
    handleSetCheckedCustomersHeader,
    handleSetCheckAllCustomers,
    handleSetCheckAllCustomersWithGroup,
    customersCount,
    loadingCustomers,
    resetCheckedCustomers,
  };
};
