import { bool, number } from "prop-types";
import { Button } from "@mui/material";
import { PadlockIcon } from "components/Icons";
import { separateNumWithComma } from "helpers/helpers";

export const ActionsBlock = ({
  loadingPayment,
  isPaymentHasBeenReceived,
  loadingStripeElement,
  balance,
}) => {
  return (
    <Button
      disabled={
        loadingPayment || isPaymentHasBeenReceived || loadingStripeElement
      }
      variant="contained"
      sx={{
        width: "100%",
        fontSize: { xs: 15, md: 18 },
        height: { xs: "37px", md: "54px" },
        marginTop: "22px",
      }}
      startIcon={<PadlockIcon />}
      type="submit"
      form="stripe-form"
    >
      Pay ${separateNumWithComma(balance)}
    </Button>
  );
};

ActionsBlock.propTypes = {
  loadingPayment: bool,
  isPaymentHasBeenReceived: bool,
  loadingStripeElement: bool,
  balance: number,
};
ActionsBlock.defaultProps = {
  loadingPayment: false,
  isPaymentHasBeenReceived: false,
  loadingStripeElement: false,
  balance: 0,
};
