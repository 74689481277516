import { bool, object } from "prop-types";
import { Box, Typography } from "@mui/material";
import { calculateAvailable } from "helpers/helpers";
import { PRODUCT_TYPE_INVENTORY } from "utils/constants";

export const AvailableBlock = ({ hasThirdParty, product }) => {
  if (hasThirdParty) return null;

  const isNonInventory = product?.type === PRODUCT_TYPE_INVENTORY.non_inventory;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        height: "100%",
        width: "9.05%",
        minWidth: "9.05%",
      }}
    >
      <Typography sx={{ fontSize: 13, color: "#1C1C19" }}>
        {isNonInventory
          ? "-"
          : product?.product?.inventory
          ? calculateAvailable(
              product?.product?.inventory?.onHand || 0,
              product?.product?.inventory?.allocated || 0
            )
          : "-"}
      </Typography>
    </Box>
  );
};

AvailableBlock.propTypes = { hasThirdParty: bool, product: object };
