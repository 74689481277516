import React from "react";

export const SmileIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.277"
      height="21.277"
      viewBox="0 0 21.277 21.277"
    >
      <g
        id="Icon_feather-smile"
        data-name="Icon feather-smile"
        transform="translate(-2.25 -2.25)"
      >
        <path
          id="Path_2758"
          data-name="Path 2758"
          d="M22.777,12.889A9.889,9.889,0,1,1,12.889,3,9.889,9.889,0,0,1,22.777,12.889Z"
          transform="translate(0 0)"
          fill="none"
          stroke="#707070"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_2759"
          data-name="Path 2759"
          d="M12,21a5.339,5.339,0,0,0,3.955,1.978A5.339,5.339,0,0,0,19.911,21"
          transform="translate(-3.067 -6.134)"
          fill="none"
          stroke="#707070"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_2760"
          data-name="Path 2760"
          d="M13.5,13.5h.01"
          transform="translate(-3.578 -3.578)"
          fill="none"
          stroke="#707070"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_2761"
          data-name="Path 2761"
          d="M22.5,13.5h.01"
          transform="translate(-6.645 -3.578)"
          fill="none"
          stroke="#707070"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
};
