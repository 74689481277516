import { Menu, MenuList } from "@mui/material";
import { any, object, bool, func } from "prop-types";
import { forwardRef } from "react";
import useStyles from "./styles";

export const StyledMenu = forwardRef(
  ({ anchorEl, isOpen, children, handleClose, ...props }, ref) => {
    const classes = useStyles();

    return (
      <Menu
        id="basic-menu-list"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <MenuList ref={ref} className={classes.menuList} {...props}>
          {children}
        </MenuList>
      </Menu>
    );
  }
);

StyledMenu.displayName = "StyledMenu";

StyledMenu.propTypes = {
  children: any,
  handleClose: func,
  anchorEl: object,
  isOpen: bool,
};

StyledMenu.defaultProps = {
  children: [],
  anchorEl: null,
  isOpen: false,
};

export default StyledMenu;
