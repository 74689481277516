import { number } from "prop-types";
import { Box, Typography } from "@mui/material";

export const OrdersBlock = ({ ordersCount }) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Typography
        variant="caption"
        color="groundLight"
        sx={{ display: "inline-block", color: "#1C1C19" }}
      >
        {ordersCount || 0}
      </Typography>
    </Box>
  );
};

OrdersBlock.propTypes = {
  ordersCount: number,
};
OrdersBlock.defaultProps = { ordersCount: 0 };
