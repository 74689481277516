import { object, func, array } from "prop-types";

import { useAdmin } from "helpers/helpers";

import { getItemWithId } from "Pages/CatalogPage/CatalogNewProductPage/ContentTabs/RequiredInfoTab/components/CategoriesComponent/helpers";

import { cl } from "./styles";

import { Box, IconButton, Typography } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

export const ParentCategory = ({
  hasParent,
  categoriesList,
  setShowCategories,
}) => {
  const isAdmin = useAdmin();

  const handleParentClick = (e) => {
    e.stopPropagation();
    if (hasParent?.parentCategoryId) {
      setShowCategories(
        getItemWithId(categoriesList, hasParent?.parentCategoryId)
          ?.childCategories
      );
    }
    if (hasParent?.parentCategoryId === null) {
      setShowCategories(categoriesList);
    }
  };

  return (
    <Box sx={cl.parentCategoryWrapper}>
      <IconButton
        sx={{
          width: "24px",
          height: "24px",
        }}
        disabled={isAdmin}
        onClick={(e) => handleParentClick(e)}
      >
        <ChevronLeftIcon />
      </IconButton>

      <Typography
        sx={{
          fontSize: 12,
          fontWeight: 600,
          color: "#1C1C19",
        }}
      >
        {hasParent?.name}
      </Typography>
    </Box>
  );
};

ParentCategory.propTypes = {
  setShowCategories: func,
  hasParent: object,
  categoriesList: array,
};
