import { Grid, Typography } from "@mui/material";
import { TAB_EXPAND_HEADER_ITEMS } from "../../../../../TransactionsTab.constants";
import { cl } from "../../components.style";

export const TransactionsExpandedTableHeader = () => {
  return (
    <Grid sx={cl.headerExpandWrapper} container>
      {TAB_EXPAND_HEADER_ITEMS.map(({ name, size, styles }) => (
        <Grid key={name} sx={styles} xs={size} item container>
          <Typography sx={cl.typography}>{name}</Typography>
        </Grid>
      ))}
    </Grid>
  );
};
