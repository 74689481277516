import { bool, array, number, func, string, object } from "prop-types";
import { Box, CircularProgress, Stack } from "@mui/material";
import { HeaderThirdParty } from "./HeaderThirdParty";
import { ItemThirdParty } from "./ItemThirdParty";
import EmptyScreen from "../../../../../../components/EmptyScreen/EmptyScreen";
import { useNavigate } from "react-router-dom";
import { InfiniteLoaderWrapper } from "../../../../../../components";
import { TableHeaderControlPanel } from "components/TableHeaderControlPanel/TableHeaderControlPanel";

export const ListThirdParty = ({
  currentTab,
  ordersLoading,
  loadingAllItems,
  ordersThirdPartyList,
  ordersThirdPartyCount,
  ordersExistThirdParty,
  allOrdersChecked,
  checkAllOrders,
  checkedOrders,
  handleCheckOrder,
  handleChoseMenuItem,
  handleOpenEmail,
  handleConfirmCheckedItemsDialog,
  isQuickBooksConnected,
  repPermissions,
  itemAllCount,
  onSelectAll,
  additionalActions,
  setCheckedOrders,
  dropDownActions,
  setPage,
  nextPageFetch,
}) => {
  const navigate = useNavigate();

  return (
    <Box>
      {ordersThirdPartyList?.length ? (
        <>
          <TableHeaderControlPanel
            actionWrapperProps={{
              sx: { padding: "0 19px 0 14.5px !important" },
            }}
            checkedCount={checkedOrders?.length}
            actionsList={additionalActions}
            loading={ordersLoading}
            loadingAllItems={loadingAllItems}
            dropDownActions={dropDownActions}
            hasCheckedItems={!!checkedOrders?.length}
            availableSelectCount={itemAllCount}
            selectName="order"
            onSelectAll={(value) => onSelectAll(value)}
            cancelSelection={() => setCheckedOrders([])}
            onSelectVisible={checkAllOrders}
            headerComponent={
              <HeaderThirdParty
                allOrdersChecked={allOrdersChecked}
                checkAllOrders={checkAllOrders}
              />
            }
          />

          {ordersLoading && (
            <CircularProgress
              size="24px"
              sx={{ position: "absolute", top: "202px", right: "74px" }}
            />
          )}
          <Stack
            sx={{
              opacity: ordersLoading ? 0.5 : 1,
              pointerEvents: ordersLoading && "none",
              border: "0.5px solid #D5D9D9",
              borderRadius: "0 0 4px 4px",
              maxHeight: "calc(100vh - 320px)",
              overflow: "hidden",
              height: "100%",
            }}
            className="infinite-scroll-custom-scrollbar"
          >
            <InfiniteLoaderWrapper
              itemsList={ordersThirdPartyList}
              itemsCount={ordersThirdPartyCount}
              offsetHeight={321}
              offsetWidth={61}
              loading={ordersLoading}
              hideScroll
              tableStyle={{ borderWidth: "0px" }}
              handleFetch={nextPageFetch}
              itemHeight={49}
              resetCallback={() => setPage(1)}
              id="orders-third-party-list"
              renderChildren={(index) => (
                <ItemThirdParty
                  order={ordersThirdPartyList[index]}
                  {...{
                    handleCheckOrder,
                    handleChoseMenuItem,
                    handleOpenEmail,
                    handleConfirmCheckedItemsDialog,
                    currentTab,
                    isQuickBooksConnected,
                    repPermissions,
                  }}
                  isChecked={checkedOrders.some(
                    (checkedOrder) =>
                      checkedOrder === ordersThirdPartyList[index].id
                  )}
                />
              )}
            />
          </Stack>
        </>
      ) : (
        <EmptyScreen
          type="orders"
          onConfirm={() => navigate("/cart")}
          height="calc(100vh - 453px)"
          showAction={!ordersExistThirdParty}
          loading={ordersLoading}
          disabled={!!repPermissions && !repPermissions?.orders?.create_edit}
        />
      )}
    </Box>
  );
};

ListThirdParty.propTypes = {
  currentTab: string,
  ordersLoading: bool,
  loadingAllItems: bool,
  ordersThirdPartyList: array,
  ordersThirdPartyCount: number,
  ordersExistThirdParty: bool,
  allOrdersChecked: bool,
  checkAllOrders: func,
  checkedOrders: array,
  handleCheckOrder: func,
  handleChoseMenuItem: func,
  handleOpenEmail: func,
  handleConfirmCheckedItemsDialog: func,
  isQuickBooksConnected: bool,
  repPermissions: object,
  onSelectAll: func,
  itemAllCount: number,
  additionalActions: array,
  setCheckedOrders: func,
  dropDownActions: array,
  setPage: func,
  nextPageFetch: func,
};
