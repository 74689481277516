import React from "react";

export const TransferIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11.017"
      height="9.64"
      viewBox="0 0 11.017 9.64"
    >
      <path
        id="Icon_open-transfer"
        data-name="Icon open-transfer"
        d="M8.263,0V1.377H0V2.754H8.263V4.131l2.754-2.066ZM2.754,5.509,0,7.574,2.754,9.64V8.263h8.263V6.886H2.754Z"
        fill="#3f3f3f"
      />
    </svg>
  );
};
