import { object, func, shape, bool } from "prop-types";
import { Box, Button, InputAdornment, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import { ActiveIcon } from "../../../../components/Icons";
import { StyledField } from "./StyledField";
import PasswordCriteria from "../../../../components/PasswordCriteria";
import { PASSWORD_CRITERIA } from "../../../../components/PasswordCriteria/PasswordCriteria.constants";

export const StepOne = ({ formData, loading }) => {
  const { control, errors, setValue, formField, trigger } = formData;

  return (
    <Box mb="62px">
      <Box
        sx={{
          pt: "10px",
          display: "flex",
          flexDirection: "column",
          gap: "22px",
        }}
      >
        <Controller
          render={({ field }) => (
            <TextField
              disabled
              fullWidth
              InputProps={{
                style: { height: "48px", borderRadius: "8px", fontSize: 18 },
                endAdornment: (
                  <InputAdornment position="end">
                    <ActiveIcon />
                  </InputAdornment>
                ),
              }}
              placeholder="Email"
              {...field}
            />
          )}
          name="email"
          control={control}
        />

        <Controller
          render={({ field, fieldState: { error } }) => (
            <StyledField
              {...{ control, field, error }}
              onChange={(e) => {
                field.onChange(e);
                if (formField?.confirmPassword) trigger(["confirmPassword"]);
              }}
              label="Password"
              type="password"
            />
          )}
          name="password"
          control={control}
        />
        <Controller
          render={({ field, fieldState: { error } }) => (
            <StyledField
              {...{ control, field, error }}
              onChange={(e) => {
                field.onChange(e);
                if (formField?.password) trigger(["password"]);
              }}
              label="Confirm password"
              type="password"
            />
          )}
          name="confirmPassword"
          control={control}
        />
      </Box>
      <PasswordCriteria
        password={formField.password}
        confirmPassword={formField.confirmPassword}
      />
      <Box sx={{ mt: "20px" }}>
        <Button
          sx={{
            height: "48px",
            fontSize: 20,
            fontWeight: 500,
          }}
          fullWidth
          variant="contained"
          disabled={
            loading ||
            PASSWORD_CRITERIA.some((criteria) => {
              const { password, confirmPassword } = formField;
              const completed = criteria.condition({
                password,
                confirmPassword,
              });
              return !completed;
            })
          }
          onClick={() => {
            if (
              formField?.password &&
              !errors?.password &&
              !errors?.confirmPassword
            ) {
              return setValue("step", 2);
            }
          }}
        >
          {loading ? "Loading..." : "Next Step"}
        </Button>
      </Box>
    </Box>
  );
};

StepOne.propTypes = {
  formData: shape({
    control: object,
    errors: object,
    setValue: func,
    formField: object,
  }),
  loading: bool,
};
