export const defaultFilters = {
  territory_id: "",
};

export const FILTER_SWITCHES = [
  {
    value: "territory_id",
    label: "Territory",
    type: "select",
    checked: false,
    selectLabel: "Select Territory",
  },
];

export const defaultRepCustomersFilters = {
  territory_id: "",
  representative_id: "",
  last_order: "",
  order_direct: "",
  tasks_due: "",
  has_assigned_routes: "",
};

export const FILTER_CUSTOMERS_SWITCHES = [
  {
    value: "representative_id",
    label: "Representative",
    type: "select",
    checked: false,
    selectLabel: "Select Representative",
  },
  {
    value: "last_visit",
    label: "Last visit",
    type: "date",
    checked: false,
    selectLabel: "Select",
  },
  {
    value: "territory_id",
    label: "Territory",
    type: "select",
    checked: false,
    selectLabel: "Select Territory",
  },
  {
    value: "last_order",
    label: "Last order",
    type: "date",
    checked: false,
    selectLabel: "Select",
  },
  {
    value: "group_id",
    label: "Group",
    type: "select",
    checked: false,
    selectLabel: "Select Group",
  },
  {
    value: "tag_id",
    label: "Tag",
    type: "select",
    checked: false,
    selectLabel: "Select Tag",
  },
  {
    value: "sort_by",
    label: "Sort by",
    type: "select",
    checked: false,
    selectLabel: "Sort by",
  },
  {
    value: "issues",
    label: "Issues",
    type: "switch",
    checked: false,
    values: [
      { value: "with_missing_info", name: "Missing info" },
      { value: "with_duplicated_info", name: "Duplicates" },
    ],
  },
  {
    value: "order_direct",
    label: "Order direct",
    type: "switch",
    checked: false,
    values: [
      { value: "APPROVED", name: "Approved" },
      { value: "PENDING", name: "Pending" },
      { value: "REJECTED", name: "Rejected" },
      { value: null, name: "No order direct" },
    ],
  },
  {
    value: "tasks_due",
    label: "Tasks",
    type: "date",
    checked: false,
    selectLabel: "Select",
  },
  {
    value: "has_assigned_routes",
    label: "Route assignment",
    type: "switch",
    checked: false,
    values: ["ASSIGNED", "NOT_ASSIGNED"],
  },
];

export const FILTER_REP_CUSTOMERS_SWITCHES = [
  {
    value: "group_id",
    label: "Group",
    type: "select",
    checked: false,
    selectLabel: "Select Group",
  },
  {
    value: "territory_id",
    label: "Territory",
    type: "select",
    checked: false,
    selectLabel: "Select Territory",
  },
];
