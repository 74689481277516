import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  roomSection: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "20px",
    justifyContent: "space-between",
    boxShadow: "none",
    border: "0.5px solid #D5D9D9",
    maxHeight: "100%",
  },

  uploadingImage: {
    height: "44px",
    width: "auto",
  },
}));

export default useStyles;
