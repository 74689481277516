import { useCallback, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { downloadExcelFile } from "../../../../../helpers/helpers";
import {
  exportCustomersService,
  exportOrdersService,
  exportProductsService,
} from "../../../../../services/data-exports";
import { error } from "../../../../../utils/notifications";

export const useExport = () => {
  const [exportOpenData, setExportOpenData] = useState({
    type: "",
    open: false,
    loading: false,
  });

  const { control, handleSubmit } = useForm({
    mode: "onSubmit",
    defaultValues: { format: "csv" },
  });

  const formField = useWatch({ control });

  const updateExportState = useCallback((updates) => {
    setExportOpenData((prev) => ({ ...prev, ...updates }));
  }, []);

  const handleExportSuccess = useCallback(
    (res) => {
      const type = exportOpenData.type;
      downloadExcelFile(res, type, type);
      updateExportState({ open: false });
    },
    [exportOpenData.type, updateExportState]
  );

  const handleService = useCallback(
    async (exportService) => {
      setExportOpenData((prev) => ({ ...prev, loading: true }));
      exportService
        .then((res) => handleExportSuccess(res))
        .catch((err) => {
          error(err?.response?.data?.message);
        })
        .finally(() => {
          setExportOpenData((prev) => ({ ...prev, loading: false }));
        });
    },
    [handleExportSuccess]
  );

  const exportHandlers = useMemo(
    () => ({
      customers: (data) => handleService(exportCustomersService(data)),
      products: (data) => handleService(exportProductsService(data)),
      orders: (data) => handleService(exportOrdersService(data)),
    }),
    [handleService]
  );

  const onSubmit = useCallback(
    (data) => {
      exportHandlers[exportOpenData.type](data);
    },
    [exportHandlers, exportOpenData.type]
  );

  return {
    updateExportState,
    handleSubmit,
    control,
    exportOpenData,
    formField,
    onSubmit,
  };
};
