import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  dialogTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "16px 23px 21px 32px",
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    gap: "29px",
    minWidth: "403px",
    padding: "0px 34px 31px 31px",
  },
  discountButton: {
    width: "100%",
    height: "40px",
    borderRadius: "8px",
  },

  title: { fontSize: "22px", color: "#3F3F3F" },
}));

export default useStyles;
