import styled from "@emotion/styled";
import { LinearProgress, linearProgressClasses } from "@mui/material";

export const BorderLinearProgress = styled(LinearProgress)(({ height }) => ({
  height: height || 11,
  borderRadius: 4,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "rgba(222, 222, 222, 0.53)",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 4,
    backgroundColor: "rgba(71, 160, 110, 0.74)",
  },
}));

export default BorderLinearProgress;
