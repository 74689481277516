export const SETTINGS = [
  {
    checked: false,
    type: "topCustomers",
    label: "Show top 3 customers",
  },
  {
    checked: false,
    type: "topProducts",
    label: "Show top 3 products",
  },
  {
    checked: false,
    type: "topRepresentatives",
    label: "Show top 3 reps",
  },
];

export const SETTINGS_NAME = {
  topCustomers: {
    name: "Top Customers",
  },
  topProducts: {
    name: "Top Products",
  },
  topRepresentatives: {
    name: "Top Reps",
  },
};
