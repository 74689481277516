import { object, func, bool } from "prop-types";
import { Box, DialogActions } from "@mui/material";
import { StyledButton } from "components";

export const FooterBlock = ({ cl, handleClose, isDisableBtn, onSubmit }) => {
  return (
    <DialogActions sx={cl.action}>
      <Box sx={cl.action.btnWrapper}>
        <StyledButton
          sx={{ color: "#6A6A6A" }}
          fontSize="13px"
          label="Cancel"
          color="groundLighter"
          onClick={handleClose}
        />

        <Box>
          <StyledButton
            disabled={isDisableBtn}
            sx={{ px: 1, ml: 1, whiteSpace: "nowrap" }}
            fontSize="15px"
            label="Confirm"
            variant="contained"
            onClick={onSubmit}
          />
        </Box>
      </Box>
    </DialogActions>
  );
};

FooterBlock.propTypes = {
  cl: object,
  handleClose: func,
  isDisableBtn: bool,
  onSubmit: func,
};
FooterBlock.defaultProps = {
  cl: {},
  handleClose: () => {},
  isDisableBtn: false,
  onSubmit: () => {},
};
