export const defaultValues = {
  id: "",
  clockInAt: "",
  clockOutAt: "",
  days: [],
  clockIn: false,
  clockOut: false,
};

export const days = [
  { id: "monday", name: "MON" },
  { id: "tuesday", name: "TUE" },
  { id: "wednesday", name: "WED" },
  { id: "thursday", name: "THU" },
  { id: "friday", name: "FRI" },
  { id: "saturday", name: "SAT" },
  { id: "sunday", name: "SUN" },
];
