import { makeStyles } from "@mui/styles";

export const cl = {
  wrapper: {
    backgroundColor: "#ffffff",
    display: "flex",
    border: "1px solid #d5d9d9",
    borderRadius: "4px",
    alignItems: "center",
    height: "67px",
  },
  btnsWrapper: {
    display: "flex",
    alignItems: "center",
    gap: 1.2,
  },
  btn: { height: "39px", px: 0 },
};

const useStyles = makeStyles(() => ({
  selectGroupTitle: {
    background: "#F7F7F7",
    borderTop: "0.5px solid #D5D9D9",
    borderBottom: "0.5px solid #D5D9D9",
    height: "36px",
    lineHeight: "36px",
  },
  tabs: {
    width: "100%",
    maxWidth: "180px",
    minHeight: "38px",
    "& .MuiTabs-flexContainer": { justifyContent: "space-between" },
  },
  tab: {
    textTransform: "none",
    fontSize: "20px",
    color: "#707070",
    borderRadius: "4px",

    "&.Mui-selected": {
      backgroundColor: "rgba(71, 160, 110, 0.2) !important",
    },
    minWidth: "10px",
    width: "84px",

    height: "38px",
    minHeight: "38px",
    padding: 0,
  },
  filterChip: {
    borderRadius: "4px",
    border: "0.7px solid #d5d9d9",
    backgroundColor: "#FAFAFB",
    // height: "28px",
    height: "39px",
  },
}));

export default useStyles;
