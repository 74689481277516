import { string, array, func } from "prop-types";

import { RouteItem } from "../RouteItem";

import { Box, Stack, Typography } from "@mui/material";

export const RoutesList = ({ title, list, stateKey, onRouteChange }) => {
  return (
    <>
      {list && !!list.length && (
        <Box>
          <Stack
            gap="10px"
            sx={{ padding: "18px 24px 20px 17px", overflow: "hidden" }}
          >
            <Typography variant="h6" fontWeight={500} p="0px 9px 0px 16px">
              {title}
            </Typography>
            <Stack>
              {list.map((route) => (
                <RouteItem
                  key={route.id}
                  {...{ route, stateKey, onRouteChange }}
                />
              ))}
            </Stack>
          </Stack>
        </Box>
      )}
    </>
  );
};

RoutesList.propTypes = {
  list: array,
  title: string,
  stateKey: string,
  onRouteChange: func,
};
