import React from "react";

const ProspectStatusIcon = () => {
  return (
    <svg
      width="13"
      height="16.25"
      viewBox="0 0 8 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.818 9.09h-.455v-.758a2.3 2.3 0 0 0-.152-.8q-.112-.303-.302-.563l-.637-.85A1.37 1.37 0 0 1 5 5.302v-.459c.001-.361.145-.708.4-.964l.299-.3a2.24 2.24 0 0 0 .634-1.29c0-.006.004-.011.004-.018l-.001-.006a2 2 0 0 0 .028-.292V.906h.455a.455.455 0 1 0 0-.909H.453a.455.455 0 0 0 0 .91h.454v1.065q.005.147.029.292l-.001.006.003.018c.065.488.288.942.635 1.29l.299.3c.255.256.398.603.4.964v.46a1.4 1.4 0 0 1-.273.818l-.637.849a2.3 2.3 0 0 0-.318.6 2.3 2.3 0 0 0-.137.763v.758H.453a.455.455 0 1 0 0 .91h6.365a.455.455 0 1 0 0-.91M1.817 1.816v-.91h3.637v.91zm.4 1.12a1.4 1.4 0 0 1-.172-.21h3.181a1.4 1.4 0 0 1-.171.21l-.3.299a2.26 2.26 0 0 0-.646 1.309h-.948a2.26 2.26 0 0 0-.646-1.31zm.51 3.73a2.3 2.3 0 0 0 .449-1.213h.92c.03.439.185.86.449 1.212l.454.607H2.271zM5.453 9.09H1.817v-.758q.001-.075.012-.151h3.613q.01.075.012.151z"
        fill="#FF8A00"
      />
    </svg>
  );
};

export default ProspectStatusIcon;
