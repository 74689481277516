import moment from "moment";
import pluralize from "pluralize";

export const openFilePreview = (res) => {
  const url = URL.createObjectURL(res);
  window.open(url, "_blank");
};

export const getPaymentDue = ({ fulfilledAt, daysInvoices, hideDue }) => {
  if ((!daysInvoices && daysInvoices !== 0) || !fulfilledAt) return false;

  const daysPassed = moment().diff(fulfilledAt, "days");

  const dayDiff = daysPassed - daysInvoices;
  const dueDate = moment(fulfilledAt).add(daysInvoices, "days");

  if (dayDiff > 0) {
    return {
      text: `Overdue ${dayDiff} ${pluralize("day", dayDiff)}`,
      overdue: true,
      dueDate,
      hideDue,
    };
  }

  const dayValue = dayDiff * -1;
  const calendar = { 0: "today", 1: "tomorrow" };
  const dayString = calendar[dayValue] || `in ${dayValue} days`;
  return { text: `Due ${dayString}`, overdue: false, dueDate, hideDue };
};
