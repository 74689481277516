import { func } from "prop-types";

import { CrossBigIcon } from "components/Icons";

import { cl } from "../styles";

import { DialogTitle, IconButton, Typography } from "@mui/material";

export const HeaderBlock = ({ onClose }) => {
  return (
    <DialogTitle sx={cl.blockTitle}>
      <IconButton sx={cl.closeBtn} onClick={onClose}>
        <CrossBigIcon size={20} />
      </IconButton>
      <Typography sx={cl.textTitle}>Change your password</Typography>
    </DialogTitle>
  );
};

HeaderBlock.propTypes = {
  onClose: func,
};
HeaderBlock.defaultProps = {
  onClose: () => {},
};
