export const cl = {
  listWrapper: {
    borderRadius: "0 0 4px 4px",
    borderWidth: "0 1px 1px 1px",
    borderStyle: "solid",
    borderColor: "#D5D9D9",
    maxHeight: "202px",
    overflow: "hidden",
  },
};
