import React from "react";

export const GalleryUnCheckedIcon = () => {
  return (
    <svg
      id="Component_116_25"
      data-name="Component 116 – 25"
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <rect
        id="Rectangle_2581"
        data-name="Rectangle 2581"
        width="18"
        height="18"
        rx="4"
        fill="#47a06d"
      />
      <path
        id="path"
        d="M1440.355,4365.574l2.732,2.732,5.745-5.745"
        transform="translate(-1435.593 -4356.434)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};
