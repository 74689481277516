import moment from "moment";

const prepareDateText = (dateName, dateFormat = "DDMMMYY") => {
  const today = moment().format(dateFormat);
  switch (dateName) {
    case "today":
      return today;

    case "yesterday":
      return moment().subtract(1, "days").format(dateFormat);

    case "this_week":
      return `${moment().startOf("isoWeek").format(dateFormat)}-${today}`;

    case "last_week":
      return `${moment()
        .subtract(1, "weeks")
        .startOf("isoWeek")
        .format(dateFormat)}-${moment()
        .subtract(1, "weeks")
        .endOf("isoWeek")
        .format(dateFormat)}`;

    case "this_month":
      return `${moment().startOf("month").format(dateFormat)}-${moment().format(
        dateFormat
      )}`;

    case "last_month":
      return `${moment()
        .subtract(1, "months")
        .startOf("month")
        .format(dateFormat)}-${moment()
        .subtract(1, "months")
        .endOf("month")
        .format(dateFormat)}`;

    case "this_year":
      return `${moment().startOf("years").format(dateFormat)}-${today}`;

    case "last_year":
      return `${moment()
        .subtract(1, "years")
        .startOf("year")
        .format(dateFormat)}-${moment()
        .subtract(1, "years")
        .endOf("year")
        .format(dateFormat)}`;

    default:
      return dateName;
  }
};

export const generateFileName = (date, title = "", dateFormat = "DDMMMYY") => {
  const d = date.date ? `-${prepareDateText(date.date).toUpperCase()}` : "";

  const startDate = date.start_date
    ? `-${moment(date.start_date).format(dateFormat).toUpperCase()}`
    : "";
  const endDate = date.end_date
    ? `-${moment(date.end_date).format(dateFormat).toUpperCase()}`
    : "";

  return title && (d || startDate || endDate)
    ? `${title}${d}${startDate}${endDate}`
    : `${title}`;
};
