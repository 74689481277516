import React from "react";

// eslint-disable-next-line react/prop-types
export const MarkerIcon = ({ fill, ...props }) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="12"
      viewBox="0 0 9 12"
    >
      <path
        id="Icon_awesome-map-marker"
        data-name="Icon awesome-map-marker"
        d="M4.038,11.758C.632,6.821,0,6.314,0,4.5a4.5,4.5,0,0,1,9,0c0,1.814-.632,2.321-4.038,7.258a.563.563,0,0,1-.925,0Z"
        fill={fill}
      />
    </svg>
  );
};
