import { object, number, func, bool } from "prop-types";
import { useWatch } from "react-hook-form";
import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import {
  CheckBoxReportCheckedIcon,
  CheckBoxReportUncheckedIcon,
  ReorderBurgerIcon,
} from "../../../../../components/Icons";

export const ReportCheckbox = ({
  item,
  index,
  control,
  setValue,
  handleAddColumn,
  orderCheck,
}) => {
  const formField = useWatch({ control });

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
      }}
    >
      <ReorderBurgerIcon />

      <FormControlLabel
        sx={{
          ml: "-10px",
          mr: 0,
        }}
        control={
          <Checkbox
            checked={!!item?.selected}
            icon={<CheckBoxReportUncheckedIcon />}
            checkedIcon={<CheckBoxReportCheckedIcon />}
            onChange={(e) => {
              setValue(
                `selectAndReorderColumns[${
                  orderCheck ? index + 1 : index
                }].selected`,
                e.target.checked
              );
              if (!item?.isHidden) {
                handleAddColumn(item);
                const i = formField?.selectAndReorderColumnsBottom?.findIndex(
                  (col) => col?.type === item?.type
                );
                setValue(
                  `selectAndReorderColumnsBottom[${i}].selected`,
                  e.target.checked
                );
                setValue(
                  `selectAndReorderColumnsBottom[${i}].chosen`,
                  e.target.checked
                );
              }
              if (item?.type === "productName") {
                formField?.selectAndReorderColumnsBottom?.forEach((el, i) => {
                  if (!!el.bottom && !!el.selected) {
                    setValue(
                      `selectAndReorderColumnsBottom[${i}].selected`,
                      false
                    );
                    setValue(
                      `selectAndReorderColumnsBottom[${i}].chosen`,
                      false
                    );
                  }
                });
                setValue(
                  "selectAndReorderColumns",
                  formField?.selectAndReorderColumnsBottom?.filter(
                    (el) => !!el.chosen
                  )
                );
              }
            }}
          />
        }
        label={
          <Typography fontSize={17} fontWeight={300} color="#707070">
            {item?.name}
          </Typography>
        }
      />
    </Box>
  );
};
ReportCheckbox.propTypes = {
  item: object,
  index: number,
  control: object,
  setValue: func,
  handleAddColumn: func,
  orderCheck: bool,
};
