import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  menuRow: {
    padding: "7px 24px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },

  buttonsBlock: {
    display: "flex",
    alignItems: "center",
    padding: "11px 22px 0",
    justifyContent: "flex-end",
    gap: "13px",

    "& button": {
      height: "28px",
    },
  },

  toggleButton: {
    textTransform: "none",
    fontSize: "12px",
    padding: "3px 6px",
    whiteSpace: "nowrap",
    "&:not(:first-of-type)": {
      marginLeft: "0!important",
      borderLeft: "none!important",
    },
  },
  textInputInventory: {
    fontSize: "12px",
    paddingRight: "0px",
  },

  textFieldsWrapper: {
    display: "flex",
    gap: "10px",
  },

  textfield: {
    "&.MuiOutlinedInput-root": {
      fontSize: "12px",
    },
  },

  selectGroupTitle: {
    background: "#F7F7F7",
    borderTop: "0.5px solid #D5D9D9",
    borderBottom: "0.5px solid #D5D9D9",
    height: "36px",
    lineHeight: "36px",
  },

  switchesContent: {
    maxHeight: "500px",
    overflow: "auto",
    pt: 0,
    scrollbarWidth: "6px",
    scrollbarColor: "rgba(0, 0, 0, 0.35) rgba(0, 0, 0, 0.1)",

    "&::-webkit-scrollbar": {
      width: "6px",
      borderRadius: "7px",
      backgroundColor: "rgba(0, 0, 0, 0.1)",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "7px",
      backgroundColor: "rgba(0, 0, 0, 0.35)",
    },
  },
}));

export default useStyles;
