import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  smallTabsWrapper: {
    minHeight: "fit-content",
    height: "fit-content",
    "& .MuiTab-root:last-child": { border: "none" },
    "& .MuiTab-root": {
      padding: "0px 6px",
      minWidth: "fit-content",
      minHeight: "fit-content",
      borderRight: "0.5px solid #D5D9D9",
      fontSize: "12px",
      fontWeight: "400",
      "&.Mui-selected": {
        backgroundColor: "transparent!important",
        fontWeight: "700",
      },
    },
  },

  searchLabel: {
    top: -3,
    paddingLeft: "25px",
    fontSize: "15px",
    fontWeight: 400,
    transitionProperty: "all",
    "&.Mui-focused": {
      paddingLeft: 0,
      top: -1,
    },
    "&.MuiFormLabel-filled": {
      paddingLeft: 0,
      top: -1,
    },
  },

  searchInput: {
    height: "31px",
    paddingLeft: "25px",
    fontSize: "15px",
    fontWeight: 400,
  },
}));

export default useStyles;
