import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  nativeWrapper: {
    borderTop: "1px solid #D5D9D9",
    padding: "10px 36px 0px",
  },

  blockTitle: {
    fontSize: "16px",
    color: "#6a6a6a",
    fontWeight: 500,
  },

  blockWrapper: {
    borderTop: "1px solid #D5D9D9",
    padding: "15px 10px",
  },

  chooseIdsWrapper: {
    flex: "1 1 60%",
    maxWidth: "100%",
  },

  groupBySelectWrapper: {
    flex: "1 1 40%",
  },

  groupByWrapper: {
    display: "flex",
    gap: "8px",
    marginTop: "8px",
  },

  reorderBlock: {
    padding: "15px 36px",
    borderTop: "1px solid #D5D9D9",
  },
  textInputSearch: {
    fontSize: "15px",
    height: "34px",
    backgroundColor: "#F8F8F8",
    paddingLeft: "25px",
  },
}));

export default useStyles;
