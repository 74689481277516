import { useEffect, useState } from "react";
import { string } from "prop-types";
import { CardMedia } from "@mui/material";

export const CardMediaImage = ({ src, placeholderSrc, alt, ...props }) => {
  const [imageSrc, setImageSrc] = useState(placeholderSrc);

  const cl = `progressive-${
    imageSrc === placeholderSrc ? "loading" : "loaded"
  }`;

  useEffect(() => {
    let didCancel = false;
    const img = new Image();
    img.src = src;
    img.onload = () => {
      if (didCancel) return;
      setImageSrc(src);
    };

    return () => {
      didCancel = true;
    };
  }, [src]);

  return (
    <CardMedia
      sx={{ borderRadius: "4px", cursor: "pointer" }}
      className={cl}
      component="img"
      height="260px"
      image={imageSrc}
      alt={alt}
      {...props}
    />
  );
};
CardMediaImage.propTypes = {
  src: string,
  placeholderSrc: string,
  alt: string,
};

export default CardMediaImage;
