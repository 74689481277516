import React from "react";

export const TaskIcon = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99994 15C11.8659 15 14.9999 11.866 14.9999 8C14.9999 4.13401 11.8659 1 7.99994 1C4.13395 1 0.999939 4.13401 0.999939 8C0.999939 11.866 4.13395 15 7.99994 15ZM8.74994 4.75V7.25L11.2499 7.25C11.6642 7.25 11.9999 7.58579 11.9999 8C11.9999 8.41421 11.6642 8.75 11.2499 8.75H8.74994V11.25C8.74994 11.6642 8.41415 12 7.99994 12C7.58573 12 7.24994 11.6642 7.24994 11.25V8.75H4.74994C4.33573 8.75 3.99994 8.41421 3.99994 8C3.99994 7.58579 4.33573 7.25 4.74994 7.25L7.24994 7.25V4.75C7.24994 4.33579 7.58572 4 7.99994 4C8.41415 4 8.74994 4.33579 8.74994 4.75Z"
        fill="#409A65"
      />
    </svg>
  );
};
