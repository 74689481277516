import { Box, Button, Checkbox, Stack, Typography } from "@mui/material";
import { CheckboxIcon } from "components/Icons";
import { object, bool, func } from "prop-types";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { cl } from "../../styles";

export const CustomerBlock = ({
  repPermissions,
  customer,
  isChecked,
  handleCheckCustomer,
}) => {
  const navigate = useNavigate();

  const { id, customId, name, displayName, shippingAddress } = customer || {};

  const address = shippingAddress?.formatted_address;

  const [showEditBtn, setShowEditBtn] = useState(true);

  return (
    <Stack
      direction="row"
      gap="11px"
      py="8px"
      alignItems="center"
      sx={{
        "&:not(:last-child)": {
          borderBottom: "1px solid #D5D9D9",
        },
      }}
      justifyContent="space-between"
      onMouseEnter={() => setShowEditBtn(true)}
      onMouseLeave={() => setShowEditBtn(false)}
    >
      <Stack
        direction="row"
        gap="11px"
        alignItems="center"
        sx={{ overflow: "hidden" }}
      >
        <Stack
          width="16px"
          height="16px"
          alignItems="center"
          justifyContent="center"
        >
          <Checkbox
            disableTouchRipple
            disableRipple
            disableFocusRipple
            icon={<CheckboxIcon size={isChecked ? 24 : 16} />}
            sx={{
              padding: 0,
              "& .MuiSvgIcon-root": { fontSize: 20 },
            }}
            checked={!!isChecked}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChange={() => handleCheckCustomer(customer)}
          />
        </Stack>
        <Stack width={`calc(100% - ${showEditBtn ? "30px" : "0px"})`}>
          <Box display="flex" gap="4px" alignItems="center">
            <Typography
              fontSize={13}
              fontWeight={500}
              color="primary"
              component={
                repPermissions
                  ? repPermissions?.customers?.view
                    ? Link
                    : "span"
                  : Link
              }
              sx={{ textDecoration: "none" }}
              to={`/customers/${id}`}
              noWrap
            >
              {displayName || name}
            </Typography>
            <Typography
              fontSize={13}
              fontWeight={400}
              color="#707070"
              whiteSpace="nowrap"
            >
              ID: {customId.customId}
            </Typography>
          </Box>

          <Box>
            <Typography fontSize={12} color="#A7A6A6" noWrap>
              {address}
            </Typography>
          </Box>
        </Stack>
      </Stack>
      {showEditBtn && (
        <Button
          onClick={() => navigate(`/customers/edit/${id}`)}
          sx={cl.editBtn}
          variant="outlined"
        >
          Edit
        </Button>
      )}
    </Stack>
  );
};

CustomerBlock.propTypes = {
  repPermissions: object,
  customer: object,
  isChecked: bool,
  handleCheckCustomer: func,
};
CustomerBlock.defaultProps = {
  customId: {},
  isChecked: false,
  repPermissions: {},
  handleCheckCustomer: () => {},
};
