const hasItem = (arr1, arr2) =>
  arr1.some((el) => {
    return arr2.some((item) => item?.id === el?.id ?? null);
  });

export const setIndeterminate = (parentItem, isCheckedParent, checkedItems) => {
  if (isCheckedParent) return false;
  const childProducts = parentItem?.childProducts ?? [];
  if (!childProducts?.length) return false;

  return hasItem(childProducts, checkedItems);
};
