import { bool, func, object } from "prop-types";
import { Box, Dialog, DialogContent, IconButton } from "@mui/material";
import { CrossIcon } from "components/Icons";

export const ImagePreview = ({ open, onClose, image }) => {
  const { src, alt } = image || {};

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { maxWidth: "calc(100% - 32px)" } }}
    >
      <IconButton
        sx={{
          position: "absolute",
          top: "1.5%",
          right: "1%",
          zIndex: "1",
          background: "rgba(256, 256, 256, 0.5)",
        }}
        onClick={onClose}
      >
        <CrossIcon fill="#5F6267" />
      </IconButton>
      <DialogContent
        sx={{ width: "100%", height: "100%", minHeight: "42px", padding: 0 }}
      >
        <Box sx={{ width: "inherit" }} src={src} alt={alt} component="img" />
      </DialogContent>
    </Dialog>
  );
};

ImagePreview.propTypes = { open: bool, onClose: func, image: object };
ImagePreview.defaultProps = { open: false, onClose: () => {}, image: {} };
