import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  label: {
    "& .MuiFormLabel-root": {
      color: "#B5B5AC",
      fontSize: "12px",
      top: "2px",
    },
    "& .Mui-focused": {
      color: "#42A57F",
      fontSize: "1rem",
      top: "0px",
    },
    "& .MuiOutlinedInput-root": {
      height: "100%",
    },
  },
  tabs: {
    minHeight: "auto",
    height: "44px",
    "& .Mui-selected": {
      backgroundColor: "transparent!important",
    },
  },
  smallerButton: {
    height: "24px",
    "& > span": {
      fontSize: "12px",
    },
  },

  biggerButton: {
    "& > span": {
      fontSize: "14px",
    },
  },
}));
export default useStyles;
