import { useMemo } from "react";
import { object, any, bool } from "prop-types";
import { useNavigate, Link as NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { upperFirst, lowerCase } from "lodash";
import { Box, CircularProgress, Paper, Typography } from "@mui/material";
import useStyles from "./styles";
import { customersDefaultFiltersSelector } from "../../../../redux/selectors/customers";
import { customerGetFiltersAction } from "../../../../redux/actions/customers";
import { useRepsPermissions } from "helpers/hooks";
import { truncateText } from "helpers/helpers";
import { StyledTooltip } from "components";

const selector = createSelector(
  customersDefaultFiltersSelector,
  (customersDefaultFilters) => ({
    customersDefaultFilters,
  })
);

const InsightsWidget = ({
  insights,
  currentFilterDate,
  notAvailable,
  isMerchendiser,
  loading,
}) => {
  const { customersDefaultFilters } = useSelector(selector);
  const dispatch = useDispatch();
  const { ordersToProcess, paymentsDue, totalPlaces, placesVisited } = insights;

  const repPermissions = useRepsPermissions();

  const setPaymentsDue = useMemo(() => {
    return notAvailable || isMerchendiser
      ? "Not available"
      : `$${paymentsDue?.toLocaleString() || 0}`;
  }, [isMerchendiser, notAvailable, paymentsDue]);

  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <Paper className={classes.widgetWrapper} elevation={0}>
      <Box className={classes.titleWrapper}>
        <Typography>Insights</Typography>
      </Box>
      <Box className={classes.insightsMatrix}>
        <Box className={classes.insightItem}>
          <Box
            className={classes.insightItemLink}
            component={
              repPermissions && !repPermissions?.orders?.view ? "span" : NavLink
            }
            to={"/orders"}
            onClick={(e) => {
              if (repPermissions && !repPermissions?.orders?.view) return;

              localStorage.setItem(
                "orderData",
                JSON.stringify({
                  filters: { order_status: ["pending"] },
                })
              );
              if (e.metaKey || e.ctrlKey) return;
              e.stopPropagation();
              e.preventDefault();
              navigate("orders");
            }}
          >
            {loading ? (
              <CircularProgress size={41} />
            ) : (
              <Typography
                className={
                  notAvailable || isMerchendiser ? "not-available" : null
                }
              >
                {notAvailable || isMerchendiser
                  ? "Not available"
                  : ordersToProcess?.toLocaleString() || 0}
              </Typography>
            )}
            <Typography noWrap>Orders to Process</Typography>
          </Box>
        </Box>

        <Box className={classes.insightItem}>
          <Box
            className={classes.insightItemLink}
            component={
              repPermissions && !repPermissions?.customers?.view
                ? "span"
                : NavLink
            }
            to="/customers/"
            onClick={(e) => {
              if (repPermissions && !repPermissions?.customers?.view) return;

              if (e.metaKey || e.ctrlKey) return;
              e.stopPropagation();
              e.preventDefault();
              navigate("customers");
            }}
          >
            {loading ? (
              <CircularProgress size={41} />
            ) : (
              <Typography>{totalPlaces || 0}</Typography>
            )}
            <Typography noWrap>Total Customers</Typography>
          </Box>
        </Box>

        <Box className={classes.insightItem}>
          <Box
            className={classes.insightItemLink}
            component={
              repPermissions && !repPermissions?.orders?.view ? "span" : NavLink
            }
            to={"/orders"}
            onClick={(e) => {
              if (repPermissions && !repPermissions?.orders?.view) return;

              localStorage.setItem(
                "orderData",
                JSON.stringify({
                  filters: { payment_status: ["PARTIALLY_PAID", "UNPAID"] },
                })
              );
              if (e.metaKey || e.ctrlKey) return;
              e.stopPropagation();
              e.preventDefault();
              navigate("orders");
            }}
          >
            {loading ? (
              <CircularProgress size={41} />
            ) : (
              <StyledTooltip title={setPaymentsDue} placement="top" arrow>
                <Typography
                  noWrap
                  maxWidth="100%"
                  className={
                    notAvailable || isMerchendiser ? "not-available" : null
                  }
                >
                  {truncateText(setPaymentsDue, 12)}
                </Typography>
              </StyledTooltip>
            )}
            <Typography>Payments Due</Typography>
          </Box>
        </Box>

        <Box className={classes.insightItem}>
          <Box
            className={classes.insightItemLink}
            component={
              repPermissions && !repPermissions?.customers?.view
                ? "span"
                : NavLink
            }
            to={"/customers"}
            state={{ withFilter: true }}
            onClick={(e) => {
              if (repPermissions && !repPermissions?.customers?.view) return;

              dispatch(
                customerGetFiltersAction({
                  ...customersDefaultFilters,
                  last_visit: {
                    label: "Last visit",
                    name: currentFilterDate.name,
                    value:
                      typeof currentFilterDate.date === "string"
                        ? upperFirst(lowerCase(currentFilterDate.date))
                        : currentFilterDate.date,
                  },
                })
              );
              if (e.metaKey || e.ctrlKey) return;
              e.stopPropagation();
              e.preventDefault();
              navigate("customers", { state: { withFilter: true } });
            }}
          >
            {loading ? (
              <CircularProgress size={41} />
            ) : (
              <Typography className={notAvailable ? "not-available" : null}>
                {notAvailable ? "Not available" : placesVisited || 0}%
              </Typography>
            )}
            <Typography noWrap>Visited Customers</Typography>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

InsightsWidget.propTypes = {
  insights: object,
  currentFilterDate: any,
  notAvailable: bool,
  isMerchendiser: bool,
  loading: bool,
};

InsightsWidget.defaultProps = {
  insights: {
    paymentsDue: 0,
    ordersToProcess: 0,
    totalPlaces: 0,
    placesVisited: 0,
  },
  notAvailable: false,
  isMerchendiser: false,
  loading: false,
};

export default InsightsWidget;
