import axios from "axios";

export const getCustomerCardsService = (customerId, params) => {
  return axios
    .get(`/stripe/customer-cards/${customerId}`, { params })
    .then((res) => res.data);
};

export const addCustomerCardService = (customerId, data) => {
  return axios
    .post(`/stripe/customer-cards/${customerId}`, data)
    .then((res) => res.data);
};

export const updateCustomerCardService = (customerId, cardId, data) => {
  return axios
    .put(`/stripe/customer-cards/${customerId}/${cardId}`, data)
    .then((res) => res.data);
};

export const deleteCustomerCardService = (customerId, cardId) => {
  return axios
    .delete(`/stripe/customer-cards/${customerId}/${cardId}`)
    .then((res) => res.data);
};

export const getDistributorPaymentCardsService = (headers) => {
  return axios
    .get("/stripe/distributor-payment-cards", headers)
    .then((res) => res.data);
};

export const addDistributorPaymentCardService = (data, headers) => {
  return axios
    .post("/stripe/distributor-payment-cards", data, headers)
    .then((res) => res.data);
};

export const replaceDistributorPaymentCardService = (
  replacedCardId,
  data,
  headers
) => {
  return axios
    .post(
      `/stripe/distributor-payment-cards/${replacedCardId}/replace`,
      data,
      headers
    )
    .then((res) => res.data);
};

export const updateDistributorPaymentCardsService = (cardId, data) => {
  return axios
    .put(`/stripe/distributor-payment-cards/${cardId}`, data)
    .then((res) => res.data);
};

export const deleteDistributorPaymentCardsService = (cardId) => {
  return axios
    .delete(`/stripe/distributor-payment-cards/${cardId}`)
    .then((res) => res.data);
};

export const getDistributorCardsService = () => {
  return axios
    .get("/stripe/distributor-payout-methods/distributor-payout-cards")
    .then((res) => res.data);
};

export const addDistributorCardService = (data) => {
  return axios
    .post("/stripe/distributor-payout-methods/distributor-payout-cards", data)
    .then((res) => res.data);
};

export const updateDistributorCardService = (cardId, data) => {
  return axios
    .put(
      `/stripe/distributor-payout-methods/distributor-payout-cards/${cardId}`,
      data
    )
    .then((res) => res.data);
};

export const deleteDistributorCardService = (cardId) => {
  return axios
    .delete(
      `/stripe/distributor-payout-methods/distributor-payout-cards/${cardId}`
    )
    .then((res) => res.data);
};

export const getBankAccountService = () => {
  return axios
    .get("/stripe/distributor-payout-methods/distributor-bank-accounts")
    .then((res) => res.data);
};

export const getPersonalDetailsService = () => {
  return axios
    .get("/stripe/distributor-payout-methods/distributor-personal-details")
    .then((res) => res.data);
};

export const createPersonalDetailsService = (body) => {
  return axios
    .post(
      "/stripe/distributor-payout-methods/distributor-personal-details",
      body
    )
    .then((res) => res.data);
};

export const updatePersonalDetailsService = (body) => {
  return axios
    .put(
      "/stripe/distributor-payout-methods/distributor-personal-details",
      body
    )
    .then((res) => res.data);
};

export const getBankAccountSettingsService = () => {
  return axios.get("/payouts/settings").then((res) => res.data);
};

export const getSettingsTransactionsService = async (query) => {
  return axios
    .get(`/payouts/transactions`, { params: query })
    .then((res) => res.data);
};

export const setBankAccountService = (data) => {
  return axios
    .post("/stripe/distributor-payout-methods/distributor-bank-accounts", data)
    .then((res) => res.data);
};

export const setBankAccountSettingsService = (data) => {
  return axios.put("/payouts/settings", data).then((res) => res.data);
};

export const getPayoutsService = () => {
  return axios.get("/payouts").then((res) => res.data);
};

export const setDefaultPaymentCardService = (data) => {
  return axios({
    method: "POST",
    url: "/stripe/set-default-payment-card",
    data,
  }).then((res) => res.data);
};

export const getDistributorSubscriptionBillingService = (params) => {
  return axios({
    method: "GET",
    url: "/distributor-subscriptions/usage/billings/by-distributor",
    params,
  }).then((res) => res.data);
};

export const setDistributorUnSubscriptionService = (id, data) => {
  return axios({
    method: "POST",
    url: `/distributor-subscriptions/usage/${id}/unsubscribe-at-end-of-cycle`,
    data,
  }).then((res) => res.data);
};

export const unsubscribeDistributorService = (id) => {
  return axios({
    method: "POST",
    url: `/distributor-subscriptions/usage/${id}/unsubscribe`,
  }).then((res) => res.data);
};

export const reactivateDistributorSubscriptionService = (
  distributorSubscriptionId
) => {
  return axios({
    method: "POST",
    url: `/distributor-subscriptions/usage/${distributorSubscriptionId}/reactivate`,
  }).then((res) => res.data);
};

export const getBillingInvoice = async (invoiceId) => {
  return axios
    .get(`distributor-subscriptions/usage/billings/${invoiceId}/pdf`, {
      responseType: "blob",
    })
    .then((res) => res.data);
};

export const payForDistributorSubscriptionService = (id) => {
  return axios({
    method: "POST",
    url: `/distributor-subscriptions/usage/${id}/pay`,
  }).then((res) => res.data);
};

export const subscribeDistributorService = (data) => {
  return axios({
    method: "POST",
    url: "/distributor-subscriptions/usage/subscribe",
    data: { extraAllowedUsers: 0, ...data },
  }).then((res) => res.data);
};

export const getDistributorSubscriptionsService = ({ params }) => {
  return axios({
    method: "GET",
    url: "/distributor-subscriptions",
    params,
  }).then((res) => res?.data);
};

export const switchDistributorSubscriptionService = ({
  oldSubscriptionId,
  newSubscriptionId,
  extraAllowedUsers,
}) => {
  return axios({
    method: "POST",
    url: `/distributor-subscriptions/usage/${oldSubscriptionId}/switch`,
    data: {
      newSubscriptionId,
      extraAllowedUsers: extraAllowedUsers || 0,
    },
  });
};
