/* eslint-disable react/prop-types */
import React from "react";

export const PlusIcon = ({
  color = "currentColor",
  width = "9.993",
  height = "9.993",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 9.993 9.993"
    >
      <g
        id="Icon_feather-plus"
        data-name="Icon feather-plus"
        transform="translate(-6.75 -6.75)"
      >
        <path
          id="Path_2791"
          data-name="Path 2791"
          d="M18,7.5v8.493"
          transform="translate(-6.253)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_2792"
          data-name="Path 2792"
          d="M7.5,18h8.493"
          transform="translate(0 -6.253)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
};
