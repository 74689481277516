import { useEffect, useMemo, useState } from "react";
import { useWatch } from "react-hook-form";

export const useVariationsTabItemList = ({
  isEdit,
  sortedFieldsList,
  product,
  position,
  control,
  setValue,
  clearErrors,
}) => {
  const formField = useWatch({ control });

  const [showCopyBtn, setShowCopyBtn] = useState(false);

  const indexInSortedList = sortedFieldsList?.findIndex((el) =>
    isEdit
      ? el?.initialId === product?.initialId
      : el?.customId === product?.customId
  );

  const indexInFormFieldList = formField?.variationsFields?.findIndex((el) =>
    isEdit
      ? el?.initialId === product?.initialId
      : el?.customId === product?.customId
  );

  const state = useMemo(() => {
    return {
      sortedFieldsList: {
        current: sortedFieldsList?.[indexInSortedList],
        prev: sortedFieldsList?.[indexInSortedList - 1],
      },
      formField: {
        current: formField?.variationsFields?.[indexInFormFieldList],
        prev: formField?.variationsFields?.[indexInFormFieldList - 1],
      },
    };
  }, [
    formField?.variationsFields,
    indexInSortedList,
    indexInFormFieldList,
    sortedFieldsList,
  ]);

  const productIndex = formField?.variationsFields?.findIndex((el) =>
    isEdit
      ? el?.initialId === state?.sortedFieldsList?.prev?.initialId
      : el?.customId === state?.sortedFieldsList?.prev?.customId
  );

  const prevSortedCustomItem = formField?.variationsFields[productIndex];

  useEffect(() => {
    const itemsPerCase = prevSortedCustomItem?.itemsPerCase;
    const wholesalePrice = prevSortedCustomItem?.wholesalePrice;
    const retailPrice = prevSortedCustomItem?.retailPrice;

    if (
      parseFloat(product?.itemsPerCase) > 0 &&
      parseFloat(product?.wholesalePrice) > 0 &&
      parseFloat(product?.retailPrice) > 0
    )
      return setShowCopyBtn(false);

    if (
      parseFloat(itemsPerCase) > 0 &&
      parseFloat(wholesalePrice) > 0 &&
      parseFloat(retailPrice) > 0
    ) {
      setShowCopyBtn(true);
    } else {
      setShowCopyBtn(false);
    }
  }, [
    prevSortedCustomItem?.itemsPerCase,
    prevSortedCustomItem?.retailPrice,
    prevSortedCustomItem?.wholesalePrice,
    product?.itemsPerCase,
    product?.retailPrice,
    product?.wholesalePrice,
  ]);

  const handleClickCopy = () => {
    clearErrors(`variationsFields[${position}].itemsPerCase`);
    setValue(
      `variationsFields[${position}].itemsPerCase`,
      prevSortedCustomItem?.itemsPerCase
    );
    setValue(
      `variationsFields[${position}].wholesalePrice`,
      prevSortedCustomItem?.wholesalePrice
    );
    setValue(
      `variationsFields[${position}].retailPrice`,
      prevSortedCustomItem?.retailPrice
    );
  };

  return { showCopyBtn, handleClickCopy, formField };
};
