/* eslint-disable react/prop-types */
export const GalleryLocationIcon = ({
  width = 12.222,
  height = 15.943,
  fill,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 12.222 15.943"
    >
      <path
        id="Subtraction_7"
        data-name="Subtraction 7"
        d="M6.111,15.943h0c-.357-.462-.856-1.013-1.435-1.652C2.7,12.112,0,9.129,0,6.389A6.5,6.5,0,0,1,1.786,1.876a5.925,5.925,0,0,1,8.651,0,6.5,6.5,0,0,1,1.786,4.513c0,2.74-2.7,5.724-4.677,7.9-.578.638-1.077,1.189-1.433,1.65Zm0-11.957A2.126,2.126,0,1,0,8.237,6.111,2.128,2.128,0,0,0,6.111,3.985Z"
        fill={fill || "#409a65"}
      />
    </svg>
  );
};
