import {
  getInvitesService,
  getReceivedService,
} from "../../services/order-directs";

export const START_LOADING_ORDER_DIRECTS = "START_LOADING_ORDER_DIRECTS";
export const END_LOADING_ORDER_DIRECTS = "END_LOADING_ORDER_DIRECTS";
export const GET_INVITES_LIST = "GET_INVITES_LIST";
export const GET_INVITES_COUNT = "GET_INVITES_COUNT";
export const GET_RECEIVED_LIST = "GET_RECEIVED_LIST";
export const GET_RECEIVED_COUNT = "GET_RECEIVED_COUNT";
export const GET_NOTIFICATIONS_COUNT = "GET_NOTIFICATIONS_COUNT";
export const GET_NOTIFICATION_RECEIVED_CUSTOMERS =
  "GET_NOTIFICATION_RECEIVED_CUSTOMERS";

const startLoading = () => ({ type: START_LOADING_ORDER_DIRECTS });
const endLoading = () => ({ type: END_LOADING_ORDER_DIRECTS });

export const getNotificationReceivedCustomersAction = (payload) => ({
  type: GET_NOTIFICATION_RECEIVED_CUSTOMERS,
  payload,
});

const getInvitesList = (payload) => ({
  type: GET_INVITES_LIST,
  payload,
});

const getReceivedList = (payload) => ({
  type: GET_RECEIVED_LIST,
  payload,
});

const getInvitesCount = (payload) => ({
  type: GET_INVITES_COUNT,
  payload,
});

const getReceivedCount = (payload) => ({
  type: GET_RECEIVED_COUNT,
  payload,
});

const getNotificationsCount = (payload) => ({
  type: GET_NOTIFICATIONS_COUNT,
  payload,
});

export const getInvitesListAction = (params) => {
  return (dispatch) => {
    dispatch(startLoading());
    getInvitesService(params)
      .then((res) => {
        dispatch(getInvitesList(res?.rows));
        dispatch(getInvitesCount(res?.count));
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.error(err?.message))
      .finally(() => dispatch(endLoading()));
  };
};

export const getReceivedListAction = (params) => {
  return (dispatch) => {
    dispatch(startLoading());
    getReceivedService(params)
      .then((res) => {
        dispatch(getReceivedList(res?.rows));
        dispatch(getReceivedCount(res?.count));
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.error(err?.message))
      .finally(() => dispatch(endLoading()));
  };
};

export const getNotificationsCountAction = (count) => {
  return (dispatch) => {
    dispatch(getNotificationsCount(count));
  };
};
