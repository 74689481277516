import { useMemo } from "react";
import { func, bool, string } from "prop-types";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { LargeCheckbox } from "components/Checkboxes";
import { columnLayoutsSelector } from "redux/selectors/settings";

const selector = createSelector(columnLayoutsSelector, (columnLayouts) => ({
  columnLayouts,
}));

const ReprHeaderComponent = ({ checkAllUsers, allChecked, currentTab }) => {
  const { columnLayouts } = useSelector(selector);
  const rep_all_tab_disable = columnLayouts.rep_all.disable;
  const rep_all_tab = columnLayouts.rep_all.main;

  const columns = useMemo(() => {
    if (rep_all_tab_disable)
      return [
        ...rep_all_tab_disable,
        ...rep_all_tab.filter((col) => col.selected),
      ];

    return [];
  }, [rep_all_tab, rep_all_tab_disable]);

  const columnWidth = {
    Status: 4,
    "Rep Name": 17,
    Role: 10,
    Territory: 12,
    "Work Status": 9,
    "Latest Activity": 15,
    "Visits Today": 6,
    Revenue: 7,
    Orders: 6.5,
    Customers: 6.5,
  };

  const defaultColumnWidth = useMemo(
    () => Object.values(columnWidth).reduce((acc, cur) => (acc += cur), 0),
    // eslint-disable-next-line
    []
  );

  const classes = {
    grid: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
    typography: {
      fontSize: "12px",
      fontWeight: "400",
      color: "#6A6A6A",
      textTransform: "uppercase",
    },
  };

  return currentTab !== "3rd Party" ? (
    <Paper
      sx={{
        height: "38px",
        backgroundColor: "#EEEEEE",
        alignItems: "center",
        borderWidth: "0px 1px 1px 1px",
        borderStyle: "solid",
        borderColor: "#D5D9D9",
      }}
      elevation={0}
      square
      component={Grid}
      container
    >
      <Box width="50px" sx={classes.grid}>
        <LargeCheckbox
          sx={{ padding: 0 }}
          formSx={{ m: 0 }}
          size={22}
          checked={allChecked}
          onChange={checkAllUsers}
        />
      </Box>

      {columns.map((col) => {
        const setWidth = columnWidth?.[col?.name]
          ? `${columnWidth?.[col?.name]}%`
          : `${
              (100 - defaultColumnWidth) /
              rep_all_tab.filter((col) => col.selected).length
            }%`;

        const setPl = (col) => {
          if (col?.name === "Rep Name") return "40px";
          if (col?.name === "Territory") return "30px";
          return 0;
        };

        return (
          <Box
            key={col.id}
            sx={{
              ...classes.typography,
              display: "flex",
              justifyContent: col?.name === "Status" ? "center" : "flex-start",
              alignItems: "center",
              gap: 1,
              pl: setPl(col),
              width: setWidth,
              minWidth: setWidth,
              maxWidth: setWidth,
            }}
          >
            {col.name}
          </Box>
        );
      })}
      <Grid item xs />
    </Paper>
  ) : (
    <Paper
      sx={{
        height: "37px",
        backgroundColor: "#EEEEEE",
        alignItems: "center",
        borderWidth: "0px 1px 1px 1px",
        borderStyle: "solid",
        borderColor: "#D5D9D9",
        pr: "10px",
      }}
      component={Grid}
      elevation={0}
      square
      container
    >
      <Box width="50px" sx={classes.grid}>
        <LargeCheckbox
          sx={{ padding: 0 }}
          formSx={{ m: 0 }}
          size={22}
          checked={allChecked}
          onChange={checkAllUsers}
        />
      </Box>

      <Grid item xs={0.9} sx={{ textAlign: "center" }}>
        <Typography variant="header">STATUS</Typography>
      </Grid>

      <Grid item xs={2.25} pl="40px">
        <Typography variant="header">REP NAME</Typography>
      </Grid>

      <Grid item xs={1.5}>
        <Typography variant="header">ROLE</Typography>
      </Grid>

      <Grid item xs={3}>
        <Typography pl={0.8} variant="header">
          TERRITORY
        </Typography>
      </Grid>

      <Grid item xs={2}>
        <Typography variant="header">CUSTOMER COUNT</Typography>
      </Grid>
    </Paper>
  );
};

ReprHeaderComponent.propTypes = {
  checkAllUsers: func,
  allChecked: bool,
  currentTab: string,
};

ReprHeaderComponent.defaultProps = {
  allChecked: false,
};

export default ReprHeaderComponent;
