import { ExpandMore } from "@mui/icons-material";
import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import PropTypes, {
  string,
  array,
  object,
  bool,
  func,
  number,
  any,
} from "prop-types";
import { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import useStyles from "./styles";
import { FILTER_DATES } from "../../utils/constants";
import { StyledTextField } from "../TextFields/TextFields";
import { ClosePinIcon, HighestOrderIcon, LowestOrderIcon } from "../Icons";
import StyledDateRangePicker from "../StyledDateRangePicker/StyledDateRangePicker";
import { SmallCheckbox } from "../Checkboxes";
import StyledTooltip from "../StyledTooltip";
import { useAdmin } from "helpers/helpers";
import { FILTER_DATE_VALUES } from "Pages/OrdersPage/Orders.constants";

const styles = {
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgb(213,217,217)",
    },
  },
};

const SquareSelect = forwardRef(
  (
    {
      label,
      children,
      fullWidth,
      formSx,
      leftSidedIcon,
      radiused,
      radiusedRight,
      unfocused,
      handleFetch,
      dataLength,
      dataCount,
      height,
      fontSizeLabel,
      labelPaddingTop,
      squareSelectLabelPosition,
      squareSelectLabelColor,
      selectLabelSx,
      ...props
    },
    ref
  ) => {
    const isAdmin = useAdmin();
    const classes = useStyles({
      leftSidedIcon,
      radiused,
      radiusedRight,
      unfocused,
      height,
      labelPaddingTop,
      fontSizeLabel,
      squareSelectLabelPosition,
      squareSelectLabelColor,
    });

    return (
      <FormControl
        size="small"
        fullWidth={fullWidth}
        className={classes.squareSelectForm}
        sx={formSx}
        disabled={isAdmin || props?.disabled}
      >
        <InputLabel
          id={`${label}-select`}
          className={classes.squareSelectLabel}
          sx={selectLabelSx}
        >
          {label}
        </InputLabel>
        <Select
          labelId={`${label}-select`}
          variant="outlined"
          inputProps={{ classes: { icon: classes.squareSelectIcon } }}
          IconComponent={ExpandMore}
          className={classes.squareSelect}
          ref={ref}
          MenuProps={{
            PaperProps: {
              sx: {
                maxWidth: "200px",
                overflowX: "hidden!important",
                maxHeight: "360px",
                overflow: "auto",
                pb: 0,
              },
              onScroll: (e) => {
                if (
                  e.target.scrollTop + 365 < e.target.scrollHeight ||
                  dataLength >= dataCount
                )
                  return;
                handleFetch();
              },
            },
          }}
          {...props}
        >
          {children}
          {dataLength < dataCount && (
            <Box
              display="flex"
              justifyContent="center"
              width="100%"
              height="30px"
            >
              <CircularProgress sx={{ mt: "2px" }} size="20px" />
            </Box>
          )}
        </Select>
      </FormControl>
    );
  }
);

SquareSelect.displayName = "SquareSelect";

SquareSelect.propTypes = {
  label: string,
  children: PropTypes.oneOfType([array, object]),
  labelSx: object,
  fullWidth: bool,
  formSx: object,
  leftSidedIcon: bool,
  radiused: bool,
  radiusedRight: bool,
  unfocused: bool,
  handleFetch: func,
  dataLength: number,
  dataCount: number,
  height: string,
  fontSizeLabel: string,
  labelPaddingTop: number,
  disabled: bool,
  squareSelectLabelPosition: string,
  squareSelectLabelColor: string,
  selectLabelSx: object,
};

SquareSelect.defaultProps = {
  label: "",
  fullWidth: false,
  children: null,
  leftSidedIcon: false,
  radiused: false,
  radiusedRight: false,
  unfocused: false,
  dataLength: 0,
  handleFetch: () => {},
  dataCount: 0,
};

const StyledSelect = forwardRef(
  (
    {
      leftSidedIcon,
      fill,
      error,
      label,
      children,
      labelSx,
      fullWidth,
      formSx,
      fontSize,
      color,
      height,
      dataLength,
      dataCount,
      handleFetch,
      labelProps,
      noErrorMessage,
      border,
      borderRadius,
      adminIsAllowed,
      IconComponent,
      PAPER_WIDTH,
      iconStyles,
      PaperPropsSx,
      doNotApplyDisabledForAdmin,
      ...props
    },
    ref
  ) => {
    const classes = useStyles({
      leftSidedIcon,
      fill,
      fontSize,
      color,
      height,
      labelSx,
      border,
      borderRadius,
      iconStyles,
    });
    const PAPER_HEIGHT = 315;
    const isAdmin = useAdmin(adminIsAllowed);

    const disabled = useMemo(() => {
      if (isAdmin) {
        return !doNotApplyDisabledForAdmin;
      }

      return props?.disabled;
    }, [doNotApplyDisabledForAdmin, isAdmin, props?.disabled]);

    return (
      <FormControl
        size="small"
        fullWidth={fullWidth}
        className={classes.inputForm}
        sx={{ ...styles, ...formSx }}
        error={!!error}
        disabled={disabled}
      >
        <InputLabel
          id={`${label}-select`}
          sx={labelSx}
          className={classes.label}
          {...labelProps}
        >
          {label}
        </InputLabel>
        <Select
          labelId={`${label}-select`}
          label={label}
          variant="outlined"
          inputProps={{
            classes: {
              icon: classes.icon,
            },
          }}
          IconComponent={IconComponent ? IconComponent : ExpandMore}
          className={classes.select}
          ref={ref}
          MenuProps={{
            PaperProps: {
              sx: {
                maxHeight: `${PAPER_HEIGHT}px`,
                overflow: "auto",
                pb: 0,
                width: `${PAPER_WIDTH}`,

                "& .MuiMenu-list": {
                  padding: "7px 0px",
                },

                scrollbarWidth: "6px",
                scrollbarColor: "rgba(0, 0, 0, 0.35) rgba(0, 0, 0, 0.1)",

                "::-webkit-scrollbar": {
                  width: "6px",
                  borderRadius: "7px",
                  backgroundColor: "rgba(0, 0, 0, 0.1)",
                },
                "::-webkit-scrollbar-thumb": {
                  borderRadius: "7px",
                  backgroundColor: "rgba(0, 0, 0, 0.35)",
                },
                ...PaperPropsSx,
              },
              onScroll: (e) => {
                if (
                  e.target.scrollTop + PAPER_HEIGHT <
                    e.target.scrollHeight - 5 ||
                  dataLength >= dataCount
                )
                  return;
                handleFetch();
              },
            },
          }}
          {...props}
        >
          {children}
          {dataLength < dataCount && (
            <Box
              display="flex"
              justifyContent="center"
              width="100%"
              height="30px"
            >
              <CircularProgress sx={{ mt: "2px" }} size="20px" />
            </Box>
          )}
        </Select>
        {!!error && !noErrorMessage && typeof error === "string" && (
          <FormHelperText
            sx={{ position: "absolute", bottom: -20, left: 0, margin: 0 }}
          >
            {error}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
);

StyledSelect.propTypes = {
  label: string,
  fill: string,
  children: PropTypes.oneOfType([array, object]),
  labelSx: object,
  fullWidth: bool,
  formSx: object,
  error: PropTypes.oneOfType([string, bool]),
  leftSidedIcon: bool,
  fontSize: string,
  color: string,
  height: string,
  dataLength: number,
  dataCount: number,
  handleFetch: func,
  labelProps: object,
  noErrorMessage: bool,
  border: string,
  borderRadius: string,
  cursor: string,
  disabled: bool,
  adminIsAllowed: bool,
  IconComponent: any,
  iconStyles: object,
  PAPER_WIDTH: string,
  PaperPropsSx: object,
  doNotApplyDisabledForAdmin: bool,
};

StyledSelect.defaultProps = {
  label: "",
  fill: "",
  fullWidth: false,
  children: null,
  error: null,
  leftSidedIcon: false,
  fontSize: "12px",
  height: "32px",
  color: "#1C1C19",
  dataLength: 0,
  dataCount: 0,
  handleFetch: () => {},
  noErrorMessage: false,
  borderRadius: "4px",
  adminIsAllowed: false,
  doNotApplyDisabledForAdmin: false,
};

StyledSelect.displayName = "StyledSelect";

const StyledMultipleSelect = forwardRef(
  (
    {
      leftSidedIcon,
      fill,
      error,
      label,
      children,
      labelSx,
      fullWidth,
      formSx,
      fontSize,
      color,
      height,
      dataLength,
      dataCount,
      handleFetch,
      labelProps,
      noErrorMessage,
      border,
      borderRadius,
      adminIsAllowed,
      IconComponent,
      iconStyles,
      multiple,
      ...props
    },
    ref
  ) => {
    const classes = useStyles({
      leftSidedIcon,
      fill,
      fontSize,
      color,
      height,
      labelSx,
      border,
      borderRadius,
      iconStyles,
    });
    const PAPER_HEIGHT = 315;
    const isAdmin = useAdmin(adminIsAllowed);
    return (
      <FormControl
        size="small"
        fullWidth={fullWidth}
        sx={{ ...styles, ...formSx }}
        className={classes.inputForm}
        error={!!error}
        disabled={isAdmin || props?.disabled}
      >
        <InputLabel
          id={`${label}-select`}
          sx={labelSx}
          className={classes.label}
          {...labelProps}
        >
          {label}
        </InputLabel>
        <Select
          labelId={`${label}-select`}
          label={label}
          multiple={multiple}
          variant="outlined"
          inputProps={{
            classes: {
              icon: classes.icon,
            },
          }}
          IconComponent={IconComponent ? IconComponent : ExpandMore}
          className={classes.select}
          ref={ref}
          MenuProps={{
            PaperProps: {
              sx: {
                maxHeight: `${PAPER_HEIGHT}px`,
                overflow: "auto",
                pb: 0,
              },
              onScroll: (e) => {
                if (
                  e.target.scrollTop + PAPER_HEIGHT <
                    e.target.scrollHeight - 5 ||
                  dataLength >= dataCount
                )
                  return;
                handleFetch();
              },
            },
          }}
          {...props}
        >
          {children}
          {dataLength < dataCount && (
            <Box
              display="flex"
              justifyContent="center"
              width="100%"
              height="30px"
            >
              <CircularProgress sx={{ mt: "2px" }} size="20px" />
            </Box>
          )}
        </Select>
        {!!error && !noErrorMessage && typeof error === "string" && (
          <FormHelperText
            sx={{ position: "absolute", bottom: -20, left: 0, margin: 0 }}
          >
            {error}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
);

StyledMultipleSelect.propTypes = {
  multiple: bool,
  label: string,
  fill: string,
  children: PropTypes.oneOfType([array, object]),
  labelSx: object,
  fullWidth: bool,
  formSx: object,
  error: PropTypes.oneOfType([string, bool]),
  leftSidedIcon: bool,
  fontSize: string,
  color: string,
  height: string,
  dataLength: number,
  dataCount: number,
  handleFetch: func,
  labelProps: object,
  noErrorMessage: bool,
  border: string,
  borderRadius: string,
  cursor: string,
  disabled: bool,
  adminIsAllowed: bool,
  IconComponent: any,
  iconStyles: object,
};

StyledMultipleSelect.defaultProps = {
  multiple: false,
  label: "",
  fill: "",
  fullWidth: false,
  children: null,
  error: null,
  leftSidedIcon: false,
  fontSize: "12px",
  height: "32px",
  color: "#1C1C19",
  dataLength: 0,
  dataCount: 0,
  handleFetch: () => {},
  noErrorMessage: false,
  borderRadius: "4px",
  adminIsAllowed: false,
};

StyledMultipleSelect.displayName = "StyledMultipleSelect";

const SearchSelect = forwardRef(
  (
    {
      leftSidedIcon,
      error,
      label,
      children,
      labelSx,
      fullWidth,
      formSx,
      handleDelete,
      multiselect,
      chipSx,
      PaperPropsSx,
      ...props
    },
    ref
  ) => {
    const isAdmin = useAdmin();
    const classes = useStyles({ leftSidedIcon });
    return (
      <FormControl
        size="small"
        fullWidth={fullWidth}
        sx={{ ...formSx, ...styles }}
        className={classes.inputForm}
        error={!!error}
        disabled={isAdmin || props?.disabled}
      >
        {!props.value?.length && (
          <InputLabel
            id={`${label}-select`}
            sx={labelSx}
            disableAnimation
            shrink={false}
            className={classes.label}
          >
            {label}
          </InputLabel>
        )}
        <Select
          labelId={`${label}-select`}
          label={label}
          variant="outlined"
          notched={false}
          inputProps={{ classes: { icon: classes.icon } }}
          IconComponent={ExpandMore}
          className={classes.select}
          multiple={multiselect}
          MenuProps={{
            PaperProps: {
              sx: {
                ...PaperPropsSx,
              },
            },
          }}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
              {multiselect ? (
                selected.map((value) => (
                  <Chip
                    key={value.id}
                    sx={{
                      borderRadius: "4px",
                      backgroundColor: "#F8F8FA",
                      ...chipSx,
                    }}
                    size="small"
                    label={
                      <Typography
                        sx={{ fontSize: "12px" }}
                        color="groundLighter.main"
                      >
                        {value.label || value.name}
                      </Typography>
                    }
                    deleteIcon={<CloseIcon />}
                    onMouseDown={(e) => e.stopPropagation()}
                    onDelete={() => handleDelete(value.id)}
                  />
                ))
              ) : (
                <Chip
                  sx={{
                    borderRadius: "4px",
                    backgroundColor: "#F8F8FA",
                  }}
                  size="small"
                  label={
                    <Typography
                      sx={{ fontSize: "12px" }}
                      color="groundLighter.main"
                    >
                      {selected.label || selected}
                    </Typography>
                  }
                  deleteIcon={<CloseIcon />}
                  onMouseDown={(e) => e.stopPropagation()}
                  onDelete={handleDelete}
                />
              )}
            </Box>
          )}
          ref={ref}
          {...props}
        >
          {children}
        </Select>
        {!!error && (
          <FormHelperText
            sx={{ position: "absolute", bottom: -20, left: 0, margin: 0 }}
          >
            {error}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
);
SearchSelect.displayName = "SearchSelect";
SearchSelect.propTypes = {
  label: string,
  children: array,
  labelSx: object,
  fullWidth: bool,
  formSx: object,
  error: string,
  leftSidedIcon: bool,
  handleDelete: func,
  multiselect: bool,
  chipSx: object,
  value: any,
  disabled: bool,
  PaperPropsSx: object,
};
SearchSelect.defaultTypes = {
  label: "",
  fullWidth: false,
  children: null,
  error: null,
  leftSidedIcon: false,
  multiselect: false,
};

const SelectDatePicker = forwardRef(
  (
    {
      handleDateInput,
      handleClearValue,
      isAll,
      height,
      fontSizeLabel,
      datesList,
      labelPaddingTop,
      textfieldInputSx,
      noSquare,
      isVisit,
      isOrder,
      adminIsAllowed,
      isCustomFulfillmentStatus,
      isCustomDate,
      hideCustomDate,
      moreLessFirst,
      showDivider,
      doNotApplyDisabledForAdmin,
      ...props
    },
    ref
  ) => {
    const isAdmin = useAdmin(adminIsAllowed);
    const [anchorEl, setAnchorEl] = useState(null);
    const dateRef = useRef(null);

    const disabled = useMemo(() => {
      if (isAdmin) {
        return !doNotApplyDisabledForAdmin;
      }

      return props?.disabled;
    }, [doNotApplyDisabledForAdmin, isAdmin, props?.disabled]);

    return (
      <>
        <StyledDateRangePicker
          anchorEl={anchorEl}
          handleClose={() => setAnchorEl(null)}
          handleDateInput={handleDateInput}
          date={props.value}
          disabled={disabled}
          isCustomDate={isCustomDate}
        />
        {typeof props.value === "string" ? (
          noSquare ? (
            <StyledSelect
              adminIsAllowed
              sx={{
                "&.Mui-focused": {
                  filter: "drop-shadow(0.1px 0.2px 2px #47A06D)",
                  "& > fieldset.MuiOutlinedInput-notchedOutline": {
                    borderWidth: "0.3px",
                  },
                },
              }}
              ref={dateRef}
              {...props}
              disabled={disabled}
              height={height}
            >
              {isVisit && moreLessFirst && (
                <MenuItem value="more_than" sx={{ gap: "8px" }}>
                  <span style={{ width: "65px" }}>More Than</span>{" "}
                  <LowestOrderIcon />
                </MenuItem>
              )}
              {isVisit && moreLessFirst && (
                <MenuItem value="less_than" sx={{ gap: "8px" }}>
                  <span style={{ width: "65px" }}>Less Than</span>{" "}
                  <HighestOrderIcon />
                </MenuItem>
              )}
              {isVisit && moreLessFirst && <Divider />}

              {isVisit && !isOrder && (
                <MenuItem value="never">Not visited</MenuItem>
              )}
              {isOrder && <MenuItem value="never">Not ordered</MenuItem>}
              {isVisit && !moreLessFirst && <Divider />}
              {isAll && <MenuItem value="">All</MenuItem>}
              {(datesList?.length
                ? datesList
                : !isCustomFulfillmentStatus
                ? FILTER_DATES
                : FILTER_DATE_VALUES
              ).map((el) => (
                <MenuItem
                  key={el?.name}
                  value={el?.name}
                  sx={{
                    borderBottom:
                      el.withDivider && "1px solid rgba(0, 0, 0, 0.12)",
                  }}
                >
                  {el?.name}
                </MenuItem>
              ))}
              {hideCustomDate ? null : (
                <MenuItem
                  onClick={() => setAnchorEl(dateRef.current)}
                  sx={{
                    borderTop: showDivider && "1px solid rgba(0, 0, 0, 0.12)",
                  }}
                >
                  Custom
                </MenuItem>
              )}

              {isVisit && !moreLessFirst && <Divider />}
              {isVisit && !moreLessFirst && (
                <MenuItem value="more_than" sx={{ gap: "8px" }}>
                  <span style={{ width: "65px" }}>More Than</span>{" "}
                  <LowestOrderIcon />
                </MenuItem>
              )}
              {isVisit && !moreLessFirst && (
                <MenuItem value="less_than" sx={{ gap: "8px" }}>
                  <span style={{ width: "65px" }}>Less Than</span>{" "}
                  <HighestOrderIcon />
                </MenuItem>
              )}
            </StyledSelect>
          ) : (
            <SquareSelect
              ref={dateRef}
              fontSizeLabel={fontSizeLabel}
              height={height}
              labelPaddingTop={labelPaddingTop}
              {...props}
              disabled={disabled}
            >
              {isAll && <MenuItem value="">All</MenuItem>}
              {(datesList?.length ? datesList : FILTER_DATES).map((el) => (
                <MenuItem key={el?.name} value={el?.name}>
                  {el?.name}
                </MenuItem>
              ))}
              <MenuItem value="All time">All time</MenuItem>
              <MenuItem onClick={() => setAnchorEl(dateRef.current)}>
                Custom
              </MenuItem>
            </SquareSelect>
          )
        ) : (
          <StyledTextField
            InputProps={{
              sx: {
                height: height || "39px",
                color: "#707070",
                fontSize: "11px",
                fontWeight: 400,
                backgroundColor: "#fff",
                pr: "10px",
                ...textfieldInputSx,
              },
              endAdornment: (
                <InputAdornment position="end" sx={{ zIndex: 10 }}>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClearValue();
                    }}
                  >
                    <ClosePinIcon />
                  </IconButton>
                </InputAdornment>
              ),
              startAdornment: (
                <InputAdornment position="start" sx={{ height: "100%" }}>
                  <Typography
                    whiteSpace="nowrap"
                    fontSize="11px"
                    color="#707070"
                    mr="11px"
                  >
                    Custom date
                  </Typography>
                  <Divider orientation="vertical" />
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              sx: { backgroundColor: "#fff" },
            }}
            onClick={(e) => setAnchorEl(e.target)}
            ref={ref}
            {...props}
            disabled={disabled}
            value={
              props.value[1]
                ? ` ${props.value[0]?.format(
                    "MM/DD/YYYY"
                  )} - ${props.value[1]?.format("MM/DD/YYYY")}`
                : props.value[0]?.format("MM/DD/YYYY")
            }
            label=""
            radiused="false"
          />
        )}
      </>
    );
  }
);

SelectDatePicker.displayName = "SelectDatePicker";

SelectDatePicker.defaultProps = {
  isVisit: false,
  adminIsAllowed: false,
  hideCustomDate: false,
  moreLessFirst: false,
  doNotApplyDisabledForAdmin: false,
};

SelectDatePicker.propTypes = {
  value: PropTypes.oneOfType([array, string]),
  height: string,
  fontSizeLabel: string,
  datesList: array,
  handleDateInput: func,
  handleClearValue: func,
  isAll: bool,
  labelPaddingTop: number,
  textfieldInputSx: object,
  noSquare: bool,
  isVisit: bool,
  isOrder: bool,
  disabled: bool,
  adminIsAllowed: bool,
  isCustomFulfillmentStatus: bool,
  isCustomDate: bool,
  hideCustomDate: bool,
  moreLessFirst: bool,
  showDivider: bool,
  doNotApplyDisabledForAdmin: bool,
};

const AssignedRepsSelect = forwardRef(
  (
    {
      repsList,
      handleDelete,
      value,
      setValue,
      fieldName,
      repsListCheck,
      label,
      sx,
      error,
      ...props
    },
    ref
  ) => {
    const isAdmin = useAdmin();
    const [openTooltip, setOpenTooltip] = useState(false);
    const [mountedStatus, setMountedStatus] = useState(false);

    useEffect(() => {
      if (repsListCheck.length === 1) setMountedStatus(true);
    }, [repsListCheck.length]);

    useEffect(() => {
      if (mountedStatus && repsListCheck.length === 1) {
        setValue(fieldName, repsList, { shouldDirty: true });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mountedStatus, repsListCheck, fieldName]);
    return (
      <StyledTooltip
        key={1}
        title="No reps found in the system"
        placement="top-start"
        arrow
        open={openTooltip && !repsList?.length}
        onOpen={() => setOpenTooltip(true)}
        onClose={() => setOpenTooltip(false)}
      >
        <Box sx={sx}>
          <SearchSelect
            fullWidth
            ref={ref}
            disabled={isAdmin || !repsList.length}
            label={label}
            multiselect
            chipSx={{
              height: "20px",
              border: "0.5px solid #D4D4D4",
              borderRadius: "10px",
              backgroundColor: "#FAFAFB",
            }}
            handleDelete={(e) => {
              if (isAdmin) return;
              handleDelete(e);
            }}
            onChange={(e) => {
              const val = e.target.value;
              if (val.length < value.length)
                return setValue(fieldName, val, {
                  shouldDirty: true,
                });

              const newRep = val[val.length - 1];
              if (value.some((rep) => rep.id === newRep.id))
                return setValue(
                  fieldName,
                  value.filter((rep) => rep.id !== newRep.id),
                  { shouldDirty: true }
                );
              setValue(fieldName, val, {
                shouldDirty: true,
              });
            }}
            value={value}
            error={error?.message || ""}
            {...props}
          >
            {repsList.map((rep) => {
              return (
                <MenuItem key={rep.id} value={rep}>
                  <SmallCheckbox
                    checked={value.some((listRep) => listRep.id === rep.id)}
                  />
                  <ListItemText primary={rep.name} />
                </MenuItem>
              );
            })}
          </SearchSelect>
        </Box>
      </StyledTooltip>
    );
  }
);

AssignedRepsSelect.displayName = "AssignedRepsSelect";

AssignedRepsSelect.propTypes = {
  repsList: array,
  handleDelete: func,
  value: array,
  repsListCheck: array,
  setValue: func,
  fieldName: string,
  error: any,
  label: string,
  sx: object,
};

AssignedRepsSelect.defaultProps = {
  repsList: [],
  value: [],
  setValue: () => {},
  handleDelete: () => {},
  fieldName: "",
  label: "Representatives",
  sx: {},
};

export {
  StyledSelect,
  SquareSelect,
  SearchSelect,
  SelectDatePicker,
  AssignedRepsSelect,
  StyledMultipleSelect,
};
