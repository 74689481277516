export const DiscountInfoIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32.5"
      height="32.5"
      viewBox="0 0 32.5 32.5"
    >
      <g
        id="Group_5684"
        data-name="Group 5684"
        transform="translate(0.25 0.25)"
      >
        <circle
          id="Ellipse_916"
          data-name="Ellipse 916"
          cx="15.25"
          cy="15.25"
          r="15.25"
          transform="translate(0.75 0.75)"
          fill="none"
          stroke="#6a6a6a"
          strokeWidth="2"
        />
        <circle
          id="Ellipse_917"
          data-name="Ellipse 917"
          cx="2"
          cy="2"
          r="2"
          transform="translate(9.75 8.75)"
          fill="none"
          stroke="#6a6a6a"
          strokeWidth="2"
        />
        <circle
          id="Ellipse_918"
          data-name="Ellipse 918"
          cx="1.875"
          cy="1.875"
          r="1.875"
          transform="translate(18.75 18.75)"
          fill="none"
          stroke="#6a6a6a"
          strokeWidth="2"
        />
        <path
          id="Path_3856"
          data-name="Path 3856"
          d="M11,21.5,22.5,10"
          fill="none"
          stroke="#6a6a6a"
          strokeLinecap="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};
