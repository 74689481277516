import { useState } from "react";
import { object, func } from "prop-types";
import { Box, Button, Typography } from "@mui/material";
import StyledAvatar from "../../../../components/StyledAvatar/StyledAvatar";
import { photoUrl } from "../../../../helpers/helpers";
import { useWatch } from "react-hook-form";
import StyledButton from "../../../../components/StyledButton";
import UploadFile from "../../../../components/UploadFile";
import {
  MAX_UPLOAD_FILE_RESOLUTION_DISTRIBUTIVE_MP,
  MAX_UPLOAD_FILE_SIZE_DISTRIBUTIVE_MB,
} from "utils/constants";
import { error } from "utils/notifications";

export const LogoComponent = ({ control, setValue }) => {
  const formField = useWatch({ control });

  const [preparingPhoto, setPreparingPhoto] = useState(false);

  const handleUploadChange = (data) => {
    if (data?.fileName) return setValue("profilePhoto", data);

    setValue("profilePhoto", null);
  };

  return (
    <Box display="flex" alignItems="center">
      <StyledAvatar
        sx={{
          height: "61px",
          width: "61px",
          border: "1px solid #CBCBCB",
        }}
        src={photoUrl(formField.profilePhoto?.fileName)}
        stringStyle={{ fontSize: "25px" }}
        name={formField.businessName}
      />
      <Box>
        <Box display="flex" alignItems="center">
          <UploadFile
            label="Upload new logo"
            maxUploadFileSizeMb={{
              count: MAX_UPLOAD_FILE_SIZE_DISTRIBUTIVE_MB,
              callback: (fileName) => {
                error(
                  <>
                    <Typography component="span" fontSize={12}>
                      Photo upload failed
                      <br />
                      {fileName}
                      <br />
                      Exceeds maximum file size of{" "}
                      {MAX_UPLOAD_FILE_SIZE_DISTRIBUTIVE_MB} MB
                    </Typography>
                    <br />
                  </>
                );
              },
            }}
            maxUploadFileResolutionMp={{
              count: MAX_UPLOAD_FILE_RESOLUTION_DISTRIBUTIVE_MP,
              callback: (fileName) => {
                error(
                  <>
                    <Typography component="span" fontSize={12}>
                      Photo upload failed
                      <br />
                      {fileName}
                      <br />
                      Exceeds maximum image resolution of{" "}
                      {MAX_UPLOAD_FILE_RESOLUTION_DISTRIBUTIVE_MP} MP
                    </Typography>
                    <br />
                  </>
                );
              },
            }}
            accept="image/png, image/gif, image/jpeg"
            wrapperProps={{ sx: { p: "5px" } }}
            onChange={handleUploadChange}
            Wrapper={Button}
            path="users/distributors/profile-photo"
            withCropper
            dialogSize="small"
            settingsCropper={{ cropShape: "round" }}
            uploadData={{
              distributorId: formField?.distributor?.id,
              type: "PROFILE_DISTRIBUTOR",
              setGroupContentId: (groupContentId) => {
                if (groupContentId) setValue("groupContentId", groupContentId);
              },
            }}
            token={formField?.token}
            {...{ preparingPhoto, setPreparingPhoto }}
          />

          {formField.profilePhoto && (
            <>
              <Typography color="#707070">•</Typography>
              <StyledButton
                label="Remove logo"
                fontSize="15px"
                sx={{ p: "5px" }}
                onClick={() => handleUploadChange()}
              />
            </>
          )}
        </Box>
        <Typography ml="5px" fontSize="15px" color="#707070" mt="-3px">
          Logos help recognize your company
        </Typography>
      </Box>
    </Box>
  );
};

LogoComponent.propTypes = { control: object, setValue: func };
