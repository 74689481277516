import { useMemo, useRef, useState } from "react";
import { func, string } from "prop-types";
import { Box, Chip, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useStyles from "./styles";
import { FilterSearchTextField } from "../../../../../../../components/TextFields/TextFields";
import StyledButton from "../../../../../../../components/StyledButton";
import { defaultFilters, FILTER_SWITCHES } from "./constants";
import { normalizeSnakeCaseString } from "../../../../../../../helpers/helpers";
import { FilterIcon } from "../../../../../../../components/Icons";
import FilterMenu from "../../../../../../../components/FilterMenu";
import { useSelector } from "react-redux";
import { UNCATEGORIZED_TERRITORY } from "../../../../../../../utils/constants";

export const NewTransferFilter = ({
  handleTerritoryFilter,
  searchInput,
  setSearchInput,
}) => {
  const classes = useStyles();
  const territoryList = useSelector(({ territory }) => territory.territoryList);
  const filterAnchor = useRef();

  const [filterFields, setFilterFields] = useState(defaultFilters);
  const [switches, setSwitches] = useState(FILTER_SWITCHES);
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);

  const filterChipKeys = useMemo(
    () => Object.keys(filterFields).filter((key) => filterFields[key]),
    [filterFields]
  );

  const handleDeleteFilter = (key) => {
    const newState = switches;
    const index = switches.findIndex((s) => s.value === key);
    const insert = {
      ...switches[index],
      checked: false,
    };
    newState.splice(index, 1, insert);
    setSwitches([...newState]);
    setFilterFields((prev) => {
      return { ...prev, [key]: "" };
    });
    handleTerritoryFilter("");
  };

  const handleApplyFilter = (newSwitches, newFields) => {
    setFilterMenuOpen(false);
    setSwitches([...newSwitches]);
    setFilterFields({ ...newFields });
    handleTerritoryFilter(newFields?.territory_id?.value);
  };

  const getChipLabel = (key) => {
    const icon = filterFields[key]?.icon;
    return (
      <Box display="flex" alignItems="center" gap="6px">
        <Typography
          sx={{ fontSize: "13px", fontWeight: 500 }}
          color="groundLighter.main"
        >
          {normalizeSnakeCaseString(filterFields[key]?.label || key)}:{" "}
          {!icon && (
            <span style={{ color: "#5F6368", marginTop: !!icon && "5px" }}>
              {normalizeSnakeCaseString(
                filterFields[key]?.name || filterFields[key]
              )}
            </span>
          )}
        </Typography>
        {icon}
      </Box>
    );
  };

  const [territoriesState, setTerritoriesState] = useState({ search: "" });
  let filteredTerritories = territoryList.filter((territory) =>
    territory.name.toLowerCase().includes(territoriesState.search.toLowerCase())
  );

  return (
    <Box px="32px" mb="10px">
      <Box className={classes.filterWrapper}>
        <FilterSearchTextField
          formSx={{ minWidth: "380px", overflow: "hidden" }}
          placeholderWidth="600px"
          placeholder={"Search reps by name, email or ID"}
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          onKeyPress={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
          fullWidth
          customAdornment={
            <Box
              display="flex"
              alignItems="center"
              overflow="hidden"
              width="100%"
              justifyContent="flex-end"
            >
              <Box
                display="flex"
                gap="5px"
                overflow="auto"
                sx={{
                  py: "4px",
                  "&::-webkit-scrollbar": {
                    height: "2px",
                  },
                }}
              >
                {filterChipKeys.map((key) => (
                  <Chip
                    key={key}
                    className={classes.filterChip}
                    size="small"
                    label={getChipLabel(key)}
                    deleteIcon={
                      <CloseIcon size="15" style={{ fill: "#707070" }} />
                    }
                    onMouseDown={(e) => e.stopPropagation()}
                    onDelete={() => handleDeleteFilter(key)}
                  />
                ))}
              </Box>
            </Box>
          }
        />
        <StyledButton
          label="Filter"
          startIcon={
            filterChipKeys.length ? (
              <Typography
                fontSize="9px!important"
                color="#ffffff"
                backgroundColor="#47A06D"
                borderRadius="50%"
                width="15px"
                height="15px"
              >
                {filterChipKeys.length}
              </Typography>
            ) : (
              <FilterIcon />
            )
          }
          variant="outlined"
          ref={filterAnchor}
          color="edit"
          sx={{
            height: "39px",
            width: "100%",
            maxWidth: "74px",
            border: "0.5px solid #D5D9D9",
            "& .MuiButton-startIcon": {
              ml: 0,
            },
          }}
          fontSize="15px"
          onClick={() => setFilterMenuOpen(true)}
        />
        <FilterMenu
          anchorEl={filterAnchor.current}
          open={filterMenuOpen}
          onClose={() => setFilterMenuOpen(false)}
          filterFields={filterFields}
          setFilterFields={setFilterFields}
          switches={switches}
          handleApplyFilter={handleApplyFilter}
          selectMatchData={[
            {
              id: "territory_id",
              childrenList: [
                {
                  id: UNCATEGORIZED_TERRITORY.id,
                  name: UNCATEGORIZED_TERRITORY.name,
                },
                ...filteredTerritories,
              ],
              setParams: setTerritoriesState,
              label: "",
            },
          ]}
        />
      </Box>
    </Box>
  );
};

NewTransferFilter.propTypes = {
  handleTerritoryFilter: func,
  searchInput: string,
  setSearchInput: func,
};
