import { object, func } from "prop-types";
import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import { StyledTimePicker } from "components";
import moment from "moment-timezone";
import AdapterMoment from "@mui/lab/AdapterMoment";

export const ReceivingHoursItem = ({
  day,
  handleSetDay,
  handleShowDatePicker,
}) => {
  return (
    <Grid xs={12} item container>
      <Grid color="#979797" xs={5} item>
        <FormControlLabel
          control={
            <Checkbox
              checked={!!day?.value}
              onChange={() => {
                if (!day?.value) {
                  const timeIn = moment().utc();
                  const timeOut = moment().utc();

                  timeIn.set({
                    hour: 8,
                    minute: 0,
                    second: 0,
                  });
                  timeOut.set({
                    hour: 17,
                    minute: 0,
                    second: 0,
                  });

                  handleShowDatePicker(
                    day?.name,
                    timeIn.toISOString(),
                    timeOut.toISOString()
                  );
                } else {
                  handleShowDatePicker(day?.name);
                }
              }}
            />
          }
          label={day?.name}
        />
      </Grid>

      {day?.value ? (
        <Grid
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
          xs={7}
          item
        >
          <StyledTimePicker
            adminIsAllowed
            timezone="UTC"
            dateLibInstance={moment}
            dateAdapter={AdapterMoment}
            textFieldProps={{
              fullWidth: false,
              formSx: {
                backgroundColor: "#ffffff",
                width: "126px",
                color: "#1C1C19",
                fontSize: 16,
              },
            }}
            am={true}
            value={moment(day?.value?.clockInAt).utc()}
            onChange={(time) => {
              const isoTime = new Date(time).toISOString();
              handleSetDay(day?.name, "clockInAt", isoTime);
            }}
          />
          <StyledTimePicker
            adminIsAllowed
            timezone="UTC"
            dateLibInstance={moment}
            dateAdapter={AdapterMoment}
            textFieldProps={{
              fullWidth: false,
              formSx: {
                backgroundColor: "#ffffff",
                width: "126px",
                color: "#1C1C19",
                fontSize: 16,
                ml: 2,
              },
            }}
            value={moment(day?.value?.clockOutAt).utc()}
            onChange={(time) => {
              const isoTime = new Date(time).toISOString();
              handleSetDay(day?.name, "clockOutAt", isoTime);
            }}
          />
        </Grid>
      ) : null}
    </Grid>
  );
};

ReceivingHoursItem.propTypes = {
  day: object,
  handleShowDatePicker: func,
  handleSetDay: func,
};
ReceivingHoursItem.defaultProps = {
  day: {
    name: "",
    value: "",
  },
  handleShowDatePicker: () => {},
  handleSetDay: () => {},
};
