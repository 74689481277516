import { useCallback, useState, useMemo, useEffect } from "react";
import { number, array, func, object, bool } from "prop-types";
import { Stack, Box, Typography } from "@mui/material";
import ParentItem from "./ParentItem";
import CustomersGroupsHeader from "./CustomersGroupsHeader";
import TabHeaderComponent from "../TabSortedComponent/TabHeaderComponent";
import TabListComponent from "../TabSortedComponent/TabListComponent";
import { createSelector } from "reselect";
import { useSelector, useDispatch } from "react-redux";
import {
  customersGetParamsAction,
  getCustomersAction,
} from "redux/actions/customers.js";
import {
  customersInactiveListSelector,
  customersListSelector,
  customersShowInactiveSelector,
  customersCountSelector,
  customersLoadingSelector,
  customersInactiveCountSelector,
  customersExistSelector,
  customersGetParamsSelector,
} from "redux/selectors/customers";
import { CircularProgress } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import {
  InfiniteLoadMoreBtn,
  InfiniteLoaderWrapper,
  InfiniteScrollWrapper,
  EmptyScreen,
  TableHeaderControlPanel,
} from "components";
import {
  flatCountSelector,
  groupsGetParamsSelector,
  parentCustomersLoadingSelector,
} from "redux/selectors/parentCustomers";
import { useAdmin } from "helpers/helpers";

const selector = createSelector(
  customersListSelector,
  customersInactiveListSelector,
  customersShowInactiveSelector,
  customersCountSelector,
  customersInactiveCountSelector,
  customersLoadingSelector,
  customersExistSelector,
  customersGetParamsSelector,
  parentCustomersLoadingSelector,
  groupsGetParamsSelector,
  flatCountSelector,
  (
    customersActiveList,
    customersInactiveList,
    showInactive,
    customersActiveCount,
    customersInactiveCount,
    customersLoading,
    customersExist,
    customersGetParams,
    loadingParents,
    groupsGetParams,
    countParents
  ) => ({
    customersActiveList,
    customersInactiveList,
    showInactive,
    customersActiveCount,
    customersInactiveCount,
    customersLoading,
    customersExist,
    customersGetParams,
    loadingParents,
    groupsGetParams,
    countParents,
  })
);

const FlatView = ({
  handleCheckCustomer,
  checkedGroups,
  handleCheckGroup,
  checkAllGroups,
  handleDeleteGroup,
  handleOpenConfirmDialog,
  handleOpenCustomerProfile,
  handleRedirectMissingInfo,
  handleChangeTerritory,
  handleChangeGroup,
  handleChangeParentGroup,
  handleChangePaymentTerm,
  handleDeleteCustomers,
  handleArchiveCustomers,
  currentFlatTab,
  checkedCustomers,
  handleSetCheckedCustomersHeader,
  handleAssignReps,
  parentCustomersList,
  customersCount,
  handleOpenCustomerNotes,
  onReport,
  reportData,
  handleOrders,
  page,
  setPage,
  handleFetchParents,
  handleCustomerTaskDrawer,
  handleAssignTags,
  repPermissions,
  setCheckedCustomers,
  loadingCustomers,
  handleSetCheckAllCustomers,
  additionalActions,
  actionList,
  handleGetOrderActions,
  loadingGroups,
  onSelectAllGroups,
  resetTableCallback,
  tableFilterParams,
}) => {
  const { state } = useLocation();
  const isAdmin = useAdmin();
  const [allGroupsChecked, setAllGroupsChecked] = useState(false);
  const navigate = useNavigate();

  const {
    customersActiveList,
    customersInactiveList,
    showInactive,
    customersActiveCount,
    customersInactiveCount,
    customersLoading,
    customersExist,
    customersGetParams,
    loadingParents,
    groupsGetParams,
    countParents,
  } = useSelector(selector);

  const dispatch = useDispatch();

  const customersList = useMemo(
    () => (showInactive ? customersInactiveList : customersActiveList),
    [showInactive, customersInactiveList, customersActiveList]
  );

  const count = useMemo(
    () => (showInactive ? customersInactiveCount : customersActiveCount),
    [showInactive, customersInactiveCount, customersActiveCount]
  );

  useEffect(() => {
    const withoutUncategorized = parentCustomersList.filter(
      (g) => g.name !== "Uncategorized"
    );
    if (
      withoutUncategorized.length &&
      checkedGroups.length === withoutUncategorized.length
    )
      return setAllGroupsChecked(true);
    setAllGroupsChecked(false);
  }, [checkedGroups.length, parentCustomersList]);

  const handleFetch = useCallback(() => {
    dispatch(getCustomersAction({ limit: 15, page: page + 1 }));
    setPage((prev) => prev + 1);
  }, [dispatch, page, setPage]);

  const handleSetSortVisit = useCallback(
    (sortField) => {
      const sortParams = {
        ...customersGetParams,
        sort_orders: null,
        sort_visit: null,
        sort_last_order: null,
        sort_display_name: null,
        sort_parent_customer_name: null,
        sort_territory_name: null,
        sort_order_direct: null,
        sort_by_orders_count: null,
      };

      (sortParams[sortField] =
        customersGetParams?.[sortField] !== "desc" ? "desc" : "asc"),
        dispatch(customersGetParamsAction(sortParams));
    },
    [customersGetParams, dispatch]
  );

  const pageContent = useMemo(() => {
    switch (currentFlatTab) {
      case 0:
        return customersList.length ? (
          <Box
            sx={{
              "& .tab-list-item": {
                borderColor: "#d5d9d9",
                borderWidth: "0 0.5px 0.5px 0.5px",
                borderStyle: "solid",
              },
              "& #content-wrapper > div > div:last-child > div": {
                borderRadius: "0 0 4px 4px",
              },
              borderRadius: "0 0 4px 4px",
            }}
          >
            <TableHeaderControlPanel
              actionWrapperProps={{
                sx: { padding: "0 19px 0 15px !important" },
              }}
              checkedCount={checkedCustomers?.length}
              actionsList={additionalActions}
              loading={loadingCustomers}
              dropDownActions={actionList}
              onSelectAll={(value) => handleSetCheckAllCustomers(value)}
              hasCheckedItems={!!checkedCustomers?.length}
              availableSelectCount={count}
              selectName="customer"
              onSelectVisible={handleSetCheckedCustomersHeader}
              cancelSelection={() => setCheckedCustomers([])}
              headerComponent={
                <TabHeaderComponent
                  customersListCount={customersList?.length ?? 0}
                  checkedCustomers={checkedCustomers}
                  handleSetCheckedCustomersHeader={
                    handleSetCheckedCustomersHeader
                  }
                  customersCount={customersCount}
                  customersList={customersList}
                  isFlat
                  quickSort={{
                    sort_visit: customersGetParams?.sort_visit,
                    sort_orders: customersGetParams?.sort_orders,
                    sort_last_order: customersGetParams?.sort_last_order,
                    sort_display_name: customersGetParams?.sort_display_name,
                    sort_parent_customer_name:
                      customersGetParams?.sort_parent_customer_name,
                    sort_territory_name:
                      customersGetParams?.sort_territory_name,
                    sort_order_direct: customersGetParams?.sort_order_direct,
                    sort_by_orders_count:
                      customersGetParams?.sort_by_orders_count,
                  }}
                  handleSetSortVisit={(sortField) =>
                    handleSetSortVisit(sortField)
                  }
                  currentTab="FLAT"
                  isAdmin={isAdmin}
                />
              }
            />

            <InfiniteLoaderWrapper
              itemsList={customersList}
              itemsCount={count}
              offsetHeight={393}
              offsetWidth={64}
              loading={customersLoading}
              handleFetch={handleFetch}
              resetCallback={resetTableCallback}
              contentParams={tableFilterParams}
              itemHeight={51}
              tableStyle={{ borderWidth: "0px" }}
              bordersWidthProp={0.5}
              renderChildren={(index) => (
                <TabListComponent
                  customer={customersList[index]}
                  checkedCustomers={checkedCustomers}
                  handleCheckCustomer={handleCheckCustomer}
                  handleOpenConfirmDialog={handleOpenConfirmDialog}
                  handleOpenCustomerProfile={handleOpenCustomerProfile}
                  handleRedirectMissingInfo={handleRedirectMissingInfo}
                  handleChangeTerritory={handleChangeTerritory}
                  handleChangeGroup={handleChangeGroup}
                  handleChangePaymentTerm={handleChangePaymentTerm}
                  handleDeleteCustomers={handleDeleteCustomers}
                  handleArchiveCustomers={handleArchiveCustomers}
                  handleAssignReps={handleAssignReps}
                  groupList={parentCustomersList}
                  handleOpenCustomerNotes={handleOpenCustomerNotes}
                  currentTab="FLAT"
                  onReport={onReport}
                  reportData={reportData}
                  handleGetOrderActions={handleGetOrderActions}
                  lastItem={index === customersList.length - 1}
                  {...{
                    handleOrders,
                    handleChangeParentGroup,
                    handleCustomerTaskDrawer,
                    handleAssignTags,
                    repPermissions,
                    isAdmin,
                  }}
                />
              )}
            />
          </Box>
        ) : (
          <EmptyScreen
            type="customers"
            height="calc(100vh - 470px)"
            onConfirm={() =>
              navigate("/customers/new", {
                state: state?.type === "onboarding" && { type: "onboarding" },
              })
            }
            showAction={!customersExist}
            loading={customersLoading}
            disabled={
              !!repPermissions && !repPermissions?.customers?.create_edit
            }
          />
        );

      case 1:
        return (
          <Box>
            <TableHeaderControlPanel
              actionWrapperProps={{
                sx: { padding: "0 19px 0 15px !important" },
              }}
              checkedCount={checkedGroups?.length}
              actionsList={additionalActions}
              loading={loadingGroups || loadingParents}
              dropDownActions={actionList}
              onSelectAll={(value) => onSelectAllGroups(value)}
              hasCheckedItems={!!checkedGroups?.length}
              availableSelectCount={countParents - 1}
              selectName="group"
              onSelectVisible={checkAllGroups}
              cancelSelection={() => checkAllGroups(false)}
              headerComponent={
                <CustomersGroupsHeader
                  allGroupsChecked={allGroupsChecked}
                  checkAllGroups={checkAllGroups}
                  isAdmin={isAdmin}
                />
              }
            />

            <Stack
              sx={{
                maxHeight: "calc(100vh - 400px)",
                overflowY: "hidden",
                position: "relative",
                opacity: loadingParents ? 0.5 : 1,
                pointerEvents: loadingParents && "none",
                borderWidth: parentCustomersList?.length ? "0 1px 1px 1px" : 0,
                borderStyle: "solid",
                borderColor: "#D5D9D9",
                borderRadius: "0 0 4px 4px",
              }}
              className="infinite-scroll-custom-scrollbar"
            >
              <InfiniteScrollWrapper
                maxHeight="calc(100vh - 400px)"
                dataLength={parentCustomersList.length}
                loading={loadingParents}
                next={() => handleFetchParents(groupsGetParams.page + 1)}
                loader={
                  <CircularProgress sx={{ ml: "50%", mt: "2px" }} size="30px" />
                }
                hasMore={parentCustomersList?.length < countParents}
                id="groups-scroll-table"
              >
                {parentCustomersList?.length ? (
                  parentCustomersList.map((parent) => (
                    <ParentItem
                      key={parent?.id}
                      parent={parent}
                      checkedParents={checkedGroups}
                      handleCheckParent={handleCheckGroup}
                      handleDeleteParent={handleDeleteGroup}
                      handleOpenConfirmDialog={handleOpenConfirmDialog}
                      handleChangeTerritory={handleChangeTerritory}
                      handleChangeGroup={handleChangeGroup}
                      handleChangePaymentTerm={handleChangePaymentTerm}
                      handleDeleteCustomers={handleDeleteCustomers}
                      handleArchiveCustomers={handleArchiveCustomers}
                      handleAssignReps={handleAssignReps}
                      groupList={parentCustomersList}
                      handleGetOrderActions={handleGetOrderActions}
                      handleOpenCustomerNotes={handleOpenCustomerNotes}
                      onReport={onReport}
                      reportData={reportData}
                      {...{
                        handleOrders,
                        handleFetchParents,
                        handleAssignTags,
                        repPermissions,
                        isAdmin,
                      }}
                    />
                  ))
                ) : (
                  <Typography
                    fontWeight="600"
                    fontSize="16px"
                    textAlign="center"
                    width="100%"
                    color="#1C1C19"
                    paddingTop="170px"
                    paddingBottom="20px"
                  >
                    No groups found
                  </Typography>
                )}
                {!loadingParents &&
                  parentCustomersList?.length < countParents && (
                    <InfiniteLoadMoreBtn
                      onClick={() =>
                        handleFetchParents(groupsGetParams.page + 1)
                      }
                    />
                  )}
              </InfiniteScrollWrapper>
            </Stack>
          </Box>
        );

      default:
        return <></>;
    }
  }, [
    currentFlatTab,
    customersList,
    checkedCustomers,
    additionalActions,
    loadingCustomers,
    actionList,
    count,
    handleSetCheckedCustomersHeader,
    customersCount,
    customersGetParams?.sort_visit,
    customersGetParams?.sort_orders,
    customersGetParams?.sort_last_order,
    customersGetParams?.sort_display_name,
    customersGetParams?.sort_parent_customer_name,
    customersGetParams?.sort_territory_name,
    customersGetParams?.sort_order_direct,
    customersGetParams?.sort_by_orders_count,
    isAdmin,
    customersLoading,
    handleFetch,
    resetTableCallback,
    tableFilterParams,
    customersExist,
    repPermissions,
    checkedGroups,
    loadingGroups,
    loadingParents,
    countParents,
    checkAllGroups,
    allGroupsChecked,
    parentCustomersList,
    handleSetCheckAllCustomers,
    setCheckedCustomers,
    handleSetSortVisit,
    handleCheckCustomer,
    handleOpenConfirmDialog,
    handleOpenCustomerProfile,
    handleRedirectMissingInfo,
    handleChangeTerritory,
    handleChangeGroup,
    handleChangePaymentTerm,
    handleDeleteCustomers,
    handleArchiveCustomers,
    handleAssignReps,
    handleOpenCustomerNotes,
    onReport,
    reportData,
    handleGetOrderActions,
    handleOrders,
    handleChangeParentGroup,
    handleCustomerTaskDrawer,
    handleAssignTags,
    navigate,
    state?.type,
    onSelectAllGroups,
    handleFetchParents,
    groupsGetParams.page,
    handleCheckGroup,
    handleDeleteGroup,
  ]);

  return <>{pageContent}</>;
};

FlatView.propTypes = {
  setAssignedAnchorEl: func,
  handleCheckCustomer: func,
  checkedGroups: array,
  handleCheckGroup: func,
  checkAllGroups: func,
  // handleEditGroup: func,
  handleDeleteGroup: func,
  handleOpenConfirmDialog: func,
  handleOpenCustomerProfile: func,
  handleRedirectMissingInfo: func,
  handleChangeTerritory: func,
  handleChangeGroup: func,
  handleChangeParentGroup: func,
  handleChangePaymentTerm: func,
  handleDeleteCustomers: func,
  handleArchiveCustomers: func,
  currentFlatTab: number,
  checkedCustomers: array,
  handleSetCheckedCustomersHeader: func,
  handleAssignReps: func,
  parentCustomersList: array,
  customersCount: number,
  handleOpenCustomerNotes: func,
  onReport: func,
  reportData: object,
  handleOrders: func,
  page: number,
  setPage: func,
  handleFetchParents: func,
  handleCustomerTaskDrawer: func,
  handleAssignTags: func,
  repPermissions: object,
  setCheckedCustomers: func,
  handleSetCheckAllCustomers: func,
  loadingCustomers: bool,
  additionalActions: array,
  actionList: array,
  handleGetOrderActions: func,
  loadingGroups: bool,
  onSelectAllGroups: func,
  tableFilterParams: object,
  resetTableCallback: func,
};

FlatView.defaultProps = {
  setAssignedAnchorEl: () => {},
  handleCheckCustomer: () => {},
  checkedGroups: [],
  actionList: [],
  handleGetOrderActions: () => {},
  handleCheckGroup: () => {},
  checkAllGroups: () => {},
  // handleEditGroup: () => {},
  handleDeleteGroup: () => {},
  handleOpenConfirmDialog: () => {},
  handleOpenCustomerProfile: () => {},
  handleRedirectMissingInfo: () => {},
  handleChangeTerritory: () => {},
  handleChangeGroup: () => {},
  handleChangeParentGroup: () => {},
  handleChangePaymentTerm: () => {},
  handleDeleteCustomers: () => {},
  handleArchiveCustomers: () => {},
  checkedCustomers: [],
  handleSetCheckedCustomersHeader: () => {},
  handleAssignReps: () => {},
  parentCustomersList: [],
  customersCount: 0,
  onReport: () => {},
  handleOrders: () => {},
  page: 1,
  setPage: () => {},
  handleCustomerTaskDrawer: () => {},
  setCheckedCustomers: () => {},
  handleSetCheckAllCustomers: () => {},
  loadingCustomers: false,
  additionalActions: [],
};

export default FlatView;
