import { bool, string, func } from "prop-types";
import { Box, Grid, Typography } from "@mui/material";
import { CheckmarkIcon } from "components/Icons";
import { StyledButton } from "components";

export const HeaderBlock = ({
  status,
  title,
  handleSelectProducts,
  hasProducts,
}) => {
  return (
    <Grid
      sx={{
        height: "49px",
        border: "0.5px solid #D5D9D9",
        borderRadius: "4px 4px 0 0",
        bgcolor: "#47A06D10",
      }}
      container
    >
      <Grid
        display="flex"
        justifyContent="center"
        alignItems="center"
        xs={0.55}
        item
      >
        <Box
          sx={{
            width: "18px",
            height: "18px",
            border: "1px solid #47A06D",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {status && (
            <CheckmarkIcon width="11.18" height="8" stroke="#47A06D" />
          )}
        </Box>
      </Grid>

      <Grid display="flex" alignItems="center" xs={8} item>
        <Typography fontSize={20} fontWeight={500} color="#707070">
          {title}
        </Typography>
      </Grid>

      <Grid
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        xs={3.25}
        item
      >
        <StyledButton
          label={hasProducts ? "Update" : "Select Products"}
          variant="contained"
          fontSize="13px"
          sx={{ height: "25px" }}
          onClick={handleSelectProducts}
        />
      </Grid>
    </Grid>
  );
};

HeaderBlock.propTypes = {
  status: bool,
  title: string,
  handleSelectProducts: func,
  hasProducts: bool,
};
HeaderBlock.defaultProps = {
  status: false,
  title: "",
  handleSelectProducts: () => {},
  hasProducts: false,
};
