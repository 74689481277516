import { Controller, useWatch } from "react-hook-form";
import { any, func, object } from "prop-types";

import { PasswordCriteria, StyledTextField } from "components";

import { FORM_FIELDS } from "../ChangePasswordDialog.constants";

import { cl } from "../styles";

import { DialogContent, Stack, Typography } from "@mui/material";

export const ContentBlock = ({
  control,
  handleForgotPassword,
  errors,
  clearErrors,
}) => {
  const formField = useWatch({ control });

  return (
    <DialogContent sx={cl.dialogContent}>
      <Stack gap="10px" pt="20px">
        <Controller
          render={({ field, fieldState: { error } }) => (
            <StyledTextField
              {...{ control, field, error }}
              error={error ? error.message : ""}
              onChange={(e) => {
                field.onChange(e);
              }}
              sx={{
                ...(field.value && {
                  "& .MuiInputLabel-root": {
                    fontSize: "16px",
                    lineHeight: "23px",
                  },
                }),
              }}
              label={FORM_FIELDS[0].label}
              type={FORM_FIELDS[0].type}
            />
          )}
          name={FORM_FIELDS[0].name}
          control={control}
        />

        <Typography sx={cl.forgotText} onClick={handleForgotPassword}>
          Forgot password?
        </Typography>
      </Stack>

      <Stack gap="20px" mt="40px">
        {FORM_FIELDS.slice(1, 3).map(({ type, label, name }, index) => (
          <Controller
            key={index}
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                sx={{
                  ...(field.value && {
                    "& .MuiInputLabel-root": {
                      fontSize: "16px",
                      lineHeight: "23px",
                    },
                  }),
                }}
                {...{ control, field, error }}
                error={error ? error.message : ""}
                onChange={(e) => {
                  if (errors?.length) clearErrors();
                  field.onChange(e);
                }}
                label={label}
                type={type}
              />
            )}
            name={name}
            control={control}
          />
        ))}
      </Stack>

      <Stack sx={cl.criterial}>
        <PasswordCriteria
          {...{
            iconWrapperSx: { width: "18px", height: "18px" },
            password: formField.newPassword,
            confirmPassword: formField.confirmPassword,
          }}
        />
      </Stack>
    </DialogContent>
  );
};

ContentBlock.propTypes = {
  control: any,
  handleForgotPassword: func,
  trigger: func,
  errors: object,
  clearErrors: func,
};
ContentBlock.defaultProps = {
  control: null,
  handleForgotPassword: () => {},
  trigger: () => {},
  errors: {},
  clearErrors: () => {},
};
