import { string } from "prop-types";
import { Box, Typography } from "@mui/material";

export const DiscountBlock = ({ discount }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        height: "100%",
        minWidth: "7.4%",
        width: "7.4%",
      }}
    >
      <Typography fontSize={13} color="#1C1C19">
        -{discount}
      </Typography>
    </Box>
  );
};

DiscountBlock.propTypes = { discount: string };
DiscountBlock.defaultProps = { discount: "0" };
