import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  cardWrapper: {
    borderRadius: "8px",
    boxShadow: "0px 5px 30px rgba(0, 0, 0, 0.16)",
    width: "292px",
    height: "188px",
    padding: "19px 21px 16px 25px",
    opacity: ({ disabled }) => (disabled ? 0.7 : 1),
  },

  statusDot: {
    width: "8px",
    height: "8px",
    borderRadius: "50%",
  },

  cardTitle: {
    display: "flex",
    justifyContent: "space-between",
    height: "46px",
    alignItems: "center",
  },

  cardStatusWrap: {
    display: "flex",
    gap: "9px",
    alignItems: "center",
  },

  cardStatus: {
    display: "flex",
    gap: "5px",
    alignItems: "center",
  },

  syncProgressWrap: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
}));

export default useStyles;
