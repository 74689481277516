import { useCallback } from "react";
import { func, object } from "prop-types";
import { Controller, useWatch } from "react-hook-form";

import { SWITCHERS, TABS } from "../NotificationsMenu.constants";
import { SCROLL_LIMIT_INVITES } from "../../../utils/constants";

import { cl } from "../NotificationsMenu.styles";

import { Box, Tab, Tabs } from "@mui/material";

export const Switcher = ({
  onSwitchClick,
  setValue,
  control,
  // hasAnyInvites,
  // hasAnyReceived,
  closeMenu,
}) => {
  const { currentTab } = useWatch({ control });

  const getParams = useCallback((tab) => {
    let res = {
      limit: SCROLL_LIMIT_INVITES,
    };
    if (tab === "accepted") res.status = "APPROVED";
    if (!["all", "accepted"].includes(tab)) res.status = tab.toUpperCase();
    return res;
  }, []);

  // const showTabs = useMemo(() => {
  //   if (currentTab === TABS[1]) return hasAnyInvites;
  //   if (currentTab === TABS[0]) return hasAnyReceived;
  // }, [currentTab, hasAnyInvites, hasAnyReceived]);

  const handleSwitchClick = (tab) => {
    onSwitchClick(getParams(tab));
    closeMenu();
  };

  return (
    <Box sx={cl.switcher.tabsWrapper}>
      <Controller
        render={({ field }) => (
          <Tabs
            sx={cl.switcher.tabs}
            {...field}
            value={field.value}
            onChange={(e, newValue) => setValue("currentSwitch", newValue)}
          >
            {SWITCHERS.map((tab) => (
              <Tab
                key={tab}
                sx={cl.switcher.tab}
                // disabled={!showTabs}
                label={
                  <Box sx={cl.switcher.tabLabel}>
                    {currentTab === TABS[1] && tab === "accepted"
                      ? "approved"
                      : tab}
                  </Box>
                }
                value={tab}
                onClick={() => handleSwitchClick(tab)}
              />
            ))}
          </Tabs>
        )}
        name="currentSwitch"
        control={control}
      />
    </Box>
  );
};

Switcher.propTypes = {
  onSwitchClick: func,
  setValue: func,
  control: object,
  // hasAnyInvites: bool,
  // hasAnyReceived: bool,
  closeMenu: func,
};
Switcher.defaultProps = {
  setCurrentTab: () => {},
  // hasAnyInvites: false,
  // hasAnyReceived: false,
};
