import React, {
  useEffect,
  useState,
  Fragment,
  useRef,
  useCallback,
  useMemo,
} from "react";
import {
  Box,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import PropTypes, {
  bool,
  func,
  array,
  number,
  string,
  object,
} from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { CrossBigIcon } from "../../../../components/Icons";
import {
  productsCountSelector,
  productsListSelector,
} from "../../../../redux/selectors/products";
import PopupProductsItem from "./PopupProductsItem/PopupProductsItem";
import StyledButton from "../../../../components/StyledButton";
import {
  getProductsAction,
  resetProductsGetParamsAction,
} from "../../../../redux/actions/products";
import {
  PRODUCT_TYPE_INVENTORY,
  SCROLL_LIMIT,
} from "../../../../utils/constants";
import { containsEvery, useAdmin } from "../../../../helpers/helpers";
import PopupProductsHeader from "./PopupProductsHeader/PopupProductsHeader";
import { NewProductsFilter } from "./components";
import StyledPopper from "../../../../components/StyledPopper/StyledPopper";
import { setIndeterminate } from "./helpers";
import { InfiniteScrollWrapper } from "components";

const selector = createSelector(
  productsListSelector,
  productsCountSelector,
  (productsList, productsCount) => ({
    productsList,
    productsCount,
  })
);

const AllProductsPopup = ({
  isOpen,
  handleClose,
  handleAddProducts,
  handleCancelProducts,
  handleCheckProduct,
  checkedProducts,
  addedProducts,
  specificManufacturer,
  disableOutOfStock,
  isOrder,
  hideAvailable,
  environment,
  skipExpanding = false,
  hideGreenNavigationBar = false,
  allowMultipleSelect = false,
}) => {
  const isAdmin = useAdmin();
  const { productsList, productsCount } = useSelector(selector);

  const [limitProducts, setLimitProducts] = useState(2 * SCROLL_LIMIT);
  const [expandedParentId, setExpandedParentId] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isOpen) {
      // dispatch(resetProductsGetParamsAction());
      setExpandedParentId(null);
    } else dispatch(resetProductsGetParamsAction(specificManufacturer?.id));
  }, [isOpen, dispatch, specificManufacturer]);

  const handleFetchProducts = () => {
    const preparedData = {
      limit: limitProducts,
    };
    if (specificManufacturer?.id)
      preparedData.manufacturer_id = specificManufacturer?.id;

    dispatch(getProductsAction({ query: preparedData }));
    setLimitProducts(limitProducts + SCROLL_LIMIT);
  };

  const [currentProductRef, setCurrentProductRef] = useState(false);
  const [textProduct, setTextProduct] = useState("");

  const productsRefs = useRef([]);

  const calcAvailable = useCallback((product) => {
    const onHand = product?.inventory?.onHand;
    const allocated = product?.inventory?.allocated;

    return onHand - allocated;
  }, []);

  const productsAvailableLessThanMinimum = useCallback(
    (product) => {
      const isNonInventory =
        product?.type === PRODUCT_TYPE_INVENTORY.non_inventory;

      const available = calcAvailable(product);
      if (
        product?.sellingOutOfStock ||
        product?.childProducts?.length ||
        isNonInventory
      )
        return false;
      return available < product?.minOrderQTY;
    },
    [calcAvailable]
  );

  const supplierWithOnlyOneProductWithVariations = useMemo(() => {
    const productsWithChildren = productsList?.filter(
      (p) => p?.childProducts?.length
    );
    if (productsWithChildren?.length === 1) return true;
    return false;
  }, [productsList]);

  // If all products of the parent product are out of stock
  const calcProductOutOfStockForParent = useCallback(
    (product) => {
      if (product?.childProducts?.length && !product?.sellingOutOfStock) {
        return !product?.childProducts?.some((prod) => calcAvailable(prod) > 0);
      }
      return false;
    },
    [calcAvailable]
  );

  const handleDisableIfChosenProduct = (
    product,
    parent,
    allowMultiple = false
  ) => {
    if (isOrder || !checkedProducts?.length) return false;
    const manufacturerId =
      product?.manufacturer?.id || parent?.manufacturer?.id;

    const checkedManufacturerId =
      checkedProducts?.[0]?.manufacturer?.id ||
      checkedProducts?.[0]?.parentProduct?.manufacturer?.id;

    if (manufacturerId === checkedManufacturerId && allowMultiple) return false;

    const selectedParentId =
      checkedProducts?.[0]?.parentProduct?.id ||
      checkedProducts?.[0]?.parentId ||
      checkedProducts?.[0]?.id;

    const currentProductId = parent?.id || product?.id;

    return !(selectedParentId === currentProductId);
  };

  const handleProductsDisable = useCallback(
    (product, isChild) => {
      if (!addedProducts?.length) return false;

      if (isChild || !product.isMultiple)
        return (
          (disableOutOfStock && productsAvailableLessThanMinimum(product)) ||
          addedProducts?.some(
            (addedProduct) =>
              addedProduct?.id === product?.id ||
              addedProduct?.product?.id === product?.id
          )
        );

      return (
        (disableOutOfStock && productsAvailableLessThanMinimum(product)) ||
        containsEvery(
          product?.childProducts?.filter(
            // if isMultiple, check if Added products (formfield) contain
            // any of child products from this parent
            (childProduct) =>
              !addedProducts?.some(
                (prod) =>
                  prod?.id === childProduct?.id ||
                  prod?.product?.id === childProduct?.id
              )
          ),
          addedProducts
        )
      );
    },
    [addedProducts, productsAvailableLessThanMinimum, disableOutOfStock]
  );

  const handleProductsChecked = (product) => {
    const disabled = handleProductsDisable(product);
    if (disabled) return false;

    if (product.isMultiple)
      return containsEvery(
        product?.childProducts?.filter(
          (childProduct) =>
            !addedProducts?.some(
              (prod) =>
                prod?.id === childProduct?.id ||
                prod?.product?.id === childProduct.id
            )
        ),
        checkedProducts
      );

    return checkedProducts.some(
      (checkedProduct) => checkedProduct?.id === product?.id
    );
  };

  return (
    <>
      <StyledPopper
        style={{ zIndex: 1401, whiteSpace: "pre-wrap" }}
        open={!!currentProductRef && isOpen && !hideAvailable}
        anchorEl={productsRefs.current?.[currentProductRef] || null}
        text={textProduct}
        renderRoot
        modifiers={[
          {
            name: "offset",
            options: { offset: [0, -6] },
          },
        ]}
        placement="top"
        transition
        aria-hidden="true"
      />
      <Dialog open={isOpen} PaperProps={{ sx: { maxWidth: "784px" } }}>
        <DialogContent sx={{ width: "784px", height: "580px", px: "32px" }}>
          <Box display="flex" justifyContent="space-between" mb="20px">
            <Typography fontSize="30px" color="#707070">
              All Products{" "}
              {specificManufacturer?.name ? specificManufacturer?.name : null}
            </Typography>
            <IconButton onClick={handleClose}>
              <CrossBigIcon />
            </IconButton>
          </Box>

          <NewProductsFilter
            isOpen={isOpen}
            specificManufacturer={specificManufacturer}
          />

          <PopupProductsHeader {...{ hideAvailable }} />
          <Stack
            sx={{
              height: "281px",
              overflow: "hidden",
              border: "0.5px solid #D5D9D9",
              borderRadius: "0 0 4px 4px",
            }}
          >
            <InfiniteScrollWrapper
              maxHeight="281px"
              dataLength={productsList.length}
              next={handleFetchProducts}
              loader={
                <CircularProgress sx={{ ml: "50%", mt: "2px" }} size="30px" />
              }
              hasMore={productsList.length < productsCount}
              id="all-products-scroll-table"
            >
              {productsList?.length ? (
                productsList.map((product) => {
                  const disableIfChosenProduct = handleDisableIfChosenProduct(
                    product,
                    null,
                    allowMultipleSelect
                  );

                  const isNonInventory =
                    product?.type === PRODUCT_TYPE_INVENTORY.non_inventory;

                  const setOutOfStock = (product) => {
                    return (
                      (!isNonInventory &&
                        disableOutOfStock &&
                        productsAvailableLessThanMinimum(product)) ||
                      (!isNonInventory &&
                        calcProductOutOfStockForParent(product))
                    );
                  };

                  const outOfStock = setOutOfStock(product);

                  const isChecked = handleProductsChecked(product);

                  const indeterminateParent = setIndeterminate(
                    product,
                    isChecked,
                    checkedProducts
                  );

                  const isMissingInfo = product?.missingFields;

                  return (
                    <Fragment key={product.id}>
                      <PopupProductsItem
                        isOrder={isOrder}
                        productsRefs={productsRefs}
                        onMouseEnter={() => {
                          if (
                            productsAvailableLessThanMinimum(product) &&
                            !product?.isMultiple &&
                            disableOutOfStock &&
                            outOfStock
                          ) {
                            setCurrentProductRef(product?.id);
                            setTextProduct(
                              `Cannot add this product to the cart.\n Items Available: ${calcAvailable(
                                product
                              )}, Minimum Order QTY: ${product?.minOrderQTY}`
                            );
                          }
                        }}
                        onMouseLeave={() => {
                          setCurrentProductRef(false);
                          setTextProduct("");
                        }}
                        addedProducts={addedProducts}
                        key={product.id}
                        product={product}
                        hideGreenNavigationBar={hideGreenNavigationBar}
                        skipExpanding={skipExpanding}
                        hideAvailable
                        expandedParentId={expandedParentId}
                        parentInOrder={
                          product.isMultiple &&
                          containsEvery(
                            product.childProducts.filter(
                              // if isMultiple, check if Added products (formfield) contain
                              // any of child products from this parent
                              (childProduct) =>
                                !addedProducts?.some(
                                  (prod) => prod.id === childProduct.id
                                )
                            ),
                            addedProducts
                          )
                        }
                        handleCheckProduct={handleCheckProduct}
                        supplierWithOnlyOneProductWithVariations={
                          supplierWithOnlyOneProductWithVariations &&
                          !!product?.childProducts?.length
                        }
                        handleExpandVariations={(id) => {
                          if (id === expandedParentId)
                            return setExpandedParentId(null);
                          setExpandedParentId(id);
                        }}
                        isChecked={isChecked}
                        isMissingInfo={isMissingInfo}
                        disabled={
                          handleProductsDisable(product, false) ||
                          disableIfChosenProduct ||
                          !!isMissingInfo?.length
                        }
                        indeterminateParent={indeterminateParent}
                        setExpandedParentId={setExpandedParentId}
                        disableIfChosenProduct={disableIfChosenProduct}
                        outOfStock={outOfStock}
                        {...{ hideAvailable }}
                      />
                      <Collapse
                        in={expandedParentId === product.id}
                        timeout={700}
                      >
                        {product.childProducts?.map((children, indexChild) => {
                          children.sellingOutOfStock =
                            product?.sellingOutOfStock;
                          children.minOrderQTY = product?.minOrderQTY;

                          const isNonInventory =
                            product?.type ===
                            PRODUCT_TYPE_INVENTORY.non_inventory;

                          const setOutOfStock = (children) => {
                            return (
                              !isNonInventory &&
                              disableOutOfStock &&
                              productsAvailableLessThanMinimum(children)
                            );
                          };

                          const outOfStock = setOutOfStock(children);

                          const disableIfChosenProduct =
                            handleDisableIfChosenProduct(
                              children,
                              product,
                              allowMultipleSelect
                            );
                          const isMissingInfo = children?.missingFields;

                          return (
                            <PopupProductsItem
                              productsRefs={productsRefs}
                              onMouseEnter={() => {
                                if (outOfStock) {
                                  setCurrentProductRef(children?.id);
                                  setTextProduct(
                                    `Cannot add this product to the cart.\n Items Available: ${calcAvailable(
                                      children
                                    )}, Minimum Order QTY: ${
                                      product?.minOrderQTY
                                    }`
                                  );
                                }
                              }}
                              hideAvailable
                              onMouseLeave={() => {
                                setCurrentProductRef(false);
                                setTextProduct("");
                              }}
                              key={children.id}
                              product={children}
                              parent={product}
                              hideGreenNavigationBar={hideGreenNavigationBar}
                              skipExpanding={skipExpanding}
                              supplierWithOnlyOneProductWithVariations={
                                supplierWithOnlyOneProductWithVariations &&
                                !!product?.childProducts?.length
                              }
                              handleExpandVariations={(id) => {
                                if (id === expandedParentId)
                                  return setExpandedParentId(null);
                                setExpandedParentId(id);
                              }}
                              isChild
                              handleCheckProduct={handleCheckProduct}
                              isChecked={checkedProducts.some(
                                (checkedProduct) =>
                                  checkedProduct.id === children.id
                              )}
                              isMissingInfo={isMissingInfo}
                              disabled={
                                handleProductsDisable(children, true) ||
                                disableIfChosenProduct ||
                                !!isMissingInfo?.length
                              }
                              disableIfChosenProduct={disableIfChosenProduct}
                              outOfStock={outOfStock}
                              lastChild={
                                product?.childProducts.length === indexChild + 1
                              }
                              environment={environment}
                            />
                          );
                        })}
                      </Collapse>
                    </Fragment>
                  );
                })
              ) : (
                <Typography
                  fontWeight="300"
                  fontSize="16px"
                  textAlign="center"
                  width="100%"
                  color="#6A6A6A"
                  paddingTop="20px"
                  paddingBottom="20px"
                >
                  No products found
                </Typography>
              )}
            </InfiniteScrollWrapper>
          </Stack>
          <DialogActions sx={{ p: "23px 0px 0px" }}>
            <StyledButton
              label="Cancel"
              sx={{ height: "27px", mr: "8px" }}
              color="cancel"
              onClick={handleCancelProducts}
              fontWeight="600"
              fontSize="10px"
            />
            <StyledButton
              disabled={isAdmin}
              label="Add"
              variant="contained"
              sx={{ height: "27px" }}
              fontWeight="600"
              fontSize="10px"
              onClick={handleAddProducts}
            />
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

AllProductsPopup.propTypes = {
  isOpen: bool,
  handleClose: func,
  handleCancelProducts: func,
  handleAddProducts: func,
  handleCheckProduct: func,
  checkedProducts: array,
  addedProducts: array,
  specificManufacturer: PropTypes.oneOfType([number, string, object]),
  disableOutOfStock: bool,
  isOrder: bool,
  hideAvailable: bool,
  allowMultipleSelect: bool,
  skipExpanding: bool,
  hideGreenNavigationBar: bool,
  environment: string,
};

AllProductsPopup.defaultProps = {
  isOpen: false,
  checkedProducts: [],
  addedProducts: [],
  disableOutOfStock: false,
  isOrder: false,
  hideAvailable: false,
  skipExpanding: false,
  hideGreenNavigationBar: false,
};

export default AllProductsPopup;
