import {
  GET_TRANSACTIONS,
  GET_TRANSACTIONS_COUNT,
  START_LOADING_TRANSACTIONS,
  END_LOADING_TRANSACTIONS
} from "../actions/transactions";

const initialState = {
  transactions: [],
  loading: false,
  count: 0,
};

const transactionsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_TRANSACTIONS:
      return { ...state, transactions: payload };

    case GET_TRANSACTIONS_COUNT:
      return { ...state, count: payload };

    case START_LOADING_TRANSACTIONS:
      return { ...state, loading: true };

    case END_LOADING_TRANSACTIONS:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default transactionsReducer;
