import { useTheme } from "@emotion/react";
import { PriorityHighRounded } from "@mui/icons-material";
import { doc, getFirestore } from "firebase/firestore";
import { useCallback, useMemo, useState } from "react";
import { useDocument } from "react-firebase-hooks/firestore";
import { useSelector } from "react-redux";
import {
  CheckmarkIcon,
  CrossIcon,
  PendingIcon,
} from "../../../../../../../components/Icons";
import { Link } from "@mui/material";
import { NavLink } from "react-router-dom";
import { useRepsPermissions } from "helpers/hooks";

export const usePersonalDetails = ({ isSetup = false }) => {
  const {
    id: userId,
    stripeBankAccountId,
    brandLogo,
    storeName,
    allowStoreCreditCardPayments,
  } = useSelector(({ auth }) => auth?.currentUser || {});
  const repPermissions = useRepsPermissions();

  const hasOrderDirectCompleted = useMemo(
    () => !!brandLogo && !!storeName,
    [brandLogo, storeName]
  );

  const hasBank = useMemo(() => !!stripeBankAccountId, [stripeBankAccountId]);

  const [isSameState, setIsSameState] = useState({
    officeLocation: false,
    businessContact: false,
  });

  const checkSame = (key) => {
    setIsSameState((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const [verificationDoc, verificationDocLoading] = useDocument(
    doc(getFirestore(), `stripe-account-verification-data/${userId}`)
  );

  const verificationStatus = useMemo(
    () => verificationDoc?.data(),
    [verificationDoc]
  );

  const REQUIREMENT_MESSAGES = useMemo(
    () => ({
      external_account: "Missing bank account",
      "person.id_number": "Invalid SSN",
      "representative.ssn_last_4": "Invalid SSN",
      "representative.address.city": "Invalid city",
      "representative.address.postal_code": "Invalid zip code",
      "representative.address.line1": "Invalid street address",
      "representative.address.state": "Invalid state",
      "person.address.city": "Invalid city",
      "person.address.postal_code": "Invalid zip code",
      "person.address.line1": "Invalid street address",
      "person.address.state": "Invalid state",
      "owners.address.city": "Invalid business city",
      "owners.address.postal_code": "Invalid business zip code",
      "owners.address.line1": "Invalid business street address",
      "owners.address.state": "Invalid business state",
      "company.address.city": "Invalid business city",
      "company.address.postal_code": "Invalid business zip code",
      "company.address.line1": "Invalid business street address",
      "company.address.state": "Invalid business state",
      "representative.dob.day": "Invalid date of birth: day",
      "representative.dob.month": "Invalid date of birth: month",
      "representative.dob.year": "Invalid date of birth: year",
      "representative.email": "Invalid email",
      "owners.email": "Invalid email",
      "representative.first_name": "Invalid first name",
      "representative.last_name": "Invalid last name",
      "owners.first_name": "Invalid first name",
      "owners.last_name": "Invalid last name",
      "representative.phone": "Invalid phone",
      "representative.relationship.title": "Invalid title",
      "company.phone": "Invalid business phone",
      "company.tax_id": "Invalid business federal tax id",
    }),
    []
  );

  const theme = useTheme();

  const PERSONAL_DETAILS_VERIFICATIONS = useMemo(
    () => ({
      UNVERIFIED: {
        color: theme.palette.ground.light,
        icon: <CrossIcon fill="#fff" opacity={1} size={isSetup ? 14 : 7} />,
        status: "Unverified",
      },
      VERIFIED: {
        color: theme.palette.primary.main,
        icon: (
          <CheckmarkIcon
            stroke="#fff"
            height={isSetup ? "8.33" : "4.22"}
            width={isSetup ? "11.34" : "5.75"}
          />
        ),
        status: "Verified",
      },
      PENDING_VERIFICATION: {
        color: theme.palette.warning.main,
        icon: (
          <PendingIcon
            width={isSetup ? "6.303" : "3.303"}
            height={isSetup ? "8.303" : "4.847"}
            style={{ margin: "0 0 4px 3px" }}
          />
        ),
        status: "Pending verification",
      },
      VERIFICATION_FAILED: {
        color: theme.palette.confirmDelete.main,
        icon: (
          <PriorityHighRounded
            style={{ fill: "#fff", fontSize: isSetup ? "14px" : "8px" }}
          />
        ),
        status: "Verification failed",
      },
    }),
    [
      theme.palette.confirmDelete.main,
      theme.palette.ground.light,
      theme.palette.primary.main,
      theme.palette.warning.main,
      isSetup,
    ]
  );

  const statusProps = useMemo(
    () =>
      PERSONAL_DETAILS_VERIFICATIONS[
        verificationStatus?.stripeAccountVerificationStatus
      ],
    [
      PERSONAL_DETAILS_VERIFICATIONS,
      verificationStatus?.stripeAccountVerificationStatus,
    ]
  );

  const requirements = useMemo(
    () =>
      verificationStatus?.stripeRequirements?.map(
        (req) => REQUIREMENT_MESSAGES[req] ?? req
      ),
    [REQUIREMENT_MESSAGES, verificationStatus?.stripeRequirements]
  );

  const allowedToAddCard = useMemo(
    () =>
      hasBank &&
      statusProps?.status === PERSONAL_DETAILS_VERIFICATIONS.VERIFIED.status,
    [
      PERSONAL_DETAILS_VERIFICATIONS.VERIFIED.status,
      hasBank,
      statusProps?.status,
    ]
  );

  const getPayoutWarningText = useCallback(() => {
    if (
      verificationStatus?.stripeAccountVerificationStatus ===
      "PENDING_VERIFICATION"
    )
      return (
        <span style={{ whiteSpace: "pre-wrap" }}>
          <Link
            color="primary"
            to="/settings"
            state={{ tab: "Payments", paymentsTab: "Payouts" }}
            component={NavLink}
          >
            Personal details
          </Link>{" "}
          verification status pending.
          {"\n"}
          Once approved you will be able to enable order direct store setup.
        </span>
      );

    if (!allowedToAddCard)
      return (
        <span style={{ whiteSpace: "pre-wrap" }}>
          No bank account linked to your profile. <br />
          Add a{" "}
          <Link
            color="primary"
            to="/settings"
            state={{ tab: "Payments", paymentsTab: "Payouts" }}
            component={NavLink}
          >
            bank account
          </Link>{" "}
          under the payment section to proceed
        </span>
      );
    return "";
  }, [allowedToAddCard, verificationStatus?.stripeAccountVerificationStatus]);

  const combineOrderDirectWithBankText = useCallback(
    (customer) => {
      if (
        !allowStoreCreditCardPayments &&
        customer?.paymentTermsDuplicate?.type === "ADVANCE_PAYMENT"
      ) {
        return (
          <span>
            Change{" "}
            <Link
              color="primary"
              to="/settings"
              state={{ tab: "Payment Terms" }}
              component={NavLink}
            >
              Advance payment
            </Link>{" "}
            term
          </span>
        );
      }

      return (
        <span style={{ whiteSpace: "pre-wrap" }}>
          {!allowedToAddCard &&
            "1. No bank account linked to your profile.\nAdd a "}
          {!allowedToAddCard &&
            (repPermissions && !repPermissions?.settings?.payments ? (
              "bank account "
            ) : (
              <Link
                color="primary"
                to="/settings"
                state={{ tab: "Payments", paymentsTab: "Payouts" }}
                component={NavLink}
              >
                bank account
              </Link>
            ))}
          {!allowedToAddCard && " under the payment section.\n2."} Complete your{" "}
          <Link
            color={allowedToAddCard ? "primary" : "black"}
            variant="inherit"
            to="/settings"
            state={{ tab: "Order Direct" }}
            underline={allowedToAddCard ? "always" : "none"}
            component={NavLink}
            onClick={(e) => {
              if (!allowedToAddCard) e.preventDefault();
            }}
          >
            order direct
          </Link>{" "}
          store setup
        </span>
      );
    },
    [allowStoreCreditCardPayments, allowedToAddCard, repPermissions]
  );

  return {
    statusProps,
    verificationDocLoading,
    requirements,
    checkSame,
    isSameState,
    allowedToAddCard,
    verificationStatus,
    getPayoutWarningText,
    combineOrderDirectWithBankText,
    hasOrderDirectCompleted,
  };
};
