import { useCallback, useEffect, useState } from "react";
import { getPaytermsService } from "services/payterms";
import { SCROLL_LIMIT } from "utils/constants";
import { error } from "utils/notifications";

const init = {
  loading: false,
  list: [],
  // count: 0,
  // existData: false,
};

export const usePayTermsActions = ({ params = {}, open = true } = {}) => {
  const [state, setState] = useState(init);

  const [initParams, setInitParams] = useState({
    page: 1,
    limit: SCROLL_LIMIT,
    ...params,
  });

  const handleFetch = useCallback(
    async (page) => {
      setState((prev) => ({ ...prev, loading: true }));
      try {
        const res = await getPaytermsService({
          ...initParams,
          page: page || initParams.page,
        });

        // const count = res?.count || 0;
        // const existData = !!res?.existData;
        const rows = res?.rows || res || [];

        setState((prev) => ({
          ...prev,
          list: page > 1 ? [...prev.list, ...rows] : rows,
          // count,
          // existData,
        }));

        if (page) setInitParams((prev) => ({ ...prev, page }));
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        error(err?.response?.data?.message || "Something went wrong.");
      } finally {
        setState((prev) => ({ ...prev, loading: false }));
      }
    },
    [initParams]
  );

  useEffect(() => {
    if (open) {
      handleFetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return {
    ...state,
    page: initParams.page,
    handleFetch,
  };
};
