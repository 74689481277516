import { func, string, bool, object } from "prop-types";
import { Box, Typography } from "@mui/material";
import { NewOrderIcon } from "../../../../../../components/Icons";
import { ThirdPartyItem } from "./ThirdPartyItem";

export const OrderItem = ({
  onClickOnOrder,
  size,
  order = { type: "DIRECT" },
}) => {
  const isDirectOrder = order?.type === "DIRECT";
  const isThirdPartyOrder = order?.type === "THIRD_PARTY";

  return (
    <Box
      sx={{
        border: isDirectOrder && "0.2px solid #D5D9D9",
        borderRadius: "4px",
        width: size === "small" ? "40px" : "57px",
        maxWidth: size === "small" ? "40px" : "57px",
        minWidth: size === "small" ? "40px" : "57px",
        height: size === "small" ? "40px" : "57px",
        maxHeight: size === "small" ? "40px" : "57px",
        minHeight: size === "small" ? "40px" : "57px",
        display: "flex !important",
        flexDirection: "column",
        justifyContent: "space-between",
        cursor: "pointer",
        backgroundColor: "#F6F6F6",
        position: "relative",
      }}
      onClick={onClickOnOrder}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-end",
          flexGrow: 1,
          mt: isDirectOrder && "4px",
        }}
      >
        {isDirectOrder && (
          <NewOrderIcon
            width={size === "small" ? 18 : 28}
            height={size === "small" ? 22 : 30.755}
          />
        )}
        {isThirdPartyOrder && <ThirdPartyItem size={size} />}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "18px",
          borderRadius: "0 0 4px 4px",
        }}
      >
        {isDirectOrder && (
          <Typography
            sx={{
              fontSize: 8,
              fontWeight: 600,
              color: "#5F6267",
            }}
          >
            Order
          </Typography>
        )}
      </Box>
    </Box>
  );
};

OrderItem.propTypes = {
  onClickOnOrder: func,
  size: string,
  hasThirdPartyItem: bool,
  order: object,
};

OrderItem.defaultProps = { size: "" };
