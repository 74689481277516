import { bool, shape, number } from "prop-types";
import { Box, Paper } from "@mui/material";
import { TotalSummaryInfoBlock } from "../../../../../../components";
import theme from "theme";

export const CustomersTotalSummaries = ({ isLoading, totalSummaries }) => {
  return (
    <Paper
      sx={{
        px: 1.75,
        height: "64px",
        maxHeight: "64px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
      elevation={0}
      variant="outlined"
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <TotalSummaryInfoBlock
          isLoading={isLoading}
          items={[
            {
              name: "Total Groups",
              value: totalSummaries?.totalParentCustomers ?? 0,
            },
            {
              name: "Total Customers",
              value: totalSummaries?.totalCustomers ?? 0,
            },
            {
              name: "Active Customers",
              value: totalSummaries?.totalActiveCustomers ?? 0,
              valueStyles: { color: theme.palette.primary.main },
            },
            {
              name: "Inactive Customers",
              value: totalSummaries?.totalInactiveCustomers ?? 0,
              valueStyles: { color: theme.palette.error.main },
            },
            {
              name: "Prospects",
              value: totalSummaries?.totalProspects ?? 0,
              valueStyles: { color: theme.palette.yellow.main },
            },
          ]}
        />
      </Box>
    </Paper>
  );
};

CustomersTotalSummaries.propTypes = {
  isLoading: bool,
  totalSummaries: shape({
    totalCustomerGroups: number,
    totalCustomers: number,
    totalActiveCustomers: number,
    totalInactiveCustomers: number,
  }),
};
