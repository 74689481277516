import { object, func } from "prop-types";
import { Box, Grid, TextField } from "@mui/material";
import { StyledTooltip } from "components";
import { Controller } from "react-hook-form";
import NumberFormat from "react-number-format";
import useStyles from "../../../styles";

export const BarcodeBlock = ({ errors, setValue, control }) => {
  const classes = useStyles();

  return (
    <Grid xs={12} item>
      <StyledTooltip
        placement="right"
        arrow
        title="Max 14 symbols allowed!"
        sx={{ zIndex: (theme) => theme.zIndex.modal - 1 }}
        open={errors.barcode?.type === "barcode"}
      >
        <Box sx={{ display: "flex", gap: 2 }}>
          <Controller
            render={({ field, fieldState: { error } }) => {
              return (
                <NumberFormat
                  customInput={TextField}
                  fullWidth
                  size="small"
                  InputProps={{
                    className: classes.textInput,
                  }}
                  InputLabelProps={{
                    className: classes.inputLabel,
                  }}
                  error={!!error}
                  label="Barcode"
                  {...field}
                  onChange={(e) => {
                    const v = e.target.value || "";
                    setValue("barcode", v, { shouldDirty: true });
                  }}
                />
              );
            }}
            name="barcode"
            control={control}
          />
        </Box>
      </StyledTooltip>
    </Grid>
  );
};

BarcodeBlock.propTypes = {
  errors: object,
  setValue: func,
  control: object,
};
