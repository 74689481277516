/* eslint-disable react/prop-types */
import React from "react";

export const TrashIcon = ({ fill = "#b5b5ac", ...props }) => {
  return (
    <svg
      id="icons8-trash-can"
      xmlns="http://www.w3.org/2000/svg"
      width="14.583"
      height="17.5"
      viewBox="0 0 14.583 17.5"
      {...props}
    >
      <path
        id="icons8-trash-can-2"
        data-name="icons8-trash-can"
        d="M11.537,4l-.228.205-.524.524H6V6.188h.729V19.313A2.2,2.2,0,0,0,8.917,21.5h8.75a2.2,2.2,0,0,0,2.188-2.187V6.188h.729V4.729H15.8l-.524-.524L15.046,4Zm.615,1.458h2.279l.524.524.228.205H18.4V19.313a.727.727,0,0,1-.729.729H8.917a.727.727,0,0,1-.729-.729V6.188H11.4l.228-.205ZM9.646,9.1v8.021H11.1V9.1Zm2.917,0v8.021h1.458V9.1Zm2.917,0v8.021h1.458V9.1Z"
        transform="translate(-6 -4)"
        fill={fill}
      />
    </svg>
  );
};
