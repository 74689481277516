import { useEffect, useMemo, useState } from "react";
import { bool, func, object, string, array } from "prop-types";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import NumberFormat from "react-number-format";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  Box,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import useStyles from "./styles";
import { CrossIcon, CVVIcon } from "../../../../../../components/Icons";

import {
  LargeCheckbox,
  StyledButton,
  StyledTextField,
  TypographyAddress,
  Loader,
  CardIconComponent,
  StyledTooltip,
} from "../../../../../../components";
import AddressField from "../../../AddressField/AddressField";
import { WarningBox } from "./components";

import { cardMask, defaultValues } from "./CustomerCardPopup.constants";
import { validationSchema } from "./CustomerCardPopup.validations";
import { error, success } from "../../../../../../utils/notifications";

import {
  addCustomerCardService,
  addDistributorPaymentCardService,
  replaceDistributorPaymentCardService,
} from "../../../../../../services/stripe";
import { getOrderByIdAction } from "../../../../../../redux/actions/orders";
import {
  getDistributorPaymentCards,
  getDistributorPaymentCardsAction,
} from "../../../../../../redux/actions/auth";
import {
  currentUserSelector,
  distributorPaymentCardsSelector,
} from "../../../../../../redux/selectors/auth";

const selector = createSelector(
  currentUserSelector,
  distributorPaymentCardsSelector,
  (currentUser, distributorPaymentCards) => ({
    currentUser,
    distributorPaymentCards,
  })
);

const CustomerCardPopup = ({
  isOpen,
  handleClose,
  customerId,
  distributorId,
  orderId,
  billingAddress,
  handleSave,
  replacedCardId,
  setCardDefault,
  // skipContactValidate,
  customerCreditCards,
  headers,
  isRegistration,
  successMsg,
}) => {
  const { currentUser, distributorPaymentCards } = useSelector(selector);
  const dispatch = useDispatch();

  const [state, setState] = useState({
    toggleUnit: false,
    edit: false,
    loading: false,
    cardValue: "",
    cardValueType: "",
  });
  const classes = useStyles({ toggleUnit: state.toggleUnit });

  const hasCustomerDefaultCard = useMemo(() => {
    if (customerCreditCards) {
      return customerCreditCards.some((card) => card?.defaultMethod);
    }
  }, [customerCreditCards]);

  const hasDistributorDefaultCard = useMemo(() => {
    if (setCardDefault) return false;
    if (customerCreditCards === undefined) {
      return distributorPaymentCards?.some((card) => card?.defaultMethod);
    }
  }, [customerCreditCards, distributorPaymentCards, setCardDefault]);

  const setDefaultCreditCard = useMemo(() => {
    if (customerCreditCards) {
      return hasCustomerDefaultCard ? defaultValues?.defaultMethod : true;
    }
    if (customerCreditCards === undefined) {
      return hasDistributorDefaultCard ? defaultValues?.defaultMethod : true;
    }
  }, [customerCreditCards, hasCustomerDefaultCard, hasDistributorDefaultCard]);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    setError,
    formState: { errors },
    trigger,
    clearErrors,
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      ...defaultValues,
      fid: Date.now(),
      billingAddress: billingAddress?.formatted_address
        ? billingAddress
        : defaultValues.billingAddress,
      defaultMethod: setDefaultCreditCard,
    },
    resolver: yupResolver(
      validationSchema({
        editingAddress: state.edit || customerId,
        isEdit: false,
        cardValueType: state.cardValueType,
      })
    ),
  });

  const formField = useWatch({ control });

  const currentUserHasBillingAddress = useMemo(() => {
    if (isRegistration) return !!formField?.billingAddress?.formatted_address;
    return !!currentUser?.billingAddress?.formatted_address;
  }, [
    currentUser?.billingAddress?.formatted_address,
    formField?.billingAddress?.formatted_address,
    isRegistration,
  ]);

  useEffect(() => {
    reset({
      ...defaultValues,
      fid: Date.now(),
      billingAddress: billingAddress?.formatted_address
        ? billingAddress
        : defaultValues.billingAddress,
      defaultMethod: setDefaultCreditCard,
    });
  }, [
    billingAddress,
    hasCustomerDefaultCard,
    isOpen,
    reset,
    setDefaultCreditCard,
  ]);

  const getErrorMessage = (err) => {
    if (err === "Your card number is incorrect.") {
      setError("number", { message: err });
      return;
    }
    if (err === "Your card's security code is incorrect.") {
      setError("cvc", { message: err });
      return;
    }
    if (err === "Your card has expired.") {
      setError("exp", { message: err });
      return;
    }
    if (err === "Your card has insufficient funds.") {
      setError("number", { message: err });
      return;
    }
    error("Something went wrong!");
    handleClose();
  };

  const onSubmit = (data) => {
    if (!currentUserHasBillingAddress) {
      setError("billingAddress", {
        message: "Please add a default Billing Address before saving.",
      });
      return;
    }
    // SD-3654
    // if (!currentUserHasContacts && !skipContactValidate) {
    //   setError("contacts", {
    //     message: "Please add a primary Contact before saving.",
    //   });
    //   return;
    // }

    const { exp, fid, ...saveData } = data;
    const expSplit = data.exp.split(" / ");
    const dataCard = {
      ...saveData,
      expMonth: expSplit[0],
      expYear: "20" + expSplit[1],
      billingAddress: state.edit ? data.billingAddress : billingAddress,
      number: data.number.split(" ").join(""),
    };

    if (isRegistration) {
      setState((prop) => ({ ...prop, loading: true }));

      if (replacedCardId) {
        replaceDistributorPaymentCardService(replacedCardId, dataCard, headers)
          .then((res) => handleSave(res))
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.error(err);
            getErrorMessage(err?.response?.data?.error);
          })
          .finally(() => {
            setState((prop) => ({ ...prop, loading: false }));
          });
        return;
      }

      addDistributorPaymentCardService(dataCard, headers)
        .then((res) => handleSave(res))
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
          getErrorMessage(err?.response?.data?.error);
        })
        .finally(() => {
          setState((prop) => ({ ...prop, loading: false }));
        });
      return;
    }

    if (customerId) {
      setState((prop) => ({ ...prop, loading: true }));
      addCustomerCardService(customerId, dataCard)
        .then((res) => {
          if (orderId) {
            dispatch(getOrderByIdAction(orderId));
          }
          handleSave(res);
          success(successMsg ? successMsg : "The card was added!");
          handleClose();
        })
        .catch((err) => {
          getErrorMessage(err?.response?.data?.error);
          // eslint-disable-next-line no-console
          console.error(err);
        })
        .finally(() => {
          setState((prop) => ({ ...prop, loading: false }));
        });
    }
    if (distributorId) {
      setState((prop) => ({ ...prop, loading: true }));
      if (replacedCardId) {
        replaceDistributorPaymentCardService(replacedCardId, dataCard)
          .then((res) => {
            success("The card was replaced!");
            handleSave(res.rows);
            dispatch(getDistributorPaymentCards(res.rows));
            handleClose();
          })
          .catch((err) => {
            getErrorMessage(err?.response?.data?.error);
            // eslint-disable-next-line no-console
            console.error(err);
          })
          .finally(() => {
            setState((prop) => ({ ...prop, loading: false }));
          });
      }
      if (!replacedCardId) {
        addDistributorPaymentCardService(dataCard)
          .then(() => {
            if (orderId) {
              dispatch(getOrderByIdAction(orderId));
            }
            success(successMsg ? successMsg : "The card was added!");
            handleClose();
          })
          .then(() => {
            dispatch(getDistributorPaymentCardsAction());
          })
          .catch((err) => {
            getErrorMessage(err?.response?.data?.error);
            // eslint-disable-next-line no-console
            console.error(err);
          })
          .finally(() => {
            setState((prop) => ({ ...prop, loading: false }));
          });
      }
    }
  };

  useEffect(() => {
    setState((prop) => ({
      ...prop,
      cardValueType:
        cardMask.find((m) => m.regex.test(+state.cardValue))?.cardtype ||
        "Default",
    }));
  }, [state.cardValue]);

  useEffect(() => {
    if (errors?.billingAddress) {
      setState((prop) => ({ ...prop, edit: true, toggleUnit: true }));
    }
  }, [errors?.billingAddress]);

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        setState((prop) => ({ ...prop, edit: false }));
        handleClose();
      }}
      PaperProps={{ sx: { maxHeight: "100vh" } }}
    >
      <Loader isLoading={state.loading} />
      <DialogTitle className={classes.dialogTitle}>
        <Typography className={classes.title}>Add card</Typography>
        <IconButton onClick={handleClose}>
          <CrossIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          justifyContent: "center",
          padding: 0,
        }}
        className={classes.dialogContent}
      >
        <Box
          component="form"
          id="customer-card-details-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Typography className={classes.contentTitle}>
            Add a credit or debit card
          </Typography>
          <Collapse in={!!Object.keys(errors).length}>
            <WarningBox sx={{ mt: 1 }} errors={Object.entries(errors)} />
          </Collapse>
          <Box mt="14px">
            <Typography className={classes.textFieldTitle}>
              Name on card
            </Typography>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <StyledTextField
                  error={error?.message || ""}
                  noErrorMessage
                  {...field}
                />
              )}
              name="name"
              control={control}
            />
          </Box>
          <Box mt="8px">
            <Typography className={classes.textFieldTitle}>
              Card number
            </Typography>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <NumberFormat
                  onValueChange={(v) =>
                    setState((prop) => ({ ...prop, cardValue: v?.value }))
                  }
                  customInput={StyledTextField}
                  error={error?.message || ""}
                  placeholder="1234 1234 1234 1234"
                  format="#### #### #### ####"
                  endIcon={<CardIconComponent type={state.cardValueType} />}
                  noErrorMessage
                  {...field}
                />
              )}
              name="number"
              control={control}
            />
          </Box>

          <Box my="10px" display="flex" gap="13px">
            <Box width={state.toggleUnit ? "50%" : null}>
              <Typography className={classes.textFieldTitle}>
                Expiration Date
              </Typography>
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <NumberFormat
                    customInput={StyledTextField}
                    format="## / ##"
                    error={error?.message || ""}
                    placeholder="MM / YY"
                    noErrorMessage
                    {...field}
                  />
                )}
                name="exp"
                control={control}
              />
            </Box>
            <Box width={state.toggleUnit ? "50%" : null}>
              <Typography className={classes.textFieldTitle}>
                Security code
              </Typography>
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <StyledTextField
                    placeholder="CVC"
                    noErrorMessage
                    endIcon={
                      <InputAdornment position="end">
                        <CVVIcon />
                      </InputAdornment>
                    }
                    error={error?.message || ""}
                    {...field}
                  />
                )}
                name="cvc"
                control={control}
              />
            </Box>
          </Box>
          <Controller
            render={({ field }) => {
              return (
                <LargeCheckbox
                  disabled={
                    customerCreditCards
                      ? !hasCustomerDefaultCard
                      : !hasDistributorDefaultCard
                  }
                  checkedColor={
                    customerCreditCards
                      ? !hasCustomerDefaultCard
                        ? "#D5D9D9"
                        : "#47a06d"
                      : !hasDistributorDefaultCard
                      ? "#D5D9D9"
                      : "#47a06d"
                  }
                  size={16}
                  formSx={{ pl: "3px" }}
                  label={
                    <Typography className={classes.defaultMethodText}>
                      Set as primary payment method
                    </Typography>
                  }
                  {...field}
                  onChange={() => setValue("defaultMethod", !field.value)}
                  checked={field.value}
                />
              );
            }}
            name="defaultMethod"
            control={control}
          />
          <Divider className={classes.contentDivider} />
          <Box>
            <Box display="flex" alignItems="center" mt="14px" mb="7px">
              <Typography className={classes.addressTitle}>
                {state.edit ? "Current billing" : "Billing"} address
              </Typography>
              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  height: "14px",
                  mt: "3px",
                  mx: "4px",
                }}
              />
              {state.edit ? (
                <StyledButton
                  label="Cancel"
                  sx={{ minWidth: "20px", height: "20px", p: "1px" }}
                  fontWeight="400"
                  onClick={() => setState((prop) => ({ ...prop, edit: false }))}
                  color="error"
                />
              ) : (
                <StyledButton
                  label="Edit"
                  sx={{ minWidth: "20px", height: "20px", p: "1px" }}
                  fontWeight="400"
                  onClick={() => setState((prop) => ({ ...prop, edit: true }))}
                />
              )}
            </Box>
            <TypographyAddress
              address={billingAddress?.formatted_address}
              className={classes.billingDate}
            />
            {state.edit && (
              <Box>
                <Typography
                  className={classes.addressTitle}
                  mt="20px"
                  mb="12px"
                >
                  New billing address
                </Typography>
                <AddressField
                  withoutAbsoluteLabel
                  isEdit={state.edit}
                  control={control}
                  setValue={setValue}
                  fieldName="billingAddress"
                  clearErrors={clearErrors}
                  address={formField.billingAddress}
                  addressToggled={state.toggleUnit}
                  wrapperProps={{ mt: "16px" }}
                  noToggleLabel
                  toggleButtonProps={{ className: classes.toggleButton }}
                  hideUnit
                  curtainPropsStyles={{
                    maxHeight: state.toggleUnit ? null : 0,
                    display: state.toggleUnit ? "flex" : "none",
                  }}
                  selectProps={{
                    labelSx: {
                      "&.Mui-focused": {
                        top: "2.5px",
                      },
                      "&.MuiFormLabel-filled": {
                        top: "2.5px",
                      },
                    },
                  }}
                  autocompleteProps={{
                    placeholder: "Address",
                    InputProps: {
                      className: classes.textInput,
                      sx: {
                        height: "32px",
                        fontSize: "12px",
                        "&:first-child": {
                          "& fieldset": {
                            borderRadius: "4px 0 0 4px",
                          },
                        },
                      },
                    },
                    InputLabelProps: {
                      className: classes.inputLabel,
                    },
                  }}
                  onToggle={() => {
                    setState((prev) => ({
                      ...prev,
                      toggleUnit: !prev.toggleUnit,
                    }));
                  }}
                  error={!!errors.billingAddress}
                />
              </Box>
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <StyledButton
          color="cancel"
          variant="outlined"
          label="Cancel"
          sx={{ height: "27px" }}
          fontSize="10px"
          onClick={() => {
            setState((prop) => ({ ...prop, edit: false }));
            handleClose();
          }}
        />
        <StyledTooltip
          title="Billing address is missing"
          placement="top"
          arrow
          disableHoverListener={!errors?.billingAddress}
        >
          <Box ml={1} onMouseEnter={() => trigger()}>
            <StyledButton
              variant="contained"
              label="Save"
              fontSize="10px"
              type="submit"
              form="customer-card-details-form"
              sx={{ height: "27px" }}
            />
          </Box>
        </StyledTooltip>
      </DialogActions>
    </Dialog>
  );
};

CustomerCardPopup.propTypes = {
  isOpen: bool,
  handleClose: func,
  customerId: string,
  distributorId: string,
  orderId: string,
  billingAddress: object,
  handleSave: func,
  replacedCardId: string,
  setCardDefault: bool,
  skipContactValidate: bool,
  customerCreditCards: array,
  headers: object,
  isRegistration: bool,
  successMsg: string,
};

export default CustomerCardPopup;
