import { getCurrentUser } from "../../services/account";
import { getDistributorPaymentCardsService } from "../../services/stripe";
import { error } from "../../utils/notifications";

const SET_CURRENT_USER = "SET_CURRENT_USER";
const DELETE_CURRENT_USER = "DELETE_CURRENT_USER";
const GET_DISTRIBUTOR_PAYMENT_CARDS = "GET_DISTRIBUTOR_PAYMENT_CARDS";
const START_LOADING_CURRENT_USER = "START_LOADING_CURRENT_USER";
const END_LOADING_CURRENT_USER = "END_LOADING_CURRENT_USER";
const SET_REPRESENTATIVE_USER = "SET_REPRESENTATIVE_USER";

const setCurrentUser = (currentUser) => ({
  type: SET_CURRENT_USER,
  payload: currentUser,
});

const setRepresentativeUser = (payload) => ({
  type: SET_REPRESENTATIVE_USER,
  payload,
});

const deleteCurrentUser = () => ({
  type: SET_CURRENT_USER,
});

const startLoadingCurrentUser = () => ({
  type: START_LOADING_CURRENT_USER,
});
const endLoadingCurrentUser = () => ({
  type: END_LOADING_CURRENT_USER,
});

export const setCurrentUserAction = () => {
  return (dispatch) => {
    dispatch(startLoadingCurrentUser());
    getCurrentUser()
      .then((res) => {
        dispatch(setCurrentUser(res));
      })
      .catch((err) => {
        error(err?.data?.response?.message || "Something went wrong.");
        // eslint-disable-next-line no-console
        console.error(err?.data?.response?.message);
      })
      .finally(() => dispatch(endLoadingCurrentUser()));
  };
};

export const getDistributorPaymentCards = (payload) => ({
  type: GET_DISTRIBUTOR_PAYMENT_CARDS,
  payload,
});

const getDistributorPaymentCardsAction = () => {
  return (dispatch) => {
    return (
      getDistributorPaymentCardsService()
        .then((res) => dispatch(getDistributorPaymentCards(res?.rows)))
        // eslint-disable-next-line no-console
        .catch((err) => console.error(err.message))
    );
  };
};

const updateUserFieldAction = (field, value) => {
  return (dispatch, getState) => {
    const state = getState();
    const currentUser = state.auth.currentUser;
    dispatch(setCurrentUser({ ...currentUser, [field]: value }));
  };
};

export default {
  SET_CURRENT_USER,
  setCurrentUser,
  setRepresentativeUser,
  DELETE_CURRENT_USER,
  deleteCurrentUser,
  GET_DISTRIBUTOR_PAYMENT_CARDS,
  getDistributorPaymentCardsAction,
  updateUserFieldAction,
  START_LOADING_CURRENT_USER,
  END_LOADING_CURRENT_USER,
  SET_REPRESENTATIVE_USER,
};

export {
  SET_CURRENT_USER,
  setCurrentUser,
  setRepresentativeUser,
  DELETE_CURRENT_USER,
  deleteCurrentUser,
  GET_DISTRIBUTOR_PAYMENT_CARDS,
  getDistributorPaymentCardsAction,
  updateUserFieldAction,
  START_LOADING_CURRENT_USER,
  END_LOADING_CURRENT_USER,
  SET_REPRESENTATIVE_USER,
};
