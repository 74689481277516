import { any } from "prop-types";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { getTokenFromLocalStorage } from "../../helpers/auth";

const PrivateRoute = ({ children }) => {
  const currentUser = useSelector(({ auth }) => auth.currentUser);
  const token = getTokenFromLocalStorage();

  if (!currentUser && !token) {
    return <Navigate to="/login" />;
  }

  return children;
};

PrivateRoute.propTypes = {
  children: any,
};
PrivateRoute.defaultTypes = {
  children: null,
};

export default PrivateRoute;
