export const cl = {
  wrapper: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "33px",
  },
  paper: {
    width: "1126px",
    borderRadius: "8px",
    padding: "24px 32px 19px 34px",
  },
  title: { fontSize: 30, color: "#5F6267" },
  tabs: {
    ml: 1.1,
    mb: 0.4,
    minHeight: "36px",
    height: "36px",
    "& .MuiButtonBase-root.MuiTab-root": {
      minHeight: "36px",
    },
    "& .MuiTabs-indicator": {
      borderRadius: "4px 4px 0 0",
    },
  },
  tab: {
    fontSize: 19,
    color: "#757575",
    mx: 2,
    py: 0,
    px: 0.6,
    minWidth: 50,
  },
  tabLabel: { textTransform: "none" },
};
