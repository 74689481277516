import {} from "react";
import {} from "prop-types";
import { Box, Typography } from "@mui/material";
import StyledButton from "../../components/StyledButton";
import { FolderIcon } from "../../components/Icons/Files";

export const FolderPage = () => {
  return (
    <Box
      sx={{
        height: "calc(100vh - 140px)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <FolderIcon width={49.94} height={44.95} />
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: 600,
          color: "#1C1C19",
          mt: "26px",
          mb: "14px",
        }}
      >
        No files in this folder
      </Typography>
      <StyledButton
        sx={{ height: 28, width: 88, whiteSpace: "nowrap" }}
        fontSize="12px"
        variant="contained"
        label="Upload Files"
      />
    </Box>
  );
};

FolderPage.propTypes = {};
FolderPage.defaultProps = {};

export default FolderPage;
