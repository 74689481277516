/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  bool,
  func,
  object,
  any,
  number,
  array,
  oneOfType,
  string,
} from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import {
  Autocomplete,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { LargeCheckbox, StyledAvatar, StyledTextField } from "components";
import { CrossIcon } from "components/Icons";
import useStyles from "./styles";
import {
  formatDate,
  getFormattedDate,
  matchEmail,
  normalizeUppercaseString,
  photoUrl,
} from "helpers/helpers";
import { useDebounce } from "helpers/hooks";
import { getUserRecipients } from "services/account";
import { getPaymentIntentLinkService } from "services/orders";
import { validationSchema } from "./OrderEmailDialog.validations";
import { defaultValues, letterText } from "./OrderEmailDialog.constants";
import { EmailToBlock } from "./EmailToBlock";
import { DRAFT_ORDER_TYPES } from "utils/constants";
import { PreviewEmailBlock } from "./components/PreviewEmailBlock/PreviewEmailBlock";
import { ActionBlock } from "./components/ActionBlock/ActionBlock";
import { updateUserFieldAction } from "redux/actions/auth";

const OrderEmailDialog = ({
  isOpen,
  handleClose,
  order,
  handleSendEmail,
  isEdit,
  isDraft,
  isDuplicate,
  totalDiscount,
  deliveryFee,
  grandTotal,
  calculatedTotal,
  customer,
  loading,
  selectedContactEmail,
  oldOrder,
  freeCaseList,
}) => {
  const dispatch = useDispatch();
  const distributor = useSelector(({ auth }) => auth.currentUser);
  const { timeZone, showIncludedPrices } = distributor || {};
  const repsList = useSelector(({ reps }) => reps.reps);
  const rep = repsList?.find((r) => r?.id === order?.salesId);

  const customerName = useMemo(
    () => order?.customer?.displayName || order?.customer?.name,
    [order?.customer?.displayName, order?.customer?.name]
  );

  const formDate = formatDate({
    utc_date: oldOrder?.createdAt,
    formatThisYear: "MMM DD[,] H:mm a",
  });

  const { createOrderType } = order || {};
  const type = order?.type || order?.orderType || "";

  const hasCreatedOrderThirdParty = useMemo(() => {
    if (createOrderType === "THIRD_PARTY" && order?.salesId) {
      return { ...rep, role: "THIRD_PARTY" };
    }

    if (type === "THIRD_PARTY") {
      return { ...order?.salesDuplicate, role: "THIRD_PARTY" };
    }

    return false;
  }, [order, rep, type, createOrderType]);

  const orderState = useMemo(() => {
    if (!isOpen) return {};

    return {
      createOrder: !order?.id && !isEdit,
      editOrder: !order?.id && !!isEdit,
      justOpenOrder: !!order?.id && !isEdit,
    };
  }, [isEdit, isOpen, order?.id]);

  const isPreviewValid = useMemo(() => {
    return (
      order?.paymentStatus !== "PAID" &&
      type !== "THIRD_PARTY" &&
      distributor?.stripeAccountVerificationStatus === "VERIFIED"
    );
  }, [
    order?.paymentStatus,
    type,
    distributor?.stripeAccountVerificationStatus,
  ]);

  const isThirdPartyOrder =
    type === "THIRD_PARTY" || createOrderType === "THIRD_PARTY";

  const { control, handleSubmit, setValue, reset, setError, clearErrors } =
    useForm({
      mode: "onChange",
      defaultValues: {
        ...defaultValues,
        includePrices: isThirdPartyOrder ? showIncludedPrices : true,
        businessEmail: customer?.email || defaultValues?.businessEmail,
        contacts: customer
          ? [
              {
                customerId: customer?.id,
                defaultContact: true,
                email: customer?.email,
                id: customer?.customId?.customId,
                name: customer?.name,
                phone: customer?.phone,
                role: "Customer",
              },
            ]
          : defaultValues?.contacts,
        message: letterText(customerName, distributor?.name, isEdit),
      },
      resolver: yupResolver(validationSchema({ isCustomerEmail: !!customer })),
    });
  const classes = useStyles(isPreviewValid);

  const formField = useWatch({ control });

  const messageBoxRef = useRef(null);
  const [showScrollbar, setShowScrollbar] = useState(false);

  useEffect(() => {
    if (messageBoxRef.current) {
      const height = messageBoxRef.current.clientHeight;
      if (height) setShowScrollbar(height >= 260);
    }
  }, [formField.message]);

  useEffect(() => {
    if (!formField.others.some((o) => o.email === distributor.baseUser.email))
      setValue("sendMe", false);
  }, [formField.others, setValue, distributor.baseUser.email]);

  const [recipientsSearch, setRecipientsSearch] = useState("");
  const [contactsSearch, setContactsSearch] = useState("");
  const [recipientsLoading, setRecipientsLoading] = useState(false);

  const [CCList, setCCList] = useState([]);
  const [contactsList, setContactsList] = useState([]);
  const [invoiceLink, setInvoiceLink] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const recipientsSearchDebounced = useDebounce(recipientsSearch, 500);
  const contactsSearchDebounced = useDebounce(contactsSearch, 500);
  const cleanText = inputValue?.replace(/<br\s*\/?>/g, "\n");

  useEffect(() => setContactsSearch(""), [formField?.contacts]);
  useEffect(() => setRecipientsSearch(""), [formField?.others]);
  useEffect(() => {
    setInputValue(formField?.message);
  }, [formField?.message]);
  const daysInvoices = order?.paymentTerms?.daysInvoices || 0;

  const dueDate = useMemo(() => {
    if (!order?.fulfillBy) return "";

    const date = new Date(order.fulfillBy);
    return date.setDate(date?.getDate() + daysInvoices);
  }, [daysInvoices, order?.fulfillBy]);

  const formattedDueDate = moment(getFormattedDate(dueDate, timeZone)).format(
    "MM/DD/YYYY"
  );
  const dateT = order?.createdAt || oldOrder?.createdAt;
  const formattedDate = moment(getFormattedDate(dateT, timeZone)).format(
    "MM/DD/YYYY"
  );

  const isDisabled = useMemo(() => {
    if (
      (customer && !formField?.message.trim()) ||
      loading ||
      recipientsSearch.trim() ||
      contactsSearch.trim()
    )
      return true;
    const noContactsWithEmail = !formField?.contacts?.filter(
      ({ email, baseUser }) => !!email || !!baseUser?.email
    )?.length;
    const noSubject = !formField?.subject.trim();
    const disabled = noContactsWithEmail || noSubject;
    return disabled;
  }, [
    customer,
    formField?.contacts,
    formField?.message,
    formField?.subject,
    loading,
    contactsSearch,
    recipientsSearch,
  ]);

  const handleInputChange = (event) => {
    setInputValue(event.value);
  };

  const getPaymentLink = async () => {
    if (
      order?.paymentStatus === "PAID" ||
      order?.orderStatus === "CANCELED" ||
      [DRAFT_ORDER_TYPES.open, DRAFT_ORDER_TYPES.closed].includes(order?.status)
    )
      return;

    const res = await getPaymentIntentLinkService(order?.id);
    const { paymentIntentLink } = res || {};
    if (!paymentIntentLink) return;
    setInvoiceLink(paymentIntentLink);
  };

  useEffect(() => {
    if (isOpen && !isDuplicate && !isEdit && !orderState?.createOrder) {
      getPaymentLink();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order?.id, isOpen]);

  useEffect(() => {
    if (!isOpen) {
      setRecipientsSearch("");
      setContactsSearch("");
      setInvoiceLink(null);
      reset({
        ...defaultValues,
        // message: letterText(order?.customer?.name, distributor?.name, isEdit),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order?.id, isOpen]);

  const handleDeleteContact = (id) => {
    setValue(
      "contacts",
      formField.contacts.filter((c) => c.id !== id)
    );
  };
  const defaultContact = contactsList.find((cc) => cc.active === true);

  const orderCustomId = useMemo(
    () =>
      isDraft
        ? ""
        : order?.orderCustomId?.customId ||
          order?.customId?.customId ||
          order?.customId,
    [isDraft, order?.customId, order?.orderCustomId?.customId]
  );

  const setSubject = useMemo(() => {
    const isDraft = [DRAFT_ORDER_TYPES.open, DRAFT_ORDER_TYPES.closed].includes(
      order?.status
    );

    const subtext = orderCustomId || "[Order №]";

    if (isDraft) {
      return type === "THIRD_PARTY"
        ? `Order ${subtext} confirmed for ${distributor.name} (3rd Party)`
        : `Order ${subtext} confirmed`;
    }

    if (orderState?.justOpenOrder) {
      return type !== "THIRD_PARTY"
        ? `${distributor.name} - Order ${subtext}`
        : `Order ${subtext}`;
    }

    if (orderState?.editOrder || orderState?.createOrder) {
      return isEdit && !isDuplicate
        ? !isThirdPartyOrder
          ? `Order ${subtext} updated`
          : `Order ${subtext} updated for ${distributor.name} (3rd Party)`
        : !isThirdPartyOrder
        ? `Order ${subtext} confirmed`
        : `Order ${subtext} confirmed for ${distributor.name} (3rd Party)`;
    }
  }, [
    order,
    orderState?.justOpenOrder,
    orderState?.editOrder,
    orderState?.createOrder,
    type,
    distributor.name,
    isEdit,
    isDuplicate,
    isThirdPartyOrder,
    orderCustomId,
  ]);

  const setMessage = useMemo(() => {
    if (orderState?.justOpenOrder)
      return `Hello,\n\nWe appreciate your business. Please find your invoice details here. Feel free to contact us with any questions.\n\nKind regards,\n${distributor?.name} Team.`;
    if (orderState?.createOrder || orderState?.editOrder) {
      if ((type, createOrderType === "THIRD_PARTY")) {
        return `Hello,\n\nThere is a new order for ${customerName}. Please review and process the order.\n\nKind regards,\n${distributor?.name} Team.`;
      } else {
        return letterText(customerName, distributor?.name, isEdit);
      }
    }
    return "";
  }, [
    orderState?.justOpenOrder,
    orderState?.createOrder,
    orderState?.editOrder,
    customerName,
    distributor?.name,
    type,
    createOrderType,
    isEdit,
  ]);

  const defaultCustomerBusinessEmail = order?.customer?.email
    ? {
        customerId: order?.customer?.id,
        name: order?.customer?.name,
        email: order?.customer?.email,
      }
    : null;

  useEffect(() => {
    if (!isOpen) return;
    const selectContact = order?.customer?.contacts?.find(
      (contact) => contact.id === selectedContactEmail
    );
    const { customer: customerOfOrder } = order || {};
    const defaultContact = !selectedContactEmail
      ? defaultCustomerBusinessEmail
      : customerOfOrder?.contacts?.find(
          (c) => c?.email === selectContact?.email
        );

    {
      reset({
        ...defaultValues,
        subject:
          setSubject !== formField?.subject ? setSubject : formField?.subject,
        contacts: hasCreatedOrderThirdParty
          ? [hasCreatedOrderThirdParty]
          : defaultContact
          ? [defaultContact]
          : [],
        businessEmail: customerOfOrder?.email || "",
        message: setMessage,
        includePrices: isThirdPartyOrder ? showIncludedPrices : true,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order?.customer?.id, isOpen, customer]);

  useEffect(() => {
    const trimmedString = recipientsSearchDebounced.trim().trimStart();
    if (trimmedString) {
      setRecipientsLoading(true);
      return getUserRecipients({ search: trimmedString })
        .then((res) => {
          setRecipientsLoading(false);
          setCCList(res);
        })
        .catch(() => setRecipientsLoading(false));
    }
    setCCList(distributor?.contacts || []);
  }, [distributor, recipientsSearchDebounced]);

  useEffect(() => {
    const customerOfOrder = order?.customer;

    const currentCustomer = customer || customerOfOrder;

    if (hasCreatedOrderThirdParty && Array.isArray(currentCustomer?.contacts)) {
      return setContactsList([
        hasCreatedOrderThirdParty,
        ...currentCustomer?.contacts?.filter((c) => c?.email),
      ]);
    }
    if (!currentCustomer) return;

    const list = [
      ...(currentCustomer?.contacts?.filter((c) => c.email) || []),
      ...(repsList?.filter((c) => c.email || c?.baseUser?.email) || []),
    ];
    if (currentCustomer?.email)
      list.push({ ...currentCustomer, role: "Business", isCustomer: true });
    if (contactsSearchDebounced) {
      return setContactsList(
        list?.filter(
          (contact) =>
            contact?.email &&
            contact?.email
              .toLowerCase()
              .includes(contactsSearchDebounced.toLowerCase())
        )
      );
    }
    return setContactsList([...list]);
  }, [
    contactsSearchDebounced,
    customer,
    hasCreatedOrderThirdParty,
    order,
    repsList,
  ]);

  const onSubmit = (data) => {
    if (recipientsSearch.trim()) {
      return setError("others", {
        message: "Please add a valid email address.",
      });
    }

    if (contactsSearch.trim()) {
      return setError("contacts", {
        message: "Please add a valid email address.",
      });
    }

    const {
      message,
      subject,
      sendMe,
      files,
      includePaymentLink,
      includePrices,
    } = data || {};
    const updatedMessage = message?.replace(/\n/g, "<br>");
    const otherEmails = data?.others
      ?.map((o) => o.email.trim())
      ?.filter((email) => email !== distributor?.baseUser?.email);

    const recipientEmails = data?.contacts
      ?.filter(
        (c) =>
          typeof c === "object" &&
          ((c?.email && c?.role === "THIRD_PARTY") ||
            c?.id ||
            c?.customerId ||
            (c.id && !(c?.isCustomer || c?.role === "Customer")))
      )
      ?.map((c) => c?.email || (c?.baseUser && c?.baseUser?.email))
      ?.filter((email) => typeof email === "string");

    const isCreate = isDraft || !isEdit || isDuplicate;

    const formattedData = {
      recipientEmails,
      otherEmails,
      // businessEmail,
      includePaymentLink,
      message: updatedMessage,
      subject: isCreate ? undefined : subject,
      sendMe,
      ...(isThirdPartyOrder && { includePrices }),
    };

    if (isThirdPartyOrder)
      dispatch(updateUserFieldAction("showIncludedPrices", includePrices));

    if (customer?.id) {
      const params = {
        customer_id: customer?.id,
      };

      const uploadFormData = new FormData();

      Object.keys(formattedData).forEach((key) =>
        uploadFormData.append(key, JSON.stringify(formattedData[key]))
      );

      const filesArray = Object.keys(files).map((f) => files[f]);

      filesArray.forEach((file) => uploadFormData.append("file[]", file));

      handleSendEmail({ params, data: uploadFormData });
    } else {
      handleSendEmail(formattedData);
    }
  };

  const getCreateButtonLabel = () => {
    if (loading) return "Loading...";
    if (isDraft) return "Update Draft & Email";
    if (isEdit && !isDuplicate) return "Update Order & Email";
    return "Create Order & Email";
  };

  const processingIntoChip = () => {
    const newEmail = recipientsSearch.trim().trimStart();
    const newEmailC = contactsSearch.trim().trimStart();

    const alreadyAddedRecipients =
      !!formField.others.find((e) => e.email === newEmail) ||
      !!formField.contacts.find((e) => e.email === newEmail);

    const alreadyAddedContacts =
      !!formField.others.find((e) => e.email === newEmailC) ||
      !!formField.contacts.find((e) => e.email === newEmailC);

    if (!alreadyAddedRecipients && newEmail && matchEmail(newEmail)) {
      setRecipientsSearch("");
      setValue("others", [
        ...formField.others,
        {
          id: newEmail,
          email: newEmail,
        },
      ]);
      clearErrors("others");
    }

    if (!alreadyAddedContacts && newEmailC && matchEmail(newEmailC)) {
      setContactsSearch("");
      clearErrors("contacts");
      setValue("contacts", [
        ...formField.contacts,
        {
          id: newEmailC,
          email: newEmailC,
        },
      ]);
    }
  };
  const handlePressSpace = (e) => {
    const val = e.target.value;
    if (e.keyCode === 32 && val !== "") {
      processingIntoChip();
    }
  };

  const setBillingAddress = (order) => {
    if (order?.customer?.billToParentCustomer) {
      return order?.customer?.parentCustomer?.billingAddress?.formatted_address;
    }

    return (
      order?.customer?.billingAddress?.formatted_address ||
      customer?.billingAddress?.formatted_address
    );
  };

  const isVerifiedDist = useMemo(
    () => distributor?.stripeAccountVerificationStatus === "VERIFIED",
    [distributor?.stripeAccountVerificationStatus]
  );

  const showPaymentLinkCheckbox = useMemo(
    () =>
      isPreviewValid &&
      !isThirdPartyOrder &&
      formField.includePaymentLink &&
      isVerifiedDist,
    [
      formField.includePaymentLink,
      isPreviewValid,
      isThirdPartyOrder,
      isVerifiedDist,
    ]
  );

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      PaperProps={{ className: classes.dialog }}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Box sx={{ display: "inline-flex", gap: "15px" }}>
          <Typography className={classes.titleText}>Email Order</Typography>
        </Box>
        <IconButton onClick={handleClose}>
          <CrossIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          width: "100%",
          minWidth: "631px",
          padding: "20px 41px 0px 42px!important",
          display: "flex",
          overflow: "auto",
          height: "calc(100vh - 214px)",
        }}
      >
        <form
          id="order-email-form"
          onSubmit={handleSubmit(onSubmit)}
          className={classes.formBox}
        >
          <Box>
            <EmailToBlock
              customerName={
                order?.customer?.displayName ||
                order?.customer?.name ||
                customer?.displayName ||
                customer?.name
              }
              handlePressSpace={handlePressSpace}
              processingIntoChip={processingIntoChip}
              formattedBillingAddress={setBillingAddress(order)}
              {...{
                control,
                setValue,
                handleDeleteContact,
                contactsList,
                recipientsLoading,
                contactsSearch,
                setContactsSearch,
              }}
            />

            <Box className={classes.rowWrap} mt="14px" alignItems="center">
              <Box flex="0 0 15%">
                <Typography className={classes.rowTitle}>CC:</Typography>
              </Box>
              <Box flex="0 0 80%" maxWidth="80%" position="relative">
                <Controller
                  render={({ field, fieldState: { error } }) => (
                    <Autocomplete
                      multiple
                      freeSolo
                      disableCloseOnSelect
                      loading={recipientsLoading}
                      PaperComponent={(props) => (
                        <Box {...props} className={classes.recipientsTable} />
                      )}
                      size="small"
                      isOptionEqualToValue={(option, value) =>
                        option.email === value.email
                      }
                      sx={{
                        "& .MuiAutocomplete-paper": {
                          boxShadow: "none",
                        },
                      }}
                      className={classes.textfield}
                      getOptionLabel={(option) =>
                        option.email || option?.baseUser?.email
                      }
                      renderInput={(params) => (
                        <StyledTextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            value: recipientsSearch,
                          }}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: <></>,
                          }}
                          placeholder="Emails"
                          error={error?.message || ""}
                          noErrorMessage
                          className={classes.textfield}
                          value={recipientsSearch}
                          inputSx={{ color: "#707070" }}
                          onChange={(e) => setRecipientsSearch(e.target.value)}
                          endIcon={
                            recipientsLoading && <CircularProgress size={20} />
                          }
                          onKeyDown={handlePressSpace}
                          onBlur={processingIntoChip}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              height: "fit-content",
                              p: "5px!important",
                              fontSize: "12px",
                            },
                          }}
                        />
                      )}
                      ListboxProps={{
                        sx: {
                          p: 0,
                          maxHeight: "178px",
                          boxShadow: "none",
                          overflow: "overlay",
                        },
                      }}
                      renderOption={(props, recipient, { selected }) => (
                        <Grid
                          key={recipient?.id}
                          container
                          columns={10}
                          sx={{
                            backgroundColor:
                              selected && "rgba(66, 165, 127, 0.1)",
                          }}
                          {...props}
                          className={classes.recipientItem}
                        >
                          <Grid item xs={7}>
                            <Box
                              display="flex"
                              gap="11px"
                              alignItems="center"
                              pl="13px"
                            >
                              <StyledAvatar
                                src={photoUrl(
                                  recipient?.profilePhoto?.fileName
                                )}
                                name={recipient?.name}
                                sx={{
                                  height: "29px",
                                  width: "29px",
                                  backgroundColor: "#F5F5F5",
                                  color: "#7A7A7A",
                                  fontWeight: "700",
                                }}
                                stringStyle={{
                                  fontSize: "13px",
                                }}
                              />
                              <Box sx={{ width: "calc(100% - 40px)" }}>
                                <Typography noWrap>
                                  {recipient?.name}
                                </Typography>
                                <Typography fontWeight="400" noWrap>
                                  {recipient?.email}
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography noWrap>
                              {normalizeUppercaseString(recipient?.role) || "-"}
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                      options={CCList}
                      renderTags={(value, getTagProps) => (
                        <Box className={classes.recipientChipWrapper}>
                          {value
                            ?.filter((val) => !!val?.email)
                            ?.map((cc, index) => (
                              <Chip
                                key={index}
                                sx={{
                                  borderRadius: "10px",
                                  border: "1px solid #D4D4D4",
                                  backgroundColor: "#F8F8FA",
                                  height: "19px",
                                  maxWidth: "90%",
                                }}
                                size="small"
                                label={
                                  <Typography
                                    sx={{
                                      fontSize: "12px",
                                      lineHeight: 1.5,
                                    }}
                                    color="groundLighter.main"
                                    noWrap
                                  >
                                    {cc?.email}
                                  </Typography>
                                }
                                deleteIcon={<CloseIcon />}
                                onMouseDown={(e) => e.stopPropagation()}
                                {...getTagProps({ index })}
                              />
                            ))}
                        </Box>
                      )}
                      error={error?.message || ""}
                      {...field}
                      onChange={(event, newValue) => {
                        if (event.keyCode !== 8) {
                          setValue(field.name, newValue);
                        }
                      }}
                    />
                  )}
                  name="others"
                  control={control}
                />
              </Box>
            </Box>
            <Box className={classes.rowWrap} mt="23px" alignItems="center">
              <Box flex="0 0 15%">
                <Typography className={classes.rowTitle}>Subject:</Typography>
              </Box>
              <Box flex="0 0 80%">
                <Controller
                  render={({ field, fieldState: { error } }) => (
                    <StyledTextField
                      placeholder="Subject"
                      className={classes.textfield}
                      inputSx={{ color: "#707070" }}
                      disabled={!order?.createdAt && !isEdit ? false : true}
                      error={error?.message || ""}
                      {...field}
                    />
                  )}
                  name="subject"
                  control={control}
                />
              </Box>
            </Box>
            <Box className={classes.rowWrap} mt="25px">
              <Box flex="0 0 15%" mt="4px">
                <Typography className={classes.rowTitle}>Message:</Typography>
              </Box>
              <Box flex="0 0 80%">
                <Controller
                  render={({ field, fieldState: { error } }) => (
                    <Box ref={messageBoxRef}>
                      <StyledTextField
                        placeholder={
                          customer
                            ? "Example of the message"
                            : "Example of the message (optional)"
                        }
                        multiline
                        size="small"
                        inputSx={{ color: "#707070" }}
                        // onChange={handleInputChange(field)}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            height: "auto",
                            maxHeight: "260px",
                            minHeight: "86px",
                            overflowY: "auto",
                            display: "flex",
                            alignItems: "baseline",
                            "& textarea": {
                              overflow: showScrollbar
                                ? "scroll !important"
                                : "hidden !important",
                              maxHeight: "243px !important",
                            },
                          },
                        }}
                        className={classes.textfield}
                        error={error?.message || ""}
                        {...field}
                        onChange={(e) => {
                          handleInputChange(field);
                          field.onChange(e);
                        }}
                      />
                    </Box>
                  )}
                  name="message"
                  control={control}
                />
              </Box>
            </Box>

            <Box
              sx={{
                mt: "10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "baseline",
              }}
            >
              <Controller
                render={({ field }) => (
                  <LargeCheckbox
                    label="Send me a copy of this email"
                    formSx={{ pl: "15%", ml: "-9px" }}
                    size={16}
                    labelSx={{ fontSize: "11px", color: "#363531" }}
                    checked={!!field.value}
                    {...field}
                    onChange={() => {
                      setValue(field.name, !field.value);
                      if (!field.value)
                        return setValue("others", [
                          ...formField.others,
                          { email: distributor.baseUser.email },
                        ]);
                      setValue(
                        "others",
                        formField.others.filter(
                          (o) => o.email !== distributor.baseUser.email
                        )
                      );
                    }}
                  />
                )}
                name="sendMe"
                control={control}
              />

              {isThirdPartyOrder && (
                <Controller
                  render={({ field }) => (
                    <LargeCheckbox
                      label="Include pricing"
                      formSx={{ pl: "15%", ml: "-9px" }}
                      size={16}
                      labelSx={{ fontSize: "11px", color: "#363531" }}
                      checked={!!field.value}
                      {...field}
                    />
                  )}
                  name="includePrices"
                  control={control}
                />
              )}
            </Box>

            {isVerifiedDist && !isThirdPartyOrder && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "baseline",
                }}
              >
                <Controller
                  render={({ field }) => (
                    <LargeCheckbox
                      label="Include payment link"
                      formSx={{ pl: "15%", ml: "-9px" }}
                      size={16}
                      labelSx={{ fontSize: "11px", color: "#363531" }}
                      checked={!!field.value}
                      {...field}
                    />
                  )}
                  name="includePaymentLink"
                  control={control}
                />
              </Box>
            )}
          </Box>
        </form>

        <PreviewEmailBlock
          {...{
            order,
            isEdit,
            isDuplicate,
            totalDiscount,
            deliveryFee,
            grandTotal,
            calculatedTotal,
            oldOrder,
            freeCaseList,
            formDate,
            orderCustomId,
            isThirdPartyOrder,
            showPaymentLinkCheckbox,
            dueDate,
            formattedDueDate,
            formattedDate,
            invoiceLink,
            cleanText,
            hasCreatedOrderThirdParty,
            type,
            createOrderType,
            customer,
            defaultContact,
            hasShowPrices: isThirdPartyOrder ? formField.includePrices : true,
          }}
        />
      </DialogContent>

      <ActionBlock {...{ handleClose, getCreateButtonLabel, isDisabled }} />
    </Dialog>
  );
};

OrderEmailDialog.propTypes = {
  isOpen: bool,
  handleClose: func,
  order: object,
  handleSendEmail: func,
  isEdit: bool,
  isDraft: bool,
  isDuplicate: bool,
  totalDiscount: oneOfType([number, string]),
  deliveryFee: oneOfType([number, string]),
  grandTotal: oneOfType([number, string]),
  calculatedTotal: oneOfType([number, string]),
  customer: object,
  loading: bool,
  selectedContactEmail: any,
  oldOrder: object,
  freeCaseList: array,
};

OrderEmailDialog.defaultProps = {
  order: {
    products: [],
    createdAt: "",
    orderStatus: "",
    customId: { customId: "" },
  },
  customer: {
    customId: { customId: "" },
  },
  freeCaseList: [],
};

export default OrderEmailDialog;
