import { object, func, array, string } from "prop-types";
import { Grid, IconButton, InputAdornment, MenuItem } from "@mui/material";
import { Controller } from "react-hook-form";
import { StyledSelect, StyledTextField } from "components";
import { CrossIcon, SearchIcon } from "components/Icons";
import useStyles from "../../../styles";

export const CountryBlock = ({
  control,
  setValue,
  countryOfOriginList,
  countryOfOriginSearch,
  setCountryOfOriginSearch,
}) => {
  const classes = useStyles();

  return (
    <Grid xs={12} item>
      <Controller
        render={({ field, fieldState: { error } }) => (
          <StyledSelect
            adminIsAllowed
            error={error ? error.message : ""}
            noErrorMessage
            fullWidth
            label="Country of Origin"
            labelSx={{
              color: "#B5B5AC!important",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: 1.2,
              "&.Mui-focused": {
                transform: "translate(14px, -5px) scale(0.75)",
              },
              "&.MuiInputLabel-shrink": {
                transform: "translate(14px, -5px) scale(0.75)",
              },
            }}
            fontSize={"14px"}
            PaperPropsSx={{ "& ul": { pt: 0 } }}
            onClose={() => setCountryOfOriginSearch("")}
            inputProps={{
              classes: {
                icon: classes.selectIcon,
              },
            }}
            {...field}
            data-testid="country_of_origin"
          >
            <MenuItem
              sx={{
                p: "10px",
                "&:hover": { backgroundColor: "#FFF !important" },
                "&.Mui-focused": { bgcolor: "#FFF !important" },
                "&.Mui-focusVisible": { bgcolor: "#FFF !important" },
                "&.Mui-selected": { bgcolor: "#FFF !important" },
                "&.Mui-selected:hover": { bgcolor: "#FFF" },
              }}
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
            >
              <Grid xs={12} item>
                <StyledTextField
                  adminIsAllowed
                  fullWidth
                  size="small"
                  value={countryOfOriginSearch}
                  onChange={(e) => setCountryOfOriginSearch(e.target.value)}
                  placeholder="Search country of origin"
                  onClick={(e) => e.stopPropagation()}
                  InputProps={{
                    className: classes.textInputSearch,
                    endAdornment: (
                      <>
                        <InputAdornment
                          sx={{ position: "absolute", left: 9 }}
                          position="start"
                        >
                          <SearchIcon />
                        </InputAdornment>
                        {countryOfOriginSearch && (
                          <InputAdornment position="end">
                            <IconButton
                              sx={{ p: "1px" }}
                              onClick={() => setCountryOfOriginSearch("")}
                            >
                              <CrossIcon size="15" />
                            </IconButton>
                          </InputAdornment>
                        )}
                      </>
                    ),
                  }}
                  InputLabelProps={{ className: classes.inputLabel }}
                />
              </Grid>
            </MenuItem>

            <MenuItem value={""} sx={{ opacity: "50%" }}>
              not specified
            </MenuItem>

            {countryOfOriginList.map((country) => (
              <MenuItem
                onClick={() => setValue("countryOfOrigin", country)}
                key={country}
                value={country}
              >
                {country}
              </MenuItem>
            ))}
          </StyledSelect>
        )}
        name="countryOfOrigin"
        control={control}
      />
    </Grid>
  );
};

CountryBlock.propTypes = {
  control: object,
  setValue: func,
  countryOfOriginList: array,
  countryOfOriginSearch: string,
  setCountryOfOriginSearch: func,
};
CountryBlock.defaultProps = {
  control: {},
  setValue: () => {},
  countryOfOriginList: [],
  countryOfOriginSearch: "",
  setCountryOfOriginSearch: () => {},
};
