import { useEffect, useState } from "react";
import { array, func, string } from "prop-types";
import lodash, { cloneDeep } from "lodash";
import moment from "moment-timezone";
import { Box, Paper, Tab, Tabs, Typography } from "@mui/material";
import AdapterMoment from "@mui/lab/AdapterMoment";
import { DayButton } from "../../../../../components/Buttons";
import IOSSwitch from "../../../../../components/IOSSwitch/IOSSwitch";
import { StyledTimePicker } from "../../../../../components/TextFields/TextFields";
import useStyles from "./styles";
import { Controller, useForm, useWatch } from "react-hook-form";
import { days, defaultValues } from "./RepsTab.constants";
import { useDebounce } from "../../../../../helpers/hooks";
import { useAdmin } from "helpers/helpers";

const RepsTab = ({ notifications, updateNotifications, timeZone }) => {
  const isAdmin = useAdmin();
  const classes = useStyles();
  const tabs = [
    { role: "SALES", name: "Sales Reps" },
    { role: "MERCHANDISER", name: "Merchandiser Reps" },
  ];

  const [currentTab, setCurrentTab] = useState(tabs[0].role);

  const handleCheckWeekday = (day) => {
    const fieldDays = [...formField.days];
    const dayIndex = fieldDays.findIndex((fieldDay) => fieldDay === day);
    if (dayIndex === -1) return setValue("days", [...fieldDays, day]);
    setValue(
      "days",
      fieldDays.filter((fieldDay) => fieldDay !== day)
    );
  };

  const [date, setDate] = useState({});

  useEffect(() => {
    const date = notifications.find((not) => not.role === currentTab) || {};
    const dateTemp = cloneDeep(date);

    const d = moment();
    const preparedData = { year: d.year(), month: d.month(), date: d.date() };

    dateTemp.clockInAt = dateTemp.clockInAt
      ? moment(dateTemp?.clockInAt).utcOffset(0).set(preparedData).toISOString()
      : "";

    dateTemp.clockOutAt = dateTemp.clockOutAt
      ? moment(dateTemp?.clockOutAt)
          .utcOffset(0)
          .set(preparedData)
          .toISOString()
      : "";

    setDate(dateTemp);
  }, [currentTab, notifications, timeZone]);

  const { control, setValue, reset } = useForm({
    mode: "onChange",
    defaultValues: {
      ...defaultValues,
      ...date,
    },
  });

  useEffect(() => {
    reset({
      ...defaultValues,
      ...date,
    });
  }, [reset, currentTab, date]);

  const formField = useWatch({ control });

  useEffect(() => {
    if (formField.clockIn && !formField.clockInAt) {
      const isoTime = new Date();
      isoTime.setHours(8);
      isoTime.setMinutes(0);

      setValue("clockInAt", isoTime.toISOString());
    }
    if (formField.clockOut && !formField.clockOutAt) {
      const isoTime = new Date();
      isoTime.setHours(17);
      isoTime.setMinutes(0);

      setValue("clockOutAt", isoTime.toISOString());
    }
  }, [
    formField.clockIn,
    formField.clockInAt,
    formField.clockOut,
    formField.clockOutAt,
    setValue,
  ]);

  const formFieldDebounced = useDebounce(formField, 500);

  useEffect(() => {
    const { distributorId, id, role, type, ...uploadData } = formFieldDebounced;
    const fieldsForCompare = {
      ...notifications.find((not) => not.role === currentTab),
    };
    if (
      !lodash.isEqual(fieldsForCompare, formFieldDebounced) &&
      formFieldDebounced.id
    )
      updateNotifications(formFieldDebounced.id, uploadData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formFieldDebounced, notifications?.length]);

  return (
    <>
      <Paper sx={{ boxShadow: "none", borderRadius: "8px" }}>
        <Box
          sx={{ borderBottom: "1px solid #D5D9D9" }}
          className={classes.repsHeader}
        >
          <Typography fontSize="30px" color="#5F6267">
            Representatives
          </Typography>
          <Tabs
            value={currentTab}
            onChange={(e, newValue) => setCurrentTab(newValue)}
            sx={{ ml: "-16px" }}
          >
            {tabs.map((tab) => (
              <Tab
                className="profile-tabs"
                sx={{
                  fontSize: "20px",
                  color: "#707070",
                }}
                key={tab.name}
                label={<Box sx={{ textTransform: "none" }}>{tab.name}</Box>}
                value={tab.role}
              />
            ))}
          </Tabs>
        </Box>

        <Box className={classes.repsBody}>
          <Box className={classes.notifications}>
            <Typography className={classes.blockTitle}>
              Notification Settings
            </Typography>
            <Paper className={classes.buttonBlock}>
              <Box display="flex">
                <Controller
                  render={({ field, fieldState: { error } }) => (
                    <IOSSwitch
                      disabled={isAdmin}
                      label={
                        <Typography className={classes.switchLabel}>
                          Reminder to clock in at
                        </Typography>
                      }
                      checked={formField.clockIn}
                      formSx={{ flex: "0 0 34%" }}
                      error={error ? error.message : ""}
                      {...field}
                      onChange={(e) => {
                        if (isAdmin) return;
                        field.onChange(e);
                      }}
                    />
                  )}
                  name="clockIn"
                  control={control}
                />
                <Controller
                  render={({ field, fieldState: { error } }) => (
                    <StyledTimePicker
                      dateLibInstance={moment}
                      dateAdapter={AdapterMoment}
                      timezone={timeZone}
                      textFieldProps={{
                        fullWidth: false,
                        formSx: {
                          backgroundColor: "#ffffff",
                        },
                      }}
                      disabled={isAdmin || !formField.clockIn}
                      error={error ? error.message : ""}
                      {...field}
                      value={moment.tz(field.value, timeZone)}
                      onChange={(time) => {
                        if (isAdmin) return;
                        const isoTime = new Date(time).toISOString();
                        setValue("clockInAt", isoTime);
                      }}
                    />
                  )}
                  name="clockInAt"
                  control={control}
                />
              </Box>
            </Paper>
            <Paper className={classes.buttonBlock}>
              <Box display="flex">
                <Controller
                  render={({ field, fieldState: { error } }) => (
                    <IOSSwitch
                      disabled={isAdmin}
                      label={
                        <Typography className={classes.switchLabel}>
                          Reminder to clock out at
                        </Typography>
                      }
                      checked={formField.clockOut}
                      error={error ? error.message : ""}
                      formSx={{ flex: "0 0 34%" }}
                      {...field}
                      onChange={(e) => {
                        if (isAdmin) return;
                        field.onChange(e);
                      }}
                    />
                  )}
                  name="clockOut"
                  control={control}
                />
                <Controller
                  render={({ field, fieldState: { error } }) => (
                    <StyledTimePicker
                      dateLibInstance={moment}
                      dateAdapter={AdapterMoment}
                      timezone={timeZone}
                      textFieldProps={{
                        fullWidth: false,
                        formSx: {
                          backgroundColor: "#ffffff",
                        },
                      }}
                      disabled={isAdmin || !formField.clockOut}
                      error={error ? error.message : ""}
                      {...field}
                      value={moment.tz(field.value, timeZone)}
                      onChange={(time) => {
                        const isoTime = new Date(time).toISOString();
                        setValue("clockOutAt", isoTime);
                      }}
                    />
                  )}
                  name="clockOutAt"
                  control={control}
                />
              </Box>
            </Paper>
            <Paper className={classes.buttonBlock}>
              <Box display="flex" alignItems="center">
                <Typography className={classes.switchLabel} mr="40px">
                  Apply to days
                </Typography>
                <Box>
                  {days.map((day) => (
                    <DayButton
                      disabled={isAdmin}
                      key={day.id}
                      label={day.name}
                      className={classes.dayButton}
                      isChecked={
                        !!formField.days.find((fieldDay) => fieldDay === day.id)
                      }
                      handleCheckWeekday={() => handleCheckWeekday(day.id)}
                    />
                  ))}
                </Box>
              </Box>
            </Paper>
          </Box>
          {/* <Box className={classes.location}>
            <Typography className={classes.blockTitle}>
              Location Settings
            </Typography>
            <Paper className={classes.buttonBlock}>
              <IOSSwitch
                label={
                  <Box>
                    <Typography>Location tracking</Typography>
                    <Typography>
                      Require location tracking for start and end day (not
                      constant tracking)
                    </Typography>
                  </Box>
                }
              />
            </Paper>
            <Paper className={classes.buttonBlock}>
              <IOSSwitch
                label={
                  <Box>
                    <Typography>Customer Radius</Typography>
                    <Typography>Default customer radius</Typography>
                  </Box>
                }
              />
            </Paper>
          </Box>
          <Box className={classes.permissions}>
            <Typography className={classes.blockTitle}>Permissions</Typography>
            <Paper className={classes.buttonBlock}>
              <IOSSwitch
                label={
                  <Box>
                    <Typography>Prevent creating customers</Typography>
                    <Typography>
                      When enabled, reps cannot create new customers.
                    </Typography>
                  </Box>
                }
              />
            </Paper>
            <Paper className={classes.buttonBlock}>
              <IOSSwitch
                label={
                  <Box>
                    <Typography>Prevent editing customers</Typography>
                    <Typography>
                      When enabled, reps cannot edit or delete existing
                      customers.
                    </Typography>
                  </Box>
                }
              />
            </Paper>
            <Paper className={classes.buttonBlock}>
              <IOSSwitch
                label={
                  <Box>
                    <Typography>Prevent editing pricing</Typography>
                    <Typography>
                      When enabled, reps cannot change prices on orders.
                      (example: cannot edit discounts)
                    </Typography>
                  </Box>
                }
              />
            </Paper>
            <Paper className={classes.buttonBlock}>
              <IOSSwitch
                label={
                  <Box>
                    <Typography>View orders for Order Direct</Typography>
                    <Typography>
                      When enabled, reps can view orders that came via Order
                      Direct
                    </Typography>
                  </Box>
                }
              />
            </Paper>
          </Box> */}
        </Box>
      </Paper>

      {/* <Typography>Representatives1</Typography> */}
    </>
  );
};

RepsTab.propTypes = {
  notifications: array,
  updateNotifications: func,
  timeZone: string,
};

RepsTab.defaultProps = {
  notifications: [],
};

export default RepsTab;
