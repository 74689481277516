import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import {
  defaultValues,
  DELIVERY_METHODS,
  RETURNS,
  validationSchema,
} from "../helpers";
import { useSelector } from "react-redux";

export const useFormActions = ({ state, order, open, returnItem }) => {
  const { defaultDeliveryMethod } = useSelector(
    ({ auth }) => auth?.currentUser || {}
  );

  const {
    control,
    trigger,
    formState: { errors },
    reset,
    setValue,
    handleSubmit,
  } = useForm({
    mode: "onChange",
    defaultValues: { ...defaultValues, deliveryMethod: defaultDeliveryMethod },
    resolver: yupResolver(validationSchema()),
  });

  const formField = useWatch({ control });

  useEffect(() => {
    let prepareReturnItem;

    if (returnItem?.id) {
      const defaultReturnsWithoutOther = RETURNS.filter(
        (el) => el.name !== "Other"
      );

      const reason = defaultReturnsWithoutOther.find(
        (r) => r?.name === returnItem?.reason
      );

      prepareReturnItem = {
        deliveryMethod:
          returnItem?.deliveryMethod ||
          defaultDeliveryMethod ||
          DELIVERY_METHODS[0],
        reason: reason?.name || "Other",
        otherReason: reason ? "" : returnItem?.reason,
        restock: returnItem?.restock || false,
        trackingId: returnItem?.trackingId || "",
        shippingCarrier: returnItem?.shippingCarrier?.id || "",
        delivererId: returnItem?.delivererDuplicate?.representativeId ?? "",
      };
    }
    if (open) {
      reset({
        ...defaultValues,
        sendNotification: state?.sendNotification,
        // delivererId: order?.customer?.assignedRepresentatives?.length
        //   ? order?.customer?.assignedRepresentatives[0]?.representative?.id
        //   : state?.repsSalesUsers?.[0]?.id ?? "",
        delivererId: state?.repsSalesUsers?.[0]?.id ?? "",
        productReturns: returnItem?.id
          ? returnItem?.productReturns?.map((prod) => ({
              ...prod,
              returnQuantity: prod.quantity,
            }))
          : order?.products?.map((prod) => ({
              ...prod,
              returnQuantity: 0,
            })),
        deliveryMethod: defaultDeliveryMethod,
        ...prepareReturnItem,
      });
    }
  }, [
    open,
    order?.customer?.assignedRepresentatives,
    order?.products,
    reset,
    returnItem,
    state?.sendNotification,
    state?.repsSalesUsers,
    defaultDeliveryMethod,
  ]);

  return {
    control,
    trigger,
    errors,
    formField,
    setValue,
    handleSubmit,
  };
};
