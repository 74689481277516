import { useMemo } from "react";
import { bool, func, array } from "prop-types";
import { Box, Paper, Stack, Typography } from "@mui/material";
// import { QuestionMarkIcon } from "../Icons";
import { TaskItem } from "./components";
import { useSelector } from "react-redux";
import { ActiveIcon } from "../Icons";
import { StyledButton } from "../../components";

export const OnboardingTasks = ({
  open,
  onClose,
  onFetch,
  stateOnboarding,
}) => {
  const currentUser = useSelector(({ auth }) => auth?.currentUser);
  const onboarding = currentUser?.onboarding;
  const onboardingIsOpen = currentUser?.onboardingIsOpen;

  const hasOnboardingDone = useMemo(() => {
    return !onboarding && onboardingIsOpen;
  }, [onboarding, onboardingIsOpen]);

  return (
    <Box
      sx={{
        position: "absolute",
        width: "100%",
        minHeight: "calc(100vh - 72px)",
        height: "calc(100% + 42px)",
        bgcolor: "rgba(0, 0, 0, 0.5)",
        zIndex: 101,
        display: open ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper width="742px">
        {hasOnboardingDone ? (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "742px",
                maxWidth: "742px",
                height: "130px",
                minHeight: "130px",
                px: "65px",
              }}
            >
              <Typography
                sx={{
                  fontSize: 32,
                  fontWeight: 700,
                  color: "#5F6267",
                }}
              >
                Onboarding Completed
              </Typography>
            </Box>
            <Box display="flex" justifyContent="center">
              <Box
                sx={{
                  border: "0.5px solid #CCC",
                  borderRadius: "4px",
                  width: "calc(742px - 150px)",
                  mb: "52px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "28px",
                  py: "24px",
                }}
              >
                <Box
                  sx={{
                    width: "48px",
                    height: "48px",
                    border: "2px solid #47A06D",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "50%",
                  }}
                >
                  <ActiveIcon width="21.55" height="16.12" />
                </Box>
                <StyledButton
                  variant="contained"
                  label="Continue"
                  fontSize="16px"
                  sx={{ minWidth: "129px", height: "37px" }}
                  onClick={onClose}
                />
              </Box>
            </Box>
          </>
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "742px",
                maxWidth: "742px",
                height: "130px",
                minHeight: "130px",
                px: "65px",
              }}
            >
              <Typography
                sx={{
                  fontSize: 32,
                  fontWeight: 700,
                  color: "#5F6267",
                }}
              >
                Onboarding Tasks
              </Typography>
              {/* temporarly */}
              {/* <Button
            sx={{
              width: "106px",
              height: "35px",
              fontSize: 15,
              fontWeight: 500,
              borderColor: "#D4D4D4",
              whiteSpace: "nowrap",
            }}
            startIcon={<QuestionMarkIcon />}
            color="cancel"
            variant="outlined"
            onClick={() => {
              window.location.href = "mailto:support@simplydepo.com";
            }}
          >
            Get help
          </Button> */}
            </Box>
            <Stack sx={{ px: 7.75, pb: 7.75 }} spacing={2}>
              {stateOnboarding.map(
                ({
                  title,
                  subTitle,
                  status,
                  date,
                  nav,
                  name,
                  hasError,
                  titleTooltip,
                  showTooltip,
                }) => (
                  <TaskItem
                    key={title}
                    {...{
                      status,
                      title,
                      subTitle,
                      date,
                      name,
                      hasError,
                      titleTooltip,
                      showTooltip,
                    }}
                    onClickGo={(name, isSkip) => {
                      if (name) onFetch(name);
                      if (!isSkip) nav();
                    }}
                  />
                )
              )}
            </Stack>
          </>
        )}
      </Paper>
    </Box>
  );
};

OnboardingTasks.propTypes = {
  open: bool,
  onClose: func,
  onFetch: func,
  stateOnboarding: array,
};
