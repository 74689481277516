import { useCallback, useEffect, useState } from "react";
import { getManufacturersService } from "services/manufacturers";
import { SCROLL_LIMIT } from "utils/constants";
import { error } from "utils/notifications";
import { useDebounce } from "./hooks";

const init = {
  loading: false,
  list: [],
  count: 0,
  existData: false,
};

export const useManufacturersActions = ({ params = {}, open = true } = {}) => {
  const [manufacturersState, setManufacturersState] = useState(init);
  const [search, setSearch] = useState("");

  const searchInputDebounced = useDebounce(search, 500);

  const [manufacturersParams, setManufacturersParams] = useState({
    page: 1,
    limit: SCROLL_LIMIT,
    ...params,
  });

  const handleSetPage = (page = 1) => {
    setManufacturersParams((prev) => ({ ...prev, page }));
  };

  const handleFetch = useCallback(
    async (page) => {
      setManufacturersState((prev) => ({ ...prev, loading: true }));
      try {
        const res = await getManufacturersService({
          ...manufacturersParams,
          search,
          page: page || manufacturersParams.page,
        });

        const count = res?.count || 0;
        const existData = !!res?.existData;
        const rows = res?.rows || [];

        setManufacturersState((prev) => ({
          ...prev,
          list: page > 1 ? [...prev.list, ...rows] : rows,
          count,
          existData,
        }));

        if (page) setManufacturersParams((prev) => ({ ...prev, page }));
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        error(err?.response?.data?.message || "Something went wrong.");
      } finally {
        setManufacturersState((prev) => ({ ...prev, loading: false }));
      }
    },
    [manufacturersParams, search]
  );

  useEffect(() => {
    if (open) {
      handleFetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, searchInputDebounced]);

  return {
    ...manufacturersState,
    page: manufacturersParams.page,
    search,
    setSearch,
    handleFetch,
    handleSetPage,
  };
};
