import { makeStyles } from "@mui/styles";

export const cl = {
  search: {
    "& .MuiAutocomplete-paper": {
      boxShadow: "none",
    },
  },
  searchInput: {
    "& .MuiAutocomplete-endAdornment": { mr: "-2px" },
    "& .MuiOutlinedInput-root": {
      height: "32px",
      py: "0px !important",
      fontSize: "14px",
      fontWeight: 300,
      backgroundColor: "#FFF",
      "& > fieldset": {
        borderColor: "rgb(213,217,217)!important",
        borderWidth: "1px!important",
      },
    },
  },
  dropdownList: {
    width: "100%",
    maxHeight: "255px",
    overflow: "auto",

    scrollbarWidth: "6px",
    scrollbarColor: "rgba(0, 0, 0, 0.35) rgba(0, 0, 0, 0.1)",

    "&::-webkit-scrollbar": {
      width: "6px",
      borderRadius: "7px",
      backgroundColor: "rgba(0, 0, 0, 0.1)",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "7px",
      backgroundColor: "rgba(0, 0, 0, 0.35)",
    },
  },
  searchListBoxProps: {
    p: 0,
    maxHeight: "146px",
    boxShadow: "none",
    overflow: "overlay",
    borderRadius: "4px",
  },
};

const useStyles = makeStyles(() => ({
  recipientsTable: {
    height: "100%",
    zIndex: "10",
    border: "0.5px solid #D5D9D9",
    width: "100%",
    backgroundColor: "#ffffff",
    overflow: "overlay",
    borderRadius: "4px",
    marginTop: "1px",
  },
  textfield: {
    color: "#ff0000",
    "& input::placeholder, & textarea::placeholder": {
      color: "#B5B5AC",
      opacity: 1,
      fontWeight: 400,
    },
  },
}));

export default useStyles;
