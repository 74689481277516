import { useCallback, useEffect, useState } from "react";
import { getRepsService } from "../../services/reps";
import { SCROLL_LIMIT } from "../../utils/constants";
import { error } from "../../utils/notifications";

const defaultState = {
  loading: false,
  list: [],
  hasMore: false,
  params: {
    role: "third_party",
    active: true,
    cursor: null,
    limit: SCROLL_LIMIT,
  },
};

export const useThirdPartyActions = () => {
  const [thirdPartyRepsState, setThirdPartyRepsState] = useState({
    ...defaultState,
  });

  const fetchThirdPartyReps = useCallback(async () => {
    try {
      setThirdPartyRepsState((prev) => ({ ...prev, loading: true }));
      const repsData = await getRepsService(thirdPartyRepsState?.params);
      setThirdPartyRepsState((prev) => ({
        ...prev,
        count: repsData.countThirdParty,
        list: [...prev?.list, ...repsData?.rows],
        hasMore:
          (repsData?.countThirdParty ?? 0) >
          prev?.list?.length + (repsData?.rows?.length ?? 0),
        params: {
          ...prev?.params,
          cursor: repsData?.rows?.[repsData?.rows?.length - 1]?.id ?? "",
        },
      }));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      error(err?.response?.data?.message || "Something went wrong.");
    } finally {
      setThirdPartyRepsState((prev) => ({ ...prev, loading: false }));
    }
  }, [thirdPartyRepsState?.params]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => fetchThirdPartyReps(), []);

  return {
    thirdPartyRepsState,
    fetchThirdPartyReps,
  };
};
