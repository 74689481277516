import { Typography, Box } from "@mui/material";
import { string, bool, any } from "prop-types";

const TableHeader = ({ title, isEdit, addElement }) => {
  return (
    <Box
      height="47px"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      pl="21px"
      pr="15px"
      backgroundColor="#EEEEEE"
      borderRadius="4px"
      border="1px solid #d5d9d9"
    >
      <Typography fontSize="23px" color="#000000">
        {title}
      </Typography>
      {isEdit && addElement}
    </Box>
  );
};

TableHeader.propTypes = { title: string, isEdit: bool, addElement: any };

export default TableHeader;
