import { useCallback, useContext } from "react";
import { LargeCheckbox, TableHeader } from "components";
import { Box, Grid, Typography } from "@mui/material";
import { ArrowDownIcon, ArrowUpIcon } from "components/Icons";
import { TasksPageContext } from "Pages/TasksPage/TasksPage";

const cl = {
  arrowBox: {
    display: "flex",
  },
  centredHeaderItem: {
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
  },
  leftHeaderItem: {
    justifyContent: "flex-start",
    display: "flex",
    alignItems: "center",
  },
};

export const TasksHeader = () => {
  const {
    tasksList,
    allTasksChecked,
    handleSelectAvailableTasks,
    checkedTaskIds,
    handleSetSortBy,
    tasksFilter,
  } = useContext(TasksPageContext);

  const TABLE_DATA = [
    {
      id: 1,
      title: "",
      element: (
        <LargeCheckbox
          formSx={{ m: 0 }}
          checked={allTasksChecked}
          onChange={handleSelectAvailableTasks}
          sx={{
            p: 0,
            "&.MuiCheckbox-root.MuiCheckbox-indeterminate": {
              ml: "-3px !important",
            },
          }}
          onClick={(e) => e.stopPropagation()}
          size={22}
          rectSize={22}
          indeterminate={
            checkedTaskIds?.length > 0 &&
            checkedTaskIds?.length !== tasksList?.length
          }
          indeterminateIcon={27}
          checkedViewBox="0 0 24 24"
        />
      ),
      sx: { pl: 1.6 },
      xs: 0.5,
    },
    {
      id: 2,
      title: "STATUS",
      sx: { ...cl.leftHeaderItem },
      xs: 0.5,
    },
    {
      id: 3,
      title: "TASK NAME",
      column: "sort_by_name",
      sx: { ...cl.leftHeaderItem },
      xs: 3,
    },
    {
      id: 4,
      title: "ASSIGNED TO",
      column: "sort_by_assigned",
      sx: { ...cl.leftHeaderItem },
      xs: 1.15,
    },
    {
      id: 5,
      title: "CUSTOMER",
      column: "sort_by_customer",
      sx: { ...cl.leftHeaderItem },
      xs: 2.2,
    },
    {
      id: 6,
      title: "DUE DATE",
      column: "sort_by_due_date",
      sx: { ...cl.leftHeaderItem },
      xs: 1,
    },
    {
      id: 7,
      title: "PRIORITY",
      column: "sort_by_priority",
      sx: { ...cl.leftHeaderItem },
      xs: 1,
    },
    {
      id: 8,
      // title: "CONNECTED FORMS",
      title: "",
      sx: { ...cl.leftHeaderItem },
      xs: 2.65,
    },
  ];

  const SORTABLE_COLUMNS = {
    "TASK NAME": { sortableField: tasksFilter.sort_by_name },
    "ASSIGNED TO": { sortableField: tasksFilter.sort_by_assigned },
    CUSTOMER: { sortableField: tasksFilter.sort_by_customer },
    "DUE DATE": { sortableField: tasksFilter.sort_by_due_date },
    PRIORITY: { sortableField: tasksFilter.sort_by_priority },
  };
  // tasksFilter
  const quickSortArrow = useCallback((value) => {
    if (value === "asc") return <ArrowDownIcon />;
    if (value === "desc") return <ArrowUpIcon />;

    return (
      <Box sx={{ opacity: 0.5 }}>
        <ArrowDownIcon />
      </Box>
    );
  }, []);

  return (
    <TableHeader sx={{ borderRadius: 0, borderTop: "none" }} columns={12}>
      {TABLE_DATA.map(({ id, sx, title, element, column, xs }) => {
        const sortableField = SORTABLE_COLUMNS[title]?.sortableField;

        return (
          <Grid sx={sx} key={id} item xs={xs}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: SORTABLE_COLUMNS[title] ? "pointer" : "auto",
              }}
              onClick={() => {
                if (SORTABLE_COLUMNS[title]) {
                  const preparedData = {
                    sort_by_name: undefined,
                    sort_by_assigned: undefined,
                    sort_by_customer: undefined,
                    sort_by_due_date: undefined,
                    sort_by_priority: undefined,
                  };

                  preparedData[column] =
                    sortableField === "asc" ? "desc" : "asc";

                  handleSetSortBy(preparedData);
                }
              }}
            >
              {title && (
                <Typography fontSize={12} color="#6A6A6A" mr={1}>
                  {title}
                </Typography>
              )}
              {element && element}

              {SORTABLE_COLUMNS[title] && (
                <Box sx={cl.arrowBox}>{quickSortArrow(sortableField)}</Box>
              )}
            </Box>
          </Grid>
        );
      })}
    </TableHeader>
  );
};
