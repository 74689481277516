import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { confirmUserAccount } from "../../services/account.js";
import {
  Box,
  Button,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { validationSchema } from "./ConfirmAccount.validations.js";
import { Controller, useForm, useWatch } from "react-hook-form";
import { LinksRegistration } from "../RegistrationPage/components/LinksRegistration";
import { FooterRegistration } from "../RegistrationPage/components/FooterRegistration";
import { StyledField } from "../RegistrationPage/components/BodyRegistration/StyledField";
import { ActiveIcon } from "../../components/Icons";
import backgroundImg from "assets/images/login-background.jpg";
import { MainLogoIcon } from "../../components/Icons/MainLogos";
import { PasswordCriteria } from "../../components";
import { PASSWORD_CRITERIA } from "../../components/PasswordCriteria/PasswordCriteria.constants.js";

const ConfirmPasswordPage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [params] = useSearchParams();

  const { control, handleSubmit, setValue } = useForm({
    mode: "onChange",
    defaultValues: {
      newPassword: "",
      confirmPassword: "",
      email: "",
      token: "",
    },
    resolver: yupResolver(validationSchema()),
  });

  const formField = useWatch({ control });

  const getToken = params.get("confirmation_token");
  const getEmail = params.get("email");

  useEffect(() => {
    setValue("token", getToken ?? "");
    setValue("email", getEmail ?? "");
  }, [getEmail, getToken, params, setValue]);

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      await confirmUserAccount(formField?.token, {
        password: data.newPassword,
      });
      navigate("/info");
    } catch (error) {
      error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        p: 1,
        background: `linear-gradient(0deg, rgba(225, 225, 225, 0.7), rgba(225, 225, 225, 0.7)), url(${backgroundImg})`,
        backgroundSize: "100% 100%",
        flexDirection: "column",
      }}
    >
      <Paper
        sx={{
          maxWidth: "636px",
          width: "100%",
          mb: 1,
          borderRadius: "16px",
          border: "0.5px solid #D5D9D9",
          mx: "1%",
        }}
        elevation={0}
        variant="outlined"
      >
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            py: "42px",
          }}
        >
          <MainLogoIcon width={307} height={45} />
        </Box>

        <Box
          sx={{
            height: "44px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: 25,
              fontWeight: 500,
              color: "#5F6267",
            }}
          >
            Set Password
          </Typography>
        </Box>

        <Box
          sx={{
            pt: "10px",
            display: "flex",
            flexDirection: "column",
            gap: "22px",
            px: "13%",
          }}
        >
          <Controller
            render={({ field }) => (
              <TextField
                disabled
                fullWidth
                InputProps={{
                  style: {
                    height: "48px",
                    borderRadius: "8px",
                    fontSize: 18,
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <ActiveIcon />
                    </InputAdornment>
                  ),
                }}
                placeholder="Email"
                {...field}
              />
            )}
            name="email"
            control={control}
          />
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledField
                {...{ control, field, error }}
                label="Password"
                type="password"
              />
            )}
            name="newPassword"
            control={control}
          />
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledField
                {...{ control, field, error }}
                label="Confirm password"
                type="password"
              />
            )}
            name="confirmPassword"
            control={control}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            px: "13%",
          }}
        >
          <PasswordCriteria
            password={formField.newPassword}
            confirmPassword={formField.confirmPassword}
          />
        </Box>

        <Box
          sx={{
            mt: "40px",
            px: "13%",
            pb: "62px",
          }}
        >
          <Button
            disabled={
              isLoading ||
              PASSWORD_CRITERIA.some((criteria) => {
                const { newPassword, confirmPassword } = formField;
                const completed = criteria.condition({
                  password: newPassword,
                  confirmPassword,
                });
                return !completed;
              })
            }
            sx={{
              height: "48px",
              fontSize: 20,
              fontWeight: 500,
            }}
            fullWidth
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            {isLoading ? "Loading..." : "Set password"}
          </Button>
        </Box>
        <FooterRegistration step={1} />
      </Paper>
      <Box height="60px" width="100%">
        <LinksRegistration />
      </Box>
    </Box>
  );
};

export default ConfirmPasswordPage;
