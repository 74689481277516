import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  text: {
    fontSize: "14px",
  },

  wrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "25px",
    "@media (max-width: 1480px)": {
      gap: "10px",
    },
  },

  radioGroup: {
    gap: "9px",

    "@media (max-width: 1480px)": {
      gap: "4px",
    },

    "& .MuiFormControlLabel-root": {
      "& .MuiRadio-root svg": {
        height: "15px",
        width: "15px",
      },
      "& .MuiTypography-root": {
        fontSize: "14px",
        color: "#979797",
        marginLeft: "8px",
      },
    },
  },

  widgetWrapper: {
    borderRadius: "4px",
    filter: "drop-shadow(0px 5px 30px rgba(0, 0, 0, 0.16))",

    height: "356px",
    backgroundColor: "#f8f8fa",
    position: "relative",
    "& .MuiButton-root": {
      position: "absolute",
      top: 13,
      left: 12,
      zIndex: 100,
    },
    "@media (max-width: 1480px)": {
      height: "330px",
    },
  },

  iframeWrap: {
    transform: ({ scaleIframe }) =>
      `scale(${scaleIframe}) translate(-${(1 - scaleIframe) * 200 || 50}%, -${
        (1 - scaleIframe) * 200 || 50
      }%)`,
    // transform: "scale(1)",
    position: "absolute",
    top: "50%",
    overflow: "hidden",
    left: "50%",
    "& iframe": {
      border: "none",
      boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.2)",
      background: "transparent",
      overflow: "hidden",
    },
  },
}));

export default useStyles;
