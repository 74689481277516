import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  linkWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
    width: "fit-content",
    maxWidth: "400px",
  },

  description: {
    fontSize: "14px",
  },

  storeLinkTitle: {
    fontSize: "14px",
    fontWeight: "600",
  },

  storeLinkBlock: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },

  storeLinkButton: {
    maxWidth: "fit-content",
    minWidth: "89px",
    height: "28px",
  },

  storeLinkPrefix: {
    fontSize: "10px",
    color: "#C2C0C0",
    whiteSpace: "nowrap",
    "&.dialogField": {
      fontSize: "14px",
    },
  },

  // storeLinkField: {
  //   "& .MuiOutlinedInput-root": {
  //     height: "30px",
  //     fontSize: "10px",
  //     color: "#535353",
  //   },

  //   "&.dialogField": {
  //     "& .MuiOutlinedInput-root": {
  //       height: "48px",
  //       fontSize: "18px",
  //       borderRadius: "8px",
  //     },
  //   },
  // },

  dialogContent: {
    maxWidth: "635px",
    padding: "0 85px",
  },

  dialog: {
    "& .MuiPaper-root": {
      maxWidth: "635px",
      textAlign: "center",
      maxHeight: "348px",
      height: "100%",
    },

    "& .MuiDialogTitle-root": {
      textAlign: "center",
      fontSize: "32px",
      color: "#5F6267",
      fontWeight: "700",
      paddingTop: "27px",
    },

    "& .MuiDialogActions-root": {
      justifyContent: "center",
      padding: "0 85px 35px",
      flex: "1 1 auto",
      "& .MuiButton-root": {
        width: "100%",
        height: "48px",
      },
    },
  },

  fieldWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "38px",
  },

  logoBlockWrap: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    margin: "32px 0 55px 0",
    "@media (max-width: 1480px)": {
      marginBottom: "12px",
    },
  },
}));

export default useStyles;
