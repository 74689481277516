export const classes = {
  grid: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "3px",
    "& svg": {
      mb: "1px",
      width: "6px",
    },
  },
  typography: {
    fontSize: "12px",
    color: "#6A6A6A",
    textTransform: "uppercase",
  },
  paperWrapper: {
    backgroundColor: "#EEEEEE",
    border: "none",
    borderBottom: "0.5px solid #D5D9D9",
    borderRadius: "4px 4px 0 0",
    // p: "9px 8px",
  },
};
