/* eslint-disable react/prop-types */
import * as React from "react";
export const TaskSmallIcon = ({ color = "#CCC", ...props }) => (
  <svg
    width={12}
    height={12}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.013 5.228 6.316 7.6c.544-.99 2.34-3.395 5.166-5.09"
      stroke={color}
      strokeWidth={1.5}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.5 6.954V8.3c0 .42 0 .63-.082.79a.75.75 0 0 1-.328.328c-.16.082-.37.082-.79.082H3.7c-.42 0-.63 0-.79-.082a.75.75 0 0 1-.328-.328C2.5 8.93 2.5 8.72 2.5 8.3V3.7c0-.42 0-.63.082-.79a.75.75 0 0 1 .328-.328c.16-.082.37-.082.79-.082h4.146l1.64-1.486C9.253 1 8.966 1 8.6 1H3.4c-.84 0-1.26 0-1.581.163a1.5 1.5 0 0 0-.656.656C1 2.139 1 2.559 1 3.4v5.2c0 .84 0 1.26.163 1.581a1.5 1.5 0 0 0 .656.655c.32.164.74.164 1.581.164h5.2c.84 0 1.26 0 1.581-.164a1.5 1.5 0 0 0 .655-.655C11 9.861 11 9.441 11 8.6V5.61L9.5 6.955Z"
      fill={color}
    />
  </svg>
);
