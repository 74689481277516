import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  headerWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "44px",
    borderBottom: "0.5px solid #D4D4D4",
    padding: "0 32px",
    backgroundColor: "rgba(181, 181, 172, 0.05)",
  },

  pageTitleWrap: {
    display: "flex",
    alignItems: "center",
    gap: "15px",
    "& .MuiTypography-root": {
      "&.secondary": {
        whiteSpace: "pre-wrap",
      },
      "&.main": {
        cursor: "pointer",
      },
    },
  },
}));

export const cl = {
  actionSelect: {
    height: "24px !important",
    fontSize: "12px",
    color: "#6A6A6A !important",
    top: "107 !important",
    "&.makeStyles-squareSelect-46 > .MuiSelect-select": {
      padding: "0px !important",
    },

    "& .MuiOutlinedInput-input": {
      py: "4px",
      px: "5px",
      pr: "0px !important",
    },

    "&.MuiOutlinedInput-root": {
      "& svg": {
        mr: "-2px !important",
        width: "24px",
        height: "24px",
        fill: "#47a16d !important",
        transform: "scale(1)",
        zIndex: 1,
      },
    },
  },
  actionFormSelect: {
    backgroundColor: "white",
    width: "84px !important",
    color: "#6A6A6A !important",
    transition: "all 0.2s",

    "&:hover": {
      transition: "all 0.2s",
      "& fieldset": {
        transition: "all 0.2s",
        backgroundColor: "#EBEDEB !important",
        borderColor: "#47a16d !important",
      },
    },

    "& .MuiInputLabel-root.Mui-focused": {
      color: "#6A6A6A !important",
    },
  },
  actionSelectLabel: {
    fontSize: "12px !important",
    ml: "-3.5px !important",
  },
};

export default useStyles;
