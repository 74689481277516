import { getTotalSummariesCustomersService } from "../../services/customers";
import { getTotalSummariesOrdersService } from "../../services/orders";
import { getTotalSummariesCatalogService } from "../../services/products";
import { error } from "../../utils/notifications";

export const START_LOADING_TOTAL_SUMMARIES_CATALOG =
  "START_LOADING_TOTAL_SUMMARIES_CATALOG";
export const END_LOADING_TOTAL_SUMMARIES_CATALOG =
  "END_LOADING_TOTAL_SUMMARIES_CATALOG";
export const GET_TOTAL_SUMMARIES_CATALOG_DATA =
  "GET_TOTAL_SUMMARIES_CATALOG_DATA";

export const START_LOADING_TOTAL_SUMMARIES_ORDERS =
  "START_LOADING_TOTAL_SUMMARIES_ORDERS";
export const END_LOADING_TOTAL_SUMMARIES_ORDERS =
  "END_LOADING_TOTAL_SUMMARIES_ORDERS";
export const GET_TOTAL_SUMMARIES_ORDERS_DATA =
  "GET_TOTAL_SUMMARIES_ORDERS_DATA";
export const SET_TOTAL_SUMMARIES_ORDERS_PARAMS =
  "SET_TOTAL_SUMMARIES_ORDERS_PARAMS";

export const GET_TOTAL_SUMMARIES_CUSTOMERS_DATA =
  "GET_TOTAL_SUMMARIES_CUSTOMERS_DATA";
export const START_LOADING_TOTAL_SUMMARIES_CUSTOMERS =
  "START_LOADING_TOTAL_SUMMARIES_CUSTOMERS";
export const END_LOADING_TOTAL_SUMMARIES_CUSTOMERS =
  "END_LOADING_TOTAL_SUMMARIES_CUSTOMERS";

const startLoadingTotalSummariesCatalog = () => ({
  type: START_LOADING_TOTAL_SUMMARIES_CATALOG,
});
const endLoadingTotalSummariesCatalog = () => ({
  type: END_LOADING_TOTAL_SUMMARIES_CATALOG,
});
const getTotalSummariesCatalog = (payload) => ({
  type: GET_TOTAL_SUMMARIES_CATALOG_DATA,
  payload,
});

const startLoadingTotalSummariesOrders = () => ({
  type: START_LOADING_TOTAL_SUMMARIES_ORDERS,
});
const endLoadingTotalSummariesOrders = () => ({
  type: END_LOADING_TOTAL_SUMMARIES_ORDERS,
});
const getTotalSummariesOrders = (payload) => ({
  type: GET_TOTAL_SUMMARIES_ORDERS_DATA,
  payload,
});
const setTotalSummariesOrdersParams = (payload) => ({
  type: SET_TOTAL_SUMMARIES_ORDERS_PARAMS,
  payload,
});

const getTotalSummariesCustomersData = (payload) => ({
  type: GET_TOTAL_SUMMARIES_CUSTOMERS_DATA,
  payload,
});
const startLoadingTotalSummariesCustomers = () => ({
  type: START_LOADING_TOTAL_SUMMARIES_CUSTOMERS,
});
const endLoadingTotalSummariesCustomers = () => ({
  type: END_LOADING_TOTAL_SUMMARIES_CUSTOMERS,
});

export const getTotalSummariesCatalogAction = () => {
  return (dispatch) => {
    dispatch(startLoadingTotalSummariesCatalog());
    getTotalSummariesCatalogService()
      .then((res) => dispatch(getTotalSummariesCatalog(res)))
      .catch((err) => error(err?.response?.data?.message))
      .finally(() => dispatch(endLoadingTotalSummariesCatalog()));
  };
};

export const getTotalSummariesOrdersAction = () => {
  return (dispatch, getState) => {
    const {
      totalSummaries: { totalSummariesOrdersParams },
    } = getState();
    dispatch(startLoadingTotalSummariesOrders());
    getTotalSummariesOrdersService(totalSummariesOrdersParams)
      .then((res) => dispatch(getTotalSummariesOrders(res)))
      .catch((err) => {
        if (err?.message === "canceled") return;
        error(err?.response?.data?.message);
      })
      .finally(() => dispatch(endLoadingTotalSummariesOrders()));
  };
};

export const setTotalSummariesOrdersParamsAction = (data) => {
  return (dispatch) => {
    dispatch(setTotalSummariesOrdersParams(data));
    dispatch(getTotalSummariesOrdersAction());
  };
};

export const getTotalSummariesCustomersDataAction = () => {
  return (dispatch) => {
    dispatch(startLoadingTotalSummariesCustomers());
    getTotalSummariesCustomersService()
      .then((res) => dispatch(getTotalSummariesCustomersData(res)))
      .catch((err) => error(err?.response?.data?.message))
      .finally(() => dispatch(endLoadingTotalSummariesCustomers()));
  };
};
