import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { object, bool, string } from "prop-types";
import {
  CheckmarkIcon,
  CrossIcon,
  StyledProductIco,
} from "../../../../../../components/Icons";
import { photoUrl } from "../../../../../../helpers/helpers";
import useStyles from "./styles";

const ProductAccordionItem = ({
  product,
  onPromotion,
  marketingMaterials,
  total,
}) => {
  const classes = useStyles();

  return (
    <Grid container columns={14} className={classes.productItemContainer}>
      <Grid item xs={4.1} className={classes.productNameWrapper}>
        <Box width="100%" display="inline-flex" alignItems="center" gap="9px">
          <Box className={classes.productPhoto}>
            <StyledProductIco
              src={
                product?.product?.photos?.length
                  ? photoUrl(product?.product?.photos[0]?.fileName)
                  : ""
              }
              styles={{
                objectFit: "contain",
                width: "100%",
                height: "100%",
                borderRadius: "4px",
              }}
            />
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            width="115px"
          >
            <Typography color="#1C1C19!important" fontWeight="600" noWrap>
              {product?.product?.name ||
                product?.product?.parentProduct?.name ||
                "-"}
            </Typography>
            <Typography
              fontSize="12px"
              fontWeight="400"
              color="#1C1C19!important"
              noWrap
            >
              {product?.product?.sku}
              {(product?.product?.size || product?.product?.color) &&
                ` / ${product?.product?.size || product?.product?.color}`}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid
        sx={{
          justifyContent: "flex-start !important",
        }}
        item
        xs={1.7}
      >
        <Typography>$ {product.price.toFixed(2)}</Typography>
      </Grid>
      <Grid
        sx={{
          justifyContent: "flex-start !important",
        }}
        item
        xs={1.7}
      >
        <Box position="relative">
          <Typography>{product.quantity}</Typography>
          {!!product.product.itemsPerCase && (
            <Typography
              fontWeight="400"
              fontSize="10px!important"
              color="#B1B1B1!important"
              sx={{
                position: "absolute",
                top: 2,
                transform: "translateX(100%)",
                right: -3,
              }}
            >
              (
              {(total === "CASES"
                ? product.quantity * product.product.itemsPerCase
                : product.quantity / product.product.itemsPerCase
              ).toFixed(1) || "-"}{" "}
              {total === "CASES" ? "pcs" : "cs"})
            </Typography>
          )}
        </Box>
      </Grid>
      <Grid item xs={1.6}>
        {onPromotion ? (
          <CheckmarkIcon stroke="#BEBEBE" />
        ) : (
          <CrossIcon fill="#BEBEBE" opacity={1} />
        )}
      </Grid>
      <Grid item xs={2.6}>
        {marketingMaterials ? (
          <CheckmarkIcon stroke="#BEBEBE" />
        ) : (
          <CrossIcon fill="#BEBEBE" opacity={1} />
        )}
      </Grid>
      <Grid item xs={2.3}>
        {product.reorderRequired ? (
          <Typography color="#FF8000!important">yes</Typography>
        ) : (
          <Typography color="#000000!important">no</Typography>
        )}
      </Grid>
    </Grid>
  );
};

ProductAccordionItem.propTypes = {
  product: object,
  onPromotion: bool,
  marketingMaterials: bool,
  total: string,
};

export default ProductAccordionItem;
