import { string, func, array, object, bool } from "prop-types";

import { FilterMenu, StyledTextField } from "components";

import { cl } from "../styles";

import { DialogContent } from "@mui/material";
import { checkIfAllUnchecked } from "helpers/filters";

export const EditFilterContent = ({
  viewName,
  onChange,
  selectMatchData,
  filterForEdit,
  switches,
  onClose,
  onSave,
  isNameChanged,
  onDelete,
}) => {
  const contentProps = {
    saveBtnProps: { label: "Update View", disabled: isNameChanged },
    menuContentProps: {
      sx: { "& hr": { mt: "11px" } },
    },
    menuSwitchWrapperProps: {
      sx: { padding: "9px 24px !important" },
    },
    menuButtonBlockProps: {
      sx: {
        padding: "20px 17px 0 !important",
        "& button": {
          "& span": {
            fontSize: "13px",
          },
        },
      },
    },
    deleteBtnProps: {
      label: "Delete",
      onClick: () => onDelete(filterForEdit),
      showFunc: (switchesList) => checkIfAllUnchecked(switchesList),
    },
  };

  return (
    <DialogContent sx={cl.contentWrapper}>
      <StyledTextField
        label="Filter Name"
        name="filterName"
        fullWidth
        inputSx={cl.inputSx}
        labelSx={cl.inputLabel}
        value={viewName}
        onChange={onChange}
        formSx={{ px: "24px" }}
      />

      <FilterMenu
        {...{
          selectMatchData,
          filterFields: filterForEdit.filters,
          switches,
          showOnlyList: true,
          contentProps,
          handleApplyFilter: onSave,
          onClose,
        }}
      />
    </DialogContent>
  );
};

EditFilterContent.propTypes = {
  viewName: string,
  onChange: func,
  onClose: func,
  onSave: func,
  selectMatchData: array,
  switches: array,
  filterForEdit: object,
  isNameChanged: bool,
  onDelete: func,
};
EditFilterContent.defaultProps = {
  viewName: "",
  isNameChanged: "",
  onChange: () => {},
  onSave: () => {},
  onClose: () => {},
  onDelete: () => {},
};
