import { Grid, Paper, Typography } from "@mui/material";
import { oneOf } from "prop-types";
import { cl } from "./styles";
import { BREAKPOINTS } from "helpers/useBreakpoint";

export const HeaderList = ({ breakpoint }) => {
  const {
    HeaderList: { paperOptions, text },
  } = cl;

  return (
    <Paper component={Grid} {...paperOptions}>
      <Grid xs={1.68} item>
        <Typography sx={text} pl="23%">
          ID
        </Typography>
      </Grid>
      <Grid xs={1.8} item>
        <Typography sx={text}>DATE</Typography>
      </Grid>
      <Grid xs={breakpoint !== "xl" ? 4.5 : 5} item>
        <Typography sx={text}>CUSTOMER</Typography>
      </Grid>
      <Grid xs={breakpoint !== "xl" ? 2.7 : 2.2} item>
        <Typography sx={text}>CREATED BY</Typography>
      </Grid>
      <Grid xs={0.96} item>
        <Typography sx={text}>TOTAL</Typography>
      </Grid>
    </Paper>
  );
};

HeaderList.propTypes = {
  breakpoint: oneOf(BREAKPOINTS),
};

HeaderList.defaultProps = {
  breakpoint: "xl",
};
