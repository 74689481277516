import { useCallback, useEffect, useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { array, func, bool, object } from "prop-types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { CrossBigIcon } from "../Icons";
import TabCustomersComponent from "../../Pages/RepresentativesPage/components/ProfileComponent/TabsContent/TabCustomersComponent/TabCustomersComponent";
import StyledButton from "../StyledButton";
import { TAB_NAMES } from "./TransferPopup.constants";
import AssignReps from "./components/AssignReps/AssignReps";
import { getRepsService } from "../../services/reps";
import { handleConfirmAction } from "../../Pages/DiscountsPage/DiscountsPage.helpers";
import { setConfirmIsOpenAction } from "../../redux/actions/confirmDialogs";
import { removeAllRepsAction } from "../../redux/actions/customers";

const TransferPopup = ({
  checkedCustomersArr,
  isOpen,
  handleClose,
  handleConfirmTransfer,
  handleConfirmAssign,
  checkedReps,
  deletingReps,
}) => {
  const dispatch = useDispatch();

  const [selectedRep, setSelectedRep] = useState(null);
  const [currentTab, setCurrentTab] = useState(0);
  const [salesList, setSalesList] = useState([]);
  const [merchList, setMerchList] = useState([]);
  const [thirdPartyList, setThirdPartyList] = useState([]);
  const [selectedSales, setSelectedSales] = useState([]);
  const [selectedMerch, setSelectedMerch] = useState([]);
  const [selectedThirdParty, setSelectedThirdParty] = useState([]);
  const [searchQuery, setSearchQuery] = useState({
    search: "",
    territoryId: "",
  });

  // must wrap in useCallback to prevent rerender in Filter Search useEffect
  const handleSelectRep = useCallback((rep) => setSelectedRep(rep), []);

  useEffect(() => {
    if (!checkedCustomersArr.length && isOpen) setCurrentTab(1);
    return () => {
      setCurrentTab(0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) return setSelectedRep(null);
    getRepsService({ ...searchQuery, role: "sales", active: true }).then(
      (res) =>
        setSalesList(
          res.rows.filter((rep) => !checkedReps.some((r) => r.id === rep.id))
        )
    );
    getRepsService({ ...searchQuery, role: "merchandiser", active: true }).then(
      (res) =>
        setMerchList(
          res.rows.filter((rep) => !checkedReps.some((r) => r.id === rep.id))
        )
    );
    getRepsService({ ...searchQuery, role: "third_party", active: true }).then(
      (res) =>
        setThirdPartyList(
          res.rows.filter((rep) => !checkedReps.some((r) => r.id === rep.id))
        )
    );

    return () => {
      setSelectedMerch([]);
      setSelectedSales([]);
      setSelectedThirdParty([]);
      setSelectedRep(null);
    };
  }, [isOpen, checkedReps, searchQuery]);

  useEffect(() => {
    setSelectedMerch([]);
    setSelectedSales([]);
    setSelectedThirdParty([]);
    setSearchQuery({ search: "", territoryId: "" });
  }, [currentTab]);

  const role = useMemo(() => {
    const mapRole = [...new Set(checkedReps?.map((item) => item.role))];
    return mapRole;
  }, [checkedReps]);

  const selectedSalesCheck =
    role.find((i) => i === "SALES") && !selectedSales?.length;

  const selectedMerchCheck =
    role.find((i) => i === "MERCHANDISER") && !selectedMerch?.length;

  const selectedThirdPartyCheck =
    role.find((i) => i === "THIRD_PARTY") && !selectedThirdParty?.length;

  const handleDisableConfirmButton = useMemo(() => {
    return (
      (selectedSalesCheck && salesList.length !== 0) ||
      (selectedMerchCheck && merchList.length !== 0) ||
      (selectedThirdPartyCheck && thirdPartyList.length !== 0)
    );
  }, [
    merchList.length,
    salesList.length,
    selectedMerchCheck,
    selectedSalesCheck,
    selectedThirdPartyCheck,
    thirdPartyList.length,
  ]);

  const handleDeleteAllReps = useCallback(() => {
    dispatch(
      handleConfirmAction({
        title: "Remove all reps",
        text: (
          <Typography
            fontWeight="400"
            fontSize="13px"
            color="#363531"
            whiteSpace="pre-line"
            marginTop="4px"
            marginBottom="8px"
          >
            Are you sure you want to remove all assigned reps for selected
            customers?
          </Typography>
        ),
        confirmColor: "confirmDelete",
        onConfirm: () => {
          dispatch(
            removeAllRepsAction(checkedCustomersArr.map((reps) => reps?.id))
          );
          dispatch(setConfirmIsOpenAction(false));
        },
      })
    );
  }, [dispatch, checkedCustomersArr]);

  return (
    <Dialog maxWidth="lg" scroll="body" open={isOpen}>
      <DialogTitle
        display="flex"
        justifyContent="space-between"
        sx={{ pt: "38px" }}
      >
        <Tabs
          value={currentTab}
          onChange={(e, newValue) => setCurrentTab(newValue)}
          sx={{
            "& .MuiTabs-indicator": {
              backgroundColor: "#42A57F",
            },
          }}
        >
          {TAB_NAMES.map((tab, i) => (
            <Tab
              key={tab}
              label={tab}
              value={i}
              disabled={i === 0 && deletingReps}
              className="profile-tabs"
              sx={{
                fontSize: "20px",
                fontWeight: "400",
                textTransform: "none",
                color: "#707070",
                "&.Mui-selected": {
                  color: "#42A57F",
                },
              }}
            />
          ))}
        </Tabs>
        <IconButton onClick={handleClose}>
          <CrossBigIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ width: "980px", p: 0 }}>
        {currentTab === 0 ? (
          <AssignReps
            checkedCustomers={checkedCustomersArr}
            merchList={merchList}
            salesList={salesList}
            thirdPartyList={thirdPartyList}
            selectedMerch={selectedMerch}
            selectedSales={selectedSales}
            selectedThirdParty={selectedThirdParty}
            setSelectedMerch={setSelectedMerch}
            setSelectedSales={setSelectedSales}
            setSelectedThirdParty={setSelectedThirdParty}
          />
        ) : (
          <TabCustomersComponent
            checkedCustomers={checkedCustomersArr}
            handleSelectRep={handleSelectRep}
            selectedRep={selectedRep}
            isTransfer
            checkedReps={checkedReps}
            role={role}
            merchList={merchList}
            salesList={salesList}
            thirdPartyList={thirdPartyList}
            selectedMerch={selectedMerch}
            selectedSales={selectedSales}
            selectedThirdParty={selectedThirdParty}
            setSelectedMerch={setSelectedMerch}
            setSelectedSales={setSelectedSales}
            setSelectedThirdParty={setSelectedThirdParty}
            setAssignSearchQuery={setSearchQuery}
          />
        )}
      </DialogContent>
      <DialogActions sx={{ p: "27px 25px" }}>
        <StyledButton
          label="Cancel"
          variant="outlined"
          color="cancel"
          onClick={handleClose}
          fontSize="15px"
          sx={{ height: "37px", mr: "9px" }}
        />
        {currentTab === 0 ? (
          <>
            <StyledButton
              label="Remove All Reps"
              variant="contained"
              color="confirmDelete"
              fontSize="15px"
              sx={{ height: "37px" }}
              onClick={() => {
                handleClose();
                handleDeleteAllReps();
              }}
            />
            <StyledButton
              label="Assign Reps"
              variant="contained"
              disabled={
                !selectedMerch?.length &&
                !selectedSales?.length &&
                !selectedThirdParty?.length
              }
              onClick={() =>
                handleConfirmAssign({
                  merch: selectedMerch,
                  sales: selectedSales,
                  thirdParty: selectedThirdParty,
                })
              }
              fontSize="15px"
              sx={{ height: "37px" }}
            />
          </>
        ) : (
          <StyledButton
            label={deletingReps ? "Confirm & Delete" : "Confirm Transfer"}
            variant="contained"
            disabled={handleDisableConfirmButton}
            onClick={() =>
              handleConfirmTransfer(
                selectedSales,
                selectedMerch,
                selectedThirdParty
              )
            }
            fontSize="15px"
            color={checkedCustomersArr.length ? "primary" : "confirmDelete"}
            sx={{ height: "37px" }}
          />
        )}
      </DialogActions>
    </Dialog>
  );
};

TransferPopup.propTypes = {
  checkedCustomersArr: array,
  isOpen: bool,
  handleClose: func,
  handleConfirmTransfer: func,
  handleConfirmAssign: func,
  checkedReps: array,
  queryParams: object,
  customersShowInactive: bool,
  deletingReps: bool,
};

TransferPopup.defaultProps = {
  checkedCustomersArr: [],
  isOpen: false,
  selectedRep: null,
  checkedReps: [],
  deletingReps: false,
};

export default TransferPopup;
