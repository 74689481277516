import { ClipBoard } from "components/Icons/ClipBoard";
import { BookIcon, CustomerIcon } from "../../../../../components/Icons";

export const KEY_TYPES = {
  customers: "customers",
  products: "products",
  orders: "orders",
};

export const getImportOptions = ({ isProdEnv, repPermissions, isAdmin }) => [
  {
    title: "Customers",
    icon: <CustomerIcon width={63.52} height={52.8} />,
    key: KEY_TYPES.customers,
    disabled: false,
    disabledImport: repPermissions && !repPermissions?.customers?.import,
    disabledExport: repPermissions && !repPermissions?.customers?.export,
  },
  {
    title: "Products",
    icon: <BookIcon />,
    key: KEY_TYPES.products,
    disabled: isProdEnv || isAdmin,
    disabledImport: repPermissions && !repPermissions?.catalog?.import,
    disabledExport: repPermissions && !repPermissions?.catalog?.export,
  },
  {
    title: "Orders",
    icon: <ClipBoard />,
    key: KEY_TYPES.orders,
    disabled: false,
    disabledImport: repPermissions && !repPermissions?.orders?.import,
    disabledExport: repPermissions && !repPermissions?.orders?.export,
  },
];
