import { shape, array, func, bool } from "prop-types";
import { Box, Button, Typography } from "@mui/material";
import { ItemText } from "./ItemText";
import { CardItem } from "./CardItem";
import { StyledButton } from "../../../../components";
import { FilledPlusIcon } from "../../../../components/Icons";
import CustomerCardPopup from "../../../CustomersPage/components/CustomerCreditCards/components/CustomerCardPopup/CustomerCardPopup";
import { useEffect, useState } from "react";
import { getDistributorPaymentCardsService } from "../../../../services/stripe";
import { error } from "../../../../utils/notifications";

export const StepTwoHalf = ({ formData, regState, onSubmit }) => {
  const { loading } = regState;
  const { formField, setValue } = formData || {};
  const { cards } = formField || [];

  const [openCardDialog, setOpenCardDialog] = useState(false);

  const headers = { headers: { Authorization: `Bearer ${formField?.token}` } };

  const handleSaveCard = (cards) => {
    setValue("cards", cards?.rows);
    setValue("editCard", null);
    setOpenCardDialog(false);
  };

  useEffect(() => {
    getDistributorPaymentCardsService(headers)
      .then((res) => setValue("cards", res?.rows))
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err?.rows);
        error(err?.response?.data?.message || "Something went wrong.");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <CustomerCardPopup
        isRegistration
        skipContactValidate
        replacedCardId={formField?.editCard?.id}
        isOpen={openCardDialog}
        setCardDefault
        handleClose={() => {
          setValue("editCard", null);
          setOpenCardDialog(false);
        }}
        distributorId={formField?.distributor?.id}
        // billingAddress={currentUser?.billingAddress} //?
        billingAddress={
          formField?.distributor?.billingAddress || formField?.billingAddress
        }
        headers={headers}
        handleSave={handleSaveCard}
      />
      <Box minHeight="500px">
        <Box
          sx={{
            pt: 2,
            display: "flex",
            flexDirection: "column",
            gap: "22px",
          }}
        >
          <Box textAlign="center">
            <Typography fontSize={25} fontWeight={400} color="#5F6267">
              Add payment method
            </Typography>
          </Box>

          <Box
            sx={{
              py: 3,
              border: "0.5px solid #CCCCCC",
              borderRadius: "4px",
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <ItemText
              name="Subscription"
              value={
                formField?.distributor?.subscriptionForConfirmation?.name ||
                formField?.distributor?.confirmationSubscriptionDraft
                  ?.subscription?.name
              }
              valueStyle={{ fontWeight: 600, color: "#47A06D" }}
            />
            <ItemText
              name="Subscription price"
              value={`$ ${
                formField?.distributor?.subscriptionForConfirmation?.price ||
                formField?.distributor?.confirmationSubscriptionDraft
                  ?.subscription?.price
              } ${
                (formField?.distributor?.subscriptionForConfirmation?.term ||
                  formField?.distributor?.confirmationSubscriptionDraft
                    ?.subscription?.term) === "MONTHLY"
                  ? " / month"
                  : ""
              }`}
            />
            <Box pl="27px" pt={2}>
              <Typography fontSize={16} color="#292929" whiteSpace="pre-wrap">
                {`Your subscription automatically renews until canceled.\nTo avoid being charged, you must cancel at least a day before each renewal date.`}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            {!!cards?.length && (
              <CardItem
                card={cards?.[0]}
                onEditCard={() => {
                  setValue("editCard", cards?.[0]);
                  setOpenCardDialog(true);
                }}
              />
            )}
          </Box>
          {!cards?.length && (
            <Box
              sx={{
                border: "0.5px solid #CCCCCC",
                borderRadius: "4px",
                height: "51px",
                px: "18px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <StyledButton
                label="Add Card"
                fontSize="14px"
                startIcon={<FilledPlusIcon />}
                color="blackBtn"
                sx={{ height: "28px", width: "100%" }}
                onClick={() => setOpenCardDialog(true)}
              />
            </Box>
          )}
        </Box>
        <Box mt="20px" mb="26px">
          <Button
            disabled={loading || !cards?.length}
            sx={{
              height: "48px",
              fontSize: 20,
              fontWeight: 500,
            }}
            fullWidth
            variant="contained"
            onClick={onSubmit}
          >
            {loading ? "Loading..." : "Next Step"}
          </Button>
        </Box>
      </Box>
    </>
  );
};

StepTwoHalf.propTypes = {
  formData: shape({
    cards: array,
  }),
  regState: shape({
    loading: bool,
  }),
  onSubmit: func,
};
StepTwoHalf.defaultProps = {};
