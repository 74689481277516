import { useCallback } from "react";
import { shape, string, func, bool, number } from "prop-types";
import moment from "moment";
import { capitalize } from "lodash";
import { Box, Button, Radio, Typography } from "@mui/material";
import { CardIconComponent } from "../../CardIconComponent/CardIconComponent";
import { ExpiredIcon } from "../../Icons";

export const CardItem = ({
  card,
  onEditCard,
  isLoading,
  onSetDefaultCard,
  showRadioBtn,
}) => {
  const isCardExpired = useCallback((month, year) => {
    const cardDate = moment(`${year}.${month}`).format("yyyy.MM");

    return moment().diff(cardDate, "months") > 0;
  }, []);

  return (
    <Box
      sx={{
        border: "0.5px solid #CCCCCC",
        borderRadius: "4px",
        height: "51px",
        px: 1,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
        }}
      >
        {showRadioBtn && (
          <Radio
            sx={{
              p: "6px",
            }}
            disabled={isLoading}
            checked={card?.defaultMethod}
            onChange={() => onSetDefaultCard(card)}
          />
        )}

        <CardIconComponent size={30} type={card?.brand?.toLowerCase()} />

        <Typography
          sx={{
            fontSize: 22,
            fontWeight: 400,
            color: "#707070",
          }}
        >
          {capitalize(card?.brand)} ending in {card?.last4}
        </Typography>
        {isCardExpired(card?.expMonth, card?.expYear) && <ExpiredIcon />}
      </Box>
      <Button
        sx={{
          fontSize: 18,
        }}
        onClick={() => onEditCard(card)}
      >
        Replace
      </Button>
    </Box>
  );
};

CardItem.propTypes = {
  card: shape({
    name: string,
    brand: string,
    expMonth: number,
    expYear: number,
    last4: string,
    defaultMethod: bool,
  }),
  onEditCard: func,
  isLoading: bool,
  onSetDefaultCard: func,
  showRadioBtn: bool,
};
CardItem.defaultProps = {
  showRadioBtn: true,
};
