import * as React from "react";
export const ChainIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={9.448}
    height={9.443}
    viewBox="0 0 9.448 9.443"
    {...props}
  >
    <g data-name="Icon ionic-ios-link" fill="#47a06d">
      <path
        data-name="Path 5312"
        d="M5.266 6.653h-.027a.376.376 0 0 0-.218.1L3.555 8.224a1.653 1.653 0 0 1-2.338-2.338l1.557-1.557a1.643 1.643 0 0 1 .259-.211 1.669 1.669 0 0 1 .336-.17 1.589 1.589 0 0 1 .341-.084 1.61 1.61 0 0 1 .232-.016h.1a1.649 1.649 0 0 1 1.062.477 1.628 1.628 0 0 1 .388.62.357.357 0 0 0 .438.229h.007a.355.355 0 0 0 .238-.434 2.057 2.057 0 0 0-.55-.931 2.39 2.39 0 0 0-1.308-.665 1.904 1.904 0 0 0-.129-.018 2.342 2.342 0 0 0-.252-.014c-.059 0-.118 0-.175.007a2.29 2.29 0 0 0-.368.057c-.025 0-.048.011-.073.018a2.361 2.361 0 0 0-.443.166 2.332 2.332 0 0 0-.629.452L.692 5.368A2.4 2.4 0 0 0 0 7.059a2.387 2.387 0 0 0 4.072 1.687l1.483-1.482a.359.359 0 0 0-.289-.611Z"
      />
      <path
        data-name="Path 5313"
        d="M8.747.697a2.392 2.392 0 0 0-3.376 0L3.925 2.143a.365.365 0 0 0 .229.622.369.369 0 0 0 .288-.1l1.449-1.448a1.653 1.653 0 0 1 2.338 2.338L6.672 5.112a1.643 1.643 0 0 1-.259.211 1.669 1.669 0 0 1-.336.17 1.59 1.59 0 0 1-.341.084 1.61 1.61 0 0 1-.232.016h-.1a1.631 1.631 0 0 1-1.435-1.056.363.363 0 0 0-.434-.222.367.367 0 0 0-.257.465 2.074 2.074 0 0 0 .536.845 2.39 2.39 0 0 0 1.437.683 2.343 2.343 0 0 0 .252.014q.089 0 .177-.007a2.6 2.6 0 0 0 .438-.073 2.361 2.361 0 0 0 .443-.166 2.332 2.332 0 0 0 .629-.452l1.561-1.549a2.389 2.389 0 0 0 0-3.378Z"
      />
    </g>
  </svg>
);
