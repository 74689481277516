import { Box } from "@mui/material";
import { string } from "prop-types";

const NotAvailable = ({ text }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100%"
      color="#9C9C9C"
      fontSize="12px"
    >
      {text}
    </Box>
  );
};

NotAvailable.propTypes = {
  text: string,
};

NotAvailable.defaultProps = {
  text: "Not available for 3rd party reps",
};

export default NotAvailable;
