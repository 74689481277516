import { string, number, oneOfType, object, bool } from "prop-types";
import { Box, Skeleton, Typography } from "@mui/material";
import { truncateText } from "helpers/helpers";
import { StyledTooltip } from "components";

export const TotalSummaryInfoItem = ({
  name,
  value,
  valueStyles,
  isLoading,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        "&:not(:last-of-type)": { borderRight: "1px solid #D5D9D9" },
        maxHeight: "25.5px",
      }}
    >
      <Typography
        sx={{
          pl: "clamp(6px, 0.84vw, 16px)",
          pr: isLoading ? "clamp(3px, 0.5vw, 8px)" : "clamp(6px, 0.84vw, 16px)",
        }}
        fontSize="clamp(11.5px, 1vw, 17px)"
        noWrap
      >
        <Box
          component="span"
          sx={{
            color: "#A5A5A5",
            fontWeight: 400,
          }}
        >
          {name}{" "}
        </Box>
        {isLoading ? null : (
          <StyledTooltip
            isDark
            title={
              <Box>
                <Typography fontSize={11}>{value}</Typography>
              </Box>
            }
            followCursor
          >
            <Box
              component="span"
              sx={{
                color: "#5F6267",
                fontWeight: 700,
                ...valueStyles,
              }}
            >
              {truncateText(value, 12)}
            </Box>
          </StyledTooltip>
        )}
      </Typography>
      {isLoading ? (
        <Skeleton
          sx={{
            mr: 2,
          }}
          width={15}
          height={30}
        />
      ) : null}
    </Box>
  );
};

TotalSummaryInfoItem.propTypes = {
  name: string,
  value: oneOfType([number, string]),
  isLoading: bool,
  valueStyles: object,
};
TotalSummaryInfoItem.defaultProps = { name: "", value: 0, isLoading: false };
