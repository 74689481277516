import { TABS } from "Pages/OrdersPage/components/OrdersPageTab/constants";
import { openFilePreview } from "Pages/OrdersPage/Orders.helper";
import { useOrders } from "Pages/OrdersPage/Orders.hook";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getOrdersAction, sendOrderEmailAction } from "redux/actions/orders";
import {
  getCustomerByIdService,
  updateCustomerService,
} from "services/customers";
import {
  getOrderByIdService,
  getOrderReportPDF as getOrderReportPDFService,
  printPackingSlipService,
  bulkPackingSlipOrderReportService,
  bulkConfirmDeliveriesService,
} from "services/orders";
import { SCROLL_LIMIT } from "utils/constants";
import { error, success } from "utils/notifications";
import { defaultTableData } from "./CustomersPage/pages/CustomerProfilePage/CustomerProfile.constants";
import { setConnectToFirebaseSnackbarAction } from "redux/actions/snackbar";
// import { useThirdPartyOrdersTabActions } from "./OrdersPage/useThirdPartyOrdersTabActions";

export const OrdersActions = (
  archiveCallback = () => {},
  handleFetchOrdersThirdParty = () => {}
) => {
  const dispatch = useDispatch();
  const isCheckedGroupOrders = false;

  const navigate = useNavigate();
  const [order, setOrder] = useState({});
  const [customId, setCustomId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [checkedOrders, setCheckedOrders] = useState([]);
  const [isAcceptPaymentPopupOpen, setIsAcceptPaymentPopupOpen] =
    useState(false);

  const [openRefundDialog, setOpenRefundDialog] = useState(false);
  const [openReturnDialog, setOpenReturnDialog] = useState(false);
  const [printInvoiceListDialog, setPrintInvoiceListDialog] = useState(false);

  const [nameRefundDialog, setNameRefundDialog] = useState("");
  const [isConfirmDeliveryPopupOpen, setIsConfirmDeliveryPopupOpen] =
    useState(false);
  const [type, setType] = useState("");

  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [emailOpen, setEmailOpen] = useState(false);

  const [currentTab, setCurrentTab] = useState(TABS[0]);

  const [customerState, setCustomerState] = useState({
    customer: null,
    loading: true,
    tableData: defaultTableData,
    tableTab: { value: 0, label: "Orders" },
  });

  // const { handleFetchOrdersThirdParty } = useThirdPartyOrdersTabActions();

  const { handleBulkArchiveOrders, handleMultipleArchiveOrders } = useOrders({
    setIsLoading,
    fetchDirectList: true,
    handleFetchOrdersThirdParty,
    currentTab,
  });

  const handleOpenEmail = (order) => {
    setEmailOpen(true);
    setOrder(order);
  };
  const handleCloseAcceptPaymentPopup = () => {
    setIsAcceptPaymentPopupOpen(false);
  };

  const [assignTagsState, setAssignTagsState] = useState({
    open: false,
    order: null,
    orders: [],
    isBulk: false,
    isDraft: false,
  });

  const handleCloseAssignTagsDialog = () => {
    setAssignTagsState({
      open: false,
      order: null,
      orders: [],
      isBulk: false,
      isDraft: false,
    });
  };

  const handleClickOnAssignTags = useCallback(
    ({ order, checkedOrders, isDraft = false }) => {
      if (order)
        return setAssignTagsState(() => ({
          open: true,
          order,
          orders: [],
          isBulk: false,
          isDraft,
        }));

      setAssignTagsState((prev) => ({
        ...prev,
        open: true,
        order: null,
        orders: checkedOrders,
        isBulk: true,
        isDraft,
      }));
    },
    []
  );

  const handleFetchOrdersList = () => {
    if (currentTab === "Direct")
      dispatch(getOrdersAction({ limit: SCROLL_LIMIT }));
    if (currentTab === "3rd Party")
      handleFetchOrdersThirdParty({
        type: "THIRD_PARTY",
        limit: SCROLL_LIMIT,
        page: 1,
        sort_by_order_date: "desc",
        archived: order?.archived,
      });
  };

  const getOrderReportPDF = ({ orderId, onSuccess, onError }) => {
    getOrderReportPDFService(orderId)
      .then((res) => {
        if (onSuccess) onSuccess(res);
      })
      .catch((err) => {
        if (onError) onError(err);
        // eslint-disable-next-line no-console
        console.log(err?.response?.data?.message);
        error(err?.response?.data?.message || "Something went wrong.");
      });
  };

  const getPrintPackingSlip = ({ orderId, type, onSuccess, onError }) => {
    printPackingSlipService(orderId, type)
      .then((res) => {
        if (onSuccess) onSuccess(res);
      })
      .catch((err) => {
        if (onError) onError(err);
        // eslint-disable-next-line no-console
        console.log(err?.response?.data?.message);
        error(err?.response?.data?.message || "Something went wrong.");
      });
  };

  const getBulkPackingSlipOrderReport = ({ data, onSuccess, onError }) => {
    bulkPackingSlipOrderReportService({ data })
      .then((res) => {
        if (onSuccess) onSuccess(res);
      })
      .catch((err) => {
        if (onError) onError(err);
        // eslint-disable-next-line no-console
        console.log(err?.response?.data?.message);
        error(err?.response?.data?.message || "Something went wrong.");
      });
  };

  const handleChoseMenuItem = (order, itemName, onSuccess) => {
    switch (itemName) {
      case "Return Items":
      case "Refund":
        setIsLoading(true);
        getOrderByIdService(order?.id)
          .then((res) => {
            setOrder(res);
            if (itemName === "Refund") {
              setOpenRefundDialog(true);
              setNameRefundDialog(itemName);
            }
            if (itemName === "Return Items") {
              setOpenReturnDialog(true);
            }
          })
          .catch((err) => {
            error(err?.response?.data?.message || "Something went wrong.");
          })
          .finally(() => setIsLoading(false));
        break;
      case "Edit order":
        navigate(`/cart/${order.id}`, { state: order?.products });
        break;
      case "Duplicate order":
        navigate(`/cart/duplicate/${order.id}`, {
          state: { duplicateOrder: order },
        });
        break;
      case "Print order":
        if (currentTab === "3rd Party") {
          setCheckedOrders([order]);
          return setPrintInvoiceListDialog(true);
        }
        setIsLoading(true);
        getOrderReportPDF({
          orderId: order.id,
          onSuccess: (res) => {
            openFilePreview(res);
            handleFetchOrdersList();
            setIsLoading(false);
            if (typeof onSuccess === "function") onSuccess();
          },
          onError: () => setIsLoading(false),
        });
        break;
      case "Print packing slip":
        setIsLoading(true);
        getPrintPackingSlip({
          orderId: order.id,
          type: "order",
          onSuccess: (res) => {
            openFilePreview(res);
            handleFetchOrdersList();
            setIsLoading(false);
            if (typeof onSuccess === "function") onSuccess();
          },
          onError: () => setIsLoading(false),
        });
        break;
      case "Print picklist":
        setIsLoading(true);
        getBulkPackingSlipOrderReport({
          data: {
            ordersIds: [order.id],
            groupOrders: isCheckedGroupOrders,
          },
          onSuccess: (res) => {
            setTimeout(() => {
              openFilePreview(res.data);
              handleFetchOrdersList();
              setIsLoading(false);
              if (typeof onSuccess === "function") onSuccess();
            }, 1000);
          },
          onError: () => setIsLoading(false),
        });
        break;
      case "Print all":
        setIsLoading(true);
        Promise.all([
          getOrderReportPDF({
            orderId: order.id,
            onSuccess: (res) => openFilePreview(res),
          }),
          getPrintPackingSlip({
            orderId: order.id,
            type: "order",
            onSuccess: (res) => openFilePreview(res),
          }),
          getBulkPackingSlipOrderReport({
            data: { ordersIds: [order.id], groupOrders: isCheckedGroupOrders },
            onSuccess: (res) => {
              setTimeout(() => {
                openFilePreview(res.data);
              }, 1000);
            },
          }),
        ])
          .catch((err) => {
            error(err?.response?.data?.message || "Something went wrong.");
          })
          .finally(() => {
            handleFetchOrdersList();
            if (typeof onSuccess === "function") onSuccess();
            setIsLoading(false);
          });
        break;
      case "Confirm shipment":
        setIsLoading(true);
        getOrderByIdService(order.id)
          .then((res) => {
            setOrder(res);
            setIsConfirmDeliveryPopupOpen(true);
            setType(itemName);
            onSuccess && onSuccess();
          })
          .catch((err) => {
            error(err?.response?.data?.message || "Something went wrong.");
          })
          .finally(() => setIsLoading(false));
        break;
      case "Confirm delivery":
        setIsLoading(true);
        getOrderByIdService(order?.id)
          .then((res) => {
            setOrder(res);
            setIsConfirmDeliveryPopupOpen(true);
            setType(itemName);
            // if (onSuccess) onSuccess(res); // avoid refresh the orders list on the customer profile
          })
          .catch((err) => {
            error(err?.response?.data?.message || "Something went wrong.");
          })
          .finally(() => setIsLoading(false));
        break;
      case "Accept payment":
        setIsLoading(true);
        getOrderByIdService(order?.id)
          .then((res) => {
            setOrder(res);
            setIsAcceptPaymentPopupOpen(true);
          })
          .catch((err) => {
            error(err?.response?.data?.message || "Something went wrong.");
          })
          .finally(() => setIsLoading(false));

        break;
      case "Email order":
        // eslint-disable-next-line no-console
        console.log("Email order is exclude for now");
        break;
      case "Cancel order":
        setIsLoading(true);
        getOrderByIdService(order?.id)
          .then((res) => {
            setOrder(res);
            setOpenCancelDialog(true);
          })
          .catch((err) => {
            error(err?.response?.data?.message || "Something went wrong.");
          })
          .finally(() => setIsLoading(false));
        break;
      case "Archive order":
        handleBulkArchiveOrders(order, onSuccess);
        setCheckedOrders([]);
        archiveCallback && archiveCallback([]);
        break;
      case "Archive orders bulk":
        handleMultipleArchiveOrders(order, true, onSuccess);
        setCheckedOrders([]);
        archiveCallback && archiveCallback([]);
        break;
      case "Unarchive orders bulk":
        handleMultipleArchiveOrders(order, false, onSuccess);
        setCheckedOrders([]);
        archiveCallback && archiveCallback([]);
        break;
      case "Unarchive order":
        archiveCallback && archiveCallback([]);
        handleBulkArchiveOrders(order, onSuccess);
        break;
      case "Assign tags":
        handleClickOnAssignTags({ order });
        break;

      default:
        break;
    }
  };

  const [confirmDeliveryState, setConfirmDeliveryState] = useState({
    loading: false,
    orders: [],
    isOpenDialog: false,
  });

  const handleCloseConfirmDeliveryDialog = () => {
    setConfirmDeliveryState({
      loading: false,
      orders: [],
      isOpenDialog: false,
    });
  };

  const handleBulkConfirmDelivery = async ({ orderIds, onSuccess, data }) => {
    const countOrders = orderIds?.length;
    const SEND_TO_BACKGROUND_COUNT = 100;

    const sendToBackground = countOrders >= SEND_TO_BACKGROUND_COUNT;

    if (sendToBackground) dispatch(setConnectToFirebaseSnackbarAction(true));

    setConfirmDeliveryState((prev) => ({ ...prev, loading: true }));
    try {
      const preparedData = {
        orderIds,
        ...data,
        sendToBackground,
      };

      await bulkConfirmDeliveriesService(preparedData);

      if (onSuccess) onSuccess();
      dispatch(getOrdersAction({ limit: SCROLL_LIMIT }));
      if (!sendToBackground) success("Orders marked as fulfilled");
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      error(err?.response?.data?.message);
    } finally {
      setConfirmDeliveryState((prev) => ({ ...prev, loading: false }));
    }
  };

  const [loadingSendEmail, setLoadingSendEmail] = useState(false);

  const handleSendEmail = (data) => {
    setLoadingSendEmail(true);
    dispatch(
      sendOrderEmailAction(order.id, data, () => {
        setEmailOpen(false);
        setLoadingSendEmail(false);
      })
    );
  };

  const updateCustomerState = useCallback((newValues) => {
    setCustomerState((prev) => ({ ...prev, ...newValues }));
  }, []);

  const handleFetchCustomer = useCallback(async () => {
    updateCustomerState({ loading: true });
    try {
      const customer = await getCustomerByIdService(customId);
      updateCustomerState({ loading: false, customer });
    } catch (err) {
      error(err?.response?.data?.message);
      updateCustomerState({ loading: false });
    }
  }, [customId, updateCustomerState]);

  const handleUpdateCustomer = async (data) => {
    try {
      updateCustomerState({ loading: true });
      const customer = await updateCustomerService(data, customId);
      updateCustomerState({ loading: false, customer });
    } catch (err) {
      updateCustomerState({ loading: false });
      error(err?.response?.data?.message);
    }
  };
  const handleSaveContacts = async (oldContacts, newContact) => {
    delete newContact.fid;
    const contacts = [...oldContacts, newContact];
    await handleUpdateCustomer({ contacts });
    dispatch(getOrdersAction({ limit: SCROLL_LIMIT }));
  };

  return {
    handleFetchCustomer,
    updateCustomerState,
    customerState,
    handleChoseMenuItem,
    setOpenReturnDialog,
    setNameRefundDialog,
    setOpenRefundDialog,
    setOpenCancelDialog,
    setCurrentTab,
    openCancelDialog,
    openRefundDialog,
    nameRefundDialog,
    openReturnDialog,
    type,
    currentTab,
    setType,
    isAcceptPaymentPopupOpen,
    setIsAcceptPaymentPopupOpen,
    handleOpenEmail,
    order,
    setOrder,
    emailOpen,
    setEmailOpen,
    handleSendEmail,
    loadingSendEmail,
    isLoading,
    checkedOrders,
    isConfirmDeliveryPopupOpen,
    setIsConfirmDeliveryPopupOpen,
    handleCloseAcceptPaymentPopup,
    handleSaveContacts,
    setCustomId,
    confirmDeliveryState,
    setConfirmDeliveryState,
    handleCloseConfirmDeliveryDialog,
    handleBulkConfirmDelivery,
    assignTagsState,
    setAssignTagsState,
    handleCloseAssignTagsDialog,
    handleClickOnAssignTags,
    printInvoiceListDialog,
    setPrintInvoiceListDialog,
    setCheckedOrders,
  };
};
