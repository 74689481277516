import { useCallback, useContext, useMemo } from "react";
import { object, string } from "prop-types";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import moment from "moment";
import { Box } from "@mui/material";
import { CheckInStatusBlock } from "components";
import { InfoBlock } from "./components";
import { currentUserSelector } from "redux/selectors/auth";
import { getFormattedDate, photoUrl } from "helpers/helpers";
import { CustomerProfileContext } from "Pages/CustomersPage/pages/CustomerProfilePage/CustomerProfilePage";

const selector = createSelector(currentUserSelector, (currentUser) => ({
  currentUser,
}));
export const HeaderBlock = ({ activity, timeZone }) => {
  const { currentUser } = useSelector(selector);

  const { setMapData } = useContext(CustomerProfileContext);

  const profilePhoto = useCallback(
    (user) => user?.avatar || photoUrl(user?.profilePhoto?.fileName),
    []
  );

  const preparedAvatarData = useMemo(() => {
    return {
      name:
        ["COMMENT_ADDED", "NOTE_ADDED"].includes(activity?.type) &&
        activity?.createdBy === "DISTRIBUTOR" &&
        currentUser?.name
          ? currentUser.name
          : activity?.representativeDuplicate?.name ||
            activity?.appCustomer?.name,

      src: profilePhoto(
        activity?.representativeDuplicate ||
          activity?.appCustomer ||
          currentUser
      ),

      onMouseEnter: () => {},
      // handleSetAssignedPopover(
      //   e.target,
      //   (activity?.type === "COMMENT_ADDED" &&
      //   activity?.createdBy === "DISTRIBUTOR" &&
      //   currentUser) ||
      //     activity?.representativeDuplicate ||
      //     activity?.appCustomer,
      //   true
      // ),
      onMouseLeave: () => {},
      // handleSetAssignedPopover(
      //   e.target,
      //   (activity?.type === "COMMENT_ADDED" &&
      //   activity?.createdBy === "DISTRIBUTOR" &&
      //   currentUser) ||
      //     activity?.representativeDuplicate ||
      //     activity?.customer,
      //   false
      // ),
    };
  }, [
    activity?.appCustomer,
    activity?.createdBy,
    activity?.representativeDuplicate,
    activity?.type,
    currentUser,
    profilePhoto,
  ]);

  const checkInTime = useMemo(() => {
    return activity?.checkIn?.checkedInAt
      ? activity?.checkIn?.checkedInAt
      : activity?.createdAt;
  }, [activity?.checkIn?.checkedInAt, activity?.createdAt]);

  const checkOutTime = useMemo(() => {
    return activity?.checkIn?.checkedOutAt
      ? activity?.checkIn?.checkedOutAt
      : null;
  }, [activity?.checkIn?.checkedOutAt]);

  const date = useMemo(() => {
    const date = moment(getFormattedDate(checkInTime, timeZone));
    const checkInYear = date.get("year");
    const currentYear = moment().year();

    return date.format(`MMM D${checkInYear === currentYear ? "" : ", YY"}`);
  }, [checkInTime, timeZone]);

  const setDate = useMemo(() => {
    return `${date} ${
      checkInTime
        ? `| ${moment(getFormattedDate(checkInTime, timeZone)).format(
            "h:mm a"
          )}`
        : ""
    } ${
      checkOutTime
        ? ` - ${moment(getFormattedDate(checkOutTime, timeZone)).format(
            "h:mm a"
          )}`
        : ""
    }`;
  }, [checkInTime, checkOutTime, date, timeZone]);

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "100%",
        height: "46px",
        display: "flex",
      }}
    >
      <Box width="96%" maxWidth="96%" display="flex" alignItems="center">
        <InfoBlock
          name={preparedAvatarData.name}
          src={preparedAvatarData.src}
          createdBy={activity?.createdBy}
          date={setDate}
          onMouseEnter={preparedAvatarData.onMouseEnter}
          onMouseLeave={preparedAvatarData.onMouseLeave}
        />
      </Box>

      <Box width="4%" display="flex" alignItems="center">
        <CheckInStatusBlock
          item={activity}
          onClickCheckin={() => setMapData(activity)}
          showCheckInStatus={currentUser?.checkInCustomerRadiusEnabled}
        />
      </Box>
    </Box>
  );
};

HeaderBlock.propTypes = { activity: object, timeZone: string };
