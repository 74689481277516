import { array, func, shape, bool } from "prop-types";
import { Box } from "@mui/material";
import { CardPlanItem } from "./CardPlanItem/CardPlanItem";

export const DialogBodyComponent = ({
  state,
  handleSetExtraUser,
  handleChangeCurrentPlan,
  handleSetPayDescription,
}) => {
  const { isTrial } = state;

  return (
    <Box
      sx={{
        px: 4.375,
        display: "flex",
        justifyContent:
          state?.plans_list?.length > 2 ? "space-between" : "center",
        gap: 3,
      }}
    >
      {state?.plans_list[0] &&
        state?.current_plan?.subscription?.name !== "Wholesaler Premium" && (
          <CardPlanItem
            plan={state?.plans_list[0]}
            {...{
              state,
              handleSetExtraUser,
              handleChangeCurrentPlan,
              handleSetPayDescription,
            }}
          />
        )}
      {state?.plans_list[1] && (
        <CardPlanItem
          plan={state?.plans_list[1]}
          {...{
            state,
            handleSetExtraUser,
            handleChangeCurrentPlan,
            handleSetPayDescription,
          }}
        />
      )}
      {!isTrial && (
        <CardPlanItem
          isExtra
          {...{
            state,
            handleSetExtraUser,
            handleChangeCurrentPlan,
            handleSetPayDescription,
          }}
        />
      )}
    </Box>
  );
};

DialogBodyComponent.propTypes = {
  plans: array,
  state: shape({
    isTrial: bool,
  }),
  handleSetExtraUser: func,
  handleChangeCurrentPlan: func,
  handleSetPayDescription: func,
};
DialogBodyComponent.defaultProps = {
  plans: [],
  state: {
    isTrial: false,
  },
  handleSetExtraUser: () => {},
  handleChangeCurrentPlan: () => {},
  handleSetPayDescription: () => {},
};
