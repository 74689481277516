import { Grid, Typography } from "@mui/material";
import { TAB_HEADER_ITEMS } from "./PaymentPlansHeader.constants";
import { cl } from "./PaymentPlansHeader.styles";

export const PaymentPlansHeader = () => {
  return (
    <Grid sx={cl.headerWrapper} container>
      {TAB_HEADER_ITEMS.map(({ name, size, styles }) => (
        <Grid key={name} sx={styles} xs={size} item container>
          <Typography sx={cl.typography}>{name}</Typography>
        </Grid>
      ))}
    </Grid>
  );
};
