/* eslint-disable no-useless-escape */
import * as Yup from "yup";

const PHONE_REGEXP =
  /^[\+][\d]{1}[\s]?[\(]?[\d]{3}[\)]?[\s]?[\d]{3}[\s]?[\d]{4}$/im;

export const validationSchema = () =>
  Yup.object().shape({
    name: Yup.string().trim().required("This field is required"),
    email: Yup.string()
      .trim()
      .test("emailValidation", "Email not valid!", (value) => {
        if (value === "") return true;
        const EMAIL_REGEXP =
          /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d)|(([a-z]|\d)([a-z]|\d|-|\.|_|~)*([a-z]|\d)))\.)+(([a-z])|(([a-z])([a-z]|\d|-|\.|_|~)*([a-z])))$/i; // eslint-disable-line
        return EMAIL_REGEXP.test(value);
      }),

    phone: Yup.string()
      .trim()
      .test("phoneValidation", "Not valid phone number!", (value) => {
        const cleaned = value.replace(/[^+\d]/g, "");

        if (!value) return true;
        if (cleaned === "+1") return true;

        return PHONE_REGEXP.test(value);
      }),
    role: Yup.string().trim().required("This field is required"),
    custom: Yup.string()
      .trim()
      .when("role", {
        is: "custom",
        then: Yup.string()
          .trim()
          .test(
            "customValidation",
            "Incorrect custom contact type!",
            (value) => {
              const NAME_REGEXP = /[a-zA-Z0-9]/;
              return NAME_REGEXP.test(value);
            }
          ),
      }),
  });
