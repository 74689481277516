import { bool, object } from "prop-types";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";

export const VariationsSelectorBlock = ({ isEdit, control }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingY: "20px",
        paddingLeft: "34px",
        paddingRight: "90px",
      }}
    >
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: 500,
          color: "#707070",
        }}
      >
        Does the product have variations?
      </Typography>
      <Controller
        render={({ field: { onChange, value } }, field) => (
          <FormControl sx={{ marginLeft: 0 }} component="fieldset">
            <RadioGroup
              sx={{
                display: "flex",
                justifyContent: "space-around",
              }}
              row
              {...field}
            >
              <FormControlLabel
                sx={{ marginRight: 0 }}
                label={
                  <Typography
                    sx={{ fontSize: 14, color: "#707070" }}
                    variant="caption"
                  >
                    Yes
                  </Typography>
                }
                control={
                  <Radio
                    checked={value}
                    disabled={isEdit}
                    onChange={() => onChange(true)}
                    sx={{
                      "& svg": {
                        width: "18px",
                        height: "18px",
                      },
                    }}
                  />
                }
              />
              <FormControlLabel
                sx={{
                  m: 0,
                }}
                label={
                  <Typography
                    sx={{ fontSize: 14, color: "#707070" }}
                    variant="caption"
                  >
                    No
                  </Typography>
                }
                control={
                  <Radio
                    checked={!value}
                    disabled={isEdit}
                    onChange={() => onChange(false)}
                    sx={{
                      "& svg": {
                        width: "18px",
                        height: "18px",
                      },
                    }}
                  />
                }
              />
            </RadioGroup>
          </FormControl>
        )}
        name="isMultiple"
        control={control}
      />
    </Box>
  );
};

VariationsSelectorBlock.propTypes = { isEdit: bool, control: object };
