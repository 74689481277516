import { func } from "prop-types";
import { IconButton, Stack, Typography } from "@mui/material";
import { CrossIcon } from "components/Icons";

export const HeaderBlock = ({ onClose }) => {
  return (
    <Stack
      sx={{
        height: "67px",
        minHeight: "67px",
        borderBottom: "0.5px solid #D5D9D9",
        background: "#F8F8F8",
        justifyContent: "space-between",
        alignItems: "center",
        pl: 4,
        pr: 1,
      }}
      direction="row"
    >
      <Typography fontWeight={400} fontSize={17} color="#3F3F3F">
        Assign tags
      </Typography>

      <IconButton onClick={onClose}>
        <CrossIcon />
      </IconButton>
    </Stack>
  );
};

HeaderBlock.propTypes = { onClose: func };
HeaderBlock.defaultProps = { onClose: () => {} };
