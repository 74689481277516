import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box } from "@mui/material";
import { func, string, bool } from "prop-types";
import { forwardRef } from "react";

const ThreeDotButton = forwardRef(
  ({ onClick, paddingRight, disabled, ...props }, ref) => {
    return (
      <Box
        sx={{
          cursor: "pointer",
          pointerEvents: disabled && "none",
          opacity: disabled && 0.5,
        }}
        pr={paddingRight || "12px"}
        onClick={(e) => {
          if (!disabled) onClick(e);
        }}
        height="24px"
        ref={ref}
        {...props}
      >
        <MoreVertIcon style={{ fill: "#9C9C94" }} />
      </Box>
    );
  }
);

ThreeDotButton.displayName = "ThreeDotButton";

ThreeDotButton.propTypes = {
  onClick: func,
  paddingRight: string,
  disabled: bool,
};

export default ThreeDotButton;
