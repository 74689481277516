export const cl = {
  headerWrapper: {
    width: "100%",
    height: "39px",
    minHeight: "39px",
    backgroundColor: "#EEEEEE",
    border: "1px solid #D5D9D9",
    borderRadius: "4px 4px 0 0",
    alignItems: "center",
  },
  typography: {
    fontSize: 16,
    color: "#6A6A6A",
  },
};
