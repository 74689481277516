import { makeStyles } from "@mui/styles";

export const cl = {
  textTitle: { fontSize: "30px", color: "#707070" },
  textSubTitle: { fontSize: "20px", fontWeight: 400, color: "#707070" },
  blockTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    p: 0,
    width: "500px",
    borderBottom: "1px solid #D5D9D9",
  },
  boxTitle: {
    paddingTop: "14px",
    paddingLeft: "26px",
    paddingBottom: "10px",
  },
  blockContent: {
    maxWidth: "500px",
    marginTop: "10px",
    p: 0,
  },
  blockActions: {
    maxWidth: "500px",
    paddingX: 3.25,
  },
  textContent: {
    fontSize: 12,
    color: "#000000",
  },
  textSmallContent: {
    fontSize: 10,
    color: "#000000",
  },
  summaryText: { fontSize: 13, fontWeight: 400, color: "#000000" },
  summaryTextBold: { fontSize: 13, fontWeight: 600, color: "#000000" },
  summaryTextHuge: { fontSize: 15, fontWeight: 500, color: "#000000" },
};

const useStyles = makeStyles(() => ({
  textInput: {
    fontSize: "12px",
    height: "28px",
    fontWeight: 400,
    "& .MuiInputBase-input": {
      paddingLeft: "8px",
      paddingRight: "8px",
    },
  },
  textInputAmount: {
    fontSize: "12px",
    height: "30px",
    fontWeight: 400,
    "& .MuiInputBase-input": {
      paddingLeft: "8px",
      paddingRight: "8px",
    },
  },
  textInputColor: {
    fontSize: "12px",
    fontWeight: 400,
    height: "28px",
    color: "#47A06D",
    "& .MuiInputBase-input": {
      paddingLeft: "8px",
      paddingRight: "8px",
    },
  },
  multiTextInput: {
    fontSize: "12px",
    paddingTop: 6,
    paddingBottom: 6,
  },
  inputLabel: {
    fontSize: "11px",
    fontWeight: 400,
    color: "#000000",
    marginLeft: "9px",
    "&.Mui-focused": {
      color: "#000000",
    },
  },
  inputLabelText: {
    fontSize: "12px",
    fontWeight: 400,
    color: "#000000",
    "&.Mui-focused": {
      color: "#000000",
    },
  },
  selectInput: {
    "& .MuiInputBase-input": {
      fontSize: "12px",
      fontWeight: 400,
      color: "#5F6267",
    },
    "& svg": {
      fill: "#B5B5AC",
    },
  },
}));

export default useStyles;
