import axios from "axios";

export const saveProspectsSearchResultsService = async (data) => {
  return await axios
    .post("/customers/prospects-search-result", data)
    .then((res) => res.data);
};

export const createProspectsService = async (data) => {
  return await axios.post("/customers/prospects", data).then((res) => res.data);
};

export const getProspectsSearchResultsService = async (data) => {
  return await axios
    .get("/customers/prospects-search-result", data)
    .then((res) => res.data);
};

export const createProspectsFromSearchResultsService = async (data) => {
  return await axios
    .post("/customers/from-prospects", data)
    .then((res) => res.data);
};
