import * as React from "react";

export const ViewTypeIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14.678}
    height={14.678}
    viewBox="0 0 14.678 14.678"
    {...props}
  >
    <g
      data-name="Icon feather-layout"
      fill="none"
      stroke="#5f6267"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        data-name="Path 5289"
        d="M2.02.5h10.638a1.52 1.52 0 0 1 1.52 1.52v10.638a1.52 1.52 0 0 1-1.52 1.52H2.02a1.52 1.52 0 0 1-1.52-1.52V2.02A1.52 1.52 0 0 1 2.02.5"
      />
      <path data-name="Path 5290" d="M.5 5.059h13.678" />
      <path data-name="Path 5291" d="M5.059 14.177V5.059" />
    </g>
  </svg>
);
