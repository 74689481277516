/* eslint-disable no-extra-boolean-cast */
import { object, func, array, string, bool } from "prop-types";

import { useAdmin } from "helpers/helpers";

import {
  CheckboxIcon,
  CheckboxIconChecked,
  SearchIcon,
} from "components/Icons";
import { StyledTextField } from "components";
import { getItemWithId } from "Pages/CatalogPage/CatalogNewProductPage/ContentTabs/RequiredInfoTab/components/CategoriesComponent/helpers";
import { ChipComponent } from "Pages/CatalogPage/CatalogNewProductPage/ContentTabs/RequiredInfoTab/components/CategoriesComponent/ChipComponent";

import useStyles, { cl } from "./styles";

import {
  Box,
  Stack,
  Paper,
  IconButton,
  Typography,
  InputAdornment,
  CircularProgress,
  ClickAwayListener,
  Checkbox,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

export const FieldList = ({
  parentCategory,
  searchInput,
  setSearchInput,
  showCategories,
  setShowCategories,
  categoriesList,
  onClose,
  handleAddCategory,
  isCheckedCategory,
  fieldName,
  loading,
}) => {
  const isAdmin = useAdmin();
  const classes = useStyles();

  return (
    <ClickAwayListener
      onClickAway={(event) => {
        onClose(event);
      }}
    >
      <Paper
        sx={{
          width: "100%",
          height: "168px",
          overflow: "hidden",
        }}
        className={classes.recipientsTable}
        elevation={0}
      >
        <StyledTextField
          fullWidth
          size="small"
          value={searchInput}
          onChange={(e) => {
            setSearchInput(e.target.value);
          }}
          placeholder="Search categories"
          onClick={(e) => {
            e.stopPropagation();
          }}
          sx={cl.textInputSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        {loading ? (
          <Stack justifyContent="center" alignItems="center" height="70%">
            <CircularProgress size="30px" />
          </Stack>
        ) : (
          <>
            {!!showCategories.length ? (
              <>
                {parentCategory && (
                  <Box sx={{ mb: "10px" }}>{parentCategory}</Box>
                )}
                <Stack
                  direction="column"
                  gap="10px"
                  pl="10px"
                  pr="24px"
                  sx={{ overflow: "hidden" }}
                >
                  {showCategories?.map((category) => {
                    const isChecked = isCheckedCategory(
                      category.id,
                      category?.parentCategoryId
                    );
                    return (
                      <Box
                        key={category?.id}
                        sx={{
                          borderRadius: "4px",
                          width: "100%",
                          height: "26px",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          cursor: "pointer",
                          overflow: "hidden",
                        }}
                      >
                        <Checkbox
                          checked={isChecked}
                          sx={{
                            m: 0,
                            p: 0,

                            "& svg": {
                              width: "17px",
                              height: "17px",
                            },
                          }}
                          icon={<CheckboxIcon />}
                          checkedIcon={<CheckboxIconChecked />}
                          onClick={() => handleAddCategory(category, fieldName)}
                        />

                        <Typography
                          sx={{
                            fontSize: 13,
                            fontWeight: 400,
                            color: "#5F6267",
                            flexGrow: 1,
                            ml: "10px",
                          }}
                        >
                          {category?.name}
                        </Typography>

                        {!!category?._count?.products && (
                          <ChipComponent
                            count={category._count.products}
                            name=""
                            size="small"
                            variant="outlined"
                            sx={{
                              width: "fit-content",
                              height: "17px",
                              fontSize: "12px",
                              fontWeight: 300,
                              backgroundColor: "#F7F7F7",
                              border: "0.5px solid #D5D9D9",
                            }}
                          />
                        )}
                        {!!category?.childCategories?.length ? (
                          <IconButton
                            sx={{
                              ml: "5px",
                              width: "20px",
                              height: "20px",
                            }}
                            disabled={isAdmin}
                            onClick={(e) => {
                              e.stopPropagation();
                              setShowCategories(
                                getItemWithId(categoriesList, category?.id)
                                  ?.childCategories
                              );
                            }}
                          >
                            <ChevronRightIcon />
                          </IconButton>
                        ) : (
                          <Box width="25px" />
                        )}
                      </Box>
                    );
                  })}
                </Stack>
              </>
            ) : (
              <Stack justifyContent="center" alignItems="center" height="70%">
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: 400,
                    color: "#5F6267",
                  }}
                >
                  No categories found
                </Typography>
              </Stack>
            )}
          </>
        )}
      </Paper>
    </ClickAwayListener>
  );
};

FieldList.propTypes = {
  control: object,
  parentCategory: object,
  searchInput: string,
  setSearchInput: func,
  showCategories: array,
  setShowCategories: func,
  categoriesList: array,
  onClose: func,
  handleAddCategory: func,
  isCheckedCategory: func,
  fieldName: string,
  loading: bool,
};
