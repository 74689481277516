export const SET_UNREAD_COUNT = "SET_UNREAD_COUNT";
export const SET_ROOMS = "SET_ROOMS";
export const SET_ROOMS_LOADING = "SET_ROOMS_LOADING";

export const SET_UNREAD_GROUP_ROOMS_COUNT = "SET_UNREAD_GROUP_ROOMS_COUNT";
export const SET_GROUP_CHAT_ROOMS = "SET_GROUP_CHAT_ROOMS";
export const SET_GROUP_ROOMS_LOADING = "SET_GROUP_ROOMS_LOADING";

export const setUnreadRoomsCount = (payload) => ({
  type: SET_UNREAD_COUNT,
  payload,
});

export const setChatRooms = (payload) => ({
  type: SET_ROOMS,
  payload,
});

export const setRoomsLoading = (payload) => ({
  type: SET_ROOMS_LOADING,
  payload,
});

export const setUnreadGroupRoomsCount = (payload) => ({
  type: SET_UNREAD_GROUP_ROOMS_COUNT,
  payload,
});

export const setGroupChatRooms = (payload) => ({
  type: SET_GROUP_CHAT_ROOMS,
  payload,
});

export const setGroupRoomsLoading = (payload) => ({
  type: SET_GROUP_ROOMS_LOADING,
  payload,
});
