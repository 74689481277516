import * as React from "react";
export const TaskBigIcon = (props) => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m8.8 14.402 6.729 6.728c1.59-2.81 6.832-9.629 15.089-14.437"
      stroke="#5F6267"
      strokeWidth={1.5}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 10.4c0-2.24 0-3.36.436-4.216a4 4 0 0 1 1.748-1.748C7.04 4 8.16 4 10.4 4h11.2c2.24 0 3.36 0 4.216.436a4 4 0 0 1 1.7 1.656l-1.138.74a1.453 1.453 0 0 0-.096-.24 2 2 0 0 0-.874-.874C24.98 5.5 24.42 5.5 23.3 5.5H8.7c-1.12 0-1.68 0-2.108.218a2 2 0 0 0-.874.874C5.5 7.02 5.5 7.58 5.5 8.7v14.6c0 1.12 0 1.68.218 2.108a2 2 0 0 0 .874.874c.428.218.988.218 2.108.218h14.6c1.12 0 1.68 0 2.108-.218a2 2 0 0 0 .874-.874c.218-.428.218-.988.218-2.108V12.058l1.5-1.133V21.6c0 2.24 0 3.36-.436 4.216a4 4 0 0 1-1.748 1.748C24.96 28 23.84 28 21.6 28H10.4c-2.24 0-3.36 0-4.216-.436a4 4 0 0 1-1.748-1.748C4 24.96 4 23.84 4 21.6V10.4Z"
      fill="#5F6267"
    />
  </svg>
);
