import { DRAFT_ORDER_TYPES } from "utils/constants";

export const FILTER_STATUSES = [
  {
    label:
      DRAFT_ORDER_TYPES.open.charAt(0) +
      DRAFT_ORDER_TYPES.open.slice(1).toLowerCase(),
    value: DRAFT_ORDER_TYPES.open.toLowerCase(),
  },
  {
    label: "Completed",
    value: DRAFT_ORDER_TYPES.closed.toLowerCase(),
  },
];

export const FILTER_DATE_VALUES = [
  { name: "All", id: "" },
  { name: "Today", id: "today" },
  { name: "Tomorrow", id: "tomorrow" },
  { name: "This week", id: "this_week" },
  { name: "Next week", id: "next_week" },
  { name: "This month", id: "this_month" },
  { name: "Next month", id: "next_month" },
];
