import { Stack, Typography } from "@mui/material";

export const HeaderBlock = () => {
  return (
    <Stack
      sx={{
        backgroundColor: "#F8F8F8",
        borderBottom: "1px solid #D5D9D9",
        p: "7.5px 18px 5px",
        borderRadius: "8px 8px 0 0",
      }}
    >
      <Typography fontSize="13px" lineHeight="17px" color="#5F6267">
        Multiple customers at selected location
      </Typography>
    </Stack>
  );
};
