import { useSelector } from "react-redux";
import { createSelector } from "reselect";

import { Box } from "@mui/material";
import { TransactionsTable } from "./components/TransactionsTable/TransactionsTable";
import { Loader } from "components";
import { currentUserSelector } from "redux/selectors/auth";
import {
  transactionsCountSelector,
  transactionsListLoadingSelector,
  transactionsListSelector,
} from "redux/selectors/transactions";

const selector = createSelector(
  transactionsListSelector,
  transactionsListLoadingSelector,
  transactionsCountSelector,
  currentUserSelector,
  (transactionsList, transactionsLoading, transactionsCount, currentUser) => ({
    transactionsList,
    transactionsLoading,
    transactionsCount,
    currentUser,
  })
);

export const TransactionsTab = () => {
  const {
    transactionsList,
    transactionsLoading,
    transactionsCount,
    currentUser,
  } = useSelector(selector);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 1.4,
        marginTop: 1.85,
      }}
    >
      <Loader isLoading={transactionsLoading && !transactionsList?.length} />
      <TransactionsTable
        list={transactionsList}
        count={transactionsCount}
        timeZone={currentUser.timeZone}
      />
    </Box>
  );
};

TransactionsTab.propTypes = {};
TransactionsTab.defaultProps = {};
