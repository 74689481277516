/* eslint-disable no-undef */
import axios from "axios";

export const getUserByIdService = async (id) => {
  const { data } = await axios.get(`/users/${id}`);
  return data;
};

export const showUserPosition = async (latitude, longitude) => {
  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
  const authClient = axios.create({
    baseURL: process.env.REACT_APP_COUNTRY_API,
  });

  const res = await authClient.get(
    `json?latlng=${latitude},${longitude}&key=${apiKey}&language=en`
  );
  return res.data;
};
