import { array, object } from "prop-types";
import { StyledTooltip } from "components";
import { Box, Typography } from "@mui/material";
import { PrinterIcon } from "components/Icons";
import moment from "moment-timezone";
import { useSelector } from "react-redux";

const TYPE = {
  INVOICE_PRINTED: "- Order",
  PACKING_SLIP_PRINTED: "- Packing Slip",
  PICKLIST_PRINTED: "- Pick List",
};

export const PrintedBlock = ({ lastPrinted, sx = {} }) => {
  const currentUser = useSelector(({ auth }) => auth?.currentUser);
  const timezone = currentUser?.timeZone;

  return (
    <StyledTooltip
      arrow
      placement="top"
      sx={{ "& .MuiTooltip-tooltip": { p: 1 } }}
      title={
        <Box minWidth="200px">
          <Box my={0.2} display="flex" alignItems="center">
            <Box
              width="88px"
              component={Typography}
              fontSize={13}
              fontWeight={600}
              color="#000"
            >
              Printed
            </Box>
            <Box
              component={Typography}
              fontSize={13}
              fontWeight={600}
              color="#000"
            >
              On
            </Box>
          </Box>

          {lastPrinted.map((item) => (
            <Box
              key={item?.orderId}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                width="88px"
                component={Typography}
                fontSize={13}
                fontWeight={300}
                color="#47A06D"
              >
                {TYPE[item?.type]}
              </Box>
              <Box
                component={Typography}
                fontSize={13}
                fontWeight={300}
                color="#000"
              >
                {moment
                  .tz(item?.createdAt, timezone)
                  .format("MMM D [at] h:mm a")}
              </Box>
            </Box>
          ))}
        </Box>
      }
      PopperProps={{
        modifiers: [{ name: "offset", options: { offset: [0, -12] } }],
      }}
    >
      <Box
        sx={{
          width: "28px",
          height: "28px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "#707070",
          ...sx,
        }}
      >
        <PrinterIcon width="17" />
      </Box>
    </StyledTooltip>
  );
};

PrintedBlock.propTypes = { lastPrinted: array.isRequired, sx: object };
PrintedBlock.defaultProps = { lastPrinted: [], sx: {} };

export default PrintedBlock;
