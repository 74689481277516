import { useEffect, useMemo, useState } from "react";
import { object, func } from "prop-types";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import { Box, Typography } from "@mui/material";
import { HoursItem } from "./components";
import { setIncomingReceivingHours } from "Pages/CustomersPage/pages/CustomerPage/CustomerPage.helpers";
import { WEEK_DAYS } from "components/ReceivingHoursDrawer/ReceivingHoursDrawer.constants";

export const HoursBlock = ({
  classes,
  repPermissions,
  customer,
  handleOpenReceivingHours,
}) => {
  const currentUser = useSelector(({ auth }) => auth.currentUser);
  const { timeZone } = currentUser || {};

  const receivingHours = customer?.receivingHours
    ? setIncomingReceivingHours(customer?.receivingHours)
    : WEEK_DAYS;

  const timeWithTimeZone = moment.tz(timeZone);
  const time = moment()
    .utc()
    .set({
      hour: timeWithTimeZone.get("hour"),
      minute: timeWithTimeZone.get("minute"),
      second: 0,
    });

  const [currentWeekOrdered, setCurrentWeekOrdered] = useState([]);

  const setDay = (i, d) => {
    if (i === 0) return "Today";
    if (i === 1) return "Tomorrow";
    return d;
  };

  useEffect(() => {
    const tempArr = [];

    Object.keys(receivingHours)?.forEach((day, i) => {
      const d = moment.tz(timeZone).add(i, "days").format("dddd");
      tempArr.push({
        ...receivingHours?.[d],
        dayOfWeek: setDay(i, d),
      });
    });

    setCurrentWeekOrdered(tempArr);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const incomingDay = receivingHours?.[currentWeekOrdered?.[0]?.name];

  const openingTime = incomingDay?.value?.clockInAt
    ? moment(incomingDay?.value?.clockInAt).utc()
    : "";

  const closingTime = incomingDay?.value?.clockOutAt
    ? moment(incomingDay?.value?.clockOutAt).utc()
    : "";

  const isOpen = useMemo(
    () =>
      openingTime && closingTime
        ? time.isBetween(openingTime, closingTime)
        : false,
    [closingTime, openingTime, time]
  );

  const shouldBeOpenToday = useMemo(() => {
    if (!isOpen) {
      const isTimeArrived = openingTime ? openingTime.isAfter(time) : false;

      return isTimeArrived;
    }
  }, [openingTime, isOpen, time]);

  const opensAt = useMemo(() => {
    if (!isOpen) {
      if (
        !customer?.receivingHours ||
        customer?.receivingHours.every((el) => !el?.working)
      )
        return;

      // today, if it has not yet opened today
      if (shouldBeOpenToday) {
        const hours = openingTime.format("h");
        const minutes = openingTime.format("mm");
        const ampm = openingTime.format("a");
        return minutes === "00"
          ? `Today ${hours} ${ampm}`
          : `Today ${hours}:${minutes} ${ampm}`;
      }

      // tomorrow, if it has already closed today and has time to open tomorrow
      const tempArr = [...currentWeekOrdered];
      let firstElement = tempArr.shift();
      tempArr.push(firstElement);

      const nextOpenDay = tempArr.find((day) => {
        return day?.value?.clockInAt;
      });

      if (nextOpenDay) {
        const hours = moment(nextOpenDay?.value?.clockInAt).utc().format("h");
        const minutes = moment(nextOpenDay?.value?.clockInAt)
          .utc()
          .format("mm");
        const ampm = moment(nextOpenDay?.value?.clockInAt).utc().format("a");

        const t =
          minutes === "00" ? `${hours} ${ampm}` : `${hours}:${minutes} ${ampm}`;

        return `${
          nextOpenDay?.dayOfWeek === "Today"
            ? nextOpenDay?.name
            : nextOpenDay?.dayOfWeek
        } ${t}`;
      }
    }
  }, [
    currentWeekOrdered,
    customer?.receivingHours,
    isOpen,
    openingTime,
    shouldBeOpenToday,
  ]);

  const closesAt = useMemo(() => {
    if (isOpen) {
      const hours = closingTime.format("h");
      const minutes = closingTime.format("mm");
      const ampm = closingTime.format("a");
      return minutes === "00"
        ? `${hours} ${ampm}`
        : `${hours}:${minutes} ${ampm}`;
    }
  }, [closingTime, isOpen]);

  const workingTime = useMemo(() => {
    return (
      <>
        {customer?.receivingHours && (
          <>
            {isOpen ? (
              <Typography fontSize={13} color="#47A06D">
                Open
              </Typography>
            ) : (
              <Typography fontSize={13} color="error">
                Closed
              </Typography>
            )}
          </>
        )}
        {/* {!isOpen && (
          <Typography fontSize={13} color="#EB4233">
            Closed
          </Typography>
        )} */}

        {opensAt && (
          <Typography ml={0.5} fontSize={13} color="#707070">
            <Box color="#D4D4D4" component="span">
              •
            </Box>{" "}
            Opens {opensAt}
          </Typography>
        )}

        {closesAt && (
          <Typography ml={0.5} fontSize={13} color="#707070">
            <Box color="#D4D4D4" component="span">
              •
            </Box>{" "}
            Closes {closesAt}
          </Typography>
        )}
      </>
    );
  }, [closesAt, customer?.receivingHours, isOpen, opensAt]);

  return (
    <Box mt={1}>
      <HoursItem
        {...{
          classes,
          repPermissions,
          customer,
          handleOpenReceivingHours,
          workingTime,
        }}
      />
    </Box>
  );
};

HoursBlock.propTypes = {
  classes: object,
  repPermissions: object,
  customer: object,
  handleOpenReceivingHours: func,
};
HoursBlock.defaultProps = {
  classes: {},
  repPermissions: {},
  customer: {},
};
