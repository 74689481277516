import { makeStyles } from "@mui/styles";

export const cl = {
  categoryChip: {
    border: "0.5px solid #D4D4D4",
    backgroundColor: "#FAFAFB",
    fontSize: 14,
    color: "#5F6368",
    cursor: "pointer",
  },
  textInputSearch: {
    mb: "12px",
    "& .MuiInputAdornment-root": { mt: "-2px", mr: "8px" },
    "& .MuiOutlinedInput-root": {
      pl: "10px",
      fontSize: "15px",
      fontWeight: 300,
      backgroundColor: "#F8F8F8",
      "& > fieldset": {
        border: "none!important",
      },
    },

    "& .MuiOutlinedInput-input": {
      py: "6px",
    },

    "& .MuiInputLabel-root": {
      color: " rgb(181, 181, 172) !important",
      fontSize: "15px",
      fontWeight: 300,
    },
  },
  categoryChipWrapper: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    gap: "6.5px",
    py: "9.5px",
  },
  categoryInput: {
    fontSize: 13,
    flexDirection: "column",
    "& .MuiOutlinedInput-input": {
      padding: "12.2px 14px",
    },
  },
  parentCategoryWrapper: {
    backgroundColor: "red",
    "&:hover": { backgroundColor: "#F7F7F7" },
    borderRadius: "4px",
    width: "100%",
    height: "26px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
};

const useStyles = makeStyles(() => ({
  recipientsTable: {
    zIndex: 10,
    border: "0.5px solid #D5D9D9",
    width: "100%",
    backgroundColor: "#ffffff",
    overflow: "auto",
    borderRadius: "4px",
    marginTop: "6.5px",
    padding: "13px 10px",
    scrollbarWidth: "6px",
    scrollbarColor: "rgba(0, 0, 0, 0.35) rgba(0, 0, 0, 0.1)",

    "&::-webkit-scrollbar": {
      width: "6px",
      borderRadius: "7px",
      backgroundColor: "rgba(0, 0, 0, 0.1)",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "7px",
      backgroundColor: "rgba(0, 0, 0, 0.35)",
    },
  },
}));

export default useStyles;
