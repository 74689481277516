import { useMemo } from "react";
import { string, func, object, bool } from "prop-types";
import { Box, TextField } from "@mui/material";
import { useWindowSize } from "helpers/hooks";

export const NoteBlock = ({ note, setNote, editNote, isAdmin }) => {
  const INLINE_OF_ONE_ROW = 23;
  const PADDINGS_TEXTFIELD = 20;
  const BODY_WRAPPER_PADDINGS = 66;
  const HEADER_HEIGHT = 67;
  const FOOTER_HEIGHT = 67;

  const calcHeight = useMemo(() => {
    return (
      PADDINGS_TEXTFIELD + BODY_WRAPPER_PADDINGS + HEADER_HEIGHT + FOOTER_HEIGHT
    );
  }, []);

  const [, windowHeight] = useWindowSize();

  const dynamicRows = ((windowHeight - calcHeight) / INLINE_OF_ONE_ROW).toFixed(
    0
  );

  const setRows = useMemo(() => {
    // return editNote ? { maxRows: dynamicRows } : { rows: 3 };
    return editNote
      ? { maxRows: dynamicRows >= 4 ? 4 : dynamicRows }
      : { rows: 3 };
  }, [dynamicRows, editNote]);

  return (
    <Box
      px={4}
      pt={editNote ? `${PADDINGS_TEXTFIELD}px` : 0}
      // pb={editNote ? 0 : 2.5}
      pb={2.5}
      height="140px"
      minHeight="140px"
      display="flex"
      // alignItems={editNote ? "flex-start" : "flex-end"}
      alignItems="flex-end"
      flexGrow={editNote ? 1 : 0}
    >
      <TextField
        disabled={isAdmin}
        placeholder="New note"
        multiline
        fullWidth
        value={note}
        onChange={(e) => setNote(e.target.value)}
        {...setRows}
      />
    </Box>
  );
};

NoteBlock.propTypes = {
  note: string,
  setNote: func,
  editNote: object,
  isAdmin: bool,
};
NoteBlock.defaultProps = { note: "", setNote: () => {} };
