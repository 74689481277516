import { useMemo, useRef, useState } from "react";
import { object, func, bool, shape } from "prop-types";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { ExpandMore } from "@mui/icons-material";
import NumberFormat from "react-number-format";
import { LogoComponent } from "../../components";
import { INDUSTRIES, COUNTRY_LIST } from "../../../../utils/constants";
import AddressField from "../../../CustomersPage/components/AddressField/AddressField";
import useStyles, { cl } from "./styles";
import { StyledField } from "./StyledField";
import {
  PlaceAutocompleteField,
  StyledTextField,
} from "../../../../components/TextFields/TextFields";
import { getAddressComponents } from "../../../CustomersPage/components/AddressField/AddressField.helpers";
import {
  getPhoneCodes,
  getTimeZonesList,
  onPastePhone,
  sliceCountry,
} from "../../../../helpers/helpers";
import {
  CANADAS_STATE_NAMES,
  STATE_NAMES,
} from "Pages/CustomersPage/pages/NewCustomerPage/NewCustomerPage.constants";
import {
  defaultValues,
  initialErrPhoneStatus,
} from "Pages/RegistrationPage/RegistrationPage.constants";

export const StepTwo = ({
  formData,
  onSubmit,
  loading,
  errPhoneStatus,
  setErrPhoneStatus,
}) => {
  const classes = useStyles();

  const { control, errors, setValue, formField, clearErrors, reset, trigger } =
    formData;

  const {
    name,
    tax,
    industry,
    phone,
    country,
    billingAddress,
    countryPrefics,
    timeZone,
  } = formField || {};

  const [toggleDetails, setToggleDetails] = useState("");

  const nameRef = useRef(null);

  const isCanada = useMemo(() => country === "CA", [country]);

  const countryList = useMemo(
    () => (country === "CA" ? CANADAS_STATE_NAMES : STATE_NAMES),
    [country]
  );

  const phoneCodeList = useMemo(
    () => getPhoneCodes(isCanada ? "CA" : "US"),
    [isCanada]
  );

  const userTimeZones = useMemo(
    () => getTimeZonesList(isCanada ? "CA" : "US"),
    [isCanada]
  );

  const handleResetForm = (userCountry) => {
    reset({
      ...formField,
      billingAddress: defaultValues.billingAddress,
      name: "",
      country: userCountry,
      timeZone: "",
      industry: "",
      website: "",
      phone: "",
      tax: "",
      countryPrefics: getPhoneCodes(userCountry)[0],
    });
  };

  const [googleAddress, setGoogleAddress] = useState({
    billingAddress: null,
  });

  const preparedNumber = (n) => {
    if (!n) return "";
    if (n.startsWith("(") && n.length === 14)
      return n.replace(/\s|\(|\)|-/g, "");
    return n;
  };

  const handleSetCustomer = (customerInfo) => {
    const { street, state, city, zip } = getAddressComponents(customerInfo);

    setValue("name", customerInfo.name, { shouldDirty: true });
    setValue("phone", preparedNumber(customerInfo.formatted_phone_number), {
      shouldDirty: true,
    });
    setValue("website", customerInfo.website || "", { shouldDirty: true });

    const address = {
      formatted_address: sliceCountry(customerInfo.formatted_address),
      lat: customerInfo.geometry.location.lat(),
      lng: customerInfo.geometry.location.lng(),
      street,
      state,
      city,
      zip,
    };

    if (setGoogleAddress)
      setGoogleAddress((prev) => ({
        ...prev,
        billingAddress: { ...address },
      }));
    clearErrors("billingAddress");
    setValue("billingAddress", address);
  };

  const resetBillingAddressCoordinates = () => {
    setValue("billingAddress.lat", null);
    setValue("billingAddress.lng", null);
  };

  const changeCurtainMargin = useMemo(
    () =>
      toggleDetails &&
      (!!errors?.billingAddress?.lat || !!errors?.billingAddress?.lng),
    [errors.billingAddress, toggleDetails]
  );

  const disabledNextStepBtn = useMemo(() => {
    if (loading) return true;

    if (!name || errors?.name) return true;
    if (!tax || errors?.tax) return true;
    if (!industry || errors?.industry) return true;
    if (!phone || errors?.phone) return true;
    if (!timeZone || errors?.timeZone) return true;
    if (
      !billingAddress?.formatted_address ||
      !billingAddress?.street ||
      !billingAddress?.city ||
      !billingAddress?.state ||
      !billingAddress?.zip ||
      !billingAddress?.lat ||
      !billingAddress?.lng ||
      errors?.billingAddress
    )
      return true;

    return false;
  }, [
    loading,
    name,
    errors?.name,
    errors?.tax,
    errors?.industry,
    errors?.phone,
    errors?.timeZone,
    errors?.billingAddress,
    tax,
    industry,
    phone,
    timeZone,
    billingAddress?.formatted_address,
    billingAddress?.street,
    billingAddress?.city,
    billingAddress?.state,
    billingAddress?.zip,
    billingAddress?.lat,
    billingAddress?.lng,
  ]);

  return (
    <Box mb="22px">
      <Box
        sx={{
          pt: "10px",
          display: "flex",
          flexDirection: "column",
          gap: "22px",
        }}
      >
        <Controller
          render={({ field, fieldState: { error } }) => (
            <PlaceAutocompleteField
              size="medium"
              paperStyles={{ top: 46 }}
              sx={{ borderRadius: "8px" }}
              InputProps={{
                style: {
                  fontSize: "18px",
                  height: "48px",
                  borderRadius: "8px",
                },
              }}
              InputLabelProps={{
                sx: {
                  top: "-4px",
                  fontSize: "18px",
                  height: "48px",
                  "&.MuiInputLabel-shrink": {
                    top: "0px",
                  },
                },
              }}
              formSx={{ fontSize: 18, height: "48px" }}
              handleSetCustomer={handleSetCustomer}
              country={country}
              error={error ? error.message : ""}
              label="Legal business name"
              autoComplete="new-password"
              noErrorMessage
              setCustomerLoading={() => {}}
              {...field}
              ref={nameRef}
              value={field.value}
              onChange={(e) => {
                clearErrors("name");
                field.onChange(e);
              }}
            />
          )}
          name="name"
          control={control}
        />

        <Controller
          render={({ field, fieldState: { error } }) => (
            <NumberFormat
              customInput={StyledField}
              noErrorMessage
              fullWidth
              autoComplete="new-password"
              label="Federal Tax ID (EIN)"
              format="##-#######"
              error={error}
              {...field}
              onChange={(e) => {
                clearErrors("tax");
                field.onChange(e);
              }}
            />
          )}
          name="tax"
          control={control}
        />

        <AddressField
          fieldName="billingAddress"
          label="Billing address"
          control={control}
          country={country}
          countryList={countryList}
          setValue={setValue}
          address={billingAddress}
          clearErrors={clearErrors}
          curtainProps={{
            curtainHeight: "250px",
            marginTop: changeCurtainMargin && "8px",
          }}
          error={!!errors.billingAddress}
          typingTrigger={resetBillingAddressCoordinates}
          errorCoordinates={
            !!errors?.billingAddress?.lat || !!errors?.billingAddress?.lng
          }
          billingErrorOnly
          addressToggled={toggleDetails === "billingAddress"}
          // eslint-disable-next-line no-undef
          apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
          onToggle={() => {
            if (toggleDetails === "billingAddress") return setToggleDetails("");
            setToggleDetails("billingAddress");
          }}
          googleAddress={googleAddress}
          toggleButtonProps={{
            sx: {
              height: "48px",
              "& .MuiTypography-root": { fontSize: "18px" },
              borderColor: errors?.billingAddress ? "#F2545B" : null,
              borderRadius: "8px",
            },
          }}
          selectProps={cl.selectProps}
          textFieldProps={{
            onBlur: () => trigger("billingAddress"),
            InputProps: {
              size: "small",
              className: classes.input,
            },
            size: "small",
            InputLabelProps: {
              className: classes.inputLabel,
            },
          }}
          formSx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#D5D9D9",
              },
            },
          }}
          noToggleLabel
          hideUnit
          autocompleteProps={{
            autoComplete: "new-password",
            noErrorMessage:
              !errors?.billingAddress?.lat || !errors?.billingAddress?.lng,
            size: "small",
            InputProps: {
              className: classes.input,
              placeholder: "",
              sx: {
                "& input": { animationName: "none !important" },
              },
            },
            InputLabelProps: {
              size: "small",
              className: classes.inputLabel,
            },
          }}
        />

        <Controller
          render={({ field, fieldState: { error } }) => (
            <FormControl fullWidth>
              <InputLabel
                id={`${field.name}-select-label`}
                sx={{
                  top: "-4px",
                  fontSize: "18px",
                  "&.MuiInputLabel-shrink": {
                    top: "0px",
                  },
                }}
              >
                Industry
              </InputLabel>
              <Select
                label="Industry"
                labelId={`${field.name}-select-label`}
                sx={{
                  height: 48,
                  borderRadius: "8px",
                  "& .MuiInputBase-input": {
                    fontSize: 18,
                  },
                  "& fieldset": {
                    border: "1px solid #D5D9D9",
                  },
                  color: field.value === "" ? "#717171" : "",
                  "& fieldset legend": {
                    pr: field?.value ? "6px" : 0,
                  },
                }}
                fullWidth
                IconComponent={(props) => (
                  <ExpandMore sx={{ fontSize: 20 }} {...props} />
                )}
                error={!!error?.message}
                {...field}
                onChange={(e) => {
                  clearErrors("industry");
                  field.onChange(e);
                }}
              >
                {INDUSTRIES.map((industry) => (
                  <MenuItem key={industry} value={industry}>
                    {industry}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          name="industry"
          control={control}
        />

        <Controller
          render={({ field, fieldState: { error } }) => (
            <NumberFormat
              customInput={StyledTextField}
              fullWidth
              format={countryPrefics?.format}
              mask="_"
              size="large"
              InputProps={{
                style: {
                  height: "48px",
                  fontSize: "18px",
                  borderRadius: "9px",
                  paddingLeft: 0,
                },
                startAdornment: (
                  <FormControl>
                    <Controller
                      render={({ field }) => {
                        return (
                          <Select
                            sx={{
                              borderRadius: "0",
                              marginRight: 1,
                              "& .MuiSelect-select": {
                                minWidth: "50px",
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderWidth: "0 1px 0 0 !important",
                                borderColor: "#D5D9D9 !important",
                              },
                            }}
                            size="small"
                            renderValue={() => field?.value?.phone_code}
                            IconComponent={(props) => (
                              <ExpandMore sx={{ fontSize: 20 }} {...props} />
                            )}
                            {...field}
                            value={JSON.stringify(field?.value)}
                          >
                            {phoneCodeList.map((code) => {
                              return (
                                <MenuItem key={code.label} value={code}>
                                  {code.phone_code} {code.label}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        );
                      }}
                      name="countryPrefics"
                      control={control}
                    />
                  </FormControl>
                ),
              }}
              error={
                errPhoneStatus.status
                  ? errPhoneStatus.message
                  : error?.message || ""
              }
              {...field}
              autoComplete="new-password"
              onPaste={(e) => {
                const prepareVal = (v) => {
                  if (/^\+[\d]+/.test(v)) {
                    return v.substring(2);
                  }
                  return v;
                };

                onPastePhone({
                  e,
                  setValue: (type, val) => setValue(type, prepareVal(val)),
                  currentValue: formField.phone,
                });
              }}
              onChange={(e) => {
                clearErrors("phone");
                setErrPhoneStatus(initialErrPhoneStatus);
                field.onChange(e);
              }}
            />
          )}
          name="phone"
          control={control}
        />

        <Controller
          render={({ field, fieldState: { error } }) => (
            <StyledField
              autoComplete="new-password"
              {...{ control, field, error }}
              label="Website"
            />
          )}
          name="website"
          control={control}
        />

        <LogoComponent control={control} setValue={setValue} />

        <Controller
          render={({ field, fieldState: { error } }) => (
            <FormControl fullWidth>
              <InputLabel
                id={`${field.name}-select-label`}
                sx={{
                  top: "-4px",
                  fontSize: "18px",
                  "&.MuiInputLabel-shrink": {
                    top: "0px",
                  },
                }}
              >
                Country
              </InputLabel>
              <Select
                label="Country"
                labelId={`${field.name}-select-label`}
                sx={{
                  height: 48,
                  borderRadius: "8px",
                  "& .MuiInputBase-input": {
                    fontSize: 18,
                  },
                  "& fieldset": {
                    border: "1px solid #D5D9D9",
                  },
                  color: field.value === "" ? "#717171" : "",
                  "& fieldset legend": {
                    fontSize: "13px",
                  },
                }}
                fullWidth
                IconComponent={(props) => (
                  <ExpandMore sx={{ fontSize: 20 }} {...props} />
                )}
                error={!!error?.message}
                {...field}
                onChange={(e) => {
                  const newCountry = e.target.value;
                  handleResetForm(newCountry);
                }}
              >
                {COUNTRY_LIST.map((country) => (
                  <MenuItem key={country?.value} value={country?.value}>
                    {country?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          name="country"
          control={control}
        />

        <Controller
          render={({ field, fieldState: { error } }) => (
            <FormControl fullWidth>
              <InputLabel
                id={`${field.name}-select-label`}
                sx={{
                  top: "-4px",
                  fontSize: "18px",
                  "&.MuiInputLabel-shrink": {
                    top: "0px",
                  },
                }}
              >
                Primary Time Zone
              </InputLabel>
              <Select
                label="Primary Time Zone"
                labelId={`${field.name}-select-label`}
                sx={{
                  height: 48,
                  borderRadius: "8px",
                  "& .MuiInputBase-input": {
                    fontSize: 18,
                  },
                  "& fieldset": {
                    border: "1px solid #D5D9D9",
                  },
                  color: field.value === "" ? "#717171" : "",
                  "& fieldset legend span": { pr: "18px" },
                }}
                fullWidth
                IconComponent={(props) => (
                  <ExpandMore sx={{ fontSize: 20 }} {...props} />
                )}
                error={!!error?.message}
                {...field}
              >
                {userTimeZones.map((zone) => (
                  <MenuItem key={zone?.value} value={zone?.value}>
                    {zone?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          name="timeZone"
          control={control}
        />
      </Box>

      <Box mt="20px">
        <Button
          sx={{
            height: "48px",
            fontSize: 20,
            fontWeight: 500,
          }}
          disabled={disabledNextStepBtn}
          fullWidth
          variant="contained"
          onClick={onSubmit}
        >
          {loading ? "Loading..." : "Next Step"}
        </Button>
      </Box>
    </Box>
  );
};

StepTwo.propTypes = {
  formData: shape({
    control: object,
    errors: object,
    setValue: func,
    formField: object,
  }),
  setErrPhoneStatus: func,
  onSubmit: func,
  loading: bool,
  errPhoneStatus: object,
};
