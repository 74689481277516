import * as Yup from "yup";

export const validationSchema = () =>
  Yup.object().shape({
    name: Yup.string().trim().required("This field is required"),
    priority: Yup.string().trim().required("This field is required"),
    stops: Yup.array().test(
      "stopsValidation",
      "Add at least one stop.",
      (value) => {
        return value.length > 0;
      }
    ),
  });
