import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  widgetWrapper: {
    border: "1px solid #D5D9D9",
    height: "296px",
    padding: ({ breakpoint }) =>
      breakpoint === "xl" ? "13px 26px 10px 27px" : "13px 16px 10px 17px",
    position: "relative",
  },

  selectWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  tabs: {
    minHeight: "29px",
    alignItems: "center",
    "& .MuiTabs-flexContainer": { justifyContent: "space-between" },
    marginRight: "-10px",
  },
  tab: {
    textTransform: "none",
    fontSize: 11,
    color: "#707070",
    borderRadius: "4px",
    "&.Mui-selected": {
      backgroundColor: "rgba(71, 160, 110, 0.2) !important",
    },
    minWidth: "36px",
    height: "24px",
    minHeight: "24px",
    paddingLeft: "11px",
    paddingRight: "11px",
  },
}));

export default useStyles;
