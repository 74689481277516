import React from "react";

export const PlacesIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="52"
      height="52"
      viewBox="0 0 52 52"
    >
      <rect
        id="Rectangle_8857"
        data-name="Rectangle 8857"
        width="52"
        height="52"
        rx="26"
        fill="#409a65"
        opacity="0.1"
      />
      <g id="akar-icons:map" transform="translate(17 17)">
        <g id="Group" transform="translate(-3 -3)">
          <path
            id="Vector"
            d="M5.368,1.682,2.632.769A2,2,0,0,0,0,2.667V14.45a2,2,0,0,0,1.368,1.9l4,1.333a2,2,0,0,0,1.264,0L11.368,16.1a2,2,0,0,1,1.264,0l2.736.912A2,2,0,0,0,18,15.116V3.334a2,2,0,0,0-1.367-1.9L12.633.1a2,2,0,0,0-1.265,0L6.631,1.681a2,2,0,0,1-1.264,0Z"
            transform="translate(3 3.108)"
            fill="none"
            stroke="#409a65"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M0,2V17ZM6,0V16"
            transform="translate(9 3)"
            fill="none"
            stroke="#409a65"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
        </g>
      </g>
    </svg>
  );
};
