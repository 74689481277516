import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  tabs: {
    width: "100%",
    maxWidth: "fit-content",
    minHeight: "38px",
    "& .MuiTabs-flexContainer": {
      justifyContent: "space-between",
      gap: "10px",
    },
    marginRight: "5px",
    gap: "5px",
  },

  tab: {
    textTransform: "none",
    fontSize: "20px",
    color: "#707070",
    borderRadius: "4px",
    "&.Mui-selected": {
      backgroundColor: "rgb(71, 160, 110, 0.2)!important",
    },
    minWidth: "57px",
    // width: "84px",
    height: "38px",
    minHeight: "38px",
    padding: "0 13px",
    fontWeight: ({ tabStyles }) => tabStyles?.fontWeight || "500",
  },
}));

export default useStyles;
