import { alpha, styled } from "@mui/material";

const CircleWrap = styled("div")(({ charLength, theme: { palette } }) => ({
  display: "flex",
  alignItems: "center",
  border: `2px solid ${alpha(palette.primary.main, 0.2)}`,
  borderRadius: "50%",
  width: "25px",
  height: "25px",
  fontSize: `${12 - (charLength - 2 < 0 ? 0 : charLength - 2) * 2}px`,
  justifyContent: "center",
  color: palette.primary.main,
  fontWeight: 600,
}));

export { CircleWrap };
