import { Box } from "@mui/material";
import useStyles from "./styles";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  CustomerTables,
  LeftColumnBlock,
  RightColumnBlock,
} from "./components";
import { CustomerProfileContext } from "../../CustomerProfilePage";
import { hasWorkingDays } from "../../CustomerProfile.helpers";
import {
  useCustomerActions,
  useCustomerCards,
  useEmailActions,
  useLicenses,
  useRepsActions,
  useRightColumnActions,
} from "../../CustomerProfile.hooks";
import CustomerCardPopup from "Pages/CustomersPage/components/CustomerCreditCards/components/CustomerCardPopup/CustomerCardPopup";
import { useDispatch, useSelector } from "react-redux";
import { customerTagsListSelector } from "redux/selectors/tags";
import ContactAddDrawer from "components/ContactsSection/ContactPopup/ContactAddDrawer";
import ContactDeletePopup from "components/ContactsSection/ContactPopup/ContactDeletePopup";
import { useContact } from "components/ContactsSection/useContact.hooks";
import { updateCustomerAction } from "redux/actions/customers";
import PhotoGroupPopup from "Pages/CustomersPage/components/GalleryTab/components/PhotoGroupPopup/PhotoGroupPopup";
import EditPaymentPopup from "Pages/CustomersPage/components/CustomerCreditCards/components/EditPaymentPopup/EditPaymentPopup";
import EditCustomerPopup from "Pages/OrdersPage/components/EditCustomerPopup/EditCustomerPopup";
import RepsAssignedDrawer from "components/RepsAssignedDrawer/RepsAssignedDrawer";
import { ProfileComponent } from "Pages/RepresentativesPage/components";
import { Loader, LicensesDrawer, TagsDrawer } from "components";
import MapPopup from "Pages/CustomersPage/pages/NewCustomerPage/MapPopup/MapPopup";
import ReceivingHoursDrawer from "components/ReceivingHoursDrawer/ReceivingHoursDrawer";
import {
  setIncomingReceivingHours,
  setReceivingHours,
} from "Pages/CustomersPage/pages/CustomerPage/CustomerPage.helpers";
import { useReceivingHours } from "Pages/CustomersPage/pages/CustomerPage/components/CustomerMainBody/components/ReceivingHoursBlock/useReceivingHours";
import { WEEK_DAYS } from "components/ReceivingHoursDrawer/ReceivingHoursDrawer.constants";
import { success } from "utils/notifications";
import CustomerEmailDialog from "./components/CustomerEmailDialog/CustomerEmailDialog";
import { TAG_TYPES } from "helpers/useTagsActions";

const CustomerProfile = () => {
  const tagsList = useSelector(customerTagsListSelector);
  const [editCustomerOpen, setEditCustomerOpen] = useState(false);
  const [editCustomerType, setEditCustomerType] = useState("");
  const [editCustomerData, setEditCustomerData] = useState(null);
  const [assignedRepsOpen, setAssignedRepsOpen] = useState(false);
  const [isAddingHoursMode, setIsAddingHoursMode] = useState(false);
  const classes = useStyles();

  const [deleteContact, setDeleteContact] = useState(null);
  const [deleteContactOpen, setDeleteContactOpen] = useState(false);
  const [editContact, setEditContact] = useState(null);

  const dispatch = useDispatch();
  const {
    customerState,
    photoGroupPopupState,
    updateCustomerState,
    order,
    emailOpen,
    mapData,
    setMapData,
    setContactsOpen,
    contactsOpen,
    repPermissions,
  } = useContext(CustomerProfileContext);

  const {
    handleSaveContacts,
    handleSaveTags,
    handleSaveAssignReps,
    handleSaveLicenses,
  } = useCustomerActions();

  const customer = useMemo(
    () => customerState.customer,
    [customerState.customer]
  );

  useEffect(() => {
    if (!contactsOpen) setEditContact(null);
    if (!deleteContactOpen) setDeleteContact(null);
  }, [contactsOpen, deleteContactOpen]);

  const {
    cardsState,
    handleFetchCards,
    handleOpenCardsPopup,
    handleCloseCardsPopup,
    customerId,
    handleConfirmDeleteCard,
    handleEditCard,
    editPaymentOpen,
    editCard,
    setEditPaymentOpen,
  } = useCustomerCards();

  const {
    contacts,
    assignedRepresentatives,
    shippingAddress,
    billingAddress,
    tags,
    name,
    displayName,
    receivingHours,
    // orderDirectInvite,
    documents,
  } = useMemo(() => customer, [customer]);

  const [tagsOpen, setTagsOpen] = useState(false);

  const customerDescRef = useRef(null);

  const handleChangeCustomer = (data, type, err, setError, callback) => {
    if (!data) return;
    if (err?.formatted_address?.type === "duplicate")
      return setError("data.formatted_address", {
        ...err.formatted_address,
      });

    let fieldName = "";
    switch (type) {
      case "customer contact": {
        fieldName = "contactId";
        break;
      }
      case "billing address": {
        fieldName = "billingAddress";
        break;
      }
      case "shipping address": {
        fieldName = "shippingAddress";
        break;
      }
      default:
        return "";
    }

    const { heightOfGoogleAddresses, ...preparedData } = data || {};

    const key = Object.keys(preparedData)[0];
    dispatch(
      updateCustomerAction({
        data: {
          [fieldName]: data[key],
          ...(type !== "customer contact" && {
            proceededWithDuplicatedFields:
              preparedData.proceededWithDuplicatedFields,
          }),
        },
        id: customerId,
        onSuccess: (customer) => updateCustomerState({ customer }),
        returnCustomer: true,
      })
    );
    callback && callback();
    setEditCustomerOpen(false);
  };

  const handleEditContact = (contact) => {
    setEditContact(contact);
    setContactsOpen(true);
  };

  const handleUpdateEvent = ({ contacts }) => {
    const hasDefaultContact = contacts.some((c) => c?.defaultContact);

    const newContacts = contacts.map(
      ({ fid, phone, email, defaultContact, ...contact }, index) => ({
        phone: phone || "",
        email: email || "",
        defaultContact: hasDefaultContact ? defaultContact : index === 0,
        ...contact,
      })
    );

    dispatch(
      updateCustomerAction({
        data: { contacts: newContacts },
        id: customerId,
        onSuccess: (customer) => updateCustomerState({ customer }),
        skipSuccessMsg: true,
        returnCustomer: true,
      })
    );
  };
  const { handleDeleteContact } = useContact({
    contacts,
    customerId,
    handleUpdateEvent,
  });

  const weekDays = useMemo(
    () =>
      receivingHours ? setIncomingReceivingHours(receivingHours) : WEEK_DAYS,
    [receivingHours]
  );

  const handleUpdateHours = useCallback(
    (_, data, msg) => {
      dispatch(
        updateCustomerAction({
          data: { receivingHours: data ? setReceivingHours(data) : data },
          id: customerId,
          onSuccess: (customer) => {
            updateCustomerState({ customer });
            handleCloseReceivingHours();
            success(
              msg || `Working hours ${isAddingHoursMode ? "added" : "updated"}.`
            );
            if (isAddingHoursMode) setIsAddingHoursMode(false);
          },
          skipSuccessMsg: true,
          returnCustomer: true,
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customerId, dispatch, updateCustomerState, isAddingHoursMode]
  );

  const {
    openReceivingHours,
    handleSetHours,
    removeAllHours,
    handleOpenReceivingHours,
    handleCloseReceivingHours,
  } = useReceivingHours({
    setValue: handleUpdateHours,
    weekDays,
    onDelete: handleUpdateHours,
  });

  const handleAddHours = useCallback(() => {
    handleOpenReceivingHours();
    setIsAddingHoursMode(true);
  }, [handleOpenReceivingHours]);

  const showWorkingDays = useMemo(() => hasWorkingDays(weekDays), [weekDays]);

  const {
    openLicenses,
    handleOpenLicenses,
    handleCloseLicenses,
    editLicense,
    setEditLicense,
    onSubmitLicense,
    handleDeleteLicense,
  } = useLicenses({ handleSaveLicenses });

  const { infoCards, handleSaveSettings, setTempLayoutSettings } =
    useRightColumnActions({
      assignedRepresentatives,
      setAssignedRepsOpen,
      contacts,
      setContactsOpen,
      documents,
      setEditLicense,
      handleOpenLicenses,
      handleDeleteLicense,
      tags,
      setTagsOpen,
      tagsList,
      handleSaveTags,
      weekDays,
      removeAllHours,
      handleOpenReceivingHours,
      showWorkingDays,
      handleAddHours,
      cardsState,
      handleOpenCardsPopup,
      handleConfirmDeleteCard,
      handleEditCard,
    });

  const handleOpenEditCustomer = (type) => {
    setEditCustomerOpen(true);
    setEditCustomerType(type);
    setEditCustomerData(
      type === "shipping address" ? shippingAddress : billingAddress
    );
  };

  useEffect(() => {
    handleFetchCards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    open: openEmailDialog,
    handleOpen: handleOpenEmailDialog,
    handleClose: handleCloseEmailDialog,
    handleSendEmail,
    loading,
  } = useEmailActions();

  const {
    repProfile,
    handleOpenRepDialog,
    handleCloseRepDialog,
    loading: loadingRepProfile,
  } = useRepsActions({
    customer,
    assignedRepresentatives,
    updateCustomerState,
    hasCustomerPendingOrder: !!customer?.activeOrderCount,
  });

  return (
    <Box className={classes.profileWrapper}>
      <Loader isLoading={loadingRepProfile} />

      <LicensesDrawer
        isOpen={openLicenses}
        editLicense={editLicense}
        handleClose={handleCloseLicenses}
        handleSetData={(license) =>
          onSubmitLicense({ licenses: documents, license })
        }
        handleDeleteLicense={(license) => {
          handleDeleteLicense({ licenses: documents, license });
        }}
      />

      <CustomerEmailDialog
        isOpen={openEmailDialog}
        handleClose={handleCloseEmailDialog}
        handleSendEmail={handleSendEmail}
        customer={customer}
        loading={loading}
        order={emailOpen ? order : null}
      />

      <RepsAssignedDrawer
        isOpen={assignedRepsOpen}
        handleSave={(data) => handleSaveAssignReps({ data })}
        isEditMode
        handleClose={() => setAssignedRepsOpen(false)}
        assignedRepresentatives={assignedRepresentatives?.map(
          (r) => r?.representative
        )}
        hasCustomerPendingOrder={!!customer?.activeOrderCount}
        submitBtnLabel="Update"
      />

      <CustomerCardPopup
        isOpen={cardsState.cardsPopupOpen}
        handleClose={handleCloseCardsPopup}
        customerId={customerId}
        billingAddress={billingAddress}
        handleSave={() => handleFetchCards()}
        customerCreditCards={cardsState.list}
        successMsg="Card added"
      />

      <EditPaymentPopup
        isOpen={editPaymentOpen}
        handleClose={() => setEditPaymentOpen(false)}
        customerId={customerId}
        card={editCard}
        handleSave={() => handleFetchCards()}
        successMsg="Card updated"
      />

      <EditCustomerPopup
        isOpen={editCustomerOpen}
        data={editCustomerData}
        type={editCustomerType}
        handleClose={() => setEditCustomerOpen(false)}
        handleSave={handleChangeCustomer}
        contacts={contacts}
        customer={customer}
        withoutAbsoluteLabel
      />

      <ContactDeletePopup
        contact={deleteContact}
        isOpen={deleteContactOpen}
        handleClose={() => setDeleteContactOpen(false)}
        handleDeleteContact={handleDeleteContact}
      />

      <ContactAddDrawer
        editContact={editContact}
        isOpen={contactsOpen}
        handleClose={() => setContactsOpen(false)}
        handleAddContact={(newContact) =>
          handleSaveContacts(contacts, newContact)
        }
        contacts={contacts}
        handleDeleteContact={(contact) => {
          setDeleteContact(contact);
          setDeleteContactOpen(true);
        }}
      />

      <ReceivingHoursDrawer
        isOpen={openReceivingHours}
        handleClose={handleCloseReceivingHours}
        handleSetData={handleSetHours}
        weekDays={weekDays}
      />

      <TagsDrawer
        type={TAG_TYPES.customer}
        open={tagsOpen}
        handleClose={() => setTagsOpen(false)}
        customers={[customer]}
        handleSave={({ tags }) => {
          const preparedTags = tags.map((tag) => {
            if (tag?.newTag) return tag?.tag;
            return tag;
          });
          handleSaveTags(preparedTags);
          setTagsOpen(false);
        }}
        setCheckedCustomers={() => {}}
      />

      <PhotoGroupPopup
        user={{
          avatar: photoGroupPopupState.currentUser?.profilePhoto,
          name: photoGroupPopupState.currentUser?.name,
          id: photoGroupPopupState.currentUser?.id,
        }}
        isOpenProductDialog={!!photoGroupPopupState.open}
        handleCloseProductDialog={
          photoGroupPopupState.handleClosePhotoGroupPopup
        }
        currentPage={photoGroupPopupState.currentPage}
        galleryGroupItem={photoGroupPopupState.galleryGroupItem}
      />

      <ProfileComponent
        profile={repProfile}
        isOpenProfileDialog={!!repProfile}
        handleCloseProfileDialog={handleCloseRepDialog}
      />

      <MapPopup
        skipCustomerRedirect
        withHeader
        isOpen={!!mapData}
        handleClose={() => setMapData(null)}
        address={mapData?.customer?.shippingAddress}
        customer={mapData}
        closeBtnStyle={{
          top: 10,
          right: 10,
        }}
      />

      <LeftColumnBlock
        {...{
          customerDescRef,
          handleOpenEmailDialog,
          classes,
          shippingAddress,
          billingAddress,
          handleOpenEditCustomer,
        }}
        name={displayName || name}
      />

      <Box
        flex="1 1 59.5%"
        maxWidth="59.5%"
        height={
          customerDescRef.current
            ? `${customerDescRef.current.clientHeight}px`
            : "auto"
        }
        overflowy="hidden"
      >
        <CustomerTables
          clientHeight={customerDescRef?.current?.clientHeight}
          {...{ repPermissions }}
        />
      </Box>

      <RightColumnBlock
        {...{
          infoCards,
          setDeleteContact,
          setDeleteContactOpen,
          handleSaveAssignReps,
          assignedRepresentatives,
          handleEditContact,
          handleOpenRepDialog,
          handleSaveSettings,
          classes,
          setTempLayoutSettings,
        }}
      />
    </Box>
  );
};

export default CustomerProfile;
