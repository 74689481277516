import { Grid, Typography } from "@mui/material";
import { TAB_HEADER_ITEMS } from "./PaymentDetailsHeader.constants";
import { cl } from "./PaymentDetailsHeader.styles";

export const PaymentDetailsHeader = () => {
  return (
    <Grid sx={cl.headerWrapper} container>
      {TAB_HEADER_ITEMS.map(({ name, size, styles }) => (
        <Grid
          key={name}
          sx={{ ...styles, ...cl.typography }}
          xs={size}
          item
          component={Typography}
        >
          {name}
        </Grid>
      ))}
    </Grid>
  );
};
