import * as React from "react";

export const BillingCalendarIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14.8}
    height={16.278}
    viewBox="0 0 14.8 16.278"
    {...props}
  >
    <g
      data-name="Icon feather-calendar"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        data-name="Path 4243"
        d="M2.228 2.268h10.344a1.476 1.476 0 0 1 1.478 1.473v10.314a1.476 1.476 0 0 1-1.478 1.473H2.228A1.476 1.476 0 0 1 .75 14.055V3.741a1.476 1.476 0 0 1 1.478-1.473Z"
      />
      <path data-name="Path 4244" d="M10.356.75v3.035" />
      <path data-name="Path 4245" d="M4.444.75v3.035" />
      <path data-name="Path 4246" d="M.75 6.661h13.3" />
    </g>
  </svg>
);
