import { bool } from "prop-types";
import { Box, Typography } from "@mui/material";

const OrdersDetailsTableHeader = ({ hasThirdParty, hasDiscount }) => {
  const cl = {
    box: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
    typography: {
      fontSize: "14px",
      color: "#6A6A6A",
    },
  };

  return (
    <Box
      sx={{
        border: "1px solid #D5D9D9",
        backgroundColor: "#EEEEEE",
        borderRadius: "4px 4px 0 0",
        mt: "11px",
        display: "flex",
        height: "39px",
        minHeight: "39px",
        width: "100%",
      }}
    >
      <Box sx={cl.box} width="2.9%" minWidth="2.9%" maxWidth="2.9%" />

      <Box sx={{ ...cl.box, justifyContent: "flex-start", flexGrow: 1 }}>
        <Typography sx={cl.typography}>PRODUCT</Typography>
      </Box>

      <Box
        // width={!hasThirdParty ? "20.96%" : "15.01%"}
        width={!hasThirdParty ? "16.96%" : "11.01%"}
        sx={{ ...cl.box, justifyContent: "flex-start" }}
      >
        <Typography sx={cl.typography}>BAR CODE</Typography>
      </Box>

      {!hasThirdParty && (
        <Box
          sx={{
            ...cl.box,
            justifyContent: "flex-end",
          }}
          width="9.05%"
        >
          <Typography sx={cl.typography}>AVAILABLE</Typography>
        </Box>
      )}

      <Box
        sx={{
          ...cl.box,
          justifyContent: "flex-end",
          width: !hasThirdParty ? "9.1%" : "14.1%",
        }}
      >
        <Typography sx={cl.typography}>QUANTITY</Typography>
      </Box>

      <Box
        xs={!hasThirdParty ? 2.74 : 3.82}
        sx={{
          ...cl.box,
          justifyContent: "flex-end",
          // width: !hasThirdParty ? "12.45%" : "17.45%",
          width: !hasThirdParty ? "14.45%" : "19.45%",
        }}
      >
        <Typography sx={cl.typography}>COST</Typography>
      </Box>

      {hasDiscount && (
        <Box
          sx={{
            ...cl.box,
            justifyContent: "flex-end",
            width: "7.4%",
          }}
        >
          <Typography sx={cl.typography}>DISCOUNT</Typography>
        </Box>
      )}

      <Box
        sx={{
          ...cl.box,
          justifyContent: "flex-end",
          // width: !hasThirdParty ? "7.4%" : "12.4%",
          width: !hasThirdParty ? "9.4%" : "14.4%",
        }}
      >
        <Typography sx={cl.typography}>TOTAL</Typography>
      </Box>

      <Box sx={cl.box} width="0.9%" />
    </Box>
  );
};
OrdersDetailsTableHeader.propTypes = {
  hasThirdParty: bool,
  hasDiscount: bool,
};
OrdersDetailsTableHeader.defaultProps = {
  hasThirdParty: false,
  hasDiscount: false,
};

export default OrdersDetailsTableHeader;
