// eslint-disable-next-line react/prop-types
export const ImportIcon = ({ fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15.669"
      viewBox="0 0 15 15.669"
      fill={fill || "#000"}
    >
      <path
        id="Icon_awesome-upload"
        data-name="Icon awesome-upload"
        d="M8.672,3.923H6.328a.718.718,0,0,0-.7.735V9.8H3.056a.615.615,0,0,0-.413,1.044L7.1,15.5a.551.551,0,0,0,.8,0l4.459-4.659A.615.615,0,0,0,11.944,9.8H9.375V4.658A.718.718,0,0,0,8.672,3.923ZM15,4.168V.74a.718.718,0,0,0-.7-.735H.7A.718.718,0,0,0,0,.74V4.168A.718.718,0,0,0,.7,4.9H4.688V4.658A1.68,1.68,0,0,1,6.328,2.944H8.672a1.68,1.68,0,0,1,1.641,1.714V4.9H14.3A.718.718,0,0,0,15,4.168ZM11.367,1.474a.586.586,0,1,1-1.172,0,.586.586,0,1,1,1.172,0Zm1.875,0a.586.586,0,1,1-1.172,0,.586.586,0,1,1,1.172,0Z"
        transform="translate(0 -0.005)"
      />
    </svg>
  );
};
