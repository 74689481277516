import React from "react";
import { Box } from "@mui/material";
import { PlaceholderIcon } from "../Icons";

// eslint-disable-next-line react/prop-types
export const ImagePlaceholder = ({ wrapperProps, ...props }) => {
  return (
    <Box {...wrapperProps}>
      <PlaceholderIcon {...props} />
    </Box>
  );
};

export default ImagePlaceholder;
