import { makeStyles } from "@mui/styles";

export const cl = {
  search: {
    "& .MuiAutocomplete-paper": {
      boxShadow: "none",
    },
  },
  searchInput: {
    "& .MuiAutocomplete-endAdornment": { mr: "-2px" },
    "& .MuiOutlinedInput-root": {
      height: "32px",
      py: "0px !important",
      fontSize: "14px",
      fontWeight: 300,
      backgroundColor: "#FFF",
      "& > fieldset": {
        borderColor: "rgb(213,217,217)!important",
        borderWidth: "1px!important",
      },
    },
    "& .MuiInputLabel-root": {
      color: " rgb(181, 181, 172) !important",
      fontSize: "14px",
      transform: "translate(14px, 6px) scale(1)",
      "&.Mui-focused": {
        transform: "translate(14px, -7px) scale(0.75)",
      },
      "&.MuiInputLabel-shrink": {
        transform: "translate(14px, -7px) scale(0.75)",
      },
    },
  },
  searchListBoxProps: {
    p: 0,
    maxHeight: "146px",
    boxShadow: "none",
    overflow: "overlay",
    borderRadius: "4px",
  },
};

const useStyles = makeStyles(() => ({
  recipientsTable: {
    height: "100%",
    zIndex: "10",
    border: "0.5px solid #D5D9D9",
    width: "100%",
    backgroundColor: "#ffffff",
    overflow: "overlay",
    borderRadius: "4px",
    marginTop: "1px",
  },
  textfield: {
    color: "#ff0000",
    "& input::placeholder, & textarea::placeholder": {
      // color: "#BEBEBE",
      color: "#B5B5AC",
      opacity: 1,
      fontWeight: 400,
    },
  },
}));

export default useStyles;
