import { useEffect, useMemo, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { setCurrentUser } from "redux/actions/auth";

import { GeneralTab } from "./components/GeneralTab";
import { RolesTab } from "./components/RolesTab";

import { useAdmin } from "helpers/helpers";
import { useDebounce, useRepsPermissions } from "helpers/hooks";

import { error } from "utils/notifications";
import { TABS } from "./CustomersTab.constants";

import { updateDistributorSettingsService } from "services/account";

import useStyles, { cl } from "./styles";

import { Box, Grid, Stack, Tab, Tabs, Typography } from "@mui/material";
import { StyledButton, ValidationPopper } from "components";
import { useContactRoles } from "./useContactRoles";
import {
  setEditTypeAction,
  setFormChangedAction,
} from "redux/actions/confirmDialogs";

export const CustomersTab = () => {
  const isAdmin = useAdmin();
  const saveAnchor = useRef();
  const dispatch = useDispatch();

  const repPermissions = useRepsPermissions();

  const [currentTab, setCurrentTab] = useState(TABS[0].name);

  const currentUser = useSelector(({ auth }) => auth.currentUser);
  const { shippingAddress, name } = currentUser || {};

  const {
    onSubmit,
    onError,
    validationPopperOpen,
    setValidationPopperOpen,
    control,
    setValue,
    handleSubmit,
    reset,
    trigger,
    errors,
    isDirty,
    formField,
    DEFAULT_VALUES,
    isLoading,
    handleAddRole,
    fields,
    handleChangeDefault,
    handleDeleteRole,
    onEditRole,
    isTyping,
    checkForUniqNames,
    handleInputChange,
    setIsTyping,
    hasDublicateError,
  } = useContactRoles({ currentUser });

  const classes = useStyles();

  const { checkInCustomerRadiusEnabled, checkInCustomerRadius } =
    formField || {};

  const debouncedRadius = useDebounce(checkInCustomerRadius, 1000);

  const loading = useMemo(
    () => checkInCustomerRadius !== debouncedRadius,
    [checkInCustomerRadius, debouncedRadius]
  );

  const activeTabElement = useMemo(() => {
    switch (currentTab) {
      case TABS[0].name:
        return (
          <GeneralTab
            name={name}
            control={control}
            loading={loading}
            setValue={setValue}
            shippingAddress={shippingAddress}
            debouncedRadius={debouncedRadius}
            checkInCustomerRadiusEnabled={checkInCustomerRadiusEnabled}
          />
        );
      case TABS[1].name:
        return (
          <RolesTab
            fields={fields}
            errors={errors}
            control={control}
            isLoading={isLoading}
            formField={formField}
            onEditRole={onEditRole}
            setIsTyping={setIsTyping}
            handleAddRole={handleAddRole}
            handleDeleteRole={handleDeleteRole}
            handleInputChange={handleInputChange}
            checkForUniqNames={checkForUniqNames}
            handleChangeDefault={handleChangeDefault}
          />
        );

      default:
        return (
          <GeneralTab
            name={name}
            control={control}
            loading={loading}
            setValue={setValue}
            shippingAddress={shippingAddress}
            debouncedRadius={debouncedRadius}
            checkInCustomerRadiusEnabled={checkInCustomerRadiusEnabled}
          />
        );
    }
  }, [
    checkInCustomerRadiusEnabled,
    control,
    currentTab,
    debouncedRadius,
    fields,
    formField,
    handleAddRole,
    handleChangeDefault,
    handleDeleteRole,
    loading,
    name,
    setIsTyping,
    setValue,
    shippingAddress,
    checkForUniqNames,
    handleInputChange,
    onEditRole,
    isLoading,
    errors,
  ]);

  useEffect(() => {
    if (isAdmin) return;
    if (repPermissions && !repPermissions?.settings?.customers) return;

    const errorKeys = Object.keys(errors);
    if (errorKeys.length) return;

    const preparedData = {
      checkInCustomerRadiusEnabled,
    };

    if (checkInCustomerRadiusEnabled) {
      preparedData.checkInCustomerRadius = checkInCustomerRadius || 0;
    }

    updateDistributorSettingsService(preparedData)
      .then((res) => {
        dispatch(setCurrentUser(res));
      })
      .catch((err) => {
        error(err?.response?.data?.message || "Something went wrong.");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedRadius, dispatch, handleSubmit, checkInCustomerRadiusEnabled]);

  const showBtns = useMemo(
    () => currentTab === TABS[1].name && isDirty && !isTyping && !isLoading,
    [currentTab, isDirty, isTyping, isLoading]
  );

  useEffect(() => {
    if (!isTyping && currentTab !== TABS[0].name) {
      dispatch(setFormChangedAction(isDirty));
    }
  }, [isDirty, dispatch, isTyping, currentTab]);

  useEffect(() => {
    dispatch(setEditTypeAction("customer", false));

    return () => dispatch(setEditTypeAction("", false));
  }, [dispatch]);

  return (
    <>
      <ValidationPopper
        isOpen={validationPopperOpen && showBtns}
        anchorEl={saveAnchor.current}
        errors={errors}
        setIsOpen={setValidationPopperOpen}
        tailProps={{
          top: -6,
          right: 28,
          rotate: -180,
        }}
        offset={[-190, -10]}
      />
      <form onSubmit={handleSubmit(onSubmit)} id="new-customer-form">
        <Stack direction="row" className={classes.ordersHeader}>
          <Typography fontSize={30} color="#5F6267">
            Customers
          </Typography>
          <Grid alignItems="flex-end" xs={12} item container>
            <Tabs
              value={currentTab}
              onChange={(e, newValue) => {
                if (isDirty) {
                  dispatch(setFormChangedAction(false));
                }

                setCurrentTab(newValue);
              }}
              sx={cl.tabs}
            >
              {TABS.map(({ name }) => (
                <Tab
                  key={name}
                  className="profile-tabs"
                  sx={cl.tab}
                  label={<Box sx={cl.tabLabel}>{name}</Box>}
                  value={name}
                />
              ))}
            </Tabs>
          </Grid>
          <Stack direction="row" gap="10px" mt="4px">
            {showBtns && (
              <>
                <StyledButton
                  label="Cancel"
                  variant="outlined"
                  color="edit"
                  disabled={isLoading}
                  onClick={() => reset(DEFAULT_VALUES)}
                  sx={{ height: "28px", width: "80px" }}
                  fontSize="14px"
                />
                <Box
                  onMouseEnter={() => {
                    const dublicateError = hasDublicateError();
                    if (dublicateError) return;
                    onError();
                    if (trigger) trigger();
                  }}
                  component="span"
                  form="new-profile-form"
                  ref={saveAnchor}
                >
                  <StyledButton
                    label="Save"
                    disabled={isLoading || !!Object.keys(errors).length}
                    variant="contained"
                    color="primary"
                    sx={{ height: "28px", width: "80px" }}
                    fontSize="14px"
                    type="submit"
                  />
                </Box>
              </>
            )}
          </Stack>
        </Stack>

        <Box padding="25px 76px" maxHeight="100%">
          {activeTabElement}
        </Box>
      </form>
    </>
  );
};

export default CustomersTab;
