import * as React from "react";
export const MenuListIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={10.293}
    height={8.363}
    viewBox="0 0 10.293 8.363"
    {...props}
  >
    <path
      data-name="Icon awesome-list-ul"
      d="M.965 0a.965.965 0 1 0 .965.965A.965.965 0 0 0 .965 0Zm0 3.217a.965.965 0 1 0 .965.965.965.965 0 0 0-.965-.965Zm0 3.217a.965.965 0 1 0 .965.965.965.965 0 0 0-.965-.965Zm9.006.322H3.538a.322.322 0 0 0-.322.322v.643a.322.322 0 0 0 .322.322h6.433a.322.322 0 0 0 .322-.322v-.643a.322.322 0 0 0-.322-.323Zm0-6.433H3.538a.322.322 0 0 0-.322.322v.643a.322.322 0 0 0 .322.322h6.433a.322.322 0 0 0 .322-.322V.643a.322.322 0 0 0-.322-.318Zm0 3.217H3.538a.322.322 0 0 0-.322.322v.643a.322.322 0 0 0 .322.322h6.433a.322.322 0 0 0 .322-.322V3.86a.322.322 0 0 0-.322-.322Z"
      fill="#3f3f3f"
    />
  </svg>
);
