import { bool, string, func } from "prop-types";
import { Box, Button } from "@mui/material";

export const CardFooter = ({
  nameBtn,
  disabled,
  isExtra,
  onChangeCurrentPlan,
  name,
  onSetPayDescription,
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "114px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        px: 3,
      }}
    >
      <Button
        sx={{
          height: "67px",
          fontSize: 16,
          borderRadius: "8px",
          color: isExtra && "#009C60",
          borderColor: isExtra && "#D5D9D9",
          bgcolor: !isExtra && "#409A65",
        }}
        disabled={disabled}
        fullWidth
        variant={isExtra ? "outlined" : "contained"}
        color={isExtra ? "cancel" : "primary"}
        onMouseEnter={() => onSetPayDescription(nameBtn)}
        onMouseLeave={() => onSetPayDescription(null)}
        onClick={() => onChangeCurrentPlan(isExtra ? "extra" : name)}
      >
        {nameBtn}
      </Button>
    </Box>
  );
};

CardFooter.propTypes = {
  nameBtn: string,
  disabled: bool,
  isExtra: bool,
  onChangeCurrentPlan: func,
  name: string,
  onSetPayDescription: func,
};
CardFooter.defaultProps = {
  nameBtn: "Select",
  disabled: false,
};
