import { forwardRef } from "react";
import { number, bool, string } from "prop-types";
import { Typography } from "@mui/material";

export const PhotoCount = forwardRef(({ count, show, name, ...props }, ref) => {
  return show ? (
    <Typography
      ref={ref}
      sx={{
        maxWidth: "50%",
        minWidth: "fit-content",
        "&:hover": {
          textDecoration: "underline",
          cursor: "pointer",
        },
        fontSize: 12,
        color: "#5F6267",
      }}
      {...props}
    >
      {count} {name}
    </Typography>
  ) : null;
});
PhotoCount.propTypes = { count: number, show: bool, name: string };
PhotoCount.defaultProps = { count: 0, show: false, name: "" };
PhotoCount.displayName = "PhotoCount";
