import { useMemo } from "react";

export const useRestockActions = ({
  isEdit,
  isTotalDelivery,
  productReturns,
}) => {

  const productReturnsWithQuantity = productReturns
    ?.filter((prod) => prod.returnQuantity > 0)
    ?.map((el) => {
      return {
        orderProductId: el.orderProductId || el.id,
        quantity: el.returnQuantity,
      };
    });

  const isRestockDisable = useMemo(() => {
    if (!productReturnsWithQuantity?.length) return true;

    if (isEdit) {
      return true;
    } else {
      return isTotalDelivery;
    }
  }, [isEdit, isTotalDelivery, productReturnsWithQuantity?.length]);

  return {
    isTotalDelivery,
    isRestockDisable,
  };
};
