import React from "react";

export const PenIcon = () => {
  return (
    <svg
      id="pen-solid"
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="9.999"
      viewBox="0 0 10 9.999"
    >
      <path
        id="pen-solid-2"
        data-name="pen-solid"
        d="M5.668,1.828l2.5,2.5L2.739,9.758.509,10a.469.469,0,0,1-.517-.518L.24,7.255,5.668,1.828Zm4.047-.372L8.54.282a.938.938,0,0,0-1.326,0l-1.1,1.1,2.5,2.5,1.1-1.1a.938.938,0,0,0,0-1.326Z"
        transform="translate(0.011 -0.007)"
        fill="#3f3f3f"
      />
    </svg>
  );
};
