import { string, number } from "prop-types";
import moment from "moment";
import { Box, Typography } from "@mui/material";
import {
  getFormattedDate,
  separateNumWithComma,
} from "../../../../helpers/helpers";

export const TextPlanItem = ({ date, amount, timeZone }) => {
  return (
    <Box mt={2} px={1.5}>
      <Typography fontSize={16} fontWeight={400} color="#292929">
        When your plan renews on{" "}
        {moment(getFormattedDate(date, timeZone)).format("MMM D, YYYY")}, your
        new monthly total will be{" "}
        <Box color="#47A06D" fontWeight={400} component="span">
          ${separateNumWithComma(amount)}.
        </Box>{" "}
        You will receive a prorated charge for your current billing cycle on
        your next invoice.
      </Typography>
    </Box>
  );
};

TextPlanItem.propTypes = {
  date: string,
  amount: number,
  timeZone: string,
};
