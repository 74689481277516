import { Grid, IconButton, MenuItem, Typography } from "@mui/material";
import { string, any, bool, object, func } from "prop-types";
import { Controller } from "react-hook-form";
import { CartTrashIcon } from "../../../../../../../../../components/Icons";
import { StyledSelect } from "../../../../../../../../../components/Selects";
import {
  GoogleAutocompleteField,
  StyledTextField,
} from "../../../../../../../../../components/TextFields/TextFields";
import { sliceCountry } from "../../../../../../../../../helpers/helpers";
import { getAddressComponents } from "../../../../../../../../CustomersPage/components/AddressField/AddressField.helpers";
import {
  STATE_NAMES,
  CANADAS_STATE_NAMES,
} from "../../../../../../../../CustomersPage/pages/NewCustomerPage/NewCustomerPage.constants";
import useStyles from "../../../../newStyles";

const LocationsItem = ({
  title,
  address,
  isEdit,
  control,
  fieldName,
  setValue,
  isCompulsory,
  userCountry,
  handleDeleteLocation,
  clearErrors,
}) => {
  const selectCityState =
    userCountry === "US" ? STATE_NAMES : CANADAS_STATE_NAMES;

  const classes = useStyles();
  return (
    <Grid
      sx={{
        ml: !isEdit && "20px",
        "&:not(:last-of-type)": {
          borderBottom: !isEdit && "1px solid #d5d9d9",
        },
        "& .MuiTypography-root": {
          fontSize: "15px",
        },
      }}
      display="flex"
      width="unset"
      justifyContent="space-between"
      mr="10px"
      height="62px"
      alignItems="center"
      container
    >
      <Grid item xs={1.5} display="flex" alignItems="center">
        {isEdit && !isCompulsory ? (
          <Controller
            render={({ field, fieldState: { error } }) => {
              return (
                <StyledTextField
                  fullWidth
                  size="small"
                  InputProps={{
                    className: classes.editInput,
                  }}
                  InputLabelProps={{
                    className: classes.inputLabel,
                  }}
                  placeholder="Name"
                  error={error ? error.message : ""}
                  autoComplete="new-password"
                  {...field}
                />
              );
            }}
            name={`${fieldName}.name`}
            control={control}
          />
        ) : (
          <Typography color="#979797" fontWeight={600} noWrap>
            {title}
          </Typography>
        )}
      </Grid>
      {isEdit ? (
        <Grid
          item
          xs={10.2}
          container
          gap="21px"
          alignItems="center"
          width="unset"
        >
          <Grid item xs={4}>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <GoogleAutocompleteField
                  // disabled={isEdit}
                  onPlaceSelected={(place) => {
                    if (place) clearErrors(fieldName);
                    const { street, state, city, zip } =
                      getAddressComponents(place);
                    setValue(fieldName, {
                      formatted_address: sliceCountry(place.formatted_address),
                      lat: place.geometry.location.lat(),
                      lng: place.geometry.location.lng(),
                      street,
                      state,
                      city,
                      zip,
                    });
                  }}
                  noErrorMessage
                  InputProps={{
                    className: classes.editInput,
                    autoComplete: "new-password",
                  }}
                  size="large"
                  error={error ? error.message : ""}
                  {...field}
                  onChange={(e) =>
                    setValue(fieldName, {
                      ...address,
                      formatted_address: e.target.value,
                    })
                  }
                />
              )}
              name={`${fieldName}.formatted_address`}
              control={control}
            />
          </Grid>
          <Grid item xs={1}>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <StyledTextField
                  fullWidth
                  size="small"
                  InputProps={{
                    className: classes.editInput,
                    autoComplete: "new-password",
                  }}
                  InputLabelProps={{
                    className: classes.inputLabel,
                  }}
                  placeholder="Unit"
                  error={error ? error.message : ""}
                  {...field}
                  value={field?.value ?? ""}
                />
              )}
              name={`${fieldName}.appartement`}
              control={control}
            />
          </Grid>
          <Grid item xs={2}>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <StyledTextField
                  disabled={title === "Office"}
                  fullWidth
                  size="small"
                  InputProps={{
                    className: classes.editInput,
                    autoComplete: "new-password",
                  }}
                  InputLabelProps={{
                    className: classes.inputLabel,
                  }}
                  placeholder="City"
                  error={error ? error.message : ""}
                  {...field}
                />
              )}
              name={`${fieldName}.city`}
              control={control}
            />
          </Grid>
          <Grid item xs={1.5}>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <StyledSelect
                  disabled={title === "Office"}
                  noErrorMessage
                  notched={false}
                  fullWidth
                  error={error?.message || ""}
                  height="43px"
                  fontSize="15px"
                  borderRadius="9px"
                  {...field}
                  value={field.value || ""}
                >
                  {Object.keys(selectCityState).map((key) => (
                    <MenuItem key={key} value={key}>
                      {key}
                    </MenuItem>
                  ))}
                </StyledSelect>
              )}
              name={`${fieldName}.state`}
              control={control}
            />
          </Grid>
          <Grid item xs={1.5}>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <StyledTextField
                  disabled={title === "Office"}
                  fullWidth
                  size="small"
                  InputProps={{
                    className: classes.editInput,
                    autoComplete: "new-password",
                  }}
                  InputLabelProps={{
                    className: classes.inputLabel,
                  }}
                  placeholder="Zip Code"
                  error={error ? error.message : ""}
                  {...field}
                />
              )}
              name={`${fieldName}.zip`}
              control={control}
            />
          </Grid>
          <Grid item xs={0.5}>
            <IconButton onClick={handleDeleteLocation} disabled={isCompulsory}>
              <CartTrashIcon stroke={isCompulsory ? "#D5D9D9" : null} />
            </IconButton>
          </Grid>
        </Grid>
      ) : (
        <Typography color="#000000">
          {address.formatted_address ? address.formatted_address : "-"}{" "}
        </Typography>
      )}
    </Grid>
  );
};

LocationsItem.propTypes = {
  title: string,
  value: any,
  isDefault: bool,
  isEdit: bool,
  EditField: any,
  address: object,
  control: object,
  fieldName: string,
  setValue: func,
  isCompulsory: bool,
  handleDeleteLocation: func,
  clearErrors: func,
  userCountry: string,
};

LocationsItem.defaultProps = {
  title: "",
  userCountry: "US",
  isDefault: false,
  isEdit: false,
  fieldName: "",
  isCompulsory: false,
  handleDeleteLocation: () => {},
};

export default LocationsItem;
