/* eslint-disable no-useless-escape */
import * as Yup from "yup";

const PHONE_REGEXP =
  /^[\+][\d]{1}[\s]?[\(]?[\d]{3}[\)]?[\s]?[\d]{3}[\s]?[\d]{4}$/im;

export const validationSchema = () =>
  Yup.object().shape({
    firstName: Yup.string().trim().required("This field is required"),
    lastName: Yup.string().trim().required("This field is required"),
    title: Yup.string().trim().required("This field is required"),
    ssn: Yup.string()
      .trim()
      .required("This field is required")
      .test("len", "Must be 4 characters", (val) => val.length === 4),
    dateOfBirth: Yup.object().shape({
      month: Yup.string().trim().required("This field is required"),
      day: Yup.string().trim().required("This field is required"),
      year: Yup.string().trim().required("This field is required"),
    }),
    email: Yup.string()
      .trim()
      .required("This field is required.")
      .test("emailValidation", "Email not valid!", (value) => {
        if (!value) return true;
        const EMAIL_REGEXP =
          /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d)|(([a-z]|\d)([a-z]|\d|-|\.|_|~)*([a-z]|\d)))\.)+(([a-z])|(([a-z])([a-z]|\d|-|\.|_|~)*([a-z])))$/i; // eslint-disable-line
        return EMAIL_REGEXP.test(value);
      }),
    phone: Yup.string()
      .trim()
      .required("This field is required.")
      .test("phoneValidation", "Not valid phone number!", (value) => {
        if (!value) return true;
        return PHONE_REGEXP.test(value);
      }),
    address: Yup.object().shape({
      formatted_address: Yup.string().required("This field is required"),
      street: Yup.string().required("This field is required"),
      city: Yup.string().required("This field is required"),
      state: Yup.string().required("This field is required").nullable(),
      zip: Yup.string().required("This field is required"),
    }),
  });
