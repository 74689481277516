export const PendingIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="3.303"
      height="4.847"
      viewBox="0 0 3.303 4.847"
      {...props}
    >
      <path
        id="path"
        d="M0,3.663H3.847"
        transform="translate(-3.162 4.347) rotate(-90)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        id="path-2"
        data-name="path"
        d="M0,3.663H2.3"
        transform="translate(2.803 8.01) rotate(180)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
    </svg>
  );
};
