import React from "react";

export const TagsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42.5"
      height="34"
      viewBox="0 0 42.5 34"
    >
      <path
        id="Icon_awesome-tags"
        data-name="Icon awesome-tags"
        d="M33.066,15,19,.934A3.187,3.187,0,0,0,16.742,0H3.188A3.187,3.187,0,0,0,0,3.188V16.742A3.187,3.187,0,0,0,.934,19L15,33.066a3.187,3.187,0,0,0,4.508,0L33.066,19.512a3.188,3.188,0,0,0,0-4.508ZM7.438,10.625a3.188,3.188,0,1,1,3.188-3.188A3.187,3.187,0,0,1,7.438,10.625Zm34.129,8.887L28.012,33.066a3.187,3.187,0,0,1-4.508,0l-.024-.024L35.039,21.484a5.977,5.977,0,0,0,0-8.452L22.007,0h3.235A3.187,3.187,0,0,1,27.5.934L41.566,15a3.188,3.188,0,0,1,0,4.508Z"
        fill="#707070"
      />
    </svg>
  );
};
