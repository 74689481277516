import { string, bool, object } from "prop-types";
import { Controller } from "react-hook-form";
import { LargeCheckbox } from "components";
import { Typography } from "@mui/material";

export const CheckboxComponent = ({
  type,
  label,
  disabled,
  isAdmin,
  control,
  checkedColor,
}) => {
  return (
    <Controller
      render={({ field }) => (
        <LargeCheckbox
          disabled={disabled}
          checkedColor={checkedColor}
          size={18}
          sx={{ p: 0, mr: "9px" }}
          formSx={{ m: 0 }}
          onChange={(e) => {
            if (isAdmin) return;
            field.onChange(e.target.checked);
          }}
          checked={!!field.value}
          label={
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                color: "#707070",
              }}
            >
              {label}
            </Typography>
          }
        />
      )}
      control={control}
      name={type}
    />
  );
};

CheckboxComponent.propTypes = {
  type: string,
  label: string,
  disabled: bool,
  isAdmin: bool,
  control: object,
  checkedColor: string,
};
CheckboxComponent.defaultProps = {
  type: "",
  label: "",
  disabled: false,
  isAdmin: false,
  control: {},
  checkedColor: "#47a06d",
};
