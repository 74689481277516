import { Controller } from "react-hook-form";
import { object, func, bool } from "prop-types";

import { Filter } from "./Filter";
import { StyledTextField } from "../../../components/TextFields/TextFields";

import { Stack } from "@mui/material";

export const Search = ({
  control,
  handleSwitchClick,
  setValue,
  hasAnyInvites,
  hasAnyReceived,
}) => {
  return (
    <Stack direction="row" gap="9px" alignItems="center" width="100%" px="16px">
      <Controller
        render={({ field }) => (
          <StyledTextField
            label=""
            placeholder="Search connections"
            fullWidth
            noErrorMessage
            sx={{
              "& .MuiOutlinedInput-root": {
                height: "27px",
                fontWeight: 300,
              },
              "& .MuiOutlinedInput-input": {
                padding: "5px 8px",
              },
            }}
            {...field}
          />
        )}
        name="search"
        control={control}
      />
      <Filter
        control={control}
        handleSwitchClick={handleSwitchClick}
        setValue={setValue}
        hasAnyInvites={hasAnyInvites}
        hasAnyReceived={hasAnyReceived}
      />
    </Stack>
  );
};

Search.propTypes = {
  control: object,
  handleSwitchClick: func,
  setValue: func,
  hasAnyInvites: bool,
  hasAnyReceived: bool,
};
Search.defaultProps = {};
