import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  ordersHeader: {
    padding: "24px 0 19px 34px",
  },

  ordersBody: {
    padding: "18px 76px",
    maxHeight: "100%",
  },

  buttonBlock: {
    padding: "15px 28px",
    backgroundColor: "#F7F7F7",
    boxShadow: "none",
    marginBottom: "9px",
  },
  textInputInventory: {
    fontSize: "12px",
    height: "24px",
    paddingRight: 0,
    "& .MuiInputBase-input": {
      padding: " 0 8px 0 8px",
    },
  },
  inputLabel: {
    fontSize: "12px",
    fontWeight: 400,
    color: "#000000",
    "&.Mui-focused": {
      color: "#000000",
    },
  },
}));

export default useStyles;
