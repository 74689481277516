import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  textInput: {
    fontSize: "12px",
    height: "32px",
  },

  inputLabel: {
    fontSize: "12px",
    color: "#B5B5AC",
  },
  buttonCancel: {
    "& > span": {
      fontSize: "10px",
      color: "#6A6A6A"
    },
  },
  buttonSave: {
    "& > span": {
      fontSize: "10px",
    },
  },
}));

export default useStyles;
