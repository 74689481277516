import { useEffect } from "react";
import { bool, object, func, string } from "prop-types";
import { useLocation } from "react-router-dom";
import { useFieldArray, useWatch } from "react-hook-form";
import { Box, IconButton } from "@mui/material";
import { PlusIcon } from "../../../../../../../components/Icons";
import TableHeader from "../TableHeader";
import LocationsItem from "./components/LocationsItem";

const LocationsTab = ({
  isEdit,
  control,
  setValue,
  trigger,
  clearErrors,
  userCountry,
}) => {
  const { state } = useLocation();
  const formField = useWatch({ control });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "locations",
  });

  useEffect(() => {
    if (state?.type === "onboarding" && state?.profileTab === 1) {
      trigger();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddLocations = () => {
    append({
      formatted_address: "",
      name: "",
      phone: "",
      defaultContact: false,
      lat: 0,
      lng: 0,
      appartement: "",
      street: "",
      city: "",
      state: "",
      zip: "",
    });
  };

  const handleDeleteLocation = (index) => {
    remove(index);
  };

  return (
    <Box>
      <TableHeader
        title="Locations"
        addElement={
          <IconButton onClick={handleAddLocations}>
            <PlusIcon stroke="#eeeeee" fill="#47A06D" width="20" height="20" />
          </IconButton>
        }
        isEdit={isEdit}
      />
      <Box
      // sx={{
      //   maxHeight: "432px",
      //   overflowY: "auto",
      // }}
      >
        <LocationsItem
          title="Office"
          isCompulsory
          isEdit={isEdit}
          address={formField.billingAddress}
          control={control}
          userCountry={userCountry}
          fieldName="billingAddress"
          setValue={setValue}
          clearErrors={clearErrors}
        />
        <LocationsItem
          title="Fulfillment"
          isCompulsory
          isEdit={isEdit}
          address={formField.shippingAddress}
          userCountry={userCountry}
          control={control}
          fieldName="shippingAddress"
          setValue={setValue}
          clearErrors={clearErrors}
        />

        {fields.map((location, i) => (
          <LocationsItem
            key={location.id}
            title={formField.locations[i]?.name}
            isEdit={isEdit}
            userCountry={userCountry}
            address={formField.locations[i]}
            control={control}
            fieldName={`locations.${i}`}
            setValue={setValue}
            clearErrors={clearErrors}
            handleDeleteLocation={() => handleDeleteLocation(i)}
          />
        ))}
        {/* <TableItem title="Office" /> */}
      </Box>
    </Box>
  );
};

LocationsTab.propTypes = {
  isEdit: bool,
  control: object,
  setValue: func,
  trigger: func,
  clearErrors: func,
  userCountry: string,
};

export default LocationsTab;
