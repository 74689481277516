import React from "react";

export const UsageInfoIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32.5"
      height="32.5"
      viewBox="0 0 32.5 32.5"
    >
      <g
        id="Group_5683"
        data-name="Group 5683"
        transform="translate(0.25 0.25)"
      >
        <path
          id="Path_3852"
          data-name="Path 3852"
          d="M12.973,13.628H16.46V23"
          fill="none"
          stroke="#6a6a6a"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_3853"
          data-name="Path 3853"
          d="M12.481,23.217H20"
          fill="none"
          stroke="#6a6a6a"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          id="Path_3854"
          data-name="Path 3854"
          d="M16.238,10.359a1.743,1.743,0,1,1,1.233-.511,1.743,1.743,0,0,1-1.233.511Z"
          fill="#6a6a6a"
        />
        <path
          id="Path_3855"
          data-name="Path 3855"
          d="M26.787,5.212h0A15.119,15.119,0,0,0,21.91,1.935h0A15.174,15.174,0,0,0,16,.75h-.069A15.213,15.213,0,0,0,9.991,1.972h0A15.155,15.155,0,0,0,5.158,5.256h0a15.1,15.1,0,0,0-3.24,4.86h0A15.256,15.256,0,0,0,.75,16.071h0a15.3,15.3,0,0,0,1.634,6.806l.08.159v5.607a.893.893,0,0,0,.893.893h5.61l.159.08a15.3,15.3,0,0,0,6.8,1.634H16a15.193,15.193,0,0,0,10.739-4.407h0a15.1,15.1,0,0,0,3.284-4.832h0a15.36,15.36,0,0,0,.038-11.918Zm0,0a15.253,15.253,0,0,1,3.279,4.878Z"
          fill="none"
          stroke="#6a6a6a"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};
