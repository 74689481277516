import { IconButton, InputAdornment, Radio, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { string, any, bool, object, number, func } from "prop-types";
import { Controller } from "react-hook-form";
import { CartTrashIcon } from "../../../../../../../components/Icons";
import StyledLabel from "../../../../../../../components/StyledLabel/StyledLabel";
import { StyledTextField } from "../../../../../../../components/TextFields/TextFields";
import useStyles from "../../newStyles";

const CarriersItem = ({
  carrier,
  isEdit,
  control,
  index,
  handleRemoveCarrier,
  handleChangeDefault,
  isNotEdited,
}) => {
  const classes = useStyles();

  if (!carrier) return <></>;
  return (
    <Box
      sx={{
        "&:not(:last-of-type)": {
          borderBottom: !isEdit && "1px solid #d5d9d9",
        },
        "& .MuiTypography-root": {
          fontSize: "15px",
        },
      }}
      display="flex"
      justifyContent="space-between"
      ml="20px"
      mr="10px"
      height="62px"
      alignItems="center"
    >
      <Box display="flex" gap="10px" alignItems="center" width="100%">
        {isEdit ? (
          <Box display="flex" alignItems="center">
            <Controller
              render={({ field }) => (
                <>
                  <Radio
                    checked={!!field.value}
                    {...field}
                    onChange={() => handleChangeDefault(index)}
                  />
                  <StyledLabel
                    fill={field.value ? "#47a06d" : "#D5D9D9"}
                    style={{
                      mr: "22px",
                      pt: "0.5px",
                      width: "48px",
                    }}
                  />
                </>
              )}
              name={`shippingCarriers.${index}.default`}
              control={control}
            />
            <Controller
              render={({ field, fieldState: { error } }) => (
                <StyledTextField
                  disabled={isNotEdited}
                  fullWidth
                  size="large"
                  InputProps={{
                    className: classes.editInput,
                    sx: { minWidth: "243px" },
                    endAdornment:
                      isNotEdited || carrier?.default ? null : (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => handleRemoveCarrier(index)}
                          >
                            <CartTrashIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                  }}
                  error={error?.message || ""}
                  {...field}
                />
              )}
              name={`shippingCarriers.${index}.name`}
              control={control}
            />
          </Box>
        ) : (
          <Box display="flex" gap="10px" alignItems="center" width="100%">
            <Typography color="#979797" noWrap>
              {carrier.name}{" "}
            </Typography>
            {carrier.default && <StyledLabel style={{ mt: "2px" }} />}
          </Box>
        )}
      </Box>
    </Box>
  );
};

CarriersItem.propTypes = {
  title: string,
  value: any,
  isDefault: bool,
  isEdit: bool,
  EditField: any,
  control: object,
  index: number,
  handleRemoveCarrier: func,
  handleChangeDefault: func,
  carrier: object,
  isNotEdited: bool,
};

export default CarriersItem;
