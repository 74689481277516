export const SALES_PERMISSIONS = {
  role: "SALES",
  customers: {
    view: false,
    delete: false,
    import: false,
    export: false,
    gallery: false,
    prospects: false,
    create_edit: false,
    territories: false,
    customer_tags: false,
  },
  orders: {
    view: false,
    // drafts: false,
    create_edit: false,
    // third_party: false,
    import: false,
    export: false,
  },
  catalog: {
    view: false,
    import: false,
    export: false,
    create_edit: false,
    product_tags: false,
    manufacturers: false,
  },
  representatives: {
    view: false,
  },
  discounts: {
    view: false,
    create_edit: false,
  },
  tasks: {
    view: false,
    create_edit: false,
    delete: false,
  },
  forms: {
    view: false,
    create_edit: false,
    delete: false,
  },
  routes: {
    view: false,
    assign: false,
    create_edit: false,
  },
  reports: {
    view: false,
  },
  settings: {
    orders: false,
    profile: false,
    payments: false,
    customers: false,
    inventory: false,
    // import_export: false, // temporarily solution - SD-7621
    payment_terms: false,
    representatives: false,
  },
};

export const MERCHANDISER_PERMISSIONS = {
  role: "MERCHANDISER",
  customers: {
    view: false,
    delete: false,
    import: false,
    export: false,
    gallery: false,
    prospects: false,
    create_edit: false,
    territories: false,
    customer_tags: false,
  },
  orders: {
    view: false,
    // import: false,
    export: false,
  },
  catalog: {
    view: false,
    // import: false,
    export: false,
  },
  representatives: {
    view: false,
  },
  discounts: {
    view: false,
  },
  tasks: {
    view: false,
    create_edit: false,
    delete: false,
  },
  forms: {
    view: false,
    create_edit: false,
    delete: false,
  },
  routes: {
    view: false,
    assign: false,
    create_edit: false,
  },
  reports: {
    view: false,
  },
  settings: {
    customers: false,
    // import_export: false, // temporarily solution - SD-7621
    representatives: false,
  },
};

export const defaultValues = {
  email: "",
  name: "",
  // avatar: null,
  phone: "",
  status: "",
  role: "MERCHANDISER",
  active: true,
};

export const ROLES = [
  {
    key: "SALES",
    value: "Sales",
  },
  {
    key: "MERCHANDISER",
    value: "Merchandiser",
  },
  {
    key: "THIRD_PARTY",
    value: "3rd Party",
  },
];
