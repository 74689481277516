import { array, string, func, number } from "prop-types";
import { Chip, Stack } from "@mui/material";
import { Box } from "@mui/system";

const CustomerTabSwitcherComponent = ({
  tabs,
  currentTab,
  handleChangeCurrentTab,
  // currentFlatTab,
  // handleChangeCurrentFlatTab,
  // checkedCustomersCount,
  // checkedGroupsCount,
}) => {
  return (
    <Box
      sx={{
        // px: "32px",
        mr: "15px",
      }}
    >
      <Stack
        sx={{
          py: "16px",
        }}
        direction="column"
      >
        <Box display="flex" gap={1}>
          {tabs &&
            tabs.map((tab) => (
              <Chip
                key={tab}
                sx={{
                  height: "28px",
                  fontSize: "11px",
                  fontWeight: 400,
                  borderRadius: "7px",
                  textTransform: "uppercase",
                }}
                label={tab}
                color={tab === currentTab ? "ground" : "groundLight"}
                onClick={() => handleChangeCurrentTab(tab)}
              />
            ))}
        </Box>

        {/* {currentTab === "flat" && (
          <>
            <Tabs
              className={classes.tabs}
              value={currentFlatTab}
              onChange={(e, newValue) => handleChangeCurrentFlatTab(newValue)}
            >
              <Tab
                label={
                  <Typography variant="caption" sx={{ textTransform: "none" }}>
                    Customers
                  </Typography>
                }
                key={0}
                value={0}
              />
              <Tab
                label={
                  <Typography variant="caption" sx={{ textTransform: "none" }}>
                    Groups
                  </Typography>
                }
                key={1}
                value={1}
              />
            </Tabs>

            {((currentFlatTab === 0 && !!checkedCustomersCount) ||
              (currentFlatTab === 1 && !!checkedGroupsCount)) && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    borderRight: "1px solid #BCBCBC",
                    width: "16px",
                    height: "84%",
                  }}
                />
                <Box
                  sx={{
                    ml: 3,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "4px",
                    minWidth: "24px",
                    height: "24px",
                    backgroundColor: "rgba(71, 160, 110, 0.2)",
                  }}
                >
                  <Typography fontSize="14px" fontWeight={600} color="primary">
                    {currentFlatTab === 0 &&
                      checkedCustomersCount &&
                      checkedCustomersCount}
                    {currentFlatTab === 1 &&
                      checkedGroupsCount &&
                      checkedGroupsCount}
                  </Typography>
                </Box>
                <Typography
                  fontSize="14px"
                  fontWeight={600}
                  letterSpacing={0.2}
                  color="primary"
                  ml={1}
                >
                  {currentFlatTab === 0 &&
                    checkedCustomersCount &&
                    "CUSTOMERS "}
                  {currentFlatTab === 1 && checkedGroupsCount && "GROUPS "}
                  SELECTED
                </Typography>
              </Box>
            )}
          </>
        )} */}
      </Stack>
    </Box>
  );
};

CustomerTabSwitcherComponent.propTypes = {
  tabs: array,
  currentTab: string,
  currentFlatTab: number,
  handleChangeCurrentTab: func,
  handleChangeCurrentFlatTab: func,
  checkedCustomersCount: number,
  checkedGroupsCount: number,
};

CustomerTabSwitcherComponent.defaultProps = {
  tabs: null,
};

export default CustomerTabSwitcherComponent;
