import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { bool, func, object } from "prop-types";
import { LargeCheckbox } from "../../../../../../components/Checkboxes";

const DiscountGroupItem = ({ item, disabled, isChecked, handleCheckItem }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      p="4.5px 14px 6px 6px"
      height="43px"
      borderBottom="0.5px solid #D5D9D9"
      sx={{
        opacity: disabled ? 0.5 : 1,
      }}
    >
      <LargeCheckbox
        checked={isChecked}
        onChange={() => handleCheckItem(item)}
        size={16}
        formSx={{ m: 0 }}
        disabled={disabled}
      />
      <Box
        sx={{
          gap: "3px",
          display: "inline-flex",
          "& > .MuiTypography-root": {
            fontSize: "12px",
            color: "#1C1C19",
          },
        }}
      >
        <Typography>{item.name}</Typography>
        <Typography fontWeight="400">
          (ID: {item?.customId?.customId || item?.customId})
        </Typography>
      </Box>
    </Box>
  );
};

DiscountGroupItem.propTypes = {
  item: object,
  handleCheckItem: func,
  disabled: bool,
  isChecked: bool,
};

DiscountGroupItem.defaultProps = {
  item: null,
  disabled: false,
  isChecked: false,
};

export default DiscountGroupItem;
