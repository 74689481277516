import { useCallback, useState } from "react";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { bool, func, string, object } from "prop-types";
import {
  FilterSearchTextField,
  InfiniteLoadMoreBtn,
  InfiniteScrollWrapper,
  ReturnDialog,
  StyledButton,
} from "../../../../../../components";
import useStyles from "./styles";
import { CrossBigIcon } from "../../../../../../components/Icons";
import ActiveTabs from "../../../../../../components/ActiveTabs";
import { FILTER_TABS } from "./CustomerOrdersPopup.constants";
import {
  getOrderByIdService,
  getOrdersService,
} from "../../../../../../services/orders";
import { error } from "../../../../../../utils/notifications";
import { useEffect } from "react";
import { useMemo } from "react";
import {
  useDebounce,
  useRepsPermissions,
} from "../../../../../../helpers/hooks";
import { getCustomOrdersService } from "../../../../../../services/reports";
import { downloadExcelFile, useAdmin } from "../../../../../../helpers/helpers";
import { selectsOfSalesSummary } from "../../../../../ReportsPage/ReportsPage.constants";
import AcceptPaymentPopup from "Pages/OrdersPage/OrderDetailsPage/OrdersTopPaymentBar/AcceptPaymentPopup";
import { OrdersActions } from "Pages/useOrdersActions";
import CancelDialog from "Pages/OrdersPage/components/CancelDialog/CancelDialog";
import RefundDialog from "Pages/OrdersPage/components/RefundDialog/RefundDialog";
import ConfirmDeliveryPopup from "Pages/OrdersPage/OrderDetailsPage/OrdersTopPaymentBar/components/OrdersDelivery/ConfirmDeliveryPopup/ConfirmDeliveryPopup";
import ViewOrderEmailDialog from "Pages/OrdersPage/components/ViewOrderEmailDialog/ViewOrderEmailDialog";
import { OrdersPopupHeader, OrdersPopupItem } from "./components";
import { useSelector } from "react-redux";

const CustomerOrdersPopup = ({ open, onClose, timeZone, customer }) => {
  const classes = useStyles();
  const isAdmin = useAdmin();
  const [tabField, setTabField] = useState({ name: "", value: "" });
  const [ordersState, setOrdersState] = useState({
    list: [],
    loading: false,
    count: 0,
    page: 1,
    search: "",
    reportLoading: false,
  });
  const {
    handleChoseMenuItem,
    order,
    setOrder,
    emailOpen,
    setEmailOpen,
    handleSendEmail,
    loadingSendEmail,
    handleCloseAcceptPaymentPopup,
    handleOpenEmail,
    isAcceptPaymentPopupOpen,
    openCancelDialog,
    openRefundDialog,
    nameRefundDialog,
    openReturnDialog,
    setOpenRefundDialog,
    setNameRefundDialog,
    setOpenReturnDialog,
    setOpenCancelDialog,
    setType,
    type,
    isConfirmDeliveryPopupOpen,
    setIsConfirmDeliveryPopupOpen,
  } = OrdersActions();
  const repPermissions = useRepsPermissions();

  const currentUser = useSelector(({ auth }) => auth.currentUser);

  const isQuickBooksConnected = !!currentUser?.quickBooksTokens;

  const searchDebounced = useDebounce(ordersState.search, 500);

  const handleCloseAcceptDeliveryPopup = () => {
    setIsConfirmDeliveryPopupOpen(false);
    setType("");
  };
  const handleFetch = useCallback(() => {
    if (!customer?.id) return;
    setOrdersState((prev) => ({
      ...prev,
      loading: true,
    }));

    getOrdersService({
      customer_id: customer?.id,
      page: ordersState.page,
      limit: 15,
      [tabField.name]: JSON.stringify(tabField.value),
      search: searchDebounced,
    })
      .then((res) => {
        setOrdersState((prev) => ({
          ...prev,
          list: [...prev.list, ...res.rows],
          loading: false,
          count: res.count,
          page: prev.page + 1,
        }));
      })
      .catch((err) => {
        setOrdersState((prev) => ({
          ...prev,
          loading: false,
        }));
        error(err?.response?.data?.message);
      });
  }, [customer?.id, ordersState.page, tabField, searchDebounced]);

  const handleGetOrder = useCallback((orderId) => {
    getOrderByIdService(orderId)
      .then((newOrderData) => {
        setOrdersState((prev) => ({
          ...prev,
          list: prev.list.map((order) =>
            order.id === orderId ? newOrderData : order
          ),
        }));
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error("Error fetching new order data:", error);
      });
  }, []);

  useEffect(() => {
    if (open) return handleFetch();
    setOrdersState((prev) => ({ ...prev, page: 1, list: [] }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer?.id, open, tabField, searchDebounced]);

  const tableContent = useMemo(() => {
    if (ordersState.loading && !ordersState.list.length)
      return (
        <Box className={classes.contentWrap}>
          <CircularProgress />
        </Box>
      );
    if (!ordersState.list.length)
      return (
        <Box className={classes.contentWrap}>
          <Typography fontSize="25px" color="#a6a6a6">
            No orders found
          </Typography>
        </Box>
      );
    return (
      <Stack
        sx={{
          height: "100%",
          maxHeight: "236px",
          overflowY: "hidden",
        }}
      >
        <InfiniteScrollWrapper
          loading={ordersState.loading}
          maxHeight="236px"
          id="orders-scroll-table"
          dataLength={ordersState.list.length}
          next={handleFetch}
          loader={
            <CircularProgress sx={{ ml: "50%", mt: "5px" }} size="30px" />
          }
          hasMore={ordersState.list.length < ordersState.count}
        >
          {ordersState.list.map((order, index) => (
            <OrdersPopupItem
              key={order.id}
              order={order}
              timeZone={timeZone}
              handleOpenEmail={handleOpenEmail}
              handleChoseMenuItem={handleChoseMenuItem}
              showBorder={
                index !== ordersState.list.length - 1 ||
                ordersState.list.length < 5
              }
              repPermissions={repPermissions}
              isAdmin={isAdmin}
              isQuickBooksConnected={isQuickBooksConnected}
            />
          ))}
          {!ordersState.loading &&
            ordersState.list.length < ordersState.count && (
              <InfiniteLoadMoreBtn onClick={handleFetch} />
            )}
        </InfiniteScrollWrapper>
      </Stack>
    );
  }, [
    ordersState.loading,
    ordersState.list,
    ordersState.count,
    classes.contentWrap,
    handleFetch,
    timeZone,
    handleOpenEmail,
    handleChoseMenuItem,
    repPermissions,
    isAdmin,
    isQuickBooksConnected,
  ]);

  const handleExport = async () => {
    const body = {
      customerIds: [customer?.id],
      fields: [...selectsOfSalesSummary.map((s) => s.type)],
      type: "common",
      withoutCents: false,
    };
    try {
      setOrdersState((prev) => ({ ...prev, reportLoading: true }));
      const res = await getCustomOrdersService("order", body, {});
      if (res.status === 200) downloadExcelFile(res, "report", customer?.name);
      setOrdersState((prev) => ({ ...prev, reportLoading: false }));
    } catch (err) {
      setOrdersState((prev) => ({ ...prev, reportLoading: false }));
      error(err?.response?.data?.message);
    }
  };

  return (
    <>
      <CancelDialog
        order={order}
        title="Cancel order"
        orderId={order?.id}
        customId={order?.customId?.customId}
        paymentStatus={order?.paymentStatus}
        deliveryStatus={order?.deliveryStatus}
        totalPayments={order?.totalPayments}
        open={openCancelDialog}
        onClose={() => setOpenCancelDialog(false)}
        handleGetOrder={handleGetOrder}
      />
      <RefundDialog
        open={openRefundDialog}
        onClose={() => {
          setOpenRefundDialog(false);
          setNameRefundDialog("");
          setOrder({});
        }}
        nameRefundDialog={nameRefundDialog}
        order={order}
        handleGetOrder={handleGetOrder}
      />
      <ReturnDialog
        open={openReturnDialog}
        onClose={() => {
          setOpenReturnDialog(false);
          setOrder({});
        }}
        handleGetOrder={handleGetOrder}
        order={order}
      />
      <ViewOrderEmailDialog
        isOpen={emailOpen}
        handleClose={() => setEmailOpen(false)}
        order={order}
        handleSendEmail={handleSendEmail}
        loading={loadingSendEmail}
      />
      <AcceptPaymentPopup
        order={order}
        isOpen={isAcceptPaymentPopupOpen}
        handleGetOrder={handleGetOrder}
        handleClose={handleCloseAcceptPaymentPopup}
      />
      <ConfirmDeliveryPopup
        order={order}
        isOpen={isConfirmDeliveryPopupOpen}
        handleClose={handleCloseAcceptDeliveryPopup}
        handleGetOrder={handleGetOrder}
        type={type}
      />
      <Dialog
        {...{ open, onClose }}
        PaperProps={{ sx: { maxWidth: "1318px", width: "100%" } }}
      >
        <DialogTitle className={classes.dialogTitle}>
          <Box display="flex" gap="29px" alignItems="center">
            <Typography className={classes.title}>Orders</Typography>
            <StyledButton
              onClick={handleExport}
              fontSize="13px"
              label="Export Report"
              variant="outlined"
              disabled={
                repPermissions
                  ? !repPermissions?.reports?.orders
                  : ordersState.reportLoading
              }
            />
          </Box>
          <IconButton onClick={onClose}>
            <CrossBigIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Box className={classes.filterWrap}>
            <ActiveTabs
              value={tabField.value}
              onChange={(e, newVal) => {
                setOrdersState((prev) => ({
                  ...prev,
                  page: 1,
                  list: [],
                  loading: true,
                }));
                setTabField({ name: e.target.name, value: newVal });
              }}
              customTabs={FILTER_TABS}
              tabStyles={{ fontWeight: "400" }}
            />
            <FilterSearchTextField
              value={ordersState.search}
              onChange={(e) => {
                setOrdersState((prev) => ({
                  ...prev,
                  search: e.target.value,
                  page: 1,
                  list: [],
                  loading: true,
                }));
              }}
              fullWidth
              placeholder="Search orders by ID"
            />
          </Box>
          <Box className={classes.tableWrap}>
            <OrdersPopupHeader />
            {tableContent}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

CustomerOrdersPopup.propTypes = {
  open: bool,
  onClose: func,
  timeZone: string,
  customer: object,
};

export default CustomerOrdersPopup;
