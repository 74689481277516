import { useMemo, useRef } from "react";
import { bool, func } from "prop-types";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { Box, Grid, Typography } from "@mui/material";
import { LargeCheckbox, TableHeader } from "components";
import { columnLayoutsSelector } from "redux/selectors/settings";

const selector = createSelector(columnLayoutsSelector, (columnLayouts) => ({
  columnLayouts,
}));

const ORDER_WIDTH = 72;
const EMPTY_WIDTH = 120;

export const OrdersHeader = ({
  allOrdersChecked,
  checkAllOrders,
  short,
  isPopup,
}) => {
  const checkboxRef = useRef(null);
  const checkboxWidth = checkboxRef.current?.offsetWidth + 1 || 0;

  const { columnLayouts } = useSelector(selector);
  const direct_tab_disable = columnLayouts.direct.disable;
  const direct_tab = columnLayouts.direct.main;

  const columns = useMemo(() => {
    if (direct_tab_disable)
      return [
        ...direct_tab_disable,
        ...direct_tab.filter((col) => col.selected),
      ];

    return [];
  }, [direct_tab, direct_tab_disable]);
  const columnWidth = {
    Note: 2.5,
    Date: 11,
    Customer: 24.1,
    "Created by": 9.4,
    Payment: 13,
    Fulfillment: 12,
    Total: 8,
    Tags: 20,
  };

  const defaultColumnWidth = useMemo(
    () => Object.values(columnWidth).reduce((acc, cur) => (acc += cur), 0),
    // eslint-disable-next-line
    []
  );

  const classes = {
    grid: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
    typography: {
      fontSize: "12px",
      fontWeight: "400",
      color: "#6A6A6A",
      textTransform: "uppercase",
    },
  };

  const hasOrderColumn = columns?.findIndex((c) => c?.name === "Order") >= 0;

  const orderBoxWidth = hasOrderColumn ? ORDER_WIDTH : 0;

  const setColumnWidth = (col) => {
    if (col?.name === "Order") return `${ORDER_WIDTH}px`;

    if (col?.name === "Tags")
      return `calc(${columnWidth?.[col?.name]}% - ${orderBoxWidth}px)`;

    const columns = columnWidth?.[col?.name]
      ? `${columnWidth?.[col?.name]}%`
      : `${
          (100 - defaultColumnWidth) /
          direct_tab.filter((col) => col.selected).length
        }%`;
    return columns;
  };

  return (
    <TableHeader
      sx={{
        display: "flex",
        backgroundColor: "#EEEEEE",
        borderTop: "none",
        borderLeft: short && "none",
        borderRight: short && "none",
      }}
      variant="outlined"
      columns={22}
    >
      {!short && (
        <Box ref={checkboxRef} sx={{ ...classes.grid, width: "52px" }}>
          <LargeCheckbox
            formSx={{ m: 0 }}
            sx={{ padding: 0 }}
            size={22}
            checked={allOrdersChecked}
            onChange={checkAllOrders}
          />
        </Box>
      )}
      {isPopup ? (
        <>
          <Box
            sx={{
              ...classes.grid,
              justifyContent: "flex-start",
              pl: short && "15px",
              width: "100px",
            }}
          >
            <Typography sx={classes.typography}>Order #</Typography>
          </Box>
          <Grid xs item container>
            <Grid
              item
              xs={short ? 3.75 : 1}
              sx={{ ...classes.grid, justifyContent: "flex-start" }}
            >
              <Typography sx={classes.typography}>Date</Typography>
            </Grid>
            {!short && (
              <Grid
                item
                xs={6.1}
                sx={{ ...classes.grid, justifyContent: "flex-start" }}
              >
                <Typography sx={classes.typography}>Customer</Typography>
              </Grid>
            )}
            <Grid
              item
              xs={short ? 3.6 : 2.6}
              sx={{ ...classes.grid, justifyContent: "flex-start" }}
            >
              <Typography sx={classes.typography}>Created By</Typography>
            </Grid>
            <Grid
              item
              xs={short ? 4.25 : 2.8}
              sx={{ ...classes.grid, justifyContent: "flex-start" }}
            >
              <Typography sx={classes.typography}>Payment</Typography>
            </Grid>
            <Grid
              item
              xs={short ? 3.2 : 3}
              sx={{ ...classes.grid, justifyContent: "flex-start" }}
            >
              <Typography sx={classes.typography}>Fulfillment</Typography>
            </Grid>
            <Grid
              item
              xs={2}
              sx={{ ...classes.grid, justifyContent: "flex-start" }}
            >
              <Typography sx={classes.typography}>Total</Typography>
            </Grid>{" "}
          </Grid>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: `calc(100% - ${checkboxWidth + EMPTY_WIDTH}px)`,
          }}
        >
          {columns.map((col) => {
            const valueOfWidth = setColumnWidth(col);
            return (
              <Box
                key={col.id}
                sx={{
                  ...classes.typography,
                  display: "flex",
                  alignItems: "center",
                  opacity: col.name === "Note" ? 0 : 1,
                  pl: col.name === "Tags" ? "38px" : 0,
                  gap: 1,
                  width: valueOfWidth,
                  maxWidth: valueOfWidth,
                  minWidth: valueOfWidth,
                }}
              >
                {col?.name === "Order" ? col.name + "#" : col.name}
              </Box>
            );
          })}
        </Box>
      )}
      {!short && <Box sx={{ width: `${EMPTY_WIDTH}px` }} />}
    </TableHeader>
  );
};

OrdersHeader.defaultProps = {
  allOrdersChecked: false,
  checkAllOrders: () => {},
  short: false,
};

OrdersHeader.propTypes = {
  allOrdersChecked: bool,
  checkAllOrders: func,
  short: bool,
  isPopup: bool,
};

export default OrdersHeader;
