import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    transition: "all ease .3s",
    transform: ({ selectedNum }) =>
      !selectedNum ? "translateX(105%)" : "translateX(0%)",
  },

  numberBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "4px",
    minWidth: "24px",
    height: "24px",
    backgroundColor: "rgba(71, 160, 110, 0.2)",
    paddingLeft: "4px",
    paddingRight: "4px",
  },

  animationWrapper: {
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
  },

  divider: {
    margin: "0 13px",
  },
}));

export default useStyles;
