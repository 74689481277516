import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  blockTitle: {
    marginLeft: "10px",
    fontSize: "18px",
    fontWeight: 400,
    color: "#707070",
  },

  smallerButtonReport: {
    marginLeft: "16px",
    height: "28px",
    "& > span": {
      fontSize: "13px",
    },
  },
}));

export default useStyles;
