import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  wrapper: {
    border: "1px solid #d5d9d9",
    borderRadius: "4px",
    width: "272px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  titleWrapper: {
    width: "100%",
    backgroundColor: "#F3F3F3",
    height: "48px",
    borderBottom: "0.5px solid #d5d9d9",
    textAlign: "center",
  },

  title: {
    fontSize: "28px",
  },

  iconWrapper: {
    padding: "16px",
    height: "93px",
  },

  buttonsWrapper: {
    display: "flex",
    gap: "6px",
    paddingBottom: "16px",
  },

  button: {
    width: "122px",
    color: "#000",
    "&.import": {
      color: ({ inProgress }) => (inProgress ? "#fff" : "#000"),
    },
    height: "30px",
  },
}));

export default useStyles;
