export const defaultValues = {
  deliveryMethod: "SHIPPING_CARRIER",
  date: "",
  sendNotification: false,
  products: [],
  trackingId: "",
  shippingCarrier: "",
  delivererId: "Select reps",
};

export const textForTooltipEditFulfill = (
  <>
    <span>Fulfilment could</span>
    <br />
    <span>not be edited</span>
    <br />
    <span>due to returned items</span>
  </>
);

export const textForTooltipDeleteFulfill = (
  <>
    <span>Fulfilment could</span>
    <br />
    <span>not be deleted</span>
    <br />
    <span>due to returned items</span>
  </>
);

export const textForTooltipEditReturn = (
  <>
    <span>Return could</span>
    <br />
    <span>not be edited</span>
    <br />
    <span>due to delivered items</span>
  </>
);

export const textForTooltipEditRefundWithReturn = (
  <>
    <span>Return via refund could</span>
    <br />
    <span>not be edited</span>
  </>
);

export const textForTooltipEditRefundWithReturnHasDelivery = (
  <>
    <span>Return via refund could</span>
    <br />
    <span>not be edited</span>
    <br />
    <span>due to delivered items</span>
  </>
);

export const textForTooltipDeleteReturn = (
  <>
    <span>Return could</span>
    <br />
    <span>not be deleted</span>
    <br />
    <span>due to delivered items</span>
  </>
);

export const textForTooltipPaymentReturn = (
  <>
    <span>Payment could</span>
    <br />
    <span> not be edited</span>
    <br />
    <span>due to refund</span>
  </>
);
export const textForTooltipDeletePayment = (
  <>
    <span>Payment could</span>
    <br />
    <span> not be deleted</span>
    <br />
    <span>due to refund</span>
  </>
);
