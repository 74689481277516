/* eslint-disable no-useless-escape */
import * as Yup from "yup";

export const validationSchema = () =>
  Yup.object().shape({
    deliveryFeeAmount: Yup.number()
      .required("This field is required")
      .typeError("Must be a number.")
      .when("deliveryFeeType", {
        is: "PERCENTAGE",
        then: Yup.number()
          .transform((o, v) => {
            if (v === "") return 0;
            return parseFloat(v);
          })
          .test(
            "deliveryFeeValidations",
            "Please type a valid fee.",
            (value) => {
              return value >= 0 && value <= 100;
            }
          ),
        otherwise: Yup.number()
          .transform((o, v) => {
            if (v === "") return 0;
            return parseFloat(v.replace(/,/g, ""));
          })
          .test(
            "deliveryFeeValidations",
            "Please type a valid fee.",
            (value) => {
              return value >= 0;
            }
          ),
      }),
    deliveryFeePriceCapAmount: Yup.number()
      .typeError("Must be a number.")
      .when("deliveryFeePriceCap", {
        is: true,
        then: Yup.number()
          .transform((o, v) => {
            if (v === "") return 0;
            return parseFloat(v.replace(/,/g, ""));
          })
          .required("This field is required")
          .test(
            "deliveryFeePriceCapAmountValidations",
            "Please type a valid amount.",
            (value) => {
              return value >= 0;
            }
          ),
      }),
  });
