import { useMemo } from "react";
import { array, shape, string, number, bool } from "prop-types";
import { Box, Skeleton, Typography } from "@mui/material";
import { StyledProductIco } from "../../Icons";
import {
  photoUrl,
  separateNumWithComma,
  truncateText,
} from "../../../helpers/helpers";
import { useBreakpoint } from "helpers/useBreakpoint";
import { StyledTooltip } from "components";

export const TotalSummarySellersItem = ({ seller, isLoading }) => {
  const breakpoint = useBreakpoint();

  const getProductVariant = useMemo(() => {
    if (seller?.sku && seller?.color)
      return `${seller?.sku} / ${seller?.color}`;
    if (seller?.sku && seller?.size) return `${seller?.sku} / ${seller?.size}`;
    if (seller?.sku) return seller?.sku;
    return "";
  }, [seller?.color, seller?.size, seller?.sku]);

  return isLoading ? (
    <Skeleton width={200} height={60} />
  ) : (
    <Box
      sx={{
        display: "flex",
      }}
    >
      <Box display="flex" width="fit-content">
        <StyledTooltip
          isDark
          title={
            <Box>
              <>
                <Typography fontSize={11}>{seller?.name}</Typography>
                <Typography fontSize={11}>{getProductVariant}</Typography>
              </>
            </Box>
          }
          followCursor
        >
          <Box
            sx={{
              maxWidth: "38px",
              minWidth: "38px",
              height: "38px",
              backgroundColor: "white",
              border: "1px solid #D5D9D9",
              borderRadius: "4px 0 0 4px",
            }}
          >
            <StyledProductIco
              src={seller?.photo ? photoUrl(seller.photo?.fileName) : ""}
              styles={{
                objectFit: "contain",
                width: "100%",
                height: "100%",
                borderRadius: "4px 0 0 4px",
              }}
              placeholderStyles={{
                style: {
                  borderRadius: "4px 0 0 4px",
                  border: "1px solid #D5D9D9",
                },
              }}
            />
          </Box>
        </StyledTooltip>
        <Box
          sx={{
            maxWidth: breakpoint !== "xl" ? "75px" : null,
            maxHeight: "38px",
            borderWidth: "1px 1px 1px 0",
            borderStyle: "solid",
            borderColor: "#D5D9D9",
            borderRadius: "0 4px 4px 0",
            bgcolor: "#F6F6F6",
            display: "flex",
            alignItems: "center",
            px: "4px",
          }}
        >
          <StyledTooltip
            isDark
            title={
              <Box>
                <Typography fontSize={11}>{`$ ${separateNumWithComma(
                  seller?.totalSales
                )}`}</Typography>
              </Box>
            }
            followCursor
          >
            <Typography
              sx={{
                fontSize: 12,
                color: "#1C1C19",
              }}
              noWrap
            >
              {truncateText(
                `$ ${separateNumWithComma(seller?.totalSales)}`,
                14
              )}
            </Typography>
          </StyledTooltip>
        </Box>
      </Box>
    </Box>
  );
};

TotalSummarySellersItem.propTypes = {
  seller: shape({
    name: string,
    totalSales: number,
    sku: string,
    color: string,
    size: string,
    photos: array,
  }),
  isLoading: bool,
};
