import { useState } from "react";
import { Grid, Paper, Typography, Box } from "@mui/material";
import { object, number, func, array, bool } from "prop-types";
import {
  calculateAvailable,
  photoUrl,
} from "../../../../../../helpers/helpers";
import { StyledProductIco } from "../../../../../../components/Icons";
import { PriceFormat } from "../../../../../../components/TextFields/TextFields";
import { Controller } from "react-hook-form";
import ValueToggleButtonGroup from "../../../../../../components/ValueToggleButtonGroup/ValueToggleButtonGroup";
import { StyledTooltip } from "components";
import { PRODUCT_TYPE_INVENTORY } from "utils/constants";

const ItemDiscount = ({
  product,
  control,
  index,
  setValue,
  products,
  hideAvailable,
  trigger,
}) => {
  const [openTooltip, setOpenTooltip] = useState(false);

  const isNonInventory = product?.type === PRODUCT_TYPE_INVENTORY.non_inventory;

  const returnPhoto = () => {
    if (product.photo) return photoUrl(product.photo.fileName);
    if (product.photos?.length) return photoUrl(product.photos[0].fileName);
    return "";
  };

  const styles = {
    emptyProduct: {
      objectFit: "contain",
      width: "100%",
      height: "100%",
      borderRadius: "4px",
    },
    productPhoto: {
      width: "38px",
      backgroundColor: "white",
      border: "1px solid #D5D9D9",
      borderRadius: "4px",
    },
  };

  return (
    <Paper
      sx={{
        border: "none",
        alignItems: "center",
        pl: "16px",
        "&:not(:last-child)": { borderBottom: "1px solid #D4D4D4" },
        "& .MuiTypography-root": {
          fontSize: "12px",
          color: "#1C1C19",
        },
        "&:last-child": {
          borderRadius: "0 0 4px 4px",
        },
      }}
      elevation={0}
      variant="outlined"
      square
      component={Grid}
      container
      columns={26}
      height="48px"
    >
      <Grid item xs={0.7} height="38px">
        <Box sx={styles.productPhoto} height="38px">
          <StyledProductIco src={returnPhoto()} styles={styles.emptyProduct} />
        </Box>
      </Grid>
      <Grid item xs={3} pl="5px">
        <Typography noWrap>
          {product.name || product.parentProduct.name}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography noWrap>{product.sku}</Typography>
      </Grid>
      <Grid item xs={4.55}>
        <Typography noWrap>
          {product.manufacturer?.name ||
            product.manufacturerName ||
            product.parentProduct?.manufacturer?.name ||
            product.parentProduct?.manufacturerName}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        {!hideAvailable && (
          <Typography>
            {isNonInventory
              ? "-"
              : calculateAvailable(
                  product.inventory?.onHand ||
                    product.product?.inventory?.onHand,
                  product.inventory?.allocated ||
                    product.product?.inventory?.allocated
                ) || "-"}
          </Typography>
        )}
      </Grid>
      <Grid item xs={3.3}>
        <Typography>{product.quantity}</Typography>
      </Grid>
      <Grid item xs={4.3}>
        <Typography>
          ${(product.wholesalePrice || product.price || 0).toFixed(2)}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <StyledTooltip
          key={index}
          title={
            "This order has been partially fulfilled or fulfilled. " +
            "The discount can't be added to this order"
          }
          placement="top"
          arrow
          open={openTooltip && product.totalDelivered > 0}
          onOpen={() => setOpenTooltip(true)}
          onClose={() => setOpenTooltip(false)}
        >
          <Box>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <PriceFormat
                  type={products[index].itemDiscountType}
                  errorMsgSx={{
                    transform: "translate(-100%, 0)",
                    bottom: 0,
                    left: -5,
                  }}
                  noErrorMessage={false}
                  fullWidth
                  disabled={product.totalDelivered > 0}
                  error={error?.message || ""}
                  InputProps={{
                    sx: {
                      height: "32px",
                      fontSize: "12px",
                      pr: 0,
                    },
                    endAdornment: (
                      <Controller
                        render={({ field }) => (
                          <ValueToggleButtonGroup
                            qtyPicker={false}
                            type={field.value}
                            {...field}
                            onChange={(e) => {
                              field?.onChange(e);
                              trigger();
                            }}
                          />
                        )}
                        name={`products.${index}.itemDiscountType`}
                        control={control}
                      />
                    ),
                  }}
                  {...field}
                  value={field.value || ""}
                  onChange={(e) => {
                    const val = e.target.value;
                    setValue(`products.${index}.itemDiscountAmount`, val);
                  }}
                />
              )}
              name={`products.${index}.itemDiscountAmount`}
              control={control}
            />
          </Box>
        </StyledTooltip>
      </Grid>
    </Paper>
  );
};

ItemDiscount.propTypes = {
  product: object,
  control: object,
  index: number,
  setValue: func,
  products: array,
  hideAvailable: bool,
  trigger: func,
};

export default ItemDiscount;
