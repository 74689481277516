import { arrayOf, any, object } from "prop-types";
import { Box, Grid } from "@mui/material";
import { useTheme } from "@emotion/react";
import TableHeader from "components/TableHeader";

const DataTableHeader = ({ labelsWithFixedWidth, labels, actionBlock }) => {
  const theme = useTheme();
  return (
    <TableHeader
      columns={25}
      sx={{
        borderRight: "none",
        borderLeft: "none",
        height: "39px",
        scrollbarGutter: "stable",
      }}
    >
      <Box display="flex" alignItems="center">
        {labelsWithFixedWidth.map(({ text, sx }) => (
          <Box key={text} sx={sx}>
            <Box
              sx={{
                fontSize: "12px",
                color: theme.palette.greyBtn.contrastText,
                textTransform: "uppercase",
              }}
            >
              {text}
            </Box>
          </Box>
        ))}
      </Box>

      <Grid container item xs columns={12}>
        {labels.map(({ text, xs, sx }) => (
          <Grid key={text} item xs={xs} sx={sx}>
            <Box
              sx={{
                fontSize: "12px",
                color: theme.palette.greyBtn.contrastText,
                textTransform: "uppercase",
                display: "flex",
                alignItems: "center",
                height: "100%",
              }}
            >
              {text}
            </Box>
          </Grid>
        ))}
      </Grid>

      {actionBlock && (
        <Box sx={actionBlock?.sx}>
          <Box
            sx={{
              fontSize: "12px",
              color: theme.palette.greyBtn.contrastText,
              textTransform: "uppercase",
            }}
          >
            {actionBlock?.text}
          </Box>
        </Box>
      )}
    </TableHeader>
  );
};

DataTableHeader.propTypes = {
  labelsWithFixedWidth: arrayOf(any),
  labels: arrayOf(any),
  actionBlock: object,
};

DataTableHeader.defaultProps = {
  labelsWithFixedWidth: [],
  labels: [],
  actionBlock: {},
};

export default DataTableHeader;
