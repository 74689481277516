import * as React from "react";

// eslint-disable-next-line react/prop-types
export const PeopleIcon = ({ color = "#a5a5a5", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={29.391}
    height={20.874}
    viewBox="0 0 29.391 20.874"
    {...props}
  >
    <path
      id="Icon_material-supervisor-account"
      data-name="Icon material-supervisor-account"
      d="M23.584,17.437a3.549,3.549,0,1,0-3.549-3.549A3.538,3.538,0,0,0,23.584,17.437Zm-10.647-1.42a4.259,4.259,0,1,0-4.259-4.259A4.241,4.241,0,0,0,12.937,16.017Zm10.647,4.259c-2.6,0-7.808,1.306-7.808,3.9v3.194H31.391V24.18C31.391,21.582,26.182,20.276,23.584,20.276Zm-10.647-1.42C9.629,18.857,3,20.517,3,23.825v3.549h9.937V24.18c0-1.207.468-3.322,3.364-4.926A17.437,17.437,0,0,0,12.937,18.857Z"
      transform="translate(-2.5 -7)"
      fill={color}
      stroke={color}
      strokeWidth={1}
    />
  </svg>
);
