import React, { useMemo } from "react";
import { object, string } from "prop-types";
import {
  ProspectAddress,
  ProspectDetails,
  ProspectPrice,
  ProspectRating,
  ProspectTitle,
  ProspectTooltipBody,
} from "components/MapTooltip/styles";
import { Rating, Typography } from "@mui/material";

const ProspectContent = ({ prospect, coloring = "dark" }) => {
  const hasRating = useMemo(
    () => !!prospect.rating || prospect.rating === 0,
    [prospect.rating]
  );
  const hasPrice = useMemo(() => !!prospect.price, [prospect.price]);

  const totalRatings = useMemo(
    () => (hasRating ? prospect.stats?.total_ratings : null),
    [hasRating, prospect.stats?.total_ratings]
  );

  const hasStats = useMemo(
    () => !!prospect.stats?.total_ratings,
    [prospect.stats?.total_ratings]
  );

  const prices = useMemo(
    () => (hasPrice ? Array.from(Array(prospect.price)) : null),
    [hasPrice, prospect.price]
  );

  return (
    <ProspectTooltipBody coloring={coloring}>
      <ProspectTitle noWrap coloring={coloring}>
        {prospect.name}
      </ProspectTitle>
      <ProspectDetails coloring={coloring}>
        {hasRating && (
          <>
            <ProspectRating coloring={coloring}>
              {prospect.rating.toFixed(1)}
              <Rating
                color="white"
                value={parseFloat(prospect.rating)}
                precision={0.1}
                readOnly
                size="small"
                sx={
                  coloring === "dark"
                    ? {
                        "& .MuiRating-icon:not(.MuiRating-iconFilled)": {
                          "& path": {
                            fill: "white",
                          },
                        },
                      }
                    : {}
                }
              />
              {hasStats && `(${totalRatings})`}
            </ProspectRating>
          </>
        )}
      </ProspectDetails>
      <ProspectDetails>
        {hasPrice && (
          <>
            <ProspectPrice coloring={coloring}>
              {" "}
              {prices?.map(() => "$")}
            </ProspectPrice>
            •
          </>
        )}
        <div style={{ overflow: "hidden" }}>
          <Typography noWrap fontSize="13px">
            {prospect.categories
              ?.filter(({ name }) => !!name)
              ?.map(({ name }) => name)
              ?.join(", ")}
          </Typography>
        </div>
      </ProspectDetails>
      <ProspectAddress noWrap coloring={coloring}>
        {prospect.shippingAddress.formatted_address}
      </ProspectAddress>
    </ProspectTooltipBody>
  );
};

ProspectContent.propTypes = { prospect: object, coloring: string };

export default ProspectContent;
