import { useMemo, useState } from "react";
import { array, func, object, bool } from "prop-types";
import { Box, Grid, Typography } from "@mui/material";
import {
  FileLabel,
  ImagePreview,
  ScrollControlWrapper,
  StyledButton,
  StyledTooltip,
} from "components";
import moment from "moment";
import LicensesDeletePopup from "./LicensesDeletePopup";
import { LICENSES } from "utils/constants";
import { useShowPicture } from "components/LicensesDrawer/useShowPicture";
import { useRepsPermissions } from "helpers/hooks";

export const LicensesBlock = ({
  licenses,
  onEdit,
  onDelete,
  handleDeleteLicenseFile,
  scrollBarStyle,
  hideAttachments,
}) => {
  const [hoveredBlock, setHoveredBlock] = useState(null);
  const [deleteLicense, setDeleteLicense] = useState(null);

  const repPermissions = useRepsPermissions();

  const disabledCreateEdit = useMemo(() => {
    if (repPermissions) {
      return !repPermissions?.customers?.create_edit;
    }
  }, [repPermissions]);

  const { openImagePreview, image, handleShowPicture, handleCloseShowPicture } =
    useShowPicture();

  const setName = (license, fieldType) => {
    // fieldType - oneOf ["name", "type"]
    const { type, name } = license || {};

    if (!type) return "";

    if (type === "OTHER" && name) return name;

    const licenseName = LICENSES.find((l) => l.type === type)[fieldType];

    return licenseName;
  };

  return (
    <>
      <LicensesDeletePopup
        isOpen={!!deleteLicense}
        license={deleteLicense}
        handleClose={() => setDeleteLicense(null)}
        onDelete={onDelete}
      />
      <ImagePreview
        open={openImagePreview && !!image}
        onClose={handleCloseShowPicture}
        image={image}
      />
      <ScrollControlWrapper
        dataLength={licenses?.length}
        maxHeight="226px"
        id="licenses-scroll-wrapper"
        sx={{ width: "100%", position: "relative" }}
        scrollBarStyle={scrollBarStyle}
        component={Grid}
        gap={1}
        container
      >
        {licenses?.map((license) => (
          <Grid
            key={license?.id || license?.tempId}
            sx={{
              border: "0.5px solid #D5D9D9",
              borderRadius: "4px",
              bgcolor: "#F8F8F8",
              minHeight: "65px",
              alignItems: "center",
            }}
            xs={12}
            item
            container
            onMouseEnter={() => setHoveredBlock(license)}
            onMouseLeave={() => setHoveredBlock(null)}
          >
            <Grid
              sx={{
                fontSize: 13,
                color: "#5F6267",
                pt: "10px",
                pb: "8px",
                pl: "12px",
              }}
              xs={9.5}
              item
              container
            >
              <Grid xs={12} item>
                <StyledTooltip
                  arrow
                  placement="top"
                  title={setName(license, "name")}
                >
                  <Typography fontSize={13} fontWeight={500} color="#5F6267">
                    {setName(license, "type")}

                    {license?.documentNumber ? (
                      <Box component="span" fontWeight={400}>
                        : {license.documentNumber}{" "}
                      </Box>
                    ) : null}

                    {license?.expirationDate ? (
                      <>
                        <br></br>
                        Expires:{" "}
                        <Box component="span" fontWeight={400}>
                          {moment(license?.expirationDate).format("M/DD/YYYY")}
                        </Box>
                      </>
                    ) : null}
                  </Typography>
                </StyledTooltip>
              </Grid>
            </Grid>

            {license?.attachments?.length ? (
              <Box px="12px" width="100%">
                <Box
                  sx={{
                    mt: "5px",
                    width: "100%",
                    maxWidth:
                      hideAttachments && hoveredBlock === license
                        ? "240px"
                        : "300px",
                  }}
                >
                  {license.attachments.map((f) => {
                    const fileName = f?.fileName?.split("/")?.pop();
                    return (
                      <FileLabel
                        key={fileName}
                        name={fileName}
                        size={Math.floor(f?.size / 1024)}
                        handleDeleteFile={
                          handleDeleteLicenseFile
                            ? () => handleDeleteLicenseFile(f, license)
                            : null
                        }
                        license={license}
                        handleShowPicture={() => handleShowPicture(f)}
                        setHoveredBlock={setHoveredBlock}
                      />
                    );
                  })}
                </Box>
              </Box>
            ) : null}

            {hoveredBlock === license && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  position: "absolute",
                  right: "8px",
                }}
              >
                <StyledButton
                  label="Edit"
                  variant="outlined"
                  color="ground"
                  fontSize="10px"
                  fontWeight="500"
                  disabled={disabledCreateEdit}
                  sx={{
                    borderColor: "#D4D4D4",
                    color: "#000",
                    maxWidth: "55px",
                    minWidth: "55px",
                    height: "20px",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                    mb: "5px",
                    bgcolor: "#F8F8F8",
                    "&:hover": { bgcolor: "#F8F8F8 !important" },
                  }}
                  onClick={() => onEdit(license)}
                />
                <StyledButton
                  sx={{
                    borderColor: "#D4D4D4",
                    color: "#000",
                    maxWidth: "55px",
                    minWidth: "55px",
                    height: "20px",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                    bgcolor: "#F8F8F8",
                    "&:hover": { bgcolor: "#F8F8F8 !important" },
                  }}
                  disabled={disabledCreateEdit}
                  label="Remove"
                  variant="outlined"
                  color="ground"
                  fontSize="10px"
                  fontWeight="500"
                  onClick={() => setDeleteLicense(license)}
                />
              </Box>
            )}
          </Grid>
        ))}
      </ScrollControlWrapper>
    </>
  );
};

LicensesBlock.propTypes = {
  licenses: array,
  onEdit: func,
  onDelete: func,
  handleDeleteLicenseFile: func,
  scrollBarStyle: object,
  hideAttachments: bool,
};
LicensesBlock.defaultProps = {
  licenses: [],
  onEdit: () => {},
  onDelete: () => {},
  scrollBarStyle: {},
};
