import axios from "axios";

export const getCustomerNotesService = async (customerId, params) => {
  return await axios
    .get(`/notes/${customerId}`, { params })
    .then((res) => res.data);
};

export const createNoteService = async ({ customerId, text }) => {
  return await axios
    .post("/notes", { customerId, text })
    .then((res) => res.data);
};

export const deleteNoteService = async ({ noteId }) => {
  return await axios.delete(`/notes/${noteId}`).then((res) => res.data);
};

export const updateNoteService = async ({ noteId, text }) => {
  return await axios.put(`/notes/${noteId}`, { text }).then((res) => res.data);
};
