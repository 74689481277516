import { useEffect, useMemo, useState } from "react";
import { Box, IconButton, Snackbar, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { BorderLinearProgress } from "components";
import { cl } from "./styles";
import { error, success } from "utils/notifications";
import {
  closeSnackbar,
  setConnectToFirebaseSnackbarAction,
} from "redux/actions/snackbar";
import { createSelector } from "reselect";
import {
  countOrdersSnackbarSelector,
  openSnackbarSelector,
  progressSnackbarSelector,
  statusSnackbarSelector,
} from "redux/selectors/snackbar";
import { CrossBigIcon } from "components/Icons";
import { debounce } from "lodash";

const selector = createSelector(
  openSnackbarSelector,
  progressSnackbarSelector,
  countOrdersSnackbarSelector,
  statusSnackbarSelector,
  (open, progress, countOrders, status) => ({
    open,
    progress,
    countOrders,
    status,
  })
);

export const SnackbarComponent = () => {
  const dispatch = useDispatch();

  const { open, progress, countOrders, status } = useSelector(selector);

  const progressValue = useMemo(() => {
    if (progress > 0 && countOrders > 0) {
      return Math.round((progress / countOrders) * 100);
    }

    return 0;
  }, [countOrders, progress]);

  const useStableValue = ({ value, delay = 30000 }) => {
    const [isStable, setIsStable] = useState(false);

    const checkStability = debounce(() => {
      setIsStable(true);
    }, delay);

    useEffect(() => {
      setIsStable(false);
      checkStability();

      return () => checkStability.cancel();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return isStable;
  };

  const showCloseBtn = useStableValue({ value: progressValue });

  useEffect(() => {
    return () => {
      if (["ERROR", "COMPLETE"].includes(status)) {
        dispatch(setConnectToFirebaseSnackbarAction(false));
      }

      if (status === "ERROR") error("Something went wrong!");

      if (status === "COMPLETE") success("Orders marked as fulfilled");
    };
  }, [dispatch, status]);

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={open}
      autoHideDuration={6000}
    >
      <Box sx={cl.wrapper}>
        {showCloseBtn && (
          <Box sx={{ position: "absolute", top: 0, right: 0 }}>
            <IconButton
              onClick={() => {
                dispatch(closeSnackbar());
                dispatch(setConnectToFirebaseSnackbarAction(false));
              }}
            >
              <CrossBigIcon size={10} />
            </IconButton>
          </Box>
        )}

        <Box sx={cl.titleWrapper}>
          <Typography fontSize={14} fontWeight={400} color="#000">
            Order fulfilment in process
          </Typography>

          <Typography fontSize={14} fontWeight={400} color="#000">
            {progress} of {countOrders}
          </Typography>
        </Box>

        <Box width="100%">
          <BorderLinearProgress
            height={9}
            variant="determinate"
            value={progressValue}
          />
        </Box>
      </Box>
    </Snackbar>
  );
};
