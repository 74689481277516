import React from "react";
/* eslint-disable react/prop-types */
export const ArchiveIconCustomer = () => {
  return (
    <svg
      id="Icon_feather-archive"
      dataname="Icon feather-archive"
      xmlns="http://www.w3.org/2000/svg"
      width="9.74"
      height="7.969"
      viewBox="0 0 9.74 7.969"
    >
      <path
        id="Path_2377"
        dataname="Path 2377"
        d="M12.469,12v5.756H4.5V12"
        transform="translate(-3.615 -9.786)"
        fill="#3f3f3f"
      />
      <path
        id="Path_2378"
        dataname="Path 2378"
        d="M1.5,4.5h9.74V6.714H1.5Z"
        transform="translate(-1.5 -4.5)"
        fill="#3f3f3f"
      />
      <path
        id="Path_2379"
        dataname="Path 2379"
        d="M15,18h1.771"
        transform="translate(-11.015 -14.015)"
        fill="#3f3f3f"
      />
    </svg>
  );
};
