import { ListItemText, MenuItem, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { SmallCheckbox } from "../../../../../Checkboxes";
import { SearchSelect } from "../../../../../Selects";
import { array, func, string } from "prop-types";
import StyledTooltip from "../../../../../StyledTooltip";
import { useState } from "react";

const SelectTable = ({ selectedReps, setSelectedReps, repsList, repLabel }) => {
  const [openTooltip, setOpenTooltip] = useState(false);
  return (
    <Box mt="20px" border="1px solid #F0F0F0" borderRadius="4px 4px 0 0">
      <Box backgroundColor="#EEEEEE" p="10px 32px 9px 32px" height="39px">
        <Typography color="cancel.main">{repLabel} Representatives</Typography>
      </Box>
      <Box p="14.5px 35px 21.5px 32px">
        <StyledTooltip
          key={1}
          title="No reps found in the system"
          placement="top-start"
          sx={{
            "& .MuiTooltip-arrow": {
              left: "10%!important",
              transform: "translateX(-10%)!important",
            },
          }}
          arrow
          open={openTooltip && !repsList?.length}
          onOpen={() => setOpenTooltip(true)}
          onClose={() => setOpenTooltip(false)}
        >
          <Box>
            <SearchSelect
              fullWidth
              disabled={!repsList?.length}
              label={`${repLabel} Representatives`}
              multiselect
              chipSx={{
                height: "20px",
                border: "0.5px solid #D4D4D4",
                borderRadius: "10px",
                backgroundColor: "#FAFAFB",
              }}
              value={selectedReps || []}
              onChange={(e) => {
                const val = e.target.value;
                if (val.length < selectedReps.length)
                  return setSelectedReps(val);
                const newRep = val[val.length - 1];
                if (selectedReps.some((rep) => rep.id === newRep.id))
                  return setSelectedReps(
                    selectedReps.filter((rep) => rep.id !== newRep.id)
                  );
                setSelectedReps(val);
              }}
              handleDelete={(id) =>
                setSelectedReps(selectedReps.filter((rep) => rep.id !== id))
              }
            >
              {repsList.map((rep) => {
                return (
                  <MenuItem key={rep.id} value={rep}>
                    <SmallCheckbox
                      checked={
                        !!selectedReps?.some((listRep) => listRep.id === rep.id)
                      }
                    />
                    <ListItemText primary={rep.name} />
                  </MenuItem>
                );
              })}
            </SearchSelect>
          </Box>
        </StyledTooltip>
      </Box>
    </Box>
  );
};

SelectTable.propTypes = {
  selectedReps: array,
  setSelectedReps: func,
  repsList: array,
  repLabel: string,
};

export default SelectTable;
