import {
  SET_CURRENT_USER,
  DELETE_CURRENT_USER,
  GET_DISTRIBUTOR_PAYMENT_CARDS,
  START_LOADING_CURRENT_USER,
  END_LOADING_CURRENT_USER,
  SET_REPRESENTATIVE_USER,
} from "../actions/auth";

const initialState = {
  currentUser: null,
  distributorPaymentCards: [],
  loading: false,
  representative: null,
};

const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CURRENT_USER:
      return { ...state, currentUser: { ...state.currentUser, ...payload } };
    case DELETE_CURRENT_USER:
      return { ...state, currentUser: {} };
    case GET_DISTRIBUTOR_PAYMENT_CARDS:
      return { ...state, distributorPaymentCards: payload };

    case SET_REPRESENTATIVE_USER:
      return {
        ...state,
        representative: { ...state.representative, ...payload },
      };

    case START_LOADING_CURRENT_USER:
      return { ...state, loading: true };
    case END_LOADING_CURRENT_USER:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default authReducer;
