import { useMemo } from "react";
import { object, bool } from "prop-types";
import { Box } from "@mui/material";

import { cl } from "./OrderDetailsTableItem.styles";
import {
  AvailableBlock,
  BarcodeBlock,
  CostPerUnitBlock,
  CounterBlock,
  DiscountBlock,
  NameBlock,
  PictureBlock,
  QuantityBlock,
  TotalBlock,
} from "./components";

const OrdersDetailsTableItem = ({ product, hasThirdParty, hasDiscount }) => {
  const productName = product?.name;
  const productSku = product?.sku || "-";
  const productColor = product?.color;
  const productSize = product?.size;

  const { quantity, manufacturerDiscountDuplicates, isFreeCase, totalAmount } =
    product;

  const freeCaseQuantity = useMemo(
    () =>
      manufacturerDiscountDuplicates.find((d) => !!d.quantity)?.quantity || 0,
    [manufacturerDiscountDuplicates]
  );

  const listPriceCase = (product?.price).toFixed(2);

  const calcTotal = useMemo(() => {
    // Qty * Cost per case = total [SD-1947]
    // return separateNumWithComma(quantity * price);
    if (
      totalAmount &&
      typeof totalAmount === "number" &&
      !isNaN(totalAmount) &&
      totalAmount > 0
    ) {
      return totalAmount.toFixed(2);
    }
    return "";
  }, [totalAmount]);

  const slicedSku = useMemo(() => {
    const slicedSkuName = productSku?.slice(0, 24);
    return productSku?.length > slicedSkuName?.length
      ? `${slicedSkuName}...`
      : productSku;
  }, [productSku]);

  const discount = useMemo(() => {
    if (
      product?.isFreeCase &&
      typeof product?.amount === "number" &&
      !isNaN(product?.amount) &&
      product?.amount > 0
    ) {
      return (quantity * product?.amount).toFixed(2);
    }

    if (
      typeof product?.itemDiscountValue === "number" &&
      !isNaN(product?.itemDiscountValue) &&
      product?.itemDiscountValue > 0
    )
      return (quantity * product?.itemDiscountValue).toFixed(2);

    return "";
  }, [
    product?.isFreeCase,
    product?.itemDiscountValue,
    product?.amount,
    quantity,
  ]);

  return (
    <Box sx={cl.mainWrapper}>
      <CounterBlock />

      <PictureBlock productPhoto={product?.photo} />

      <NameBlock
        productName={productName}
        productSku={slicedSku}
        productSize={productSize}
        productColor={productColor}
      />

      <BarcodeBlock
        hasThirdParty={hasThirdParty}
        productBarcode={product?.barcode}
      />

      <AvailableBlock {...{ hasThirdParty, product }} />

      <QuantityBlock
        hasThirdParty={hasThirdParty}
        productQuantity={quantity - freeCaseQuantity}
      />

      <CostPerUnitBlock
        {...{ hasThirdParty, listPriceCase, isFreeCase }}
        productItemsPerCase={product?.itemsPerCase}
      />

      {hasDiscount && <DiscountBlock {...{ discount }} />}

      <TotalBlock {...{ hasThirdParty, calcTotal, isFreeCase }} />

      <Box sx={cl.empty} />
    </Box>
  );
};

OrdersDetailsTableItem.propTypes = {
  product: object,
  hasThirdParty: bool,
  hasDiscount: bool,
};

OrdersDetailsTableItem.defaultProps = {
  product: null,
  hasThirdParty: false,
  hasDiscount: false,
};

export default OrdersDetailsTableItem;
