import {} from "react";
import { func } from "prop-types";
import { Box, IconButton, Typography } from "@mui/material";
import { CrossIcon } from "../../Icons";

export const DialogHeaderComponent = ({ onClose }) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "49px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        pl: 3,
        borderBottom: "0.5px solid #D5D9D9",
      }}
    >
      <Typography fontSize={24} fontWeight={400} color="#707070">
        Confirm selected plan
      </Typography>
      <IconButton onClick={onClose}>
        <CrossIcon />
      </IconButton>
    </Box>
  );
};

DialogHeaderComponent.propTypes = { onClose: func };
DialogHeaderComponent.defaultProps = { onClose: () => {} };
