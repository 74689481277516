import { Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { number, string, bool } from "prop-types";
import useStyles from "./styles";

const SelectedLabel = ({
  selectedNum,
  selectedPrefix,
  divider,
  isHideText,
}) => {
  const classes = useStyles({ selectedNum });
  return (
    <Box className={classes.animationWrapper}>
      <Box className={classes.wrapper}>
        {divider && (
          <Divider
            orientation="vertical"
            className={classes.divider}
            flexItem
          />
        )}
        <Box className={classes.numberBox}>
          <Typography fontSize="14px" fontWeight={600} color="primary">
            {selectedNum}
          </Typography>
        </Box>
        {isHideText ? null : (
          <Typography
            fontSize="14px"
            fontWeight={600}
            letterSpacing={0.2}
            color="primary"
            ml={1}
          >
            {selectedPrefix}
            SELECTED
          </Typography>
        )}
      </Box>
    </Box>
  );
};

SelectedLabel.propTypes = {
  selectedNum: number,
  selectedPrefix: string,
  divider: bool,
  isHideText: bool,
};

SelectedLabel.defaultProps = {
  selectedNum: 0,
  selectedPrefix: "",
  divider: false,
  isHideText: false,
};

export default SelectedLabel;
