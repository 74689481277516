import { COUNTRY_PHONE_CODES } from "../../utils/constants";

export const defaultValues = {
  distributor: {
    id: "",
    confirmationProgress: "",
    subscriptionForConfirmation: null,
  },
  token: "",

  step: 1,

  email: "",
  password: "",
  confirmPassword: "",

  name: "",
  tax: "",
  billingAddress: {
    appartement: "",
    city: "",
    formatted_address: "",
    state: "",
    street: "",
    zip: "",
  },
  industry: "",
  countryPrefics: COUNTRY_PHONE_CODES[0],
  phone: "",
  website: "",
  timeZone: "",
  country: "",
  profilePhoto: "",
  cards: [],
  editCard: null,
};

export const initialErrPhoneStatus = {
  status: false,
  message: "",
  value: "",
};
