export const PRODUCTS_HEADER = [
  {
    label: "PRODUCT",
    xs: 4,
    style: { pl: 1 },
  },
  {
    label: "LAST ORDER DATE",
    xs: 2.3,
    style: {},
  },
  {
    label: "PRICE",
    xs: 1.1,
    style: {},
  },
  {
    label: "ORDERED QTY",
    xs: 1.2,
    style: { justifyContent: "center" },
  },
];

export const SORTABLE_COLUMNS = {
  PRODUCT: { sortableField: "sort_by_name" },
  "LAST ORDER DATE": { sortableField: "sort_by_last_order_date" },
  "ORDERED QTY": { sortableField: "sort_by_quantity" },
};
