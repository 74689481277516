import { func } from "prop-types";

import { OutlinedPlusIcon } from "components/Icons";

import { cl } from "./styles";

import { IconButton, Stack, Typography } from "@mui/material";

export const PurchasedProductsFilterHeader = ({ handleOpen }) => {
  return (
    <Stack direction="row" alignItems="center" gap={1} mb="10px">
      <IconButton onClick={handleOpen} sx={cl.plusIcon}>
        <OutlinedPlusIcon size={21} circleColor="#707070" plusColor="#47A06D" />
      </IconButton>
      <Typography fontSize={13} color="#5F6267">
        Add products
      </Typography>
    </Stack>
  );
};

PurchasedProductsFilterHeader.propTypes = {
  handleOpen: func,
};

PurchasedProductsFilterHeader.defaultProps = {
  handleOpen: () => {},
};
