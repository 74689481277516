import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  fullscreenDialog: {
    minWidth: "95%",
    maxWidth: "95%",
    height: "100%",
    overflow: "visible",
    maxHeight: "95%",
    marginTop: "0.5%",
  },

  closeButton: {
    position: "absolute",
    top: "1.5%",
    right: "1%",
    zIndex: "1",
    background: "rgba(256, 256, 256, 0.5)",
  },

  customPaging: {
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    border: "1px solid #5F6267",
  },

  arrow: {
    top: "0",
    zIndex: "10",
    height: "100%",
    width: "50px",
    display: "flex!important",
    alignItems: "center",
    justifyContent: "center",
  },

  fullscreenDialogContent: {
    overflow: "visible",
    height: "100%",
    padding: 0,

    "& .slick-dots": {
      bottom: "-3px",
      transform: "translateY(100%)",
      height: "25px",
      display: "flex!important",
      justifyContent: "center",
      alignItems: "center",
      "& li": {
        width: "30px",
        height: "25px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: 0,
        background: "rgba(256, 256, 256, 0.5)",
        "&:nth-of-type(1)": {
          borderRadius: "15px 0 0 15px",
        },
        "&:last-of-type": {
          borderRadius: "0 15px 15px 0",
        },
      },

      "& .slick-active .MuiBox-root": {
        backgroundColor: "#5F6267",
      },
    },

    "& .slick-slider": {
      height: "100%",
    },
    "& .slick-list": {
      height: "100%",

      "& div": {
        height: "100%",
      },
    },
    "& .slick-arrow::before": {
      content: '""',
    },
  },

  imageWrapper: {
    display: "flex!important",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },

  backgroundWrapper: {
    top: 0,
    width: "100%",
    height: "100%",
    maxWidth: "100%",
    maxHeight: "100%",
    position: "absolute",
    filter: "blur(70px) brightness(112%)",
  },
}));

export default useStyles;
