import { useState } from "react";
import {
  Box,
  Grid,
  LinearProgress,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { array, func, bool, string, number } from "prop-types";
import useStyles from "./styles";
import { SimpleArrowDownIcon } from "components/Icons";
import { StyledAvatar } from "components";

const RouteProgressDropDown = ({
  setAssignedAnchorEl,
  dropDownItems,
  isOrderList,
  stops,
  setSelectedRepData,
  routeId,
}) => {
  const classes = useStyles();

  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const handleChange = (event) => {
    const e = event.target.value;

    const selected = dropDownItems.find(
      (rep) => rep?.representative?.id === e?.representative?.id
    );

    const representativesIds = dropDownItems.map(
      (rep) => rep?.representative?.id
    );

    const skipFirstRep = e?.index > 0;

    setSelectedRepData(
      skipFirstRep
        ? { selectedRep: selected?.representative, routeId, representativesIds }
        : null
    );
  };

  const toggleSelectOpen = () => {
    setIsSelectOpen(!isSelectOpen);
  };
  const firstItem = dropDownItems?.[0];
  const remainingItems = dropDownItems.slice(1);

  const getRouteProgress = (rep) => {
    const progress = rep?.representative?.routeProgresses?.[0];
    if (!progress) return "Not started";
    return `${progress?._count?.stops} of ${stops} stops`;
  };

  const thirdPartyLabel = (rep) => {
    return rep?.representative?.role === "THIRD_PARTY" ? (
      <Box className={classes.thirdPartyLabel}>3rd Party</Box>
    ) : (
      ""
    );
  };

  if (!dropDownItems.length)
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={{ textAlign: "left", mr: "14px", ml: "44px" }}>
          -
        </Typography>
        <LinearProgress
          variant="determinate"
          value={0}
          className={classes.routeProgress}
          sx={{ m: "0 5px", backgroundColor: "#E9E9E9" }}
        />
        <Typography sx={{ fontSize: "12px", color: "#DBDBDB" }}>
          Unassigned
        </Typography>
      </Box>
    );

  return (
    <Grid
      className={classes.sliderWrapper}
      container
      columns={4}
      sx={{
        position: isOrderList && "absolute",
        top: isOrderList && "-20px",
        left: isOrderList && "-5px",
        width: "416px ",
      }}
    >
      <Box display="flex" alignItems="center" maxHeight="48px">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: remainingItems.length ? "pointer" : "auto",
          }}
          onClick={() => {
            if (!remainingItems.length) return;
            toggleSelectOpen();
          }}
        >
          {remainingItems.length > 0 ? (
            <Typography sx={{ fontSize: "12px", color: "#47A06D", mr: "5px" }}>
              +{remainingItems.length}
            </Typography>
          ) : null}
          {remainingItems.length ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mr: "5px",
                width: "10px",
              }}
            >
              <SimpleArrowDownIcon width="20px" />
            </Box>
          ) : (
            <Box width="34px" />
          )}
        </Box>
        <Box
          sx={{ width: "211px" }}
          key={firstItem.representative?.id || firstItem.representative?.name}
        >
          <Box display="flex" alignItems="center">
            <Grid item>
              {thirdPartyLabel(firstItem)}
              <StyledAvatar
                sx={{ width: 30, height: 30 }}
                name={(firstItem?.representative?.name || "").trim()}
                src={firstItem?.representative?.profilePhoto?.fileName}
                stringStyle={{ fontSize: 12 }}
                onMouseEnter={(e) =>
                  setAssignedAnchorEl(e.target, firstItem.representative, true)
                }
                onMouseLeave={(e) =>
                  setAssignedAnchorEl(e.target, firstItem.representative, false)
                }
              />
            </Grid>

            <Grid item>
              {" "}
              <LinearProgress
                variant="determinate"
                value={
                  (firstItem?.representative?.routeProgresses?.[0]?._count
                    ?.stops /
                    stops) *
                    100 || 0
                }
                className={classes.routeProgress}
                sx={{ m: "0 5px", backgroundColor: "#E9E9E9" }}
              />
            </Grid>
            <Grid item>
              {" "}
              <Typography color="#9C9C94" noWrap>
                {getRouteProgress(firstItem)}
              </Typography>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Select
        open={isSelectOpen}
        onClose={() => setIsSelectOpen(false)}
        value={""}
        onChange={handleChange}
        sx={{ opacity: 0 }}
      >
        {dropDownItems.map((rep, index) => {
          const photo = rep?.representative?.profilePhoto?.fileName;
          const name = (rep?.representative?.name || "").trim();
          return (
            <MenuItem
              key={rep?.representative?.id}
              px="10px"
              value={{ ...rep, index }}
              sx={{
                maxWidth: "700px",
                px: 0,
                "&:hover": { backgroundColor: "#FFF" },
              }}
            >
              <Grid
                sx={{
                  height: "50px",
                  flexWrap: "nowrap",
                  alignItems: "center",
                  "&:hover": {
                    background: "#E9E9E950",
                    borderRadius: "4px",
                    cursor: "pointer",
                  },
                }}
                container
              >
                <Grid pl={3} item xs="auto">
                  {thirdPartyLabel(rep)}
                  <StyledAvatar
                    sx={{ width: 30, height: 30 }}
                    name={name}
                    src={photo}
                    stringStyle={{ fontSize: 12 }}
                  />
                </Grid>

                <Grid item xs>
                  <Typography sx={{ maxWidth: "412px", px: 0.5 }} noWrap>
                    {rep?.representative?.name}
                  </Typography>
                </Grid>

                <Grid item xs="auto">
                  <Box width="106px">
                    <LinearProgress
                      variant="determinate"
                      value={
                        (rep?.representative?.routeProgresses?.[0]?._count
                          ?.stops /
                          stops) *
                          100 || 0
                      }
                      className={classes.routeProgress}
                      sx={{ backgroundColor: "#E9E9E9" }}
                    />
                  </Box>
                </Grid>

                <Grid pl={0.5} pr={1} item xs="auto">
                  <Box width="116px" maxWidth="116px" textAlign="end" pr="16px">
                    <Typography color="#9C9C94" noWrap>
                      {getRouteProgress(rep)}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </MenuItem>
          );
        })}
      </Select>
    </Grid>
  );
};

RouteProgressDropDown.propTypes = {
  setAssignedAnchorEl: func,
  dropDownItems: array,
  isOrderList: bool,
  stops: number,
  setSelectedRepData: func,
  routeId: string,
};

RouteProgressDropDown.propTypes = {
  setAssignedAnchorEl: () => {},
};

export default RouteProgressDropDown;
