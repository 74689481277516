export const parentCustomersLoadingSelector = (state) => state.groups.loading;

export const parentCustomersActiveSelector = (state) =>
  state.groups.groupsActive;
export const parentCustomersActiveCountSelector = (state) =>
  state.groups.groupsActiveCount;

export const parentCustomersInactiveSelector = (state) =>
  state.groups.groupsInactive;
export const parentCustomersInactiveCountSelector = (state) =>
  state.groups.groupsInactiveCount;

export const flatSelector = (state) => state.groups.flat;
export const flatCountSelector = (state) => state.groups.flatCount;

export const groupsGetParamsSelector = (state) =>
  state.groups.parentCustomersParams;
