/* eslint-disable no-useless-escape */
import * as Yup from "yup";

export const validationSchema = () =>
  Yup.object().shape({
    manualDeliveryFee: Yup.bool(),
    deliveryFee: Yup.number()
      .nullable()
      .when("manualDeliveryFee", {
        is: true,
        then: Yup.number()
          .nullable()
          .test("deliveryFeeValidation", "Not valid Delivery Fee", (value) => {
            const NAME_REGEXP = /[0-9]/;
            return NAME_REGEXP.test(value);
          }),
      }),
  });
