import React from "react";
import { string } from "prop-types";

export const ButtonTagIcon = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 13.393 13.397"
    >
      <path
        id="Icon_ionic-ios-pricetag"
        data-name="Icon ionic-ios-pricetag"
        d="M15.845,3.375H11.887a.469.469,0,0,0-.329.134L3.646,11.423a.929.929,0,0,0,0,1.311L7.413,16.5a.929.929,0,0,0,1.311,0l7.91-7.909a.469.469,0,0,0,.134-.329V4.3a.923.923,0,0,0-.923-.928Zm-1.537,3.6a1.03,1.03,0,1,1,.912-.911,1.03,1.03,0,0,1-.912.911Z"
        transform="translate(-3.375 -3.375)"
        fill="#6a6a6a"
      />
    </svg>
  );
};


ButtonTagIcon.propTypes = {
  width: string,
  height: string,
};

ButtonTagIcon.defaultProps = {
  width: "13.393",
  height: "13.393",
};

