import { string } from "prop-types";
import { Box } from "@mui/system";

export const PaymentStatusBilling = ({ str }) => {
  const styles = {
    block: {
      backgroundColor: "#47A06D",
      width: "max-content",
      minWidth: "73px",
      height: "22px",
      padding: 0.5,
      borderRadius: "10px",
      lineHeight: 1,
      fontSize: 10,
      fontWeight: 600,
      color: "#FFFFFF",
      textTransform: "uppercase",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  };

  switch (str) {
    case "PAID":
      return <Box sx={{ ...styles.block, color: "#47A06D" }}>{str}</Box>;

    case "PENDING":
      return (
        <Box
          sx={{
            ...styles.block,
            color: "#F0C401",
            backgroundColor: "rgba(240, 196, 1, 0.1)",
          }}
        >
          UNPAID
        </Box>
      );

    default:
      return <Box sx={styles.block}>{str}</Box>;
  }
};

PaymentStatusBilling.propTypes = { str: string };
