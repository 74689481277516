import { useCallback, useMemo, useRef, useEffect } from "react";
import { bool, func, string } from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import {
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Typography,
  Box,
  Link,
} from "@mui/material";
import { Controller, useForm, useWatch } from "react-hook-form";
import useStyles from "./styles";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema } from "./PaytermComponent.validations";

import { CrossIcon } from "components/Icons";
import { error } from "utils/notifications";
import {
  LargeCheckbox,
  StyledButton,
  StyledTextField,
  StyledTooltip,
} from "components";

import { createPaytermService } from "services/payterms";
import {
  createPaytermAction,
  getPaytermsAction,
  updatePaytermAction,
} from "redux/actions/payterms";

const PaytermComponent = ({
  open,
  onClose,
  isQuickAdd,
  isEdit,
  setValue,
  customName,
  handleFetchPayTerms,
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { state: editTerm } = useLocation();

  const paytermsList = useSelector(({ payterms }) => payterms?.payterms);
  const currentUser = useSelector(({ auth }) => auth?.currentUser || {});
  const allowStoreCreditCardPayments =
    !!currentUser?.allowStoreCreditCardPayments;

  const dispatch = useDispatch();

  const initEditDefaultTerm = useRef(editTerm?.defaultTerm);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    setValue: setValuePayTerm,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      id: editTerm?.id || "",
      name: editTerm?.name || "",
      daysInvoices: editTerm?.daysInvoices || "",
      defaultTerm: editTerm?.defaultTerm || false,
      type: ["STANDARD", "DATE_DRIVEN", "ADVANCE_PAYMENT"].includes(
        editTerm?.type
      )
        ? editTerm?.type
        : "STANDARD",
      status: "ACTIVE",
    },
    resolver: yupResolver(validationSchema()),
  });

  const formField = useWatch({ control });

  const { type } = formField || {};

  const isAdvancedType = useMemo(() => type === "ADVANCE_PAYMENT", [type]);

  useEffect(() => {
    reset({
      id: editTerm?.id || "",
      name: editTerm?.name || "",
      daysInvoices: isAdvancedType ? 0 : editTerm?.daysInvoices ?? "",
      defaultTerm: editTerm?.defaultTerm || false,
      type: ["STANDARD", "DATE_DRIVEN", "ADVANCE_PAYMENT"].includes(
        editTerm?.type
      )
        ? editTerm?.type
        : "STANDARD",
      status: editTerm?.status || "ACTIVE",
    });
  }, [editTerm, isAdvancedType, open, reset]);

  const checkUniqueFailed = useCallback(() => {
    const name = formField.name.trim().toLowerCase();

    return paytermsList
      .find((pterm) => pterm?.name.toLowerCase() === name)
      ?.name.toLowerCase();
  }, [formField.name, paytermsList]);

  const uniqueFailed = useMemo(() => {
    const res = checkUniqueFailed();

    if (editTerm?.name && res === editTerm?.name?.toLowerCase()) return false;
    return res;
  }, [checkUniqueFailed, editTerm?.name]);

  const isDisabled = useMemo(() => {
    return (
      !formField?.name ||
      !formField?.daysInvoices.toString() ||
      errors?.name ||
      errors?.daysInvoices ||
      !!uniqueFailed
    );
  }, [
    errors?.daysInvoices,
    errors?.name,
    formField?.daysInvoices,
    formField?.name,
    uniqueFailed,
  ]);

  const onSubmit = useCallback(
    async (data) => {
      const { id, active, name, ...uploadData } = data;

      uploadData.name = name.trim();

      if (isAdvancedType) delete uploadData.daysInvoices;

      if (!isQuickAdd) {
        if (data.id)
          dispatch(
            updatePaytermAction(data.id, {
              ...uploadData,
              status: data.status,
            })
          );
        else
          dispatch(
            createPaytermAction({
              ...uploadData,
              status: data.status,
            })
          );

        navigate(-1);
      }

      if (isQuickAdd) {
        try {
          const res = await createPaytermService({
            ...uploadData,
          });

          setValue(
            customName ? customName : "paymentTermsId",
            customName ? { ...res, paymentTermsId: res?.id } : res?.id
          );

          if (handleFetchPayTerms) handleFetchPayTerms();
          dispatch(getPaytermsAction());
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err);
          error(err?.response?.data?.message || "Something went wrong.");
        }
        onClose();
      }
    },
    [
      handleFetchPayTerms,
      customName,
      dispatch,
      isAdvancedType,
      isQuickAdd,
      navigate,
      onClose,
      setValue,
    ]
  );

  const isDisableActive = useMemo(
    () => formField?.defaultTerm || isAdvancedType,
    [formField?.defaultTerm, isAdvancedType]
  );

  const isDisablePrimary = useMemo(
    () =>
      (isEdit && initEditDefaultTerm.current) ||
      (isAdvancedType && initEditDefaultTerm.current),
    [isAdvancedType, isEdit]
  );

  useEffect(() => {
    if (formField?.defaultTerm) {
      setValuePayTerm("active", true);
    }
  }, [formField?.defaultTerm, setValuePayTerm]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent
        sx={{
          p: "16px 32px 32px 32px",
          minWidth: "342px",
        }}
      >
        <Grid sx={{ flexDirection: "column" }} container>
          <Grid sx={{ position: "absolute", top: 16, right: 19 }} item>
            <IconButton onClick={() => (isQuickAdd ? onClose() : navigate(-1))}>
              <CrossIcon />
            </IconButton>
          </Grid>
          <Grid item maxWidth="95%">
            <Typography sx={{ fontSize: "30px", color: "#000000" }}>
              {isEdit ? "Edit term" : "Add term"}
            </Typography>
          </Grid>
          <Box
            border="0.5px solid #D5D9D9"
            borderRadius="4px"
            mt="12px"
            p="15px 32px 43px 33px"
          >
            <Grid item>
              <Typography
                mb="8px"
                fontSize="12px"
                fontWeight="400"
                color="#000000"
              >
                Term Name
              </Typography>
              <StyledTooltip
                style={{ zIndex: 1401, whiteSpace: "pre-wrap" }}
                open={!!(uniqueFailed || errors?.name?.type === "character")}
                title={
                  errors?.name?.type === "character"
                    ? errors?.name?.message
                    : "This term already exist"
                }
                placement="top"
                arrow
              >
                <Box component="span">
                  <Controller
                    render={({ field, fieldState: { error } }) => (
                      <Box>
                        <StyledTextField
                          disabled={isAdvancedType}
                          fullWidth
                          size="small"
                          adminIsAllowed
                          InputProps={{
                            className: classes.textInput,
                          }}
                          InputLabelProps={{
                            className: classes.inputLabel,
                          }}
                          error={error ? error.message : ""}
                          {...field}
                        />
                      </Box>
                    )}
                    name="name"
                    control={control}
                  />
                </Box>
              </StyledTooltip>
              <Grid
                sx={{ marginTop: "21px" }}
                item
                display="flex"
                alignItems="center"
              >
                <Typography
                  fontSize="12px"
                  fontWeight="400"
                  color="#000000"
                  whiteSpace="nowrap"
                  mr="9px"
                >
                  Net due in
                </Typography>
                <Controller
                  render={({ field, fieldState: { error } }) => (
                    <StyledTextField
                      disabled={isAdvancedType}
                      fullWidth
                      size="small"
                      placeholder="0"
                      adminIsAllowed
                      InputProps={{
                        className: classes.textInput,
                      }}
                      InputLabelProps={{
                        className: classes.inputLabel,
                      }}
                      error={error ? error.message : ""}
                      {...field}
                    />
                  )}
                  name="daysInvoices"
                  control={control}
                />
                <Typography
                  fontSize="12px"
                  fontWeight="400"
                  color="#000000"
                  ml="10px"
                >
                  days.
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Grid
            sx={{
              marginTop: "22px",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            item
            container
          >
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: 1,
              }}
              item
            >
              {!isQuickAdd ? (
                <Controller
                  render={({ field }) => {
                    return (
                      <LargeCheckbox
                        disabled={isDisableActive}
                        adminIsAllowed
                        formSx={{ ml: "0px" }}
                        sx={{
                          p: 0,
                          opacity: isDisableActive ? 0.5 : 1,
                        }}
                        checked={["ACTIVE", "INACTIVE_FOR_CUSTOMERS"].includes(
                          field.value
                        )}
                        label={
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: 400,
                              color: isDisableActive
                                ? "rgba(28, 28, 25, 0.5)"
                                : "#1C1C19",
                              whiteSpace: "nowrap",
                              pl: "9px",
                            }}
                          >
                            Active
                          </Typography>
                        }
                        {...field}
                        stroke="#5F6267"
                        size={15}
                        onChange={() =>
                          setValuePayTerm(
                            "status",
                            field.value === "INACTIVE" ? "ACTIVE" : "INACTIVE"
                          )
                        }
                      />
                    );
                  }}
                  name="status"
                  control={control}
                />
              ) : null}
              <Controller
                render={({ field }) => (
                  <LargeCheckbox
                    formSx={{ ml: "0px" }}
                    sx={{ p: 0 }}
                    adminIsAllowed
                    label={
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: 400,
                          color: "#1C1C19",
                          whiteSpace: "nowrap",
                          pl: "9px",
                        }}
                      >
                        Inactive for customers only
                      </Typography>
                    }
                    {...field}
                    stroke="#5F6267"
                    size={15}
                    checked={field.value === "INACTIVE_FOR_CUSTOMERS"}
                    onChange={() =>
                      setValuePayTerm(
                        "status",
                        field.value === "INACTIVE_FOR_CUSTOMERS"
                          ? "ACTIVE"
                          : "INACTIVE_FOR_CUSTOMERS"
                      )
                    }
                  />
                )}
                name="status"
                control={control}
              />
              <StyledTooltip
                title={
                  <span style={{ whiteSpace: "pre-wrap" }}>
                    Not available with{" "}
                    <Link
                      color="primary"
                      to="/settings"
                      state={{ tab: "Order Direct" }}
                      component={NavLink}
                    >
                      Credit card
                    </Link>{" "}
                    off
                  </span>
                }
                placement="top"
                arrow
                disableHoverListener={
                  isAdvancedType ? allowStoreCreditCardPayments : true
                }
                sx={{
                  "& .MuiTooltip-arrow": {
                    left: "-90px !important",
                  },
                }}
                PopperProps={{
                  modifiers: [
                    { name: "offset", options: { offset: [-90, -12] } },
                  ],
                }}
              >
                <Box component="span">
                  <Controller
                    render={({ field }) => (
                      <LargeCheckbox
                        disabled={
                          isDisablePrimary ||
                          (!allowStoreCreditCardPayments && isAdvancedType)
                        }
                        formSx={{ ml: "0px" }}
                        sx={{
                          p: 0,
                          opacity:
                            isDisablePrimary ||
                            (!allowStoreCreditCardPayments && isAdvancedType)
                              ? 0.5
                              : 1,
                        }}
                        adminIsAllowed
                        checked={formField.defaultTerm}
                        label={
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: 400,
                              color:
                                isDisablePrimary ||
                                (!allowStoreCreditCardPayments &&
                                  isAdvancedType)
                                  ? "rgba(28, 28, 25, 0.5)"
                                  : "#1C1C19",
                              whiteSpace: "nowrap",
                              pl: "9px",
                            }}
                          >
                            Set as primary
                          </Typography>
                        }
                        {...field}
                        stroke="#5F6267"
                        size={15}
                      />
                    )}
                    name="defaultTerm"
                    control={control}
                  />
                </Box>
              </StyledTooltip>
            </Grid>
            <Grid item>
              <StyledButton
                sx={{ width: 65, height: 28, boxShadow: "none" }}
                className={classes.buttonCancel}
                color="groundLighter"
                label="Cancel"
                onClick={() => (isQuickAdd ? onClose() : navigate(-1))}
              />
              <StyledButton
                disabled={isDisabled}
                sx={{
                  width: 65,
                  height: 28,
                  marginLeft: 1,
                }}
                className={classes.buttonSave}
                variant="contained"
                label="Done"
                onClick={handleSubmit(onSubmit)}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
PaytermComponent.propTypes = {
  isEdit: bool,
  open: bool,
  onClose: func,
  isQuickAdd: bool,
  setValue: func,
  customName: string,
  handleFetchPayTerms: func,
};
PaytermComponent.defaultProps = {
  isEdit: false,
  open: true,
  onClose: () => {},
};

export default PaytermComponent;
