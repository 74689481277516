export const cl = {
  dialog: {
    ".MuiPaper-root": {
      borderRadius: "4px",
      boxShadow: "none",
      width: "100%",
      maxWidth: "398px",
      minWidth: "398px",
      position: "relative",
    },
  },
};
