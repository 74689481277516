import React from "react";
import OrdersDetailsTableItem from "../OrderDetailsTableItem";
import { object, number, bool, array } from "prop-types";

const TableItemWithFreeCases = (props) => {
  const { product, freeCases } = props;
  const attachedFreeCase = product.isFreeCase
    ? null
    : freeCases.find(
        ({ id }) => id === product.id || id === product.product?.id
      );

  return (
    <>
      <OrdersDetailsTableItem {...props} product={product} />
      {attachedFreeCase && (
        <OrdersDetailsTableItem {...props} product={attachedFreeCase} />
      )}
    </>
  );
};

export default TableItemWithFreeCases;

TableItemWithFreeCases.propTypes = {
  product: object,
  index: number,
  discount: number,
  isColorColumnShow: bool,
  isSizeColumnShow: bool,
  hasThirdParty: bool,
  freeCases: array,
};
