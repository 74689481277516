import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import { object, string } from "prop-types";
import {
  AccordionComponent,
  DetailsComponent,
  SummaryComponent,
} from "../../../../../../../../../../../components/AccordionComponent/AccordionComponent";
import {
  BankIcon,
  ShevronIcon,
} from "../../../../../../../../../../../components/Icons";
import PaymentStatus from "../../../../../../../../../../../components/PaymentStatus/PaymentStatus";
import { getFormattedDate } from "../../../../../../../../../../../helpers/helpers";

const PayoutsAccordionItem = ({ payout, timeZone }) => {
  return (
    <AccordionComponent
      item={payout}
      expandedDflt={false}
      sx={{
        m: "0!important",
        border: "none!important",
        "& .MuiAccordionSummary-root.Mui-expanded": {
          backgroundColor: "rgba(238, 238, 238, 0.3)",
          borderBottom: "1px solid #d5d9d9",
          "& ~ .MuiCollapse-root": {
            backgroundColor: "rgba(238, 238, 238, 0.3)",
          },
        },
        "&:not(:last-of-type)": {
          borderBottom: "1px solid #d5d9d9!important",
          borderRadius: "0!important",
        },
      }}
    >
      <SummaryComponent
        item={payout}
        sx={{
          px: 0,
          height: "40px!important",
          minHeight: "40px",
          "& .MuiTypography-root": {
            fontSize: "12px",
          },
        }}
      >
        <Grid container alignItems="center">
          <Grid item xs={4} sx={{ pl: 3.5 }}>
            <Typography>
              {moment(getFormattedDate(payout.createdAt, timeZone)).format(
                "MMM D, YYYY"
              )}
            </Typography>
          </Grid>
          <Grid item xs={1.8}>
            <Typography>
              ${((payout.sums?.amount || payout.amount) / 100).toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={1.8}>
            <Typography>
              -${((payout.sums?.fee || 0) / 100).toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={1.5}>
            <Typography>
              ${((payout.sums?.net || 0) / 100).toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={2.3} display="flex" justifyContent="center">
            <PaymentStatus str={payout.status.toUpperCase()} />
          </Grid>

          <Grid item xs={0.6} display="flex" justifyContent="center">
            <ShevronIcon
              className="my-ico"
              width={12}
              height={6}
              color="#707070"
            />
          </Grid>
        </Grid>
      </SummaryComponent>
      <DetailsComponent>
        {payout.transactions.length ? (
          <Grid
            container
            sx={{
              "& .MuiTypography-root": {
                fontSize: "12px",
              },
            }}
          >
            <Grid item xs={8.4} container>
              <Grid
                container
                item
                sx={{
                  height: "30px",
                  alignItems: "center",
                  borderBottom: "1px solid #d5d9d9",
                }}
              >
                <Grid item xs={3.35} pl="27px">
                  <Typography>Transaction date</Typography>
                </Grid>
                <Grid item xs={2.4}>
                  <Typography>Invoice #</Typography>
                </Grid>
                <Grid item xs={2.55}>
                  <Typography>Gross total</Typography>
                </Grid>
                <Grid item xs={2.55}>
                  <Typography>Fee</Typography>
                </Grid>
                <Grid item xs={1.15}>
                  <Typography>Net total</Typography>
                </Grid>
              </Grid>
              {payout.transactions.map((transaction) => (
                <Grid
                  key={transaction.id}
                  container
                  item
                  sx={{
                    height: "30px",
                    alignItems: "center",
                    "&:not(:last-of-type)": {
                      borderBottom: "1px solid #d5d9d9",
                    },
                  }}
                >
                  <Grid item xs={3.35} pl="27px">
                    <Typography>
                      {moment(
                        getFormattedDate(transaction.date, timeZone)
                      ).format("MMM D, YYYY - h:mm a")}{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs={2.4}>
                    <Typography>#{transaction.orderCustomId}</Typography>
                  </Grid>
                  <Grid item xs={2.55}>
                    <Typography>
                      ${(transaction.amount / 100).toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid item xs={2.55}>
                    <Typography>
                      -${(transaction.fee / 100).toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid item xs={1.15}>
                    <Typography>
                      ${(transaction.net / 100).toFixed(2)}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Grid
              item
              xs={3.6}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Box display="flex" gap="6px" alignItems="center">
                <BankIcon />
                <Typography>
                  {payout.destination.bankName} **** {payout.destination.last4}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        ) : (
          <Typography
            textAlign="center"
            color="#6A6A6A"
            fontWeight="300"
            fontSize="16px"
            lineHeight={4}
          >
            No transactions found
          </Typography>
        )}
      </DetailsComponent>
    </AccordionComponent>
  );
};

PayoutsAccordionItem.propTypes = { payout: object, timeZone: string };

PayoutsAccordionItem.defaultProps = { payout: {} };

export default PayoutsAccordionItem;
