import { useEffect, useState } from "react";
import { object, string, func } from "prop-types";
import { Controller, useWatch } from "react-hook-form";
import moment from "moment-timezone";
import AdapterMoment from "@mui/lab/AdapterMoment";
import { Grid, Paper, Stack, Typography } from "@mui/material";
import IOSSwitch from "../../../../../../components/IOSSwitch/IOSSwitch.jsx";
import { DayButton } from "../../../../../../components/Buttons/index.jsx";
import { StyledTimePicker } from "../../../../../../components/TextFields/TextFields.jsx";
import { days } from "../../../../../SettingsPage/components/SettingsTabs/RepsTab/RepsTab.constants.js";
import { getDistributorNotificationsService } from "../../../../../../services/notifications.js";
import { error } from "../../../../../../utils/notifications.js";
import Loader from "../../../../../../components/Loader/index.jsx";
import { useAdmin } from "helpers/helpers.js";

const TabNotificationsComponent = ({
  control,
  setValue,
  // formField,
  timeZone,
}) => {
  const isAdmin = useAdmin();
  const formField = useWatch({ control });

  const [notificationsLoading, setNotificationsLoading] = useState(false);

  // const { control, handleSubmit, setValue, reset } = useForm({
  //   mode: "onChange",
  //   defaultValues: {
  //     ...defaultValuesNotifications,
  //     ...notifications,
  //   },
  //   resolver: yupResolver(validationSchema()),
  // });

  // const formField = useWatch({
  //   control,
  // });

  // useEffect(() => {
  //   reset({ ...defaultValuesNotifications, ...notifications });
  // }, [notifications, reset]);

  // const [notificationsLoading, setNotificationsLoading] = useState(false);

  useEffect(() => {
    if (formField.notificationSettings.type === "INDIVIDUAL" || !formField.role)
      return;
    setNotificationsLoading(true);
    getDistributorNotificationsService()
      .then((res) => {
        const companyRoleSettings = res.find(
          (notification) => notification.role === formField.role
        );
        const { id, distributorId, role, ...fields } = companyRoleSettings;
        setValue("notificationSettings", fields);
        setNotificationsLoading(false);
      })
      .catch(() => {
        setNotificationsLoading(false);
        error("Can't get notifications.");
      });
  }, [formField.role, setValue, formField.notificationSettings.type]);

  const classes = {
    textInput: {
      height: "32px",
      textTransform: "uppercase",
      "& > input": {
        textTransform: "uppercase",
      },
    },
    grid: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    typography: {
      fontSize: "15px",
      fontWeight: "400",
      color: "#707070",
    },
    innerGrid: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      borderBottom: "1px solid #D5D9D9",
    },
  };

  const handleCheckWeekday = (day) => {
    const fieldDays = [...formField.notificationSettings.days];
    const dayIndex = fieldDays.findIndex((fieldDay) => fieldDay === day);
    if (dayIndex === -1)
      return setValue("notificationSettings.days", [...fieldDays, day]);
    setValue(
      "notificationSettings.days",
      fieldDays.filter((fieldDay) => fieldDay !== day)
    );
  };

  // const onSubmit = (data) => {
  //   if (!data.days.length) return;
  //   const { distributorId, id, role, ...uploadData } = data;
  //   setNotificationsLoading(true);
  //   updateRepNotificationsService(
  //     repId,
  //     data.type === "COMPANY" ? { type: "COMPANY" } : uploadData
  //   )
  //     .then((res) => {
  //       setNotificationsLoading(false);
  //       setNotifications(res.notificationSettings);
  //       success("Notifications updated successfully.");
  //     })
  //     .catch(() => {
  //       setNotificationsLoading(false);
  //       error("Something went wrong");
  //     });
  // };

  return (
    // <form id="repr-profile-form" onSubmit={handleSubmit(onSubmit)}>
    <>
      <Loader isLoading={notificationsLoading} />
      <Stack>
        <Paper
          elevation={0}
          variant="outlined"
          square
          component={Grid}
          container
          height="106px"
          sx={{ border: "unset" }}
        >
          <Grid item xs={1} />
          <Grid item xs={3} sx={classes.grid}>
            <Typography sx={classes.typography}>
              Use company settings
            </Typography>
          </Grid>
          <Grid item xs={8} sx={classes.innerGrid}>
            <Controller
              render={({ field }) => (
                <IOSSwitch
                  disabled={isAdmin}
                  checked={field.value === "COMPANY"}
                  {...field}
                  onChange={() => {
                    if (isAdmin) return;
                    setValue(
                      field.name,
                      field.value === "COMPANY" ? "INDIVIDUAL" : "COMPANY"
                    );
                  }}
                />
              )}
              name="notificationSettings.type"
              control={control}
            />
          </Grid>
        </Paper>
        <Paper
          elevation={0}
          variant="outlined"
          square
          component={Grid}
          container
          height="106px"
          sx={{ border: "unset" }}
        >
          <Grid item xs={1} />
          <Grid item xs={3} sx={classes.grid}>
            <Typography sx={classes.typography}>
              Reminder to clock in at
            </Typography>
          </Grid>
          <Grid item xs={8} sx={classes.innerGrid}>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <StyledTimePicker
                  dateLibInstance={moment}
                  dateAdapter={AdapterMoment}
                  textFieldProps={{ fullWidth: false }}
                  error={error ? error.message : ""}
                  disabled={
                    isAdmin || formField.notificationSettings.type === "COMPANY"
                  }
                  {...field}
                  value={moment.tz(field.value, timeZone)}
                  onChange={(time) => {
                    if (isAdmin) return;
                    const isoTime = new Date(time).toISOString();
                    setValue("notificationSettings.clockInAt", isoTime);
                  }}
                />
              )}
              name="notificationSettings.clockInAt"
              control={control}
            />
            <Controller
              render={({ field }) => (
                <IOSSwitch
                  formSx={{ ml: "4px" }}
                  checked={!!field.value}
                  disabled={
                    isAdmin || formField.notificationSettings.type === "COMPANY"
                  }
                  {...field}
                  onChange={(e) => {
                    if (isAdmin) return;
                    field.onChange(e);
                  }}
                />
              )}
              name="notificationSettings.clockIn"
              control={control}
            />
          </Grid>
        </Paper>
        <Paper
          elevation={0}
          variant="outlined"
          square
          component={Grid}
          container
          height="106px"
          sx={{ border: "unset" }}
        >
          <Grid item xs={1} />
          <Grid item xs={3} sx={classes.grid}>
            <Typography sx={classes.typography}>
              Reminder to clock out at
            </Typography>
          </Grid>
          <Grid item xs={8} sx={classes.innerGrid}>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <StyledTimePicker
                  dateLibInstance={moment}
                  dateAdapter={AdapterMoment}
                  textFieldProps={{ fullWidth: false }}
                  error={error ? error.message : ""}
                  disabled={
                    isAdmin || formField.notificationSettings.type === "COMPANY"
                  }
                  {...field}
                  value={moment.tz(field.value, timeZone)}
                  onChange={(time) => {
                    if (isAdmin) return;
                    const isoTime = new Date(time).toISOString();
                    setValue("notificationSettings.clockOutAt", isoTime);
                  }}
                />
              )}
              name="notificationSettings.clockOutAt"
              control={control}
            />
            <Controller
              render={({ field }) => (
                <IOSSwitch
                  checked={!!field.value}
                  formSx={{ ml: "4px" }}
                  disabled={
                    isAdmin || formField.notificationSettings.type === "COMPANY"
                  }
                  {...field}
                  onChange={(e) => {
                    if (isAdmin) return;
                    field.onChange(e);
                  }}
                />
              )}
              name="notificationSettings.clockOut"
              control={control}
            />
          </Grid>
        </Paper>
        <Paper
          elevation={0}
          variant="outlined"
          square
          component={Grid}
          container
          height="106px"
          sx={{ border: "unset" }}
        >
          <Grid item xs={1} />
          <Grid item xs={3} sx={classes.grid}>
            <Typography sx={classes.typography}>Apply to days</Typography>
          </Grid>
          <Grid item xs={8} sx={{ ...classes.innerGrid, position: "relative" }}>
            {!formField.notificationSettings?.days.length &&
              formField.notificationSettings.type === "INDIVIDUAL" && (
                <Typography position="absolute" bottom="0" color="error">
                  Provide days please.
                </Typography>
              )}
            {days.map((day) => (
              <DayButton
                key={day.id}
                label={day.name}
                disabled={
                  isAdmin || formField.notificationSettings?.type === "COMPANY"
                }
                isChecked={
                  !!formField.notificationSettings?.days.find(
                    (fieldDay) => fieldDay === day.id
                  )
                }
                handleCheckWeekday={() => handleCheckWeekday(day.id)}
              />
            ))}
          </Grid>
        </Paper>
      </Stack>
      {/* </form> */}
    </>
  );
};

TabNotificationsComponent.propTypes = {
  notifications: object,
  repId: string,
  setNotifications: func,
  control: object,
  setValue: func,
  handleSubmit: func,
  timeZone: string,
};

export default TabNotificationsComponent;
