import React from "react";
/* eslint-disable react/prop-types */
export const AddIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9.826"
      height="9.826"
      viewBox="0 0 9.826 9.826"
    >
      <g
        id="Icon_feather-plus"
        data-name="Icon feather-plus"
        transform="translate(-7 -7)"
      >
        <path
          id="Path_2223"
          data-name="Path 2223"
          d="M18,7.5v8.826"
          transform="translate(-6.087)"
          fill="none"
          stroke="#6a6a6a"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_2224"
          data-name="Path 2224"
          d="M7.5,18h8.826"
          transform="translate(0 -6.087)"
          fill="none"
          stroke="#6a6a6a"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};
