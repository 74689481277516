import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema } from "../RegistrationPage.validations";
import { defaultValues } from "../RegistrationPage.constants";
import { showUserPosition } from "services/users";
import { getTimeZonesList } from "helpers/helpers";

export const useFormActions = ({ state }) => {
  const [userCountry, setUserCountry] = useState("US");

  const timeZoneBrowser = useMemo(
    () => Intl.DateTimeFormat().resolvedOptions().timeZone,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userCountry]
  );

  const setTimeZone = useMemo(() => {
    if (timeZoneBrowser.includes("Europe")) return "";

    const timeZoneList = getTimeZonesList(userCountry);

    return (
      timeZoneList.find((tz) => tz?.value === timeZoneBrowser)?.value || ""
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeZoneBrowser, userCountry]);

  const {
    control,
    setValue,
    formState: { errors },
    handleSubmit,
    setError,
    trigger,
    reset,
    clearErrors,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: {
      ...defaultValues,
      email: state?.email,
      country: userCountry,
      timeZone: setTimeZone,
      confirmation_token: state?.confirmation_token,
    },
    resolver: yupResolver(validationSchema()),
  });

  const formField = useWatch({ control });

  const handleUserPosition = useCallback(
    async (position) => {
      const countriesToSave = ["United States", "Canada"];
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;

      const userInfo = await showUserPosition(latitude, longitude);

      const userStateInfo =
        userInfo.results[userInfo.results.length - 1]?.formatted_address;

      if (!countriesToSave.includes(userStateInfo)) return;

      const formattedUserCountry = userStateInfo === "Canada" ? "CA" : "US";

      setUserCountry(formattedUserCountry);
      setValue("country", formattedUserCountry);
    },
    [setValue]
  );

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(handleUserPosition);
    } else {
      // eslint-disable-next-line no-console
      console.log("Geolocation is not supported by this browser.");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleUserPosition, navigator.geolocation]);

  return {
    control,
    setValue,
    errors,
    handleSubmit,
    setError,
    formField,
    trigger,
    reset,
    userCountry,
    clearErrors,
  };
};
