import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { bool, object, func } from "prop-types";

const ChatImage = ({ isCurrentUser, attachment, onClick }) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const img = new Image();
    img.src = attachment.url;
    img.onload = () => setLoading(false);
    img.onerror = () => setLoading(false);
  }, [attachment.url]);

  return (
    <Box display="flex" justifyContent="center">
      <Box
        component="img"
        alt="attachment"
        sx={{
          maxWidth: "436px",
          maxHeight: "300px",
          height: "auto",
          width: "auto",
          minWidth: "200px",
          minHeight: "300px",
          border: `3px solid ${isCurrentUser ? "#F0F0F0" : "#E3F2EC"}`,
          cursor: "pointer",
          filter: loading && "blur(10px)",
          background: "#fff",
        }}
        onClick={onClick}
        src={attachment.url}
      />
    </Box>
  );
};

ChatImage.propTypes = {
  isCurrentUser: bool,
  attachment: object,
  onClick: func,
};

ChatImage.defaultProps = {
  isCurrentUser: false,
  attachment: {},
  onClick: () => undefined,
};

export default ChatImage;
