import { string, any, func } from "prop-types";
import { Box, IconButton, Typography } from "@mui/material";
import { OutlinedPlusIcon } from "components/Icons";

export const WrapperWithBtnBlock = ({ title, labelBtn, children, onAdd }) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
      px={3}
    >
      <Box mb={onAdd ? 0 : 1.5} display="flex" alignItems="center">
        <Typography fontSize={18} fontWeight={600} color="ground.main">
          {title}
        </Typography>
      </Box>

      <Box>{children}</Box>

      {onAdd ? (
        <Box
        // border="0.5px solid blue"
        >
          <Box ml="-8px" display="flex" alignItems="center" gap={1}>
            <IconButton onClick={onAdd}>
              <OutlinedPlusIcon
                size={21}
                circleColor="#707070"
                plusColor="#47A06D"
              />
            </IconButton>
            <Typography fontSize={13} color="#5F6267">
              {labelBtn}
            </Typography>
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

WrapperWithBtnBlock.propTypes = {
  title: string,
  labelBtn: string,
  children: any,
  onAdd: func,
};
WrapperWithBtnBlock.defaultProps = { title: "", labelBtn: "Add" };
