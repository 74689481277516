import { DataGrid } from "@mui/x-data-grid";
import { array, bool, number, func, object } from "prop-types";
import useStyles from "./styles";
import { IMPORT_LIMIT } from "../../ImportTab.constants";
import { useEffect, useState } from "react";

const StyledDataGrid = ({
  rows,
  columns,
  editable,
  rowCount,
  loading,
  onPageChange,
  handleSaveCell,
  page,
  showErrors,
}) => {
  const [paginationPosition, setPaginationPosition] = useState({
    x: 0,
    y: 0,
  });

  const [gridElement, setGridElement] = useState(null);
  const classes = useStyles({
    editable,
    paginationPosition,
    columnsCount: columns.length,
  });

  useEffect(() => {
    if (!gridElement) return;
    const updatePaginationPosition = () => {
      const table = gridElement.getBoundingClientRect();
      const scrollOffset = gridElement.parentNode?.scrollLeft;
      const { x, y, width, height } = table;
      setPaginationPosition({
        x: x + width + scrollOffset,
        y: y + height,
      });
    };

    updatePaginationPosition();

    window.addEventListener("resize", updatePaginationPosition);
    window.addEventListener("scroll", updatePaginationPosition);

    return () => {
      window.removeEventListener("resize", updatePaginationPosition);
      window.removeEventListener("scroll", updatePaginationPosition);
    };
  }, [gridElement, setPaginationPosition]);

  return (
    <DataGrid
      ref={(node) => setGridElement(node)}
      autoHeight
      rows={rows}
      loading={loading}
      rowCount={rowCount}
      disableVirtualization
      columns={columns}
      pageSize={IMPORT_LIMIT}
      rowsPerPageOptions={[IMPORT_LIMIT]}
      disableSelectionOnClick
      page={page || 0}
      onPageChange={(newPage) => onPageChange(newPage)}
      disableColumnSelector
      disableColumnFilter
      paginationMode="server"
      density="compact"
      onCellEditCommit={handleSaveCell}
      getRowClassName={({ row }) => !!row.error && showErrors && "error"}
      getCellClassName={({ row, field }) => {
        const errorCell = Object.keys(row?.validation || {}).some(
          (key) => key === field
        );

        if (field === "DELETE") return "delete";

        return showErrors && errorCell ? "error" : "";
      }}
      classes={{
        columnHeader: classes.columnHeader,
        panelHeader: classes.panelHeader,
        columnHeaders: classes.columnHeaders,
        columnHeaderTitleContainerContent:
          classes.columnHeaderTitleContainerContent,

        columnHeaderTitle: classes.columnHeaderTitle,
        cell: classes.gridCell,
        columnSeparator: classes.columnSeparator,
        virtualScroller: classes.virtualScroller,
        row: classes.gridRow,
        root: classes.gridRoot,
        main: classes.gridMain,
        editInputCell: classes.cellInput,
        "cell--editing": classes.editingCell,
        autoHeight: classes.autoHeight,
        footerContainer: classes.footerContainer,
      }}
      sx={{
        "& .MuiDataGrid-cell": {
          padding: "0 !important",
        },
        "& p": {
          overflow: "hidden !important",
          textOverflow: "ellipsis !important",
          whiteSpace: "nowrap !important",
        },
        "& .MuiDataGrid-columnHeader": {
          maxWidth: "200px !important",
          minWidth: "200px !important",
          width: "200px !important",
          whiteSpace: "nowrap !important",
        },
      }}
    />
  );
};

StyledDataGrid.propTypes = {
  rows: array,
  columns: array,
  editable: bool,
  rowCount: number,
  loading: bool,
  onPageChange: func,
  paginationPosition: object,
  handleSaveCell: func,
  page: number,
  showErrors: bool,
};

StyledDataGrid.defaultProps = {
  rows: array,
  columns: array,
  editable: false,
  rowCount: 0,
  loading: false,
  onPageChange: () => {},
  handleSaveCell: () => {},
  page: 0,
  showErrors: false,
};

export default StyledDataGrid;
