import {
  DELETE_GALLERY,
  END_LOADING_GALLERY,
  END_LOADING_PHOTO_TAGS,
  END_LOADING_PICTURE,
  GALLERY_GET_PARAMS,
  GET_GALLERY,
  GET_GALLERY_COUNT,
  GET_SHOWING_PHOTOS_COUNT,
  SET_PICTURE,
  START_LOADING_GALLERY,
  START_LOADING_PHOTO_TAGS,
  START_LOADING_PICTURE,
  UPDATE_GALLERY,
  REPS_WITH_PHOTO,
  REPS_WITH_PHOTO_PARAMS,
  REPS_WITH_PHOTO_COUNT,
  UPDATE_MULTIPLE_GALLERY,
  GROUP_IDS,
} from "../actions/gallery";

const initialState = {
  gallery: [],
  galleryGetParams: {},
  tagsLoading: false,
  pictureItem: null,
  pictureLoading: false,
  loading: false,
  count: 0,
  showingCount: 0,
  repsWithPhoto: [],
  repsWithPhotoParams: {},
  repsWithPhotoCount: 0,
  groupIds: [],
};

const galleryReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_GALLERY: {
      const { newPhotos, isScrolling } = payload;
      if (isScrolling)
        return { ...state, gallery: [...state.gallery, ...newPhotos] };
      return { ...state, gallery: newPhotos };
    }

    case GET_GALLERY_COUNT:
      return { ...state, count: payload };

    case GET_SHOWING_PHOTOS_COUNT:
      return { ...state, showingCount: payload };

    case UPDATE_GALLERY: {
      const galleryList = [...state.gallery];
      const updateIndex = galleryList.findIndex(
        (gallery) => gallery.id === payload.id
      );
      if (updateIndex >= 0) {
        galleryList.splice(updateIndex, 1, payload);
      }
      return { ...state, gallery: galleryList, loading: false };
    }

    case UPDATE_MULTIPLE_GALLERY: {
      const galleryList = [...state.gallery];

      payload.forEach((updatedItem) => {
        const updateIndex = galleryList.findIndex(
          (gallery) => gallery.id === updatedItem.id
        );

        if (updateIndex >= 0) {
          galleryList.splice(updateIndex, 1, updatedItem);
        }
      });

      return { ...state, gallery: galleryList, loading: false };
    }

    case DELETE_GALLERY: {
      const galleryList = [...state.gallery];
      payload.forEach((id) => {
        const index = galleryList.findIndex((photo) => photo.id === id);
        if (index >= 0) {
          galleryList.splice(index, 1);
        }
      });
      return {
        ...state,
        gallery: galleryList,
        loading: false,
        count: state.count - payload.length,
      };
    }

    case GALLERY_GET_PARAMS:
      return { ...state, galleryGetParams: payload };

    case SET_PICTURE:
      return { ...state, pictureItem: payload };

    case START_LOADING_GALLERY:
      return { ...state, loading: true };

    case END_LOADING_GALLERY:
      return { ...state, loading: false };

    case START_LOADING_PICTURE:
      return { ...state, pictureLoading: true };

    case END_LOADING_PICTURE:
      return { ...state, pictureLoading: false };

    case START_LOADING_PHOTO_TAGS:
      return { ...state, tagsLoading: true };

    case END_LOADING_PHOTO_TAGS:
      return { ...state, tagsLoading: false };

    case REPS_WITH_PHOTO:
      return { ...state, repsWithPhoto: payload };

    case REPS_WITH_PHOTO_PARAMS:
      return { ...state, repsWithPhotoParams: payload };

    case REPS_WITH_PHOTO_COUNT:
      return { ...state, repsWithPhotoCount: payload };

    case GROUP_IDS:
      return { ...state, groupIds: payload };

    default:
      return state;
  }
};

export default galleryReducer;
