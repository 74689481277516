/* eslint-disable no-useless-escape */
import * as Yup from "yup";

export const validationSchema = () =>
  Yup.object().shape({
    products: Yup.array().of(
      Yup.object().shape({
        itemDiscountAmount: Yup.number()
          .transform((v, o) => (o === "" ? null : v))
          .nullable()
          .required("This field is required")
          .typeError("Must be a number.")
          .when("itemDiscountType", {
            is: "PERCENTAGE",
            then: Yup.number()
              .transform((v, o) => (o === "" ? null : v))
              .nullable()
              .test(
                "percentageDiscountValidation",
                "Discount should be 0-100",
                (value) => {
                  return value >= 0 && value <= 100;
                }
              ),
            otherwise: Yup.number()
              .transform((v, o) => (o === "" ? null : v))
              .nullable()
              .test(
                "absoluteNonNegativeValidation",
                "Discount can't be less than zero",
                (value) => {
                  return value >= 0;
                }
              ),
          }),
      })
    ),
  });
