import { useMemo } from "react";
import { func, object, number, bool, string, array } from "prop-types";

import { Box, Grid, Paper, Typography } from "@mui/material";
import { photoUrl } from "../../../../../../../helpers/helpers";
import { StyledProductIco } from "../../../../../../../components/Icons";
import { QauntitySelect } from "./components";

const DeliveryProductsItem = ({
  product,
  products,
  order,
  isEdit,
  deliveredQty,
  discount,
  titleType,
  showTitleForProduct,
  control,
  error,
  setValue,
}) => {
  const isSingleProduct = !product?.product?.parentProduct;

  const isProductFullyDelivered =
    titleType === "Confirm delivery" &&
    product?.totalDelivered === product?.quantity;

  const isParent = useMemo(
    () => isProductFullyDelivered || product?.id === product?.name,
    [isProductFullyDelivered, product?.id, product?.name]
  );

  let isParentDelivered = false;
  const filterParents = products.filter(
    (baseProduct) => baseProduct?.name === product?.id
  );

  if (filterParents.length > 0) {
    const deliveredProducts = filterParents.map(
      (newProduct) => newProduct?.quantity === newProduct?.totalDelivered
    );
    deliveredProducts.map((el) => {
      if (!el) return (isParentDelivered = true);
    });
  }

  const calcAvailableQuantity = useMemo(() => {
    if (titleType === "Edit delivery")
      return (
        product?.quantity +
        1 -
        product?.totalDelivered +
        product?.originalDeliveryProduct?.quantity
      );
    if (titleType === "Confirm delivery")
      return product?.quantity + 1 - (product?.totalDelivered || 0);
    return 1;
  }, [
    product?.originalDeliveryProduct?.quantity,
    product?.quantity,
    product?.totalDelivered,
    titleType,
  ]);

  const productDescription = product?.color || product?.size || "";

  return isParent ? (
    (order.deliveryStatus !== "FULFILLED" ||
      isEdit ||
      titleType === "details") &&
    (titleType === "Confirm delivery" ? isParentDelivered : true) &&
    (product?.id === product?.name ||
      (titleType === "Confirm delivery" &&
        product?.id === product?.name &&
        showTitleForProduct)) ? (
      <Paper
        sx={{
          minHeight: "53px",
          height: "53px",
        }}
        elevation={0}
        square
        component={Grid}
        container
        columns={12}
        maxWidth="100%"
      >
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            pl: "6px",
            backgroundColor: "#F7F7F7",
            borderRadius: "4px",
            mx: "8px",
            my: "4px",
            width: "calc(100% - 16px)",
          }}
        >
          <Grid container wrap="nowrap" alignItems="center">
            <Grid item display="flex" alignItems="center">
              <StyledProductIco
                src={photoUrl(product?.photo?.fileName)}
                styles={{
                  border: "1px solid #d5d9d9",
                  objectFit: "contain",
                  width: "28px",
                  height: "28px",
                  borderRadius: "4px",
                }}
                placeholderStyles={{
                  width: "28px",
                  height: "28px",
                  wrapperProps: { height: "28px" },
                }}
              />
            </Grid>

            <Box pl={1} display="flex" maxWidth="90%">
              <Typography
                fontSize="12px"
                color="#1C1C19"
                fontWeight="500"
                noWrap
              >
                {product?.name}{" "}
                <Box
                  fontSize="12px"
                  color="#1C1C19"
                  fontWeight="300"
                  component="span"
                >{`by ${product?.manufacturerName}`}</Box>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    ) : (
      <></>
    )
  ) : (
    <Paper
      sx={{
        py: 1,
        mt: "1px",
        minHeight: "53px",
        height: "53px",
        cursor: "pointer",
        "&:not(:last-of-type)": {
          borderBottom:
            (product?.isLastEl || isSingleProduct) && "1px solid #D5D9D9",
        },
      }}
      elevation={0}
      square
      component={Grid}
      container
      columns={12}
    >
      <Grid
        item
        xs={5.5}
        sx={{
          display: "flex",
          alignItems: "center",
          pl: "9px",
        }}
      >
        <Grid container wrap="nowrap" alignItems="center">
          <Grid item display="flex" alignItems="center">
            <StyledProductIco
              src={photoUrl(product?.photo?.fileName)}
              styles={{
                border: "1px solid #d5d9d9",
                objectFit: "contain",
                width: "33px",
                height: "33px",
                borderRadius: "4px",
              }}
              placeholderStyles={{
                width: "33px",
                height: "33px",
                wrapperProps: { height: "33px" },
              }}
            />
          </Grid>
          <Grid xs item zeroMinWidth pl={1}>
            <Typography fontSize="12px" color="#1C1C19" fontWeight="500" noWrap>
              {product?.product?.parentProduct
                ? `${product?.sku} ${
                    productDescription && "/"
                  } ${productDescription}`
                : product?.name}
            </Typography>
            <Typography
              color="#1C1C19"
              fontSize="12px"
              fontWeight="400"
              pr={1}
              noWrap
            >
              {product?.product?.parentProduct
                ? ""
                : `by ${product?.manufacturerName}`}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        xs
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography fontSize="12px" fontWeight="400" color="#1C1C19">
          $
          {(product.price - (product.price * (discount || 0)) / 100)?.toFixed(
            2
          )}
        </Typography>
      </Grid>

      <Grid
        item
        xs={3.5}
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {isEdit ? (
          <Grid
            sx={{
              border: `0.5px solid ${error ? "#EB4233" : "#CCCCCC"}`,
              borderRadius: "4px",
              width: "auto",
              height: "26px",
            }}
            container
          >
            <Grid item>
              <QauntitySelect
                {...{ control, titleType, product, calcAvailableQuantity }}
              />
            </Grid>
            <Grid
              sx={{ borderLeft: "1px solid #CCCCCC", height: "25px" }}
              item
            />
            <Grid
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingX: 1,
              }}
              item
            >
              <Typography
                fontWeight="400"
                fontSize="12px"
                color="#000000"
                onClick={() => {
                  if (isEdit) {
                    setValue(
                      `products.${product?.index}.deliverQuantity`,
                      calcAvailableQuantity - 1
                    );
                  }
                }}
              >
                {titleType === "Edit delivery" &&
                  `of ${
                    product?.quantity -
                    product?.totalDelivered +
                    product?.originalDeliveryProduct?.quantity
                  }`}
                {titleType === "Confirm delivery" &&
                  `of ${product?.quantity - (product?.totalDelivered || 0)}`}
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Typography ml="6px" fontWeight="400" fontSize="12px" color="#000000">
            {product.deliverQuantity || deliveredQty} of{" "}
            {product?.product?.quantity || product.quantity}
          </Typography>
        )}
      </Grid>
    </Paper>
  );
};

DeliveryProductsItem.propTypes = {
  product: object,
  products: array,
  order: object,
  quantity: number,
  setValue: func,
  handleRemoveOrderProduct: func,
  discount: number,
  customerSelected: bool,
  isEdit: bool,
  deliveredQty: number,
  titleType: string,
  showTitleForProduct: bool,
  control: object,
  isListMoreThanBlock: bool,
  error: bool,
};

DeliveryProductsItem.defaultProps = {
  product: null,
  products: [],
  quantity: 1,
  discount: 0,
  customerSelected: false,
  isEdit: false,
  deliveredQty: 0,
};

export default DeliveryProductsItem;
