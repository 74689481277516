import axios from "axios";

export const getSubParentCustomersService = async (query) => {
  return axios({
    url: "/customers/parent-customers/with-sub-customers",
    params: { ...query },
  }).then((res) => res.data);
};

export const getCustomerByGroupIdService = async (groupId, query) => {
  return axios({
    url: `/customers/${groupId}/with-sub-customers`,
    params: { ...query },
  }).then((res) => res.data);
};

export const getFlatGroupsService = async (query) => {
  return axios({
    url: "/customers/parent-customers",
    params: { ...query },
  }).then((res) => res.data);
};

export const deleteGroupService = async (customerIds) => {
  return axios
    .delete(`/customers`, {
      data: { customerIds },
    })
    .then((res) => res.data);
};

export const getSubCustomersByParentCustomerIdService = async ({
  params,
  parentCustomerId,
}) => {
  return axios({
    url: `/customers/sub-customers/${parentCustomerId}`,
    params: { ...params },
  }).then((res) => res.data);
};
