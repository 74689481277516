import * as React from "react";
export const AlarmClockIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16.5}
    height={16.5}
    viewBox="0 0 17.135 17.135"
    {...props}
  >
    <path
      data-name="Icon ionic-md-alarm"
      d="M17.135 3.316 13.194.047 12.08 1.361l3.941 3.274Zm-12.123-2L3.898 0l-3.9 3.316 1.116 1.318Zm3.984 3.912H7.711v5.1l4.069 2.424.643-1.063-3.427-2V5.23Zm-.428-3.4a7.654 7.654 0 1 0 7.711 7.653 7.677 7.677 0 0 0-7.711-7.653m0 13.606a5.953 5.953 0 1 1 6-5.953 5.99 5.99 0 0 1-6 5.953"
      fill="#9e9e9e"
    />
  </svg>
);
