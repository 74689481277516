import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  pageWrapper: {
    display: "flex",
    justifyContent: "center",
  },

  reportsPaper: {
    marginTop: "55px",
    padding: "31px 54px",
    overflowY: "overlay",
    overflowX: "hidden",
    width: "1176px",
  },

  titleWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

export default useStyles;
