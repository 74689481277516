export const FlagIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8.2"
      height="9.156"
      viewBox="0 0 8.2 9.156"
    >
      <path
        id="Path_1115"
        className="skipstroke"
        data-name="Path 1115"
        d="M7.608.6A3.44,3.44,0,0,1,5.8,1.151,3.7,3.7,0,0,1,3.978.59,3.952,3.952,0,0,0,2.038,0C.466,0,.086.392.048.437L0,.494V6.067l.228-.142a3.7,3.7,0,0,1,1.81-.363A4.1,4.1,0,0,1,3.5,5.884a6.228,6.228,0,0,0,2.3.446,3.751,3.751,0,0,0,1.959-.6l.079-.055V.445Z"
        transform="translate(0.36 0.077)"
      />
      <path
        id="Path_1116"
        data-name="Path 1116"
        d="M1,3v8.156"
        transform="translate(-0.5 -2.5)"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
    </svg>
  );
};
