export const cl = {
  dialog: {
    ".MuiPaper-root": {
      borderRadius: "18px",
      boxShadow: "none",
      width: "100%",
      maxWidth: "964px",
      minWidth: "964px",
    },
  },
};
