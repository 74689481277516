import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Routes,
  Route,
  useSearchParams,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import {
  isMobile,
  isDesktop,
  isTablet,
  useMobileOrientation,
} from "react-device-detect";

import Loader from "./components/Loader";
import PrivateRoute from "./components/PrivateRoute";
import MasterPage from "./Pages/MasterPage/MasterPage";
import NewOrdersPage from "./Pages/OrdersPage/NewOrderPage";
import ConfirmAccountPage from "./Pages/ConfirmAccountPage";
import CatalogNewProductPage from "./Pages/CatalogPage/CatalogNewProductPage";
import RepresentativesPage from "./Pages/RepresentativesPage";
import NotFoundPage from "./Pages/NotFoundPage/index.jsx";
import { getCurrentUser, getTokenFromLocalStorage } from "./helpers/auth";
import authActions from "./redux/actions/auth";
import ProfileComponent from "./Pages/RepresentativesPage/components/ProfileComponent";
import NewTagComponent from "./components/NewTagComponent/NewTagComponent";
import ManufacturerPage from "./Pages/CatalogPage/ManufacturersTab/ManufacturerPage/ManufacturerPage";
import { forbiddenUrls, TAGS_TYPE } from "./utils/constants";
import SettingsPage from "./Pages/SettingsPage/SettingsPage";
import PaytermComponent from "./Pages/SettingsPage/components/SettingsTabs/PaytermsTab/PaytermComponent";
import ConversationsPage from "./Pages/ConversationsPage/ConversationsPage";
import ChatRoom from "./Pages/ConversationsPage/ChatRoom/ChatRoom";
import NewDiscountPage from "./Pages/DiscountsPage/components/NewDiscountPage/NewDiscountPage";
import ApplyDiscountPage from "./Pages/DiscountsPage/components/ApplyDiscountPage/ApplyDiscountPage";
import PasswordUpdatedPage from "./Pages/SetNewPasswordPage/PasswordUpdatedPage";
import ManageCustomersPage from "./Pages/ManageCustomersPage";
import RoutesPage from "./Pages/RoutesPage/RoutesPage";
import CreateRoutePage from "./Pages/CreateRoutePage";
import Oauth2 from "./Pages/Oauth2/Oauth2";
import { StatusPage } from "./Pages/StatusPage/StatusPage";
import { InfoPage } from "./Pages/InfoPage/InfoPage";
import { getDraftsAction } from "./redux/actions/drafts";
import {
  useLogInRedirect,
  useOnBoardingTasks,
  useRepsPermissions,
} from "./helpers/hooks";
import LinkWidgetPage from "Pages/LinkWidgetPage/LinkWidgetPage";
import {
  CartPage,
  CatalogPage,
  CustomerProfilePage,
  CustomerPage,
  ViewRoutePage,
  PaymentPage,
  ResetPasswordPage,
  SetNewPasswordPage,
  ReportsPage,
  DiscountsPage,
  OrdersPage,
  FolderPage,
  RegistrationPage,
  LoginPage,
  CustomersPage,
  DashboardPage,
  ViewAdminPage,
  PaymentLinkPage,
  TasksPage,
} from "Pages";
import { MobileAppPromptPage } from "components";
import "react-nestable/dist/styles/index.css";
import { createSelector } from "reselect";
import {
  confirmDialogFormChangedSelector,
  editTypeSelector,
} from "redux/selectors/confirmDialogs";

const selector = createSelector(
  confirmDialogFormChangedSelector,
  editTypeSelector,
  (formChanged, editType) => ({
    formChanged,
    editType,
  })
);

const App = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { formChanged, editType } = useSelector(selector);

  const [searchParams] = useSearchParams();
  const { handleSetLocalStoragePaths } = useLogInRedirect();
  const confirmation_token = searchParams.get("confirmation_token");
  const email = searchParams.get("email");
  const representative = searchParams.get("representative");

  const { pathname } = useLocation();

  const repPermissions = useRepsPermissions();

  const isWidgetView = useMemo(
    () => pathname.split("/")[1] === "widgets",
    [pathname]
  );

  useEffect(() => {
    if (confirmation_token && email && representative === "false") {
      return navigate("/registration", {
        state: { confirmation_token, email },
      });
    }
  }, [confirmation_token, email, navigate, representative]);

  const currentUser = useSelector(({ auth }) => auth?.currentUser);

  const { onBoardingTasks } = useOnBoardingTasks();

  const checkForbiddenUrls = useCallback((url) => {
    return forbiddenUrls.includes(url);
  }, []);

  useEffect(() => {
    if (onBoardingTasks.shopping_cart && !isWidgetView) {
      const getUserRole = JSON.parse(localStorage.getItem("userRole"));

      if (getUserRole !== "DISTRIBUTOR" && !repPermissions) return;

      if (repPermissions && !repPermissions?.orders?.create_edit) return;

      return dispatch(getDraftsAction());
    }

    if (repPermissions) {
      if (repPermissions?.orders?.create_edit) {
        return dispatch(getDraftsAction());
      }
    }
  }, [dispatch, isWidgetView, onBoardingTasks?.shopping_cart, repPermissions]);

  const createAxiosRequestInterceptor = useCallback(() => {
    const interceptor = axios.interceptors.request.use(
      (config) => {
        const controller = new AbortController();
        if (
          !(currentUser?.onboarding === null) &&
          checkForbiddenUrls(config?.url)
        ) {
          controller.abort();
        }
        return {
          ...config,
          signal: controller.signal,
        };
      },

      (err) => {
        axios.interceptors.response.eject(interceptor);
        return Promise.reject(err);
      }
    );
  }, [checkForbiddenUrls, currentUser?.onboarding]);

  useEffect(() => {
    if (currentUser) createAxiosRequestInterceptor();
  }, [createAxiosRequestInterceptor, currentUser]);

  const [isLoading, setLoadingStatus] = useState(true);

  const setCurrentUser = (user) => {
    dispatch(authActions.setCurrentUser(user));
  };

  useEffect(() => {
    const token = getTokenFromLocalStorage();

    if (token && !isWidgetView) {
      getCurrentUser({
        navigate,
        setCurrentUser,
        onSuccess: () => setLoadingStatus(false),
        onFail: () => setLoadingStatus(false),
      });
      return;
    }

    setLoadingStatus(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWidgetView]);

  useEffect(handleSetLocalStoragePaths, [handleSetLocalStoragePaths]);

  useEffect(() => {
    const handleBeforeunload = (e) => {
      if (formChanged && editType) {
        e.preventDefault();
        e.returnValue = "";
        return "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeunload);

    return () => window.removeEventListener("beforeunload", handleBeforeunload);
  }, [editType, formChanged]);

  const { isLandscape } = useMobileOrientation();

  const mobile = useMemo(() => {
    if (isDesktop || (isTablet && isLandscape)) return false;

    return isMobile;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop, isTablet, isLandscape, isMobile]);

  if (
    mobile &&
    ![
      "/confirm-account",
      "/info",
      "/restore-password",
      "/password-updated",
    ].includes(pathname) &&
    !isWidgetView
  )
    return <MobileAppPromptPage />;

  return isLoading ? (
    <Loader isLoading={isLoading} />
  ) : (
    <Routes>
      <Route
        path="/"
        element={
          <PrivateRoute>
            <MasterPage />
          </PrivateRoute>
        }
      >
        <Route
          index
          element={
            <PrivateRoute>
              <DashboardPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/orders"
          element={
            <PrivateRoute>
              <OrdersPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/orders/new"
          element={
            <PrivateRoute>
              <NewOrdersPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/orders/:orderId"
          element={
            <PrivateRoute>
              <NewOrdersPage isEdit />
            </PrivateRoute>
          }
        />
        <Route
          path="/orders/drafts/:orderId"
          element={
            <PrivateRoute>
              <NewOrdersPage isDraftOrder isEdit />
            </PrivateRoute>
          }
        />
        <Route
          path="/catalog"
          element={
            <PrivateRoute>
              <CatalogPage />
            </PrivateRoute>
          }
        >
          <Route
            path="tags/new"
            element={
              <PrivateRoute>
                <NewTagComponent
                  type={TAGS_TYPE.PRODUCT}
                  navigatePath="/catalog"
                  navigateState="Product Tags"
                />
              </PrivateRoute>
            }
          />
          <Route
            path="tags/:id"
            element={
              <PrivateRoute>
                <NewTagComponent
                  type={TAGS_TYPE.PRODUCT}
                  navigatePath="/catalog"
                  navigateState="Product Tags"
                />
              </PrivateRoute>
            }
          />
          {/* <Route
            path="category/new"
            element={
              <PrivateRoute>
                <CategoryComponent />
              </PrivateRoute>
            }
          /> */}
          {/* <Route
            path="category/:id"
            element={
              <PrivateRoute>
                <CategoryComponent isEdit />
              </PrivateRoute>
            }
          /> */}
        </Route>
        <Route
          path="/catalog/new"
          element={
            <PrivateRoute>
              <CatalogNewProductPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/catalog/:productId"
          element={
            <PrivateRoute>
              <CatalogNewProductPage isEdit />
            </PrivateRoute>
          }
        />
        <Route
          path="/catalog/manufacturers/new"
          element={
            <PrivateRoute>
              <ManufacturerPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/catalog/manufacturers/:manufacturerId"
          element={
            <PrivateRoute>
              <ManufacturerPage isEdit />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/customers"
          element={
            <PrivateRoute>
              <CustomersPage />
            </PrivateRoute>
          }
        >
          <Route
            path="tags/new"
            element={
              <PrivateRoute>
                <NewTagComponent
                  type={TAGS_TYPE.CUSTOMER}
                  navigatePath="/customers"
                  navigateState="Customer Tags"
                />
              </PrivateRoute>
            }
          />
          <Route
            path="tags/:id"
            element={
              <PrivateRoute>
                <NewTagComponent
                  type={TAGS_TYPE.CUSTOMER}
                  navigatePath="/customers"
                  navigateState="Customer Tags"
                />
              </PrivateRoute>
            }
          />
          <Route
            path="folder/:folderId"
            element={
              <PrivateRoute>
                <FolderPage />
              </PrivateRoute>
            }
          />
          <Route
            path="manage/:fileId"
            element={
              <PrivateRoute>
                <ManageCustomersPage />
              </PrivateRoute>
            }
          />
        </Route>
        <Route
          path="/customers/new"
          element={
            <PrivateRoute>
              <CustomerPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/customers/:customerId"
          element={
            <PrivateRoute>
              <CustomerProfilePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/customers/edit/:customerId"
          element={
            <PrivateRoute>
              <CustomerPage isEdit />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/representatives"
          element={
            <PrivateRoute>
              <RepresentativesPage />
            </PrivateRoute>
          }
        >
          <Route
            path="new"
            element={
              <PrivateRoute>
                <ProfileComponent isOpenProfileDialog />
              </PrivateRoute>
            }
          />
        </Route>
        <Route
          path="/discounts"
          element={
            <PrivateRoute>
              <DiscountsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/discounts/new"
          element={
            <PrivateRoute>
              <NewDiscountPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/cart/discount/:type"
          element={
            <PrivateRoute>
              <ApplyDiscountPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/discounts/:discountId"
          element={
            <PrivateRoute>
              <NewDiscountPage />
            </PrivateRoute>
          }
        />

        <Route
          path="/tasks"
          element={
            <PrivateRoute>
              <TasksPage />
            </PrivateRoute>
          }
        />

        <Route
          path="/routes"
          element={
            <PrivateRoute>
              <RoutesPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/routes/new"
          element={
            <PrivateRoute>
              <CreateRoutePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/routes/edit/:routeId"
          element={
            <PrivateRoute>
              <CreateRoutePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/routes/view/:routeId"
          element={
            <PrivateRoute>
              <ViewRoutePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/reports"
          element={
            <PrivateRoute>
              <ReportsPage />
            </PrivateRoute>
          }
        />

        <Route
          path="/cart"
          element={
            <PrivateRoute>
              <CartPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/cart/:orderId"
          element={
            <PrivateRoute>
              <CartPage />
            </PrivateRoute>
          }
        />

        <Route
          path="/cart/duplicate/:orderId"
          element={
            <PrivateRoute>
              <CartPage isDuplicate />
            </PrivateRoute>
          }
        />

        <Route
          path="/cart/draft/:orderId"
          element={
            <PrivateRoute>
              <CartPage isDraftOrder />
            </PrivateRoute>
          }
        />

        <Route
          path="/settings"
          element={
            <PrivateRoute>
              <SettingsPage />
            </PrivateRoute>
          }
        >
          <Route
            path="payterm/new"
            element={
              <PrivateRoute>
                <PaytermComponent />
              </PrivateRoute>
            }
          />
          <Route
            path="payterm/:paytermId"
            element={
              <PrivateRoute>
                <PaytermComponent isEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="tags/new"
            element={
              <PrivateRoute>
                <NewTagComponent
                  type={TAGS_TYPE.ORDERS}
                  navigatePath="/settings"
                  navigateState="tags"
                />
              </PrivateRoute>
            }
          />
          <Route
            path="tags/:id"
            element={
              <PrivateRoute>
                <NewTagComponent
                  type={TAGS_TYPE.ORDERS}
                  navigatePath="/settings"
                  navigateState="tags"
                />
              </PrivateRoute>
            }
          />
        </Route>
        <Route
          path="/conversations"
          element={
            <PrivateRoute>
              <ConversationsPage />
            </PrivateRoute>
          }
        >
          <Route
            path=":roomId"
            element={
              <PrivateRoute>
                <ChatRoom />
              </PrivateRoute>
            }
          />
        </Route>
      </Route>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/reset" element={<ResetPasswordPage />} />
      <Route path="/registration" element={<RegistrationPage />} />
      <Route path="/confirm-account" element={<ConfirmAccountPage />} />
      <Route path="/restore-password" element={<SetNewPasswordPage />} />
      <Route path="/password-updated" element={<PasswordUpdatedPage />} />
      <Route path="/oauth2/login" element={<Oauth2 />} />
      <Route path="/status" element={<StatusPage />} />
      <Route path="/info" element={<InfoPage />} />
      <Route path="/widgets/:size" element={<LinkWidgetPage />} />
      <Route path="/payment" element={<PaymentPage />} />
      <Route path="/view-admin/:token" element={<ViewAdminPage />} />
      <Route path="/payment-link/:id" element={<PaymentLinkPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default App;
