import { Box, Typography } from "@mui/material";
import { CheckmarkIcon } from "../../../../../../../../../components/Icons";
import StyledButton from "../../../../../../../../../components/StyledButton";
import { SETUP_STEPS } from "./CompleteSetup.constants";
import useStyles from "./styles";
import { bool, func } from "prop-types";
import { usePersonalDetails } from "../../PayoutsTab.hooks";
import { useCallback, useMemo } from "react";
import { useAdmin } from "helpers/helpers";

const CompleteSetup = ({ hasBank, hasPersonalDetails, handleAction }) => {
  const isAdmin = useAdmin();
  const { statusProps } = usePersonalDetails({ isSetup: true });
  const { icon, color } = useMemo(() => statusProps || {}, [statusProps]);

  const classes = useStyles();
  const setupStatus = {
    personalDetails: hasPersonalDetails,
    banking: hasBank,
  };

  const showStatusWithoutValidation = useCallback(
    (step) => {
      const isBanking = step.type === SETUP_STEPS[1].type;
      const isPersonalDetails =
        step.type === SETUP_STEPS[0].type && !hasPersonalDetails;
      return isBanking || isPersonalDetails;
    },
    [hasPersonalDetails]
  );

  return (
    <Box className={classes.completeWrap}>
      <Typography className={classes.completeTitle}>
        Complete account setup to start accepting payments.
      </Typography>
      <Box className={classes.stepsWrap}>
        {SETUP_STEPS.map((step) => (
          <Box
            key={step.type}
            className={classes.rowWrap}
            data-testid="payments-setup-step"
          >
            <Box
              sx={{
                background: showStatusWithoutValidation(step)
                  ? setupStatus[step.type]
                    ? "#47A06D"
                    : "#AFAFAF"
                  : color,
              }}
              className={classes.iconWrap}
            >
              {showStatusWithoutValidation(step) ? (
                <CheckmarkIcon stroke="#fff" width="11.34" height="8.33" />
              ) : (
                icon
              )}
            </Box>
            <Box className={classes.innerWrap}>
              <Box>
                <Typography className={classes.rowTitle}>
                  {setupStatus[step.type] ? step.successTitle : step.title}
                </Typography>
                {!setupStatus[step.type] && (
                  <Typography className={classes.description}>
                    {step.description}
                  </Typography>
                )}
              </Box>
              {setupStatus[step.type] ? (
                <StyledButton
                  disabled={isAdmin}
                  className={classes.goButton}
                  label="Update"
                  variant="outlined"
                  fontSize="16px"
                  color="edit"
                  onClick={() => handleAction(step.type)}
                />
              ) : (
                <StyledButton
                  disabled={isAdmin}
                  className={classes.goButton}
                  label="Go"
                  variant="contained"
                  fontSize="16px"
                  onClick={() => handleAction(step.type)}
                />
              )}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

CompleteSetup.propTypes = {
  hasBank: bool,
  hasPersonalDetails: bool,
  handleAction: func,
};

export default CompleteSetup;
