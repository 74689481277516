import { CircularProgress, LinearProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { object, func, bool, shape, string } from "prop-types";
import { useEffect, useMemo } from "react";
import StyledButton from "../../../../../../../components/StyledButton";
import {
  useAdmin,
  normalizeEveryFirstLetterToUpper,
} from "../../../../../../../helpers/helpers";
import useStyles from "./styles";
import { useIntegrationCard } from "../../Integrations.hooks";
import { StyledTooltip } from "components";

const IntegrationsCard = ({
  integration,
  onEnable,
  onDisable,
  loading,
  isConnected,
  syncStatus,
  onSync,
  hasSync,
  disabled,
  tooltipProps,
  progressBar,
}) => {
  const isAdmin = useAdmin();
  const { loading: loadingSync, setLoading: setLoadingSync } =
    useIntegrationCard();
  const classes = useStyles({ disabled });
  const status = useMemo(() => {
    if (isConnected) return { title: "Active", color: "#47a06d" };
    return { title: "Inactive", color: "#CCCCCC" };
  }, [isConnected]);

  const statusColor = useMemo(
    () => ({
      PENDING: "#FF8000",
      DELAY: "#FF8000",
      COMPLETED: "#47a06d",
      ERROR: "#FF6254",
    }),
    []
  );

  useEffect(
    () => setLoadingSync(["PENDING", "DELAY"].includes(syncStatus?.status)),
    [setLoadingSync, syncStatus]
  );

  return (
    <Box className={classes.cardWrapper}>
      <Box className={classes.cardTitle}>
        {integration.icon}
        <Box display="flex" flexDirection="column" gap="5px">
          <Box className={classes.cardStatus}>
            <Box className={classes.statusDot} backgroundColor={status.color} />{" "}
            <Typography fontSize="11px">{status.title}</Typography>
          </Box>
        </Box>
      </Box>
      <Box mt="15px">
        <Typography fontSize="14px">
          Manage and ship your orders with {integration.name}
        </Typography>
      </Box>

      <Box
        display="flex"
        justifyContent="flex-end"
        mt="20px"
        alignItems="center"
        gap="10px"
      >
        {loading && <CircularProgress size={27} />}
        {isConnected && hasSync && (
          <Box
            display="flex"
            flexDirection="column"
            gap="5px"
            alignItems="center"
          >
            <Box className={classes.cardStatusWrap}>
              <StyledButton
                sx={{ height: "28px", fontWeight: "500" }}
                fontSize="13px"
                variant="contained"
                label="Sync"
                disabled={isAdmin || loadingSync}
                onClick={() => {
                  onSync(integration);
                  setLoadingSync(true);
                }}
              />
              <Box className={classes.syncProgressWrap}>
                <Box className={classes.cardStatus}>
                  <Box
                    className={classes.statusDot}
                    backgroundColor={
                      statusColor[
                        syncStatus?.status === "ERROR"
                          ? "PENDING"
                          : syncStatus?.status
                      ]
                    }
                  />

                  <StyledTooltip
                    title={progressBar?.tooltip}
                    arrow
                    placement="top"
                    disableHoverListener={
                      !(
                        progressBar?.hide &&
                        ["PENDING", "DELAY", "ERROR"].includes(
                          syncStatus?.status
                        )
                      )
                    }
                  >
                    <Typography fontSize="11px" noWrap>
                      {["PENDING", "DELAY", "ERROR"].includes(
                        syncStatus?.status
                      )
                        ? "Synchronizing"
                        : `Sync ${normalizeEveryFirstLetterToUpper(
                            syncStatus?.status === "ERROR"
                              ? "PENDING"
                              : syncStatus?.status
                          )}`}
                    </Typography>
                  </StyledTooltip>
                </Box>

                {progressBar?.hide ? null : (
                  <>
                    {syncStatus?.status !== "COMPLETED" && (
                      <LinearProgress
                        variant="determinate"
                        value={syncStatus?.progress || 0}
                        sx={{ width: "100%" }}
                      />
                    )}
                  </>
                )}
              </Box>
            </Box>
          </Box>
        )}
        {isConnected ? (
          <StyledButton
            disabled={isAdmin}
            sx={{ height: "28px", fontWeight: "500" }}
            fontSize="13px"
            variant="contained"
            color="disable"
            label="Disable"
            onClick={() => onDisable(integration)}
          />
        ) : (
          <StyledTooltip
            title="Coming soon"
            arrow
            placement="top"
            {...tooltipProps}
          >
            <Box>
              <StyledButton
                disabled={disabled || isAdmin}
                sx={{ height: "28px", fontWeight: "500" }}
                fontSize="13px"
                variant="contained"
                label="Enable"
                onClick={() => onEnable(integration)}
              />
            </Box>
          </StyledTooltip>
        )}
      </Box>
    </Box>
  );
};

IntegrationsCard.propTypes = {
  integration: object,
  onEnable: func,
  loading: bool,
  isConnected: bool,
  onDisable: func,
  syncStatus: object,
  onSync: func,
  hasSync: bool,
  disabled: bool,
  tooltipProps: object,
  progressBar: shape({
    hide: bool,
    tooltip: string,
  }),
};

export default IntegrationsCard;
