import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { bool, func, string } from "prop-types";
import useStyles from "./styles";
import { TrialIcon } from "../Icons";
import { ContactSalesFooter } from "./components";

const HubspotContactForm = ({
  open,
  onClose,
  contact,
  description,
  formId,
}) => {
  const classes = useStyles();

  const [formLoading, setFormLoading] = useState(true);

  const onFormSubmitted = useCallback(() => {
    setTimeout(onClose, 3000);
  }, [onClose]);

  const onFormReady = useCallback(() => {
    setFormLoading(false);
  }, []);

  const createHubspotForm = useCallback(() => {
    if (!open) return;
    setFormLoading(true);
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/embed/v2.js";
    document.body.appendChild(script);
    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          // eslint-disable-next-line no-undef
          portalId: process.env.REACT_APP_HUBSPOT_PORTAL_ID,
          formId,
          target: "#hubspot-form",
          cssRequired:
            ".hs-button {width: 100%} input.hs-input { width: 100% } textarea.hs-input { width: 100%} .submitted-message { text-align: center; font-size: 24px!important; padding-top: 70px }",
          onFormSubmitted,
          onFormReady,
        });
      }
    });
  }, [open, formId, onFormSubmitted, onFormReady]);

  useEffect(createHubspotForm, [createHubspotForm]);

  return (
    <Dialog
      {...{ open, onClose }}
      sx={{
        ".MuiPaper-root": {
          borderRadius: "18px",
          boxShadow: "none",
          width: "100%",
          maxWidth: "742px",
        },
      }}
    >
      <DialogContent className={classes.modalBox}>
        <Typography className={classes.trialTitle}>
          Contact {contact}
        </Typography>

        <Typography className={classes.trialSubTitle}>{description}</Typography>
        <TrialIcon className={classes.trialIcon} />
        <Box pt="20px">
          {formLoading && (
            <Box
              display="flex"
              justifyContent="center"
              height="300px"
              alignItems="center"
            >
              <CircularProgress />
            </Box>
          )}
          <Box id="hubspot-form" className={classes.hubspotWrap} />
        </Box>
      </DialogContent>
      <ContactSalesFooter />
    </Dialog>
  );
};

HubspotContactForm.propTypes = {
  open: bool,
  onClose: func,
  contact: string,
  description: string,
  formId: string,
};
HubspotContactForm.defaultProps = {
  open: false,
  contact: "Sales",
  description: "",
};

export default HubspotContactForm;
