import { object } from "prop-types";
import { Box } from "@mui/material";
import { RatingItem } from "./components";

export const RatingsBlock = ({ customer }) => {
  const { rating = 0, stats = {}, price = 0 } = customer || {};

  return rating ? (
    <Box sx={{ height: "22px", mt: 1 }}>
      <RatingItem
        rating={rating}
        reviews={stats?.totalRatings || 0}
        price={price}
      />
    </Box>
  ) : null;
};

RatingsBlock.propTypes = {
  customer: object,
};
RatingsBlock.defaultProps = {
  customer: {},
};
