import { useMemo } from "react";
import { success } from "utils/notifications";

export const useContact = ({
  contacts,
  customerId,
  handleUpdateEvent,
  setValue,
}) => {
  const defaultContact = useMemo(() => {
    const contact = contacts?.find((contact) => !!contact?.defaultContact);
    return contact?.id || contact?.fid;
  }, [contacts]);

  const handleAddContact = (contact, isEdit) => {
    const newContacts = [...contacts];

    const index = contacts.findIndex(
      (contactItem) =>
        (contact.id && contactItem.id === contact.id) ||
        (contact.fid && contactItem.fid === contact.fid)
    );

    const defaultContactIndex = contacts.findIndex(
      (item) =>
        (item.id && item.id === defaultContact) ||
        (item.fid && item.fid === defaultContact)
    );

    if (defaultContactIndex > -1 && contact.defaultContact) {
      const changedContact = {
        ...contacts[defaultContactIndex],
        defaultContact: false,
      };
      newContacts.splice(defaultContactIndex, 1, changedContact);
    }

    if (isEdit && index > -1) {
      newContacts.splice(index, 1, contact);
      if (handleUpdateEvent) {
        if (!customerId) success("Contact updated");
        handleUpdateEvent({
          contacts: newContacts,
          successMsg: "Contact updated",
        });
      }
      return setValue("contacts", [...newContacts], { shouldDirty: true });
    }

    if (!isEdit) {
      if (!contact.id) delete contact.id;
      if (index > -1) {
        newContacts.splice(index, 1, contact);
        return setValue("contacts", [...newContacts], { shouldDirty: true });
      }
      setValue("contacts", [...newContacts, contact], { shouldDirty: true });
      if (handleUpdateEvent) {
        if (!customerId) success("Contact added");
        handleUpdateEvent({
          contacts: [...newContacts, contact],
          successMsg: "Contact added",
        });
      }
    }
  };

  const handleDeleteContact = (contact, remove) => {
    if (remove) {
      let newContacts = contacts.filter((contactItem) =>
        customerId
          ? contact.id && contactItem.id !== contact.id
          : (contact.fid && contactItem.fid !== contact.fid) ||
            (contact.id && contactItem.id !== contact.id)
      );
      if (contact?.defaultContact && newContacts?.length) {
        let tempContact = { ...newContacts[0] };
        tempContact.defaultContact = true;
        newContacts.splice(0, 1, tempContact);
      }
      if (setValue) {
        setValue("contacts", [...newContacts], { shouldDirty: true });
      }
      handleUpdateEvent && handleUpdateEvent({ contacts: [...newContacts] });
      success("Contact deleted");
    }
  };
  return { defaultContact, handleAddContact, handleDeleteContact };
};
