import { getFormattedDate } from "helpers/helpers";
import moment from "moment";
import { useSelector } from "react-redux";
import { ROLES } from "utils/constants";

export const useBodyNoteDrawer = () => {
  const currentUser = useSelector(({ auth }) => auth.currentUser);
  const { timeZone } = currentUser || {};

  const getRole = (role) => {
    if (!role) return;
    return ROLES.find((i) => Object.keys(i)[0] === role)[role];
  };

  const daysAgo = (date) => {
    const days = moment().diff(date, "days");
    if (days === 0)
      return `Today at ${moment(getFormattedDate(date, timeZone)).format(
        "h:mm a"
      )}`;
    if (days === 1)
      return `Yesterday at ${moment(getFormattedDate(date, timeZone)).format(
        "h:mm a"
      )}`;
    return moment(getFormattedDate(date, timeZone)).format(
      "MMM D, YY [at] h:mm a"
    );
  };

  return { getRole, daysAgo, distributorPhoto: currentUser?.profilePhoto };
};
