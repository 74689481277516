/* eslint-disable no-extra-boolean-cast */
import React, { Fragment, useCallback } from "react";
import { bool, func, array, object, number } from "prop-types";

import { CrossBigIcon } from "components/Icons";
import { InfiniteScrollWrapper, StyledButton } from "components";
import OrderedProductItem from "./components/OrderedProductItem";
import { OrderedProductsHeader } from "./components/OrderedProductsHeader";

import {
  Box,
  Stack,
  Dialog,
  IconButton,
  Typography,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { PRODUCT_TYPE_INVENTORY } from "utils/constants";

export const OrderedProductsPopup = ({
  isOpen,
  loading,
  control,
  setValue,
  handleClose,
  currentPage,
  handleCancel,
  calcAvailable,
  getOutOfStock,
  disabledSaveBtn,
  orderedProducts,
  handleAddOrders,
  handleAddProduct,
  handleSubtractQty,
  orderedProductsFetch,
  orderedProductsCount,
  checkSellingOutStock,
  handleIsAlreadyInCart,
  handleSetProductError,
}) => {
  const productsAvailableLessThanMinimum = useCallback(
    (product) => {
      const available = calcAvailable(product);
      if (
        product?.sellingOutOfStock ||
        product?.parentProduct?.sellingOutOfStock ||
        product?.childProducts?.length
      )
        return false;
      return available < product?.minOrderQTY;
    },
    [calcAvailable]
  );

  return (
    <Dialog
      open={isOpen}
      sx={{ overflow: "hidden" }}
      PaperProps={{
        sx: {
          maxWidth: "784px",
          overflow: "hidden",
        },
      }}
    >
      <DialogContent sx={{ width: "784px", maxHeight: "500px", px: "32px" }}>
        <Box display="flex" justifyContent="space-between" mb="15px">
          <Typography fontSize="30px" color="#707070">
            Ordered Items
          </Typography>
          <IconButton onClick={handleClose}>
            <CrossBigIcon size={23} />
          </IconButton>
        </Box>

        <Stack
          sx={{
            maxHeight: "349px",
            overflow: "hidden",
            border: "0.5px solid #D5D9D9",
            borderRadius: "4px 4px",
          }}
        >
          <OrderedProductsHeader />
          <Stack sx={{ maxHeight: "315px", overflow: "hidden" }}>
            <InfiniteScrollWrapper
              maxHeight="315px"
              dataLength={orderedProducts.length}
              id="ordered-products-table"
              loading={loading}
              next={() => orderedProductsFetch(currentPage + 1)}
              hasMore={orderedProducts.length < orderedProductsCount}
            >
              {!!orderedProducts?.length ? (
                orderedProducts.map((product, index) => {
                  const isNonInventory =
                    product?.type === PRODUCT_TYPE_INVENTORY.non_inventory;

                  const isOutOfStock = getOutOfStock(product, index);
                  const isAlreadyAdded = handleIsAlreadyInCart(product.id);
                  const isLessThanMin =
                    productsAvailableLessThanMinimum(product);

                  return (
                    <Fragment key={product.id}>
                      <OrderedProductItem
                        isNonInventory={isNonInventory}
                        calcAvailable={calcAvailable}
                        product={product}
                        disabled={!!isLessThanMin && !isNonInventory}
                        outOfStock={isOutOfStock && !isNonInventory}
                        isAlreadyAdded={isAlreadyAdded}
                        isOrder={false}
                        index={index}
                        control={control}
                        setValue={setValue}
                        handleAddProduct={handleAddProduct}
                        handleSubtractQty={handleSubtractQty}
                        checkSellingOutStock={checkSellingOutStock}
                        handleSetProductError={handleSetProductError}
                      />
                    </Fragment>
                  );
                })
              ) : (
                <Stack
                  height="100%"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography
                    fontWeight="300"
                    fontSize="16px"
                    textAlign="center"
                    width="100%"
                    color="#6A6A6A"
                    paddingTop="20px"
                    paddingBottom="20px"
                  >
                    No ordered items found
                  </Typography>
                </Stack>
              )}
            </InfiniteScrollWrapper>
          </Stack>
        </Stack>
        <DialogActions sx={{ p: "24px 0px 0px" }}>
          <StyledButton
            label="Cancel"
            sx={{ height: "27px", mr: "8px" }}
            color="cancel"
            onClick={handleCancel}
            fontWeight="600"
            fontSize="10px"
          />
          <StyledButton
            disabled={disabledSaveBtn}
            label="Add"
            variant="contained"
            sx={{ height: "27px" }}
            fontWeight="600"
            fontSize="10px"
            onClick={handleAddOrders}
          />
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

OrderedProductsPopup.propTypes = {
  isOpen: bool,
  handleClose: func,
  calcAvailable: func,
  handleCancel: func,
  handleAddOrders: func,
  orderedProducts: array,
  loading: bool,
  control: object,
  setValue: func,
  handleSubtractQty: func,
  disabledSaveBtn: bool,
  handleAddProduct: func,
  handleIsAlreadyInCart: func,
  getOutOfStock: func,
  checkSellingOutStock: func,
  handleSetProductError: func,
  orderedProductsFetch: func,
  currentPage: number,
  orderedProductsCount: number,
};

OrderedProductsPopup.defaultProps = {
  isOpen: false,
  control: null,
  loading: false,
  currentPage: 1,
  orderedProducts: [],
  disabledSaveBtn: false,
  orderedProductsCount: 0,

  setValue: () => {},
  getOutOfStock: () => {},
  calcAvailable: () => {},
  handleAddProduct: () => {},
  handleSubtractQty: () => {},
  orderedProductsFetch: () => {},
  checkSellingOutStock: () => {},
  handleIsAlreadyInCart: () => {},
  handleSetProductError: () => {},
};
