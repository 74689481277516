import { string } from "prop-types";
import { Box } from "@mui/material";

export const PaymentFailedFooter = ({ text }) => {
  return (
    <Box
      sx={{
        bgcolor: "rgba(64, 154, 102, 0.15)",
        height: "60px",
        minHeight: "60px",
        fontSize: 20,
        fontWeight: 500,
        color: "#47A06D",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {text}
    </Box>
  );
};

PaymentFailedFooter.propTypes = {
  text: string,
};
PaymentFailedFooter.defaultProps = {
  text: "Wholesale Made Simple",
};
