import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUserFieldAction } from "../../redux/actions/auth";
import { switchDistributorSubscriptionService } from "../../services/stripe";
import { error } from "../../utils/notifications";

export const useSelectionPlanActions = () => {
  const currentUser = useSelector(({ auth }) => auth?.currentUser);
  const dispatch = useDispatch();

  const currentPlan = useMemo(
    () => currentUser?.confirmSelectionPlanDialog?.current_plan || {},
    [currentUser?.confirmSelectionPlanDialog?.current_plan]
  );
  const chosenPlan = useMemo(
    () => currentUser?.confirmSelectionPlanDialog?.chosen_plan || {},
    [currentUser?.confirmSelectionPlanDialog?.chosen_plan]
  );

  const extraUser =
    currentUser?.confirmSelectionPlanDialog?.extra_user_count || 0;

  const [state, setState] = useState({
    current_plan: currentPlan,
    chosen_plan: chosenPlan,
    extra_user: extraUser,
    loading: false,
  });

  useEffect(() => {
    setState({
      current_plan: currentPlan,
      chosen_plan: chosenPlan,
      extra_user: extraUser,
    });
  }, [chosenPlan, currentPlan, extraUser]);

  const handleSetConfirm = useCallback(async () => {
    const type = state.extra_user > 0 ? "Request sent" : "Activated";
    try {
      setState((prev) => ({ ...prev, loading: true }));

      if (type === "Request sent") {
        const { data } = await switchDistributorSubscriptionService({
          oldSubscriptionId: currentPlan?.id,
          newSubscriptionId: currentPlan?.subscription?.id,
          extraAllowedUsers: state.extra_user ?? 0,
        });
        dispatch(updateUserFieldAction("distributorSubscription", data));
      }

      if (type === "Activated") {
        const { data } = await switchDistributorSubscriptionService({
          oldSubscriptionId: currentPlan?.id,
          newSubscriptionId: chosenPlan?.id,
          extraAllowedUsers: 0,
        });
        dispatch(updateUserFieldAction("distributorSubscription", data));
      }
      dispatch(
        updateUserFieldAction("confirmedSelectionPlanDialog", {
          ...state,
          type,
        })
      );
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      error(err?.response?.data?.message || "Something went wrong.");
    } finally {
      dispatch(updateUserFieldAction("confirmSelectionPlanDialog", null));
      setState((prev) => ({ ...prev, loading: false }));
    }
  }, [
    chosenPlan?.id,
    currentPlan?.id,
    currentPlan?.subscription?.id,
    dispatch,
    state,
  ]);

  return {
    state,
    handleSetConfirm,
    timeZone: currentUser?.timeZone,
  };
};
