import { useState } from "react";
import {} from "prop-types";
import { Box } from "@mui/material";
import {
  FiltersManegePage,
  HeaderManegePage,
  ListManegePage,
} from "./components";
import { createSelector } from "reselect";
import {
  customersCountSelector,
  customersListSelector,
} from "../../redux/selectors/customers";
import { useSelector } from "react-redux";

const selector = createSelector(
  customersListSelector,
  customersCountSelector,
  (customersList, customersActiveCount) => ({
    customersList,
    customersActiveCount,
  })
);

export const ManageCustomersPage = () => {
  const { customersList, customersActiveCount } = useSelector(selector);
  const [checkedCustomers, setCheckedCustomers] = useState([]);

  const handleCheckedCustomer = (customer) => {
    const tempStore = [...checkedCustomers];

    const customerIndex = tempStore.findIndex(
      (item) => item?.id === customer?.id
    );

    if (customerIndex === -1)
      return setCheckedCustomers((prev) => [...prev, customer]);

    if (customerIndex > -1) {
      tempStore.splice(customerIndex, 1);
      setCheckedCustomers([...tempStore]);
    }
  };

  const handleCheckedAllCustomer = (value) => {
    if (value) setCheckedCustomers([...customersList]);
    if (!value) setCheckedCustomers([]);
  };

  return (
    <Box>
      <HeaderManegePage />
      <FiltersManegePage
        checkedCustomers={checkedCustomers}
        setCheckedCustomers={setCheckedCustomers}
        onCheckedAllCustomer={handleCheckedAllCustomer}
      />
      <ListManegePage
        checkedCustomers={checkedCustomers}
        setCheckedCustomers={setCheckedCustomers}
        handleCheckedCustomer={handleCheckedCustomer}
        customersList={customersList}
        customersActiveCount={customersActiveCount}
        handleCheckedAllCustomer={handleCheckedAllCustomer}
      />
    </Box>
  );
};

ManageCustomersPage.propTypes = {};
ManageCustomersPage.defaultProps = {};

export default ManageCustomersPage;
