export const cl = {
  wrapper: {
    border: "1px solid #D5D9D9",
    borderRadius: "4px",
    minWidth: "234px",
    width: "100%",
    maxWidth: "234px",
    minHeight: "38px",
    height: "100%",
    maxHeight: "38px",
  },
  iconWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  infoWrapper: { alignItems: "center" },
  last: { fontSize: 12, color: "#000", mb: "-4px" },
  date: { fontSize: 9, color: "#707070", mt: "-4px" },
  labelWrapper: {
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  btnWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    mr: 0.3,
  },
  btn: {
    fontSize: 9,
    fontWeight: 300,
    minWidth: 10,
    height: 16,
    py: 0,
    px: 0.5,
  },
  divider: { borderLeft: "1px solid #D5D9D9", height: 11, marginX: 0.2 },
};
