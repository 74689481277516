import axios from "axios";

export const getDistributorNotificationsService = async () => {
  return await axios.get("/settings/notifications").then((res) => res.data);
};

export const getRepNotificationsService = async (id) => {
  return await axios
    .get(`/settings/representative-notifications/${id}`)
    .then((res) => res.data);
};

export const updateDistributorNotificationsService = async (id, data) => {
  return await axios
    .put(`/settings/notifications/${id}`, data)
    .then((res) => res.data);
};

export const updateRepNotificationsService = async (id, data) => {
  return await axios
    .put(`/settings/representative-notifications/${id}`, data)
    .then((res) => res.data);
};
