import {
  START_LOADING_MANUFACTURERS_DISCOUNTS,
  END_LOADING_MANUFACTURERS_DISCOUNTS,
  GET_MANUFACTURERS_DISCOUNTS,
  GET_MANUFACTURERS_DISCOUNTS_COUNT,
  SET_MANUFACTURERS_DICOUNTS_BY_ID,
  SHOW_INACTIVE_DISCOUNTS,
  GET_MANUFACTURERS_DICOUNTS_PARAMS,
  EXIST_MANUFACTURER_DISCOUNTS,
  GET_MANUFACTURERS_DISCOUNTS_COUNT_ALL,
} from "../actions/manufacturers-discounts.js";

const initialState = {
  manufacturersDiscounts: [],
  loadingManufacturersDiscounts: false,
  countManufacturersDiscounts: 0,
  countAllManufacturersDiscounts: 0,
  showInactive: false,
  params: {},
  existData: true,
};

const discountsReducer = (state = initialState, { type, payload, id }) => {
  switch (type) {
    case START_LOADING_MANUFACTURERS_DISCOUNTS:
      return { ...state, loadingManufacturersDiscounts: true };
    case END_LOADING_MANUFACTURERS_DISCOUNTS:
      return { ...state, loadingManufacturersDiscounts: false };

    case EXIST_MANUFACTURER_DISCOUNTS:
      return { ...state, existData: payload };

    case GET_MANUFACTURERS_DISCOUNTS:
      if (payload.isScrolling) {
        return {
          ...state,
          manufacturersDiscounts: [
            ...state.manufacturersDiscounts,
            ...payload.manufacturersDiscounts,
          ],
        };
      }
      return {
        ...state,
        manufacturersDiscounts: payload.manufacturersDiscounts,
      };

    case GET_MANUFACTURERS_DISCOUNTS_COUNT:
      return { ...state, countManufacturersDiscounts: payload };

    case GET_MANUFACTURERS_DISCOUNTS_COUNT_ALL:
      return { ...state, countAllManufacturersDiscounts: payload };

    case SET_MANUFACTURERS_DICOUNTS_BY_ID: {
      const list = state.manufacturersDiscounts;
      const index = list.findIndex((el) => el.id === id);
      const currentDiscount = list.filter((el) => el.id === id);
      if (index >= 0) {
        const discountsList = payload.isScrolling
          ? [
              ...currentDiscount?.[0]?.discounts,
              ...payload.discountsList.discounts,
            ]
          : payload.discountsList.discounts;

        const updatedCurrentDiscount = {
          ...payload.discountsList,
          discounts: discountsList,
        };

        list.splice(index, 1, updatedCurrentDiscount);
      }
      return { ...state, manufacturersDiscounts: list };
    }

    case SHOW_INACTIVE_DISCOUNTS:
      return { ...state, showInactive: payload };

    case GET_MANUFACTURERS_DICOUNTS_PARAMS:
      return { ...state, params: payload };

    default:
      return state;
  }
};

export default discountsReducer;
