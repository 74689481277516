import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  tail: {
    width: "19px",
    height: "11.36px",
    left: "32px",
    top: "-10px",
    zIndex: 10,
    backgroundColor: "#ffffff",
    clipPath: "polygon(50% 0%, 0% 100%, 100% 100%)",
    position: "absolute",
  },

  tailBackground: {
    width: "21px",
    height: "12.36px",
    left: "31px",
    top: "-12px",
    zIndex: 10,
    backgroundColor: "#D5D9D9",
    clipPath: "polygon(50% 0%, 0% 100%, 100% 100%)",
    position: "absolute",
  },

  menu: {
    overflow: "visible",
    transform: "translateY(10px)!important",
    "& .MuiPickerStaticWrapper-root": {
      "& .MuiTypography-subtitle1": {
        whiteSpace: "nowrap",
        fontSize: "12px",
      },

      "& .MuiTypography-caption": {
        width: "18px",
        height: "23px",
        textTransform: "lowercase",
        display: "inline-block",
        "&:first-letter": {
          textTransform: "uppercase",
        },
      },
      "& .PrivatePickersSlideTransition-root": {
        minWidth: "186px",
        width: "186px",
        minHeight: "135px",
        height: "135px",
        borderBottom: "0.5px solid #D4D4D4",
      },
      "& > div": {
        width: "374px",
        "& > div > div": {
          borderRight: "none",
          "& > div": { padding: 0 },
          "& > div:nth-of-type(2)": {
            borderTop: "0.5px solid #D4D4D4",
          },
          "&:not(:last-of-type)": {
            "& > div:nth-of-type(2)": {
              borderRight: "0.5px solid #D4D4D4",
            },
            "& .PrivatePickersSlideTransition-root": {
              borderRight: "0.5px solid #D4D4D4",
            },
          },
        },
      },
      "& .MuiDateRangePickerDay-root": {
        height: "20px",
        width: "22px",
        "& div": {
          height: "20px",
          border: "none",
          display: "flex",
        },
      },
    },
  },
}));

export default useStyles;
