import { object, func, string, number, shape, bool } from "prop-types";
import { Box, Button, Grid, Radio, Typography } from "@mui/material";
import { CardIconComponent } from "../CardIconComponent/CardIconComponent";
import { cl } from "./BillingCreditCard.styles";
import StyledLabel from "../StyledLabel/StyledLabel";
import { useMemo } from "react";
import { useAdmin } from "helpers/helpers";
import { StyledTooltip } from "components";

export const BillingCreditCard = ({
  card,
  styles,
  onEditCard,
  onDeleteCard,
  onSetDefaultCard,
  isLoading,
  moreThanOneCard,
}) => {
  const isAdmin = useAdmin();

  const setTitleTooltip = useMemo(() => {
    if (!moreThanOneCard) return "At least 1 card is required";
    if (card?.defaultMethod) return "The primary card cannot be deleted";
  }, [card?.defaultMethod, moreThanOneCard]);

  const hasDisableBtn = useMemo(() => {
    if (card?.defaultMethod) return true;
    if (!moreThanOneCard) return true;
    return false;
  }, [card?.defaultMethod, moreThanOneCard]);

  return (
    <Box display="flex">
      <Grid sx={{ ...cl.wrapper, ...styles }} container>
        <Grid sx={cl.iconWrapper} xs={2.2} item>
          <CardIconComponent size={22} type={card?.brand?.toLowerCase()} />
        </Grid>
        <Grid sx={cl.infoWrapper} xs={4.8} item container>
          <Typography sx={cl.last}>**** {card?.last4}</Typography>
          <Typography sx={cl.date}>
            ending in {card?.expMonth}/{card?.expYear}
          </Typography>
        </Grid>
        <Grid sx={cl.labelWrapper} xs={5} item container>
          {/* {card?.defaultMethod === "true" ? (
            <Box sx={{ mt: "-2px", mr: "4px" }}>
              <StyledLabel />
            </Box>
          ) : ( */}
          <Box />
          {/* )} */}
          <Box sx={cl.btnWrapper}>
            <Button
              disabled={isAdmin}
              sx={cl.btn}
              color="groundLighter"
              onClick={() => onEditCard(card)}
            >
              Edit
            </Button>
            <Box sx={cl.divider} />
            <StyledTooltip
              title={setTitleTooltip || ""}
              placement="top"
              arrow
              disableHoverListener={!setTitleTooltip}
            >
              <Box display="flex" alignItems="center">
                <Button
                  disabled={isAdmin || hasDisableBtn}
                  sx={cl.btn}
                  color="groundLighter"
                  onClick={() => onDeleteCard(card)}
                >
                  Remove
                </Button>
              </Box>
            </StyledTooltip>
          </Box>
        </Grid>
      </Grid>
      <Box display="flex" maxHeight="38px">
        {moreThanOneCard && (
          <>
            <Radio
              sx={{
                p: "6px",
              }}
              disabled={isAdmin || isLoading}
              checked={card?.defaultMethod}
              onChange={() => onSetDefaultCard(card)}
            />

            <StyledLabel
              fill={card?.defaultMethod ? "#47a06d" : "#D5D9D9"}
              style={{
                mr: "22px",
                px: "7px",
              }}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

BillingCreditCard.propTypes = {
  card: shape({
    id: string,
    brand: string,
    expMonth: number,
    expYear: number,
    last4: string,
    defaultMethod: bool,
  }),
  styles: object,
  onEditCard: func,
  onDeleteCard: func,
  onSetDefaultCard: func,
  isLoading: bool,
  moreThanOneCard: bool,
};
BillingCreditCard.defaultProps = {
  onEditCard: () => {},
  onDeleteCard: () => {},
  moreThanOneCard: false,
};
