import axios from "axios";

export const getTagsService = async (type, query) => {
  return await axios
    .get(`/${type}-tags`, { params: query })
    .then((res) => res.data);
};

export const createTagService = async (data, type) => {
  return axios.post(`/${type}-tags`, data).then((res) => res.data);
};

export const deleteTagService = async (id, type) => {
  return axios.delete(`/${type}-tags/${id}`).then((res) => res.data);
};

export const updateTagService = async (id, data, type) => {
  return axios.put(`/${type}-tags/${id}`, data).then((res) => res.data);
};

export const mergeTagsService = async (type, tagId, mergeIds) => {
  return axios
    .put(`/${type}-tags/${tagId}/merge`, { tagIds: mergeIds })
    .then((res) => res.data);
};

export const checkTagAvailabilityService = (params, type) => {
  return axios({
    method: "GET",
    url: `/${type}-tags/check-availability`,
    params,
  }).then((res) => res.data);
};

export const bulkAssignCustomerTagsService = async (data) => {
  return axios.put("/customers/bulk-assign-tags", data).then((res) => res.data);
};
