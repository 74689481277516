import * as React from "react";
// eslint-disable-next-line react/prop-types
export const FolderIcon = ({ width, height, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || 14.74}
    height={height || 13.27}
    viewBox="0 0 14.743 13.269"
    {...props}
  >
    <path
      data-name="Icon feather-folder"
      d="M14.743 11.795a1.474 1.474 0 0 1-1.474 1.474H1.474A1.474 1.474 0 0 1 0 11.795V1.474A1.474 1.474 0 0 1 1.474 0H5.16l1.474 2.211h6.634a1.474 1.474 0 0 1 1.474 1.474Z"
      fill="#47a06d"
    />
  </svg>
);
