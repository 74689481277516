import { array, number, string } from "prop-types";
import { Box, Grid } from "@mui/material";
import {
  ActionsBlock,
  ItemBlock,
  MainTitleBlock,
  TitleBlock,
} from "./components";
import { usePaymentReceived } from "./usePaymentReceived";

export const PaymentReceivedBlock = ({ paymentSum, paidFields, token }) => {
  const { loading, handleDownloadReport } = usePaymentReceived({ token });

  return (
    <Box width={{ xs: "calc(100% - 32px)", md: "auto" }} mx="16px">
      <Box
        sx={{
          width: { xs: "100%", md: "690px" },
          p: { md: "78px 0", xs: "29px 16px 30px 16px" },
          background: "#fff",
          borderRadius: "16px",
          border: "0.5px solid #D5D9D9",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid width={{ xs: "100%", md: "412px" }} container>
          <MainTitleBlock sum={paymentSum} />
          <Grid
            borderBottom={{ xs: "1px solid #D5D9D9", md: "1px solid #47A06D" }}
            height="1px"
            my={1.5}
            xs={12}
            item
          />
          <TitleBlock />
          {paidFields?.map((item) => (
            <ItemBlock
              key={item?.value}
              title={item?.title}
              value={item?.value}
            />
          ))}
          <Box width="100%" display={{ xs: "none", md: "block" }}>
            <ActionsBlock {...{ loading, handleDownloadReport }} />
          </Box>
        </Grid>
      </Box>
      <Box width="100%" display={{ xs: "block", md: "none" }}>
        <ActionsBlock {...{ loading, handleDownloadReport }} />
      </Box>
    </Box>
  );
};

PaymentReceivedBlock.propTypes = {
  paidFields: array,
  paymentSum: number,
  token: string,
};
PaymentReceivedBlock.defaultProps = { paidFields: [], paymentSum: 0 };
