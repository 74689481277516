import axios from "axios";

export const getDraftOrdersService = (params) => {
  return axios.get("/draft-orders", { params }).then((res) => res.data);
};

export const createDraftOrderService = (data) => {
  return axios.post("/draft-orders", data).then((res) => res.data);
};

export const deleteDraftOrderService = (id) => {
  return axios.delete(`/draft-orders/${id}`).then((res) => res.data);
};

export const getDraftOrderByIdService = (id) => {
  return axios.get(`/draft-orders/${id}`).then((res) => res.data);
};

export const getDraftOrderDuplicateByIdService = (id) => {
  return axios
    .get(`/draft-orders/${id}/duplicate-info`)
    .then((res) => res.data);
};

export const createOrderFromDraftService = (data) => {
  return axios.post(`/orders/from-draft`, data).then((res) => res.data);
};

export const getDraftOrderActivitiesService = (orderId, params) => {
  return axios
    .get(`/draft-orders/${orderId}/activities`, { params })
    .then((res) => res.data);
};

export const updateDraftOrderService = ({ id, data }) => {
  return axios.put(`/draft-orders/${id}`, data).then((res) => res.data);
};
