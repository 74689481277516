import { ADD_SYNC, UPDATE_SYNC } from "../actions/integrations";

const initialState = {
  integrationStatuses: {},
};

const integrationsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_SYNC:
      return { ...state, integrationStatuses: payload };

    case ADD_SYNC: {
      return {
        ...state,
        integrationStatuses: { ...state.integrationStatuses, ...payload },
      };
    }

    default:
      return state;
  }
};

export default integrationsReducer;
