import ReportDialog from "./components/ReportDialog/ReportDialog";
import ReportsPageTab from "./components/ReportsPageTab";
import StyledTabs from "components/StyledTabs/StyledTabs";
import { Box, CircularProgress, Paper, Typography } from "@mui/material";
import useStyles from "./styles";
import { useReportPage } from "./ReportsPage.hooks";
import { FilterSearchTextField } from "components";

const ReportsPage = () => {
  const classes = useStyles();

  const {
    currentTab,
    handleChangeTab,
    handleCloseReportDialog,
    reportState,
    ReportPageContent,
    REPORT_TABS,
    handleTypeSearch,
    handleSetFavorite,
    setReportState,
  } = useReportPage();

  return (
    <>
      <ReportDialog
        open={reportState.isOpenReportDialog}
        onClose={handleCloseReportDialog}
        report={reportState.currentReport}
        isCustom={reportState.isCustom}
        handleSetFavorite={handleSetFavorite}
      />
      <ReportsPageTab />
      <Box className={classes.pageWrapper}>
        <Paper className={classes.reportsPaper} elevation={0}>
          <Box width="1068px">
            <Box className={classes.titleWrapper}>
              <Box display="flex" alignItems="center" gap="40px">
                <Typography fontSize="30px">Reports</Typography>
                <StyledTabs
                  onChange={(e, newVal) => handleChangeTab(newVal)}
                  value={currentTab}
                  tabs={REPORT_TABS}
                />
                {reportState.loading && (
                  <CircularProgress size={35} sx={{ ml: "-30px" }} />
                )}
              </Box>
              <FilterSearchTextField
                adminIsAllowed
                fullWidth
                formSx={{ maxWidth: "525px" }}
                placeholder="Search by report name"
                value={reportState.search}
                onChange={handleTypeSearch}
                // show the clear button only if the input field has a value and does not have a chip
                handleClearValue={() =>
                  setReportState((prev) => ({ ...prev, search: "" }))
                }
              />
            </Box>
            {ReportPageContent[currentTab]}
          </Box>
        </Paper>
      </Box>
    </>
  );
};

export default ReportsPage;
