import { shape, string, func } from "prop-types";
import { Link as NavLink } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { getMonthDiff } from "../../../../../../../../../../helpers/helpers";

export const CustomerColumn = ({ customer, handleOpenCustomerProfile }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "flex-start",
        cursor: "pointer",
        overflow: "hidden",
        position: "relative",
        textDecoration: "none",
      }}
      component={NavLink}
      to={`/customers/${customer.id}`}
      onClick={(e) => {
        if (e.metaKey || e.ctrlKey) return;
        e.stopPropagation();
        e.preventDefault();
        handleOpenCustomerProfile(customer.id);
      }}
    >
      <Box display="flex" gap="5px" alignItems="center" maxWidth="100%">
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: 400,
            color: "#1C1C19",
            "&:hover": {
              textDecoration: "underline",
            },
          }}
          noWrap
        >
          {customer.displayName || customer.name}
        </Typography>
        {getMonthDiff(customer.createdAt) < 1 && (
          <Typography
            fontSize="9px"
            color="#fff"
            sx={{
              backgroundColor: "#409A65",
              borderRadius: "4px",
              px: "6px",
              animation: "pulse 0.7s 3 ease-in-out alternate",
              height: "13px",
              width: "32px",
            }}
          >
            NEW
          </Typography>
        )}
      </Box>
      {/* <NewCustomerLabel /> */}
      <Typography
        sx={{
          fontSize: 12,
          color: "#1C1C19",
          fontWeight: 400,
        }}
        noWrap
      >
        {customer.shippingAddress?.formatted_address ||
          customer.billingAddress?.formatted_address}
      </Typography>
    </Box>
  );
};

CustomerColumn.propTypes = {
  customer: shape({
    id: string,
    createdAt: string,
    shippingAddress: shape({ formatted_address: string }),
    billingAddress: shape({ formatted_address: string }),
  }),
  handleOpenCustomerProfile: func,
};
