/*global google*/
import { useMemo, useState } from "react";
import { object, func, number, string, bool } from "prop-types";
import GoogleMapReact from "google-map-react";
import { Box, CircularProgress, Paper, Typography } from "@mui/material";
import { getDiffTime, getFormattedDate, sliceCountry } from "helpers/helpers";
import { getAddressComponents } from "Pages/CustomersPage/components/AddressField/AddressField.helpers";
import { mapOptions } from "utils/mapOptions";
import { error } from "utils/notifications";
import MapPoint from "../MapPoint/MapPoint";
import { useDaysAgo } from "helpers/hooks";
import moment from "moment";

export const GoogleMap = ({
  handleSetAddress,
  address,
  customer,
  radius,
  checkInPoint,
  checkIn,
  timeZone,
  hideTooltip = false,
}) => {
  const [loading, setLoading] = useState(false);
  const handleMapClick = ({ lat, lng }) => {
    setLoading(true);
    const geocoder = new google.maps.Geocoder();

    geocoder
      .geocode({ location: { lat, lng } })
      .then((res) => {
        const loc = res.results[0];
        if (!loc) return error("Google maps error. Check console");
        const { street, state, city, zip } = getAddressComponents(loc);
        const newAddress = {
          formatted_address: sliceCountry(loc?.formatted_address),
          lat: loc?.geometry?.location?.lat(),
          lng: loc?.geometry?.location?.lng(),
          street,
          state,
          city,
          zip,
        };
        handleSetAddress(newAddress);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        error("Google maps error. Check console");
      });
  };

  const checkInTime = useMemo(() => {
    return checkIn?.checkedInAt ? checkIn?.checkedInAt : checkIn?.createdAt;
  }, [checkIn?.checkedInAt, checkIn?.createdAt]);

  const checkOutTime = useMemo(() => {
    return checkIn?.checkedOutAt ? checkIn?.checkedOutAt : null;
  }, [checkIn?.checkedOutAt]);

  const diffTime = useMemo(
    () => getDiffTime(checkInTime, checkOutTime, timeZone),
    [checkInTime, checkOutTime, timeZone]
  );

  const preparedDueDate = useDaysAgo({
    date: checkInTime,
    timeZone,
    format: "MMM D, YYYY",
  });

  return (
    <>
      {loading && (
        <Box
          sx={{
            zIndex: 1000,
            backgroundColor: "#fff",
            position: "absolute",
            left: 40,
            top: 40,
            padding: "15px",
            borderRadius: "20px",
          }}
        >
          <CircularProgress />
        </Box>
      )}

      {(!!checkIn?.createdAt || !!checkIn?.checkedInAt) && (
        <Paper
          sx={{
            position: "absolute",
            top: "43px",
            left: "50%",
            transform: "translateX(-50%)",
            border: "1px solid #D5D9D9",
            borderRadius: "6px",
            display: "flex",
            alignItems: "center",
            height: "32px",
            padding: "0 16px",
            zIndex: 1,
          }}
          elevation={3}
        >
          <Typography
            sx={{ textDecoration: "underline" }}
            fontSize={12}
            fontWeight={500}
            color="#707070"
            mr={0.5}
          >
            {preparedDueDate}
          </Typography>
          <Typography fontSize={12} fontWeight={400} color="#707070">
            {checkInTime
              ? ` Check in: ${moment(
                  getFormattedDate(checkInTime, timeZone)
                ).format("h:mm a")}`
              : null}{" "}
            {checkOutTime
              ? ` | Check out: ${moment(
                  getFormattedDate(checkOutTime, timeZone)
                ).format("h:mm a")}`
              : null}{" "}
            {!!diffTime && diffTime}
          </Typography>
        </Paper>
      )}

      <GoogleMapReact
        defaultCenter={{
          lat: 40.72316765399142,
          lng: -73.99994350677083,
        }}
        center={{
          lat: address?.lat || 40.72316765399142,
          lng: address?.lng || -73.99994350677083,
        }}
        defaultZoom={12}
        bootstrapURLKeys={{
          // eslint-disable-next-line no-undef
          key: process.env.REACT_APP_MAP_KEY,
          libraries: ["drawing", "geometry", "places"],
        }}
        onClick={handleMapClick}
        options={{
          styles: mapOptions,
        }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map }) => {
          map.setClickableIcons(false);
          if (radius > 0) {
            new google.maps.Circle({
              strokeColor: "#1757D7",
              strokeOpacity: 0.8,
              strokeWeight: 0.5,
              fillColor: "#1757D7",
              fillOpacity: 0.3,
              map,
              center: {
                lat: address?.lat || 40.72316765399142,
                lng: address?.lng || -73.99994350677083,
              },
              radius,
            });
          }
        }}
      >
        {address?.lat && (
          <MapPoint
            hideTooltip={hideTooltip}
            customer={customer}
            address={address?.formatted_address}
            color={
              checkIn?.locationType === "NO_LOCATION_PROVIDED"
                ? checkInPoint?.color
                : "#47A06D"
            }
            lat={address?.lat}
            lng={address?.lng}
          />
        )}
        {!!checkInPoint?.lat && !!checkInPoint?.lng && (
          <MapPoint
            justDot={true}
            lat={checkInPoint?.lat}
            lng={checkInPoint?.lng}
            color={checkInPoint?.color}
          />
        )}
      </GoogleMapReact>
    </>
  );
};

GoogleMap.propTypes = {
  handleSetAddress: func,
  address: object,
  customer: object,
  radius: number,
  checkInPoint: object,
  checkIn: object,
  timeZone: string,
  hideTooltip: bool,
};

export default GoogleMap;
