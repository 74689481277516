import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  dialogTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #d5d9d9",
    padding: "15px 19px 14px 26px",
    "& .MuiTypography-root": {
      fontSize: "30px",
      color: "#707070",
    },
  },

  dialogContent: {
    width: "344px",
    padding: "17px 43px!important",
  },

  fieldBlock: {
    "& .MuiTypography-root": {
      fontSize: "12px",
      "&:nth-of-type(1)": {
        marginBottom: "7px",
      },
    },
  },

  dialogActions: {
    borderTop: "1px solid #d5d9d9",
    padding: "17px 22px",
  },

  warningBlock: {
    display: "flex",
    alignItems: "center",
    gap: "9px",
    padding: "6px 10px 9px 12px",
    border: "1px solid #EB4233",
    backgroundColor: "rgba(235, 66, 51, 0.1)",
    marginBottom: "13px",
    borderRadius: "4px",
    "& .MuiTypography-root": {
      fontSize: "12px",
    },
  },
}));

export default useStyles;
