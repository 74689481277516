import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  endLoadingSavedFilters,
  startLoadingSavedFilters,
} from "redux/actions/savedFilters";
import { checkFilterNameService } from "services/savedFilters";
import { error } from "utils/notifications";

export const useFilterViewSave = ({ handleSave }) => {
  const dispatch = useDispatch();
  const [viewName, setViewName] = useState("");

  const onChange = (e) => setViewName(e.target.value);

  const onSave = async () => {
    dispatch(startLoadingSavedFilters());
    try {
      const res = await checkFilterNameService(viewName);
      if (res.available) {
        handleSave(viewName);
        setViewName("");
        return;
      }
      dispatch(endLoadingSavedFilters());
      return error("This name is already exists");
    } catch (err) {
      error(err.message);
      dispatch(endLoadingSavedFilters());
    }
  };

  return { viewName, setViewName, onSave, onChange };
};
