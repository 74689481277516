import { object, string } from "prop-types";
import { Box } from "@mui/material";
import { CardPlanItem, TextPlanItem } from "./CardPlan";
import { useCallback } from "react";
import { EXTRA_USER_PRICE } from "../../ReachedUsersLimitDialog/ReachedUseraLimitDialog.constants";

export const DialogBodyComponent = ({ state, timeZone }) => {
  const {
    current_plan: currentPlan,
    chosen_plan: chosenPlan,
    extra_user: extraUsers,
  } = state || {};

  const setName = useCallback(
    (type) => {
      if (extraUsers > 0) {
        return currentPlan?.subscription?.name;
      }

      if (extraUsers === 0) {
        if (type === "Current plan") return currentPlan?.subscription?.name;
        if (type === "New plan") return chosenPlan?.name;
      }
    },
    [chosenPlan?.name, currentPlan?.subscription?.name, extraUsers]
  );

  const setAmount = useCallback(
    (type) => {
      if (type === "Current plan") return currentPlan?.subscription?.price;
      if (type === "New plan")
        return extraUsers > 0
          ? currentPlan?.subscription?.price + extraUsers * EXTRA_USER_PRICE
          : chosenPlan?.price;
    },
    [chosenPlan?.price, currentPlan?.subscription?.price, extraUsers]
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        px: 3,
      }}
    >
      <CardPlanItem
        type="Current plan"
        {...{ state }}
        name={setName("Current plan")}
        amount={setAmount("Current plan")}
      />
      <CardPlanItem
        type="New plan"
        {...{ state }}
        name={setName("New plan")}
        amount={setAmount("New plan")}
      />
      <TextPlanItem
        date={currentPlan?.currentPeriodEnd}
        amount={setAmount("New plan")}
        {...{ timeZone }}
      />
    </Box>
  );
};

DialogBodyComponent.propTypes = { state: object, timeZone: string };
DialogBodyComponent.defaultProps = {};
