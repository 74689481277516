import { useEffect, useMemo, useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import { bool, string, object, func, number, array } from "prop-types";
import {
  MapPinChecked,
  MapPinEnd,
  MapPinIndexed,
  MapPinRedIcon,
  MapPinStart,
} from "../Icons";
import MapTooltip from "../MapTooltip/MapTooltip";
import { MARKER_TYPE } from "Pages/CustomersPage/components/CustomersTab/components/TabsContent/TabMapComponent/TabMapComponent.constants";
import MapProspectTooltip from "components/MapTooltip/MapProspectTooltip";
import { MapPointIndexed } from "./components";
import theme from "theme";
import MapSameCustomersTooltip from "components/MapSameCustomersTooltip/MapSameCustomersTooltip";

const MapPoint = ({
  $hover,
  isCluster,
  count,
  length,
  address,
  customer,
  onMarkerClick,
  color,
  isChecked,
  checkedCount,
  checkedCustomers,
  handleOpenProductDialog,
  handleOpenNotes,
  index,
  completed,
  startLoc,
  subAddresses,
  endLoc,
  justDot,
  type,
  prospectPercentage,
  handleOpenRoutesDrawer,
  handleOpenReceivingHours,
  handleCustomerTaskDrawer,
  currentUser,
  handleCheckCustomer,
  hideTooltip,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipHovered, setTooltipHovered] = useState(false);
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const [colorItem, setColorItem] = useState(color || "");

  const tooltipRef = useRef(null);

  const hasSubAddresses = subAddresses && !!subAddresses.length;
  const subAddressesList = hasSubAddresses ? [customer, ...subAddresses] : [];

  const { top, left } = useMemo(() => {
    const coordinates = tooltipRef.current?.getBoundingClientRect();
    return coordinates ? coordinates : { top: 0, left: 0 };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showTooltip, tooltipHovered]);

  useEffect(() => {
    if (colorItem === "#3e7c18") {
      setColorItem("#47A06D");
    }
  }, [colorItem, setColorItem]);

  if (justDot)
    return (
      <Box
        sx={{
          width: "10px",
          height: "10px",
          borderRadius: "50%",
          bgcolor: color ? color : "#26874B",
        }}
      />
    );

  if (isCluster) {
    if (!length) return <></>;
    return (
      <Box
        width={`${35 + (count / length) * 25}px`}
        height={`${35 + (count / length) * 25}px`}
        // backgroundColor={checkedCount ? "#fff" : color ?? "#47A06D"}
        // backgroundColor={checkedCount ? "#fff" : "#989898"}
        backgroundColor={checkedCount ? "#fff" : color}
        border={!prospectPercentage && `4px solid ${color || "#47A06D"}`}
        borderRadius="50%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        position="absolute"
        top="-18px"
        left="-18px"
        sx={{
          "&:before": {
            content: "''",
            position: "absolute",
            borderRadius: "50%",
            inset: 0,
            // background:
            //   !checkedCount &&
            //   `conic-gradient(${theme.palette.primary.main} ${prospectPercentage}%,#0000 0)`,
          },
          zIndex: $hover && 100,
          cursor: "pointer",
        }}
      >
        <Typography
          fontWeight={600}
          color={checkedCount ? color : "#fff"}
          fontSize={checkedCount ? "11px" : "16px"}
          whiteSpace="nowrap"
        >
          {checkedCount ? `${checkedCount}/${count}` : count}
        </Typography>
      </Box>
    );
  }

  const getMarker = () => {
    if (subAddresses && subAddresses.length)
      return <MapPinIndexed index={subAddressesList.length} completed />;
    if (startLoc) return <MapPinStart />;
    if (endLoc) return <MapPinEnd />;

    if (isChecked) return <MapPinChecked fill={color} />;
    if (type === MARKER_TYPE.prospectSearchResult) {
      return <MapPointIndexed index={index} color="#989898" />;
    }
    if (index) {
      if (type === MARKER_TYPE.customer) {
        return <MapPinIndexed index={index} completed={completed} />;
      } else {
        return (
          <MapPointIndexed
            index={index}
            color={color ?? theme.palette.primary.main}
          />
        );
      }
    }
    return <MapPinRedIcon fill={color} />;
  };

  const tooltipData = (
    <Box
      ref={tooltipRef}
      onClick={() => {
        if ($hover || showTooltip) {
          onMarkerClick(customer, type, subAddressesList);
          setTooltipHovered(false);
          setShowTooltip(false);
        }
      }}
      position="absolute"
      top="-36px"
      left="-18px"
      margin="0 auto"
      height="30px"
      whiteSpace="nowrap"
      sx={{
        cursor: "pointer",
        zIndex: showTooltip && 100,
      }}
      onMouseEnter={() => setShowTooltip(true)}
    >
      {getMarker()}
    </Box>
  );

  const tooltip = {
    [MARKER_TYPE.customer]: (
      <MapTooltip
        {...{
          handleOpenProductDialog,
          address,
          customer,
          handleOpenNotes,
          setTooltipHovered,
          top,
          left,
          handleOpenRoutesDrawer,
          handleOpenReceivingHours,
          handleCustomerTaskDrawer,
          currentUser,
          showTooltip,
          hideTooltip,
          setShowTooltip,
          tooltipIsOpen,
          setTooltipIsOpen,
        }}
      >
        {tooltipData}
      </MapTooltip>
    ),
    [MARKER_TYPE.subAddresses]: (
      <MapSameCustomersTooltip
        {...{
          subAddresses: subAddressesList,
          customer,
          setTooltipHovered,
          top,
          left,
          currentUser,
          checkedCustomers,
          handleCheckCustomer,
          hideTooltip,
          showTooltip,
          setShowTooltip,
          tooltipIsOpen,
          setTooltipIsOpen,
        }}
      >
        {tooltipData}
      </MapSameCustomersTooltip>
    ),
    [MARKER_TYPE.prospect]: (
      <MapProspectTooltip
        {...{
          showTooltip,
          setTooltipHovered,
          hideTooltip,
          setShowTooltip,
          tooltipIsOpen,
          setTooltipIsOpen,
        }}
        prospect={customer}
      >
        {tooltipData}
      </MapProspectTooltip>
    ),
    [MARKER_TYPE.prospectSearchResult]: (
      <MapProspectTooltip
        {...{
          setTooltipHovered,
          hideTooltip,
          setShowTooltip,
          showTooltip,
          tooltipIsOpen,
          setTooltipIsOpen,
        }}
        prospect={customer}
      >
        {tooltipData}
      </MapProspectTooltip>
    ),
    [MARKER_TYPE.new]: (
      <MapProspectTooltip
        {...{
          setTooltipHovered,
          hideTooltip,
          setShowTooltip,
          tooltipIsOpen,
          setTooltipIsOpen,
          showTooltip,
        }}
        prospect={customer}
      >
        {tooltipData}
      </MapProspectTooltip>
    ),
  };

  return <>{tooltip[hasSubAddresses ? MARKER_TYPE.subAddresses : type]}</>;
};

MapPoint.propTypes = {
  $hover: bool,
  address: string,
  customer: object,
  onMarkerClick: func,
  handleCheckCustomer: func,
  color: string,
  isCluster: bool,
  count: number,
  length: number,
  isChecked: bool,
  checkedCount: number,
  handleOpenProductDialog: func,
  handleOpenNotes: func,
  index: number,
  completed: bool,
  startLoc: bool,
  endLoc: bool,
  justDot: bool,
  type: string,
  prospectPercentage: number,
  handleOpenRoutesDrawer: func,
  handleOpenReceivingHours: func,
  handleCustomerTaskDrawer: func,
  currentUser: object,
  subAddresses: array,
  checkedCustomers: array,
  hideTooltip: bool,
};

MapPoint.defaultProps = {
  $hover: false,
  address: "",
  customer: null,
  color: "#000000",
  onMarkerClick: () => {},
  handleOpenRoutesDrawer: () => {},
  isChecked: false,
  checkedCount: 0,
  handleOpenProductDialog: () => {},
  handleOpenNotes: () => {},
  completed: false,
  startLoc: false,
  endLoc: false,
  justDot: false,
  type: MARKER_TYPE.customer,
  prospectPercentage: 0,
  currentUser: {},
  checkedCustomers: [],
  hideTooltip: false,
};

export default MapPoint;
