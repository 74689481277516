import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";

export const useStoreWidget = () => {
  const { brandLogo, storeName } = useSelector(({ auth }) => auth?.currentUser);

  const [widgetState, setWidgetState] = useState({
    size: "lg",
    showWidget: false,
    storeLinkDialogOpen: false,
    link: "",
    error: false,
    uniqueChecked: false,
    linkLoading: false,
  });

  const updateWidgetState = useCallback((newObj) => {
    setWidgetState((prev) => ({ ...prev, ...newObj }));
  }, []);

  const onStoreLinkConfirm = useCallback(() => {
    updateWidgetState({ showWidget: true });
  }, [updateWidgetState]);

  const showSetup = useMemo(
    () => !brandLogo || !storeName,
    [brandLogo, storeName]
  );

  return {
    widgetState,
    updateWidgetState,
    onStoreLinkConfirm,
    showSetup,
  };
};
