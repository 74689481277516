/* eslint-disable react/prop-types */
import * as React from "react";
export const PurchaseOrderIcon = ({ fill = "#ccc", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={9.418}
    height={11.473}
    viewBox="0 0 9.418 11.473"
    {...props}
  >
    <path
      data-name="icons8-purchase-order (5)"
      d="M1.407 0A1.4 1.4 0 0 0 0 1.407v8.658a1.4 1.4 0 0 0 1.407 1.407h6.6a1.4 1.4 0 0 0 1.407-1.407V1.407A1.4 1.4 0 0 0 8.009 0Zm0 .649h6.6a.753.753 0 0 1 .754.758v8.658a.753.753 0 0 1-.758.758H1.4a.753.753 0 0 1-.758-.758V1.407a.753.753 0 0 1 .765-.758Zm.758 1.732a.325.325 0 1 0 0 .649h4.87a.325.325 0 0 0 0-.649Zm0 1.619a.325.325 0 1 0 0 .649h4.87a.325.325 0 0 0 0-.649Zm0 1.621a.325.325 0 1 0 0 .649h2.221a.325.325 0 1 0 0-.649Zm4.382 3.257a.321.321 0 0 0-.228.089.323.323 0 0 0 .228.552.295.295 0 0 0 .228-.1.312.312 0 0 0 .1-.227.295.295 0 0 0-.1-.228.321.321 0 0 0-.226-.083Z"
      fill={fill}
    />
  </svg>
);
