/* eslint-disable react/prop-types */
import React from "react";

export const PenIcon = ({ size = 16, color = "#b5b5ac", props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 17 16"
      {...props}
    >
      <g id="pen-solid" transform="translate(0 0)">
        <path
          id="pen-solid-2"
          data-name="pen-solid"
          d="M9.075,2.921l4,4L4.388,15.608.822,16a.75.75,0,0,1-.828-.828l.4-3.569L9.075,2.921Zm6.475-.6L13.671.447a1.5,1.5,0,0,0-2.122,0L9.782,2.214l4,4L15.55,4.447a1.5,1.5,0,0,0,0-2.122Z"
          transform="translate(0.011 -0.007)"
          fill={color}
        />
      </g>
    </svg>
  );
};
