import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { StyledButton, StyledTextField } from "components";
import { bool, func, string } from "prop-types";
import useStyles from "./styles";
import { CrossIcon } from "components/Icons";

const CustomReportDialog = ({
  open,
  onClose,
  name,
  handleSetFavorite,
  disabledCustomReportBtn = false,
}) => {
  const classes = useStyles();
  const [nameState, setNameState] = useState({
    name: name || "",
    error: false,
  });
  useEffect(() => {
    setNameState((prev) => ({ ...prev, name: name || "" }));
  }, [name]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle className={classes.dialogTitle}>
        Custom Report Name
        <IconButton onClick={onClose} sx={{ mt: "-8px" }}>
          <CrossIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <StyledTextField
          value={nameState.name}
          onChange={({ target }) =>
            setNameState((prev) => ({
              ...prev,
              name: target.value,
              error: false,
            }))
          }
          error={nameState.error}
        />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <StyledButton
          label="Cancel"
          variant="outlined"
          color="cancel"
          onClick={onClose}
        />
        <StyledButton
          label="Add"
          disabled={disabledCustomReportBtn}
          variant="contained"
          onClick={async () => {
            if (!nameState.name)
              setNameState((prev) => ({ ...prev, error: true }));
            else {
              await handleSetFavorite(nameState.name);
              onClose();
            }
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

CustomReportDialog.propTypes = {
  open: bool,
  disabledCustomReportBtn: bool,
  onClose: func,
  name: string,
  handleSetFavorite: func,
};

export default CustomReportDialog;
