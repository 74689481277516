import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  gridItem: {
    height: ({ type }) => (type === "promotions" ? "50px" : "52px"),
    alignItems: "center",
    "&:not(:last-of-type)": { borderBottom: "0.5px solid #d5d9d9" },
  },

  gridColumn: {
    "&.main": {
      "& .MuiTypography-root": {
        fontSize: "13px",
        color: "#1C1C19",
        "&:last-of-type": { fontWeight: "300" },
      },
    },

    "&.secondary": {
      textAlign: "right",
      "& .MuiTypography-root": {
        fontWeight: "500",
        color: "#000",
        "&:first-of-type": { fontSize: "10px", color: "#BFBFBF" },
        "&:last-of-type": { fontSize: "13px" },
      },
    },
  },
}));

export default useStyles;
