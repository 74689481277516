import { bool, string } from "prop-types";
import { Box, Typography } from "@mui/material";

export const BarcodeBlock = ({ hasThirdParty, productBarcode }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        height: "100%",
        // width: !hasThirdParty ? "20.96%" : "15.01%",
        // minWidth: !hasThirdParty ? "20.96%" : "15.01%",
        width: !hasThirdParty ? "16.96%" : "11.01%",
        minWidth: !hasThirdParty ? "16.96%" : "11.01%",
      }}
    >
      <Typography sx={{ fontSize: 13, color: "#1C1C19" }} noWrap>
        {productBarcode || "-"}
      </Typography>
    </Box>
  );
};

BarcodeBlock.propTypes = { hasThirdParty: bool, productBarcode: string };
