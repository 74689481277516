import { array, object, bool, func } from "prop-types";
import { Box, Chip, Menu } from "@mui/material";

export const TagsMenu = ({ anchorEl, open, handleClose, tags }) => {
  return (
    <Menu
      id="basic-menu"
      sx={{
        ".MuiPopover-paper": {
          boxShadow: "none !important",
          border: "0.5px solid #D5D9D9",
        },
      }}
      MenuListProps={{
        "aria-labelledby": "basic-button",
        sx: {
          minWidth: "202px",
          maxWidth: "640px",
          width: "min-content",
          p: "10px",
        },
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      onClick={(e) => e.stopPropagation()}
    >
      <Box display="flex" flexWrap="wrap" gap={0.5}>
        {tags.map((tag) => {
          return (
            <Chip
              key={tag?.id}
              sx={{
                fontSize: "14px",
                height: "24px",
                color: "#5F6368",
                backgroundColor: "#F8F8F8",
              }}
              label={tag?.tag}
              variant="outlined"
            />
          );
        })}
      </Box>
    </Menu>
  );
};

TagsMenu.propTypes = {
  open: bool,
  tags: array,
  anchorEl: object,
  handleClose: func,
};

export default TagsMenu;
