import { object } from "prop-types";
import { Box } from "@mui/material";
import { Controller } from "react-hook-form";
import { StyledTextField } from "components";

export const OtherReasonBlock = ({ control }) => {
  return (
    <Box>
      <Controller
        render={({ field, fieldState: { error } }) => (
          <StyledTextField
            placeholder="Reason"
            multiline
            minRows={1}
            maxRows={5}
            fullWidth
            inputProps={{ maxLength: 2000 }}
            sx={{
              "& .MuiOutlinedInput-root": { height: "100%" },
            }}
            error={error ? error.message : ""}
            {...field}
          />
        )}
        name="otherReason"
        control={control}
      />
    </Box>
  );
};

OtherReasonBlock.propTypes = { control: object };
