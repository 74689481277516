import { shape, string, number, object, func } from "prop-types";
import { Box } from "@mui/material";
import { CardHeader, CardBody, CardFooter } from "./components";

export const CardPlanItem = ({ plan, handleChooseCurrentPlan }) => {
  const { name, price, term, features } = plan || {};
  return (
    <Box
      sx={{
        width: "284px",
        minWidth: "284px",
        height: "358px",
        border: "0.5px solid #D5D9D9",
        "&:hover": { border: "0.5px solid #409A65" },
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CardHeader
        name={name}
        amount={price}
        period={`/${term?.toLowerCase()?.slice(0, 3)}`}
      />
      <CardBody {...{ name, features }} />
      <CardFooter
        nameBtn="Select"
        onChangeCurrentPlan={() => handleChooseCurrentPlan(plan)}
      />
    </Box>
  );
};

CardPlanItem.propTypes = {
  plan: shape({
    name: string,
    price: number,
    term: string,
    features: object,
  }),
  handleChooseCurrentPlan: func,
};
