import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  typeToggle: {
    border: "none",
    backgroundColor: "#FAFAFA",
    "&.Mui-selected": { backgroundColor: "#F2F2F2" },
    width: "30px",
    textTransform: "none"
  },

  toggleGroup: {
    height: "28px",
  },
}));

export default useStyles;
