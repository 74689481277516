import {
  getTerritoryTreeService,
  getTerritoryListService,
  getCustomersByTerritoryService,
  addTerritoryService,
} from "../../services/territory";
import { error } from "../../utils/notifications";

export const GET_TERRITORY_TREE = "GET_TERRITORY_TREE";
export const GET_TERRITORY_LIST = "GET_TERRITORY_LIST";
export const GET_CUSTOMERS_WITHOUT_TERRITORY =
  "GET_CUSTOMERS_WITHOUT_TERRITORY";
export const START_LOADING_TERRITORY_TREE = "START_LOADING_TERRITORY_TREE";
export const END_LOADING_TERRITORY_TREE = "  END_LOADING_TERRITORY_TREE";
export const START_LOADING_TERRITORY_LIST = "  START_LOADING_TERRITORY_LIST";
export const END_LOADING_TERRITORY_LIST = "  END_LOADING_TERRITORY_LIST";

const getTerritoryTree = (payload) => ({
  type: GET_TERRITORY_TREE,
  payload,
});

const getTerritoryList = (payload) => ({
  type: GET_TERRITORY_LIST,
  payload,
});

const getCustomersByTerritory = (payload) => ({
  type: GET_CUSTOMERS_WITHOUT_TERRITORY,
  payload,
});

const startLoadingTerritoryTree = () => ({
  type: START_LOADING_TERRITORY_TREE,
});
const endLoadingTerritoryTree = () => ({ type: END_LOADING_TERRITORY_TREE });
const startLoadingTerritoryList = () => ({
  type: START_LOADING_TERRITORY_LIST,
});
const endLoadingTerritoryList = () => ({ type: END_LOADING_TERRITORY_LIST });

export const getTerritoryTreeAction = () => {
  return (dispatch) => {
    dispatch(startLoadingTerritoryTree());

    getTerritoryTreeService()
      .then((res) => {
        dispatch(getTerritoryTree(res));
        dispatch(endLoadingTerritoryTree());
      })
      .catch((err) => {
        dispatch(endLoadingTerritoryTree());
        // eslint-disable-next-line no-console
        console.log(err?.message);
      });
  };
};

export const getTerritoryListAction = () => {
  return (dispatch) => {
    dispatch(startLoadingTerritoryList());
    getTerritoryListService()
      .then((res) => {
        dispatch(getTerritoryList(res));
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err?.message);
      })
      .finally(dispatch(endLoadingTerritoryList()));
  };
};

export const getCustomersByTerritoryAction = () => {
  return (dispatch) => {
    dispatch(startLoadingTerritoryTree());
    dispatch(startLoadingTerritoryList());

    getCustomersByTerritoryService("")
      .then((res) => {
        dispatch(getCustomersByTerritory(res));
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err?.message);
      })
      .finally(() => {
        dispatch(endLoadingTerritoryTree());
        dispatch(endLoadingTerritoryList());
      });
  };
};

export const addTerritoryAction = ({ data, handleCollapse, onSuccess }) => {
  return (dispatch, getState) => {
    const {
      territory: { loadingTerritoryTree, loadingTerritoryList },
    } = getState();
    if (!loadingTerritoryTree && !loadingTerritoryList) {
      dispatch(startLoadingTerritoryTree());
      addTerritoryService(data)
        .then((res) => {
          dispatch(getTerritoryTree(res));
          dispatch(getTerritoryListAction());
          dispatch(endLoadingTerritoryTree());
          if (handleCollapse) handleCollapse();
          if (onSuccess) onSuccess();
        })
        .catch((err) => {
          dispatch(endLoadingTerritoryTree());
          error(err?.message || "Something went wrong.");
          // eslint-disable-next-line no-console
          console.log(err?.message);
        });
    }
  };
};
