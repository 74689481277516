import { ExpandMore } from "@mui/icons-material";
import { FormControl, InputLabel, Select } from "@mui/material";
import { string, array, object, bool } from "prop-types";
import { forwardRef } from "react";
import useStyles from "./styles";
import { useAdmin } from "helpers/helpers";

const StyledSelect = forwardRef(
  ({ label, children, labelSx, fullWidth, ...props }, ref) => {
    const isAdmin = useAdmin();
    const classes = useStyles();

    return (
      <FormControl disabled={isAdmin || props?.disabled} fullWidth={fullWidth}>
        <InputLabel
          id={`${label}-select`}
          sx={labelSx}
          className={classes.label}
        >
          {label}
        </InputLabel>
        <Select
          labelId={`${label}-select`}
          label={label}
          variant="outlined"
          inputProps={{ classes: { icon: classes.icon } }}
          IconComponent={ExpandMore}
          className={classes.select}
          ref={ref}
          {...props}
        >
          {children}
        </Select>
      </FormControl>
    );
  }
);

StyledSelect.propTypes = {
  label: string,
  children: array,
  labelSx: object,
  fullWidth: bool,
  disabled: bool,
};

StyledSelect.defaultTypes = {
  label: "",
  fullWidth: false,
  children: null,
};

StyledSelect.displayName = "StyledSelect";

export default StyledSelect;
