import { Box, Typography } from "@mui/material";

export const HeaderList = () => {
  return (
    <Box
      sx={{
        display: "flex",
        border: "0.5px solid #D5D9D9",
        borderRadius: "4px 4px 0 0",
        height: "39px",
        alignItems: "center",
        backgroundColor: "#EEEEEE",
      }}
    >
      <Box pl="20px" width="65%">
        <Typography fontSize={12} color="#6A6A6A">
          ITEMS
        </Typography>
      </Box>

      <Box pl={2.5} width="35%">
        <Typography fontSize={12} color="#6A6A6A">
          QUANTITY
        </Typography>
      </Box>
    </Box>
  );
};
