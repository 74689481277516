/* eslint-disable react/prop-types */
import React from "react";

export const CartTrashIcon = ({ stroke = "#707070", ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.241"
      height="17.912"
      viewBox="0 0 16.241 17.912"
      {...props}
    >
      <g
        id="Icon_feather-trash-2"
        data-name="Icon feather-trash-2"
        transform="translate(0.6 0.6)"
      >
        <path
          id="Path_2479"
          data-name="Path 2479"
          d="M4.5,9H19.541"
          transform="translate(-4.5 -5.658)"
          fill="none"
          stroke={stroke || "#707070"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.2"
        />
        <path
          id="Path_2480"
          data-name="Path 2480"
          d="M19.2,6.342v11.7a1.671,1.671,0,0,1-1.671,1.671H9.171A1.671,1.671,0,0,1,7.5,18.041V6.342m2.507,0V4.671A1.671,1.671,0,0,1,11.678,3h3.342a1.671,1.671,0,0,1,1.671,1.671V6.342"
          transform="translate(-5.829 -3)"
          fill="none"
          stroke={stroke || "#707070"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.2"
        />
        <path
          id="Path_2481"
          data-name="Path 2481"
          d="M15,16.5v5.014"
          transform="translate(-9.151 -8.979)"
          fill="none"
          stroke={stroke || "#707070"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.2"
        />
        <path
          id="Path_2482"
          data-name="Path 2482"
          d="M21,16.5v5.014"
          transform="translate(-11.808 -8.979)"
          fill="none"
          stroke={stroke || "#707070"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.2"
        />
      </g>
    </svg>
  );
};
