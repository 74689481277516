import * as Yup from "yup";

const containsSpecialChars = (str) => {
  const regex = /[:\t\n]/;

  return regex.test(str);
};

export const validationSchema = () =>
  Yup.object().shape({
    name: Yup.string()
      .trim()
      .required("This field is required")
      .test("character", "Invalid character used", (value) => {
        if (value === "") return true;
        const containsSpecial = containsSpecialChars(value);
        return !containsSpecial;
      }),
    daysInvoices: Yup.number()
      .required("This field is required")
      .typeError("Must be a number.")
      .test("daysInvoices", "Must be a number!", (value) => {
        const FED_REGEXP = /\d/;
        return FED_REGEXP.test(value);
      }),
  });
