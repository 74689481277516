import { cl } from "../styles";

import { Stack, Typography } from "@mui/material";

export const MenuHeader = () => {
  return (
    <Stack sx={cl.headerWrapper}>
      <Typography
        fontSize="14px"
        lineHeight="19px"
        fontWeight={500}
        color="#000000"
      >
        Layout Filters
      </Typography>
    </Stack>
  );
};
