import React, { useCallback, useContext, useMemo, useState } from "react";
import { FilterButton, FilterSelect, FilterWrap } from "./styles";
import { FilterSearchTextField } from "components";
import { Box, Chip, MenuItem } from "@mui/material";
import { FilterIcon } from "components/Icons";
import { func, array, string, object } from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import { MAP_PROSPECT_TYPES } from "./ProspectsFilter.constants";
import { startCase } from "lodash";
import { ProspectsPageContext } from "../../ProspectsTab";

const ProspectsFilter = ({
  onOpenFilters,
  handleDeleteFilter,
  filter,
  handleChangeFilter,
}) => {
  const { filtersState, listState, handleSearchChange } =
    useContext(ProspectsPageContext);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const { categories } = useMemo(() => filtersState, [filtersState.categories]);

  const [searchValue, setSearchValue] = useState("");

  const handleLocalSearchChange = useCallback(
    (e) => {
      if (!e.target) return;
      const { value } = e.target;
      setSearchValue(value);
      handleSearchChange(value);
    },
    [handleSearchChange]
  );

  return (
    <FilterWrap>
      <FilterSelect
        displayEmpty
        renderValue={(key) => {
          return startCase(key);
        }}
        value={filter}
        onChange={handleChangeFilter}
        formSx={{ width: "100%", maxWidth: "159px" }}
      >
        <MenuItem value={MAP_PROSPECT_TYPES.new}>
          New Search ({listState[MAP_PROSPECT_TYPES.new].list.length})
        </MenuItem>
        <MenuItem value={MAP_PROSPECT_TYPES.history}>
          History ({listState[MAP_PROSPECT_TYPES.history].list.length})
        </MenuItem>
        <MenuItem value={MAP_PROSPECT_TYPES.prospects}>
          Prospects ({listState[MAP_PROSPECT_TYPES.prospects].list.length})
        </MenuItem>
      </FilterSelect>
      <FilterSearchTextField
        adminIsAllowed
        value={searchValue}
        onChange={handleLocalSearchChange}
        formSx={{ minWidth: "380px" }}
        placeholderWidth="308px"
        placeholder="Search by customer name or address"
        fullWidth
        adornmentProps={{
          sx: {
            justifyContent: "flex-end",
          },
        }}
        customAdornment={
          <Box
            display="flex"
            gap="5px"
            overflow="auto"
            sx={{
              py: "4px",
              "&::-webkit-scrollbar": {
                height: "2px",
              },
            }}
          >
            {(filter !== MAP_PROSPECT_TYPES.new ? [] : categories).map((c) => (
              <Chip
                key={c.id}
                size="small"
                label={c.name}
                deleteIcon={<CloseIcon size="15" style={{ fill: "#707070" }} />}
                onMouseDown={(e) => e.stopPropagation()}
                onDelete={() => handleDeleteFilter(c.id)}
                sx={{
                  height: "26.5px",
                  border: "0.5px solid #D4D4D4",
                  borderRadius: "4px",
                  backgroundColor: "#FAFAFB",
                }}
              />
            ))}
          </Box>
        }
      />
      <Box display="flex" alignItems="center" gap="4px" pr="12px">
        <FilterButton
          label="Filter"
          startIcon={<FilterIcon />}
          variant="outlined"
          color="edit"
          fontSize="15px"
          onClick={onOpenFilters}
          disabled={filter !== MAP_PROSPECT_TYPES.new}
        />
      </Box>
    </FilterWrap>
  );
};

ProspectsFilter.propTypes = {
  onOpenFilters: func,
  categories: array,
  handleDeleteFilter: func,
  filter: string,
  handleChangeFilter: func,
  counterInfo: object,
};
ProspectsFilter.defaultProps = {
  onOpenFilters: () => {},
  categories: [],
  handleDeleteFilter: () => {},
  filter: MAP_PROSPECT_TYPES.history,
  handleChangeFilter: () => {},
  counterInfo: {},
};

export default ProspectsFilter;
