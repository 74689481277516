export const cl = {
  wrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 1.4,
  },
  confirmText: { fontWeight: "500", color: "#000000" },
  cancelBtn: {
    width: "98px",
    color: "#6A6A6A",
    borderColor: "#D4D4D4",
    height: "28px",
    ml: "auto",
  },
  confirmBtn: {
    width: "98px",
    color: "#FFFFFF",
    height: "28px",
    boxShadow: "none",
  },
};
