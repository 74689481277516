import { useCallback, useEffect, useState } from "react";

import { error } from "utils/notifications";

import { getOrderedProductsByCustomer } from "services/products";
import { useForm, useWatch } from "react-hook-form";
import { calculateAvailable } from "helpers/helpers";

const DEFAULT_FORM_VALUES = {
  products: [],
  productsCount: 0,
};

export const useOrderedItems = ({
  allowFetch,
  customer_id,
  cartProducts,
  isThirdParty,
}) => {
  const [loading, setLoading] = useState(true);

  const [params, setParams] = useState({
    limit: 20,
    sort_by_quantity: "desc",
  });

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    setError,
    formState: { errors, isDirty },
    clearErrors,
    trigger,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: DEFAULT_FORM_VALUES,
  });

  const formField = useWatch({ control });

  const formatProducts = (products) => {
    if (!products.length) return [];
    return products.map((product) => {
      const newProduct = {
        ...product,
        itemDiscountType: "PERCENTAGE",
        itemDiscountAmount: 0,
        isNewAdded: true,
      };
      newProduct.totalQuantity = product?.quantity;
      newProduct.quantity = 0;
      return newProduct;
    });
  };

  const handleSetProductError = (hasError, name, title) => {
    if (hasError)
      return setError(name, {
        type: "custom",
        message: title,
      });
    return clearErrors(name);
  };

  const handleCheckForErrors = (product, quantity, index) => {
    if (quantity === 0) return clearErrors(`products.${index}.quantity`);
    const hasQError =
      product?.minOrderQTY > quantity ||
      product?.product?.minOrderQTY > quantity ||
      product?.parentProduct?.minOrderQTY > quantity ||
      product?.product?.parentProduct?.minOrderQTY > quantity;

    if (hasQError)
      return setError(`products.${index}.quantity`, {
        type: "custom",
        message: "Min Q error",
      });
    return clearErrors(`products.${index}.quantity`);
  };

  const calcAvailable = useCallback((product) => {
    const onHand = product?.inventory?.onHand;
    const allocated = product?.inventory?.allocated;
    return onHand - allocated;
  }, []);

  const handleSubtractQty = (product, qty, index) => {
    if (qty <= 0) return;

    handleCheckForErrors(product, qty - 1, index);
    return setValue(`products.${index}.quantity`, qty - 1, {
      shouldDirty: true,
    });
  };

  const handleAddProduct = (product, qty, index) => {
    const productsMinOrderQTY =
      product.minOrderQTY || product.parentProduct.minOrderQTY;

    const productsQTY =
      qty === 0 && productsMinOrderQTY ? productsMinOrderQTY : 1;

    const productCount = qty === 0 ? productsQTY : qty + 1;

    handleCheckForErrors(product, productCount, index);
    setValue(`products.${index}.quantity`, productCount, {
      shouldDirty: true,
    });
  };

  const handleIsAlreadyInCart = (productId) => {
    return cartProducts.some(({ id }) => id === productId);
  };

  const handleCheckAddBtn = useCallback(() => {
    return !!formField.products.filter(({ quantity }) => quantity > 0).length;
  }, [formField.products]);

  const handleFetch = useCallback(
    async (page) => {
      setLoading(true);
      try {
        const res = await getOrderedProductsByCustomer({
          ...params,
          customer_id,
          page: page ? page : 1,
        });

        const formattedProducts = formatProducts(res?.rows || res || []);

        reset({
          products: page
            ? [...formField?.products, ...formattedProducts]
            : formattedProducts,
          productsCount: res?.count,
        });
        setParams((prev) => ({ ...prev, page: page ? page : 1 }));
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err?.response?.data?.message);
        error(err?.response?.data?.message || "Something went wrong.");
      } finally {
        setLoading(false);
      }
    },
    [customer_id, formField.products, params, reset]
  );

  const getOutOfStock = (product, index) => {
    const available = calculateAvailable(
      product.inventory?.onHand || product.product?.inventory?.onHand,
      product.inventory?.allocated || product.product?.inventory?.allocated
    );
    const qty = formField?.products?.[index]?.quantity - product.quantity;

    return (
      !isThirdParty &&
      !product?.sellingOutOfStock &&
      !product?.product?.sellingOutOfStock &&
      !product?.parentProduct?.sellingOutOfStock &&
      !product?.product?.parentProduct?.sellingOutOfStock &&
      available - qty <= 0
    );
  };

  useEffect(() => {
    if (!allowFetch) return;
    handleFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowFetch, customer_id]);

  return {
    control,
    handleSubmit,
    setValue,
    reset,
    errors,
    isDirty,
    clearErrors,
    handleSetProductError,
    formField,
    trigger,
    loading,
    handleSubtractQty,
    handleCheckAddBtn,
    handleIsAlreadyInCart,
    getOutOfStock,
    handleAddProduct,
    handleFetch,
    calcAvailable,
    params,
  };
};
