import { object, array, bool, func, string, number } from "prop-types";
import { Box, Grid, MenuItem, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { StyledDatePicker, StyledSelect, StyledTooltip } from "components";
import { truncateText } from "helpers/helpers";

export const FulfillBlock = ({
  cl,
  repsList,
  openTooltip,
  setOpenTooltip,
  control,
  deliveryMethod,
  repsSalesUsers,
  repsSalesCount,
  handleFetchReps,
}) => {
  return (
    <Grid sx={cl.contentWrapper} container>
      <Grid xs={3.6} item>
        <Box>
          <Typography sx={cl.textBody}>Fulfill VIA</Typography>
        </Box>
        <Box sx={cl.block}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledSelect
                notched={false}
                fullWidth
                formSx={cl.selectFulfillVia}
                size="small"
                color="#000000"
                displayEmpty
                noErrorMessage
                border="0.5px solid #D5D9D9"
                error={error?.message || ""}
                {...field}
              >
                {repsList.length > 0 ? (
                  <MenuItem value="LOCAL_DELIVERY">Local Delivery</MenuItem>
                ) : (
                  <StyledTooltip
                    key={1}
                    title={
                      "No reps found in the system, in order to fulfill order " +
                      "via local delivery, please add at least 1 rep."
                    }
                    placement="top"
                    arrow
                    open={openTooltip && !repsList.length > 0}
                    onOpen={() => setOpenTooltip(true)}
                    onClose={() => setOpenTooltip(false)}
                  >
                    <Box>
                      <MenuItem
                        disabled={!repsList.length > 0}
                        value="LOCAL_DELIVERY"
                      >
                        Local Delivery
                      </MenuItem>
                    </Box>
                  </StyledTooltip>
                )}
                <MenuItem value="SHIPPING_CARRIER">Shipping carrier</MenuItem>
              </StyledSelect>
            )}
            name="deliveryMethod"
            control={control}
          />
        </Box>
      </Grid>
      <Grid xs={0.6} item />
      <Grid xs={3.6} item>
        <Box>
          <Typography sx={cl.textBody}>Date</Typography>
        </Box>
        <Box sx={cl.block} width="100%">
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledDatePicker
                error={error}
                inputSx={{ fontSize: "12px" }}
                {...field}
                value={field.value || new Date()}
              />
            )}
            name="date"
            control={control}
          />
        </Box>
      </Grid>
      {deliveryMethod === "LOCAL_DELIVERY" && (
        <>
          <Grid xs={0.6} item />
          <Grid xs={3.6} item>
            <Box>
              <Typography sx={cl.textBody}>Fulfill by</Typography>
            </Box>
            <Box sx={cl.block}>
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <StyledSelect
                    notched={false}
                    fullWidth
                    formSx={cl.selectFulfillBy}
                    size="small"
                    color="#000000"
                    error={error?.message || ""}
                    noErrorMessage
                    border="0.5px solid #D5D9D9"
                    dataLength={repsSalesUsers?.length}
                    dataCount={repsSalesCount}
                    handleFetch={handleFetchReps}
                    {...field}
                  >
                    <MenuItem
                      sx={{
                        display: !repsSalesUsers.length > 0 ? "block" : "none",
                      }}
                      value="Select reps"
                    >
                      <Typography
                        fontSize={12}
                        fontWeight={400}
                        color="#5F6267"
                      >
                        Select reps
                      </Typography>
                    </MenuItem>
                    {repsSalesUsers?.map((rep) => (
                      <MenuItem
                        key={rep?.id}
                        value={rep?.id}
                        // onClick={(e) => updateCustomer(e, rep)}
                      >
                        {truncateText(rep?.name, 30)}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                )}
                name="delivererId"
                control={control}
              />
            </Box>
          </Grid>
        </>
      )}
    </Grid>
  );
};

FulfillBlock.propTypes = {
  cl: object,
  repsList: array,
  openTooltip: bool,
  setOpenTooltip: func,
  control: object,
  deliveryMethod: string,
  repsSalesUsers: array,
  repsSalesCount: number,
  handleFetchReps: func,
};
FulfillBlock.defaultProps = {
  cl: {},
  repsList: [],
  openTooltip: false,
  setOpenTooltip: () => {},
  deliveryMethod: "",
  repsSalesUsers: [],
  repsSalesCount: 0,
  handleFetchReps: () => {},
};
