import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  filterWrapper: {
    backgroundColor: "#ffffff",
    borderRadius: "4px",
    display: "flex",
    border: "1px solid #d5d9d9",
    marginTop: "12px",
    alignItems: "center",
    padding: "0px 17px 0px 8px",
    height: "67px",
    gap: "11px",
  },

  // tab: {
  //   textTransform: "none",
  //   fontSize: "20px",
  //   color: "#707070",
  //   "&.Mui-selected": {
  //     backgroundColor: "transparent!important",
  //   },
  //   width: "fit-content",
  //   minWidth: "10px",
  //   paddingLeft: "20px",
  //   paddingRight: "20px",
  // },

  // tabs: {
  //   width: "100%",
  //   maxWidth: "208px",
  // },

  filterChip: {
    borderRadius: "4px",
    border: "0.7px solid #d5d9d9",
    backgroundColor: "#FAFAFB",
    height: "28px",
  },

  // searchButton: {
  //   padding: "0px 15px",
  //   minWidth: "fit-content",
  // },

  // divider: {
  //   height: "14px",
  //   borderColor: "#5F6267",
  // },
}));

export default useStyles;
