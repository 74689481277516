import axios from "axios";

export const getFiltersService = async (type) => {
  return await axios
    .get(`/custom-filters?type=${type}`)
    .then((res) => res.data);
};

export const checkFilterNameService = async (name) => {
  return await axios
    .get(`/custom-filters/check-info-availability?name=${name}`)
    .then((res) => res.data);
};

export const createFilterService = async (data) => {
  return axios.post("/custom-filters", data).then((res) => res.data);
};

export const updateFiltersService = async (id, data) => {
  return axios.put(`/custom-filters/${id}`, data).then((res) => res.data);
};

export const deleteFilterService = async (id) => {
  return axios.delete(`/custom-filters/${id}`).then((res) => res.data);
};
