import React from "react";

export const DefaultIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={33}
    height={25}
    viewBox="0 0 26 18"
    {...props}
  >
    <defs>
      <pattern
        id="a"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 36 25"
      >
        <image
          width={36}
          height={25}
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAZCAYAAABZ5IzrAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAFJSURBVEhL7ZZvToNAEMWfxa0uQWgTUj94ATCph/AWntRbGC9Qm7S1/4ypBFoSIqjrTtIqYIEC5YO/ZMK+Cdm87Ozs7snHF2gQLfo2hsYZSizZeDTCerNGldVkjKHXu4Su65SRxAxNp89YLl8QRRFlqoPzc9j2NSlJzNBw+ITV6g1BEMDzPMqm0263oWkaqXwwdop+/4aUJHUPhWH4bWpfiP/KJNWQoiiZomxSS1YHuUp2LJp/Du12meM4lD0c0Y2GYZCS5O6yMiMrqSskJvF9n7KHIzqSc05KkrRClXbZJmI0+slZK4SCqH5Dr+8q7l2L1Ja77mN+Q0U2tSjJ7jVSxNCfJ7WYPE/83iNFqLxkD/4VqS23F4PsJVss5pjN5rU8P1SVw7JsUpKYIcFkMobruqSqQTzQTNNEp9OljCTR0DH5v1z30TBDwCdCYhTC9AXEYwAAAABJRU5ErkJggg=="
        />
      </pattern>
    </defs>
    <path data-name="Image 72" fill="url(#a)" d="M0 0h26v18H0z" />
  </svg>
);
