import { useMemo } from "react";
import { oneOf, object } from "prop-types";
import { Box } from "@mui/material";
import { useTheme } from "@emotion/react";
import { CheckmarkIcon } from "components/Icons";

export const StatusIcon = ({ status, sx }) => {
  const {
    palette: {
      primary: { main: mainPrimaryColor },
      inactive: { main: mainInactiveColor },
    },
  } = useTheme();

  const COLORS = useMemo(
    () => ({ PENDING: mainInactiveColor, COMPLETED: mainPrimaryColor }),
    [mainInactiveColor, mainPrimaryColor]
  );

  const color = useMemo(() => COLORS[status], [COLORS, status]);

  return (
    <Box
      sx={{
        width: "22px",
        height: "22px",
        border: `1px solid ${color}`,
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...sx,
      }}
    >
      <CheckmarkIcon
        width="8.92"
        height="6.83"
        stroke={color}
        strokeWidth={2.5}
      />
    </Box>
  );
};

StatusIcon.propTypes = { status: oneOf(["PENDING", "COMPLETED"]), sx: object };
StatusIcon.defaultProps = { status: "PENDING", sx: {} };
