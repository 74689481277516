import { func, object, number, bool, string } from "prop-types";
import { Box, FormControlLabel, Typography } from "@mui/material";
import { ReorderBurgerIcon } from "../../../../../../../../components/Icons";
import { LargeCheckbox } from "../../../../../../../../components/Checkboxes";

export const ColumnsSettingsItem = ({
  setValue,
  item,
  index,
  isDisabled,
  currentTab,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        "&:hover": {
          bgcolor: isDisabled ? "" : "#F7F7F7",
          "& svg": {
            opacity: isDisabled ? "" : "1 !important",
          },
        },
        borderRadius: "4px",
        px: 1,
        mx: 1,
        cursor: isDisabled ? "auto" : "pointer",
      }}
    >
      <ReorderBurgerIcon style={{ opacity: 0 }} />

      <FormControlLabel
        sx={{
          ml: "-10px",
          mr: 0,
        }}
        control={
          <LargeCheckbox
            disabled={isDisabled}
            sx={{ p: "8px" }}
            formSx={{ marginX: 0 }}
            size={22}
            checked={!!item?.selected}
            checkedColor={isDisabled ? "#E2E2E2" : "#47a06d"}
            onChange={(e) => {
              if (currentTab === "flat")
                return setValue(
                  `columnsFlat[${index}].selected`,
                  e.target.checked
                );
              if (currentTab === "sorted")
                return setValue(
                  `columnsSorted[${index}].selected`,
                  e.target.checked
                );
              if (currentTab === "Direct")
                return setValue(
                  `columnsDirect[${index}].selected`,
                  e.target.checked
                );
              if (currentTab === "Drafts")
                return setValue(
                  `columnsDrafts[${index}].selected`,
                  e.target.checked
                );
              if (currentTab === "3rd Party")
                return setValue(
                  `columnsThirdParty[${index}].selected`,
                  e.target.checked
                );

              if (
                ["rep_All", "rep_Sales", "rep_Merchandiser"].includes(
                  currentTab
                )
              )
                return setValue(
                  `columnsRepAll[${index}].selected`,
                  e.target.checked
                );
            }}
          />
        }
        label={
          <Typography fontSize={14} fontWeight={400} color="#5F6267">
            {item?.name}
          </Typography>
        }
      />
    </Box>
  );
};

ColumnsSettingsItem.propTypes = {
  item: object,
  index: number,
  setValue: func,
  isDisabled: bool,
  currentTab: string,
};
ColumnsSettingsItem.defaultProps = {
  item: {},
  setValue: () => {},
  isDisabled: false,
};
