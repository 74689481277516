import { useMemo, useState } from "react";
import { func, string, any } from "prop-types";

import { Box, ListSubheader, MenuItem, Typography } from "@mui/material";
import useStyles, { cl } from "./styles";
import { LocationIcon } from "../../../../components/Icons";

import StyledButton from "../../../../components/StyledButton";

import { VIEWS } from "../../DashboardPage.constants";
import { ListIcon } from "../../../../components/Icons/MenuIcons/ListIcon";
import { SelectDatePicker, StyledSelect } from "../../../../components/Selects";
import { ROLES, TYPES, useRepsActions } from "helpers/useRepsActions";

const StyledMenuItem = ({ value, children, ...props }) => {
  return (
    <MenuItem value={value} {...props}>
      <Typography fontSize={12} fontWeight={400} color="#3F3F3F" noWrap>
        {children}
      </Typography>
    </MenuItem>
  );
};
StyledMenuItem.propTypes = {
  value: string,
  children: any,
};

export const NewDashboardFilter = ({
  currentView,
  switchToView,
  handleChangeView,
  handleSetCurrentFilterDate,
  handleSetCurrentRepresentative,
  currentRepresentative,
}) => {
  const classes = useStyles();
  const { wrapper } = cl;

  const [date, setDate] = useState("This week");

  const needMoreSpaceForLongDate = useMemo(() => {
    if (Array.isArray(date) && !!date[1]) return "302px";

    if (Array.isArray(date) && !!date[0]) return "226px";

    return null;
  }, [date]);

  const {
    list: repsList,
    count: repsCount,
    handleFetchReps,
    page: repsPage,
    loading: repsLoading,
  } = useRepsActions({
    type: TYPES.reps,
    params: {
      exclude_roles: JSON.stringify([ROLES.third_party]),
      active: true,
      limit: 500,
    },
  });

  const sortByField = (a, b) => {
    const aName = a?.name?.trim()?.toLowerCase();
    const bName = b?.name?.trim()?.toLowerCase();
    if (aName < bName) return -1;
    if (aName > bName) return 1;
    return 0;
  };

  const salesList = useMemo(() => {
    return Object.values(
      repsList
        .filter((el) => el.role === "SALES")
        .sort(sortByField)
        .reduce((acc, cur) => Object.assign(acc, { [cur.id]: cur }), {})
    );
  }, [repsList]);

  const merchandisersList = useMemo(() => {
    return Object.values(
      repsList
        .filter((el) => el.role === "MERCHANDISER")
        .sort(sortByField)
        .reduce((acc, cur) => Object.assign(acc, { [cur.id]: cur }), {})
    );
  }, [repsList]);

  const handleDateInput = (newDates) => {
    if (newDates) {
      setDate(newDates);
    }
    handleSetCurrentFilterDate(newDates);
  };

  return (
    <>
      <Box mb={"12px"} width="100%" zIndex="100">
        <Box sx={wrapper}>
          <Box display="flex" gap={2.5} width="49.4%">
            <Box width="33.3%" minWidth={needMoreSpaceForLongDate}>
              <SelectDatePicker
                adminIsAllowed
                formSx={{
                  width: "100%",
                  pl: "17px",
                  "& .MuiOutlinedInput-root:not(.Mui-disabled):hover": {
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#d5d9d9",
                    },
                  },
                }}
                value={date}
                noSquare
                height="39px"
                fontSize="15px"
                fullWidth
                sx={{
                  "& > fieldset": {
                    borderRadius: "4px",
                    border: "0.5px solid #D5D9D9",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    filter: "drop-shadow(0.1px 0.2px 2px #47A06D)",
                    borderWidth: "0.3px",
                  },
                }}
                notched={false}
                labelProps={{ shrink: false }}
                textfieldInputSx={{
                  borderRadius: "4px",
                }}
                handleDateInput={handleDateInput}
                label=""
                handleClearValue={() => {
                  setDate("This week");
                  handleSetCurrentFilterDate("This week");
                }}
                onChange={(e) => handleDateInput(e.target.value)}
                data-testid="date-select"
              />
            </Box>
            <Box width="33.3%">
              <StyledSelect
                disabled={repsLoading}
                adminIsAllowed
                displayEmpty
                value={currentRepresentative}
                height="39px"
                fontSize="15px"
                formSx={{
                  width: "100%",
                  "& .MuiOutlinedInput-root:not(.Mui-disabled):hover": {
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#d5d9d9",
                    },
                  },
                }}
                PaperPropsSx={{ maxWidth: "300px" }}
                dataCount={repsCount}
                dataLength={repsList?.length}
                handleFetch={() => handleFetchReps(repsPage + 1)}
                onChange={(e) => handleSetCurrentRepresentative(e.target.value)}
                data-testid="reps-select"
              >
                <MenuItem value="admin">Admin</MenuItem>
                <MenuItem value="">All representatives</MenuItem>
                {salesList?.length && (
                  <ListSubheader className={classes.selectGroupTitle}>
                    Sales
                  </ListSubheader>
                )}
                {salesList?.map((rep) => (
                  <StyledMenuItem key={rep.id} value={rep.id}>
                    {rep.name}
                  </StyledMenuItem>
                ))}
                {merchandisersList?.length && (
                  <ListSubheader className={classes.selectGroupTitle}>
                    Merchandisers
                  </ListSubheader>
                )}
                {merchandisersList?.map((rep) => (
                  <StyledMenuItem key={rep.id} value={rep.id}>
                    {rep.name}
                  </StyledMenuItem>
                ))}
                {/* temporarly hidden */}
                {/* {thirdPartyList?.length && (
                  <ListSubheader className={classes.selectGroupTitle}>
                    Third Party
                  </ListSubheader>
                )} */}
                {/* {thirdPartyList?.map((rep) => (
                  <MenuItem key={rep.id} value={rep.id}>
                    {rep.name}
                  </MenuItem>
                ))} */}
              </StyledSelect>
            </Box>
            <Box width="33.3%" />
          </Box>
          <Box
            display="flex"
            gap={1.5}
            flexGrow={1}
            justifyContent="flex-end"
            pr="17px"
          >
            <StyledButton
              fontSize="16px"
              sx={{
                width: "125px",
                minWidth: "125px",
                maxHeight: "39px",
                flex: "0 1 8%",
                ml: currentView === VIEWS.LIST ? "0px" : "auto",
                backgroundColor: "#fff",
                border: "0.5px solid #D5D9D9",
                whiteSpace: "nowrap",
                zIndex: "100",
                display: "flex",
                gap: "13px",
              }}
              variant="outlined"
              color="edit"
              label={switchToView}
              height="39px"
              onClick={handleChangeView}
              endIcon={
                <Box width="11.44px" height="18.3px">
                  {currentView === VIEWS.LIST ? (
                    <LocationIcon
                      width="11.44px"
                      height="18.3px"
                      fill="#5F6267"
                    />
                  ) : (
                    <ListIcon />
                  )}
                </Box>
              }
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

NewDashboardFilter.propTypes = {
  currentView: string,
  switchToView: string,
  handleChangeView: func,
  handleSetCurrentFilterDate: func,
  handleSetCurrentRepresentative: func,
  handleGetType: func,
  currentRepresentative: string,
};
NewDashboardFilter.defaultProps = {};
