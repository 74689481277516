import React, { useMemo, useRef, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import useStyles from "./styles";
import { Loader, StyledButton, StyledPopper, UploadFile } from "components";
import { object } from "prop-types";
import { CheckmarkIcon } from "components/Icons";
import { STORE_LOGO_ASPECT } from "Pages/SettingsPage/components/SettingsTabs/OrderDirectTab/OrderDirectTab.constants";
import { useAdmin } from "helpers/helpers";

const SetupItem = ({ step }) => {
  const {
    completed,
    handleAction,
    endElement,
    title,
    buttonTitle,
    updatable,
    isUpload,
    buttonCompletedTitle,
    showEndElement,
  } = useMemo(() => step, [step]);
  const classes = useStyles();
  const isAdmin = useAdmin();

  const { id: distributorId } = useSelector(({ auth }) => auth?.currentUser);

  const actionButtonTitle = useMemo(
    () =>
      completed && !!buttonCompletedTitle ? buttonCompletedTitle : buttonTitle,
    [buttonCompletedTitle, buttonTitle, completed]
  );

  const [isCroppedDialogOpen, setIsCroppedDialogOpen] = useState(false);

  const tooltipRef = useRef(null);

  const [tooltipData, setTooltipData] = useState({
    open: false,
    text: (
      <>
        <Typography textAlign="start" fontSize={12}>
          • Use at least 714 px by 200 px <br /> • Use white or neutral
          background
        </Typography>
      </>
    ),
    modifiers: [{ name: "offset", options: { offset: [5, 10] } }],
  });

  const [progressPhotoUpload, setProgressPhotoUpload] = useState(false);
  const [preparingPhoto, setPreparingPhoto] = useState(false);

  const actionButton = useMemo(
    () =>
      isUpload ? (
        isAdmin ? null : (
          <>
            <Loader isLoading={progressPhotoUpload || preparingPhoto} />
            <StyledPopper
              open={tooltipData.open && !isCroppedDialogOpen}
              anchorEl={tooltipRef.current}
              text={tooltipData.text}
              modifiers={tooltipData.modifiers}
              placement="top"
              transition
              aria-hidden="true"
            />
            <Box
              component="span"
              ref={tooltipRef}
              onMouseEnter={() =>
                setTooltipData((prev) => ({ ...prev, open: true }))
              }
              onMouseLeave={() =>
                setTooltipData((prev) => ({ ...prev, open: false }))
              }
              onMouseDown={() =>
                setTooltipData((prev) => ({ ...prev, open: false }))
              }
            >
              <UploadFile
                className={classes.goButton}
                label={actionButtonTitle}
                accept="image/png, image/gif, image/jpeg"
                withCropper
                wrapperProps={{
                  variant: "contained",
                  color: "primary",
                  className: classes.goButton,
                }}
                Wrapper={Button}
                path="users/distributors/brand-logo"
                defaultAspect={STORE_LOGO_ASPECT}
                onChange={handleAction}
                setIsOpen={setIsCroppedDialogOpen}
                uploadData={{
                  distributorId,
                  type: "BRAND_LOGO_DISTRIBUTOR",
                }}
                progressUpload={setProgressPhotoUpload}
                {...{ preparingPhoto, setPreparingPhoto }}
              />
            </Box>
          </>
        )
      ) : (
        <StyledButton
          disabled={isAdmin}
          className={classes.goButton}
          label={actionButtonTitle}
          variant="contained"
          fontSize="13px"
          onClick={handleAction}
        />
      ),
    [
      actionButtonTitle,
      classes.goButton,
      distributorId,
      handleAction,
      isAdmin,
      isCroppedDialogOpen,
      isUpload,
      preparingPhoto,
      progressPhotoUpload,
      tooltipData.modifiers,
      tooltipData.open,
      tooltipData.text,
    ]
  );

  return (
    <Box className={classes.rowWrap}>
      <Box
        sx={{
          background: completed ? "#47A06D" : "#AFAFAF",
        }}
        className={classes.iconWrap}
      >
        <CheckmarkIcon stroke="#fff" width="11.34" height="8.33" />
      </Box>
      <Box className={classes.innerWrap}>
        <Box>
          <Typography className={classes.rowTitle}>{title}</Typography>
          {/* {!completed && (
            <Typography className={classes.description}>
              {step.description}
            </Typography>
          )} */}
        </Box>

        <Box className={classes.buttonWrap}>
          {showEndElement && endElement}
          <Box width="108px">{(!completed || updatable) && actionButton}</Box>
        </Box>
      </Box>
    </Box>
  );
};

SetupItem.propTypes = {
  step: object,
};

export default SetupItem;
