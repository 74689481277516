import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  createButton: {
    position: "fixed",
    right: "calc(19% + 24px + 39px)",
    height: "44px",
    borderRadius: "20px",
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",

    "& .MuiButton-startIcon": { marginRight: 0 },
  },
}));

export default useStyles;
