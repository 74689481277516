import { object, func, bool } from "prop-types";
import { TagField, TagsDrawer } from "components";
import { Grid } from "@mui/material";
import { TAG_TYPES } from "helpers/useTagsActions";
import { useRepsPermissions } from "helpers/hooks";

export const TagsBlock = ({
  setValue,
  formField,
  errors,
  setError,
  viewAllTags,
  setViewAllTags,
  adminIsAllowed,
}) => {
  const repPermissions = useRepsPermissions();

  return (
    <Grid mt="-14px" xs={12} item>
      <TagsDrawer
        type={TAG_TYPES.product}
        open={viewAllTags}
        handleClose={() => setViewAllTags(false)}
        productTags={formField.tags}
        handleSave={({ tags }) => {
          setValue("tags", tags, { shouldDirty: true });
          setViewAllTags(false);
        }}
        setCheckedCustomers={() => {}}
        adminIsAllowed={adminIsAllowed}
      />
      <TagField
        disabled={!!repPermissions && !repPermissions?.catalog?.product_tags}
        fieldName="tags"
        setValue={setValue}
        errors={errors}
        setError={setError}
        handleViewAll={() => setViewAllTags(true)}
        chosenTags={formField.tags}
        type="product"
        adminIsAllowed={adminIsAllowed}
      />
    </Grid>
  );
};

TagsBlock.propTypes = {
  setValue: func,
  formField: object,
  errors: object,
  setError: func,
  viewAllTags: bool,
  setViewAllTags: func,
  adminIsAllowed: bool,
};
