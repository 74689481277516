import { string, number } from "prop-types"
import { Grid, Typography } from "@mui/material";
import { cl } from "../../components.style";

export const TransactionsExpandedTableItem = ({
  date,
  orderId,
  grossTotal,
  fees,
  netTotal,
}) => {
  return (
    <Grid sx={cl.wrapper} container>
      {/* Transaction date */}
      <Grid sx={cl.grid} xs={4.2} item>
        <Typography sx={cl.typographyPlan}>{date}</Typography>
      </Grid>
      {/* Invoice # */}
      <Grid sx={cl.grid} xs={1.4} item>
        <Typography sx={cl.typographyLight}>#{orderId}</Typography>
      </Grid>
      {/* Gross total */}
      <Grid sx={cl.grid} xs={1.4} item>
        <Typography sx={cl.typographyLight}>
          ${grossTotal.toFixed(2)}
        </Typography>
      </Grid>
      {/* Fees */}
      <Grid sx={cl.grid} xs={1.3} item>
        <Typography sx={cl.typographyLight}>
          -${fees.toFixed(2)}
        </Typography>
      </Grid>
      {/* Net total */}
      <Grid sx={cl.grid} xs={2} item>
        <Typography sx={cl.typographyLight}>
          ${netTotal.toFixed(2)}
        </Typography>
      </Grid>
    </Grid>
  );
};

TransactionsExpandedTableItem.propTypes = {
  id: number,
  date: string,
  orderId: string,
  grossTotal: number,
  fees: number,
  netTotal: number,
};
TransactionsExpandedTableItem.defaultProps = {
  id: 0,
  date: "",
  orderId: "",
  grossTotal: 0,
  fees: 0,
  netTotal: 0,
};
