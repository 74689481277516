import { useMemo, useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  PaymentFailedFooter,
  PaymentFailedQuestions,
  PaymentInfo,
  CardItem,
  WarningBox,
  ConfirmSelectionPlanBox,
} from "./components";

import StyledButton from "../StyledButton";
import { ActiveIcon, FilledPlusIcon } from "../Icons";
import CustomerCardPopup from "../../Pages/CustomersPage/components/CustomerCreditCards/components/CustomerCardPopup/CustomerCardPopup";
import {
  payForDistributorSubscriptionService,
  reactivateDistributorSubscriptionService,
  setDefaultPaymentCardService,
  setDistributorUnSubscriptionService,
  subscribeDistributorService,
  switchDistributorSubscriptionService,
  unsubscribeDistributorService,
} from "services/stripe";
import {
  getDistributorPaymentCardsAction,
  setCurrentUser,
  updateUserFieldAction,
} from "redux/actions/auth";
import { error } from "utils/notifications";
import { applyForConfirmationService, getCurrentUser } from "services/account";
import { useTitles } from "./useTitles";
import {
  setPaymentFailedDialogCloseAction,
  setPaymentFailedDialogOpenAction,
  setTrialDialogClose,
  toggleTrialDialogOpenAction,
} from "redux/actions/subscriptionDialogs";

const defaultData = {
  cards: [],
  openCreateCardDialog: false,
  editCard: null,
  loading: false,
  success: false,
  error: "",
};

export const PaymentFailed = () => {
  const { distributorPaymentCards, currentUser } = useSelector(
    ({ auth }) => auth
  );
  const trialDialogIsOpen = useSelector(
    ({ subscriptionDialogs }) => subscriptionDialogs.trialDialog.open
  );

  const paymentFailedDialogIsOpen = useSelector(
    ({ subscriptionDialogs }) => subscriptionDialogs.paymentFailedDialog.open
  );
  const { distributorSubscription, showReactivateDialog, timeZone } =
    currentUser || {};
  const dispatch = useDispatch();

  const [dialogState, setDialogState] = useState(defaultData);

  const hasTrialDialogState = useMemo(() => {
    return currentUser?.trialDialogState;
  }, [currentUser?.trialDialogState]);

  const hasStatusCanceled = useMemo(() => {
    if (
      distributorSubscription?.stripeSubscriptionStatus === "canceled" &&
      distributorSubscription?.failureMessage === null &&
      !trialDialogIsOpen
    ) {
      return true;
    }

    return false;
  }, [
    distributorSubscription?.failureMessage,
    distributorSubscription?.stripeSubscriptionStatus,
    trialDialogIsOpen,
  ]);

  const hasCanceledPaidSubscription = useMemo(() => {
    return (
      currentUser?.subscriptionUsageStatus === "PAID_USED" &&
      distributorSubscription?.stripeSubscriptionStatus === "canceled" &&
      !!currentUser?.distributorSubscription?.subscription?.id
    );
  }, [
    currentUser?.distributorSubscription?.subscription?.id,
    currentUser?.subscriptionUsageStatus,
    distributorSubscription?.stripeSubscriptionStatus,
  ]);

  const hasStatusCanceledWithEndPeriod = useMemo(() => {
    if (
      showReactivateDialog &&
      distributorSubscription?.stripeSubscriptionStatus === "active" &&
      distributorSubscription?.failureMessage === null
    ) {
      return true;
    }
    return (
      distributorSubscription?.stripeSubscriptionStatus === "canceled" &&
      distributorSubscription?.failureMessage === null &&
      !!distributorSubscription?.canBeReactivated &&
      !currentUser?.subscriptionUsageStatus === "TRIAL_USED"
    );
  }, [
    currentUser?.subscriptionUsageStatus,
    distributorSubscription?.canBeReactivated,
    distributorSubscription?.failureMessage,
    distributorSubscription?.stripeSubscriptionStatus,
    showReactivateDialog,
  ]);

  const hasStatusPayFailed = useMemo(() => {
    return (
      distributorSubscription?.stripeSubscriptionStatus === "canceled" &&
      distributorSubscription?.failureMessage !== null &&
      !!distributorSubscription?.canBeReactivated
    );
  }, [
    distributorSubscription?.canBeReactivated,
    distributorSubscription?.failureMessage,
    distributorSubscription?.stripeSubscriptionStatus,
  ]);

  const hasStatusPayTrialFailed = useMemo(() => {
    return (
      currentUser?.subscriptionUsageStatus === "PAID_USED" &&
      distributorSubscription?.stripeSubscriptionStatus === "trialing" &&
      distributorSubscription?.switchingToSubscription
    );
  }, [
    currentUser?.subscriptionUsageStatus,
    distributorSubscription?.stripeSubscriptionStatus,
    distributorSubscription?.switchingToSubscription,
  ]);

  const hasStatusPayFailedAndIncompleted = useMemo(() => {
    return (
      distributorSubscription?.stripeSubscriptionStatus === "incomplete" &&
      distributorSubscription?.failureMessage !== null
    );
  }, [
    distributorSubscription?.failureMessage,
    distributorSubscription?.stripeSubscriptionStatus,
  ]);

  const hasStatusPayFailedAndIncompletedTrial = useMemo(() => {
    return !!distributorSubscription?.switchingToSubscription?.failureMessage;
  }, [distributorSubscription?.switchingToSubscription?.failureMessage]);

  const hasStatusPayFailedAndIncompletedAfter23h = useMemo(() => {
    return (
      distributorSubscription?.stripeSubscriptionStatus ===
        "incomplete_expired" &&
      !!distributorSubscription?.canBeReactivated &&
      distributorSubscription?.failureMessage !== null
    );
  }, [
    distributorSubscription?.canBeReactivated,
    distributorSubscription?.failureMessage,
    distributorSubscription?.stripeSubscriptionStatus,
  ]);

  const hasConfirmSelectionPlan = useMemo(
    () => !!currentUser?.confirmSelectionPlanDialog,
    [currentUser?.confirmSelectionPlanDialog]
  );

  const hasTrialEndedWithNextSubscription = useMemo(() => {
    return (
      currentUser?.subscriptionUsageStatus === "TRIAL_USED" &&
      currentUser?.distributorSubscription === null &&
      currentUser?.delayedSubscriptionDraft
    );
  }, [
    currentUser?.delayedSubscriptionDraft,
    currentUser?.distributorSubscription,
    currentUser?.subscriptionUsageStatus,
  ]);

  const openDialog = useMemo(() => {
    if (hasTrialEndedWithNextSubscription) return true;
    if (currentUser?.confirmSelectionPlanDialog) return true;

    if (currentUser?.trialDialogState?.incomplete) return false;
    return (
      hasStatusCanceled ||
      hasStatusCanceledWithEndPeriod ||
      hasStatusPayFailed ||
      (!showReactivateDialog && hasStatusPayFailedAndIncompleted) ||
      hasStatusPayFailedAndIncompletedAfter23h ||
      !!showReactivateDialog ||
      !!hasTrialDialogState
    );
  }, [
    currentUser?.confirmSelectionPlanDialog,
    currentUser?.trialDialogState?.incomplete,
    hasStatusCanceled,
    hasStatusCanceledWithEndPeriod,
    hasStatusPayFailed,
    hasStatusPayFailedAndIncompleted,
    hasStatusPayFailedAndIncompletedAfter23h,
    hasTrialDialogState,
    hasTrialEndedWithNextSubscription,
    showReactivateDialog,
  ]);

  const { title, titleSuccess, btnName, subTitle } = useTitles({
    hasTrialDialogState,
    hasStatusCanceled,
    hasStatusCanceledWithEndPeriod,
    hasStatusPayFailed,
    hasStatusPayFailedAndIncompleted,
    hasStatusPayFailedAndIncompletedAfter23h,
    hasConfirmSelectionPlan,
    hasTrialEndedWithNextSubscription,
  });

  useEffect(() => {
    setDialogState((prev) => ({
      ...prev,
      cards: distributorPaymentCards?.map((card) => {
        return { ...card, name: card?.billingAddress?.name };
      }),
    }));
  }, [distributorPaymentCards]);

  useEffect(() => {
    if (openDialog) {
      dispatch(setPaymentFailedDialogOpenAction());
      dispatch(setTrialDialogClose());
    } else {
      dispatch(setPaymentFailedDialogCloseAction());
    }
  }, [dispatch, openDialog]);

  useEffect(() => {
    // if (openDialog)
    if (paymentFailedDialogIsOpen)
      return setDialogState((prev) => ({ ...defaultData, cards: prev?.cards }));
    // }, [openDialog]);
  }, [paymentFailedDialogIsOpen]);

  const handleSetDefaultCard = useCallback(
    (card) => {
      setDialogState((prev) => ({ ...prev, loading: true }));
      setDefaultPaymentCardService({
        type: "distributorPayment",
        id: card?.id,
      })
        .then(() => {
          dispatch(getDistributorPaymentCardsAction());
        })
        .catch((err) => {
          error("Something went wrong!");
          // eslint-disable-next-line no-console
          console.error(err?.response?.data?.message);
        })
        .finally(() => {
          setDialogState((prev) => ({ ...prev, loading: false }));
        });
    },
    [dispatch]
  );

  const compareSubscriptionAfterFail = useMemo(() => {
    return (
      distributorSubscription?.switchingToSubscription?.subscription?.id ===
      currentUser?.confirmSelectionPlanDialog?.chosen_plan?.id
    );
  }, [
    currentUser?.confirmSelectionPlanDialog?.chosen_plan?.id,
    distributorSubscription?.switchingToSubscription?.subscription?.id,
  ]);

  const payForDistributorSubscription = (subscriptionId) => {
    payForDistributorSubscriptionService(subscriptionId)
      .then((res) => {
        dispatch(
          updateUserFieldAction("distributorSubscription", {
            ...distributorSubscription,
            ...res,
          })
        );

        if (res?.failureMessage) {
          return setDialogState((prev) => ({
            ...prev,
            error: "Your card was declined",
          }));
        }

        setDialogState((prev) => ({ ...prev, success: true }));
        setTimeout(() => {
          dispatch(updateUserFieldAction("showReactivateDialog", false));
          dispatch(updateUserFieldAction("trialDialogState", null));
          dispatch(updateUserFieldAction("confirmSelectionPlanDialog", null));
        }, 2000);
      })
      .catch((err) => {
        if (err.message) {
          // eslint-disable-next-line no-console
          console.error(err.message);
          setDialogState((prev) => ({
            ...prev,
            error: err.message,
            success: false,
          }));
        }
      })
      .finally(() => {
        setDialogState((prev) => ({ ...prev, loading: false }));
      });
  };

  const handlePaySubscription = () => {
    setDialogState((prev) => ({ ...prev, loading: true }));
    const subsId = () => {
      if (hasStatusPayFailedAndIncompletedTrial) {
        return distributorSubscription?.switchingToSubscription?.id;
      }

      if (currentUser?.trialDialogState && !hasStatusPayFailedAndIncompleted) {
        return currentUser?.trialDialogState?.id;
      }
      if (hasStatusPayFailedAndIncompleted) {
        return distributorSubscription?.id;
      } else {
        return distributorSubscription.reactivatingToSubscription?.id;
      }
    };

    if (!compareSubscriptionAfterFail) {
      unsubscribeDistributorService(
        distributorSubscription?.switchingToSubscription?.id
      )
        .then(() => handleSetConfirm())
        .catch((err) => {
          if (err.message) {
            // eslint-disable-next-line no-console
            console.error(err.message);
            setDialogState((prev) => ({
              ...prev,
              error: err.message,
              success: false,
            }));
          }
        });
      return;
    }

    payForDistributorSubscription(subsId());
  };

  const handleSubscription = () => {
    setDialogState((prev) => ({ ...prev, loading: true }));

    subscribeDistributorService({
      subscriptionId:
        currentUser?.trialDialogState?.id ||
        currentUser?.distributorSubscription?.subscription?.id,
    })
      .then((res) => {
        if (res?.failureMessage) {
          return setDialogState((prev) => ({
            ...prev,
            error: "Your card was declined",
          }));
        }
        setDialogState((prev) => ({ ...prev, success: true }));
        setTimeout(() => {
          dispatch(updateUserFieldAction("showReactivateDialog", false));
          dispatch(updateUserFieldAction("trialDialogState", null));
          dispatch(updateUserFieldAction("confirmSelectionPlanDialog", null));
        }, 2000);
      })
      .then(() => {
        getCurrentUser()
          .then((res) => {
            dispatch(toggleTrialDialogOpenAction());
            dispatch(setCurrentUser(res));
          })
          // eslint-disable-next-line no-console
          .catch((err) => console.error(err));
      })
      .catch((err) => {
        if (err.message) {
          // eslint-disable-next-line no-console
          console.error(err.message);
          setDialogState((prev) => ({
            ...prev,
            error: err?.response?.data?.message || err.message,
          }));
        }
      })
      .finally(() => {
        setDialogState((prev) => ({ ...prev, loading: false }));
      });
  };

  const handleReactivatePayment = useCallback(() => {
    setDialogState((prev) => ({ ...prev, loading: true }));
    setDistributorUnSubscriptionService(distributorSubscription?.id, {
      unsubscribe: false,
    })
      .then((res) => {
        dispatch(updateUserFieldAction("distributorSubscription", res));
        setDialogState((prev) => ({ ...prev, success: true }));
        setTimeout(() => {
          dispatch(updateUserFieldAction("showReactivateDialog", false));
          dispatch(updateUserFieldAction("confirmSelectionPlanDialog", null));
        }, 2000);
      })
      .catch((err) => {
        if (err.message) {
          // eslint-disable-next-line no-console
          console.error(err.message);
          setDialogState((prev) => ({ ...prev, error: err.message }));
        }
      })
      .finally(() => {
        setDialogState((prev) => ({ ...prev, loading: false }));
      });
  }, [dispatch, distributorSubscription?.id]);

  const activateSubscriptionWithDraft = () => {
    setDialogState((prev) => ({ ...prev, loading: true }));
    applyForConfirmationService("", { type: "delayed" })
      .then((data) => {
        if (
          data?.failureMessage ||
          data?.reactivatingToSubscription?.stripeSubscriptionStatus ===
            "incomplete" ||
          data?.reactivatingToSubscription?.stripeSubscriptionStatus ===
            "incomplete_expired"
        ) {
          return setDialogState((prev) => ({
            ...prev,
            error: "Your card was declined",
            loading: false,
          }));
        }

        setDialogState((prev) => ({ ...prev, success: true }));
        setTimeout(() => {
          dispatch(updateUserFieldAction("showReactivateDialog", false));
          dispatch(updateUserFieldAction("confirmSelectionPlanDialog", null));
        }, 2000);
      })
      .then(() => {
        getCurrentUser()
          .then((res) => {
            dispatch(setCurrentUser(res));
          })
          // eslint-disable-next-line no-console
          .catch((err) => console.error(err?.response?.data?.message));
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err?.response?.data?.message);
      })
      .finally(() => {
        setDialogState((prev) => ({ ...prev, loading: false }));
      });
  };

  const handleProcessPayment = useCallback(() => {
    setDialogState((prev) => ({ ...prev, loading: true }));
    reactivateDistributorSubscriptionService(
      currentUser?.distributorSubscription?.id
    )
      .then((data) => {
        if (
          data?.failureMessage ||
          data?.reactivatingToSubscription?.stripeSubscriptionStatus ===
            "incomplete" ||
          data?.reactivatingToSubscription?.stripeSubscriptionStatus ===
            "incomplete_expired"
        ) {
          return setDialogState((prev) => ({
            ...prev,
            error: "Your card was declined",
            loading: false,
          }));
        }

        setDialogState((prev) => ({ ...prev, success: true }));
        setTimeout(() => {
          dispatch(updateUserFieldAction("showReactivateDialog", false));
          dispatch(updateUserFieldAction("confirmSelectionPlanDialog", null));
        }, 2000);
      })
      .then(() => {
        getCurrentUser()
          .then((res) => {
            dispatch(setCurrentUser(res));
          })
          // eslint-disable-next-line no-console
          .catch((err) => console.error(err?.response?.data?.message));
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err?.response?.data?.message);
      })
      .finally(() => {
        setDialogState((prev) => ({ ...prev, loading: false }));
      });
  }, [currentUser?.distributorSubscription?.id, dispatch]);

  const switchDistributorSubscription = (preparedData) => {
    switchDistributorSubscriptionService(preparedData)
      .then((data) => {
        if (
          data?.failureMessage ||
          data?.reactivatingToSubscription?.stripeSubscriptionStatus ===
            "incomplete" ||
          data?.reactivatingToSubscription?.stripeSubscriptionStatus ===
            "incomplete_expired" ||
          data?.data?.switchingToSubscription?.failureMessage
        ) {
          if (data?.data?.switchingToSubscription?.failureMessage) {
            dispatch(
              updateUserFieldAction("distributorSubscription", {
                ...distributorSubscription,
                ...data?.data,
              })
            );
          }

          return setDialogState((prev) => ({
            ...prev,
            error: "Your card was declined",
            loading: false,
          }));
        }
        dispatch(
          updateUserFieldAction("distributorSubscription", {
            ...distributorSubscription,
            ...data?.data,
          })
        );

        setDialogState((prev) => ({ ...prev, success: true }));
        setTimeout(() => {
          dispatch(updateUserFieldAction("showReactivateDialog", false));
          dispatch(updateUserFieldAction("confirmSelectionPlanDialog", null));
        }, 2000);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err?.response?.data?.message);
      })
      .finally(() => {
        setDialogState((prev) => ({ ...prev, loading: false }));
      });
  };

  function handleSetConfirm() {
    setDialogState((prev) => ({ ...prev, loading: true }));

    const currentPlan = currentUser?.confirmSelectionPlanDialog?.current_plan;
    const chosenPlan = currentUser?.confirmSelectionPlanDialog?.chosen_plan;
    const extraUser = currentUser?.confirmSelectionPlanDialog?.extra_user_count;

    const type = extraUser > 0 ? "Request sent" : "Activated";

    const preparedData = {
      oldSubscriptionId: null,
      newSubscriptionId: null,
      extraAllowedUsers: 0,
    };

    if (type === "Request sent") {
      preparedData.oldSubscriptionId = currentPlan?.id;
      preparedData.newSubscriptionId = currentPlan?.subscription?.id;
      preparedData.extraAllowedUsers = extraUser ?? 0;
    }
    if (type === "Activated") {
      preparedData.oldSubscriptionId = currentPlan?.id;
      preparedData.newSubscriptionId = chosenPlan?.id;
      preparedData.extraAllowedUsers = 0;
    }

    switchDistributorSubscription(preparedData);
  }

  const hasDisableBtn = useMemo(() => {
    if (dialogState?.loading) return true;
    return !dialogState.cards.some((card) => card?.defaultMethod);
  }, [dialogState.cards, dialogState?.loading]);
  return (
    <Dialog
      // open={openDialog}
      open={paymentFailedDialogIsOpen}
      onClose={() => {
        if (hasStatusCanceled || hasStatusCanceledWithEndPeriod) {
          dispatch(updateUserFieldAction("showReactivateDialog", false));
        }
        if (currentUser?.confirmSelectionPlanDialog) {
          dispatch(updateUserFieldAction("confirmSelectionPlanDialog", null));
        }
      }}
      sx={{
        ".MuiPaper-root": {
          borderRadius: "18px",
          boxShadow: "none",
          width: "100%",
          maxWidth: "742px",
          overflow: "scroll",
          maxHeight: "fit-content !important",
          "&::-webkit-scrollbar": { display: "none" },
        },
      }}
    >
      <CustomerCardPopup
        skipContactValidate
        replacedCardId={dialogState?.editCard?.id}
        isOpen={dialogState?.openCreateCardDialog}
        setCardDefault
        handleClose={() =>
          setDialogState((prev) => ({
            ...prev,
            openCreateCardDialog: false,
            editCard: null,
          }))
        }
        distributorId={currentUser?.id}
        billingAddress={currentUser?.billingAddress}
        handleSave={(cards) => {
          setDialogState((prev) => ({
            ...prev,
            cards: cards,
            openCreateCardDialog: false,
            editCard: null,
          }));
        }}
      />

      <Box px="112px">
        <DialogTitle
          sx={{
            "&.MuiDialogTitle-root": {
              p: 0,
              height: "90px",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
            },
          }}
        >
          <Typography fontSize={32} fontWeight={700} color="#5F6267">
            {dialogState?.success ? titleSuccess : title}
          </Typography>
        </DialogTitle>
        <Box
          sx={{
            height: subTitle || dialogState?.success ? "76px" : "20px",
            textAlign: "center",
            px: 5,
          }}
        >
          <Typography
            fontSize={dialogState?.success ? 25 : 16}
            fontWeight={400}
            color="#1C1C19"
          >
            {dialogState?.success ? "We're so glad you're back!" : subTitle}
          </Typography>
        </Box>

        <DialogContent
          sx={{
            "&.MuiDialogContent-root": { p: 0 },
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          {dialogState?.success ? (
            <Box
              sx={{
                mb: "76px",
                border: "0.5px solid #EEEEEE",
                borderRadius: "4px",
                height: "174px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Typography fontSize={22} fontWeight={700} color="#000000">
                Activation successful!
              </Typography>
              <Box
                sx={{
                  width: "48px",
                  height: "48px",
                  border: "2px solid #47A06D",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "50%",
                }}
              >
                <ActiveIcon width="21.55" height="16.12" />
              </Box>
            </Box>
          ) : hasConfirmSelectionPlan ? (
            <ConfirmSelectionPlanBox
              state={
                currentUser?.confirmSelectionPlanDialog ||
                currentUser?.delayedSubscriptionDraft
              }
              timeZone={timeZone}
            />
          ) : (
            <PaymentInfo
              hasStatusCanceled={
                !!hasStatusCanceled ||
                !!hasStatusCanceledWithEndPeriod ||
                !!hasTrialDialogState
              }
              hasTrialDialogState={hasTrialDialogState}
              subscription={
                currentUser?.trialDialogState ||
                distributorSubscription ||
                currentUser?.delayedSubscriptionDraft
              }
              currentUser={currentUser}
            />
          )}

          {!dialogState?.success &&
            dialogState?.cards.map((card) => (
              <CardItem
                key={card?.id}
                card={card}
                onEditCard={() => {
                  setDialogState((prev) => ({
                    ...prev,
                    editCard: card,
                    openCreateCardDialog: true,
                  }));
                }}
                isLoading={dialogState?.loading}
                showRadioBtn={
                  !(dialogState?.cards?.length < 2) || hasDisableBtn
                }
                onSetDefaultCard={handleSetDefaultCard}
              />
            ))}
          {!dialogState?.success && dialogState?.cards?.length < 2 && (
            <Box
              sx={{
                border: "0.5px solid #CCCCCC",
                borderRadius: "4px",
                height: "51px",
                px: "18px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <StyledButton
                label="Add Card"
                fontSize="14px"
                startIcon={<FilledPlusIcon />}
                color="blackBtn"
                sx={{ height: "28px", width: "100%" }}
                onClick={() =>
                  setDialogState((prev) => ({
                    ...prev,
                    openCreateCardDialog: true,
                  }))
                }
              />
            </Box>
          )}
          {dialogState.error && !dialogState?.success && (
            <WarningBox error={dialogState.error} />
          )}
        </DialogContent>

        {!dialogState?.success && (
          <DialogActions
            sx={{
              "&.MuiDialogActions-root": {
                p: 0,
                mt: hasConfirmSelectionPlan ? 2 : "105px",
              },
            }}
          >
            <Button
              disabled={hasDisableBtn}
              sx={{
                height: "48px",
                fontSize: 20,
              }}
              fullWidth
              variant="contained"
              onClick={() => {
                if (hasStatusCanceledWithEndPeriod) {
                  handleReactivatePayment();
                  return;
                }
                if (
                  hasStatusPayFailedAndIncompletedAfter23h &&
                  !hasTrialDialogState
                ) {
                  handleProcessPayment();
                  return;
                }
                if (
                  hasStatusPayFailedAndIncompleted ||
                  hasStatusPayFailedAndIncompletedTrial
                ) {
                  handlePaySubscription();
                  return;
                }
                if (
                  hasTrialDialogState ||
                  hasStatusPayFailedAndIncompletedAfter23h ||
                  hasCanceledPaidSubscription
                ) {
                  handleSubscription();
                  return;
                }
                if (hasStatusCanceled) {
                  handleReactivatePayment();
                  return;
                }
                if (distributorSubscription?.reactivatingToSubscription) {
                  handlePaySubscription();
                  return;
                }
                if (hasConfirmSelectionPlan && !hasStatusPayTrialFailed) {
                  handleSetConfirm();
                  return;
                }
                if (hasTrialEndedWithNextSubscription) {
                  activateSubscriptionWithDraft();
                  return;
                }
                handleProcessPayment();
              }}
            >
              {dialogState?.loading ? "Loading..." : btnName}
            </Button>
          </DialogActions>
        )}
      </Box>
      {!dialogState?.success && (
        <>
          <PaymentFailedQuestions />
          <PaymentFailedFooter />
        </>
      )}
    </Dialog>
  );
};
