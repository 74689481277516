import * as React from "react";
export const IosArrowForward = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={5.206}
    height={9.105}
    viewBox="0 0 5.206 9.105"
    {...props}
  >
    <path
      data-name="Icon ionic-ios-arrow-forward"
      d="M3.637 4.551.192 1.104a.648.648 0 0 1 0-.919.656.656 0 0 1 .922 0l3.9 3.9a.65.65 0 0 1 .019.9l-3.92 3.928a.651.651 0 1 1-.922-.919Z"
      fill="#5f6267"
    />
  </svg>
);
