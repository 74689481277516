import { array, number, object, func, bool } from "prop-types";
import { Box } from "@mui/material";
import { RoutesItem } from "./components";

export const RoutesBlock = ({
  routes,
  headerBlockWidth,
  classes,
  repPermissions,
  handleOpenRoutesDrawer,
  customer,
  routeItemsRef,
  tooltipIsOpen,
  headerBlockRef,
}) => {
  return (
    <Box py={1}>
      <RoutesItem
        {...{
          routes,
          headerBlockWidth,
          classes,
          repPermissions,
          handleOpenRoutesDrawer,
          customer,
          routeItemsRef,
          tooltipIsOpen,
          headerBlockRef,
        }}
      />
    </Box>
  );
};

RoutesBlock.propTypes = {
  routes: array,
  headerBlockWidth: number,
  classes: object,
  repPermissions: object,
  handleOpenRoutesDrawer: func,
  customer: object,
  routeItemsRef: object,
  headerBlockRef: object,
  showRouteArrows: bool,
  tooltipIsOpen: bool,
};
RoutesBlock.defaultProps = {
  routes: [],
  routesTitleWidth: 0,
  classes: {},
  repPermissions: {},
  handleOpenRoutesDrawer: () => {},
  customer: {},
  showRouteArrows: false,
  tooltipIsOpen: false,
};
