import React from "react";

export const DiscountsIconFilled = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19.497 19.498"
      {...props}
    >
      <g
        id="tabler:discount-2_Default"
        data-name="tabler:discount-2/Default"
        transform="translate(0.75 0.75)"
      >
        <g id="Group" transform="translate(-3.011 -3.011)">
          <path
            id="Vector"
            d="M1.989,4.189a2.2,2.2,0,0,1,2.2-2.2h1a2.2,2.2,0,0,0,1.55-.64l.7-.7a2.2,2.2,0,0,1,3.12,0l.7.7a2.2,2.2,0,0,0,1.55.64h1a2.2,2.2,0,0,1,2.2,2.2v1a2.2,2.2,0,0,0,.64,1.55l.7.7a2.2,2.2,0,0,1,0,3.12l-.7.7a2.2,2.2,0,0,0-.64,1.55v1a2.2,2.2,0,0,1-2.2,2.2h-1a2.2,2.2,0,0,0-1.55.64l-.7.7a2.2,2.2,0,0,1-3.12,0l-.7-.7a2.2,2.2,0,0,0-1.55-.64h-1a2.2,2.2,0,0,1-2.2-2.2v-1a2.2,2.2,0,0,0-.64-1.55l-.7-.7a2.2,2.2,0,0,1,0-3.12l.7-.7a2.2,2.2,0,0,0,.64-1.55Z"
            transform="translate(3.011 3.011)"
            fill="#409a65"
            stroke="#409a65"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M0,6,6,0"
            transform="translate(9 9)"
            fill="#fff"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            id="Vector-3"
            data-name="Vector"
            d="M1,.5A.5.5,0,1,1,.5,0,.5.5,0,0,1,1,.5Z"
            transform="translate(9 9)"
            fill="#fff"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            id="Vector-4"
            data-name="Vector"
            d="M1,.5A.5.5,0,1,1,.5,0,.5.5,0,0,1,1,.5Z"
            transform="translate(14 14)"
            fill="#fff"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
        </g>
      </g>
    </svg>
  );
};
