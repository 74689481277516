import { useMemo } from "react";

export const useNewRepresentativesFilter = ({ checkedUsers }) => {
  const separateSelectedUsers = useMemo(() => {
    return checkedUsers.reduce((acc, cur) => {
      if (acc[cur.role]) return { ...acc, [cur.role]: [...acc[cur.role], cur] };
      return { ...acc, [cur.role]: [cur] };
    }, {});
  }, [checkedUsers]);

  const isDisabledResetBtn = useMemo(() => {
    const { MERCHANDISER, SALES, THIRD_PARTY } = separateSelectedUsers;
    return !MERCHANDISER?.length && !SALES?.length && THIRD_PARTY?.length;
  }, [separateSelectedUsers]);

  const setTextConfirmReset = useMemo(() => {
    if (separateSelectedUsers?.THIRD_PARTY?.length)
      return "Please confirm that you would like to reset the passwords for the selected employee(s)? Only employees with accounts in the Merchandiser and Sales roles will receive an email to reset their password. This action will NOT apply to 3rd Party Representatives.";
    return "Please confirm that you would like to reset the passwords for the selected employee(s). Each employee will receive an email to reset password.";
  }, [separateSelectedUsers?.THIRD_PARTY?.length]);

  return {
    separateSelectedUsers,
    isDisabledResetBtn,
    setTextConfirmReset,
  };
};
