import { func, string, object } from "prop-types";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { LargeCheckbox, StyledDatePicker, StyledTimePicker } from "components";
import moment from "moment-timezone";
import AdapterMoment from "@mui/lab/AdapterMoment";

import { AlarmClockIcon } from "components/Icons";
import { IntervalContainer } from "components/TaskDrawer/components/IntervalContainer";

export const DueDateDrawerBody = ({
  onSubmit,
  timeZone,
  control,
  setError,
  clearErrors,
  setValue,
  formField,
}) => {
  const isValidDate = (str) => {
    if (!str) return false;
    const parsedDate = moment(str, "M/D/YYYY", true);

    return parsedDate.isValid();
  };

  return (
    <Box
      overflow="auto"
      flexGrow={1}
      width="544px"
      maxWidth="544px"
      component="form"
      id="due-date-drawer-form"
      onSubmit={onSubmit}
    >
      <Grid
        sx={{
          mt: 3,
          px: 4,
        }}
        columnSpacing={1.75}
        container
      >
        <Grid xs={8} item>
          <Controller
            render={({ field, fieldState: { error } }) => {
              return (
                <StyledDatePicker
                  value={moment.tz(field.value, timeZone)}
                  dateLibInstance={moment}
                  dateAdapter={AdapterMoment}
                  // wrapperClassName={classes.calendarInput}
                  textFieldProps={{ fullWidth: true, noErrorMessage: true }}
                  inputProps={{ placeholder: "Due date" }}
                  inputSx={{
                    height: "43px",
                    fontSize: "13px",
                    color: "#5F6267",
                  }}
                  error={error?.message ? error.message : ""}
                  onChange={(time, keyboardVal) => {
                    let isoTime = "";
                    if (keyboardVal) {
                      if (!isValidDate(keyboardVal)) {
                        setError("dueDate", {
                          type: "dueDateValidate",
                          message: "Incorrect date",
                        });
                      } else {
                        clearErrors("dueDate");
                      }

                      const formattedDate = moment
                        .tz(keyboardVal, "MM/DD/YYYY", timeZone)
                        .utc()
                        .format();

                      const isValidFormattedDate =
                        moment(formattedDate).isValid();

                      isoTime =
                        isValidFormattedDate && formattedDate
                          ? formattedDate
                          : "";
                    } else if (time) {
                      isoTime = moment
                        .tz(time, "MM/DD/YYYY", timeZone)
                        .hours(0)
                        .minutes(0)
                        .seconds(0)
                        .utc()
                        .toISOString();
                    } else {
                      if (!keyboardVal) {
                        setValue("dueDate", null);
                        clearErrors("dueDate");
                        return;
                      }
                    }
                    field.onChange(isoTime);
                  }}
                />
              );
            }}
            name="dueDate"
            control={control}
          />
        </Grid>
        <Grid xs={4} item>
          <Controller
            render={({ field }) => {
              return (
                <StyledTimePicker
                  disabled={!formField.addTime}
                  adminIsAllowed
                  timezone="UTC"
                  dateLibInstance={moment}
                  dateAdapter={AdapterMoment}
                  textFieldProps={{
                    fullWidth: true,
                    formSx: { backgroundColor: "#ffffff" },
                  }}
                  OpenPickerIcon={AlarmClockIcon}
                  InputProps={{
                    sx: {
                      height: "43px",
                      fontSize: "13px",
                      color: "#5F6267",
                    },
                  }}
                  am={true}
                  value={moment.tz(formField?.dueTime, timeZone).utc()}
                  onChange={(time) => {
                    const isoTime = new Date(time).toISOString();
                    setValue(field?.name, isoTime);
                  }}
                />
              );
            }}
            name="dueTime"
            control={control}
          />
        </Grid>
        <Grid xs={12} item>
          <Stack mt={3} direction="row" alignItems="center" gap="9px">
            <Box>
              <Controller
                render={({ field }) => (
                  <LargeCheckbox
                    checked={!!field.value}
                    size={16}
                    formSx={{ m: 0 }}
                    sx={{ padding: 0 }}
                    {...field}
                    onChange={(e, newVal) => {
                      setValue("addTime", newVal);

                      if (!newVal) {
                        setValue("repeatInterval", "day");
                        setValue("repeatStep", "1");
                      }
                    }}
                  />
                )}
                name="addTime"
                control={control}
              />
            </Box>
            <Typography variant="header" fontSize="13px">
              Add time
            </Typography>
          </Stack>
        </Grid>
      </Grid>

      {/* Interval */}
      <Box
        sx={{
          mt: 1,
          px: 4,
          height: "35px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <IntervalContainer
          control={control}
          formField={formField}
          setValue={setValue}
        />
      </Box>
    </Box>
  );
};

DueDateDrawerBody.propTypes = {
  onSubmit: func,
  timeZone: string,
  control: object,
  setError: func,
  clearErrors: func,
  setValue: func,
  formField: object,
};
DueDateDrawerBody.defaultProps = {
  onSubmit: () => {},
  timeZone: "",
  control: {},
  setError: () => {},
  clearErrors: () => {},
  setValue: () => {},
  formField: {},
};
