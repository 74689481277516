import { bool, shape, number } from "prop-types";
import { Box, Paper } from "@mui/material";
import { TotalSummaryInfoBlock } from "../../../../../../components";
import { separateNumWithComma } from "../../../../../../helpers/helpers";

export const ThirdPartyOrdersTotalSummaries = ({
  isLoading,
  totalSummaries,
}) => {
  return (
    <Paper
      sx={{
        mt: "4px",
        px: 1.75,
        height: "64px",
        maxHeight: "64px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
      elevation={0}
      variant="outlined"
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <TotalSummaryInfoBlock
          isLoading={isLoading}
          items={[
            {
              name: "Total Sales",
              value: totalSummaries?.totalSales
                ? `$ ${separateNumWithComma(totalSummaries?.totalSales)}`
                : 0,
            },
            { name: "Orders", value: totalSummaries?.totalOrders ?? 0 },
            {
              name: "Items Ordered",
              value: totalSummaries?.totalOrderedItems ?? 0,
            },
          ]}
        />
      </Box>
      <Box />
    </Paper>
  );
};

ThirdPartyOrdersTotalSummaries.propTypes = {
  isLoading: bool,
  totalSummaries: shape({
    totalSales: number,
    totalOrders: number,
    totalOrderedItems: number,
  }),
};
