import { object, func, any } from "prop-types";
import { Box } from "@mui/material";

export const HoursItem = ({
  classes,
  repPermissions,
  customer,
  handleOpenReceivingHours,
  workingTime,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
      }}
    >
      <Box
        fontSize={13}
        color="#5F6267"
        className={classes.routesTitle}
        onClick={() => {
          if (repPermissions && handleOpenReceivingHours) {
            if (repPermissions?.routes?.create_edit) {
              handleOpenReceivingHours(customer);
              return;
            }
            return;
          }
          if (handleOpenReceivingHours) handleOpenReceivingHours(customer);
        }}
      >
        Assign hours
      </Box>

      <Box display="flex" alignItems="center">
        {workingTime}
      </Box>
    </Box>
  );
};

HoursItem.propTypes = {
  classes: object,
  repPermissions: object,
  customer: object,
  handleOpenReceivingHours: func,
  workingTime: any,
};
HoursItem.defaultProps = {
  classes: {},
  repPermissions: {},
  customer: {},
};
