import { func, shape, array, string, bool } from "prop-types";
import { CircularProgress, Link, Typography } from "@mui/material";
import moment from "moment";
import { useMemo } from "react";
import pluralize from "pluralize";

export const LastOrderColumn = ({
  customer,
  // currentUser,
  handleDownloadInvoice,
  pageLoading,
}) => {
  const daysAgo = useMemo(
    () => moment().diff(customer.orders[0]?.createdAt, "days"),
    [customer.orders]
  );

  const calendar = { 0: "Today", 1: "Yesterday" };

  return (
    <Link
      underline="hover"
      color="groundLighter.main"
      component="button"
      onClick={() => handleDownloadInvoice(customer.orders[0].id)}
      sx={{ position: "relative" }}
    >
      <Typography
        sx={{
          fontSize: 12,
          fontWeight: 400,
        }}
      >
        {calendar[daysAgo] ?? `${pluralize("day", daysAgo, true)} ago`}
      </Typography>
      {pageLoading && (
        <CircularProgress
          size={20}
          sx={{ position: "absolute", top: 0, right: -27 }}
        />
      )}
    </Link>
  );
};

LastOrderColumn.propTypes = {
  customer: shape({
    orders: array,
  }),
  handleDownloadInvoice: func,
  currentUser: shape({ timeZone: string }),
  pageLoading: bool,
};
