import { makeStyles } from "@mui/styles";
import backgroundImg from "assets/images/login-background.jpg";

const useStyles = makeStyles(() => ({
  background: {
    background: `linear-gradient(0deg, rgba(225, 225, 225, 0.7), rgba(225, 225, 225, 0.7)), url(${backgroundImg})`,
    backgroundSize: "100% 100%",
  },

  mainBlock: {
    maxWidth: "660px",
    width: "100%",
    padding: "5%",
    borderRadius: "8px",
    backgroundColor: "rgba(255, 255, 255)",
  },

  input: {
    height: "64px",
    fontSize: "18px",
    borderRadius: "8px",
    "&.Mui-error > fieldset": {
      filter: "drop-shadow(0.1px 0.2px 2px #EB4233)",
    },
  },

  inputLabel: {
    fontSize: "18px",
    fontWeight: 400,
    color: "#B5B5AC",
    top: 4,
    "&.Mui-focused": {
      top: 0,
    },
    "&.MuiFormLabel-filled": {
      top: -1,
    },
  },

  loginButton: {
    fontSize: "19px",
    height: "67px",
    borderRadius: "8px",
  },

  resetButton: {
    fontSize: "18px",
    height: "67px",
    borderRadius: "8px",
  },

  bottomPanel: {
    marginTop: "22px",
    justifyContent: "space-between",
    "@media only screen and (max-width: 1550px)": {
      width: "100%",
    },
  },
}));

export default useStyles;
