import { string, bool, object, func, oneOf } from "prop-types";
import { Paper, Typography } from "@mui/material";
import useStyles from "Pages/CartPage/styles";
import { Controller } from "react-hook-form";
import { StyledTextField } from "components";
import FulfillBy from "../FulfillBy";
import { TagsBlock } from "./components";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import { ORDER_TYPES } from "Pages/CartPage/CartPage.constants";

export const OrderNoteBlock = ({
  customersSearchInput,
  isAdmin,
  control,
  setValue,
  errors,
  setError,
  clearErrors,
  createOrderType,
}) => {
  const currentUser = useSelector(({ auth }) => auth.currentUser);
  const { timeZone } = currentUser || {};

  const classes = useStyles({ customersSearchInput });

  const hideFulfillBy = useMemo(() => {
    return ORDER_TYPES["3rd Party"] === createOrderType;
  }, [createOrderType]);

  return (
    <Paper
      className={classes.section}
      sx={{
        mt: "17px",
        p: "9px 32px 28px 28px",
      }}
    >
      <Typography
        color="#707070"
        fontWeight={600}
        fontSize="clamp(15px, 1.3vw, 25px)"
      >
        Order Note
      </Typography>
      <Controller
        render={({ field }) => (
          <StyledTextField
            disabled={isAdmin}
            fullWidth
            formSx={{ mt: "12px" }}
            size="small"
            placeholder="Add order note here"
            multiline
            rows={6}
            InputProps={{
              className: classes.multiTextInput,
            }}
            InputLabelProps={{
              className: classes.multiInputLabel,
            }}
            {...field}
            onChange={(e) => {
              if (isAdmin) return;
              field.onChange(e);
            }}
          />
        )}
        name="note"
        control={control}
      />
      {hideFulfillBy ? null : (
        <Controller
          render={({ field }) => (
            <FulfillBy
              value={field.value}
              onChange={(time, keyboardVal) => {
                let isoTime = "";
                if (keyboardVal) {
                  const formattedDate = moment
                    .tz(keyboardVal, "MM/DD/YYYY", timeZone)
                    .endOf("day")
                    .toISOString();

                  const isValidFormattedDate = moment(formattedDate).isValid();

                  isoTime =
                    isValidFormattedDate && formattedDate ? formattedDate : "";
                } else if (time) {
                  isoTime = moment
                    .tz(time, timeZone)
                    .endOf("day")
                    .toISOString();
                }
                field.onChange(isoTime);
              }}
            />
          )}
          name="fulfillBy"
          control={control}
        />
      )}

      <TagsBlock
        {...{
          control,
          setValue,
          errors,
          setError,
          clearErrors,
        }}
      />
    </Paper>
  );
};

OrderNoteBlock.propTypes = {
  customersSearchInput: string,
  isAdmin: bool,
  control: object,
  setValue: func,
  errors: object,
  setError: func,
  clearErrors: func,
  createOrderType: oneOf([ORDER_TYPES.Direct, ORDER_TYPES["3rd Party"]]),
};
OrderNoteBlock.defaultProps = {
  customersSearchInput: "",
  isAdmin: false,
  createOrderType: ORDER_TYPES.Direct,
};
