import { useState } from "react";

export const useShowPicture = () => {
  const [showPictureState, setShowPictureState] = useState({
    openImagePreview: false,
    image: null,
  });

  const handleShowPicture = (f) => {
    const { fileName } = f || {};

    // eslint-disable-next-line no-undef
    const src = `${process.env.REACT_APP_GOOGLE_BUCKET_DOCUMENT}/${fileName}`;

    setShowPictureState((prev) => ({
      ...prev,
      openImagePreview: true,
      image: { src, alt: fileName?.split("/")?.pop() },
    }));
  };

  const handleCloseShowPicture = () => {
    setShowPictureState((prev) => ({
      ...prev,
      openImagePreview: false,
      image: null,
    }));
  };

  return {
    ...showPictureState,
    handleShowPicture,
    handleCloseShowPicture,
  };
};
