import { Grid, Paper, Typography } from "@mui/material";

const DeliveryProductsHeader = () => {
  const classes = {
    grid: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    typography: {
      fontSize: "12px",
      color: "#6A6A6A",
      textTransform: "uppercase",
    },
  };
  return (
    <Paper
      sx={{
        backgroundColor: "#EEEEEE",
        borderRadius: "4px 4px 0 0",
        borderTop: "none",
        borderLeft: "none",
        borderRight: "none",
      }}
      elevation={0}
      variant="outlined"
      square
      component={Grid}
      container
      columns={12}
      height="39px"
    >
      <Grid item xs={5.5} sx={classes.grid}>
        <Typography sx={classes.typography} pl="20px">
          Items
        </Typography>
      </Grid>

      <Grid item xs sx={classes.grid}>
        <Typography sx={classes.typography}>Price paid</Typography>
      </Grid>
      <Grid item xs={3.5} sx={classes.grid}>
        <Typography sx={classes.typography}>Quantity</Typography>
      </Grid>
    </Paper>
  );
};

export default DeliveryProductsHeader;
