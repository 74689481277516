export const getAddressComponents = (location) => {
  const address_components = location.address_components;

  const streetName =
    address_components.find((comp) => comp.types.includes("route"))
      ?.short_name || "";
  const streetNum =
    address_components.find((comp) => comp.types.includes("street_number"))
      ?.short_name || "";
  const city =
    address_components.find((comp) => comp.types.includes("locality"))
      ?.short_name ||
    address_components.find((comp) =>
      comp.types.includes("administrative_area_level_1")
    )?.long_name ||
    "";

  const state =
    address_components.find((comp) =>
      comp.types.includes("administrative_area_level_1")
    )?.short_name || "";

  const zip =
    address_components.find((comp) => comp.types.includes("postal_code"))
      ?.short_name || "";

  return {
    street: streetName ? `${streetNum} ${streetName}` : "",
    city,
    state,
    zip,
  };
};
