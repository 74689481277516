import {} from "react";
import {} from "prop-types";
import { Box, Typography } from "@mui/material";

const file = {
  id: "1",
  fileName: "Image.png",
};

export const HeaderManegePage = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "72px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        pl: "35px",
      }}
    >
      <Typography
        sx={{
          fontSize: 16,
          fontWeight: 600,
          color: "#5F6267",
        }}
      >
        Manage Customers /{" "}
        <Box color="#47A06D" component="span">
          {file?.fileName}
        </Box>
      </Typography>
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: 400,
          color: "#5F6267",
        }}
      >
        2 customers are linked with {file?.fileName}
      </Typography>
    </Box>
  );
};

HeaderManegePage.propTypes = {};
HeaderManegePage.defaultProps = {};
