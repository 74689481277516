import { useContext, useRef } from "react";
import { Grid } from "@mui/material";
import { Controller } from "react-hook-form";
import { StyledPopper, StyledTextField } from "components";
import { CustomerContext } from "Pages/CustomersPage/pages/CustomerPage/CustomerPage";

export const DisplayNameBlock = () => {
  const { control } = useContext(CustomerContext);
  const displayNameRef = useRef(null);

  return (
    <Grid xs={12} item>
      <Controller
        render={({ field, fieldState: { error } }) => (
          <>
            <StyledPopper
              open={!!error && error.type === "custom"}
              anchorEl={displayNameRef ? displayNameRef.current : null}
              text={error ? error.message : ""}
              modifiers={[
                {
                  name: "offset",
                  options: { offset: [10, 10] },
                },
              ]}
              placement="top"
              transition
              aria-hidden="true"
            />

            <StyledTextField
              adminIsAllowed
              fullWidth
              size="small"
              InputLabelProps={{
                sx: { fontSize: "13px", color: "#5F6267", top: 2 },
              }}
              error={error ? error.message : ""}
              label="Display name"
              noErrorMessage
              InputProps={{ sx: { fontSize: "13px", height: "43px" } }}
              {...field}
              ref={displayNameRef}
              value={field.value}
            />
          </>
        )}
        name="displayName"
        control={control}
      />
    </Grid>
  );
};
