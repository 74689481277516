import { string } from "prop-types";
import { Link } from "@mui/material";
import { AppStoreBtnIcon } from "./AppStoreBtnIcon";

export const AppStoreBtn = ({ url }) => {
  return (
    <Link href={url} target="_blank">
      <AppStoreBtnIcon />
    </Link>
  );
};

AppStoreBtn.propTypes = { url: string };
AppStoreBtn.defaultProps = { url: "" };
