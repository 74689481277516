import React from "react";
/* eslint-disable react/prop-types */
export const EyeIcon = ({ height, width, fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "14.973"}
      height={height || "9.625"}
      viewBox="0 0 14.973 9.625"
    >
      <path
        id="Path_2455"
        d="M9.73,7.875c-2.687,0-4.806,1.691-7.339,4.455a.528.528,0,0,0,0,.712C4.557,15.438,6.469,17.5,9.73,17.5c3.222,0,5.638-2.6,7.356-4.478a.524.524,0,0,0,.017-.692C15.352,10.208,12.929,7.875,9.73,7.875Zm.147,7.817a3.008,3.008,0,1,1,2.864-2.864A3.009,3.009,0,0,1,9.877,15.692Z"
        transform="translate(-2.252 -7.875)"
        fill={fill || "#707070"}
      />
      <path
        id="Path_2456"
        d="M15.64,14.068a1.57,1.57,0,0,1,.1-.565c-.033,0-.067,0-.1,0a2.139,2.139,0,1,0,2.139,2.139c0-.043,0-.087,0-.13a1.482,1.482,0,0,1-.608.13A1.552,1.552,0,0,1,15.64,14.068Z"
        transform="translate(-8.154 -10.826)"
        fill={fill || "#707070"}
      />
    </svg>
  );
};
