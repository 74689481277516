import {
  START_LOADING_DISCOUNTS,
  CREATE_DISCOUNT,
  DELETE_DISCOUNT,
  END_LOADING_DISCOUNTS,
  GET_DISCOUNTS,
  GET_DISCOUNTS_COUNT,
  UPDATE_DISCOUNT,
} from "../actions/discounts";

const initialState = {
  discounts: [],
  loading: false,
  count: 0,
};

const discountsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_DISCOUNTS:
      return { ...state, discounts: payload };

    case GET_DISCOUNTS_COUNT:
      return { ...state, count: payload };

    case CREATE_DISCOUNT:
      return { ...state, discounts: [...state.discounts, payload] };

    case DELETE_DISCOUNT: {
      const discountsList = [...state.discounts];
      const index = discountsList.findIndex(
        (discount) => discount.id === payload.id
      );
      if (index >= 0) {
        discountsList.splice(index, 1);
      }
      return { ...state, discounts: discountsList, loading: false };
    }

    case UPDATE_DISCOUNT: {
      const tagsList = [...state.discounts];
      const updateIndex = tagsList.findIndex(
        (customer) => customer.id === payload.id
      );
      if (updateIndex >= 0) {
        tagsList.splice(updateIndex, 1, payload);
      }
      return { ...state, discounts: tagsList, loading: false };
    }

    case END_LOADING_DISCOUNTS:
      return { ...state, loading: false };

    case START_LOADING_DISCOUNTS:
      return { ...state, loading: true };

    default:
      return state;
  }
};

export default discountsReducer;
