import { useMemo, useState } from "react";
import { object, string, func } from "prop-types";
import moment from "moment-timezone";
import { StyledTooltip } from "components";
import { Box } from "@mui/material";
import { LocationIcon } from "components/Icons";

const cl = {
  wrapper: {
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  overLine: {
    width: "130%",
    height: "1px",
    border: "0.5px solid #515151",
    position: "absolute",
    transform: "rotateY(0deg) rotate(135deg)",
  },
  checkedBlock: {
    height: "24px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
};

export const CheckInBlock = ({ stop, timeZone, handleClickCheckIn }) => {
  const { wrapper, overLine, checkedBlock } = cl;

  const { checkedInAt, checkedOutAt, representativeStoppedAt, locationType } =
    stop || {};

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const greenColor = { main: "#26874B", background: "#E7F5EB" };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const redColor = { main: "#FF6254", background: "#FFEFEF" };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const greyColor = { main: "#5F6267", background: "#D4D4D450" };

  const setColor = useMemo(() => {
    if (locationType === "NO_LOCATION_PROVIDED") return greyColor;

    if (locationType === "OUTSIDE_STORE") return redColor;

    if (checkedInAt) return greenColor;

    return redColor;
  }, [checkedInAt, greenColor, greyColor, locationType, redColor]);

  const initialTooltip = { open: false, title: "" };

  const [tooltipState, setTooltipState] = useState(initialTooltip);

  const handleClose = () => {
    setTooltipState((prev) => ({ ...prev, open: false }));
  };

  const handleOpen = () => {
    setTooltipState({
      open: true,
      title: (
        <Box width="150px">
          <Box>
            {moment.tz(representativeStoppedAt, timeZone).format("MMM D, YYYY")}
          </Box>

          {checkedInAt ? (
            <Box
              sx={{
                ...checkedBlock,
                mt: 1,
                pt: 1,
                borderTop: "0.5px solid #CCCCCC",
              }}
            >
              <Box>Check In:</Box>
              <Box>{moment.tz(checkedInAt, timeZone).format("h:mm a")}</Box>
            </Box>
          ) : (
            <Box
              sx={{
                ...checkedBlock,
                mt: 1,
                pt: 1,
                borderTop: "0.5px solid #CCCCCC",
              }}
            >
              <Box>Skipped check in</Box>
            </Box>
          )}

          {checkedOutAt ? (
            <Box sx={checkedBlock}>
              <Box>Check Out:</Box>
              <Box>{moment.tz(checkedOutAt, timeZone).format("h:mm a")}</Box>
            </Box>
          ) : null}
        </Box>
      ),
    });
  };

  return (
    <StyledTooltip
      open={tooltipState.open}
      title={tooltipState.title}
      placement="top"
      arrow
      onClose={handleClose}
      onOpen={handleOpen}
    >
      <Box
        sx={{
          ...wrapper,
          border: `0.2px solid ${setColor.main}`,
          width: "22.6px",
          maxWidth: "22.6px",
          minWidth: "22.6px",
          height: "22.6px",
          maxHeight: "22.6px",
          minHeight: "22.6px",
          backgroundColor: setColor.background,
          cursor: stop?.checkIn ? "pointer" : "default",
        }}
        onClick={() => {
          if (stop?.checkIn) handleClickCheckIn(stop);
        }}
      >
        <LocationIcon width="8.06" height="12.9" fill={setColor.main} />
        {stop?.checkIn === null ||
        stop?.locationType === "NO_LOCATION_PROVIDED" ? (
          <Box sx={overLine} component="span" />
        ) : null}
      </Box>
    </StyledTooltip>
  );
};

CheckInBlock.propTypes = {
  stop: object,
  timeZone: string,
  handleClickCheckIn: func,
};
