import {
  useCallback,
  useState,
  useMemo,
  forwardRef,
  useRef,
  useEffect,
} from "react";
import { bool, object, func, array } from "prop-types";
import lodash from "lodash";
import deepdash from "deepdash";
const _ = deepdash(lodash);

import {
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import {
  PlusIcon,
  SearchIcon,
  TrashCanIcon,
} from "../../../../../components/Icons";
import {
  openConfirmDialogAction,
  setConfirmIsOpenAction,
} from "../../../../../redux/actions/confirmDialogs";
import { useDispatch } from "react-redux";
// import ConfirmNewInstanceText from "../../CustomersTab/components/TabsContent/TabSortedComponent/ConfirmNewInstanceText";
import { deleteByIdWithTransferService } from "../../../../../services/territory";
import {
  getTerritoryListAction,
  getTerritoryTreeAction,
} from "../../../../../redux/actions/territory";
import StyledTooltip from "../../../../../components/StyledTooltip";
import { UNCATEGORIZED_TERRITORY } from "../../../../../utils/constants";
import { useAdmin } from "helpers/helpers";
import { success } from "utils/notifications";

export const TerritoryItem = forwardRef(
  (
    {
      territory,
      territories,
      hasChildren,
      lastTerritory,
      handler,
      handleChangeNameTerritory,
      handleSearchTerritory,
      handleAddTerritory,
      lastOfTerritories,
      handleChangedTerritory,
      territoryLoading,
    },
    ref
  ) => {
    const isAdmin = useAdmin();
    const dispatch = useDispatch();

    const [isEditName, setIsEditName] = useState(false);
    const [terName, setTerName] = useState(territory?.name);
    const terNameRef = useRef(territory?.name);
    const [openTooltip, setOpenTooltip] = useState(false);

    const hasAnyCustomersOrReps = useMemo(
      () =>
        territory?._count?.customer === 0 &&
        (!territory?.representativeCount ||
          territory?.representativeCount === 0),
      [territory?._count?.customer, territory?.representativeCount]
    );

    const handleSetName = (e) => {
      setTerName(e.target.value);
    };
    const handleChangeName = () => {
      handleChangeNameTerritory(
        territory?.id,
        terName ? terName : terNameRef.current
      );
      setIsEditName(false);
    };

    useEffect(() => {
      setTerName(territory?.name);
      terNameRef.current = territory?.name;
    }, [territory?.name]);

    const handleOpenConfirmDialog = useCallback(
      (confirmQuery) => {
        dispatch(openConfirmDialogAction(confirmQuery));
      },
      [dispatch]
    );

    const foundAndDel = (arr, id) => {
      return _.filterDeep(arr, (el) => el.id !== id, {
        childrenPath: "children",
        onFalse: {
          skipChildren: true,
        },
      });
    };

    const handleDeleteTerritory = useCallback(
      (id, newTerritoryId, childrenItems) => {
        if (!newTerritoryId || newTerritoryId === UNCATEGORIZED_TERRITORY.id)
          return handleChangedTerritory({
            ter: foundAndDel(territories, id[0] || id) || [],
            onSuccess: () => success("Territory deleted"),
          });

        if (childrenItems?.length) {
          for (let Ids of childrenItems) {
            deleteByIdWithTransferService(Ids, newTerritoryId).catch((err) => {
              // eslint-disable-next-line no-console
              console.log(err?.message);
            });
            dispatch(getTerritoryTreeAction());
            dispatch(getTerritoryListAction());
          }
        } else {
          deleteByIdWithTransferService(id[0] || id, newTerritoryId)
            .then(() => {
              handleChangedTerritory({
                ter: foundAndDel(territories, id[0] || id) || [],
                onSuccess: () => success("Territory deleted"),
              });
            })
            .catch((err) => {
              // eslint-disable-next-line no-console
              console.log(err?.message);
            });
        }
      },
      [dispatch, handleChangedTerritory, territories]
    );

    const handleDeleteDialog = useCallback(() => {
      handleOpenConfirmDialog({
        title: "Delete territory?",
        text: (
          <Box>
            <Typography
              sx={{
                fontSize: 13,
                fontWeight: 400,
                color: "#363531",
              }}
            >
              Are you sure you want to delete
              <Box component="span" color="#009B43">
                {" "}
                {territory?.name}{" "}
              </Box>
              territory?
            </Typography>
            <Typography
              sx={{
                fontSize: 13,
                fontWeight: 400,
                color: "#363531",
              }}
            >
              All customers will be reassigned to
              <Box component="span" color="#009B43">
                {" "}
                Uncategorized{" "}
              </Box>
              territory.
            </Typography>
          </Box>
        ),
        dialogStyles: { width: "423px" },
        dialogButtonsStyles: { paddingTop: 0 },
        buttons: (
          <Box mt={1} display="flex" gap={2}>
            <Button
              sx={{
                width: "85px",
                height: "28px",
                fontSize: 13,
                color: "#6A6A6A",
                borderColor: "#D4D4D4",
              }}
              onClick={() => {
                dispatch(setConfirmIsOpenAction(false));
              }}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              sx={{
                width: "85px",
                height: "28px",
                fontSize: 13,
                color: "#FFFFFF",
                boxShadow: "none",
              }}
              color="confirmDelete"
              onClick={() => {
                dispatch(setConfirmIsOpenAction(false));
                if (!territory?._count?.customer)
                  return handleChangedTerritory({
                    ter: foundAndDel(territories, territory.id) || [],
                    onSuccess: () => success("Territory deleted"),
                  });
                handleDeleteTerritory(territory?.id);
              }}
              variant="contained"
            >
              Confirm
            </Button>
          </Box>
        ),
      });
    }, [
      dispatch,
      handleChangedTerritory,
      handleDeleteTerritory,
      handleOpenConfirmDialog,
      territories,
      territory?._count?.customer,
      territory?.id,
      territory?.name,
    ]);

    return (
      <Grid sx={{ marginTop: "-1px", height: "44px" }} container ref={ref}>
        <Grid
          sx={{
            px: "32px",
            border: "1px solid #CCCCCC",
            backgroundColor: "white",
            borderRadius: lastOfTerritories ? "0 0 4px 4px" : "0",
          }}
          className={lastTerritory ? "lastTerritory" : ""}
          container
          justifyContent="space-between"
          item
        >
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              flexGrow: 1,
              gap: 2,
              width: "80%",
            }}
            xs
            item
          >
            <Box
              sx={{
                width: "6px",
                height: "102%",
                backgroundColor:
                  territory?.parentPath?.length === 0 ? "" : "#47A06D",
                marginLeft: "-36px",
                borderRadius: "0 0 0 4px",
                marginTop: "1px",
                zIndex: 1,
              }}
            />
            {handler}
            <Box>
              {hasChildren && <Box sx={{ width: "36px", height: "36px" }} />}
            </Box>
            <Box
              sx={{
                width: `calc(100% - 48px - ${hasChildren ? "36px" : "0px"})`,
              }}
            >
              {isEditName ? (
                <TextField
                  size="small"
                  value={terName}
                  autoFocus
                  onBlur={handleChangeName}
                  onChange={handleSetName}
                />
              ) : (
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 700,
                  }}
                  noWrap
                  color="primary"
                  onClick={() => {
                    if (isAdmin) return;
                    setIsEditName(true);
                  }}
                >
                  {territory?.name}
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid xs="auto" item display="flex" alignItems="center" gap={3}>
            <Box>
              <StyledTooltip
                sx={{ textAlign: "center" }}
                title={
                  "This territory has no customers or representatives assigned yet"
                }
                placement="top"
                arrow
                open={openTooltip && hasAnyCustomersOrReps}
                onClose={() => setOpenTooltip(false)}
                onOpen={() => setOpenTooltip(true)}
              >
                <Box component="span">
                  <IconButton
                    disabled={isAdmin || hasAnyCustomersOrReps}
                    onClick={() => {
                      if (territoryLoading) return;
                      handleSearchTerritory(territory);
                    }}
                  >
                    <SearchIcon />
                  </IconButton>
                </Box>
              </StyledTooltip>
            </Box>
            <Box>
              <IconButton
                disabled={isAdmin}
                onClick={() => {
                  if (territoryLoading) return;
                  handleAddTerritory(territory?.id);
                }}
              >
                <PlusIcon fill={territoryLoading ? "#12gh34" : "#707070"} />
              </IconButton>
            </Box>
            <Box>
              <IconButton
                disabled={isAdmin}
                onClick={(e) => {
                  e.stopPropagation();
                  if (territoryLoading) return;
                  handleDeleteDialog();
                }}
              >
                <TrashCanIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    );
  }
);
TerritoryItem.propTypes = {
  territory: object,
  territories: array,
  hasChildren: bool,
  lastTerritory: bool,
  handler: object,
  handleChangeNameTerritory: func,
  handleSearchTerritory: func,
  handleAddTerritory: func,
  handleChangedTerritory: func,
  lastOfTerritories: bool,
  territoryLoading: bool,
};
TerritoryItem.displayName = "TerritoryItem";

export default TerritoryItem;
