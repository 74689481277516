import { object } from "prop-types";
import { Box, Divider, Typography } from "@mui/material";
import useStyles from "./styles";

const RoutesSummaries = ({ summaries }) => {
  const classes = useStyles();
  return (
    <Box className={classes.summaryWrapper}>
      <Box className={classes.countBlock}>
        <Typography className={classes.countTitle}>
          Total Routes{" "}
          <span className={classes.countNumber}>{summaries.totalRoutes}</span>
        </Typography>
      </Box>
      <Divider orientation="vertical" className={classes.divider} />
      <Box>
        <Typography className={classes.countTitle}>
          Total Stops{" "}
          <span className={classes.countNumber}>{summaries.totalStops}</span>
        </Typography>
      </Box>
      <Divider orientation="vertical" className={classes.divider} />
      <Box>
        <Typography className={classes.countTitle}>
          Active{" "}
          <span className={classes.countNumber}>{summaries.activeRoutes}</span>
        </Typography>
      </Box>
      <Divider orientation="vertical" className={classes.divider} />
      <Box>
        <Typography className={classes.countTitle}>
          Visited Stops Today{" "}
          <span className={classes.countNumber}>{summaries.visitedStops}</span>
        </Typography>
      </Box>
    </Box>
  );
};

RoutesSummaries.propTypes = {
  summaries: object,
};

export default RoutesSummaries;
