import { Grid, Typography } from "@mui/material";
import TableHeader from "../../../../components/TableHeader";

const styles = {
  "@media only screen and (max-width: 1800px)": {
    ".repName": {
      marginLeft: "10px",
    },
  },
  "@media only screen and (max-width: 1650px)": {
    ".repName": {
      marginLeft: "20px",
    },
    ".role": {
      marginLeft: "-10px",
    },
  },
};

const ManufacturersHeader = () => {
  return (
    <TableHeader
      sx={{
        ...styles,
        backgroundColor: "#EEEEEE",
        alignItems: "center",
        border: "0.5px solid #D5D9D9",
        borderTop: "none",
        paddingBottom: "3px",
      }}
      columns={24}
    >
      <Grid item textAlign="center" marginLeft="33px">
        <Typography variant="header">STATUS</Typography>
      </Grid>
      <Grid item xs={2} marginLeft="21px">
        <Typography variant="header">ID</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="header">MANUFACTURERS NAME</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="header">EMAIL</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography pl={0.8} variant="header">
          CONTACTS
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="header">TOTAL PRODUCTS</Typography>
      </Grid>
    </TableHeader>
  );
};

export default ManufacturersHeader;
