/* eslint-disable no-useless-escape */
import * as Yup from "yup";

export const validationSchema = ({ checkedProducts }) =>
  Yup.object().shape({
    products: Yup.array().when("productType", {
      is: "SPECIFIC_PRODUCTS",
      then: Yup.array().test(
        "productsValidation",
        "At least 1 product is required",
        () => {
          return checkedProducts.length > 0;
        }
      ),
    }),
    customers: Yup.array().when("eligibility", {
      is: "CUSTOMERS",
      then: Yup.array().test(
        "customersValidation",
        "At least 1 customer is required",
        (value) => {
          return value.length > 0;
        }
      ),
    }),
    parentCustomers: Yup.array().when("eligibility", {
      is: "GROUPS",
      then: Yup.array().test(
        "groupsValidation",
        "At least 1 group is required",
        (value) => {
          return value.length > 0;
        }
      ),
    }),
    minimum: Yup.number()
      .transform((v, o) => (o === "" ? null : v))
      .nullable()
      .when("requirements", {
        is: "NONE",
        otherwise: Yup.number().test(
          "discountValidation",
          "Please type a valid discount.",
          (value) => {
            return value > 0;
          }
        ),
      })
      .when("type", {
        is: "QUANTITY",
        then: Yup.number().when("discount", (discount, schema) => {
          (value) => {
            return (
              value > 0,
              schema.test({
                test: (minimum) => minimum >= discount,
                message: "MOQ can not be less than discount value",
              })
            );
          };
        }),
      }),
    limit: Yup.number()
      .transform((v, o) => (o === "" ? null : v))
      .nullable()
      .when("isLimited", {
        is: true,
        then: Yup.number().test(
          "discountValidation",
          "Please type a valid limitation.",
          (value) => {
            return value > 0;
          }
        ),
      }),
    name: Yup.string()
      .trim()
      .max(18, "18 symbols max")
      .required("This field is required"),
    manufacturer: Yup.object().shape({
      id: Yup.number().required("This field is required"),
      name: Yup.string(),
    }),
    discount: Yup.number()
      .required("This field is required")
      .typeError("Must be a number.")
      .when("type", {
        is: "PERCENTAGE",
        then: Yup.number().test(
          "discountValidation",
          "Please type a valid discount.",
          (value) => {
            return value > 0 && value <= 100;
          }
        ),
        otherwise: Yup.number().test(
          "discountValidation",
          "Please type a valid discount.",
          (value) => {
            return value > 0;
          }
        ),
      }),
    startDate: Yup.string().trim().required("This field is required"),
    endDate: Yup.string()
      .trim()
      .when("hasEndDate", {
        is: true,
        then: Yup.string().trim().required("This field is required"),
      }),
  });
