import { bool, object, func, string } from "prop-types";
import { Link as NavLink } from "react-router-dom";
import { Menu, MenuItem } from "@mui/material";

const MessageMenu = ({
  isOpen,
  anchorEl,
  handleClose,
  handleDelete,
  handleEdit,
  room,
  collectionId,
  ...props
}) => {
  return (
    <Menu
      open={isOpen}
      anchorEl={anchorEl}
      onClose={handleClose}
      transformOrigin={{ horizontal: "left", vertical: "top" }}
      {...props}
    >
      {(!!room?.id || !!room?.roomId) && (
        <MenuItem
          component={NavLink}
          to={`/conversations/${room?.id || room?.roomId}`}
          target="_blank"
          onClick={() => {
            localStorage.setItem(
              "msgData",
              JSON.stringify({
                collectionId: collectionId || null,
                representativeId: room.id || null,
                representativesIds: room?.participants || null,
                statusDocId: room.statusDocId || null,
                messageId: room.messageId || null,
              })
            );
            handleClose();
          }}
        >
          Open in New Tab
        </MenuItem>
      )}
      {handleEdit && <MenuItem onClick={handleEdit}>Edit</MenuItem>}
      {handleDelete && (
        <MenuItem onClick={handleDelete} color="confirmDelete">
          Delete
        </MenuItem>
      )}
    </Menu>
  );
};

MessageMenu.propTypes = {
  isOpen: bool,
  anchorEl: object,
  handleClose: func,
  handleDelete: func,
  handleEdit: func,
  room: object,
  collectionId: string,
};

MessageMenu.defaultProps = {
  isOpen: false,
  anchorEl: null,
};

export default MessageMenu;
