export const cl = {
  headerBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  nameText: {
    fontSize: "clamp(14px, 1.13vw, 22px)",
    color: "#000",
  },
  chipBox: {
    backgroundColor: "rgba(71, 160, 110, 0.1)",
    paddingX: 1,
    paddingY: 0.5,
    borderRadius: "20px",
  },
  chipText: {
    fontSize: "10px",
    fontWeight: 600,
    color: "#47A06D",
    textTransform: "uppercase",
  },
  infoStatus: {
    fontSize: "12px",
    fontWeight: 400,
    color: "#5F6267",
  },
};
