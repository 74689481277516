import { bool, func } from "prop-types";

import {
  ActionBlock,
  ContentBlock,
  HeaderBlock,
  WarningBlock,
} from "./components";

import { useChangePassword } from "./useChangePassword";

import { cl } from "./styles";

import { Dialog, Stack } from "@mui/material";

export const ChangePasswordDialog = ({ open, onClose }) => {
  const {
    control,
    handleSubmit,
    handleForgotPassword,
    errors,
    clearErrors,
    onSubmit,
    trigger,
    disabledBtn,
    showWarning,
  } = useChangePassword(onClose);

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ sx: cl.dialogWrapper }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack sx={cl.form}>
          <HeaderBlock {...{ onClose }} />

          {showWarning && <WarningBlock {...{ errors }} />}

          <ContentBlock
            {...{ control, handleForgotPassword, trigger, errors, clearErrors }}
          />
          <ActionBlock {...{ disabledBtn }} />
        </Stack>
      </form>
    </Dialog>
  );
};

ChangePasswordDialog.propTypes = {
  open: bool,
  onClose: func,
};
ChangePasswordDialog.defaultProps = {
  open: false,
  onClose: () => {},
};
