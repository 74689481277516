import { Typography } from "@mui/material";
import { checkFileSize } from "helpers/helpers";
import { useState } from "react";
import { getDocumentSignedUrl, uploadFile } from "services/customers";
import { MAX_UPLOAD_LICENSES_FILE_SIZE_MB } from "utils/constants";
import { error } from "utils/notifications";

export const useUploadFile = () => {
  const [loadingUploadFile, setLoadingUploadFile] = useState(false);

  const [currentAttachment, setCurrentAttachment] = useState([]);

  const handleGetDocumentSignedUrl = async (files) => {
    if (!files?.length) return;
    try {
      // setState({ ...state, loadingUploadFile: true });

      const filesData = files.map((f) => ({
        fileName: f?.name,
        contentType: f?.type,
        size: f?.size,
      }));

      const res = await getDocumentSignedUrl({ files: filesData });

      return res;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      error(err?.response?.data?.message || "Something went wrong.");
    } finally {
      // setState({ ...state, loadingUploadFile: false });
    }
  };

  const handleUploadFile = async ({ headers, file, url, fileType }) => {
    try {
      // setState({ ...state, loadingUploadFile: true });

      const res = await uploadFile({ headers, file, url, fileType });

      return res;
      // eslint-disable-next-line no-useless-catch
    } catch (err) {
      throw err;
    } finally {
      // setState({ ...state, loadingUploadFile: false });
    }
  };

  const handleCheckedFiles = (files) => {
    const checkedFiles = [];

    const filesArr = Array.from(files);

    filesArr.forEach((file) => {
      if (
        checkFileSize({
          fileSize: file.size,
          maxFileSizeMb: MAX_UPLOAD_LICENSES_FILE_SIZE_MB,
        })
      ) {
        return error(
          <>
            <Typography component="span" fontSize={12}>
              Photo upload failed
              <br />
              {file.name}
              <br />
              Exceeds maximum file size of {MAX_UPLOAD_LICENSES_FILE_SIZE_MB} MB
            </Typography>
            <br />
          </>
        );
      } else {
        checkedFiles.push({
          file,
          name: file?.name,
          type: file?.type,
          size: file?.size,
        });
      }
    });

    return checkedFiles;
  };

  const handleUpload = async (e) => {
    if (e.target.files.length === 0) return;

    setLoadingUploadFile(true);

    const { files } = e.currentTarget;

    const checkedFiles = handleCheckedFiles(files);

    const filesWithSignedUrl = await handleGetDocumentSignedUrl(checkedFiles);

    if (!filesWithSignedUrl?.length) {
      e.target.value = null;
      return;
    }

    const promises = filesWithSignedUrl.map(
      async ({ headers, url, attachment }) => {
        const fileName = attachment?.fileName?.split("/")?.pop();
        const file = checkedFiles.find((f) => f.name === fileName);

        const arrayBuffer = await file?.file?.arrayBuffer();
        const uint8Array = new Uint8Array(arrayBuffer);

        return handleUploadFile({
          headers,
          url,
          file: uint8Array,
          fileType: file?.file?.type,
        });
      }
    );

    Promise.all(promises)
      .then(() => {
        const preparedAttachments = filesWithSignedUrl.map((signedFile) => {
          const fileName = signedFile?.attachment?.fileName?.split("/")?.pop();

          const file = checkedFiles.find((file) => file?.name === fileName);

          return { ...signedFile?.attachment, file };
        });
        setCurrentAttachment((prev) => [...prev, ...preparedAttachments]);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        error(err?.response?.data?.message || "Something went wrong.");
      })
      .finally(() => setLoadingUploadFile(false));

    e.target.value = null;
  };

  const handleDeleteFile = (file) => {
    const tempArr = [...currentAttachment];

    const index = currentAttachment.findIndex((f) => f?.url === file?.url);

    if (index === -1) return;

    tempArr.splice(index, 1);

    setCurrentAttachment(tempArr);
  };

  return {
    currentAttachment,
    setCurrentAttachment,
    handleUpload,
    handleDeleteFile,
    loadingUploadFile,
  };
};
