import { array, func } from "prop-types";
import { Paper } from "@mui/material";
import ProductsSearchItem from "../ProductsSearchItem/ProductsSearchItem";
import useStyles from "../../styles";
import { SearchDropDownButtons } from "../../../../components/Buttons";
import { ScrollControlWrapper } from "components";

const ProductsDropDown = ({
  productsList,
  handleCheckProduct,
  checkedProducts,
  formProducts,
  onCancel,
  onAdd,
}) => {
  const classes = useStyles();

  const checkedParentId =
    checkedProducts?.[0]?.parentId ||
    checkedProducts?.[0]?.parentProduct?.id ||
    checkedProducts?.[0]?.id;

  const isDisable = (list, product, parent) => {
    const manufacturerId =
      product?.manufacturer?.id || parent?.manufacturer?.id;

    const checkedManufacturerId = checkedProducts?.[0]?.manufacturer?.id;

    if (manufacturerId === checkedManufacturerId) return false;

    if (!(checkedParentId === parent?.id) && checkedProducts?.length)
      return true;
  };

  return (
    <Paper
      className={classes.section}
      sx={{
        position: "absolute",
        zIndex: 10,
        width: "100%",
        left: 0,
      }}
    >
      <ScrollControlWrapper
        maxHeight="200px"
        dataLength={productsList?.length}
        id="products-search-list"
      >
        {productsList.map((product) => {
          return product.isMultiple ? (
            product.childProducts.map((child) => {
              child.parentId = product?.id;
              return (
                <ProductsSearchItem
                  key={child.id}
                  product={child}
                  name={product.name}
                  manufacturer={product.manufacturer}
                  handleCheckProduct={handleCheckProduct}
                  isChecked={checkedProducts.some(
                    (prod) => prod.id === child.id
                  )}
                  disabled={isDisable(formProducts, child, product)}
                  hideAvailable={true}
                />
              );
            })
          ) : (
            <ProductsSearchItem
              key={product.id}
              product={product}
              name={product.name}
              manufacturer={product.manufacturer}
              handleCheckProduct={handleCheckProduct}
              isChecked={checkedProducts.some((prod) => prod.id === product.id)}
              disabled={isDisable(formProducts, product, product?.id)}
              hideAvailable={true}
            />
          );
        })}
      </ScrollControlWrapper>
      <SearchDropDownButtons onCancel={onCancel} onAdd={onAdd} />
    </Paper>
  );
};

ProductsDropDown.propTypes = {
  checkedProducts: array,
  handleCheckProduct: func,
  productsList: array,
  formProducts: array,
  onCancel: func,
  onAdd: func,
};

ProductsDropDown.defaultProps = {};

export default ProductsDropDown;
