import React from "react";
import { string, func, bool, object } from "prop-types";
import { Box, Typography } from "@mui/material";
import StyledDatePicker from "components/StyledDatePicker/StyledDatePicker";
import useStyles from "./styles";
import moment from "moment-timezone";
import { useSelector } from "react-redux";

const FulfillBy = ({
  value,
  onChange,
  hideTitle,
  placeholder,
  inputProps,
  titleProps,
  disabled,
}) => {
  const currentUser = useSelector(({ auth }) => auth.currentUser);
  const { timeZone } = currentUser || {};

  const classes = useStyles();
  return (
    <Box className={classes.fulfillWrapper} {...titleProps}>
      {hideTitle ? null : (
        <Typography
          fontSize="clamp(15px, 1.3vw, 25px)"
          fontWeight={600}
          color="#707070"
        >
          Fulfill by
        </Typography>
      )}
      <StyledDatePicker
        dateLibInstance={moment}
        value={moment.tz(value, timeZone)}
        disablePast
        disabled={disabled}
        onChange={onChange}
        wrapperClassName={classes.calendarInput}
        textFieldProps={{
          fullWidth: true,
          inputProps: {
            placeholder: placeholder || "-",
          },
          ...inputProps,
          noErrorMessage: true,
        }}
      />
    </Box>
  );
};

FulfillBy.propTypes = {
  value: string,
  onChange: func,
  hideTitle: bool,
  placeholder: string,
  inputProps: object,
  titleProps: object,
  disabled: bool,
};

FulfillBy.defaultProps = {
  value: "-",
  onChange: () => {},
  hideTitle: false,
  placeholder: "",
  inputProps: {},
  titleProps: {},
  disabled: false,
};

export default FulfillBy;
