import { useState } from "react";
import { success } from "utils/notifications";

export const useLicenses = ({ licenses, setValue, handleSaveLicenses }) => {
  const [openLicenses, setOpenLicenses] = useState(false);

  const [editLicense, setEditLicense] = useState(null);
  const [dialogState, setDialogState] = useState({
    attachment: null,
  });

  const handleDeleteLicense = (licenseDelete) => {
    const tempLicenses = [...licenses];

    const index = tempLicenses.findIndex((l) => {
      if (licenseDelete?.id) {
        return l?.id === licenseDelete.id;
      }
      if (licenseDelete?.tempId) {
        return l?.tempId === licenseDelete.tempId;
      }
    });

    tempLicenses.splice(index, 1);

    if (setValue) setValue("licenses", tempLicenses);
    if (handleSaveLicenses) handleSaveLicenses(tempLicenses);

    setDialogState({
      attachment: null,
    });
    success("License deleted");
  };

  const handleDeleteLicenseFile = (licenseData, license) => {
    if (licenseData && setValue) {
      const tempLicenses = [...licenses];

      const indexCurrentLicense = tempLicenses.findIndex((l) => {
        if (license?.id) {
          return l?.id === license.id;
        }
        if (license?.tempId) {
          return l?.tempId === license.tempId;
        }
      });

      if (indexCurrentLicense === -1) return;

      const attachmentsTemp = [
        ...tempLicenses[indexCurrentLicense].attachments,
      ];

      const indexCurrentAttachment = attachmentsTemp.findIndex(
        (a) => a?.fileName === licenseData?.fileName
      );

      attachmentsTemp.splice(indexCurrentAttachment, 1);

      tempLicenses[indexCurrentLicense].attachments = attachmentsTemp;

      setValue("licenses", tempLicenses);
    }

    if (licenseData && handleSaveLicenses) {
      handleSaveLicenses([
        ...licenses?.map((license) =>
          license === licenseData ? { ...license, attachment: null } : license
        ),
      ]);
    }

    setDialogState({
      attachment: null,
    });
  };

  const handleOpenLicenses = () => {
    setOpenLicenses(true);
  };
  const handleCloseLicenses = () => {
    setOpenLicenses(false);
    setEditLicense(null);
  };

  const handleSetLicenseData = (licenseData) => {
    // const existingLicense = licenses.find(
    //   (license) =>
    //     license?.name === licenseData?.name &&
    //     license?.number === licenseData?.number
    // );

    // if (existingLicense) return success("The license already exists.");

    const index = licenses.findIndex((l) => {
      if (licenseData?.id) {
        return l?.id === licenseData.id;
      }
      if (licenseData?.tempId) {
        return l?.tempId === licenseData.tempId;
      }
    });

    if (index === -1) {
      if (setValue) setValue("licenses", [...licenses, licenseData]);
      if (handleSaveLicenses) handleSaveLicenses([...licenses, licenseData]);
      success("License added");
    } else {
      const tempLicenses = [...licenses];
      tempLicenses[index] = licenseData;
      if (setValue) setValue("licenses", tempLicenses);
      if (handleSaveLicenses) handleSaveLicenses(tempLicenses);
      success("License updated");
    }
    setDialogState({
      attachment: null,
    });
  };

  return {
    openLicenses,
    handleOpenLicenses,
    handleCloseLicenses,
    handleSetLicenseData,
    editLicense,
    setEditLicense,
    setDialogState,
    dialogState,
    handleDeleteLicense,
    handleDeleteLicenseFile,
  };
};
