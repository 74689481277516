import axios from "axios";

export const exportCustomersService = async (params) => {
  return axios({
    method: "post",
    responseType: "blob",
    url: "/data-exports/customers",
    params,
    data: {},
  }).then((res) => res.data);
};

export const exportProductsService = async (params) => {
  return axios({
    method: "post",
    responseType: "blob",
    url: "/data-exports/products",
    params,
  }).then((res) => res.data);
};

export const exportOrdersService = async (params) => {
  return axios({
    method: "post",
    responseType: "blob",
    url: "/data-exports/orders",
    params,
  }).then((res) => res.data);
};
