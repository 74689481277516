export const totalSummariesCatalogDataSelector = (state) =>
  state.totalSummaries.totalSummariesCatalogData;
export const totalSummariesCatalogLoadingSelector = (state) =>
  state.totalSummaries.totalSummariesCatalogLoading;

export const totalSummariesOrdersDataSelector = (state) =>
  state.totalSummaries.totalSummariesOrdersData;
export const totalSummariesOrdersLoadingSelector = (state) =>
  state.totalSummaries.totalSummariesOrdersLoading;
export const totalSummariesOrdersParamsSelector = (state) =>
  state.totalSummaries.totalSummariesOrdersParams;

export const totalSummariesCustomersDataSelector = (state) =>
  state.totalSummaries.totalSummariesCustomersData;
export const totalSummariesCustomersLoadingSelector = (state) =>
  state.totalSummaries.totalSummariesCustomersLoading;
