import { DateIcon, DeliverIcon } from "../../components/Icons/MenuIcons";
import SettingsIcon from "@mui/icons-material/Settings";
import { SvgIcon } from "@mui/material";

export const SORT_TYPES = [
  { id: "sort_by_order_date", name: "By order date", icon: <DateIcon /> },
  {
    id: "sort_by_shipping_date",
    name: "By ship date",
    icon: <DeliverIcon />,
  },
];

export const FILTER_DATE_VALUES = [
  { name: "All", id: "" },
  { name: "Today", id: "today" },
  { name: "Tomorrow", id: "tomorrow" },
  { name: "This week", id: "this_week" },
  { name: "Next week", id: "next_week" },
  { name: "This month", id: "this_month" },
  { name: "Next month", id: "next_month" },
  { name: "All time", id: "all_time" },
];

export const PAYMENT_FILTER_DATE_VALUES = [
  { name: "Overdue", id: "overdue", isDueDate: true },
  { name: "Not due", id: "not_due", withDivider: true, isDueDate: true },
];

export const PAYMENT_STATUSES = [
  "PARTIALLY_PAID",
  "PAID",
  "UNPAID",
  "REFUNDED",
];

export const DELIVERY_STATUSES = [
  "FULFILLED",
  "PARTIALLY_FULFILLED",
  "UNFULFILLED",
];

export const FILTER_STATUSES = [
  // { value: "ALL", name: "All" },
  { value: "UNFULFILLED", name: "Unfulfilled", field: "delivery_status" },
  { value: "PENDING", name: "Unpaid", field: "payment_status" },
  { value: "pending", name: "Open", field: "order_status" },
  { value: "completed", name: "Closed", field: "order_status" },
];

export const DELIVERY_METHODS = ["LOCAL_DELIVERY", "SHIPPING_CARRIER"];

export const ORDER_STATUSES = ["pending", "completed", "canceled"];
export const ARCHIVED = ["on"];

export const FILTER_SWITCHES = [
  {
    value: "sales_id",
    label: "Created by",
    type: "select",
    checked: false,
    selectLabel: "Select Rep",
  },
  {
    value: "sort_by",
    label: "Sort by",
    type: "switch",
    checked: false,
    values: ["order_date", "shipping_date"],
  },
  {
    value: "order_status",
    label: "Order status",
    type: "switch",
    values: ORDER_STATUSES,
    checked: false,
    multiple: true,
  },
  {
    value: "payment_status",
    label: "Payment status",
    type: "switch",
    values: PAYMENT_STATUSES,
    checked: false,
    multiple: true,
  },
  {
    value: "delivery_status",
    label: "Fulfillment status",
    type: "switch",
    values: DELIVERY_STATUSES,
    checked: false,
    multiple: true,
  },
  {
    value: "delivery_method",
    label: "Fulfillment method",
    type: "switch",
    values: DELIVERY_METHODS,
    checked: false,
  },
];

export const defaultFilters = {
  order_status: "",
  payment_status: "",
  delivery_status: "",
  delivery_method: "",
  sort_by: "",
};

export const CARRIER_INFO = {
  SHIP_ENGINE: {
    logo: (
      <SvgIcon
        fontSize="26px"
        color="primary2"
        sx={{
          backgroundColor: "#d5d9d9",
          padding: "2px",
          height: "20px",
          width: "20px",
          borderRadius: "50%",
        }}
      >
        <SettingsIcon />
      </SvgIcon>
    ),
    carrierName: "Shipping Carrier",
    name: "ShipStation",
  },
};
