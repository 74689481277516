import { string, func, number, any } from "prop-types";
import { Box, IconButton, Typography } from "@mui/material";
import { CrossIcon } from "components/Icons";

export const FileLabel = ({
  name,
  size,
  bgcolor,
  handleDeleteFile,
  license,
  handleShowPicture,
  setHoveredBlock,
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "26px",
        border: "0.5px solid #D5D9D9",
        borderRadius: "4px",
        background: bgcolor || "#F8F8F8",
        display: "flex",
        alignItems: "center",
        pl: "10px",
        m: "5px 0",
      }}
      onMouseEnter={() => {
        if (setHoveredBlock) setHoveredBlock(null);
      }}
      onMouseLeave={() => {
        if (setHoveredBlock) setHoveredBlock(license);
      }}
    >
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: 600,
          color: "#47A06D",
          cursor: handleShowPicture ? "pointer" : "auto",
        }}
        onClick={handleShowPicture}
        noWrap
      >
        {name}
      </Typography>
      <Typography fontSize={14} fontWeight={600} color="#707070" ml="12px">
        ({size}K)
      </Typography>
      {handleDeleteFile && (
        <IconButton
          sx={{ ml: "auto", p: "6px" }}
          onClick={() => {
            handleDeleteFile(license);
          }}
        >
          <CrossIcon size={"12px"} />
        </IconButton>
      )}
    </Box>
  );
};

FileLabel.propTypes = {
  name: string,
  size: number,
  bgcolor: string,
  handleDeleteFile: func,
  license: any,
  handleShowPicture: func,
  setHoveredBlock: func,
};
FileLabel.defaultProps = { name: "", size: "" };
