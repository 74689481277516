export const FOURSQUARE_RESPONSE_FIELDS = [
  "categories",
  "geocodes",
  "location",
  "name",
  "hours",
  "hours_popular",
  "price",
  "rating",
  "tel",
  "fax",
  "website",
  "social_media",
  "stats",
  "fsq_id",
  "photos",
];

export const WORKING_DAYS = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];
