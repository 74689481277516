import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  productItemContainer: {
    height: "44px",
    "&:not(:last-of-type)": {
      borderBottom: "1px solid #d5d9d9",
    },
    "& .MuiGrid-root": {
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      "& .MuiTypography-root": {
        whiteSpace: "nowrap",
        fontSize: "12px",
        color: "#000000",
      },
    },
  },

  productNameWrapper: {
    paddingLeft: "11px",
    justifyContent: "flex-start!important",
  },

  productPhoto: {
    height: "38px",
    minWidth: "38px",
    width: "38px",
    backgroundColor: "white",
    border: "1px solid #D5D9D9",
    borderRadius: "4px",
  },

  productPriceWrapper: {
    paddingLeft: "19px",
    justifyContent: "flex-start!important",
  },
}));

export default useStyles;
