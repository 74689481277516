export const EmptyOrdersIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="170.358"
      height="132.025"
      viewBox="0 0 170.358 132.025"
    >
      <defs>
        <filter
          id="Path_5047"
          x="0"
          y="0"
          width="170.358"
          height="132.025"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dx="3" dy="-2" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodOpacity="0.039" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g
        id="Group_7460"
        data-name="Group 7460"
        transform="translate(-3994.198 -20314.475)"
      >
        <path
          id="Path_5067"
          data-name="Path 5067"
          d="M386.413,163.588H250.818a7.469,7.469,0,0,1-7.468-7.468V58.648a7.469,7.469,0,0,1,7.468-7.468H386.413a7.469,7.469,0,0,1,7.468,7.468v97.471A7.469,7.469,0,0,1,386.413,163.588Z"
          transform="translate(3768.091 20266.715)"
          fill="#fbfbfb"
          stroke="#f5f5f5"
          strokeWidth="1"
        />
        <g
          transform="matrix(1, 0, 0, 1, 3994.2, 20314.47)"
          filter="url(#Path_5047)"
        >
          <path
            id="Path_5047-2"
            data-name="Path 5047"
            d="M387.2,164.2H250.859a7.51,7.51,0,0,1-7.509-7.509V58.689a7.51,7.51,0,0,1,7.509-7.509H387.2a7.51,7.51,0,0,1,7.509,7.509V156.7A7.51,7.51,0,0,1,387.2,164.2Z"
            transform="translate(-236.85 -39.68)"
            fill="#fff"
            stroke="#f5f5f5"
            strokeWidth="1"
          />
        </g>
        <path
          id="Path_5053"
          data-name="Path 5053"
          d="M728.32,520.142H714.811a5.4,5.4,0,0,1-5.4-5.4V501.231a5.4,5.4,0,0,1,5.4-5.4H728.32a5.4,5.4,0,0,1,5.4,5.4V514.74A5.4,5.4,0,0,1,728.32,520.142Z"
          transform="translate(3359.656 19899.449)"
          fill="#f5f5f5"
        />
        <path
          id="Path_5054"
          data-name="Path 5054"
          d="M945.224,507.816H928.1a2.783,2.783,0,0,1,0-5.566h17.121a2.783,2.783,0,0,1,0,5.566Z"
          transform="translate(3175.419 19893.971)"
          fill="#f5f5f5"
        />
        <path
          id="Path_5055"
          data-name="Path 5055"
          d="M957.464,577.156H928.1a2.783,2.783,0,0,1,0-5.566h29.363a2.783,2.783,0,1,1,0,5.566Z"
          transform="translate(3175.419 19832.283)"
          fill="#f5f5f5"
        />
        <path
          id="Path_5056"
          data-name="Path 5056"
          d="M957.464,646.506H928.1a2.783,2.783,0,0,1,0-5.566h29.363a2.783,2.783,0,1,1,0,5.566Z"
          transform="translate(3175.419 19770.584)"
          fill="#f5f5f5"
        />
        <path
          id="Path_5057"
          data-name="Path 5057"
          d="M331.7,597.626h-8.853a3.172,3.172,0,0,1-3.172-3.172V585.6a3.172,3.172,0,0,1,3.172-3.172H331.7a3.172,3.172,0,0,1,3.172,3.172v8.853A3.172,3.172,0,0,1,331.7,597.626Z"
          transform="translate(3692.215 19821.477)"
          fill="#f5f5f5"
        />
        <path
          id="Path_5058"
          data-name="Path 5058"
          d="M467.08,589.926h-10.7a1.738,1.738,0,0,1,0-3.477h10.7a1.738,1.738,0,1,1,0,3.477Z"
          transform="translate(3577.053 19818.047)"
          fill="#f5f5f5"
        />
        <path
          id="Path_5059"
          data-name="Path 5059"
          d="M474.73,633.267H456.378a1.738,1.738,0,1,1,0-3.477H474.73a1.738,1.738,0,1,1,0,3.477Z"
          transform="translate(3577.053 19779.748)"
          fill="#f5f5f5"
        />
        <path
          id="Path_5060"
          data-name="Path 5060"
          d="M474.73,676.617H456.378a1.738,1.738,0,0,1,0-3.477H474.73a1.738,1.738,0,0,1,0,3.477Z"
          transform="translate(3577.053 19741.438)"
          fill="#f5f5f5"
        />
        <path
          id="Path_5062"
          data-name="Path 5062"
          d="M347.41,312.291H307.759a2.9,2.9,0,0,1-2.9-2.9V276.329a2.9,2.9,0,0,1,2.9-2.9h39.65a2.9,2.9,0,0,1,2.9,2.9v33.063A2.9,2.9,0,0,1,347.41,312.291Z"
          transform="translate(3704.861 20081.92)"
          fill="#409a65"
          opacity="0.15"
        />
        <path
          id="Path_5063"
          data-name="Path 5063"
          d="M728.32,275.742H714.811a5.4,5.4,0,0,1-5.4-5.4V256.831a5.4,5.4,0,0,1,5.4-5.4H728.32a5.4,5.4,0,0,1,5.4,5.4V270.34A5.4,5.4,0,0,1,728.32,275.742Z"
          transform="translate(3359.656 20103.92)"
          fill="#f5f5f5"
        />
        <path
          id="Path_5064"
          data-name="Path 5064"
          d="M945.224,263.416H928.1a2.783,2.783,0,0,1,0-5.566h17.121a2.783,2.783,0,0,1,0,5.566Z"
          transform="translate(3175.419 20098.441)"
          fill="#f5f5f5"
        />
        <path
          id="Path_5065"
          data-name="Path 5065"
          d="M957.464,332.755H928.1a2.783,2.783,0,0,1,0-5.566h29.363a2.783,2.783,0,1,1,0,5.566Z"
          transform="translate(3175.419 20038.006)"
          fill="#f5f5f5"
        />
        <path
          id="Path_5066"
          data-name="Path 5066"
          d="M957.464,402.106H928.1a2.783,2.783,0,0,1,0-5.566h29.363a2.783,2.783,0,1,1,0,5.566Z"
          transform="translate(3175.419 19977.557)"
          fill="#f5f5f5"
        />
        <rect
          id="Rectangle_11745"
          data-name="Rectangle 11745"
          width="152"
          height="14"
          rx="3"
          transform="translate(4000 20325.732)"
          fill="#e2f0e8"
        />
        <path
          id="Path_5050"
          data-name="Path 5050"
          d="M36.271,139.441a2.371,2.371,0,1,0-2.371-2.371A2.371,2.371,0,0,0,36.271,139.441Z"
          transform="translate(3975.17 20195.662)"
          fill="#bbb"
        />
        <path
          id="Path_5051"
          data-name="Path 5051"
          d="M84.46,139.441a2.371,2.371,0,1,0-2.371-2.371A2.371,2.371,0,0,0,84.46,139.441Z"
          transform="translate(3934.05 20195.662)"
          fill="#bbb"
        />
        <path
          id="Path_5052"
          data-name="Path 5052"
          d="M129.211,139.441a2.371,2.371,0,1,0-2.371-2.371A2.371,2.371,0,0,0,129.211,139.441Z"
          transform="translate(3895.864 20195.662)"
          fill="#7d7d7d"
        />
        <g
          id="Group_7459"
          data-name="Group 7459"
          transform="translate(-4549.055 5473.978)"
        >
          <path
            id="Path_4962"
            data-name="Path 4962"
            d="M297,20.5a2,2,0,1,0-2-2A2,2,0,0,0,297,20.5Z"
            transform="translate(8278.5 14889)"
            fill="none"
            stroke="#409a65"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_4963"
            data-name="Path 4963"
            d="M308,20.5a2,2,0,1,0-2-2A2,2,0,0,0,308,20.5Z"
            transform="translate(8278.5 14889)"
            fill="none"
            stroke="#409a65"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_4964"
            data-name="Path 4964"
            d="M308,16.5H297V5h-3"
            transform="translate(8278.5 14889)"
            fill="none"
            stroke="#409a65"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_4965"
            data-name="Path 4965"
            d="M297,12.5h13l2-7"
            transform="translate(8278.5 14889)"
            fill="none"
            stroke="#409a65"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
      </g>
    </svg>
  );
};
