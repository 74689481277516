import React from "react";

export const CityIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.215"
      height="13.949"
      viewBox="0 0 13.215 13.949"
    >
      <path
        id="Icon_material-location-city"
        data-name="Icon material-location-city"
        d="M13.31,9.608V5.2L11.108,3l-2.2,2.2V6.671H4.5V16.949H17.715V9.608ZM7.437,15.481H5.968V14.013H7.437Zm0-2.937H5.968V11.076H7.437Zm0-2.937H5.968V8.139H7.437Zm4.405,5.873H10.373V14.013h1.468Zm0-2.937H10.373V11.076h1.468Zm0-2.937H10.373V8.139h1.468Zm0-2.937H10.373V5.2h1.468Zm4.405,8.81H14.778V14.013h1.468Zm0-2.937H14.778V11.076h1.468Z"
        transform="translate(-4.5 -3)"
        fill="#5f6267"
      />
    </svg>
  );
};
