import { string, number } from "prop-types"
import { Box, Grid, Typography } from "@mui/material";
import { cl } from "../components.style";
import {
  getFormattedDate,
  normalizeSnakeCaseString,
  separateNumWithComma,
} from "../../../../../../../../../../../helpers/helpers";
import moment from "moment";

export const TransactionsItem = ({
  date,
  orderCustomId,
  customerName,
  type,
  amount,
  fee,
  net,
  timeZone,
}) => {
  return (
    <Grid sx={cl.wrapper} container>
      {/* Transaction date */}
      <Grid sx={{ ...cl.grid, pl: 3.5 }} xs={2.4} item>
        <Typography sx={cl.typography}>
          {moment(getFormattedDate(date, timeZone))
            .format("MMM D, YYYY")}
        </Typography>
      </Grid>
      {/* Order # */}
      <Grid sx={cl.grid} xs={1.8} item>
        <Typography
          sx={{
            ...cl.typography,
            fontWeight: 500
          }}
        >
          #{orderCustomId}
        </Typography>
      </Grid>
      {/* Customer */}
      <Grid sx={cl.grid} xs={2} item>
        <Box sx={cl.invoice.wrapper}>
          <Typography
            sx={{
              ...cl.typography,
              fontWeight: 400
            }}
          >
            {customerName}
          </Typography>
        </Box>
      </Grid>
      {/* Gross total */}
      <Grid sx={cl.grid} xs={1.6} item>
        <Typography sx={cl.typography}>
          ${separateNumWithComma(amount / 100)}
        </Typography>
      </Grid>
      {/* Fees */}
      <Grid sx={cl.grid} xs={1.6} item>
        <Typography sx={cl.typography}>
          -${separateNumWithComma(fee / 100)}
        </Typography>
      </Grid>
      {/* Net total */}
      <Grid sx={cl.grid} xs={1.4} item>
        <Typography sx={cl.typography}>
          ${separateNumWithComma(net / 100)}
        </Typography>
      </Grid>
      {/* Type */}
      <Grid sx={cl.grid} xs={1.2} item>
        <Typography sx={cl.typography}>
          {normalizeSnakeCaseString(type)}
        </Typography>
      </Grid>
    </Grid>
  );
};

TransactionsItem.propTypes = {
  date: string,
  orderCustomId: string,
  customerName: string,
  type: string,
  amount: number,
  fee: number,
  net: number,
  timeZone: string,
};
TransactionsItem.defaultProps = {
  date: "",
  orderCustomId: "",
  customerName: "",
  type: "",
  amount: 0,
  fee: 0,
  net: 0,
  timeZone: ""
};
