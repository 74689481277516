import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { func, object, bool } from "prop-types";

export const RecurringTaskContent = ({ setValue, formField, isDeleting }) => {
  const RECURRING_OPTIONS = [
    { name: "This task", value: false },
    {
      name: "This task and all future tasks",
      value: true,
    },
  ];

  return (
    <Stack>
      <Typography
        variant="header"
        sx={{
          fontSize: "15px",
          color: "#707070",
          textAlign: isDeleting ? "left" : "center",
        }}
      >
        This task is scheduled to repeat in the future. Choose what you want to
        edit.
      </Typography>
      <Box mt="20px">
        <RadioGroup
          value={formField.applyForFutureTasks}
          onChange={(e, newVal) => {
            setValue("applyForFutureTasks", newVal === "false" ? false : true, {
              shouldDirty: true,
            });
          }}
        >
          {RECURRING_OPTIONS.map(({ name, value }) => (
            <FormControlLabel
              key={name}
              label={name}
              control={<Radio />}
              value={value}
            />
          ))}
        </RadioGroup>
      </Box>
    </Stack>
  );
};

RecurringTaskContent.propTypes = {
  setValue: func,
  formField: object,
  isDeleting: bool,
};
