import { Button, Link, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FileIconOutlined } from "../../../../../../components/Icons";
import UploadFile from "../../../../../../components/UploadFile";
import useStyles from "../styles";
import { useImport } from "../Import.hooks";
import { useContext, useMemo } from "react";
import { ImportContext } from "Pages/SettingsPage/SettingsPage";
import { bytesToSize } from "firebase/Chat/helpers";

const ImportStepOne = () => {
  const classes = useStyles();
  const { handleUploadSheet, getCurrentTemplate } = useImport();
  const { importData } = useContext(ImportContext);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const { file } = useMemo(() => importData, [importData.file]);
  const templateHref = useMemo(
    () => getCurrentTemplate(),
    [getCurrentTemplate]
  );

  return (
    <Box>
      <Typography className={classes.bodyText}>
        {`- All ${importData.importType?.key
          ?.slice(0, -1)
          ?.toLowerCase()} information must be in one file\n- Top row of your file must contain a header title for each column of information`}
      </Typography>
      <Box className={classes.uploadBlock}>
        <Box mb="15px">
          <Typography mb="15px" fontSize="17px">
            Select a CSV or Excel file to upload
          </Typography>
          <Box className={classes.uploadWrapper}>
            <UploadFile
              label={
                file ? `${file.name}, ${bytesToSize(file.size)}` : "Browse file"
              }
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              wrapperProps={{
                variant: "contained",
                color: "uploadBtn",
                startIcon: <FileIconOutlined />,
                sx: {
                  border: "1px solid #d5d9d9",
                },
              }}
              Wrapper={Button}
              handleUploadFiles={handleUploadSheet}
            />{" "}
            {importData.uploading && (
              <Typography className={classes.uploadProgress}>
                {importData.uploadingProgress}%
              </Typography>
            )}
          </Box>
        </Box>
        <Link download target="_blank" fontSize="17px" href={templateHref}>
          Download sample template
        </Link>
      </Box>
    </Box>
  );
};

export default ImportStepOne;
