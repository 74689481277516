/* eslint-disable no-useless-escape */
import * as Yup from "yup";
import { URL_REGEXP } from "../../utils/validations";
import {
  LOWERCASE_REG_EXP,
  MIN_PASSWORD_LENGTH,
  NUMBER_REG_EXP,
  SYMBOL_REG_EXP,
  UPPERCASE_REG_EXP,
} from "components/PasswordCriteria/PasswordCriteria.constants";

const PHONE_REGEXP =
  /^[\+][\d]{1}[\s]?[\(]?[\d]{3}[\)]?[\s]?[\d]{3}[\s]?[\d]{4}$/im;

// const businessPhoneValidation = (value, ctx) => {
//   if (!PHONE_REGEXP.test(value))
//     return ctx.createError({
//       message: "Not valid phone number",
//     });
//   return true;
// };

export const validationSchema = () =>
  Yup.object().shape({
    email: Yup.string().trim().required("Email field is required"),
    password: Yup.string()
      .min(
        MIN_PASSWORD_LENGTH,
        `Password must be at least ${MIN_PASSWORD_LENGTH} characters`
      )
      .matches(
        LOWERCASE_REG_EXP,
        "Password must have at least one lowercase char"
      )
      .matches(
        UPPERCASE_REG_EXP,
        "Password must have at least one uppercase char"
      )
      .matches(NUMBER_REG_EXP, "Password must have at least one digit")
      .matches(SYMBOL_REG_EXP, "Password must have at least one special char")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords don't match!")
      .required("Required")
      .test("confirmPassword", "Incorrect confirm password", (value, ctx) => {
        return value === ctx?.parent?.password;
      }),
    name: Yup.string().trim().required("Legal business name field is required"),
    country: Yup.string().trim().required("Country field is required"),
    industry: Yup.string()
      .trim()
      .required("Legal business name field is required"),
    tax: Yup.string()
      .trim()
      .nullable()
      .required("Federal Tax ID (EIN) field is required")
      .test("tax", "Must be exactly 9 digits", (v) => {
        return /^\d{2}[-{1}]?\d{7}$/.test(v);
      }),
    // tax: Yup.string()
    //   .required("Federal Tax ID (EIN) field is required")
    //   .matches(FED_REGEXP, "Must be only digits")
    //   .test("tax", "Must be exactly 9 digits", (val) => val.length === 9),
    billingAddress: Yup.object().shape({
      formatted_address: Yup.string().required("This field is required"),
      street: Yup.string().required("This field is required"),
      city: Yup.string().required("This field is required"),
      state: Yup.string().required("This field is required").nullable(),
      zip: Yup.string().required("This field is required"),
      lat: Yup.number().required("Coordinates are missing"),
      lng: Yup.number().required("Coordinates are missing"),
    }),

    phone: Yup.string()
      .trim()
      .required("This field is required")
      .test(
        "phoneValidation",
        "Not valid phone number!",
        (value, { parent }) => {
          if (!value) return true;
          const { countryPrefics } = parent;
          return PHONE_REGEXP.test(`${countryPrefics?.phone_code}${value}`);
        }
      ),
    // phone: Yup.string()
    //   .trim()
    //   .required("Business phone field is required")
    //   .test((value, ctx) => businessPhoneValidation(value, ctx)),

    website: Yup.string()
      .nullable()
      .test("websiteValidation", "Enter valid link", (value) => {
        if (!value) return true;
        return URL_REGEXP.test(value);
      })
      .notRequired(),
    timeZone: Yup.string().trim().required("Time Zone field is required"),
  });
