import { array, shape, string, bool } from "prop-types";
import { Avatar, Box, Skeleton, Typography } from "@mui/material";
import { photoUrl, stringAvatar } from "../../../helpers/helpers";

export const TotalSummarySalesRepsItem = ({ salesRep, isLoading }) => {
  return isLoading ? (
    <Skeleton width={200} height={60} />
  ) : (
    <Box
      sx={{
        display: "flex",
        width: "22%",
        minWidth: "22%",
        alignItems: "center",
        gap: "8px",
        py: "3px",
        px: "3px",
        border: "0.5px solid rgba(213, 217, 217, 0.3)",
        borderRadius: "4px",
      }}
    >
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Box>
          {photoUrl(salesRep?.profilePhoto?.fileName) ? (
            <Avatar sx={{ width: "39px", height: "39px" }} variant="rounded">
              <Box
                component="img"
                sx={{ objectFit: "cover", width: "100%", height: "100%" }}
                src={photoUrl(salesRep?.profilePhoto.fileName)}
              />
            </Avatar>
          ) : (
            <Avatar
              {...stringAvatar(salesRep?.name, {
                width: "39px",
                height: "39px",
              })}
              variant="rounded"
            />
          )}
        </Box>
      </Box>
      <Box width="calc(100% - 50px)">
        <Typography
          sx={{
            fontSize: 13,
            color: "#1C1C19",
          }}
          noWrap
        >
          {salesRep?.name}
        </Typography>
      </Box>
    </Box>
  );
};

TotalSummarySalesRepsItem.propTypes = {
  salesRep: shape({
    name: string,
    photos: array,
  }),
  isLoading: bool,
};
