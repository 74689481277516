/* eslint-disable no-useless-escape */
import * as Yup from "yup";

export const validationSchema = () =>
  Yup.object().shape({
    lowInventoryThreshold: Yup.number()
      .required()
      .typeError("Must be a number.")
      .test(
        "defaultThresholdField",
        "Must be a positiv number",
        (value) => value >= 0
      ),
  });
