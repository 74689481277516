export const HomeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10.012"
      height="8.329"
      viewBox="0 0 10.012 8.329"
    >
      <path
        id="Path_5409"
        className="skipstroke"
        data-name="Path 5409"
        d="M6.538,2.3l-.329.358L5.88,2.3,1.2,6.216l.138.4H2.5V10.18a.455.455,0,0,0,.464.445H4.818a.455.455,0,0,0,.464-.445V7.508H7.136V10.18a.455.455,0,0,0,.464.445H9.454a.455.455,0,0,0,.464-.445V6.617h1.159l.138-.4Z"
        transform="translate(-1.203 -2.296)"
      />
    </svg>
  );
};
