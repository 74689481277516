import { getEnvironment } from "helpers/helpers";
import { APP_ENVS } from "utils/constants";

const currentEnv = getEnvironment();

export const TABS_PAGE = [
  {
    value: 0,
    label: "Tasks",
    disabled: false,
    showTooltip: false,
    tooltipText: "",
  },
  {
    value: 1,
    label: "Forms",
    disabled: currentEnv !== APP_ENVS.dev,
    showTooltip: currentEnv !== APP_ENVS.dev,
    tooltipText: "Coming soon",
  },
];

export const PRIORITIES = {
  low: "LOW",
  medium: "MEDIUM",
  high: "HIGH",
};

export const STATUSES_TASKS = {
  PENDING: "PENDING",
  COMPLETED: "COMPLETED",
};
