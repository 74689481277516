import { string, number } from "prop-types";
import { Box, Typography } from "@mui/material";

export const CardHeader = ({ name, amount, period }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          borderBottom: "0.5px solid #D5D9D9",
          width: "236px",
          height: "104px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box>
          <Typography
            fontSize={24}
            fontWeight={400}
            color="#5F6267"
            letterSpacing={-0.5}
          >
            {name}
          </Typography>
        </Box>

        <Box>
          <Typography fontSize={28} fontWeight={600} color="#5F6267">
            ${amount}
            <Box component="span" fontSize={16} fontWeight={400}>
              {" "}
              {period}
            </Box>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

CardHeader.propTypes = {
  name: string.isRequired,
  amount: number.isRequired,
  period: string.isRequired,
};
CardHeader.defaultProps = { name: "", amount: 0, period: "" };
